import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  editPersonAddress,
  addPersonAddress
} from "actions/configuration/Profile";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import axios from "axios";
import Api from "services/api";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import MaskCep from "components/Masks/MaskCep";
import Button from "@material-ui/core/Button";

class FormAddress extends Component {
  state = {
    id: "",
    name: "",
    postalcode: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    country: "",
    error: false
  };

  componentDidMount() {
    if (this.props.address.id) {
      const {
        id,
        name,
        postalcode,
        address,
        number,
        complement,
        neighborhood,
        city,
        state,
        country
      } = this.props.address;

      this.setState({
        id,
        name,
        postalcode,
        address,
        number,
        complement,
        neighborhood,
        city,
        state,
        country
      });
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  searchCep = event => {
    let cep = event.target.value.replace(/_/g, "");
    if (cep.length === 9) {
      cep = cep.replace(",", "");
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => {
          this.setState({
            postalcode: res.data.cep,
            address: res.data.logradouro,
            neighborhood: res.data.bairro,
            city: res.data.localidade,
            state: res.data.uf,
            country: "Brasil"
          });
        })
        .catch(() => {
          console.log("Ocorreu um erro ao recuperar as informações do cep.");
        });
    }
  };

  // retorna o formato do obj do store
  getObj = address => {
    let addressContent = `${address.address}, ${address.number}`;
    addressContent += address.complement
      ? ` - Comp: ${address.complement}`
      : "";
    addressContent += ` - ${address.neighborhood}. ${address.city} - ${address.state} - ${address.country}. CEP: ${address.postalcode}.`;
    return {
      addressContent,
      addressTitle: address.name,
      userAddress: { ...address }
    };
  };

  validate = () => {
    const { name, address, number, city } = this.state;
    if (name && address && number && city) {
      this.setState({ error: false });
      return true;
    } else {
      this.setState({ error: true });
      return false;
    }
  };

  save() {
    if (this.validate()) {
      const user = this.props.user;
      let formData = {
        user,
        data: this.state
      };

      Api.post(`/microservice/profile/saveAddressPerson/`, formData)
        .then(res => {
          let data = this.state;
          if (data.id > 0) {
            this.props.editPersonAddress(this.getObj(data));
          } else {
            data.id = res.data.record.id;
            this.props.addPersonAddress(this.getObj(data));
          }
          this.props.viewAlertMessage("success", "Salvo com sucesso!");
          setTimeout(() => this.props.closeAlertMessage(), 5000);
          this.props.handleClose();
        })
        .catch(() => {
          this.props.viewAlertMessage("error", "Ocorreu um erro ao salvar!");
          setTimeout(() => this.props.closeAlertMessage(), 5000);
        });
    }
  }

  render() {
    const {
      id,
      name,
      postalcode,
      address,
      number,
      complement,
      neighborhood,
      city,
      state,
      country,
      error
    } = this.state;
    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="text-primary font-weight-semibold">
            {id > 0 ? "Editar endereço" : "Cadastrar endereço"}
          </h2>
        </div>
        <div className="col-md-12 col-12">
          <TextField
            id="description"
            select
            required
            label="Descrição:"
            value={name}
            onChange={this.handleChange("name")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            error={error && !name ? true : false}
          >
            <MenuItem key="1" value="Residencial">
              Residencial
            </MenuItem>
            <MenuItem key="2" value="Comercial">
              Comercial
            </MenuItem>
            <MenuItem key="3" value="Cobrança">
              Cobrança
            </MenuItem>
          </TextField>
        </div>
        <div className="col-md-12 col-12">
          <TextField
            id="postalcode"
            label="CEP"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCep,
              value: postalcode,
              onChange: this.searchCep
            }}
          />
        </div>
        <div className="col-md-12 col-12">
          <TextField
            id="address"
            label="Logradouro"
            value={address}
            margin="normal"
            fullWidth
            onChange={this.handleChange("address")}
            error={error && !address ? true : false}
          />
        </div>
        <div className="col-md-4 col-12">
          <TextField
            id="number"
            label="Número"
            value={number}
            margin="normal"
            fullWidth
            onChange={this.handleChange("number")}
            error={error && !number ? true : false}
          />
        </div>
        <div className="col-md-8 col-12">
          <TextField
            id="complement"
            label="Complemento"
            value={complement}
            margin="normal"
            fullWidth
            onChange={this.handleChange("complement")}
          />
        </div>
        <div className="col-md-6 col-12">
          <TextField
            id="neighborhood"
            label="Bairro"
            value={neighborhood}
            margin="normal"
            fullWidth
            onChange={this.handleChange("neighborhood")}
          />
        </div>
        <div className="col-md-6 col-12">
          <TextField
            id="city"
            label="Cidade"
            value={city}
            margin="normal"
            fullWidth
            InputProps={{ readOnly: postalcode ? true : false }}
            onChange={this.handleChange("city")}
            error={error && !city ? true : false}
          />
        </div>
        <div className="col-md-6 col-12">
          <TextField
            id="state"
            label="Estado"
            value={state}
            margin="normal"
            fullWidth
            InputProps={{ readOnly: postalcode ? true : false }}
            onChange={this.handleChange("state")}
            error={error && !state ? true : false}
          />
        </div>
        <div className="col-md-6 col-12">
          <TextField
            id="country"
            label="País"
            value={country}
            margin="normal"
            fullWidth
            InputProps={{ readOnly: postalcode ? true : false }}
            onChange={this.handleChange("country")}
          />
        </div>
        <div className="col-md-12 mt-20">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={() => this.props.handleClose()}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm bg-success"
            onClick={() => this.save()}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.authUser
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editPersonAddress,
      addPersonAddress,
      viewAlertMessage,
      closeAlertMessage
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormAddress);
