import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { hideChatModal, setMinimaze } from "actions/comunication/Chat";

import Draggable from "react-draggable";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Paper, IconButton, Badge } from "@material-ui/core";
import Comunication from "../../../app/routes/comunication/routes/chat/Comunication";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles(theme => ({
  chatBox: {
    position: "absolute",
    zIndex: "1200",
    width: "350px"
  },
  minimazeChat: {
    position: "fixed",
    zIndex: "1200",
    bottom: "0",
    cursor: "pointer"
  }
}));
const StyledBadge = withStyles(theme => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}))(Badge);

export default function ChatDraggable() {
  const openModalDiag = useSelector(({ chat }) => chat.chatModal);
  const selectedUser = useSelector(({ chat }) => chat.selectedUser);
  const notRead = useSelector(({ chat }) => chat.notRead);
  const minimaze = useSelector(({ chat }) => chat.minimaze);
  const width = useSelector(({ settings }) => settings.width);
  const classes = useStyles();
  const dispatch = useDispatch();

  const x = 200;
  const left = 80;
  return (
    openModalDiag &&
    selectedUser.map((row, index) => {
      if (!minimaze[row.chat_id]) {
        return (
          <Draggable
            key={index}
            handle=".handler"
            positionOffset={{
              x: `${index !== 0 ? x - index * 100 : x}%`,
              y: width > 1366 ? "91%" : "61%"
            }}
          >
            <Paper className={classes.chatBox} elevation={3}>
              <Comunication
                onToggleDrawer={() => {}}
                modal={true}
                selectedUser={row}
              />
            </Paper>
          </Draggable>
        );
      } else {
        return (
          <Paper
            className={classes.minimazeChat}
            style={{ left: `${index !== 0 ? left - index * 20 : left}%` }}
            elevation={3}
          >
            <div
              className="chat-main"
              onClick={() => dispatch(setMinimaze(row.chat_id, false))}
            >
              <div className={`chat-main-header p-2`}>
                <div className="chat-avatar">
                  <div className="chat-avatar-mode">
                    <StyledBadge
                      color="primary"
                      badgeContent={notRead[row.chat_id]}
                    >
                      <img
                        src={row.thumb}
                        className={`rounded-circle size-30`}
                        alt=""
                      />
                    </StyledBadge>

                    <span className={`chat-mode ${row.status}`} />
                  </div>
                </div>

                <div
                  className="chat-contact-name mr-2"
                  style={{ fontSize: "14px" }}
                >
                  {row.name.length > 25
                    ? row.name.substring(0, 20) + "..."
                    : row.name}
                </div>
                <IconButton
                  style={{ padding: "0", float: "right" }}
                  color="secondary"
                  onClick={() => dispatch(hideChatModal(row.chat_id))}
                >
                  <HighlightOffIcon />
                </IconButton>
              </div>
            </div>
          </Paper>
        );
      }
    })
  );
}
