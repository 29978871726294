import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { Button, TextField, MenuItem } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import SearchPatient from "./SearchPatient";
import Api from "services/api";

export default function FormContact({ users, closeMenu, width }) {
  const dispatch = useDispatch();
  const initial = {
    modal_form: false,
    remetente: "",
    nameRemetent: "",
    patient: "",
    forma_envio: "",
    mensage: "",
    error: false,
    loading: false,
  };
  const [state, setState] = useState(initial);
  const Auth = useSelector((state) => state.auth.authUser);

  const ComponentForm = () => (
    <>
      <div className="col-12" style={{ marginTop: 10 }}>
        <TextField
          select
          label="Remetente"
          required
          fullWidth
          value={state.remetente}
          onChange={(e) =>
            setState({
              ...state,
              remetente: e.target.value,
              nameRemetent: e.nativeEvent.target.textContent,
            })
          }
          error={state.error && !state.remetente ? true : false}
        >
          {users.map((el) => (
            <MenuItem key={el.user_id} value={el.user_id}>
              {el.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="col-12" style={{ marginTop: 10 }}>
        <SearchPatient
          handleChangeAutocomplete={(obj) =>
            setState({ ...state, idPatient: obj.id, patient: obj.name })
          }
          error={state.error && !state.idPatient ? true : false}
          req={true}
        />
      </div>
      <div className="col-12" style={{ marginTop: 10 }}>
        <TextField
          select
          label="Forma de envio"
          required
          fullWidth
          value={state.forma_envio}
          onChange={(e) => setState({ ...state, forma_envio: e.target.value })}
          error={state.error && !state.forma_envio ? true : false}
        >
          <MenuItem value="sms">SMS</MenuItem>
          {/* <MenuItem value="wpp">WhatsApp</MenuItem> */}
        </TextField>
      </div>
      <div className="col-12" style={{ marginTop: 10 }}>
        <TextField
          multiline
          rows={4}
          label="Mensagem"
          required
          fullWidth
          value={state.mensage}
          onChange={(e) => setState({ ...state, mensage: e.target.value })}
          error={state.error && !state.mensage ? true : false}
        />
      </div>
    </>
  );

  const save = () => {
    if (
      state.remetente &&
      state.idPatient &&
      state.forma_envio &&
      state.mensage
    ) {
      setState({ ...state, error: false, loading: true });
      const obj = {
        remetente: state.remetente,
        nameRemetent: state.nameRemetent,
        patient: state.patient,
        idPatient: state.idPatient,
        mensage: state.mensage,
        forma_envio: state.forma_envio,
        Auth,
      };
      Api.post(`/ms_system/calendar/sentMessagePatient/`, obj)
        .then((res) => {
          // const {} = res.data;
          if (res.data.success) {
            dispatch(
              viewAlertMessage("success", "Mensagem Enviada com sucesso!")
            );
            setState({
              ...state,
              remetente: "",
              nameRemetent: "",
              patient: "",
              forma_envio: "",
              mensage: "",
              idPatient: "",
              loading: false,
            });
            closeMenu();
            setTimeout(() => dispatch(closeAlertMessage()), 5000);
          }
          setState({ ...state, modal_form: false, loading: false });
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao Enviar!"));
          setTimeout(() => dispatch(closeAlertMessage(), 5000));
          setState({ ...state, loading: false });
        });
    } else {
      setState({ ...state, error: true });
    }
  };

  return (
    <>
      {width > 500 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setState({ ...state, modal_form: true })}
          style={{ marginTop: 15, marginLeft: 10 }}
        >
          <i className="zmdi zmdi-comment-text mr-3" /> Contatar Paciente
        </Button>
      ) : (
        <MenuItem onClick={() => setState({ ...state, modal_form: true })}>
          <i className="zmdi zmdi-comment-text mr-3" /> Contatar Paciente
        </MenuItem>
      )}

      <ModalResponsive
        title="Contatar paciente"
        open={state.modal_form}
        close={() => {
          setState(initial);
          closeMenu();
        }}
        maxWidth="sm"
        buttons={
          <div className="col-md-12">
            <Button
              hidden={state.loading}
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => {
                setState(initial);
                closeMenu();
              }}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              hidden={state.loading}
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right", marginRight: 0 }}
              onClick={() => save()}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Enviar </span>
            </Button>
          </div>
        }
      >
        {ComponentForm()}
        <CircularProgress hidden={!state.loading} style={{ float: "right" }} />
      </ModalResponsive>
    </>
  );
}
