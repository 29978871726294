import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%",
  },
  root: {
    backgroundColor: "#fff",
  },
  selected: {
    backgroundColor: "#e2e2e8 !important",
  },
};

class AutocompleteOcuppation extends Component {
  state = {
    suggestions: [],
    selected: null,
  };

  componentWillReceiveProps(nextProps) {
    const { id, name } = nextProps.drugStore;
    this.setState({ selected: [{ id, name }] });
  }

  getSuggestions = (e) => {
    if (e.target.value.length > 2) {
      Api.post(`/microservice/autocomplete/getDrugStores/`, {
        term: e.target.value,
      }).then((res) => {
        const { data } = res;
        this.setState({
          suggestions: data,
        });
      });
    }
  };

  renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    const { classes } = this.props;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
      >
        {suggestion.name}
      </MenuItem>
    );
  }

  renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...InputProps,
        }}
        onKeyUp={this.getSuggestions}
        onChange={this.handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { suggestions, selected } = this.state;

    return (
      <Downshift
        onSelect={(selectedItem) => {
          let item = suggestions.filter((row) => {
            return row.name === selectedItem;
          });
          this.props.change("fk_drug_store", item[0].id);
          this.props.change("drug_store", item[0].name);
        }}
        selectedItem={selected ? selected[0].name : null}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            {this.renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                id: "drugStore",
              }),
              label: "Farmácia Padrão:",
              margin: "normal",
            })}
            {isOpen ? (
              <Paper square>
                {suggestions.map((suggestion, index) =>
                  this.renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.name,
                    }),
                    highlightedIndex,
                    selectedItem,
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

AutocompleteOcuppation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutocompleteOcuppation);
