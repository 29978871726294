import React, { useState, useEffect } from "react";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnSave, BtnCan } from "components/Button";
import MaskCellphone from "components/Masks/MaskCellphone";
import BarProgress from "components/BarProgress";

import { FormHelperText, TextField } from "@material-ui/core";

function ModalSendToDrugStore({ open, handleClose, type, handleSend }) {
  const [email, setEmail] = useState("");
  const [celphone, setCelphone] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setEmail("");
      setCelphone("");
      setError(false);
      setLoading(false);
    }
  }, [open]);

  const handleCancel = () => {
    setEmail("");
    setCelphone("");
    setError(false);
    setLoading(false);
    handleClose();
  };
  const handleSave = () => {
    if (type === "email" && email) {
      handleSend(type, email);
      setLoading(true);
    } else if (type === "sms" && celphone) {
      handleSend(type, celphone);
      setLoading(true);
    } else {
      setError(true);
    }
  };
  return (
    <ModalResponsive
      title={type === "email" ? "Enviar por E-mail" : "Enviar por SMS"}
      open={open}
      close={handleClose}
      maxWidth={"sm"}
      buttons={
        !loading && (
          <div className="col-12">
            <BtnCan title={"Cancelar"} onClick={handleCancel} float="left" />
            <BtnSave title={"Enviar"} onClick={handleSave} float="right" />
          </div>
        )
      }
    >
      {loading && <BarProgress />}
      {!loading && (
        <div className="container">
          {type === "email" && (
            <div className="row justify-content-center">
              <div className="col-md-10">
                <TextField
                  type="email"
                  fullWidth
                  value={email}
                  error={error && !email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="E-mail"
                />
                <FormHelperText>Digite o E-mail da farmácia</FormHelperText>
              </div>
            </div>
          )}
          {type === "sms" && (
            <div className="row justify-content-center">
              <div className="col-md-10">
                <TextField
                  fullWidth
                  margin="normal"
                  InputProps={{
                    inputComponent: MaskCellphone,
                    value: celphone,
                    onChange: (e) => setCelphone(e.target.value),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={error && !celphone}
                  label="Celular"
                />
                <FormHelperText>Digite o celular da farmácia</FormHelperText>
              </div>
            </div>
          )}
        </div>
      )}
    </ModalResponsive>
  );
}

export default ModalSendToDrugStore;
