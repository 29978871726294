import React from "react";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { Tooltip, Checkbox } from "@material-ui/core";

const DragHandle = SortableHandle(() => (
  <Tooltip title="Mover item">
    <i className="zmdi zmdi-menu draggable-icon" style={{ fontSize: 25 }} />
  </Tooltip>
));

function Cell({ cell, handleCheck, sort }) {
  return (
    <div className="col-12 contact-item ripple row no-gutters align-items-center pt-0 pb-0">
      {sort && <DragHandle />}

      <Checkbox
        checked={cell.checked}
        onChange={(e) => handleCheck(cell.id, e.target.checked)}
        value="checkedB"
        color="primary"
        className="ml-3"
        inputProps={{
          "aria-label": "secondary checkbox",
        }}
      />

      <div className="col text-truncate font-weight-bold ml-3">
        {cell.description}
      </div>
      
    </div>
  );
}

export default SortableElement(Cell);
