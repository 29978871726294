import React from "react";
import {
  MenuItem,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  IconButton,
  withStyles,
  Slider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Api from "services/api";
import CircularProgress from "components/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { BtnCan } from "components/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { showMsg } from "services/functions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "white",
    flex: 1,
  },
  score: {
    marginTop: "20px",
    display: "block",
    fontSize: "18px",
    fontWeight: "500",
  },
  score_watter: {
    marginLeft: "15px",
    display: "block",
    fontSize: "18px",
    fontWeight: "500",
  },
  styleModal: {
    "& .MuiDialogContent-root": {
      padding: "0px",
    },
  },
}));

const CustomSlider = withStyles((theme) => ({
  root: {
    maxWidth: "90%",
    marginTop: "2rem",
  },
  thumb: {
    backgroundColor: "#fff",
    border: "1px solid #707070",
  },
  valueLabel: {
    left: "calc(-50% - 6px)",
  },
}))(Slider);

export default function ViewHistory({
  open,
  question,
  patient,
  handleClose,
  max_watter,
  getDataQuizes,
}) {
  const [state, setState] = React.useState({
    option: "7",
    start: moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });

  const [record, setRecord] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const classes = useStyles();

  const width = useSelector((state) => state.settings.width);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (question) {
      getData(state.start, state.end);
    }
  }, [question]);

  /*   React.useEffect(() => {
    getData();
  }, [state.start, state.end]) */

  const handleChangeDate = (value) => {
    let init = state.start;
    let last = state.end;

    switch (value) {
      case "30":
        init = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        last = moment().format("YYYY-MM-DD");
        break;
      case "60":
        init = moment()
          .subtract(60, "days")
          .format("YYYY-MM-DD");
        last = moment().format("YYYY-MM-DD");
        break;
      case "90":
        init = moment()
          .subtract(90, "days")
          .format("YYYY-MM-DD");
        last = moment().format("YYYY-MM-DD");
        break;
      case "7":
        init = moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD");
        last = moment().format("YYYY-MM-DD");
        break;
      case "15":
        init = moment()
          .subtract(15, "days")
          .format("YYYY-MM-DD");
        last = moment().format("YYYY-MM-DD");
        break;
    }
    if (value !== "especific") {
      getData(init, last);
    }
    setState({
      option: value,
      start: init,
      end: last,
    });
  };

  const getData = (start, end) => {
    setLoader(true);
    Api.post("microservice/patientportal/getHistoryQuizze", {
      patient,
      start,
      end,
      question,
    })
      .then((res) => {
        setRecord(res.data.record);
        setLoader(false);
      })
      .catch(() => {
        setRecord([]);
        setLoader(false);
      });
  };

  const getContent = (line) => {
    switch (question) {
      case "mood":
        return contentMood(line);
      case "watter":
        return contentWatter(line);
      case "sleep":
        return contentSleep(line);
      case "complaint":
        return contentComplaint(line);
      case "humor":
        return contentHumor(line);
      default:
        return "";
    }
  };

  const contentMood = (line) => (
    <div className="row">
      <div className="col-10">
        <span>Como está a sua energia?</span>
        <CustomSlider
          step={1}
          marks
          min={1}
          max={10}
          value={line.mood}
          style={{
            color: !line.mood
              ? "#d1d1d1"
              : parseInt(line.mood) >= 8
              ? "#00B1E2"
              : parseInt(line.mood) > 4
              ? "#EEE800"
              : "#D6201B",
          }}
          valueLabelDisplay="on"
          aria-labelledby="continuous-slider"
        />
      </div>
      <div className="col-2">
        <span className={classes.score}>{line.mood}</span>
      </div>
    </div>
  );

  const contentWatter = (line) => (
    <div className="row">
      <div className="col-12 d-flex">
        <span style={{ marginTop: "2px" }}>Copos d'àgua</span>
        <span
          className={classes.score_watter}
          style={{
            color:
              parseInt(max_watter) > parseInt(line.watter) ? "red" : "green",
          }}
        >
          {line.watter}
        </span>
      </div>
    </div>
  );

  const contentSleep = (line) => (
    <div className="row">
      <div className="col-10">
        <span>Qualidade do sono</span>
        <CustomSlider
          step={1}
          marks
          min={1}
          max={10}
          value={line.sleep}
          valueLabelDisplay="on"
          aria-labelledby="continuous-slider"
          style={{
            color: !line.sleep
              ? "#d1d1d1"
              : line.sleep >= 6
              ? "#00B1E2"
              : line.sleep > 3
              ? "#EEE800"
              : "#D6201B",
          }}
        />
      </div>
      <div className="col-2">
        <span className={classes.score}>{line.sleep}</span>
      </div>
      <div className="col-12 d-flex">
        <span style={{ marginTop: "2px" }}>Horas de sono</span>
        <span className={classes.score_watter}>{line.sleep_duration}</span>
      </div>
    </div>
  );

  const contentComplaint = (line) => {
    let values = [];
    if (line.value) {
      let aux = line.value.split(",");
      let aux2 = line.score.split(",");
      aux.forEach((row, i) => {
        values.push({
          value: row,
          score: aux2[i],
        });
      });
    }

    return (
      <div className="row">
        <div className="col-12 d-flex">
          <span style={{ marginTop: "2px" }}>Problemas não relatados</span>
          <span
            className={classes.score_watter}
            style={{
              color: line.complaint === "1" ? "red" : "green",
            }}
          >
            {line.complaint === "1" ? "Sim" : "Não"}
          </span>
        </div>
        <ul>
          {values.map((val) => (
            <li>
              {val.value} - {val.score}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const contentHumor = (line) => {
    let values = [];
    if (line.value) {
      let aux = line.value.split(",");
      let aux2 = line.score.split(",");
      aux.forEach((row, i) => {
        values.push({
          value: row,
          score: aux2[i],
        });
      });
    }

    return (
      <div className="row">
        <div className="col-10">
          <span>Como está se sentindo</span>
          <CustomSlider
            step={1}
            marks
            min={1}
            max={10}
            value={line.humor}
            valueLabelDisplay="on"
            aria-labelledby="continuous-slider"
            style={{
              color: !line.humor
                ? "#d1d1d1"
                : Number(line.humor) >= 8
                ? "#00B1E2"
                : Number(line.humor) > 4
                ? "#EEE800"
                : "#D6201B",
            }}
          />
        </div>
        <div className="col-2">
          <span className={classes.score}>{line.humor}</span>
        </div>

        <ul>
          {values.map((val) => (
            <li>
              {val.value} - {val.score}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  function del(id) {
    let urlApi = "";
    switch (question) {
      case "mood":
        urlApi = "delAboutDay";
        break;
      case "watter":
        urlApi = "delHydration";
        break;
      case "sleep":
        urlApi = "delSleep";
        break;
      case "complaint":
        urlApi = "delComplaint";
        break;
      case "humor":
        urlApi = "delFeeling";
        break;
      default:
        break;
    }
    if (urlApi) {
      Api.post(`microservice/patientportal/${urlApi}`, {
        id,
      })
        .then(({ data }) => {
          if (data.success) {
            showMsg("success", "Respostas apagadas", dispatch);
            setRecord(record.filter((row) => row.id !== id));
            getDataQuizes();
          } else {
            throw true;
          }
        })
        .catch(() => {
          showMsg("error", "Não foi possível apagar suas respostas", dispatch);
        });
    }
  }

  return (
    <Dialog
      fullScreen={width < 1024 ? true : false}
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
      className={classes.styleModal}
      maxWidth={`md`}
      PaperProps={{
        style: {
          overflowX: "hidden",
          background: "#f1f1f1",
          padding: "0px",
        },
      }}
    >
      {width < 1024 ? (
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Histórico
            </Typography>
          </Toolbar>
        </AppBar>
      ) : (
        <DialogTitle style={{ background: "#fff" }} id="max-width-dialog-title">
          Histórico
        </DialogTitle>
      )}
      <DialogContent style={{ background: width < 1024 ? "#f1f1f1" : "#fff" }}>
        {loader ? (
          <CircularProgress />
        ) : (
          <div className={width < 1024 ? "p-0 m-0" : "p-3 m-0"}>
            {question !== "complaint" && (
              <div className="mt-3 col-12">
                <TextField
                  select
                  value={state.option}
                  onChange={(e) => handleChangeDate(e.target.value)}
                  label="Período:"
                  fullWidth
                >
                  <MenuItem key="3" value="7">
                    Últimos 7 dias
                  </MenuItem>
                  <MenuItem key="4" value="15">
                    Últimos 15 dias
                  </MenuItem>
                  <MenuItem key="0" value="30">
                    Últimos 30 dias
                  </MenuItem>
                  <MenuItem key="1" value="60">
                    Últimos 60 dias
                  </MenuItem>
                  <MenuItem key="2" value="90">
                    Últimos 90 dias
                  </MenuItem>
                  <MenuItem key="5" value="especific">
                    Período específico
                  </MenuItem>
                </TextField>
              </div>
            )}
            {state.option === "especific" && question !== "complaint" && (
              <>
                <div className="col-md-6 col-xs-12 mt-3">
                  <TextField
                    id="date"
                    type="date"
                    label="Data Inicial"
                    value={state.start}
                    onChange={(e) =>
                      setState({
                        ...state,
                        start: e.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-6 col-xs-12 mt-3">
                  <TextField
                    id="date"
                    type="date"
                    label="Data Final"
                    value={state.end}
                    onChange={(e) =>
                      setState({
                        ...state,
                        end: e.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </>
            )}

            {record.map((row, index) => (
              <div className="col-12 d-flex card mt-3 pt-2">
                <div className="row px-3 align-items-center justify-content-between">
                  <Typography variant="subtitle2" gutterBottom>
                    <strong>{row.date}</strong>
                  </Typography>
                  {index === 0 && (
                    <IconButton onClick={() => del(row.id)} color="secondary">
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
                {getContent(row)}
              </div>
            ))}
            {record.length === 0 && (
              <div className="col-12 mt-5">
                <Typography
                  variant="subtitle2"
                  className="text-center"
                  gutterBottom
                >
                  <i>Nenhum Registro.</i>
                </Typography>
              </div>
            )}
          </div>
        )}
      </DialogContent>
      {width >= 1024 && (
        <DialogActions
          style={{ background: "#fff", justifyContent: "end", display: "flex" }}
        >
          <BtnCan float={"left"} title={"fechar"} onClick={handleClose} />
        </DialogActions>
      )}
    </Dialog>
  );
}
