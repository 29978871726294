const INITIAL_STATE = {
  message_type: "",
  alert_message: false,
  text_message: "",
  wrongBrowser: false,
};

export default function alertMessage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "VIEW_ALERT_MESSAGE":
      return {
        ...state,
        message_type: action.message_type,
        alert_message: true,
        text_message: action.text_message,
      };
    case "CLOSE_ALERT_MESSAGE":
      return {
        ...state,
        message_type: "success",
        alert_message: false,
        text_message: "",
      };
    case "WRONG_BROWSER":
      return {
        ...state,
        wrongBrowser: action.payload,
      };
    default:
      return state;
  }
}
