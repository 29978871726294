const INITIAL_STATE = {
  loader: true,
  active: "view",
  color: "primary",
  text: "Adicionar",
  icon: "plus",
  cadList: [],
  formNutrition: {
    edit: false,
    error: false,
    suplementsList: [],
  },
  // hidden: "hidden"
};

export default function habits(state = INITIAL_STATE, action) {
  let list;
  switch (action.type) {
    case "GET_LIST_HABITS":
      return {
        ...state,
        rows: action.payload.list,
        multiOptions: action.payload.multiple,
      };
    case "REQUEST_CAD_HABITS":
      return {
        ...state,
        loader: true,
      };
    case "REQUEST_CAD_HABITS_SUCCESS":
      return {
        ...state,
        loader: false,
        cadList: action.payload.cadList ? action.payload.cadList : [],
        cadListOptions: action.payload.cadListOptions,
      };
    case "ADD_HABITS_SUCCESS":
      return {
        ...state,
        success: action.payload.success,
      };
    case "CHANGE_BUTTON":
      return {
        ...state,
        color: action.color,
        text: action.text,
        active: action.active,
        icon: action.icon,
      };
    case "ADD_NEW_HABIT":
      let idsRows = state.rows.map((el) => el.id);
      let newRows = [];
      action.rows.forEach((element) => {
        if (idsRows.indexOf(element.id) === -1) {
          newRows.push(element);
        }
      });

      let idsCad = state.cadList.map((el) => el.fk_ls_personal_habits_list);
      let newCads = [];
      action.cadList.forEach((element) => {
        if (idsCad.indexOf(element.fk_ls_personal_habits_list) === -1) {
          newCads.push(element);
        }
      });
      return {
        ...state,
        rows: [...state.rows, ...newRows],
        cadList: [...state.cadList, ...newCads],
      };

    case "HANDLE_VALIDATE_HABITS":
      return {
        ...state,
        cadList: state.cadList.map((row) =>
          row.id === action.id ? { ...row, approved_by: action.user } : row
        ),
      };
    case "RESET_HABITS":
      return INITIAL_STATE;
    case "GET_FORM_HABITS_NUTRITION":
      return {
        ...state,
        formNutrition: action.payload.formNutrition,
      };
    case "FORM_HABITS_NUTRITION":
      return {
        ...state,
        formNutrition: {
          ...state.formNutrition,
          [action.payload.name]: action.payload.value,
          edit: true,
        },
      };
    case "FORM_HABITS_NUTRITION_HABITS":
      if (state.formNutrition[action.payload.name] === undefined) {
        list = [action.payload.value];
      } else {
        let find = state.formNutrition[action.payload.name].filter(
          (el) => el.id !== action.payload.value.id
        );
        list = [...find, action.payload.value];
      }
      return {
        ...state,
        formNutrition: {
          ...state.formNutrition,
          [action.payload.name]: list,
          edit: false,
        },
      };
    case "FORM_HABITS_NUTRITION_HABITS_OBS":
      let newValues = state.formNutrition.eating_habits.map((row) => {
        if (row.id === action.payload.id) {
          return {
            ...row,
            [action.payload.nameObs]: action.payload.valueObs,
          };
        } else {
          return row;
        }
      });
      return {
        ...state,
        formNutrition: {
          ...state.formNutrition,
          eating_habits: newValues,
          edit: false,
        },
      };
    case "FORM_HABITS_NUTRITION_SAVE":
      return {
        ...state,
        formNutrition: {
          ...state.formNutrition,
          id: action.payload.id,
          edit: false,
        },
      };
    case "FORM_HABITS_NUTRITION_SUPLEMENTS":
      let newReg = state.formNutrition.suplementsList.find(
        (el) => el.id === action.payload.id
      );
      if (action.payload.dosage !== undefined)
        newReg[action.payload.field] = action.payload.dosage;
      if (action.payload.whatSup !== undefined)
        newReg[action.payload.field] = action.payload.whatSup;
      return {
        ...state,
        formNutrition: {
          ...state.formNutrition,
          edit: true,
        },
      };
    case "FORM_HABITS_NUTRITION_ADD_SUPLEMENTS":
      let id = state.formNutrition.suplementsList.length;
      let obj = {
        id,
        [`obsWhatSuplements${id}`]: "",
        [`obsDosageSuplements${id}`]: "",
      };
      list = [...state.formNutrition.suplementsList, obj];
      return {
        ...state,
        formNutrition: {
          ...state.formNutrition,
          suplementsList: list,
          edit: true,
        },
      };
    case "FORM_HABITS_NUTRITION_REMOVE_SUPLEMENTS":
      list = state.formNutrition.suplementsList.filter(
        (el) => el.id !== action.payload.id
      );
      return {
        ...state,
        formNutrition: {
          ...state.formNutrition,
          suplementsList: list,
          edit: true,
        },
      };
    default:
      return state;
  }
}
