const INITIAL_STATE = {
  values: []
};

export default function Aga(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_QUESTIONS_AGA_COMPLETE":
      return { ...state, questions: action.questions.getQuestions };
    case "REQUEST_HISTORY_AGA_COMPLETE":
      return { ...state, values: action.payload };
    case "ADD_NEW_AGA":
      return { ...state, success: action.payload };
    case "REQUEST_HISTORY_REGISTER_AGA_COMPLETE":
      return { ...state, respQuestions: action.payload };
    case "RESET_AGA":
      return INITIAL_STATE;
    default:
      return state;
  }
}
