import React, { useState } from "react";

import Api from "services/api";

import { Typography, Icon } from "@material-ui/core";
import BorderLinearProgress from "components/BorderLinearProgress";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import AllQuizes from "./AllQuizes";
import QuizesDiario from "./QuizesDiario";
import Pian from "./Pain";
import Hydration from "./Hydration";
import Sleep from "./Sleep";
import Feeling from "./Feeling";
import Energy from "./Energy";
import SymptomsCovid from "./SymptomsCovid";
import VaccineCovid from "./VaccineCovid";

export default function PatientQuizes() {
  const width = useSelector((state) => state.settings.width);
  const dispatch = useDispatch();

  const [quiz, setQuiz] = useState("");
  const [state, setState] = useState({
    complaint: null,
    feeling: null,
    aboutDay: null,
    sleep: null,
    watter: null,
    hydration: null,
  });

  React.useEffect(() => {
    getData();
    dispatch({
      type: "SET_ROUTE",
      payload: "patient-quizes",
    });
  }, []);

  React.useEffect(() => {
    !quiz && getData();
  }, [quiz]);

  function getData() {
    Api.post("microservice/patientportal/getAnswersDay", {}).then(
      ({ data }) => {
        let { complaint, sleep, watter, hydration, max_watter } = data;
        let steps = 0;
        if (complaint) {
          Object.keys(complaint).forEach((el) => {
            if (el !== "id" && el !== "show") {
              steps++;
            }
          });
        }
        setState({
          ...state,
          complaint: { ...complaint, steps },
          sleep,
          watter: { watter, max_watter },
          hydration,
          max_watter,
        });
      }
    );
  }

  return (
    <div
      className={width < 500 ? "w-100" : "w-100 p-3"}
      style={{ marginBottom: "70px" }}
    >
      {!quiz && (
        <div className="col-12 ml-2">
          <Typography className="pt-1" variant="h6" gutterBottom>
            Check up diário
          </Typography>
        </div>
      )}

      {!quiz && (
        <>
          <Typography className="ml-3" variant="subtitle2" gutterBottom>
            <strong>Perguntas deste instante</strong>
          </Typography>
          <AllQuizes setQuiz={setQuiz} progress={state} getData={getData} />
          <Typography className="ml-3" variant="subtitle2" gutterBottom>
            <strong>Perguntas diárias</strong>
          </Typography>
          <QuizesDiario setQuiz={setQuiz} progress={state} getData={getData} />
        </>
      )}
      {quiz === "pain" && (
        <Pian saved={state.complaint} handleClose={() => setQuiz("")} />
      )}
      {quiz === "hydration" && (
        <Hydration saved={state.watter} handleClose={() => setQuiz("")} />
      )}
      {quiz === "sleep" && (
        <Sleep saved={state.sleep} handleClose={() => setQuiz("")} />
      )}
      {quiz === "feeling" && (
        <Feeling
          saved={state.feeling}
          handleClose={() => setQuiz("")}
          setFeeling={(value) => setState({ ...state, feeling: value })}
        />
      )}
      {quiz === "energy" && (
        <Energy
          saved={state.aboutDay}
          handleClose={() => setQuiz("")}
          setAboutDay={(value) => setState({ ...state, aboutDay: value })}
        />
      )}
      {quiz === "symptomsCovid" && (
        <SymptomsCovid
          saved={state.symptomsCovid}
          handleClose={() => setQuiz("")}
          setSymptomsCovid={(value) => setState({ ...state, symptomsCovid: value })}
        />
      )}
      {quiz === "vaccineCovid" && (
        <VaccineCovid
          saved={state.vaccineCovid}
          handleClose={() => setQuiz("")}
          setVaccineCovid={(value) => setState({ ...state, vaccineCovid: value })}
        />
      )}
    </div>
  );
}
