// import { delay } from "redux-saga";
import { all, takeLatest, put, call } from "redux-saga/effects";
import api from "services/api";

const getPatients = async address => {
  try {
    const response = await api.post(`/ms_evolution/group/getPatientsAddress/`, {
      address
    });
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetPatients(action) {
  try {
    const response = yield call(getPatients, action.address);
    yield put({
      type: "SET_PATIENTS",
      payload: {
        record: response
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

export default function* rootSaga() {
  yield all([takeLatest("GET_PATIENTS", asyncGetPatients)]);
}
