import React from "react";
import DataTable from "components/Tables/DataTable";
import { Tooltip, IconButton } from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan } from "components/Button";
import { useSelector } from "react-redux";
import ProfileNew from "components/ComponentsProfile/ProfileNew";
import Api from "services/api";

export default function ListMedicines({ record }) {
  const [listP, setListP] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [med, setMed] = React.useState("");
  const data_dash = useSelector((state) => state.dashboard.data_dash);
  const address = useSelector((state) => state.auth.address);

  const getPatientMed = (id) => {
    Api.post(`/ms_system/dashboard/getPatientMed`, {
      address,
      medicine_id: id,
      start: data_dash.start,
      end: data_dash.end,
    }).then((res) => {
      setListP(res.data.record);
      setOpen(true);
    });
  };

  return (
    <>
      <DataTable
        name="Medicamentos Prescritos"
        columns={["Medicamento", "Quantidade", "Ação"]}
        rows={record.map((row, index) => [
          row.name_display,
          row.total,
          <Tooltip title="Visualizar Pacientes">
            <IconButton
              style={{ padding: "6px" }}
              color="primary"
              onClick={() => {
                getPatientMed(row.id);
                setMed(row.name_display);
              }}
            >
              <i className="zmdi zmdi-eye zmdi-fw" />
            </IconButton>
          </Tooltip>,
        ])}
        hideSearch={true}
      />
      <ModalResponsive
        open={open}
        close={() => setOpen(false)}
        maxWidth="md"
        title="Medicamentos Prescritos Pacientes"
        buttons={
          <div className="col-12">
            <BtnCan
              title="Fechar"
              onClick={() => setOpen(false)}
              float="left"
            />
          </div>
        }
      >
        <>
          <DataTable
            name={`${med}`}
            columns={["Pacientes"]}
            rows={listP.map((row, index) => [
              <ProfileNew
                profile={{
                  id: row.id,
                  name: row.name,
                  photo: row.photo,
                  birthday: row.birthday,
                  mother_name: row.mother_name,
                }}
              />,
            ])}
            hideSearch={true}
          />
        </>
      </ModalResponsive>
    </>
  );
}
