import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Typography from "@material-ui/core/Typography";
import Api from "services/api";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "670px",
    padding: "15px",
  },
  listMobile: {
    padding: "15px",
  },
  fullList: {
    width: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
  fontSubText: {
    fontSize: "19px",
    fontWeight: "600",
    color: "#5a5a5a",
  },
  fontText: {
    fontWeight: "600",
    color: "#282828",
  },
  buttonExit: {
    position: "fixed",
    bottom: 0,
    fontSize: "20px",
  },
}));

export default function SwipeableTemporaryDrawer({ open, onCLose, mode }) {
  const classes = useStyles();
  const [list, setlist] = useState([]);
  const [listSubCat, setlistSubCat] = useState([]);
  const [type, setType] = useState(0);
  const [state, setState] = useState({
    name: "",
  });
  const [item, setItem] = useState({ name: "", html: "" });

  useEffect(() => {
    setType(0);
    getListCategory();
  }, []);

  useEffect(() => {
    if (mode === "login" && list.length > 0) {
      let aux = list.find((row) => row.id === "7");
      handleSetSubCategory(aux);
    }
    if (mode === "register" && list.length > 0) {
      let aux = list.find((row) => row.id === "5");
      handleSetSubCategory(aux);
    }
  }, [mode, list]);

  const getListCategory = () => {
    Api.get(`/ms_admin/report/getListCategoryTutorial/`).then((res) => {
      setlist(res.data.record);
    });
  };

  const handleSetSubCategory = (params) => {
    Api.post(`/ms_admin/report/getSubCategoryTutorial/`, {
      id: params.id,
    }).then((res) => {
      setState({
        ...state,
        name: params.name,
        description: params.description,
      });
      setlistSubCat(res.data.record);
      if (res.data.record.length === 1) {
        handleSetItem(res.data.record[0]);
      } else {
        setType(1);
      }
    });
  };

  const handleSetItem = (params) => {
    setItem({
      name: params.name,
      html: params.description,
    });
    setType(2);
  };

  const component = () => (
    <div
      className="p-3"
      className={
        window.innerWidth > 900 ? clsx(classes.list) : classes.listMobile
      }
      role="presentation"
    >
      <Typography variant="h6" gutterBottom className={classes.fontText}>
        <IconButton onClick={() => handleClose()}>
          <ArrowBackIcon />
        </IconButton>
        Tutoriais e Ajuda
      </Typography>

      <Typography
        variant="body2"
        gutterBottom
        style={{ color: "#676767", paddingBottom: "9px" }}
      >
        Aqui você aprende tudo sobre o portal SalutemPlus.
        <br />
        <br />
        Precisa de ajuda? Selecione a categoria desejada para visualizar o
        tutorial.
      </Typography>

      <List component="nav" aria-label="main mailbox folders">
        {list.map((row) => (
          <ListItem
            button
            style={{
              paddingLeft: "23px",
              paddingBottom: "9px",
              borderBottom: "1px solid #e3e3e3",
            }}
            onClick={() => {
              setlistSubCat([]);
              handleSetSubCategory(row);
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              style={{
                color: "#3da8cd",
              }}
            >
              {row.name}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const componentSubCategoty = () => (
    <div
      className="p-3"
      className={
        window.innerWidth > 900 ? clsx(classes.list) : classes.listMobile
      }
      role="presentation"
    >
      <Typography variant="h6" gutterBottom className={classes.fontText}>
        <IconButton onClick={() => handleClose()}>
          <ArrowBackIcon />
        </IconButton>
        Tutoriais e Ajuda
      </Typography>

      <div className="col-12 d-flex align-items-center p-0 m-0">
        <IconButton className="pl-0" onClick={() => setType(0)}>
          <ArrowBackIcon fontSize="small" />
        </IconButton>
        <span className={classes.fontSubText}>{state.name}</span>
      </div>
      <Typography
        variant="body2"
        gutterBottom
        style={{ color: "#676767", paddingBottom: "9px" }}
      >
        {state.description}
      </Typography>

      <List component="nav" aria-label="main mailbox folders">
        {listSubCat.map((row) => (
          <ListItem
            button
            style={{
              paddingLeft: "23px",
              paddingBottom: "9px",
              borderBottom: "1px solid #e3e3e3",
            }}
            onClick={() => {
              handleSetItem(row);
            }}
          >
            <Typography
              variant="body2"
              gutterBottom
              style={{
                color: "#3da8cd",
              }}
            >
              {row.name}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const componentItem = () => (
    <div
      className="p-3"
      className={
        window.innerWidth > 900 ? clsx(classes.list) : classes.listMobile
      }
      role="presentation"
    >
      <Typography variant="h6" gutterBottom className={classes.fontText}>
        Tutoriais e Ajuda
      </Typography>
      <div className="col-12 d-flex align-items-center p-0 m-0">
        <IconButton className="pl-0" onClick={() => setType(1)}>
          <ArrowBackIcon fontSize="small" />
        </IconButton>
        <span className={classes.fontSubText}>{item.name}</span>
      </div>
      <div className="border">
        <div
          className="m-3"
          dangerouslySetInnerHTML={{
            __html: item.html,
          }}
        />
      </div>
    </div>
  );

  const handleClose = () => {
    onCLose();
    setType(0);
  };

  return (
    <div>
      <React.Fragment key={"right"}>
        <SwipeableDrawer anchor={"right"} open={open} onClose={handleClose}>
          {type === 0 && <>{component("right")}</>}
          {type === 1 && <>{componentSubCategoty("right")}</>}
          {type === 2 && <>{componentItem("right")}</>}
          {/* <IconButton
            onClick={() => handleClose()}
            className={classes.buttonExit}
          >
            <ArrowBackIcon />
            <span>Voltar</span>
          </IconButton> */}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
