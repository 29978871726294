import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@material-ui/core";
import Api from "services/api";
import apiFile from "services/apiFile";

import Header from "components/Header/index";
import PersonalData from "./PersonalData";
import Terms from "./ComponentTerms";
import InitialData from "./InitialData";
import ModalToken from "./ModalToken";

function getSteps() {
  return ["Informações Iniciais", "Dados Pessoais", "Termos"];
}

function getStepsDoctor() {
  return ["Informações Iniciais", "Dados Pessoais", "Termos"];
}

export default function RegisterPatient({
  match,
  modal,
  history,
  beforeRegister,
}) {
  const dispatch = useDispatch();
  const initState = {
    activeStep: 0,
    skipped: new Set(),
  };
  const initValidate = {
    cpf: false,
    email: false,
    password: false,
  };
  const initData = {
    personalData: {
      date: "",
      id: "",
      idData: "",
      idNaturalty: "",
      name: "",
      username: "",
      cpf: "",
      sex: "",
      naturalty: "",
      email: "",
      cellphone: "",
      password: "",
      confirmPassword: "",
      error: false,
      type: "",
    },
    photo: { image: "", blob: "" },
    healthPlan: [
      {
        id: "",
        plan_id: "",
        plan_name: "",
        register: "",
        validate: "",
        identfy: "",
        alter: false,
      },
    ],
    professionalData: {
      area: "",
      specialty: "",
      council: "",
      numberCouncil: "",
      uf: "",
      year: "",
      error: false,
    },
  };

  const [state, setState] = useState(initState);
  const [data, setData] = useState(initData);
  const [cadOk, setCadOk] = useState(false);
  const [validate, setValidate] = useState(initValidate);
  const [modalToken, setModalToken] = useState(false);
  const [idUser, setIdUser] = useState({ id: "", token: "" });
  const [errorTk, setErrorTk] = useState(false);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <InitialData
            handleNext={handleNext}
            getState={handleChangeInitial}
            stateInit={data.personalData}
            validateData={validate}
            history={history}
            modal={modal}
          />
        );
      case 1:
        return (
          <>
            <PersonalData
              handleNext={handleNext}
              type="notification"
              getState={getState}
              stateInit={data.personalData}
              validateData={validate}
              history={history}
              validationUser={1}
            />
          </>
        );
      case 2:
        return (
          <>
            <Terms
              handleBack={handleBack}
              handleNext={registerForm}
              getState={getState}
            />
            <ModalToken
              id={idUser}
              open={modalToken}
              error={errorTk}
              validationToken={handleValidationToken}
              handleClose={() => setModalToken(false)}
            />
          </>
        );
      default:
        return "Unknown step";
    }
  };

  const handleValidationToken = (id, token) => {
    Api.post(`/ms_system/user/validationToken`, { id, token }).then(
      async (res) => {
        if (res.data.success) {
          if (res.data.token_id) {
            setErrorTk(false);
            setModalToken(false);

            beforeRegister(true, id);
          } else {
            setErrorTk(true);
          }
        }
      }
    );
  };

  const handleChangeInitial = async (stateForm, form) => {
    setData({
      ...data,
      personalData: {
        ...stateForm,
      },
      professionalData: {
        area: stateForm.area_id,
        uhs_id: stateForm.uhs_id,
        specialty: stateForm.specialty_id,
        council: stateForm.council_id,
        numberCouncil: stateForm.council_number,
        uf: stateForm.council_state_id,
        year: stateForm.council_year,
        error: false,
      },
    });
  };

  const uploadPhotoUser = async (photo, user) => {
    let formData = new FormData();
    formData.append("user", user);
    let block = photo.image.split(";");
    let contentType = block[0].split(":")[1];
    let realData = block[1].split(",")[1];
    let blob = await b64toBlob(realData, contentType);
    formData.append("image", blob);

    apiFile.post(`/ms_system/user/savePhotoRegister/`, formData);
  };

  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const registerForm = async (license, terms) => {
    const obj = {
      ...data,
      terms: { license, terms },
      type: data.personalData.type ? data.personalData.type : "3",
    };

    Api.post(`/ms_system/user/saveRegister`, obj).then(async (res) => {
      if (res.data.success) {
        setIdUser(res.data.id);
        if (res.data.photo.image !== "") {
          uploadPhotoUser(res.data.photo, res.data.id);
        }
        setCadOk(true);

        setTimeout(() => {
          setCadOk(false);
          setModalToken(true);
        }, 3000);
      }
    });
  };

  const getState = async (stateForm, form) => {
    if (form === "validate") {
      await setValidate(stateForm);
    } else {
      await setData({ ...data, [form]: { ...stateForm } });
    }
  };

  const handleNext = () => {
    const { activeStep } = state;
    setState({
      ...state,
      activeStep: activeStep + 1,
    });
  };
  const handleBack = () => {
    const { activeStep } = state;
    setState({
      ...state,
      activeStep: activeStep - 1,
    });
  };
  const handleReset = () => {
    setState({
      ...state,
      activeStep: 0,
    });
  };

  const getContainer = () => (
    <div className="container">
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        style={{ display: "none" }}
        className="horizontal-stepper-linear"
      >
        {steps.map((label, index) => {
          return (
            <Step
              key={label}
              className={`horizontal-stepper ${
                index === activeStep ? "active" : ""
              }`}
            >
              <StepLabel className="stepperlabel">{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>
          <Typography className="my-2">{getStepContent(activeStep)}</Typography>
        </div>
        <SweetAlert
          show={cadOk}
          showConfirm={false}
          success
          title="Cadastrado completado com sucesso."
        />
      </div>
    </div>
  );

  const steps = data.personalData.type === "0" ? getStepsDoctor() : getSteps();
  const { activeStep } = state;
  if (!modal) {
    return (
      <div className={`app-container`}>
        <div className="app-main-container">
          <div className={`app-header`}>
            <Header left={true} />
          </div>
          <main className="app-main-content-wrapper">
            <div className="container mt-2">
              <ContainerHeader title="Completar Cadastro" match={match} />
              <Card className="shadow border-0">
                <CardBody>{getContainer()};</CardBody>
              </Card>
            </div>
          </main>
        </div>
      </div>
    );
  } else {
    return <>{getContainer()}</>;
  }
}
