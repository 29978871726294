import React from "react";
import { ModalResponsive } from "components/ModalResponsive";

function ModalQrCode({ open, handleClose, link }) {
  // const handleCancel = () => {};
  // const handleSave = () => {};
  return (
    <ModalResponsive title={"Escanear QrCode"} open={open} close={handleClose}>
      <div className="container">
        <img style={{ width: "100%" }} src={link} alt="QrCode da prescrição" />
      </div>
    </ModalResponsive>
  );
}

export default ModalQrCode;
