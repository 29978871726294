import React from "react";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  RadioGroup,
  Radio,
} from "@material-ui/core";

// import { Container } from './styles';

export default function FormComorbidity({
  selecteds,
  handleChange,
  comorbidityOther,
  setComorbidityOther,
}) {
  let questions = [
    "Diabetes mellitus ",
    "Asma / Enfisema",
    "Hipertensão arterial",
    "Insuficiência cardíaca",
    "Infarto (Síndrome coronariana)",
    "Lesão de válvula do coração",
    "Arritmia",
    "Outros problemas cardíacos",
    "Doença renal",
    "Baixa imunidade",
    "Anemia falciforme ou talassemia",
    "Obesidade",
    "Síndrome de Down",
    "Cirrose hepática",
    "Doença neurológica/psiquiátrica",
    "Outros",
  ];

  const [isset, setIsset] = React.useState(false);

  React.useEffect(() => {
    if (selecteds.length > 0 && isset !== "1") {
      setIsset("1");
    }
  }, [selecteds]);

  return (
    <>
      {/* <div className="col-12 mt-3">
        <h4 className="font-weight-semibold">Possui alguma comorbidade?</h4>
      </div> */}
      <div className="col-12 mt-3">
        <h3 className="font-weight-semibold">
          <u>Possui alguma comorbidade?</u>
        </h3>
        <RadioGroup
          className="d-flex flex-row ml-3"
          value={isset}
          onChange={(e) => setIsset(e.target.value)}
        >
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="Sim"
          />
          <FormControlLabel
            value="0"
            control={<Radio color="primary" />}
            label="Não"
          />
        </RadioGroup>
      </div>
      {isset === "1" &&
        questions.map((row, index) => (
          <div className="col-md-4 col-12">
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selecteds.indexOf(row) !== -1}
                  onChange={() => handleChange(row)}
                  color="primary"
                />
              }
              label={row}
            />
          </div>
        ))}
      {selecteds.indexOf("Outros") !== -1 && (
        <div className="col-md-8 col-12">
          <TextField
            label="Qual?"
            value={comorbidityOther}
            onChange={(e) => setComorbidityOther(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </div>
      )}
    </>
  );
}
