const INITIAL_STATE = {
  route: "",
};
export default function forms(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_ROUTE":
      return {
        ...state,
        route: action.payload,
      };
    case "GET_ROUTE":
      return state.route;
    default:
      return state;
  }
}
