const INITIAL_STATE = {
  idForward: "",
  listForward: [],
  listForwardAppointment: []
  // forwardAtual: {
  //   id: "",
  //   type: "",
  //   provider_name: "",
  //   provider_id: "",
  //   professional_name: "",
  //   professional_id: "",
  //   area: "",
  //   speciality: "",
  //   email: "",
  //   telefone: "",
  //   conselho: "",
  //   num_conselho: "",
  //   procedimento: "",
  //   observacoes: ""
  // }
};

export default function forward(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_FORWARD_SUCCESS":
      return {
        ...state,
        success: action.payload.success,
        idForward: action.payload.idForward,
        provider: "",
        professional: "",
        mailProvider: "",
        phoneProvider: "",
        optionsArea: [],
        optionsSpecialty: [],
        specialty: "",
        area: "",
        disabled: true,
        prov_id: "",
        prof_id: "",
        listForwardAppointment: [
          ...state.listForwardAppointment,
          action.payload
        ]
      };
    case "GET_LIST_FORWARD_COMPLETE":
      return {
        ...state,
        listForward: action.payload
      };
    case "GET_FORWARD_APPOINTMENT_COMPLETE":
      return {
        ...state,
        listForwardAppointment: action.payload
      };
    case "REMOVE_FORWARD_SUCCESS":
      let forwards = state.listForwardAppointment.filter(row => {
        return row.idForward !== action.payload.idForward;
      });
      return {
        ...state,
        listForwardAppointment: forwards
      };
    case "RESET_FORWARD":
      return INITIAL_STATE;
    default:
      return state;
  }
}
