import React, { useState, useEffect } from "react";
import { BtnCan, BtnSave } from "components/Button";
import {
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import Api from "services/api";
import { useDispatch, useSelector } from "react-redux";
import { setAppointment, setSavedActions } from "actions/consult/Appointment";
import { showMsg } from "services/functions";

export default function FormPause({
  open,
  data,
  handleClose,
  patient,
  type,
  record,
  list,
  handleAdd,
}) {
  const initial = {
    id: "",
    activitie: "",
    repetition: "",
    days: [],
    start: "",
    end: "",
    interval: "",
    observation: "",
    alert: true,
  };
  const [state, setState] = useState(initial);

  const appointment = useSelector((state) => state.appointment.id);
  const prof_resp = useSelector((state) => state.appointment.saved.prof_resp);
  const appointment_action = useSelector((state) => state.appointment.saved.id);
  const appointment_action_form = useSelector(
    (state) => state.appointment.saved.schedule_pause
  );
  const start = useSelector((state) => state.appointment.start);
  const user = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.id) {
      setState({
        ...data,
        days: data.days.split(","),
        alert: data.alert === "0" ? false : true,
      });
    }
  }, [data]);

  useEffect(() => {
    switch (state.repetition) {
      case "Todos os dias":
        setState({ ...state, days: ["0", "1", "2", "3", "4", "5", "6"] });
        break;
      case "Segunda a sábado":
        setState({ ...state, days: ["1", "2", "3", "4", "5", "6"] });
        break;
      case "Segunda a sexta":
        setState({ ...state, days: ["1", "2", "3", "4", "5"] });
        break;
    }
  }, [state.repetition]);

  const handleChange = (field) => (e) => {
    setState({ ...state, [field]: e.target.value });
  };

  const handleChangeDays = (value, day) => {
    if (value) {
      setState({ ...state, days: [...state.days, day] });
    } else {
      setState({
        ...state,
        days: state.days.filter((row) => row !== day),
      });
    }
  };

  const onClose = () => {
    setState(initial);
    handleClose();
  };

  const handleSave = () => {
    Api.post("microservice/patientportal/saveSchedulePause", {
      patient,
      data: state,
      type,
      user,
      address,
      appointment,
      appointment_action,
      appointment_action_form,
      prof_resp,
      start,
    })
      .then((res) => {
        showMsg("success", "Salvo com sucesso!", dispatch);
        onClose();
        handleAdd({
          ...state,
          id: res.data.id,
          days: state.days.join(","),
          name: list.find((row) => row.id === state.activitie).name,
        });

        if (type === "consult") {
          if (!appointment) {
            dispatch(setAppointment(res.data.appointment));
          }
          if (!appointment_action) {
            dispatch(setSavedActions("schedule_pause", res.data.saved));
          }
        }
      })
      .catch(() => {
        showMsg("error", "Ocorreu um erro ao salvar!", dispatch);
      });
  };

  return (
    <ModalResponsive
      title="Programar pausa"
      open={open}
      close={onClose}
      maxWidth="md"
      buttons={
        <div className="col-12">
          <BtnCan title="Fechar" onClick={onClose} float="left" />

          <BtnSave title="Salvar" onClick={handleSave} float="right" />
        </div>
      }
    >
      <div className="row">
        <div className="col-md-12">
          <TextField
            select
            label="Pausa para:"
            value={state.activitie}
            onChange={handleChange("activitie")}
            InputLabelProps={{ shrink: true }}
            fullWidth
          >
            {list
              .filter(
                (row) =>
                  record.indexOf(row.id) === -1 || row.id === data.activitie
              )
              .map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
        {state.activitie && (
          <>
            <div className="col-md-3 col-xs-12 mt-3">
              <TextField
                select
                label="Dias da semana:"
                value={state.repetition}
                onChange={handleChange("repetition")}
                InputLabelProps={{ shrink: true }}
                fullWidth
              >
                <MenuItem key="1" value="Todos os dias">
                  Todos os dias
                </MenuItem>
                <MenuItem key="2" value="Segunda a sexta">
                  Segunda a sexta
                </MenuItem>
                <MenuItem key="3" value="Segunda a sábado">
                  Segunda a sábado
                </MenuItem>
                <MenuItem key="4" value="Dias específicos">
                  Dias específicos
                </MenuItem>
              </TextField>
            </div>
            <div className="col-md-3 col-xs-12 mt-3">
              <TextField
                type="time"
                label="Início:"
                value={state.start}
                onChange={handleChange("start")}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </div>
            <div className="col-md-3 col-xs-12 mt-3">
              <TextField
                type="time"
                label="Fim:"
                value={state.end}
                onChange={handleChange("end")}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </div>
            <div className="col-md-3 col-xs-12 mt-3">
              <TextField
                type="time"
                label="Intervalo:"
                value={state.interval}
                onChange={handleChange("interval")}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </div>
            {state.repetition === "Dias específicos" && (
              <div className="col-md-12">
                {/* <FormLabel>Dias:</FormLabel> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.days.indexOf("0") !== -1}
                      onChange={(e) => handleChangeDays(e.target.checked, "0")}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Domingo"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.days.indexOf("1") !== -1}
                      onChange={(e) => handleChangeDays(e.target.checked, "1")}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Segunda"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.days.indexOf("2") !== -1}
                      onChange={(e) => handleChangeDays(e.target.checked, "2")}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Terça"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.days.indexOf("3") !== -1}
                      onChange={(e) => handleChangeDays(e.target.checked, "3")}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Quarta"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.days.indexOf("4") !== -1}
                      onChange={(e) => handleChangeDays(e.target.checked, "4")}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Quinta"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.days.indexOf("5") !== -1}
                      onChange={(e) => handleChangeDays(e.target.checked, "5")}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Sexta"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.days.indexOf("6") !== -1}
                      onChange={(e) => handleChangeDays(e.target.checked, "6")}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Sábado"
                />
              </div>
            )}
            <div className="col-md-3 col-xs-12 mt-3">
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={state.alert}
                    onChange={() => setState({ ...state, alert: !state.alert })}
                  />
                }
                label="Receber alertas:"
                labelPlacement="start"
                style={{
                  marginTop: "13px",
                  marginLeft: "0px",
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <TextField
                label="Observações:"
                value={state.observation}
                onChange={handleChange("observation")}
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={4}
              />
            </div>
          </>
        )}
      </div>
    </ModalResponsive>
  );
}
