import React, { useState, useEffect } from "react";
import Api from "services/api";
import { useSelector } from "react-redux";

import moment from "moment";

import { Card, CardBody } from "reactstrap";
import ComponentsProfile from "components/ComponentsProfile/ProfileNew";
import DataTable from "components/Tables/DataTable";
import ChildsGraphic from "./ChildsGraphic";
import SearchComponent from "components/SearchComponent";
import CircularProgress from "components/CircularProgress";
import { getStringToSearch } from "services/functions";

export default function Childs() {
  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const data = useSelector((state) => state.dashboard.data_dash);

  useEffect(() => {
    Api.post(`/ms_system/dashboard/getChildsRegister`, {
      start: data.start,
      end: data.end,
    }).then((res) => {
      setRecord(res.data.record);
      setTotal(res.data.total);
      setLoader(false);
    });
  }, [data]);

  if (loader) {
    return <CircularProgress />;
  }

  return (
    <>
      <Card className="shadow border-0 mb-3">
        <CardBody>
          <div className="row">
            <div className="col-12">
              <h2 className="font-weight-semibold d-flex">
                <span className="">Crianças Cadastradas</span>
              </h2>
            </div>
            <div className="col-12" style={{ height: "250px" }}>
              <ChildsGraphic record={total} />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card className="shadow border-0 mb-3">
        <CardBody>
          <div className="row">
            <div className="col-12">
              <h2 className="font-weight-semibold d-flex">
                <span className="mt-3 mr-5">Listagem</span>
                {record.length > 0 && (
                  <div className="w-100 pl-3">
                    <SearchComponent searchContact={(e) => setSearch(e)} />
                  </div>
                )}
              </h2>
            </div>
            <div className="col-12">
              <DataTable
                name=""
                columns={[
                  "Criança",
                  "Vacinado",
                  "Vacinado em",
                  "Registrado em",
                ]}
                rows={record
                  .filter(
                    (row) =>
                      search.length === 0 ||
                      getStringToSearch(row.name).includes(
                        getStringToSearch(search)
                      ) ||
                      getStringToSearch(row.cpf ? row.cpf : "").includes(
                        getStringToSearch(search)
                      ) ||
                      getStringToSearch(row.cns ? row.cns : "").includes(
                        getStringToSearch(search)
                      ) ||
                      getStringToSearch(
                        row.birthday ? row.birthday : ""
                      ).includes(getStringToSearch(search))
                  )
                  .map((row, index) => [
                    <ComponentsProfile
                      profile={{
                        ...row,
                        birthday: moment(row.birthday).format("DD/MM/YYYY"),
                      }}
                    />,
                    // row.vaccine ? (
                    //   <Chip label="Sim" variant="outlined" color="primary" />
                    // ) : (
                    //   <Chip label="Não" variant="outlined" color="secondary" />
                    // ),
                    row.vaccine ? "Sim" : "Não",
                    row.date_of_vaccine
                      ? moment(row.date_of_vaccine).format("DD/MM/YYYY")
                      : "",
                    moment(row.created_at).format("DD/MM/YYYY HH:mm"),
                  ])}
                download={true}
                print={true}
                hideSearch={true}
                rowsPerPage={25}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
