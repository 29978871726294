import {
  all,
  takeEvery,
  takeLatest,
  put,
  call,
  fork
} from "redux-saga/effects";
import api from "services/api";

const getInfoProceduresGroups = async id => {
  const response = await api.post(
    `/microservice/attendanceRecord/getInfoProceduresGroups/`,
    { id }
  );
  //Retorna Infos do Grupo(Nome, Desc)
  const data = await response.data.record;
  return data;
};

const requestGetAttendanceFromPP = async (id, doctor) => {
  const res = await api.post(`/microservice/consult/getHistoryAppointment/`, {
    appointment_id: id,
    doctor
  });
  return res.data;
};

function* asyncInfoSelectedProcedureGroup(action) {
  try {
    const infoProcedureGroupSelect = yield call(
      getInfoProceduresGroups,
      action.payload.id
    );
    yield put({
      type: "INFO_PROCEDURES_GROUP_SUCCESS",
      payload: {
        infoProcedureGroupSelect: infoProcedureGroupSelect
      }
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncGetAttendanceFromPP(action) {
  try {
    const res = yield call(
      requestGetAttendanceFromPP,
      action.appointment,
      action.doctor
    );
    yield put({
      type: "SET_ATTENDANCE_FROM_PP",
      payload: res
    });
  } catch (error) {
    console.log("SAGA => asyncGetAttendanceFromPP", error);
  }
}

export function* requestInfoProcedureSelectedGroup() {
  yield takeEvery("GET_INFO_PROCEDURE_GROUP", asyncInfoSelectedProcedureGroup);
}

export function* listenGetAttendanceFromPP() {
  yield takeLatest("GET_ATTENDANCE_FROM_PP", asyncGetAttendanceFromPP);
}

export default function* rootSaga() {
  yield all([
    fork(requestInfoProcedureSelectedGroup),
    fork(listenGetAttendanceFromPP)
  ]);
}
