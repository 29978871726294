import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import {
  InputAdornment,
  TextField,
  MenuItem,
  Button,
  Tooltip,
  IconButton,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Checkbox,
  Tabs,
  Tab,
  FormControl,
} from "@material-ui/core";
import { Alert } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";
import SearchPatient from "./SearchPatient";
import FormInfoPatient from "./FormInfoPatient";
import moment from "moment";
import TakePhotoWithCropper from "components/Forms/TakePhotoWithCropper";
import CircularProgress from "components/CircularProgress";
import { getUrlPhoto } from "services/functions";
import RegisterUser from "components/Forms/RegisterUser";
import { SingleSelect } from "react-select-material-ui";
import FormEvent from "./FormEvent";
import { Grid, FormHelperText, Input } from "@material-ui/core";

function FormScheduling({ date, close, id, warningRepetition }) {
  const [patient, setPatient] = useState({
    id: "",
    name: "",
    email: "",
    smartphone: "",
  });

  const [selectedPatient, setSelectedPatient] = useState(false);
  const [observation, setObservation] = useState("");
  const [error, setError] = useState(false);
  const [dateEvent, setDateEvent] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [repeat, setRepeat] = useState("0");
  const [repeatDate, setRepeatDate] = useState();
  const [typeRepeat, setTypeRepeat] = useState();
  const [weekdays, setWeekdays] = useState([]);
  const [numberOcurrency, setNumberOcurrency] = useState(0);
  const user = useSelector((state) => state.auth.authUser);
  const type_user = useSelector((state) => state.auth.type);
  const calendar = useSelector(
    (state) => state.calendar.calendar_to_scheduling
  );

  const calendars = useSelector((state) => state.calendar.calendars);
  const calendar_selected = useSelector((state) => state.calendar.calendar_id);
  const doctor_id = useSelector((state) => state.calendar.calendar_doctor);

  const config = useSelector((state) => state.calendar.config);
  const address_selected = useSelector((state) => state.auth.address_selected);
  const [typesEvent, setTypesEvent] = useState([]);
  const [typeSelected, setTypeSelected] = useState({ value: "" });
  const [healthPlans, setHealthPlans] = useState([]);
  const [planSelected, setPlanSelected] = useState("particular");
  const [health_plan_card, setHealthPlanCard] = useState("");
  const [take_photo, setTakePhoto] = useState(false);
  const [imgPatient, setImgPatient] = useState("");
  const [validatePatient, setValidatePatient] = useState({
    foulLast: false,
    hasFouls: false,
  });
  const [modalRegister, setModalRegister] = useState(false);
  const [modalAlertDate, setModalAlertDate] = useState(false);
  const [modalHourBloqued, setModalHourBloqued] = useState(false);

  const [eventAfter, setEventAfter] = useState({});
  const [modal_after, setModalAfter] = useState(false);
  const [modalInfoPatient, setModalInfoPatient] = useState(false);
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const formPayments = [
    "Boleto Bancário",
    "Cartão de crédito",
    "Cartão de débito",
    "Moeda Corrente",
    "Transferência Bancária",
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (calendar) {
      setDateEvent(moment(date).format("YYYY-MM-DD"));
      setRepeatDate(
        moment(date)
          .add(1, "days")
          .format("YYYY-MM-DD")
      );
      setStart(moment(date).format("HH:mm"));
      setEnd(
        moment(date)
          .add(30, "minutes")
          .format("HH:mm")
      );
      Api.post(`/ms_system/calendar/getTypesEventsCalendar/`, {
        calendar,
      }).then((res) => {
        if (res.data.record.length > 0) {
          let aux = res.data.record.map((row) => ({
            value: row.id,
            label: row.description,
            duration: row.duration,
            transaction: row.transaction,
            valueEvent: row.value,
          }));
          setTypesEvent(aux);
        }
      });

      Api.post(`/ms_system/calendar/getHealthPlansCalendar/`, {
        calendar,
      }).then((res) => {
        if (res.data.record.length > 0) {
          let aux = res.data.record.map((row) => ({
            value: row.plan_id,
            label: row.name,
          }));
          setHealthPlans(aux);
        }
      });
    }
  }, [calendar]);

  useEffect(() => {
    if (patient.id && patient.id !== "new") {
      Api.post(`/ms_system/calendar/getEventsPatientInCalendar/`, {
        patient: patient.id,
        calendar,
      }).then((res) => {
        let obj = {
          foulLast: false,
          hasFouls: false,
          numberFouls: "",
          numberEvents: "",
        };
        let { record } = res.data;

        if (record.length > 0) {
          // Verifica faltas do paciente
          if (record[0].status === "6") {
            obj.foulLast = true;
          }

          let hasFoul = record.filter((row) => row.status === "6");

          if (hasFoul.length > 0) {
            obj.hasFouls = true;
            obj.numberFouls = hasFoul.length;
            obj.numberEvents = record.length;
          }

          // Verifica se paciente possui algum outro evento já marcado para uma data superior
          let dt = `${dateEvent} ${start}`;
          let hasEvents = record.filter(
            (row) => !moment(dt).isAfter(moment(row.start))
          );

          if (hasEvents.length > 0) {
            setEventAfter(hasEvents[0]);
            setModalAfter(true);
          }
        }
        setValidatePatient(obj);
      });
    }
  }, [patient.id]);

  useEffect(() => {
    if (typeSelected.value) {
      let dt = `2000-01-01 ${start}`;
      let info = typeSelected.duration.split(":");
      let hrs = info[0];
      let min = info[1];
      let newEnd = moment(dt)
        .add(hrs, "hours")
        .add(min, "minutes")
        .format("HH:mm");
      setEnd(newEnd);
    }
  }, [typeSelected]);

  useEffect(() => {
    if (patient.id && planSelected && planSelected !== "particular") {
      Api.post(`/ms_system/calendar/getRegisterPlanUser/`, {
        patient: patient.id,
        health_plan: planSelected,
      }).then((res) => setHealthPlanCard(res.data.register));
    } else {
      setHealthPlanCard("");
    }
  }, [planSelected, patient.id]);

  useEffect(() => {
    if (moment().isAfter(moment(`${dateEvent} 23:59:59`))) {
      setModalAlertDate(true);
    }
  }, [dateEvent]);

  useEffect(() => {
    Api.post(`/ms_system/calendar/verifyHourBloqued/`, {
      calendar,
      start: `${dateEvent} ${start}`,
      end: `${dateEvent} ${end}`,
    }).then((res) => {
      if (res.data.record.length > 0) {
        setModalHourBloqued(true);
      }
    });
  }, [dateEvent, start, end]);

  function save() {
    if (validate()) {
      setSaving(true);

      Api.post(`/ms_system/calendar/createEvent/`, {
        id,
        user,
        type_user,
        calendar,
        doctor_id,
        patient,
        observation,
        type: typeSelected,
        start: `${dateEvent} ${start}`,
        end: `${dateEvent} ${end}`,
        health_plan: planSelected,
        health_plan_card,
        config,
        repeat,
        repeatDate,
        typeRepeat,
        numberOcurrency,
        weekdays,
      })
        .then((res) => {
          warningRepetition(res.data.warning);
          dispatch(viewAlertMessage("success", "Agendado com sucesso!"));
          setTimeout(() => {
            dispatch(closeAlertMessage());
          }, 5000);
          setSaving(false);
          close();
        })
        .catch(() => {
          setSaving(false);
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao agendar!"));
          setTimeout(() => {
            dispatch(closeAlertMessage());
          }, 5000);
        });
    }
  }

  function validate() {
    if (
      patient.id &&
      ((start && end && typeSelected.value && dateEvent) || id)
    ) {
      if (
        moment(`${dateEvent} ${start}`).isAfter(moment(`${dateEvent} ${end}`))
      ) {
        setEnd("");
        setError(true);
        dispatch(viewAlertMessage("error", "Horário final incorreto"));
        setTimeout(() => {
          dispatch(closeAlertMessage());
        }, 5000);
        return false;
      } else {
        return true;
      }
    } else {
      setError(true);
      return false;
    }
  }

  function cancelEventsAfter() {
    setModalAfter(false);
    let url =
      eventAfter.multiple === "1"
        ? `/ms_system/calendar/cancelPatientInEventMultiple/`
        : `/ms_system/calendar/cancelEvent/`;
    Api.post(url, {
      event: eventAfter.id,
      user,
    }).then(() => {
      // getEvents();
    });
  }

  function handleChangeWeekday(value) {
    if (weekdays.indexOf(value) === -1) {
      setWeekdays([...weekdays, value]);
    } else {
      setWeekdays(weekdays.filter((row) => row !== value));
    }
  }

  if (saving) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="text-primary font-weight-semibold">
            Agendamento
            <Tooltip title="Fechar" placement="top">
              <IconButton
                aria-label="Fechar"
                onClick={() => close()}
                style={{ float: "right", marginTop: "-10px" }}
              >
                <i className="zmdi zmdi-close" />
              </IconButton>
            </Tooltip>
          </h2>
        </div>

        {calendar_selected === "integrated_week" && (
          <div className="col-12 mb-3">
            <TextField
              id="calendar"
              select
              label="Agenda:"
              value={calendar}
              onChange={(e) => {
                let info_cal = calendars.find(
                  (row) => row.id === e.target.value
                );
                dispatch({
                  type: "CHANGE_CALENDAR_INTEGRATED_WEEK",
                  calendar: e.target.value,
                  doctor: info_cal.doctor_id,
                });

                if (!info_cal.doctor_id) {
                  setActiveTab(1);
                } else if (activeTab === 1) {
                  setActiveTab(0);
                }
              }}
              fullWidth
            >
              {calendars.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  <>
                    <span
                      className="badge"
                      style={{
                        background: row.color,
                        display: "inline",
                        width: "30px",
                        height: "15px",
                        marginTop: "10px",
                      }}
                    />
                    <span>{row.name}</span>
                  </>
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}

        {(calendar || id) && (
          <>
            {!id && (
              <div className="col-12">
                <Tabs
                  value={activeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(e, newValue) => setActiveTab(newValue)}
                  aria-label="disabled tabs example"
                  centered
                >
                  <Tab label="Atendimento" />
                  <Tab label="Compromisso" />
                </Tabs>
              </div>
            )}

            {activeTab === 1 && (
              <div className="col-12">
                <FormEvent id={id} date={date} close={close} modal={true} />
              </div>
            )}
            {activeTab === 0 && (
              <>
                {!selectedPatient || patient.id === "new" ? (
                  <>
                    {patient.id === "new" && (
                      <div className="col-md-1 col-xs-4">
                        <Tooltip title="Alterar foto">
                          <img
                            src={
                              imgPatient
                                ? imgPatient
                                : getUrlPhoto(patient.id, "")
                            }
                            alt={patient.name}
                            className="rounded-circle img-responsive"
                            style={{
                              width: "60px",
                              height: "60px",
                              cursor: "pointer",
                            }}
                            onClick={() => setTakePhoto(true)}
                          />
                        </Tooltip>
                      </div>
                    )}
                    <div
                      className={
                        patient.id === "new" ? "col-md-11 col-xs-8" : "col-12"
                      }
                    >
                      <SearchPatient
                        handleChangeAutocomplete={(obj) => {
                          setSelectedPatient(true);
                          setPatient(obj);
                          if (obj.id === "new") {
                            setModalRegister(true);
                          }
                        }}
                        req={true}
                        error={error && !patient.id}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-6 col-xs-12 d-flex mt-3">
                      <Tooltip title="Alterar foto" placement="bottom">
                        <img
                          src={
                            imgPatient
                              ? imgPatient
                              : getUrlPhoto(patient.id, patient.photo)
                          }
                          alt={patient.name}
                          className="rounded-circle img-responsive"
                          style={{
                            width: "60px",
                            height: "60px",
                            cursor: "pointer",
                          }}
                          onClick={() => setTakePhoto(true)}
                        />
                      </Tooltip>
                      <div className="pl-2 f-date">
                        <strong>
                          {patient.name}{" "}
                          <Tooltip
                            title="Editar informações do paciente"
                            placement="top"
                          >
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setModalInfoPatient(true);
                              }}
                            >
                              <i className="zmdi zmdi-edit" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Alterar paciente" placement="top">
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                setSelectedPatient(false);
                                setPatient({ id: "", name: "" });
                                setImgPatient("");
                              }}
                            >
                              <i className="zmdi zmdi-close" />
                            </IconButton>
                          </Tooltip>
                        </strong>
                        <br />
                        {patient.email}
                        <br />
                        {patient.smartphone}
                      </div>
                    </div>
                    <div className="col-md-6 col-12 mt-3">
                      {(validatePatient.foulLast ||
                        validatePatient.hasFouls) && (
                        <Alert className="alert-addon-card bg-light bg-light text-black shadow-lg">
                          <span className="icon-addon alert-addon">
                            <i className="zmdi zmdi-alert-circle-o zmdi-hc-fw zmdi-hc-lg" />
                          </span>

                          <span className="d-inline-block">
                            {validatePatient.foulLast
                              ? `${patient.name} não compareceu ao seu último agendamento!`
                              : `${patient.name} não compareceu ${validatePatient.numberFouls} vez(es) em um total de
                    ${validatePatient.numberEvents} agendamentos.`}
                          </span>
                        </Alert>
                      )}
                    </div>
                  </>
                )}

                {!id && (
                  <>
                    <div className="col-md-12">
                      <SingleSelect
                        label="Tipo da consulta:"
                        value={typeSelected.value}
                        options={typesEvent}
                        onChange={(e) => {
                          let info = typesEvent.find((row) => row.value === e);
                          setTypeSelected(info);
                        }}
                        SelectProps={{
                          isCreatable: false,
                          msgNoOptionsMatchFilter:
                            "Nenhuma opção para este filtro",
                        }}
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        id="date"
                        type="date"
                        label="Data:"
                        value={dateEvent}
                        onChange={(e) => setDateEvent(e.target.value)}
                        fullWidth
                        required
                        margin="normal"
                        error={error && !dateEvent}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        id="start"
                        type="time"
                        label="Início:"
                        value={start}
                        onChange={(e) => setStart(e.target.value)}
                        fullWidth
                        required
                        margin="normal"
                        error={error && !start}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        id="end"
                        type="time"
                        label="Fim:"
                        value={end}
                        onChange={(e) => setEnd(e.target.value)}
                        fullWidth
                        required
                        margin="normal"
                        error={error && !end}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </>
                )}
                {address_selected.provider_type !== "56" && (
                  <div className="col-12">
                    <TextField
                      id="health_plan"
                      select
                      required
                      label="Faturamento:"
                      value={planSelected}
                      onChange={(e) => {
                        setPlanSelected(e.target.value);
                        setHealthPlanCard(
                          formPayments.indexOf(e.target.value) !== -1
                            ? ""
                            : health_plan_card
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    >
                      {/* <MenuItem key="0" value="particular">
                      Particular
                    </MenuItem> */}

                      {formPayments.map((pay, idx) => (
                        <MenuItem key={idx} value={pay}>
                          {pay}
                        </MenuItem>
                      ))}

                      {healthPlans.map((row) => (
                        <MenuItem key={row.value} value={row.value}>
                          {row.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}

                {address_selected.provider_type !== "56" &&
                  planSelected &&
                  formPayments.indexOf(planSelected) === -1 && (
                    <div className="col-12">
                      <TextField
                        id="health_plan_card"
                        label="Carteirinha:"
                        value={health_plan_card}
                        onChange={(e) => setHealthPlanCard(e.target.value)}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  )}
                <div className="col-md-12">
                  <TextField
                    id="observation"
                    multiline={true}
                    rows={4}
                    label="Observações:"
                    value={observation}
                    onChange={(e) => setObservation(e.target.value)}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                {!id && (
                  <div className="col-md-12">
                    <TextField
                      id="repeat"
                      select
                      label="Repetir:"
                      value={repeat}
                      onChange={(e) => {
                        setRepeat(e.target.value);
                        if (e.target.value === "esp") {
                          setTypeRepeat("1");
                        }
                      }}
                      SelectProps={{}}
                      fullWidth
                      style={{ marginTop: "17px" }}
                      error={error && !repeat ? true : false}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem key="rp1" value="0">
                        Sem repetição
                      </MenuItem>
                      <MenuItem key="rp1" value="1">
                        Diariamente
                      </MenuItem>
                      <MenuItem key="rp2" value="7">
                        Semanalmente
                      </MenuItem>
                      <MenuItem key="rp3" value="15">
                        Quizenalmente
                      </MenuItem>
                      <MenuItem key="rp4" value="30">
                        Mensalmente
                      </MenuItem>
                      <MenuItem key="rp5" value="60">
                        Bimestralmente
                      </MenuItem>
                      <MenuItem key="rp6" value="90">
                        Trimestralmente
                      </MenuItem>
                      <MenuItem key="rp7" value="180">
                        Semestrealmente
                      </MenuItem>
                      <MenuItem key="rp8" value="365">
                        Anualmente
                      </MenuItem>
                      <MenuItem key="esp" value="esp">
                        Dias específicos
                      </MenuItem>
                    </TextField>
                  </div>
                )}
                {repeat === "esp" && (
                  <div className="col-12 d-flex">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={weekdays.indexOf("0") !== -1}
                          onChange={() => handleChangeWeekday("0")}
                        />
                      }
                      label="Domingo"
                      labelPlacement="end"
                      style={{
                        marginTop: "13px",
                        marginLeft: "0px",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={weekdays.indexOf("1") !== -1}
                          onChange={() => handleChangeWeekday("1")}
                        />
                      }
                      label="Segunda"
                      labelPlacement="end"
                      style={{
                        marginTop: "13px",
                        marginLeft: "0px",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={weekdays.indexOf("2") !== -1}
                          onChange={() => handleChangeWeekday("2")}
                        />
                      }
                      label="Terça"
                      labelPlacement="end"
                      style={{
                        marginTop: "13px",
                        marginLeft: "0px",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={weekdays.indexOf("3") !== -1}
                          onChange={() => handleChangeWeekday("3")}
                        />
                      }
                      label="Quarta"
                      labelPlacement="end"
                      style={{
                        marginTop: "13px",
                        marginLeft: "0px",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={weekdays.indexOf("4") !== -1}
                          onChange={() => handleChangeWeekday("4")}
                        />
                      }
                      label="Quinta"
                      labelPlacement="end"
                      style={{
                        marginTop: "13px",
                        marginLeft: "0px",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={weekdays.indexOf("5") !== -1}
                          onChange={() => handleChangeWeekday("5")}
                        />
                      }
                      label="Sexta"
                      labelPlacement="end"
                      style={{
                        marginTop: "13px",
                        marginLeft: "0px",
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={weekdays.indexOf("6") !== -1}
                          onChange={() => handleChangeWeekday("6")}
                        />
                      }
                      label="Sábado"
                      labelPlacement="end"
                      style={{
                        marginTop: "13px",
                        marginLeft: "0px",
                      }}
                    />
                  </div>
                )}
                {repeat !== "0" && (
                  <div className="col-md-12" style={{ marginTop: 15 }}>
                    <FormLabel component="legend">Termina: </FormLabel>
                    <RadioGroup
                      name="repeatType"
                      value={typeRepeat}
                      onChange={(e) => setTypeRepeat(e.target.value)}
                      style={{ marginTop: 0, marginLeft: 20 }}
                    >
                      <div className="d-flex">
                        <div>
                          <FormControlLabel
                            style={{ justifyItems: "baseline" }}
                            control={
                              <Radio
                                checked={typeRepeat === "1"}
                                value="1"
                                name="radio-button-demo"
                              />
                            }
                            label="Em: "
                          />
                        </div>
                        <div
                          className="row"
                          style={{ marginLeft: 10, minWidth: 350 }}
                        >
                          <div className="col-md-12">
                            <TextField
                              id="repeatDate"
                              type="date"
                              value={repeatDate}
                              onChange={(e) => setRepeatDate(e.target.value)}
                              fullWidth
                              required
                              margin="normal"
                              error={error && !date}
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                      </div>
                      {repeat !== "esp" && (
                        <div className="d-flex">
                          <div>
                            <FormControlLabel
                              style={{
                                marginTop: "15px",
                                justifyItems: "baseline",
                              }}
                              control={
                                <Radio
                                  checked={typeRepeat === "2"}
                                  value="2"
                                  name="radio-button-demo"
                                />
                              }
                              label="Após: "
                            />
                          </div>
                          <div
                            className="row"
                            style={{ marginLeft: 10, minWidth: 350 }}
                          >
                            <div
                              className="col-md-12"
                              style={{ marginTop: "15px" }}
                            >
                              <TextField
                                id="quantityDays"
                                disabled={typeRepeat === "2" ? false : true}
                                type="number"
                                min={1}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      style={{ marginRight: 15 }}
                                    >
                                      Agendamentos
                                    </InputAdornment>
                                  ),
                                }}
                                value={numberOcurrency}
                                onChange={(e) =>
                                  setNumberOcurrency(e.target.value)
                                }
                                fullWidth
                                error={error && !numberOcurrency ? true : false}
                                InputLabelProps={{ shrink: true }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </RadioGroup>
                  </div>
                )}
                <div className="col-md-12" style={{ marginTop: "30px" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    className="jr-btn jr-btn-sm"
                    style={{ float: "left" }}
                    onClick={() =>
                      id
                        ? dispatch({
                            type: "ALTER_MODAL_TYPE",
                            modal_type: "view",
                          })
                        : close()
                    }
                  >
                    <i className="zmdi zmdi-close zmdi-hc-lg" />
                    <span> Cancelar </span>
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className="jr-btn jr-btn-sm bg-success"
                    style={{ float: "right" }}
                    onClick={() => save()}
                  >
                    <i className="zmdi zmdi-check zmdi-hc-lg" />
                    <span> Salvar </span>
                  </Button>
                </div>
              </>
            )}
          </>
        )}

        <TakePhotoWithCropper
          open={take_photo}
          handleClose={() => setTakePhoto(false)}
          imageReceive={(img) => {
            setImgPatient(img);
            if (patient.id !== "new") {
              dispatch({
                type: "ALTER_PHOTO",
                payload: { patient: patient.id, image: img },
              });
            }
          }}
        />
        {/* Aviso consulta posterior a data */}
        <SweetAlert
          show={modal_after}
          warning
          showConfirm={false}
          title=""
          onConfirm={() => {}}
        >
          <div className="row">
            <div className="col-12">
              <span style={{ fontWeight: "500" }}>
                Este paciente já possui um agendamento para{" "}
                {moment(eventAfter.start).format("DD/MM/YYYY HH:mm")}. Deseja
                continuar?
              </span>
            </div>
            <div className="col-6 mt-3">
              <Button
                variant="contained"
                className="jr-btn jr-btn-sm"
                fullWidth
                onClick={() => close()}
              >
                <span> Não, Cancelar </span>
              </Button>
            </div>
            <div className="col-6 mt-3">
              <Button
                color="primary"
                variant="contained"
                className="jr-btn jr-btn-sm"
                fullWidth
                onClick={() => setModalAfter(false)}
              >
                <span> Sim, Continuar </span>
              </Button>
            </div>
            <div className="col-12 mt-3">
              <Button
                color="secondary"
                variant="contained"
                className="jr-btn jr-btn-sm"
                fullWidth
                onClick={() => cancelEventsAfter()}
              >
                <span> Desmarcar e continuar </span>
              </Button>
            </div>
          </div>
        </SweetAlert>
        {/* alerta em caso de repetição */}
        {/* <SweetAlert
          show={warningModal}
          // show={true}
          warning
          showConfirm={false}
          title={warning}
          onConfirm={() => setModalWarning(true)}
        /> */}

        <SweetAlert
          show={modalAlertDate}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, continuar"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Data anterior a data atual, deseja continuar?"
          onConfirm={() => setModalAlertDate(false)}
          onCancel={() => close()}
        />
        <SweetAlert
          show={modalHourBloqued}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, continuar"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Horário bloqueado, deseja continuar?"
          onConfirm={() => setModalHourBloqued(false)}
          onCancel={() => close()}
        />
        <FormInfoPatient
          patient={patient.id}
          open={modalInfoPatient}
          handleClose={() => setModalInfoPatient(false)}
        />
        <RegisterUser
          add={(id, name) => {
            setPatient({ ...patient, id, name });
            setModalRegister(false);
          }}
          nameUser={patient.name}
          open={modalRegister}
          close={() => {
            setModalRegister(false);
            setPatient({ id: "", name: "" });
          }}
          address={""}
        />
      </div>
    );
  }
}

export default FormScheduling;
