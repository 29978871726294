import React, { useState, useEffect } from "react";
import CircularProgress from "components/CircularProgress";
import History from "./HistorySystemReview";
import Api from "services/api";

export default function SystemReview({ patient }) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    Api.get(`/microservice/systemreview/getCategories/`).then(res =>
      setCategories(res.data.categories)
    );
  }, []);

  if (categories.length === 0) {
    return <CircularProgress />;
  } else {
    return (
      <div className="container-person border">
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400"
          }}
        >
          Revisão dos sistemas
        </h3>
        <div className="w-100">
          {categories.map(row => (
            <History category={row} patient={patient} />
          ))}
        </div>
      </div>
    );
  }
}
