import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { BtnSave, BtnCan } from "components/Button";
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import moment from "moment";
import Api from "services/api";
import SweetAlert from "react-bootstrap-sweetalert";

function EditHourEvent({ event, user, date, start, end, edit, close }) {
  const [dateEvent, setDateEvent] = useState(moment(date).format("YYYY-MM-DD"));
  const [startEvent, setStartEvent] = useState(start);
  const [endEvent, setEndEvent] = useState(end);
  const [error, setError] = useState(false);
  const [modal_isset, setModalIsset] = useState(false);
  const config = useSelector((state) => state.calendar.config);

  function save(verify, deleteEvents) {
    if (dateEvent && startEvent && endEvent) {
      let formatStart = `${dateEvent} ${startEvent}:00`;
      let formatEnd = `${dateEvent} ${endEvent}:00`;
      Api.post(`/ms_system/calendar/editHourEvent/`, {
        event,
        start: formatStart,
        end: formatEnd,
        user,
        verify,
        deleteEvents,
        config,
      }).then((res) => {
        if (res.data.isset_events) {
          setModalIsset(true);
        } else {
          edit(formatStart, formatEnd);
        }
      });
    } else {
      setError(true);
    }
  }

  return (
    <Dialog open={true} onClose={() => close()}>
      <DialogTitle className="text-primary font-weight-semibold">
        Editar Horário
      </DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-12">
            <TextField
              id="date"
              type="date"
              label="Data:"
              value={dateEvent}
              onChange={(e) => setDateEvent(e.target.value)}
              fullWidth
              required
              margin="normal"
              error={error && !dateEvent}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="col-12">
            <TextField
              id="start"
              type="time"
              label="Início:"
              value={startEvent}
              onChange={(e) => setStartEvent(e.target.value)}
              fullWidth
              margin="normal"
              error={error && !startEvent}
            />
          </div>
          <div className="col-12">
            <TextField
              id="end"
              type="time"
              label="Fim:"
              value={endEvent}
              onChange={(e) => setEndEvent(e.target.value)}
              fullWidth
              margin="normal"
              error={error && !endEvent}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="col-12">
          <BtnCan title={"Cancelar"} onClick={() => close()} float="left" />
          <BtnSave
            title={"Salvar"}
            onClick={() => save(true, false)}
            float="right"
          />
        </div>
      </DialogActions>
      <SweetAlert
        show={modal_isset}
        warning
        showConfirm={false}
        title=""
        onConfirm={() => {}}
      >
        <div className="row">
          <div className="col-12">
            <span style={{ fontWeight: "500" }}>
              Existem eventos conflitantes neste horário. Deseja continuar?
            </span>
          </div>
          <div className="col-6 mt-3">
            <Button
              variant="contained"
              className="jr-btn jr-btn-sm"
              fullWidth
              onClick={() => {
                setModalIsset(false);
                close();
              }}
            >
              <span> Não, Cancelar </span>
            </Button>
          </div>
          <div className="col-6 mt-3">
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              fullWidth
              onClick={() => save(false, false)}
            >
              <span> Sim, Continuar </span>
            </Button>
          </div>
          <div className="col-12 mt-3">
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              fullWidth
              onClick={() => save(false, true)}
            >
              <span> Desmarcar agendamentos e continuar </span>
            </Button>
          </div>
        </div>
      </SweetAlert>
    </Dialog>
  );
}

export default EditHourEvent;
