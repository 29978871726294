import React from "react";

export default function ContractTerms({ providerData }) {
  console.log("providerData", providerData);
  const {
    address,
    cnpj,
    company_name,
    neighborhood,
    number,
    postalcode,
    rep_cpf,
    rep_estado_civil,
    rep_name,
    rep_nat,
    city,
    rep_prof,
  } = providerData;
  return (
    <div className="text-justify">
      <p>
        <strong className="toUp">
          INSTRUMENTO PARTICULAR DE CONTRATO DE LICENÇA DE USO DE SOFTWARE E
          PRESTAÇÃO DE SERVIÇOS CORRELATOS
        </strong>
      </p>
      <p>
        Por este{" "}
        <strong>
          INSTRUMENTO PARTICULAR DE CONTRATO DE LICENÇA DE USO DE SOFTWARE E
          PRESTAÇÃO DE SERVIÇOS CORRELATOS
        </strong>
        , no contexto a seguir designado como <strong>CONTRATO</strong>, de um
        lado,
      </p>
      <p>
        <strong>LIGADO NA SAÚDE LTDA.</strong>, pessoa jurídica de direito
        privado regularmente constituída, inscrita no CNPJ/MF sob o nº
        28.830.034/0001-22, com sede a Rua Mato Grosso, nº 1041, Bairro Santo
        Agostinho, Belo Horizonte, Minas Gerais, CEP nº 30190-088, representada,
        neste ato, pelo Sócio Administrador{" "}
        <strong>GERALDO MAJELLA DE CASTRO VASCONCELLOS</strong>, brasileiro,
        divorciado, empresário, portador da carteira de Identidade nº
        MG-155.935, expedida pela SSP-MG, CPF/MF nº 176.601.146-20, residente e
        domiciliado à Rua Aníbal Gontijo, nº 100, Apto 302, Bairro Luxemburgo,
        CEP 30380-290, no município de Belo Horizonte, Minas Gerais, doravante
        denominada <strong>“LICENCIANTE”</strong>; e de outro,
      </p>
      <p>
        {company_name}, pessoa jurídica de direito privado regularmente
        constituída sob a forma de sociedade empresária limitada, inscrita no
        CNPJ/MF sob o nº. {cnpj}, com sede na {address}, nº. {number}, no Bairro{" "}
        {neighborhood}, no Município de {city}, CEP {postalcode}, neste ato
        representada por seu representante legal {rep_name}, {rep_nat},{" "}
        {rep_estado_civil}, {rep_prof}, inscrito no CPF/MF sob o nº. {rep_cpf},
        doravante denominada <strong>“LICENCIADA”</strong>;
      </p>
      <p>
        <strong>LICENCIANTE</strong> e <strong>LICENCIADA</strong>, denominadas{" "}
        <strong>CONTRATANTES</strong> quando mencionados em conjunto neste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong>Considerando:</strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(A)</strong>que a{" "}
        <strong>LICENCIANTE</strong> atua em atividades de prestação de serviços
        de tratamento de dados, provedores de serviços de aplicação e serviços
        de hospedagem na internet, desenvolvimento/criação de interfaces para
        internet (web design), desenvolvimento e licenciamento de programas de
        computador customizáveis e não customizáveis, consultoria em tecnologia
        da informação, suporte técnico, manutenção e outros serviços em
        tecnologia da informação, edição de listas de dados e de outras
        informações, cuja forma impressa, eletrônica e na internet, agência de
        publicidade e desenvolvimento de programas de computador sob encomenda;
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(B)</strong>ainda que a{" "}
        <strong>LICENCIANTE</strong> é proprietária do portal{" "}
        <strong>SalutemPlus</strong>, que consiste em um software aplicativo PWA
        (Progressive Web App), cujos os objetivos são:
        <ul style={{ marginTop: "1rem" }}>
          <li>
            gestão dos processos de atendimento pelos profissionais e
            prestadores de serviços de saúde, bem como a gestão dos cuidados de
            saúde;
          </li>
          <li>
            gestão dos processos operacionais e administrativos de organização
            da área da saúde;
          </li>
          <li>
            oferecer recursos para os pacientes interagirem com suas informações
            de saúde bem como inserir dados de anamnese (peso, altura, alergias,
            doenças da infância e outros) e seus dados pessoais;
          </li>
          <li>
            oferecer recursos de mídia digital para que os pacientes possam
            interagir e se comunicar com profissionais e prestadores de serviços
            de saúde autorizados por ele, com familiares e/ou outras pessoas
            externas à ILPI que o acompanham em seus cuidados de saúde;
          </li>
          <li>
            oferecer recursos de busca facilitada de profissionais e prestadores
            de serviços de saúde cadastrados no portal.
          </li>
        </ul>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(C)</strong>a{" "}
        <strong>LICENCIADA</strong> tem interesse em estabelecer negócio
        jurídico com a <strong>LICENCIANTE</strong> na forma prevista neste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        As <strong>CONTRATANTES</strong> resolvem firmar o presente{" "}
        <strong>CONTRATO</strong>, negócio jurídico entabulado e regulamentado
        na forma das cláusulas, termos e condições a seguir discriminadas.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA PRIMEIRA – DEFINIÇÕES
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>1.</strong>Os termos,
        expressões, palavras e verbos empregados neste <strong>CONTRATO</strong>{" "}
        serão interpretados de acordo com seu sentido legal, especialmente na
        forma prevista na Lei nº. 9.609 de 19 de fevereiro de 1998, Lei nº.
        9.610 de 19 de fevereiro ode 1998, Lei nº 10.406 de 10 de janeiro de
        2002 e Lei nº 13.709 de 14 de agosto de 2018 (Lei Geral de Proteção de
        Dados Pessoais – LGPD).
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>1.1</strong>Sem prejuízo da
        previsão contida no caput desta cláusula, os termos, expressões,
        palavras e verbos abaixo empregados serão interpretados a partir das
        seguintes definições:
      </p>
      <p>
        <strong>Aplicativo ou App</strong>: Software concebido para processar
        dados eletronicamente, facilitando e reduzindo o tempo de execução de
        uma tarefa pelo usuário de computadores, notebooks, smartphone, tablet
        ou outro equipamento compatível.
      </p>
      <p>
        <strong>Licença de uso/Licenciamento de uso</strong>: ato jurídico pelo
        qual a <strong>LICENCIADA</strong> adquire o direito de uso do software
        de propriedade da <strong>LICENCIANTE</strong>.
      </p>
      <p>
        <strong>Software</strong>: consiste no aplicativo PWA (Progressive Web
        App) que dispensa a utilização de hospedagem do app nas lojas Google
        Play e Apple Store, pois utiliza as tecnologias mais avançadas dos
        navegadores modernos, como Push API, Cache, Web Worker e Service Worker.
        Desta forma é possível ter uma aplicação que roda no browser mas tem uma
        experiência de usuário de aplicativo mobile, implementando a segurança
        de dados através de criptografia de ponta a ponta, garantindo assim a
        integridade dos dados através de recursos de rastreabilidade dos dados
        inseridos e alterados e a interface gráfica que possibilita navegação
        fluida e intuitiva.
      </p>
      <p>
        <strong>SVA</strong>: serviço de valor adicionado são todos os serviços
        fornecidos pela <strong>LICENCIANTE</strong> à{" "}
        <strong>LICENCIADA</strong> que implicam em custo extraordinário para{" "}
        <strong>LICENCIADA</strong> na forma deste <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong>SSVA</strong>: serviço sem valor adicionado são todos os
        serviços fornecidos pela <strong>LICENCIANTE</strong> à{" "}
        <strong>LICENCIADA</strong> que não implicam em custo extraordinário
        para <strong>LICENCIADA</strong> na forma deste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong>Termo aditivo</strong>: ato jurídico documentado que
        possibilitará a renovação da existência, validade e eficácia de todos os
        direitos e obrigações regulamentadas por este <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong>Consentimento</strong> – manifestação livre, informada e
        inequívoca pela qual o titular concorda com o tratamento de seus dados
        pessoais para uma finalidade determinada, sendo passível de revogação a
        qualquer momento. A revogação do consentimento não cancela os
        processamentos realizados previamente.
      </p>
      <p>
        <strong>Controlador</strong> – pessoa jurídica de direito privado a quem
        competem as decisões referentes ao tratamento de dados pessoais, no caso
        a <strong>LIGADO NA SAÚDE LTDA.</strong>
      </p>
      <p>
        <strong>Operador</strong> - pessoa natural ou jurídica, de direito
        público ou privado, que realiza o tratamento de dados pessoais em nome
        do controlador, tais como a <strong>LICENCIANTE</strong> (ILPI),
        profissionais de saúde ligados à <strong>LICENCIANTE</strong>,
        funcionários da <strong>LICENCIANTE</strong>, os próprios
        residentes/pacientes e familiares, todos com expressa autorização dos
        residentes/pacientes e/ou seus representantes legais.
      </p>
      <p>
        <strong>Dado pessoal</strong> – é a informação relacionada a uma pessoa
        natural cadastrada na plataforma controlada pela{" "}
        <strong>LIGADO NA SAÚDE LTDA.</strong>, ou seja, trata-se de qualquer
        informação relacionada a um usuário que o identifique ou que, usada em
        combinação com outras informações tratadas, permita que se identifique
        um indivíduo.{" "}
      </p>
      <p>
        <strong>Plataforma</strong> – são todos os recursos tecnológicos
        incluindo o Data Center da Amazon que disponibiliza na nuvem recursos de
        hardware (ex.: computadores servidores, dispositivos de armazenamento de
        dados, ect.) e software (ex.: sistema gerenciador de banco de dados,
        sistema operacional, sistema de armazenamento de arquivos, etc) que
        suporta a operação de um software e o próprio software{" "}
        <strong>SalutemPlus</strong> que é um PWA – Progressive Web App (uma
        aplicação híbrida entre o Web e o Mobile) podendo ser operado a partir
        de qualquer dispositivo e local com disponibilidade de acesso à internet
        disponibilizado aos operadores e aos usuários pela controladora{" "}
        <strong>LIGADO NA SAÚDE LTDA.</strong>
      </p>
      <p>
        <strong>Tratamento</strong> – configura toda operação de coleta,
        produção, recepção, classificação, utilização, acesso, reprodução,
        transmissão, distribuição, processamento, arquivamento, armazenamento,
        eliminação virtual de dados (significa que os dados ficam indisponíveis
        para utilização mas não são removidos do local onde estão armazenados),
        avaliação ou controle da informação, modificação, comunicação,
        transferência, difusão ou extração realizada com dados dos usuários,
        sendo os mesmos registrados/alimentados pelos operadores no sistema da
        controladora <strong>LIGADO NA SAÚDE LTDA.</strong>
      </p>
      <p>
        <strong>Usuário</strong> – é a pessoa natural titular dos dados pessoais
        que serão objeto de tratamento na plataforma controlada pela
        controladora <strong>LIGADO NA SAÚDE LTDA.</strong>, no caso os
        residentes nas ILPIs.
      </p>
      <p>
        <strong>Banco de dados</strong> – trata-se do conjunto estruturado de
        dados pessoais e empresariais, estabelecido em um ou em vários locais,
        em suporte eletrônico ou físico, podendo identificar um determinado
        usuário.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA SEGUNDA – OBJETO
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>2.</strong>O objeto deste{" "}
        <strong>CONTRATO</strong> é a regulamentação de maneira ampla do negócio
        jurídico celebrado entre as <strong>CONTRATANTES</strong>, com a
        finalidade de garantir,{" "}
        <strong style={{ textDecoration: "underline" }}>
          sem exclusividade
        </strong>
        , o licenciamento de uso do software portal <strong>SalutemPlus</strong>{" "}
        pela <strong>LICENCIANTE</strong> à <strong>LICENCIADA</strong>, bem
        como a prestação de serviços correlatos ao referido licenciamento
        (implantação e ativação do “software”, atendimento ao cliente (suporte),
        treinamento, atualização e modificações do “software”), tudo na forma
        definida neste <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>2.1</strong>É, também, objeto
        deste <strong>CONTRATO</strong> a autorização pela{" "}
        <strong>LICENCIANTE</strong> do uso de seu nome, marca e logotipo pela{" "}
        <strong>LICENCIADA</strong> como garantia da consecução do objeto
        previsto no caput desta cláusula, bem como dos direitos e obrigações
        inseridos neste <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA TERCEIRA – PROPRIEDADE DO SOFTWARE
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>3.</strong>A{" "}
        <strong>LICENCIADA</strong> declara e reconhece que o software portal{" "}
        <strong>SalutemPlus</strong> objeto de licenciamento por este{" "}
        <strong>CONTRATO</strong> é de propriedade{" "}
        <strong style={{ textDecoration: "underline" }}>exclusiva</strong> da{" "}
        <strong>LICENCIANTE</strong>, única detentora dos direitos autorais e de
        propriedade intelectual deste programa de computador, independentemente
        de qualquer registro no Instituto Nacional de Propriedade Industrial –
        INPI ou em outro órgão competente, encontrando-se este direito amparado
        e protegido por Tratados Internacionais de Direitos Autorais e
        Intelectuais e pelo Ordenamento Jurídico Brasileiro, especialmente pela
        Lei nº. 9.609/98 e Lei nº. 9.610/98.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>3.1</strong>As{" "}
        <strong>CONTRATANTES</strong> declaram e reconhecem, para todos os fins
        de direito, que o software portal <strong>SalutemPlus</strong> objeto de
        licenciamento por este <strong>CONTRATO</strong> não terá sua
        propriedade transferida, em hipótese alguma, à{" "}
        <strong>LICENCIADA</strong> e/ou a terceiros, razão pela qual esta
        licença não poderá ser interpretada como transferência de quaisquer
        direitos do “software portal <strong>SalutemPlus</strong> de propriedade
        da <strong>LICENCIANTE</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>3.2</strong>Na ocorrência de
        violação direta ou indireta do software portal{" "}
        <strong>SalutemPlus</strong> objeto de licenciamento por este{" "}
        <strong>CONTRATO</strong> pela <strong>LICENCIADA</strong>, sem prejuízo
        das demais cominações previstas neste <strong>CONTRATO</strong>, esta
        responderá pessoalmente pelos danos causados ao titular/proprietário
        deste programa de computador.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA QUARTA – LICENÇA DE USO DO SOFTWARE
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>4.</strong>A licença de uso
        objeto deste <strong>CONTRATO</strong> consiste estritamente na
        autorização pela <strong>LICENCIANTE</strong> para utilização do
        software portal <strong>SalutemPlus</strong>, em caráter oneroso,
        temporário, não exclusivo, de forma limitada e intransferível pela{" "}
        <strong>LICENCIADA</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>4.1.</strong>Fica convencionado
        entres as <strong>CONTRATANTES</strong> que a{" "}
        <strong>LICENCIADA</strong> somente adquirirá o direito de uso do
        software portal <strong>SalutemPlus</strong> que lhe é licenciado pela{" "}
        <strong>LICENCIANTE</strong> e dos serviços objeto deste{" "}
        <strong>CONTRATO</strong> após a assinatura deste{" "}
        <strong>CONTRATO</strong> e cumprimento das obrigações assumidas.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>4.2.</strong>A autorização para
        uso do software portal <strong>SalutemPlus</strong> objeto deste{" "}
        <strong>CONTRATO</strong> depende de conformidade{" "}
        <strong>contínua</strong> da <strong>LICENCIADA</strong> com suas
        cláusulas, termos e condições, especialmente aquelas que tratam dos
        pagamentos mensais.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>4.3.</strong>A{" "}
        <strong>LICENCIADA</strong> declara por este <strong>CONTRATO</strong>{" "}
        que conheceu previamente o software portal <strong>SalutemPlus</strong>{" "}
        e seus termos de uso, bem como seus módulos e funcionalidades.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>4.4.</strong>É expressamente
        vedado à <strong>LICENCIADA</strong>, seus prepostos, funcionários e/ou
        colaboradores, alienar de qualquer forma (vender, permutar e/ou doar),
        dar em garantia, alugar, arrendar, emprestar, ceder, distribuir,
        licenciar, sublicenciar, hospedar, copiar, modificar, alterar,
        completar, desmontar, adulterar, decompor, efetuar engenharia reversa
        dos códigos executáveis (fonte) do software portal{" "}
        <strong>SalutemPlus</strong> e/ou, mas não se limitando, transferir,
        total ou parcialmente, sob qualquer modalidade, gratuita ou
        onerosamente, provisória ou permanentemente, a quaisquer terceiros o uso
        do software portal <strong>SalutemPlus</strong> sem a prévia e expressa
        autorização da <strong>LICENCIANTE</strong> ou, ainda, permitir que tais
        práticas ocorram, sob pena de responsabilização civil e criminal.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>4.5.</strong>Na ocorrência de
        qualquer tipo de violação direta ou indireta da licença de uso ou
        integridade do software portal <strong>SalutemPlus</strong> objeto de
        licenciamento por este <strong>CONTRATO</strong> pela{" "}
        <strong>LICENCIADA</strong>, por seus prepostos, funcionários e/ou
        colaboradores, sem prejuízo das demais cominações previstas neste{" "}
        <strong>CONTRATO</strong>, será devida pela <strong>LICENCIADA</strong>{" "}
        à <strong>LICENCIANTE</strong> uma multa compensatória no valor de 24
        (vinte e quatro) vezes do valor pago à <strong>LICENCIANTE</strong>{" "}
        mensalmente, além de eventuais perdas e danos a serem oportunamente
        apurados, tudo sem prejuízo da cominação prevista no item 4.2 da
        cláusula quarta.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA QUINTA – ATUALIZAÇÃO E MANUTENÇÃO DO SOFTWARE
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>5.</strong>As atualizações da
        licença de uso do software portal <strong>SalutemPlus</strong> objeto
        deste <strong>CONTRATO</strong> consistem em todos os processos
        periódicos (melhorias, adaptações, modificações/alterações, reparos,
        complementações, customizações, etc.) que envolvam a disponibilização de
        uma nova versão do programa de computador pela{" "}
        <strong>LICENCIANTE</strong> à <strong>LICENCIADA</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>5.1.</strong>O software portal{" "}
        <strong>SalutemPlus</strong> objeto de licenciamento por este{" "}
        <strong>CONTRATO</strong> poderá ser atualizado, bem como em quaisquer
        de seus aplicativos complementares, independentemente de comunicação
        prévia e/ou consentimento expresso da <strong>LICENCIADA</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>5.2.</strong>A{" "}
        <strong>LICENCIADA</strong> deverá atualizar a versão do software portal{" "}
        <strong>SalutemPlus</strong> objeto deste <strong>CONTRATO</strong>{" "}
        sempre que a <strong>LICENCIANTE</strong> disponibilizar uma nova versão
        do programa de computador, mesmo que isto acarrete a indisponibilidade
        temporária do software <strong>PORTAL SALUTEM PLUS</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>5.3.</strong>A indisponibilidade
        temporária constante do item 5.2. deste <strong>CONTRATO</strong>, além
        dos casos de atualização do sistema e/ou manutenção que serão
        previamente informados (data e hora), poderá ocorrer também por fatores
        imprevisíveis (caso fortuito ou força maior), o que será resolvido a
        tempo e modo, dependendo da complexidade do evento.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA SEXTA – IMPLANTAÇÃO E ATIVAÇÃO DO SOFTWARE
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>6.</strong>A implantação da
        licença de uso do software portal <strong>SalutemPlus</strong> objeto
        deste <strong>CONTRATO</strong> consiste na configuração, treinamento
        dos operadores, bem como orientação sobre as funcionalidades deste
        programa de computador como forma de garantir à{" "}
        <strong>LICENCIADA</strong> a sua regular utilização.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>6.1</strong>A implantação da
        licença de uso do software portal <strong>SalutemPlus</strong> objeto
        deste <strong>CONTRATO</strong> ocorrerá em data e horário previamente
        ajustado pelas <strong>CONTRANTES</strong>, devendo a{" "}
        <strong>LICENCIADA</strong> solicitar este serviço formalmente para{" "}
        <strong>LICENCIANTE</strong> com prazo de 7 (sete) dias úteis de
        antecedência.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA SÉTIMA – SUPORTE TÉCNICO
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>7.</strong>A{" "}
        <strong>LICENCIANTE</strong> disponibilizará à{" "}
        <strong>LICENCIADA</strong> a prestação de serviços de suporte técnico
        que consiste no atendimento pelo website, e-mail institucional e/ou
        whatsapp, possibilitando a abertura de chamados relativos ao uso do
        software portal <strong>SalutemPlus</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>7.1</strong>O suporte técnico
        disponibilizado pela <strong>LICENCIANTE</strong> à{" "}
        <strong>LICENCIADA</strong> pode ter natureza de serviços sem valor
        adicionado (SSVA), quando os chamados relativos ao uso do software
        portal <strong>SalutemPlus</strong> formalizados pela{" "}
        <strong>LICENCIADA</strong> se restringirem a esclarecimento/dúvidas,
        correção de erros ou sugestões.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>7.2</strong>O suporte técnico
        disponibilizado pela <strong>LICENCIANTE</strong> à{" "}
        <strong>LICENCIADA</strong> pode ter natureza de serviço de valor
        adicionado (SVA), quando os chamados relativos ao uso do software portal{" "}
        <strong>SalutemPlus</strong> formalizados pela{" "}
        <strong>LICENCIADA</strong> não se restringirem a
        esclarecimento/dúvidas, correção de erros ou sugestões.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>7.3</strong>As{" "}
        <strong>CONTRATANTES</strong> ajustam e estabelecem que qualquer
        customização no “software aplicativo” ou “APP” e nas “tecnologias e
        soluções digitais” que a este integram e que sejam solicitadas pela{" "}
        <strong>LICENCIADA</strong>, será considerado “serviço de valor
        adicionado” (SVA) e sua contratação respeitará o previsto neste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>7.4</strong>O atendimento do
        suporte poderá não ser imediato, podendo a resposta e resolução depender
        da criticidade/complexidade do chamado.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>7.5</strong>O suporte técnico
        não inclui a prestação de serviços de consultoria em áreas como, por
        exemplo, gestão de empresa, tecnologia da informação, contabilidade,
        direito, infraestrutura e outras que não tem relação com o software
        portal <strong>SalutemPlus</strong>.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA OITAVA – OBRIGAÇÕES E RESPONSABILIDADE DA LICENCIANTE
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>8.</strong>A{" "}
        <strong>LICENCIANTE</strong> obriga-se, não somente pelo que prescrevem
        as Leis nº. 9.609/98, 9.610/98 10.406/2002 e 13.709/2018, mas também:
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>8.1</strong>A cumprir
        pontualmente com todos os termos, cláusulas e condições previstas neste{" "}
        <strong>CONTRATO</strong>;
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>8.2</strong>A disponibilizar os
        módulos e funcionalidades do software portal{" "}
        <strong>SalutemPlus</strong> cuja licença é objeto deste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>8.3</strong>A disponibilizar as
        novas versões e atualizações do software portal{" "}
        <strong>SalutemPlus</strong> cuja licença é objeto deste{" "}
        <strong>CONTRATO</strong> que contenham alterações, correções ou
        melhorias de desempenho.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>8.4</strong>A promover a
        implantação e ativação da licença de uso do software portal{" "}
        <strong>SalutemPlus</strong> objeto deste <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>8.5</strong>A prestar os
        serviços de suporte técnico na forma prevista na cláusula oitava deste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>8.6</strong>A obter e manter em
        vigor o uso de licença de “software” de terceiros para garantir a
        operacionalidade do “software” cuja licença é objeto deste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>8.7</strong>A emitir e
        encaminhar o respectivo boleto bancário até o 3º (terceiro) dia útil
        para pagamento pela <strong>LICENCIADA</strong> dos valores relativos ao
        licenciamento/utilização do software portal <strong>SalutemPlus</strong>
        .
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA NONA – OBRIGAÇÕES E RESPONSABILIDADES DA LICENCIADA
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.</strong>A{" "}
        <strong>LICENCIADA</strong> obriga-se, não somente pelo que prescrevem
        as Leis nº. 9.609/98, 9.610/98 10.406/2002 e 13.709/2018, mas também:
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.1</strong>A cumprir
        pontualmente com todos os termos, cláusulas e condições previstas neste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.2</strong>A realizar todas as
        contraprestações financeiras objeto deste <strong>CONTRATO</strong> na
        forma, modo e prazo previstos na cláusula décima primeira deste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.3</strong>A proteger o
        software portal <strong>SalutemPlus</strong> cuja licença é objeto deste{" "}
        <strong>CONTRATO</strong> de forma adequada e manter a identificação da
        propriedade intelectual da <strong>LICENCIANTE</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.4</strong>A responsabilizar-se
        pela adequação dos procedimentos operacionais que se façam necessários
        ao uso adequado do software portal <strong>SalutemPlus</strong> cuja
        licença é objeto deste <strong>CONTRATO</strong>, inclusive em relação
        aos seus aspectos legais e fiscais referentes ao desenvolvimento de suas
        atividades econômicas empresariais geridas pelo uso do software portal{" "}
        <strong>SalutemPlus</strong> objeto deste <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.5</strong>A conferir e validar
        a veracidade das informações inseridas no software portal{" "}
        <strong>SalutemPlus</strong> cuja licença é objeto deste{" "}
        <strong>CONTRATO</strong>, bem como a exatidão da configuração dos
        parâmetros do software portal <strong>SalutemPlus</strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.6</strong>A providenciar e
        manter em perfeito funcionamento toda a infraestrutura para utilização
        do software portal <strong>SalutemPlus</strong>, incluindo: servidores,
        estações de trabalho, rede de computadores, conexão com a internet,
        nobreaks, periféricos, sistemas operacionais, firewall, antivírus, etc.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.7</strong>A permitir acesso in
        loco e via internet aos computadores e servidores onde o software portal{" "}
        <strong>SalutemPlus</strong> cuja licença é objeto deste{" "}
        <strong>CONTRATO</strong> estiver instalado, oferecendo as condições
        necessárias para que a <strong>LICENCIANTE</strong> realize eventual
        implantação, ativação, instalações, configurações, manutenção,
        monitoramento ou quaisquer outras atividades que possam influenciar
        funcionamento e uso do software portal <strong>SalutemPlus</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.8</strong>A providenciar,
        sempre que ocorrerem quaisquer problemas com o software portal{" "}
        <strong>SalutemPlus</strong> cuja licença é objeto deste{" "}
        <strong>CONTRATO</strong>, toda a documentação, relatórios de erros e
        demais informações que relatem as circunstâncias em que os problemas
        ocorreram, seguindo as orientações e procedimentos determinados pela{" "}
        <strong>LICENCIANTE</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.9</strong>A manter o software
        portal <strong>SalutemPlus</strong> cuja licença é objeto deste{" "}
        <strong>CONTRATO</strong> atualizado através do das novas versões
        disponibilizadas pela <strong>LICENCIANTE</strong>, uma vez que este
        procedimento é imprescindível para garantir o perfeito funcionamento do
        software portal <strong>SalutemPlus</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.10</strong>A manter seus dados
        cadastrais atualizados junto a <strong>LICENCIANTE</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.11</strong> encaminhar no
        último dia útil de cada mês à <strong>LICENCIANTE</strong> a relação dos
        valores das mensalidades pagas pelos residentes no mês corrente, sem a
        necessidade de especificar quaisquer dados pessoais dos residentes, a
        fim de viabilizar o levantamento dos valores que serão quitados pelo
        licenciamento do software portal <strong>SalutemPlus</strong> até o 5º
        (quinto) dia útil, via boleto bancário, na forma do item 12.1 deste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.12</strong>A adotar medidas de
        segurança, técnicas e administrativas aptas a proteger os dados pessoais
        dos pacientes/residentes, inclusive de acessos não autorizados e de
        situações acidentais ou ilícitas de destruição, perda, alteração,
        comunicação ou qualquer forma de tratamento inadequado ou ilícito.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>9.13</strong>A garantir a
        segurança da informação em relação aos dados pessoais dos
        pacientes/residentes, mesmo após o término do tratamento dos dados.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA DÉCIMA – VIGÊNCIA
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>10.</strong>Este{" "}
        <strong>CONTRATO</strong> entrará em vigor na data da sua assinatura e
        terá vigência conforme previsto, ou seja, pelo prazo de 12 (doze) meses.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>10.1</strong>As{" "}
        <strong>CONTRATANTES</strong> contratam a possibilidade de prorrogação
        sucessiva deste <strong>CONTRATO</strong> por períodos correspondentes
        ao prazo previsto no caput desta cláusula, mesmo sem a formalização de
        novo <strong>contrato</strong> e/ou termo aditivo, o que se implementará
        caso: i) for ultrapassada a vigência prevista no caput desta cláusula;
        ii) nenhuma das <strong>CONTRATANTES</strong> manifeste seu interesse em
        rescindir o presente <strong>CONTRATO</strong>; e, iii) a{" "}
        <strong>LICENCIADA</strong> mantenha-se credenciada para utilização da
        licença de uso do software portal <strong>SalutemPlus</strong> objeto
        deste <strong>CONTRATO</strong> e prestação de serviços correlatos.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA DÉCIMA PRIMEIRA – PAGAMENTO E REMUNERAÇÃO
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>11.</strong>A
        <strong>LICENCIADA</strong> pagará à <strong>LICENCIANTE</strong>, pelo
        licenciamento mensal do uso do software portal{" "}
        <strong>SalutemPlus</strong> objeto deste <strong>CONTRATO</strong> o
        percentual de 1% (um por cento) incidente sobre o valor da mensalidade
        paga por cada residente à<strong>LICENCIADA</strong> naquele mês.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>11.1</strong>A
        <strong>LICENCIADA</strong> pagará o percentual de 1% (um por cento)
        sobre o salário mínimo, caso o residente pague à
        <strong>LICENCIADA</strong> valor inferior ao salário mínimo a título de
        mensalidade.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>11.2</strong>A
        <strong>LICENCIADA</strong> pagará à <strong>LICENCIANTE</strong>, pela
        prestação de serviço de valor adicionado (SVA), remoto ou presencial, o
        valor mínimo de R$ 120,00 (cento e vinte reais) equivalente a hora
        técnica.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>11.3</strong>As{" "}
        <strong>CONTRATANTES</strong> ajustam que todas as contraprestações
        financeiras mensais vencerão no dia 5º (quinto) dia útil de cada mês
        subsequente a utilização do software portal <strong>SalutemPlus</strong>{" "}
        objeto deste <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>11.4</strong>As{" "}
        <strong>CONTRATANTES</strong> ajustam que o pagamento de todas as
        contraprestações financeiras mensais objeto deste{" "}
        <strong>CONTRATO</strong> serão realizadas através de pagamento de
        boleto bancário emitido para o respectivo fim.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>11.5</strong>As{" "}
        <strong>CONTRATANTES</strong> ajustam que caso se verifique a
        impontualidade e/ou inadimplemento das contraprestações financeiras
        definidas nesta cláusula, esta situação implicará, sobre a parcela
        vencida, no acréscimo, respectivamente, de: i) correção monetária de
        acordo com o Índice Geral de Preços do Mercado – IGPM acumulado entre a
        data do inadimplemento e a data do pagamento; ii) juros moratórios a
        razão de 1% (um por cento) ao mês, pro rata die; acumulado entre a data
        do inadimplemento e a data do pagamento e, iii) de multa 2% (dois por
        cento) sobre o valor total da obrigação vencida corrigida e atualizada
        na forma deste item.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>11.6</strong>O inadimplemento da
        remuneração estabelecida neste <strong>CONTRATO</strong> por prazo
        superior a 30 (trinta) dias, acarretará a suspensão imediata da licença
        de uso do software portal <strong>SalutemPlus</strong> até a
        regularização dos pagamentos.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>11.7</strong>As{" "}
        <strong>CONTRATANTES</strong> ajustam que todos os valores devidos à{" "}
        <strong>LICENCIANTE</strong> pela<strong>LICENCIADA</strong> serão
        corrigidos a cada período de 12 (doze) meses de vigência do{" "}
        <strong>CONTRATO</strong>, de acordo com a variação do Índice Geral de
        Preços do Mercado – IGPM acumulada no período dos 12 (doze) meses de
        vigência do <strong>CONTRATO</strong>, ou por qualquer outro índice
        legal sugerido pela <strong>LICENCIANTE</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>11.8</strong>O valor mensal
        estabelecido a título de remuneração neste <strong>CONTRATO</strong>{" "}
        poderá ser alterado caso a<strong>LICENCIADA</strong> faça a opção
        expressa pela contratação de novos módulos e funcionalidades
        disponibilizados pela <strong>LICENCIANTE</strong> no software portal{" "}
        <strong>SalutemPlus</strong>, o que será feito por termo aditivo.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA DÉCIMA SEGUNDA – RESCISÃO E MULTA
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>12.</strong>As{" "}
        <strong>CONTRATANTES</strong> contratam a possibilidade de resilição
        unilateral deste <strong>CONTRATO</strong>, ou seja, a possibilidade de
        encerrar a vigência do negócio jurídico nele instrumentalizado de forma
        consensual, dispensando-se, neste caso, exigência à sanção penal e/ou
        multa compensatória prevista nesta cláusula, desde que: i) qualquer das{" "}
        <strong>CONTRATANTES</strong> notifique a outra{" "}
        <strong>CONTRATANTE</strong> com antecedência prévia de 60 (sessenta)
        dias; e, cumulativamente ii) seja respeitado o prazo mínimo de vigência
        previsto no caput da cláusula sétima, ou seja, 12 (doze) meses.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>12.1</strong>As{" "}
        <strong>CONTRATANTES</strong> contratam a possibilidade de resilição
        unilateral antecipada deste <strong>CONTRATO</strong>, ou seja, a
        possibilidade de se encerrar a vigência do negócio jurídico nele
        instrumentalizado de forma antecipada, ou melhor, antes de encerrado o
        prazo mínimo de vigência previsto no caput da cláusula décima primeira,
        caso se verifique a mora e/ou o inadimplemento de qualquer das
        obrigações estabelecidas neste <strong>CONTRATO</strong> e por qualquer
        das <strong>CONTRATANTES</strong>, desde que a referida infração não
        seja corrigida pela <strong>CONTRATANTE</strong> infratora dentro de 30
        (trinta) dias contados do recebimento da notificação por escrito enviada
        pela <strong>CONTRATANTE</strong> prejudicada para o devido fim,
        respondendo a <strong>CONTRATANTE</strong> infratora.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>12.2</strong>Este{" "}
        <strong>CONTRATO</strong>, poderão ser rescindidos pelas{" "}
        <strong>CONTRATANTES</strong>, em qualquer tempo, independentemente de
        aviso, notificação extrajudicial ou interpelação judicial, nos seguintes
        casos:
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(A)</strong> insolvência de
        qualquer das <strong>CONTRATANTES</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(B)</strong> solicitação ou
        ajuizamento de ação de recuperação extrajudicial ou judicial, falência,
        dissolução ou liquidação extrajudicial ou judicial por qualquer das{" "}
        <strong>CONTRATANTES</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(C)</strong> caso fortuito ou
        evento de força maior, regularmente comprovados, impeditivos da execução
        do objeto deste <strong>CONTRATO</strong> que perdure por mais de 30
        (trinta) dias.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA DÉCIMA TERCEIRA – CONFIDENCIALIDADE E PRIVACIDADE
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>13.</strong>Para fins deste{" "}
        <strong>CONTRATO</strong>, considera-se informação sigilosa e
        confidencial toda e qualquer informação utilizada pelas{" "}
        <strong>
          <strong>CONTRATANTE</strong>S
        </strong>{" "}
        para consecução de seu objeto, ficando expressamente vedado à{" "}
        <strong>CONTRATANTE</strong> receptora da informação confidencial, por
        si, seus representantes legais, diretores, cotistas, acionistas,
        agentes, prepostos e empregados, de revelá-la, a qualquer título e sob
        quaisquer circunstâncias, sejam estas informações sob a forma oral ou
        escrita, mecânica ou digital, magnético ou por qualquer outro meio que
        possa ser criado, incluindo, mas não limitando, dados, atos, fatos,
        decisões, arquivos, registros e documentos referentes à{" "}
        <strong>CONTRATANTE</strong> reveladora, que venham a ser tratados,
        fornecidos e/ou que tiverem conhecimento, sob pena de responsabilização
        civil e criminal.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>13.1</strong>As informações
        confidenciais não devem ser reproduzidas de nenhuma forma, salvo para
        cumprimento do objetivo deste <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>13.2</strong>Em relação às
        informações confidenciais da outra <strong>CONTRATANTE</strong>, cada
        uma das <strong>CONTRATANTES</strong> deverá adotar todas as
        providências razoáveis (definidas abaixo) para mantê-las em sigilo; não
        podendo divulgá-las a nenhuma pessoa que não seja de boa fé e à qual o
        acesso se faz necessário para exercício de seus direitos e/ou
        cumprimento das obrigações previstas neste <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>13.3</strong>As informações
        confidenciais que são preservadas no presente <strong>CONTRATO</strong>{" "}
        incluem, sem limitação:
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(A)</strong>todas e quaisquer
        informações técnicas ou não técnicas.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(B)</strong>conceitos e ideias
        relacionadas a licença de uso objeto deste <strong>CONTRATO</strong>,
        bem como ao próprio software.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(C)</strong>os segredos
        comerciais, descobertas, ideias, conceitos, figuras, desenhos,
        especificações, as obras de autoria, invenções, know-how, técnicas,
        programas de softwares, software-fonte, aplicativos, websites, entre
        outros, além de documentos e respectivos manuais.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(D)</strong>as informações
        relativas à investigação, desenvolvimento, as novas ofertas de serviços
        e produtos, marketing e vendas, planos de negócios, estratégias
        empresariais, dos negócios e operações, clientes, fornecedores e dos
        clientes, listas, parcerias, orçamentos e demonstrações financeiras,
        regime de licenciamento e de distribuição, preços e custos
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(E)</strong>a existência de
        quaisquer negócios, discussões, negociações ou acordos entre as{" "}
        <strong>CONTRATANTES</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(F)</strong>todas as informações
        sobre as habilidades e compensações dos funcionários, contratados ou
        outros agentes das <strong>CONTRATANTES</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>13.4</strong>As{" "}
        <strong>CONTRATANTE</strong>S receptoras das informações confidenciais
        utilizarão o mais alto grau de cuidado para protegê-las, assegurando,
        inclusive, que aqueles funcionários ou empregados que dispõem de acesso
        a esta informação não a divulguem.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>13.5</strong>As{" "}
        <strong>CONTRATANTE</strong>S concordam que qualquer informação
        confidencial da outra <strong>CONTRATANTE</strong>, durante a vigência e
        ao término deste <strong>CONTRATO</strong> serão tratados com o mesmo
        cuidado que ambas utilizam para proteger suas próprias informações
        confidenciais.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>13.6</strong>As{" "}
        <strong>CONTRATANTE</strong>S concordam que, não obstante o contido no
        item 14.2 desta cláusula, nenhuma cláusula deste{" "}
        <strong>CONTRATO</strong> impedirá a <strong>CONTRATANTE</strong>{" "}
        receptora da informação confidencial de divulgá-la em cumprimento as
        exigências legais de um órgão público; ou por força de lei, caso em que
        a <strong>CONTRATANTE</strong> receptora deverá:
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(A)</strong>notificar de forma
        antecipada, imediatamente e por escrito, a <strong>CONTRATANTE</strong>{" "}
        reveladora sobre a existência desta exigência legal.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>(B)</strong>cooperar
        inteiramente com a <strong>CONTRATANTE</strong> reveladora no sentido de
        proteger a informação confidencial contra qualquer divulgação e/ou
        promover um pedido de proteção e sigilo legal no caso de sua veiculação.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>13.7</strong>As{" "}
        <strong>CONTRATANTES</strong> apenas poderão tratar os dados dos
        residentes com prévio consentimento e autorização destes ou de seus
        responsáveis, por escrito, sendo que seus dados serão tratados com
        atenção a boa fé e aos princípios da finalidade, adequação, necessidade,
        livre acesso, qualidade dos dados, transparência, segurança, prevenção,
        não discriminação, responsabilização e prestação de contas.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>13.8</strong>As{" "}
        <strong>CONTRATANTES</strong>, neste ato, firmam o compromisso de
        transparência, privacidade e segurança dos dados dos usuários durante
        todo o processo de interação com o software portal{" "}
        <strong>SalutemPlus</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>13.9</strong>Os pacientes/
        residentes das <strong>LICENCIADAS</strong> obterão através do software
        portal <strong>SalutemPlus</strong> informação acerca das atividades e
        operações realizadas com seus dados pessoais, especificamente com
        relação a sua “coleta, produção, recepção, classificação, utilização,
        acesso, reprodução, transmissão, distribuição, processamento,
        arquivamento, armazenamento, avaliação ou controle da informação,
        modificação, comunicação, transferência, difusão ou extração”.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA DÉCIMA QUARTA – AUTONOMIA DAS CONTRATANTES
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>14.</strong>Este{" "}
        <strong>CONTRATO</strong> não importa a formação de sociedade, nem mesmo
        sociedade de fato, permanecendo distintas e inconfundíveis as
        personalidades jurídicas e os patrimônios das{" "}
        <strong>CONTRATANTES</strong> e de seus respectivos sócios/acionistas.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>14.1</strong>Este{" "}
        <strong>CONTRATO</strong> não cria qualquer vínculo empregatício ou
        societário entre as <strong>CONTRATANTES</strong>, tampouco de
        solidariedade, sendo certo que as <strong>CONTRATANTES</strong>,
        reciprocamente, se isentam de toda e qualquer responsabilidade com
        relação ao pagamento de encargos trabalhistas, fiscais, previdenciários,
        civis, comerciais ou outros de quaisquer espécies que venham a se
        sujeitar por força do desenvolvimento de suas atividades econômicas
        empresariais.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>14.2</strong>Cada{" "}
        <strong>CONTRATANTE</strong> será responsável pelo pagamento de todos os
        impostos, taxas, contribuições e demais tributos relativos ao
        desenvolvimento de suas atividades econômicas empresariais, sejam estes
        decorrentes de relações jurídicas de natureza comerciais, civis,
        trabalhistas, sindicais, entre outras inerentes as suas respectivas
        atividades, com exclusão de qualquer responsabilidade pela outra{" "}
        <strong>CONTRATANTE</strong> por tais obrigações.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>14.3</strong>A{" "}
        <strong>LICENCIADA</strong> é a única responsável pelo
        pagamento/rateio/remuneração/pró-labore dos seus sócios, empregados,
        agentes, colaboradores e/ou prepostos utilizados na consecução do objeto
        deste <strong>CONTRATO</strong>, inexistindo por parte da{" "}
        <strong>LICENCIANTE</strong> qualquer relação de pessoalidade ou vínculo
        de qualquer natureza desta com aqueles.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA DÉCIMA QUINTA – DISPOSIÇÕES GERAIS
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>15.</strong>Quaisquer alterações
        no presente <strong>CONTRATO</strong> somente terão validade e eficácia
        se realizadas através de aditivo contratual celebrado por ambas as{" "}
        <strong>CONTRATANTES</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>15.1</strong>Este{" "}
        <strong>CONTRATO</strong> não poderá ser cedido, nem ter seus direitos
        e/ou obrigações transferidas pela <strong>LICENCIADA</strong>, mesmo que
        parcialmente, sem prévio e expresso consentimento da{" "}
        <strong>LICENCIANTE</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>15.2</strong>As{" "}
        <strong>CONTRATANTES</strong> concordam que os avisos, comunicações e/ou
        notificações relacionadas a este <strong>CONTRATO</strong> serão
        efetuados por escrito, facultando sua eleição por carta registrada, via
        cartorária ou judiciária, e-mail e/ou web site.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>15.3</strong>As{" "}
        <strong>CONTRATANTES</strong> declaram não existir em vigor nenhum{" "}
        <strong>contrato</strong>, obrigação, gravame, ônus ou restrição que
        lhes impeçam de cumprirem as obrigações assumidas neste{" "}
        <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>15.4</strong>As{" "}
        <strong>CONTRATANTES</strong> ajustam que, em razão do regime jurídico
        de tributação que se encontram enquadradas, fica autorizada àquela
        CONTRATANTE responsável pelo recolhimento dos tributos a promover sua
        retenção na forma exigida por lei, regulamento ou regras de usos e
        costumes, quando eventualmente devidos em virtude das contraprestações
        financeiras realizadas à <strong>LICENCIANTE</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>15.5</strong>As{" "}
        <strong>CONTRATANTES</strong> fornecerão uma à outra todo e qualquer
        documento que possibilite a redução ou isenção dos tributos incidentes
        nos negócios jurídicos objeto deste <strong>CONTRATO</strong>.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>15.6</strong>As{" "}
        <strong>CONTRANTES</strong> pactuam que a <strong>LICENCIADA</strong>{" "}
        não poderá contratar empregados, agentes, prepostos e prestadores de
        serviços da <strong>LICENCIANTE</strong> que efetivamente se envolverem
        com o objeto deste <strong>CONTRATO</strong>, vedação esta que
        permanecerá vigente pelo prazo de 60 (sessenta) meses após seu termo.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>15.7</strong>As{" "}
        <strong>CONTRATANTES</strong> entendem que, caso qualquer ou mais de um
        dispositivo deste <strong>CONTRATO</strong> seja considerado nulo ou
        inexigível em seu aspecto, essa nulidade ou inexigibilidade não afetará
        seus demais dispositivos, sendo este <strong>CONTRATO</strong>{" "}
        interpretado como se aquele dispositivo nulo ou inexigível jamais nele
        estivesse contido.
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>15.8</strong>As{" "}
        <strong>CONTRANTENTES</strong> ajustam que as eventuais omissões
        decorrentes da regulamentação por este <strong>CONTRATO</strong> dos
        negócios jurídicos por este instrumentalizado serão objeto de resolução
        em comum acordo, mediante reunião das partes para tal finalidade e
        formalizadas através de aditivo contratual.
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          CLÁUSULA DÉCIMA SEXTA – DA SUCESSÃO E DO FORO
        </strong>
      </p>
      <p>
        <strong style={{ marginRight: "1rem" }}>16.</strong>As{" "}
        <strong>CONTRATANTES</strong> obrigam-se por si, seus sucessores e
        cessionários, a qualquer título, a cumprir este{" "}
        <strong>CONTRATO</strong> em sua íntegra, elegendo, com renúncia
        expressa a qualquer outro, por mais privilegiado que seja, o Foro de
        Belo Horizonte, Minas Gerais, para dirimir as questões dele oriundas.
      </p>
    </div>
  );
}
