import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import api from "services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = {
  HeaderTable: {
    fontSize: 16
  }
};
class TableHistory extends Component {
  state = {
    record: []
  };

  componentDidMount() {
    api
      .post(`/microservice/consult/getHistoryPerson/`, {
        user: this.props.patient
      })
      .then(res => {
        this.setState({ record: res.data.record });
      })
      .catch(() => {});
  }

  render() {
    const { record } = this.state;
    return record ? (
      <div
        className="col-12"
        style={{
          minWidth: "650",
          overflowX: "auto"
        }}
      >
        <Table className="table-history">
          <TableHead>
            <TableRow>
              <TableCell>Doenças</TableCell>
              <TableCell align="left">Observações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.map(row => (
              <TableRow key={row.id_personal_history}>
                <TableCell component="th" scope="row">
                  {row.alias_description
                    ? row.alias_description
                    : row.description}
                </TableCell>
                <TableCell align="left">
                  {row.additional_considerations
                    ? row.additional_considerations
                    : " --- "}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    ) : (
      <p
        style={{
          textAlign: "center",
          color: "#a6a6a6",
          fontStyle: "italic"
        }}
      >
        Nenhum Registro Encontrado
      </p>
    );
  }
}

TableHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TableHistory);
