import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardNurse from "./routes/typeUsers/professional/Nurse";
import DashboardDoctor from "./routes/typeUsers/professional/Doctor";
import DashboardProfessionalPrefecture from "./routes/typeUsers/professional/Prefecture";
import DashboardPrestador from "./routes/typeUsers/prestador";
import DashboardResidential from "./routes/typeUsers/prestador/Residential";
import DashboardHomeCare from "./routes/typeUsers/prestador/HomeCare";
import DashboardPrefecture from "./routes/typeUsers/prestador/Prefecture";
import DashboardAcademic from "./routes/typeUsers/prestador/Academic";
import DashboardPatient from "./routes/typeUsers/patient";
import DashboardSecretary from "./routes/typeUsers/secretary/index";
import DashboardStudent from "./routes/typeUsers/student/index";
import CircularProgress from "components/CircularProgress";
import { pedirPermissaoParaReceberNotificacoes } from "services/firebase";
import { wrongBrowser } from "actions/Alerts";
import Api from "services/api";

function Dashboard({ match, history }) {
  const dispatch = useDispatch();
  const type = useSelector((state) => state.auth.type);
  const provider_type = useSelector((state) => state.auth.provider_type);
  const address_selected = useSelector((state) => state.auth.address_selected);
  const area = useSelector((state) => state.auth.area);
  const secretary = useSelector((state) => state.auth.secretary);
  const auth = useSelector((state) => state.auth.authUser);

  const managers = useSelector((state) => state.auth.managers);
  const loaded_managers = useSelector((state) => state.auth.loaded_managers);

  React.useEffect(() => {
    if (!auth) {
      history.push("/login");
    } else {
      pedirPermissaoParaReceberNotificacoes(auth);
      dispatch(wrongBrowser(navigator.userAgent.indexOf("Edg") > -1));

      //Verifica se usuário é gestor de algum provider
      if (!loaded_managers) {
        Api.post("ms_system/user/verifyUserManager", { auth })
          .then((res) => {
            if (res.data.record.length > 0) {
              dispatch({
                type: "SET_MANAGERS_AUTH",
                payload: {
                  managers: res.data.record,
                },
              });
            }
          })
          .catch(() => {
            dispatch({
              type: "SET_MANAGERS_AUTH",
              payload: {
                managers: [],
              },
            });
          });
      }
    }
  }, []);

  if (!type && !area && !loaded_managers) {
    return <CircularProgress />;
  } else if (secretary) {
    return <DashboardSecretary />;
  } else if (managers.length > 0 && managers[0].provider_type_id === "56") {
    return <DashboardPrefecture match={match} user={managers[0].provider} />;
  } else if (type === "0") {
    if (address_selected.provider_type === "56") {
      return (
        <DashboardProfessionalPrefecture match={match} history={history} />
      );
    } else {
      switch (area) {
        case "5":
        case "6":
        case "29":
          return <DashboardNurse />;
        default:
          if (
            address_selected.provider_type === "5" ||
            address_selected.provider_type === "55"
          ) {
            return <DashboardNurse />;
          } else {
            return <DashboardDoctor match={match} history={history} />;
          }
      }
    }
  } else if (type === "1") {
    if (provider_type === "5") {
      return <DashboardResidential match={match} />;
    } else if (provider_type === "53") {
      return <DashboardAcademic />;
    } else if (provider_type === "55") {
      return <DashboardHomeCare />;
    } else if (provider_type === "56") {
      return <DashboardPrefecture match={match} user={auth} />;
    } else {
      return <DashboardPrestador match={match} />;
    }
  } else if (type === "2") {
    return <DashboardStudent history={history} />;
  } else {
    return <DashboardPatient match={match} history={history} />;
  }
}

export default Dashboard;
