import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import Api from "services/api";
import CloseIcon from "@material-ui/icons/Close";

export default function ViewIndexPlate({
  onClose,
  indexPlate,
  percent,
  description
}) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    Api.post(`/microservice/od_indexplate/getViewIndexPlate`, {
      index: indexPlate
    })
      .then(res => {
        setData(res.data.record);
      })
      .catch(() => {
        dispatch({
          type: "VIEW_ALERT_MESSAGE",
          message_type: "error",
          text_message: "Ocorreu um erro ao buscar os dados."
        });
        setTimeout(() => dispatch({ type: "CLOSE_ALERT_MESSAGE" }), 5000);
      });
  }, []);
  return (
    <div className="row">
      <div
        className="col-12"
        style={{
          minWidth: "650",
          overflowX: "auto"
        }}
      >
        <div
          className="d-flex"
          style={{
            margin: 5,
            padding: 5,
            fontSize: "0.875rem"
          }}
        >
          <strong>
            <span className="mr-4">Total: {percent} %</span>
            <span>Resultado: {description}</span>
          </strong>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="20%">Dente</TableCell>
              <TableCell>Distal</TableCell>
              <TableCell>Vestibular</TableCell>
              <TableCell>Mesial</TableCell>
              <TableCell>Lingual-Palatina</TableCell>
              <TableCell>Observação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <TableRow key={row.id}>
                <TableCell width="20%">Dente {row.code}</TableCell>

                <TableCell>
                  {row.face_4 === "1" ? (
                    <i
                      className="zmdi zmdi-check zmdi-hc-2x"
                      style={{ color: "green" }}
                    ></i>
                  ) : (
                    <CloseIcon style={{ color: "#a7a7a7" }} />
                  )}
                </TableCell>
                <TableCell>
                  {row.face_3 === "1" ? (
                    <i
                      className="zmdi zmdi-check zmdi-hc-2x"
                      style={{ color: "green" }}
                    ></i>
                  ) : (
                    <CloseIcon style={{ color: "#a7a7a7" }} />
                  )}
                </TableCell>
                <TableCell>
                  {row.face_2 === "1" ? (
                    <i
                      className="zmdi zmdi-check zmdi-hc-2x"
                      style={{ color: "green" }}
                    ></i>
                  ) : (
                    <CloseIcon style={{ color: "#a7a7a7" }} />
                  )}
                </TableCell>
                <TableCell>
                  {row.face_1 === "1" ? (
                    <i
                      className="zmdi zmdi-check zmdi-hc-2x"
                      style={{ color: "green" }}
                    ></i>
                  ) : (
                    <CloseIcon style={{ color: "#a7a7a7" }} />
                  )}
                </TableCell>
                <TableCell>{row.observation}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {onClose !== undefined && (
          <div>
            <Button
              onClick={() => onClose()}
              color="primary"
              aria-label="Voltar"
            >
              <i className="zmdi zmdi-undo" /> Voltar
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
