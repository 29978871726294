import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAppointment,
  setDataAppointment,
  setContinue,
  getAppointmentActions,
} from "actions/consult/Appointment";
import { Card, CardBody } from "reactstrap";
import {
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import NoRecord from "components/Alert/NoRecord";
import Api from "services/api";
import moment from "moment";
import ComponentsProfile from "components/ComponentsProfile";
import ViewRehabilitation from "app/routes/consult/routes/fisio/rehabilitation/ViewRehabilitation";

export default function Sessions({ history, area }) {
  const [record, setRecord] = useState([]);
  const [modalView, setModalView] = useState({ open: false, data: {} });
  const user = useSelector((state) => state.auth.authUser);
  const dispatch = useDispatch();

  useEffect(() => {
    Api.post(`/microservice/fisio/getSessionsDoctorDay`, {
      user,
    })
      .then((res) => {
        setRecord(res.data.record);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <div className="col-12 m-0 p-0 animated slideInUpTiny animation-duration-4">
        <Card className="shadow border-0 mb-3">
          <CardBody style={{ paddingBottom: 0, marginBottom: 0 }}>
            <div className="row">
              <div className="col-12">
                <Typography
                  className="d-flex justify-content-start"
                  variant="h6"
                  gutterBottom
                >
                  Sessões do dia
                </Typography>
              </div>
              <div
                className="col-12"
                style={{
                  minWidth: "650",
                  overflowX: "auto",
                }}
              >
                {record.length === 0 ? (
                  <NoRecord />
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Horário</TableCell>
                        <TableCell>Paciente</TableCell>
                        <TableCell>N° da Sessão</TableCell>
                        {/* <TableCell>Reabilitação</TableCell> */}
                        <TableCell align="right">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {moment(row.date).format("HH:mm")}
                          </TableCell>
                          <TableCell>
                            <ComponentsProfile
                              nameProfile={row.patient_name}
                              subTitle={row.birthday}
                              photo={row.photo}
                              id={row.patient}
                            />
                          </TableCell>
                          <TableCell>{row.index}°</TableCell>
                          {/* <TableCell>{row.name}</TableCell> */}
                          <TableCell align="right">
                            <div className="d-flex">
                              {!row.fk_ls_pp_appointment ? (
                                <Tooltip
                                  title="Iniciar atendimento"
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={() => {
                                      dispatch(
                                        setDataAppointment(
                                          row.patient,
                                          "",
                                          false,
                                          false
                                        )
                                      );
                                      if (row.fk_ls_pp_appointment) {
                                        dispatch(
                                          setAppointment(
                                            row.fk_ls_pp_appointment
                                          )
                                        );
                                        dispatch(setContinue(true));
                                        dispatch(
                                          getAppointmentActions(
                                            row.fk_ls_pp_appointment
                                          )
                                        );
                                      }
                                      history.push(
                                        `/app/consult/${
                                          area === "9" ? "sessions" : "session"
                                        }/` + row.schedule
                                      );
                                    }}
                                    color="primary"
                                  >
                                    <i className="zmdi zmdi-sign-in text-primary" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Sessão registrada!"
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={() => {}}
                                    color="primary"
                                    style={{ cursor: "default" }}
                                  >
                                    <i className="zmdi zmdi-check text-primary" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip
                                title="Visualizar Reabilitação"
                                placement="top"
                              >
                                <IconButton
                                  onClick={() =>
                                    setModalView({ open: true, data: row })
                                  }
                                  color="primary"
                                  aria-label="Visualizar"
                                >
                                  <i className="zmdi zmdi-eye zmdi-hc-fw" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
        <ViewRehabilitation
          data={modalView.data}
          open={modalView.open}
          handleClose={() => setModalView({ open: false, data: {} })}
        />
      </div>
    </>
  );
}
