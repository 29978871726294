import React, { useEffect, useState } from "react";
import Api from "services/api";
import CircularProgress from "components/CircularProgress";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan, BtnSave, Btn } from "components/Button";
import { IconButton } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export default function PrescriptionSigned({ open, close, arrFiles }) {
  const [loading, setLoading] = useState(false);
  const [timeLineData, setTimeLineData] = useState([]);
  const [isValid, setIsValid] = useState();

  useEffect(() => {
    if (open) {
      setLoading(true);
      getValidateDigitalSignature();
    }
  }, [open]);

  const getValidateDigitalSignature = async () => {
    arrFiles.forEach(async (el) => {
      await Api.post(
        `/microservice/prescription/getValidateDigitalSignature/`,
        {
          url: el.url_file,
          fileName: el.file_name,
        }
      )
        .then((res) => {
          if (res.data.isValid) {
            setTimeLineData(res.data.record);
            setIsValid(res.data.isValid);
          } else {
            setTimeLineData(res.data.record);
            setIsValid(res.data.isValid);
            return false;
          }
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <div>
      <ModalResponsive
        title={"Validação de Assinatura Digital"}
        open={open}
        close={() => {
          close();
        }}
        maxWidth={"sm"}
        buttons={
          <div className="col-12">
            <>
              <BtnCan
                title="Fechar"
                onClick={() => {
                  close();
                }}
                float="left"
              />

              {/* <BtnSave title="Salvar" onClick={() => close()} float="right" /> */}
            </>
          </div>
        }
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <div
            className="container text-left"
            style={{ marginLeft: "-25px", marginRight: "-25px" }}
          >
            {/* <div class="card-body"> */}
            {isValid ? (
              <h3 className="text-dark font-weight-semibold">
                Assinatura é Válida{" "}
                <VerifiedUserIcon
                  style={{ color: green[500], marginBottom: "-7px" }}
                />
              </h3>
            ) : (
              <h3 className="text-dark font-weight-semibold">
                Assinatura não é Válida{" "}
                <IconButton color="secondary" aria-label="Invalida">
                  <VerifiedUserIcon />
                </IconButton>
              </h3>
            )}
            <hr />
            <h5 className="text-dark font-weight-semibold">
              {" "}
              <strong>Informações do assinante: </strong>{" "}
            </h5>
            <div className="col-12 my-2">
              <span>
                Nome da Assinatura:
                <br />
                <strong>{timeLineData.subject}</strong>
              </span>
            </div>
            <div className="col-12 my-2">
              <span>
                Email:
                <br />
                <strong>{timeLineData.email}</strong>
              </span>
            </div>
            <div className="col-12 my-2">
              <span>
                Data:
                <br />
                <strong>{timeLineData.signingTime}</strong>
              </span>
            </div>
            <hr />
            <h5 className="text-dark font-weight-semibold">
              <strong>ICP-Brasil: </strong>
            </h5>
            <div className="col-12 my-2">
              <span>
                Tipo de certificado:
                <br />
                <strong>{timeLineData.typecertificate}</strong>
              </span>
            </div>
            <div className="col-12 my-2">
              <span>
                {timeLineData.cnpj && "CNPJ:"}
                {timeLineData.cpf && "CPF:"}
                <br />
                {timeLineData.cpf && <strong>{timeLineData.cpf}</strong>}
                {timeLineData.cnpj && <strong>{timeLineData.cnpj}</strong>}
              </span>
            </div>
            <div className="col-12 my-2">
              <span>
                Responsável:
                <br />
                <strong>{timeLineData.responsible}</strong>
              </span>
            </div>
          </div>
        )}
      </ModalResponsive>
    </div>
  );
}
