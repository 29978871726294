import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Api from "services/api";
import { debounce, showMsg, getStringToSearch } from "services/functions";

import AutocompleteGeneric from "./AutocompleteGeneric";
import { makeStyles } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

const useStyles = makeStyles((theme) => ({
  imgType: {
    width: "30px",
    height: "30px",
  },
  imgTarget: {
    width: "30px",
    height: "30px",
    marginLeft: "15px",
  },
  nameDisplay: {
    marginLeft: "15px",
  },
  nameLab: {
    float: "right",
    paddingRight: "10px",
    fontWeight: "300",
  },
}));

export default function MultipleMedicine({ onChange, value, plusPosology }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector(({ auth }) => auth.authUser);
  const address_user = useSelector(({ auth }) => auth.address_selected.user_id);

  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);

  const getMedicines = async (term) => {
    if (term.length > 2) {
      setLoader(true);
      let url = "/microservice/autocomplete/getMedicineFarm/";

      try {
        const { data } = await Api.post(url, {
          term: getStringToSearch(term),
          user,
          address_user,
        });
        setOptions(data);
      } catch (err) {
        showMsg("error", "Não foi possível encontar os medicamentos", dispatch);
        setOptions([]);
      } finally {
        setLoader(false);
      }
    }
  };

  const getClassImage = (type) => {
    let imgIcon = "";
    switch (type) {
      case "Similar":
        imgIcon = require("assets/images/consult/similar.jpg");
        break;
      case "Generico":
        imgIcon = require("assets/images/consult/generico.jpg");
        break;
      case "Referencia":
      case "Especifico":
        imgIcon = require("assets/images/consult/referencia.jpg");
        break;
      case "Biológicos":
        imgIcon = require("assets/images/consult/biologico.jpg");
        break;
      default:
        imgIcon = require("assets/images/consult/outros.jpg");
        break;
    }
    return imgIcon;
  };

  const getTarge = (targe) => {
    if (targe === "TP") {
      return require("assets/images/consult/Preta.svg");
    } else if (targe === "TV") {
      return require("assets/images/consult/Vermelha.svg");
    } else {
      return require("assets/images/consult/Branca.svg");
    }
  };

  return (
    <AutocompleteGeneric
      limitTags={2}
      filterOptions={(x) => x}
      // variant={"outlined"}
      disableCloseOnSelect
      onChange={onChange}
      onInputChange={debounce(getMedicines)}
      value={value}
      getOptionLabel={(option) =>
        `${option.name_display} ${
          plusPosology ? "" : option.formulation ? option.formulation : ""
        }`
      }
      label={"Selecione o(s) medicamento(s)"}
      options={options}
      loading={loader}
      shrink={true}
      renderOption={(option, { inputValue }) => {
        let imgIcon = getClassImage(option.medtype_display);
        let targe = getTarge(option.targe);

        return (
          <div className="w-100">
            <span style={{ float: "left" }}>
              <img
                src={imgIcon}
                alt="Tipo do medicamento"
                className={classes.imgType}
              />
              <img
                src={targe}
                alt="Tarja do medicamento"
                className={classes.imgTarget}
              />
              <strong className={classes.nameDisplay}>
                {option.active_principle} ({option.name_display}){" "}
                {option.formulation}
              </strong>{" "}
              {option.form} - <small>{option.package}</small>
            </span>

            {option.id_goods && (
              <Chip
                variant="outlined"
                className="ml-3"
                size="small"
                label={`Disponível`}
                icon={<HomeWorkIcon />}
              />
            )}
            <span className={classes.nameLab}>{option.laboratory_display}</span>
          </div>
        );
      }}
    />
  );
}
