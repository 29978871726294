import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setConversation } from "actions/comunication/Chat";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import {
  selectedUserChat,
  setSelectedChat,
  resetChat,
} from "actions/comunication/Chat";
import {
  hideChatModal,
  setMinimaze,
  modalVideo,
} from "actions/comunication/Chat";
import apiFile from "services/apiFile";
import imageCompression from "browser-image-compression";

import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import Conversation from "components/chatPanel/Conversation/index";
import CustomScrollbars from "util/CustomScrollbars";
import Moment from "moment";
import BarProgress from "components/CircularProgress/ProgressBar";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ModalAttachment from "./ModalComunication";
import MinimizeIcon from "@material-ui/icons/Minimize";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import VideocamIcon from "@material-ui/icons/Videocam";
import { BtnSave } from "components/Button";
import { BtnCan } from "components/Button";
import moment from "moment";
import Videos from "../video";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  imgBox: {
    backgroundColor: "#fff",
    position: "absolute",
    height: "calc(100vh - 120px)",
    paddingTop: "15px",
    transform: "none",
    width: "71%",
    [theme.breakpoints.down("md")]: {
      width: "-webkit-fill-available",
      paddingTop: "25px",
    },
  },
  textArea: {
    height: "40px",
    oBoxShadow: "none",
    borderRadius: "0",
    borderBottom: "2px solid !important",
    border: "0",
    outline: "none",
    boxShadow: "none !important",
    width: "100%",
  },
  ScrollCursor: {
    cursor: "all-scroll",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function Comunication({
  onToggleDrawer,
  modal = false,
  selectedUser,
  match,
}) {
  const classes = useStyles();
  const userAuth = useSelector(({ auth }) => auth.authUser);
  const user = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const width = useSelector(({ settings }) => settings.width);
  const [message, setMessage] = useState("");
  const conversation = useSelector(
    ({ chat }) => chat.conversation[selectedUser.chat_id]
  );
  const [loader, setLoader] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [limit, setLimit] = useState(25);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mediaRecord, setMediaRecord] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [sendAudio, setSendAudio] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [attachment, setAttachment] = useState({
    modal: false,
    type: null,
  });
  const [confirmationVideo, setConfirmationVideo] = useState(false);
  const [contactSelected, setContactSelected] = useState({});
  const [viewVideo, setViewVideo] = useState(false);

  useEffect(() => {
    setLoader(true);
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/${selectedUser.chat_id}`)
      .limitToLast(limit)
      .once("value", (snap) => {
        dispatch({
          type: "SET_NEW_CONVERSATION_CHAT",
          payload: { conversation: snap.val(), id: selectedUser.chat_id },
        });
        // setConversation(snap.val());
        setLoader(false);
      });
  }, [selectedUser.chat_id]);

  useEffect(() => {
    if (audioBlob !== null && audioBlob.size > 0 && sendAudio) {
      let file = new File([audioBlob], "audio.wav");
      let formData = new FormData();
      formData.append(`files-0`, file);
      formData.append("id", userAuth);
      apiFile
        .post(`/ms_comunication/chat/uploadImages`, formData)
        .then(({ data }) => {
          if (data.success) {
            data.urls.forEach((url) => {
              let keyNotify = sendNotify({
                user_from: userAuth,
                user_to: selectedUser.id,
                date: Moment().format("DD/MM/YYYY HH:mm:ss"),
                title: `Nova mensagem de ${user.name}`,
                type: "chat",
                action: {
                  ...selectedUser,
                  id: userAuth,
                  name: user.name,
                  thumb: user.photo,
                },
                image: null,
                icon: "zmdi-comment-alt-text",
                read: false,
              });
              getDataFirebase()
                .child(
                  `chats${getEnviromentFirebase()}/${selectedUser.chat_id}`
                )
                .push({
                  from: userAuth,
                  message: url,
                  sentAt: Moment().format("DD/MM/YYYY HH:mm:ss"),
                  read: false,
                  type: "audio",
                  pathNotify: `${selectedUser.id}/${keyNotify}`,
                });
            });
          }
        })
        .catch(() => {
          dispatch(
            viewAlertMessage("error", "Não foi possível enviar a mensagem!")
          );
          setTimeout(() => {
            dispatch(closeAlertMessage());
          }, 5000);
        });
      setSendAudio(false);
    }
    return () => {
      setAudioBlob(null);
    };
  }, [audioBlob]);

  getDataFirebase()
    .child(`chats${getEnviromentFirebase()}/`)
    .on("child_changed", (snap) => {
      dispatch(setConversation(selectedUser.chat_id));
    });
  !conversation &&
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/`)
      .once("child_added", (snap) => {
        dispatch(setConversation(selectedUser.chat_id));
      });

  const _handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      submitComment();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitComment = () => {
    if (message !== "") {
      let keyNotify = sendNotify({
        user_from: userAuth,
        user_to: selectedUser.id,
        date: Moment().format("DD/MM/YYYY HH:mm:ss"),
        title: `Nova mensagem de ${user.name}`,
        type: "chat",
        action: {
          ...selectedUser,
          id: userAuth,
          name: user.name,
          thumb: user.photo,
        },
        image: null,
        icon: "zmdi-comment-alt-text",
        read: false,
      });
      getDataFirebase()
        .child(`chats${getEnviromentFirebase()}/${selectedUser.chat_id}`)
        .push({
          from: userAuth,
          message,
          sentAt: Moment().format("DD/MM/YYYY HH:mm:ss"),
          read: false,
          type: "text",
          pathNotify: `${selectedUser.id}/${keyNotify}`,
        });
      setTimeout(() => {
        setMessage("");
      }, 100);
    }
  };

  const handleScroller = (scroller) => {
    if (scroller.scrollHeight - scroller.scrollTop > 800) {
      setScrollToBottom(true);
    } else {
      setScrollToBottom(false);
    }
    if (scroller.scrollTop < 1) {
      let scrollBiforeRender = scroller.scrollHeight;
      setLoadMore(true);
      getDataFirebase()
        .child(`chats${getEnviromentFirebase()}/${selectedUser.chat_id}`)
        .limitToLast(limit + 25)
        .once("value", (snap) => {
          setTimeout(() => {
            scroller.scrollTop = scroller.scrollHeight - scrollBiforeRender;
          }, 100);
          dispatch({
            type: "SET_NEW_CONVERSATION_CHAT",
            payload: { conversation: snap.val(), id: selectedUser.chat_id },
          });
          setLoadMore(false);
          setLimit(limit + 25);
        });
    }
  };

  const toggleAttachments = (type = null) =>
    setAttachment({ modal: !attachment.modal, type });

  const submitAttachments = ({ files, type }) => {
    setLoader(true);
    let formData = new FormData();
    files.forEach((file, index) => {
      var options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(file, options).then(function(compressedFile) {
        formData.append(`files-${index}`, compressedFile);
        formData.append("id", userAuth);
        apiFile
          .post(`/ms_comunication/chat/uploadImages`, formData)
          .then(({ data }) => {
            if (data.success) {
              setLoader(false);
              data.urls.forEach((url) => {
                let keyNotify = sendNotify({
                  user_from: userAuth,
                  user_to: selectedUser.id,
                  date: Moment().format("DD/MM/YYYY HH:mm:ss"),
                  title: `Nova mensagem de ${user.name}`,
                  type: "chat",
                  action: {
                    ...selectedUser,
                    id: userAuth,
                    name: user.name,
                    thumb: user.photo,
                  },
                  image: null,
                  icon: "zmdi-comment-alt-text",
                  read: false,
                });
                getDataFirebase()
                  .child(
                    `chats${getEnviromentFirebase()}/${selectedUser.chat_id}`
                  )
                  .push({
                    from: userAuth,
                    message: url,
                    sentAt: Moment().format("DD/MM/YYYY HH:mm:ss"),
                    read: false,
                    type,
                    pathNotify: `${selectedUser.id}/${keyNotify}`,
                  });
              });
            }
          })
          .catch(() => {
            dispatch(
              viewAlertMessage("error", "Não foi possível enviar a mensagem!")
            );
            setTimeout(() => {
              dispatch(closeAlertMessage());
            }, 5000);
          });
      });
    });
  };

  const startRecordAudio = async () => {
    if (mediaRecord === null) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      await mediaRecorder.start();
      setMediaRecord(mediaRecorder);
    } else {
      await mediaRecord.start();
    }
  };

  mediaRecord !== null &&
    mediaRecord.addEventListener("dataavailable", (event) => {
      setAudioChunks([...audioChunks, event.data]);
    });

  mediaRecord !== null &&
    mediaRecord.addEventListener("stop", () => {
      const audioBlob = new Blob(audioChunks, {
        type: "audio/wav; codecs=MS_PCM",
      });
      setAudioBlob(audioBlob);
      setMediaRecord(null);
      setAudioChunks([]);
    });

  const sendNotify = (notify) => {
    const newNotify = getDataFirebase()
      .child(`notification${getEnviromentFirebase()}/${notify.user_to}`)
      .push(notify);
    return newNotify.key;
  };

  const startVideo = () => {
    if (width < 900) {
      setViewVideo(true);
      setContactSelected(selectedUser);
      setConfirmationVideo(false);
    } else {
      setConfirmationVideo(false);
      getDataFirebase()
        .child(`notification${getEnviromentFirebase()}/` + selectedUser.id)
        .push({
          user_from: user.authUser,
          user_to: selectedUser.id,
          date: moment().format("DD/MM/YYYY HH:mm"),
          title: `Iniciar videochamada com ${user.name}`,
          type: "videoconference",
          // action: `comunication/video?room=${selectedUser.chat_id}`,
          action: selectedUser.chat_id,
          image: user.photo,
          icon: "zmdi-comment-video",
          read: false,
        });
      dispatch(modalVideo(selectedUser.chat_id));
      dispatch(setMinimaze(selectedUser.chat_id));
    }
  };

  return (
    <div className="chat-main">
      {!viewVideo ? (
        <>
          <div className={`chat-main-header mt-3 px-3 ${modal && "p-2"}`}>
            <IconButton
              aria-label="delete"
              className={classes.margin}
              size="small"
              onClick={() => dispatch(resetChat())}
            >
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            {/* {!modal && (
          <IconButton
            className="d-block d-xl-none chat-btn"
            aria-label="Menu"
            onClick={onToggleDrawer}
          >
            <i className="zmdi zmdi-comment-text" />
          </IconButton>
        )} */}
            <div
              className={`handler d-flex chat-main-header-info ${modal &&
                classes.ScrollCursor}`}
            >
              <div className="chat-avatar mr-2">
                <div className="chat-avatar-mode">
                  <img
                    src={selectedUser.thumb}
                    className={`rounded-circle ${
                      modal ? "size-40" : "size-60"
                    }`}
                    alt=""
                  />

                  <span className={`chat-mode ${selectedUser.status}`} />
                </div>
              </div>

              <div
                className="chat-contact-name"
                style={{ fontSize: modal ? "14px" : "20px" }}
              >
                {width >= 500
                  ? selectedUser.name
                  : selectedUser.name.substring(0, 15) + "..."}
              </div>
            </div>
            <Tooltip title="Iniciar videochamada" placement="top">
              <IconButton
                style={{ padding: "0" }}
                onClick={() => setConfirmationVideo(true)}
              >
                {/* <VideocamIcon style={{ fontSize: "xx-large" }} /> */}
                <i className="zmdi zmdi-comment-video zmdi-fw" />
              </IconButton>
            </Tooltip>
            {modal && (
              <>
                <IconButton
                  style={{ padding: "0" }}
                  // color="secondary"
                  onClick={() => dispatch(setMinimaze(selectedUser.chat_id))}
                >
                  <MinimizeIcon />
                </IconButton>
                <IconButton
                  style={{ padding: "0" }}
                  color="secondary"
                  onClick={() => dispatch(hideChatModal(selectedUser.chat_id))}
                >
                  <HighlightOffIcon />
                </IconButton>
              </>
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  toggleAttachments("img");
                }}
              >
                <i className="zmdi zmdi-image mr-1" /> Imagens
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  toggleAttachments("camera");
                }}
              >
                <i className="zmdi zmdi-camera mr-1" /> Câmera
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  toggleAttachments("archive");
                }}
              >
                <i className="zmdi zmdi-file-plus mr-1" /> Arquivos
              </MenuItem>
            </Menu>
          </div>

          <CustomScrollbars
            // className="chat-list-scroll scrollbar"
            style={{
              height:
                width >= 1200
                  ? modal
                    ? "272px"
                    : "calc(100vh - 300px)"
                  : modal
                  ? "272px"
                  : "calc(86vh - 194px)",
              backgroundColor: "#f8f8f8",
            }}
            onScroll={(e) => handleScroller(e.currentTarget)}
          >
            {loader ? (
              <div
                className="loader-view w-100"
                style={{ height: modal ? "unset" : "calc(100vh - 120px)" }}
              >
                <BarProgress />
              </div>
            ) : (
              <Conversation
                toBottom={scrollToBottom}
                loader={loadMore}
                conversationData={conversation ? conversation : []}
                selectedUser={selectedUser}
              />
            )}
          </CustomScrollbars>

          <div className={`chat-main-footer ${modal && "p-2"}`}>
            <div
              className="d-flex flex-row align-items-center"
              style={{ maxHeight: 51 }}
            >
              <IconButton style={{ padding: "5px" }} onClick={handleClick}>
                <AttachFileIcon />
              </IconButton>
              <div className={`col ${modal && "pr-1 pl-1"}`}>
                <div className="form-group">
                  {width >= 1200 ? (
                    <textarea
                      id="required"
                      className="border-0 form-control chat-textarea"
                      onKeyDown={(e) => _handleKeyPress(e)}
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      placeholder="Digite uma mensagem"
                    />
                  ) : (
                    <TextField
                      id="outlined-full-width"
                      label=""
                      className="border-0 form-control chat-textarea"
                      style={{ margin: 8 }}
                      onChange={(e) => setMessage(e.target.value)}
                      fullWidth
                      margin="normal"
                      value={message}
                      placeholder="Digite uma mensagem"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  )}
                </div>
              </div>
              <div className="chat-sent">
                {!message ? (
                  mediaRecord !== null && mediaRecord.status !== "inactive" ? (
                    <>
                      <IconButton
                        onClick={() => mediaRecord.stop()}
                        aria-label="Send message"
                        color="secondary"
                      >
                        <i className="zmdi zmdi-close" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          mediaRecord.stop();
                          setSendAudio(true);
                        }}
                        aria-label="Send message"
                        color="primary"
                      >
                        <i className="zmdi zmdi-mic" />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      onClick={startRecordAudio}
                      aria-label="Send message"
                    >
                      <i className="zmdi zmdi-mic" />
                    </IconButton>
                  )
                ) : (
                  <IconButton onClick={submitComment} aria-label="Send message">
                    <i className="zmdi  zmdi-mail-send" />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Videos
          match={match}
          selected={contactSelected}
          handleClose={() => setViewVideo(false)}
        />
      )}

      <ModalAttachment
        open={attachment.modal}
        handleClose={toggleAttachments}
        type={attachment.type}
        handleSuccess={(e) => submitAttachments(e)}
      />

      <Dialog
        onClose={() => setConfirmationVideo(false)}
        aria-labelledby="customized-dialog-title"
        open={confirmationVideo}
      >
        <DialogContent className="d-flex justify-content-center ">
          <div
            className="col-12"
            style={{
              margin: width < 900 ? "0px" : "20px",
              display: "grid",
              justifyItems: "center",
            }}
          >
            <VideocamIcon
              color="primary"
              style={{
                padding: "3px",
                fontSize: "70px",
                borderRadius: "36px",
                border: "5px solid #177493",
              }}
            />
            <Typography variant="h6" gutterBottom>
              Iniciar Videochamada?
            </Typography>
            <span style={{ fontSize: "smalle", color: "#a6a6a6" }}>
              com {selectedUser.name && selectedUser.name}
            </span>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "space-between",
            margin: "8px",
          }}
        >
          <BtnCan
            title={"fechar"}
            onClick={() => setConfirmationVideo(false)}
            float="left"
          />
          <BtnSave
            title={"Iniciar Videochamada"}
            onClick={() => startVideo()}
            float="right"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
