import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  IconButton,
  Typography,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { getUrlPhoto } from "services/functions";
import BarProgress from "components/CircularProgress/ProgressBar";
import ViewEvent from "./ViewEvent";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import PhonelinkOffIcon from "@material-ui/icons/PhonelinkOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import moment from "moment";
import Api from "services/api";
import "../styles.css";
import Backdrop from "@material-ui/core/Backdrop";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { makeStyles } from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import ColorizeIcon from "@material-ui/icons/Colorize";
import ModalAdd from "./ModalAdd";
import SweetAlert from "react-bootstrap-sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import ComponentCalendar from "./Calendar";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  speedDial: {
    margin: 0,
    top: "auto",
    right: 20,
    left: "auto",
    position: "fixed",
    zIndex: 999,
    bottom: 10,
    [theme.breakpoints.down("sm")]: {
      bottom: 80,
    },
  },
}));

export default function CalendarPatient() {
  const dispatch = useDispatch();

  const [modalView, setModalView] = useState({ open: false, event: "" });
  const [state, setState] = useState({});
  const [loader, setLoader] = useState(true);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    type: "",
    title: "",
  });
  const width = useSelector((state) => state.settings.width);
  const user = useSelector((state) => state.auth.authUser);
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    id: "",
    time: "",
    type: "",
    value: "",
  });
  const save = useSelector((state) => state.prescription.save_medicine);

  const actions = [
    { id: "event", icon: <EventIcon />, name: "Evento" },
    { id: "pause", icon: <AccessAlarmIcon />, name: "Pausa Programada" },
    { id: "activity", icon: <FitnessCenterIcon />, name: "Atividade Física" },
    { id: "medicines", icon: <ColorizeIcon />, name: "Medicamento" },
  ];

  const classes = useStyles();

  useEffect(() => {
    getData();
  }, [date]);

  useEffect(() => {
    // Usado para carregar os medicamentos após salvar no ModalAdd
    if (save) {
      setLoader(true);
      getData();
    }
  }, [save]);

  useEffect(() => {
    dispatch({
      type: "SET_ROUTE",
      payload: "calendar-patient",
    });
  }, []);

  const getData = () => {
    Api.post("/ms_system/dashboard/getCompromissesPatient", { user, date })
      .then((res) => {
        let aux = {};

        if (
          res.data.compromisses.length === 0 &&
          res.data.medicines.length === 0 &&
          res.data.events.length === 0 &&
          res.data.activities.length === 0 &&
          res.data.pauses.length === 0
        ) {
          setState(null);
        } else {
          res.data.medicines.forEach((element) => {
            if (typeof aux[element.hour] !== "undefined") {
              aux[element.hour] = [
                ...aux[element.hour],
                { ...element, type: "1" },
              ];
            } else {
              aux[element.hour] = [{ ...element, type: "1" }];
            }
          });

          res.data.pauses.forEach((element) => {
            if (typeof aux[element.time] !== "undefined") {
              aux[element.time] = [
                ...aux[element.time],
                { ...element, type: "0" },
              ];
            } else {
              aux[element.time] = [{ ...element, type: "0" }];
            }
          });

          res.data.activities.forEach((element) => {
            if (typeof aux[element.time] !== "undefined") {
              aux[element.time] = [
                ...aux[element.time],
                { ...element, type: "2" },
              ];
            } else {
              aux[element.time] = [{ ...element, type: "2" }];
            }
          });

          res.data.events.forEach((element) => {
            if (typeof aux[element.hour] !== "undefined") {
              aux[element.hour] = [
                ...aux[element.hour],
                { ...element, type: "3" },
              ];
            } else {
              aux[element.hour] = [{ ...element, type: "3" }];
            }
          });

          res.data.compromisses.forEach((element) => {
            if (typeof aux[element.hour] !== "undefined") {
              aux[element.hour] = [
                ...aux[element.hour],
                { ...element, type: "3" },
              ];
            } else {
              aux[element.hour] = [{ ...element, type: "4" }];
            }
          });
          setState(aux);
        }
      })
      .finally(() => setLoader(false));
  };

  const getIcon = (icon) => {
    switch (icon) {
      case "4662":
        return <LocalDrinkIcon />;
      case "4657":
        return <EmojiPeopleIcon />;
      case "4659":
        return <DirectionsWalkIcon />;
      case "4660":
        return <PhonelinkOffIcon />;
      case "4658":
        return <VisibilityIcon />;
      case "4675":
        return <RestaurantIcon />;
      default:
        return <AccessibilityIcon />;
    }
  };

  const getContent = (obj) => {
    if (obj.type === "1") {
      return (
        <>
          <Avatar
            style={{
              backgroundColor:
                obj.realized === "1"
                  ? "#177493"
                  : obj.realized === "0"
                  ? "#000"
                  : "",
              color: "white",
              width: "30px",
              height: "30px",
            }}
          >
            <ColorizeIcon />
          </Avatar>
          <Typography variant="subtitle2" className="ml-2 mr-3" gutterBottom>
            {obj.name_display} {obj.dosage} {obj.form_pharm}
          </Typography>
        </>
      );
    } else if (obj.type === "3") {
      return (
        <>
          <Avatar
            alt={obj.name}
            src={getUrlPhoto(obj.doctor, obj.photo)}
            style={{
              width: "30px",
              height: "30px",
            }}
          />

          <Typography variant="subtitle2" className="ml-2 mr-3" gutterBottom>
            Consulta - {obj.name}
          </Typography>
        </>
      );
    } else if (obj.type === "4") {
      return (
        <>
          <Avatar
            alt="Compromisso"
            style={{
              width: "30px",
              height: "30px",
            }}
          >
            <EventIcon />
          </Avatar>

          <Typography variant="subtitle2" className="ml-2 mr-3" gutterBottom>
            {obj.title}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Avatar
            style={{
              backgroundColor:
                obj.realized === "1"
                  ? "#177493"
                  : obj.realized === "0"
                  ? "#000"
                  : "",
              color: "white",
              width: "30px",
              height: "30px",
            }}
          >
            {getIcon(obj.fk_ls_table_type)}
          </Avatar>
          <Typography variant="subtitle2" className="ml-2 mr-3" gutterBottom>
            {obj.name}
          </Typography>
        </>
      );
    }
  };

  const handleChecked = () => {
    setState({
      ...state,
      [alert.time]: state[alert.time].map((row) =>
        row.id === alert.id ? { ...row, realized: alert.value } : row
      ),
    });

    Api.post(`/microservice/patientportal/registerAlarm/`, {
      id: alert.id,
      value: alert.value,
      type: alert.type,
    });
    setAlert({
      open: false,
      title: "",
      id: "",
      time: "",
      type: "",
      value: "",
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = (type, title) => {
    setModal({
      open: true,
      type,
      title,
    });
  };

  const handleSetDate = (value) => {
    setDate(moment(value).format("YYYY-MM-DD"));
  };

  return (
    <div className="container text-left mx-2" style={{ maxWidth: "100%" }}>
      <div className={width < 960 ? "row mb-2 W-100" : "row col-12 mb-2 W-100"}>
        <Backdrop open={open} style={{ zIndex: 999 }} />
        <div className="col-12">
          <Typography className="pl-3" variant="h6" gutterBottom>
            Compromissos
          </Typography>
        </div>
        {loader ? (
          <div className="col-12 d-flex justify-content-center">
            <BarProgress />
          </div>
        ) : (
          <div className="w-100 d-flex pl-0 pr-0">
            {width >= 960 && (
              <div className="col-6">
                <ComponentCalendar setDate={handleSetDate} date={date} />
              </div>
            )}
            <div
              className={
                width < 960
                  ? "card pl-3 pr-3 pb-3 w-100 d-flex"
                  : "col-6 card pl-3 pr-3 pb-3 justify-content-center"
              }
            >
              <div className="row text-left">
                {width < 960 && (
                  <>
                    <div className="col-12 mt-3">
                      <TextField
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        label="Data:"
                        fullWidth
                      />
                      <hr className="w-100" />
                    </div>
                  </>
                )}

                {state ? (
                  Object.keys(state)
                    .sort((a, b) => {
                      if (
                        moment(`2020-01-01 ${a}`).isBefore(
                          moment(`2020-01-01 ${b}`)
                        )
                      ) {
                        return -1;
                      }
                      if (
                        !moment(`2020-01-01 ${a}`).isBefore(
                          moment(`2020-01-01 ${b}`)
                        )
                      ) {
                        return 1;
                      }
                      // a must be equal to b
                      return 0;
                    })

                    .map((row) => (
                      <>
                        <div className="col-12 mt-3">
                          <strong>{row}</strong>
                        </div>
                        {state[row].map((el) => (
                          <div
                            className="col-12 d-flex ml-3 mt-1"
                            style={{
                              justifyContent:
                                el.type !== "4" &&
                                moment().isAfter(moment(el.date))
                                  ? "space-between"
                                  : "",
                            }}
                          >
                            {getContent(el)}
                            {el.type !== "4" &&
                              moment().isAfter(moment(el.date)) && (
                                <div
                                  className="mr-3"
                                  style={{ display: "inherit" }}
                                >
                                  {el.type !== "3" ? (
                                    <>
                                      <Tooltip
                                        title="Realizei :)"
                                        placement="top"
                                      >
                                        <DoneAllIcon
                                          color={
                                            el.realized !== "0"
                                              ? "primary"
                                              : "default"
                                          }
                                          onClick={
                                            () => {
                                              setAlert({
                                                open: true,
                                                title:
                                                  el.type === "1"
                                                    ? `${el.name_display} ${el.dosage} ${el.form_pharm}`
                                                    : el.name,
                                                id: el.id,
                                                time: row,
                                                type: el.type,
                                                value: "1",
                                              });
                                            }
                                            // handleChecked(el.id, "1", el.type, row)
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title="Não realizei :("
                                        placement="top"
                                      >
                                        <ClearIcon
                                          color={
                                            el.realized !== "1"
                                              ? "secondary"
                                              : "default"
                                          }
                                          className="ml-3"
                                          onClick={() => {
                                            setAlert({
                                              open: true,
                                              title:
                                                el.type === "1"
                                                  ? `${el.name_display} ${el.dosage} ${el.form_pharm}`
                                                  : el.name,
                                              id: el.id,
                                              time: row,
                                              type: el.type,
                                              value: "0",
                                            });
                                            // handleChecked(el.id, "0", el.type, row)
                                          }}
                                        />
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        setModalView({
                                          open: true,
                                          event: el,
                                        });
                                      }}
                                      style={{ float: "right" }}
                                    >
                                      <i className="zmdi zmdi-eye zmdi-fw" />
                                    </IconButton>
                                  )}
                                </div>
                              )}
                          </div>
                        ))}
                      </>
                    ))
                ) : (
                  <div className="col-12 d-flex justify-content-center">
                    <EventBusyIcon
                      fontSize="small"
                      style={{ marginRight: "3px" }}
                    />
                    <span style={{ fontStyle: "italic" }}>
                      Nenhum Compromisso Nesta Data
                    </span>
                  </div>
                )}
              </div>
            </div>

            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              hidden={false}
              icon={<EditIcon />}
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
              className={classes.speedDial}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  onClick={() => handleAdd(action.id, action.name)}
                />
              ))}
            </SpeedDial>
          </div>
        )}
        <div className="col-md-12" style={{ marginTop: "100px" }}></div>
        <ViewEvent
          open={modalView.open}
          event={modalView.event}
          close={() => setModalView({ open: false, event: "" })}
        />
        <ModalAdd
          patient={user}
          open={modal.open}
          type={modal.type}
          title={modal.title}
          handleClose={() => setModal({ open: false, type: "", title: "" })}
          beforeSave={() => {
            if (modal.type !== "medicines") {
              setLoader(true);
              getData();
            }
          }}
        />
        <SweetAlert
          show={alert.open}
          warning
          showCancel
          confirmBtnBsStyle="default"
          cancelBtnBsStyle="danger"
          confirmBtnText="Sim, confirmo"
          cancelButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title={
            <div>
              {alert.title}
              <br />
              Realizado: <strong>{alert.value === "1" ? "Sim" : "Não"}</strong>
            </div>
          }
          onConfirm={() => handleChecked()}
          onCancel={() =>
            setAlert({
              open: false,
              title: "",
              id: "",
              time: "",
              type: "",
              value: "",
            })
          }
        />
      </div>
    </div>
  );
}
