import React from "react";

import { Tooltip } from "@material-ui/core/";
import HotelIcon from "@material-ui/icons/Hotel";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import Decubito_Dorsal from "assets/images/prescriptioncare/Decubito_Dorsal.jpg";
import Decubito_Lateral_Direito from "assets/images/prescriptioncare/Decubito_Lateral_Direito.jpg";
import Decubito_Lateral_Esquerdo from "assets/images/prescriptioncare/Decubito_Lateral_Esquerdo.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("sm")]: { marginTop: "-35px" }
  },
  title: {
    fontSize: "26px",
    color: "#000"
  },
  border: {
    borderRight: "1px solid #dcdcdc"
  }
}));
export default function ViewDecubits({ data, is_pattern, justInfo }) {
  const classes = useStyles();
  const hours = data.hours.split(",");
  const decubits = data.decubits.split(",");
  const getImgDecubit = decubit => {
    switch (decubit) {
      case "Dorsal":
        return (
          <img
            alt="imagem do sistema"
            className="img-fluid"
            src={Decubito_Dorsal}
          />
        );
      case "Lateral Direito":
        return (
          <img
            alt="imagem do sistema"
            className="img-fluid"
            src={Decubito_Lateral_Direito}
          />
        );
      case "Lateral Esquerdo":
        return (
          <img
            alt="imagem do sistema"
            className="img-fluid"
            src={Decubito_Lateral_Esquerdo}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className={`col-12 p-0 ${classes.root}`}>
      {!justInfo && (
        <div className={`col-12 d-flex align-items-center ${classes.title}`}>
          <Tooltip placement="top" title={"Mudança de Decúbito"}>
            <HotelIcon style={{ color: "#575757" }} />
          </Tooltip>
          <span className="pl-2">{data.name}</span>
        </div>
      )}
      <div className={`row p-3`}>
        <div className={`col-12`}>
          <span>Horários</span>
          <br />
          <div className={`pl-2`}>
            <div className="row">
              {hours.map((row, index) => (
                <React.Fragment key={index}>
                  <div className="col-2 pt-3 text-center">{row}</div>
                  <div className="col-3">{getImgDecubit(decubits[index])}</div>
                  <div
                    className={`col-1 ${index % 2 === 0 && classes.border}`}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={`row p-3`}>
        <div className={`col-4`}>
          <span>Data Inicial</span>
          <br />
          <span className={`pl-2`}>
            {moment(data.start).format("DD/MM/YYYY")}
          </span>
        </div>
        {data.end && !is_pattern && (
          <div className={`col-4`}>
            <span>Data Final</span>
            <br />
            <span className={`pl-2`}>
              {moment(data.end).format("DD/MM/YYYY")}
            </span>
          </div>
        )}
      </div>
      {data.observation && (
        <div className={`row p-3`}>
          <div className={`col-12`}>
            <span>Observações</span>
            <br />
            <span className={`pl-2`}>{data.observation}</span>
          </div>
        </div>
      )}
    </div>
  );
}
