import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IntlMessages from "util/IntlMessages";
import Professional from "./professional";
import Student from "./student";
import { userSignInSuccess } from "actions/Auth";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide
} from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import "./style.scss";

class ModalChoose extends Component {
  state = {
    open: false
  };

  render() {
    return (
      <>
        <Button
          className="no-arrow"
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          {this.props.icon}
          <span className="nav-text">{this.props.title}</span>
        </Button>
        <div className="animated slideInUpTiny animation-duration-3">
          <Dialog
            fullWidth
            maxWidth="sm"
            open={this.state.open}
            onClose={() => {
              this.setState({ open: false });
            }}
            aria-labelledby="max-width-dialog-title"
            scroll="body"
          >
            <DialogContent>
              <div className="row">
                <div className="col-12">
                  <h2 className="text-primary font-weight-semibold">
                    <IntlMessages id="module.select_patient" />
                    <Tooltip title="Fechar" placement="top">
                      <IconButton
                        aria-label="Fechar"
                        onClick={() => {
                          this.setState({ open: false });
                        }}
                        style={{ float: "right", marginTop: "-10px" }}
                      >
                        <i className="zmdi zmdi-close" />
                      </IconButton>
                    </Tooltip>
                  </h2>
                </div>
                <div className="col-12">
                  {/* {this.props.type === "0" ? ( */}
                  <Professional
                    url={this.props.url}
                    onClose={() => {
                      this.setState({ open: false });
                    }}
                  />
                  {/* ) : (
                    <Student history={this.props.url} />
                  )} */}
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  }
}

// const mapStateToProps = state => ({
//   type: state.auth.type,
//   auth: state.auth.authUser
// });

// const mapDispatchToProps = dispatch =>
//   bindActionCreators({ userSignInSuccess }, dispatch);

export default ModalChoose;
// export default connect(mapStateToProps, mapDispatchToProps)(ModalChoose);
