import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPersonalData } from "actions/consult/PersonalData";
import { Card, CardBody } from "reactstrap";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import BasicData from "./basicData/index";
import AdditionalData from "./additionalData/index";
import HealthPlan from "./healthPlan";
import Childrens from "./childrens";
import PersonAddress from "./personAddress";
import EmergencyContact from "./emergencyContact";
import CircularProgress from "components/CircularProgress";
import "./styles.css";

function PersonalData({ match, patient, history }) {
  const loaded = useSelector((state) => state.personalData.loaded);
  const address = useSelector((state) => state.auth.address);
  const dispatch = useDispatch();

  useEffect(() => {
    if (patient) {
      dispatch(getPersonalData(patient, address));
    }
  }, [patient]);

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        {match ? (
          <ContainerHeader
            title={<IntlMessages id="module.consult.personal_data" />}
            match={{
              ...match,
              path: "/app/consult/personal-data",
              url: "/app/consult/personal-data",
            }}
          />
        ) : (
          ""
        )}
        <Card className="border">
          <CardBody>
            <BasicData patient={patient} />
          </CardBody>
        </Card>
        <Card className="border">
          <CardBody>
            <AdditionalData patient={patient} />
          </CardBody>
        </Card>
        <Card className="border">
          <CardBody>
            <HealthPlan patient={patient} />
          </CardBody>
        </Card>
        <Card className="border">
          <CardBody>
            <PersonAddress patient={patient} />
          </CardBody>
        </Card>
        <Card className="border">
          <CardBody>
            <EmergencyContact patient={patient} />
          </CardBody>
        </Card>
        <Card className="border">
          <CardBody>
            <Childrens history={true} patient={patient} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default PersonalData;
