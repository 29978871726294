import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  removeMedicine,
  handleChange,
  newMedicine,
  cancelMedicine,
  alterMode,
  savePrescription,
  cancelMedicineInUse,
  renewMedicine,
} from "actions/consult/Prescription";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { viewHistory } from "actions/consult/Appointment";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Switch,
  Divider,
} from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import SweetAlert from "react-bootstrap-sweetalert";
import PlusPosology from "./PlusPosology";
import FormMedicine from "./FormMedicine";
import NoRecord from "components/Alert/NoRecord";
import HistoryIcon from "@material-ui/icons/History";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CustomDropzone from "components/CustomDropzoneTranscription";
import BarProgress from "components/CircularProgress/ProgressBar";
import { Tooltip } from "@material-ui/core";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import ComponentToPrintt from "app/routes/consult/routes/print";
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import Api from "services/api";

const SpeechRecognition =
  window.SpeechRecognition ||
  window.webkitSpeechRecognition ||
  window.mozSpeechRecognition ||
  window.msSpeechRecognition ||
  window.oSpeechRecognition;

const recognition =
  !SpeechRecognition || navigator.userAgent.indexOf("Edg") > -1
    ? null
    : new SpeechRecognition();
if (recognition !== null) recognition.lang = "pt-BR";
class FormPrescription extends Component {
  state = {
    modal_delete: false,
    medicine_id: "",
    error: false,
    loading_file: false,
    confirm_transcription: false,
    file: "",
    use_start: moment().format("MM-DD-YYYY"),
    imgsFile: null,
    medicine_in_use: false,
    medicines_allergics: false,
    in_use_state: [],
    modalPlusPosology: false,
    detailPosology: {},
    viewPosology: false,
    listen: false,
  };

  alertDeleteMedicine(medicine) {
    this.setState({ modal_delete: true, medicine_id: medicine });
  }

  onCancelDelete = () => {
    this.setState({ modal_delete: false, medicine_id: "" });
  };

  removeMedicine = () => {
    this.props.removeMedicine(this.state.medicine_id);
    this.setState({ modal_delete: false, medicine_id: "" });
    this.props.viewAlertMessage("success", "Medicamento removido!");
    setTimeout(() => this.props.closeAlertMessage(), 5000);
  };

  saveFileTemp = (arr_files) => {
    this.setState({ file: arr_files[0] });
    this.setState({ imgsFile: arr_files });
  };

  toggleListen = () => {
    this.setState({ listen: !this.state.listen }, this.handleLinsten);
  };

  handleLinsten = () => {
    if (this.state.listen) {
      recognition.start();
      recognition.onend = () => {
        recognition.start();
      };
    } else {
      recognition.abort();
      recognition.onend = () => {
        recognition.stop();
      };
    }

    recognition.onresult = (event) => {
      let speech = this.props.orientation;
      speech += " ";
      speech += event.results[0][0].transcript;
      this.props.handleChange("orientation", speech);
    };
  };

  savePrescription = async () => {
    const {
      appointment_id,
      appointment_admission,
      start,
      triage,
      patient,
      patient_admission,
      name_doctor,
      crm_doctor,
      transcription,
      medicineUse,
      prof_resp,
      doctor,
      address,
      prescription_id,
      pattern_id,
      pattern,
      name,
      orientation,
      medicines,
      form,
    } = this.props;

    let nameRecipe = name;
    let isTranscription = transcription;
    if (medicineUse) {
      nameRecipe = "MEDICAMENTOS EM USO";
      isTranscription = false;
    }
    if (nameRecipe && medicines.length > 0) {
      const obj = {
        appointment_id:
          form === "admission" ? appointment_admission : appointment_id,
        start: start ? start : moment().format("YYYY-MM-DD"),
        triage,
        patient: form === "admission" ? patient_admission : patient,
        transcription: isTranscription,
        medicineUse,
        form,
        file: this.state.file,
        saved_id: this.props.saved_id,
        saved: this.props.saved_prescription,
        use_start: this.state.use_start,
        prof_resp,
        doctor,
        address,
        data: {
          prescription_id,
          name_doctor,
          crm_doctor,
          id_transcription:
            this.props.file !== undefined && this.props.file !== null
              ? this.props.file.id_transcription
              : null,
          pattern_id,
          pattern,
          name: nameRecipe,
          orientation,
          medicines,
        },
      };
      await this.props.savePrescription(obj);
      if (medicineUse) {
        this.props.handleClose("save");
      }
    } else {
      this.setState({ error: true });
      if (medicines.length === 0) {
        this.props.viewAlertMessage("error", "Nenhum medicamento prescrito");
        setTimeout(() => this.props.closeAlertMessage(), 5000);
      }
    }
  };

  stopUseMedicine = (id, medicine) => {
    Api.post(`/microservice/consult/stopUseMedicine/`, {
      medicine: id,
      date: moment().format("YYYY-MM-DD"),
      user: this.props.doctor,
    }).then(() => {
      this.props.cancelMedicineInUse(medicine);
    });
  };

  existMedicineInUse() {
    this.setState({ medicine_in_use: false });
    if (this.props.in_use) {
      this.props.in_use.forEach((row) => {
        let info = this.props.medicines.find(
          (med) => med.ls_mp_medicine === row.ls_mp_medicine
        );
        if (info !== undefined) {
          this.setState({
            medicine_in_use: true,
            in_use_state: this.props.in_use,
          });
        }
      });
    }
  }

  renewMedicine(medicine, i) {
    let date =
      this.state.in_use_state[i].date_start !== undefined
        ? this.state.in_use_state[i].date_start
        : moment(this.state.in_use_state[i].use_end)
            .add(1, "d")
            .format("MM-DD-YYYY");

    this.props.renewMedicine(medicine, date, this.props.medicines);
    let aux = this.state.in_use_state.filter(
      (e) => e.ls_mp_medicine !== medicine
    );
    this.setState({
      in_use_state: aux,
    });

    let infook = false;
    aux.forEach((row) => {
      let info = this.props.medicines.find(
        (med) => med.ls_mp_medicine === row.ls_mp_medicine
      );

      if (info !== undefined) {
        infook = true;
      }
    });
    this.setState({
      medicine_in_use: infook,
    });
  }

  handleChangeDate = (name, value) => {
    this.setState({ [name]: value });
  };

  existMedicineAllergic() {
    this.setState({ medicines_allergics: false });
    if (this.props.in_allergic) {
      this.props.in_allergic.forEach((row) => {
        let info = this.props.medicines.find(
          (med) => med.ls_mp_medicine === row.id
        );
        if (info !== undefined) {
          this.setState({ medicines_allergics: true });
        }
      });
    }
  }

  modalPlusPosology = () => {
    this.setState({ modalPlusPosology: true });
  };

  mountPosology = (medicine, posology) => {
    let ret = "";
    let compositions = [];

    posology.map((pos) => {
      compositions = pos.detail.filter(
        (e) => e.dosage !== posology[0].detail[0].dosage
      );
    });

    if (compositions.length > 0) {
      this.setState({
        detailPosology: (
          <div className="row">
            <div className="col-md-12">
              <span style={{ fontSize: "18px", fontWeight: "600" }}>
                {medicine}
              </span>
            </div>
            {posology.map((pos, c) => {
              return (
                <>
                  <div className="col-md-12">
                    <span>
                      {c !== 0 ? "Depois tomar " : "Tomar "}
                      {this.transcriptionInterval(pos.interval, pos.days_week) +
                        pos.xdia +
                        " vezes ao dia, durante " +
                        pos.duration +
                        " dias"}
                    </span>
                  </div>
                  <div className="col-md-12">
                    <ul>
                      {pos.detail.map((e, i) => {
                        return (
                          <li className="ml-2">
                            <span>
                              {this.getDisplayQtd(e.qtd) +
                                " " +
                                medicine +
                                " " +
                                e.dosage +
                                " " +
                                e.when.toLowerCase() +
                                " (" +
                                e.time +
                                ")"}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              );
            })}
          </div>
        ),
      });
    } else {
      this.setState({
        detailPosology: (
          <div className="row">
            <div className="col-md-12">
              <span style={{ fontSize: "18px", fontWeight: "600" }}>
                {medicine + " " + posology[0].detail[0].dosage}
              </span>
            </div>
            {posology.map((pos, c) => {
              return (
                <>
                  <div className="col-md-12">
                    <span>
                      {c !== 0 ? "depois tomar " : "tomar "}
                      {this.transcriptionInterval(pos.interval, pos.days_week) +
                        pos.xdia +
                        " vezes ao dia, durante " +
                        pos.duration +
                        " dias"}
                    </span>
                  </div>
                  <div className="col-md-12">
                    {pos.detail.map((e, i) => {
                      return (
                        <span>
                          {i !== 0 ? ", " : ""}
                          {this.getDisplayQtd(e.qtd) +
                            " " +
                            e.when.toLowerCase() +
                            " às " +
                            e.time +
                            "hs"}
                        </span>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        ),
      });
    }

    this.setState({ viewPosology: true });
  };

  getDisplayQtd = (value) => {
    let aux = value.split(".");
    if (aux[1] == "00") {
      return aux[0];
    } else {
      if (aux[1] == "25") {
        return `${aux[0]} e 1/4`;
      }
      if (aux[1] == "50") {
        return `${aux[0]} e 1/2`;
      }
      if (aux[1] == "75") {
        return `${aux[0]} e 3/4`;
      }
    }
  };

  transcriptionInterval = (interval, days_week = null) => {
    if (interval === "Diário") {
      return "diariamente, ";
    } else if (interval === "Dia sim, dia não") {
      return "dia sim, dia não, ";
    } else if (interval === "Semanal") {
      return "1x por semana, ";
    } else if (interval === "Mensal") {
      return "1x por mês, ";
    } else if (interval === "2x por semana") {
      return "2x por semana, ";
    } else if (interval === "15 em 15 dias") {
      return "de 15 em 15 dias, ";
    } else if (interval === "Sempre") {
      return " , ";
    } else if (interval === "Anual") {
      return "1x por ano, ";
    } else if (interval === "3 x por semana") {
      return "3x por semana, ";
    } else if (interval === "trimestral") {
      return "1x a cada trimestre, ";
    } else if (interval === "Dias específicos") {
      return "(" + days_week.toString() + ") ";
    } else {
      return "";
    }
  };

  componentWillMount() {
    this.existMedicineInUse();
    this.existMedicineAllergic();
  }

  render() {
    const {
      medicines,
      name,
      name_doctor,
      crm_doctor,
      file,
      orientation,
      new_medicine,
      handleChange,
      viewHistory,
      saved,
      pattern,
      prescription_id,
      transcription,
      medicineUse,
      in_use,
      in_allergic,
      LoadSavePrescription,
    } = this.props;

    return (
      <div className="row">
        {LoadSavePrescription ? (
          <div className="loader-view w-100">
            <BarProgress />
          </div>
        ) : (
          <>
            {!medicineUse && (
              <div className="col-md-12">
                <h3 className="text-primary font-weight-semibold">
                  {transcription ? "Dados da transcrição" : "Nova prescrição"}
                  <IconButton
                    color="primary"
                    onClick={() => viewHistory("prescription")}
                  >
                    <HistoryIcon />
                  </IconButton>
                </h3>
              </div>
            )}

            {!medicineUse && (
              <div className={transcription ? "col-12" : "col-md-9"}>
                <TextField
                  id="name"
                  label={
                    transcription
                      ? "Nome da transcrição"
                      : "Nome da prescrição:"
                  }
                  value={name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  fullWidth
                  margin="normal"
                  autoFocus={true}
                  style={{ marginTop: "0px" }}
                  error={this.state.error && !name ? true : false}
                />
              </div>
            )}
            {/* {medicineUse && (
              <>
                <div className="col-md-4 col-12">
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={brLocale}
                  >
                    <KeyboardDatePicker
                      label="Início:"
                      value={this.state.use_start}
                      onChange={(e) =>
                        this.setState({
                          use_start: moment(e).format("MM-DD-YYYY"),
                        })
                      }
                      format="dd/MM/yyyy"
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </>
            )} */}

            {!transcription && !medicineUse && (
              <div className="col-md-3">
                <span>Salvar como padrão: </span>
                <Switch
                  id="switch-pattern"
                  classes={{
                    checked: "text-primary",
                  }}
                  color="primary"
                  checked={pattern}
                  onChange={(e) => handleChange("pattern", e.target.checked)}
                  aria-label="checked"
                />
              </div>
            )}

            {transcription && !medicineUse && (
              <>
                <div className="col-md-8">
                  <TextField
                    id="name_doctor"
                    label="Nome do Médico:"
                    value={name_doctor}
                    onChange={(e) =>
                      handleChange("name_doctor", e.target.value)
                    }
                    fullWidth
                    margin="normal"
                    style={{ marginTop: "0px" }}
                    error={this.state.error && !name_doctor ? true : false}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    id="crm_doctor"
                    label="CRM do médico:"
                    type="number"
                    value={crm_doctor}
                    onChange={(e) => handleChange("crm_doctor", e.target.value)}
                    fullWidth
                    margin="normal"
                    style={{ marginTop: "0px" }}
                    error={this.state.error && !crm_doctor ? true : false}
                  />
                </div>
              </>
            )}

            {!medicineUse && (
              <div className="col-md-12">
                <TextField
                  id="orientation"
                  label="Orientação:"
                  multiline={true}
                  rows={2}
                  value={orientation}
                  onChange={(e) => handleChange("orientation", e.target.value)}
                  fullWidth
                  margin="normal"
                  // variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {/* SPEECH TO TEXT */}
                {recognition && (
                  <IconButton
                    title="falar"
                    color={this.state.listen ? "secondary" : "primary"}
                    style={{
                      position: "absolute",
                      right: "20px",
                      width: "5%",
                      marginTop: "30px",
                    }}
                    onClick={this.toggleListen}
                  >
                    {this.state.listen ? <MicOffIcon /> : <MicIcon />}
                  </IconButton>
                )}
              </div>
            )}

            <div className="col-md-12" style={{ marginTop: "10px" }}>
              <Button
                onClick={() => this.props.newMedicine()}
                className="jr-btn jr-btn-sm"
                color="primary"
                variant="contained"
                style={{ float: "right" }}
              >
                <i className="zmdi zmdi-plus" />{" "}
                <span>
                  {transcription
                    ? "Dados do Medicamento"
                    : "Adicionar medicamento"}
                </span>
              </Button>
              <Table className="table-medicines">
                <TableHead>
                  <TableRow>
                    <TableCell>Medicamento</TableCell>
                    <TableCell align="left">Orientação</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medicines.length > 0 ? (
                    medicines.map((row) => (
                      <TableRow key={row.ls_mp_medicine}>
                        <TableCell component="th" scope="row">
                          <div>{row.name_display}</div>
                          {row.posology === undefined ? (
                            <span style={{ color: "#aaa" }}>{row.detail}</span>
                          ) : (
                            <a
                              style={{ color: "#177493", cursor: "pointer" }}
                              onClick={() =>
                                this.mountPosology(
                                  row.name_display,
                                  row.posology
                                )
                              }
                            >
                              Posologia avançada
                            </a>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {row.orientation !== "0" ? row.orientation : ""}
                        </TableCell>
                        <TableCell align="right">
                          {!row.complete && (
                            <Tooltip
                              placement="top"
                              title="Completar informações"
                            >
                              <IconButton
                                onClick={() => {
                                  this.props.newMedicine(row.ls_mp_medicine, {
                                    ...row,
                                    date_start: row.date_start
                                      ? new Date(`${row.date_start}T00:00:00`)
                                      : new Date(),
                                  });
                                }}
                                color="secondary"
                                aria-label="Completar"
                              >
                                <AssignmentLateIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {row.complete && (
                            <Tooltip placement="top" title="Editar medicamento">
                              <IconButton
                                onClick={() => {
                                  this.props.newMedicine(row.ls_mp_medicine, {
                                    ...row,
                                    date_start: row.date_start
                                      ? new Date(`${row.date_start}T00:00:00`)
                                      : new Date(),
                                  });
                                }}
                                color="primary"
                                aria-label="Editar"
                              >
                                <i className="zmdi zmdi-edit zmdi-hc-fw" />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip placement="top" title="Remover medicamento">
                            <IconButton
                              onClick={() =>
                                this.alertDeleteMedicine(row.ls_mp_medicine)
                              }
                              color="secondary"
                              aria-label="Remover"
                            >
                              <i className="zmdi zmdi-delete zmdi-hc-fw" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow key="1">
                      <TableCell colSpan={3}>
                        <NoRecord />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>

            {transcription && !medicineUse && (
              <div className="col-12">
                {this.state.loading_file ? (
                  <>
                    <div className="col-12 text-center">
                      <BarProgress />
                    </div>
                    <div className="col-12 text-center">
                      <p
                        style={{
                          textAlign: "center",
                          color: "#a6a6a6",
                          fontStyle: "italic",
                        }}
                      >
                        Carregando Arquivo...
                      </p>
                    </div>
                  </>
                ) : (
                  <CustomDropzone
                    returnAcceptedFiles={(arr) => this.saveFileTemp(arr)}
                    max_files={1}
                    extensions="image/*"
                    files={file}
                    patient={this.props.patient}
                  />
                )}
              </div>
            )}
          </>
        )}
        <div className="col-md-12" style={{ marginTop: "20px" }}>
          <Button
            color="secondary"
            variant="contained"
            disabled={LoadSavePrescription}
            className="jr-btn jr-btn-sm"
            onClick={() => {
              if (!medicineUse) {
                this.props.alterMode(false);
              } else {
                this.props.handleClose("cancel");
              }
            }}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>

          <Button
            color="primary"
            className="jr-btn jr-btn-sm bg-success"
            disabled={LoadSavePrescription}
            onClick={() => {
              if (transcription && !medicineUse) {
                if (medicines.length > 0) {
                  this.setState({ confirm_transcription: true });
                } else {
                  this.setState({ error: true });
                  this.props.viewAlertMessage(
                    "error",
                    "Nenhum medicamento prescrito"
                  );
                  setTimeout(() => this.props.closeAlertMessage(), 5000);
                }
              } else {
                if (medicines.filter((row) => !row.complete).length > 0) {
                  this.props.viewAlertMessage(
                    "error",
                    "Complete as informações de todos os medicamentos"
                  );
                  setTimeout(() => this.props.closeAlertMessage(), 5000);
                } else {
                  this.savePrescription();
                }
              }
            }}
            variant="contained"
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>

          {saved && (
            <div>
              <ComponentToPrintt
                dados={{ name: "prescription", id: prescription_id }}
              />
            </div>
          )}

          <Dialog
            // fullScreen={medicineUse}
            fullWidth={true}
            maxWidth="lg"
            open={new_medicine}
            onClose={() => this.props.cancelMedicine()}
          >
            <DialogContent>
              <FormMedicine
                transcription={transcription}
                medicineUse={medicineUse}
                modalPlusPosology={() => this.modalPlusPosology()}
              />
            </DialogContent>
          </Dialog>

          <PlusPosology
            openModal={this.state.modalPlusPosology}
            closeModal={() => {
              this.setState({ modalPlusPosology: false });
            }}
            patient={this.props.patient}
          />

          {/* Remover Medicamento */}
          <SweetAlert
            show={this.state.modal_delete}
            warning
            showCancel
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            confirmBtnText="Sim, remover"
            confirmButtonColor="#bc0003"
            cancelBtnText="Cancelar"
            title="Remover este medicamento?"
            onConfirm={() => this.removeMedicine()}
            onCancel={this.onCancelDelete}
          />

          {/* Confirmar dados */}
          <SweetAlert
            show={this.state.confirm_transcription}
            warning
            showCancel
            showConfirm
            title="Confirma os dados desta transcrição?"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            confirmBtnText="Sim, confirmo"
            confirmButtonColor="#bc0003"
            cancelBtnText="Não, rever"
            onConfirm={() => {
              this.savePrescription();
              this.setState({ confirm_transcription: false });
            }}
            onCancel={() => this.setState({ confirm_transcription: false })}
          />

          {/* Medicamento já em uso pelo paciente */}
          <ModalResponsive
            title={"Atenção"} // titulo
            open={this.state.medicine_in_use}
            close={() => {
              this.setState({ medicine_in_use: false });
              if (!medicineUse) {
                this.props.alterMode(false);
              } else {
                this.props.handleClose("cancel");
              }
            }}
            maxWidth={"md"}
          >
            <div className="row">
              <div className="col-12">
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Medicamento(s) já está(ão) em uso para o paciente.
                </span>
              </div>
              {this.state.in_use_state.map((row, i) => {
                let info = medicines.find(
                  (med) => med.ls_mp_medicine === row.ls_mp_medicine
                );
                if (info !== undefined) {
                  return (
                    <>
                      <div className="col-12 mt-3">
                        <span style={{ fontWeight: "500" }}>
                          {info.name_display}{" "}
                        </span>
                        <span>
                          <br /> Período:{" "}
                          {moment(row.use_start, "YYYY-MM-DD", true).format(
                            "DD/MM/YYYY"
                          )}{" "}
                          -{" "}
                          {moment(row.use_end, "YYYY-MM-DD", true).format(
                            "DD/MM/YYYY"
                          )}
                        </span>

                        <div>
                          <span>
                            Renovar uso do medicamento a partir do dia:
                          </span>
                        </div>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={brLocale}
                        >
                          <KeyboardDatePicker
                            value={
                              row.date_start !== undefined
                                ? row.date_start
                                : moment(row.use_end)
                                    .add(1, "d")
                                    .format("MM-DD-YYYY")
                            }
                            onChange={(e) => {
                              let aux = this.state.in_use_state;
                              aux[i] = {
                                ...this.state.in_use_state[i],
                                date_start: moment(e).format("MM-DD-YYYY"),
                              };

                              this.setState({ in_use_state: aux });
                            }}
                            minDate={moment(row.use_end, "YYYY-MM-DD", true)
                              .add(1, "d")
                              .format("MM-DD-YYYY")}
                            format="dd/MM/yyyy"
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      <div className="col-4 mt-3">
                        <Button
                          variant="contained"
                          className="jr-btn jr-btn-sm bg-success"
                          style={{ color: "#fff" }}
                          fullWidth
                          onClick={() => {
                            this.renewMedicine(info.ls_mp_medicine, i);
                          }}
                        >
                          <span> Renovar </span>
                        </Button>
                      </div>
                      <div className="col-4 mt-3">
                        <Button
                          onClick={() =>
                            this.stopUseMedicine(row.id, row.ls_mp_medicine)
                          }
                          color="primary"
                          variant="contained"
                          className="jr-btn jr-btn-sm"
                          fullWidth
                          aria-label="Remover"
                        >
                          <i className="zmdi zmdi-close zmdi-hc-fw" />{" "}
                          Interromper o uso e continuar
                        </Button>
                      </div>
                      <div className="col-4 mt-3">
                        <Button
                          onClick={async () => {
                            await this.props.cancelMedicineInUse(
                              row.ls_mp_medicine
                            );
                            await this.existMedicineInUse();
                          }}
                          variant="contained"
                          aria-label="Editar"
                          className="jr-btn jr-btn-sm"
                          color="secondary"
                          fullWidth
                        >
                          <i className="zmdi zmdi-block zmdi-hc-fw" /> Cancelar
                          medicamento
                        </Button>
                      </div>
                      <div class="col-12 mt-3 mb-3">
                        <Divider />
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </ModalResponsive>

          {/* Medicamento que não podem ser prescritos por causa de alergia a medicamentos */}
          {in_allergic && (
            <SweetAlert
              show={this.state.medicines_allergics}
              warning
              showConfirm={false}
              title=""
              onConfirm={() => {}}
            >
              <div className="row">
                <div className="col-12">
                  <span style={{ fontWeight: "500" }}>
                    O paciente tem alergia a este medicamento.
                  </span>
                  <br />
                </div>
              </div>
              <div
                className="row"
                style={{ overflow: "scroll", height: "320px" }}
              >
                <div className="col-12">
                  <Table className="mt-5">
                    <TableBody>
                      {in_allergic.map((row) => {
                        // debugger;
                        let info = medicines.find(
                          (med) => med.ls_mp_medicine === row.id
                        );
                        if (info !== undefined) {
                          return (
                            <TableRow key={row.id}>
                              <TableCell component="th" scope="row">
                                <div className="col-12">
                                  <div>{info.name_display}</div>
                                  <span style={{ color: "#aaa" }}>
                                    {info.detail}
                                  </span>
                                </div>
                                <div className="col-12 mt-3">
                                  <Button
                                    onClick={async () => {
                                      await this.props.cancelMedicineInUse(
                                        row.id
                                      );
                                      await this.existMedicineAllergic();
                                    }}
                                    variant="contained"
                                    aria-label="Editar"
                                    className="jr-btn jr-btn-xs"
                                  >
                                    <i className="zmdi zmdi-block zmdi-hc-fw" />{" "}
                                    Cancelar medicamento
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </SweetAlert>
          )}

          <ModalResponsive
            title={"Posologia Complexa"} // titulo
            open={this.state.viewPosology}
            close={() => {
              this.setState({ viewPosology: false });
            }}
            maxWidth={"md"}
          >
            {this.state.detailPosology}
          </ModalResponsive>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ prescription, auth, appointment, admission }) => {
  const {
    prescription_id,
    medicines,
    name,
    name_doctor,
    crm_doctor,
    file,
    orientation,
    new_medicine,
    saved,
    pattern,
    pattern_id,
    in_use,
    in_allergic,
    LoadSavePrescription,
    posology_advanced,
  } = prescription;

  return {
    prescription_id,
    pattern_id,
    pattern,
    start: appointment.start,
    triage: appointment.triage,
    medicines,
    in_use,
    in_allergic,
    name,
    name_doctor,
    crm_doctor,
    file,
    orientation,
    new_medicine,
    saved,
    doctor: auth.authUser,
    patient_admission: admission.patient,
    patient: appointment.patient,
    prof_resp: appointment.prof_resp,
    address: auth.address,
    appointment_id: appointment.id,
    appointment_admission: admission.appointment,
    saved_id: appointment.saved.id,
    saved_prescription: appointment.saved.prescription,
    LoadSavePrescription,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeMedicine,
      handleChange,
      cancelMedicine,
      cancelMedicineInUse,
      newMedicine,
      alterMode,
      savePrescription,
      viewAlertMessage,
      closeAlertMessage,
      viewHistory,
      renewMedicine,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormPrescription);
