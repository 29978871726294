import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import asyncComponent from "../../../../../util/asyncComponent";

export default function Nutrition({ match, history }) {
  const patient = useSelector(({ appointment }) => appointment.patient);

  React.useEffect(() => {
    if (!patient) {
      history.push("/app/choose/data-consult");
    }
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/`}
        component={asyncComponent(() => import("./personalData"))}
      />
      <Route
        path={`${match.url}/attendance-record`}
        component={asyncComponent(() => import("./attendanceRecord"))}
      />
      <Route
        path={`${match.url}/attendance-return`}
        component={asyncComponent(() => import("./attendanceReturn"))}
      />
      <Route
        path={`${match.url}/anamnese`}
        component={asyncComponent(() => import("./anamnese"))}
      />
      <Route
        path={`${match.url}/anthropometry`}
        component={asyncComponent(() => import("./anthropometry"))}
      />
      <Route
        path={`${match.url}/bristol`}
        component={asyncComponent(() => import("./bristol"))}
      />
      <Route
        path={`${match.url}/conduct`}
        component={asyncComponent(() => import("./conduct"))}
      />
      <Route
        path={`${match.url}/food-plan`}
        component={asyncComponent(() => import("./foodPlan"))}
      />
      <Route
        path={`${match.url}/food-reminder`}
        component={asyncComponent(() => import("./foodReminder"))}
      />
    </Switch>
  );
}
