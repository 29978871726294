import React, { Component } from "react";
import "react-summernote/dist/react-summernote.css"; // import styles
import CKEditor from "react-ckeditor-component";
import "./style.css";

import $ from "jquery";
window.jQuery = $;
require("bootstrap");

export default function ModalAddConduct({
  textConductGuidance,
  setEditListFormPrint,
}) {
  const [state, setState] = React.useState({
    content: "",
  });

  React.useEffect(() => {
    if (textConductGuidance) {
      setState({
        content: textConductGuidance,
      });
    }
  }, []);

  const onChange = (evt) => {
    const newContent = evt.editor.getData();

    setState({
      content: newContent,
    });
    setEditListFormPrint(newContent);
  };

  return (
    <>
      <CKEditor
        activeClass="p10"
        content={state.content}
        events={{
          // blur: this.onBlur.bind(this),
          // afterPaste: this.afterPaste.bind(this),
          change: onChange.bind(this),
        }}
      />
    </>
  );
}
