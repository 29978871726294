import { delay } from "redux-saga";
import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import api from "services/api";

/**
 * Realiza requisições
 */

const requestGetTagsFromProvider = async address => {
  const res = await api.post(`/microservice/tags/getTags`, { address });
  const data = await res.data;
  return data;
};

const requestSaveNewTag = async params => {
  const res = await api.post(`/microservice/tags/saveNewTag/`, params);
  const data = await res.data;
  return data;
};

const requestIncludeTagToUser = async params => {
  const res = await api.post(`/microservice/tags/includeTagToPatient`, params);
  const data = await res.data;
  return data;
};

const requestGetTagsFromPatient = async params => {
  const res = await api.post(`/microservice/tags/getTagsFromUser/`, params);
  const data = await res.data;
  return data;
};

const reuqestExcluirTagFromPatient = async id => {
  const res = await api.post(`/microservice/tags/deletTagsFromPatient/`, {
    id
  });
  const data = await res.data;
  return data;
};

const requestDeleteTag = async id => {
  const res = await api.post(`/microservice/tags/deleteTag/`, { id });
  const data = await res.data;
  return data;
};

/**
 * Recebe resposta da requisição e chama o reducer
 */

function* executaGetTagsFromProvider(action) {
  try {
    const res = yield call(requestGetTagsFromProvider, action.address);
    yield put({
      type: "SET_TAGS_FROM_PROVIDER",
      payload: res.tags
    });
  } catch (error) {
    console.log("Erro executaGetTagsFromProvider() ** Tags **", error);
  }
}

function* executaSaveNewTag(action) {
  try {
    const res = yield call(requestSaveNewTag, action.payload);
    if (res.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "TAG salva com sucesso!"
      });
      yield executaGetTagsFromProvider(action.payload);
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Não foi possível salvar a TAG"
      });
    }
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log("Erro executaSaveNewTag() ** Tags **", error);
  }
}

function* executaIncludeTag(action) {
  try {
    const res = yield call(requestIncludeTagToUser, action.payload);
    if (res.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Adicionado com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro!"
      });
    }
    const list = yield call(requestGetTagsFromPatient, {
      id_user: action.payload.user,
      address: action.payload.address
    });
    yield put({
      type: "SET_TAGS_FROM_PATIENT",
      payload: list.tags
    });
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log("Erro executaIncludeTag() ** Tags **", error);
  }
}

function* executaGetTagsFromPatient(action) {
  try {
    const res = yield call(requestGetTagsFromPatient, {
      id_user: action.patient,
      address: action.address
    });
    yield put({
      type: "SET_TAGS_FROM_PATIENT",
      payload: res.tags
    });
  } catch (error) {
    console.log("Erro executaGetTagsFromPatient() ** Tags **", error);
  }
}

function* executaExcluirTagFromPatient(action) {
  try {
    const res = yield call(reuqestExcluirTagFromPatient, action.id);
    if (res.success) {
      const res = yield call(requestGetTagsFromPatient, {
        id_user: action.patient,
        address: action.address
      });
      yield put({
        type: "SET_TAGS_FROM_PATIENT",
        payload: res.tags
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Removido com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao executar esta operação"
      });
    }
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log("Erro executaExcluirTagFromPatient() ** Tags **", error);
  }
}

function* executaDeleteTag(action) {
  try {
    const res = yield call(requestDeleteTag, action.id);
    if (res.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "TAG removida da com sucesso!"
      });
      const list = yield call(requestGetTagsFromProvider, action.address);
      yield put({
        type: "SET_TAGS_FROM_PROVIDER",
        payload: list.tags
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao executar esta operação"
      });
    }
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log("Erro executaDeleteTag() ** Tags **", error);
  }
}

/**
 * Escuta actions e executa funções
 */

export function* listenGetTagsFromProvider() {
  yield takeLatest("GET_TAGS_FROM_PROVIDER", executaGetTagsFromProvider);
}

export function* listenSaveNewTag() {
  yield takeLatest("SAVE_NEW_TAGS", executaSaveNewTag);
}

export function* listenIncludeTags() {
  yield takeLatest("INCLUDE_TAGS_TO_USER", executaIncludeTag);
}

export function* listenGetTagsFromPatient() {
  yield takeLatest("GET_TAGS_FROM_PATIENT", executaGetTagsFromPatient);
}

export function* listenExcluirTagFromPatient() {
  yield takeLatest("EXCLUIR_TAG_FROM_PATIENT", executaExcluirTagFromPatient);
}

export function* listenDeleteTag() {
  yield takeLatest("DELETE_TAG", executaDeleteTag);
}

/**
 * Exporta funções do saga
 */
export default function* rootSaga() {
  yield all([
    fork(listenGetTagsFromProvider),
    fork(listenSaveNewTag),
    fork(listenIncludeTags),
    fork(listenGetTagsFromPatient),
    fork(listenExcluirTagFromPatient),
    fork(listenDeleteTag)
  ]);
}
