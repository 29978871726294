import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "services/api";
import FormDiagnostic from "./FormDiagnostic";
import FormFinishTreatment from "./FormFinishTreatment";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import IconDone from "@material-ui/icons/Done";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { BtnAdd } from "components/Button";
import NoRecord from "components/Alert/NoRecord";
import BarProgress from "components/CircularProgress/ProgressBar";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";

export default function Diagnostics({ type, classSelectCom }) {
  const [modalAdd, setModalAdd] = useState(false);
  const [modalFinish, setModalFinish] = useState(false);
  const [dataModal, setDataModal] = useState({ id: "" });
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [record, setRecord] = useState([]);
  const [groups_diagnostics, setGroupsDiagnostics] = useState([]);
  const [loader, setLoader] = useState(true);
  const patient = useSelector((state) =>
    type === "consult" ? state.appointment.patient : state.admission.patient
  );
  const user = useSelector((state) => state.auth.authUser);
  const user_address = useSelector(
    (state) => state.auth.address_selected.user_id
  );

  // Flag para att os registros quando e adicionado um diagnostico pelo mention do registro do atendimento
  const save_mention = useSelector(
    (state) => state.attendanceRecord.save_mention
  );

  useEffect(() => {
    getDiagnostics();
  }, []);

  useEffect(() => {
    if (parseInt(save_mention) > 0) {
      getDiagnostics();
    }
  }, [save_mention]);

  const getDiagnostics = () => {
    Api.post(`/microservice/admission/getDiagnosticsPatient`, {
      patient,
      user_address,
      type: "1",
    })
      .then(({ data }) => {
        if (data.success) {
          setRecord(data.diagnostics);
          setGroupsDiagnostics(data.groups);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const removeDiagnostic = () => {
    Api.post(`/microservice/admission/removeDiagnostic/`, {
      id: dataModal.id,
      user,
    })
      .then(() => {
        setRecord(record.filter((row) => row.id !== dataModal.id));
        setDataModal({ id: "" });
        setModalDelete(false);
        setSuccessDelete(true);
      })
      .catch(() => {
        setModalDelete(false);
        setDataModal({ id: "" });
        alert("Ocorreu um erro! Tente novamente.");
      });
  };

  const handleAdd = (obj, new_obj) => {
    if (new_obj) {
      setRecord([...record, obj]);
    } else {
      setRecord(record.map((row) => (row.id === obj.id ? obj : row)));
    }
    setModalAdd(false);
    setDataModal({ id: "" });
  };
  const handleFinish = () => {
    setRecord(record.filter((row) => row.id !== dataModal.id));
    setModalFinish(false);
    setDataModal({ id: "" });
  };

  return (
    <div className="row mt-3 w-100">
      <div className="col-12">
        <h4
          className="text-primary"
          style={{
            display: "inline-flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          Diágnostico(s)
          <BtnAdd
            title="Adicionar diagnóstico"
            onClick={() => setModalAdd(true)}
            float="right"
          />
        </h4>
      </div>

      {loader ? (
        <div className="col-12 d-flex justify-content-center m-2">
          <BarProgress />
        </div>
      ) : (
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          {record.length === 0 ? (
            <NoRecord />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>CID</TableCell>
                  <TableCell>Suspeito</TableCell>
                  <TableCell>Confirmado</TableCell>
                  <TableCell>Crônico</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell width="50%">
                      {row.description}{" "}
                      {!row.date_confirm &&
                      !row.date_suspect &&
                      !row.is_cronic ? (
                        <Tooltip title="Informações pendentes">
                          <ErrorOutlineIcon fontSize="small" color="error" />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {row.date_suspect
                        ? moment(row.date_suspect).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {row.date_confirm
                        ? moment(row.date_confirm).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {row.is_cronic
                        ? row.is_cronic === "0"
                          ? "Não"
                          : "Sim"
                        : ""}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Editar diagnóstico" placement="top">
                        <IconButton
                          onClick={() => {
                            setModalAdd(true);
                            setDataModal(row);
                          }}
                          color="primary"
                          aria-label="Editar"
                        >
                          <i className="zmdi zmdi-edit zmdi-hc-fw" />
                        </IconButton>
                      </Tooltip>
                      {row.is_cronic !== "1" && (
                        <Tooltip
                          title="Finalizar tratamento do diagnóstico"
                          placement="top"
                        >
                          <IconButton
                            onClick={() => {
                              setModalFinish(true);
                              setDataModal(row);
                            }}
                            color="secondary"
                            aria-label="Editar"
                          >
                            <IconDone style={{ color: "green" }} />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip title="Remover diagnóstico" place="top">
                        <IconButton
                          aria-label="Delete"
                          color="secondary"
                          onClick={() => {
                            setDataModal(row);
                            setModalDelete(true);
                          }}
                        >
                          <i className="zmdi zmdi-delete" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      )}
      <FormDiagnostic
        type={type}
        record={dataModal}
        groups={groups_diagnostics}
        classSelectCom={classSelectCom}
        open={modalAdd}
        handleClose={() => setModalAdd(false)}
        handleAdd={handleAdd}
      />
      <FormFinishTreatment
        record={dataModal}
        open={modalFinish}
        handleClose={() => setModalFinish(false)}
        handleFinish={handleFinish}
      />
      <SweetAlert
        show={modal_delete}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Remover o registro deste diagnóstico?"
        onConfirm={removeDiagnostic}
        onCancel={() => setModalDelete(false)}
      />
      <SweetAlert
        show={success_delete}
        success
        title="Registro removido com sucesso!"
        onConfirm={() => setSuccessDelete(false)}
      />
    </div>
  );
}
