import React from "react";
import PlaceIcon from "@material-ui/icons/Place";
import { useSelector, useDispatch } from "react-redux";
import { Paper, IconButton, Tooltip, Chip, TextField } from "@material-ui/core";
import IconBlock from "@material-ui/icons/Block";
import CloseIcon from "@material-ui/icons/Close";
import CompomentsDataDash from "./CompomentsDataDash";

export default function CardViewAddress({}) {
  const address_selected = useSelector((state) => state.auth.address_selected);
  const width = useSelector(({ settings }) => settings.width);
  const data_dash = useSelector((state) => state.dashboard.data_dash);

  const dispatch = useDispatch();

  const handleClearList = () => {
    dispatch({
      type: "CHANGE_ADDRESS",
      payload: {
        address: "",
      },
    });
  };

  return (
    <>
      {width > 500 ? (
        <div className="col-12 d-flex p-2">
          <div className="col-6">
            <div className="user-profile py-2 d-flex flex-row align-items-center">
              <PlaceIcon
                color="secondary"
                style={{
                  fontSize: "43px",
                }}
              />
              <div className="user-detail">
                <h3 className="user-name font-weight-bold">
                  {address_selected.name}{" "}
                </h3>
                <span className="text-light-grey jr-fs-sm">
                  {address_selected.full_address}
                </span>
              </div>
            </div>
          </div>
          {/* <div className="col-2">
            <TextField
              type="date"
              value={date.start}
              onChange={(e) => setDate({ ...date, start: e.target.value })}
              label="Início:"
            />
          </div>
          <div className="col-2">
            <TextField
              type="date"
              value={date.end}
              onChange={(e) => setDate({ ...date, end: e.target.value })}
              label="Término:"
            />
          </div> */}
          <div className="col-6 d-flex justify-content-end align-self-center">
            <CompomentsDataDash />

            <Tooltip title="Sair" placement="top">
              <IconButton
                color="secondary"
                onClick={handleClearList}
                style={{ float: "right", marginLeft: "30px" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="w-100 py-2">
          <div className="col-12">
            <div className="user-profile py-2 d-flex flex-row align-items-center">
              <PlaceIcon
                color="secondary"
                style={{
                  fontSize: "43px",
                }}
              />
              <div className="user-detail">
                <h3 className="user-name font-weight-bold">
                  {address_selected.name}{" "}
                </h3>
                <span className="text-light-grey jr-fs-sm">
                  {address_selected.full_address}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex mb-3">
            <div className="col-12">
              <CompomentsDataDash />

              {/* <TextField
                type="date"
                value={date.start}
                onChange={(e) => setDate({ ...date, start: e.target.value })}
                label="Início:"
              />
            </div>
            <div className="col-5">
              <TextField
                type="date"
                value={date.end}
                onChange={(e) => setDate({ ...date, end: e.target.value })}
                label="Término:"
              /> */}
            </div>
          </div>
          <div className="col-12 pb-2">
            <Chip
              className="w-100"
              size="small"
              color="secondary"
              onClick={handleClearList}
              icon={<IconBlock />}
              label="Sair"
            />
          </div>
        </div>
      )}
    </>
  );
}
