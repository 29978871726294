import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  editEmergencyContact,
  addEmergencyContact
} from "actions/consult/PersonalData";
import * as alertActions from "actions/Alerts";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import MaskCellphone from "components/Masks/MaskCellphone";
import MaskPhone from "components/Masks/MaskPhone";
import Button from "@material-ui/core/Button";
import AutocompleteParents from "../autocomplete/Parents";
import Api from "services/api";

class FormEmergencyContact extends Component {
  state = {
    id: "",
    contact_id: "",
    name: "",
    reference: "",
    priority: "",
    locality: "",
    cellphone: "",
    phone: "",
    whatsapp: "",
    email: "",
    error: false
  };

  componentDidMount() {
    if (this.props.contact.id) {
      const {
        id,
        contact_id,
        name,
        reference,
        priority,
        locality,
        cellphone,
        phone,
        whatsapp,
        emaill
      } = this.props.contact;
      this.setState({
        id,
        contact_id: contact_id ? contact_id : "",
        name,
        reference,
        priority,
        locality,
        cellphone,
        phone,
        whatsapp,
        emaill
      });
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChangeAutocomplete = () => event => {
    this.setState({
      [`${event.index}`]: event.value
    });
  };

  handleBlurCellphone = () => {
    if (!this.state.whatsapp) {
      this.setState({ whatsapp: this.state.cellphone });
    }
  };

  validate = () => {
    const { name, reference, priority, cellphone } = this.state;
    if (name && reference && priority && cellphone) {
      this.setState({ error: false });
      return true;
    } else {
      this.setState({ error: true });
      return false;
    }
  };

  save() {
    if (this.validate()) {
      const { patient, doctor } = this.props;
      let formData = {
        patient,
        doctor,
        data: this.state
      };

      Api.post(`/microservice/consult/saveEmergencyContact/`, formData)
        .then(res => {
          let data = this.state;
          if (data.id > 0) {
            this.props.editEmergencyContact(data);
          } else {
            this.props.addEmergencyContact({ ...data, id: res.data.record.id });
          }
          this.props.viewAlertMessage("success", "Salvo com sucesso!");
          setTimeout(() => this.props.closeAlertMessage(), 5000);
          this.props.handleClose();
        })
        .catch(() => {
          this.props.viewAlertMessage("error", "Ocorreu um erro ao salvar!");
          setTimeout(() => this.props.closeAlertMessage(), 5000);
        });
    }
  }

  render() {
    const {
      id,
      contact_id,
      name,
      reference,
      locality,
      priority,
      cellphone,
      phone,
      whatsapp,
      email,
      error
    } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <h2 className="text-primary font-weight-semibold">
            {id > 0
              ? "Editar contato de emergência"
              : "Cadastrar contato de emergência"}
          </h2>
        </div>
        <div className="col-12">
          <AutocompleteParents
            parent={{
              id: contact_id,
              label: name
            }}
            field={`emergency_contact`}
            handleChangeAutocomplete={this.handleChangeAutocomplete()}
            error={error && !name ? true : false}
          />
        </div>
        <div className="col-12">
          <TextField
            id="reference"
            select
            label="Referência"
            value={reference}
            onChange={this.handleChange("reference")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            error={error && !reference ? true : false}
            InputLabelProps={{
              shrink: true
            }}
          >
            <MenuItem key="1" value="Pai">
              Pai
            </MenuItem>
            <MenuItem key="2" value="Mãe">
              Mãe
            </MenuItem>
            <MenuItem key="3" value="Filho(a)">
              Filho(a)
            </MenuItem>
            <MenuItem key="4" value="Irmã(o)">
              Irmã(o)
            </MenuItem>
            <MenuItem key="5" value="Tio(a)">
              Tio(a)
            </MenuItem>
            <MenuItem key="6" value="Sobrinho(a)">
              Sobrinho(a)
            </MenuItem>
            <MenuItem key="7" value="Avó(ô)">
              Avó(ô)
            </MenuItem>
            <MenuItem key="8" value="Cônjuge">
              Cônjuge
            </MenuItem>
            <MenuItem key="9" value="Amigo">
              Amigo
            </MenuItem>
            <MenuItem key="10" value="Outros">
              Outros
            </MenuItem>
          </TextField>
        </div>
        <div className="col-12">
          <TextField
            id="priority"
            select
            label="Prioridade"
            value={priority}
            onChange={this.handleChange("priority")}
            SelectProps={{}}
            fullWidth
            error={error && !priority ? true : false}
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true
            }}
          >
            <MenuItem key="1" value="0">
              Preferêncial
            </MenuItem>
            <MenuItem key="2" value="1">
              Alternativo
            </MenuItem>
          </TextField>
        </div>
        <div className="col-12">
          <TextField
            id="locality"
            label="Localidade"
            value={locality}
            margin="normal"
            fullWidth
            onChange={this.handleChange("locality")}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className="col-12">
          <TextField
            id="cellphone"
            label="Celular"
            margin="normal"
            fullWidth
            error={error && !cellphone ? true : false}
            InputProps={{
              inputComponent: MaskCellphone,
              value: cellphone,
              onChange: this.handleChange("cellphone")
            }}
            onBlur={this.handleBlurCellphone}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className="col-12">
          <TextField
            id="whatsapp"
            label="Whatsapp"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCellphone,
              value: whatsapp,
              onChange: this.handleChange("whatsapp")
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className="col-12">
          <TextField
            id="phone"
            label="Fixo"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskPhone,
              value: phone,
              onChange: this.handleChange("phone")
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className="col-12">
          <TextField
            id="email"
            label="E-mail"
            value={email}
            margin="normal"
            fullWidth
            onChange={this.handleChange("email")}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className="col-md-12 mt-20">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={() => this.props.handleClose()}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm bg-success"
            onClick={() => this.save()}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    doctor: state.auth.authUser
    // patient: state.appointment.patient
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { editEmergencyContact, addEmergencyContact, ...alertActions },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormEmergencyContact);
