import React, { useState, useEffect } from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";
import Api from "services/api";

const GraphicFCM = ({ user }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user) {
      Api.post("ms_system/dashboard/getDataGraphicFCM", { user }).then(
        (res) => {
          setData(
            res.data.result
              .map((row) => ({ ...row, Valor: parseFloat(row.Valor) }))
              .reverse()
          );
        }
      );
    }
  }, []);

  return (
    <ResponsiveContainer height={220}>
      <ComposedChart
        data={data}
        height={220}
        margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
        <Bar dataKey="Valor" barSize={25} fill="#177493" />
        <Line type="monotone" dataKey="Valor" stroke="#177493" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
export default GraphicFCM;
