import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import {
  Button,
  IconButton,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import SearchDoctor from "../SearchDoctor";

export default function AddMember({ group, professional, close, add }) {
  const [state, setState] = useState([]);
  const dispatch = useDispatch();

  function save() {
    Api.post(`/ms_system/workplace/addMemberAcademic/`, {
      group,
      professional,
      record: state,
    })
      .then((res) => {
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        let { record } = res.data;
        let data = state.map((row) => {
          let info = record.find((val) => val.user === row.id);
          return {
            id_member_in_group: info.id,
            id: row.id,
            name: row.name,
          };
        });
        add(data);
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
        close();
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage(), 5000));
      });
  }

  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <h2 className="text-primary font-weight-semibold">
          Membros
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
      </div>

      <div className="col-12">
        <SearchDoctor
          doctor={{ id: "", name: "" }}
          specialty={false}
          add_new={false}
          professional={professional}
          handleChangeAutocomplete={(id, value) => {
            setState([...state, { id, name: value }]);
          }}
          edit={false}
          clear={true}
        />
      </div>

      <div className="col-12">
        {state.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Membro</TableCell>
                <TableCell align="right" width="30%">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.map((row) => (
                <TableRow hover key={row.id}>
                  <TableCell component="th" scope="row" width="25%">
                    {row.name}
                  </TableCell>
                  <TableCell width="25%" align="right">
                    <IconButton
                      onClick={() => {
                        setState(state.filter((val) => val.id !== row.id));
                      }}
                      color="secondary"
                      aria-label="Remover"
                    >
                      <i className="zmdi zmdi-delete zmdi-hc-fw" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>

      <div className="col-md-12 mt-3">
        <Button
          color="secondary"
          variant="contained"
          className="jr-btn jr-btn-sm"
          onClick={() => close()}
          style={{ float: "left" }}
        >
          <i className="zmdi zmdi-close zmdi-hc-lg" />
          <span> Cancelar </span>
        </Button>
        {state.length > 0 && (
          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm bg-success"
            onClick={() => save()}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>
        )}
      </div>
    </div>
  );
}
