import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import ContainerHeader from "components/ContainerHeader";
import Jitsi from "react-jitsi";
import { Prompt } from "react-router-dom";
import ConfirmCloseCollpase from "components/Modal/ConfirmCloseCollpase";
import Alert from "components/Alert/AlertSnack";
import "../video/style.css";

export default function Telemedicine({ match, history }) {
  const [view, setView] = useState("3");
  const [confirmClose, setConfirmClose] = useState({
    open: false,
    obj: {},
  });
  const [modalAlert, setModalAlert] = useState(false);
  const name = useSelector((state) => state.auth.name);
  const url = window.location.href;
  const event = url.split("consult=");

  useEffect(() => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      if (navigator.userAgent.match(/CriOS/i)) {
        setModalAlert(true);
      }
    }
  }, []);

  useEffect(() => {
    if (view === "1") {
      document.getElementById("div-start").classList.remove("d-none");
      document.getElementById("div-start").scrollIntoView();
    }
  }, [view]);

  const handleLoadJitsi = (jitsi) => {
    jitsi.addEventListener("videoConferenceLeft", () => {
      if (document.getElementById("load-jitsi")) {
        document.getElementById("load-jitsi").classList.remove("d-none");
      }
      if (document.getElementById("div-jitsi")) {
        document.getElementById("div-jitsi").classList.add("d-block");
      }
      setView("2");
    });
    jitsi.addEventListener("readyToClose", () => {
      document.getElementById("load-jitsi").classList.remove("d-none");
      document.getElementById("div-jitsi").classList.add("d-block");
      setView("2");
    });
    jitsi.addEventListener("videoConferenceJoined", () => {
      document.getElementById("div-jitsi").classList.remove("d-none");
      document.getElementById("div-jitsi").classList.add("d-block");
      document.getElementById("load-jitsi").classList.add("d-none");
      // document.getElementsByClassName(
      //   "leftwatermark"
      // )[0].style.backgroundImage = "none";
    });
  };

  return (
    <div className="row">
      <div className="col-md-2 col-xs-12"></div>
      <div className="col-md-8 col-xs-12">
        <ContainerHeader title="Teleconsulta" match={match} />
        <Card className="shadow border-0">
          <CardBody>
            <div className="row">
              <div className="col-12">
                {view === "2" ? (
                  <div className="loader-view" style={{ height: "80vh" }}>
                    <i className="zmdi zmdi-videocam-off s-128 text-muted" />
                    <h1 className="text-muted">Teleconsulta encerrada</h1>
                  </div>
                ) : (
                  <>
                    <div
                      id="load-jitsi"
                      className="loader-view"
                      style={{ height: "80vh" }}
                    >
                      {/* <i className="zmdi zmdi-more s-128 text-muted" /> */}
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <h1 className="text-muted">Iniciando teleconsulta ...</h1>
                    </div>
                    <div id="div-jitsi" className="d-none w-100">
                      <Jitsi
                        roomName={`salutemplus-5242654807${event[1]}9531784`}
                        // roomName={`salutemplus-52426548079531784`}
                        displayName={name}
                        config={{
                          defaultLanguage: "pt",
                          disableDeepLinking: true,
                          prejoinPageEnabled: false,
                          enableWelcomePage: false,
                        }}
                        interfaceConfig={{
                          BRAND_WATERMARK_LINK:
                            "https://balancer.salutemplus.com",
                          DEFAULT_REMOTE_DISPLAY_NAME: "SalutemPlus",
                          SHOW_JITSI_WATERMARK: false,
                          SHOW_WATERMARK_FOR_GUESTS: false,
                          DISPLAY_WELCOME_PAGE_CONTENT: false,
                          HIDE_INVITE_MORE_HEADER: true,
                          APP_NAME: "SalutemPlus",
                          NATIVE_APP_NAME: "SalutemPlus",
                          PROVIDER_NAME: "SalutemPlus",
                          MOBILE_APP_PROMO: false,
                          TOOLBAR_BUTTONS: [
                            "microphone",
                            "camera",
                            "closedcaptions",
                            "desktop",
                            "fullscreen",
                            // "fodeviceselection",
                            "hangup",
                            // "profile",
                            // "settings",
                            // "videoquality",
                            // "filmstrip",
                            // "feedback",
                            // "stats",
                            // "shortcuts",
                            // "tileview",
                            // "videobackgroundblur",
                            // "download",
                          ],
                        }}
                        containerStyle={{ width: "100%", height: "500px" }}
                        onAPILoad={handleLoadJitsi}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
        <Prompt
          when={view === "3"}
          message={"Deseja desconectar desta chamada?"}
        />
        <ConfirmCloseCollpase
          open={confirmClose.open}
          message="Deseja desconectar desta chamada?"
          confirm={() => {
            setConfirmClose({
              open: false,
              value: {},
            });
            setView("2");
          }}
          cancel={() =>
            setConfirmClose({
              open: false,
              value: {},
            })
          }
        />

        <Alert
          open={modalAlert}
          onClose={() => setModalAlert(false)}
          severity="warning"
        >
          Este navegador não é compatível com os recursos utilizados na
          videochamada, recomendamos o uso do{" "}
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="https://support.apple.com/pt_BR/downloads/safari"
            target="_blank"
          >
            Safari
          </a>
          .
        </Alert>
      </div>
    </div>
  );
}
