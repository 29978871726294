import firebase from "firebase";
import api from "./api";

//Função inicializa a conexção com o firebase
export const inicializarFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyBIgi1fbqpTfA7NsSHfFt_0fw2pndk4yAk",
    authDomain: "salutemplus-1541509900289.firebaseapp.com",
    databaseURL: "https://salutemplus-1541509900289.firebaseio.com",
    projectId: "salutemplus-1541509900289",
    storageBucket: "salutemplus-1541509900289.appspot.com",
    messagingSenderId: "680926874502",
    appId: "1:680926874502:web:ca39b48a96d6e7fdbb7a07",
  });
};

export const getDataFirebase = () => {
  return firebase.database().ref();
};

export const getEnviromentFirebase = () => {
  if (window.location.hostname === "localhost") {
    return "-dev";
  } else if (
    window.location.hostname === "beta1.salutemplus.com" ||
    window.location.hostname === "app.salutemplus.com"
  ) {
    return "";
  } else {
    return "-hml";
  }
};

export const pedirPermissaoParaReceberNotificacoes = async (id) => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    api.post(`/ms_comunication/chat/acceptNotificationPush`, { token, id });
    // console.log("token do usuário:", token);
    return token;
  } catch (error) {
    console.error(error);
  }
};

export const firebaseMessage = () => {
  return firebase.messaging();
};
