import React, { useEffect, useState } from "react";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan } from "components/Button";
import ComponentsProfile from "components/ComponentsProfile";
import moment from "moment";
import Api from "services/api";
import Typography from "@material-ui/core/Typography";

export default function ViewNotification({ open, handleClose, data }) {
  const [symptoms, setSymptoms] = useState([]);
  const [vaccines, setVaccines] = useState([]);

  useEffect(() => {
    if (data.type === "0") {
      Api.post("/ms_system/notificationcovid/getSymptomsNotification/", {
        id: data.id,
      }).then((res) => {
        setSymptoms(res.data.record);
      });
    }

    if (data.type === "1") {
      Api.post("/ms_system/notificationcovid/getVaccinesNotification/", {
        date: moment(data.created_at).format("YYYY-MM-DD"),
        user: data.fk_ls_user,
      }).then((res) => {
        setVaccines(res.data.record);
      });
    }
  }, [data]);

  return (
    <ModalResponsive
      open={open}
      close={handleClose}
      maxWidth="md"
      title="Visualizar Notificação de Paciente Sintomático"
      buttons={
        <div className="col-12">
          <BtnCan title="Fechar" onClick={() => handleClose()} float="left" />
        </div>
      }
    >
      <div className="row">
        <div className="col-12">
          <strong> Data da notificação: </strong>{" "}
          {moment(data.date).format("DD/MM/YYYY HH:MM")}
        </div>
        <div className="col-12">
          <strong> Tipo da notificação: </strong>{" "}
          {data.type === "1"
            ? "Vacinação"
            : data.type === "2"
            ? "Sequelas"
            : data.type === "0"
            ? "Sintomas"
            : "Comorbidades"}
        </div>
        <div className="col-12 mt-2">
          <strong> Paciente: </strong>
          <ComponentsProfile
            id={data.patient}
            nameProfile={data.patient_name}
            photo={data.patient_photo}
            subTitle={moment(data.patient_birthday).format("DD/MM/YYYY")}
          />
        </div>
        {data.type === "1" && (
          <>
            <div className="col-12 mt-2">
              <strong>Vacinou-se?</strong>{" "}
              {data.vaccine === "1" ? "Sim" : "Não"}
            </div>
            {data.reason_not_vaccine && (
              <div className="col-12 mt-2">
                <strong>Motivo:</strong> {data.reason_not_vaccine}
              </div>
            )}
            {vaccines.map((row, index) => (
              <div className="col-12 row">
                {index === 0 && <hr className="w-100" />}
                <div className="col-6">
                  <Typography variant="subtitle2" gutterBottom>
                    {row.name} - {row.dosage}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="body2" gutterBottom>
                    {row.is_applied === "1" ? "Aplicada" : "Prevista"} - (
                    {moment(row.date_of_vaccine).format("DD/MM/YYYY")})
                  </Typography>
                </div>

                {row.city && (
                  <div className="col-3">
                    <Typography variant="subtitle2" gutterBottom>
                      Cidade:
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {row.city}
                    </Typography>
                  </div>
                )}

                <hr className="w-100" />
              </div>
            ))}
            {data.vaccine === "1" && (
              <>
                <div className="col-12 ml-2">
                  <strong>Houve reações?</strong>{" "}
                  {data.has_vaccine_reaction === "1" ? "Sim" : "Não"}
                </div>

                {data.has_vaccine_reaction === "1" && data.vaccine_reaction && (
                  <div className="col-12 ml-2">
                    <strong> Reações(s): </strong>
                    <ul>
                      {data.vaccine_reaction.split(",").map((el) => (
                        <li key={el}>{el}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {data.type === "2" && (
          <>
            <div className="col-12 mt-2">
              <strong>Sequelas</strong>{" "}
            </div>
            <div className="col-12 ml-2">
              Data do diagnóstico:{" "}
              {moment(data.sequel_date).format("DD/MM/YYYY")}
            </div>
            <div className="col-md-4 ml-2">
              Fadiga: {data.sequel_fatigue === "1" ? "Sim" : "Não"}
            </div>
            <div className="col-md-4">Tosse: {data.sequel_cough}</div>
            <div className="col-md-3">
              Perda de Olfato: {data.sequel_smell === "1" ? "Sim" : "Não"}
            </div>
            <div className="col-md-4 ml-2">
              Perda de paladar: {data.sequel_taste === "1" ? "Sim" : "Não"}
            </div>
            <div className="col-md-4">
              Cansaço Muscular: {data.sequel_muscle === "1" ? "Sim" : "Não"}
            </div>
            <div className="col-md-3">
              Impotência Sexual: {data.sequel_sex === "1" ? "Sim" : "Não"}
            </div>
            <div className="col-md-12 ml-2">Outros: {data.sequel_others}</div>
          </>
        )}
        {data.type === "0" && (
          <>
            <div className="col-12 mt-2">
              <strong> Sintoma(s): </strong>
            </div>
            <hr className="w-100" />
            {symptoms.map((row) => (
              <div className="col-12 row ">
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    {row.name}
                  </Typography>
                </div>
                {row.gravity && (
                  <div className="col-3">
                    <Typography variant="subtitle2" gutterBottom>
                      Nível de Gravidade:
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {row.gravity}
                    </Typography>
                  </div>
                )}
                {row.temperature && (
                  <div className="col-3">
                    <Typography variant="subtitle2" gutterBottom>
                      Temperatura:
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {row.temperature}
                    </Typography>
                  </div>
                )}
                {row.start && (
                  <div className="col-3">
                    <Typography variant="subtitle2" gutterBottom>
                      Início:
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {row.start
                        ? moment(row.start).format("DD/MM/YYYY")
                        : "--"}
                    </Typography>
                  </div>
                )}
                {row.end && (
                  <div className="col-3">
                    <Typography variant="subtitle2" gutterBottom>
                      Término:
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {row.end ? moment(row.end).format("DD/MM/YYYY") : "--"}
                    </Typography>
                  </div>
                )}
                <hr className="w-100" />
              </div>
            ))}
          </>
        )}
        {data.comorbiditys && (
          <div className="col-12 mt-2">
            <strong> Comobirdade(s): </strong>
            <ul>
              {data.comorbiditys.split(",").map((el) => (
                <li key={el}>{el}</li>
              ))}
              {data.comorbiditys_other && (
                <li key="0">{data.comorbiditys_other}</li>
              )}
            </ul>
          </div>
        )}
        {data.smartphone && (
          <div className="col-12 mt-2">
            <strong> Celular: </strong> {data.smartphone} <br />
          </div>
        )}
        {data.fixo && (
          <div className="col-12 mt-2">
            <strong> Telefone Fixo: </strong> {data.fixo} <br />
          </div>
        )}
        {data.email && (
          <div className="col-12 mt-2">
            <strong> Email: </strong> {data.email} <br />
          </div>
        )}
        <div className="col-12 mt-2">
          <strong> Notificado por: </strong>
          <ComponentsProfile
            id={data.notificator}
            nameProfile={data.notificator_name}
            photo={data.notificator_photo}
          />
        </div>
      </div>
    </ModalResponsive>
  );
}
