import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

/* Services */
import Api from "services/api";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import moment from "moment";

/* Components Locais */
import TopBar from "./TopBar";

/*Components Gerais */
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import BarProgress from "components/CircularProgress/ProgressBar";
import Table from "components/PrescriptionCare/Table";
import TableInterrupted from "components/PrescriptionCare/TableInterrupted";
import ModalCare from "components/PrescriptionCare/Modal";
import { Btn } from "components/Button";
import Tabs from "components/TabsDefault";
import CardChooseAddress from "components/Forms/CardChooseAddress";
import CardViewAddress from "components/Forms/CardViewAddress";
import ChoosePatient from "components/Forms/ChoosePatient";

export default function PrescriptionCare({ match }) {
  const dispatch = useDispatch();

  /* Varáveis do redux */
  const address = useSelector((state) => state.auth.address);
  const user = useSelector(({ auth }) => auth.authUser);

  /* Variáveis Locais */
  const [patient, setPatient] = useState(null);
  const [loader, setLoader] = useState(false);
  const [listCare, setListCare] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(null);
  const [view, setView] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [interrupteds, setInterrupteds] = useState([]);

  useEffect(() => {
    if (address && patient) {
      getRecords();
    }
  }, [address, patient]);

  useEffect(() => {
    if (patient && listCare) {
      getInterrupted();
    }
  }, [listCare]);

  /**
   * Função Buscar Prescrição do paciente
   */
  const getRecords = () => {
    setLoader(true);
    Api.post(`/microservice/prescriptioncare/getHistory/`, {
      patient: patient.id,
    }).then(({ data }) => {
      setLoader(false);
      setListCare(data.record.map((row) => ({ ...row, check: false })));
    });
  };

  /**
   * Função Buscar Prescrição interrompidas do paciente
   */
  const getInterrupted = () => {
    // setLoading(true);
    Api.post(`microservice/prescriptioncare/getPrescriptionInterrupted`, {
      patient: patient.id,
    })
      .then(({ data }) => {
        setInterrupteds(
          data.record.sort((a, b) => {
            let dateA = a.interrupted_date ? a.interrupted_date : a.end;
            let dateB = b.interrupted_date ? b.interrupted_date : b.end;
            if (
              moment(dateA, "DD/MM/YYYY").isBefore(moment(dateB, "DD/MM/YYYY"))
            ) {
              return 1;
            }
            if (
              !moment(dateA, "DD/MM/YYYY").isBefore(moment(dateB, "DD/MM/YYYY"))
            ) {
              return -1;
            }
            // a must be equal to b
            return 0;
          })
        );
      })
      .catch(() => {});
  };

  /**
   * Função Função de delete das prescrições selecionadas
   * @param {Array} arrDelete obrigatorio
   */
  const handleDelete = (arrDelete) => {
    Api.post(`/microservice/prescriptioncare/deletePrescriptionCare/`, {
      arrDelete,
      patient: patient.id,
      user,
    })
      .then(({ data }) => {
        setListCare(data.newList.map((row) => ({ ...row, check: false })));
        // getRecords();
        dispatch(viewAlertMessage("success", "Interrompido com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch(() => {
        dispatch(
          viewAlertMessage("error", "Não foi possível interromper o registro!")
        );
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      });
  };

  /**
   * Fecha o modal e retorna a nova lista no estado
   * @param {Array} newList nova listCares
   */
  const handleCloseModal = (newList) => {
    setOpen(false);
    setView(false);
    setEdit(null);
    newList && setListCare(newList.map((row) => ({ ...row, check: false })));
  };

  return (
    <div className="animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        title={<IntlMessages id={`module.consult.prescription.care`} />}
        match={match}
      />
      {/* Selecionar Local*/}
      {!address && <CardChooseAddress />}

      {/* Selecionar paciente */}
      {address && !patient && (
        <>
          <CardViewAddress />
          <ChoosePatient handleSelected={setPatient} />
        </>
      )}

      {/* Prescrições de Cuidado */}
      <div className="row" style={{ padding: "5px 15px" }}>
        {address && patient && (
          <div className="col-12">
            {/* Barra com Paciente e Local */}
            <TopBar
              id={patient.id}
              nameProfile={patient.name}
              photo={patient.photo}
              removePatient={() => setPatient(null)}
            />
            <div className="jr-card jr-card-widget w-100 p-2 mb-3">
              {/* Loading */}
              {loader && (
                <div
                  className="col-12 d-flex justify-content-center py-4"
                  style={{ alignItems: "center" }}
                >
                  <BarProgress />
                </div>
              )}

              {!loader && (
                <Tabs
                  value={activeTab}
                  setValue={setActiveTab}
                  tabs={[
                    "Prescrições Ativas",
                    "Prescrições Finalizadas/Interrompidas",
                  ]}
                >
                  <React.Fragment>
                    {activeTab === 0 && (
                      <div>
                        <div className="row">
                          <div className="col-12">
                            <Btn
                              title={"Prescrever Cuidado"}
                              icon={"zmdi zmdi-plus zmdi-hc-lg"}
                              name={"Prescrever Cuidado"}
                              onClick={() => setOpen(!open)}
                              float="right"
                            />
                          </div>
                        </div>

                        {/* Tabela de Cuidados */}
                        <Table
                          listCare={listCare}
                          setListCare={setListCare}
                          setOpenView={(edit) => {
                            setOpen(!open);
                            setView(!view);
                            setEdit(edit);
                          }}
                          setEdit={(edit) => {
                            setOpen(!open);
                            setEdit(edit);
                          }}
                          handleDelete={handleDelete}
                        />
                      </div>
                    )}
                    {/* Table de Cuidados Interrrompidos */}
                    {activeTab === 1 && (
                      <TableInterrupted prescriptions={interrupteds} />
                    )}
                  </React.Fragment>
                </Tabs>
              )}
            </div>
            {/* Modal Prescrição de Cuidados */}
            <ModalCare
              open={open}
              handleClose={(state) => handleCloseModal(state)}
              record={edit}
              view={view}
              setView={(e) => setView(e)}
              set_appointment={false}
              patient_no_appointment={patient.id}
            />
          </div>
        )}
      </div>
    </div>
  );
}
