export const CUTANEO = 7;

export const URINARIO = 2;

export const LESOES = 8;

export const SUP_INF = 9;

export const SENTIDOS = 6;

export const OSTEOMUSCULAR = 1;

export const CARDIO = 3;

export const DIGESTIVO = 5;

export const NERVOSO = 10;

export const RESPIRATORIO = 4;

export const URINARIO_M = 11;

export const URINARIO_F = 12;

export const URINARIO_O = 13;

export const CATEGORIES_ARRAY = [
  { id: CUTANEO, description: "Cutâneo", url: "cutaneous_system" },
  {
    id: URINARIO,
    description: "Aparelho Genito-urinário",
    url: "urinary_system",
  },
  { id: LESOES, description: "Lesões Cutâneas", url: "lesion" },
  { id: SUP_INF, description: "Membros superiores e inferiores", url: "limbs" },
  { id: SENTIDOS, description: "Orgão dos Sentidos", url: "sense-organ" },
  { id: OSTEOMUSCULAR, description: "Osteomuscular", url: "musculoskeletal" },
  { id: CARDIO, description: "Cardiovascular", url: "cardio-system" },
  { id: DIGESTIVO, description: "Aparelho Digestivo", url: "digestive_system" },
  { id: NERVOSO, description: "Nervoso", url: "nervous_system" },
  { id: RESPIRATORIO, description: "Respiratório", url: "respiratory_system" },
];

export const QUESTION_CUTANEIO = [
  { id: 142, description: "Descoloração" },
  { id: 143, description: "Erupções cutâneas" },
  { id: 144, description: "Feridas na pele" },
  { id: 145, description: "Comichão" },
  { id: 146, description: "Úlseras de pressão" },
  { id: 147, description: "Ceratose" },
  { id: 148, description: "Lesões pré-cancerosas" },
  { id: 149, description: "Lesões suspeitas de câncer" },
  { id: 150, description: "Linfonodos palpáveis" },
];

export const QUESTION_DIGESTIVO = [
  { id: 52, description: "Apetite" },
  { id: 53, description: "Náusea" },
  { id: 54, description: "Vômito" },
  { id: 55, description: "Xerostomia" },
  { id: 56, description: "Problemas de mastigação" },
  { id: 57, description: "Higienização regular da boca ou prótese" },
  { id: 58, description: "Feridas ou lesões na mucosa oral ou língua " },
  { id: 59, description: "Prótese Dentária" },
  { id: 60, description: "Dor para Engolir" },
  { id: 61, description: "Queimação Epigástrica (Azia)" },
  { id: 62, description: "Engasgo" },
  { id: 65, description: "Coloração" },
  { id: 66, description: "Consistência" },
  { id: 67, description: "Constipação" },
  { id: 68, description: "Diarreia" },
  { id: 69, description: "Eliminação Intestinais (Frequência)" },
  { id: 70, description: "Incontinência Fecal" },
  {
    id: 71,
    description: "Necessita do uso regular de laxativo e/ou extração manual ",
  },
  { id: 72, description: "Dor abdominal" },
  { id: 73, description: "Massas" },
  { id: 74, description: "RHA(Ruído Hidroaéreo)" },
  { id: 77, description: "Timpanismo" },
  { id: 78, description: "Visceromegalia" },
];

export const QUESTION_NERVOSO = [
  { id: 80, description: "Estado de Consciência" },
  { id: 81, description: "Bradicinesia" },
  { id: 82, description: "Convulsões" },
  { id: 83, description: "Dificuldades de memória" },
  { id: 84, description: "Distúrbio da fala" },
  { id: 85, description: "Dores de Cabeça" },
  { id: 86, description: "Equilíbrio" },
  { id: 87, description: "Reflexos Ostendíneos" },
  { id: 88, description: "Reflexos Profundos" },
  { id: 89, description: "Rigidez" },
  { id: 90, description: "Tonturas" },
  { id: 91, description: "Tremores" },
  { id: 92, description: "Déficit Focal" },
  { id: 93, description: "Dificuldades de equilíbrio" },
  { id: 94, description: "Dormência/formigamento" },
  { id: 95, description: "Fraqueza" },
  { id: 96, description: "Confusão Mental" },
];

export const QUESTION_RESPIRATORIO = [
  { id: 114, description: "Auscultura Pulmunar" },
  { id: 122, description: "Dispneia (Falta de ar)" },
  { id: 123, description: "Tipo da Dispnéia" },
  { id: 115, description: "Dor torácica" },
  { id: 116, description: "Hemoptóicos" },
  { id: 117, description: "Passado de pneumonia" },
  { id: 118, description: "Passado de TBC" },
  { id: 119, description: "Suor noturno" },
  { id: 120, description: "Tabagismo" },
  { id: 121, description: "Tosse" },
  { id: 112, description: "Sauturação de o²" },
];

export const QUESTION_CARDIO = [
  { id: 38, description: "Desconforto precordial(Dor no Peito)" },
  { id: 39, description: "Dispinéia(Falta de Ar)" },
  { id: 40, description: "Dor na mandíbula" },
  { id: 41, description: "Dor nos MMII ao caminhar" },
  { id: 42, description: "Edema de MMI(Inchaço das Pernas)" },
  { id: 43, description: "Embolia" },
  { id: 44, description: "Marcapasso" },
  { id: 45, description: "Desfibrilador" },
  { id: 46, description: "Náusea" },
  { id: 47, description: "Palpitações" },
  { id: 48, description: "Tontura" },
  { id: 49, description: "Dor no braço" },
];

/**
 * retorna arry de questões
 * @param {string} gender sexo do paciente M|F|any
 */
export const QUESTION_URINARIO = (gender) => {
  if (gender === "M") {
    return [
      { id: 125, description: "Disfunção Sexual" },
      { id: 126, description: "Disúria (dor ao urinar)" },
      { id: 127, description: "Hermatúria (sangue na urina)" },
      { id: 128, description: "Incontinência urinária" },
      { id: 129, description: "Mamas" },
      { id: 130, description: "Noctúria" },
      { id: 131, description: "Odor" },
      { id: 132, description: "Usa proteção ou Fraldas" },
      { id: 133, description: "Volume da micção" },
      { id: 134, description: "Prostatismo" },
      { id: 135, description: "Alteração de jato urinário" },
      { id: 136, description: "Último controle urológico" },
    ];
  } else if (gender === "F") {
    return [
      { id: 125, description: "Disfunção Sexual" },
      { id: 126, description: "Disúria (dor ao urinar)" },
      { id: 127, description: "Hermatúria (sangue na urina)" },
      { id: 128, description: "Incontinência urinária" },
      { id: 129, description: "Mamas" },
      { id: 130, description: "Noctúria" },
      { id: 131, description: "Odor" },
      { id: 132, description: "Usa proteção ou Fraldas" },
      { id: 133, description: "Volume da micção" },
      { id: 137, description: "Secura vaginal" },
      { id: 138, description: "Corrimento" },
      { id: 139, description: "Sangramento Vaginal" },
      { id: 140, description: "Último controle ginecológico" },
    ];
  } else {
    return [
      { id: 125, description: "Disfunção Sexual" },
      { id: 126, description: "Disúria (dor ao urinar)" },
      { id: 127, description: "Hermatúria (sangue na urina)" },
      { id: 128, description: "Incontinência urinária" },
      { id: 129, description: "Mamas" },
      { id: 130, description: "Noctúria" },
      { id: 131, description: "Odor" },
      { id: 132, description: "Usa proteção ou Fraldas" },
      { id: 133, description: "Volume da micção" },
      { id: 134, description: "Prostatismo" },
      { id: 135, description: "Alteração de jato urinário" },
      { id: 136, description: "Último controle urológico" },
      { id: 137, description: "Secura vaginal" },
      { id: 138, description: "Corrimento" },
      { id: 139, description: "Sangramento Vaginal" },
      { id: 140, description: "Último controle ginecológico" },
    ];
  }
};

/**
 * Retorna o ID do Tipo do custom form do aparelho urinário com base no sexo
 * @param {string} gender M|F|any
 * @returns {Number} id do tipo
 */
export const getTypeUrinarioGender = (gender) => {
  if (gender === "M") return URINARIO_M;
  else if (gender === "F") return URINARIO_F;
  else return URINARIO_O;
};

export const QUESTION_SENTIDOS = [
  { id: 1, description: "Olhos", title: "eyes" },
  { id: 2, description: "Olfato", title: "smell" },
  { id: 3, description: "Ouvidos", title: "ears" },
  { id: 4, description: "Paladar", title: "taste" },
];

export const QUESTION_OSTEOMUSCULAR = [
  { id: 1, description: "Articulações", title: "joints" },
  { id: 2, description: "Estado dos pés", title: "feet_status" },
  { id: 3, description: "Coluna Cervical", title: "cervical" },
  { id: 4, description: "Coluna Lombar", title: "lumbar" },
  { id: 5, description: "Estado das mãos", title: "hand_status" },
  { id: 6, description: "Estado das unhas", title: "nails_status" },
];

/**
 * Retorna todos os arrays de questões
 */
export const ALL_QUESTIONS_ARR = {
  [CUTANEO]: QUESTION_CUTANEIO,
  [LESOES]: [],
  [SUP_INF]: [],
  [SENTIDOS]: QUESTION_SENTIDOS,
  [OSTEOMUSCULAR]: QUESTION_OSTEOMUSCULAR,
  [CARDIO]: QUESTION_CARDIO,
  [DIGESTIVO]: QUESTION_DIGESTIVO,
  [NERVOSO]: QUESTION_NERVOSO,
  [RESPIRATORIO]: QUESTION_RESPIRATORIO,
};
