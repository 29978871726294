import React from "react";
// import MUIDataTable from 'mui-datatables';
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
// import {TrendingFlat} from '@material-ui/icons';
// import { MuiThemeProvider } from '@material-ui/core/styles';
// import muiDatatableTheme from 'containers/themes/muiDatatableTheme';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  tableStyle: {
    backgroundColor: "white",
    textShadow: "none",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.75)",
    marginBottom: 0,
    with: "100"
  }
});

function PaperDados({ classes, dados, handleClick }) {
  const onClickBack = () => {
    handleClick();
  };
  return (
    <React.Fragment>
      {dados !== undefined && (
        <>
          <IconButton
            onClick={onClickBack}
            className="m-1"
            color="primary"
            aria-label="Visualizar"
          >
            <i className="zmdi zmdi-undo" />
          </IconButton>
          {dados.record !== undefined && (
            <div className="col-12">
              <div style={{ align: "center" }}>
                <Typography variant="h6" gutterBottom>
                  {dados.name}
                </Typography>
              </div>
              <Table className="table-history">
                <TableHead>
                  <TableRow>
                    <TableCell>Questão</TableCell>
                    <TableCell align="left">Resposta</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dados.record.map(ele => (
                    <TableRow key={ele.id}>
                      <TableCell align="left">{ele.labelQuestion}</TableCell>
                      <TableCell align="left">{ele.labelOption}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {dados.length === 0 && (
                <div className="m-2" align="center">
                  <p
                    style={{
                      textAlign: "center",
                      color: "#a6a6a6",
                      fontStyle: "italic"
                    }}
                  >
                    Nenhum Registro Encontrado
                  </p>{" "}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
}
export default withStyles(styles)(PaperDados);
