import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAppointment, setSavedActions } from "actions/consult/Appointment";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import AutocompleteCid from "components/Autocomplete/Cid";
import {
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { BtnSave, BtnCan } from "components/Button";
import TextField from "components/TextFields";
import ConfirmCloseCollpase from "components/Modal/ConfirmCloseCollpase";
import Api from "services/api";
import moment from "moment";

export default function FormDiagnostics({
  type,
  record,
  groups,
  open,
  handleClose,
  handleAdd,
  classSelectCom,
}) {
  const initial = {
    id: "",
    cid: "",
    cid_description: "",
    status: "",
    date_suspect: null,
    date_confirm: null,
    is_cronic: "",
    observation: "",
    group: "",
  };
  const [state, setState] = useState(initial);
  const [error, setError] = useState(false);
  const [edited, setEdited] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [itensGroup, setItensGroup] = useState([]);

  const address = useSelector((state) => state.auth.address);
  const doctor = useSelector((state) => state.auth.authUser);
  const prof_resp = useSelector((state) => state.appointment.prof_resp);
  const start =
    type === "consult"
      ? useSelector((state) => state.appointment.start)
      : moment().format("YYYY-MM-DD");
  const saved_id =
    type === "consult"
      ? useSelector((state) => state.appointment.saved.id)
      : "";
  const saved =
    type === "consult"
      ? useSelector((state) => state.appointment.saved.diagnostic)
      : "";
  const patient = useSelector((state) =>
    type === "consult" ? state.appointment.patient : state.admission.patient
  );
  const appointment = useSelector((state) =>
    type === "consult" ? state.appointment.id : state.admission.appointment
  );
  const admission =
    type === "admission" ? useSelector((state) => state.admission.id) : "";
  const admission_form =
    type === "admission"
      ? useSelector((state) => state.admission.forms.current_disease)
      : "";

  const dispatch = useDispatch();

  useEffect(() => {
    if (record.id) {
      setState({
        id: record.id,
        cid: record.cid ? record.cid : "",
        cid_description: record.description ? record.description : "",
        status: record.status ? record.status : "",
        observation: record.observation ? record.observation : "",
        date_suspect: record.date_suspect ? moment(record.date_suspect) : null,
        date_confirm: record.date_confirm ? moment(record.date_confirm) : null,
        is_cronic: record.is_cronic ? record.is_cronic : "",
        group: "all",
      });
    }
  }, [record]);

  useEffect(() => {
    if (state.status && !record.id) {
      if (state.status === "0" && !state.date_suspect) {
        setState({ ...state, date_suspect: moment() });
      }
      if (state.status === "1" && !state.date_confirm) {
        setState({ ...state, date_confirm: moment() });
      }
    }
  }, [state.status]);

  useEffect(() => {
    if (state.group && state.group !== "all") {
      Api.post(`/microservice/diagnosticgroup/getItensGroup/`, {
        id: state.group,
      }).then((res) => {
        setItensGroup(res.data.record);
      });
    }
  }, [state.group]);

  const handleCloseModal = () => {
    if (edited) {
      setConfirmClose(true);
    } else {
      setState(initial);
      setError(false);
      setEdited(false);
      handleClose();
    }
  };

  const handleSave = () => {
    if (state.cid) {
      let formData = {
        appointment: { id: appointment, start },
        patient,
        doctor,
        address,
        prof_resp,
        saved_id,
        saved,
        admission,
        admission_form,
        type,
        record: state,
      };
      Api.post(`/microservice/admission/saveAppointmentDiagnostics`, formData)
        .then((res) => {
          if (type === "admission") {
            if (!admission_form) {
              dispatch({
                type: "SET_ADMISSION_DATA",
                payload: {
                  admission: res.data.admission,
                  form: "family_history",
                  form_value: res.data.form,
                  appointment: res.data.appointment,
                },
              });
            }
          } else {
            if (!appointment) {
              dispatch(setAppointment(res.data.appointment));
            }
            if (!saved_id) {
              dispatch(setSavedActions("diagnostic", res.data.saved));
            }
          }

          handleAdd(
            {
              ...state,
              id: res.data.diagnostic,
              description: state.cid_description,
            },
            !state.id
          );
          setState(initial);
          setError(false);
          setEdited(false);
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
        });
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="max-width-dialog-title"
      scroll="body"
    >
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-primary font-weight-semibold">
              {record.id ? "Editar" : "Adicionar"} diagnóstico
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={handleCloseModal}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>
          {classSelectCom && (
            <div className="col-12 mb-4">
              <TextField
                select
                fullWidth
                label="Selecione a Classe da Comorbidade:"
                value={groups.length === 0 ? "all" : state.group}
                onChange={(e) => setState({ ...state, group: e.target.value })}
              >
                {groups.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}

          <div className="col-12">
            <TextField
              select
              fullWidth
              label="Grupo de Diagnósticos:"
              value={groups.length === 0 ? "all" : state.group}
              onChange={(e) => setState({ ...state, group: e.target.value })}
            >
              {groups.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
              <MenuItem key="all" value="all">
                Todos os diagnósticos
              </MenuItem>
            </TextField>
          </div>

          {state.group && state.group !== "all" && (
            <div className="col-12 mt-3 mb-3">
              <TextField
                select
                fullWidth
                label="Informe o CID:"
                value={state.cid}
                onChange={(e) => {
                  let info = itensGroup.find(
                    (row) => row.id === e.target.value
                  );
                  setState({
                    ...state,
                    cid: info.id,
                    cid_description: info.name,
                  });
                }}
              >
                {itensGroup.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}
          {(groups.length === 0 || state.group === "all") && (
            <div className="col-12 mt-3 mb-3">
              <AutocompleteCid
                handleChangeAutocomplete={(e) => {
                  if (e.id) {
                    setState({
                      ...state,
                      cid: e.id,
                      cid_description: e.short_description,
                    });
                  } else {
                    setState({
                      ...state,
                      cid: "",
                      cid_description: "",
                    });
                  }
                  if (!edited) {
                    setEdited(true);
                  }
                }}
                cid={{
                  id: state.cid,
                  short_description: state.cid_description,
                }}
                error={error && !state.cid}
              />
            </div>
          )}

          {state.cid && (
            <>
              <div className="col-md-4 col-12">
                <FormControl row>
                  <FormLabel>Status:</FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={state.status}
                    onChange={(e) => {
                      setState({
                        ...state,
                        status: e.target.value,
                      });
                      if (!edited) {
                        setEdited(true);
                      }
                    }}
                    className="mt-1"
                    row
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label="Suspeita"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label="Confirmado"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              {state.status && (
                <div className="col-md-8 col-12">
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={brLocale}
                  >
                    {state.status === "0" ? (
                      <KeyboardDatePicker
                        className="mr-3"
                        margin="normal"
                        label="Data da suspeita do diagnóstico:"
                        format="dd/MM/yyyy"
                        value={state.date_suspect}
                        onChange={(date) => {
                          setState({ ...state, date_suspect: date });
                          if (!edited) {
                            setEdited(true);
                          }
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        cancelLabel="Cancelar"
                        disableFuture={true}
                      />
                    ) : (
                      <KeyboardDatePicker
                        className="mr-3"
                        margin="normal"
                        label="Dt. confirmação diagnóstico:"
                        format="dd/MM/yyyy"
                        value={state.date_confirm}
                        onChange={(date) => {
                          setState({ ...state, date_confirm: date });
                          if (!edited) {
                            setEdited(true);
                          }
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        cancelLabel="Cancelar"
                        disableFuture={true}
                      />
                    )}
                  </MuiPickersUtilsProvider>
                </div>
              )}

              {state.status === "1" && (
                <div className="col-12">
                  <FormControl row>
                    <FormLabel>Doença crônica?</FormLabel>
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      value={state.is_cronic}
                      onChange={(e) => {
                        setState({
                          ...state,
                          is_cronic: e.target.value,
                        });
                        if (!edited) {
                          setEdited(true);
                        }
                      }}
                      className="mt-1"
                      row
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label="Não"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Sim"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}

              <div className="col-12">
                <TextField
                  multiline
                  rows={3}
                  value={state.observation}
                  onChange={(e) => {
                    setState({
                      ...state,
                      observation: e.target.value,
                    });
                    if (!edited) {
                      setEdited(true);
                    }
                  }}
                  label="História da moléstia atual:"
                  fullWidth
                />
              </div>
            </>
          )}
          <div className="col-12 mt-3">
            <BtnCan title="Fechar" onClick={handleCloseModal} float="left" />
            <BtnSave onClick={handleSave} float="right" />
          </div>
        </div>
      </DialogContent>
      <ConfirmCloseCollpase
        open={confirmClose}
        message="Alterações não salvas! Deseja sair sem salvar?"
        confirm={() => {
          setState(initial);
          setConfirmClose(false);
          setEdited(false);
          setError(false);
          handleClose();
        }}
        cancel={() => setConfirmClose(false)}
      />
    </Dialog>
  );
}
