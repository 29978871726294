import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.css";
import ConsultHistory from "./consult";
import PersonalData from "app/routes/consult/routes/personalData";
import PersonalHistory from "./personalHistory";
import Allergies from "./allergies";
import MedicinesInUse from "./medicinesInUse";
import Aga from "./aga";
import Circumferences from "./circumferences";
import Folds from "./folds";
import Exams from "./exams";
import Vaccines from "./vaccines";
import Disabilities from "./disabilities";
import FamilyHistory from "./familyHistory";
import ChildDiseases from "./childDisorders";
import ProcedureHistory from "./procedureHistory";
import PersonalHabits from "./personalHabits";
import Prescription from "./prescription";
import Forward from "./forward";
import SystemReview from "./systemReview";
import NursePrescription from "./nursePrescription";
import PainScale from "./painScale";
import PrescriptionCare from "./prescriptionCare";
import Chip from "@material-ui/core/Chip";
import HistoryIcon from "@material-ui/icons/History";
import Treatment from "./treatment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    // minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function HistoryInnerWidth({
  openModal,
  patient,
  doctor,
  closeModal,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState();
  const [state, setState] = React.useState({
    moduleHitory: [
      { name: "personalData" },
      { name: "prescription" },
      { name: "habits" },
    ],
    selectModule: "",
  });

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  function handleClose() {
    closeModal();
  }
  function handleChange(event) {
    setState({ ...state, selectModule: event.target.value });
  }
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{ color: "#6ebeda", textAlign: "center" }}
        >
          <Chip color="primary" icon={<HistoryIcon />} label="Histórico" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl
              className={`col-12 justify-content-center ${classes.formControl}`}
            >
              <InputLabel htmlFor="age-simple">Modulo</InputLabel>
              <Select
                value={state.selectModule}
                onChange={handleChange}
                style={{ background: "#f4f4f87d" }}
              >
                <MenuItem value={"consult"}>Atendimentos</MenuItem>
                <MenuItem value={"allergies"}>Alergias</MenuItem>
                {/* <MenuItem value={"aga"}>Avaliação Geriátrica Ampla</MenuItem> */}
                <MenuItem value={"disabilities"}>
                  Disfunções funcionais e outras
                </MenuItem>
                <MenuItem value={"childDiseases"}>Doenças da infância</MenuItem>
                <MenuItem value={"forward"}>Encaminhamento</MenuItem>
                <MenuItem value={"painScale"}>Escala de Dor</MenuItem>
                <MenuItem value={"exams"}>Exames</MenuItem>
                <MenuItem value={"habits"}>Hábitos</MenuItem>
                <MenuItem value={"familyHistory"}>Histórico familiar</MenuItem>
                <MenuItem value={"historyPerson"}>Histórico pessoal</MenuItem>
                <MenuItem value={"personalData"}>Informações pessoais</MenuItem>
                <MenuItem value={"medicines"}>Medicamentos</MenuItem>
                <MenuItem value={"prescriptionCare"}>
                  Prescrição de cuidados
                </MenuItem>
                <MenuItem value={"prescription"}>
                  Prescrição de medicamentos
                </MenuItem>
                <MenuItem value={"cbhpm"}>Procedimentos</MenuItem>
                {/* <MenuItem value={"systemReview"}>Revisão dos sistemas</MenuItem> */}
                {/* <MenuItem value={"nursePrescription"}>SAE - Idoso</MenuItem> */}
                <MenuItem value={"treatment"}>Tratamentos</MenuItem>
                <MenuItem value={"vaccines"}>Vacinas</MenuItem>
                {/* <MenuItem value={"folds"}>Dobras</MenuItem> */}
              </Select>
            </FormControl>
          </DialogContentText>
          {state.selectModule === "personalData" && (
            <PersonalData patient={patient.id} />
          )}
          {state.selectModule === "allergies" && (
            <Allergies patient={patient.id} />
          )}
          {state.selectModule === "consult" && (
            <ConsultHistory patient={patient.id} doctor={doctor} />
          )}
          {state.selectModule === "prescription" && (
            <Prescription patient={patient.id} doctor={doctor} />
          )}
          {state.selectModule === "historyPerson" && (
            <PersonalHistory patient={patient.id} />
          )}
          {state.selectModule === "medicines" && (
            <MedicinesInUse patient={patient.id} />
          )}
          {state.selectModule === "exams" && <Exams patient={patient.id} />}
          {state.selectModule === "vaccines" && (
            <Vaccines patient={patient.id} />
          )}
          {state.selectModule === "aga" && <Aga patient={patient.id} />}
          {state.selectModule === "systemReview" && (
            <SystemReview patient={patient.id} />
          )}
          {state.selectModule === "folds" && <Folds patient={patient.id} />}
          {state.selectModule === "circumferences" && (
            <Circumferences patient={patient.id} />
          )}
          {state.selectModule === "disabilities" && (
            <Disabilities patient={patient.id} />
          )}
          {state.selectModule === "familyHistory" && (
            <FamilyHistory patient={patient.id} />
          )}
          {state.selectModule === "childDiseases" && (
            <ChildDiseases patient={patient.id} />
          )}
          {state.selectModule === "cbhpm" && (
            <ProcedureHistory patient={patient.id} />
          )}
          {state.selectModule === "habits" && (
            <PersonalHabits patient={patient.id} />
          )}
          {state.selectModule === "forward" && <Forward patient={patient.id} />}
          {state.selectModule === "nursePrescription" && (
            <NursePrescription patient={patient.id} doctor={doctor} />
          )}
          {state.selectModule === "painScale" && (
            <PainScale patient={patient.id} />
          )}
          {state.selectModule === "prescriptionCare" && (
            <PrescriptionCare patient={patient.id} />
          )}
          {state.selectModule === "treatment" && (
            <Treatment patient={patient.id} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
