const INITIAL_STATE = {
  patients: []
};

export default function homecareManager(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_PATIENTS_HOME_CARE":
      return {
        ...state,
        patients: action.payload.patients
      };
    default:
      return state;
  }
}
