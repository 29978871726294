import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Button } from "@material-ui/core";
import Api from "services/api";

export default function ProfessionalData({
  handleNext,
  handleBack,
  getState,
  stateInit,
}) {
  const [state, setState] = useState(stateInit);
  const [areaList, setAreaList] = useState([]);
  const [councilList, setCouncilList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [specialtyList, setSpecialtyList] = useState([]);
  const { area, specialty, council, year, numberCouncil, uf, error } = state;
  const validate = () => {
    if (area && specialty) {
      setState({ ...state, error: false });
      getState(state, "professionalData");
      handleNext();
    } else {
      setState({ ...state, error: true });
    }
  };

  useEffect(() => {
    Api.post(`/ms_system/user/getAreaRegister`, {}).then((res) => {
      setAreaList(res.data.record);
      setCouncilList(res.data.council);
      setStatesList(res.data.states);
    });
  }, []);

  useEffect(() => {
    Api.post(`/ms_system/user/getSpecialty`, { area: state.area }).then(
      (res) => {
        setSpecialtyList(res.data.record);
      }
    );
  }, [state.area]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <TextField
            select
            id="area"
            required
            label="Area"
            margin="normal"
            fullWidth
            value={area}
            helperText={error && !area ? "Campo Obrigatório" : ""}
            error={error && !area}
            onChange={(e) => setState({ ...state, area: e.target.value })}
            style={{ textAlign: "left" }}
          >
            {areaList.length > 0 &&
              areaList.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
        <div className="col-md-6">
          <TextField
            select
            id="specialty"
            required
            label="Especialidade"
            margin="normal"
            fullWidth
            value={specialty}
            helperText={error && !specialty ? "Campo Obrigatório" : ""}
            error={error && !specialty}
            onChange={(e) => setState({ ...state, specialty: e.target.value })}
            style={{ textAlign: "left" }}
          >
            {specialtyList.length > 0 &&
              specialtyList.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </div>
      {["29", "43", "44", "45", "46"].indexOf(area) === -1 && (
        <>
          <div className="row">
            <div className="col-md-6">
              <TextField
                select
                id="council"
                label="Conselho"
                margin="normal"
                fullWidth
                value={council}
                onChange={(e) =>
                  setState({ ...state, council: e.target.value })
                }
                style={{ textAlign: "left" }}
              >
                {councilList.length > 0 &&
                  councilList.map((row) => (
                    <MenuItem value={row.id} key={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="col-md-6">
              <TextField
                id="numberCouncil"
                label="Número"
                type="number"
                margin="normal"
                fullWidth
                value={numberCouncil}
                onChange={(e) =>
                  setState({ ...state, numberCouncil: e.target.value })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <TextField
                select
                id="uf"
                label="UF"
                margin="normal"
                fullWidth
                value={uf}
                onChange={(e) => setState({ ...state, uf: e.target.value })}
                style={{ textAlign: "left" }}
              >
                {statesList.length > 0 &&
                  statesList.map((row) => (
                    <MenuItem value={row.fk_ls_state} key={row.fk_ls_state}>
                      {row.state}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="col-md-6">
              <TextField
                type="number"
                label="Data de Expedição"
                margin="normal"
                fullWidth
                value={year}
                onChange={(e) => setState({ ...state, year: e.target.value })}
              />
            </div>
          </div>
        </>
      )}
      <div
        className="align-center mt-4"
        // style={{ justifyContent: "space-between" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={validate}
          className="jr-btn"
          style={{ float: "right", marginRight: 0 }}
        >
          Próxima
        </Button>
        <Button
          className="jr-btn"
          onClick={handleBack}
          style={{ float: "left", marginLeft: 0 }}
        >
          Voltar
        </Button>
      </div>
    </>
  );
}
