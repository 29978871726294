import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const ConfirmCloseCollpase = ({ open, message, confirm, cancel }) => (
  <SweetAlert
    show={open}
    warning
    showCancel
    confirmBtnBsStyle="danger"
    cancelBtnBsStyle="primary"
    confirmBtnText="Sim, sair"
    confirmButtonColor="#bc0003"
    cancelBtnText="Cancelar"
    title={message}
    onConfirm={confirm}
    onCancel={cancel}
  />
);

export default ConfirmCloseCollpase;
