import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { modalVideo } from "actions/comunication/Chat";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomScrollbars from "util/CustomScrollbars";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  FIXED_DRAWER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  INSIDE_THE_HEADER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import NotificationItem from "../AppNotification/NotificationItem";
import { switchLanguage, toggleCollapsedNav } from "actions/Setting";
import { setHasNotification } from "actions/comunication/Chat";
import IntlMessages from "util/IntlMessages";
import Menu from "components/TopNav/Menu";
import UserInfoPopup from "components/UserInfo/UserInfoPopup";
import "./style.css";
import ChatDraggable from "components/chatPanel/ChatDraggable";
import DraggableVideo from "components/chatPanel/DraggableVideo";
import ChatModal from "components/chatPanel/ChatModal";
import { showChatModal } from "actions/comunication/Chat";
import NoRecord from "components/Alert/NoRecord";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import AlertMessage from "components/Alert/AlertMessage";
import { BtnCan } from "components/Button";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import Calendar from "components/calendar";
import WaitingRoom from "components/calendar/WaitingRoom";

// import BottomNavigation from "@material-ui/core/BottomNavigation";
// import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import HomeIcon from "@material-ui/icons/Home";
import EventNoteIcon from "@material-ui/icons/EventNote";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

//Import FireBase
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import api from "services/api";
import ContactList from "./ContactList";
import Tutorial from "./Tutorial";
import moment from "moment";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
class Header extends React.Component {
  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification,
      view_notification: true,
    });
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification,
    });
  };
  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget,
    });
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox,
    });
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps,
    });
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo,
    });
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false,
    });
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  getUrlPhoto = (id, photo) => {
    if (photo) {
      return `https://s3.sa-east-1.amazonaws.com/salutem-webapp-files/upload/user/${id}/${photo}`;
    } else {
      return "https://balancer.salutemplus.com/images/avatar_user_men.jpg";
    }
  };

  handleChange = (value) => {
    this.props.history.push(`/app/dashboard/${value}`);
    this.setState({ navActivPatient: value });
  };

  handleRemoveItem = (values) => {
    getDataFirebase()
      .child(
        `notification${getEnviromentFirebase()}/${
          this.props.authUser
        }/${values}`
      )
      .remove((err) => {
        if (err) {
          console.log(err);
        }
      });
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: "",
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      notifications: [],
      view_notification: true,
      drawer: false,
      tutorial: false,
      contacts: [],
      searchContact: "",
      modalCall: false,
      callData: {},
      modalCalendar: false,
      modalWaiting: false,
      navActivPatient: "",
    };
  }

  componentDidMount() {
    getDataFirebase()
      .child(`notification${getEnviromentFirebase()}/` + this.props.authUser)
      .on("value", (snap) => {
        let values = snap.val();
        if (values !== null) {
          Object.keys(values).forEach((row) => {
            if (
              (moment()
                .subtract(1, "minute")
                .format("DD/MM/YYYY HH:mm") === values[row].date ||
                moment().format("DD/MM/YYYY HH:mm") === values[row].date) &&
              row !== this.state.callData.id
            ) {
              if (values[row].type === "videoconference") {
                this.setState({
                  modalCall: true,
                  callData: {
                    ...values[row],
                    id: row,
                  },
                });
              }
              if (values[row].type === "reject_videoconference") {
                this.props.viewAlertMessage("error", values[row].title);
                setTimeout(() => this.props.closeAlertMessage(), 5000);
              }
            }
          });
        }

        this.setState({
          notifications: values,
          view_notification: !(values === null
            ? false
            : Object.keys(values)
                .map((row) => values[row])
                .filter((row) => row.read === false).length > 0),
        });
      });
    api
      .post(`/ms_comunication/chat/getContactList/`, {
        user: this.props.authUser,
      })
      .then(({ data }) => {
        this.setState({
          contacts: data.map((row) => ({
            ...row,
            thumb: this.getUrlPhoto(row.id, row.thumb),
          })),
        });
      });
  }

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }

  rejectCall() {
    getDataFirebase()
      .child(
        `notification${getEnviromentFirebase()}/` +
          this.state.callData.user_from
      )
      .push({
        user_from: this.props.authUser,
        user_to: this.state.callData.user_from,
        date: moment().format("DD/MM/YYYY HH:mm"),
        title: `${this.props.name} rejeitou sua videochamada.`,
        type: "reject_videoconference",
        action: "#",
        image: this.props.photo,
        icon: "zmdi-comment-video",
        read: false,
      });
    this.setState({ modalCall: false });
  }

  Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        <li key="0" className="jr-list-item">
          <Link className="jr-list-link" to="/app/calendar/basic">
            <i className="zmdi zmdi-calendar zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.calendar.basic" />
            </span>
          </Link>
        </li>

        <li key="1" className="jr-list-item">
          <Link className="jr-list-link" to="/app/to-do">
            <i className="zmdi zmdi-check-square zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.toDo" />
            </span>
          </Link>
        </li>

        <li key="2" className="jr-list-item">
          <Link className="jr-list-link" to="/app/mail">
            <i className="zmdi zmdi-email zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.mail" />
            </span>
          </Link>
        </li>

        <li key="3" className="jr-list-item">
          <Link className="jr-list-link" to="/app/chat">
            <i className="zmdi zmdi-comment zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.chat" />
            </span>
          </Link>
        </li>

        <li key="4" className="jr-list-item">
          <Link className="jr-list-link" to="/app/contact">
            <i className="zmdi zmdi-account-box zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.contact" />
            </span>
          </Link>
        </li>

        <li key="5" className="jr-list-item">
          <Link className="jr-list-link" to="/">
            <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw" />
            <span className="jr-list-text">Add New</span>
          </Link>
        </li>
      </ul>
    );
  };

  render() {
    const {
      drawerType,
      navigationStyle,
      horizontalNavPosition,
      authUser,
      address_selected,
      history,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "d-block d-xl-none"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "d-block"
      : "d-none";

    return (
      <>
        <AppBar
          style={this.props.left !== undefined ? { left: 0 } : {}}
          className={`app-main-header ${
            navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === BELOW_THE_HEADER
              ? "app-main-header-top"
              : ""
          }`}
        >
          <Toolbar
            className="app-toolbar "
            style={{ margin: "-6px", justifyContent: "space-between" }}
            disableGutters={false}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION ? (
              <div
                className="d-block d-md-none pointer "
                onClick={this.onToggleCollapsedNav}
              >
                <span className="jr-menu-icon">
                  <span
                    className="menu-icon"
                    style={{ background: "#074C5E" }}
                  />
                </span>
              </div>
            ) : (
              <IconButton
                className={`jr-menu-icon  ${drawerStyle}`}
                aria-label="Menu"
                onClick={this.onToggleCollapsedNav}
              >
                <span className="menu-icon" style={{ background: "#074C5E" }} />
              </IconButton>
            )}
            {/* <div> */}
            <span
              className="mr-2 d-sm-block logo-app"
              style={{
                height: "28px",
                width: "100px",
                cursor: "pointer",
              }}
              onClick={() => this.props.history.push(`/app/dashboard`)}
            >
              {window.location.hostname === "senior.salutemplus.com" ? (
                <img
                  src={require("assets/images/Logo_Salutem_Senior-01.svg")}
                  alt="salutemsenior"
                  title="salutemsenior"
                />
              ) : (
                <img
                  src={require("assets/images/Logo_Salutem_Plus-01.svg")}
                  alt="salutem+"
                  title="salutem+"
                  width="100px"
                />
              )}
            </span>
            {/* </div> */}

            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

            {!this.props.history.location.pathname.includes(
              "prescription-signed"
            ) && (
              <ul
                className={`header-notifications list-inline ${
                  this.props.width < 500
                    ? "d-flex align-items-baseline"
                    : "ml-auto"
                }`}
              >
                <li key="0" className="list-inline-item mail-tour">
                  <Tooltip title="Tutorial">
                    <IconButton
                      onClick={() =>
                        this.setState({ ...this.state, tutorial: true })
                      }
                    >
                      <HelpOutlineIcon
                        style={{
                          color:
                            this.state.navActivPatient === "feed"
                              ? "#1195c2"
                              : "#074c5e",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </li>
                {this.props.type === "3" &&
                  authUser &&
                  history.location.pathname.includes("app/dashboard") &&
                  this.props.width >= 960 && (
                    <>
                      {" "}
                      <li key="0" className="list-inline-item mail-tour">
                        <Tooltip title="Ultima Notícias">
                          <IconButton
                            onClick={() => this.handleChange("feed")}
                            style={
                              this.state.navActivPatient === "feed"
                                ? {
                                    background: "#074c5e",
                                  }
                                : {}
                            }
                          >
                            <HomeIcon
                              style={{
                                color:
                                  this.state.navActivPatient === "feed"
                                    ? "#1195c2"
                                    : "#074c5e",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </li>
                      <li key="1" className="list-inline-item mail-tour">
                        <Tooltip title="Check up Diário">
                          <IconButton
                            onClick={() => this.handleChange("patient-quizes")}
                            style={
                              this.state.navActivPatient === "patient-quizes"
                                ? {
                                    background: "#074c5e",
                                  }
                                : {}
                            }
                          >
                            <FilterNoneIcon
                              style={{
                                color:
                                  this.state.navActivPatient ===
                                  "patient-quizes"
                                    ? "#1195c2"
                                    : "#074c5e",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </li>
                      <li key="2" className="list-inline-item mail-tour">
                        <Tooltip title="Históricos de Saúde">
                          <IconButton
                            onClick={() => this.handleChange("patient-graphic")}
                            style={
                              this.state.navActivPatient === "patient-graphic"
                                ? {
                                    background: "#074c5e",
                                  }
                                : {}
                            }
                          >
                            <EqualizerIcon
                              style={{
                                color:
                                  this.state.navActivPatient ===
                                  "patient-graphic"
                                    ? "#1195c2"
                                    : "#074c5e",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </li>
                      <li key="3" className="list-inline-item mail-tour">
                        <Tooltip title="Compromissos">
                          <IconButton
                            onClick={() =>
                              this.handleChange("calendar-patient")
                            }
                            style={
                              this.state.navActivPatient === "calendar-patient"
                                ? {
                                    background: "#074c5e",
                                  }
                                : {}
                            }
                          >
                            <EventNoteIcon
                              style={{
                                color:
                                  this.state.navActivPatient ===
                                  "calendar-patient"
                                    ? "#1195c2"
                                    : "#074c5e",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </li>
                      <li key="4" className="list-inline-item mail-tour">
                        <Tooltip title="Resumo clínico">
                          <IconButton
                            onClick={() => this.handleChange("patient-info")}
                            style={
                              this.state.navActivPatient === "patient-info"
                                ? {
                                    background: "#074c5e",
                                  }
                                : {}
                            }
                          >
                            <AccountCircleIcon
                              style={{
                                color:
                                  this.state.navActivPatient === "patient-info"
                                    ? "#1195c2"
                                    : "#074c5e",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </li>
                    </>
                  )}

                {authUser &&
                  address_selected.id &&
                  ["5", "55"].indexOf(address_selected.provider_type) ===
                    -1 && (
                    <li key="5" className="list-inline-item mail-tour">
                      <Tooltip title="Sala de espera">
                        <IconButton
                          className="icon-btn"
                          onClick={() => this.setState({ modalWaiting: true })}
                        >
                          <LocalLibraryIcon style={{ color: "#074C5E" }} />
                        </IconButton>
                      </Tooltip>
                    </li>
                  )}

                {authUser &&
                  address_selected.id &&
                  ["5", "55"].indexOf(address_selected.provider_type) ===
                    -1 && (
                    <li key="6" className="list-inline-item mail-tour">
                      <Tooltip title="Agenda">
                        <IconButton
                          className="icon-btn"
                          onClick={() => this.setState({ modalCalendar: true })}
                        >
                          <i
                            style={{ color: "#074C5E" }}
                            className="zmdi zmdi-calendar zmdi-hc-fw"
                          />
                        </IconButton>
                      </Tooltip>
                    </li>
                  )}

                {/* Notificações */}
                {authUser && (
                  <li key="7" className="list-inline-item app-tour">
                    <Dropdown
                      className="quick-menu"
                      isOpen={this.state.appNotification}
                      // toggle={this.onAppNotificationSelect.bind(this)}
                      toggle={() =>
                        this.setState({
                          appNotification: true,
                          view_notification: true,
                        })
                      }
                      onClose={() =>
                        this.setState({
                          appNotification: false,
                        })
                      }
                    >
                      <DropdownToggle
                        className="d-inline-block"
                        tag="span"
                        data-toggle="dropdown"
                      >
                        <IconButton className="icon-btn">
                          {this.state.view_notification ? (
                            <i
                              className="zmdi zmdi-notifications-none"
                              style={{ color: "#074C5E" }}
                            />
                          ) : (
                            <i
                              className="zmdi zmdi-notifications-none icon-alert animated infinite wobble"
                              style={{ color: "#074C5E" }}
                            />
                          )}
                        </IconButton>
                      </DropdownToggle>

                      <DropdownMenu right style={{ padding: "unset" }}>
                        <div
                          className={`jr-card-header p-2 m-0 align-items-start`}
                        >
                          <div className="row align-items-center">
                            <div className="col-9">
                              <h3 className="card-heading">Notificações</h3>
                            </div>
                            <div className="col-3">
                              <IconButton
                                color="secondary"
                                style={{ float: "right" }}
                                onClick={() =>
                                  getDataFirebase()
                                    .child(
                                      `notification${getEnviromentFirebase()}/` +
                                        this.props.authUser
                                    )
                                    .remove()
                                }
                              >
                                <ClearAllIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>

                        <CustomScrollbars
                          className="messages-list scrollbar"
                          style={{ height: 280 }}
                        >
                          <ul className="list-unstyled">
                            {this.state.notifications ? (
                              Object.keys(this.state.notifications)
                                .reverse()
                                .filter((f, i) => i < 10)
                                .map((key, index) => (
                                  <>
                                    <NotificationItem
                                      key={index}
                                      userKey={key}
                                      removeItemId={this.handleRemoveItem}
                                      notification={
                                        this.state.notifications[key]
                                      }
                                      history={this.props.history}
                                      database={getDataFirebase().child(
                                        `notification${getEnviromentFirebase()}/` +
                                          this.props.authUser
                                      )}
                                    />
                                  </>
                                ))
                            ) : (
                              <NoRecord />
                            )}
                          </ul>
                        </CustomScrollbars>
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                )}

                {/* Chat List */}
                {authUser && (
                  <li key="8" className="list-inline-item mail-tour">
                    <Tooltip title="Chat">
                      <IconButton
                        className="icon-btn"
                        onClick={() => this.setState({ drawer: true })}
                      >
                        <i
                          style={{ color: "#074C5E" }}
                          className="zmdi zmdi-comment-alt-text zmdi-hc-fw"
                        />
                      </IconButton>
                    </Tooltip>
                    {this.state.drawer && (
                      <SwipeableDrawer
                        disableBackdropTransition={!iOS}
                        disableDiscovery={iOS}
                        anchor="right"
                        open={this.state.drawer}
                        onClose={() => this.setState({ drawer: false })}
                        onOpen={() => this.setState({ drawer: true })}
                      >
                        <ContactList
                          contacts={this.state.contacts}
                          closeDrawer={() => this.setState({ drawer: false })}
                        />
                      </SwipeableDrawer>
                    )}
                  </li>
                )}

                {navigationStyle === HORIZONTAL_NAVIGATION && (
                  <li key="9" className="list-inline-item user-nav">
                    <Dropdown
                      className="quick-menu"
                      isOpen={this.state.userInfo}
                      toggle={this.onUserInfoSelect.bind(this)}
                    >
                      <DropdownToggle
                        className="d-inline-block"
                        tag="span"
                        data-toggle="dropdown"
                      >
                        <IconButton className="icon-btn size-30">
                          <Avatar
                            alt="..."
                            src={"https://via.placeholder.com/150x150"}
                            className="size-30"
                          />
                        </IconButton>
                      </DropdownToggle>

                      <DropdownMenu right>
                        <UserInfoPopup />
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                )}
              </ul>
            )}
            <div className="ellipse-shape" />
          </Toolbar>
        </AppBar>
        <ChatDraggable />
        <DraggableVideo chatId={this.props.chatId} />
        <ChatModal />
        <AlertMessage
          open={this.props.alert.alert_message}
          message={this.props.alert.text_message}
          type={this.props.alert.message_type}
        />
        <Dialog open={this.state.modalCall} maxWidth="xs">
          <DialogTitle className="text-primary">
            Convite para videochamada
          </DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-12"></div>
            </div>
            {this.state.callData.title}?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.rejectCall()} color="secondary">
              Recusar
            </Button>
            <Button
              onClick={() => {
                this.setState({ modalCall: false });
                this.props.modalVideo(this.state.callData.action);
                // this.props.history.push(`/app/${this.state.callData.action}`);
              }}
              color="primary"
            >
              Aceitar
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.modalCalendar && (
          <Dialog open={this.state.modalCalendar} maxWidth="lg">
            <DialogContent>
              <div className="row">
                <div className="col-md-12 col-12">
                  <h2 className="text-primary font-weight-semibold">
                    Agenda
                    <Tooltip title="Fechar" placement="top">
                      <IconButton
                        aria-label="Fechar"
                        onClick={() => this.setState({ modalCalendar: false })}
                        style={{ float: "right", marginTop: "-10px" }}
                      >
                        <i className="zmdi zmdi-close" />
                      </IconButton>
                    </Tooltip>
                  </h2>
                </div>
                <div className="col-12">
                  <Calendar />
                </div>
                <div className="col-12">
                  <BtnCan
                    onClick={() => this.setState({ modalCalendar: false })}
                    float="left"
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
        <WaitingRoom
          open={this.state.modalWaiting}
          handleClose={() => this.setState({ modalWaiting: false })}
        />
        {this.state.tutorial && (
          <Tutorial
            open={this.state.tutorial}
            onCLose={() => this.setState({ ...this.state, tutorial: false })}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ settings, auth, chat, alert }) => {
  const {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
    width,
  } = settings;
  const {
    secretary,
    authUser,
    name,
    photo,
    user_manage,
    address_selected,
    type,
  } = auth;
  return {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
    secretary,
    authUser,
    name,
    photo,
    user_manage,
    width,
    hasNotification: chat.hasNotification,
    chatId: chat.modalVideo,
    alert,
    address_selected,
    type,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    toggleCollapsedNav,
    switchLanguage,
    setHasNotification,
    showChatModal,
    viewAlertMessage,
    closeAlertMessage,
    modalVideo,
  })(Header)
);
