import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function AutocompleteGeneric(props) {
  const {
    onChange,
    onInputChange,
    label,
    loading,
    margin,
    variant,
    error,
    required,
    clearOnBlur,
    blurOnSelect,
    value,
    shrink,
    maskName,
    disabled,
    autoFocus,
  } = props;

  return (
    <Autocomplete
      {...props}
      closeText={"Fechar"}
      noOptionsText={"Nenhuma opção correspondente"}
      openText={"Abrir"}
      loadingText={"Carregando..."}
      clearText={"Limpar"}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={(event, newValue) =>
        onInputChange ? onInputChange(newValue) : {}
      }
      disableCloseOnSelect={true}
      autoSelect={true}
      clearOnBlur={clearOnBlur}
      blurOnSelect={blurOnSelect}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          label={label}
          error={error}
          disabled={disabled}
          required={required}
          value={value}
          margin={margin ? margin : "normal"}
          variant={variant ? variant : "standard"}
          InputLabelProps={{ shrink }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
            form: {
              autocomplete: "off",
            },
          }}
          autoComplete="new-password"
          InputProps={{
            ...params.InputProps,
            onInput: (e) => {
              if (maskName && e.target.value) {
                let formtCase = e.target.value.split(" ").map((row) => {
                  return row === "Da" ||
                    row === "da" ||
                    row === "De" ||
                    row === "de" ||
                    row === "Dos" ||
                    row === "dos"
                    ? row.charAt(0).toLowerCase() + row.slice(1).toLowerCase()
                    : row.charAt(0).toUpperCase() + row.slice(1).toLowerCase();
                });
                e.target.value = formtCase.join(" ");
              }
            },
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
