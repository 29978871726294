import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Api from "services/api";
import { debounce, showMsg } from "services/functions";
import AutocompleteGeneric from "./AutocompleteGeneric";

export default function AutocompleteParents({
  handleChange,
  value,
  error,
  patient,
  label,
}) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);

  const getOptions = async (term) => {
    if (term.length > 2) {
      setLoader(true);
      try {
        const { data } = await Api.post(
          "/microservice/autocomplete/getUserParents/",
          {
            term: term,
            patient,
          }
        );

        setOptions(data);
      } catch (err) {
        showMsg("error", "Ocorreu um erro na busca", dispatch);
        setOptions([]);
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <AutocompleteGeneric
      multiple={false}
      limitTags={1}
      filterOptions={(x) => x}
      disableCloseOnSelect
      onChange={(obj) => {
        if (obj) {
          handleChange(obj);
        } else {
          handleChange({
            id: "",
            label: "",
          });
        }
      }}
      onInputChange={debounce(getOptions)}
      value={value ? value : null}
      getOptionLabel={(option) => option.label}
      label={label}
      options={options}
      loading={loader}
      blurOnSelect={true}
      clearOnBlur={true}
      shrink={true}
      error={error}
      maskName={true}
    />
  );
}
