import { delay } from "redux-saga";
import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import api from "services/api";

const cadDysf = async (doctor, patient, dataCad, notApplicable) => {
  const response = await api.post(`/microservice/dysfunctions/cadDysf/`, {
    doctor,
    patient,
    dataCad,
    notApplicable,
  });

  return response;
};

const getCadHistory = async (patient) => {
  const response = await api.post(`/microservice/dysfunctions/getCadList/`, {
    patient,
  });

  const data = await response.data.record;
  const aux = [];
  data.forEach((element) => {
    element = {
      ...element,
      check: false,
    };
    aux.push(element);
  });
  return aux;
};

//Listagem das disfunções cadastradas
const userDysf = async (patient) => {
  const response = await api.post(`/microservice/dysfunctions/getUserDysf/`, {
    patient,
  });

  const data = await response.data.record;
  const aux = [];
  data.forEach((element) => {
    element = {
      ...element,
      check: false,
    };
    aux.push(element);
  });
  return aux;
};

/* SN MICROSERVICES*/
const getCadHistorySN = async (patient) => {
  const response = await api.post(
    `/microservice/dysfunctions/getCadListSN/`,
    {}
  );

  const data = await response.data.record;
  const aux = [];
  data.forEach((element) => {
    element = {
      ...element,
      check: false,
    };
    aux.push(element);
  });
  return aux;
};

const cadSn = async (doctor, patient, dataCad, notApplicable) => {
  const response = await api.post(`/microservice/dysfunctions/cadSn/`, {
    doctor,
    patient,
    dataCad,
    notApplicable,
  });
  return response;
};

const userSn = async (patient) => {
  const response = await api.post(`/microservice/dysfunctions/getUserSN/`, {
    patient,
  });

  const data = await response.data.record;
  const aux = [];
  data.forEach((element) => {
    element = {
      ...element,
      check: false,
    };
    aux.push(element);
  });
  return aux;
};

/*ASYNC DYSNFUNCTIONS*/
function* asyncCadDysf(action) {
  try {
    const listDysf = yield call(
      cadDysf,
      action.payload.doctor,
      action.payload.patient,
      action.payload.data,
      action.payload.notApplicable
    );
    yield put({
      type: "REQUEST_CAD_DYSF_SUCCESS",
      payload: { listDysf: listDysf },
    });
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "success",
      text_message: "Salvo com sucesso!",
    });
    yield put({
      type: "HANDLE_ADD_FUNC",
      payload: {
        active: "view",
        color: "primary",
        texto: "Editar",
        hidden: "hidden",
        icon: "edit",
      },
    });
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncDysfList(action) {
  try {
    const listDysf = yield call(getCadHistory, action.patient);
    yield put({
      type: "REQUEST_LIST_DYSF_SUCCESS",
      payload: { listDysf: listDysf },
    });
  } catch (error) {
    console.log(error);
  }
}

//Listagem das disfunções cadastradas para o respectivo usuario
function* asyncUserDysf(action) {
  try {
    const listuserDysfs = yield call(userDysf, action.payload.patient);
    yield put({
      type: "REQUEST_USER_DYSF_SUCCESS",
      payload: { listuserDysfs: listuserDysfs },
    });
  } catch (error) {
    console.log(error);
  }
}

/* ASYNC ESPECIAL NEEDS */

//Busco opções cadastradas para necessidades especiais na table type
function* asyncSNList(action) {
  try {
    const listSn = yield call(getCadHistorySN);
    yield put({
      type: "REQUEST_LIST_SN_SUCCESS",
      payload: { listSn: listSn },
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncCadSN(action) {
  try {
    const listSn = yield call(
      cadSn,
      action.payload.doctor,
      action.payload.patient,
      action.payload.data,
      action.payload.notApplicable
    );
    yield put({
      type: "REQUEST_CAD_SN_SUCCESS",
      payload: { listSn: listSn },
    });
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "success",
      text_message: "Salvo com sucesso!",
    });
    yield put({
      type: "HANDLE_ADD_SN_FUNC",
      payload: {
        active: "view",
        color: "primary",
        texto: "Editar",
        hidden: "hidden",
        icon: "edit",
      },
    });
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncUserSn(action) {
  try {
    const listuserSn = yield call(userSn, action.payload.patient);
    yield put({
      type: "REQUEST_USER_SN_SUCCESS",
      payload: { listuserSn: listuserSn },
    });
  } catch (error) {
    console.log(error);
  }
}
/*FIM ESPECIAL NEEDS*/

export function* requestListDysf() {
  yield takeEvery("REQUEST_LIST_DYSF", asyncDysfList);
}

export function* requestCadDysf() {
  yield takeEvery("SAVE_DYSF", asyncCadDysf);
}
//Listagem das disfunções cadastradas
export function* requestUserDysf() {
  yield takeEvery("REQUEST_USER_DYSFUNCTIONS", asyncUserDysf);
}

/* SPECIAL NEEDS*/
export function* requestListSN() {
  yield takeEvery("REQUEST_LIST_DYSF", asyncSNList);
}

export function* requestCadSN() {
  yield takeEvery("SAVE_SN", asyncCadSN);
}

export function* requestUserSn() {
  yield takeEvery("REQUEST_USER_SN", asyncUserSn);
}

/* EXPORT SPECIAL NEEDS*/

export default function* rootSaga() {
  yield all([
    fork(requestListDysf),
    fork(requestCadDysf),
    fork(requestUserDysf),
    //FORK NECESSIDADES ESPECIAIS
    fork(requestListSN),
    fork(requestCadSN),
    fork(requestUserSn),
  ]);
}
