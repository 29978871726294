import React, { useEffect } from "react";

import {
  MenuItem,
  TextField,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
} from "@material-ui/core/";
import moment from "moment";
import Decubito_Dorsal from "assets/images/prescriptioncare/Decubito_Dorsal.jpg";
import Decubito_Lateral_Direito from "assets/images/prescriptioncare/Decubito_Lateral_Direito.jpg";
import Decubito_Lateral_Esquerdo from "assets/images/prescriptioncare/Decubito_Lateral_Esquerdo.jpg";

export default function Decubits({ state, setState, error, pattern }) {
  useEffect(() => {
    if (state.quantity !== state.hours.length) {
      let arr = [];
      for (let index = 0; index < state.quantity; index++) {
        arr.push("");
      }
      setState({ ...state, hours: arr, decubit: arr });
    }
  }, [state.quantity]);

  useEffect(() => {
    setState({
      ...state,
      hours: getArrInterval(
        state.quantity,
        state.hours[0],
        state.time_interval
      ),
    });
  }, [state.hours[0]]);

  const crateArr = (tam) => {
    let arr = [];
    for (let i = 0; i < tam; i++) {
      arr.push("");
    }
    return arr;
  };

  useEffect(() => {
    switch (state.time_interval) {
      case 1:
        setState({
          ...state,
          quantity: 24,
          decubit: crateArr(24),
          hours: getArrInterval(24, "00:00", 1),
        });
        break;
      case 2:
        setState({
          ...state,
          quantity: 12,
          decubit: crateArr(12),
          hours: getArrInterval(12, "00:00", 2),
        });
        break;
      case 3:
        setState({
          ...state,
          quantity: 8,
          decubit: crateArr(8),
          hours: getArrInterval(8, "00:00", 3),
        });
        break;
      case 4:
        setState({
          ...state,
          quantity: 6,
          decubit: crateArr(6),
          hours: getArrInterval(6, "00:00", 4),
        });
        break;
      case 6:
        setState({
          ...state,
          quantity: 4,
          decubit: crateArr(4),
          hours: getArrInterval(4, "00:00", 6),
        });
        break;
      case 8:
        setState({
          ...state,
          quantity: 3,
          decubit: crateArr(3),
          hours: getArrInterval(3, "00:00", 8),
        });
        break;
      default:
        break;
    }
  }, [state.time_interval]);

  useEffect(() => {
    if (state.duration) {
      let diff = state.duration - 1;
      if (diff === 0) {
        setState({ ...state, end: state.start });
      } else {
        setState({
          ...state,
          end: moment(state.start.toString())
            .add(diff, "days")
            .format("YYYY-MM-DD"),
        });
      }
    }
  }, [state.start, state.duration]);

  useEffect(() => {
    let start = state.start.toString();
    let end = state.end.toString();
    let diff = moment(end).diff(moment(start), "days") + 1;
    setState({ ...state, duration: diff });
  }, [state.end]);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const handleChangeHour = (index, value) => {
    let aux = state.hours.map((row, key) => (key === index ? value : row));
    setState({ ...state, hours: aux });
  };

  const handleChangeDecubit = (index, value) => {
    let aux = state.decubit.map((row, key) => (key === index ? value : row));
    setState({ ...state, decubit: aux });
  };

  const getArrInterval = (index, ini, interval) => {
    let arr = [];
    for (let i = 0; i < index; i++) {
      if (i === 0) {
        arr.push(moment(ini, "HH:mm").format("HH:mm"));
      } else {
        arr.push(
          moment(ini, "HH:mm")
            .add(interval * i, "hours")
            .format("HH:mm")
        );
      }
    }
    return arr;
  };

  const getImgDecubit = (decubit) => {
    switch (decubit) {
      case "Dorsal":
        return (
          <img
            alt="imagem do sistema"
            className="img-fluid"
            src={Decubito_Dorsal}
          />
        );
      case "Lateral Direito":
        return (
          <img
            alt="imagem do sistema"
            className="img-fluid"
            src={Decubito_Lateral_Direito}
          />
        );
      case "Lateral Esquerdo":
        return (
          <img
            alt="imagem do sistema"
            className="img-fluid"
            src={Decubito_Lateral_Esquerdo}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    if (state.decubit[0]) {
      setDecubts();
    }
  }, [state.decubit[0]]);

  const setDecubts = () => {
    let newDecubt = [];
    state.decubit.forEach((el, index) => {
      if (index !== 0) {
        newDecubt.push(proxDecubit(newDecubt[index - 1]));
      } else {
        newDecubt.push(el);
      }
    });
    handleChange("decubit", newDecubt);
  };

  const proxDecubit = (antDecubit) => {
    switch (antDecubit) {
      case "Dorsal":
        return "Lateral Direito";
      case "Lateral Direito":
        return "Lateral Esquerdo";
      default:
        return "Dorsal";
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 col-12" style={{ paddingTop: "1.2rem" }}>
          <TextField
            id="time_interval"
            select
            label="Intervalo:"
            value={state.time_interval}
            onChange={(e) => handleChange("time_interval", e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            // error={error && !state.care}
            // helperText={error && !state.care && "Selecione um cuidado"}
          >
            <MenuItem value={1}>1 Hora</MenuItem>
            <MenuItem value={2}>2 Horas</MenuItem>
            <MenuItem value={3}>3 Horas</MenuItem>
            <MenuItem value={4}>4 Horas</MenuItem>
            <MenuItem value={6}>6 Horas</MenuItem>
            <MenuItem value={8}>8 Horas</MenuItem>
          </TextField>
        </div>
      </div>
      {state.hours.map((row, index) => (
        <div className="row" key={index}>
          <div className="col-2" style={{ paddingTop: "1.2rem" }}>
            <TextField
              type="time"
              label="Horário:"
              value={row}
              onChange={(e) => handleChangeHour(index, e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={error && !row}
            />
          </div>
          <div className="col-7" style={{ paddingTop: "1.2rem" }}>
            <TextField
              id="decubit"
              select
              label="Decúbito:"
              value={state.decubit[index]}
              onChange={(e) => handleChangeDecubit(index, e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              // error={error && !state.care}
              // helperText={error && !state.care && "Selecione um cuidado"}
            >
              <MenuItem value={"Dorsal"}>Decúbito Dorsal</MenuItem>
              <MenuItem value={"Lateral Direito"}>
                Decúbito Lateral Direito
              </MenuItem>
              <MenuItem value={"Lateral Esquerdo"}>
                Decúbito Lateral Esquerdo
              </MenuItem>
            </TextField>
          </div>
          <div className="col-3">{getImgDecubit(state.decubit[index])}</div>
        </div>
      ))}
      <div className="row">
        {!pattern && (
          <div className="col-md-4 col-6" style={{ marginTop: "-5px" }}>
            <FormControl className="my-3" fullWidth>
              <FormHelperText>Duração:</FormHelperText>
              <Input
                id="weight"
                value={state.duration}
                onChange={(e) => handleChange("duration", e.target.value)}
                endAdornment={
                  <InputAdornment position="end">dias</InputAdornment>
                }
                inputProps={{ maxLength: 3 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                error={error && parseInt(state.duration) <= 0}
              />
            </FormControl>
          </div>
        )}

        <div className="col-md-4 col-6 mt-3">
          <TextField
            type="date"
            label="Início:"
            value={state.start}
            onChange={(e) => handleChange("start", e.target.value)}
            fullWidth
          />
        </div>
        {!pattern && (
          <div className="col-md-4 col-6 mt-3">
            <TextField
              type="date"
              label="Fim:"
              value={state.end}
              onChange={(e) => handleChange("end", e.target.value)}
              error={error && moment(state.end).isBefore(moment(state.start))}
              fullWidth
            />
          </div>
        )}
        <div className="col-md-12">
          <TextField
            multiline
            rows={4}
            margin="normal"
            id="obs"
            label="Orientações:"
            type="text"
            fullWidth
            onChange={(e) => handleChange("observation", e.target.value)}
            value={state.observation}
          />
        </div>
      </div>
    </>
  );
}
