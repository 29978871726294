import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import ContainerHeader from "components/ContainerHeader";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import Api from "services/api";
import apiFile from "services/apiFile";

import Header from "components/Header/index";
import PersonalData from "./PersonalData";
import ProfessionalData from "./doctorForms/ProfessionalData";
import Photo from "./ComponentPhoto";
import Terms from "./ComponentTerms";

function getSteps() {
  return ["Dados Pessoais", "Dados Profissionais", "Adicionar Foto", "Termos"];
}

export default function RegisterDoctor({ match }) {
  const initState = {
    activeStep: 0,
    skipped: new Set(),
  };
  const initValidate = {
    cpf: true,
    username: true,
    email: true,
    password: false,
  };
  const initData = {
    personalData: {
      date: "",
      id: "",
      idData: "",
      idNaturalty: "",
      name: "",
      username: "",
      cpf: "",
      sex: "",
      naturalty: "",
      email: "",
      cellphone: "",
      password: "",
      confirmPassword: "",
      error: false,
    },
    professionalData: {
      area: "",
      specialty: "",
      council: "",
      numberCouncil: "",
      uf: "",
      year: "",
      error: false,
    },
    photo: { image: "" },
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PersonalData
            handleNext={handleNext}
            getState={getState}
            stateInit={data.personalData}
            validateData={validate}
          />
        );
      case 1:
        return (
          <ProfessionalData
            handleNext={handleNext}
            handleBack={handleBack}
            getState={getState}
            stateInit={data.professionalData}
          />
        );
      case 2:
        return (
          <Photo
            handleBack={handleBack}
            handleNext={handleNext}
            getState={getState}
            stateInit={data.photo}
          />
        );
      case 3:
        return (
          <Terms
            handleBack={handleBack}
            handleNext={registerForm}
            getState={getState}
          />
        );
      default:
        return "Unknown step";
    }
  };
  const uploadPhotoUser = async (photo, user) => {
    let formData = new FormData();
    formData.append("user", user);
    let block = photo.image.split(";");
    let contentType = block[0].split(":")[1];
    let realData = block[1].split(",")[1];
    let blob = await b64toBlob(realData, contentType);
    formData.append("image", blob);

    apiFile.post(`/ms_system/user/savePhotoRegister/`, formData);
  };

  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  const confirmCad = () => {
    if (window.location.href.indexOf("localhost") !== -1) {
      window.location.href = "http://localhost/salutem-webapp/app/";
    } else if (window.location.href.indexOf("pwa.salutem") !== -1) {
      window.location.href = "http://pwaback.salutemplus.com/";
    } else if (window.location.href.indexOf("react.salutem") !== -1) {
      window.location.href = "https://apresentacao.salutemplus.com/";
    } else {
      window.location.href = "https://balancer.salutemplus.com/";
    }
  };

  const registerForm = (license, terms) => {
    const obj = { ...data, terms: { license, terms }, type: "0" };
    Api.post(`/ms_system/user/saveRegister`, obj).then((res) => {
      if (res.data.success) {
        if (res.data.photo.image !== "") {
          uploadPhotoUser(res.data.photo, res.data.id);
        }
        setCadOk(true);
        setName(res.data.name);
      }
    });
  };
  const [state, setState] = useState(initState);
  const [data, setData] = useState(initData);
  const [cadOk, setCadOk] = useState(false);
  const [name, setName] = useState("");
  const [validate, setValidate] = useState(initValidate);

  const getState = async (stateForm, form) => {
    if (form === "validate") {
      await setValidate(stateForm);
    } else {
      await setData({ ...data, [form]: { ...stateForm } });
    }
  };
  const handleNext = () => {
    const { activeStep } = state;
    setState({
      ...state,
      activeStep: activeStep + 1,
    });
  };
  const handleBack = () => {
    const { activeStep } = state;
    setState({
      ...state,
      activeStep: activeStep - 1,
    });
  };

  const steps = getSteps();
  const { activeStep } = state;

  return (
    <div className={`app-container`}>
      <div className="app-main-container">
        <div className={`app-header`}>
          <Header left={true} />
        </div>
        <main className="app-main-content-wrapper">
          <div className="container mt-2">
            <ContainerHeader title="Cadastrar" match={match} />
            <Card className="shadow border-0">
              <CardBody>
                <div className="container">
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    className="horizontal-stepper-linear"
                  >
                    {steps.map((label, index) => {
                      return (
                        <Step
                          key={label}
                          className={`horizontal-stepper ${
                            index === activeStep ? "active" : ""
                          }`}
                        >
                          <StepLabel className="stepperlabel">
                            {label}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <div>
                    {activeStep === steps.length ? (
                      <div></div>
                    ) : (
                      <div>
                        <Typography className="my-2">
                          {getStepContent(activeStep)}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <Dialog open={cadOk} maxWidth="md" onConfirm={() => confirmCad()}>
            <DialogTitle>Cadastro Beta Tester</DialogTitle>
            <DialogContent style={{ paddingTop: 0 }}>
              {/* <i className="d-flex mb-4" style={{ justifyContent: "center" }}>
                <span
                  className="zmdi zmdi-check-circle zmdi-hc-5x"
                  style={{ color: "green" }}
                />
              </i> */}
              <p style={{ fontSize: 16 }}>
                Olá {name}, é um prazer vê-lo por aqui!
              </p>
              <p style={{ fontSize: 16 }}>
                O portal SalutemPlus encontra-se em sua versão Beta, por isso
                antes de liberar seu acesso a plataforma, iremos avaliar seu
                perfil para lhe oferecer a melhor experiência.
              </p>
              <p style={{ fontSize: 16 }}>
                Pedimos um prazo de 2 dia úteis para a análise, após esse
                período você receberá uma chave de acesso por e-mail para
                acessar sua conta SalutemPlus.
              </p>
              <p style={{ fontSize: 16 }}>
                Qualquer dúvida entre em contato, ok? Pode contar com a gente.
              </p>
              <p style={{ fontSize: 12 }}>
                Caso não receba sua chave de acesso nesse período, entre em
                contato pelo e-mail: contato@salutemplus.com
              </p>
              <p style={{ fontSize: 16 }}>Obrigada, Equipe SalutemPlus</p>
            </DialogContent>
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                color="primary"
                className="d-block"
                variant="contained"
                onClick={() => confirmCad()}
                style={{ justifyContent: "center" }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </div>
    </div>
  );
}
