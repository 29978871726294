import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Api from "services/api";
import CircularProgress from "components/CircularProgress";
import FileTranscription from "./fileTranscription";
class ViewPrescription extends Component {
  state = {
    record: []
  };

  componentDidMount() {
    Api.post(`/microservice/prescription/getDataPrescription/`, {
      prescription: this.props.prescription.id
    })
      .then(res => {
        this.setState({ record: res.data });
      })
      .catch(() => {});
  }

  render() {
    const {
      medicines,
      name,
      orientation,
      data_transcription
    } = this.state.record;
    const { onClose, prescription } = this.props;
    return medicines ? (
      <div className="row">
        <div className="col-md-12">
          <h4 className="font-weight-semibold"> Prescrição </h4>
          <div className="col-md-12">{name}</div>
          {orientation && (
            <React.Fragment>
              <h4
                className="font-weight-semibold"
                style={{ marginTop: "20px" }}
              >
                Orientação para a prescrição
              </h4>
              <div className="col-md-12">{orientation}</div>
            </React.Fragment>
          )}
          <h4 className="font-weight-semibold" style={{ marginTop: "20px" }}>
            {" "}
            Medicamentos{" "}
          </h4>
          <div className="row" style={{ paddingLeft: "10px" }}>
            {medicines.map(row => (
              <div className="col-md-12">
                <h5> - {row.name_display} </h5>
                {row.quantity && (
                  <span style={{ marginLeft: "30px" }}>
                    Quantidade: {row.quantity}
                  </span>
                )}
                {row.times_a_day && (
                  <span style={{ marginLeft: "30px" }}>
                    Vezes ao dia: {row.times_a_day}
                  </span>
                )}
                {row.interval && (
                  <span style={{ marginLeft: "30px" }}>
                    Intervalo: {row.interval}
                  </span>
                )}
                <br />
                {row.orientation && (
                  <span style={{ marginLeft: "30px" }}>
                    Orientação para o medicamento: {row.orientation}
                  </span>
                )}
                <br />
                <br />
              </div>
            ))}
          </div>
          <h4 className="font-weight-semibold">
            {" "}
            Informações do Profissional{" "}
          </h4>
          <div className="col-md-12">
            <strong>Nome: </strong>
            {prescription.prof_transcription
              ? prescription.prof_transcription
              : prescription.professional}
          </div>
          {prescription.prof_transcription ? (
            data_transcription.crm_doctor !== undefined ? (
              <div className="col-md-12">
                <strong>CRM: </strong>
                {data_transcription.crm_doctor}
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {prescription.speciality !== null ? (
            <div className="col-md-12">
              <strong>Especialidade: </strong>
              {prescription.speciality}
            </div>
          ) : (
            ""
          )}
          {prescription.prof_transcription ? (
            <div>
              <h4
                className="font-weight-semibold"
                style={{ marginTop: "20px" }}
              >
                {" "}
                Informações da Transcrição{" "}
              </h4>
              <div className="col-md-12">
                <strong>Transcrito por: </strong>
                {prescription.professional}
              </div>
              {data_transcription.file ? (
                <div className="col-md-12">
                  <strong>Arquivo: </strong>
                  <FileTranscription
                    nameFile={data_transcription.file_name}
                    descriptionFile={data_transcription.file_description}
                    file={data_transcription.file}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        {onClose !== undefined && (
          <div className="col-md-12 text-right">
            <Button
              onClick={() => {
                onClose();
              }}
              color="primary"
              aria-label="Voltar"
            >
              <i className="zmdi zmdi-undo" /> Voltar
            </Button>
          </div>
        )}
      </div>
    ) : (
      <CircularProgress />
    );
  }
}

export default ViewPrescription;
