import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "services/apiLogin";
import { showMsg } from "services/functions";
import { Link } from "react-router-dom";
import { userSignInSuccess } from "actions/Auth";
import "./login.css";
import { TextField, Button, Tooltip, IconButton } from "@material-ui/core";
import MaskCpf from "components/Masks/MaskCpf";
import MaskCpfCnpj from "components/Masks/MaskCpfCnpj";
import AlertMessage from "components/Alert/AlertMessage";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import BarProgress from "components/CircularProgress/ProgressBar";
import moment from "moment";
import ModalTerms from "./ModalTerms";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tutorial from "components/Header/Tutorial";

export default function FormLogin({
  handleSuccess,
  noRender = false,
  noShadow,
  history,
  prescription = false,
  option,
}) {
  const [mode, setMode] = useState(3);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    id: "",
    name: "",
    photo: "",
  });

  const [type, setType] = useState("");
  const [duplicate, setDuplicate] = useState(false);
  const [state, setState] = useState({
    keyCode: "",
    email: "",
    password: "",
    cpf: "",
  });
  const [modalTutorial, setModalTutorial] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(null);

  const alert = useSelector((state) => state.alert);
  const auth = useSelector((state) => state.auth);

  function successLogin(data) {
    localStorage.setItem("userAudit", data.id);
    localStorage.setItem("init", moment().format("YYYY-MM-DD HH:mm"));
    localStorage.setItem("savedQuizze", "0");
    if (!noRender) {
      dispatch({ type: "RESET_ALL", opt: "SIGNIN" });
      dispatch(userSignInSuccess(data.id, "", data.isSecretary));
      setLoading(false);
      handleSuccess(true);
    } else {
      setLoading(false);
      handleSuccess(true, data.id);
      showMsg("success", data.msg, dispatch);
    }
  }

  const loginWithKeyCode = async () => {
    setLoading(true);
    if (state.keyCode === "" || state.keyCode.length < 5) {
      setLoading(false);
      setError(true);
    } else {
      setError(false);
      let formData = {
        pass: state.password,
        id: userData.id,
        keyCode: state.keyCode.toLowerCase(),
      };

      if (!navigator.onLine) {
        setLoading(false);
        showMsg("error", "Você está offline", dispatch);
      } else {
        api.post(`/ms_system/login/login/`, formData).then(async ({ data }) => {
          if (data.success) {
            successLogin(data);
          } else if (data.terms) {
            setLoading(false);
            setAcceptTerms(data);
            showMsg("error", data.msg, dispatch);
          } else {
            setLoading(false);
            showMsg("error", "Chave de acesso inválida", dispatch);
          }
        });
      }
    }
  };

  const selectIdUser = () => {
    api
      .post(`/ms_system/login/getIdUserDuplicate/`, {
        username: state.email,
        cpf: state.cpf,
        type: type,
      })
      .then(({ data }) => {
        if (data.status === "success") {
          setUserData({ ...userData, id: data.id });
        }
      });
  };

  const verifyDuplicate = () => {
    if (state.email) {
      api
        .post(`/ms_system/login/getUsersDuplicate/`, {
          username: state.email,
        })
        .then(({ data }) => {
          if (data.status === "success") {
            setType(data.tipo);
            if (data[0].length > 1) {
              setDuplicate(true);
              setError(false);
            } else {
              setUserData(data[0][0]);
              setDuplicate(false);
              setError(false);
            }
          } else {
            setLoading(false);
            showMsg("error", data[0].msg, dispatch);
          }
        });
    }
  };

  const loginWithEmail = () => {
    setLoading(true);
    if (state.email === "") {
      setLoading(false);
      setError(true);
    } else {
      if (userData.id === "") {
        api
          .post(`/ms_system/login/getUsersDuplicate/`, {
            username: state.email,
          })
          .then(({ data }) => {
            if (data.status === "success") {
              setType(data.tipo);
              if (data[0].length > 1) {
                setError(false);
              } else {
                setUserData(data[0][0]);
                setError(false);
              }
              setLoading(false);
            } else {
              setLoading(false);
              showMsg("error", "Usuário ou senha inválido!", dispatch);
            }
          });
      } else {
        if (state.password === "") {
          setError(true);
        } else {
          let formData = {
            pass: state.password,
            id: userData.id,
            keyCode: state.keyCode,
          };
          api.post(`/ms_system/login/login/`, formData).then(({ data }) => {
            if (data.success) {
              successLogin(data);
            } else if (data.terms) {
              setLoading(false);
              setAcceptTerms(data);
              showMsg("error", data.msg, dispatch);
            } else {
              setLoading(false);
              showMsg("error", data.msg, dispatch);
            }
          });
        }
      }
    }
  };

  return (
    <div className="row">
      <div
        className="login-content"
        style={{
          boxShadow: noShadow ? "none" : "",
          padding: "0px",
        }}
      >
        <div className="login-header mb-4">
          <h2
            style={{
              color: "#3e3e3e",
            }}
          >
            Faça Login ou Cadastre-se para <strong>Notificar</strong>
            <Tooltip
              title="Tutorial"
              style={{ display: "contents", justifyContent: "end" }}
            >
              <IconButton onClick={() => setModalTutorial(true)}>
                <HelpOutlineIcon
                  style={{
                    color: "#074c5e",
                  }}
                />
              </IconButton>
            </Tooltip>
          </h2>
          {/* <h3 style={{ fontSize: "13px" }}>
            {" "}
            Novo Usuario? 
            <NavLink to="/signup">
              <span className="nav-text">Cadastre-se</span>
            </NavLink>
          </h3> */}
          <hr />
        </div>
        <div className="login-form">
          <fieldset>
            {mode === 1 && (
              <TextField
                type="password"
                id="keycode"
                label="Chave de acesso"
                fullWidth
                onChange={(event) => {
                  setState({ ...state, keyCode: event.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    loginWithKeyCode();
                  }
                }}
                value={state.keyCode}
                margin="normal"
                className="mt-1"
                autoFocus={true}
                error={error && state.keyCode.length < 6}
                InputLabelProps={{ shrink: true }}
              />
            )}
            {mode === 2 && (
              <>
                <TextField
                  id="email"
                  label="Email"
                  fullWidth
                  onChange={(event) =>
                    setState({ ...state, email: event.target.value })
                  }
                  onBlur={() => verifyDuplicate()}
                  defaultValue={state.email}
                  margin="normal"
                  className="mt-1"
                  error={error && !state.email}
                  autoFocus={true}
                  InputLabelProps={{ shrink: true }}
                />
              </>
            )}
            {duplicate && mode === 2 && (
              <TextField
                id="cpf"
                label="CPF / CNPJ"
                fullWidth
                autoFocus
                onChange={(event) => {
                  setState({ ...state, cpf: event.target.value });
                }}
                onBlur={() => selectIdUser()}
                InputProps={{
                  value: state.cpf,
                  inputComponent: MaskCpfCnpj,
                }}
                margin="normal"
                className="mt-1"
                autoFocus={true}
                InputLabelProps={{ shrink: true }}
              />
            )}
            {mode === 3 && (
              <TextField
                id="cpf"
                label="CPF"
                fullWidth
                onChange={(event) =>
                  setState({ ...state, email: event.target.value })
                }
                onBlur={() => verifyDuplicate()}
                InputProps={{
                  inputComponent: MaskCpf,
                }}
                margin="normal"
                className="mt-1"
                autoFocus={true}
                InputLabelProps={{ shrink: true }}
              />
            )}
            {mode === 4 && (
              <TextField
                id="username"
                label="Nome de usuário"
                fullWidth
                onChange={(event) =>
                  setState({ ...state, email: event.target.value })
                }
                margin="normal"
                className="mt-1"
                autoFocus={true}
                onBlur={() => verifyDuplicate()}
                error={error && !state.username}
                InputLabelProps={{ shrink: true }}
              />
            )}
            {mode !== 1 && (
              <TextField
                type="password"
                id="Senha"
                label="Senha"
                fullWidth
                onChange={(event) => {
                  setState({ ...state, password: event.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    loginWithEmail();
                  }
                }}
                defaultValue={state.password}
                margin="normal"
                className="mt-1"
                error={error && !state.password}
                InputLabelProps={{ shrink: true }}
              />
            )}

            {mode !== 1 && (
              <div
                className="mt-1 mb-2 d-flex justify-content-between align-items-center"
                style={{ float: "left" }}
              >
                <div>
                  <Link to="/password-recover" title="Reset Password">
                    Redefinir Senha
                  </Link>
                </div>
              </div>
            )}

            <Button
              color="primary"
              variant="contained"
              onClick={mode === 1 ? loginWithKeyCode : loginWithEmail}
              className="text-white "
              style={{ float: "right", marginTop: "10px" }}
            >
              Entrar
            </Button>
          </fieldset>

          {false && option !== 1 && (
            <>
              <div
                className="col-md-12 noPadding"
                style={{ marginTop: "15px" }}
              >
                <div className="col-md-5 noPadding" style={{ float: "left" }}>
                  <hr />
                </div>
                <div
                  className="col-md-2 noPadding"
                  style={{
                    float: "left",
                    marginTop: "5px",
                    textAlignLast: "center",
                  }}
                >
                  <span>OU </span>
                </div>
                <div className="col-md-5 noPadding" style={{ float: "right" }}>
                  <hr />
                </div>
              </div>

              <div style={{ marginTop: "45px" }}>
                {mode !== 1 && (
                  <div
                    className="col-12 noPadding"
                    style={{ marginTop: "5px" }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        setMode(1);
                        setDuplicate(false);
                        setState({
                          cpf: "",
                          keyCode: "",
                          password: "",
                        });
                        setError(false);
                      }}
                      style={{ width: "100%", marginBottom: "10px" }}
                    >
                      <i
                        className="zmdi zmdi-key zmdi-hc-lg"
                        style={{ marginRight: "10px" }}
                      ></i>
                      Chave de acesso
                    </Button>
                  </div>
                )}
                {mode !== 2 && (
                  <div
                    className="col-12 noPadding"
                    style={{ marginTop: "5px" }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        setMode(2);
                        setDuplicate(false);
                        setState({
                          cpf: "",
                          keyCode: "",
                          password: "",
                        });
                        setError(false);
                      }}
                      style={{
                        width: "100%",
                        marginBottom: "10px",
                      }}
                    >
                      <i
                        className="zmdi zmdi-email zmdi-hc-lg"
                        style={{ marginRight: "10px" }}
                      ></i>
                      Email
                    </Button>
                  </div>
                )}
                {mode !== 3 && (
                  <div className="col-12 noPadding">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        setMode(3);
                        setDuplicate(false);
                        setState({
                          cpf: "",
                          keyCode: "",
                          password: "",
                        });
                        setError(false);
                      }}
                      style={{ width: "100%", marginBottom: "10px" }}
                    >
                      <i
                        className="zmdi zmdi-card zmdi-hc-lg"
                        style={{ marginRight: "10px" }}
                      ></i>
                      CPF
                    </Button>
                  </div>
                )}
                {mode !== 4 && (
                  <div className="col-12 noPadding">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        setMode(4);
                        setDuplicate(false);
                        setState({
                          cpf: "",
                          keyCode: "",
                          password: "",
                        });
                        setError(false);
                      }}
                      style={{ width: "100%", marginBottom: "10px" }}
                    >
                      <i
                        className="zmdi zmdi-account-box zmdi-hc-lg"
                        style={{ marginRight: "10px" }}
                      ></i>
                      Usuário
                    </Button>
                  </div>
                )}
                <Tooltip
                  title="Tutorial"
                  style={{ display: "contents", justifyContent: "end" }}
                >
                  <IconButton onClick={() => setModalTutorial(true)}>
                    <HelpOutlineIcon
                      style={{
                        color: "#074c5e",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          )}
          {prescription && (
            <div>
              <span>
                Não tem Acesso?{" "}
                <a href="http://localhost:3000/register/drugstore">
                  Cadastre-se usando SNGPC
                </a>{" "}
              </span>
            </div>
          )}
        </div>
      </div>
      <AlertMessage
        open={alert.alert_message}
        message={alert.text_message}
        type={alert.message_type}
      />
      <Dialog open={loading}>
        <DialogContent>
          <BarProgress />
        </DialogContent>
      </Dialog>
      <ModalTerms
        open={Boolean(acceptTerms)}
        handleClose={() => setAcceptTerms(null)}
        handleSucess={() => successLogin(acceptTerms)}
        user={acceptTerms ? acceptTerms.id : null}
      />
      {modalTutorial && (
        <Tutorial
          open={modalTutorial}
          onCLose={() => setModalTutorial(false)}
          mode="login"
        />
      )}
    </div>
  );
}
