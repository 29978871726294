import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import api from "services/api";

const styles = {
  HeaderTable: {
    fontSize: 16
  }
};
class TableHistory extends Component {
  state = {
    recordMultiple: []
  };

  componentDidMount() {
    const Data = {
      patient: this.props.patient,
      idHabits: this.props.idHabits
    };
    api
      .post(`/microservice/consult/getHistoryHabitsMultiple/`, Data)
      .then(res => {
        this.setState({ recordMultiple: res.data.record });
      })
      .catch(() => {});
  }

  render() {
    // const { classes } = this.props;
    const { recordMultiple } = this.state;
    return recordMultiple ? (
      <div>
        {recordMultiple.map(row => (
          <div key={row.id}>
            <strong>{row.options}</strong>
            <br />
            <span style={{ paddingLeft: "10px" }}>
              <i>
                {row.additional_consideration_option
                  ? row.additional_consideration_option
                  : "Sem observações"}
              </i>
            </span>
          </div>
        ))}
      </div>
    ) : (
      <span> Sim </span>
    );
  }
}

TableHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TableHistory);
