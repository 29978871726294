import React from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  borderRight: {
    borderRight: "1px solid #c9c3c3",
    "&:last-child": {
      borderRight: "none"
    }
  }
}));
export default function TabsDefault(props) {
  const classes = useStyles();

  function handleChange(event, value) {
    props.setValue(value);
  }

  return (
    <div className="jr-tabs-classic col-12">
      <Tabs
        className=""
        indicatorColor="secondary"
        variant="fullWidth"
        value={props.value}
        onChange={handleChange}
      >
        {props.tabs.map(tab => (
          <Tab
            key={tab}
            className={`jr-tabs-label ${classes.borderRight}`}
            label={tab}
          />
        ))}
      </Tabs>
      <div className="jr-tabs-content jr-task-list">{props.children}</div>
    </div>
  );
}

// //COMO USAR 🔥
// import TabsDefault from "components/TabsDefault";
//<Tabs
//  value={activeTab}//Index da Tab Ativa
//  setValue={setActiveTab}//Func seta Tab ativa
//  tabs={["Test", "Test 1"]}//Array de Tabs
//>
//  {/* Estrutura a ser rendedrizada dentro das tabs */}
//  {activeTab === 0 && (
//    <div />
//  )}
//  {activeTab === 1 && (
//    <div />
//  )}
//</Tabs>
