import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import configureStore, { history } from "./store";
import App from "./containers/App";
import { inicializarFirebase } from "./services/firebase";
import UserConfirmation from "./util/UserConfirmation";

export const store = configureStore();
const persistor = persistStore(store);
inicializarFirebase();
const MainApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <BrowserRouter getUserConfirmation={UserConfirmation}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </BrowserRouter>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default MainApp;
