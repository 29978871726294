import React, { useState, useEffect } from "react";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan } from "components/Button";
import Api from "services/api";
import moment from "moment";

export default function ViewRequestExam({ open, handleClose, data }) {
  const [record, setRecord] = useState({});

  useEffect(() => {
    if (data.id) {
      Api.post(`/microservice/exam/getExamsFromRequest/`, {
        request: data.id,
      })
        .then((res) => {
          setRecord(res.data);
        })
        .catch(() => {});
    }
  }, [data]);

  return (
    <ModalResponsive
      open={open}
      close={handleClose}
      maxWidth="md"
      title="Visualizar pedido"
      buttons={
        <div className="col-12">
          <BtnCan title="Fechar" onClick={() => handleClose()} float="left" />
        </div>
      }
    >
      <div className="row">
        <div className="col-md-4 col-xs-4">
          <strong> Data do pedido: </strong>{" "}
          {moment(record.date).format("DD/MM/YYYY")}
        </div>
        <div className="col-md-4 col-xs-4">
          <strong> Paciente: </strong> {data.patient_name}
        </div>
        <div className="col-md-4 col-xs-4">
          <strong> Solicitante: </strong> {record.solicitant}
        </div>
        <div className="col-md-4 col-xs-4">
          <strong> Pedido: </strong> {record.name}
        </div>
        <div className="col-md-4 col-xs-4">
          <strong> Número: </strong> {record.number}
        </div>

        <div className="col-md-4 col-xs-4">
          <strong> Local do laboratório: </strong> {record.place_laboratory}
        </div>
        <div className="col-md-12 col-xs-4">
          <strong> Observações: </strong> {record.observation}
        </div>

        {record.list_exam &&
          record.list_exam.map((value) => (
            <div className="col-md-12 col-xs-12" style={{ marginTop: "20px" }}>
              <strong> Exame: </strong> {value.test}
              <br />
              <strong> Amostra: </strong> {value.sample ? value.sample : "---"}{" "}
              <br />
            </div>
          ))}
      </div>
    </ModalResponsive>
  );
  // <CircularProgress />
}
