import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEvents } from "actions/Calendar";
import FullCalendarComponent from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import "./style.css";
import moment from "moment";

function FullCalendar({ calendar }) {
  const calendarComponentRef = React.createRef();
  // const [businessHours, setBusinessHours] = useState([]);
  const date = useSelector((state) => state.calendar.date_calendar);
  const events = useSelector((state) => state.calendar.events);
  const config = useSelector((state) => state.calendar.config);
  const width = useSelector((state) => state.settings.width);
  const type = useSelector((state) => state.auth.type);
  const secretary = useSelector((state) => state.auth.secretary);
  const dispatch = useDispatch();

  const calendars = useSelector((state) => state.calendar.calendars);

  const [idsCalendars, setIdsCalendars] = useState("");

  useEffect(() => {
    let ids = calendars.map((row) => row.id);
    setIdsCalendars(ids.join(","));
  }, []);

  useEffect(() => {
    if (idsCalendars) {
      dispatch(
        getEvents(idsCalendars, date, "normal", type === "3" && !secretary)
      );
    }
  }, [date, idsCalendars]);

  function handleDate(start, end) {
    if (date.start !== start || date.end !== end) {
      dispatch({ type: "HANDLE_DATE", date: { start, end } });
    }
  }

  return (
    <FullCalendarComponent
      locale={ptBrLocale}
      ref={calendarComponentRef}
      selectable={true}
      selectdraw={true}
      navLinks={true}
      nowIndicator={true}
      initialDate={new Date()}
      contentHeight="auto"
      eventLimit={true}
      displayEventTime={true}
      displayEventEnd={true}
      slotMinTime={config.min_time}
      slotMaxTime={config.max_time}
      hiddenDays={config.hidden_days}
      eventTimeFormat={{
        hour: "2-digit",
        minute: "2-digit",
      }}
      headerToolbar={{
        left: width > 500 ? "prev,next today" : "prev,next",
        center: width > 500 ? "title" : "",
        right:
          width > 500
            ? "timeGridDay,timeGridWeek,dayGridMonth,listWeek"
            : "timeGridDay,listWeek",
      }}
      plugins={[timeGridPlugin, listPlugin, dayGridPlugin, interactionPlugin]}
      events={(date, callback) => {
        handleDate(
          moment(date.start).format("YYYY-MM-DD HH:mm:ss"),
          moment(date.end).format("YYYY-MM-DD HH:mm:ss")
        );
        callback(events);
      }}
      dateClick={(e, el) =>
        dispatch({
          type: "DATE_CLICK",
          date: moment(e.dateStr).format("YYYY-MM-DD HH:mm"),
          calendar,
        })
      }
      eventClick={(e) => {
        dispatch({
          type: "EVENT_CLICK",
          event: e.event.id,
          calendar: e.event.extendedProps.resourceId,
          patient: e.event.extendedProps.pacient_id,
          number_patients: parseInt(e.event.extendedProps.number_patients),
        });
      }}
      eventContent={(arg) => {
        let { event, view } = arg;
        if (
          view.type !== "listWeek" &&
          parseInt(event.extendedProps.number_patients) === 1 &&
          event.extendedProps.bloqued !== "1"
        ) {
          let icon = "";
          switch (event.extendedProps.status) {
            case "1":
              icon = "zmdi-circle-o";
              break;
            case "2":
              icon = "zmdi-circle";
              break;
            case "3":
              icon = "zmdi-alert-circle-o";
              break;
            case "4":
              icon = "zmdi-spinner";
              break;
            case "5":
              icon = "zmdi-check-circle";
              break;
            case "6":
              icon = "zmdi-close-circle-o";
              break;
            default:
              icon = "zmdi-circle-o";
              break;
          }
          return (
            <i
              className={`zmdi ${icon} zmdi-fw ml-1 mr-1 d-flex`}
              style={{ cursor: "pointer" }}
            >
              <i
                className="zmdi zmdi-tag zmdi-fw ml-1 mr-1"
                style={{
                  color: event.extendedProps.color_tag
                    ? event.extendedProps.color_tag
                    : "#FFA400",
                }}
              />
              {arg.timeText} {event.title}
            </i>
          );
        }
      }}
      eventDidMount={(arg) => {
        if (arg.event.extendedProps.bloqued === "1") {
          arg.el.style.color = "#FFA400";
          arg.el.style.background =
            "linear-gradient(135deg, rgba(242,246,248,1) 0%,rgba(216,225,231,1) 50%,rgba(181,198,208,1) 51%,rgba(224,239,249,1) 100%)";
        } else if (arg.view.type === "listWeek") {
          arg.el.style.color = "#000";
          arg.el.style.background = "#fff";
        } else {
          arg.el.style.color = arg.textColor;
          arg.el.style.background = arg.backgroundColor;
        }
      }}
      initialView={width > 500 ? config.mode_default : "timeGridDay"}
      expandRows={true}
    />
  );
}

export default FullCalendar;
