import React, { useState, useEffect } from "react";
import { Button, TextField, MenuItem } from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import Api from "services/api";
import moment from "moment";
import DataTable from "components/Tables/DataTable";

export default function ListProcedures({ calendar, width, closeMenu }) {
  const [modal_form, setModalForm] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (date && calendar && modal_form) {
      Api.post(`/ms_system/calendar/getEventsWithProcedure/`, {
        date,
        calendar,
      }).then((res) => setEvents(res.data.record));
    }
  }, [date, calendar, modal_form]);

  function closeModal() {
    setModalForm(false);
    setDate(moment().format("YYYY-MM-DD"));
    setEvents([]);
    closeMenu();
  }

  const ComponentForm = () => (
    <div className="row w-100 mt-3">
      <div className="col-12">
        <TextField
          type="date"
          label="Data:"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </div>

      <div className="col-12 mt-3">
        <DataTable
          columns={["Procedimento", "", "Profissional", "Paciente", "Horário"]}
          rows={events.map((row, index) => [
            row.description,
            <span
              style={{
                backgroundColor: row.color,
                padding: "0.3rem",
              }}
              className="size-10 lighten-1 rounded-circle d-inline-block mr-2"
            />,
            row.doctor,
            row.title,
            row.hour,
          ])}
          download={true}
          print={true}
          rowsPerPage={25}
        />
      </div>
    </div>
  );

  return (
    <>
      {width > 500 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setModalForm(true)}
          style={{ marginTop: 15, marginLeft: 10 }}
        >
          <i className="zmdi zmdi-view-list-alt mr-3" /> Procedimentos do dia
        </Button>
      ) : (
        <MenuItem onClick={() => setModalForm(true)}>
          <i className="zmdi zmdi-view-list-alt mr-3" /> Procedimentos do dia
        </MenuItem>
      )}
      <ModalResponsive
        title="Procedimentos do dia"
        open={modal_form}
        close={closeModal}
        maxWidth="lg"
        buttons={
          <div className="col-md-12">
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => closeModal()}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Fechar </span>
            </Button>
          </div>
        }
      >
        {ComponentForm()}
      </ModalResponsive>
    </>
  );
}
