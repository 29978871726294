import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton
} from "@material-ui/core";
import Api from "services/api";
import CircularProgress from "components/CircularProgress";

export default function ViewQuestionnaire({ record, hasSubcategory, onClose }) {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (record.id) {
      Api.post(`/microservice/systemreview/getAnswerHistory/`, {
        questionnaire: record.id
      }).then(res => {
        setAnswers(res.data.record);
      });
    }
  }, []);

  return answers.length > 0 ? (
    <div className="row">
      <div className="col-12">
        <strong className="mr-1">Profissional:</strong>
        {record.name}
        <strong className="ml-3 mr-1">Especialidade:</strong>
        {record.specialty}
        <strong className="ml-3 mr-1">Data:</strong>
        {record.date}
        <Tooltip title="Voltar" placement="top">
          <IconButton
            aria-label="Voltar"
            onClick={() => onClose()}
            style={{ float: "right", marginTop: "-10px" }}
          >
            <i className="zmdi zmdi-close" />
          </IconButton>
        </Tooltip>
      </div>
      <div
        className="col-12"
        style={{
          minWidth: "650",
          overflowX: "auto"
        }}
      >
        <Table className="w-100">
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "#ffffff",
                fontSize: "0.95rem",
                height: "40px"
              }}
            >
              <TableCell
                className="p-2"
                style={{
                  width: "40%",
                  color: "#00000059",
                  fontSize: "0.95rem"
                }}
                colSpan={2}
              >
                Questão
              </TableCell>
              {!hasSubcategory && (
                <TableCell
                  style={{
                    width: "30%",
                    color: "#00000059",
                    fontSize: "0.95rem"
                  }}
                >
                  Valores
                </TableCell>
              )}

              <TableCell
                style={{
                  width: "30%",
                  color: "#00000059",
                  fontSize: "0.95rem"
                }}
              >
                Observação
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {answers.map(row => (
              <>
                {hasSubcategory &&
                  ["64", "67", "70", "73", "77", "80"].indexOf(row.question) !==
                    -1 && (
                    <TableRow>
                      <TableCell colSpan="3">
                        <span
                          style={{
                            color: "##000000d6",
                            fontSize: "0.95rem",
                            fontWeight: "500"
                          }}
                        >
                          {row.question === "64" && "Articulações"}
                          {row.question === "67" && "Estado dos Pés"}
                          {row.question === "70" && "Coluna Cervical"}
                          {row.question === "73" && "Coluna Lombar"}
                          {row.question === "77" && "Estado das Mãos"}
                          {row.question === "80" && "Estado das Unhas"}
                        </span>
                      </TableCell>
                    </TableRow>
                  )}
                <TableRow className="w-100" key={row.id}>
                  <TableCell>
                    <span className="ml-2">{row.description}</span>
                  </TableCell>
                  <TableCell>{row.stats === "1" ? "Sim" : "Não"}</TableCell>
                  {!hasSubcategory && <TableCell>{row.options}</TableCell>}
                  <TableCell>{row.note ? row.note : ""}</TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="col-md-12 text-right">
        <Button onClick={() => onClose()} color="primary" aria-label="Voltar">
          <i className="zmdi zmdi-undo" /> Voltar
        </Button>
      </div>
    </div>
  ) : (
    <CircularProgress />
  );
}
