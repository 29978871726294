import React from "react";
import DataTable from "components/Tables/DataTable";

export default function ListEvents({ record }) {
  function getDisplayStatus(value) {
    switch (value) {
      case "1":
        return (
          <span>
            <i className="zmdi zmdi-calendar ml-1" /> Agendado
          </span>
        );
      case "2":
        return (
          <span className="text-primary">
            <i className="zmdi zmdi-assignment-check ml-1" /> Confirmado
          </span>
        );
      case "3":
        return (
          <span style={{ color: "#ff9800" }}>
            <i className="zmdi zmdi-time ml-1" /> Aguardando atendimento
          </span>
        );
      case "4":
        return (
          <span style={{ color: "#6f42c1" }}>
            <i className="zmdi zmdi-spinner ml-1" /> Em atendimento
          </span>
        );
      case "5":
        return (
          <span style={{ color: "#4caf50" }}>
            <i className="zmdi zmdi-check-all ml-1" /> Finalizado
          </span>
        );
      case "6":
        return (
          <span style={{ color: "#bc0003" }}>
            {" "}
            <i className="zmdi zmdi-block-alt ml-1" /> Não compareceu
          </span>
        );
      default:
        break;
    }
  }

  return (
    <DataTable
      name="Agendamentos"
      columns={["Data", "Paciente", "Serviço", "Status"]}
      rows={record.map((row, index) => [
        row.hour,
        row.title,
        row.description,
        getDisplayStatus(row.status),
      ])}
      hideSearch={true}
    />
  );
}
