import React, { useState } from "react";

import { Typography, Icon, IconButton, Slider } from "@material-ui/core";
import { ButtonLarge } from "components/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import RestoreIcon from "@material-ui/icons/Restore";
import ViewHistory from "./ViewHistory";

const useStyles = makeStyles((theme) => ({
  icon: {
    overflow: "inherit",
    fontSize: "60px",
    borderRadius: "50px",
    width: "100px",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    border: "solid 1px",
    padding: theme.spacing(1),
    borderRadius: "50%",
  },
}));

const CustomSlider = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
    marginTop: "1rem",
    color: "#dcdcdc",
    cursor: "default",
  },
  thumb: {
    backgroundColor: "#fff",
    border: "1px solid #707070",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% - 3px)",
    top: "-25px",
    "& > span": {
      width: "1.6rem",
      height: "1.6rem",
    },
    "& span > span": {
      color: "#000",
    },
  },
}))(Slider);

function patientQuizes({ setQuiz, progress, getData }) {
  const user = useSelector((state) => state.auth.authUser);
  const width = useSelector((state) => state.settings.width);

  const classes = useStyles();
  const [viewHistory, setViewHistory] = useState({
    open: false,
    question: "complaint",
  });

  return (
    <div className={width > 500 && "row px-4 d-flex"}>
      {/* Vacina covid-19 */}
      <div
        className={
          width < 500 ? "card" : "col-sm-6 col-md-4 col-lg-3 d-flex p-2"
        }
      >
        <div
          className={width < 500 ? "col-12 py-2" : "col-12 card p-3 shadow-sm"}
        >
          <div className="row">
            <div
              className={
                width < 500
                  ? "col-4 d-flex align-items-center justify-content-center"
                  : "col-12 d-flex align-items-center justify-content-center"
              }
            >
              <Icon
                className={classes.icon}
                style={{ backgroundColor: "#b6dce9" }}
              >
                💉
              </Icon>
            </div>
            <div className={width < 500 ? "col-8" : "col-12"}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Vacina Covid-19</strong>
              </Typography>
              <p>Nos diga se você já tomou a vacina do Covid-19.</p>
              <div
                className={`row col-12 align-items-center ${width > 500 &&
                  "p-0 m-0"}`}
                style={{
                  minHeight:
                    width > 500 && !progress.aboutDay ? "60px" : "unset",
                }}
              >
                {progress.aboutDay && (
                  <CustomSlider
                    step={1}
                    marks
                    min={1}
                    max={10}
                    value={Number(progress.aboutDay.mood)}
                    valueLabelDisplay="on"
                    aria-labelledby="continuous-slider"
                  />
                )}
              </div>
              {width > 500 && (
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <ButtonLarge onClick={() => setQuiz("vaccineCovid")}>
                    Responder
                  </ButtonLarge>
                  <IconButton
                    onClick={() =>
                      setViewHistory({
                        open: true,
                        question: "mood",
                      })
                    }
                    className={classes.iconButton}
                  >
                    <RestoreIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          {width < 500 && (
            <div className="row align-items-center justify-content-between">
              <div className="col-8">
                <ButtonLarge onClick={() => setQuiz("vaccineCovid")}>
                  Responder
                </ButtonLarge>
              </div>
              <div className="col-4 d-flex justify-content-center">
                <IconButton
                  onClick={() =>
                    setViewHistory({
                      open: true,
                      question: "mood",
                    })
                  }
                  className={classes.iconButton}
                >
                  <RestoreIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* sintomas covid-19 */}
      <div
        className={
          width < 500 ? "card" : "col-sm-6 col-md-4 col-lg-3 d-flex p-2"
        }
      >
        <div
          className={width < 500 ? "col-12 py-2" : "col-12 card p-3 shadow-sm"}
        >
          <div className="row">
            <div
              className={
                width < 500
                  ? "col-4 d-flex align-items-center justify-content-center"
                  : "col-12 d-flex align-items-center justify-content-center"
              }
            >
              <Icon
                className={classes.icon}
                style={{ backgroundColor: "#179340" }}
              >
                🤧
              </Icon>
            </div>
            <div className={width < 500 ? "col-8" : "col-12"}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Sintomas Covid-19</strong>
              </Typography>
              <p>Nos diga se você está com algum sintoma do Covid-19.</p>
              <div
                className={`row col-12 align-items-center ${width > 500 &&
                  "p-0 m-0"}`}
                style={{
                  minHeight:
                    width > 500 && !progress.aboutDay ? "60px" : "unset",
                }}
              >
                {progress.aboutDay && (
                  <CustomSlider
                    step={1}
                    marks
                    min={1}
                    max={10}
                    value={Number(progress.aboutDay.mood)}
                    valueLabelDisplay="on"
                    aria-labelledby="continuous-slider"
                  />
                )}
              </div>
              {width > 500 && (
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <ButtonLarge onClick={() => setQuiz("symptomsCovid")}>
                    Responder
                  </ButtonLarge>
                  <IconButton
                    onClick={() =>
                      setViewHistory({
                        open: true,
                        question: "mood",
                      })
                    }
                    className={classes.iconButton}
                  >
                    <RestoreIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          {width < 500 && (
            <div className="row align-items-center justify-content-between">
              <div className="col-8">
                <ButtonLarge onClick={() => setQuiz("symptomsCovid")}>
                  Responder
                </ButtonLarge>
              </div>
              <div className="col-4 d-flex justify-content-center">
                <IconButton
                  onClick={() =>
                    setViewHistory({
                      open: true,
                      question: "mood",
                    })
                  }
                  className={classes.iconButton}
                >
                  <RestoreIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* COMO ESTÁ SE SENTINDO */}
      <div
        className={
          width < 500 ? "card" : "col-sm-6 col-md-4 col-lg-3 d-flex p-2"
        }
      >
        <div
          className={width < 500 ? "col-12 py-2" : "col-12 card p-3 shadow-sm"}
        >
          <div className="row">
            <div
              className={
                width < 500
                  ? "col-4 d-flex align-items-center justify-content-center"
                  : "col-12 d-flex align-items-center justify-content-center"
              }
            >
              <Icon
                className={classes.icon}
                style={{ backgroundColor: "#ffa2c5" }}
              >
                🤒
              </Icon>
            </div>
            <div className={width < 500 ? "col-8" : "col-12"}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Como está se sentindo</strong>
              </Typography>
              <p>Nos diga o que está sentindo no momento.</p>
              <div
                className={`row col-12 align-items-center ${width > 500 &&
                  "p-0 m-0"}`}
                style={{
                  minHeight:
                    width > 500 && !progress.feeling ? "60px" : "unset",
                }}
              >
                {progress.feeling && (
                  <CustomSlider
                    step={1}
                    marks
                    min={1}
                    max={10}
                    value={Number(progress.feeling.humor)}
                    valueLabelDisplay="on"
                    aria-labelledby="continuous-slider"
                  />
                )}
              </div>
              {width > 500 && (
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <ButtonLarge onClick={() => setQuiz("feeling")}>
                    Responder
                  </ButtonLarge>
                  <IconButton
                    onClick={() =>
                      setViewHistory({
                        open: true,
                        question: "humor",
                      })
                    }
                    className={classes.iconButton}
                  >
                    <RestoreIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          {width < 500 && (
            <div className="row align-items-center justify-content-between">
              <div className="col-8">
                <ButtonLarge onClick={() => setQuiz("feeling")}>
                  Responder
                </ButtonLarge>
              </div>
              <div className="col-4 d-flex justify-content-center">
                <IconButton
                  onClick={() =>
                    setViewHistory({
                      open: true,
                      question: "humor",
                    })
                  }
                  className={classes.iconButton}
                >
                  <RestoreIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* COMO STÁ A SUA ENERGIA */}
      <div
        className={
          width < 500 ? "card" : "col-sm-6 col-md-4 col-lg-3 d-flex p-2"
        }
      >
        <div
          className={width < 500 ? "col-12 py-2" : "col-12 card p-3 shadow-sm"}
        >
          <div className="row">
            <div
              className={
                width < 500
                  ? "col-4 d-flex align-items-center justify-content-center"
                  : "col-12 d-flex align-items-center justify-content-center"
              }
            >
              <Icon
                className={classes.icon}
                style={{ backgroundColor: "#EFE813" }}
              >
                ⚡
              </Icon>
            </div>
            <div className={width < 500 ? "col-8" : "col-12"}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Como está a sua energia</strong>
              </Typography>
              <p>Nos diga como está a sua energia nesse momento.</p>
              <div
                className={`row col-12 align-items-center ${width > 500 &&
                  "p-0 m-0"}`}
                style={{
                  minHeight:
                    width > 500 && !progress.aboutDay ? "60px" : "unset",
                }}
              >
                {progress.aboutDay && (
                  <CustomSlider
                    step={1}
                    marks
                    min={1}
                    max={10}
                    value={Number(progress.aboutDay.mood)}
                    valueLabelDisplay="on"
                    aria-labelledby="continuous-slider"
                  />
                )}
              </div>
              {width > 500 && (
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <ButtonLarge onClick={() => setQuiz("energy")}>
                    Responder
                  </ButtonLarge>
                  <IconButton
                    onClick={() =>
                      setViewHistory({
                        open: true,
                        question: "mood",
                      })
                    }
                    className={classes.iconButton}
                  >
                    <RestoreIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          {width < 500 && (
            <div className="row align-items-center justify-content-between">
              <div className="col-8">
                <ButtonLarge onClick={() => setQuiz("energy")}>
                  Responder
                </ButtonLarge>
              </div>
              <div className="col-4 d-flex justify-content-center">
                <IconButton
                  onClick={() =>
                    setViewHistory({
                      open: true,
                      question: "mood",
                    })
                  }
                  className={classes.iconButton}
                >
                  <RestoreIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* HISTÓRICO */}
      <ViewHistory
        open={viewHistory.open}
        question={viewHistory.question}
        patient={user}
        max_watter={progress.max_watter ? progress.max_watter : 8}
        handleClose={() =>
          setViewHistory({
            open: false,
            question: "",
          })
        }
        getDataQuizes={getData}
      />
    </div>
  );
}

export default patientQuizes;
