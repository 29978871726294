import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import api from "services/api";

const postAddForward = async params => {
  const { data, patient, doctor, appointment } = params;
  const obj = { data, patient, doctor, appointment };
  const response = await api.post(`/microservice/forward/saveForward/`, obj);

  const res = await response.data;
  return res;
};

const postRemoveForward = async params => {
  const { idForward } = params;
  const response = await api.post(`/microservice/forward/removeForward/`, {
    id: idForward
  });

  const res = await response.data;
  return res;
};

const listForward = async patient => {
  const response = await api.post(`/microservice/forward/getPatientForward/`, {
    patient
  });
  const res = await response.data.record;
  return res;
};

const listForwardAppointment = async appointment => {
  const response = await api.post(
    `/microservice/forward/getForwardAppointment/`,
    { appointment }
  );
  const res = await response.data.record;
  return res;
};

function* requestAddNewForward(action) {
  try {
    const response = yield call(postAddForward, action);
    if (response.success === true) {
      yield put({
        type: "ADD_FORWARD_SUCCESS",
        payload: {
          success: response.success,
          idForward: response.id,
          idAppointment: response.appointment,
          ...action.data
        }
      });
      yield put({
        type: "SET_SAVED_ACTIONS",
        payload: {
          id: response.saved,
          field: "forward"
        }
      });
    }
    yield put({
      type: "SET_INIT_APPOINTMENT",
      payload: { id: response.appointment }
    });

    if (response.success) {
      yield put({
        type: "CHANGE_FORM_CLEAR"
      });

      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!"
      });
    }
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!"
    });
    yield put({
      type: "ADD_FORWARD_SUCCESS",
      payload: { success: false }
    });
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  }
}

function* requestDeleteForward(action) {
  try {
    const response = yield call(postRemoveForward, action);

    if (response.success) {
      yield put({
        type: "REMOVE_FORWARD_SUCCESS",
        payload: { idForward: action.idForward }
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!"
      });
    }
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!"
    });
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  }
}

function* getListForwardHistory(action) {
  try {
    const response = yield call(listForward, action.patient);

    yield put({ type: "GET_LIST_FORWARD_COMPLETE", payload: response });
  } catch (error) {}
}

function* getListForwardAppointment(action) {
  try {
    const response = yield call(listForwardAppointment, action.appointment);

    yield put({ type: "GET_FORWARD_APPOINTMENT_COMPLETE", payload: response });
  } catch (error) {}
}

export function* requestAddForward() {
  yield takeLatest("REQUEST_SAVE_FORWARD", requestAddNewForward);
}

export function* requestRemoveForward() {
  yield takeLatest("REQUEST_REMOVE_FORWARD", requestDeleteForward);
}

export function* requestListForward() {
  yield takeLatest("GET_LIST_FORWARD", getListForwardHistory);
}

export function* requestForwardAppointment() {
  yield takeLatest("GET_FORWARD_APPOINTMENT", getListForwardAppointment);
}

export default function* rootSaga() {
  yield all([
    fork(requestAddForward),
    fork(requestListForward),
    fork(requestRemoveForward),
    fork(requestForwardAppointment)
  ]);
}
