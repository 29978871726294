import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import BarProgress from "components/CircularProgress/ProgressBar";
import ModalChoose from "components/modalChoose";
import InboxIcon from "@material-ui/icons/Inbox";

class Default extends Component {
  componentDidMount() {
    const { history } = this.props;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  openMenu = (id) => {
    if (!document.getElementById(id).classList.contains("open")) {
      const that = this;
      const menuLi = document.getElementsByClassName("menu");
      for (let i = 0; i < menuLi.length; i++) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
      }

      document.getElementById(id).classList.toggle("open");
    } else {
      document.getElementById(id).classList.remove("open");
    }
  };

  render() {
    const {
      area,
      teacher,
      secretary,
      type,
      provider_type,
      permissions,
      loaded_address,
      address_selected,
    } = this.props;

    return (
      <CustomScrollbars className=" scrollbar">
        {loaded_address ? (
          <ul className="nav-menu">
            {/* Atendimento */}
            {area !== "45" &&
              type !== "1" &&
              address_selected.provider_type !== "56" &&
              address_selected.address_type !== "3" && (
                <li className="nav-header text-center">Atendimento</li>
              )}
            {false &&
              address_selected.provider_type === "56" &&
              type !== "1" &&
              area !== "9" &&
              area !== "8" && (
                <li className="menu no-arrow">
                  <NavLink to="/app/choose/triage">
                    <i className="zmdi zmdi-collection-item-1 zmdi-hc-fw" />
                    <span className="nav-text">Escuta Inicial</span>
                  </NavLink>
                </li>
              )}

            {type === "0" &&
              ["28136", "28137"].indexOf(address_selected.id) !== -1 && (
                <li className="menu no-arrow">
                  <NavLink to="/app/choose/attendance">
                    <i className="zmdi zmdi-flare zmdi-hc-fw" />
                    <span className="nav-text">Atendimento Covid-19</span>
                  </NavLink>
                </li>
              )}

            {/* Consultório */}
            {["28136", "28137", "28134"].indexOf(address_selected.id) === -1 &&
              ((secretary && area !== "45") ||
                (area === "45" && address_selected.provider_type === "56") ||
                ((type === "0" || type === "2") &&
                  // area !== "6" &&
                  // area !== "45" &&
                  area !== "29")) &&
              address_selected.provider_type !== "56" &&
              address_selected.address_type !== "3" && (
                <li className="menu no-arrow">
                  <NavLink to="/app/choose/data-consult">
                    <i className="zmdi zmdi-assignment-o zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="module.consult.title" />
                    </span>
                  </NavLink>
                </li>
              )}

            {/* Evolução */}
            {type !== "1" && address_selected.provider_type === "5" && (
              <li className="menu no-arrow">
                <NavLink to="/app/evolution/hour-evolution">
                  <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="module.evolution.group" />
                  </span>
                </NavLink>
              </li>
            )}

            {/* Evolução */}
            {address_selected.provider_type === "55" && type === "0" && (
              <li className="menu no-arrow">
                <NavLink to="/app/choose/home-care">
                  <i className="zmdi zmdi-home zmdi-hc-fw" />
                  <span className="nav-text">Evolução - Home care</span>
                </NavLink>
              </li>
            )}

            {/* Evolução */}
            {type === "0" &&
              address_selected.provider_type === "55" &&
              type === "1" && (
                <li className="menu no-arrow">
                  <NavLink to="/app/homecare/manager">
                    <i className="zmdi zmdi-home zmdi-hc-fw" />
                    <span className="nav-text">Evolução - Home care</span>
                  </NavLink>
                </li>
              )}

            {/* Ficha de admissão */}
            {type === "0" && permissions.mdl_admission && (
              <li className="menu no-arrow">
                <NavLink to="/app/choose/data-admission">
                  <i className="zmdi zmdi-assignment-o zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="module.admission" />
                  </span>
                </NavLink>
              </li>
            )}

            {/* Medicamentos */}
            {!secretary &&
              ["6", "45", "29"].indexOf(area) !== -1 &&
              address_selected.provider_type === "55" && (
                <>
                  <li className="menu no-arrow">
                    <ModalChoose
                      title="Transcrever Prescrição"
                      url="/app/consult/transcription-prescription-out"
                      icon={<i className="zmdi zmdi-file-text zmdi-hc-fw" />}
                    />
                  </li>
                  <li className="menu no-arrow">
                    <ModalChoose
                      title="Medicamentos em uso"
                      url="/app/consult/medicines-out"
                      icon={<i className="zmdi zmdi-eyedropper zmdi-hc-fw" />}
                    />
                  </li>
                </>
              )}
            {/* Prescrição de Cuidado */}
            {/* {type === "0" && permissions.prescription_care && (
              <li className="menu no-arrow">
                <NavLink to="/app/prescriptioncare">
                  <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                  <span className="nav-text">Prescrição de Cuidados</span>
                </NavLink>
              </li>
            )} */}

            {teacher === "1" && (
              <li className="menu no-arrow">
                <NavLink to="/app/academic/professor">
                  <i className="zmdi zmdi-assignment-check zmdi-hc-fw" />
                  <span className="nav-text">Validar Atendimento</span>
                </NavLink>
              </li>
            )}

            {(area === "12" || area === "16") &&
              address_selected.provider_type !== "56" && (
                <li className="menu no-arrow">
                  <NavLink to="/app/calendar/doctor">
                    <i className="zmdi zmdi-videocam" />
                    <span className="nav-text">Meus Teleatendimentos</span>
                  </NavLink>
                </li>
              )}

            {/* {area === "9" && (
              <li className="menu no-arrow">
                <NavLink to="/app/choose/sessions">
                  <i className="zmdi zmdi-pin-account zmdi-hc-fw" />
                  <span className="nav-text">Sessões</span>
                </NavLink>
              </li>
            )} */}

            {(provider_type === "56" ||
              address_selected.provider_type === "56") &&
              address_selected.address_type !== "3" && (
                <>
                  <li className="nav-header text-center">Monitoramento</li>

                  <li className="menu no-arrow">
                    <NavLink to="/app/list-notification-covid">
                      <i className="zmdi zmdi-bug zmdi-hc-fw" />
                      <span className="nav-text">Recebidas</span>
                    </NavLink>
                  </li>

                  <li className="menu no-arrow">
                    <NavLink to="/app/monitoring/index">
                      <i className="zmdi zmdi-pin-account zmdi-hc-fw" />
                      <span className="nav-text">Monitorando</span>
                    </NavLink>
                  </li>
                </>
              )}

            {/* FERRAMENTAS */}
            <li className="nav-header text-center">
              <IntlMessages id="sidebar.tools" />
            </li>

            <li className="menu no-arrow">
              <NavLink to="/app/dashboard">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">Dashboard</span>
              </NavLink>
            </li>

            {/* <li className="menu no-arrow">
              <a
                href="https://beta1.salutemplus.com/app/blog"
                target="_blank"
              >
                <i className="zmdi zmdi-collection-bookmark zmdi-hc-fw" />
                <span className="nav-text">Blog</span>
              </a>
            </li> */}

            {/*AGENDAMENTO HOME CARE*/}
            {address_selected.provider_type === "55" && (
              <li className="menu no-arrow">
                <NavLink to="/app/homecare/scheduling-professional">
                  <i className="zmdi zmdi-calendar" />
                  <span className="nav-text">Agendamento</span>
                </NavLink>
              </li>
            )}

            {/* Agenda */}
            {((["55"].indexOf(address_selected.provider_type) === -1 &&
              type !== "2") ||
              secretary) && (
              <li className="menu no-arrow">
                <NavLink to="/app/calendar">
                  <i className="zmdi zmdi-calendar-note zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.tools.calendar" />
                  </span>
                </NavLink>
              </li>
            )}

            {/* Comunicação */}
            <li
              id="comunication"
              className="menu"
              onClick={() => this.openMenu("comunication")}
            >
              <Button>
                <i className="zmdi zmdi-comment-more zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="module.comunication" />
                </span>
              </Button>

              <ul className="sub-menu">
                <li>
                  <NavLink to="/app/comunication/chat">
                    <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                    <span className="nav-text"> Chat</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/comunication/contacts">
                    <i className="zmdi zmdi-comments" />
                    <span className="nav-text">Gestão de contatos</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/comunication/scheduled">
                    <i className="zmdi zmdi-comment-more zmdi-hc-fw" />
                    <span className="nav-text">Msg. programada</span>
                  </NavLink>
                </li>

                {/* <li>
                  <NavLink to="/app/comunication/video">
                    <i className="zmdi zmdi-comments" />
                    <span className="nav-text">Videochamada</span>
                  </NavLink>
                </li> */}
              </ul>
            </li>

            {/* {(address_selected.manager ||
              permissions.manager ||
              permissions.master) &&
              type !== "2" && (
                <li className="menu no-arrow mt-2 mb-2">
                  <Tooltip title="Em desenvolvimento" placement="top">
                    <span style={{ marginLeft: "20px" }}>
                      <i className="zmdi zmdi-truck zmdi-hc-fw" />
                      <span className="nav-text">Estoque</span>
                    </span>
                  </Tooltip>
                </li>
              )} */}

            {(address_selected.provider_type === "56" ||
              provider_type === "56") &&
              address_selected.address_type === "3" && (
                <li className="menu no-arrow">
                  <NavLink to="/app/pharmacy">
                    <i className="zmdi zmdi-truck zmdi-hc-fw" />
                    <span className="nav-text">Farmácia</span>
                  </NavLink>
                </li>
              )}

            {(permissions.manager ||
              permissions.master ||
              address_selected.manager ||
              permissions.extract ||
              address_selected.provider_type !== "5") &&
              address_selected.id && (
                <li className="menu no-arrow">
                  <NavLink to="/app/report/extract">
                    <i className="zmdi zmdi-search-in-file zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.tools.extract" />
                    </span>
                  </NavLink>
                </li>
              )}

            {(type === "1" ||
              ["5", "55", "56"].indexOf(address_selected.provider_type) ===
                -1) &&
              provider_type !== "56" && (
                <li className="menu no-arrow">
                  <NavLink to="/app/finance/list">
                    <i className="zmdi zmdi-balance zmdi-hc-fw" />
                    <span className="nav-text">Financeiro</span>
                  </NavLink>
              </li>
              )}

            {(provider_type === "5" ||
              provider_type === "55" ||
              area === "46" ||
              address_selected.manager ||
              permissions.manager ||
              (permissions.master && type !== "1") ||
              permissions.approver ||
              permissions.manager_patient) && (
              <li
                className="menu"
                id="manage"
                onClick={() => this.openMenu("manage")}
              >
                <Button>
                  <i className="zmdi zmdi-settings zmdi-hc-fw" />
                  <span className="nav-text">Gestão</span>
                </Button>

                <ul className="sub-menu">
                  {(provider_type === "5" ||
                    provider_type === "55" ||
                    area === "46" ||
                    address_selected.manager ||
                    permissions.manager ||
                    permissions.master) && (
                    <li>
                      <NavLink to="/app/management/care-pattern">
                        <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                        <span className="nav-text">Cuidados Padrões</span>
                      </NavLink>
                    </li>
                  )}
                  {(provider_type === "5" ||
                    provider_type === "55" ||
                    area === "46" ||
                    address_selected.manager ||
                    permissions.manager ||
                    permissions.master) && (
                    <li>
                      <NavLink to="/app/management/evolution-intervals">
                        <i className="zmdi zmdi-alarm-plus zmdi-hc-fw" />
                        <span className="nav-text">Intervalo de horário</span>
                      </NavLink>
                    </li>
                  )}
                  {(provider_type === "5" ||
                    provider_type === "55" ||
                    area === "46" ||
                    address_selected.manager ||
                    permissions.manager ||
                    permissions.master) && (
                    <li>
                      <NavLink to="/app/management/evolution-notifications">
                        <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
                        <span className="nav-text">Notificações</span>
                      </NavLink>
                    </li>
                  )}
                  {(provider_type === "5" ||
                    provider_type === "55" ||
                    area === "46" ||
                    address_selected.manager ||
                    permissions.manager ||
                    permissions.master ||
                    permissions.manager_patient) && (
                    <li>
                      <NavLink to="/app/management/patient">
                        <i className="zmdi zmdi-accounts-list-alt zmdi-hc-fw" />
                        <span className="nav-text">Pacientes</span>
                      </NavLink>
                    </li>
                  )}
                  {(provider_type === "5" ||
                    provider_type === "55" ||
                    area === "46" ||
                    address_selected.manager ||
                    permissions.manager ||
                    permissions.master) && (
                    <li>
                      <NavLink to="/app/management/professional">
                        <i className="zmdi zmdi-case zmdi-hc-fw" />
                        <span className="nav-text">Profissionais</span>
                      </NavLink>
                    </li>
                  )}
                  {(provider_type === "5" ||
                    provider_type === "55" ||
                    area === "46" ||
                    address_selected.manager ||
                    // permissions.manager ||
                    permissions.master) && (
                    <li>
                      <NavLink to="/app/management/departments">
                        <i className="zmdi zmdi-menu zmdi-hc-fw" />
                        <span className="nav-text">Departamentos</span>
                      </NavLink>
                    </li>
                  )}
                  {(permissions.manager ||
                    address_selected.manager ||
                    permissions.approver ||
                    area === "46" ||
                    permissions.master) && (
                    <li>
                      <NavLink to="/app/requests">
                        <i className="zmdi zmdi-mail-send zmdi-hc-fw" />
                        <span className="nav-text">
                          <IntlMessages id="module.requests.title" />
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {(permissions.manager ||
                    permissions.approver ||
                    area === "16" ||
                    permissions.master) && (
                    <li>
                      <NavLink to="/app/management-odonto">
                        <i className="zmdi zmdi-face zmdi-hc-fw" />
                        <span className="nav-text">Odonto</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {/* Intranet */}
            {["5", "55"].indexOf(address_selected.provider_type) === -1 &&
              teacher === "0" &&
              type !== "2" && (
                <li
                  className="menu"
                  id="intra"
                  onClick={() => this.openMenu("intra")}
                >
                  <Button>
                    <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.intranet" />
                    </span>
                  </Button>

                  <ul className="sub-menu">
                    <li>
                      <NavLink to="/app/intranet/user-manage">
                        <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                        <span className="nav-text">Gestão de usuários</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/app/intranet/group-manage">
                        <i className="zmdi zmdi-group-work zmdi-hc-fw" />
                        <span className="nav-text">Gestão de grupos</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}

            {type !== "2" && (
              <li className="menu no-arrow">
                <NavLink to="/app/workplace">
                  <i className="zmdi zmdi-pin zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.tools.workplace" />
                  </span>
                </NavLink>
              </li>
            )}

            {type === "1" && (
              <li className="menu no-arrow">
                <NavLink to="/app/manage-point-register">
                  <i className="zmdi zmdi-assignment-check zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="module.point_register" />
                  </span>
                </NavLink>
              </li>
            )}

            {(type === "0" || secretary) && (
              <li className="menu no-arrow">
                <NavLink to="/app/point-register">
                  <i className="zmdi zmdi-assignment-check zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="module.point_register" />
                  </span>
                </NavLink>
              </li>
            )}

            {(permissions.manager ||
              address_selected.manager ||
              permissions.master ||
              secretary) && (
              <li className="menu no-arrow">
                <NavLink to="/app/report">
                  <i className="zmdi zmdi-view-list zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.tools.report" />
                  </span>
                </NavLink>
              </li>
            )}
          </ul>
        ) : (
          <ul className="nav-menu">
            <li className="d-flex justify-content-center m-2">
              <BarProgress />
            </li>
          </ul>
        )}
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.authUser,
    area: state.auth.area,
    type: state.auth.type,
    provider_type: state.auth.provider_type,
    teacher: state.auth.teacher,
    secretary: state.auth.secretary,
    address_selected: state.auth.address_selected,
    loaded_address: state.auth.loaded_address,
    permissions: state.permissions,
  };
};

export default withRouter(connect(mapStateToProps)(Default));
