import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import {
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import { BtnSave, BtnCan } from "components/Button";
import TextField from "components/TextFields";
import ConfirmCloseCollpase from "components/Modal/ConfirmCloseCollpase";
import Api from "services/api";
import moment from "moment";
import AutocompletePatient from "components/Autocomplete/AutocompletePatient";
import ComponentsProfile from "components/ComponentsProfile";
import RegisterUser from "components/Forms/RegisterUser";
import { formatName } from "services/functions";

export default function FormNewAttendance({
  open,
  handleClose,
  beforeSave,
  data,
  services,
}) {
  const initial = {
    id: "",
    patient: "",
    patient_name: "",
    type: "0",
    service: "",
    professional: "",
    schedule: "",
    classification: "",
    date: moment().format("YYYY-MM-DD"),
    hour: moment().format("HH:mm"),
  };
  const [state, setState] = useState(initial);
  const [doctors, setDoctors] = useState([]);

  const [newUser, setNewUser] = useState({ open: false, name: "" });

  const [schedules, setSchedules] = useState([]);
  const [error, setError] = useState(false);
  const [edited, setEdited] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  const address = useSelector((state) => state.auth.address_selected);
  const doctor = useSelector((state) => state.auth.authUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data.id) {
      let aux = data.arrival.split(" ");
      setState({
        id: data.id,
        patient: data.fk_ls_user_patient,
        name: data.patient_name,
        type: data.type,
        service: data.service_id,
        professional: data.fk_ls_user_professional,
        schedule: data.fk_ls_calendar_event,
        // classification: data.status,
        date: aux[0],
        hour: aux[1],
      });
    }
  }, [data]);

  useEffect(() => {
    if (state.patient) {
      Api.post(`ms_system/dashboard/getSchedulesPatientAddress`, {
        address: address.id,
        patient: state.patient,
        date: moment().format("YYYY-MM-DD"),
      }).then(({ data }) => {
        setSchedules(data.record);
      });
      if (state.add_intra) {
        dispatch({
          type: "INSERT_GROUP_INTRANET",
          payload: {
            patient: state.patient,
            address: address.id,
            auth: doctor,
          },
        });
      }
    }
  }, [state.patient]);

  useEffect(() => {
    if (state.type === "1" && schedules.length === 1) {
      setState({
        ...state,
        schedule: schedules[0].id,
      });
    }
  }, [state.type]);

  useEffect(() => {
    if (state.service) {
      Api.post(`ms_system/dashboard/getProfessionalsByService`, {
        address: address.id,
        service: state.service,
      }).then(({ data }) => {
        setDoctors(data.record);
      });
    }
  }, [state.service]);

  const handleCloseModal = () => {
    if (edited) {
      setConfirmClose(true);
    } else {
      setState(initial);
      setError(false);
      setEdited(false);
      handleClose();
    }
  };

  const handleSelectPatient = (selected) => {
    if (selected) {
      if (selected.id === "new") {
        setNewUser({
          open: true,
          name: formatName(selected.name),
        });
      } else {
        let pat_id = selected.id;
        let add_intra = false;
        if (selected.id < 0) {
          pat_id = selected.id.substr(1);
          add_intra = true;
        }
        setState({
          ...state,
          patient: pat_id,
          name: selected.name,
          add_intra,
        });
        setEdited(true);
      }
    } else {
      setState({
        ...state,
        patient: "",
        name: "",
      });
    }
  };

  const validate = () => {
    if (state.type && state.patient) {
      if (state.type === "1") {
        if (state.schedule) {
          return true;
        } else {
          setError(true);
          return false;
        }
      } else {
        if (state.service && state.professional) {
          return true;
        } else {
          setError(true);
          return false;
        }
      }
    } else {
      setError(true);
      return false;
    }
  };

  const handleSave = () => {
    if (validate()) {
      let formData = {
        ...state,
        address: address.id,
      };

      if (state.type === "1") {
        let aux = schedules.find((row) => row.id === state.schedule);
        formData = {
          ...formData,
          service: aux.fk_ls_user_has_types_event,
          professional: aux.doctor_id,
        };
      }

      Api.post(`/ms_system/dashboard/saveServiceQueue`, formData)
        .then((res) => {
          beforeSave(state.date);
          setState(initial);
          setError(false);
          setEdited(false);
          handleClose();
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
        });
    }
  };

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value });
    if (!edited) {
      setEdited(true);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="max-width-dialog-title"
      scroll="body"
    >
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-primary font-weight-semibold">
              Adicionar Atendimento
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={handleCloseModal}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>

          <div className="col-md-6">
            <TextField
              type="date"
              fullWidth
              label="Data:"
              value={state.date}
              onChange={(e) => {
                handleChange("date", e.target.value);
              }}
              inputProps={{
                max: moment().format("YYYY-MM-DD"),
              }}
            />
          </div>
          <div className="col-md-6">
            <TextField
              type="time"
              fullWidth
              label="Hora:"
              value={state.hour}
              onChange={(e) => {
                handleChange("hour", e.target.value);
              }}
            />
          </div>

          <div className="col-md-12 col-12 mt-3">
            <AutocompletePatient
              address={address.id}
              handleChangeAutocomplete={handleSelectPatient}
              doctor={doctor}
              error={error && !state.patient}
              value={{
                id: state.patient,
                name: state.name,
              }}
            />
          </div>

          {state.patient && (
            <>
              <div className="col-12 mt-3">
                <FormLabel
                  style={{
                    color: error && !state.type ? "red" : "",
                  }}
                >
                  Tipo de demanda
                </FormLabel>
                <RadioGroup
                  className="d-flex flex-row ml-3"
                  value={state.type}
                  onChange={(e) => handleChange("type", e.target.value)}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="Espontânea"
                    style={{
                      color: error && !state.type ? "red" : "",
                    }}
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Agendada"
                    style={{
                      color: error && !state.type ? "red" : "",
                    }}
                    disabled={schedules.length === 0}
                  />
                </RadioGroup>
              </div>

              {state.type !== "1" ? (
                <>
                  {/* <div className="col-12 mt-3">
                    <FormLabel
                      style={{
                        color: error && !state.service ? "red" : "",
                      }}
                    >
                      Tipo de Serviço
                    </FormLabel>
                    <RadioGroup
                      className="d-flex flex-row ml-3"
                      value={state.service}
                      onChange={(e) => handleChange("service", e.target.value)}
                    >
                      {services.map((row) => (
                        <FormControlLabel
                          value={row.value}
                          control={<Radio color="primary" />}
                          label={row.label}
                          style={{
                            color: error && !state.service ? "red" : "",
                          }}
                        />
                      ))}
                    </RadioGroup>
                  </div> */}
                  <div className="col-12 mt-3">
                    <TextField
                      select
                      fullWidth
                      label="Serviço:"
                      value={state.service}
                      onChange={(e) => {
                        handleChange("service", e.target.value);
                      }}
                      error={error && !state.service}
                    >
                      {services.map((row) => (
                        <MenuItem key={row.value} value={row.value}>
                          {row.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>

                  {state.service && (
                    <div className="col-12 mt-3">
                      <TextField
                        select
                        fullWidth
                        label="Profissional:"
                        value={state.professional}
                        onChange={(e) => {
                          handleChange("professional", e.target.value);
                        }}
                        error={error && !state.professional}
                      >
                        {doctors.map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            <ComponentsProfile
                              id={row.id}
                              nameProfile={row.name}
                              subTitle={row.specialty}
                              photo={row.photo}
                            />
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="col-12 mt-3">
                    <FormLabel
                      style={{
                        color: error && !state.schedule ? "red" : "",
                      }}
                    >
                      Agendamento do dia
                    </FormLabel>
                    <RadioGroup
                      className="ml-3"
                      value={state.schedule}
                      onChange={(e) => handleChange("schedule", e.target.value)}
                    >
                      {schedules.map((row) => (
                        <FormControlLabel
                          value={row.id}
                          control={<Radio color="primary" />}
                          label={`${moment(row.start).format(
                            "HH:mm"
                          )} - ${moment(row.end).format("HH:mm")} : ${
                            row.doctor_name
                          } - ${row.service}`}
                          className="col-12"
                          style={{
                            color: error && !state.schedule ? "red" : "",
                          }}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </>
              )}

              {/* <div className="col-12 mt-3">
                <TextField
                  label="Classificação de Risco/Vulnerabilidade:"
                  select
                  value={state.classification}
                  onChange={(e) => {
                    handleChange("classification", e.target.value);
                  }}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  error={error && !state.classification}
                >
                  <MenuItem key="0" value="0">
                    <i
                      className="zmdi zmdi-circle zmdi-fw mr-3"
                      style={{ color: "blue" }}
                    />{" "}
                    Não aguda
                  </MenuItem>
                  <MenuItem key="1" value="1">
                    <i
                      className="zmdi zmdi-circle zmdi-fw mr-3"
                      style={{ color: "green" }}
                    />{" "}
                    Baixa
                  </MenuItem>
                  <MenuItem key="2" value="2">
                    <i
                      className="zmdi zmdi-circle zmdi-fw mr-3"
                      style={{ color: "yellow" }}
                    />{" "}
                    Intermediária
                  </MenuItem>
                  <MenuItem key="3" value="3">
                    <i
                      className="zmdi zmdi-circle zmdi-fw mr-3"
                      style={{ color: "red" }}
                    />{" "}
                    Alta
                  </MenuItem>
                </TextField>
              </div> */}
            </>
          )}

          <div className="col-12 mt-3">
            <BtnCan title="Fechar" onClick={handleCloseModal} float="left" />
            <BtnSave onClick={handleSave} float="right" />
          </div>
        </div>
      </DialogContent>
      <ConfirmCloseCollpase
        open={confirmClose}
        message="Alterações não salvas! Deseja sair sem salvar?"
        confirm={() => {
          setState(initial);
          setConfirmClose(false);
          setEdited(false);
          setError(false);
          handleClose();
        }}
        cancel={() => setConfirmClose(false)}
      />
      <RegisterUser
        add={(id, name) => {
          setState({
            ...state,
            patient: id,
            name: name,
            add_intra: true,
          });
          setNewUser({ open: false, name: "" });
        }}
        nameUser={newUser.name}
        open={newUser.open}
        close={() => setNewUser({ open: false, name: "" })}
        address=""
        registerAddress={true}
        healthPlan={false}
      />
    </Dialog>
  );
}
