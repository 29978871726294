import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  TextField,
  MenuItem,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MaskCpf from "components/Masks/MaskCpf";
import MaskCellphone from "components/Masks/MaskCellphone";
import AutocompleteNaturalty from "./AutocompleteNaturalty";
import AutocompleteNaturaltyResiden from "./AutocompleteNaturaltyResiden";
import SweetAlert from "react-bootstrap-sweetalert";
import AutocompleteParents from "components/Autocomplete/Parents";
import Api from "services/api";
import { allowCPF } from "./Functions";
import moment from "moment";

export default function PersonalData({
  handleNext,
  getState,
  stateInit,
  validateData,
  history,
  validationUser,
  type,
}) {
  const [state, setState] = useState(stateInit);
  const [messageCpf, setCpfMessage] = useState("");
  const [modalEmail, setModalEmail] = useState(false);
  const [modalCpf, setModalCpf] = useState(false);
  const [modalUser, setModalUser] = useState(false);
  const [validaCpf, setValidateCpf] = useState(validateData.cpf);
  const [validaPassword, setValidatePassword] = useState(validateData.password);
  const [validaUserName, setValidateUsername] = useState(
    validationUser === 1 ? false : validateData.username
  );
  const [validaEmail, setValidateEmail] = useState(validateData.email);
  const [alert, setAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const {
    name,
    username,
    mother_name,
    cpf,
    date,
    sex,
    idNaturalty,
    naturalty,
    email,
    cellphone,
    password,
    confirmPassword,
    residen_id,
    residen,
    error,
  } = state;

  const validate = async () => {
    CheckCpf();
    validationUser !== 1 && CheckUsername();
    CheckPassword();

    await verifyState();
  };

  const verifyState = () => {
    if (
      validaCpf && validaEmail && name && validationUser === 1
        ? true
        : username &&
          cpf &&
          date &&
          sex &&
          (email || (cellphone && cellphone.indexOf("_") === -1)) &&
          password &&
          validaPassword &&
          confirmPassword
    ) {
      if (validationUser === 1 ? true : validaUserName) {
        setState({ ...state, error: false });
        getState(state, "personalData");
        getState(
          { cpf: validaCpf, email: validaEmail, username: validaUserName },
          "validate"
        );
        handleNext();
      }
    } else {
      setState({ ...state, error: true });
    }
  };

  React.useEffect(() => {
    let search = window.location.search;
    if (search) {
      let user = search.split("d20");
      if (user.length > 1) {
        getDataUser(user[1]);
      }
    }
  }, []);

  const getDataUser = (id) => {
    Api.post(`/ms_system/user/getUserQuickRegister`, { user: id }).then(
      (res) => {
        const {
          id,
          idData,
          gender,
          name,
          birthday,
          smartphone,
          email,
          cpf,
          quick_register,
        } = res.data.dataUser;

        if (quick_register === "1") {
          setState({
            ...state,
            id,
            idData,
            sex: gender,
            date: birthday,
            name,
            cellphone: smartphone ? smartphone : "",
            cpf,
            email,
          });
        } else {
          setAlert(true);
        }
      }
    );
  };

  const CheckPassword = () => {
    setValidatePassword(false);
    const caracterSpecial = [
      "@",
      ".",
      ",",
      ";",
      "'",
      "!",
      '"',
      "?",
      "/",
      "=",
      " ",
      "#",
      "_",
      "-",
      "$",
      "%",
      "*",
      "(",
      ")",
      "[",
      "]",
      "{",
      "}",
      "ª",
      "º",
    ];
    let validaPass = true;
    if (password.length >= 6) {
      password.split("").forEach((el) => {
        if (caracterSpecial.indexOf(el) !== -1) {
          validaPass = false;
        }
      });
    } else {
      validaPass = false;
    }
    setValidatePassword(validaPass);
  };

  const CheckCpf = () => {
    setValidateCpf(false);
    if (cpf !== stateInit.cpf) {
      if (cpf.length !== 0) {
        const verifica = allowCPF(cpf);
        if (verifica) {
          Api.post(`/ms_system/user/checkCPF`, { cpf: cpf }).then((res) => {
            if (res.data.record.length !== 0) {
              if (res.data.record[0].quick_register === "1") {
                setCpfMessage(
                  "Este CPF já está cadastrado pelo registro rápido. complete as informações"
                );
                setModalCpf(true);
                getDataUser(res.data.record[0].id);
                setValidateCpf(true);
                return true;
              } else {
                setCpfMessage(
                  "Este CPF já está cadastrado. Por favor utilize outro."
                );
                setModalCpf(true);
                setValidateCpf(false);
                return false;
              }
            } else {
              setValidateCpf(true);
              return true;
            }
          });
        } else {
          setCpfMessage("CPF Inválido");
          setModalCpf(true);
          setValidateCpf(false);
          return false;
        }
      } else {
        setValidateCpf(false);
        return false;
      }
    } else {
      setValidateCpf(true);
      return true;
    }
  };

  const checkEmail = () => {
    setValidateEmail(false);
    if (email)
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setValidateEmail(false);
        return false;
      } else {
        setValidateEmail(true);
        Api.post(`/ms_system/user/checkEmail`, { email }).then((res) => {
          if (res.data.record.length !== 0) {
            setModalEmail(true);
          }
        });
        return true;
      }
  };

  const recuperarSenha = () => {
    setModalEmail(false);
  };

  const CheckUsername = () => {
    if (validationUser !== 1) {
      setValidateUsername(false);
      username
        ? Api.post(`/ms_system/user/checkUserName`, { username }).then(
            (res) => {
              if (res.data.record.length !== 0) {
                setModalUser(true);
                setValidateUsername(false);
                return false;
              } else {
                setValidateUsername(true);
                return true;
              }
            }
          )
        : setValidateUsername(false);
    } else {
      setValidateUsername(false);
    }
  };

  const handleChange = (item) => {
    setState({ ...state, naturalty: item.label, idNaturalty: item.id });
  };
  const handleChangeResiden = (item) => {
    setState({ ...state, residen: item.label, residen_id: item.id });
  };
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <TextField
            required
            label="Nome completo"
            margin="normal"
            helperText={error && !name ? "Campo Obrigatório" : ""}
            fullWidth
            value={name}
            error={error && !name ? true : false}
            autocomplete="new-password"
            autoFocus
            // onChange={(e) => setState({ ...state, name: e.target.value })}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              onInput: (e) => {
                if (e.target.value) {
                  let formtCase = e.target.value.split(" ").map((row) => {
                    return row === "Da" ||
                      row === "da" ||
                      row === "De" ||
                      row === "de" ||
                      row === "do" ||
                      row === "Do" ||
                      row === "das" ||
                      row === "Das" ||
                      row === "Dos" ||
                      row === "dos"
                      ? row.charAt(0).toLowerCase() + row.slice(1).toLowerCase()
                      : row.charAt(0).toUpperCase() +
                          row.slice(1).toLowerCase();
                  });
                  setState({ ...state, name: formtCase.join(" ") });
                } else {
                  setState({ ...state, name: "" });
                }
              },
            }}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="cpf"
            label="CPF"
            required
            margin="normal"
            fullWidth
            helperText={
              error && !cpf
                ? "Campo Obrigatório"
                : error && cpf && !validaCpf
                ? "Por Favor insira outro CPF."
                : ""
            }
            InputProps={{
              inputComponent: MaskCpf,
              value: cpf,
              onChange: (e) => setState({ ...state, cpf: e.target.value }),
            }}
            error={error && !cpf ? true : error && cpf && !validaCpf}
            onBlur={CheckCpf}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="dt_nasc"
            type="date"
            label="Data de Nascimento:"
            value={date}
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setState({ ...state, date: e.target.value })}
            helperText={error && !date ? "Campo Obrigatório" : ""}
            error={error && !date ? true : false}
            onInput={(e) => {
              if (e.target.value.length > 10) {
                e.target.value = e.target.value.substr(1, 11);
              }
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              max: moment().format("YYYY-MM-DD"),
            }}
          />
        </div>
        <div className="col-md-4">
          <TextField
            select
            id="sexo"
            label="Sexo"
            required
            margin="normal"
            fullWidth
            helperText={error && !sex ? "Campo Obrigatório" : ""}
            value={sex}
            onChange={(e) => setState({ ...state, sex: e.target.value })}
            error={error && !sex ? true : false}
            style={{ textAlign: "left" }}
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="0">Masculino</MenuItem>
            <MenuItem value="1">Feminino</MenuItem>
            <MenuItem value="2">Outro</MenuItem>
          </TextField>
        </div>
        {/* </div>
      <div className="row"> */}
        <div className="col-md-4">
          <TextField
            label="Nome de usuário"
            required
            margin="normal"
            fullWidth
            value={username}
            onChange={(e) =>
              setState({ ...state, username: e.target.value.toLowerCase() })
            }
            // onBlur={CheckUsername}
            error={error && !username}
            helperText={error && !username ? "Campo Obrigatório" : ""}
            InputLabelProps={{ shrink: true }}
            error={error && !username}
          />
        </div>
        <div className="col-md-4">
          <TextField
            label="Nome da mãe:"
            margin="normal"
            fullWidth
            value={mother_name}
            onChange={(e) =>
              setState({ ...state, mother_name: e.target.value })
            }
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="email"
            label="E-mail"
            margin="normal"
            fullWidth
            value={email}
            onBlur={checkEmail}
            onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
            onChange={(e) => setState({ ...state, email: e.target.value })}
            helperText={
              error && !cellphone && !email
                ? "Informe uma forma de contato (E-mail ou Celular)"
                : !validaEmail && email
                ? "E-mail Invalido."
                : ""
            }
            error={
              (error && !cellphone && !email) || (error && !validaEmail)
                ? true
                : false
            }
            InputLabelProps={{ shrink: true }}
          />
        </div>
        {/* <div className="col-md-4">
          <AutocompleteNaturalty
            setNaturalty={handleChange}
            naturalty={{ id: idNaturalty, label: naturalty }}
            error={error && !naturalty ? true : false}
          />
        </div> */}
        <div className="col-md-4">
          <TextField
            id="celular"
            label="Celular"
            helperText={
              cellphone.indexOf("_") !== -1
                ? "Telefone Inválido"
                : error && !cellphone && !email
                ? "Informe uma forma de contato (E-mail ou Celular)"
                : ""
            }
            InputProps={{
              inputComponent: MaskCellphone,
              value: cellphone,
              onChange: (e) =>
                setState({ ...state, cellphone: e.target.value }),
            }}
            margin="normal"
            fullWidth
            error={
              (error && !cellphone && !email) ||
              (error && cellphone.indexOf("_") !== -1)
            }
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <AutocompleteNaturaltyResiden
            setNaturalty={handleChangeResiden}
            naturalty={{ id: residen_id, label: residen }}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="senha"
            label="Senha"
            required
            type={showPassword ? "text" : "password"}
            margin="normal"
            fullWidth
            value={password}
            onChange={(e) => {
              setState({ ...state, password: e.target.value });
            }}
            helperText={
              error && !password
                ? "Campo Obrigatório"
                : error && !validaPassword && password
                ? "Use 6 ou mais caracteres para a sua senha"
                : ""
            }
            error={
              error && !password
                ? true
                : error && !validaPassword && password
                ? true
                : false
            }
            onBlur={CheckPassword}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="confirm_senha"
            label="Confirmar Senha"
            required
            type={showPassword ? "text" : "password"}
            margin="normal"
            fullWidth
            value={confirmPassword}
            helperText={
              error && !confirmPassword
                ? "Campo Obrigatório"
                : confirmPassword !== password && confirmPassword.length > 0
                ? "As senhas precisam ser iguais."
                : ""
            }
            onChange={(e) =>
              setState({ ...state, confirmPassword: e.target.value })
            }
            error={
              (error && !confirmPassword) ||
              (confirmPassword !== password && confirmPassword.length > 0)
                ? true
                : false
            }
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div
        className="align-center mt-4"
        // style={{ justifyContent: "space-between" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={validate}
          className="jr-btn"
          style={{ float: "right", marginRight: 0 }}
        >
          Próxima
        </Button>
        <NavLink
          to={type === "notification" ? "/notification-covid" : "/signup"}
          style={{ float: "left", marginLeft: 0 }}
        >
          <Button className="jr-btn">Voltar</Button>
        </NavLink>
      </div>
      <SweetAlert
        show={modalCpf}
        warning
        showConfirm={true}
        title={messageCpf}
        onConfirm={() => setModalCpf(false)}
      />
      <SweetAlert
        show={modalUser}
        warning
        showConfirm={true}
        title="Este nome de usuário ja foi cadastrado. Por favor utilize outro."
        onConfirm={() => setModalUser(false)}
      />
      <SweetAlert
        show={modalEmail}
        warning
        showConfirm={true}
        showCancel={true}
        cancelBtnBsStyle="default"
        cancelBtnText="Recuperar Senha"
        confirmBtnText="Continuar"
        title="Este e-mail já está cadastrado. Deseja:"
        onConfirm={() => setModalEmail(false)}
        onCancel={() => recuperarSenha()}
      />
      <SweetAlert
        show={alert}
        warning
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Ok"
        confirmButtonColor="#bc0003"
        title="Cadastro já realizado, faça login para continuar."
        onConfirm={() => history.push("/family-accompaniment")}
      />
    </>
  );
}
