import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { Dialog, DialogContent, Tooltip, IconButton } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { BtnSave, BtnCan } from "components/Button";
import TextField from "components/TextFields";
import ConfirmCloseCollpase from "components/Modal/ConfirmCloseCollpase";
import Api from "services/api";
import moment from "moment";

export default function FormFinishTreatment({
  record,
  open,
  handleClose,
  handleFinish
}) {
  const initial = {
    date: moment(),
    observation: ""
  };
  const [state, setState] = useState(initial);
  const [edited, setEdited] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const doctor = useSelector(state => state.auth.authUser);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    if (edited) {
      setConfirmClose(true);
    } else {
      setState(initial);
      setEdited(false);
      handleClose();
    }
  };

  const handleSave = () => {
    let formData = {
      id: record.id,
      doctor,
      date: state.date,
      observation: state.observation
    };
    Api.post(`/microservice/admission/finishTreatmentDiagnostic`, formData)
      .then(() => {
        setState(initial);
        setEdited(false);
        handleFinish();
        dispatch(viewAlertMessage("success", "Tratamento finalizado!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="max-width-dialog-title"
      scroll="body"
    >
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-primary font-weight-semibold">
              Finalizar tratamento
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={handleCloseModal}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>

          <div className="col-md-8 col-12">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
              <KeyboardDatePicker
                className="mr-3"
                margin="normal"
                label="Data término:"
                format="dd/MM/yyyy"
                value={state.date}
                onChange={date => {
                  setState({ ...state, date });
                  if (!edited) {
                    setEdited(true);
                  }
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                InputLabelProps={{
                  shrink: true
                }}
                cancelLabel="Cancelar"
                disableFuture={true}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="col-12">
            <TextField
              multiline
              rows={3}
              value={state.observation}
              onChange={e => {
                setState({
                  ...state,
                  observation: e.target.value
                });
                if (!edited) {
                  setEdited(true);
                }
              }}
              label="Observação:"
              fullWidth
            />
          </div>

          <div className="col-12 mt-3">
            <BtnCan title="Fechar" onClick={handleCloseModal} float="left" />
            <BtnSave onClick={handleSave} float="right" />
          </div>
        </div>
      </DialogContent>
      <ConfirmCloseCollpase
        open={confirmClose}
        message="Alterações não salvas! Deseja sair sem salvar?"
        confirm={() => {
          setConfirmClose(false);
          setEdited(false);
          handleClose();
        }}
        cancel={() => setConfirmClose(false)}
      />
    </Dialog>
  );
}
