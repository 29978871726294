import React from "react";
import { BtnCan } from "components/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import moment from "moment";

export default function ViewPhysicalActivities({ open, data, handleClose }) {
  const getDescriptionDay = (day) => {
    switch (day) {
      case "0":
        return "Domingo";
      case "1":
        return "Segunda-feira";
      case "2":
        return "Terça-feira";
      case "3":
        return "Quarta-feira";
      case "4":
        return "Quinta-feira";
      case "5":
        return "Sexta-feira";
      case "6":
        return "Sábado";
    }
  };

  return (
    <ModalResponsive
      title={data.name}
      open={open}
      close={handleClose}
      maxWidth="xs"
      buttons={
        <div className="col-12">
          <BtnCan title="Fechar" onClick={handleClose} float="left" />
        </div>
      }
    >
      <div className="row">
        {data.start && (
          <div className="col-md-12">
            <strong>Pratica desde:</strong>{" "}
            {moment(data.start).format("DD/MM/YYYY")}
          </div>
        )}
        <div className="col-md-12">
          <strong>Receber alertas:</strong> {data.alert === "1" ? "Sim" : "Não"}
        </div>
        {data.time_alert && (
          <div className="col-md-12">
            <strong>Antêcedencia do alerta:</strong>{" "}
            {data.time_alert.substr(0, 5)}
          </div>
        )}

        <div
          className="col-12 mt-3"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          <Table className="table-finances">
            <TableHead>
              <TableRow>
                <TableCell className="pt-0 pb-1">Dia da semana</TableCell>
                <TableCell className="pt-0 pb-1" align="left">
                  Horário
                </TableCell>
                <TableCell className="pt-0 pb-1" align="left">
                  Duração
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.days.map((row) => (
                <TableRow hover key={row.weekday}>
                  <TableCell className="pt-0 pb-1" component="th" scope="row">
                    {getDescriptionDay(row.weekday)}
                  </TableCell>
                  <TableCell className="pt-0 pb-1" align="left">
                    {row.hour ? row.hour.substr(0, 5) : ""}
                  </TableCell>
                  <TableCell className="pt-0 pb-1" align="left">
                    {row.duration ? row.duration.substr(0, 5) : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="col-12 mt-3">
          <strong>Observações:</strong> {data.observation}
        </div>
      </div>
    </ModalResponsive>
  );
}
