import React from "react";
import ReactDOM from "react-dom";
import SweetAlert from "react-bootstrap-sweetalert";

const UserConfirmation = (message, callback) => {
  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  document.body.appendChild(container);
  const closeModal = callbackState => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
  };
  ReactDOM.render(
    //   <Dialog
    //      cancelLabel="Cancel"
    //      confirmLabel="Confirm"
    //      isShown={true}
    //      onCacel={() => closeModal(false)}
    //      onConfirm={() => closeModal(true)}
    //      title="Warning"
    //   >
    //    {message}
    //   </Dialog>,
    <SweetAlert
      show={true}
      warning
      showCancel
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="primary"
      confirmBtnText="Sim, sair"
      confirmButtonColor="#bc0003"
      cancelBtnText="Cancelar"
      title={message}
      onConfirm={() => closeModal(true)}
      onCancel={() => closeModal(false)}
    />,
    container
  );
};
export default UserConfirmation;
