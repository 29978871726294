const INITIAL_STATE = {
  nameGroup: "",
  sexoPatient: ""
};

export default function Circumferences(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_SEXO_PATIENT":
      return {
        ...state,
        id: action.payload.id
      };

    case "GET_SEXO_PATIENT_SUCCESS":
      return {
        ...state,
        sexoPatient: action.payload.sexoPatient
      };

    case "CAD_CIRCUMFERENCES":
      return {
        ...state,
        formData: action.payload.formData
      };
    case "RESET_CIRCUMFERENCES":
      return INITIAL_STATE;
    default:
      return state;
  }
}
