import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfigCalendar } from "actions/Calendar";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import {
  TextField,
  Switch,
  FormControlLabel,
  MenuItem,
  FormGroup,
  Typography,
  Checkbox,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { MultipleSelect } from "react-select-material-ui";
import { BtnSave, BtnCan } from "components/Button";
import Api from "services/api";

const Calendar = ({ close }) => {
  const dispatch = useDispatch();
  const [hiddenDays, setDays] = useState([]);
  const [state, setState] = useState({
    inMoment: false,
    treeDays: false,
    oneDay: false,
    inDay: false,
    accept: true,
  });
  const [init, setInit] = useState("06:00");
  const [end, setEnd] = useState("20:00");
  const [defaultModel, setDefaultModel] = useState("timeGridWeek");
  const [interval, setIntervalF] = useState("00:30:00");
  const Auth = useSelector((state) => state.auth.authUser);
  const config = useSelector((state) => state.calendar.config);

  useEffect(() => {
    if (!config.loaded) {
      dispatch(getConfigCalendar(Auth));
    }
  }, []);

  useEffect(() => {
    const {
      in_moment,
      in_day,
      tree_days,
      one_day,
      mode_default,
      slot_interval,
      min_time,
      max_time,
      accept_notifications,
      hidden_days,
    } = config;
    setDefaultModel(mode_default);
    setIntervalF(slot_interval);
    setInit(min_time);
    setEnd(max_time);
    setDays(hidden_days.map((val) => val.toString()));
    setState({
      ...state,
      accept: accept_notifications === "1",
      inMoment: in_moment === "1",
      treeDays: tree_days === "1",
      inDay: in_day === "1",
      oneDay: one_day === "1",
    });
  }, [config]);

  const handleChangeCheck = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const save = async () => {
    const obj = {
      ...state,
      defaultModel,
      hidden_days: hiddenDays.join(","),
      interval,
      init,
      end,
      Auth,
    };
    try {
      const response = await Api.post(
        `/ms_system/calendar/configurationCalendar/`,
        obj
      );
      const data = await response.data;
      close();
      if (data.success) {
        dispatch({
          type: "CHANGE_CONFIG_CALENDAR",
          config: {
            id: data.idConfig,
            loaded: true,
            min_time: init,
            max_time: end,
            slot_interval: interval,
            mode_default: defaultModel,
            hidden_days: hiddenDays.map((val) => parseInt(val)),
            inMoment: state.inMoment ? "1" : "0",
            treeDays: state.treeDays ? "1" : "0",
            oneDay: state.oneDay ? "1" : "0",
            inDay: state.inDay ? "1" : "0",
            accept: state.accept ? "1" : "0",
          },
        });
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      } else {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao Salvar!"));
        setTimeout(() => dispatch(closeAlertMessage(), 5000));
      }
    } catch (error) {
      dispatch(viewAlertMessage("error", "Ocorreu um erro ao Salvar!"));
      setTimeout(() => dispatch(closeAlertMessage(), 5000));
    }
  };

  const handleChangeHidden = (e) => {
    if (e.length < 7) {
      setDays(e);
    } else {
      alert("Não é possível ocultar todos os dias.");
    }
  };

  return (
    <div className="row">
      <h2 className="text-primary font-weight-semibold w-100">
        Customização Agenda
        <Tooltip title="Fechar" placement="top">
          <IconButton
            aria-label="Fechar"
            onClick={() => close()}
            style={{ float: "right", marginTop: "-10px" }}
          >
            <i className="zmdi zmdi-close" />
          </IconButton>
        </Tooltip>
      </h2>

      <div className="col-6 mt-3">
        <TextField
          fullWidth
          select
          label="Visualização de Modo Padrão"
          value={defaultModel}
          onChange={(e) => setDefaultModel(e.target.value)}
        >
          <MenuItem value="timeGridDay">Dia</MenuItem>
          <MenuItem value="timeGridWeek">Semana</MenuItem>
          <MenuItem value="dayGridMonth">Mês</MenuItem>
          <MenuItem value="listWeek">Compromissos</MenuItem>
        </TextField>
      </div>

      <div className="col-6 mt-3">
        <TextField
          fullWidth
          select
          label="Intervalo dos campos"
          value={interval}
          onChange={(e) => setIntervalF(e.target.value)}
        >
          <MenuItem value="00:10:00">10 Minutos</MenuItem>
          <MenuItem value="00:15:00">15 Minutos</MenuItem>
          <MenuItem value="00:20:00">20 Minutos</MenuItem>
          <MenuItem value="00:30:00">30 Minutos</MenuItem>
          <MenuItem value="01:00:00">1 Hora</MenuItem>
          <MenuItem value="02:00:00">2 Horas</MenuItem>
          <MenuItem value="04:00:00">4 Horas</MenuItem>
          <MenuItem value="12:00:00">12 Horas</MenuItem>
        </TextField>
      </div>

      <div className="col-6 mt-3">
        <TextField
          fullWidth
          type="time"
          label="Inicio:"
          value={init}
          onChange={(e) => setInit(e.target.value)}
        />
      </div>

      <div className="col-6 mt-3">
        <TextField
          fullWidth
          type="time"
          label="Fim:"
          value={end}
          onChange={(e) => setEnd(e.target.value)}
        />
      </div>

      <div className="col-12 mt-3">
        <MultipleSelect
          label="Não exibir:"
          values={hiddenDays}
          options={[
            { value: "0", label: "Domingo" },
            { value: "1", label: "Segunda-feira" },
            { value: "2", label: "Terça-feira" },
            { value: "3", label: "Quarta-feira" },
            { value: "4", label: "Quinta-feira" },
            { value: "5", label: "Sexta-feira" },
            { value: "6", label: "Sábado" },
          ]}
          onChange={(e) => {
            handleChangeHidden(e);
          }}
          SelectProps={{
            isCreatable: false,
            msgNoOptionsAvailable: "Todos os dias foram selecionados",
            msgNoOptionsMatchFilter: "Nenhum dia para este filtro",
          }}
        />
      </div>

      {/* <div className="col-6 mt-3">
        <FormControlLabel
          control={
            <Switch
              checked={state.accept}
              onChange={e => setState({ ...state, accept: e.target.checked })}
              value="AcceptNotification"
              color="primary"
            />
          }
          labelPlacement="start"
          label="Notificar Pacientes via WhatsApp"
          className="ml-1"
        />
      </div> */}

      <div className="col-12 mt-3">
        <Typography variant="body1" gutterBottom>
          Disparar:
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.inMoment}
                onChange={handleChangeCheck("inMoment")}
                value="inMoment"
              />
            }
            label="No momento da marcação"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.treeDays}
                onChange={handleChangeCheck("treeDays")}
                value="treeDays"
              />
            }
            label="3 Dias pré consulta"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.oneDay}
                onChange={handleChangeCheck("oneDay")}
                value="oneDay"
              />
            }
            label="Um dia pré consulta"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.inDay}
                onChange={handleChangeCheck("inDay")}
                value="inDay"
              />
            }
            label="No dia da consulta"
          />
        </FormGroup>
      </div>

      <div className="col-12 mt-3">
        <BtnCan title="Fechar" onClick={close} float="left" />
        <BtnSave title="Salvar" onClick={save} float="right" />
      </div>
    </div>
  );
};

export default Calendar;
