const INITIAL_STATE = {
  view: false,
  meals: [],
  idFoodPlan: "",
  nameFoodPlan: "",
  edit: false,
  foodPlanHistory: [],
  selectReminder: []
};

export default function foodPlan(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_MEAL":
      return {
        ...state
      };
    case "SELECT_REMINDERS":
      return {
        ...state,
        selectReminder: action.payload.reminders
      };
    case "SET_MEALS_PLAN":
      return {
        ...state,
        meals: action.payload
      };
    case "REMOVE_FOOD_PLAN":
      const listFinal = state.foodPlanHistory.filter(
        el => el.idFoodPlan !== action.payload
      );
      return {
        ...state,
        foodPlanHistory: listFinal,
        edit: false
      };

    case "EDIT_FOOD_PLAN":
      return {
        ...state,
        idFoodPlan: action.payload.idFoodPlan,
        nameFoodPlan: action.payload.nameFoodPlan,
        edit: true,
        view: false
      };
    case "SET_LIST_PLANS":
      const list = [];
      action.payload.map(el => {
        list.push({ idFoodPlan: el.id, nameFoodPlan: el.name });
      });
      return {
        ...state,
        foodPlanHistory: list
      };
    case "CHANGE_VIEW_PLAN":
      return {
        ...state,
        view: !state.view
      };
    case "SAVE_FOOD_PLAN":
      return {
        ...state,
        idFoodPlan: action.payload.idFoodPlan,
        nameFoodPlan: action.payload.nameFoodPlan,
        meals: action.payload.meals,
        foodPlanHistory: [
          ...state.foodPlanHistory,
          {
            nameFoodPlan: action.payload.nameFoodPlan,
            idFoodPlan: action.payload.idFoodPlan
          }
        ],
        view: true,
        edit: false
      };
    case "REMOVE_MEAL_PLAN":
      const newMeals = state.meals.filter(row => row.idMeal !== action.payload);
      return {
        ...state,
        meals: newMeals
      };
    case "ADD_MEAL_PLAN":
      const meal = [
        {
          idMeal: action.payload.idMeal,
          name: action.payload.meal,
          hour: action.payload.time,
          local: action.payload.local,
          kcal: action.payload.kcal,
          foods: action.payload.listItems
        }
      ];
      return {
        ...state,
        meals: [...state.meals, ...meal]
      };
    case "EDIT_MEAL_PLAN":
      const mealEdit = state.meals.map((row, index) => {
        return row.idMeal === action.payload.idMeal
          ? (state.meals[index] = {
              idMeal: action.payload.idMeal,
              name: action.payload.meal,
              hour: action.payload.time,
              local: action.payload.local,
              kcal: action.payload.kcal,
              foods: action.payload.listItems
            })
          : row;
      });
      return {
        ...state,
        meals: mealEdit
      };
    case "RESET_FOOD_PLAN":
      return INITIAL_STATE;
    default:
      return state;
  }
}
