import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

class Confirm extends Component {
  render() {
    const { match } = this.props;
    return (
      <div
        className="app-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Switch>
          <Route
            path={`${match.url}/calendar`}
            component={asyncComponent(() => import("./calendar"))}
            // render={_props => <PersonalData match={match} />}
          />
        </Switch>
      </div>
    );
  }
}

export default Confirm;
