import { GET_RECORDS_TO_DASH } from "constants/ActionTypes";
import moment from "moment";

const INIT_STATE = {
  data: [],
  data_dash: {
    start: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RECORDS_TO_DASH: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case "SET_DATA_DASH": {
      return {
        ...state,
        data_dash: {
          ...state.data_dash,
          [action.payload.title]: action.payload.value,
        },
      };
    }

    default:
      return state;
  }
};
