import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import FormBasicData from "./FormBasicData";
// import * as MedicinesGroupsActions from "actions/configuration/MedicinesGroups";
import * as FormGroupActions from "actions/configuration/ProcedureGroups";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TableExams from "./TableExams";
import AutocompleteProcedures from "./SearchProcedures";
class FormProcedureGroups extends Component {
  componentWillMount() {
    if (this.props.ProcedureGroups.idEdit) {
      this.props.getDataGroupForEdit(this.props.ProcedureGroups.idEdit);
    }
  }

  handleChangeNameGroup = (event) => {
    this.props.changeNameGroup(event.target.value);
  };

  handleChangeDescGroup = (event) => {
    this.props.changeDescGroup(event.target.value);
  };

  saveGroupProcedures = () => {
    this.props.saveProceduresGroups(
      this.props.doctor,
      this.props.ProcedureGroups.nameGroup,
      this.props.ProcedureGroups.descGroup,
      this.props.ProcedureGroups.listProceduresSelected,
      this.props.ProcedureGroups.idEdit
    );
  };

  render() {
    const tableExams = this.props.ProcedureGroups.listProceduresSelected;
    return (
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-primary font-weight-semibold">
            Adicionar um grupo de procedimentos
          </h2>
        </div>

        <div className="col-md-6">
          <TextField
            id="name"
            label="Nome do Grupo"
            autoFocus={true}
            value={this.props.ProcedureGroups.nameGroup}
            onChange={this.handleChangeNameGroup}
            fullWidth
          />
        </div>
        <div className="col-md-6">
          <TextField
            id="Descricao"
            label="Descrição"
            value={this.props.ProcedureGroups.descGroup}
            onChange={this.handleChangeDescGroup}
            fullWidth
          />
        </div>
        <div className="col-md-12">
          <AutocompleteProcedures />
        </div>

        <div className="col-md-12">
          {typeof tableExams !== "undefined" ? <TableExams /> : ""}
        </div>
        <div className="col-md-12 mt-2 justify-content-end">
          <div style={{ float: "left" }}>
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              onClick={() =>
                this.props.handleAddFunction(
                  "view",
                  "primary",
                  "Adicionar",
                  "hidden",
                  "plus"
                )
              }
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
          </div>
          <div style={{ float: "right" }}>
            <Button
              color="primary"
              className="jr-btn jr-btn-sm bg-success"
              onClick={this.saveGroupProcedures}
              variant="contained"
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Salvar grupo</span>
            </Button>
          </div>
        </div>
        {/* <div class="col-md-6">
          {typeof lExams !== "undefined" ? <ListExams /> : ""}
        </div>
        <div class="col-md-6">
          {typeof tableExams !== "undefined" ? <TableExams /> : ""}
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doctor: state.auth.authUser,
    ProcedureGroups: state.ProcedureGroups,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(FormGroupActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormProcedureGroups);
