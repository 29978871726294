import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import AppLocale from "../lngProvider";

import ForwardForm from "./ForwardForm";
import MainApp from "app/index";
import SignIn from "./SignIn";
import Login from "./Login/index";
import LoginFamilyAccompaniment from "./Login/FamilyAccompaniment";
import SignUp from "./SignUp";
import RegisterDoctor from "./Register/RegisterDoctor";
import RegisterPatient from "./Register/RegisterPatient";
import RegisterProvider from "./Register/RegisterProvider";
import RegisterDrugStore from "./Register/RegisterDrugStore";
import RegisterIlpi from "./RegisterIlpi";
import CompleteRegister from "./Register/CompleteRegister";
import passwordRecover from "./passwordRecover";
import NotificationCovid from "../app/routes/notificationCovid/routes/index";

import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";

/* const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
); */

class App extends Component {
  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  render() {
    const { match, location, locale, initURL } = this.props;
    let applyTheme = createMuiTheme(indigoTheme);

    if (location.pathname === "/") {
      if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/app/dashboard"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                  {/* <RestrictedRoute
                    path={`${match.url}app`}
                    authUser={authUser}
                    component={MainApp}
                  /> */}
                  <Route path={`${match.url}app`} component={MainApp} />
                  <Route path="/signin" component={SignIn} />
                  <Route path="/signup" component={SignUp} />
                  <Route path="/password-recover" component={passwordRecover} />
                  <Route path="/login" component={Login} />
                  <Route
                    path="/family-accompaniment"
                    component={LoginFamilyAccompaniment}
                  />
                  <Route path="/register/doctor" component={RegisterDoctor} />
                  <Route path="/register/patient" component={RegisterPatient} />
                  <Route
                    path="/register/complete"
                    component={CompleteRegister}
                  />
                  <Route
                    path="/register/provider"
                    component={RegisterProvider}
                  />
                  <Route
                    path="/register/drugstore"
                    component={RegisterDrugStore}
                  />
                  <Route path="/register/ilpi" component={RegisterIlpi} />
                  <Route path="/forward-form" component={ForwardForm} />
                  <Route exact path="/caboverde">
                    <Redirect to="/notification-covid" />
                  </Route>
                  <Route
                    path="/notification-covid"
                    component={(props) => (
                      <NotificationCovid {...props} type="notification" />
                    )}
                  />
                  <Route
                    path="/vacinacao-infantil"
                    component={(props) => (
                      <NotificationCovid {...props} type="vaccine" />
                    )}
                  />
                  <Route
                    component={asyncComponent(() =>
                      import("app/routes/extraPages/routes/404")
                    )}
                  />
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, isDirectionRTL } = settings;
  const { initURL } = auth;
  return { locale, isDirectionRTL, initURL };
};

export default connect(mapStateToProps, { setInitUrl })(App);
