import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";
import { TT_NAMES } from "constants/TreatmentTypes";

import { IconButton } from "@material-ui/core";
import Table from "components/Tables/DataTable";
import ViewIcon from "@material-ui/icons/Visibility";
import ViewTreatment from "components/Forms/Consult/Treatment/ViewTreatment";

export default function Treatment({ patient }) {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState("");
  const [record, setRecord] = useState([]);

  useEffect(() => {
    patient && getTreatments();
  }, [patient]);

  function getTreatments() {
    Api.post("microservice/treatment/getAllTreatment", { patient })
      .then(({ data }) => {
        setRecord(data);
      })
      .catch(() => {
        setRecord([]);
        showMsg(
          "error",
          "Não foi possível buscar os tratamentos deste paciente",
          dispatch
        );
      });
  }

  return (
    <div className="container-person border">
      <h3
        className="title-container d-flex justify-content-center"
        style={{
          fontSize: "26px",
          fontWeight: "400",
        }}
      >
        Tratamentos
        {edit && (
          <IconButton
            onClick={() => setEdit("")}
            color="primary"
            aria-label="Visualizar"
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-undo" />
          </IconButton>
        )}
      </h3>
      {!edit && (
        <Table
          columns={["Nome", "Tipo", "Iniciado", "Finalizado", "Visualizar"]}
          rows={record.map((row) => [
            row.name,
            TT_NAMES[row.type],
            row.date_init,
            row.date_finish,
            <IconButton
              onClick={() => {
                setEdit(row.id);
              }}
              color="primary"
            >
              <ViewIcon color="primary" />
            </IconButton>,
          ])}
        />
      )}
      {edit && <ViewTreatment id={edit} />}
    </div>
  );
}
