import React, { useEffect, useState } from "react";
import api from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { setDataAppointment } from "actions/consult/Appointment";
import { viewHistory } from "actions/consult/Appointment";
import { changeAddress } from "actions/Auth";
import { Typography, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HistoryIcon from "@material-ui/icons/History";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import "./styles.css";
import moment from "moment";
import NoRecord from "components/Alert/NoRecord";
import { withRouter } from "react-router-dom";
// import History from "components/History";
import HistoryPatient from "components/HistoryPatient";

const useStyles = makeStyles({
  text: {
    color: "#868686",
    width: "100%",
    fontSize: "16px",
    fontVariant: "petite-caps",
    textAlign: "center",
    marginTop: "40px",
  },
  textTble: {
    fontSize: "14px",
    fontWeight: "500",
  },
});

function TableCalendar({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.authUser);
  const user_manage = useSelector((state) => state.auth.user_manage);
  const secretary = useSelector((state) => state.auth.secretary);
  const address = useSelector((state) => state.auth.address);
  const [calendars, setCalendars] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [patient, setPatient] = useState("");

  useEffect(() => {
    if (secretary) {
      if (user_manage.id) {
        api
          .post(`/ms_system/calendar/getCalendars/`, {
            user: user_manage.id,
            type: "0",
          })
          .then(({ data }) => {
            if (data.record !== null || data.record !== undefined) {
              const ids = [];
              data.record.forEach((row) => {
                if (
                  (row.address_id === address || !address) &&
                  row.address_id !== "0"
                ) {
                  ids.push(row.id);
                }
              });
              let date = {
                start: moment().format("YYYY-MM-DD") + " 00:00:00",
                end: moment().format("YYYY-MM-DD") + " 23:59:59",
              };
              api
                .post(`/ms_system/calendar/getEvents/`, {
                  id: ids.join(","),
                  start: date.start,
                  end: date.end,
                })
                .then(({ data }) => {
                  setEvents(
                    data.record.filter(
                      (row) => !row.fk_ls_rehabilitation_schedule
                    )
                  );
                });
            }
            setCalendars(data.record);
          });
      }
    } else {
      api
        .post(`/ms_system/calendar/getCalendars/`, {
          user,
          type: "0",
        })
        .then(({ data }) => {
          if (data.record !== null || data.record !== undefined) {
            const ids = [];
            data.record.forEach((row) => {
              if (
                (row.address_id === address || !address) &&
                row.address_id !== "0"
              ) {
                ids.push(row.id);
              }
            });
            let date = {
              start: moment().format("YYYY-MM-DD") + " 00:00:00",
              end: moment().format("YYYY-MM-DD") + " 23:59:59",
            };
            api
              .post(`/ms_system/calendar/getEvents/`, {
                id: ids.join(","),
                start: date.start,
                end: date.end,
              })
              .then(({ data }) => {
                setEvents(
                  data.record.filter(
                    (row) => !row.fk_ls_rehabilitation_schedule
                  )
                );
              });
          }
          setCalendars(data.record);
        });
    }
  }, [user, user_manage]);

  useEffect(() => {
    let aux = events.map((row) => {
      let infoCalendar = calendars.find((cal) => row.resourceId === cal.id);
      return {
        ...row,
        calendar: infoCalendar.name ? infoCalendar.name : "",
        address: infoCalendar.address_id ? infoCalendar.address_id : "",
      };
    });
    setEventsData(aux);
  }, [events]);

  const playConsult = (patient, address) => {
    dispatch(changeAddress(address));
    dispatch(setDataAppointment(patient, ""));
    history.push("/app/consult/attendance-record");
  };

  return (
    <>
      <div className="col-12 m-0 p-0 mt-1">
        <Typography className={classes.text}>
          {moment().format("DD/MM/YYYY")}
        </Typography>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Horario</th>
              <th scope="col"></th>
              <th scope="col">Nome</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {eventsData
              .filter((row) => row.address_id === address || !address)
              .map((e, index) => (
                <tr key={index} className={classes.textTble}>
                  <th scope="row">{index + 1}</th>
                  <td
                    style={{
                      borderLeft: "1px solid red",
                    }}
                  >
                    {moment(e.start).format("HH:mm")} -{" "}
                    {moment(e.end).format("HH:mm")}
                  </td>
                  <td>
                    <Tooltip title={e.calendar} placement="top">
                      <span
                        style={{ backgroundColor: e.color, padding: "0.3rem" }}
                        className="size-10 lighten-1 rounded-circle d-inline-block mr-2"
                      />
                    </Tooltip>
                  </td>
                  <td>{e.title}</td>
                  <td>
                    {e.pacient_id && (
                      <div className="d-flex">
                        <Tooltip title="Histórico do paciente" placement="top">
                          <IconButton
                            style={{ padding: "6px" }}
                            color="primary"
                            onClick={() => {
                              setPatient(e.pacient_id);
                              dispatch(viewHistory("personalData"));
                            }}
                          >
                            <HistoryIcon
                              style={{
                                fontSize: "19px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        {!secretary && e.status !== "5" && (
                          <Tooltip title="Iniciar atendimento" placement="top">
                            <IconButton
                              style={{ padding: "6px" }}
                              color="primary"
                              onClick={() =>
                                playConsult(e.pacient_id, e.address_id)
                              }
                            >
                              <PlayArrowIcon
                                style={{
                                  fontSize: "19px",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              />{" "}
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            {eventsData.length === 0 && (
              <tr>
                <td colspan="4">
                  <NoRecord />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <HistoryPatient id_patient={patient} />
      </div>
    </>
  );
}

export default withRouter(TableCalendar);
