import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import axios from "axios";
import Api from "services/api";
import MaskCep from "components/Masks/MaskCep";
import MaskCellphone from "components/Masks/MaskCellphone";
import MaskPhone from "components/Masks/MaskPhone";
import {
  Button,
  Grid,
  Switch,
  TextField,
  FormControl,
  FormHelperText,
  IconButton,
  Tooltip,
  Input,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";

function FormWorkplace({
  type,
  user,
  close,
  userAuth,
  addressId,
  provider_type,
}) {
  const [state, setState] = useState({
    address_type:
      provider_type === "5" ? "4" : provider_type === "55" ? "5" : "",
    name: "",
    time_alert: "",
    home_visit: false,
    website: "",
    postalcode: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    country: "",
    cellphone: "",
    phone: "",
    email: "",
    error: false,
  });
  const dispatch = useDispatch();
  const providerType = useSelector(({ auth }) => auth.provider_type === "56");

  useEffect(() => {
    if (addressId) {
      Api.post(`/ms_system/workplace/getInfoWorkplace/`, { addressId }).then(
        (res) => {
          const { address, hours } = res.data;
          let obj = {
            ...state,
            ...address[0],
            hours,
          };

          setState(obj);
        }
      );
    }
  }, []);

  function handleChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  function searchCep(value) {
    let cep = value.replace(/_/g, "");
    if (cep.length === 9) {
      cep = cep.replace(",", "");
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => {
          setState({
            ...state,
            postalcode: res.data.cep,
            address: res.data.logradouro,
            neighborhood: res.data.bairro,
            city: res.data.localidade,
            state: res.data.uf,
            country: "Brasil",
          });
        })
        .catch(() => {
          console.log("Ocorreu um erro ao recuperar as informações do cep.");
        });
    }
  }

  function save() {
    if (validate()) {
      let formData = {
        user,
        userAuth,
        addressId,
        provider_type,
        data: {
          ...state,
        },
      };
      Api.post(`/ms_system/workplace/saveWorkplace/`, formData)
        .then((res) => {
          const payload = {
            ...res.data,
            id: res.data.address,
            maps: res.data.url,
            name: state.name,
            address: state.address,
            number: state.number,
            complement: state.complement,
            neighborhood: state.neighborhood,
            postalcode: state.postalcode,
            phone: state.phone,
            email: state.email,
            cellphone: state.cellphone,
            city: state.city,
            state: state.state,
            country: state.country,
            address_type: state.address_type,
            active: "1",
            agreements: [],
            contacts: [],
            plans: [],
            rooms: [],
            professionals: [],
            specialty: [],
            office_hour: [],
            typesEvent: [],
            formPrint: [],
            calendar: [],
          };

          if (addressId) {
            dispatch({
              type: "EDIT_WORKPLACE",
              payload,
            });
          } else {
            dispatch({
              type: "NEW_WORKPLACE",
              payload,
            });
            dispatch({
              type: "NEW_ADDRESS",
              address: {
                id: res.data.address,
                name: state.name,
                user_id: user,
                full_address: `${state.name}, ${state.number} ${state.complement}, ${state.neighborhood} - ${state.postalcode}, ${state.city} / ${state.state} - ${state.country}`,
                type,
                provider_type,
              },
            });
          }
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
          close();
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage(), 5000));
        });
    }
  }

  const validate = () => {
    if (
      state.name &&
      state.cellphone &&
      state.phone &&
      state.address &&
      state.number &&
      state.neighborhood &&
      state.city &&
      state.state
    ) {
      return true;
    } else {
      setState({ ...state, error: true });
      return false;
    }
  };

  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <h2 className="text-primary font-weight-semibold">
          Adicionar Local de Atendimento
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
      </div>
      {provider_type !== "5" && provider_type !== "55" && (
        <div className="col-md-4 col-12">
          <TextField
            id="type"
            select
            required
            label={providerType ? "Tipo de Atendimento" : "Tipo de consulta:"}
            value={state.address_type}
            onChange={(e) =>
              setState({
                ...state,
                name:
                  e.target.value === "0"
                    ? "Teleconsulta"
                    : e.target.value === "2"
                    ? "Atendimento Domiciliar"
                    : state.address_type === ""
                    ? state.name
                    : "",
                address_type: e.target.value,
              })
            }
            fullWidth
            error={state.error && !state.address_type}
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem key="0" value="0">
              Virtual
            </MenuItem>
            <MenuItem key="1" value="1">
              Presencial Local
            </MenuItem>
            <MenuItem key="2" value="2">
              Presencial Externo
            </MenuItem>
            <MenuItem key="3" value="3">
              Farmácia
            </MenuItem>
          </TextField>
        </div>
      )}
      <div
        className={
          provider_type !== "5" && provider_type !== "55"
            ? "col-md-8 col-12"
            : "col-12"
        }
      >
        <TextField
          id="name_workplace"
          required
          label="Nome do Local de atendimento:"
          value={state.name}
          onChange={(e) => handleChange("name", e.target.value)}
          fullWidth
          error={state.error && !state.name ? true : false}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-md-4 col-12">
        <FormControl className="my-3" fullWidth>
          <TextField
            label="E-mail"
            id="email"
            type="email"
            value={state.email}
            onChange={(e) => handleChange("email", e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </div>
      <div className="col-md-4 col-12">
        <FormControl className="my-3" fullWidth>
          <TextField
            required
            id="phone"
            label="Telefone:"
            // margin="normal"
            fullWidth
            error={state.error && !state.phone ? true : false}
            InputProps={{
              inputComponent: MaskPhone,
              value: state.phone,
              onChange: (e) => handleChange("phone", e.target.value),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </div>
      <div className="col-md-4 col-12">
        <FormControl className="my-3" fullWidth>
          <TextField
            required
            id="cellphone"
            label="Celular:"
            fullWidth
            error={state.error && !state.cellphone ? true : false}
            InputProps={{
              inputComponent: MaskCellphone,
              value: state.cellphone,
              onChange: (e) => handleChange("cellphone", e.target.value),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </div>

      {provider_type !== "5" && (
        <div className="col-md-4 col-12">
          <FormControl className="my-3" fullWidth>
            <FormHelperText>Tempo alerta paciente esperando:</FormHelperText>
            <Input
              id="time_alert"
              type="number"
              value={state.time_alert}
              onChange={(e) => handleChange("time_alert", e.target.value)}
              endAdornment={<InputAdornment position="end">Min</InputAdornment>}
            />
          </FormControl>
        </div>
      )}
      <div className={provider_type === "5" ? "col-12" : "col-md-8 col-12"}>
        <FormControl className="my-3" fullWidth>
          <FormHelperText>Website:</FormHelperText>
          <Input
            id="website"
            value={state.website}
            onChange={(e) => handleChange("website", e.target.value)}
            startAdornment={
              <InputAdornment position="start">https://</InputAdornment>
            }
          />
        </FormControl>
      </div>
      {state.address_type && (
        <>
          <div className="col-12">
            <h3 className="font-weight-semibold mt-3">
              {state.address_type === "0" || state.address_type === "2"
                ? "Endereço base de referência"
                : "Endereço"}
            </h3>
            <hr />
          </div>

          <div className="col-md-4 col-12">
            <TextField
              id="postalcode"
              label="CEP"
              margin="normal"
              className="mt-0"
              fullWidth
              InputProps={{
                inputComponent: MaskCep,
                value: state.postalcode,
                onChange: (e) => searchCep(e.target.value),
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="col-md-8 col-12 ">
            <TextField
              required
              id="address"
              label="Logradouro"
              value={state.address}
              margin="normal"
              className="mt-0"
              fullWidth
              onChange={(e) => handleChange("address", e.target.value)}
              error={state.error && !state.address ? true : false}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="col-md-4 col-12">
            <TextField
              required
              id="number"
              label="Número"
              value={state.number}
              margin="normal"
              fullWidth
              onChange={(e) => handleChange("number", e.target.value)}
              error={state.error && !state.number ? true : false}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="col-md-4 col-12">
            <TextField
              id="complement"
              label="Complemento"
              value={state.complement}
              margin="normal"
              fullWidth
              onChange={(e) => handleChange("complement", e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="col-md-4 col-12">
            <TextField
              id="neighborhood"
              label="Bairro"
              value={state.neighborhood}
              margin="normal"
              fullWidth
              onChange={(e) => handleChange("neighborhood", e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="col-md-4 col-12">
            <TextField
              required
              id="city"
              label="Cidade"
              value={state.city}
              margin="normal"
              fullWidth
              InputProps={{ readOnly: state.postalcode ? true : false }}
              onChange={(e) => handleChange("city", e.target.value)}
              error={state.error && !state.city ? true : false}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="col-md-4 col-12">
            <TextField
              required
              id="state"
              label="Estado"
              value={state.state}
              margin="normal"
              fullWidth
              InputProps={{ readOnly: state.postalcode ? true : false }}
              onChange={(e) => handleChange("state", e.target.value)}
              error={state.error && !state.state ? true : false}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="col-md-4 col-12">
            <TextField
              id="country"
              label="País"
              value={state.country}
              margin="normal"
              fullWidth
              InputProps={{ readOnly: state.postalcode ? true : false }}
              onChange={(e) => handleChange("country", e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </>
      )}
      <div className="col-md-12 mt-3">
        <Button
          color="secondary"
          variant="contained"
          className="jr-btn jr-btn-sm"
          onClick={() => close()}
          style={{ float: "left" }}
        >
          <i className="zmdi zmdi-close zmdi-hc-lg" />
          <span> Cancelar </span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          className="jr-btn jr-btn-sm bg-success"
          onClick={() => save()}
          style={{ float: "right" }}
        >
          <i className="zmdi zmdi-check zmdi-hc-lg" />
          <span> Salvar </span>
        </Button>
      </div>
    </div>
  );
}

export default FormWorkplace;
