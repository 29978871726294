import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  MenuItem,
} from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import { getEventLog } from "actions/Calendar";
import NoRecord from "components/Alert/NoRecord";

export default function LogCalendar({ calendar, closeMenu, width }) {
  const [modalForm, setModalForm] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const dispatch = useDispatch();
  const list = useSelector((state) => state.calendar.logs);
  useEffect(() => {
    if (modalForm) {
      dispatch(getEventLog(calendar));
    }
  }, [modalForm]);

  return (
    <>
      {width > 500 ? (
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 15, marginLeft: 10 }}
          onClick={() => setModalForm(true)}
        >
          <i className="zmdi zmdi-collection-text mr-3" /> Logs
        </Button>
      ) : (
        <MenuItem onClick={() => setModalForm(true)}>
          <i className="zmdi zmdi-collection-text mr-3" /> Logs
        </MenuItem>
      )}

      <ModalResponsive
        title="Logs"
        open={modalForm}
        close={() => {
          setModalForm(false);
          closeMenu();
        }}
        maxWidth="lg"
        buttons={
          <div className="col-md-12 mt-3">
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              onClick={() => {
                setModalForm(false);
                closeMenu();
              }}
              style={{ margin: 10 }}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Fechar </span>
            </Button>
          </div>
        }
      >
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Agenda </TableCell>
                <TableCell>Evento </TableCell>
                <TableCell>Tipo </TableCell>
                <TableCell>Descrição </TableCell>
              </TableRow>
            </TableHead>
            {list.length > 0 ? (
              <TableBody>
                {list
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((el) => (
                    <TableRow>
                      <TableCell>{el.name} </TableCell>
                      <TableCell>{el.title} </TableCell>
                      <TableCell>{el.typeText} </TableCell>
                      <TableCell>{el.description} </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colspan={3}>
                    <NoRecord />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={list.length}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage="Registros por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `Mostrando ${from} - ${to} de ${count}`
                  }
                  page={page}
                  onChangePage={(e, page) => setPage(page)}
                  onChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </ModalResponsive>
    </>
  );
}
