import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

const CircularProgress = ({ className }) => (
  <>
    <div
      className="col-12 d-flex justify-content-center"
      style={{ alignItems: "center" }}
    >
      <div>
        <img
          style={{
            alignItems: "center",
            height: "76px",
            width: "184px",
            marginLeft: "14px",
          }}
          src={
            window.location.hostname === "senior.salutemplus.com"
              ? require("assets/images/Logo_Salutem_Senior-01.svg")
              : require("assets/images/Logo_Salutem_Plus-01.svg")
          }
          alt="Preload salutem+"
          title="Preload salutem+"
        />
        <LinearProgress style={{ alignItems: "center", width: "207px" }} />
      </div>
    </div>
  </>
);
export default CircularProgress;
