import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import api from "services/api";
import moment from "moment";

/* REQUISIÇÕES DOS EVENTOS */

const requestGetEvents = async params => {
  const { data } = await api.post(
    `/ms_system/onlinescheduler/getEvents`,
    params
  );
  return data;
};

/* EXECUTA AÇÕES NO SAGA */

function* executeGetEvents(action) {
  const { calendar_id, date } = action.payload;
  try {
    const res = yield call(requestGetEvents, { calendar_id, date });
    let events = yield getAllEvents(action, res);
    yield put({ type: "SET_EVENTS_ONLINE", payload: { events, date } });
  } catch (error) {
    console.log(" ** OnlineScheduler ** Erro executeGetEvents() ==>", error);
  }
}

/* ESCUTA ACTIONS */

function* listenGetEvents() {
  yield takeLatest("GET_EVENTS_ONLINE", executeGetEvents);
}

export default function* rootSaga() {
  yield all([fork(listenGetEvents)]);
}

/* get actions */

function getAllEvents(action, list) {
  const { hours, hidden_days } = action.payload;
  let start =
    moment(action.payload.date.start) < moment()
      ? roundMinutes(moment(moment().format("YYYY-MM-DD HH:mm")))
      : moment(action.payload.date.start);
  let end = moment(action.payload.date.end);
  let events = [];
  while (start < end) {
    if (hidden_days.indexOf(start.day()) === -1) {
      if (
        start >=
          moment(
            start.format("YYYY-MM-DD") +
              " " +
              hours.find(row => row.daysOfWeek[0] === start.day()).startTime
          ) &&
        start <
          moment(
            start.format("YYYY-MM-DD") +
              " " +
              hours.find(row => row.daysOfWeek[0] === start.day()).endTime
          ) &&
        start.format("YYYY-MM-DD HH:mm:ss") !==
          (list.find(
            row => row.start === start.format("YYYY-MM-DD HH:mm:ss")
          ) !== undefined
            ? list.find(
                row => row.start === start.format("YYYY-MM-DD HH:mm:ss")
              ).start
            : "")
      ) {
        events.push({
          title: "Horário disponível",
          start: start.format("YYYY-MM-DD HH:mm:ss"),
          end: start.add(30, "minutes").format("YYYY-MM-DD HH:mm:ss"),
          textColor: "#fff"
        });
      } else {
        start.add(30, "minutes");
      }
    } else {
      start.add(1, "days");
    }
  }
  return events;
}

function roundMinutes(start) {
  const remainder = 30 - (start.minute() % 30);

  const dateTime = moment(start).add(remainder, "minutes");
  return dateTime;
}
