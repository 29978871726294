import React, { Component } from "react";
import api from "services/api";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton
} from "@material-ui/core";
import NoRecord from "components/Alert/NoRecord";
import ViewPeriodotogram from "./ViewPeriodotogram";
import { Button } from "@material-ui/core";

// import { Container } from './styles';

export default class Periodotogram extends Component {
  state = {
    view: false,
    page: 0,
    rowsPerPage: 10,
    rows: [],
    selected: null
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  componentDidMount() {
    api
      .post(`/microservice/od_periodotogram/getHistoryPatient`, {
        params: this.props.patient
      })
      .then(res => {
        this.setState({ rows: res.data.list });
      });
  }
  toogleView = (selected = null) => {
    this.setState({ view: !this.state.view, selected });
  };
  render() {
    const { view, page, rows, rowsPerPage } = this.state;
    return (
      <div className="row m-2">
        <div className="col-12 container-person border">
          <h3
            className="title-container d-flex justify-content-center"
            style={{
              fontSize: "26px",
              fontWeight: "400",
              marginLeft: "14px"
            }}
          >
            Periodontograma
          </h3>
          {!view ? (
            rows.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Profissional</TableCell>
                    <TableCell>Diagnóstico</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow key={row.id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.diagnostic}</TableCell>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>
                          <IconButton
                            className="m-0 p-0"
                            onClick={() => this.toogleView(row.id)}
                            color="primary"
                          >
                            <i className="zmdi zmdi-eye zmdi-hc-fw" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Registros por página"
                      labelDisplayedRows={({ from, to, count }) =>
                        `Mostrando ${from} - ${to} de ${count}`
                      }
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            ) : (
              <NoRecord />
            )
          ) : (
            <ViewPeriodotogram selected={this.state.selected} />
          )}

          {view && (
            <div className="ml-2">
              <Button
                onClick={this.toogleView}
                color="primary"
                aria-label="Voltar"
              >
                <i className="zmdi zmdi-undo" /> Voltar
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
