import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import IcomAssignment from "@material-ui/icons/Assignment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RegisterAttendance from "app/routes/consult/routes/attendanceRecord/registerAttendance";
import Diagnostics from "components/Forms/Consult/diagnostics";
import Procedures from "app/routes/consult/routes/attendanceRecord//procedures";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import CloseIcon from "@material-ui/icons/Close";
import "./styles.css";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    paddingTop: "60px",
  },
  appBar: {
    position: "fixed",
  },
  title: {
    marginLeft: "20px",
    color: "white",
    flex: 1,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const width = useSelector(({ settings }) => settings.width);
  const [state, setState] = React.useState({
    top: false,
  });
  const dispatch = useDispatch();

  const closeModal = () => {
    setState({ top: false });
  };

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!open) {
      dispatch({
        type: "SAVE_MODAL_REG_ATT",
      });
    }
    setState({ ...state, [side]: open });
  };

  const DialogTitleModal = (side) => (
    <div className={`col-12 m-2 ${classes.fullList} `} role="presentation">
      <RegisterAttendance
        closeModalAttendance={closeModal}
        // buttonSave={false}
        closeDrawer={() => setState({ top: false })}
      />
      <Diagnostics type="consult" />
      <Procedures />
    </div>
  );

  const handleClose = () => {
    setState({ top: false });
  };

  return (
    <div className="mr-2 contHerder">
      <Tooltip title="Registro do Atendimento" aria-label="add">
        {width < 1100 ? (
          <IconButton
            style={{ fontSize: "1.4rem", padding: "1px", marginTop: "-2px" }}
            onClick={() => setState({ ...state, top: true })}
          >
            <IcomAssignment style={{ fontSize: "1.3rem" }} />
          </IconButton>
        ) : (
          <IconButton
            style={{ fontSize: "1.4rem", padding: "1px", marginTop: "-2px" }}
            onMouseMove={toggleDrawer("top", true)}
          >
            <IcomAssignment style={{ fontSize: "1.3rem" }} />
          </IconButton>
        )}
      </Tooltip>

      <Dialog
        fullScreen
        open={state.top}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Informações do atendimento
            </Typography>
          </Toolbar>
        </AppBar>
        <List>{DialogTitleModal("top")}</List>
      </Dialog>

      {/* <Drawer
        anchor="top"
        open={state.top}
        onClose={toggleDrawer("top", false)}
      >
        {DialogTitleModal("top")}
      </Drawer> */}
    </div>
  );
}
