import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import moment from "moment";
import ComponentProfile from "components/ComponentsProfile/ProfileNew";

export default function ModalFilter({ open, handleClose, handleFilter, data }) {
  const [state, setState] = useState({
    period: "",
    start: "",
    end: "",
  });

  useEffect(() => {
    if (data.arrival) {
      setState({
        period: "",
        start: moment(data.arrival).format("YYYY-MM-DDTHH:mm"),
        end: moment().format("YYYY-MM-DDTHH:mm"),
      });
    } else {
      setState({
        period: "",
        start: "",
        end: moment().format("YYYY-MM-DDTHH:mm"),
      });
    }
  }, [data]);

  function saveFilter() {
    handleFilter(state);
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="body"
    >
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-primary font-weight-semibold">
              Gerar declaração de comparecimento
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={() => handleClose()}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>
          <div className="col-12">
            <ComponentProfile
              profile={{
                id: data.fk_ls_user_patient,
                name: data.patient_name,
                photo: data.patient_photo,
                birthday: moment(data.birthday).format("DD/MM/YYYY"),
                cpf: data.cpf,
                mother_name: data.mother_name,
                cns: data.cns,
              }}
            />
          </div>

          <div className="col-12">
            <TextField
              label="Período:"
              select
              value={state.period}
              onChange={(e) => {
                setState({ ...state, period: e.target.value });
              }}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem key="0" value="0">
                Matutino
              </MenuItem>
              <MenuItem key="1" value="1">
                Vespertino
              </MenuItem>
              <MenuItem key="2" value="2">
                Noturno
              </MenuItem>
              <MenuItem key="3" value="3">
                Horário personalizado
              </MenuItem>
            </TextField>
          </div>

          {state.period === "3" && (
            <>
              <div className="col-md-6 mt-3">
                <TextField
                  type="datetime-local"
                  label="Inicio:"
                  value={state.start}
                  onChange={(e) => {
                    setState({ ...state, start: e.target.value });
                  }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="col-md-6 mt-3">
                <TextField
                  type="datetime-local"
                  label="Fim:"
                  value={state.end}
                  onChange={(e) => {
                    setState({ ...state, end: e.target.value });
                  }}
                  inputProps={{ max: moment().format("YYYY-MM-DD") }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </>
          )}

          <div className="col-md-12" style={{ marginTop: "30px" }}>
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => handleClose()}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right" }}
              onClick={() => {
                saveFilter();
                handleClose();
              }}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Gerar </span>
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
