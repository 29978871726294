export const showModalConfig = (view) => {
  return {
    type: "SHOW_MODAL_CONFIG",
    view,
  };
};

export const hideModalConfig = () => {
  return {
    type: "HIDE_MODAL_CONFIG",
  };
};

// Revisão dos sistemas
export const getRecordSr = (user) => {
  return {
    type: "GET_RECORD_SR",
    payload: {
      user,
    },
  };
};

export const changeConfigSr = (id, view) => {
  return {
    type: "CHANGE_CONFIG_SR",
    id,
    view,
  };
};

// Aga
export const getRecordAga = (user) => {
  return {
    type: "GET_RECORD_AGA",
    payload: {
      user,
    },
  };
};

export const changeConfigAga = (id, view) => {
  return {
    type: "CHANGE_CONFIG_AGA",
    id,
    view,
  };
};

// Sidenav Consult
export const getRecordSidenavConsult = (user) => {
  return {
    type: "GET_RECORD_SNC",
    payload: {
      user,
    },
  };
};

export const changeConfigSidenavConsult = (id, view) => {
  return {
    type: "CHANGE_CONFIG_SNC",
    id,
    view,
  };
};

// Vital Sings ( Registro inicial)
export const getRecordVitalSigns = (user) => {
  return {
    type: "GET_RECORD_VS",
    payload: {
      user,
    },
  };
};

export const changeConfigVitalSigns = (id, view) => {
  return {
    type: "CHANGE_CONFIG_VS",
    id,
    view,
  };
};

export const changeConfigAnamnesis = (id, view) => {
  return {
    type: "CHANGE_CONFIG_ANAMNESIS",
    id,
    view,
  };
};

export const changeConfigAdmission = (params) => {
  return {
    type: "CHANGE_CONFIG_ADMISSION",
    payload: {
      id: params.id,
      view: params.view,
      view_sub_group: params.view_sub_group,
    },
  };
};

export const setListAdmission = (list) => {
  return {
    type: "SET_LIST_ADMISSION",
    list,
  };
};

export const setCompomentAdmission = (params) => {
  return {
    type: "SET_COMPOMENT_ADMISSION",
    payload: {
      name: params.name,
      compoment: params.compoment,
      value: params.value,
    },
  };
};
