const INITIAL_STATE = {
  patients: []
};

export default function evolutionGroup(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_EVOLUTION_GROUP":
      return INITIAL_STATE;
    case "SET_PATIENTS":
      return {
        patients: action.payload.record
      };
    default:
      return state;
  }
}
