export default {
  palette: {
    primary: {
      light: "#177493",
      main: "#177493",
      dark: "#177493",
      contrastText: "#fff",
    },
    secondary: {
      light: "#bc0003",
      main: "#bc0003",
      dark: "#bc0003",
      contrastText: "#fff",
    },
  },
  status: {
    danger: "orange",
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: "capitalize",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#000000de",
        paddingLeft: "5px",
      },
    },
    MuiInputBase: {
      root: {
        color: "#1a1a1a",
        background: "#d3d3d37d",
        paddingLeft: "5px",
      },
    },
    MuiTableCell: {
      root: {
        paddingTop: "8px",
        paddingBottom: "8px",
        border: "3px solid #ffffff",
        background: "#f0f0f0",
        // borderBottom: "",
        // border: "1px solid rgb(137 137 137)",
      },
      head: {
        background: "#badeea",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#000000cc",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "30px",
      },
    },
    MuiSpeedDialAction: {
      staticTooltipLabel: {
        width: "max-content",
        display: "flex",
      },
    },
  },
};
