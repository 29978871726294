import React from "react";
import DataTable from "components/Tables/DataTable";
import { Tooltip, IconButton } from "@material-ui/core";
import ViewAppointment from "./ViewAppointment";
import ProfileNew from "components/ComponentsProfile/ProfileNew";

export default function AttendanceDoctor({ record }) {
  const [viewDataAppointment, setViewDataAppointment] = React.useState({
    open: false,
    appointment: {},
  });

  return (
    <>
      <DataTable
        name="Atendimentos"
        columns={["Data", "Paciente", "Local", "Ações"]}
        rows={record.map((row, index) => [
          row.date,

          <ProfileNew
            profile={{
              id: row.patient_id,
              name: row.name,
              photo: row.photo,
              birthday: row.birthday,
              mother_name: row.mother_name,
              cpf: row.cpf,
              cns: row.cns,
            }}
          />,
          row.address,
          [
            <div style={{ whiteSpace: "nowrap" }}>
              <Tooltip title="Visualizar Dados do Atendimento">
                <IconButton
                  style={{ padding: "6px" }}
                  color="primary"
                  onClick={() =>
                    setViewDataAppointment({
                      open: true,
                      appointment: {
                        id: row.id,
                        doctor: row.doctor,
                        patient: row.patient_id,
                        patient_name: row.name,
                        patient_birthday: row.birthday,
                        photo: row.photo,
                        mother_name: row.mother_name,
                        cns: row.cns,
                        cpf: row.cpf,
                      },
                    })
                  }
                >
                  <i className="zmdi zmdi-eye zmdi-fw" />
                </IconButton>
              </Tooltip>
            </div>,
          ],
        ])}
        hideSearch={true}
      />
      <ViewAppointment
        open={viewDataAppointment.open}
        appointment={viewDataAppointment.appointment}
        handleClose={() =>
          setViewDataAppointment({ open: false, appointment: {} })
        }
      />
    </>
  );
}
