import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { listStyleFormPrint } from "actions/Print";
import { connect } from "react-redux";
import _ from "lodash";
import { Card } from "reactstrap";
import { Responsive, WidthProvider } from "react-grid-layout";
import "./styles.css";
import { Tooltip } from "@material-ui/core";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

class ShowcaseLayout extends Component {
  constructor(props) {
    super(props);
    let widthColumn = this.props.print.css_column_left_width_px;
    let w2;
    if (widthColumn < 200) {
      w2 = 1;
    } else if (widthColumn > 200 && widthColumn < 300) {
      w2 = 2;
    } else if (widthColumn > 300) {
      w2 = 3;
    }
    this.state = {
      printForm: null,
      currentBreakpoint: "lg",
      compactType: "vertical",
      mounted: false,
      height: "auto",
      layouts: {
        lg: [
          {
            x: 0,
            y: 0,
            w: "7",
            h: 4,
            i: "0"
          }
        ]
      },
      layouts2: {
        lg: [{ x: 0, y: 0, w: w2, h: "15", i: "0" }]
      },
      layouts3: {
        lg: [{ x: 0, y: 0, w: 10, h: 4, i: "0" }]
      },
      stylePrint: {
        css_header_height_px: "",
        css_header_width_px: "",
        css_footer_height_px: "",
        css_footer_width_px: "",
        css_column_left_height_px: "",
        css_column_left_width_px: ""
      },
      styleForm: {
        headerHeight: 0
      }
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onCompactTypeChange = this.onCompactTypeChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    // this.onNewLayout = this.onNewLayout.bind(this);
  }

  handleEditPrint(text, message) {
    this.props.setEditPrint(text);
    this.props.viewAlertMessage("success", message);
    setTimeout(() => this.props.closeAlertMessage(), 5000);
  }

  generateLayoutHeader(printHeader) {
    return _.map(this.state.layouts.lg, function(l, i) {
      return (
        <div
          id="layoutsHeader"
          key={i}
          className={
            l.static
              ? "d-block layoutsHeader static"
              : "d-block layoutsHeader static"
          }
        >
          <div
            dangerouslySetInnerHTML={
              printHeader && { __html: printHeader.free_text_header }
            }
          />
        </div>
      );
    });
  }

  generateLayoutColumBody(printColumn) {
    return _.map(this.state.layouts2.lg, function(l, i) {
      return (
        <div
          id="layoutsColumn"
          key={i}
          className={
            l.static
              ? "d-block layoutsColumn static"
              : "d-block layoutsColumn static"
          }
        >
          <div
            dangerouslySetInnerHTML={
              printColumn && {
                __html: printColumn.free_text_column_left
              }
            }
          />
        </div>
      );
    });
  }

  generateLayoutFooter(printFooter) {
    return _.map(this.state.layouts3.lg, function(l, i) {
      return (
        <div
          id="layoutsFooter"
          key={i}
          className={
            l.static
              ? "d-block layoutsFooter static"
              : "d-block layoutsFooter static"
          }
        >
          <div
            dangerouslySetInnerHTML={
              printFooter && {
                __html: printFooter.free_text_footer
              }
            }
          />
        </div>
      );
    });
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint
    });
  }

  onCompactTypeChange() {
    const { compactType: oldCompactType } = this.state;
    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
        ? null
        : "horizontal";
    this.setState({ compactType });
  }

  onLayoutChange() {
    // document
    //   .getElementById("layoutsColumn")
    //   .setAttribute("style", `max-width:none;`);

    let widthHeader = document.getElementsByClassName("layoutsHeader")[0].style
      .width;
    let heightHeader = document.getElementsByClassName("layoutsHeader")[0].style
      .height;
    let widthFooter = document.getElementsByClassName("layoutsFooter")[0].style
      .width;
    let heightFooter = document.getElementsByClassName("layoutsFooter")[0].style
      .height;
    let widthColumn = document.getElementsByClassName("layoutsColumn")[0].style
      .width;
    let heigthColumn = document.getElementsByClassName("layoutsColumn")[0].style
      .height;

    this.props.listStyle({
      css_header_width_px: widthHeader.substring(0, widthHeader.length - 2),
      css_column_left_height_px: heigthColumn.substring(
        0,
        heigthColumn.length - 2
      ),
      css_header_height_px: heightHeader.substring(0, heightHeader.length - 2),
      css_footer_height_px: heightFooter.substring(0, heightFooter.length - 2),
      css_footer_width_px: widthFooter.substring(0, widthFooter.length - 2),
      css_column_left_width_px: widthColumn.substring(0, widthColumn.length - 2)
    });

    this.setState(state => {
      state.stylePrint = {
        ...state.stylePrint,
        css_header_width_px: widthHeader.substring(0, widthHeader.length - 2),
        css_column_left_height_px: heigthColumn.substring(
          0,
          heigthColumn.length - 2
        ),
        css_header_height_px: heightHeader.substring(
          0,
          heightHeader.length - 2
        ),
        css_footer_height_px: heightFooter.substring(
          0,
          heightFooter.length - 2
        ),
        css_footer_width_px: widthFooter.substring(0, widthFooter.length - 2),
        css_column_left_width_px: widthColumn.substring(
          0,
          widthColumn.length - 2
        )
      };
      return state;
    });
  }

  onNewLayout() {
    let NewLa = [
      { x: 800, y: 600, w: 700, h: 700, i: "0" },
      { x: 200, y: 800, w: 200, h: 300, i: "1" }
    ];
    this.setState({ layouts: NewLa });
  }

  componentDidMount() {
    this.setState({ mounted: true });
    if (this.props.print !== undefined) {
      const {
        css_header_height_px,
        css_footer_height_px,
        css_footer_width_px,
        css_column_left_height_px,
        css_column_left_width_px
      } = this.props.print;

      document
        .getElementById("layoutsHeader")
        .setAttribute("style", `height: ${css_header_height_px}px;`);

      document
        .getElementById("layoutsFooter")
        .setAttribute(
          "style",
          `height: ${css_footer_height_px}px; width: ${css_footer_width_px}px;`
        );
      document
        .getElementById("layoutsColumn")
        .setAttribute(
          "style",
          `height: ${css_column_left_height_px}px; width: ${css_column_left_width_px}px;`
        );
    }

    if (this.props.print !== undefined) {
      this.setState(state => {
        state.stylePrint = {
          css_header_height_px: this.props.print.css_header_height_px,
          css_header_width_px: this.props.print.css_header_width_px,
          css_footer_height_px: this.props.print.css_footer_height_px,
          css_footer_width_px: this.props.print.css_footer_width_px,
          css_column_left_height_px: this.props.print.css_column_left_height_px,
          css_column_left_width_px: this.props.print.css_column_left_width_px
        };
        return state;
      });
    }
  }

  render() {
    return (
      <>
        <Card
          style={{
            width: "793px",
            height: "1122px",
            transform: "scale(0.5)",
            margin: "-235px",
            border: "2px solid #000",
            display: "block"
          }}
        >
          <>
            <Tooltip
              title="Clique duas vezes para editar o CABEÇALHO"
              placement="bottom"
            >
              <div
                className="d-block m-1"
                onDoubleClick={e =>
                  this.handleEditPrint("free_text_header", "Editar CABEÇALHO")
                }
                style={{ paddingTop: "30px" }}
              >
                <ResponsiveReactGridLayout
                  style={{ height: "auto" }}
                  {...this.props}
                  layouts={this.state.layouts}
                  onBreakpointChange={this.onBreakpointChange}
                  onLayoutChange={e => this.onLayoutChange()}
                  measureBeforeMount={false}
                  useCSSTransforms={this.state.mounted}
                  compactType="vertical"
                  preventCollision={!this.state.compactType}
                >
                  {this.generateLayoutHeader(this.props.print)}
                </ResponsiveReactGridLayout>
              </div>
            </Tooltip>
            <Tooltip
              title="Clique duas vezes para editar a COLUNA LATERAL"
              placement="right"
            >
              <div
                className="d-block m-1"
                onDoubleClick={e =>
                  this.handleEditPrint(
                    "free_text_column_left",
                    "Editar COLUNA LATERAL"
                  )
                }
              >
                <ResponsiveReactGridLayout
                  {...this.props}
                  style={{ height: "auto" }}
                  layouts={this.state.layouts2}
                  onBreakpointChange={this.onBreakpointChange}
                  onLayoutChange={e => this.onLayoutChange()}
                  measureBeforeMount={false}
                  useCSSTransforms={this.state.mounted}
                  compactType="vertical"
                  preventCollision={!this.state.compactType}
                >
                  {this.generateLayoutColumBody(this.props.print)}
                </ResponsiveReactGridLayout>
              </div>
            </Tooltip>
            <Tooltip
              title="Clique duas vezes para editar o RODAPÉ"
              placement="top"
            >
              <div
                className="d-block m-1"
                onDoubleClick={e =>
                  this.handleEditPrint("free_text_footer", "Editar RODAPÉ")
                }
              >
                <ResponsiveReactGridLayout
                  {...this.props}
                  style={{ height: "auto" }}
                  layouts={this.state.layouts3}
                  onBreakpointChange={this.onBreakpointChange}
                  onLayoutChange={e => this.onLayoutChange()}
                  measureBeforeMount={false}
                  useCSSTransforms={this.state.mounted}
                  compactType="vertical"
                  preventCollision={!this.state.compactType}
                >
                  {this.generateLayoutFooter(this.props.print)}
                </ResponsiveReactGridLayout>
              </div>
            </Tooltip>
          </>
          {/* )} */}
        </Card>
      </>
    );
  }
}

ShowcaseLayout.propTypes = {
  onLayoutChange: PropTypes.func.isRequired
};

ShowcaseLayout.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: function() {},
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }
  // initialLayout: generateLayout()
};

// function generateLayout() {
//   return _.map(_.range(0, 4), function(item, i) {
//     var y = Math.ceil(Math.random() * 4) + 1;
//     return {
//       x: (_.random(0, 5) * 2) % 12,
//       y: Math.floor(i / 6) * y,
//       w: 2,
//       h: y,
//       i: i.toString()
//       // static: Math.random() < 0.05
//     };
//   });
// }

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { listStyleFormPrint, viewAlertMessage, closeAlertMessage },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(ShowcaseLayout);
