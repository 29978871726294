import React from "react";
import Button from "@material-ui/core/Button";

const ViewEmergencyContact = props => {
  const {
    name,
    reference,
    cellphone,
    whatsapp,
    phone,
    email,
    priority,
    locality
  } = props.contact;

  const style = {
    marginLeft: "10px",
    fontWeight: "bold",
    fontSize: "16px"
  };
  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <h2 className="text-primary font-weight-semibold">
          Contato de emergência
        </h2>
      </div>
      <div style={{ paddingLeft: "20px" }}>
        <div className="col-md-12 col-xs-12">
          <span> Nome:</span>
          <span style={style}>{name}</span>
        </div>
        <div className="col-md-12 col-xs-12">
          <span> Referência:</span>
          <span style={style}>{reference ? reference : "---"}</span>
        </div>
        <div className="col-md-12 col-xs-12">
          <span> Prioridade:</span>
          <span style={style}>
            {priority === "0" ? "Preferêncial" : "Alternativo"}
          </span>
        </div>
        <div className="col-md-12 col-xs-12">
          <span> Localidade:</span>
          <span style={style}>{locality ? locality : "---"}</span>
        </div>
        <div className="col-md-12 col-xs-12">
          <span> Celular:</span>
          <span style={style}>{cellphone ? cellphone : "---"}</span>
        </div>
        <div className="col-md-12 col-xs-12">
          <span> Fixo:</span>
          <span style={style}>{phone ? phone : "---"}</span>
        </div>
        <div className="col-md-12 col-xs-12">
          <span> Whatsapp:</span>
          <span style={style}>{whatsapp ? whatsapp : "---"}</span>
        </div>
        <div className="col-md-12 col-xs-12">
          <span> Email:</span>
          <span style={style}>{email ? email : "---"}</span>
        </div>
      </div>

      <div className="col-md-12 text-left">
        <Button
          color="secondary"
          className="jr-btn"
          onClick={() => props.handleClose()}
        >
          <i className="zmdi zmdi-close zmdi-hc-lg" />
          <span> Cancelar </span>
        </Button>
      </div>
    </div>
  );
};

export default ViewEmergencyContact;
