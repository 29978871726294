import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import NoRecord from "components/Alert/NoRecord";
import DataTable from "components/Tables/DataTable";
import TextField from "@material-ui/core/TextField";
import { BtnCan } from "components/Button";
import { ModalResponsive } from "components/ModalResponsive";
import ComponentsProfile from "components/ComponentsProfile";
import Api from "services/api";
import moment from "moment";

export default function ModalPointRegister({
  openModal,
  closeModal,
  userId,
  addressId,
  profile,
}) {
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [pointRegister, setPointRegister] = useState([]);
  const [workWeek, setWorkWeek] = useState(0);
  const [workMonth, setWorkMonth] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      getPointRegister();
    }
  }, [userId]);

  useEffect(() => {
    if (selectedDate) {
      getPointRegister();
    }
  }, [selectedDate]);

  const handleCloseModal = () => {
    closeModal();
    setSelectedDate(moment().format("YYYY-MM-DD"));
  };

  const getPointRegister = () => {
    Api.post(`/microservice/managementprofessional/getPointRegister`, {
      user: userId,
      date: selectedDate,
      addressId,
    })
      .then((res) => {
        setPointRegister(res.data.record);
        setWorkMonth(res.data.workMonth);
        setWorkWeek(res.data.workWeek);
      })
      .catch((e) => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro!"));
        setTimeout(() => dispatch(closeAlertMessage()), 3000);
      });
  };

  return (
    <ModalResponsive
      title="Registro de Ponto"
      open={openModal}
      close={handleCloseModal}
      maxWidth="sm"
      buttons={
        <div className="col-12">
          <>
            <BtnCan
              title="Fechar"
              onClick={() => {
                handleCloseModal();
              }}
              float="left"
            />
          </>
        </div>
      }
    >
      <div className="container">
        <div className="row align-items-end">
          <div className="col-md-8">
            <ComponentsProfile
              id={profile.id}
              nameProfile={profile.name}
              subTitle={profile.profession}
              photo={profile.url}
            />
          </div>
          <div className="col-md-4" style={{ marginTop: "15px" }}>
            <TextField
              type="date"
              label="Data:"
              fullWidth
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            <p
              className="text-left"
              style={{ color: "gray", fontSize: "13px" }}
            >
              {`Total de horas trabalhadas na semana: ${workWeek}hrs`}
              <br />
              {`Total de horas trabalhadas no mês: ${workMonth}hrs`}
            </p>
          </div>
        </div>
        {pointRegister.length > 0 ? (
          <DataTable
            columns={["#", "Entrada", "Saída", "Observação"]}
            rows={pointRegister.map((e, index) => {
              return [
                index + 1,
                [e.status === "0" ? e.hour : " "],
                [e.status === "1" ? e.hour : " "],
                [e.observation],
              ];
            })}
            download={false}
            print={false}
            hideSearch={true}
            rowsPerPage={5}
            height="100%"
          />
        ) : (
          <NoRecord />
        )}
      </div>
    </ModalResponsive>
  );
}
