import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Draggable from "react-draggable";
import Jitsi from "react-jitsi";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { modalVideo } from "actions/comunication/Chat";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  chatBox: {
    position: "absolute",
    zIndex: "999",
    width: "350px",
  },
  ScrollCursor: {
    padding: theme.spacing(2),
    textAlign: "center",
    cursor: "all-scroll",
  },
}));

function DraggableVideo({ chatId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const name = useSelector(({ auth }) => auth.name);
  const width = useSelector((state) => state.settings.width);

  const handleLoadJitsi = (jitsi) => {
    jitsi.addEventListener("videoConferenceLeft", () => {
      if (document.getElementById("load-jitsi")) {
        document.getElementById("load-jitsi").classList.remove("d-none");
      }
      if (document.getElementById("div-jitsi")) {
        document.getElementById("div-jitsi").classList.add("d-block");
      }
      dispatch(modalVideo(false));
    });
    jitsi.addEventListener("readyToClose", () => {
      document.getElementById("load-jitsi").classList.remove("d-none");
      document.getElementById("div-jitsi").classList.add("d-block");
      dispatch(modalVideo(false));
    });
    jitsi.addEventListener("videoConferenceJoined", () => {
      document.getElementById("div-jitsi").classList.remove("d-none");
      document.getElementById("div-jitsi").classList.add("d-block");
      document.getElementById("load-jitsi").classList.add("d-none");
    });
  };

  return (
    chatId && (
      <Draggable
        key={chatId}
        handle=".handler"
        positionOffset={{
          x: width > 900 ? window.innerWidth - 850 : 0,
          y: width > 900 ? 50 : 25,
        }}
        bounds={{ top: 25, bottom: 20 }}
      >
        <Paper
          className={classes.chatBox}
          elevation={3}
          style={{
            width: width < 900 ? "100%" : "550px",
            marginTop: width < 900 ? "30px" : "5px",
            marginBottom: width < 900 ? "25px" : "",
            position: width < 900 ? "static" : "",
          }}
        >
          {width > 900 && (
            <div className={`handler ${classes.ScrollCursor} w-100`}>
              Videochamada
            </div>
          )}
          <div
            id="load-jitsi"
            className="loader-view"
            style={{ height: "87vh" }}
          >
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <h1 className="text-muted">Iniciando teleconsulta ...</h1>
          </div>
          <div id="div-jitsi" className="d-none">
            <Jitsi
              roomName={`salutemplus-5242654807${chatId}9531784`}
              displayName={name}
              config={{
                defaultLanguage: "pt",
                disableDeepLinking: true,
                prejoinPageEnabled: false,
              }}
              interfaceConfig={{
                SHOW_JITSI_WATERMARK: false,
                SHOW_WATERMARK_FOR_GUESTS: false,
                DISPLAY_WELCOME_PAGE_CONTENT: false,
                HIDE_INVITE_MORE_HEADER: true,
                APP_NAME: "SalutemPlus",
                NATIVE_APP_NAME: "SalutemPlus",
                PROVIDER_NAME: "SalutemPlus",
                MOBILE_APP_PROMO: false,
                TOOLBAR_BUTTONS: [
                  "microphone",
                  "camera",
                  "closedcaptions",
                  "desktop",
                  "fullscreen",
                  "hangup",
                ],
              }}
              containerStyle={{ width: "100%", height: "300px" }}
              onAPILoad={handleLoadJitsi}
            />
          </div>
        </Paper>
      </Draggable>
    )
  );
}

export default DraggableVideo;
