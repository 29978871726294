import React, { Component } from "react";
import { connect } from "react-redux";
import { getListForward } from "actions/consult/Forward";
import { bindActionCreators } from "redux";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableFooter,
  IconButton,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Moment from "moment";

class ListForward extends Component {
  state = {
    rowsPerPage: 10,
    page: 0,
  };
  componentWillMount() {
    this.props.getListForward(this.props.patient);
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { rowsPerPage, page } = this.state;
    return (
      this.props.list !== undefined && (
        <>
          {this.props.list.length > 0 ? (
            <>
              <div
                className="col-12"
                style={{
                  minWidth: "650",
                  overflowX: "auto",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Encaminhado por</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell colspan={2} style={{ textAlign: "center" }}>
                        Encaminhado para
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.list
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((el) => (
                        <TableRow key={el.id}>
                          <TableCell>{el.oName}</TableCell>
                          <TableCell>
                            {Moment(el.created_at).format("DD/MM/YYYY HH:mm")}
                          </TableCell>
                          <TableCell>{el.dName} </TableCell>
                          <TableCell>
                            {el.fk_ls_doctor ? "Profissional" : "Prestador"}
                          </TableCell>
                          <TableCell>
                            <IconButton />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={this.props.list.length}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) =>
                          `Mostrando ${from} - ${to} de ${count}`
                        }
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            </>
          ) : (
            <p
              style={{
                textAlign: "center",
                color: "#a6a6a6",
                fontStyle: "italic",
              }}
            >
              Nenhum Registro Encontrado
            </p>
          )}
        </>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  list: state.forward.listForward,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getListForward }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListForward);
