import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ModalResponsive } from "components/ModalResponsive";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import { selectedUserChat, setSelectedChat } from "actions/comunication/Chat";
import ContactList from "components/chatPanel/ContactList/index";
import { Fab, Tooltip, Drawer } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";

export default function ModalChatActiver({
  open,
  width,
  listchats,
  handleClose,
  drState,
  setDrState,
}) {
  const dispatch = useDispatch();
  const selectedChat = useSelector(({ chat }) => chat.selectedChat);
  const [state, setState] = useState({
    userNotFound: "Contato não encontrado",
    selectedSectionId: "",
    selectedTabIndex: 0,
    searchChatUser: "",
  });

  const onSelectUser = (user) => {
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/` + selectedChat)
      .off();
    setState({ ...state, selectedSectionId: user.id });
    dispatch(setSelectedChat(user.chat_id));
    dispatch(selectedUserChat(user));
    handleClose();
  };

  return (
    <ModalResponsive
      open={open}
      maxWidth={"sm"}
      title={"Conversas"}
      close={handleClose}
      paddingContent={true}
      buttons={
        <div className="col-12">
          {/* <BtnCan title={"fechar"} onClick={handleClose} float="left" />
          {/* <BtnSave
            title={"Salvar Edição"}
            onClick={handleSaveEditBudget}
            float="right"
          />  */}
        </div>
      }
    >
      {listchats.length > 0 && (
        <ContactList
          contactList={listchats}
          onSelectUser={onSelectUser}
          selectedSectionId={state.selectedSectionId}
        />
      )}
      <div className="col-12" style={{ display: "contents" }}>
        <Tooltip placement="top" title="Editar">
          <Fab
            onClick={() => setDrState(!drState)}
            color="primary"
            aria-label="add"
            style={{
              margin: 0,
              top: "auto",
              right: 20,
              bottom: width < 960 ? 80 : 10,
              left: "auto",
              position: "fixed",
              zIndex: 999,
            }}
          >
            <ChatIcon />
          </Fab>
        </Tooltip>
      </div>
    </ModalResponsive>
  );
}
