import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEvents } from "actions/Calendar";
import Api from "services/api";

import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import "./style.css";
import moment from "moment";
import "@fullcalendar/daygrid/main.css";

function FullCalendarIntegrated({ providerType }) {
  const calendarComponentRef = React.createRef();
  const [idsCalendars, setIdsCalendars] = useState("");
  const [resources, setResources] = useState([]);
  const calendars = useSelector((state) => state.calendar.calendars);
  const date = useSelector((state) => state.calendar.date_calendar);
  const events = useSelector((state) => state.calendar.events);
  const config = useSelector((state) => state.calendar.config);
  const type = useSelector((state) => state.auth.type);
  const secretary = useSelector((state) => state.auth.secretary);
  const dispatch = useDispatch();

  useEffect(() => {
    let ids = calendars.map((row) => row.id);
    setIdsCalendars(ids.join(","));
  }, []);

  useEffect(() => {
    if (idsCalendars) {
      Api.post(`/ms_system/calendar/getResources/`, {
        calendars: idsCalendars,
        providerType,
        date,
      }).then((res) => {
        if (type === "3" && !secretary) {
          setResources([
            ...res.data.rooms,
            {
              building: "Agendas",
              children: [],
              id: "compr",
              title: "Agendamentos",
            },
          ]);
        } else {
          setResources(res.data.rooms);
        }
        dispatch(
          getEvents(
            idsCalendars,
            date,
            "integrated",
            type === "3" && !secretary
          )
        );
      });
    }
  }, [idsCalendars, date]);

  function handleDate(start, end) {
    if (date.start !== start || date.end !== end) {
      dispatch({ type: "HANDLE_DATE", date: { start, end } });
    }
  }

  /*  function contentMenu() {
    return "\x30\x37\x34\x39\x33\x39\x39\x39\x35\x32\x2D\x66\x63\x73\x2D\x31\x35\x34\x34\x30\x31\x38\x38\x30\x30";
  } */

  return (
    <FullCalendar
      locale={ptBrLocale}
      ref={calendarComponentRef}
      schedulerLicenseKey="0545733363-fcs-1609764316"
      plugins={[resourceTimelinePlugin, interactionPlugin]}
      initialView="resourceTimeline"
      resources={resources}
      resourceAreaHeaderContent="Agendas"
      resourceGroupField="building"
      aspectRatio={1.5}
      events={(date, callback) => {
        handleDate(
          moment(date.start).format("YYYY-MM-DD HH:mm:ss"),
          moment(date.end).format("YYYY-MM-DD HH:mm:ss")
        );
        callback(events);
      }}
      headerToolbar={{
        left: "prev,next",
        center: "title",
        right: "",
      }}
      selectable={true}
      selectdraw={true}
      navLinks={true}
      nowIndicator={true}
      inititalDate={new Date()}
      contentHeight="auto"
      eventLimit={true}
      displayEventTime={true}
      displayEventEnd={true}
      eventTimeFormat={{
        hour: "2-digit",
        minute: "2-digit",
      }}
      slotMinTime={config.min_time}
      slotMaxTime={config.max_time}
      slotDuration={config.slot_interval}
      slotMinWidth={"100"}
      dateClick={(e) => {
        if (parseInt(e.resource.id) > 1) {
          dispatch({
            type: "DATE_CLICK",
            date: moment(e.dateStr).format("YYYY-MM-DD HH:mm"),
            calendar: e.resource.id,
          });
        }
      }}
      eventClick={(e) => {
        dispatch({
          type: "EVENT_CLICK",
          event: e.event.id,
          calendar: e.event._def.resourceIds[0],
          patient: e.event.extendedProps.pacient_id,
          number_patients: parseInt(e.event.extendedProps.number_patients),
        });
      }}
      eventContent={(arg) => {
        let { event, view } = arg;
        if (
          view.type !== "listWeek" &&
          parseInt(event.extendedProps.number_patients) === 1 &&
          event.extendedProps.bloqued !== "1"
        ) {
          let icon = "";
          switch (event.extendedProps.status) {
            case "1":
              icon = "zmdi-circle-o";
              break;
            case "2":
              icon = "zmdi-circle";
              break;
            case "3":
              icon = "zmdi-alert-circle-o";
              break;
            case "4":
              icon = "zmdi-spinner";
              break;
            case "5":
              icon = "zmdi-check-circle";
              break;
            case "6":
              icon = "zmdi-close-circle-o";
              break;
            default:
              icon = "zmdi-circle-o";
              break;
          }
          return (
            <i
              className={`zmdi ${icon} zmdi-fw ml-1 mr-1 d-flex`}
              style={{ cursor: "pointer" }}
            >
              <i
                className="zmdi zmdi-tag zmdi-fw ml-1 mr-1"
                style={{
                  color: event.extendedProps.color_tag
                    ? event.extendedProps.color_tag
                    : "#FFA400",
                }}
              />
              {arg.timeText} {event.title}
            </i>
          );
        }
      }}
      eventDidMount={(arg) => {
        if (arg.event.extendedProps.bloqued === "1") {
          arg.el.style.color = "#FFA400";
          arg.el.style.background =
            "linear-gradient(135deg, rgba(242,246,248,1) 0%,rgba(216,225,231,1) 50%,rgba(181,198,208,1) 51%,rgba(224,239,249,1) 100%)";
        } else if (arg.view.type === "listWeek") {
          arg.el.style.color = "#000";
          arg.el.style.background = "#fff";
        } else {
          arg.el.style.color = arg.textColor;
          arg.el.style.background = arg.backgroundColor;
        }
      }}
    />
  );
}

export default FullCalendarIntegrated;
