import React from "react";
import MaskedInput from "react-text-mask";

const MaskCellphone = props => {
  let { inputRef, ...others } = props;
  return (
    <MaskedInput
      {...others}
      mask={[
        "(",
        /[1-9]/,
        /[1-9]/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
    />
  );
};

export default MaskCellphone;
