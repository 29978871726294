const INITIAL_STATE = {
  nameGroup: "",
  descGroup: "",
  modalSuccessDeleteGroup: false,
  active: "view",
  color: "primary",
  texto: "Adicionar",
  hidden: false,
  icon: "plus",
  idEdit: "",
  InfosGroup: [],
  listProceduresSelected: [],
  listProceduresGroups: []
};

export default function GroupsMedicines(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_PROCEDURE_GROUPS":
      return INITIAL_STATE;
    case "HANDLE_ADD_FUNC":
      if (action.payload.id) {
        return {
          ...state,
          active: action.payload.active,
          color: action.payload.color,
          texto: action.payload.texto,
          hidden: action.payload.hidden,
          icon: action.payload.icon,
          idEdit: action.payload.id
        };
      } else {
        return {
          ...state,
          active: action.payload.active,
          color: action.payload.color,
          texto: action.payload.texto,
          hidden: action.payload.hidden,
          icon: action.payload.icon,
          idEdit: action.payload.id,
          InfosGroup: [],
          tables_exam: [],
          nameGroup: "",
          descGroup: "",
          listProceduresSelected: []
        };
      }

    case "SET_NAME_GROUP":
      return {
        ...state,
        nameGroup: action.payload.value
      };

    case "SET_DESC_GROUP":
      return {
        ...state,
        descGroup: action.payload.value
      };

    case "SET_PROCEDURES_ITENS_GROUPS":
      let aux = state.listProceduresSelected;
      let exist = aux.filter(el => el.id === action.payload.value.id);
      if (exist.length === 0) {
        aux.unshift(action.payload.value);
      }
      return {
        ...state,
        listMedicinesSelected: aux
      };

    case "CAD_PROCEDURES_GROUPS":
      return {
        ...state,
        doctor: action.payload.doctor,
        nameGroupValue: action.payload.nameGroupValue,
        descGroupValue: action.payload.descGroupValue,
        proceduresSelected: action.payload.proceduresSelected,
        idEdit: action.payload.idEdit
      };

    case "DEL_ITEM_PROCEDURE":
      let auxDel = [];

      state.listProceduresSelected.forEach(procedure => {
        if (procedure.id !== action.payload.id) {
          auxDel.push(procedure);
        }
      });

      return {
        ...state,
        listProceduresSelected: auxDel
      };

    case "CHANGE_OBS":
      let auxObs = [];
      state.listProceduresSelected.forEach((element, key) => {
        if (key === action.payload.index) {
          auxObs.push({
            ...element,
            obs: action.payload.value
          });
        } else {
          auxObs.push(element);
        }
      });
      return {
        ...state,
        listProceduresSelected: [...auxObs]
      };

    case "REQUEST_LIST_DOCTOR_PROCEDURES_GROUP":
      return {
        ...state,
        doctor: action.payload.doctor
      };

    case "REQUEST_LIST_PROCEDURES_GROUP_SUCCESS":
      return {
        ...state,
        listProceduresGroups: action.payload.listProceduresGroups
      };

    case "DEL_GROUP_PROCEDURES":
      return {
        ...state,
        id: action.payload.id
      };

    case "REMOVE_GROUP_PROCEDURES_SUCESS":
      let auxListGroup = state.listProceduresGroups.filter(
        row => row.id !== action.id
      );
      return {
        ...state,
        listProceduresGroups: auxListGroup,
        modalSuccessDeleteGroup: true
      };

    case "CLOSE_MODAL_DELETE_SUCCESS":
      return {
        ...state,
        modalSuccessDeleteGroup: false
      };

    case "GET_DATA_GROUP_PROCEDURES_FOR_EDIT": {
      return {
        ...state
      };
    }

    case "REQUEST_INFO_PROCEDURES_GROUP_SUCCESS": {
      return {
        ...state,
        nameGroup: action.payload.InfosGroup[0].name,
        descGroup: action.payload.InfosGroup[0].description,
        listProceduresSelected: action.payload.ListItensProceduresEdit
      };
    }

    default:
      return state;
  }
}
