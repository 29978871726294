import React from "react";
import Api from "services/api";
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormPrintComp from "../formPrint/index";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { useDispatch } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";

export default function FormPrint({
  data,
  formPrint,
  addressId,
  hiddenButton,
  assoc
}) {
  const dispatch = useDispatch();
  // const classes = useStyles();

  const [state, setState] = React.useState({
    openModalPrint: false,
    list: []
  });

  const handleFormPrint = () => {
    let formData = {
      address: data.id,
      idUser: data.idUser
    };

    Api.post(`/microservice/formprint/setFormPrint/`, formData)
      .then(res => {
        dispatch({
          type: "LIST_STYLE_FORM_PRINT",
          payload: res.data.formPrint
        });
        setState({ ...state, list: res.data.formPrint, openModalPrint: true });
      })
      .catch(() => {});
  };

  const newFormPrint = () => {
    let dataEmpty = {
      css_column_left_height_px: "726",
      css_column_left_width_px: "119",
      css_footer_height_px: "150",
      css_footer_width_px: "761",
      css_header_height_px: "170",
      css_header_width_px: "761"
    };

    dispatch({
      type: "LIST_STYLE_FORM_PRINT",
      // payload: []
      payload: dataEmpty
    });

    setState({ openModalPrint: true, list: dataEmpty });
  };

  const updateListWorkPlace = fromPrint => {
    dispatch({
      type: "UPDATE_LIST_WORKPLACE_FORMPRINT",
      list: fromPrint,
      id: data.id
    });
  };
  return (
    <>
      <ExpansionPanel
        square
        style={{
          boxShadow: "10px 5px 5px white",
          borderBottom: "1px solid #d2d2d2"
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
        >
          Formulário de Impressão
        </ExpansionPanelSummary>
        {formPrint !== undefined && (
          <ExpansionPanelDetails style={{ display: "block" }}>
            <FormPrintComp
              nameLocalAttendance={data.name}
              openModalPrint={state.openModalPrint}
              handleClose={() => setState({ openModalPrint: false })}
              addressId={data.id}
              formPrint={state.list}
              closeModal={() => setState({ openModalPrint: false })}
              updateListWorkPlace={updateListWorkPlace}
              assoc={assoc}
            />
            {formPrint.length === 0 && (
              <Button
                onClick={newFormPrint}
                className="jr-btn jr-btn-sm m-2"
                color="primary"
                variant="contained"
                style={{ float: "right" }}
                // hidden={hiddenButton}
              >
                <i className="zmdi zmdi-plus" /> <span> Adicionar</span>
              </Button>
            )}
            {formPrint.length > 0 && (
              <List>
                <ListItem button onClick={e => handleFormPrint()}>
                  <ListItemAvatar>
                    <Avatar>
                      <InsertDriveFileIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Formulário Salvo"
                    secondary={
                      formPrint[0]["updated_at"] === null
                        ? formPrint[0]["created_at"]
                        : formPrint[0]["updated_at"]
                    }
                  />
                  {!assoc ? (
                    <EditIcon
                      style={{
                        color: "#867e7e"
                      }}
                    />
                  ) : (
                    <VisibilityIcon
                      style={{
                        color: "#867e7e"
                      }}
                    />
                  )}
                </ListItem>
              </List>
            )}{" "}
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
    </>
  );
}
