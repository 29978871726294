export const allowCPF = cpfOrigin => {
  let cpf;
  if (cpfOrigin.length > 11) {
    let split = cpfOrigin.split(".");
    let Aux = "";
    split.forEach(element => {
      Aux += element;
    });
    split = Aux.split("-");
    cpf = split[0] + split[1];
  } else {
    cpf = cpfOrigin;
  }

  let numbers, digits, sum, i, result, equal_digits;
  equal_digits = 1;
  if (cpf.length < 11) return false;
  for (i = 0; i < cpf.length - 1; i++)
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      equal_digits = 0;
      break;
    }
  if (!equal_digits) {
    numbers = cpf.substring(0, 9);
    digits = cpf.substring(9);
    sum = 0;
    for (i = 10; i > 1; i--) sum += numbers.charAt(10 - i) * i;
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (String(result) !== digits.charAt(0)) return false;
    numbers = cpf.substring(0, 10);
    sum = 0;
    for (i = 11; i > 1; i--) sum += numbers.charAt(11 - i) * i;
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (String(result) !== digits.charAt(1)) return false;
    return true;
  } else return false;
};

export const allowCNPJ = cnpjOrigin => {
  let new_cnpj, size, numbers, digits, sum, pos, result;
  // new_cnpj = cnpj.replace(/[^\d]+/g, "");
  new_cnpj = cnpjOrigin.replace(/[^\d]+/g, "");
  if (new_cnpj === "") return false;

  if (new_cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    new_cnpj === "00000000000000" ||
    new_cnpj === "11111111111111" ||
    new_cnpj === "22222222222222" ||
    new_cnpj === "33333333333333" ||
    new_cnpj === "44444444444444" ||
    new_cnpj === "55555555555555" ||
    new_cnpj === "66666666666666" ||
    new_cnpj === "77777777777777" ||
    new_cnpj === "88888888888888" ||
    new_cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  size = new_cnpj.length - 2;
  numbers = new_cnpj.substring(0, size);
  digits = new_cnpj.substring(size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (String(result) !== digits.charAt(0)) return false;

  size = size + 1;
  numbers = new_cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (String(result) !== digits.charAt(1)) return false;

  return true;
};
