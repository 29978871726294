import * as TYPES from "constants/CustomSystemReview";

const initialState = {
  [TYPES.CUTANEO]: { id: "", view: "" },
  [TYPES.URINARIO]: { id: "", view: "" },
  [TYPES.LESOES]: { id: "", view: "" },
  [TYPES.SUP_INF]: { id: "", view: "" },
  [TYPES.SENTIDOS]: { id: "", view: "" },
  [TYPES.OSTEOMUSCULAR]: { id: "", view: "" },
  [TYPES.CARDIO]: { id: "", view: "" },
  [TYPES.DIGESTIVO]: { id: "", view: "" },
  [TYPES.NERVOSO]: { id: "", view: "" },
  [TYPES.RESPIRATORIO]: { id: "", view: "" },
  [TYPES.URINARIO_F]: { id: "", view: "" },
  [TYPES.URINARIO_M]: { id: "", view: "" },
  [TYPES.URINARIO_O]: { id: "", view: "" },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_SYSTEM_REVIEW_CUSTOM":
      let { id, view } = payload;
      return { ...state, [Number(payload.type)]: { id, view } };

    default:
      return state;
  }
};
