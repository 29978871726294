import React, { useEffect, useState } from "react";
import { viewHistory, closeHistory } from "actions/consult/Appointment";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";
import Avatar from "@material-ui/core/Avatar";
import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
// import Clear from "@material-ui/icons/Clear";
import IconClose from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ConsultHistory from "./consult";
import PersonalData from "app/routes/consult/routes/personalData";
import PersonalHistory from "./personalHistory";
import Allergies from "./allergies";
import MedicinesInUse from "./medicinesInUse";
import Aga from "./aga";
import Circumferences from "./circumferences";
import Folds from "./folds";
import Exams from "./exams";
import Vaccines from "./vaccines";
import Disabilities from "./disabilities";
import FamilyHistory from "./familyHistory";
import ChildDiseases from "./childDisorders";
import ProcedureHistory from "./procedureHistory";
import PersonalHabits from "./personalHabits";
import Prescription from "./prescription";
import Forward from "./forward";
import SystemReview from "./systemReview";
import NursePrescription from "./nursePrescription";
import HistoryInnerWidth from "./HistoryInnerWidth";
import PainScale from "./painScale";
import DiagnosticIntegral from "./odonto/diagnosticIntegral";
import Periodotogram from "./odonto/periodotogram";
import IndexPlate from "./odonto/indexPlate";
import GeneralObjective from "./odonto/generalObjective";
import FoodPlan from "./nutrition/nutFoodPlan";
import FoodReminder from "./nutrition/nutFoodReminder";
import PrescriptionCare from "./prescriptionCare";
import Manipulated from "./manipulated";
import Treatment from "./treatment";
import Monitoring from "./monitoring";
import Api from "services/api";

const styles = (theme) => ({
  root: {
    overflowY: "auto",
    backgroundColor: "#fff",
  },
  paper: {
    background: "#e8e8e8",
  },
  iconClose: {
    "&:hover": {
      color: "red",
      cursor: "pointer",
    },
  },
  spanColor: {
    color: "gray",
    fontWeight: "bold",
  },
  alignIten: {
    justifyContent: "initial !important",
  },
});

function HistoryPatient({ classes, id_patient }) {
  const [patient, setPatient] = useState({});
  const user = useSelector((state) => state.auth.authUser);
  const appointment = useSelector((state) => state.appointment);
  const active = appointment.actual_form;
  const dispatch = useDispatch();

  useEffect(() => {
    if (id_patient) {
      Api.post(`/microservice/consult/getInfoPatient/`, {
        patient: id_patient,
      }).then((res) => setPatient(res.data.patient));
    }
  }, [id_patient]);

  return (
    <>
      {window.innerWidth > 600 ? (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={appointment.modal_history}
            onClose={() => dispatch(closeHistory())}
            aria-labelledby="max-width-dialog-title"
            scroll="body"
            classes={{
              root: classes.root,
              paper: classes.paper,
            }}
            style={{ backgroundColor: "white" }}
          >
            <DialogContent>
              <div>
                <div className="d-flex justify-content-center">
                  <div className="col-6 d-flex justify-content-start mb-4 m-0 p-0">
                    <div>
                      <Avatar
                        className="size-60"
                        alt={patient.name}
                        src={patient.photo}
                      />
                    </div>
                    <div className="info-user">
                      <h3 className="name-user m-0 p-0">{patient.name}</h3>
                      <Typography
                        className="m-0 pb-0"
                        variant="body2"
                        gutterBottom
                      >
                        <span className="preText">E-mail: </span>{" "}
                        {patient.email}
                      </Typography>{" "}
                      <Typography
                        className="m-0 pb-0"
                        variant="body2"
                        gutterBottom
                      >
                        <span className="preText">Telefone: </span>{" "}
                        {patient.smartphone}
                      </Typography>{" "}
                    </div>
                  </div>
                  <div
                    onClick={() => dispatch(closeHistory())}
                    style={{ float: "right", marginLeft: "auto" }}
                  >
                    <IconClose className={classes.iconClose} />
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-3 border-right pl-0"
                    style={{ backgroundColor: "white" }}
                  >
                    <ul align="left" className="list-inline list-inline-3 p-0">
                      <li key="1" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("personalData"))}
                          className={
                            active === "personalData"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Informações pessoais</span>
                        </Button>
                      </li>
                      <hr />
                      <span className={classes.spanColor}> Anamnese </span>
                      <li
                        key="6"
                        className="list-inline-item mr-0 w-100 p-0 m-0"
                      >
                        <Button
                          onClick={() => dispatch(viewHistory("allergies"))}
                          className={
                            active === "allergies"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Alergias</span>
                        </Button>
                      </li>
                      <li key="8" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("disabilities"))}
                          className={
                            active === "disabilities"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Disfunções funcionais e outras</span>
                        </Button>
                      </li>
                      <li key="4" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("childDiseases"))}
                          className={
                            active === "childDiseases"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Doenças da infância</span>
                        </Button>
                      </li>
                      <li key="3" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("familyHistory"))}
                          className={
                            active === "familyHistory"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Histórico familiar</span>
                        </Button>
                      </li>
                      <li key="9" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("habits"))}
                          className={
                            active === "habits"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Hábitos</span>
                        </Button>
                      </li>
                      <li key="2" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("historyPerson"))}
                          className={
                            active === "historyPerson"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Histórico pessoal</span>
                        </Button>
                      </li>
                      <li key="5" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("medicines"))}
                          className={
                            active === "medicines"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Medicamentos</span>
                        </Button>
                      </li>

                      <li key="10" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("cbhpm"))}
                          className={
                            active === "cbhpm"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Procedimentos</span>
                        </Button>
                      </li>

                      <hr />
                      <li key="0" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("consult"))}
                          className={
                            active === "consult"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Atendimentos</span>
                        </Button>
                      </li>

                      {/* <li key="14" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("aga"))}
                          className={
                            active === "aga"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Avaliação Geriátrica Ampla</span>
                        </Button>
                      </li> */}

                      {/* <li key="19" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() =>
                            dispatch(viewHistory("circumferences"))
                          }
                          className={
                            active === "circumferences"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Circunferencias</span>
                        </Button>
                      </li> */}
                      {/* <li key="22" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() =>
                            dispatch(viewHistory("diagnosticIntegral"))
                          }
                          className={
                            active === "diagnosticIntegral"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Diagnóstico Integral</span>
                        </Button>
                      </li> */}

                      {/*<li key="18" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("folds"))}
                          className={
                            active === "folds"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Dobras</span>
                        </Button>
                      </li> */}

                      <li key="13" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("forward"))}
                          className={
                            active === "forward"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Encaminhamento</span>
                        </Button>
                      </li>

                      <li key="20" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("painScale"))}
                          className={
                            active === "painScale"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Escala de Dor</span>
                        </Button>
                      </li>

                      <li key="11" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("exams"))}
                          className={
                            active === "exams"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Exames</span>
                        </Button>
                      </li>

                      {/* <li key="23" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("indexPlate"))}
                          className={
                            active === "indexPlate"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Indice de Placas</span>
                        </Button>
                      </li> */}

                      {/* <li key="25" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() =>
                            dispatch(viewHistory("generalObjective"))
                          }
                          className={
                            active === "generalObjective"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Objetivo Geral</span>
                        </Button>
                      </li> */}
                      {/* <li key="24" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("periodotogram"))}
                          className={
                            active === "periodotogram"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Periodontograma</span>
                        </Button>
                      </li> */}

                      <li key="prc" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() =>
                            dispatch(viewHistory("prescriptionCare"))
                          }
                          className={
                            active === "prescriptionCare"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Prescrição de cuidados</span>
                        </Button>
                      </li>
                      {/* <li key="26" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("foodPlan"))}
                          className={
                            active === "foodPlan"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Plano Alimentar</span>
                        </Button>
                      </li> */}

                      <li key="12" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("prescription"))}
                          className={
                            active === "prescription"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Prescrição de medicamentos</span>
                        </Button>
                      </li>
                      <li key="12" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("manipulated"))}
                          className={
                            active === "manipulated"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Prescrição de Manipulados</span>
                        </Button>
                      </li>

                      <li key="12" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("treatment"))}
                          className={
                            active === "treatment"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Tratamentos</span>
                        </Button>
                      </li>
                      <li key="12" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("monitoring"))}
                          className={
                            active === "monitoring"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Monitoramento</span>
                        </Button>
                      </li>
                      {/* <li key="26" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("systemReview"))}
                          className={
                            active === "systemReview"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Revisão dos sistemas</span>
                        </Button>
                      </li>
                      {/* <li key="27" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() => dispatch(viewHistory("foodReminder"))}
                          className={
                            active === "foodReminder"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Recordatório Alimentar</span>
                        </Button>
                      </li> */}
                      {/* 
                      <li key="16" className="list-inline-item mr-0 w-100">
                        <Button
                          onClick={() =>
                            dispatch(viewHistory("nursePrescription"))
                          }
                          className={
                            active === "nursePrescription"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>SAE - Idoso</span>
                        </Button>
                      </li> */}

                      <li
                        key="7"
                        className="list-inline-item mr-0 w-100 p-0 m-0"
                      >
                        <Button
                          onClick={() => dispatch(viewHistory("vaccines"))}
                          className={
                            active === "vaccines"
                              ? "jr-btn-sm button-active w-100 button active"
                              : "jr-btn-sm w-100 bg-white button"
                          }
                        >
                          <span>Vacinas</span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-9">
                    {active === "personalData" && (
                      <PersonalData history={true} patient={patient.id} />
                    )}
                    {active === "allergies" && (
                      <Allergies patient={patient.id} />
                    )}

                    {active === "consult" && (
                      <ConsultHistory patient={patient.id} doctor={user} />
                    )}
                    {active === "prescription" && (
                      <Prescription patient={patient.id} doctor={user} />
                    )}
                    {active === "historyPerson" && (
                      <PersonalHistory patient={patient.id} />
                    )}
                    {active === "medicines" && (
                      <MedicinesInUse patient={patient.id} />
                    )}
                    {active === "systemReview" && (
                      <SystemReview patient={patient.id} />
                    )}
                    {active === "exams" && <Exams patient={patient.id} />}
                    {active === "vaccines" && <Vaccines patient={patient.id} />}
                    {active === "aga" && <Aga patient={patient.id} />}
                    {active === "folds" && <Folds patient={patient.id} />}
                    {active === "circumferences" && (
                      <Circumferences patient={patient.id} />
                    )}
                    {active === "disabilities" && (
                      <Disabilities patient={patient.id} />
                    )}
                    {active === "familyHistory" && (
                      <FamilyHistory patient={patient.id} />
                    )}
                    {active === "childDiseases" && (
                      <ChildDiseases patient={patient.id} />
                    )}
                    {active === "cbhpm" && (
                      <ProcedureHistory patient={patient.id} />
                    )}
                    {active === "habits" && (
                      <PersonalHabits patient={patient.id} />
                    )}
                    {active === "forward" && <Forward patient={patient.id} />}

                    {active === "nursePrescription" && (
                      <NursePrescription patient={patient.id} doctor={user} />
                    )}
                    {active === "painScale" && (
                      <PainScale patient={patient.id} />
                    )}
                    {active === "prescriptionCare" && (
                      <PrescriptionCare patient={patient.id} />
                    )}
                    {active === "foodPlan" && <FoodPlan patient={patient.id} />}
                    {active === "foodReminder" && (
                      <FoodReminder patient={patient.id} />
                    )}
                    {active === "manipulated" && (
                      <Manipulated patient={patient.id} doctor={user} />
                    )}
                    {active === "treatment" && (
                      <Treatment patient={patient.id} />
                    )}
                    {active === "monitoring" && (
                      <Monitoring patient={patient.id} />
                    )}
                    {/* {active === "diagnosticIntegral" && (
                      <DiagnosticIntegral patient={patient.id} />
                    )} */}
                    {/* {active === "periodotogram" && (
                      <Periodotogram patient={patient.id} />
                    )} */}
                    {/* {active === "indexPlate" && (
                      <IndexPlate patient={patient.id} />
                    )} */}
                    {/* {active === "generalObjective" && (
                      <GeneralObjective patient={patient.id} />
                    )}
                    )} */}
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <HistoryInnerWidth
          openModal={appointment.modal_history}
          closeModal={() => dispatch(closeHistory())}
          patient={patient}
          doctor={user}
        />
      )}
    </>
  );
}

HistoryPatient.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HistoryPatient);
