export function getPatterns(doctor, patient) {
  return {
    type: "GET_PATTERNS",
    doctor,
    patient,
  };
}

export function changePattern(pattern, patient) {
  return {
    type: "CHANGE_PATTERN",
    pattern,
    patient,
  };
}

export function changePatternPatient(pattern, patient) {
  return {
    type: "CHANGE_PATTERN_PATIENT",
    pattern,
    patient,
  };
}

export function changePatternMedicineUse(medicines, patient) {
  return {
    type: "CHANGE_MEDICINE_USE",
    medicines,
    patient,
  };
}

export function handleEditPrescription(id) {
  return {
    type: "EDIT_MP_PRESCRIPTION",
    payload: {
      id,
    },
  };
}

export function alterMode(form, is_transcription) {
  return {
    type: "ALTER_MODE",
    form,
    is_transcription,
  };
}

export function newMedicine(id_medicine, medicine) {
  return {
    type: "NEW_MEDICINE",
    id_medicine,
    medicine,
  };
}

export function addMedicine(medicine) {
  return {
    type: "ADD_MEDICINE",
    medicine,
  };
}

export function addMedicineComplex(
  name_display,
  ls_mp_medicine,
  posology,
  patient,
  is_continuos
) {
  return {
    type: "ADD_MEDICINE_COMPLEX",
    name_display,
    ls_mp_medicine,
    posology,
    patient,
    is_continuos,
  };
}

export function editMedicineComplex(
  old_medicine,
  name_display,
  ls_mp_medicine,
  posology,
  patient,
  is_continuos
) {
  return {
    type: "EDIT_MEDICINE_COMPLEX",
    old_medicine,
    name_display,
    ls_mp_medicine,
    posology,
    patient,
    is_continuos,
  };
}

export function editMedicine(medicine, edit) {
  return {
    type: "EDIT_MEDICINE",
    medicine,
    edit,
  };
}

export function cancelMedicine() {
  return {
    type: "CANCEL_MEDICINE",
  };
}

export function cancelMedicineInUse(medicine) {
  return {
    type: "CANCEL_MEDICINE_IN_USE",
    medicine,
  };
}

export function removeMedicine(medicine) {
  return {
    type: "REMOVE_MEDICINE",
    medicine,
  };
}

export function handleChange(index, value) {
  return {
    type: "HANDLE_CHANGE",
    index,
    value,
  };
}

export function savePrescription(formData) {
  return {
    type: "SAVE_PRESCRIPTION",
    formData,
  };
}

export function editPrescription(prescription) {
  return {
    type: "EDIT_PRESCRIPTION",
    prescription,
  };
}

export function removePrescription(prescription) {
  return {
    type: "REMOVE_PRESCRIPTION",
    prescription,
  };
}

export function requestGroupMedList(doctor, user_address) {
  return {
    type: "REQUEST_GROUP_MED_LIST",
    doctor,
    user_address,
  };
}

export function requestMedList(group, plusPosology, provider) {
  return {
    type: "REQUEST_MED_LIST",
    group,
    plusPosology,
    provider,
  };
}

export function setMedSelected(id_medicine, medicine) {
  return {
    type: "SET_MED_SELECTED",
    id_medicine,
    medicine,
  };
}

export function renewMedicine(id_medicine, date, medicines) {
  return {
    type: "RENEW_MEDICINE",
    id_medicine,
    date,
    medicines,
  };
}
