import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  cancelMedicine,
  addMedicine,
  editMedicine,
} from "actions/consult/Prescription";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import MaskHour from "components/Masks/MaskHour";
// import MaskDecimal from "components/Masks/MaskDecimal";
import MaskFloat from "components/Masks/MaskFloat";
import AutocompleteMedicine from "./AutocompleteMedicine";
// import MultipleMedicine from "./MultipleMedicine";
import MultipleMedicineFarm from "components/Autocomplete/MultipleMedicineFarm";
import moment from "moment";
import Api from "services/api";
import MedicinesDefault from "./MedicinesDefault";
import MedicinesDefaultPrefecture from "./MedicinesDefaultPrefecture";
import SweetAlert from "react-bootstrap-sweetalert";
import { ModalResponsive } from "components/ModalResponsive";
import {
  TextField,
  Button,
  MenuItem,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import { getFormatOnlyDate } from "services/functions";

class FormMedicine extends Component {
  state = {
    id: "",
    ls_mp_medicine: "",
    dosage: "",
    duration: "",
    form_pharm: "",
    interval: "Diário",
    name_display: "",
    orientation: "",
    pkg: "",
    tot_emb: 1,
    price: "",
    quantity: "",
    times_a_day: "",
    unity_measure: "",
    use: "",
    principle_active: "",
    class_farm: "",
    bula: "",
    date_start: new Date(),
    medicines_ids: [],
    all_packages: [],
    all_prices: [],
    all_qtd_comp: [],
    hours: [""],
    when: [""],
    calculate_hours: false,
    interval_divisor: 1,
    qtd_comprimido: "",
    qtd_measure: "",
    all_mode_use: [],
    in_use: false,
    data_use: {},
    error: false,
    medicine_is_allergic: false,
    is_necessary: false,
    is_continuos: false,
    listContinuos: false,
    medicinesContinuos: [],
    min_date: false,
    type_recipe: "SE",
    days_week_modal: false,
    days_week_list: [],
    value_farm_med: [],
    stock: {
      id_goods: "",
      id_goods_has_point: "",
      id_stock_goods: "",
    },
    alert_medicine_interaction: false,
    alert_medicine_interaction_type: "",
    hideAutocomplete: false,
  };

  constructor(props) {
    super(props);
    this.quantityInput = React.createRef();
  }

  componentWillMount() {
    const { selected_medicine, medicine_atual } = this.props;

    if (medicine_atual !== undefined) {
      if (medicine_atual.posology !== undefined) {
        this.props.modalPlusPosology();
        this.props.cancelMedicine();
      } else {
        if (selected_medicine !== "") {
          this.setState({ ...medicine_atual });
        }

        if (medicine_atual !== undefined) {
          if (
            medicine_atual.days_week !== undefined &&
            medicine_atual.days_week !== "" &&
            medicine_atual.days_week !== null
          ) {
            let aux_days = medicine_atual.days_week.split(", ");
            this.setState({
              days_week_list: aux_days,
              interval_divisor: 0,
            });
          }
        }
      }
      if (this.props.type_provider === "5") {
        this.setState({
          is_continuos: true,
        });
        this.getMedicinesContinuos();
      }
    }
  }

  handleChangeCheck() {
    if (!this.state.is_necessary) {
      this.setState({
        is_necessary: true,
      });
    } else {
      this.setState({
        is_necessary: false,
      });
    }
  }

  handleChangeContinuos() {
    if (!this.state.is_continuos) {
      this.setState({
        is_continuos: true,
      });
      this.getMedicinesContinuos();
    } else {
      this.setState({
        is_continuos: false,
      });
    }
  }

  getMedicinesContinuos() {
    Api.post(`/microservice/prescription/getMedicinesContinuos/`, {
      patient: this.props.patient,
    }).then(({ data }) => {
      if (data.list.length > 0) {
        this.setState({ medicinesContinuos: data.list, listContinuos: true });
      }
    });
  }

  async componentWillReceiveProps(nextProps) {
    const { medicine_atual } = nextProps;
    if (this.props.medicine_atual !== nextProps.medicine_atual) {
      let all_mode_use = medicine_atual.mode_all.split("/");
      let use = "";
      if (all_mode_use.length === 1) {
        use = medicine_atual.mode_all;
      }
      this.getComplementInfo(
        medicine_atual.name_display,
        medicine_atual.form,
        medicine_atual.dosage,
        medicine_atual.laboratory_display
      );

      if (medicine_atual.posology_advanced !== "1") {
        await this.setState({
          ls_mp_medicine: medicine_atual.id,
          name_display:
            medicine_atual.name_display + " " + medicine_atual.formulation,
          dosage: medicine_atual.dosage,
          use,
          all_mode_use,
          form_pharm: medicine_atual.form,
          unity_measure: medicine_atual.unit_measure,
          qtd_comprimido: medicine_atual.qtd_comprimido,
          principle_active: medicine_atual.active_principle,
          class_farm: medicine_atual.class_terapeutica,
          bula: medicine_atual.drug_leaflet,
          date_start: new Date(`${medicine_atual.date_start}T00:00:00`),
        });
        this.quantityInput.current.children[1].children[0].focus();
      }
    }
  }

  componentWillUnmount() {
    this.setState(this.state);
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleChangeInterval = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    if (event.target.value === "Dias específicos") {
      this.setState({ days_week_modal: true });
    }
  };

  handleChangeQuantity = (name) => (event) => {
    this.setState({ [name]: event });
  };

  handleChangeHour = (index) => (event) => {
    let hours = [];

    this.state.hours.forEach((row, key) => {
      if (key === index) {
        hours.push(event.target.value);
      } else {
        hours.push(row);
      }
    });

    this.setState({ hours: [...hours] });
  };

  handleChangeWhen = (index, value) => {
    let when = [];
    this.state.when.forEach((row, key) => {
      if (key === index) {
        when.push(value);
      } else {
        when.push(!row && value === "Horário específico" ? value : row);
      }
    });
    this.setState({ when: [...when] });
  };

  handleChangeDate = (name, value) => {
    this.setState({ [name]: value });
  };

  getComplementInfo = (name, form, dosage, laboratory) => {
    Api.post(`/microservice/prescription/getComplementMedicine/`, {
      name,
      form,
      dosage,
      laboratory,
    }).then((res) => {
      let ids = [];
      let pkgs = [];
      let comps = [];
      let prices = [];
      res.data.record.forEach((row) => {
        ids.push(row.id);
        pkgs.push(row.package);
        comps.push(row.qtd_comprimido);
        prices.push(row.price);
      });
      this.setState({
        medicines_ids: ids,
        all_packages: pkgs,
        all_prices: prices,
        all_qtd_comp: comps,
      });
    });
  };

  handleChangeAutocomplete = async (obj) => {
    if (obj) {
      let all_mode_use = obj.mode_all.split("/");
      let use = "";
      if (all_mode_use.length === 1) {
        use = obj.mode_all;
      }

      this.validMedicine(obj.id);

      // this.isAllergicMedicine(obj.id);

      this.getComplementInfo(
        obj.name_display,
        obj.form,
        obj.dosage,
        obj.laboratory_display
      );
      await this.setState({
        ls_mp_medicine: obj.id,
        name_display: `${obj.active_principle} (${obj.name_display}) ${obj.formulation} ${obj.form}`,
        dosage: obj.dosage,
        use,
        all_mode_use,
        form_pharm: obj.form,
        unity_measure: obj.unit_measure,
        qtd_comprimido: obj.qtd_comprimido,
        qtd_measure: obj.qtd_measure,
        principle_active: obj.active_principle,
        class_farm: obj.class_terapeutica,
        bula: obj.drug_leaflet,
        type_recipe: obj.type_recipe,
      });

      this.quantityInput.current.children[1].children[0].focus();
    }
  };

  handleChangeAutocompleteFarm = async (value) => {
    if (value) {
      // this.setState({ value_farm_med: value });
      let obj = value;

      if (obj) {
        let all_mode_use = obj.mode_all.split("/");
        let use = "";
        if (all_mode_use.length === 1) {
          use = obj.mode_all;
        }

        this.validMedicine(obj.id);

        if (!this.props.medicineUse) {
          this.validMedicineInteraction(obj);
        }

        // this.isAllergicMedicine(obj.id);

        /* this.getComplementInfo(
          obj.name_display,
          obj.form,
          obj.dosage,
          obj.laboratory_display
        ); */
        await this.setState({
          ls_mp_medicine: obj.id,
          name_display: `${obj.active_principle} (${obj.name_display}) ${obj.formulation} ${obj.form}`,
          dosage: obj.dosage,
          use,
          all_mode_use,
          form_pharm: obj.form,
          unity_measure: obj.unit_measure,
          qtd_comprimido: obj.qtd_comprimido,
          qtd_measure: obj.qtd_measure,
          principle_active: obj.active_principle,
          class_farm: obj.class_terapeutica,
          bula: obj.drug_leaflet,
          type_recipe: obj.type_recipe,
          id_goods: obj.id_goods,
          stock: {
            id_goods: obj.id_goods,
            id_goods_has_point: obj.id_goods_has_point,
            id_stock_goods: obj.id_stock_goods,
          },
          all_packages: [obj.package],
          all_qtd_comp: [obj.qtd_comprimido],
          pkg: obj.package,
        });

        this.quantityInput.current.children[1].children[0].focus();
      }
    }
  };

  validMedicine = (med) => {
    //método que verifica se o paciente já utiliza um medicamento com o mesmo princípio ativo
    Api.post(`/microservice/prescription/validMedicine/`, {
      medicine: med,
      patient: this.props.patient,
    }).then(({ data }) => {
      if (data.record.length > 0) {
        this.setState({
          in_use: true,
          data_use: data.record[0],
          date_start: new Date(`${data.record[0].use_end}T00:00:00`),
          min_date: new Date(`${data.record[0].use_end}T00:00:00`),
        });
      }
    });
  };

  validMedicineInteraction = (med) => {
    let lowInteraction = [
      "anfotericina b",
      "bupropiona",
      "carbamazepina",
      "ciclosporina",
      "ciprofloxacino",
      "desogestrel + etinilestradiol",
      "diltiazem",
      "fenitoína",
      "fenobarbital",
      "fluconazol",
      "furosemida",
      "gestodeno + estradiol",
      "glibenclamida",
      "glimepirida",
      "hidroclorotiazida",
      "hidroclorotiazida + amilorida",
      "irinotecano",
      "levofloxacino",
      "metformina",
      "metotrexato",
      "miconazol",
      "moxifloxacino",
      "noretisterona + estradiol",
      "norfloxacino",
      "ofloxacino",
      "paclitaxel",
      "rifampicina",
      "varfarina",
      "verapamil",
    ];

    const interaction = [
      "cetoconazol",
      "claritromicina",
      "hidróxido de alumínio",
      "itraconazol",
      "rocurônio",
    ];

    let has = false;

    let active_p = med.active_principle.split(",");
    active_p = active_p[0];
    if (lowInteraction.indexOf(active_p.toLowerCase()) !== -1) {
      has = "0";
    }
    if (interaction.indexOf(active_p.toLowerCase()) !== -1) {
      has = "1";
    }

    if (has !== false) {
      //método que verifica se o paciente já utiliza um medicamento com o mesmo princípio ativo
      Api.post(`/microservice/prescription/validMedicineInteractionTest/`, {
        patient: this.props.patient,
      }).then(({ data }) => {
        if (data.record.length > 0) {
          this.setState({
            alert_medicine_interaction: true,
            alert_medicine_interaction_type: has,
          });
        }
      });
    }
  };

  isAllergicMedicine = (med) => {
    //método que verifica se o paciente possui alergia ao medicamento
    Api.post(`/microservice/prescription/isAllergicMedicine/`, {
      medicine: med,
      patient: this.props.patient,
    }).then(({ data }) => {
      if (data.isAllergic) {
        this.setState({ medicine_is_allergic: true });
      }
    });
  };

  stopUseMedicine = () => {
    Api.post(`/microservice/consult/stopUseMedicine/`, {
      medicine: this.state.data_use.id,
      date: moment().format("YYYY-MM-DD"),
      user: this.props.user,
    }).then(() => {
      this.props.updateMedicine();
      this.setState({
        in_use: false,
        date_start: new Date(),
        min_date: new Date(),
      });
    });
  };

  renewMedicine = () => {
    this.setState({ in_use: false });
  };

  calcHour = () => (event) => {
    if (event.target.value) {
      if (!this.state.calculate_hours) {
        let times_day = 24 / this.state.times_a_day;
        let init = "2019-01-01 " + event.target.value + ":00";
        let arr = [event.target.value];
        for (let index = 1; index < this.state.times_a_day; index++) {
          arr[index] = moment(init)
            .add(times_day * index, "hours")
            .format("HH:mm");
        }
        this.setState({ hours: [...arr], calculate_hours: true });
      }
    } else {
      this.setState({ hours: [""], calculate_hours: false });
    }
  };

  changeInterval = (value) => {
    switch (value) {
      case "Semanal":
        this.setState({ interval_divisor: 7 });
        break;
      case "2x por semana":
        this.setState({ interval_divisor: 3.75 });
        break;
      case "3x por semana":
        this.setState({ interval_divisor: 2.33 });
        break;
      case "15 em 15 dias":
        this.setState({ interval_divisor: 15 });
        break;
      case "Mensal":
        this.setState({ interval_divisor: 30 });
        break;
      case "Bimestral":
        this.setState({ interval_divisor: 60 });
        break;
      case "Trimestral":
        this.setState({ interval_divisor: 90 });
        break;
      case "Semestral":
        this.setState({ interval_divisor: 182.5 });
        break;
      case "Anual":
        this.setState({ interval_divisor: 365 });
        break;
      case "Dia sim, dia não":
        this.setState({ interval_divisor: 2 });
        break;
      case "Dias específicos":
        this.setState({ interval_divisor: 0 });
        break;
      default:
        this.setState({ interval_divisor: 1 });
        break;
    }
  };

  validTimesADay = (value) => {
    const { calculate_hours, hours } = this.state;
    if (hours[0] !== "" && hours.length > 0) {
      if (calculate_hours && hours.length !== value) {
        let times_day = 24 / value;
        let init = "2019-01-01 " + hours[0] + ":00";
        let arr = [hours[0]];
        for (let index = 1; index < value; index++) {
          arr[index] = moment(init)
            .add(times_day * index, "hours")
            .format("HH:mm");
        }
        this.setState({ hours: [...arr] });
      }
    } else if (value) {
      let arr = [];
      for (let i = 0; i < value; i++) {
        arr.push("");
      }
      this.setState({ hours: arr, when: arr });
    }
    this.calcPackages();
  };

  setDayWeek = (day) => {
    if (this.state.days_week_list.find((e) => e === day) !== undefined) {
      this.setState({
        days_week_list: this.state.days_week_list.filter((e) => e !== day),
      });
    } else {
      this.setState({
        days_week_list: [...this.state.days_week_list, day],
      });
    }
  };

  addMedicine = () => {
    const {
      ls_mp_medicine,
      duration,
      interval,
      name_display,
      pkg,
      price,
      quantity,
      times_a_day,
      unity_measure,
      use,
      hours,
      is_necessary,
      date_start,
    } = this.state;

    if (
      (ls_mp_medicine !== "" &&
        duration !== "" &&
        interval !== "" &&
        name_display !== "" &&
        // pkg !== "" &&
        // price !== "" &&
        quantity !== "" &&
        times_a_day !== "" &&
        unity_measure !== "" &&
        use !== "" &&
        hours[0] !== "" &&
        date_start !== "" &&
        hours.length !== 0) ||
      (is_necessary &&
        ls_mp_medicine !== "" &&
        name_display !== "" &&
        quantity !== "" &&
        duration !== "" &&
        date_start !== "" &&
        unity_measure !== "")
    ) {
      if (this.props.selected_medicine) {
        this.props.editMedicine(
          {
            ...this.state,
            date_start: getFormatOnlyDate(date_start),
            complete: true,
          },
          this.props.selected_medicine
        );
      } else {
        this.props.addMedicine({
          ...this.state,
          date_start: getFormatOnlyDate(date_start),
          complete: true,
        });
      }
    } else {
      this.setState({ error: true });
    }
  };

  calcPackages = () => {
    if (!this.state.is_necessary) {
      let {
        quantity,
        times_a_day,
        duration,
        interval_divisor,
        medicines_ids,
        all_packages,
        all_prices,
        all_qtd_comp,
      } = this.state;

      if (quantity && times_a_day && duration && interval_divisor) {
        const tot_qtd = Math.ceil(
          (parseFloat(quantity) * times_a_day * duration) / interval_divisor
        );

        let index = 0;
        let number_pkg = Math.ceil(tot_qtd / all_qtd_comp[0]);
        // let min_price = all_prices[0] * Math.ceil(tot_qtd / all_qtd_comp[0]);
        /* for (let i = 0; i < all_packages.length; i++) {
          let pkgs = Math.ceil(tot_qtd / all_qtd_comp[i]);
          let this_price = all_prices[i] * pkgs;
          if (min_price > this_price) {
            number_pkg = pkgs;
            index = i;
            min_price = this_price;
          }
        } */

        this.setState({
          // ls_mp_medicine: medicines_ids[index],
          tot_emb: number_pkg,
          pkg: all_packages[index],
          // price: all_prices[index],
        });
      }
    }
  };

  getLimitDuration = () => {
    let time = 0;
    switch (this.state.type_recipe) {
      case "SE":
        time = "unset";
        break;
      case "A1":
        time = 30;
        break;
      case "A2":
        time = 30;
        break;
      case "A3":
        time = 30;
        break;
      case "B1":
        time = 60;
        break;
      case "B2":
        time = 30;
        break;
      case "C1":
        time = 60;
        break;
      case "C2":
        time = 60;
        break;
      case "C5":
        time = 60;
        break;
      case "D1":
        time = "unset";
        break;
      default:
        time = "unset";
        break;
    }

    return time;
  };

  render() {
    const {
      ls_mp_medicine,
      duration,
      interval,
      name_display,
      orientation,
      pkg,
      price,
      quantity,
      times_a_day,
      unity_measure,
      use,
      hours,
      interval_divisor,
      error,
      principle_active,
      bula,
      class_farm,
      all_mode_use,
      all_packages,
      tot_emb,
      data_use,
      in_use,
      is_necessary,
      is_continuos,
      date_start,
      min_date,
      days_week_list,
      value_farm_med,
      hideAutocomplete,
      qtd_measure,
    } = this.state;

    let tot_qtd = 0;
    if (interval_divisor !== 0) {
      tot_qtd = Math.ceil(
        (parseFloat(quantity) * times_a_day * duration) / interval_divisor
      );
    } else {
      tot_qtd = Math.ceil(
        (parseFloat(quantity) * times_a_day * duration) / days_week_list.length
      );
    }

    console.log("qtd_measure", qtd_measure);

    return (
      <>
        <div className="row">
          <div className="col-md-12 col-12">
            <h2 className="text-primary font-weight-semibold">
              Adicionar medicamento
            </h2>
          </div>
        </div>
        {/* {!this.props.transcription && <MedicinesDefault />} */}
        {this.props.type_provider === "56" ? (
          <MedicinesDefaultPrefecture
            onChange={this.handleChangeAutocompleteFarm.bind(this)}
            hideAutocomplete={() =>
              this.setState({ hideAutocomplete: !this.state.hideAutocomplete })
            }
          />
        ) : (
          <MedicinesDefault />
        )}
        <div className="row">
          {!hideAutocomplete && (
            <div className="col-md-12">
              {this.props.type_provider === "56" ? (
                <MultipleMedicineFarm
                  onChange={this.handleChangeAutocompleteFarm.bind(this)}
                  value={
                    ls_mp_medicine
                      ? {
                          id: ls_mp_medicine,
                          name_display,
                        }
                      : null
                  }
                />
              ) : (
                <AutocompleteMedicine
                  handleChangeAutocomplete={this.handleChangeAutocomplete.bind(
                    this
                  )}
                  error={error && !ls_mp_medicine}
                  medicine={{
                    id: ls_mp_medicine,
                    name: name_display,
                  }}
                  medicineUse={this.props.medicineUse}
                  user={this.props.user}
                />
              )}
            </div>
          )}

          {/* 
          <div
            className="col-md-1"
            style={{
              fontWeight: "bold",
              marginTop: "40px",
              textAlign: "center",
            }}
          >
            <span>OU</span>
          </div>
          <div className="col-md-2">
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm mb-3"
              style={{
                float: this.props.medicineUse ? "right" : "left",
                marginTop: "33px",
              }}
              onClick={() => {
                this.props.modalPlusPosology();
                this.props.cancelMedicine();
              }}
            >
              <i className="zmdi zmdi-plus zmdi-hc-lg" />
              <span> Posologia Avançada </span>
            </Button>
          </div> */}
          {ls_mp_medicine ? (
            <React.Fragment>
              {/* <div className="col-md-12">
                <strong>Bula: </strong>{" "}
                <a href={bula} target="_blank" rel="noopener noreferrer">
                  <span> Visualizar </span>
                  <i className="zmdi zmdi-eye zmdi-hc-lg" />
                </a>
              </div> */}
              {/* <div className="col-md-12">
                <span>
                  <strong>Princípio Ativo: </strong> {principle_active}
                </span>
              </div> */}
              <div className="col-md-12">
                <span>
                  <strong>Classe Terapêutica: </strong> {class_farm}
                </span>
              </div>
              <div className="col-md-4 pl-0">
                <Checkbox
                  checked={is_necessary === true || is_necessary === "1"}
                  onChange={() => this.handleChangeCheck()}
                  color="primary"
                />
                {"Tomar medicamento apenas se necessário"}
              </div>
              <div className="col-md-8">
                <Checkbox
                  checked={is_continuos === true || is_continuos === "1"}
                  onChange={() => this.handleChangeContinuos()}
                  color="primary"
                />
                {"Uso contínuo"}
              </div>
              <div className="col-md-2">
                <TextField
                  id="va"
                  label="Via Adm.:"
                  value={use}
                  onChange={this.handleChange("use")}
                  fullWidth
                  margin="normal"
                  select
                  SelectProps={{}}
                  style={{ marginTop: "17px" }}
                  error={error && !use}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {all_mode_use.map((row, index) => (
                    <MenuItem key={index} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-2">
                <TextField
                  id="unit_measure"
                  label="Unidade de Medida:"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={unity_measure}
                  onChange={this.handleChange("unity_measure")}
                  fullWidth
                  margin="normal"
                  error={error && !unity_measure}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-md-8"></div>

              <div className="col-md-2" style={{ paddingTop: "16px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                  <KeyboardDatePicker
                    label="Data de início:"
                    value={date_start}
                    onChange={(e) => this.handleChangeDate("date_start", e)}
                    minDate={min_date}
                    format="dd/MM/yyyy"
                    fullWidth
                    // disabled
                  />
                </MuiPickersUtilsProvider>
              </div>

              {/* {unity_measure !== "com" ? ( */}
              {qtd_measure == "unid" ? (
                <div className="col-md-2 d-flex">
                  <TextField
                    type="number"
                    label="Quantidade:"
                    margin="normal"
                    fullWidth
                    value={quantity}
                    onChange={this.handleChange("quantity")}
                    onBlur={() => this.calcPackages()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ min: 0 }}
                    error={error && !quantity}
                    ref={this.quantityInput}
                  />

                  <Tooltip
                    title={
                      <span style={{ fontSize: "16px" }}>
                        1/2 = 0,50 <br />
                        1/3 = 0,33 <br />
                        1/4 = 0,25 <br />
                        1/5 = 0,20 <br />
                      </span>
                    }
                    placement="top"
                  >
                    <i className="zmdi zmdi-info zmdi-fw mt-3" />
                  </Tooltip>
                </div>
              ) : (
                <div className="col-md-2 d-flex">
                  <TextField
                    label="Quantidade:"
                    margin="normal"
                    fullWidth
                    value={quantity}
                    onChange={this.handleChange("quantity")}
                    onBlur={() => this.calcPackages()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      onInput: (e) => {
                        if (unity_measure === "gotas") {
                          e.target.value = e.target.value
                            ? e.target.value.replace(/[^0-9]/gi, "")
                            : 0;
                        } else {
                          let aux = e.target.value
                            ? e.target.value.replace(/[^0-9]/gi, "")
                            : 0;
                          let leng = aux.length;
                          let aux2 =
                            aux.substring(0, leng - 2) +
                            "." +
                            aux.substring(leng - 2, leng);
                          e.target.value = parseFloat(aux2)
                            .toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })
                            .substr(3);
                        }
                      },
                    }}
                    error={error && !quantity}
                    ref={this.quantityInput}
                  />
                  <Tooltip
                    title={
                      <span style={{ fontSize: "16px" }}>
                        1/2 = 0,50 <br />
                        1/3 = 0,33 <br />
                        1/4 = 0,25 <br />
                        1/5 = 0,20 <br />
                      </span>
                    }
                    placement="top"
                  >
                    <i className="zmdi zmdi-info zmdi-fw mt-3" />
                  </Tooltip>
                </div>
                // <div className="col-md-2">
                //   <TextField
                //     id="qtd"
                //     label="Quantidade:"
                //     value={quantity}
                //     onChange={this.handleChange("quantity")}
                //     onBlur={() => this.calcPackages()}
                //     fullWidth
                //     margin="normal"
                //     select
                //     SelectProps={{}}
                //     error={error && !quantity}
                //     InputLabelProps={{
                //       shrink: true,
                //     }}
                //     ref={this.quantityInput}
                //   >
                //     <MenuItem key={1} value={"0.25"}>
                //       {"1/4"}
                //     </MenuItem>
                //     <MenuItem key={2} value={"0.50"}>
                //       {"1/2"}
                //     </MenuItem>
                //     <MenuItem key={13} value={"0.66"}>
                //       {"2/3"}
                //     </MenuItem>
                //     <MenuItem key={3} value={"0.75"}>
                //       {"3/4"}
                //     </MenuItem>
                //     <MenuItem key={4} value={"1.00"}>
                //       {"1"}
                //     </MenuItem>
                //     <MenuItem key={5} value={"1.25"}>
                //       {"1 e 1/4"}
                //     </MenuItem>
                //     <MenuItem key={6} value={"1.50"}>
                //       {"1 e 1/2"}
                //     </MenuItem>
                //     <MenuItem key={7} value={"1.75"}>
                //       {"1 e 3/4"}
                //     </MenuItem>
                //     <MenuItem key={8} value={"2.00"}>
                //       {"2"}
                //     </MenuItem>
                //     <MenuItem key={9} value={"2.25"}>
                //       {"2 e 1/4"}
                //     </MenuItem>
                //     <MenuItem key={10} value={"2.50"}>
                //       {"2 e 1/2"}
                //     </MenuItem>
                //     <MenuItem key={11} value={"2.75"}>
                //       {"2 e 3/4"}
                //     </MenuItem>
                //     <MenuItem key={12} value={"3.00"}>
                //       {"3"}
                //     </MenuItem>
                //   </TextField>
                // </div>
              )}

              {!(is_necessary === true || is_necessary === "1") && (
                <>
                  <div className="col-md-2">
                    <TextField
                      id="xdia"
                      type="number"
                      label="x/dia:"
                      value={times_a_day}
                      onChange={this.handleChange("times_a_day")}
                      onBlur={(e) => this.validTimesADay(e.target.value)}
                      fullWidth
                      margin="normal"
                      error={error && !times_a_day}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="col-md-2">
                    <TextField
                      id="Intervalo"
                      select
                      label="Intervalo:"
                      value={interval}
                      onChange={this.handleChangeInterval("interval")}
                      onBlur={(e) => {
                        this.changeInterval(e.target.value);
                        setTimeout(() => this.calcPackages(), 100);
                      }}
                      SelectProps={{}}
                      fullWidth
                      style={{ marginTop: "17px" }}
                      error={error && !interval}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem key="1" value="Diário">
                        Diário
                      </MenuItem>
                      <MenuItem key="12" value="Dia sim, dia não">
                        Dia sim, dia não
                      </MenuItem>
                      <MenuItem key="2" value="2x por semana">
                        2x por semana
                      </MenuItem>
                      <MenuItem key="3" value="3x por semana">
                        3x por semana
                      </MenuItem>
                      <MenuItem key="4" value="Semanal">
                        Semanal
                      </MenuItem>
                      <MenuItem key="5" value="15 em 15 dias">
                        De 15 em 15 dias
                      </MenuItem>
                      <MenuItem key="6" value="Mensal">
                        Mensal
                      </MenuItem>
                      <MenuItem key="7" value="Bimestral">
                        Bimestral
                      </MenuItem>
                      <MenuItem key="11" value="Trimestral">
                        Trimestral
                      </MenuItem>
                      <MenuItem key="10" value="Semestral">
                        Semestral
                      </MenuItem>
                      <MenuItem key="8" value="Anual">
                        Anual
                      </MenuItem>
                      <MenuItem key="9" value="Sempre">
                        Sempre
                      </MenuItem>
                      <MenuItem key="13" value="Dias específicos">
                        Dias específicos
                      </MenuItem>
                    </TextField>
                  </div>
                </>
              )}

              <div className="col-md-2">
                <TextField
                  id="tempo"
                  type="number"
                  inputProps={{ min: "1", max: this.getLimitDuration() }}
                  label="Tempo de uso (dias):"
                  value={duration}
                  onChange={this.handleChange("duration")}
                  onBlur={() => {
                    if (duration > this.getLimitDuration()) {
                      this.setState({ duration: this.getLimitDuration() });
                    }
                    this.calcPackages();
                  }}
                  fullWidth
                  margin="normal"
                  error={error && !duration}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              {!(is_necessary === true || is_necessary === "1") && (
                <>
                  <div className="col-md-2">
                    <TextField
                      id="tot_qtd"
                      type="number"
                      label="Qtd Total:"
                      value={tot_qtd}
                      onChange={this.handleChange("total_quantity")}
                      fullWidth
                      margin="normal"
                      error={error && !tot_qtd}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="col-12">
                    {hours.map((row, index) => (
                      <div key={index} className="row">
                        <div className="col-md-3">
                          <TextField
                            id="when"
                            select
                            label="Quando:"
                            value={this.state.when[index]}
                            onChange={(e) => {
                              this.handleChangeWhen(index, e.target.value);
                            }}
                            SelectProps={{}}
                            fullWidth
                            style={{ marginTop: "17px" }}
                            // error={error && !interval}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            // error={error && !det.when}
                          >
                            <MenuItem key="1" value="De manhã">
                              De manhã
                            </MenuItem>
                            <MenuItem key="2" value="De tarde">
                              De tarde
                            </MenuItem>
                            <MenuItem key="3" value="De Noite">
                              De Noite
                            </MenuItem>
                            <MenuItem key="4" value="Antes do café">
                              Antes do café
                            </MenuItem>
                            <MenuItem key="5" value="Depois do café">
                              Depois do café
                            </MenuItem>
                            <MenuItem key="6" value="Antes do almoço">
                              Antes do almoço
                            </MenuItem>
                            <MenuItem key="7" value="Depois do almoço">
                              Depois do almoço
                            </MenuItem>
                            <MenuItem key="8" value="Antes do jantar">
                              Antes do jantar
                            </MenuItem>
                            <MenuItem key="9" value="Depois do jantar">
                              Depois do jantar
                            </MenuItem>
                            <MenuItem key="10" value="Horário específico">
                              Horário específico
                            </MenuItem>
                            <MenuItem key="11" value="Outro">
                              Outro
                            </MenuItem>
                          </TextField>
                        </div>

                        <div key={index} className="col-md-2">
                          <TextField
                            id={`hr_ini_${index}`}
                            label={
                              !this.state.when[index] ||
                              this.state.when[index] ===
                                "Horário específico" ? (
                                "Horário:"
                              ) : (
                                <Tooltip title="Hora em que o sistema emitirá um alerta para a administração do médicamento">
                                  <span style={{ display: "inline-flex" }}>
                                    Hr. notificação:{" "}
                                    <i className="zmdi zmdi-info zmdi-fw" />
                                  </span>
                                </Tooltip>
                              )
                            }
                            margin="normal"
                            fullWidth
                            InputProps={{
                              inputComponent: MaskHour,
                              value: row,
                              onChange: this.handleChangeHour(index),
                              onBlur: this.calcHour(index),
                            }}
                            error={error && !row}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {/* Preços e embalagens comentados */}
              {true && (
                <>
                  <div className="col-md-2">
                    <TextField
                      id="embalagem"
                      label="Embalagem:"
                      value={pkg}
                      onChange={this.handleChange("pkg")}
                      fullWidth
                      margin="normal"
                      select
                      SelectProps={{}}
                      style={{ marginTop: "17px" }}
                      error={error && !pkg}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {/* <MenuItem value={pkg}>
                        {
                          all_packages.filter((row) => {
                            return row === pkg;
                          })[0]
                        }
                      </MenuItem> */}
                      {all_packages.map((row) => (
                        <MenuItem key={row} value={row}>
                          {row}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  {/*  <div className="col-md-2">
                    <TextField
                      id="price"
                      type="number"
                      label="Preço emb.:"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                      value={price}
                      onChange={this.handleChange("price")}
                      style={{ marginTop: "17px" }}
                      error={error && !price}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                //       {all_prices.map((row, index) => (
                //   <MenuItem key={index} value={row}>
                //     {row}
                //   </MenuItem>
                // ))}
                    </TextField>
                  </div> */}
                  <div className="col-md-2">
                    <TextField
                      id="embalagem"
                      type="number"
                      label="Qtd. emb.:"
                      value={tot_emb > 0 ? tot_emb : 1}
                      onChange={this.handleChange("tot_emb")}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  {/* <div className="col-md-2">
                    <TextField
                      id="price"
                      type="number"
                      label="Preço total:"
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                      value={(price * tot_emb).toFixed(2)}
                      onChange={this.handleChange("total_price")}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div> */}
                </>
              )}
              <div className="col-md-12">
                <TextField
                  id="orientation1_medicine"
                  label="Orientação para o medicamento:"
                  multiline={true}
                  rows={2}
                  value={orientation === "0" ? "" : orientation}
                  onChange={this.handleChange("orientation")}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
          <div className="col-md-12" style={{ marginTop: "30px" }}>
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => this.props.cancelMedicine()}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right" }}
              onClick={() => this.addMedicine()}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Salvar </span>
            </Button>
          </div>

          {/* Medicamento já em uso pelo paciente */}
          <Dialog open={in_use} onClose={false} fullWidth={true} maxWidth="md">
            <DialogTitle className="text-primary font-weight-semibold">
              Atenção
            </DialogTitle>
            <DialogContent style={{ paddingTop: "0px" }}>
              <div className="row">
                <div className="col-12">
                  <span style={{ fontWeight: "500" }}>
                    Medicamento já prescrito para o paciente.
                  </span>
                  <br />
                </div>
              </div>
              <div
                className="row"
                // style={{ overflow: "scroll", height: "500px" }}
              >
                <div className="col-12">
                  <span style={{ fontWeight: "300" }}>
                    {name_display} <br /> Período: {data_use.use_start} -{" "}
                    {data_use.use_end}
                  </span>
                </div>
                <div className="col-12">
                  <div style={{ fontWeight: "300", paddingTop: "10px" }}>
                    <span>Renovar uso do medicamento a partir do dia:</span>
                  </div>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={brLocale}
                  >
                    <KeyboardDatePicker
                      // label="Data de início:"
                      value={date_start}
                      onChange={(e) => this.handleChangeDate("date_start", e)}
                      minDate={min_date}
                      format="dd/MM/yyyy"
                      // fullWidth
                      // disabled
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="col-4 mt-3">
                  <Button
                    // color="success"
                    variant="contained"
                    className="jr-btn jr-btn-sm bg-success"
                    style={{ color: "#fff" }}
                    fullWidth
                    onClick={() => {
                      this.renewMedicine();
                    }}
                  >
                    <span> Renovar </span>
                  </Button>
                </div>
                <div className="col-4 mt-3">
                  <Button
                    color="primary"
                    variant="contained"
                    className="jr-btn jr-btn-sm"
                    fullWidth
                    onClick={() => this.stopUseMedicine()}
                  >
                    <span> Interromper o uso e Continuar </span>
                  </Button>
                </div>
                <div className="col-4 mt-3">
                  <Button
                    color="secondary"
                    variant="contained"
                    className="jr-btn jr-btn-sm"
                    fullWidth
                    onClick={() => {
                      this.props.cancelMedicine();
                    }}
                  >
                    <span> Cancelar Inserção</span>
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          {/* Medicamento que não podem ser prescritos por causa de alergia a medicamentos */}
          <SweetAlert
            show={this.state.medicine_is_allergic}
            // show={false}
            warning
            showConfirm={false}
            title=""
            onConfirm={() => {}}
          >
            <div className="row">
              <div className="col-12">
                <span style={{ fontWeight: "500" }}>
                  O paciente tem alergia a este medicamento.
                </span>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-3">
                <Button
                  color="secondary"
                  variant="contained"
                  className="jr-btn jr-btn-sm"
                  style={{ width: "50%", float: "right" }}
                  fullWidth
                  onClick={() => this.props.cancelMedicine()}
                >
                  <span> Cancelar inserção de medicamento </span>
                </Button>
              </div>
            </div>
          </SweetAlert>
        </div>

        {/* Interação medicamento de teste */}
        <SweetAlert
          show={this.state.alert_medicine_interaction}
          // show={false}
          warning
          showConfirm={false}
          title=""
          onConfirm={() => {}}
        >
          <div className="row">
            <div className="col-12">
              <span style={{ fontWeight: "500" }}>
                Interação medicamentosa{" "}
                {this.state.alert_medicine_interaction_type === "0"
                  ? "- Risco a ser avaliado"
                  : " de Risco"}
              </span>
              <br />
              <span style={{ fontWeight: "300" }}>
                Este medicamento possui interação medicamentosa com o princípio
                ativo (Dexametasona) em uso pelo paciente.
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <Button
                color="secondary"
                variant="contained"
                className="jr-btn jr-btn-sm"
                style={{ width: "40%", float: "right" }}
                fullWidth
                onClick={() => this.props.cancelMedicine()}
              >
                <span> Cancelar inserção </span>
              </Button>

              <Button
                color="default"
                variant="contained"
                className="jr-btn jr-btn-sm"
                style={{ width: "40%", float: "left" }}
                fullWidth
                onClick={() =>
                  this.setState({ alert_medicine_interaction: false })
                }
              >
                <span> Continuar mesmo assim </span>
              </Button>
            </div>
          </div>
        </SweetAlert>

        <ModalResponsive
          title="Medicamentos em uso contínuo"
          open={this.state.listContinuos}
          close={() => {
            this.setState({
              listContinuos: false,
            });
          }}
          buttons={
            <div className="col-12">
              <Button
                color="primary"
                variant="contained"
                className="jr-btn jr-btn-sm bg-success"
                style={{ float: "right" }}
                onClick={() => {
                  this.setState({
                    listContinuos: false,
                  });
                }}
              >
                <i className="zmdi zmdi-check zmdi-hc-lg" />
                <span> Ok </span>
              </Button>
            </div>
          }
        >
          <>
            <p
              style={{ color: "#ffc107", fontSize: "15px", fontweight: "700" }}
            >
              Medicamentos que estão atualmente em uso pelo paciente e serão
              renovados automaticamento devido a seu uso contínuo
            </p>
            <Table className="table-medicines">
              <TableHead>
                <TableRow>
                  <TableCell>Medicamento</TableCell>
                  <TableCell align="left">Orientação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.medicinesContinuos.map((row, index) => (
                  <TableRow key={row.ls_mp_medicine}>
                    <TableCell component="th" scope="row">
                      <div>{row.name_display}</div>
                      <span style={{ color: "#aaa" }}>{row.detail}</span>
                    </TableCell>
                    <TableCell align="left">
                      {row.orientation !== "0" ? row.orientation : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        </ModalResponsive>

        {/* Modal para selecionar os dias da semana do intervalo de medicamentos */}
        <ModalResponsive
          title="Selecione os dias para a administração dos medicamentos"
          open={this.state.days_week_modal}
          close={() => {
            this.setState({
              days_week_modal: false,
            });
          }}
          buttons={
            <div className="col-12">
              <Button
                color="primary"
                variant="contained"
                className="jr-btn jr-btn-sm bg-success"
                style={{ float: "right" }}
                onClick={() => {
                  this.setState({
                    days_week_modal: false,
                  });
                }}
              >
                <i className="zmdi zmdi-check zmdi-hc-lg" />
                <span> Ok </span>
              </Button>
            </div>
          }
          maxWidth="xs"
        >
          <>
            <div className="row">
              <div className="col-md-12">
                <Checkbox
                  checked={
                    this.state.days_week_list.find((e) => e === "segunda") !==
                    undefined
                  }
                  onChange={() => this.setDayWeek("segunda")}
                  color="primary"
                />
                {"Segunda-feira"}
              </div>
              <div className="col-md-12">
                <Checkbox
                  checked={
                    this.state.days_week_list.find((e) => e === "terca") !==
                    undefined
                  }
                  onChange={() => this.setDayWeek("terca")}
                  color="primary"
                />
                {"Terça-feira"}
              </div>
              <div className="col-md-12">
                <Checkbox
                  checked={
                    this.state.days_week_list.find((e) => e === "quarta") !==
                    undefined
                  }
                  onChange={() => this.setDayWeek("quarta")}
                  color="primary"
                />
                {"Quarta-feira"}
              </div>
              <div className="col-md-12">
                <Checkbox
                  checked={
                    this.state.days_week_list.find((e) => e === "quinta") !==
                    undefined
                  }
                  onChange={() => this.setDayWeek("quinta")}
                  color="primary"
                />
                {"Quinta-feira"}
              </div>
              <div className="col-md-12">
                <Checkbox
                  checked={
                    this.state.days_week_list.find((e) => e === "sexta") !==
                    undefined
                  }
                  onChange={() => this.setDayWeek("sexta")}
                  color="primary"
                />
                {"Sexta-feira"}
              </div>
              <div className="col-md-12">
                <Checkbox
                  checked={
                    this.state.days_week_list.find((e) => e === "sabado") !==
                    undefined
                  }
                  onChange={() => this.setDayWeek("sabado")}
                  color="primary"
                />
                {"Sábado"}
              </div>
              <div className="col-md-12">
                <Checkbox
                  checked={
                    this.state.days_week_list.find((e) => e === "domingo") !==
                    undefined
                  }
                  onChange={() => this.setDayWeek("domingo")}
                  color="primary"
                />
                {"Domingo"}
              </div>
            </div>
          </>
        </ModalResponsive>
      </>
    );
  }
}

const mapStateToProps = ({ prescription, appointment, auth }) => {
  return {
    form: prescription.form,
    selected_medicine: prescription.selected_medicine,
    medicines: prescription.medicines,
    medicine_atual: prescription.medicine_atual,
    medicine_selected: prescription.medicine_selected,
    patient: appointment.patient,
    user: auth.authUser,
    type_provider: auth.address_selected.provider_type,
  };
};
const updateMedicine = () => ({ type: "REQUEST_FORM_MEDICINE_USE" });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { cancelMedicine, addMedicine, editMedicine, updateMedicine },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormMedicine);
