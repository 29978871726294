const INITIAL_STATE = {
  modalAddDiag: false,
  listDiagnosis: [],
  listOtherRegion: [],
  diagnosisFinished: [],
  currentSelectTooth: [],
  evaluation: [],
  budget: [],
  workPlace: {},
  listBudget: {},
  editBudget: [],
  listTreat: [],
  listToothSelect: [],
  selectToothList: [],
  listToothMomentSelect: [],
  hoverTooth: {},
  calendarId: "",
  listProRegion: [],
};

export default function reducerOdonto(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_LIST_DIAGNOSIS":
      let newList = state.listDiagnosis.concat(action.params);

      newList.sort(function(a, b) {
        if (a.numTooth > b.numTooth) {
          return 1;
        }
        if (a.numTooth < b.numTooth) {
          return -1;
        }
        return 0;
      });

      return {
        ...state,
        listDiagnosis: newList,
      };
    case "SET_LIST_TOOTH_SELECT":
      let verifyList = state.listToothSelect.filter(
        (row) => row.id === action.payload.id
      );
      let newListToothSelect = state.listToothSelect;
      if (verifyList.length > 0) {
        newListToothSelect = state.listToothSelect.filter(
          (row) => row.id !== action.payload.id
        );
      } else {
        newListToothSelect = [
          ...state.listToothSelect,
          {
            tooth: action.payload.tooth,
            id: action.payload.id,
          },
        ];
      }
      return {
        ...state,
        listToothSelect: newListToothSelect,
      };
    case "SET_LIST_TOOTH_SELECT_REGION":
      let verifyListRegion = state.listProRegion.filter(
        (row) => row.id === action.payload.id
      );
      let newListProRegion = state.listProRegion;
      if (verifyListRegion.length > 0) {
        newListProRegion = state.listProRegion.filter(
          (row) => row.id !== action.payload.id
        );
      } else {
        newListProRegion = [
          ...state.listProRegion,
          {
            tooth: action.payload.tooth,
            id: action.payload.id,
          },
        ];
      }
      return {
        ...state,
        listProRegion: newListProRegion,
      };
    case "SET_LIST_TOOTH":
      return {
        ...state,
        listToothSelect: action.payload,
      };
    case "SET_LIST_REGION":
      return {
        ...state,
        listProRegion: action.payload,
      };
    case "SET_SELECT_TOOTH":
      let verify = state.selectToothList.filter(
        (row) => row.id === action.payload.id
      );
      let newselectToothList = state.selectToothList;
      if (verify.length > 0) {
        newselectToothList = state.selectToothList.filter(
          (row) => row.id !== action.payload.id
        );
      } else {
        newselectToothList = [
          ...state.selectToothList,
          {
            tooth: action.payload.tooth,
            id: action.payload.id,
            name: action.payload.name,
            type: action.payload.type,
          },
        ];
      }
      return {
        ...state,
        selectToothList: newselectToothList,
      };
    case "SET_SELECT_TOOTH_LIST":
      return {
        ...state,
        selectToothList: action.payload,
      };
    case "SET_LIST_PRO_REGION":
      let valid = state.listProRegion.filter(
        (row) => row.id === action.payload.id
      );
      let newlistProRegion = state.listProRegion;
      if (valid.length > 0) {
        newlistProRegion = state.listProRegion.filter(
          (row) => row.id !== action.payload.id
        );
      } else {
        newlistProRegion = [
          ...state.listProRegion,
          {
            tooth: action.payload.tooth,
            id: action.payload.id,
            name: action.payload.name,
            fk_ls_od_mouth_item: action.payload.id,
          },
        ];
      }
      return {
        ...state,
        listProRegion: newlistProRegion,
      };
    case "SET_LIST_TOOTH_SELECT_CLEAR":
      return {
        ...state,
        listToothSelect: [],
      };
    case "SET_LIST_PRO_REGION_CLEAR":
      return {
        ...state,
        listProRegion: [],
      };

    case "SET_LIST_TOOTH_MOMENT_SELECT":
      let verifyListMoment = state.listToothMomentSelect.filter(
        (row) => row.id === action.payload.id
      );
      let newListToothMomentSelect = state.listToothMomentSelect;
      if (verifyListMoment.length > 0) {
        newListToothMomentSelect = state.listToothMomentSelect.filter(
          (row) => row.id !== action.payload.id
        );
      } else {
        newListToothMomentSelect.push({
          tooth: action.payload.tooth,
          id: action.payload.id,
        });
      }
      return {
        ...state,
        listToothMomentSelect: newListToothMomentSelect,
      };

    case "SET_LIST_TOOTH_MOMENT_SELECT_CLEAR":
      return {
        ...state,
        listToothMomentSelect: [],
      };
    case "SET_DIAGNOSIS_FINISHED":
      return {
        ...state,
        diagnosisFinished: action.payload,
      };
    case "SET_CURRENT_SELECT_TOOTH":
      return {
        ...state,
        listDiagnosis: action.payload,
      };
    case "SET_LIST_DIAGNOSIS_UPDATE":
      return {
        ...state,
        listDiagnosis: action.payload,
      };
    case "SET_LIST_CURRENT_SELECT_TOOTH":
      return {
        ...state,
        currentSelectTooth: action.payload,
      };

    case "SET_CALENDAR_ID":
      return {
        ...state,
        calendarId: action.payload,
      };

    case "PUSH_CURRENT_SELECT_TOOTH":
      let newListSelect = state.listToothMomentSelect;
      newListSelect.push(action.payload);
      return {
        ...state,
        listToothMomentSelect: newListSelect,
      };
    case "PUSH_LIST_OTHER_REGION":
      let newListRegion = state.listOtherRegion;
      newListRegion.push(action.payload);
      return {
        ...state,
        listOtherRegion: newListRegion,
      };
    case "SET_LIST_OTHER_REGION":
      return {
        ...state,
        listOtherRegion: action.payload,
      };
    case "SET_CURRENT_SELECT_TOOTH_CLEAR":
      return {
        ...state,
        currentSelectTooth: [],
      };
    case "SET_MODAL_DIAG":
      return {
        ...state,
        modalAddDiag: action.payload,
      };
    case "SET_BUDGET":
      return {
        ...state,
        budget: action.payload,
      };
    case "SET_LIST_BUDGET":
      let newListBudget = action.payload;

      let newListrehab = newListBudget.listTreat.map((row, index) =>
        row.listrehab !== null
          ? {
              ...row,
              listrehab: JSON.parse(row.listrehab),
            }
          : row
      );
      newListBudget = {
        ...newListBudget,
        listTreat: newListrehab,
      };
      return {
        ...state,
        listBudget: newListBudget,
      };
    case "SET_LIST_EVALUATION":
      return {
        ...state,
        evaluation: action.payload,
      };
    case "SET_WORK_PLACE":
      return {
        ...state,
        workPlace: action.payload,
      };
    case "PUSH_LIST_EVALUATION":
      let newListEvaluation = action.payload;
      let listEvaluationNew = newListEvaluation.map((row) =>
        newListEvaluation.length > 0 && row.budget !== null
          ? { ...row, budget: JSON.parse(row.budget), view: false }
          : row
      );

      return {
        ...state,
        evaluation: listEvaluationNew,
      };
    case "CLEAR_EVALUATION":
      return {
        ...state,
        listDiagnosis: [],
        currentSelectTooth: [],
        evaluation: [],
        listOtherRegion: [],
        listToothSelect: [],
        listToothMomentSelect: [],
      };
    case "HOVER_TOOTH_TABLE":
      return {
        ...state,
        hoverTooth: action.payload,
      };
    case "SET_LIST_EDIT_BUDGET":
      let newBudget = action.payload.editBudget;
      newBudget = {
        ...newBudget,
        listBudget: action.payload.editBudget.listBudget.map((row) =>
          action.payload.editBudget.listBudget.length > 0 &&
          action.payload.editBudget.listBudget.listrehab !== null
            ? {
                ...row,
                budget: true,
                view: false,
                listrehab: JSON.parse(row.listrehab).map((ele) => ({
                  ...ele,
                  actived: true,
                })),
              }
            : row
        ),
      };

      return {
        ...state,
        editBudget: newBudget,
      };
    case "SET_LIST_EDIT":
      // newlistTreat = action.payload.listTreat;

      let newlistTreat = action.payload.listTreat.map((row) =>
        action.payload.listTreat.length > 0 &&
        action.payload.listTreat.listrehab !== null
          ? {
              ...row,
              budget: true,
              view: false,
              listrehab: JSON.parse(row.listrehab).map((ele) => ({
                ...ele,
                actived: true,
              })),
            }
          : row
      );

      return {
        ...state,
        listTreat: newlistTreat,
      };
    case "UPDATE_LIST_EDIT":
      return {
        ...state,
        listTreat: action.payload,
      };
    case "UPDATE_LIST_EDIT_BUDGET":
      return {
        ...state,
        editBudget: action.payload,
      };
    case "CLEAR_LIST_EDIT_BUDGET":
      return {
        ...state,
        editBudget: [],
      };
    case "CLEAR_LIST_SELECT_TOOTH":
      return {
        ...state,
        selectToothList: [],
      };
    case "CLEAR_ODONTO":
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
