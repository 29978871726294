import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import * as FormGroupActions from "actions/configuration/ProcedureGroups";
import IntlMessages from "util/IntlMessages";
import ViewProcedureGroups from "./ViewProcedureGroups";
import FormProcedureGroups from "./FormProcedureGroups";

//import components Tags

class ProcedureGroups extends Component {
  constructor(props) {
    super(props);
    this.handleAdd = this.handleAdd.bind(this);
  }

  handleAdd() {
    if (this.props.ProcedureGroups.active === "view") {
      this.props.handleAddFunction(
        "form",
        "secondary",
        "cancelar",
        true,
        "close"
      );
    } else {
      this.props.handleAddFunction(
        "view",
        "primary",
        "Adicionar",
        "hidden",
        "plus"
      );
    }
  }

  render() {
    const { match } = this.props;
    const TitleStyle = {
      color: "#177493",
      fontSize: "16px",
    };
    return (
      <div className="row">
        <h2 className="text-primary font-weight-semibold w-100">
          Meus grupos de procedimentos
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={this.props.close}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>

        <div className="col-12">
          <Button
            className="jr-btn jr-btn-sm"
            variant="contained"
            style={{ float: "right" }}
            color={this.props.ProcedureGroups.color}
            onClick={this.handleAdd}
          >
            <i
              className={`zmdi zmdi-${this.props.ProcedureGroups.icon} zmdi-hc-lg `}
            />
            <span>{this.props.ProcedureGroups.texto}</span>
          </Button>
        </div>
        <div className="col-md-12">
          {this.props.ProcedureGroups.active === "view" ? (
            <ViewProcedureGroups />
          ) : (
            <Dialog
              fullWidth={true}
              maxWidth="lg"
              open={true}
              onClose={() => {}}
              aria-labelledby="max-width-dialog-title"
              scroll="body"
            >
              <DialogContent>
                <FormProcedureGroups />
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doctor: state.auth.authUser,
    ProcedureGroups: state.ProcedureGroups,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(FormGroupActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureGroups);
