import React, { Component } from "react";
// import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";
import * as FormGroupActions from "actions/configuration/ProcedureGroups";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const styles = {
  container: {
    flexGrow: 1,
    height: 100,
    width: "100%",
  },
  root: {
    backgroundColor: "#fff",
  },
  selected: {
    backgroundColor: "#e2e2e8 !important",
  },
};

class AutocompleteProcedures extends Component {
  state = {
    suggestions: [],
  };

  getSuggestions = (e) => {
    if (e.target.value.length > 2) {
      Api.post(`/microservice/autocomplete/getProcedures/`, {
        term: e.target.value,
        area: this.props.area,
      }).then((res) => {
        this.setState({
          suggestions: res.data,
        });
      });
    }
  };

  renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    const { classes } = this.props;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
      >
        {suggestion.description}
      </MenuItem>
    );
  }

  renderInput(inputProps) {
    let { InputProps, classes, ref, ...other } = inputProps;
    return (
      <TextField
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...InputProps,
        }}
        onKeyUp={this.getSuggestions}
        onChange={this.handleChange}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { suggestions } = this.state;

    return (
      <Downshift
        onSelect={(selectedItem, obj) => {
          let item = suggestions.filter((row) => {
            return row.description === selectedItem;
          });
          this.props.setProceduresItensGroups(item[0]);
          obj.setState({ inputValue: "" });
        }}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            {this.renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                id: "procedure_id",
                value: inputValue,
              }),
              label: "Informe o Procedimento:",
              margin: "normal",
            })}
            {isOpen ? (
              <Paper square>
                {suggestions.map((suggestion, index) =>
                  this.renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.description,
                    }),
                    highlightedIndex,
                    selectedItem,
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doctor: state.auth.authUser,
    ProcedureGroups: state.ProcedureGroups,
    area: state.auth.area,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(FormGroupActions, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AutocompleteProcedures)
);
