import React, { useState, useEffect } from "react";
import { BtnCan, BtnSave } from "components/Button";
import {
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { ModalResponsive } from "components/ModalResponsive";
import Api from "services/api";
import { useDispatch, useSelector } from "react-redux";
import { setAppointment, setSavedActions } from "actions/consult/Appointment";
import { showMsg } from "services/functions";

import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import PoolIcon from "@material-ui/icons/Pool";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import BlockIcon from "@material-ui/icons/Block";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import DehazeIcon from "@material-ui/icons/Dehaze";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";

export default function FormPhysicalActivities({
  open,
  data,
  handleClose,
  patient,
  type,
  record,
  list,
  handleAdd,
}) {
  const initial = {
    id: "",
    activitie: "",
    start: "",
    observation: "",
    alert: true,
    time_alert: "00:30",
    w0: false,
    w0_hour: "",
    w0_duration: "",
    w1: false,
    w1_hour: "",
    w1_duration: "",
    w2: false,
    w2_hour: "",
    w2_duration: "",
    w3: false,
    w3_hour: "",
    w3_duration: "",
    w4: false,
    w4_hour: "",
    w4_duration: "",
    w5: false,
    w5_hour: "",
    w5_duration: "",
    w6: false,
    w6_hour: "",
    w6_duration: "",
  };
  const [state, setState] = useState(initial);
  const [error, setError] = useState(false);

  const appointment = useSelector((state) => state.appointment.id);
  const prof_resp = useSelector((state) => state.appointment.saved.prof_resp);
  const appointment_action = useSelector((state) => state.appointment.saved.id);
  const appointment_action_form = useSelector(
    (state) => state.appointment.saved.physical_activitie
  );
  const start = useSelector((state) => state.appointment.start);
  const user = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.id) {
      let obj = initial;

      data.days.forEach((row) => {
        obj = {
          ...obj,
          [`w${row.weekday}`]: true,
          [`w${row.weekday}_hour`]: row.hour ? row.hour : "",
          [`w${row.weekday}_duration`]: row.duration ? row.duration : "",
        };
      });

      setState({
        ...obj,
        id: data.id,
        activitie: data.activitie,
        start: data.start,
        observation: data.observation,
        alert: data.alert !== "1" ? false : true,
        time_alert: data.time_alert ? data.time_alert : "00:30",
      });
    }
  }, [data]);

  const handleChange = (field) => (e) => {
    setState({ ...state, [field]: e.target.value });
  };

  const handleChangeCheck = (field) => (e) => {
    let checked = e.target.checked;
    setState({
      ...state,
      [field]: checked,
      [`${field}_hour`]: checked ? getLastHourInsert() : "",
      [`${field}_duration`]: checked ? getLastDuration() : "",
    });
  };

  // const handleChange = (field) => (e) => {
  //   let fieldParts = field.split("_");
  //   let { value } = e.target;
  //   if (fieldParts.length > 1) {
  //     console.log("fieldParts", fieldParts[1]);
  //     console.log("state", state);
  //     setState({
  //       ...state,
  //       w0_hour: state.w0
  //         ? !state.w0_hour && "hour" === fieldParts[1]
  //           ? value
  //           : "w0_hour" === field
  //           ? value
  //           : state.w0_hour
  //         : state.w0_hour,
  //       w0_duration: state.w0
  //         ? !state.w0_duration && "duration" === fieldParts[1]
  //           ? value
  //           : "w0_duration" === field
  //           ? value
  //           : state.w0_duration
  //         : state.w0_duration,
  //       w1_hour: state.w1
  //         ? !state.w1_hour && "hour" === fieldParts[1]
  //           ? value
  //           : "w1_hour" === field
  //           ? value
  //           : state.w1_hour
  //         : state.w1_hour,
  //       w1_duration: state.w1
  //         ? !state.w1_duration && "duration" === fieldParts[1]
  //           ? value
  //           : "w1_duration" === field
  //           ? value
  //           : state.w1_duration
  //         : state.w1_duration,
  //       w2_hour: state.w2
  //         ? !state.w2_hour && "hour" === fieldParts[1]
  //           ? value
  //           : "w2_hour" === field
  //           ? value
  //           : state.w2_hour
  //         : state.w2_hour,
  //       w2_duration: state.w2
  //         ? !state.w2_duration && "duration" === fieldParts[1]
  //           ? value
  //           : "w2_duration" === field
  //           ? value
  //           : state.w2_duration
  //         : state.w2_duration,
  //       w3_hour: state.w3
  //         ? !state.w3_hour && "hour" === fieldParts[1]
  //           ? value
  //           : "w3_hour" === field
  //           ? value
  //           : state.w3_hour
  //         : state.w3_hour,
  //       w3_duration: state.w3
  //         ? !state.w3_duration && "duration" === fieldParts[1]
  //           ? value
  //           : "w3_duration" === field
  //           ? value
  //           : state.w3_duration
  //         : state.w3_duration,
  //       w4_hour: state.w4
  //         ? !state.w4_hour && "hour" === fieldParts[1]
  //           ? value
  //           : "w4_hour" === field
  //           ? value
  //           : state.w4_hour
  //         : state.w4_hour,
  //       w4_duration: state.w4
  //         ? !state.w4_duration && "duration" === fieldParts[1]
  //           ? value
  //           : "w4_duration" === field
  //           ? value
  //           : state.w4_duration
  //         : state.w4_duration,
  //       w5_hour: state.w5
  //         ? !state.w5_hour && "hour" === fieldParts[1]
  //           ? value
  //           : "w5_hour" === field
  //           ? value
  //           : state.w5_hour
  //         : state.w5_hour,
  //       w5_duration: state.w5
  //         ? !state.w5_duration && "duration" === fieldParts[1]
  //           ? value
  //           : "w5_duration" === field
  //           ? value
  //           : state.w5_duration
  //         : state.w5_duration,
  //       w6_hour: state.w6
  //         ? !state.w6_hour && "hour" === fieldParts[1]
  //           ? value
  //           : "w6_hour" === field
  //           ? value
  //           : state.w6_hour
  //         : state.w6_hour,
  //       w6_duration: state.w6
  //         ? !state.w6_duration && "duration" === fieldParts[1]
  //           ? value
  //           : "w6_duration" === field
  //           ? value
  //           : state.w6_duration
  //         : state.w6_duration,
  //     });
  //   } else {
  //     setState({ ...state, [field]: e.target.value });
  //   }
  // };

  const onClose = () => {
    setState(initial);
    handleClose();
  };

  function getLastHourInsert() {
    let lastHour = "";
    Object.keys(state).forEach((key) => {
      if (key.indexOf("hour") !== -1 && state[`${key}`]) {
        lastHour = state[`${key}`];
      }
    });
    return lastHour;
  }

  function getLastDuration() {
    let lastDuration = "";
    Object.keys(state).forEach((key) => {
      if (key.indexOf("duration") !== -1 && state[`${key}`])
        lastDuration = state[`${key}`];
    });
    return lastDuration;
  }

  const handleSave = () => {
    if (state.activitie) {
      Api.post("microservice/patientportal/savePhysicalActivities", {
        patient,
        data: state,
        description: list.find((row) => row.id === state.activitie).name,
        type,
        user,
        address,
        appointment,
        appointment_action,
        appointment_action_form,
        prof_resp,
        start,
      })
        .then((res) => {
          showMsg("success", "Salvo com sucesso!", dispatch);
          onClose();
          handleAdd();
          if (type === "consult") {
            if (!appointment) {
              dispatch(setAppointment(res.data.appointment));
            }
            if (!appointment_action) {
              dispatch(setSavedActions("physical_activitie", res.data.saved));
            }
          }
        })
        .catch(() => {
          showMsg("error", "Ocorreu um erro ao salvar!", dispatch);
        });
    } else {
      setError(true);
    }
  };

  const getIcon = (icon) => {
    switch (icon) {
      case "4048":
        return <FitnessCenterIcon />;
      case "4049":
        return <AccessibilityIcon />;
      case "4051":
      case "4055":
      case "5237":
        return <DirectionsRunIcon />;
      case "4052":
        return <PoolIcon />;
      case "4053":
        return <AccessibilityIcon />;
      case "4612":
        return <BlockIcon />;
      case "4611":
        return <DehazeIcon />;
      case "4054":
        return <ColorLensIcon />;
      case "4050":
        return <EmojiPeopleIcon />;
      case "5238":
        return <DirectionsBikeIcon />;
    }
  };

  return (
    <ModalResponsive
      title="Atividades Física"
      open={open}
      close={onClose}
      maxWidth="md"
      buttons={
        <div className="col-12">
          <BtnCan title="Fechar" onClick={onClose} float="left" />

          <BtnSave title="Salvar" onClick={handleSave} float="right" />
        </div>
      }
    >
      <div className="row">
        <div className="col-md-12">
          <TextField
            select
            label="Atividade:"
            value={state.activitie}
            onChange={handleChange("activitie")}
            InputLabelProps={{ shrink: true }}
            error={error && !state.activitie}
            fullWidth
          >
            {list
              .filter(
                (row) =>
                  (record.indexOf(row.id) === -1 ||
                    row.id === data.activitie) &&
                  ((row.id === "4612" && record.length === 0) ||
                    row.id !== "4612")
              )
              .map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {getIcon(row.id)}
                  <span className="ml-3">{row.name}</span>
                </MenuItem>
              ))}
          </TextField>
        </div>
        {state.activitie && state.activitie !== "4612" && (
          <>
            <div className="col-md-3 col-xs-12 mt-3">
              <TextField
                type="date"
                label="Pratica desde:"
                value={state.start}
                onChange={handleChange("start")}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </div>
            <div className="col-md-3 col-xs-12 mt-3">
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={state.alert}
                    onChange={() => setState({ ...state, alert: !state.alert })}
                  />
                }
                label="Receber alertas:"
                labelPlacement="start"
                style={{
                  marginTop: "13px",
                  marginLeft: "0px",
                }}
              />
            </div>
            {state.alert && (
              <div className="col-md-3 col-xs-12 mt-3">
                <TextField
                  type="time"
                  label="Antecedência:"
                  value={state.time_alert}
                  onChange={handleChange("time_alert")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </div>
            )}
            <div
              className="col-12 mt-3"
              style={{
                minWidth: "650",
                overflowX: "auto",
              }}
            >
              <Table className="table-finances">
                <TableHead>
                  <TableRow>
                    <TableCell className="pt-0 pb-1">Dia da semana</TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      Horário
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      Duração
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover key="0">
                    <TableCell className="pt-0 pb-1" component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={state.w0}
                            onChange={handleChangeCheck("w0")}
                          />
                        }
                        label="Domingo:"
                        labelPlacement="start"
                        style={{
                          marginTop: "13px",
                          marginLeft: "0px",
                        }}
                      />
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w0 && (
                        <TextField
                          type="time"
                          value={state.w0_hour}
                          onChange={handleChange("w0_hour")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w0 && (
                        <TextField
                          type="time"
                          value={state.w0_duration}
                          onChange={handleChange("w0_duration")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow hover key="1">
                    <TableCell className="pt-0 pb-1" component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={state.w1}
                            onChange={handleChangeCheck("w1")}
                          />
                        }
                        label="Segunda-feira:"
                        labelPlacement="start"
                        style={{
                          marginTop: "13px",
                          marginLeft: "0px",
                        }}
                      />
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w1 && (
                        <TextField
                          type="time"
                          value={state.w1_hour}
                          onChange={handleChange("w1_hour")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w1 && (
                        <TextField
                          type="time"
                          value={state.w1_duration}
                          onChange={handleChange("w1_duration")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow hover key="2">
                    <TableCell className="pt-0 pb-1" component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={state.w2}
                            onChange={handleChangeCheck("w2")}
                          />
                        }
                        label="Terça-feira:"
                        labelPlacement="start"
                        style={{
                          marginTop: "13px",
                          marginLeft: "0px",
                        }}
                      />
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w2 && (
                        <TextField
                          type="time"
                          value={state.w2_hour}
                          onChange={handleChange("w2_hour")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w2 && (
                        <TextField
                          type="time"
                          value={state.w2_duration}
                          onChange={handleChange("w2_duration")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow hover key="3">
                    <TableCell className="pt-0 pb-1" component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={state.w3}
                            onChange={handleChangeCheck("w3")}
                          />
                        }
                        label="Quarta-feira:"
                        labelPlacement="start"
                        style={{
                          marginTop: "13px",
                          marginLeft: "0px",
                        }}
                      />
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w3 && (
                        <TextField
                          type="time"
                          value={state.w3_hour}
                          onChange={handleChange("w3_hour")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w3 && (
                        <TextField
                          type="time"
                          value={state.w3_duration}
                          onChange={handleChange("w3_duration")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow hover key="4">
                    <TableCell className="pt-0 pb-1" component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={state.w4}
                            onChange={handleChangeCheck("w4")}
                          />
                        }
                        label="Quinta-feira:"
                        labelPlacement="start"
                        style={{
                          marginTop: "13px",
                          marginLeft: "0px",
                        }}
                      />
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w4 && (
                        <TextField
                          type="time"
                          value={state.w4_hour}
                          onChange={handleChange("w4_hour")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w4 && (
                        <TextField
                          type="time"
                          value={state.w4_duration}
                          onChange={handleChange("w4_duration")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow hover key="5">
                    <TableCell className="pt-0 pb-1" component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={state.w5}
                            onChange={handleChangeCheck("w5")}
                          />
                        }
                        label="Sexta-feira:"
                        labelPlacement="start"
                        style={{
                          marginTop: "13px",
                          marginLeft: "0px",
                        }}
                      />
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w5 && (
                        <TextField
                          type="time"
                          value={state.w5_hour}
                          onChange={handleChange("w5_hour")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w5 && (
                        <TextField
                          type="time"
                          value={state.w5_duration}
                          onChange={handleChange("w5_duration")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow hover key="6">
                    <TableCell className="pt-0 pb-1" component="th" scope="row">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={state.w6}
                            onChange={handleChangeCheck("w6")}
                          />
                        }
                        label="Sábado:"
                        labelPlacement="start"
                        style={{
                          marginTop: "13px",
                          marginLeft: "0px",
                        }}
                      />
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w6 && (
                        <TextField
                          type="time"
                          value={state.w6_hour}
                          onChange={handleChange("w6_hour")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                    <TableCell className="pt-0 pb-1" align="left">
                      {state.w6 && (
                        <TextField
                          type="time"
                          value={state.w6_duration}
                          onChange={handleChange("w6_duration")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

            <div className="col-12 mt-3">
              <TextField
                label="Observações:"
                value={state.observation}
                onChange={handleChange("observation")}
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows={4}
              />
            </div>
          </>
        )}
      </div>
    </ModalResponsive>
  );
}
