import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setConditions } from "actions/configuration/TagsConditions";

import {
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
  Menu,
  MenuItem
} from "@material-ui/core";

class ConditionalSexo extends Component {
  state = {
    value: "",
    open: false,
    anchorEl: undefined,
    button_text: true
  };
  handleChange = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };
  handleRequestClose = value => {
    this.setState({ open: false, button_text: value });
  };
  handleChangeSexo = e => {
    this.setState({ value: e.target.value });
    this.props.setConditions("SET_CONDITION_SEXO", e.target.value);
  };
  componentDidMount() {
    if (this.props.params !== null) {
      let sexo = null;
      if (this.props.params[0] === "0") {
        sexo = "M";
      } else if (this.props.params[0] === "1") {
        sexo = "F";
      } else {
        sexo = "O";
      }
      this.setState({ value: sexo });
      this.props.setConditions("SET_CONDITION_SEXO", sexo);
    }
  }
  render() {
    return (
      <div className="row" style={{ marginTop: "20px" }}>
        <div className="col-md-12">
          <h4>Sexo: </h4>
        </div>
        <div style={{ padding: "5px 10px" }}>
          <Button
            onClick={this.handleChange}
            fullWidth={true}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            {this.state.button_text ? "Igual A " : "Diferente De "}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </Button>
          <Menu
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
          >
            <MenuItem onClick={() => this.handleRequestClose(true)}>
              Igual A
            </MenuItem>
            <MenuItem onClick={() => this.handleRequestClose(false)}>
              Diferente De
            </MenuItem>
          </Menu>
        </div>
        <div className="col-md-9">
          <RadioGroup
            aria-label="sexo"
            name="sexo"
            value={this.state.value}
            onChange={this.handleChangeSexo}
            row
          >
            <FormControlLabel value="F" control={<Radio />} label="Feminino" />
            <FormControlLabel value="M" control={<Radio />} label="Masculino" />
            <FormControlLabel value="O" control={<Radio />} label="Outro" />
          </RadioGroup>
        </div>
        <Divider />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setConditions }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(ConditionalSexo);
