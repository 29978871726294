import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userSignOut } from "actions/Auth";
import { showModalConfig } from "actions/Configuration";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "util/IntlMessages";
import SweetAlert from "react-bootstrap-sweetalert";

function UserInfo({ history }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElList, setAnchorElList] = useState(null);
  const [menuList, setMenuList] = useState(false);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "",
  });

  const name = useSelector(({ auth }) => auth.name);
  const photo = useSelector(({ auth }) => auth.photo);
  const secretary = useSelector(({ auth }) => auth.secretary);
  const managers = useSelector(({ auth }) => auth.managers);
  const area = useSelector(({ auth }) => auth.area);
  const teacher = useSelector(({ auth }) => auth.teacher);
  const type = useSelector(({ auth }) => auth.type);
  const type_default = useSelector(({ auth }) => auth.type_default);
  const dispatch = useDispatch();

  const logoff = () => {
    dispatch(userSignOut());
    localStorage.removeItem("userAudit");
    localStorage.removeItem("init");
    if (history.location.pathname === "/app/form-notification-covid") {
      history.push("/notification-covid");
    } else if (history.location.pathname === "/app/vaccine-child") {
      history.push("/vacinacao-infantil");
    } else {
      history.push("/login");
    }
  };

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleMenuList = (event) => {
    setMenuList(true);
    setAnchorElList(event.currentTarget);
  };

  const handleRequestCloseList = () => {
    setMenuList(false);
    setOpen(false);
  };
  const handleRequestBackList = () => {
    setMenuList(false);
    setOpen(true);
  };

  const alterMode = (value) => {
    setAlert({ open: false, type: "" });
    dispatch({
      type: "ALTER_MODE_AUTH",
      payload: {
        type: value,
      },
    });
    history.push("/app/dashboard/feed");
  };

  return (
    <div
      className="user-profile d-flex flex-row align-items-center"
      style={{ padding: "7px" }}
    >
      <Avatar alt={name} src={photo} className="user-avatar" />
      <div className="user-detail">
        <h4 className="user-name" onClick={handleClick}>
          {name}
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h4>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <MenuItem
          key="0"
          onClick={() => {
            setOpen(false);
            history.push("/app/profile");
          }}
        >
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.profile" />
        </MenuItem>
        <MenuItem key="1" onClick={handleMenuList}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.setting" />
        </MenuItem>
        {type === "0" && (
          <MenuItem
            onClick={() => {
              setOpen(false);
              setAlert({ open: true, type: "3" });
            }}
          >
            <i className="zmdi zmdi-account-circle zmdi-hc-fw mr-2" />
            Meu Portal do Paciente
          </MenuItem>
        )}
        {type_default === "0" && type === "3" && (
          <MenuItem
            onClick={() => {
              setOpen(false);
              setAlert({ open: true, type: "0" });
            }}
          >
            <i className="zmdi zmdi-account-circle zmdi-hc-fw mr-2" />
            Meu Perfil de Profissional
          </MenuItem>
        )}

        <MenuItem
          key="2"
          onClick={() => {
            handleRequestClose();
            logoff();
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu>
      <Menu
        className="user-info"
        id="simple-menu"
        onClose={handleRequestCloseList}
        open={menuList}
        anchorEl={anchorElList}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
            marginLeft: 150,
          },
        }}
      >
        <MenuItem
          key="0"
          onClick={() => {
            handleRequestCloseList();
            dispatch(showModalConfig("security"));
          }}
        >
          <i className="zmdi zmdi-key zmdi-hc-fw mr-2" />
          Alterar senha/chave de acesso
        </MenuItem>

        <MenuItem
          key="1"
          onClick={() => {
            handleRequestCloseList();
            dispatch(showModalConfig("calendar"));
          }}
        >
          <i className="zmdi zmdi-calendar zmdi-hc-fw mr-2" />
          Agenda
        </MenuItem>

        <MenuItem
          key="2"
          onClick={() => {
            handleRequestCloseList();
            dispatch(showModalConfig("eventType"));
          }}
        >
          <i className="zmdi zmdi-layers zmdi-hc-fw mr-2" />
          Serviços
        </MenuItem>
        {!secretary &&
          (teacher === "1" ||
            type === "1" ||
            area === "12" ||
            area === "5" ||
            area === "16") && (
            <>
              <MenuItem
                key="5"
                onClick={() => {
                  handleRequestCloseList();
                  dispatch(showModalConfig("diagnosticGroups"));
                }}
              >
                <i className="zmdi zmdi-receipt zmdi-hc-fw mr-2" />
                Grupos de diagnósticos
              </MenuItem>

              <MenuItem
                key="6"
                onClick={() => {
                  handleRequestCloseList();
                  dispatch(showModalConfig("examsGroups"));
                }}
              >
                <i className="zmdi zmdi-eyedropper zmdi-hc-fw mr-2" />
                Grupos de exames
              </MenuItem>

              <MenuItem
                key="7"
                onClick={() => {
                  handleRequestCloseList();
                  dispatch(showModalConfig("medicinesGroups"));
                }}
              >
                <i className="zmdi zmdi-receipt zmdi-hc-fw mr-2" />
                Grupos de medicamentos
              </MenuItem>

              <MenuItem
                key="8"
                onClick={() => {
                  handleRequestCloseList();
                  dispatch(showModalConfig("procedureGroups"));
                }}
              >
                <i className="zmdi zmdi-group zmdi-hc-fw mr-2" />
                Grupos de procedimentos
              </MenuItem>
            </>
          )}
        {!secretary &&
          (teacher === "1" ||
            area === "12" ||
            area === "5" ||
            area === "16") && (
            <>
              <MenuItem
                key="3"
                onClick={() => {
                  handleRequestCloseList();
                  dispatch(showModalConfig("aga"));
                }}
              >
                <i className="zmdi zmdi-menu zmdi-hc-fw mr-2" />
                Aga
              </MenuItem>

              <MenuItem
                key="4"
                onClick={() => {
                  handleRequestCloseList();
                  dispatch(showModalConfig("anamnesis"));
                }}
              >
                <i className="zmdi zmdi-assignment-o zmdi-hc-fw mr-2" />
                Anamnese
              </MenuItem>

              <MenuItem
                key="9"
                onClick={() => {
                  handleRequestCloseList();
                  dispatch(showModalConfig("procedure"));
                }}
              >
                <i className="zmdi zmdi-local-hospital zmdi-hc-fw mr-2" />
                Procedimentos
              </MenuItem>

              <MenuItem
                key="10"
                onClick={() => {
                  handleRequestCloseList();
                  dispatch(showModalConfig("systemReview"));
                }}
              >
                <i className="zmdi zmdi-collection-plus zmdi-hc-fw mr-2" />
                Revisão dos sistemas
              </MenuItem>

              <MenuItem
                key="11"
                onClick={() => {
                  handleRequestCloseList();
                  dispatch(showModalConfig("tags"));
                }}
              >
                <i className="zmdi zmdi-bookmark-outline zmdi-hc-fw mr-2" />
                Tags
              </MenuItem>

              {managers.length > 0 && (
                <MenuItem
                  key="12"
                  onClick={() => {
                    handleRequestCloseList();
                    dispatch(showModalConfig("admissionForm"));
                  }}
                >
                  <i class="zmdi zmdi-assignment-check zmdi-hc-fw mr-2"></i>
                  Ficha de Admissão
                </MenuItem>
              )}
            </>
          )}
        <MenuItem onClick={handleRequestBackList}>
          <i className="zmdi zmdi-arrow-left zmdi-hc-fw mr-2" />
          Voltar
        </MenuItem>
      </Menu>
      <SweetAlert
        show={alert.open}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Alterar"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title={
          alert.type === "0"
            ? "Alterar para o seu perfil de profissional?"
            : "Alterar para seu Portal do Paciente?"
        }
        onConfirm={() => alterMode(alert.type)}
        onCancel={() => setAlert({ open: false, type: "" })}
      />
    </div>
  );
}

export default UserInfo;
