import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import api from "services/api";
import imgLogo from "assets/images/logo.svg";
import { allowCPF } from "./Functions";
import SweetAlert from "react-bootstrap-sweetalert";
import { Card, CardBody } from "reactstrap";
import CircularProgress from "components/CircularProgress";
import MaskCpfCnpj from "components/Masks/MaskCpfCnpj";

const PasswordRecover = ({ history }) => {
  const [modalSuccess, setModalSuccess] = useState(false);

  const [state, setState] = useState({
    cpf: "",
    step: 0, // 0 - inserir cpf // 1 - inserir token
    tokenInsert: "",
    option: 0, //0 email // 1 telefone
    password: "",
    passwordConfirm: "",
    user: "",
    phoneMask: "",
    emailMask: "",
    errrCpf: false,
    errorToken: false,
    errorPassword: false,
  });

  const [loader, setLoader] = useState(false);

  const requestRecover = async () => {
    setLoader(true);
    const valido = state.cpf.length > 15 ? true : allowCPF(state.cpf);
    if (valido) {
      const cpf = state.cpf;
      api
        .post(`/ms_system/passwordrecover/requestRecoverPass/`, {
          cpf,
          type: cpf.length > 15 ? "2" : "1",
        })
        .then(async ({ data }) => {
          setLoader(false);
          if (data.success === true) {
            setState({
              ...state,
              user: data.user,
              emailMask: data.emailMask,
              phoneMask: data.phoneMask,
              step: 1,
            });
          }
        });
    } else {
      setLoader(false);
      setState({ ...state, errorCpf: true });
    }
  };

  const verifyToken = async () => {
    if (state.cpf != "") {
      const user = state.user;
      const tokenUser = state.tokenInsert;
      api
        .post(`/ms_system/passwordrecover/verifyTokenUser/`, {
          user,
          tokenUser,
        })
        .then(async ({ data }) => {
          if (data.success === true) {
            setState({ ...state, step: 2 });
          } else {
            setState({ ...state, errorToken: true });
          }
        });
    }
  };

  const cadNewPassword = async () => {
    if (state.password === state.passwordConfirm) {
      api
        .post(`/ms_system/passwordrecover/cadPasswordUser/`, {
          password: state.password,
          user: state.user,
        })
        .then(async ({ data }) => {
          if (data.success === true) {
            setModalSuccess(true);
          }
        });
    } else {
      setState({ ...state, errorPassword: true });
    }
  };

  const redirectSuccess = () => {
    history.push("/login");
    setModalSuccess(false);
  };

  const handleContact = () => {
    if (window.location.href.indexOf("localhost") !== -1) {
      window.location.href = "http://localhost/salutem-webapp/app/#section5";
    } else if (window.location.href.indexOf("pwa.salutem") !== -1) {
      window.location.href = "http://pwaback.salutemplus.com/#section5";
    } else if (window.location.href.indexOf("react.salutem") !== -1) {
      window.location.href = "https://apresentacao.salutemplus.com/#section5";
    } else {
      window.location.href = "https://balancer.salutemplus.com/#section5";
    }
  };

  const getContent = () => (
    <>
      <div className="login-header">
        {state.step === 0 && <h3>Recuperar Senha?</h3>}
        {state.step === 1 && <h3>Verifique seu email</h3>}
        {state.step === 2 && <h3>Cadastre sua nova senha</h3>}
      </div>

      <div className="mb-2"></div>

      <div className="login-form">
        {state.step === 1 && (
          <span>
            Enviamos um código para o e-mail <b>{state.emailMask}</b> e para o
            número <b>{state.phoneMask} </b> . Quando você receber o código,
            digite-o abaixo para redefinir sua senha.
          </span>
        )}

        <form method="post">
          {state.step === 0 && (
            <>
              <TextField
                id="cpf"
                label="CPF / CNPJ"
                fullWidth
                autoFocus
                onChange={(event) => {
                  setState({ ...state, cpf: event.target.value });
                }}
                InputProps={{
                  value: state.cpf,
                  inputComponent: MaskCpfCnpj,
                }}
                margin="normal"
                className="mt-1"
                error={state.errorCpf}
                helperText={state.errorCpf ? "CPF Invalido" : ""}
              />

              {/* <TextField
                id="required"
                label="CPF"
                fullWidth
                InputProps={{
                  inputComponent: MaskCpf,
                  value: state.cpf,
                  onChange: (e) => setState({ ...state, cpf: e.target.value }),
                }}
                helperText={state.errorCpf ? "CPF Invalido" : ""}
                error={state.errorCpf ? true : false}
                margin="normal"
                className="mt-0 mb-4"
              /> */}
            </>
          )}
          {state.step === 1 && (
            <TextField
              id="required"
              label="Token"
              fullWidth
              InputProps={{
                value: state.tokenInsert,
                onChange: (e) =>
                  setState({ ...state, tokenInsert: e.target.value }),
              }}
              helperText={state.errorCpf ? "Token Invalido" : ""}
              error={state.errorToken ? true : false}
              margin="normal"
              className="mt-0 mb-4"
            />
          )}

          {state.step === 2 && (
            <>
              <TextField
                type="password"
                id="required"
                label="Digite a nova senha"
                fullWidth
                InputProps={{
                  value: state.password,
                  onChange: (e) =>
                    setState({ ...state, password: e.target.value }),
                }}
                helperText={
                  state.errorPassword ? "Senhas digitadas não são iguais" : ""
                }
                error={state.errorPassword ? true : false}
                margin="normal"
                className="mt-0 mb-4"
              />

              <TextField
                type="password"
                id="required"
                label="Repita a nova senha"
                fullWidth
                InputProps={{
                  value: state.passwordConfirm,
                  onChange: (e) =>
                    setState({ ...state, passwordConfirm: e.target.value }),
                }}
                helperText={
                  state.errorPassword ? "Senhas digitadas não são iguais" : ""
                }
                error={state.errorPassword ? true : false}
                margin="normal"
                className="mt-0 mb-4"
              />
            </>
          )}

          {state.step === 0 && (
            <>
              <p className="mb-3" onClick={handleContact}>
                Esqueceu seu CPF? 
                <span className="small jr-link">Contatar suporte</span>
              </p>

              <Button
                color="primary"
                onClick={() => history.push("/login")}
                style={{ float: "left" }}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="text-white"
                onClick={(e) => requestRecover()}
                style={{ float: "right" }}
              >
                Recuperar Senha
              </Button>
            </>
          )}

          {state.step === 1 && (
            <Button
              variant="contained"
              color="primary"
              className="text-white"
              onClick={(e) => verifyToken()}
              style={{ float: "right" }}
            >
              Confirmar
            </Button>
          )}

          {state.step === 2 && (
            <Button
              variant="contained"
              color="primary"
              className="text-white"
              onClick={(e) => cadNewPassword()}
              style={{ float: "right" }}
            >
              Cadastrar
            </Button>
          )}
        </form>
      </div>
    </>
  );

  const width = window.screen.width;

  if (width < 948) {
    return loader ? (
      <CircularProgress />
    ) : (
      <div
        className="d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
        style={{
          margin: "0 auto",
          width: "100%",
          background: "#177493",
        }}
      >
        <div className="row">
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <img src={imgLogo} style={{ width: "250px" }}></img>
          </div>
          <div className="col-12 pl-4 pr-4">
            <Card className="shadow">
              <CardBody>{getContent()}</CardBody>
            </Card>
          </div>
        </div>
        <SweetAlert
          show={modalSuccess}
          success
          showConfirm={true}
          title="Senha alterada com sucesso!"
          onConfirm={(e) => redirectSuccess()}
        />
      </div>
    );
  } else {
    return loader ? (
      <CircularProgress />
    ) : (
      <div
        className="login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
        style={{
          margin: "0 auto",
          width: "100%",
          backgroundImage: `url(${require("assets/images/background-login.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
      >
        <div className="logo" style={{ marginRight: "75px" }}>
          <img src={imgLogo} style={{ width: "250px" }}></img>
        </div>
        <div style={{ marginRight: "20px" }}></div>
        <div className="login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="login-content" style={{ marginRight: "400px" }}>
            {getContent()}
          </div>
        </div>
        <SweetAlert
          show={modalSuccess}
          success
          showConfirm={true}
          title="Senha alterada com sucesso!"
          onConfirm={(e) => redirectSuccess()}
        />
      </div>
    );
  }
};

export default PasswordRecover;
