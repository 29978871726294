import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import PersonalData from "app/routes/consult/routes/personalData";
import FormPrescription from "app/routes/consult/routes/prescription/formPrescription/index";
import VitalSigns from "app/routes/patientPortal/routes/data/index";
import FormPatologies from "components/Forms/Consult/patologies";
import AllergicDiseases from "components/Forms/Consult/Allergies/allergicDiseases";
import AllergyChemicals from "components/Forms/Consult/Allergies/allergyChemicals";
import FoodAllergies from "components/Forms/Consult/Allergies/foodAllergies";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "white",
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAdd({
  patient,
  title,
  type,
  open,
  handleClose,
  beforeSave,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          overflowX: "hidden",
        },
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="row pl-3 pr-3">
        <div className="col-12 mt-3">
          {type === "medicines" && (
            <FormPrescription
              transcription={true}
              form="pp"
              medicineUse={true}
              handleClose={() => {
                beforeSave();
                handleClose();
                dispatch({ type: "CANCEL_FORM_MEDICINE_IN_USE" });
              }}
            />
          )}
          {type === "data" && <PersonalData history={true} patient={patient} />}
          {type === "vitalsigns" && (
            <VitalSigns
              modal={true}
              beforeSave={() => {
                beforeSave();
                handleClose();
              }}
            />
          )}
          {type === "diagnostics" && (
            <FormPatologies
              patient={patient}
              type="pp"
              modal={true}
              beforeSave={() => {
                beforeSave();
                handleClose();
              }}
            />
          )}

          {type === "alergies" && (
            <>
              <AllergicDiseases type="pp" patient={patient} />

              <FoodAllergies type="pp" patient={patient} />

              <AllergyChemicals type="pp" patient={patient} />
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
}
