import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";
import { userSignInSuccess } from "actions/Auth";

class Academic extends Component {
  componentWillMount() {
    let data_get = this.props.history.location.search;
    let arr_data = data_get.split("&");
    let user = arr_data[0].split("=")[1];
    user = user.split("S15");
    user = user[1];
    if (user !== undefined) {
      this.props.userSignInSuccess(user);
    }

    // Descomentar quando for pra producao para remover os parametros da url
    window.history.pushState("", "", this.props.history.location.pathname);
  }

  render() {
    const { match } = this.props;
    return (
      <div
        className="app-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Switch>
          <Route
            path={`${match.url}/professor`}
            component={asyncComponent(() => import("./professor"))}
            // render={_props => <PersonalData match={match} />}
          />
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ userSignInSuccess }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(Academic);
