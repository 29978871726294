import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { SketchPicker } from "react-color";
import MaskMoney from "components/Masks/MaskMoney";
import { getFormatValueMoney } from "services/functions";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import AutocompleteProcedure from "./AutocompleteProcedure";

function TypesEventForm(props) {
  const [state, setState] = useState({ displayColor: false });
  const [value, setValue] = useState("");
  const [colorDefault, setColor] = useState("#177493");
  const [days_of_return, setDays] = useState("");
  const [duration, setDuration] = useState("00:30");
  const [automatic_return, setAutoReturn] = useState(false);
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [error, setError] = useState(false);
  const [listProcedures, setListProcedures] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.authUser);
  const provider_type = useSelector((state) => state.auth.provider_type);
  const secretary = useSelector((state) => state.auth.secretary);
  const user_manage = useSelector((state) => state.auth.user_manage);

  const [listTransactions, setListTransaction] = useState([]);
  const [transaction, setTransaction] = useState(
    props.transaction ? props.transaction : ""
  );
  const [listTypes, setListTypes] = useState([]);

  useEffect(() => {
    Api.post(`/ms_configuration/typeevent/getPatternTransactions/`, {
      user: secretary ? user_manage.id : user,
    }).then((res) => {
      setListTransaction(res.data.record);
      setListTypes(res.data.services);
    });
  }, []);

  useEffect(() => {
    if (props.id) {
      setValue(props.data.value);
      setColor(props.data.color);
      setDays(props.data.days);
      setDuration(props.data.duration);
      setType(props.data.type);
      setDescription(props.data.label);
      if (props.data.procedures) {
        setListProcedures(props.data.procedures);
      }
    }
  }, [props.data]);

  const handleChange = () => (e, checked) => {
    setAutoReturn(checked);
  };

  const handleClose = () => {
    setValue("");
    setColor("#177493");
    setDays("");
    setDuration("00:30");
    setAutoReturn(false);
    setDescription("");
    setType("");
    setListProcedures([]);
    props.close();
  };

  function save() {
    if (description) {
      let formData = {
        id: props.id,
        user: secretary ? user_manage.id : user,
        description,
        type,
        duration,
        value: getFormatValueMoney(value, ","),
        color: colorDefault,
        days_of_return,
        automatic_return: automatic_return ? 1 : 0,
        transaction,
        procedures: listProcedures,
      };
      Api.post(`/ms_configuration/typeevent/saveTypeEvent/`, formData).then(
        (res) => {
          if (res.data.success === true) {
            dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
            setTimeout(() => {
              dispatch(closeAlertMessage());
            }, 5000);
          } else {
            dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
            setTimeout(() => {
              dispatch(closeAlertMessage());
            }, 5000);
          }
          if (props.type === "modal") {
            props.beforeSave(res.data.idTypeEvent);
          } else {
            if (props.id) {
              dispatch({
                type: "EDIT_TYPE_EVENT",
                payload: {
                  ...formData,
                  id: res.data.idTypeEvent,
                },
              });
            } else {
              dispatch({
                type: "NEW_TYPE_EVENT",
                payload: {
                  ...formData,
                  id: res.data.idTypeEvent,
                },
              });
            }
          }
          handleClose();
        }
      );
    } else {
      setError(true);
    }
  }

  const changeAutocomplete = (procedure) => {
    setListProcedures([...listProcedures, procedure]);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="max-width-dialog-title"
      scroll="body"
    >
      <DialogContent>
        <div className="row">
          <div className="col-md-12 col-12">
            <h2 className="text-primary font-weight-semibold">
              {props.id ? "Editar" : "Adicionar"} Serviço
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={() => handleClose()}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>

          {provider_type === "56" ? (
            <div className="col-12">
              <TextField
                select
                label="Tipo:"
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                  let aux = listTypes.find((row) => row.id === e.target.value);
                  setDescription(aux.name);
                }}
                fullWidth
                margin="normal"
                error={error && !type ? true : false}
                InputLabelProps={{ shrink: true }}
              >
                {listTypes.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          ) : (
            <div className="col-12">
              <TextField
                label="Descrição:"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                margin="normal"
                error={error && !description ? true : false}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          )}

          <div className="col-12">
            <AutocompleteProcedure
              handleChangeAutocomplete={changeAutocomplete}
            />
          </div>
          {listProcedures.length > 0 && (
            <div className="col-md-12">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Procedimento</TableCell>
                    <TableCell>Realizar cobrança</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listProcedures.map((row, index) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell align="left" width="60%">
                          {row.description}
                        </TableCell>
                        <TableCell width="30%">
                          <Checkbox
                            checked={row.charge === "1"}
                            onChange={(e) =>
                              setListProcedures(
                                listProcedures.map((el, key) =>
                                  key === index
                                    ? {
                                        ...el,
                                        charge: e.target.checked ? "1" : "0",
                                      }
                                    : el
                                )
                              )
                            }
                            color="primary"
                          />
                        </TableCell>
                        <TableCell align="left" width="10%">
                          <Tooltip title="Excluir">
                            <IconButton
                              arial-label="Excluir"
                              color="secondary"
                              onClick={() =>
                                setListProcedures(
                                  listProcedures.filter(
                                    (el, key) => key !== index
                                  )
                                )
                              }
                            >
                              <i className="zmdi zmdi-delete" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}
          <div className="col-12 mt-2">
            <Grid
              component="label"
              container
              alignItems="center"
              fullWidth
              style={{ marginTop: 15 }}
            >
              <Grid item style={{ width: "20%" }}>
                <span
                  style={{
                    color: "#0000008a",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "1.3125rem",
                  }}
                >
                  Cor do Evento:
                </span>
              </Grid>
              <Grid item style={{ width: "80%", paddingRight: 20 }}>
                <div
                  className="cp-swatch ml-3"
                  style={{ width: "100%" }}
                  onClick={() =>
                    setState({
                      ...state,
                      displayColor: !state.displayColor,
                    })
                  }
                >
                  <div
                    className="cp-color"
                    style={{ backgroundColor: colorDefault, width: "100%" }}
                  />
                </div>
                {state.displayColor ? (
                  <div
                    className="cp-popover"
                    style={{ position: "fixed", width: 250, marginLeft: 15 }}
                  >
                    <div
                      className="cp-cover"
                      onClick={() =>
                        setState({
                          displayColor: !state.displayColor,
                        })
                      }
                    />
                    <SketchPicker
                      width="100%"
                      color={colorDefault}
                      onChange={(val) => {
                        setColor(val.hex);
                      }}
                    />
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </div>
          {provider_type !== "56" && (
            <div className="col-3">
              <MaskMoney
                label="Valor:"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          )}

          <div className="col-3">
            <TextField
              id="days_of_return"
              label="Dias para o retorno:"
              type="number"
              value={days_of_return}
              onChange={(e) => setDays(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="col-3">
            <TextField
              id="duration"
              label="Duração(Min):"
              type="time"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              fullWidth
              margin="normal"
            />
          </div>
          {/* <div className="col-3" style={{ marginTop: 30 }}>
            <label>Retorno automático</label>
            <Switch
              id="automatic_return"
              checked={automatic_return}
              onChange={handleChange("automatic_return")}
            />
          </div> */}
          {/* <div className="col-12">
            <TextField
              select
              label="Transação padrão:"
              value={transaction}
              onChange={(e) => setTransaction(e.target.value)}
              fullWidth
              margin="normal"
            >
              {listTransactions.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </TextField>
          </div> */}

          <div className="col-md-12" style={{ marginTop: "30px" }}>
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => handleClose()}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right" }}
              onClick={() => save()}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Salvar </span>
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default TypesEventForm;
