import React from "react";
import Api from "services/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as alertActions from "actions/Alerts";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormChildren from "./FormChildren";
import SweetAlert from "react-bootstrap-sweetalert";
import NoRecord from "components/Alert/NoRecord";

class Childrens extends React.Component {
  state = {
    open_modal: false,
    modal_delete: false,
    success_delete: false,
    success_add: false,
    parent_form: "",
    parents: [],
    edit: false,
  };

  componentDidMount() {
    this.getFamilyMembers();
  }

  getAge = (birthday) => {
    birthday = birthday.split("/");
    birthday = `${birthday[2]}-${birthday[1]}-${birthday[0]}`;
    var today = new Date();
    var birthDate = new Date(birthday);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  pushNewParent = (obj) => {
    obj.forEach((row) => {
      if (row.birthday) {
        row.birthday = this.getAge(row.birthday);
      } else {
        row.birthday = "--";
      }
    });
    this.setState({ parents: obj });
  };

  getFamilyMembers = () => {
    Api.post(`/microservice/profile/getFamilyMembersForUser/`, {
      user: this.props.user,
    })
      .then((res) => {
        res.data.record.forEach((row) => {
          if (row.birthday) {
            row.birthday = this.getAge(row.birthday);
          } else {
            row.birthday = "--";
          }
        });

        if (this.state.parents !== res.data.record) {
          this.setState({ parents: res.data.record });
        }
      })
      .catch(() => {
        this.props.viewAlertMessage(
          "error",
          "Ocorreu um erro, tente novamente 55!"
        );
        setTimeout(() => this.props.closeAlertMessage(), 5000);
        this.setState({ modal_delete: false, success_delete: false });
      });
  };

  isEmpty(obj) {
    return obj ? Object.keys(obj).length === 0 : true;
  }

  newChildren = () => {
    this.setState({ open_modal: true, parent_form: "" });
  };

  handleClose = () => {
    this.setState({ open_modal: false, edit: false });
  };

  editChildren(parent) {
    this.setState({ open_modal: true, parent_form: parent, edit: true });
  }

  alertDeleteParent(parent) {
    this.setState({ modal_delete: true, parent_form: parent });
  }

  onCancelDelete = () => {
    this.setState({ modal_delete: false, parent_form: "" });
  };

  deleteParent(id) {
    Api.post(`/microservice/profile/deleteParent/`, { id })
      .then((res) => {
        let id = this.state.parent_form.id;
        this.state.parents.forEach((row, key) => {
          if (row.id === id) {
            this.state.parents.splice(key, 1);
          }
        });
        this.setState({ modal_delete: false, success_delete: true });
      })
      .catch(() => {
        this.props.viewAlertMessage(
          "error",
          "Ocorreu um erro, tente novamente!"
        );
        setTimeout(() => this.props.closeAlertMessage(), 5000);
        this.setState({ modal_delete: false, success_delete: false });
      });
  }
  getUrlPhoto = (id, photo) => {
    if (photo) {
      return `https://s3.sa-east-1.amazonaws.com/salutem-webapp-files/upload/user/${id}/${photo}`;
    } else {
      return "https://balancer.salutemplus.com/images/avatar_user_men.jpg";
    }
  };

  render() {
    const parentsList = this.state.parents;
    const { parent_form, modal_delete, success_delete } = this.state;
    return (
      <div className="row">
        <div className="col-md-12 div-header">
          <span className="card-title text-primary title-form">
            Grupo Familiar
          </span>
          <div className="div-button">
            <Button
              onClick={() => this.newChildren()}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
            >
              <i className="zmdi zmdi-plus text-right" />{" "}
              <span> Adicionar</span>
            </Button>
          </div>
        </div>
        {!this.isEmpty(parentsList) ? (
          <Table className="table-history">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Grau</TableCell>
                <TableCell>Responsabilidade</TableCell>
                <TableCell>Idade</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parentsList.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <img
                      className="img-responsive rounded-circle mr-2"
                      src={this.getUrlPhoto(row.id, row.photo)}
                      alt={row.name}
                      style={{ width: "40px", height: "40px" }}
                    />
                    {row.name}
                  </TableCell>
                  <TableCell>{row.grau}</TableCell>
                  <TableCell>
                    {row.action ? row.action.split("|").join(" - ") : "---"}
                  </TableCell>
                  <TableCell>{row.birthday}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => this.editChildren(row)}
                      color="primary"
                      aria-label="Editar"
                    >
                      <i className="zmdi zmdi-edit zmdi-hc-fw" />
                    </IconButton>
                    <IconButton
                      onClick={() => this.alertDeleteParent(row)}
                      color="secondary"
                      aria-label="Remover"
                    >
                      <i className="zmdi zmdi-delete zmdi-hc-fw" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoRecord />
        )}

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={this.state.open_modal}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
          scroll="body"
        >
          <DialogContent>
            <FormChildren
              children={parent_form}
              edit={this.state.edit}
              handleClose={this.handleClose.bind(this)}
              pushNewParent={this.pushNewParent.bind(this)}
            />
          </DialogContent>
        </Dialog>
        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, excluir"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Excluir este Membro?"
          onConfirm={() => this.deleteParent(this.state.parent_form.idFg)}
          onCancel={this.onCancelDelete}
        />
        <SweetAlert
          show={success_delete}
          success
          title="Membro excluido com sucesso!"
          onConfirm={() => this.setState({ success_delete: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    childrens: state.Profile.childrens,
    user: state.auth.authUser,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(alertActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Childrens);
