import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink, withRouter } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import Chip from "@material-ui/core/Chip";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import * as Alerts from "actions/Alerts";

class SchedulingHomecare extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}${
      window.location.href.split("?")[1]
        ? `?${window.location.href.split("?")[1]}`
        : ""
    }`; // get current path
    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
    this.getActive();
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}${
      window.location.href.split("?")[1]
        ? `?${window.location.href.split("?")[1]}`
        : ""
    }`; // get current path
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
        this.closest(activeLi, "li").classList.add("active");
      }
    } catch (error) {}
    this.getActive();
  }

  getActive() {
    document.querySelectorAll(".nav-menu li a").forEach((el) => {
      if (el.href !== window.location.href) {
        el.classList.remove("active");
      } else {
        el.classList.add("active");
      }
    });
  }

  componentDidUpdate() {
    this.getActive();
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <CustomScrollbars className="scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">Agendamento - Home care</li>
          <li className="menu no-arrow">
            <NavLink
              to="/app/homecare/scheduling-professional"
              className="active"
            >
              <i className="zmdi zmdi-map zmdi-hc-fw" />
              <span className="nav-text">Rota de Profissionais</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/homecare/scheduling-patient">
              <i className="zmdi zmdi-calendar-check zmdi-hc-fw" />
              <span className="nav-text">Pacientes</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/homecare/schedule-professional">
              <i className="zmdi zmdi-account zmdi-hc-fw"></i>
              <span className="nav-text">Profissional</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/dashboard">
              <i className="zmdi zmdi-undo zmdi-hc-fw" />
              <span className="nav-text">Sair do Agendamento Home Care</span>
            </NavLink>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = (state) => ({
  visit_id: state.homecareProfessional.home_care_visit,
  type: state.auth.type,
  address_selected: state.auth.address_selected,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...Alerts }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SchedulingHomecare)
);
