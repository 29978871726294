import React from "react";
import { useSelector } from "react-redux";

// import DashMobile from "./DashMobile";
import DashFeedMobile from "./DashFeedMobile";
import DashDesktop from "./DashDesktop";

export default function Patient({ match, history }) {
  const width = useSelector((state) => state.settings.width);

  return <DashFeedMobile match={match} history={history} />;
  // if (width < 500) {
  //   return <DashFeedMobile match={match} history={history} />;
  // } else {
  //   return <DashDesktop />;
  // }
}
