import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { allowCPF } from "containers/Register/Functions";
import Api from "services/api";
import { showMsg } from "services/functions";

import MaskPhone from "components/Masks/MaskPhone";
import MaskCellphone from "components/Masks/MaskCellphone";
import MaskCpf from "components/Masks/MaskCpf";
import BarProgress from "components/BarProgress";
import { BtnSave, BtnCan } from "components/Button";
import { ModalResponsive } from "components/ModalResponsive";
import { TextField, MenuItem } from "@material-ui/core";

export default function RegisterProfessional({ open, handleClose, add, name }) {
  const dispatch = useDispatch();
  const initialState = {
    name,
    genderVal: "",
    cpf: "",
    email: "",
    phone: "",
    smartphone: "",
    birthday: null,
    area: "",
    council: "",
    councilName: "",
    councilNumber: "",
    specialty: "",
    uf: "",
    year: "",
  };
  const [error, setError] = useState(false);
  const [profnl, setProfnl] = useState(initialState);
  const [allCouncil, setAllCouncil] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [allArea, setAllArea] = useState([]);
  const [allSpecialty, setAllSpecialty] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMoreInfo();
  }, []);

  useEffect(() => {
    handleChange("name", name);
  }, [name]);

  const handleSubmit = (e) => {
    if (validate()) {
      saveNewProfnl();
    }
    e && e.preventDefault();
  };

  const handlecloseModal = () => {
    setProfnl(initialState);
    setError(false);
    handleClose();
  };

  const handleChange = (field, value) => {
    setProfnl({ ...profnl, [`${field}`]: value });
  };

  const CheckCpf = async () => {
    let { cpf } = profnl;
    if (cpf) {
      if (allowCPF(cpf)) {
        try {
          const { data } = await Api.post(`/ms_system/user/checkCPF`, { cpf });
          if (data.record.length !== 0) {
            showMsg("error", "CPF já cadastrado!");
            handleChange("cpf", "");
          }
        } catch (error) {
          showMsg(
            "error",
            "Algo deu errado, insira o CPF novamente!",
            dispatch
          );
          handleChange("cpf", "");
        }
      } else {
        showMsg("error", "CPF Inválido", dispatch);
        handleChange("cpf", "");
      }
    }
  };

  const getMoreInfo = async () => {
    try {
      const { data } = await Api.post(`microservice/forward/getArea`);
      setAllArea(data.area);
      setAllCouncil(data.council);
      setStatesList(data.states);
    } catch (error) {
      showMsg(
        "error",
        "Algo deu errado! Por favor tente novamente mais tarde",
        dispatch
      );
    }
  };

  const getSpecialty = async () => {
    try {
      const { data } = await Api.post(`microservice/forward/getSpecialty`, {
        area: profnl.area,
      });
      setAllSpecialty(data);
    } catch (error) {
      showMsg(
        "error",
        "Algo deu errado! Por favor tente novamente mais tarde",
        dispatch
      );
    }
  };

  const validate = () => {
    if (
      !profnl.name ||
      !profnl.area ||
      !profnl.specialty ||
      !profnl.genderVal ||
      !validateContact()
    ) {
      setError(true);
      showMsg("error", "Complete as informações necessárias!", dispatch);
      return false;
    } else {
      return true;
    }
  };

  const validateContact = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      re.test(String(profnl.email).toLowerCase()) ||
      (profnl.smartphone.length === 15 &&
        profnl.smartphone.indexOf("_") === -1) ||
      (profnl.phone.length === 14 && profnl.phone.indexOf("_") === -1)
    );
  };

  const saveNewProfnl = () => {
    setLoading(true);
    Api.post(`/ms_system/user/fastRegisterProfessional`, { ...profnl })
      .then(({ data }) => {
        if (data.success) {
          add({
            ...profnl,
            id: data.id,
            speciality: allSpecialty.find((sp) => sp.id === profnl.specialty)
              .name,
            area_id: profnl.area,
          });
          handlecloseModal();
        } else {
          showMsg("error", "Erro ao salvar!", dispatch);
        }
      })
      .catch(() => {
        showMsg("error", "Erro ao salvar!", dispatch);
      })
      .finally(() => setLoading(false));
  };

  return (
    <ModalResponsive
      title={"Cadastro de Profissional"}
      open={open}
      close={handleClose}
      buttons={
        !loading && (
          <div className="col-12">
            <BtnCan title={"fechar"} onClick={handlecloseModal} float="left" />
            <BtnSave title={"Salvar"} onClick={handleSubmit} float="right" />
          </div>
        )
      }
    >
      {loading && <BarProgress />}
      {!loading && (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="py-2 col-12">
              <TextField
                label="Nome:"
                value={profnl.name}
                onChange={(e) => handleChange("name", e.target.value)}
                fullWidth
                required
                error={error && !profnl.name}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="py-2 col-md-4 col-12">
              <TextField
                select
                label="Sexo:"
                value={profnl.genderVal}
                onChange={(e) => handleChange("genderVal", e.target.value)}
                fullWidth
                required
                error={error && !profnl.genderVal}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem key="1" value="0">
                  Masculino
                </MenuItem>
                <MenuItem key="2" value="1">
                  Feminino
                </MenuItem>
                <MenuItem key="3" value="2">
                  Outro
                </MenuItem>
              </TextField>
            </div>
            <div className="py-2 col-md-4 col-12">
              <TextField
                type="date"
                label="Data de nascimento:"
                value={profnl.birthday}
                onChange={(e) => handleChange("birthday", e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="py-2 col-md-4 col-12">
              <TextField
                label="CPF:"
                fullWidth
                InputProps={{
                  inputComponent: MaskCpf,
                  value: profnl.cpf,
                  onChange: (e) => handleChange("cpf", e.target.value),
                }}
                onBlur={CheckCpf}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="py-2 col-12">
              <TextField
                label="E-mail:"
                value={profnl.email}
                onChange={(e) => handleChange("email", e.target.value)}
                fullWidth
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
                error={error && !validateContact()}
              />
            </div>
            <div className="py-2 col-md-6 col-12">
              <TextField
                label="Celular:"
                fullWidth
                InputProps={{
                  inputComponent: MaskCellphone,
                  value: profnl.smartphone,
                  onChange: (e) => handleChange("smartphone", e.target.value),
                }}
                error={error && !validateContact()}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="py-2 col-md-6 col-12">
              <TextField
                label="Telefone de contato:"
                fullWidth
                InputProps={{
                  inputComponent: MaskPhone,
                  value: profnl.phone,
                  onChange: (e) => handleChange("phone", e.target.value),
                }}
                error={error && !validateContact()}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="py-2 col-md-6 col-12">
              <TextField
                select
                label="Area:"
                fullWidth
                value={profnl.area}
                onBlur={getSpecialty}
                onChange={(e) => handleChange("area", e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                error={error && !profnl.area}
                required
              >
                {allArea.map((area) => (
                  <MenuItem key={area.id} value={area.id}>
                    {area.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="py-2 col-md-6 col-12">
              <TextField
                select
                label="Especialidade:"
                fullWidth
                value={profnl.specialty}
                onChange={(e) => handleChange("specialty", e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                error={error && !profnl.specialty}
                required
              >
                {allSpecialty.map((specialty) => (
                  <MenuItem key={specialty.id} value={specialty.id}>
                    {specialty.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="py-2 col-md-6 col-12">
              <TextField
                select
                label="Conselho:"
                fullWidth
                value={profnl.council}
                onChange={(e) => {
                  setProfnl({
                    ...profnl,
                    council: e.target.value,
                    councilName: allCouncil.find(
                      (council) => council.id === e.target.value
                    ).name,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {allCouncil.map((council) => (
                  <MenuItem key={council.id} value={council.id}>
                    {council.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="py-2 col-md-6 col-12">
              <TextField
                label="Número do Conselho:"
                value={profnl.councilNumber}
                onChange={(e) => handleChange("councilNumber", e.target.value)}
                type="number"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-md-6 col-12">
              <TextField
                select
                id="uf"
                label="UF"
                margin="normal"
                fullWidth
                value={profnl.uf}
                onChange={(e) => handleChange("uf", e.target.value)}
                InputLabelProps={{ shrink: true }}
              >
                {statesList.length > 0 &&
                  statesList.map((row) => (
                    <MenuItem value={row.fk_ls_state} key={row.fk_ls_state}>
                      {row.state}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="col-md-6 col-12">
              <TextField
                type="number"
                label="Data de Expedição"
                margin="normal"
                fullWidth
                value={profnl.year}
                onChange={(e) => handleChange("year", e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </form>
      )}
    </ModalResponsive>
  );
}
