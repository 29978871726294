import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Button } from "@material-ui/core";
import Api from "services/api";

export default function ProviderData({
  handleNext,
  getState,
  stateInit,
  validateData,
}) {
  const [state, setState] = useState(stateInit);
  const [emailSGNPC, setEmailSGNPC] = useState();
  const [passwordSGNPC, setPasswordSGNPC] = useState();
  const [modalEmail, setModalEmail] = useState(false);
  const [validaCnpj, setValidateCnpj] = useState(validateData.cnpj);
  const [validaUserName, setValidateUsername] = useState(validateData.cnpj);
  const [validaEmail, setValidateEmail] = useState(validateData.email);
  const {
    name,
    username,
    cnpj,
    type,
    email,
    cellphone,
    phone,
    password,
    confirmPassword,
    error,
  } = state;

  const validate = async () => {
    await verifyState();
  };

  const verifyState = async () => {
    if (validaEmail && emailSGNPC && passwordSGNPC) {
      setState({ ...state, error: false });
      getState(state, "providerData");
      // getState(
      //   { cnpj: validaCnpj, username: validaUserName, email: validaEmail },
      //   "validate"
      // );

      await Api.post("/microservice/prescription/getWebService/", {
        emailSGNPC,
        passwordSGNPC,
      }).then(({ res }) => {});

      handleNext();
    } else {
      setState({ ...state, error: true });
    }
  };

  const checkEmail = () => {
    setValidateEmail(false);
    if (emailSGNPC)
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailSGNPC)) {
        setValidateEmail(false);
        return false;
      } else {
        setValidateEmail(true);
        setModalEmail(true);

        // Api.post(`/ms_system/user/checkEmail`, { email: emailSGNPC }).then(
        //   (res) => {
        //     if (res.data.record.length !== 0) {
        //       setModalEmail(true);
        //     }
        //   }
        // );
        return true;
      }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <TextField
            id="email"
            label="E-mail SGNPC"
            required
            margin="normal"
            fullWidth
            value={emailSGNPC}
            onBlur={checkEmail}
            onChange={(e) => setEmailSGNPC(e.target.value)}
            helperText={
              error && !email
                ? "Campo Obrigatório."
                : !validaEmail && emailSGNPC
                ? "E-mail Invalido."
                : ""
            }
            error={error && !emailSGNPC ? true : false}
          />
        </div>

        <div className="col-md-4">
          <TextField
            id="senha"
            label="Senha SGNPC"
            required
            type="password"
            margin="normal"
            fullWidth
            value={passwordSGNPC}
            onChange={(e) => setPasswordSGNPC(e.target.value)}
            helperText={error && !passwordSGNPC ? "Campo Obrigatório" : ""}
            error={error && !passwordSGNPC ? true : false}
          />
        </div>
      </div>

      <div className="align-center mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={validate}
          className="jr-btn"
          style={{ float: "right", marginRight: 0 }}
        >
          Próxima
        </Button>
        {/* <NavLink to="/signup" style={{ float: "left", marginLeft: 0 }}>
          <Button className="jr-btn">Voltar</Button>
        </NavLink> */}
      </div>
    </>
  );
}
