import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as patientInfoActions from "actions/consult/PatientInfo";
import * as appointmentActions from "actions/consult/Appointment";
import {
  showModalConfig,
  getRecordSidenavConsult
} from "actions/Configuration";
import { NavLink, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import Button from "@material-ui/core/Button";
import HistoryPatient from "components/HistoryPatient";
import Stopwatch from "../Stopwatch";
import SweetAlert from "react-bootstrap-sweetalert";
import Tags from "../Tags";
import HistoryIcon from "@material-ui/icons/History";
import { Chip, Tooltip, IconButton } from "@material-ui/core";

class SidenavNutrition extends Component {
  state = {
    modal_finish: false
  };

  componentDidUpdate() {
    if (this.props.finish) {
      this.props.stopRedirect();
      this.props.history.push("/app/choose/data-consult");
    }
    // code para deixar o submenu aberto
    const { history } = this.props;
    const pathname = `${history.location.pathname}${history.location.search}`; // get current path
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentDidMount() {
    this.props.getInfoPatient(this.props.patient);
    if (!this.props.config_sidenav_consult.loaded) {
      this.props.getRecordSidenavConsult(this.props.user);
    }

    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}${history.location.search}`; // get current path
    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector"
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }
  render() {
    const {
      start,
      appointment_id,
      modal_finish,
      modal_correction,
      permissions,
      modal_pause,
      config_sidenav_consult,
      config_system_review
    } = this.props;
    const isset = config_sidenav_consult.record.length > 0 ? true : false;
    const forms = isset ? config_sidenav_consult.record[0].view.split(",") : [];
    const { patient } = this.props.patientInfo;

    return (
      <CustomScrollbars className="scrollbar">
        <ul className="nav-menu" style={{ marginTop: "10px" }}>
          {/* CONSULTÓRIO */}
          <div className="text-center">
            <img
              id="img-patient"
              className="rounded-circle size-60 avatar-shadow mb-3"
              src={patient.photo}
              alt={patient.name}
              style={{ float: "left", marginLeft: "20px" }}
            />
            <div className="jr-card-hd-content">
              <Tooltip title={`Nº Tel.: ${patient.smartphone}`}>
                <h5 id="name-patient" className="mb-0 text-white">
                  {patient.name}
                </h5>
              </Tooltip>
              <p className="jr-fs-xs mb-0 text-grey text-lighten-2">
                {patient.email}
              </p>
              <Chip
                className="m-1"
                size="small"
                color="primary"
                onClick={() => this.props.viewHistory("personalData")}
                icon={<HistoryIcon />}
                label="Histórico"
              />
            </div>
          </div>
          <div className="text-center">
            <li className="nav-header p-0 ml-2 mt-3">Atendimento</li>
            {start && <Stopwatch init={start} />}
            {!appointment_id ? (
              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                <Button
                  className="jr-btn jr-btn-xs"
                  onClick={() => {
                    this.props.alterPatient();
                    this.props.history.push("/app/choose/data-consult");
                  }}
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  <i className="zmdi zmdi-close zmdi-hc-fw zmdi-hc-lg" />
                  <span>Alterar paciente</span>
                </Button>
              </div>
            ) : (
              <div className="row">
                {this.props.authType === "2" ? (
                  <div
                    className="col-6"
                    style={{ paddingLeft: "25px", paddingRight: "5px" }}
                  >
                    <Button
                      className="jr-btn jr-btn-xs"
                      onClick={() => this.props.modalCorrection(true)}
                      color="secondary"
                      variant="contained"
                      fullWidth
                    >
                      <i className="zmdi zmdi-close zmdi-hc-fw zmdi-hc-lg mr-2" />
                      <span>Enviar para correção</span>
                    </Button>
                  </div>
                ) : (
                  <div
                    className="col-6"
                    style={{ paddingLeft: "25px", paddingRight: "5px" }}
                  >
                    <Button
                      className="jr-btn jr-btn-xs"
                      onClick={() => this.props.modalFinish(true)}
                      color="secondary"
                      variant="contained"
                      fullWidth
                    >
                      <i className="zmdi zmdi-close zmdi-hc-fw zmdi-hc-lg mr-2" />
                      <span>Finalizar atendimento</span>
                    </Button>
                  </div>
                )}
                <div
                  className="col-6"
                  style={{ paddingLeft: "5px", paddingRight: "25px" }}
                >
                  <Button
                    className="jr-btn jr-btn-xs"
                    onClick={() => this.props.modalPause(true)}
                    color="default"
                    variant="contained"
                    fullWidth
                  >
                    <i className="zmdi zmdi-stop zmdi-hc-fw zmdi-hc-lg mr-2" />
                    <span>Pausar atendimento</span>
                  </Button>
                </div>
              </div>
            )}
            <div className="row">
              <Tags />
            </div>
            <div className="col-12">
              <Tooltip title="Configurações" placement="top">
                <IconButton
                  className="jr-btn jr-btn-md"
                  variant="contained"
                  color="default"
                  onClick={() => this.props.showModalConfig("sidenav_consult")}
                  fullWidth
                  style={{ color: "white" }}
                >
                  <i className="zmdi zmdi-settings zmdi-hc-fw zmdi-hc-lg mr-2" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {/* FERRAMENTAS */}

          <li className="menu no-arrow">
            <NavLink to={`/app/nutrition/`}>
              <span className="nav-text"> Dados Pessoais</span>
            </NavLink>
          </li>
          <li className="menu">
            <Button>
              <span className="nav-text">Atendimento</span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink to="/app/nutrition/attendance-record">
                  <span className="nav-text"> Informações Iniciais</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/nutrition/attendance-return">
                  <span className="nav-text"> Retorno </span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu">
            <Button>
              <span className="nav-text">Anamnese</span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink to="/app/nutrition/anamnese/patology">
                  <span className="nav-text"> Patologias</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/nutrition/anamnese/procedures">
                  <span className="nav-text"> Procedimentos e Cirurgias </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/nutrition/anamnese/dysfunctions">
                  <span className="nav-text">Disfunções Funcionais </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/nutrition/anamnese/women-health">
                  <span className="nav-text"> Saúde da Mulher </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/nutrition/anamnese/family-history">
                  <span className="nav-text"> Histórico Familiar </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/nutrition/anamnese/child-disease">
                  <span className="nav-text"> Doenças da Infância </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/nutrition/anamnese/medicines">
                  <span className="nav-text"> Medicamentos </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/nutrition/anamnese/personal-habits">
                  <span className="nav-text"> Hábitos Pessoais </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/nutrition/anamnese/food-history">
                  <span className="nav-text"> História Alimentar </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/nutrition/anamnese/fisiologic-habits">
                  <span className="nav-text"> Hábitos Fisiológicos </span>
                </NavLink>
              </li>
            </ul>
          </li>
          {/* <li className="menu no-arrow">
            <NavLink to={`/app/nutrition/personal-data`}>
              <span className="nav-text"> Exames</span>
            </NavLink>
          </li> */}
          <li className="menu no-arrow">
            <NavLink to={`/app/nutrition/bristol`}>
              <span className="nav-text"> Escala de Bristol</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to={`/app/nutrition/anthropometry`}>
              <span className="nav-text"> Antropometria</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to={`/app/nutrition/food-plan`}>
              <span className="nav-text"> Plano Alimentar</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to={`/app/nutrition/food-reminder`}>
              <span className="nav-text"> Recordatório Alimentar</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to={`/app/nutrition/conduct`}>
              <span className="nav-text"> Conduta</span>
            </NavLink>
          </li>
          {/* <li className="menu no-arrow">
            <NavLink to={`/app/nutrition/personal-data`}>
              <span className="nav-text"> Planejamento Alimentar</span>
            </NavLink>
          </li> */}
        </ul>

        <HistoryPatient id_patient={patient.id} />
        <SweetAlert
          show={modal_finish}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, finalizar"
          confirmButtonColor="#bc0003"
          cancelBtnText="Não"
          title="Finalizar este atendimento?"
          onConfirm={() => this.props.finishAppointment(appointment_id)}
          onCancel={() => this.props.closeModalFinish()}
        />
        <SweetAlert
          show={modal_pause}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText={"Sim, pausar"}
          confirmButtonColor="#bc0003"
          cancelBtnText="Não"
          title={"Pausar este atendimento?"}
          onConfirm={() => this.props.pauseAppointment(false)}
          onCancel={() => this.props.closeModalPause()}
        />
        <SweetAlert
          show={modal_correction}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText={"Sim, enviar"}
          confirmButtonColor="#bc0003"
          cancelBtnText="Não"
          title={"Enviar para correção?"}
          onConfirm={() => this.props.pauseAppointment(true)}
          onCancel={() => this.props.closeModalCorrection()}
        />
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = state => {
  return {
    patient: state.appointment.patient,
    modal_finish: state.appointment.modal_finish,
    modal_pause: state.appointment.modal_pause,
    modal_correction: state.appointment.modal_correction,
    toApprove: state.appointment.toApprove,
    finish: state.appointment.finish,
    start: state.appointment.start,
    appointment_id: state.appointment.id,
    patientInfo: state.patientInfo,
    user: state.auth.authUser,
    authArea: state.auth.area,
    authType: state.auth.type,
    permissions: state.auth.permissionsConsult,
    config_sidenav_consult: state.configuration.config_sidenav_consult,
    config_system_review: state.configuration.config_system_review
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...patientInfoActions,
      ...appointmentActions,
      showModalConfig,
      getRecordSidenavConsult
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SidenavNutrition)
);
