import React from "react";
import DataTable from "components/Tables/DataTable";

export default function ExamsProfessional({ record }) {
  return (
    <DataTable
      name="Pedidos de Exame"
      columns={["Tipo do Exame", "Número de Pedidos"]}
      rows={record.map((row, index) => [row.type_exam, row.total])}
      hideSearch={true}
    />
  );
}
