import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";

import { IconButton } from "@material-ui/core";
import ViewIcon from "@material-ui/icons/Visibility";
import Table from "components/Tables/DataTable";
import BarProgress from "components/BarProgress";
import ViewPrescription from "./ViewPrescription";
import ComponentToPrintt from "app/routes/consult/routes/print";

export default function Maipulated({ patient, doctor }) {
  const dispatch = useDispatch();
  const address = useSelector(({ auth }) => auth.address);
  const [edit, setEdit] = useState("");
  const [recordPresc, setRecordPresc] = useState(null);
  const [loader, setLoader] = useState(false);
  const [record, setRecord] = useState([]);

  useEffect(() => {
    getRecords();
  }, []);

  useEffect(() => {
    edit && getPrescriptionRecord();
  }, [edit]);

  function getRecords() {
    setLoader(true);
    Api.post("microservice/prescriptionmanipulated/getHistory", { patient })
      .then(({ data }) => {
        if (data.success) {
          setRecord(data.record);
        } else {
          throw true;
        }
      })
      .catch(() => {
        showMsg(
          "error",
          "Não foi possível encontrar suas prescrições para este paciente",
          dispatch
        );
      })
      .finally(() => {
        setLoader(false);
      });
  }

  function getPrescriptionRecord() {
    setLoader(true);
    Api.post("microservice/prescriptionmanipulated/getAllRecordsPrescription", {
      id: edit,
    })
      .then(({ data }) => {
        if (data.success) {
          setRecordPresc(data.record);
        } else {
          throw true;
        }
      })
      .catch(() => {
        showMsg(
          "error",
          "Não foi possível buscar os dados desta prescrição",
          dispatch
        );
        setEdit("");
      })
      .finally(() => {
        setLoader(false);
      });
  }
  return (
    <div className="container-person border">
      <h3
        className="title-container d-flex justify-content-center"
        style={{
          fontSize: "26px",
          fontWeight: "400",
        }}
      >
        Prescrição Médica
        {edit ? (
          <IconButton
            onClick={() => setEdit("")}
            color="primary"
            aria-label="Visualizar"
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-undo" />
          </IconButton>
        ) : (
          ""
        )}
      </h3>
      {loader && <BarProgress />}
      {!loader && !edit && (
        <Table
          columns={["Profissional", "Prescrição", "Data", "Ações"]}
          rows={record.map((row) => [
            row.doc_name,
            row.name,
            row.date,
            <>
              {row.doc_id === doctor && (
                <ComponentToPrintt
                  dados={{
                    name: "manipulated",
                    id: row.id_presc,
                    type: "iconButton",
                  }}
                  patient={patient}
                  doctor={row.doc_id}
                  address={address}
                  tooltip={{
                    placement: "top",
                    title: "Imprimir prescrição",
                  }}
                />
              )}
              <IconButton
                onClick={() => {
                  setEdit(row.id_presc);
                }}
                color="primary"
              >
                <ViewIcon color="primary" />
              </IconButton>
            </>,
          ])}
        />
      )}
      {!loader && edit && recordPresc && (
        <ViewPrescription record={recordPresc} />
      )}
    </div>
  );
}
