import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton
} from "@material-ui/core";
import * as FormGroupActions from "actions/consult/ExamsGroup";
import ViewMedicinesGroups from "./ViewMedicinesGroups";
import FormMedicinesGroups from "./FormMedicinesGroups";

class ExamsGroup extends Component {
  constructor(props) {
    super(props);
    this.handleAdd = this.handleAdd.bind(this);
  }

  handleAdd() {
    if (this.props.MedicinesGroups.active === "view") {
      this.props.handleAddFunction(
        "form",
        "secondary",
        "cancelar",
        true,
        "close"
      );
    } else {
      this.props.handleAddFunction(
        "view",
        "primary",
        "Adicionar",
        "hidden",
        "plus"
      );
    }
  }

  componentDidMount() {
    this.props.requestListDoctorExamsGroups(this.props.doctor);
  }

  render() {
    return (
      <div className="row">
        <h2 className="text-primary font-weight-semibold w-100">
          Meus grupos de medicamentos
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={this.props.close}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
        <div className="col-12">
          <Button
            className="jr-btn jr-btn-sm"
            variant="contained"
            style={{ float: "right" }}
            color={this.props.MedicinesGroups.color}
            onClick={this.handleAdd}
          >
            <i
              className={`zmdi zmdi-${this.props.MedicinesGroups.icon} zmdi-hc-lg `}
            />
            <span>{this.props.MedicinesGroups.texto}</span>
          </Button>
        </div>
        <div className="col-md-12">
          {this.props.MedicinesGroups.active === "view" ? (
            <ViewMedicinesGroups />
          ) : (
            <Dialog
              fullWidth={true}
              maxWidth="lg"
              open={true}
              onClose={() => {}}
              aria-labelledby="max-width-dialog-title"
              scroll="body"
            >
              <DialogContent>
                <FormMedicinesGroups />
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    doctor: state.auth.authUser,
    MedicinesGroups: state.MedicinesGroups
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(FormGroupActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamsGroup);
