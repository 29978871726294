import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Paper, MenuItem } from "@material-ui/core";
import Downshift from "downshift";
import Api from "services/api";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%"
  },
  root: {
    backgroundColor: "#fff"
  },
  selected: {
    backgroundColor: "#e2e2e8 !important"
  }
};

function AutocompleteOcuppation({ classes, ocuppation, change }) {
  const [suggestions, setSuggestions] = useState([]);

  function getSuggestions(e) {
    if (e.target.value.length > 2) {
      Api.post(`/microservice/autocomplete/getOcuppations/`, {
        term: e.target.value
      }).then(res => setSuggestions(res.data));
    }
  }

  function renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      >
        {suggestion.name}
      </MenuItem>
    );
  }

  function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        InputLabelProps={{ shrink: true }}
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input
          },
          ...InputProps
        }}
        onKeyUp={e => getSuggestions(e)}
        onChange={() => {}}
      />
    );
  }

  return (
    <Downshift
      onSelect={selectedItem => {
        let item = suggestions.filter(row => {
          return row.name === selectedItem;
        });
        if (item.length > 0) {
          change("fk_ocuppation", item[0].id);
          change("ocuppation", item[0].name);
        }
      }}
      selectedItem={ocuppation ? ocuppation.name : null}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex
      }) => (
        <div className={classes.container}>
          {renderInput({
            fullWidth: true,
            classes,
            InputProps: getInputProps({
              id: "ocuppation"
            }),
            label: "Ocupação:",
            margin: "normal"
          })}
          {isOpen ? (
            <Paper square>
              {suggestions.map((suggestion, index) =>
                renderSuggestion({
                  suggestion,
                  index,
                  itemProps: getItemProps({
                    item: suggestion.name
                  }),
                  highlightedIndex,
                  selectedItem
                })
              )}
            </Paper>
          ) : null}
        </div>
      )}
    </Downshift>
  );
}

AutocompleteOcuppation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AutocompleteOcuppation);
