import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  TextField,
  Button,
  MenuItem,
} from "@material-ui/core";
import Api from "services/api";
import { useSelector } from "react-redux";

export default function ModalCreateMedicine({ open, user, onSave, close }) {
  const initial = {
    name_display: "",
    dosage: "",
    form: "",
    mode_all: "",
    active_principle: "",
    unit_measure: "",
    targe: "",
    error: false,
  };

  const [state, setState] = useState(initial);

  useEffect(() => {
    if (open) {
      setState({ ...initial, name_display: open });
    } else {
      setState({ ...initial });
    }
  }, [open]);

  const save = () => {
    if (
      state.name_display &&
      state.dosage &&
      state.form &&
      state.mode_all &&
      state.unit_measure
    ) {
      Api.post(`/microservice/prescription/createMedicine/`, {
        user,
        data: state,
      }).then((res) => {
        onSave({
          ...state,
          id: res.data.medicine,
          formulation: state.dosage,
          laboratory_id: "7081",
          laboratory_display: "Laboratório Desconhecido",
        });
        close();
      });
    } else {
      setState({ ...state, error: true });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => close()}
      aria-labelledby="max-width-dialog-title"
      scroll="body"
    >
      <DialogContent>
        <div className="row">
          <div className="col-md-12 col-12">
            <h2 className="text-primary font-weight-semibold">
              Cadastrar Medicamento
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={() => close()}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>

          <div className="col-md-12">
            <TextField
              id="name"
              label="Nome:"
              value={state.name_display}
              onChange={(e) =>
                setState({ ...state, name_display: e.target.value })
              }
              fullWidth
              margin="normal"
              autoFocus
              error={state.error && !state.name_display}
            />
          </div>
          <div className="col-md-4 col-xs-6">
            <TextField
              id="dosage"
              label="Dosagem:"
              value={state.dosage}
              onChange={(e) => setState({ ...state, dosage: e.target.value })}
              fullWidth
              margin="normal"
              error={state.error && !state.dosage}
            />
          </div>
          <div className="col-md-4 col-xs-6">
            <TextField
              id="form"
              label="Formulação:"
              value={state.form}
              onChange={(e) => setState({ ...state, form: e.target.value })}
              fullWidth
              margin="normal"
              error={state.error && !state.form}
            />
          </div>
          <div className="col-md-4 col-xs-6">
            <TextField
              id="mode_all"
              label="Via Adm.:"
              value={state.mode_all}
              onChange={(e) => setState({ ...state, mode_all: e.target.value })}
              fullWidth
              margin="normal"
              error={state.error && !state.mode_all}
            />
          </div>
          <div className="col-md-4 col-xs-6">
            <TextField
              id="mode_all"
              label="Unidade de medida:"
              value={state.unit_measure}
              onChange={(e) =>
                setState({ ...state, unit_measure: e.target.value })
              }
              fullWidth
              margin="normal"
              error={state.error && !state.unit_measure}
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <TextField
              id="active_principle"
              label="Princípio ativo:"
              value={state.active_principle}
              onChange={(e) =>
                setState({ ...state, active_principle: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <TextField
              select
              id="targe"
              label="Tarja:"
              value={state.targe}
              onChange={(e) => setState({ ...state, targe: e.target.value })}
              fullWidth
              margin="normal"
            >
              <MenuItem key="SE" value="SE">
                Tarja Branca
              </MenuItem>
              <MenuItem key="SE" value="TV">
                Tarja Vermelha
              </MenuItem>
              <MenuItem key="SE" value="TP">
                Tarja Preta
              </MenuItem>
            </TextField>
          </div>

          <div className="col-md-12" style={{ marginTop: "30px" }}>
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => close()}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right" }}
              onClick={() => save()}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Salvar </span>
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
