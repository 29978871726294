import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Avatar, IconButton, Typography, Tooltip } from "@material-ui/core";
import { getUrlPhoto } from "services/functions";
import ViewEvent from "./ViewEvent";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ClearIcon from "@material-ui/icons/Clear";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import PhonelinkOffIcon from "@material-ui/icons/PhonelinkOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import moment from "moment";
import Api from "services/api";
import SweetAlert from "react-bootstrap-sweetalert";
import ColorizeIcon from "@material-ui/icons/Colorize";
// import "./styles.css";

export default function CalendarPatient({ user }) {
  const [modalView, setModalView] = useState({ open: false, event: "" });
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [state, setState] = useState({});
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    id: "",
    time: "",
    type: "",
    value: "",
  });
  const save = useSelector((state) => state.prescription.save_medicine);

  useEffect(() => {
    getData();
  }, [date]);

  useEffect(() => {
    // Usado para carregar os medicamentos após salvar no ModalAdd
    if (save) {
      getData();
    }
  }, [save]);

  const getData = () => {
    Api.post("/ms_system/dashboard/getCompromissesPatient", {
      user,
      date,
    }).then((res) => {
      let aux = {};

      res.data.medicines.forEach((element) => {
        if (
          element.realized !== "1" &&
          moment()
            .add(1, "hour")
            .isAfter(moment(`${moment().format("YYYY-MM-DD")} ${element.hour}`))
        ) {
          if (typeof aux[element.hour] !== "undefined") {
            aux[element.hour] = [
              ...aux[element.hour],
              { ...element, type: "1" },
            ];
          } else {
            aux[element.hour] = [{ ...element, type: "1" }];
          }
        }
      });

      res.data.pauses.forEach((element) => {
        if (
          element.realized !== "1" &&
          moment()
            .add(1, "hour")
            .isAfter(moment(`${moment().format("YYYY-MM-DD")} ${element.time}`))
        ) {
          if (typeof aux[element.time] !== "undefined") {
            aux[element.time] = [
              ...aux[element.time],
              { ...element, type: "0" },
            ];
          } else {
            aux[element.time] = [{ ...element, type: "0" }];
          }
        }
      });

      res.data.activities.forEach((element) => {
        if (
          element.realized !== "1" &&
          moment()
            .add(1, "hour")
            .isAfter(moment(`${moment().format("YYYY-MM-DD")} ${element.time}`))
        ) {
          if (typeof aux[element.time] !== "undefined") {
            aux[element.time] = [
              ...aux[element.time],
              { ...element, type: "2" },
            ];
          } else {
            aux[element.time] = [{ ...element, type: "2" }];
          }
        }
      });

      res.data.events.forEach((element) => {
        if (
          element.realized !== "1" &&
          moment()
            .add(1, "hour")
            .isAfter(moment(`${moment().format("YYYY-MM-DD")} ${element.hour}`))
        ) {
          if (typeof aux[element.hour] !== "undefined") {
            aux[element.hour] = [
              ...aux[element.hour],
              { ...element, type: "3" },
            ];
          } else {
            aux[element.hour] = [{ ...element, type: "3" }];
          }
        }
      });

      res.data.compromisses.forEach((element) => {
        if (
          element.realized !== "1" &&
          moment()
            .add(1, "hour")
            .isAfter(moment(`${moment().format("YYYY-MM-DD")} ${element.hour}`))
        ) {
          if (typeof aux[element.hour] !== "undefined") {
            aux[element.hour] = [
              ...aux[element.hour],
              { ...element, type: "3" },
            ];
          } else {
            aux[element.hour] = [{ ...element, type: "4" }];
          }
        }
      });
      setState(aux);
    });
  };

  const getClassImage = (type) => {
    let imgIcon = "";
    switch (type) {
      case "Similar":
        imgIcon = require("assets/images/consult/similar.jpg");
        break;
      case "Generico":
        imgIcon = require("assets/images/consult/generico.jpg");
        break;
      case "Referencia":
      case "Especifico":
        imgIcon = require("assets/images/consult/referencia.jpg");
        break;
      case "Biológicos":
        imgIcon = require("assets/images/consult/biologico.jpg");
        break;
      default:
        imgIcon = require("assets/images/consult/outros.jpg");
        break;
    }
    return imgIcon;
  };

  const getTarge = (targe) => {
    if (targe === "TP") {
      return require("assets/images/consult/Preta.svg");
    } else if (targe === "TV") {
      return require("assets/images/consult/Vermelha.svg");
    } else {
      return require("assets/images/consult/Branca.svg");
    }
  };

  const getIcon = (icon) => {
    switch (icon) {
      case "4662":
        return <LocalDrinkIcon />;
      case "4657":
        return <EmojiPeopleIcon />;
      case "4659":
        return <DirectionsWalkIcon />;
      case "4660":
        return <PhonelinkOffIcon />;
      case "4658":
        return <VisibilityIcon />;
      case "4675":
        return <RestaurantIcon />;
      default:
        return <AccessibilityIcon />;
    }
  };

  const getContent = (obj) => {
    if (obj.type === "1") {
      return (
        <div className="col-8 d-flex ml-3 mt-1">
          <Avatar
            style={{
              backgroundColor:
                obj.realized === "1"
                  ? "#177493"
                  : obj.realized === "0"
                  ? "#000"
                  : "",
              color: "white",
              width: "30px",
              height: "30px",
            }}
          >
            <ColorizeIcon />
          </Avatar>
          <Typography variant="subtitle2" className="ml-2 mr-3" gutterBottom>
            {obj.name_display} {obj.dosage} {obj.form_pharm}
          </Typography>
        </div>
      );
    } else if (obj.type === "3") {
      return (
        <div className="col-8 d-flex ml-3 mt-1">
          <Avatar
            alt={obj.name}
            src={getUrlPhoto(obj.doctor, obj.photo)}
            style={{
              width: "30px",
              height: "30px",
            }}
          />

          <Typography variant="subtitle2" className="ml-2" gutterBottom>
            Consulta - {obj.name}
          </Typography>
        </div>
      );
    } else {
      return (
        <div className="col-8 d-flex ml-3 mt-1">
          <Avatar
            style={{
              backgroundColor: obj.realized
                ? "#177493"
                : obj.realized === false
                ? "#1d1d1d"
                : "",
              color: "white",
              width: "30px",
              height: "30px",
            }}
          >
            {getIcon(obj.fk_ls_table_type)}
          </Avatar>
          <Typography variant="subtitle2" className="ml-2" gutterBottom>
            {obj.name}
          </Typography>
        </div>
      );
    }
  };

  const handleChecked = () => {
    setState({
      ...state,
      [alert.time]: state[alert.time].map((row) =>
        row.id === alert.id ? { ...row, realized: alert.value } : row
      ),
    });

    Api.post(`/microservice/patientportal/registerAlarm/`, {
      id: alert.id,
      value: alert.value,
      type: alert.type,
    });
    setAlert({
      open: false,
      title: "",
      id: "",
      time: "",
      type: "",
      value: "",
    });
  };

  return (
    <div
      className="row mb-2 W-100"
      style={
        {
          // maxHeight: "330px",
          // overflowY: "auto",
        }
      }
    >
      {state &&
        Object.keys(state)
          .sort((a, b) => {
            if (moment(`2020-01-01 ${a}`).isBefore(moment(`2020-01-01 ${b}`))) {
              return -1;
            }
            if (
              !moment(`2020-01-01 ${a}`).isBefore(moment(`2020-01-01 ${b}`))
            ) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })

          .map((row) => (
            <>
              <div className="col-12 mt-3">
                <strong>{row}</strong>
              </div>
              {state[row].map((el) => (
                <>
                  {getContent(el)}
                  {el.type !== "4" && moment().isAfter(moment(el.date)) && (
                    <div className="mr-3" style={{ display: "inherit" }}>
                      {el.type !== "3" ? (
                        <>
                          <Tooltip title="Realizei :)" placement="top">
                            <DoneAllIcon
                              color={
                                el.realized !== "0" ? "primary" : "default"
                              }
                              onClick={
                                () => {
                                  setAlert({
                                    open: true,
                                    title:
                                      el.type === "1"
                                        ? `${el.name_display} ${el.dosage} ${el.form_pharm}`
                                        : el.name,
                                    id: el.id,
                                    time: row,
                                    type: el.type,
                                    value: "1",
                                  });
                                }
                                // handleChecked(el.id, "1", el.type, row)
                              }
                            />
                          </Tooltip>
                          <Tooltip title="Não realizei :(" placement="top">
                            <ClearIcon
                              color={
                                el.realized !== "1" ? "secondary" : "default"
                              }
                              className="ml-3"
                              onClick={() => {
                                setAlert({
                                  open: true,
                                  title:
                                    el.type === "1"
                                      ? `${el.name_display} ${el.dosage} ${el.form_pharm}`
                                      : el.name,
                                  id: el.id,
                                  time: row,
                                  type: el.type,
                                  value: "0",
                                });
                                // handleChecked(el.id, "0", el.type, row)
                              }}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setModalView({
                              open: true,
                              event: el,
                            });
                          }}
                          style={{ float: "right" }}
                        >
                          <i className="zmdi zmdi-eye zmdi-fw" />
                        </IconButton>
                      )}
                    </div>
                  )}
                </>
              ))}
            </>
          ))}
      <ViewEvent
        open={modalView.open}
        event={modalView.event}
        close={() => setModalView({ open: false, event: "" })}
      />

      <SweetAlert
        show={alert.open}
        warning
        showCancel
        confirmBtnBsStyle="default"
        cancelBtnBsStyle="danger"
        confirmBtnText="Sim, confirmo"
        cancelButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title={
          <div>
            {alert.title}
            <br />
            Realizado: <strong>{alert.value === "1" ? "Sim" : "Não"}</strong>
          </div>
        }
        onConfirm={() => handleChecked()}
        onCancel={() =>
          setAlert({
            open: false,
            title: "",
            id: "",
            time: "",
            type: "",
            value: "",
          })
        }
      />
    </div>
  );
}
