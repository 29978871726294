import { all, takeLatest, put, call } from "redux-saga/effects";
import Api from "services/api";

// Revisão dos sistemas
const getRecordSr = async user => {
  try {
    const response = await Api.post(
      `/ms_configuration/custom/getRecordSystemReview/`,
      { user }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetRecordSr(action) {
  const response = yield call(getRecordSr, action.payload.user);
  yield put({
    type: "GET_RECORD_SR_SUCCESS",
    payload: {
      // categories: response.categories,
      record: response.record
    }
  });
}

// Aga
const getRecordAga = async user => {
  try {
    const response = await Api.post(`/ms_configuration/custom/getRecordAga/`, {
      user
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetRecordAga(action) {
  const response = yield call(getRecordAga, action.payload.user);
  yield put({
    type: "GET_RECORD_AGA_SUCCESS",
    payload: {
      forms: response.forms,
      record: response.record
    }
  });
}

// Sidenav Consult
const getRecordSnc = async user => {
  try {
    const response = await Api.post(
      `/ms_configuration/custom/getRecordSidenavConsult/`,
      {
        user
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetRecordSnc(action) {
  const response = yield call(getRecordSnc, action.payload.user);
  yield put({
    type: "GET_RECORD_SNC_SUCCESS",
    payload: {
      record: response.record,
      recordSr: response.recordSr
    }
  });
}

// VitalSigns (Registro Inicial)
const getRecordVs = async user => {
  try {
    const response = await Api.post(
      `/ms_configuration/custom/getRecordVitalSigns/`,
      {
        user
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetRecordVs(action) {
  const response = yield call(getRecordVs, action.payload.user);
  yield put({
    type: "GET_RECORD_VS_SUCCESS",
    payload: {
      record: response.record
    }
  });
}

export default function* rootSaga() {
  yield all([takeLatest("GET_RECORD_SR", asyncGetRecordSr)]);
  yield all([takeLatest("GET_RECORD_AGA", asyncGetRecordAga)]);
  yield all([takeLatest("GET_RECORD_SNC", asyncGetRecordSnc)]);
  yield all([takeLatest("GET_RECORD_VS", asyncGetRecordVs)]);
}
