import React, { useState, useEffect } from "react";
import CircularProgress from "components/CircularProgress";
import DataTable from "components/Tables/DataTable";
import Api from "services/api";
import { Badge } from "reactstrap";
import Tabs from "components/TabsDefault";
import CareIcons from "components/CareIcons";
import TableInterrupted from "components/PrescriptionCare/TableInterrupted";
import CareViews from "components/PrescriptionCare/views";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btn: {
    float: "right",
    color: "#fff",
    backgroundColor: "#177493",
    position: "absolute",
    right: 0,
    top: "-38px",
    zIndex: "2",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#177493",
    },
  },
}));
export default function PrescriptionCare({ patient }) {
  const classes = useStyles();
  const [interrupted, setInterrupted] = useState([]);
  const [actives, setActives] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [view, setView] = useState(null);

  useEffect(() => {
    Api.post(`/microservice/prescriptioncare/getHistoryPatient/`, {
      patient,
    }).then(({ data }) => {
      setActives(data.actives);
      setInterrupted(data.interrupted);
      setLoaded(true);
    });
  }, []);

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <div className="container-person border">
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400",
          }}
        >
          Prescrição de cuidados
        </h3>
        <div className="w-100">
          <Tabs
            value={activeTab}
            setValue={setActiveTab}
            tabs={[
              "Prescrições Ativas",
              "Prescrições Finalizadas/Interrompidas",
            ]}
          >
            <React.Fragment>
              {activeTab === 0 && (
                <>
                  {!Boolean(view) && (
                    <div
                      className="col-12"
                      style={{
                        minWidth: "650",
                        overflowX: "auto",
                      }}
                    >
                      <DataTable
                        columns={[
                          "Tipo",
                          "Cuidado",
                          "x/Dia",
                          "Periodicidade",
                          "Visualizar",
                        ]}
                        rows={actives.map((row) => [
                          [<CareIcons type={row.type} />],
                          [
                            <>
                              {row.name}
                              {row.is_pattern === "1" && (
                                <Badge className="ml-2" color={"primary"} pill>
                                  Padrão
                                </Badge>
                              )}
                            </>,
                          ],
                          row.times_a_day,
                          row.interval,
                          [
                            <Tooltip title={"Visualizar"}>
                              <IconButton
                                color={"primary"}
                                onClick={() => setView(row)}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>,
                          ],
                        ])}
                      />
                    </div>
                  )}
                  {Boolean(view) && (
                    <div className="pt-3">
                      <div className="col-12">
                        <Tooltip placement="top" title={"Voltar"}>
                          <IconButton
                            className={classes.btn}
                            color={"primary"}
                            onClick={() => setView(null)}
                          >
                            <i className="zmdi zmdi-undo" />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <CareViews record={view} typeCare={view.type} />
                    </div>
                  )}
                </>
              )}
              {activeTab === 1 && (
                <TableInterrupted prescriptions={interrupted} />
              )}
            </React.Fragment>
          </Tabs>
        </div>
      </div>
    );
  }
}
