import React from "react";
import { useSelector } from "react-redux";
import AutocompletePatient from "components/Autocomplete/AutocompletePatient";
import AutocompleteOnlyPatientsDoctor from "components/Autocomplete/AutocompleteOnlyPatientsDoctor";

export default function ChoosePatient({ handleSelected }) {
  const auth = useSelector(({ auth }) => auth.authUser);
  const address = useSelector(({ auth }) => auth.address);
  const address_selected = useSelector(({ auth }) => auth.address_selected);
  return (
    <div className="row mt-3" style={{ padding: "5px 15px" }}>
      <div className="jr-card jr-card-widget card w-100">
        <div className="mt-15">
          {address_selected.provider_type !== "5" &&
          address_selected.provider_type !== "55" ? (
            <AutocompletePatient
              address={address}
              handleChangeAutocomplete={(selected) => handleSelected(selected)}
              doctor={auth}
            />
          ) : (
            <AutocompleteOnlyPatientsDoctor
              address={address}
              handleChangeAutocomplete={(selected) => handleSelected(selected)}
              doctor={auth}
            />
          )}
        </div>
      </div>
    </div>
  );
}
