import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";

export default function TableHistory({ record, onClose }) {
  return (
    <div className="row">
      <div
        className="col-12"
        style={{
          minWidth: "650",
          overflowX: "auto"
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="20%">Dente</TableCell>
              <TableCell>Diagnóstico</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.map(row => (
              <TableRow key={1}>
                <TableCell width="20%">{row.tooth}</TableCell>
                <TableCell>{row.diagnostic}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {onClose !== undefined && (
          <div>
            <Button
              onClick={() => onClose()}
              color="primary"
              aria-label="Voltar"
            >
              <i className="zmdi zmdi-undo" /> Voltar
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
