export const handleAddFunction = (active, color, texto, hidden, icon, id) => ({
  type: "HANDLE_ADD_FUNC",
  payload: { active, color, texto, hidden, icon, id }
});

export function changeNameGroup(value) {
  return {
    type: "SET_NAME_GROUP",
    payload: { value }
  };
}

export function changeDescGroup(value) {
  return {
    type: "SET_DESC_GROUP",
    payload: { value }
  };
}

export function setMedicinesItensGroups(value) {
  return {
    type: "SET_MEDICINES_ITENS_GROUPS",
    payload: { value }
  };
}

export const delItemExam = (id, event) => ({
  type: "DEL_ITEM_EXAM",
  payload: { id }
});

export function saveMedicinesGroups(
  doctor,
  nameGroupValue,
  descGroupValue,
  medicinesSelected,
  idEdit
) {
  return {
    type: "CAD_MEDICINES_GROUPS",
    payload: {
      doctor,
      nameGroupValue,
      descGroupValue,
      medicinesSelected,
      idEdit
    }
  };
}

export const requestListDoctorMedicinesGroups = (doctor, event) => ({
  type: "REQUEST_LIST_DOCTOR_MEDICINES_GROUP",
  payload: { doctor }
});

export function delGroup(id, event) {
  return {
    type: "DEL_GROUP_MEDICINE",
    payload: { id, event }
  };
}

export function closeModalDeleteSuccess() {
  return {
    type: "CLOSE_MODAL_DELETE_SUCCESS"
  };
}

export function getDataGroupForEdit(id) {
  return {
    type: "GET_DATA_GROUP_MEDICINES_FOR_EDIT",
    payload: { id }
  };
}

export const changeObs = (index, value) => ({
  type: "CHANGE_OBS",
  payload: { index, value }
});
