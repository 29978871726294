import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { viewHistory } from "actions/consult/Appointment";
import { getUrlPhoto } from "services/functions";

import IconBlock from "@material-ui/icons/Block";
import HistoryPatient from "components/HistoryPatient";
import HistoryIcon from "@material-ui/icons/History";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  pAddress: {
    fontSize: "14px",
    color: "#868e96",
    marginBottom: 0,
    "& i": {
      color: "#f44336"
    }
  }
}));

export default function TopBar({ id, nameProfile, photo, removePatient }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const width = useSelector(({ settings }) => settings.width);
  const full_address = useSelector(
    state => state.auth.address_selected.full_address
  );
  const address_name = useSelector(state => state.auth.address_selected.name);

  return (
    <div className="row" style={{ padding: "5px 15px" }}>
      <div
        className="jr-card jr-card-widget w-100 mb-3"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px"
        }}
      >
        {width > 500 ? (
          <div className="w-100 d-flex justify-content-between flex-row">
            <div className="d-flex">
              <img
                alt={nameProfile}
                src={getUrlPhoto(id, photo)}
                width="60px"
                height="60px"
                className="img-responsive rounded-circle"
              />
              <div>
                <span className="ml-3" style={{ fontSize: "18px" }}>
                  {nameProfile}
                </span>
                <p className={`ml-3 ${classes.pAddress}`}>
                  <i className="zmdi zmdi-pin mr-2" />
                  {`${address_name} - ${full_address}`}
                </p>
              </div>
              <Tooltip title="Histórico geral" placement="top">
                <IconButton
                  color="primary"
                  onClick={() => dispatch(viewHistory("personalData"))}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Tooltip title="Alterar Paciente" placement="top">
              <IconButton
                color="secondary"
                style={{ float: "right" }}
                onClick={removePatient}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div className="row">
            <div className="w-100 d-flex justify-content-around flex-row">
              <div>
                <img
                  alt={nameProfile}
                  src={getUrlPhoto(id, photo)}
                  width="60px"
                  height="60px"
                  className="img-responsive rounded-circle"
                />
              </div>
              <div className="d-flex align-items-center">
                <span className="ml-3" style={{ fontSize: "18px" }}>
                  {nameProfile}
                </span>

                <Tooltip title="Histórico geral" placement="top">
                  <IconButton
                    color="primary"
                    onClick={() => dispatch(viewHistory("personalData"))}
                  >
                    <HistoryIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className="col-12">
              <p className={`pt-3 ${classes.pAddress}`}>
                <i className="zmdi zmdi-pin mr-2" />
                {`${address_name} - ${full_address}`}
              </p>
            </div>
            <div className="col-12 p-3">
              <Chip
                className="w-100"
                size="small"
                color="secondary"
                onClick={removePatient}
                icon={<IconBlock />}
                label="Alterar paciente"
              />
            </div>
          </div>
        )}
      </div>
      {/* MODAL HISTÓRICO PACIENTE */}
      <HistoryPatient id_patient={id} />
    </div>
  );
}
