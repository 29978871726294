import React, { useRef, useState } from "react";
// import { withStyles } from "@material-ui/styles";
import { Button, Grid } from "@material-ui/core";
import TakePhoto from "./TakePhoto";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; // see installation section above for versions of NPM older than 3.0.0
// If you choose not to use import, you need to assign Cropper to default
// var Cropper = require('react-cropper').default

export default function cropperComponent({
  src,
  getCroppedImage,
  ratioDetail
}) {
  const cropper = useRef(null);
  const [croppedImage, setCroppedImage] = useState("");
  const [specRatio] = useState(ratioDetail ? ratioDetail : 1 / 1);
  const [photo, setPhoto] = useState(src);
  const [state, setState] = useState({ image: "" });
  const uploadImage = () => {
    getCroppedImage(croppedImage);
    setState({ ...state, image: croppedImage });
  };

  const _crop = () => {
    // image in dataUrl
    setCroppedImage(cropper.current.getCroppedCanvas().toDataURL());
  };

  return (
    <>
      <div>
        {photo === undefined || photo === null ? (
          <div>
            <Grid container justify="center" spacing={2}>
              <TakePhoto
                setCaptured={img => {
                  setPhoto(img.preview);
                }}
              />
            </Grid>
          </div>
        ) : (
          <Grid container justify="center" spacing={2}>
            <Cropper
              ref={cropper}
              src={photo}
              style={{ height: 400, width: "100%" }}
              // Cropper.js options
              aspectRatio={specRatio}
              guides={false}
              crop={_crop}
            />
          </Grid>
        )}
      </div>
      <div hidden={photo === null || photo === undefined ? true : false}>
        <Button
          disabled={photo === null || photo === undefined ? true : false}
          onClick={uploadImage}
          variant="contained"
          color="primary"
          className="jr-btn jr-btn-sm"
          style={{ float: "right", marginTop: 15, marginRight: 0 }}
        >
          <span> Salvar </span>
        </Button>
        <Button
          disabled={photo === null || photo === undefined ? true : false}
          onClick={() => setPhoto(null)}
          variant="contained"
          color="primary"
          className="jr-btn jr-btn-sm"
          style={{ float: "right", marginTop: 15, marginRight: 10 }}
        >
          <span> Remover Imagem </span>
        </Button>
      </div>
    </>
  );
}
