import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  Avatar
} from "@material-ui/core";
import DataTable from "components/Tables/DataTable";
import Api from "services/api";
import AddMember from "./AddMember";
import SweetAlert from "react-bootstrap-sweetalert";
import { getUrlPhoto } from "services/functions";
import CircularProgress from "components/CircularProgress";

export default function ListMembers({ type, group, close }) {
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [memberDelete, setMemberDelete] = useState("");

  useEffect(() => {
    Api.post("ms_system/workplace/getMembersAcademic", {
      group
    }).then(res => {
      setRows(res.data.record);
      setLoaded(true);
    });
  }, []);

  const handleDelete = () => {
    Api.post("ms_system/workplace/removeMemberAcademic", {
      memberDelete
    }).then(() => {
      setModalDelete(false);
      setRows(rows.filter(row => row.id !== memberDelete));
    });
  };

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="text-primary font-weight-semibold">
            {type === "teacher" ? "Professores" : "Alunos"}
            <Tooltip title="Fechar" placement="top">
              <IconButton
                aria-label="Fechar"
                onClick={() => close()}
                style={{ float: "right", marginTop: "-10px" }}
              >
                <i className="zmdi zmdi-close" />
              </IconButton>
            </Tooltip>
          </h2>
        </div>
        <div className="col-12">
          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={() => setModal(true)}
            style={{ float: "right", marginTop: "20px" }}
          >
            <i className="zmdi zmdi-plus zmdi-hc-lg" />
            <span> Adicionar </span>
          </Button>
          <DataTable
            columns={["Nome", "Ações"]}
            rows={rows.map(row => {
              return [
                [
                  <div className="d-flex">
                    <Avatar
                      alt={row.name}
                      src={getUrlPhoto(row.user_id, row.photo)}
                      size="40"
                    />
                    <span className="ml-3 mt-1 d-flex">{row.name}</span>
                  </div>
                ],
                [
                  <IconButton
                    className="m-0 p-0"
                    style={{ color: "#bc0003" }}
                    onClick={() => {
                      setModalDelete(true);
                      setMemberDelete(row.id);
                    }}
                  >
                    <i className="zmdi zmdi-delete zmdi-hc-fw" />
                  </IconButton>
                ]
              ];
            })}
          />
        </div>
        <div className="col-md-12 mt-3">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={() => close()}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Fechar </span>
          </Button>
        </div>
        <Dialog
          fullWidth
          maxWidth="md"
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="max-width-dialog-title"
          scroll="body"
        >
          <DialogContent>
            <AddMember
              group={group}
              professional={type === "teacher"}
              close={() => setModal(false)}
              add={obj => setRows([...rows, ...obj])}
            />
          </DialogContent>
        </Dialog>
        <SweetAlert
          show={modalDelete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, excluir"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Deseja realmente remover este membro?"
          onConfirm={() => handleDelete()}
          onCancel={() => setModalDelete(false)}
        />
      </div>
    );
  }
}
