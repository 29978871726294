import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { TextField, MenuItem, Button } from "@material-ui/core";
// import MaskCpf from "components/Masks/MaskCpf";
// import MaskCellphone from "components/Masks/MaskCellphone";
// import AutocompleteNaturalty from "./AutocompleteNaturalty";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";
import { allowCPF } from "./Functions";

export default function PersonalData({
  handleNext,
  getState,
  stateInit,
  validateData,
  history,
  modal,
}) {
  const [state, setState] = useState(stateInit);
  const [messageCpf, setCpfMessage] = useState("");
  const [issetRegister, setIssetRegister] = useState(false);
  const [modalCpf, setModalCpf] = useState(false);
  const [modalNotRegister, setModalNotRegister] = useState(false);
  const [validaCpf, setValidateCpf] = useState(validateData.cpf);
  const [validaPassword, setValidatePassword] = useState(validateData.password);
  const [validaUserName, setValidateUsername] = useState(validateData.username);
  const [validaEmail, setValidateEmail] = useState(validateData.email);
  const [error, setError] = useState(false);
  const {
    name,
    username,
    cpf,
    date,
    sex,
    idNaturalty,
    naturalty,
    email,
    cellphone,
    password,
    confirmPassword,
    type,
  } = state;

  const validate = () => {
    if (state.name && state.date) {
      Api.post(`/ms_system/user/getUserCompleteRegister`, {
        name: state.name,
        date: state.date,
      }).then((res) => {
        if (res.data.dataUser) {
          const {
            id,
            idData,
            gender,
            name,
            birthday,
            smartphone,
            email,
            cpf,
            quick_register,
            type,
            area_id,
            council_id,
            council_number,
            council_state_id,
            council_year,
            specialty_id,
            uhs_id,
          } = res.data.dataUser;

          if (quick_register === "1") {
            getState(
              {
                ...state,
                id,
                idData,
                sex: gender,
                date: birthday,
                name,
                cellphone: smartphone ? smartphone : "",
                cpf,
                email,
                type,
                area_id,
                council_id,
                council_number,
                council_state_id,
                council_year,
                specialty_id,
                uhs_id,
              },
              "personalData"
            );
            handleNext();
          } else {
            setIssetRegister(true);
          }
        } else {
          if (modal) {
            getState(state, "personalData");
            handleNext();
          } else {
            setModalNotRegister(true);
          }
        }
      });
    } else {
      setError(true);
    }
  };

  const verifyState = () => {
    if (
      validaUserName &&
      validaCpf &&
      validaEmail &&
      name &&
      username &&
      cpf &&
      date &&
      sex &&
      naturalty &&
      email &&
      cellphone &&
      cellphone.indexOf("_") === -1 &&
      password &&
      validaPassword &&
      confirmPassword
    ) {
      getState(state, "personalData");
      getState(
        { cpf: validaCpf, email: validaEmail, username: validaUserName },
        "validate"
      );
      handleNext();
    } else {
      setState({ ...state, error: true });
    }
  };

  const getDataUser = (id) => {
    Api.post(`/ms_system/user/getUserQuickRegister`, { user: id }).then(
      (res) => {
        const {
          id,
          idData,
          gender,
          name,
          birthday,
          smartphone,
          email,
          cpf,
          quick_register,
          type,
        } = res.data.dataUser;

        if (quick_register === "1") {
          setState({
            ...state,
            id,
            idData,
            sex: gender,
            date: birthday,
            name,
            cellphone: smartphone ? smartphone : "",
            cpf,
            email,
            type,
          });
        } else {
          setIssetRegister(true);
        }
      }
    );
  };

  const CheckCpf = () => {
    setValidateCpf(false);
    if (cpf !== stateInit.cpf) {
      if (cpf.length !== 0) {
        const verifica = allowCPF(cpf);
        if (verifica) {
          Api.post(`/ms_system/user/checkCPF`, { cpf: cpf }).then((res) => {
            if (res.data.record.length !== 0) {
              if (res.data.record[0].quick_register === "1") {
                setCpfMessage(
                  "Este CPF já está cadastrado pelo registro rápido. complete as informações"
                );
                setModalCpf(true);
                getDataUser(res.data.record[0].id);
                setValidateCpf(true);
                return true;
              } else {
                setCpfMessage(
                  "Este CPF já está cadastrado. Por favor utilize outro."
                );
                setModalCpf(true);
                setValidateCpf(false);
                return false;
              }
            } else {
              setValidateCpf(true);
              return true;
            }
          });
        } else {
          setCpfMessage("CPF Inválido");
          setModalCpf(true);
          setValidateCpf(false);
          return false;
        }
      } else {
        setValidateCpf(false);
        return false;
      }
    } else {
      setValidateCpf(true);
      return true;
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <TextField
            id="name"
            required
            label="Nome completo"
            margin="normal"
            helperText={error && !name ? "Campo Obrigatório" : ""}
            fullWidth
            value={name}
            error={error && !name ? true : false}
            // onChange={(e) => setState({ ...state, name: e.target.value })}
            InputLabelProps={{ shrink: true }}
            autoFocus
            InputProps={{
              onInput: (e) => {
                if (e.target.value) {
                  let formtCase = e.target.value.split(" ").map((row) => {
                    return row === "Da" ||
                      row === "da" ||
                      row === "De" ||
                      row === "de" ||
                      row === "do" ||
                      row === "Do" ||
                      row === "Das" ||
                      row === "das" ||
                      row === "Dos" ||
                      row === "dos"
                      ? row.charAt(0).toLowerCase() + row.slice(1).toLowerCase()
                      : row.charAt(0).toUpperCase() +
                          row.slice(1).toLowerCase();
                  });
                  setState({ ...state, name: formtCase.join(" ") });
                } else {
                  setState({ ...state, name: "" });
                }
              },
            }}
          />
        </div>

        {/* <div className="col-md-6">
          <TextField
            id="cpf"
            label="CPF"
            required
            margin="normal"
            fullWidth
            helperText={
              error && !cpf
                ? "Campo Obrigatório"
                : cpf && !validaCpf
                ? "Por Favor insira outro CPF."
                : ""
            }
            InputProps={{
              inputComponent: MaskCpf,
              value: cpf,
              onChange: (e) => setState({ ...state, cpf: e.target.value }),
            }}
            error={error && !cpf ? true : cpf && !validaCpf}
            onBlur={CheckCpf}
          />
        </div> */}
        <div className="col-md-4">
          <TextField
            id="dt_nasc"
            type="date"
            label="Data de Nascimento:"
            value={date}
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setState({ ...state, date: e.target.value })}
            helperText={error && !date ? "Campo Obrigatório" : ""}
            error={error && !date ? true : false}
            onInput={(e) => {
              if (e.target.value.length > 10) {
                e.target.value = e.target.value.substr(1, 11);
              }
            }}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="align-center mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={validate}
          className="jr-btn"
          style={{ float: "right", marginRight: 0 }}
        >
          Próxima
        </Button>
        {!modal && (
          <NavLink to="/signup" style={{ float: "left", marginLeft: 0 }}>
            <Button className="jr-btn">Voltar</Button>
          </NavLink>
        )}
      </div>
      <SweetAlert
        show={issetRegister}
        warning
        showConfirm={true}
        title="Você já possui um cadastro, faça login para continuar."
        onConfirm={() => history.push("/login")}
      />
      <SweetAlert
        show={modalNotRegister}
        warning
        showConfirm={true}
        title="Nenhum usuário encontrado."
        onConfirm={() => setModalNotRegister(false)}
      />
    </>
  );
}
