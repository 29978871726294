import React, { Component } from "react";
// import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%"
  },
  root: {
    backgroundColor: "#fff"
  },
  selected: {
    backgroundColor: "#e2e2e8 !important"
  }
};

class AutocompleteDiseasesAllergy extends Component {
  state = {
    suggestions: []
  };

  getSuggestions = e => {
    if (e.target.value.length > 2) {
      Api.post(`/microservice/autocomplete/getListDA/`, {
        term: e.target.value
      }).then(res => {
        this.setState({
          suggestions: res.data
        });
      });
    }
  };

  renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    const { classes } = this.props;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id_registry}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      >
        {suggestion.name_registry}
      </MenuItem>
    );
  }

  renderInput(inputProps) {
    let { InputProps, classes, ref, ...other } = inputProps;
    return (
      <TextField
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input
          },
          ...InputProps
        }}
        style={{ marginTop: "0" }}
        onKeyUp={this.getSuggestions}
        onChange={this.handleChange}
      />
    );
  }

  render() {
    const { classes, handleChangeAutocomplete } = this.props;
    const { suggestions } = this.state;

    return (
      <Downshift
        onSelect={(selectedItem, obj) => {
          let item = suggestions.filter(row => {
            return row.name_registry === selectedItem;
          });
          handleChangeAutocomplete(item[0]);
          obj.setState({ inputValue: "" });
        }}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex
        }) => (
          <div className={classes.container}>
            {this.renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                id: "allergi_id",
                value: inputValue
              }),
              label: "Doença Alérgica",
              margin: "normal"
            })}
            {isOpen ? (
              <Paper square>
                {suggestions.map((suggestion, index) =>
                  this.renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.name_registry
                    }),
                    highlightedIndex,
                    selectedItem
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}
export default withStyles(styles)(AutocompleteDiseasesAllergy);
