import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModalConfig } from "actions/Configuration";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import ModalRegisterAttendance from "./ModalRegisterAttendance";
import ModalDraftShiftChange from "./ModalDraftShiftChange";
import { IconButton, Tooltip } from "@material-ui/core";
import "./styles.css";

const translate = {
  app: "Salutem+",
  modules: {
    consult: {
      name: "Consultorio",
      submodules: {
        exam: "Exames",
        pain_scale: "Escala de dor",
        circumferences: "Circunferências",
        initial_care: "Registro Inicial",
        prescription: "Prescrição",
        transcription_prescription: "Transcrição de Prescrição",
        manipulated: "Prescrição Manipulada",
        consultation_summary: "Últimos atendimentos",
        attendance_record: "Registro do atendimento",
        aga: "Avaliação Geriátrica Ampla",
        forward: "Encaminhar paciente",
        system_review: "Revisão dos sistemas",
        personal_data: "Dados Pessoais",
        personal_history: "Patologias",
        child_disease: "Doenças da infância",
        habits: "Hábitos",
        procedures: "Procedimentos",
        family_history: "Histórico Familiar",
        medicines: "Medicamentos",
        dysfunctions: "Disfunções Funcionais e outras",
        compare_pictures: "Comparador de Fotos",
        nurse_prescription: "Prescrição de Enfermagem",
        nurse_diagnostic: "Diagnóstico de Enfermagem",
        nurse_transcription: "Transcrição da Prescrição",
        complaints: "Queixas",
        vaccines: "Vacinas",
        integral_diagnostic: "Diagnóstico Integral",
        periodotogram: "Periodontograma",
        allergies: "Alergias",
        conduct: "Consulta",
        folds: "Dobras",
        sae: "SAE",
        files_cloud: "Arquivos na Nuvem",
        prescription_care: "Prescrição de cuidados",
        physical_avaliation: "Avaliação física",
        initial_record: "Registro inicial",
        attestation: "Atestados",
        healthy_habits: "Hábitos saudáveis",
        defects: "Vícios",
        video: "Teleconsulta",
        fisio_avaliation: "Avaliação Fisioterapêutica",
        fisio_pos_covid: "Avaliação Pós-Covid",
        fisio_rehabilitation: "Reabilitação",
        sessions: "Sessões de reabilitação",
        rehabilitation: "Reabilitação",
        session: "Sessões",
        prenatal: "Pré-Natal",
      },
    },
    finance: {
      name: "Financeiro",
      submodules: {
        list: "Meus Financeiros",
        account: "Contas",
        transaction: "Transações",
        report: "Relatórios",
        suppliers: "Clientes/Fornecedores",
        pattern_transaction: "Transações padrões",
      },
    },
    configuration: {
      name: "Configurações",
      submodules: {
        tags: "Tags",
        portfolio_note: "Apontamento de carteira",
        exams_groups: "Grupos de exames",
        procedure_groups: "Grupos de procedimentos",
        medicines_groups: "Grupos de medicamentos",
        add_procedure: "Procedimentos",
        event_type: "Serviços",
        profile: "Perfil",
        calendar: "Agenda",
        system_review: "Customização revisão dos sistemas",
      },
    },
    evolution: {
      name: "Evolução",
      submodules: {
        personal: "Evolução diária - individual",
        group: "Evolução diária",
        accompaniment: "Acompanhamento familiar",
        requests: "Solicitação",
        request_care: "Marcação de cuidado",
        intercurrence: "Intercorrências",
        time_evolution: "Linha do tempo",
        hour_evolution: "Horários",
        patient_evolution: "Pacientes",
        shift_change: "Passagem de ponto",
        prescriptioncare: "Prescrição de Cuidados",
      },
    },
    workplace: {
      name: "Locais de atendimento",
    },
    dashboard: {
      name: "Dashboard",
    },
    calendar: {
      name: "Agenda",
      submodules: {
        compromisses: "Meus teleatendimentos",
      },
    },
    profile: {
      name: "Perfil",
    },
    blog: {
      name: "Blog",
      submodules: {
        post: "Postagem",
      },
    },
    choose: {
      name: "Parâmetros",
      submodules: {
        data_consult: "Atendimento",
        data_admission: "Ficha de admissão",
        family_accompaniment: "Acompanhamento familiar",
        sessions: "Sessões",
      },
    },
    intranet: {
      name: "Intranet",
      submodules: {
        user_manage: "Gestão de usuários",
        group_manage: "Gestão de grupos",
      },
    },
    comunication: {
      name: "Comunicação",
      submodules: {
        contacts: "Contatos",
        scheduled: "Comunicação Programada",
        video: "Videochamada",
        telemedicine: "Teleconsulta",
      },
    },
    report: {
      name: "Relatórios",
      submodules: {
        index: "dashboard",
        extract: "Extrator de dados",
      },
    },
    admission: {
      name: "Ficha de admissão",
      submodules: {
        data_patient: "Identificação do idoso",
        responsible: "Identificação do familiar ou responsável",
        reason: "Motivo da admissão",
        avaliation: "Avaliação admissional",
        functional_avaliation: "Avaliação funcional",
        personal_history: "História pessoal atual e pregressa",
        family_history: "Histórico Familiar Positivo",
        psychic_avaliation: "Avaliação Psíquica e Sócio Familiar",
        diagnostic_conclusion: "Conclusão Diagnóstica / Plano De Cuidado",
        system_review: "Revisão dos sistemas",
        report: "Relatórios",
      },
    },
    management: {
      name: "Gerenciamento",
      submodules: {
        professional: "Profissionais",
        patient: "Pacientes",
        care_pattern: "Padrões de Cuidados",
        evolution_intervals: "Intervalo de horários",
      },
    },
    provider: {
      name: "Cadastre-se",
    },
    patient: {
      name: "Cadastre-se",
    },
    doctor: {
      name: "Cadastre-se",
    },
    point_register: {
      name: "Registro de ponto",
    },
    homecare: {
      name: "HomeCare",
      submodules: {
        professional: "Profissional",
        manager: "Gestor",
      },
    },
    prescriptioncare: {
      name: "Prescrição de Cuidados",
    },
    family_accompaniment: {
      name: "Acompanhamento Familiar",
      submodules: {
        report: "Relatório",
        accompaniment: "Acompanhamento diário",
      },
    },
    patient_portal: {
      name: "Portal do Paciente",
      submodules: {
        personal_data: "Dados Pessoais",
        allergies: "Alergias",
        files_cloud: "Arquivos na Nuvem",
        compare_pictures: "Comparador de Fotos",
        disabilities: "Disfunções funcionais e outros",
        child_disease: "Doenças da infância",
        patologies: "Patologias",
        procedures: "Procedimentos",
        medicines: "Medicamentos",
        habits: "Hábitos",
        data: "Dados Clinícos",
        healthy_habits: "Hábitos saudáveis",
        defects: "Vícios",
        exams: "Exames",
        attendance: "Atendimentos",
      },
    },
    online_scheduler: {
      name: "Agendamento Online",
    },
    notification_covid: {
      name: "Notificação Covid-19",
    },
    form_notification_covid: {
      name: "Registro de Notificação Covid-19",
    },
    list_notification_covid: {
      name: "Notificações de Pacientes Sintomáticos",
    },
    pharmacy: {
      name: "Farmácia",
      submodules: {
        inventory: "Inventário",
        product_stock: "Cadastrar Produtos",
        report: "Consulta Estoque",
        stock_point: "Ponto de estoque",
        provider: "Fornecedores",
        movement: "Movimentação",
        dismissal: "Medicamentos Reservados",
        dismissal_patient: "Dispensa",
        conference: "Conferência",
        note_entry: "Notas",
        input_product: "Entrada de Produtos",
        protocol: "Protocolo",
      },
    },
  },
};

const getUrlString = (path, sub, index) => {
  if (index === 0) {
    return "/app/dashboard/";
  } else {
    return "#/" + path.split(sub)[0] + sub;
  }
};

const ContainerHeader = ({ title, showConfig, config, match }) => {
  const path = match.path.substr(1);
  const subPath = path.split("/");
  const dispatch = useDispatch();
  const shift_change = useSelector((state) => state.permissions.shift_change);
  const triage = useSelector((state) => state.appointment.triage);

  return (
    <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
      <h2 className="title mb-3 mb-sm-0">
        {title}
        {showConfig && (
          <Tooltip title="Configurações">
            <IconButton
              onClick={() => dispatch(showModalConfig(config))}
              className="pt-0 pb-0"
            >
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
            </IconButton>
          </Tooltip>
        )}
      </h2>

      <Breadcrumb className="mb-0 textHeader" tag="nav">
        {subPath[1] === "consult" &&
          subPath[2] !== "attendance-record" &&
          triage !== "1" && (
            <div>
              <ModalRegisterAttendance />
            </div>
          )}
        {subPath[1] === "evolution" &&
          subPath[2] !== "shift-change" &&
          shift_change && (
            <div>
              <ModalDraftShiftChange />
            </div>
          )}
        <BreadcrumbItem
          active={false}
          tag="span"
          key="0"
          href={getUrlString(path, subPath[0], 0)}
        >
          Salutem+
        </BreadcrumbItem>
        {translate.modules[`${subPath[1].replace("-", "_")}`] && (
          <>
            {subPath.length >= 3 && subPath[2] ? (
              <>
                <BreadcrumbItem active={false} tag="span" key="1" href={"./"}>
                  {translate.modules[`${subPath[1].replace("-", "_")}`].name}
                </BreadcrumbItem>
                <BreadcrumbItem
                  active={true}
                  tag="span"
                  key="2"
                  href={getUrlString(path, subPath[2], 2)}
                >
                  {
                    translate.modules[`${subPath[1].replace("-", "_")}`]
                      .submodules[`${subPath[2].replace("-", "_")}`]
                  }
                </BreadcrumbItem>
              </>
            ) : (
              <>
                <BreadcrumbItem
                  active={true}
                  tag="span"
                  key="1"
                  href={getUrlString(path, subPath[1], 1)}
                >
                  {translate.modules[`${subPath[1].replace("-", "_")}`].name}
                </BreadcrumbItem>
              </>
            )}
          </>
        )}
      </Breadcrumb>
    </div>
  );
};

export default ContainerHeader;
