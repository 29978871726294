import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

export default function DataTable({
  rows,
  columns,
  name,
  height,
  rowsPerPage,
  download,
  print,
  hideSearch,
  viewColumns,
}) {
  // Example rows
  //    rows = ["Ramon Duarte", "Membro", [<IconButtom>]]

  // Example columns
  //    columns = ["Nome", "Descrição", "Ações"]

  const getMuiTheme = () =>
    createMuiTheme({
      palette: {
        primary: {
          light: "#177493",
          main: "#177493",
          dark: "#177493",
          contrastText: "#fff",
        },
        secondary: {
          light: "#bc0003",
          main: "#bc0003",
          dark: "#bc0003",
          contrastText: "#fff",
        },
      },
      status: {
        danger: "orange",
      },
      typography: {
        button: {
          fontWeight: 400,
          textAlign: "capitalize",
        },
      },
      overrides: {
        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveScrollMaxHeight: {
            height,
          },
        },
        MUIDataTableHeadCell: {
          fixedHeaderCommon: {
            backgroundColor: "",
          },
        },
        MuiTableCell: {
          root: {
            paddingTop: "8px",
            paddingBottom: "8px",
            border: "3px solid #ffffff",
            background: "#f0f0f0",
          },
          head: {
            background: "#badeea",
          },
        },
        MuiTypography: {
          h6: {
            color: "#177493",
          },
        },
      },
    });

  const options = {
    selectableRows: "none",
    filterType: true,
    rowsPerPage: rowsPerPage ? rowsPerPage : 10,
    rowsPerPageOptions: rowsPerPage ? [rowsPerPage, 10, 25, 50] : [10, 25, 50],
    download: download ? true : false,
    downloadOptions: {
      filename: name ? name + ".csv" : "tabela.csv",
    },
    print: print ? true : false,
    viewColumns: viewColumns ? true : false,
    responsive: true,
    search: hideSearch ? false : true,
    filter: false,
    textLabels: {
      body: {
        noMatch: "Nenhum registro.",
        toolTip: "Ordernar",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Pesquisar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Visualizar colunas",
        filterTable: "Filtrar registros",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Resetar",
      },
      viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Esconder colunas da tabela",
      },
      selectedRows: {
        text: "linha(s) selecionadas",
        delete: "Excluir",
        deleteAria: "Excluir linhas selecionadas",
      },
    },
  };

  return (
    <MuiThemeProvider theme={getMuiTheme}>
      <MUIDataTable
        title={name}
        data={rows}
        columns={columns}
        options={options}
        className="w-100"
      />
    </MuiThemeProvider>
  );
}
