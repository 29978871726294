import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteTag } from "actions/consult/Tags";

import {
  getTagFromProvider,
  includeTagToUser,
  getTagsFromPatient,
  excluirTagFromPatient
} from "actions/consult/Tags";
import { setEditTag } from "actions/configuration/TagsConditions";

//import components material
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  DialogContent,
  DialogActions,
  IconButton,
  ListItemIcon
} from "@material-ui/core";
import IconLabel from "@material-ui/icons/Label";
import IconEdit from "@material-ui/icons/Edit";
import IconDelete from "@material-ui/icons/Delete";

class IncludeTags extends Component {
  state = {
    name: [],
    select: [],
    list: []
  };

  handleDeleteTag = id => {
    this.props.deleteTag(id, this.props.address);
  };

  handleChangeCheck = tag => {
    this.props.includeTagToUser(
      this.props.patient,
      this.props.doctor,
      tag,
      this.props.address
    );
    this.props.handleRequestClose();
  };

  hendleEditTag = tag => {
    this.props.setEditTag(tag);
    this.props.handleChangeButton();
  };

  componentDidMount() {
    this.props.getTagFromProvider(this.props.address);
    this.props.getTagsFromPatient(this.props.patient, this.props.address);
  }

  render() {
    const list = [];
    const ids_selected = this.props.list_selected.map(tag => tag.id);
    if (this.props.list.length > 0) {
      this.props.list.forEach(tag => {
        if (ids_selected.indexOf(tag.id) === -1) {
          tag = {
            ...tag,
            check: false
          };
        } else {
          tag = {
            ...tag,
            check: true
          };
        }
        list.push(tag);
      });
    }
    return (
      <React.Fragment>
        <DialogContent>
          <div className="row">
            <div className="col-md-12">
              {list.length > 0 ? (
                <List>
                  {list
                    .filter(row => row.check === false)
                    .map(tag => (
                      <ListItem
                        button
                        key={tag.id}
                        onClick={() => this.handleChangeCheck(tag)}
                      >
                        <ListItemIcon>
                          <IconLabel style={{ color: tag.color }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={tag.name}
                          secondary={tag.description}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            color="primary"
                            onClick={() => this.hendleEditTag(tag)}
                          >
                            <IconEdit />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={() => this.handleDeleteTag(tag.id)}
                          >
                            <IconDelete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>
              ) : (
                <p className="text-center">Nenhum registro encontrado</p>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="col-md-12 mt-20">
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              onClick={this.props.handleRequestClose}
              style={{ float: "left" }}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Fechar </span>
            </Button>
          </div>
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  address: state.auth.address,
  doctor: state.auth.authUser,
  list: state.tags.tags_from_provider,
  list_selected: state.tags.tags_from_patient
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTagFromProvider,
      includeTagToUser,
      getTagsFromPatient,
      excluirTagFromPatient,
      deleteTag,
      setEditTag
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IncludeTags);
