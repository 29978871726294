import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import api from "services/api";

const getListHabits = async (patient) => {
  const response = await api.post(`/microservice/habits/getList/`, { patient });
  const data = await response.data.record;
  return data;
};

const getMultipleHabits = async () => {
  const response = await api.post(`/microservice/habits/getListMultiple/`, {});
  const data = await response.data.record;
  return data;
};

const getCadHabits = async (patient) => {
  const response = await api.post(`/microservice/habits/getCadList/`, {
    patient,
  });

  const data = await response.data.record;
  const dataOpt = await response.data.recordOptions;

  return { data, dataOpt };
};

const postAddHabits = async (params) => {
  const { data, patient, doctor } = params;
  const obj = { data, patient, doctor };

  const response = await api.post(`/microservice/habits/saveHabits/`, obj);

  const res = await response.data;
  return res;
};

const postFoodHistory = async params => {
  const { questions, patient, doctor, appointment } = params;

  const response = await api.post(
    `ms_nutrition/nutattendance/saveFoodHistory`,
    {
      questions,
      patient,
      doctor,
      appointment
    }
  );
  const res = await response.data;
  return res;
};

function* asyncGetList(action) {
  try {
    // variável que faz a busca
    const getListH = yield call(getListHabits, action.patient);

    const getMultipleOptions = yield call(getMultipleHabits);
    // parte do método que identifica qual método usar após a conclusão do método acima
    yield put({
      type: "GET_LIST_HABITS",
      payload: { list: getListH, multiple: getMultipleOptions },
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

function* asyncCadList(action) {
  try {
    const cadHabits = yield call(getCadHabits, action.patient);

    yield put({
      type: "REQUEST_CAD_HABITS_SUCCESS",
      payload: { cadList: cadHabits.data, cadListOptions: cadHabits.dataOpt },
    });
  } catch (error) {
    console.log(error);
  }
}

function* requestAddNewHabits(action) {
  try {
    const response = yield call(postAddHabits, action);

    yield put({
      type: "ADD_HABITS_SUCCESS",
      payload: { success: response.success },
    });

    yield call(asyncCadList, action);

    yield call(asyncGetList, action);

    if (response.success) {
      yield put({
        type: "CHANGE_BUTTON",
        active: "view",
        color: "primary",
        text: "Adicionar",
        icon: "plus",
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!",
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!",
      });
    }
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!",
    });
    yield put({
      type: "ADD_HABITS_SUCCESS",
      payload: { success: false },
    });
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  }
}

function* requestNutFoodHistory(action) {
  try {
    const response = yield call(postFoodHistory, action.payload);

    if (response.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });
      yield put({
        type: "FORM_HABITS_NUTRITION_SAVE",
        payload: { success: response.success, id: response.id }
      });
      if (action.payload.appointment) {
        yield put({
          type: "SET_INIT_APPOINTMENT",
          payload: {
            id: response.appointment,
            saved: "id",
            saved_form: response.appointment
          }
        });
      }
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!"
      });
    }
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!"
    });
    yield put({
      type: "ADD_HABITS_SUCCESS",
      payload: { success: false }
    });
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  }
}

export function* requestList() {
  yield takeLatest("REQUEST_GET_HABITS", asyncGetList);
}

export function* requestCadList() {
  yield takeLatest("REQUEST_CAD_HABITS", asyncCadList);
}

export function* requestAddHabits() {
  yield takeLatest("ADD_HABITS", requestAddNewHabits);
}

export function* requestEatingHabitsNut() {
  yield takeLatest("SAVE_FOOD_HISTORY_NUTRITION", requestNutFoodHistory);
}

export default function* rootSaga() {
  yield all([
    fork(requestList),
    fork(requestCadList),
    fork(requestAddHabits),
    fork(requestEatingHabitsNut)
  ]);
}
