import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { BtnSave, BtnCan } from "components/Button";

import Api from "services/api";
import AutocompleteGeneric from "components/Autocomplete/AutocompleteGeneric";
import { debounce } from "services/functions";

function EditTypeEvent({ event, calendar, value, edit, close }) {
  const user = useSelector((state) => state.auth.authUser);
  const [type, setType] = useState(value);
  const [typesEvent, setTypesEvent] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    Api.post(`/ms_system/calendar/getTypesEventsCalendar/`, { calendar }).then(
      (res) => {
        if (res.data.record.length > 0) {
          setTypesEvent(res.data.record);
          setSuggestions(res.data.record);
        }
      }
    );
  }, []);

  function save() {
    Api.post(`/ms_system/calendar/editTypeEvent/`, {
      event,
      type: type.id,
    }).then(() => {
      edit(type.id, type.description);
    });
  }

  const getOptions = (inputValue) => {
    let aux = typesEvent.filter((suggestion) => {
      const keep =
        !inputValue ||
        suggestion.description.toLowerCase().includes(inputValue.toLowerCase());

      return keep;
    });
    setSuggestions(aux);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={true} onClose={() => close()}>
      <DialogTitle className="text-primary font-weight-semibold">
        Editar Tipo do evento
      </DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <AutocompleteGeneric
              multiple={false}
              limitTags={1}
              filterOptions={(x) => x}
              disableCloseOnSelect
              onChange={(obj) => {
                console.log("obj", obj);
                if (obj) {
                  setType(obj);
                } else {
                  setType({
                    id: "",
                    description: "",
                  });
                }
              }}
              onInputChange={debounce(getOptions)}
              value={type ? type : null}
              getOptionLabel={(option) => option.description}
              label="Tipo da consulta:"
              options={
                type.description === value.description
                  ? typesEvent
                  : suggestions
              }
              blurOnSelect={true}
              clearOnBlur={true}
              shrink={true}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="col-12">
          <BtnCan title={"Cancelar"} onClick={() => close()} float="left" />
          <BtnSave title={"Salvar"} onClick={() => save()} float="right" />
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default EditTypeEvent;
