import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Calendar from "./TableCalendar";
import moment from "moment";
import { TextField, MenuItem, Avatar } from "@material-ui/core";
import { changeAddress } from "actions/Auth";
import "./styles.css";
import Api from "services/api";

const useStyles = makeStyles({
  avatar: {
    margin: 6,
    padding: 0,
  },
  nameProv: {
    color: "#676767",
    fontSize: "15px",
    fontWeight: "500",
  },
  areaProv: {
    color: "#676767",
    fontSize: "14px",
    fontWeight: "300",
  },
  root: {
    width: "100%",
  },
});

export default function Provider({ provider }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const list_address = useSelector((state) => state.auth.list_address);
  const address = useSelector((state) => state.auth.address);
  const [state, setState] = useState({
    nameProv: "",
    eventData: [],
    idProviderAddress: "",
    dadosProv: [],
  });

  function getUrlPhoto(id, photo) {
    if (photo) {
      return `https://s3.sa-east-1.amazonaws.com/salutem-webapp-files/upload/user/${id}/${photo}`;
    } else {
      return "https://balancer.salutemplus.com/images/avatar_user_men.jpg";
    }
  }

  useEffect(() => {
    if (address) {
      let dadosGetProv = {
        provider,
        place: address,
        index: "1",
      };
      Api.post(
        `/ms_system/dashboard/getRecordToDashProvider`,
        dadosGetProv
      ).then((res) => {
        setState({
          ...state,
          dadosProv: res.data.doctors,
          nameProv: "",
          idProviderAddress: "",
        });
      });
    }
  }, [address]);

  useEffect(() => {
    if (state.nameProv && state.idProviderAddress) {
      Api.post(`/ms_system/dashboard/getCalendarProfessionalProvider`, {
        doctor: state.nameProv,
        idProviderAddress: state.idProviderAddress,
        dateInit: moment().format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
      }).then((res) => {
        setState({ ...state, eventData: res.data.calendar });
      });
    }
  }, [state.nameProv]);

  return (
    <div className="col-12 animated slideInUpTiny animation-duration-4 m-0 p-0">
      <Typography className="pt-3 ml-3" variant="h6" gutterBottom>
        Profissional
      </Typography>
      <div className="col-12">
        <div className="pb-3">
          <TextField
            select
            value={address}
            label="Endereços"
            onChange={(e) => dispatch(changeAddress(e.target.value))}
            fullWidth
          >
            {list_address.map((e) => (
              <MenuItem value={e.id} key={e.id}>
                <span className="ml-2 d-block">{e.name}</span>
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div hidden={address === ""}>
          {state.dadosProv.length > 0 ? (
            <TextField
              select
              label="Profissional"
              value={state.nameProv}
              onChange={(e, attr) => {
                setState({
                  ...state,
                  nameProv: e.target.value,
                  idProviderAddress: attr.props.idAddress,
                });
              }}
              fullWidth
              className="teste mb-3"
            >
              {state.dadosProv.map((e) => (
                <MenuItem value={e.name} idAddress={e.id} key={e.id}>
                  <Avatar
                    alt="Remy Sharp"
                    src={getUrlPhoto(e.fk_ls_user, e.photo)}
                    className={classes.avatar}
                  />
                  <div>
                    <span className="ml-2 d-block">{e.name}</span>
                    <span
                      className={`ml-2 d-block ${classes.areaProv}`}
                      style={{
                        marginTop: "0px",
                      }}
                    >
                      {e.area}
                    </span>
                  </div>
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Typography className="pt-3 ml-3" variant="h6" gutterBottom>
              Não possui Profissionais Registrados
            </Typography>
          )}
        </div>
        <div className="col-12 m-0 p-0 mt-1"></div>
      </div>
      <div
        className="col-12 mt-5"
        hidden={state.nameProv === "" ? true : false}
      >
        <Calendar eventsData={state.eventData} />
        {/* <TableProv naneProv={state.nameProv} /> */}
      </div>
    </div>
  );
}
