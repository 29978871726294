import React from "react";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnSave, BtnCan } from "components/Button";
import EditText from "./EditText";
import PrintConductGuidance from "./PrintConductGuidance";

export default function ModalAddConduct({ open, close, data, handleChange }) {
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    if (data.text) {
      setText(data.text);
    } else {
      setText("");
    }
  }, [data]);

  const setEditListFormPrint = (params) => {
    setText(params);
  };

  const handleText = () => {
    handleChange(data.index, text, "text");
    handleClose();
  };

  const handleClose = () => {
    setText("");
    close();
  };

  return (
    <div>
      <ModalResponsive
        title={"Adicionar Conduta"} // titulo
        open={open}
        close={handleClose}
        maxWidth={"sm"}
        buttons={
          <div className="col-12">
            <BtnCan title={"fechar"} onClick={handleClose} float="left" />
            <BtnSave title={"Adicionar"} onClick={handleText} float="right" />
          </div>
        }
      >
        <div className="col-12">
          <div
            className="col-12 d-flex"
            style={{
              justifyContent: "space-around",
            }}
          >
            <div style={{ display: "grid" }}>
              <span className="font-weight-bold"> Sintoma:</span>
              <span> {data.description}</span>
            </div>
            <div style={{ display: "grid" }}>
              <span className="font-weight-bold">Nível de Gravidade:</span>
              <span> {data.gravity}</span>
            </div>
            <div style={{ display: "grid" }}>
              <span className="font-weight-bold"> Data de Início:</span>
              <span> {data.dateStart}</span>
            </div>
            <div style={{ display: "grid" }}>
              <span className="font-weight-bold"> Data de Fim:</span>
              <span> {data.dateEnd ? data.dateEnd : "--"}</span>
            </div>
          </div>

          <EditText
            textConductGuidance={text}
            setEditListFormPrint={setEditListFormPrint}
          />

          <PrintConductGuidance textConductGuidance={text} />
        </div>
      </ModalResponsive>
    </div>
  );
}
