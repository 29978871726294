import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardBody } from "reactstrap";
import NoRecord from "components/Alert/NoRecord";
import { Tooltip, IconButton } from "@material-ui/core";
import { viewHistory } from "actions/consult/Appointment";
import HistoryIcon from "@material-ui/icons/History";
import HistoryPatient from "components/HistoryPatient";
import { getInfoPatient } from "actions/consult/PatientInfo";
import ComponentsProfile from "components/ComponentsProfile";
import SearchComponent from "components/SearchComponent";
import { getStringToSearch } from "services/functions";

function Residents() {
  const [patient, setPatient] = React.useState("");
  const [search, setSearch] = React.useState("");
  const residents = useSelector(
    ({ evolutionDaily }) => evolutionDaily.residents
  );
  const dispatch = useDispatch();

  return (
    <div className="col-12 m-0 p-0 animated slideInUpTiny animation-duration-4">
      <CardBody>
        <div className="col-12 m-0 p-0">
          <h2 className="font-weight-semibold d-flex">
            <span className="mt-3 mr-5">Residentes</span>
            {residents.length > 0 && (
              <SearchComponent searchContact={(e) => setSearch(e)} />
            )}
          </h2>
        </div>
        <div className="col-12">
          {residents.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {residents
                  .filter(
                    (row) =>
                      search.length === 0 ||
                      getStringToSearch(row.name).includes(
                        getStringToSearch(search)
                      )
                  )
                  .map((e, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="w-100 d-flex">
                        <ComponentsProfile
                          nameProfile={e.name}
                          subTitle={e.birthday}
                          photo={e.photo}
                          id={e.id}
                        />
                      </td>
                      <td className="">
                        <Tooltip title="Histórico do paciente" placement="top">
                          <IconButton
                            style={{ padding: "6px" }}
                            onClick={() => {
                              setPatient(e.id);
                              dispatch(viewHistory("personalData"));
                              dispatch(getInfoPatient(e.id));
                            }}
                          >
                            <HistoryIcon
                              style={{
                                fontSize: "19px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        {/* 
                        <Tooltip
                          title="Evolução diária do paciente"
                          placement="top"
                        >
                          <NavLink to="">
                            <IconButton
                              style={{ padding: "6px" }}
                              onClick={() => {
                                setPatient(e.id);
                                // dispatch(viewHistory("personalData"));
                              }}
                            >
                              <AssignmentIcon
                                style={{
                                  fontSize: "19px",
                                  margin: "0px",
                                  padding: "0px"
                                }}
                              />
                            </IconButton>
                          </NavLink>
                        </Tooltip>
                        <Tooltip title="Consultório" placement="top">
                          <NavLink to={}>
                          <IconButton
                            style={{ padding: "6px" }}
                            onClick={() => {
                              setPatient(e.id);
                              // dispatch(viewHistory("personalData"));
                            }}
                          >
                            <i
                              style={{
                                fontSize: "19px",
                                margin: "0px",
                                padding: "0px"
                              }}
                              className="zmdi zmdi-assignment-o"
                            />
                          </IconButton>
                          </NavLink>
                        </Tooltip> 
                      */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <NoRecord />
          )}
        </div>
      </CardBody>
      <HistoryPatient id_patient={patient} />
    </div>
  );
}

export default Residents;
