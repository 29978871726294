import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import api from "services/api";
import { delay } from "redux-saga";

const cadProceduresGroups = async (
  doctor,
  nameGroupValue,
  descGroupValue,
  procedureSelected,
  idEdit
) => {
  const response = await api.post(
    `/microservice/proceduresGroups/cadProceduresGroups/`,
    {
      doctor,
      nameGroupValue,
      descGroupValue,
      procedureSelected,
      idEdit,
    }
  );

  return response;
};

const getlistProceduresGroups = async (doctor, user_address) => {
  const response = await api.post(
    `/microservice/proceduresGroups/getListProceduresGroup/`,
    { doctor, user_address }
  );

  const data = await response.data.record;
  const aux = [];
  data.forEach((element) => {
    element = {
      ...element,
      check: false,
    };
    aux.push(element);
  });
  return aux;
};

const delProceduresGroup = async (id) => {
  const response = await api.post(
    `/microservice/proceduresGroups/delProceduresGroup/`,
    {
      id,
    }
  );

  return response;
};

const getInfoProceduresGroupsForEdit = async (id) => {
  const response = await api.post(
    `/microservice/proceduresGroups/getInfoProceduresGroups/`,
    { id }
  );
  //Retorna Infos do Grupo(Nome, Desc)
  const data = await response.data.record;
  //Retorna lista de exames cadastrados no respectivo Grupo
  const list = await response.data.ItensProcedures;

  return { data, list };
};

function* asyncCadProceduresGroups(action) {
  try {
    const resultCadGroupProcedures = yield call(
      cadProceduresGroups,
      action.payload.doctor,
      action.payload.nameGroupValue,
      action.payload.descGroupValue,
      action.payload.proceduresSelected,
      action.payload.idEdit
    );
    if (resultCadGroupProcedures.data.success) {
      yield put({
        type: "HANDLE_CAD_EXAMS_GROUP_SUCCESS",
        payload: { resultCadGroupProcedures: resultCadGroupProcedures },
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!",
      });

      yield put({
        type: "REQUEST_LIST_DOCTOR_EXAMS_GROUP",
        payload: { doctor: action.payload.doctor },
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao executar esta operação",
      });
    }
    yield put({
      type: "HANDLE_ADD_FUNC",
      payload: {
        active: "view",
        color: "primary",
        texto: "Adicionar",
        hidden: "hidden",
        icon: "plus",
      },
    });
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log(error);
  }
}

function* asyncListProceduresGroups(action) {
  try {
    const listProceduresGroups = yield call(
      getlistProceduresGroups,
      action.payload.doctor,
      action.payload.user_address
    );
    yield put({
      type: "REQUEST_LIST_PROCEDURES_GROUP_SUCCESS",
      payload: { listProceduresGroups: listProceduresGroups },
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncDelProceduresGroups(action) {
  try {
    yield call(delProceduresGroup, action.payload.id);
    yield put({
      type: "REMOVE_GROUP_PROCEDURES_SUCESS",
      id: action.payload.id,
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncDataProceduresGroupForEdit(action) {
  try {
    const InfosGroup = yield call(
      getInfoProceduresGroupsForEdit,
      action.payload.id
    );
    yield put({
      type: "REQUEST_INFO_PROCEDURES_GROUP_SUCCESS",
      payload: {
        InfosGroup: InfosGroup.data,
        ListItensProceduresEdit: InfosGroup.list,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export function* requestCadProceduresGroups() {
  yield takeEvery("CAD_PROCEDURES_GROUPS", asyncCadProceduresGroups);
}

export function* requestListProceduresGroups() {
  yield takeEvery(
    "REQUEST_LIST_DOCTOR_PROCEDURES_GROUP",
    asyncListProceduresGroups
  );
}

export function* requestDelProceduresGroups() {
  yield takeEvery("DEL_GROUP_PROCEDURES", asyncDelProceduresGroups);
}

export function* requestDataProceduresGroupForEdit() {
  yield takeEvery(
    "GET_DATA_GROUP_PROCEDURES_FOR_EDIT",
    asyncDataProceduresGroupForEdit
  );
}

export default function* rootSaga() {
  yield all([
    fork(requestCadProceduresGroups),
    fork(requestListProceduresGroups),
    fork(requestDelProceduresGroups),
    fork(requestDataProceduresGroupForEdit),
  ]);
}
