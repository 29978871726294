import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Tooltip, IconButton } from "@material-ui/core";
import ListExamsGroup from "./ListExamsGroup";
import FormExamsGroup from "./FormExamsGroup";
import Api from "services/api";
import { showMsg } from "services/functions";

export default function ExamsGroup({ close }) {
  const [mode, setMode] = useState("view");
  const [data, setData] = useState({ id: "" });
  const [record, setRecord] = useState([]);

  const doctor = useSelector((state) => state.auth.authUser);
  const dispatch = useDispatch();

  useEffect(() => {
    Api.post(`/microservice/examsgroup/getListExamsGroup/`, {
      doctor,
    }).then((res) => setRecord(res.data.record));
  }, []);

  const handleEdit = (obj) => {
    setRecord(record.map((row) => (row.id === obj.id ? obj : row)));
  };

  const handleAdd = (obj) => {
    setRecord([obj, ...record]);
  };

  const handleRemove = (id) => {
    Api.post(`/microservice/examsgroup/removeExamsGroup/`, {
      id,
    })
      .then(() => {
        setRecord(record.filter((row) => row.id !== id));
        showMsg("success", "Excluído com sucesso!", dispatch);
      })
      .catch(() => showMsg("error", "Ocorreu um erro!", dispatch));
  };

  return (
    <div className="row">
      <h2 className="text-primary font-weight-semibold w-100">
        Meus grupos de exames
        <Tooltip title="Fechar" placement="top">
          <IconButton
            aria-label="Fechar"
            onClick={close}
            style={{ float: "right", marginTop: "-10px" }}
          >
            <i className="zmdi zmdi-close" />
          </IconButton>
        </Tooltip>
      </h2>

      <div className="col-12">
        <Button
          className="jr-btn jr-btn-sm"
          variant="contained"
          style={{ float: "right" }}
          color="primary"
          onClick={() => setMode("form")}
        >
          <i className="zmdi zmdi-plus zmdi-hc-lg" />
          <span>Novo Grupo</span>
        </Button>
      </div>
      <div className="col-md-12">
        {mode === "view" ? (
          <ListExamsGroup
            edit={(obj) => {
              setMode("form");
              setData(obj);
            }}
            handleRemove={handleRemove}
            record={record}
          />
        ) : (
          <FormExamsGroup
            open={true}
            doctor={doctor}
            data={data}
            handleAdd={handleAdd}
            handleEdit={handleEdit}
            onClose={() => setMode("view")}
          />
        )}
      </div>
    </div>
  );
}
