import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import api from "services/api";

import { setConditions } from "actions/configuration/TagsConditions";

import { Button, Chip, Tooltip, Menu, MenuItem } from "@material-ui/core";

import AutocompleteProcedures from "./autocomplete/AutocompleteProducere";

import "./styles.css";

class ConditionalProducere extends Component {
  state = {
    produceres: [],
    open: false,
    anchorEl: undefined,
    button_text: true
  };
  handleChange = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };
  handleRequestClose = value => {
    this.setState({ open: false, button_text: value });
  };
  handleChangeAutocomplete = () => event => {
    let prod = this.state.produceres.map(pro => pro);
    prod.push(event);
    this.setState({ produceres: prod });
    this.props.setConditions("SET_CONDITION_PRODUCERE", prod);
  };
  handleDeleteProdure = index => {
    let newArr = this.state.produceres.map(prod => prod);
    newArr.splice(index, 1);
    this.setState({ produceres: newArr });
    this.props.setConditions("SET_CONDITION_PRODUCERE", newArr);
  };
  componentDidMount() {
    if (this.props.params !== null) {
      api
        .post(`/ms_configuration/configurationTags/getProducereFromId/`, {
          ids: this.props.params
        })
        .then(res => {
          let prod = res.data;
          this.setState({ produceres: prod });
          this.props.setConditions("SET_CONDITION_PRODUCERE", prod);
        });
    } else {
      this.setState({ produceres: [] });
    }
  }
  render() {
    return (
      <div className="row" style={{ marginTop: "20px" }}>
        <div className="col-md-12">
          <h4>Procedimentos: </h4>
        </div>
        <div style={{ padding: "10px" }}>
          <Button
            onClick={this.handleChange}
            fullWidth={true}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            {this.state.button_text ? "Igual A " : "Diferente De "}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </Button>
          <Menu
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
          >
            <MenuItem onClick={() => this.handleRequestClose(true)}>
              Igual A
            </MenuItem>
            <MenuItem onClick={() => this.handleRequestClose(false)}>
              Diferente De
            </MenuItem>
          </Menu>
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-12">
              <AutocompleteProcedures
                handleChangeAutocomplete={this.handleChangeAutocomplete()}
              />
            </div>
          </div>
          <div className="row">
            {this.state.produceres.map((prod, index) => (
              <Tooltip title={prod.description} key={prod.id}>
                <Chip
                  label={prod.description}
                  onDelete={() => this.handleDeleteProdure(index)}
                  className="chip-conditions"
                  color="primary"
                />
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setConditions }, dispatch);

export default connect(null, mapDispatchToProps)(ConditionalProducere);
