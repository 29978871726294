import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { TextField, MenuItem } from "@material-ui/core";
import { requestMedList } from "actions/consult/Prescription";
import { getStringToSearch, getClassImage, getTarge } from "services/functions";
import AutocompleteGeneric from "components/Autocomplete/AutocompleteGeneric";
import { makeStyles } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

const useStyles = makeStyles((theme) => ({
  imgType: {
    width: "30px",
    height: "30px",
  },
  imgTarget: {
    width: "30px",
    height: "30px",
    marginLeft: "15px",
  },
  nameDisplay: {
    marginLeft: "15px",
  },
  nameLab: {
    float: "right",
    paddingRight: "10px",
    fontWeight: "300",
  },
}));

export default function MedicinesDefault({ onChange, hideAutocomplete }) {
  const [state, setState] = useState({
    group_selected: false,
    group_id: "all",
    isOpen: true,
  });

  const provider = useSelector((state) => state.auth.address_selected.user_id);
  const list_med = useSelector((state) => state.prescription.list_med);
  const dispatch = useDispatch();
  const classes = useStyles();

  function handleChangeSelectedMedicineGroup(group_id, provider) {
    dispatch(requestMedList(group_id, false, provider));
    setState({ ...state, group_selected: true, group_id });
    hideAutocomplete();
  }

  function handleChangeSelectedMedicine(medicine) {
    medicine.date_start = moment().format("YYYY-MM-DD");
    onChange(medicine);
    hideAutocomplete();
    setState({ group_selected: false, group_id: "", isOpen: false });
  }

  return (
    <div className="row">
      <div className="col-12">
        <TextField
          select
          label="Grupo de Medicamentos:"
          value={state.group_id}
          onChange={(e) =>
            handleChangeSelectedMedicineGroup(e.target.value, provider)
          }
          fullWidth
          InputLabelProps={{ shrink: true }}
        >
          <MenuItem key="1" value="all">
            Todos os Medicamentos
          </MenuItem>
          <MenuItem key="0" value="pref">
            Medicamentos Farmácia Prefeitura
          </MenuItem>
        </TextField>
      </div>
      <div className="col-12 mt-3">
        {state.group_id === "pref" && (
          <AutocompleteGeneric
            limitTags={2}
            filterOptions={(x) => x}
            disableCloseOnSelect
            onChange={(e) => handleChangeSelectedMedicine(e)}
            getOptionLabel={(option) =>
              `${option.name_display} ${
                option.formulation ? option.formulation : ""
              }`
            }
            label={"Selecione o medicamento"}
            options={list_med}
            shrink={true}
            filterOptions={(items, { inputValue }) => {
              return [
                ...items.filter(
                  (row) =>
                    inputValue.length > 0 &&
                    getStringToSearch(row.search_string).includes(
                      getStringToSearch(inputValue)
                    )
                ),
              ];
            }}
            renderOption={(option, { inputValue }) => {
              let imgIcon = getClassImage(option.medtype_display);
              let targe = getTarge(option.targe);

              return (
                <div className="w-100">
                  <span style={{ float: "left" }}>
                    <img
                      src={imgIcon}
                      alt="Tipo do medicamento"
                      className={classes.imgType}
                    />
                    <img
                      src={targe}
                      alt="Tarja do medicamento"
                      className={classes.imgTarget}
                    />
                    <strong className={classes.nameDisplay}>
                      {option.active_principle} ({option.name_display}){" "}
                      {option.formulation}
                    </strong>{" "}
                    {option.form} - <small>{option.package}</small>
                  </span>

                  {option.id_goods && (
                    <Chip
                      variant="outlined"
                      className="ml-3"
                      size="small"
                      label={option.stock}
                      icon={<HomeWorkIcon />}
                    />
                  )}
                  <span className={classes.nameLab}>
                    {option.laboratory_display}
                  </span>
                </div>
              );
            }}
          />
        )}
      </div>
    </div>
  );
}
