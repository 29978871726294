import React, { useState, useEffect } from "react";
import { Typography, TextField, IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { BtnSave } from "components/Button";
import { SYMPTOMS_ARRAY } from "constants/CustomSymptoms";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import { makeStyles } from "@material-ui/core/styles";
import ModalAddConduct from "app/routes/consult/routes/initial/modal/modalAddConduct";
import ModalHistory from "app/routes/consult/routes/initial/modal/modalHistory";
import HistoryIcon from "@material-ui/icons/History";
import Chip from "@material-ui/core/Chip";
import Api from "services/api";
import { showMsg } from "services/functions";
import { ButtonLarge } from "components/Button";
import CircularProgress from "components/CircularProgress";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function Symptoms({
  isOpen,
  handleOpen,
  type,
  quize,
  handleBack,
  handleClose,
  symptoms_covid,
  progress,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const appointment = useSelector((state) => state.appointment);
  const patient = useSelector((state) =>
    type === "pp" ? state.auth.authUser : state.appointment.patient
  );
  const doctor = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);

  const [list, setList] = React.useState(
    SYMPTOMS_ARRAY.map(
      (row) =>
        row && { ...row, text: "", dateStart: "", dateEnd: "", gravity: "" }
    )
  );
  const [modalAdd, setModalAdd] = React.useState({ open: false, data: "" });
  const [modalHistory, setModalHistory] = React.useState({
    open: false,
    data: "",
  });

  const [data, setState] = useState({
    symptoms_covid: symptoms_covid,
    user: useSelector((state) => state.auth.authUser),
  });

  const [loader, setLoader] = useState(false);

  React.useEffect(() => {
    getInitialCovid();
  }, [isOpen === true]);

  const getInitialCovid = () => {
    if (list) {
      setLoader(true);
      Api.post(`/microservice/consult/getInitialCovid`, {
        patient,
      })
        .then((res) => {
          let listNew = list;
          listNew.forEach((element, idx) => {
            res.data.record.forEach((row) => {
              if (row.fk_ls_table_type === element.id) {
                list[idx] = {
                  ...element,
                  id_symptoms: row.id,
                  dateStart: row.start,
                  gravity: row.gravity,
                  status: true,
                  text: row.conduct,
                };
              }
            });
          });

          setList(listNew);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSave = () => {
    let formData = {
      patient,
      doctor,
      address,
      appointment,
      type,
      list: list.filter((row) => row.status === true),
    };

    Api.post(`/microservice/consult/saveInitialCovid`, formData)
      .then((res) => {
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      });

    if (quize) {
      Api.post("microservice/patientportal/saveSymptomsCovid", data) //falta criar função backend
        .then(({ data }) => {
          if (data.success) {
            handleClose();
          } else {
            throw true;
          }
        })
        .catch(() => {
          showMsg("error", "Não foi possível salvar as informações", dispatch);
        });
    }
  };

  const handleChange = (index, value, title) => {
    setList(
      list.map((row, idx) =>
        index === idx ? { ...row, [title]: value } : { ...row }
      )
    );
  };

  const handleHistory = () => {
    Api.post(`/microservice/consult/getInitialCovidHistory`, { patient })
      .then((res) => {
        setModalHistory({
          open: true,
          data: { list: res.data.record },
        });
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      });
  };

  const currencies = [
    {
      label: "Moderado",
      value: 1,
    },
    {
      label: "Médio",
      value: 1,
    },
    {
      label: "Acentuado",
      value: 1,
    },
  ];

  if (loader) {
    return <CircularProgress />;
  }

  return (
    <div>
      {/* <ExpansionPanel
        expanded={isOpen}
        onChange={() => {
          if (!isOpen) {
            handleOpen(3);
          } else {
            handleOpen("");
          }
        }}
      > */}
      {/* <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Sintomas</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails> */}
      <div className="row w-100">
        <Typography>Sintomas</Typography>
        <div className="col-12">
          <Chip
            size="small"
            label="Historico"
            color="primary"
            avatar={<HistoryIcon />}
            onClick={handleHistory}
            style={{ float: "right" }}
          />
        </div>
        <div className="col-12">
          {list.map((row, index) => (
            <>
              {row.title && (
                <div className="col-12">
                  <h3 className="font-weight-bold">{row.title}</h3>
                </div>
              )}
              <div className="col-12 d-flex">
                <div className="col-3 d-flex align-items-center">
                  {row.description}
                </div>
                <div className="col-1 d-flex align-items-center">
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onClick={(e) =>
                          handleChange(index, !row.status, "status")
                        }
                        checked={row.status}
                      />
                    }
                  />
                </div>
                {row.status && (
                  <>
                    <div className="col-7 d-flex">
                      <div className="col-4">
                        <TextField
                          id="standard-select-currency"
                          select
                          value={row.gravity}
                          label="Nível de Gravidade:"
                          onChange={(e) =>
                            handleChange(index, e.target.value, "gravity")
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                        >
                          {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.label}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div className="col-4">
                        <TextField
                          id="date"
                          label="Data de Início:"
                          type="date"
                          value={row.dateStart}
                          onChange={(e) =>
                            handleChange(index, e.target.value, "dateStart")
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-4">
                        <TextField
                          id="date"
                          label="Data de Fim:"
                          type="date"
                          value={row.dateEnd}
                          onChange={(e) =>
                            handleChange(index, e.target.value, "dateEnd")
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-1">
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={() =>
                          setModalAdd({
                            open: true,
                            data: { ...row, index },
                          })
                        }
                      >
                        <NoteAddIcon fontSize="small" color="primary" />
                      </IconButton>
                    </div>
                  </>
                )}
              </div>
            </>
          ))}
        </div>
        <div className="col-12 mt-3">
          {quize ? (
            <>
              <ButtonLarge
                onClick={progress === 0 ? handleClose : handleBack}
                style={{
                  padding: "1px",
                  width: "unset",
                  minWidth: "100px",
                  float: "left",
                }}
              >
                {progress === 0 ? "Sair" : "Voltar"}
              </ButtonLarge>
              <ButtonLarge
                onClick={handleSave}
                style={{
                  padding: "1px",
                  width: "unset",
                  minWidth: "100px",
                  float: "right",
                  backgroundColor: symptoms_covid ? "#3d8b40" : "unset",
                  color: symptoms_covid ? "#fff" : "#8c8c8c",
                }}
              >
                Confirmar
              </ButtonLarge>
            </>
          ) : (
            <BtnSave onClick={handleSave} float="right" />
          )}
        </div>
      </div>
      {/* </ExpansionPanelDetails> */}
      <ModalAddConduct
        open={modalAdd.open}
        data={modalAdd.data}
        handleChange={handleChange}
        close={() => setModalAdd({ open: false, data: "" })}
      />
      <ModalHistory
        open={modalHistory.open}
        data={modalHistory.data}
        close={() => setModalHistory({ open: false, data: "" })}
      />
      {/* </ExpansionPanel> */}
    </div>
  );
}
