import React, { useEffect } from "react";

import {
  MenuItem,
  TextField,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core/";
import moment from "moment";
import { MultipleSelect } from "react-select-material-ui";

export default function Hydratation({ state, setState, error, pattern }) {
  useEffect(() => {
    if (state.quantity !== state.hours.length) {
      let arr = [];
      for (let index = 0; index < state.quantity; index++) {
        arr.push("");
      }
      setState({ ...state, hours: arr });
    }
  }, [state.quantity]);

  useEffect(() => {
    if (state.duration) {
      let diff = state.duration - 1;
      if (diff === 0) {
        setState({ ...state, end: state.start });
      } else {
        setState({
          ...state,
          end: moment(state.start)
            .add(diff, "days")
            .format("YYYY-MM-DD"),
        });
      }
    }
  }, [state.start, state.duration]);

  useEffect(() => {
    let start = state.start;
    let end = state.end;
    let diff = moment(end).diff(moment(start), "days") + 1;
    setState({ ...state, duration: diff });
  }, [state.end]);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const handleChangeHour = (index, value) => {
    let aux = state.hours.map((row, key) => (key === index ? value : row));
    setState({ ...state, hours: aux });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 pt-4">
          <TextField
            select
            id="type"
            label="Tipo da Hidratação:"
            value={state.type_hydratation}
            onChange={(e) => handleChange("type_hydratation", e.target.value)}
            fullWidth
            error={error && !state.type_hydratation}
            helperText={
              error &&
              !state.type_hydratation &&
              "Selecione o tipo da hidratação"
            }
          >
            <MenuItem key="1" value="Oral">
              Oral
            </MenuItem>

            {!pattern && (
              <MenuItem key="2" value="Enteral">
                Enteral
              </MenuItem>
            )}
            {!pattern && (
              <MenuItem key="3" value="Subcutânea (venóclise)">
                Subcutânea (venóclise)
              </MenuItem>
            )}
            {!pattern && (
              <MenuItem key="4" value="Venosa">
                Venosa
              </MenuItem>
            )}
            {/* <MenuItem key="5" value="Administração Livre">
              Administração Livre
            </MenuItem> */}
          </TextField>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-12">
          <FormControl className="my-3" fullWidth>
            <FormHelperText>Volume:</FormHelperText>
            <Input
              fullWidth
              value={state.volume}
              onChange={(e) => handleChange("volume", e.target.value)}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              endAdornment={<InputAdornment position="end">ml</InputAdornment>}
              error={error && !state.volume}
            />
            {error && !state.volume && (
              <FormHelperText style={{ color: "#f44336" }}>
                Insira o volume
              </FormHelperText>
            )}
          </FormControl>
        </div>
        {state.type_hydratation &&
          state.type_hydratation !== "Oral" &&
          state.type_hydratation !== "Administração Livre" && (
            <>
              <div className="col-md-4 col-12">
                <FormControl className="my-3" fullWidth>
                  <FormHelperText>Fluxo:</FormHelperText>
                  <Input
                    fullWidth
                    value={state.flow}
                    onChange={(e) => handleChange("flow", e.target.value)}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                    error={error && !state.flow}
                  />
                </FormControl>
              </div>
              <div className="col-md-4 col-12 mt-4">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={state.volume_type}
                    onChange={(e) =>
                      handleChange("volume_type", e.target.value)
                    }
                    row
                  >
                    <FormControlLabel
                      value="gts/min"
                      control={<Radio color="primary" />}
                      label="gts/min"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="ml/hora"
                      control={<Radio color="primary" />}
                      label="ml/hora"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </>
          )}
        {(state.type_hydratation === "Subcutânea" ||
          state.type_hydratation === "Venosa") && (
          <div className="col-md-4 col-12 mt-3">
            <TextField
              select
              id="serum_type"
              label="Tipo de Soro:"
              value={state.serum_type}
              onChange={(e) => handleChange("serum_type", e.target.value)}
              fullWidth
              error={error && !state.serum_type}
              helperText={
                error && !state.serum_type && "Selecione o tipo do soro"
              }
            >
              <MenuItem key="1" value="Fisiológico">
                Fisiológico
              </MenuItem>
              <MenuItem key="2" value="Glicosado 5%">
                Glicosado 5%
              </MenuItem>
              <MenuItem key="3" value="Ringer">
                Ringer
              </MenuItem>
              <MenuItem key="4" value="Outros">
                Outros
              </MenuItem>
            </TextField>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4 col-12">
          <FormControl className="my-3" fullWidth>
            <FormHelperText>x/Dia:</FormHelperText>
            <Input
              fullWidth
              value={state.quantity}
              onChange={(e) => handleChange("quantity", e.target.value)}
              inputProps={{ maxLength: 2 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              error={error && !state.quantity}
            />
            {error && !state.quantity && (
              <FormHelperText style={{ color: "#f44336" }}>
                Defina quantas vezes por dia
              </FormHelperText>
            )}
          </FormControl>
        </div>
        {state.hours.map((row, index) => (
          <div
            key={index}
            className="col-md-2 col-6"
            style={{ paddingTop: "1.2rem" }}
          >
            <TextField
              type="time"
              label="Horário:"
              value={row}
              onChange={(e) => handleChangeHour(index, e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={error && !row}
            />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-md-3 col-6 mt-3">
          <TextField
            id="Intervalo"
            select
            label="Intervalo:"
            value={state.interval}
            onChange={(e) => handleChange("interval", e.target.value)}
            fullWidth
            error={error && !state.interval}
            helperText={error && !state.interval && "Selecione um intervalo"}
          >
            <MenuItem key="1" value="Diário">
              Diário
            </MenuItem>
            <MenuItem key="12" value="Dias da Semana">
              Dias da Semana
            </MenuItem>
            <MenuItem key="2" value="2x por semana">
              2x por semana
            </MenuItem>
            <MenuItem key="3" value="3x por semana">
              3x por semana
            </MenuItem>
            <MenuItem key="4" value="Semanal">
              Semanal
            </MenuItem>
            <MenuItem key="5" value="15 em 15 dias">
              De 15 em 15 dias
            </MenuItem>
            <MenuItem key="6" value="Mensal">
              Mensal
            </MenuItem>
            <MenuItem key="7" value="Bimestral">
              Bimestral
            </MenuItem>
            <MenuItem key="11" value="Trimestral">
              Trimestral
            </MenuItem>
            <MenuItem key="10" value="Semestral">
              Semestral
            </MenuItem>
            <MenuItem key="8" value="Anual">
              Anual
            </MenuItem>
          </TextField>
        </div>
        {state.interval === "Dias da Semana" && (
          <div className="col-md-9 col-6">
            <MultipleSelect
              label="Selecione os materiais:"
              values={state.days}
              options={[
                "Domingo",
                "Segunda",
                "Terça",
                "Quarta",
                "Quinta",
                "Sexta",
                "Sábado",
              ]}
              onChange={(e) => handleChange("days", e)}
              SelectProps={{
                isCreatable: false,
                msgNoOptionsAvailable: "Todas as opções foram selecionadas",
                msgNoOptionsMatchFilter: "Nenhuma opção para este filtro",
              }}
              style={{
                marginTop: "10px",
                borderBottom:
                  error && state.days.length <= 0 ? "2px solid red" : "none",
              }}
            />
          </div>
        )}
        {!pattern && (
          <div className="col-md-3 col-6" style={{ marginTop: "-5px" }}>
            <FormControl className="my-3" fullWidth>
              <FormHelperText>Duração:</FormHelperText>
              <Input
                id="weight"
                value={state.duration}
                onChange={(e) => handleChange("duration", e.target.value)}
                endAdornment={
                  <InputAdornment position="end">dias</InputAdornment>
                }
                inputProps={{ maxLength: 3 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                error={error && parseInt(state.duration) <= 0}
              />
            </FormControl>
          </div>
        )}

        <div className="col-md-3 col-6 mt-3">
          <TextField
            type="date"
            label="Início:"
            value={state.start}
            onChange={(e) => handleChange("start", e.target.value)}
            fullWidth
          />
        </div>
        {!pattern && (
          <div className="col-md-3 col-6 mt-3">
            <TextField
              type="date"
              label="Fim:"
              value={state.end}
              onChange={(e) => handleChange("end", e.target.value)}
              fullWidth
              error={error && moment(state.end).isBefore(moment(state.start))}
            />
          </div>
        )}
        <div className="col-md-12">
          <TextField
            multiline
            rows={4}
            margin="normal"
            id="obs"
            label="Orientações:"
            type="text"
            fullWidth
            onChange={(e) => handleChange("observation", e.target.value)}
            value={state.observation}
          />
        </div>
      </div>
    </>
  );
}
