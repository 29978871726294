import { all } from "redux-saga/effects";
// Consultório
import patientInfoSaga from "./consult/PatientInfo";
import habitsSaga from "./consult/Habits";
import forwardSaga from "./consult/Forward";
import procedureSaga from "./consult/Procedure";
import personalData from "./consult/PersonalData";
import dysfunctions from "./consult/Dysfunctions";
import Vaccines from "./consult/Vaccines";
import MedicinesGroups from "./configuration/MedicinesGroups";
import AttendanceRecord from "./consult/AttendanceRecord";
import appointment from "./consult/Appointment";
import aga from "./consult/Aga";
import prescription from "./consult/Prescription";
import prescriptionManipulated from "./consult/PrescriptionManipulated";
import Circumferences from "./consult/Circumferences";
import print from "./consult/Print";
import tags from "./consult/Tags";
import painScale from "./consult/PainScale";
import ProcedureGroups from "./configuration/ProcedureGroups";
import Profile from "./configuration/Profile";
import AddProcedure from "./configuration/AddProcedure";
import comparePictures from "./consult/ComparePictures";
import tbca from "./consult/Tbca";
// imports odontologia
import plateIndex from "./consult/odonto/PlateIndex";

// Financeiro
import finance from "./Finance";

// Configurações
import typeEvent from "./configuration/TypeEvent";
import tagsConditions from "./configuration/TagsConditions";

// Evolution
import evolutionPersonal from "./evolution/Personal";
import evolutionGroup from "./evolution/Group";

// Locais de Atendimento
import workplace from "./Workplace";

// Intranet
import intranet from "./Intranet";

// Agenda
import calendar from "./Calendar";

//Agenda Online
import onlineScheduler from "./OnlineScheduler";

//Chat
import chat from "./comunication/Chat";

// Globals
import globals from "./Globals";
import auth from "./Auth";
import configuration from "./Configuration";

export default function* rootSaga(getState) {
  yield all([
    // sagas salutem consult
    AttendanceRecord(),
    globals(),
    patientInfoSaga(),
    painScale(),
    habitsSaga(),
    forwardSaga(),
    procedureSaga(),
    personalData(),
    dysfunctions(),
    appointment(),
    aga(),
    Circumferences(),
    Vaccines(),
    MedicinesGroups(),
    prescription(),
    prescriptionManipulated(),
    print(),
    tags(),
    comparePictures(),
    // Financeiro
    finance(),
    //Configurations
    tagsConditions(),
    typeEvent(),
    AddProcedure(),
    ProcedureGroups(),
    Profile(),
    //Evolution
    evolutionPersonal(),
    evolutionGroup(),
    workplace(),
    intranet(),
    calendar(),
    tbca(),
    //odontologia
    plateIndex(),
    //auth
    auth(),
    configuration(),
    //Agendamento Online
    onlineScheduler(),
    //Chat
    chat(),
  ]);
}
