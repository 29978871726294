import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton
} from "@material-ui/core";
import * as AddProcedureActions from "actions/configuration/AddProcedure";
import ViewAddProcedure from "./ViewAddProcedure";
import FormAddProcedure from "./FormAddProcedure";

class ExamsGroup extends Component {
  constructor(props) {
    super(props);
    this.handleAdd = this.handleAdd.bind(this);
  }

  handleAdd() {
    if (this.props.AddProcedure.active === "view") {
      this.props.handleAddFunction(
        "form",
        "secondary",
        "cancelar",
        true,
        "close"
      );
    } else {
      this.props.handleAddFunction(
        "view",
        "primary",
        "Adicionar",
        "hidden",
        "plus"
      );
    }
  }

  render() {
    return (
      <div className="row">
        <h2 className="text-primary font-weight-semibold w-100">
          Seus Procedimentos
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={this.props.close}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>

        <div className="col-md-12">
          <Button
            className="jr-btn jr-btn-sm"
            variant="contained"
            style={{ float: "right" }}
            color={this.props.AddProcedure.color}
            onClick={this.handleAdd}
          >
            <i
              className={`zmdi zmdi-${this.props.AddProcedure.icon} zmdi-hc-lg `}
            />
            <span>{this.props.AddProcedure.texto}</span>
          </Button>
        </div>
        <div className="col-md-12">
          {this.props.AddProcedure.active === "view" ? (
            <ViewAddProcedure />
          ) : (
            <Dialog
              fullWidth={true}
              maxWidth="lg"
              open={true}
              onClose={() => {}}
              aria-labelledby="max-width-dialog-title"
              scroll="body"
            >
              <DialogContent>
                <FormAddProcedure />
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    doctor: state.auth.authUser,
    AddProcedure: state.AddProcedure
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(AddProcedureActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamsGroup);
