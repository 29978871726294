import React, { Component } from "react";
import "./styles.css";

import TableHistory from "./tableHistory";

class PersonalHistory extends Component {
  state = {
    patient: this.props.patient,
    record: []
  };

  render() {
    return (
      <div className="container-person border">
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400"
          }}
        >
          {" "}
          Histórico pessoal
        </h3>
        <TableHistory patient={this.props.patient} />
      </div>
    );
  }
}

export default PersonalHistory;
