import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import api from "services/api";

const postAddNewAga = async params => {
  const { data, patient, doctor, questionnaire, total, descTotal } = params;
  const obj = { data, patient, doctor, questionnaire, total, descTotal };

  const response = await api.post(`/microservice/aga/saveAgaIvcf/`, obj);

  const res = await response.data;
  return res;
};

const requestAsyncQuestionsAga = async () => {
  const data = await api.post(`/microservice/aga/getQuestionnaires`, {});
  const response = await data.data.forms;
  return response;
};

const requestAsyncHistoryAga = async patient => {
  const data = await api.post(`/microservice/aga/getRegisterAgaForms`, {
    patient
  });
  const response = await data.data.record;
  return response;
};

const requestAsyncHistoryAnswerAga = async header => {
  const data = await api.post(`/microservice/aga/getRegisterAga`, header);
  const response = await data.data.record;
  return response;
};

function* requestAddNewAga(action) {
  try {
    const response = yield call(postAddNewAga, action);
    yield put({
      type: "ADD_NEW_AGA",
      payload: response.success
    });
    // yield call(requestQuestionsAga);
    yield call(requestAsyncHistoryAga, action.patient);

    if (response.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!"
      });
    }

    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!"
    });
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  }
}

function* requestQuestionsAga() {
  try {
    // variável que faz a busca
    const getQuestions = yield call(requestAsyncQuestionsAga);
    // parte do método que identifica qual método usar após a conclusão do método acima
    yield put({
      type: "GET_QUESTIONS_AGA_COMPLETE",
      questions: { getQuestions }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

function* requestHistoryAga(action) {
  try {
    // variável que faz a busca
    const getHistory = yield call(requestAsyncHistoryAga, action.patient);
    // parte do método que identifica qual método usar após a conclusão do método acima
    yield put({
      type: "REQUEST_HISTORY_AGA_COMPLETE",
      payload: getHistory
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

function* requestHistoryRegisterAga(action) {
  try {
    // variável que faz a busca
    const getHistoryRegister = yield call(
      requestAsyncHistoryAnswerAga,
      action.header.id
    );
    // parte do método que identifica qual método usar após a conclusão do método acima
    yield put({
      type: "REQUEST_HISTORY_REGISTER_AGA_COMPLETE",
      payload: getHistoryRegister
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

export function* requestAddAgaAga() {
  yield takeLatest("REQUEST_SAVE_AGA", requestAddNewAga);
}

export function* requestQuestions() {
  yield takeLatest("GET_QUESTIONS_AGA", requestQuestionsAga);
}

export function* requestHistory() {
  yield takeLatest("REQUEST_HISTORY_AGA", requestHistoryAga);
}

export function* requestHistoryRegister() {
  yield takeLatest("REQUEST_HISTORY_REGISTER_AGA", requestHistoryRegisterAga);
}

export default function* rootSaga() {
  yield all([
    fork(requestAddAgaAga),
    fork(requestQuestions),
    fork(requestHistory),
    fork(requestHistoryRegister)
  ]);
}
