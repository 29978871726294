import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";
import Symptoms from "components/Forms/Consult/symptoms";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";

import { Typography, makeStyles, Icon } from "@material-ui/core";
import { ButtonLarge } from "components/Button";
import CustomSlider from "components/CustomSlider";

import CloseIcon from "@material-ui/icons/Close";
import Dr_prancheta from "assets/images/dr_prancheta.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    width: "100%",
  },
  box: {
    border: "solid 2px #c1c1c1",
    borderRadius: "25px",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(220 243 255)",
    },
  },
  icon: {
    overflow: "inherit",
    fontSize: "40px",
    width: "auto",
    height: "auto",
  },
  dFlex: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  positionBad: {
    position: "absolute",
    bottom: "-5px",
    left: 0,
  },
  positionGood: {
    position: "absolute",
    bottom: "-5px",
    right: 0,
  },
}));

export default function SymptomsCovid({
  progress,
  handleNext,
  handleBack,
  handleClose,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(0);

  const [state, setState] = useState({
    symptoms_covid: "",
    what_symptoms_covid: [],
    user: useSelector((state) => state.auth.authUser),
  });

  useEffect(() => {
    if (state.symptoms_covid === "1") handleNext();
    else if (state.symptoms_covid === "0") handleSave();
  }, [state.symptoms_covid]);

  function handleSave() {
    Api.post("microservice/patientportal/saveSymptomsCovid", state) //falta criar função backend
      .then(({ data }) => {
        if (data.success) {
          handleClose();
        } else {
          throw true;
        }
      })
      .catch(() => {
        showMsg("error", "Não foi possível salvar as informações", dispatch);
      });
  }

  return (
    <div className={classes.root}>
      <div className={classes.dFlex}>
        {progress === 0 && (
          <>
            <div className="col-12 col-sm-12 col-md-8">
              <Typography variant="subtitle1" gutterBottom>
                <strong>Você está com algum sintoma do Covid-19?</strong>
              </Typography>
              <div className="row">
                <div className="col-6 d-flex justify-content-end">
                  <div
                    className={classes.box}
                    onClick={() => setState({ ...state, symptoms_covid: "0" })}
                  >
                    <Icon className={classes.icon}>😃</Icon>
                    <span style={{ fontSize: "25px" }}>Não</span>
                  </div>
                </div>
                <div className="col-6 ">
                  <div
                    className={classes.box}
                    onClick={() => setState({ ...state, symptoms_covid: "1" })}
                  >
                    <Icon className={classes.icon}>😭</Icon>
                    <span style={{ fontSize: "25px" }}>Sim</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 justify-content-end">
              <img src={Dr_prancheta} alt="Dra. Prancehta" />
            </div>
          </>
        )}
        {progress === 1 && ( //falta pegar ficha de vacinação
          <div className="col-12 col-sm-12 col-md-12">
            <div className="col-md-12 mt-1">
              <Symptoms
                isOpen={open === 3}
                handleOpen={(e) => setOpen(e)}
                type={"pp"}
                handleBack={() => {
                  setState({ ...state, symptoms_covid: "" });
                  handleBack();
                }}
                handleClose={handleClose}
                symptoms_covid={state.symptoms_covid}
                progress={progress}
                quize={true}
              />
            </div>
          </div>
        )}
      </div>
      {progress === 0 && (
        <div className="row">
          <div className="col-12">
            <ButtonLarge
              onClick={handleClose}
              style={{
                padding: "1px",
                width: "unset",
                minWidth: "100px",
                float: "left",
              }}
            >
              Sair
            </ButtonLarge>

            {/* {progress === 1 && (
            <ButtonLarge
              onClick={handleSave}
              style={{
                padding: "1px",
                width: "unset",
                minWidth: "100px",
                float: "right",
                backgroundColor: state.symptoms_covid ? "#3d8b40" : "unset",
                color: state.symptoms_covid ? "#fff" : "#8c8c8c",
              }}
            >
              Confirmar
            </ButtonLarge>
          )} */}
          </div>
        </div>
      )}
    </div>
  );
}
