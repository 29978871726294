import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { BtnSave, BtnCan } from "components/Button";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import BarProgress from "components/CircularProgress/ProgressBar";
import AutocompleteContact from "./AutocompleteContact";
import DeleteIcon from "@material-ui/icons/Delete";
import { getUrlPhoto } from "services/functions";
import api from "services/api";
import Moment from "moment";
import { ModalResponsive } from "components/ModalResponsive";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";

export default function ModalAdd({ open, handleClose, handleSuccess }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user_id = useSelector(({ auth }) => auth.authUser);
  const type = useSelector(({ auth }) => auth.type);
  const secretary = useSelector(({ auth }) => auth.secretary);
  const user_photo = useSelector(({ auth }) => auth.photo);

  const handleAutocomplete = (user) => {
    if (user) {
      let achou = false;
      list.forEach((el) => {
        if (el.id === user.id) {
          achou = true;
        }
      });
      !achou && setList([...list, user]);
    }
  };

  const handleSave = () => {
    let formData = {
      user_id,
      data: list,
    };
    api
      .post(`/ms_comunication/contact/saveContacts`, formData)
      .then(({ data }) => {
        if (data.success) {
          handleSuccess(data.list);
          list.forEach((row) => {
            getDataFirebase()
              .child(`notification${getEnviromentFirebase()}/${row.id}`)
              .push({
                user_from: user_id,
                user_to: row.id,
                date: Moment().format("DD/MM/YYYY HH:mm:ss"),
                title: `Nova solicitação de contato`,
                type: "contact",
                action: "comunication/contacts?requests",
                image: user_photo,
                icon: "zmdi-account-add",
                read: false,
              });
          });
          handleClose();
          dispatch(viewAlertMessage("success", "Contato(s) adicionado(s)!"));
          setTimeout(() => {
            dispatch(closeAlertMessage());
            setList([]);
          }, 5000);
        } else {
          dispatch(viewAlertMessage("error", "Algo deu errado!"));
          setTimeout(() => {
            dispatch(closeAlertMessage());
          }, 5000);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        dispatch(viewAlertMessage("error", "Algo deu errado!"));
        setTimeout(() => {
          dispatch(closeAlertMessage());
        }, 5000);
      });
  };

  return (
    <ModalResponsive
      title={"Adicionar Novo Contato"} // titulo
      open={open}
      maxWidth={"lg"}
      fullWidth={true}
      onClose={handleClose}
      buttons={
        <div className="col-md-12" style={{ marginTop: "5px" }}>
          <BtnSave
            title={"salvar"}
            onClick={() => {
              setLoading(true);
              handleSave();
            }}
            float="right"
          />
          <BtnCan title={"cancelar"} onClick={handleClose} float="left" />
        </div>
      }
    >
      {loading ? (
        <div
          className="col-12 d-flex justify-content-center"
          style={{ alignItems: "center" }}
        >
          <BarProgress />
        </div>
      ) : (
        <>
          <AutocompleteContact
            user_id={user_id}
            secretary={secretary}
            type={type}
            onChange={(obj) => handleAutocomplete(obj)}
          />
          {list.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan="2">Nome</TableCell>
                  <TableCell align="right">Remover</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell className="p-2" style={{ width: "50px" }}>
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50px",
                        }}
                        src={getUrlPhoto(user.id, user.photo)}
                        alt={user.name}
                      />
                    </TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => {
                          setList(list.filter((el) => el.id !== user.id));
                        }}
                        color="secondary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </>
      )}
    </ModalResponsive>
  );
}
