/* import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";
import { debounce } from "services/functions";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%",
  },
  root: {
    backgroundColor: "#fff",
  },
  selected: {
    backgroundColor: "#e2e2e8 !important",
  },
};

function AutocompleteCids({ handleChangeAutocomplete, error, cid, classes }) {
  const [suggestions, setSuggestion] = useState([]);

  const getSuggestions = (e) => {
    if (e.target.value.length >= 2) {
      Api.post(`/microservice/autocomplete/getCids/`, {
        term: e.target.value.toLowerCase(),
      }).then((res) => {
        setSuggestion(res.data);
      });
    }
  };

  const renderSuggestion = (params) => {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
      >
        {suggestion.short_description}
      </MenuItem>
    );
  };

  const renderInput = (inputProps) => {
    let { InputProps, classes, ref, ...other } = inputProps;
    return (
      <TextField
        {...other}
        inputref={ref}
        error={error}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...InputProps,
        }}
        style={{ marginTop: "0" }}
        InputLabelProps={{
          shrink: true,
        }}
        onKeyUp={(e) => debounce(getSuggestions(e))}
        onChange={(e) => {}}
      />
    );
  };

  return (
    <Downshift
      onSelect={(selectedItem, obj) => {
        let item = suggestions.filter((row) => {
          return row.short_description === selectedItem;
        });
        if (item.length > 0) {
          handleChangeAutocomplete(item[0]);
        } else {
          handleChangeAutocomplete(false);
        }
        if (!cid) {
          obj.setState({ inputValue: "" });
        }
      }}
      selectedItem={cid ? cid : null}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex,
        clearSelection,
      }) => (
        <div className={classes.container}>
          {renderInput({
            fullWidth: true,
            classes,
            InputProps: getInputProps({
              id: "cid_id",
              value: inputValue,
              onChange: (e) => {
                if (e.target.value === "") {
                  clearSelection();
                }
              },
            }),
            label: "Informe o CID:",
            margin: "normal",
          })}
          {isOpen ? (
            <Paper square>
              {suggestions.map((suggestion, index) =>
                renderSuggestion({
                  suggestion,
                  index,
                  itemProps: getItemProps({
                    item: suggestion.short_description,
                  }),
                  highlightedIndex,
                  selectedItem,
                })
              )}
            </Paper>
          ) : null}
        </div>
      )}
    </Downshift>
  );
}

export default withStyles(styles)(AutocompleteCids);
 */

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Api from "services/api";
import { debounce, showMsg } from "services/functions";

import AutocompleteGeneric from "./AutocompleteGeneric";

export default function AutocompleteCids({
  handleChangeAutocomplete,
  error,
  cid,
}) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);

  const getOptions = async (term) => {
    if (term.length > 2) {
      setLoader(true);
      try {
        const { data } = await Api.post("/microservice/autocomplete/getCids/", {
          term: term.toLowerCase(),
        });

        if (data.length > 0) {
          setOptions(data);
        }
      } catch (err) {
        showMsg("error", "Não foi possível encontrar os exames", dispatch);
        setOptions([]);
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <AutocompleteGeneric
      multiple={false}
      limitTags={1}
      filterOptions={(x) => x}
      disableCloseOnSelect
      onChange={(obj) => {
        if (obj) {
          handleChangeAutocomplete(obj);
        } else {
          handleChangeAutocomplete({
            id: "",
            short_description: "",
          });
        }
      }}
      onInputChange={debounce(getOptions)}
      value={cid ? cid : null}
      getOptionLabel={(option) => option.short_description}
      label="Informe o CID:"
      options={options}
      loading={loader}
      blurOnSelect={true}
      clearOnBlur={true}
      shrink={true}
      error={error}
    />
  );
}
