import React, { Component } from "react";
import api from "services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import NoRecord from "components/Alert/NoRecord";

class TableHistory extends Component {
  state = {
    record: [],
    recordDA: [],
    recordFA: [],
    // recordDR: [],
    recordAC: [],
  };

  componentWillMount() {
    //Doenças Alergicas
    api
      .post(`/microservice/allergies/getListUserDA/`, {
        patient: this.props.patient,
      })
      .then((res) => {
        if (res.data.record) {
          this.setState({ recordDA: res.data.record });
        }
      })
      .catch(() => {});

    //Alergias Alimentares
    api
      .post(`/microservice/allergies/getListUserFA/`, {
        patient: this.props.patient,
      })
      .then((res) => {
        if (res.data.record) {
          this.setState({ recordFA: res.data.record });
        }
      })
      .catch(() => {});

    //Alergias Medicamentos
    // api
    //   .post(`/microservice/allergies/getListUserDR/`, {
    //     patient: this.props.patient,
    //   })
    //   .then((res) => {
    //     if (res.data.record) {
    //       this.setState({ recordDR: res.data.record });
    //     }
    //   })
    //   .catch(() => {});

    //Alergias Produtos Químicos
    api
      .post(`/microservice/allergies/getListUserAC/`, {
        patient: this.props.patient,
      })
      .then((res) => {
        if (res.data.record) {
          this.setState({ recordAC: res.data.record });
        }
      })
      .catch(() => {});
  }

  render() {
    const headerTable = {
      color: "rgba(0, 0, 0, 0.35)",
      height: "40px",
    };
    const recordDA = this.state.recordDA;
    const recordFA = this.state.recordFA;
    // const recordDR = this.state.recordDR;
    const recordAC = this.state.recordAC;
    return (
      <div>
        <h4 style={{ padding: "5px", fontWeight: "bold" }}>
          Doenças Alérgicas
        </h4>
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          <Table className="table-history">
            <TableHead>
              <TableRow>
                <TableCell style={headerTable}>Doenças Alérgicas</TableCell>
                <TableCell style={headerTable}>Observações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordDA &&
                recordDA.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name_display}</TableCell>
                    <TableCell>{row.obs ? row.obs : "---"}</TableCell>
                  </TableRow>
                ))}
              {recordDA.length === 0 && (
                <TableRow key="1">
                  <TableCell colSpan={4}>
                    <NoRecord />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <h4 style={{ marginTop: "25px", padding: "5px", fontWeight: "bold" }}>
          Alergias Alimentares
        </h4>
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={headerTable}>Alergias Alimentares</TableCell>
                <TableCell style={headerTable}>Observações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordFA &&
                recordFA.map((row) => (
                  <TableRow key={row.id_registry}>
                    <TableCell>{row.name_registry}</TableCell>
                    <TableCell>
                      {row.additional_considerations
                        ? row.additional_considerations
                        : "---"}
                    </TableCell>
                  </TableRow>
                ))}
              {recordFA.length === 0 && (
                <TableRow key="1">
                  <TableCell colSpan={4}>
                    <NoRecord />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {/* <h4 style={{ marginTop: "25px", padding: "5px", fontWeight: "bold" }}>
          Alergia a Medicamentos
        </h4>
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={headerTable}>Medicamentos</TableCell>
                <TableCell style={headerTable}>Observações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordDR &&
                recordDR.map((row) => (
                  <TableRow key={row.id_registry}>
                    <TableCell>{row.name_registry}</TableCell>
                    <TableCell>
                      {row.additional_considerations
                        ? row.additional_considerations
                        : "---"}
                    </TableCell>
                  </TableRow>
                ))}
              // {recordDR.length === 0 && (
                <TableRow key="1">
                  <TableCell colSpan={4}>
                    <NoRecord />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div> */}
        <h4 style={{ marginTop: "25px", padding: "5px", fontWeight: "bold" }}>
          Produtos Químicos
        </h4>
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={headerTable}>Produtos Químicos</TableCell>
                <TableCell style={headerTable}>Observações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordAC &&
                recordAC.map((row) => (
                  <TableRow key={row.id_registry}>
                    <TableCell>{row.name_registry}</TableCell>
                    <TableCell>
                      {row.additional_considerations
                        ? row.additional_considerations
                        : "---"}
                    </TableCell>
                  </TableRow>
                ))}
              {recordAC.length === 0 && (
                <TableRow key="1">
                  <TableCell colSpan={4}>
                    <NoRecord />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

export default TableHistory;
