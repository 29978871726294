import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";
import Api from "services/api";

const GraphicLine = ({ user }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user) {
      Api.post("ms_system/dashboard/getDataGraphicPA", { user }).then((res) => {
        setData(
          res.data.result
            .map((row) => ({
              ...row,
              pas: parseInt(row.pas),
              pad: parseInt(row.pad),
            }))
            .reverse()
        );
      });
    }
  }, []);

  return (
    <ResponsiveContainer height={220}>
      <ComposedChart
        data={data}
        height={220}
        margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
        <Bar dataKey="pas" barSize={25} fill="#177493" />
        <Line type="monotone" dataKey="pas" stroke="#177493" />
        <Bar dataKey="pad" barSize={25} fill="#86ddfb" />
        <Line type="monotone" dataKey="pad" stroke="#86ddfb" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
export default GraphicLine;
