import React from "react";
import { useDispatch } from "react-redux";
import { CardBody } from "reactstrap";
import NoRecord from "components/Alert/NoRecord";
import { Tooltip, IconButton } from "@material-ui/core";
import { viewHistory } from "actions/consult/Appointment";
import HistoryIcon from "@material-ui/icons/History";
import HistoryPatient from "components/HistoryPatient";
import ComponentsProfile from "components/ComponentsProfile";
import DataTable from "components/Tables/DataTable";
import BarProgress from "components/CircularProgress/ProgressBar";
import SearchComponent from "components/SearchComponent";
import { getStringToSearch } from "services/functions";

export default function Patients({ patients, loaded }) {
  const [patient, setPatient] = React.useState("");
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();

  return (
    <div className="col-12 animated slideInUpTiny animation-duration-4">
      <CardBody>
        {loaded ? (
          <div className="col-12 m-0 p-0">
            <h2 className="font-weight-semibold d-flex">
              <span className="mt-3 mr-5">Pacientes</span>
              <SearchComponent searchContact={e => setSearch(e)} />
            </h2>
            {patients.length > 0 ? (
              <DataTable
                columns={["#", "Nome", "Ações"]}
                rows={patients
                  .filter(
                    row =>
                      search.length === 0 ||
                      getStringToSearch(row.name).includes(
                        getStringToSearch(search)
                      )
                  )
                  .map((e, index) => {
                    return [
                      index + 1,
                      [
                        <ComponentsProfile
                          nameProfile={e.name}
                          subTitle={e.birthday}
                          photo={e.photo}
                          id={e.id}
                        />
                      ],
                      [
                        <Tooltip title="Histórico do paciente" placement="top">
                          <IconButton
                            style={{ padding: "6px" }}
                            onClick={() => {
                              setPatient(e.id);
                              dispatch(viewHistory("personalData"));
                            }}
                          >
                            <HistoryIcon
                              style={{
                                fontSize: "19px",
                                margin: "0px",
                                padding: "0px"
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ]
                    ];
                  })}
                download={false}
                print={false}
                hideSearch={true}
                rowsPerPage={5}
                height="100%"
              />
            ) : (
              <NoRecord />
            )}
          </div>
        ) : (
          <div className="col-12 d-flex justify-content-center m-2">
            <BarProgress />
          </div>
        )}
      </CardBody>
      <HistoryPatient id_patient={patient} />
    </div>
  );
}
