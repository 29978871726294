import React, { useEffect, useState } from "react";
import Api from "services/api";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

export default function ViewFoodPlan({ plan }) {
  const [listNutrients, setList] = useState([]);
  useEffect(() => {
    Api.post(`/ms_nutrition/nutfoodplan/getFoodsInMeal/`, {
      plan
    }).then(res => {
      let list = [];
      let elementsID = [
        { id: "2", unity: "Kcal" },
        { id: "4", unity: "g" },
        { id: "6", unity: "g" },
        { id: "7", unity: "g" },
        { id: "8", unity: "g" }
      ];
      let meal = [];
      res.data.dataFoodPlan.forEach(el => {
        let componentsId = el.componentsId.split(",");
        let values = el.values.split(",");
        let i = 0;
        let data = {};
        while (i < elementsID.length) {
          let aux = componentsId.indexOf(elementsID[i].id);
          if (aux !== -1) {
            data = {
              ...data,
              [elementsID[i].id]: {
                key: elementsID[i].id,
                value: `${values[aux]} ${elementsID[i].unity}`
              }
            };
          } else {
            data = {
              ...data,
              [elementsID[i].id]: {
                key: elementsID[i].id,
                value: `0.00 ${elementsID[i].unity}`
              }
            };
          }
          i++;
        }
        let obj = { name: el.name, data };

        if (meal.length === 0) {
          meal = [{ meal: el.meal, hour: el.hour, foods: [obj] }];
        } else {
          if (el.meal === meal[meal.length - 1].meal) {
            meal[meal.length - 1] = {
              ...meal[meal.length - 1],
              foods: [...meal[meal.length - 1].foods, obj]
            };
          } else {
            meal = [...meal, { meal: el.meal, hour: el.hour, foods: [obj] }];
          }
        }
        list = meal;
      });
      list.forEach(row => {
        let total = [];
        row.foods.forEach(el => {
          Object.values(el.data).forEach(ele => {
            let value = ele.value.split(" ");
            value[0] = parseFloat(value[0]);
            let find = total.filter(el => ele.key === el.key);
            if (find.length === 0) {
              total.push({ key: ele.key, value: value[0], unity: value[1] });
            } else {
              find[0].value += value[0];
            }
          });
        });

        total = total.map(el => ({
          key: el.key,
          value: `${el.value.toFixed(2)} ${el.unity}`
        }));

        row.foods = [
          ...row.foods,
          {
            name: "Total",
            data: total
          }
        ];
      });
      setList(list);
    });
  }, []);
  return (
    <>
      <div className="container-person">
        {listNutrients.map(el => (
          <>
            <div className="col-md-6 mt-4">
              <h3 className="primary">
                <span className="font-weight-semibold">
                  {el.hour} | {el.meal}
                </span>
              </h3>
            </div>
            <Table fullWidth>
              <TableHead>
                <TableRow>
                  <TableCell>Calorias</TableCell>
                  <TableCell>Carboidratos</TableCell>
                  <TableCell>Proteínas</TableCell>
                  <TableCell>Lipídios</TableCell>
                  <TableCell>Fibras</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {el.foods.length > 0 &&
                  el.foods.map(row => (
                    <>
                      <TableRow>
                        <TableCell colspan={5}>{row.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        {Object.values(row.data).map(element => (
                          <TableCell>{element.value}</TableCell>
                        ))}
                      </TableRow>
                    </>
                  ))}
              </TableBody>
              {/* <TableBody>
                {el.foods.length > 0 &&
                  el.foods.map(row => (
                    <TableRow>
                      <TableCell style={{ width: "35%" }}>{row.name}</TableCell>
                      {Object.values(row.data).map(element => (
                        <TableCell>{element.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody> */}
            </Table>
          </>
        ))}
      </div>
    </>
  );
}
