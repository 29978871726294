import appLocaleData from 'react-intl/locale-data/pt';
import ptMessages from '../locales/pt_br.json';

const PtLang = {
  messages: {
    ...ptMessages
  },
  locale: 'pt-BR',
  data: appLocaleData
};
export default PtLang;
