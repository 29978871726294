import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as alertActions from "actions/Alerts";
import Api from "services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormEmergencyContact from "./FormEmergencyContact";
import ViewEmergencyContact from "./ViewEmergencyContact";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import SweetAlert from "react-bootstrap-sweetalert";
import NoRecord from "components/Alert/NoRecord";

class EmergencyContact extends React.Component {
  state = {
    open_modal: false,
    modal_delete: false,
    modal_view: false,
    success_delete: false,
    success_add: false,
    contact_form: ""
  };

  isEmpty(obj) {
    return obj ? Object.keys(obj).length === 0 : true;
  }

  newContact = () => {
    this.setState({ open_modal: true, contact_form: "" });
  };

  handleClose = () => {
    this.setState({ open_modal: false, modal_view: false });
  };

  editContact(contact) {
    this.setState({ open_modal: true, contact_form: contact });
  }

  viewContact(contact) {
    this.setState({ modal_view: true, contact_form: contact });
  }

  alertDeleteContact(contact) {
    this.setState({ modal_delete: true, contact_form: contact });
  }

  onCancelDelete = () => {
    this.setState({ modal_delete: false, contact_form: "" });
  };

  deleteEmergencyContact(id) {
    Api.post(`/microservice/profile/deleteEmergencyContact/`, { id })
      .then(res => {
        let id = this.state.contact_form.id;
        this.props.contacts.forEach((row, key) => {
          if (row.id === id) {
            this.props.contacts.splice(key, 1);
          }
        });
        this.setState({ modal_delete: false, success_delete: true });
      })
      .catch(() => {
        this.props.viewAlertMessage(
          "error",
          "Ocorreu um erro, tente novamente!"
        );
        setTimeout(() => this.props.closeAlertMessage(), 5000);
        this.setState({ modal_delete: false, success_delete: false });
      });
  }

  render() {
    const { contacts } = this.props;
    const { contact_form, modal_delete, success_delete } = this.state;
    return (
      <div className="row">
        <div className="col-md-12 div-header">
          <span className="card-title text-primary title-form">
            Contato de emergência
          </span>
          <div className="div-button">
            <Button
              onClick={() => this.newContact()}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
            >
              <i className="zmdi zmdi-plus text-right" />{" "}
              <span> adicionar </span>
            </Button>
          </div>
        </div>
        {!this.isEmpty(contacts) ? (
          <Table className="table-history">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="left">Referência</TableCell>
                <TableCell align="left">Prioridade</TableCell>
                <TableCell align="left">Celular</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    {row.reference ? row.reference : "---"}
                  </TableCell>
                  <TableCell align="left">
                    {row.priority === "0" ? "Preferêncial" : "Alternativo"}
                  </TableCell>
                  <TableCell align="left">{row.cellphone}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => this.viewContact(row)}
                      color="primary"
                      aria-label="Visualizar"
                    >
                      <i className="zmdi zmdi-eye zmdi-hc-fw" />
                    </IconButton>
                    <IconButton
                      onClick={() => this.editContact(row)}
                      color="primary"
                      aria-label="Editar"
                    >
                      <i className="zmdi zmdi-edit zmdi-hc-fw" />
                    </IconButton>
                    <IconButton
                      onClick={() => this.alertDeleteContact(row)}
                      color="secondary"
                      aria-label="Remover"
                    >
                      <i className="zmdi zmdi-delete zmdi-hc-fw" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoRecord />
        )}
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={this.state.open_modal}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
          scroll="body"
        >
          <DialogContent>
            <FormEmergencyContact
              contact={contact_form}
              handleClose={this.handleClose.bind(this)}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={this.state.modal_view}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
          scroll="body"
        >
          <DialogContent>
            <ViewEmergencyContact
              contact={contact_form}
              handleClose={this.handleClose.bind(this)}
            />
          </DialogContent>
        </Dialog>
        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, excluir"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Excluir este contato de emergência?"
          onConfirm={() =>
            this.deleteEmergencyContact(this.state.contact_form.id)
          }
          onCancel={this.onCancelDelete}
        />
        <SweetAlert
          show={success_delete}
          success
          title="Excluido com sucesso!"
          onConfirm={() => this.setState({ success_delete: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.Profile.emergency_contacts
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(alertActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmergencyContact);
