import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core";
import { BtnCan, BtnAdd } from "components/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconRemove from "@material-ui/icons/Remove";
import IconAccessTime from "@material-ui/icons/AccessTime";
import moment from "moment";

export default function Glice({
  glice,
  handleChange,
  readOnly,
  handleAdd,
  handleRemove,
  hideMore = false,
  helperText,
}) {
  return (
    <>
      {glice.map((row, index) => (
        <div className="row" style={{ marginBottom: "20px" }} key={index}>
          <div className="col-md-3 col-xs-9 d-flex">
            <FormControl fullWidth>
              <FormHelperText>Horário:</FormHelperText>
              <Input
                type="time"
                value={row.hour}
                onChange={(event) =>
                  handleChange(event.target.value, "hour", index)
                }
                endAdornment={
                  <InputAdornment position="end">hr</InputAdornment>
                }
                readOnly={readOnly}
                fullWidth
              />
              {index === 0 && helperText && helperText.hour && (
                <FormHelperText>
                  U.V.R: {helperText.hour.substr(0, 5)}
                </FormHelperText>
              )}
            </FormControl>
            {!readOnly && (
              <Tooltip title="horário atual">
                <IconButton
                  color="primary"
                  onClick={() =>
                    handleChange(moment().format("HH:mm"), "hour", index)
                  }
                  style={{ marginTop: "15px" }}
                >
                  <IconAccessTime />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div className="col-md-6 col-xs-12">
            <FormControl>
              <FormHelperText>Valor:</FormHelperText>
              <Input
                type="number"
                value={row.value}
                onChange={(event) =>
                  handleChange(event.target.value, "value", index)
                }
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 3);
                }}
                endAdornment={
                  <InputAdornment position="end">mg/dl</InputAdornment>
                }
                readOnly={readOnly}
              />
              {index === 0 && helperText && helperText.value && (
                <FormHelperText>U.V.R: {helperText.value}</FormHelperText>
              )}
              {parseInt(row.value) > 200 && (
                <FormHelperText style={{ color: "red" }}>
                  Atenção
                </FormHelperText>
              )}
            </FormControl>
            {!readOnly && (
              <>
                {glice.length > 1 && (
                  <IconButton
                    color="secondary"
                    onClick={() => handleRemove(index)}
                    style={{ marginTop: "15px" }}
                  >
                    <IconRemove />
                  </IconButton>
                )}

                {glice.length - 1 === index && !hideMore && (
                  <IconButton
                    color="primary"
                    onClick={handleAdd}
                    style={{ marginTop: "15px" }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </>
            )}
          </div>
        </div>
      ))}
      {/* </DialogContent>
        <DialogActions>
          <div className="col-md-12 mt-20">
            <BtnCan title="Fechar" onClick={handleCancel} float="left" />
            {!readOnly && (
              <BtnAdd title="adicionar" float="right" onClick={handleConfirm} />
            )}
          </div>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
