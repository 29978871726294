import React, { Component } from "react";
import { deleteTag } from "actions/consult/Tags";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SweetAlert from "react-bootstrap-sweetalert";

class ModalDelete extends Component {
  handleDeleteTag = () => {
    this.props.deleteTag(this.props.tag.id, this.props.address);
    this.props.handleCloseDelete();
  };
  render() {
    return (
      <SweetAlert
        show={this.props.open}
        warning
        showCancel
        confirmBtnText="Excluir"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Excluir esta tag?"
        onConfirm={this.handleDeleteTag}
        onCancel={this.props.handleCloseDelete}
      >
        {`Você tem certeza que deseja excluir a tag ${this.props.tag.name}`}
      </SweetAlert>
    );
  }
}

const mapStateToProps = state => ({
  address: state.auth.address
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ deleteTag }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDelete);
