import React from "react";
import { useHistory } from "react-router-dom";
import { TextField, MenuItem, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import BarProgress from "components/CircularProgress/ProgressBar";
import { changeAddress } from "actions/Auth";
import { useSelector, useDispatch } from "react-redux";

export default function Attendance({ title }) {
  const address = useSelector((state) => state.auth.address);
  const list_address = useSelector(({ auth }) => auth.list_address);
  const loaded_address = useSelector(({ auth }) => auth.loaded_address);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="col-12 animated slideInUpTiny animation-duration-4">
      {loaded_address ? (
        <>
          <Typography className="pt-3 ml-1" variant="h6" gutterBottom>
            {title ? title : "Local de Atendimento"}
          </Typography>
          <div className="col-12">
            <div className="d-flex mb-3">
              {list_address.length > 0 ? (
                <TextField
                  select
                  value={address}
                  onChange={(e) => {
                    dispatch(changeAddress(e.target.value));
                  }}
                  fullWidth
                  label="Local:"
                >
                  {list_address.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.name}{" "}
                    </MenuItem>
                  ))}
                  {/* {list_address.length > 0 ? (
                  list_address.map(e => (
                    <MenuItem value={e.id}>{e.name} </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <NavLink to="/app/workplace">
                      Criar local de atendimento
                    </NavLink>
                  </MenuItem>
                )} */}
                </TextField>
              ) : (
                <h4>
                  Nenhum local de atendimento cadastrado.{" "}
                  <Button
                    color="primary"
                    onClick={() => history.push("/app/workplace")}
                  >
                    Cadastrar?
                  </Button>
                </h4>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="col-12 d-flex justify-content-center m-2">
          <BarProgress />
        </div>
      )}
    </div>
  );
}
