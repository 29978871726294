const INITIAL_STATE = {
  listAddProf: {
    typeSchedule: null,
    dataInit: new Date(),
    dataEnd: null,
    workdays: [
      {
        weekdays: [],
        hourInit: null,
        hourEnd: null,
        interval: 0,
        listInterval: [],
        listHoursInterval: [],
      },
    ],
    weekdays: [],
    hourInit: null,
    hourEnd: null,
    interval: 0,
    listInterval: [],
    listHoursInterval: [],
    listPatient: [],
    professional: null,
    weekHours: null,
    weekHoursDisplay: null,
    minutesDay: null,
    minutesDayDisplay: null,
    hoursDay: 0,
    hoursWeek: 0,
    weekdaysOff: null,
    calendarProf: null,
  },
};

export default function managementProfessional(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_LIST_ADD_PROF":
      let newList = {
        ...state.listAddProf,
        [action.payload.type]: action.payload.value,
      };

      return {
        ...state,
        listAddProf: newList,
      };

    case "HANDLE_CHANGE_INTERVAL_MNG":
      return {
        ...state,
        listAddProf: {
          ...state.listAddProf,
          workdays: state.listAddProf.workdays.map((row, key) =>
            key === action.payload.index
              ? { ...row, [action.payload.field]: action.payload.value }
              : row
          ),
        },
      };

    case "CLEAR_LIST_ADD_PROF":
      return INITIAL_STATE;

    default:
      return state;
  }
}
