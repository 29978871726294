import { delay } from "redux-saga";
import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import api from "services/api";
import apiFile from "services/apiFile";

/* REQUISIÇÕES A API MICROSERVICES */

//salva imagens e retorna lista
const requeSaveNewUploadFilesEvolution = async formData => {
  const res = await apiFile.post(
    `/microservice/comparepictures/saveUploadFilesEvolution/`,
    formData
  );
  return res.data;
};

//retorna lista de imagens
const requeGetListOfImages = async params => {
  const res = await api.post(
    `/microservice/comparepictures/getListOfImages/`,
    params
  );
  return res.data;
};

//Deleta imagem
const requestDeletePicture = async params => {
  const res = await api.post(
    `/microservice/comparepictures/deletePictures/`,
    params
  );
  return res.data;
};

/* EXCUTA AÇÕES NO SAGA */

function* executaSaveNewUploadFilesEvolution(action) {
  //Cria formData para enviar a API
  let formData = new FormData();
  formData.append("id_patient", action.payload.id_patient);
  formData.append("id_doctor", action.payload.id_doctor);
  formData.append("date", action.payload.date);
  formData.append("file_evolution", action.payload.file);
  try {
    const res = yield call(requeSaveNewUploadFilesEvolution, formData);
    if (res.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Arquivo salvo com sucesso!"
      });
      yield put({
        type: "SET_LIST_PICTURES",
        list: res.list_files
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: res.error
          ? res.error
          : "Não foi possível salvar o arquivo!"
      });
    }
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log(
      " ** ComparePictures ** Erro executaSaveNewUploadFilesEvolution() ==>",
      error
    );
  }
}

function* executaGetListOfImages(action) {
  try {
    const res = yield call(requeGetListOfImages, action.payload);
    if (res.success) {
      yield put({
        type: "SET_LIST_PICTURES",
        list: res.list_files
      });
    } else {
      console.log("Erro ao carregar imagens: ", res.error);
    }
  } catch (error) {
    console.log(
      " ** ComparePictures ** Erro executaGetListOfImages() ==>",
      error
    );
  }
}

function* executaDeletePicture(action) {
  try {
    const res = yield call(requestDeletePicture, action.payload);
    if (res.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Imagem deletada com sucesso!"
      });
      yield put({
        type: "SET_LIST_PICTURES",
        list: res.list_files
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: res.error
          ? res.error
          : "Não foi possível deletar o arquivo!"
      });
    }

    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Algo deu errado!"
    });
    console.log(
      " ** ComparePictures ** Erro executaDeletePicture() ==>",
      error
    );
  }
}

/* ESCUTA ACTIONS */

function* listenSaveNewUploadFilesEvolution() {
  yield takeLatest(
    "SAVE_NEW_UPLOAD_FILES_EVOLUTION",
    executaSaveNewUploadFilesEvolution
  );
}

function* listenGetListOfImages() {
  yield takeLatest("GET_LIST_OF_IMAGES", executaGetListOfImages);
}

function* listenDeletePictures() {
  yield takeLatest("DELETE_PICTURES_COMPARE", executaDeletePicture);
}

export default function* rootSaga() {
  yield all([
    fork(listenSaveNewUploadFilesEvolution),
    fork(listenGetListOfImages),
    fork(listenDeletePictures)
  ]);
}
