import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import api from "services/api";
import { delay } from "redux-saga";

const getSexoPatient = async id => {
  const response = await api.post(
    `/microservice/circumferences/getSexoPatient/`,
    { id }
  );

  const data = await response.data.record[0].gender;

  return data;
};

const cadCircumferences = async formData => {
  const response = await api.post(
    `/microservice/circumferences/cadCircumferences/`,
    {
      formData
    }
  );

  return response;
};

function* asyncGetSexo(action) {
  try {
    const sexoPatient = yield call(getSexoPatient, action.payload.id);
    yield put({
      type: "GET_SEXO_PATIENT_SUCCESS",
      payload: { sexoPatient: sexoPatient }
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncCadCircumferences(action) {
  try {
    const res = yield call(cadCircumferences, action.payload.formData);
    if (res.data.success) {
      yield put({
        type: "SET_INIT_APPOINTMENT",
        payload: {
          id: res.data.id
        }
      });
      yield put({
        type: "HANDLE_CAD_CIRCUNFERENCES_SUCCESS",
        payload: { res: res }
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao executar esta operação"
      });
    }
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log(error);
  }
}

export function* requestSexoPatient() {
  yield takeEvery("GET_SEXO_PATIENT", asyncGetSexo);
}

export function* requestCadCircumferences() {
  yield takeEvery("CAD_CIRCUMFERENCES", asyncCadCircumferences);
}

export default function* rootSaga() {
  yield all([fork(requestSexoPatient), fork(requestCadCircumferences)]);
}
