const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  authUser: "",
  teacher: "",
  secretary: false,
  provider_type: "",
  name: "",
  fullname: "",
  type: "",
  type_default: "",
  area: "",
  photo: "",
  gender: "",
  birthday: "",
  cpf: "",
  rg: "",
  permissionsConsult: {},
  loadedPermissions: false,
  address: "",
  address_selected: {
    id: "",
    name: "",
    full_address: "",
    type: "",
    provider_type: "",
    user_id: "",
  },
  list_address: [],
  loaded_address: false,
  user_manage: {
    id: "",
    type: "",
    provider_type: "",
  },
  loaded_info: false,
  managers: [],
  loaded_managers: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SIGNOUT_USER_SUCCESS": {
      return INIT_STATE;
    }
    case "SIGNIN_USER_SUCCESS": {
      let request = window.indexedDB.open("DBteste", 1);
      request.onerror = function(event) {
        // Fazer algo com request.errorCode!
      };
      request.onsuccess = function(event) {
        // Fazer algo com request.result!
      };

      request.onupgradeneeded = function(event) {
        var db = event.target.result;

        // Criando outro objeto chamado "names" com o autoIncrement setado.
        var objStore = db.createObjectStore("auth", { autoIncrement: true });

        // Porque "names" tem o the key generator, a chave para o nome é gerada automaticamente.
        // Os registros adicionados serão assim:
        // key : 1 => value : "Bill"
        // key : 2 => value : "Donna"

        objStore.add(action.payload);
      };
      return {
        ...INIT_STATE,
        authUser: action.payload.user,
        address: action.payload.address,
        secretary: action.payload.secretary,
      };
    }
    case "SET_AUTH_DATA": {
      const {
        type,
        area,
        name,
        photo,
        provider_type,
        teacher,
        gender,
        birthday,
        cpf,
        rg,
        fullname,
      } = action.payload;
      return {
        ...state,
        type,
        type_default: type,
        area,
        name,
        photo,
        provider_type,
        teacher,
        gender,
        birthday,
        cpf,
        rg,
        fullname,
        loaded_address: type === "2" ? true : state.loaded_address,
        loaded_info: true,
      };
    }
    case "SET_USER_MANAGE": {
      return {
        ...state,
        user_manage: {
          id: action.payload.id,
          type: action.payload.type,
          provider_type: action.payload.provider_type,
        },
      };
    }
    case "SET_PERMISSIONS_CONSULT": {
      let permisson = {};
      switch (action.area) {
        case "5":
          permisson = {
            sae: true,
            system_review: true,
            pain_scale: true,
            prescription_transcription: true,
            forward: true,
            attest: true,
          };
          break;
        case "6":
        case "45":
        case "47":
          permisson = {
            system_review: true,
            pain_scale: true,
            prescription_transcription: true,
          };
          break;
        case "9":
          permisson = {
            system_review: true,
            pain_scale: true,
            prescription_transcription: false,
            forward: true,
            attest: true,
          };
          break;
        case "12":
          permisson = {
            diagnostics: true,
            procedures: true,
            exam: true,
            prescription: true,
            prescription_manipuled: true,
            prescription_care: true,
            aga: true,
            system_review: true,
            pain_scale: true,
            forward: true,
            attest: true,
          };
          break;
        case "13":
          permisson = {
            food_reminder: true,
            food_plan: true,
            diagnostics: true,
            procedures: true,
            folds: true,
            circumferences: true,
            conduct: true,
            exam: true,
            system_review: true,
            pain_scale: true,
            forward: true,
            attest: true,
          };
          break;
        case "16":
          permisson = {
            diagnostics: true,
            procedures: true,
            diagnostic_integral: true,
            plate_index: true,
            periodontogram: true,
            prescription: true,
            exam: true,
            forward: true,
            attest: true,
          };
          break;
        case "46":
          permisson = {
            diagnostics: true,
            procedures: true,
            diagnostic_integral: true,
            plate_index: true,
            periodontogram: true,
            prescription: true,
            prescription_care: true,
            exam: true,
            system_review: true,
            pain_scale: true,
            folds: true,
            circumferences: true,
            conduct: true,
          };
          break;

        default:
          return state;
      }
      return {
        ...state,
        permissionsConsult: permisson,
        loadedPermissions: true,
      };
    }
    case "SET_LIST_ADDRESS": {
      return {
        ...state,
        list_address: action.list,
        address: action.list.length === 1 ? action.list[0].id : state.address,
        address_selected:
          action.list.length === 1 ? action.list[0] : state.address_selected,
        managers: action.managers ? action.managers : state.managers,
        loaded_address: true,
        loaded_managers: true,
      };
    }
    case "NEW_ADDRESS": {
      return {
        ...state,
        address:
          state.list_address.length === 0 ? action.address.id : state.address,
        address_selected:
          state.list_address.length === 0
            ? action.address
            : state.address_selected,
        list_address: [...state.list_address, action.address],
        loaded_address: true,
      };
    }
    case "REMOVE_LIST_ADDRESS": {
      return {
        ...state,
        address: state.address === action.payload.id ? "" : state.address,
        address_selected:
          state.address === action.payload.id
            ? INIT_STATE.address_selected
            : state.address_selected,
        list_address: state.list_address.filter(
          (row) => row.id !== action.payload.id
        ),
        loaded_address: true,
      };
    }
    case "CHANGE_ADDRESS": {
      return {
        ...state,
        address: action.payload.address,
        address_selected: action.payload.address
          ? state.list_address.find((row) => row.id === action.payload.address)
          : {
              id: "",
              name: "",
              full_address: "",
              type: "",
              provider_type: "",
            },
      };
    }
    case "ALTER_MODE_AUTH": {
      return {
        ...state,
        type: action.payload.type,
      };
    }
    case "SET_MANAGERS_AUTH": {
      return {
        ...state,
        managers: action.payload.managers,
        loaded_managers: true,
      };
    }
    default:
      return state;
  }
};
