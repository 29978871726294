import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalOffline({ open }) {
  const [opening, setOpen] = useState(open);

  return (
    <SweetAlert
      show={opening}
      warning
      confirmBtnBsStyle="danger"
      confirmBtnText="ok"
      confirmButtonColor="#bc0003"
      title={"Você está offline"}
      onConfirm={() => setOpen(false)}
    />
  );
}
