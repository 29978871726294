import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import FormVaccines from "components/Forms/Consult/Vaccines";
import { showMsg } from "services/functions";
import Api from "services/api";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLarge } from "components/Button";

import { Typography, makeStyles, Icon } from "@material-ui/core";
import Dr_prancheta from "assets/images/dr_prancheta.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    width: "100%",
  },
  box: {
    border: "solid 2px #c1c1c1",
    borderRadius: "25px",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(220 243 255)",
    },
  },
  icon: {
    overflow: "inherit",
    fontSize: "40px",
    width: "auto",
    height: "auto",
  },
  dFlex: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  positionBad: {
    position: "absolute",
    bottom: "-5px",
    left: 0,
  },
  positionGood: {
    position: "absolute",
    bottom: "-5px",
    right: 0,
  },
}));

export default function VaccineCovid({
  progress,
  handleNext,
  handleBack,
  handleClose,
}) {
  const classes = useStyles();
  const [state, setState] = useState({
    vaccine_covid: "",
    user: useSelector((state) => state.auth.authUser),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (state.vaccine_covid === "1") handleNext();
    else if (state.vaccine_covid === "0") handleSave();
  }, [state.vaccine_covid]);

  function handleSave() {
    Api.post("microservice/patientportal/saveVaccineCovid", state) //falta criar função backend
      .then(({ data }) => {
        if (data.success) {
          handleClose();
          localStorage.setItem("savedQuizze", "1");
        } else {
          throw true;
        }
      })
      .catch(() => {
        showMsg("error", "Não foi possível savar as informações", dispatch);
      });
  }

  return (
    <div className={classes.root}>
      <div className={classes.dFlex}>
        {progress === 0 && (
          <>
            <div className="col-12 col-sm-12 col-md-8">
              <Typography variant="subtitle1" gutterBottom>
                <strong>Você já tomou a vacina do Covid-19?</strong>
              </Typography>
              <div className="row">
                <div className="col-6 d-flex justify-content-end">
                  <div
                    className={classes.box}
                    onClick={() => setState({ ...state, vaccine_covid: "0" })}
                  >
                    <Icon className={classes.icon}>😭</Icon>
                    <span style={{ fontSize: "25px" }}>Não</span>
                  </div>
                </div>
                <div className="col-6 ">
                  <div
                    className={classes.box}
                    onClick={() => setState({ ...state, vaccine_covid: "1" })}
                  >
                    <Icon className={classes.icon}>😃</Icon>
                    <span style={{ fontSize: "25px" }}>Sim</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 justify-content-end">
              <img src={Dr_prancheta} alt="Dra. Prancehta" />
            </div>
          </>
        )}
        {progress === 1 && ( //falta pegar ficha de vacinação
          <div className="col-12 col-sm-12 col-md-12">
            <div className={"container text-left"}>
              <Card className="border-0">
                <CardBody>
                  <div className="row">
                    <div className="col-md-6">
                      <h3
                        className="card-title"
                        style={{
                          color: "#177493",
                          fontSize: "16px",
                        }}
                      >
                        Registros de vacinação
                      </h3>
                    </div>
                  </div>
                  <FormVaccines
                    type="pp"
                    quize={true}
                    handleClose={handleClose}
                    handleBack={() => {
                      setState({ ...state, vaccine_covid: "" });
                      handleBack();
                    }}
                    progress={progress}
                    vaccine_covid={state.vaccine_covid}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </div>
      {progress === 0 && (
        <div className="row">
          <div className="col-12">
            <ButtonLarge
              onClick={handleClose}
              style={{
                padding: "1px",
                width: "unset",
                minWidth: "100px",
                float: "left",
              }}
            >
              Sair
            </ButtonLarge>
            {/* {progress === 1 && (
            <ButtonLarge
              onClick={handleSave}
              style={{
                padding: "1px",
                width: "unset",
                minWidth: "100px",
                float: "right",
                backgroundColor: state.vaccine_covid ? "#3d8b40" : "unset",
                color: state.vaccine_covid ? "#fff" : "#8c8c8c",
              }}
            >
              Confirmar
            </ButtonLarge>
          )} */}
          </div>
        </div>
      )}
    </div>
  );
}
