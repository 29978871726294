import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import BarProgress from "components/CircularProgress/ProgressBar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Api from "services/api";
import "../styles.css";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import ColorizeIcon from "@material-ui/icons/Colorize";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import ModalAdd from "./ModalAdd";
import IconChart from "components/Charts/IconChart";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  name: {
    fontSize: "18px",
  },
  info_root: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    paddingBottom: "22px",
    width: "100%",
    marginTop: "20px",
  },
  info_div: {
    width: "33.33%",
    paddingLeft: "12px",
    paddingRight: "12px",
    "&:not(:first-child)": {
      borderLeft: "1px solid #0000000f",
    },
  },
  info_div_max: {
    width: "33.33%",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  info_value: {
    fontSize: "18px",
    fontWeight: "500",
  },
  info_value_max: {
    fontWeight: "500",
    display: "flex",
    justifyContent: "center",
  },
  info_title_max: {
    color: "#00000099",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
  },
  antro_value: {
    marginLeft: "20px",
    fontSize: "16px",
    fontWeight: "500",
    display: "block",
    marginBottom: "5px",
  },
  antro_title: {
    marginLeft: "20px",
    display: "block",
  },
  list_ul: {
    paddingLeft: "20px",
  },
  speedDial: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 10,
    [theme.breakpoints.down("sm")]: {
      bottom: 80,
    },
    left: "auto",
    position: "fixed",
    zIndex: 999,
  },
  card: {
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    backgroundColor: "#fff",
    backgroundClip: "border-box",
    border: "1px solid rgba(0, 0, 0, 0.125)",
    borderRadius: "0.25rem",
  },
}));

export default function PatientInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const width = useSelector((state) => state.settings.width);

  const [record, setRecord] = useState({
    vitalsigns: {},
  });
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    type: "",
    title: "",
  });

  const user = useSelector((state) => state.auth.authUser);
  const name = useSelector((state) => state.auth.fullname);
  const photo = useSelector((state) => state.auth.photo);
  const gender = useSelector((state) => state.auth.gender);
  const birthday = useSelector((state) => state.auth.birthday);
  const save = useSelector((state) => state.prescription.save_medicine);

  const actions = [
    { id: "data", icon: <AccountCircleIcon />, name: "Dados Básicos" },
    {
      id: "vitalsigns",
      icon: <AccessibilityIcon />,
      name: "Antropometria e Dados Vitais",
    },
    { id: "diagnostics", icon: <MoodBadIcon />, name: "Patologias" },
    { id: "alergies", icon: <BlurOnIcon />, name: "Alergias" },
    { id: "medicines", icon: <ColorizeIcon />, name: "Medicamentos em uso" },
  ];

  useEffect(() => {
    getData();
    dispatch({
      type: "SET_ROUTE",
      payload: "patient-info",
    });
  }, []);

  useEffect(() => {
    // Usado para carregar os medicamentos após salvar no ModalAdd
    if (save) {
      getData();
    }
  }, [save]);

  const getData = () => {
    Api.post("/ms_system/dashboard/getProntuary", { user })
      .then((res) => {
        setRecord({
          ...res.data.info,
          vitalsigns: res.data.info.vitalsigns ? res.data.info.vitalsigns : {},
        });
      })
      .finally(() => setLoader(false));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = (type, title) => {
    setModal({
      open: true,
      type,
      title,
    });
  };

  return (
    <>
      {loader ? (
        <div className="col-12 d-flex justify-content-center">
          <BarProgress />
        </div>
      ) : (
        <div className={width >= 960 ? "row col-12 p-4" : ""}>
          <Backdrop open={open} style={{ zIndex: 999 }} />
          {width < 960 && (
            <div className="col-12 ml-2">
              <Typography className="pt-1" variant="h6" gutterBottom>
                Resumo clínico
              </Typography>
            </div>
          )}
          <div
            className={
              width < 960
                ? "d-flex col-12 avatar-text mr-auto"
                : "d-flex col-6 avatar-text pb-4 pl-5"
            }
          >
            <Avatar className={classes.avatar} alt={name} src={photo} />
            <div className="ml-2">
              <span className={classes.name}>
                <strong>{name}</strong>
              </span>
              <p>{record.email ? record.email : ""}</p>
            </div>
          </div>
          {width < 960 ? (
            <div className={classes.info_root}>
              <div className={classes.info_div}>
                <div className={classes.info_value}>
                  {record.blood_type ? record.blood_type.toUpperCase() : "---"}
                </div>
                <span className={classes.info_title}>Tipo Sanguíneo</span>
              </div>
              <div className={classes.info_div}>
                <div className={classes.info_value}>
                  {gender === "0" ? "Masculino" : "Feminino"}
                </div>
                <span className={classes.info_title}>Sexo</span>
              </div>
              <div className={classes.info_div}>
                <div className={classes.info_value}>
                  {moment().diff(birthday, "years")} Anos
                </div>
                <span className={classes.info_title}>Idade</span>
              </div>
            </div>
          ) : (
            <div className={"col-md-6 col-xl-4 d-flex"}>
              <div className={classes.info_div_max}>
                <div className={classes.info_value_max}>
                  {record.blood_type ? record.blood_type.toUpperCase() : "---"}
                </div>
                <span className={classes.info_title_max}>Tipo Sanguíneo</span>
              </div>
              <div className={classes.info_div_max}>
                <div className={classes.info_value_max}>
                  {gender === "0" ? "Masculino" : "Feminino"}
                </div>
                <span className={classes.info_title_max}>Sexo</span>
              </div>
              <div className={classes.info_div_max}>
                <div className={classes.info_value_max}>
                  {moment().diff(birthday, "years")} Anos
                </div>
                <span className={classes.info_title_max}>Idade</span>
              </div>
            </div>
          )}

          <div
            className={
              width >= 960
                ? "col-12 row m-0 pr-2 pl-5 pt-2"
                : "col-12 row m-0 pt-2"
            }
          >
            {/* Antropometria*/}
            <div
              className={
                width < 960
                  ? "col-12 m-0 p-0"
                  : `col-4 ${classes.card} p-1 shadow-sm`
              }
              style={
                width >= 960 ? { marginLeft: "-20px", marginBottom: "9px" } : {}
              }
            >
              <div className="col-12 mt-3">
                <h3 className="title-prontuary pb-2">Antropometria</h3>
              </div>
              <div className="row">
                <div className="col-6 mt-2">
                  <span className={classes.antro_title}>
                    Peso{" "}
                    <IconChart
                      title="Evolução - Peso"
                      type="weight"
                      patient={user}
                    />
                  </span>
                  <span className={classes.antro_value}>
                    {record.vitalsigns.weight
                      ? `${record.vitalsigns.weight} Kg`
                      : "---"}
                  </span>
                </div>
                <div className="col-6 mt-2">
                  <span className={classes.antro_title}>
                    Altura{" "}
                    <IconChart
                      title="Evolução - Altura"
                      type="tall"
                      patient={user}
                    />
                  </span>
                  <span className={classes.antro_value}>
                    {record.vitalsigns.tall
                      ? `${record.vitalsigns.tall} m`
                      : "---"}
                  </span>
                </div>
                <div className="col-6 mt-2">
                  <span className={classes.antro_title}>
                    I.M.C{" "}
                    <IconChart
                      title="Evolução - IMC"
                      type="imc"
                      patient={user}
                    />
                  </span>
                  <span className={classes.antro_value}>
                    {record.vitalsigns.imc ? record.vitalsigns.imc : "---"}
                  </span>
                </div>
                <div className="col-6">
                  <span className={classes.antro_title}>Classe I.M.C</span>
                  <span className={classes.antro_value}>
                    {record.vitalsigns.class_imc
                      ? record.vitalsigns.class_imc
                      : "---"}
                  </span>
                </div>
              </div>
            </div>

            {/* Dados Vitais */}
            <div
              className={
                width < 960
                  ? "col-12 m-0 p-0"
                  : `col-8 ${classes.card} p-1 shadow-sm`
              }
              style={
                width >= 960 ? { marginLeft: "10px", marginBottom: "9px" } : {}
              }
            >
              <div className="col-12 mt-3">
                <h3 className="title-prontuary pb-2">Dados Vitais</h3>
              </div>
              <div className="row">
                <div className="col-4 mt-2">
                  <span className={classes.antro_title}>
                    Temperatura{" "}
                    <IconChart
                      title="Evolução - Temperatura"
                      type="tc"
                      patient={user}
                    />
                  </span>
                  <span className={classes.antro_value}>
                    {record.vitalsigns.at
                      ? `${record.vitalsigns.at} °C`
                      : "---"}
                  </span>
                </div>
                <div className="col-4 mt-2">
                  <span className={classes.antro_title}>
                    Saturação{" "}
                    <IconChart
                      title="Evolução - Saturação"
                      type="sat"
                      patient={user}
                    />
                  </span>
                  <span className={classes.antro_value}>
                    {record.vitalsigns.saturation
                      ? `${record.vitalsigns.saturation}%`
                      : "---"}
                  </span>
                </div>
                <div className="col-4 mt-2">
                  <span className={classes.antro_title}>
                    Freq. Resp.{" "}
                    <IconChart
                      title="Evolução - Frequência Respiratória"
                      type="fr"
                      patient={user}
                    />
                  </span>
                  <span className={classes.antro_value}>
                    {record.vitalsigns.ost0_fcrsp
                      ? `${record.vitalsigns.ost0_fcrsp} irpm`
                      : "---"}
                  </span>
                </div>

                <div className="col-4">
                  <span className={classes.antro_title}>
                    Pressão Arterial{" "}
                    <IconChart
                      title="Evolução - Pressão Arterial"
                      type="pa"
                      patient={user}
                    />
                  </span>
                  <span className={classes.antro_value}>
                    {record.vitalsigns.ost0_pas
                      ? `${record.vitalsigns.ost0_pas}x${record.vitalsigns.ost0_pad}`
                      : "---"}
                  </span>
                </div>
                <div className="col-4">
                  <span className={classes.antro_title}>
                    Freq. Cardíaca{" "}
                    <IconChart
                      title="Evolução - Frequência Cardíaca Basal"
                      type="fc"
                      patient={user}
                    />
                  </span>
                  <span className={classes.antro_value}>
                    {record.vitalsigns.ost0_fcbasal
                      ? `${record.vitalsigns.ost0_fcbasal} bpm`
                      : "---"}
                  </span>
                </div>
              </div>
            </div>

            <div
              className={
                width < 960
                  ? "col-12 mt-3 p-0"
                  : `col-4 ${classes.card} p-1 shadow-sm`
              }
              style={width >= 960 ? { marginLeft: "-20px" } : {}}
            >
              <div className="col-12 mt-3">
                <h3 className="title-prontuary pb-2">Medicamentos em uso</h3>
              </div>
              <div className="col-12 ml-2 mt-3">
                {record.medicines.length > 0 ? (
                  <ul className={classes.list_ul}>
                    {record.medicines.map((row) => (
                      <li>{`${row.name_display} ${row.dosage} ${row.form_pharm} `}</li>
                    ))}
                  </ul>
                ) : (
                  <span> Não há medicamentos registrados.</span>
                )}
              </div>
            </div>

            <div
              className={
                width < 960
                  ? "col-12 mt-3 p-0"
                  : `col-4 ${classes.card} p-1 shadow-sm`
              }
              style={width >= 960 ? { marginLeft: "10px" } : {}}
            >
              <div className="col-12 mt-3">
                <h3 className="title-prontuary pb-2">Patologias</h3>
              </div>
              <div className="col-12 ml-2 mt-3">
                {record.patologies && record.patologies.length > 0 ? (
                  <ul className={classes.list_ul}>
                    {record.patologies.map((row) => (
                      <li>{row}</li>
                    ))}
                  </ul>
                ) : (
                  <span> Nenhum registro.</span>
                )}
              </div>
            </div>

            <div
              className={
                width < 960
                  ? "col-12 mt-3 p-0"
                  : `col-4 ${classes.card} p-1 shadow-sm`
              }
              style={width >= 960 ? { marginLeft: "10px" } : {}}
            >
              <div className="col-12 mt-3">
                <h3 className="title-prontuary pb-2">Alergias</h3>
              </div>
              <div className="col-12 ml-2 mt-3">
                <div className="col-12 pl-0">
                  <i>
                    <strong>Doenças Alergias:</strong>
                  </i>
                  {record.disease_allergic.length > 0 ? (
                    <ul>
                      {record.disease_allergic.map((row) => (
                        <li>{row}</li>
                      ))}
                    </ul>
                  ) : (
                    <span> Nenhum registro.</span>
                  )}
                </div>

                <div className="col-12 pl-0">
                  <i>
                    <strong>Alergia a alimentos:</strong>
                  </i>
                  {record.food_allergy.length > 0 ? (
                    <ul>
                      {record.food_allergy.map((row) => (
                        <li>{row}</li>
                      ))}
                    </ul>
                  ) : (
                    <span> Nenhum registro.</span>
                  )}
                </div>
                <div className="col-12 pl-0">
                  <i>
                    <strong>Alergia a químicos:</strong>
                  </i>
                  {record.quimic.length > 0 ? (
                    <ul>
                      {record.quimic.map((row) => (
                        <li>{row}</li>
                      ))}
                    </ul>
                  ) : (
                    <span> Nenhum registro.</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-5 pt-5"></div>
          <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            hidden={false}
            icon={open ? <ClearIcon /> : <AddIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            className={classes.speedDial}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={() => handleAdd(action.id, action.name)}
              />
            ))}
          </SpeedDial>

          <ModalAdd
            patient={user}
            open={modal.open}
            type={modal.type}
            title={modal.title}
            handleClose={() => {
              if (modal.type === "alergies") {
                getData();
              }
              setModal({ open: false, type: "", title: "" });
            }}
            beforeSave={() => {
              setLoader(true);
              if (modal.type !== "medicines") {
                getData();
              }
            }}
          />
        </div>
      )}
    </>
  );
}
