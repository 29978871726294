import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import NoRecord from "components/Alert/NoRecord";
import FormSpecialty from "./provider/clinic/FormSpecialty";
import FormSpecialtyProfessional from "./professional/FormSpecialty";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function Specialty({ data, addressId, hiddenButton, type }) {
  const [state, setState] = useState([]);
  const [modal_form, setModalForm] = useState(false);
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [specialty_selected, setSpecialtySelected] = useState({});

  useEffect(() => {
    setState(data);
  }, []);

  function addSpecialty(obj) {
    setState([...state, obj]);
  }

  function deleteSpecialty() {
    Api.post(`/ms_system/workplace/deleteSpecialty/`, {
      id: specialty_selected,
    }).then(() => {
      let aux = state.filter((row) => row.id !== specialty_selected);
      setState(aux);
      setModalDelete(false);
      setSuccessDelete(true);
    });
  }
  return (
    <>
      <ExpansionPanel
        square
        style={{
          boxShadow: "10px 5px 5px white",
          borderBottom: "1px solid #d2d2d2",
          // marginTop: "10px"
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
        >
          Especialidades
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: "block" }}>
          <div className="w-100">
            <Button
              onClick={() => {
                setModalForm(true);
                setSpecialtySelected("");
              }}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
              style={{ float: "right" }}
              hidden={hiddenButton}
            >
              <i className="zmdi zmdi-plus" /> <span> Adicionar</span>
            </Button>
          </div>
          {state.length > 0 ? (
            <Table className="table-finances w-100">
              <TableHead>
                <TableRow>
                  <TableCell>Especialidade</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.map((row) => (
                  <TableRow hover key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.specialty !== "Não especificada"
                        ? row.specialty
                        : row.area}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => {
                          setSpecialtySelected(row.id);
                          setModalDelete(true);
                        }}
                        color="secondary"
                        aria-label="Remover"
                        hidden={hiddenButton}
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoRecord />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={modal_form}
        onClose={() => {
          setModalForm(false);
        }}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogContent>
          {type === "1" ? (
            <FormSpecialty
              data={specialty_selected}
              addressId={addressId}
              close={() => setModalForm(false)}
              add={(obj) => addSpecialty(obj)}
            />
          ) : (
            <FormSpecialtyProfessional
              data={state}
              addressId={addressId}
              close={() => setModalForm(false)}
              add={(obj) => addSpecialty(obj)}
            />
          )}
        </DialogContent>
      </Dialog>
      <SweetAlert
        show={modal_delete}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Deseja realmente excluir esta especialidade?"
        onConfirm={() => deleteSpecialty()}
        onCancel={() => setModalDelete(false)}
      />
      <SweetAlert
        show={success_delete}
        success
        title="Especialidade excluída com sucesso!"
        onConfirm={() => setSuccessDelete(false)}
      />
    </>
  );
}

export default Specialty;
