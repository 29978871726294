import React from "react";
import {
  TextField,
  Input,
  FormControl,
  FormHelperText
} from "@material-ui/core";

export default function TextFields(props) {
  const handleChange = e => {
    props.onChange(e);
  };
  return props.select === undefined ? (
    <FormControl className={`${props.className}`} fullWidth>
      <FormHelperText>{props.label}</FormHelperText>
      <Input {...props} />
    </FormControl>
  ) : (
    <TextField
      {...props}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true
      }}
    >
      {props.children}
    </TextField>
  );
}

export function InputMaskedCpf(props) {
  const handleChange = e => {
    props.onChange(e);
  };
  return "Mask";
}
