import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { setReadOnly } from "actions/evolution/Daily";
// import { setReadOnly, setUsersResidential } from "actions/evolution/Daily";
import Api from "services/api";

const Evolution = ({ match, history }) => {
  const dispatch = useDispatch();
  const address = useSelector(({ auth }) => auth.address);
  const doctor = useSelector(({ auth }) => auth.authUser);
  // const type = useSelector(({ auth }) => auth.type);

  const mdl_evolution = useSelector(
    ({ permissions }) => permissions.mdl_evolution
  );
  const isWorkday = useSelector(
    ({ evolutionDaily }) => evolutionDaily.isWorkday
  );
  const intervals = useSelector(
    ({ evolutionDaily }) => evolutionDaily.intervals
  );
  const [inWorkTime, setInWorkTime] = React.useState(false);

  React.useEffect(() => {
    if (!address) {
      history.push("/app/dashboard");
    } else {
      validWorkTime();
      getDraftShiftChange();
    }
  }, []);

  const validWorkTime = () => {
    if (mdl_evolution) {
      if (!isWorkday) {
        setInWorkTime(true);
      } else {
        if (
          moment().isAfter(moment(intervals[0].start).subtract(1, "hour")) &&
          moment().isBefore(
            moment(intervals[intervals.length - 1].end).add(1, "hour")
          )
        ) {
          setInWorkTime(false);
          dispatch(setReadOnly(false));
        } else {
          setInWorkTime(true);
        }
      }
    } else {
      dispatch(setReadOnly(true));
    }
  };

  const getDraftShiftChange = () => {
    Api.post(`/ms_evolution/shiftchange/getDraftShiftChange`, {
      doctor,
      address,
      start: intervals[0].start,
      end: intervals[intervals.length - 1].end,
    }).then((res) => {
      if (res.data.record.length > 0) {
        dispatch({
          type: "SET_DRAFT_SHIFT_CHANGE",
          payload: res.data.record[0].description,
        });
        dispatch({
          type: "SET_DRAFT_SAVED_ID",
          payload: res.data.record[0].id,
        });
      }
    });
  };

  // const getProfessionalPatient = () => {
  //   if (type !== "1") {
  //     Api.post(`/microservice/managementprofessional/getProfessionalPatient/`, {
  //       idAddress: address,
  //       idDoctor: doctor
  //     }).then(({ data }) => {
  //       data.success && dispatch(setUsersResidential(data.list_patient_prof));
  //     });
  //   }
  // };

  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect
          exact
          from={`${match.url}/`}
          to={`${match.url}/time-evolution`}
        />
        <Route
          path={`${match.url}/time-evolution`}
          component={asyncComponent(() => import("./routes/timeEvolution"))}
        />
        <Route
          path={`${match.url}/hour-evolution`}
          component={asyncComponent(() => import("./routes/hourEvolution"))}
        />
        <Route
          path={`${match.url}/patient-evolution`}
          component={asyncComponent(() => import("./routes/patientEvolution"))}
        />
        <Route
          path={`${match.url}/requests-old`}
          component={asyncComponent(() => import("./routes/requestCares"))}
        />
        <Route
          path={`${match.url}/request_care`}
          component={asyncComponent(() => import("./routes/approvedRequest"))}
        />
        <Route
          path={`${match.url}/intercurrence`}
          component={asyncComponent(() => import("./routes/intercurrence"))}
        />
        <Route
          path={`${match.url}/prescription-transcription`}
          component={asyncComponent(() =>
            import("./routes/prescriptionTranscription")
          )}
        />
        <Route
          path={`${match.url}/medicines`}
          component={asyncComponent(() => import("./routes/medicines"))}
        />
        <Route
          path={`${match.url}/prescriptioncare`}
          component={asyncComponent(() => import("./routes/PrescriptionCare"))}
        />
        <Route
          path={`${match.url}/shift-change`}
          component={asyncComponent(() => import("./routes/shiftChange"))}
        />
        <Route
          path={`${match.url}/requests`}
          component={asyncComponent(() => import("./routes/requests"))}
        />
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
      <SweetAlert
        show={inWorkTime}
        warning
        confirmBtnBsStyle="default"
        confirmBtnText="Ok"
        confirmButtonColor="#bc0003"
        title={
          "Você não está no seu horário de trabalho! Apenas poderá visualizar as informações."
        }
        onConfirm={() => {
          setInWorkTime(false);
          dispatch(setReadOnly(true));
        }}
      />
    </div>
  );
};

export default Evolution;
