export function getTagFromProvider(address) {
  return {
    type: "GET_TAGS_FROM_PROVIDER",
    address
  };
}

export function saveNewTag(state, address) {
  return {
    type: "SAVE_NEW_TAGS",
    payload: {
      state,
      address
    }
  };
}

export function includeTagToUser(user, doctor, tags, address) {
  return {
    type: "INCLUDE_TAGS_TO_USER",
    payload: {
      user,
      doctor,
      tags,
      address
    }
  };
}

export function getTagsFromPatient(patient, address) {
  return {
    type: "GET_TAGS_FROM_PATIENT",
    patient,
    address
  };
}

export function excluirTagFromPatient(id, patient, address) {
  return {
    type: "EXCLUIR_TAG_FROM_PATIENT",
    id,
    patient,
    address
  };
}

export function deleteTag(id, address) {
  return {
    type: "DELETE_TAG",
    id,
    address
  };
}
