import React, { useState } from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.css";
import moment from "moment";
import NoRecord from "components/Alert/NoRecord";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  text: {
    color: "#868686",
    width: "100%",
    fontSize: "16px",
    fontVariant: "petite-caps",
    textAlign: "center",
    marginTop: "40px"
  },
  textTble: {
    fontSize: "14px",
    fontWeight: "500"
  }
});

function TableCalendar({ eventsData }) {
  const classes = useStyles();

  return (
    <>
      <div className="col-12">
        <Typography className={classes.text}>
          {moment().format("DD/MM/YYYY")}
        </Typography>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Horario</th>
              <th scope="col"></th>
              <th scope="col">Nome</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {eventsData.map((e, index) => (
              <tr key={index} className={classes.textTble}>
                <th scope="row">{index + 1}</th>
                <td
                  style={{
                    borderLeft: "1px solid red"
                  }}
                >
                  {moment(e.start).format("HH:mm")} -{" "}
                  {moment(e.end).format("HH:mm")}
                </td>
                <td>
                  <Tooltip title={e.calendar} placement="top">
                    <span
                      style={{ backgroundColor: e.color, padding: "0.3rem" }}
                      className="size-10 lighten-1 rounded-circle d-inline-block mr-2"
                    />
                  </Tooltip>
                </td>
                <td>{e.title}</td>
                <td>{e.pacient_id && <div className="d-flex"></div>}</td>
              </tr>
            ))}
            {eventsData.length === 0 && (
              <tr>
                <td colspan="4">
                  <NoRecord />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default withRouter(TableCalendar);
