import React from "react";

import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    border: "solid 2px #8c8c8c",
    minWidth: "200px",
  },
  colorPrimary: {
    backgroundColor: "#fff",
  },
  bar: {
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export default function BorderLinearProgressStyled(props) {
  return <BorderLinearProgress {...props} />;
}
