import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  TextField,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import AutocompleteExam from "./AutocompleteExam";
import { ModalResponsive } from "components/ModalResponsive";
import Api from "services/api";
import { showMsg } from "services/functions";

const listTypesExam = [
  {
    id: 20,
    name: "Eletrofisiológicos / Mecânicos e Funcionais",
  },
  {
    id: 21,
    name: "Endoscópicos",
  },
  {
    id: 22,
    name: "Medicina Laboratorial",
  },
  {
    id: 23,
    name: "Medicina Transfusional",
  },
  {
    id: 24,
    name: "Genética",
  },
  {
    id: 25,
    name: "Anatomia Patológica e Citopatologia",
  },
  {
    id: 26,
    name: "Medicina Nuclear",
  },
  {
    id: 27,
    name: "Métodos Diagnósticos por Imagem",
  },
  {
    id: 28,
    name: "Ultrassonografia",
  },
  {
    id: 29,
    name: "Tomografia Computadorizada",
  },
  {
    id: 30,
    name: "Ressonância Magnética",
  },
  {
    id: 32,
    name: "Exames Específicos",
  },
  {
    id: 33,
    name: "Testes para Diagnósticos",
  },
  {
    id: 34,
    name: "Outros",
  },
];

export default function FormExamsGroup({
  open,
  data,
  handleEdit,
  handleAdd,
  doctor,
  onClose,
}) {
  const [state, setState] = useState({
    id: "",
    name: "",
    description: "",
    type_exam: "",
    list: [],
  });
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.id) {
      Api.post(`/microservice/examsgroup/getItensExamGroup/`, {
        id: data.id,
      }).then((res) => {
        setState({
          id: data.id,
          name: data.name,
          description: data.description,
          type_exam: data.fk_ls_tst_type_exam,
          list: res.data.record,
        });
      });
    } else {
      setState({
        id: "",
        name: "",
        description: "",
        type_exam: "",
        list: [],
      });
    }
  }, []);

  const handleChange = (field, value) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleSave = () => {
    if (state.name && state.type_exam && state.list.length > 0) {
      Api.post(`/microservice/examsgroup/saveExamsGroup/`, {
        doctor,
        ...state,
      })
        .then((res) => {
          let obj = {
            id: res.data.id,
            name: state.name,
            description: state.description,
            fk_ls_tst_type_exam: state.type_exam,
          };
          if (data.id) {
            handleEdit(obj);
          } else {
            handleAdd(obj);
          }
          onClose();
          showMsg("success", "Salvo com sucesso!", dispatch);
        })
        .catch(() => showMsg("error", "Ocorreu um erro!", dispatch));
    } else {
      setError(true);
    }
  };

  const handleChangeObs = (value, index) => {
    setState({
      ...state,
      list: state.list.map((row, key) =>
        key === index ? { ...row, obs: value } : row
      ),
    });
  };

  const handleRemove = (index) => {
    setState({
      ...state,
      list: state.list.filter((row, key) => key !== index),
    });
  };

  return (
    <ModalResponsive
      title="Novo grupo de exames"
      open={open}
      close={onClose}
      maxWidth="md"
      buttons={
        <div className="col-12">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={onClose}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>

          <Button
            color="primary"
            className="jr-btn jr-btn-sm bg-success"
            onClick={handleSave}
            variant="contained"
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar grupo</span>
          </Button>
        </div>
      }
    >
      <div className="row">
        <div className="col-md-6">
          <TextField
            id="name"
            label="Nome do Grupo"
            value={state.name}
            onChange={(e) => handleChange("name", e.target.value)}
            fullWidth
            error={error && !state.name}
          />
        </div>
        <div className="col-md-6">
          <TextField
            id="Descricao"
            label="Descrição"
            value={state.description}
            onChange={(e) => handleChange("description", e.target.value)}
            fullWidth
          />
        </div>
        <div className="col-md-12">
          <TextField
            id="type_exam"
            select
            label="Tipo do exame:"
            value={state.type_exam}
            onChange={(e) => handleChange("type_exam", e.target.value)}
            fullWidth
            style={{ marginTop: "17px" }}
            error={error && !state.type_exam}
          >
            {listTypesExam.map((row, index) => (
              <MenuItem key={row.id} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {state.type_exam && (
          <div className="col-md-12">
            <AutocompleteExam
              type={state.type_exam}
              handleChangeAutocomplete={(obj) =>
                setState({ ...state, list: [...state.list, obj] })
              }
              error={error && state.list.length === 0}
            />
          </div>
        )}
        {state.list.length > 0 && (
          <div className="col-md-12">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Exame</TableCell>
                  <TableCell>Observação</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.list.map((exam, index) => {
                  return (
                    <TableRow key={exam.id}>
                      <TableCell align="left" width="60%">
                        {exam.test}
                      </TableCell>
                      <TableCell width="30%">
                        <TextField
                          fullWidth
                          placeholder="Observações"
                          onChange={(e) =>
                            handleChangeObs(e.target.value, index)
                          }
                          value={exam.obs}
                        />
                      </TableCell>
                      <TableCell align="left" width="10%">
                        <Tooltip title="Excluir">
                          <IconButton
                            arial-label="Excluir"
                            color="secondary"
                            onClick={() => handleRemove(index)}
                          >
                            <i className="zmdi zmdi-delete" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </ModalResponsive>
  );
}
