import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import Button from "@material-ui/core/Button";

class Stock extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}${history.location.search}`; // get current path
    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="menu no-arrow">
            <NavLink to="/app/pharmacy/report">
              {/* <i className="zmdi zmdi-dns zmdi-hc-fw" /> */}
              <span className="nav-text">Consulta Estoque</span>
            </NavLink>
          </li>

          {/* <li className="menu no-arrow">
            <NavLink to="/app/pharmacy/dismissal-patient">
              <span className="nav-text">Dispensa por Paciente</span>
            </NavLink>
          </li> */}
          <li className="menu">
            <Button>
              <span className="nav-text">Entrada</span>
            </Button>

            <ul className="sub-menu">
              <li className="menu no-arrow">
                <NavLink to="/app/pharmacy/note-entry">
                  <span className="nav-text">Nota</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/pharmacy/input-product">
                  <span className="nav-text">Produto</span>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* <li className="menu no-arrow">
            <NavLink to="/app/pharmacy/dismissal-simple">
              <span className="nav-text">Dispensação Simples</span>
            </NavLink>
          </li> */}

          <li className="menu">
            <Button>
              <span className="nav-text">Dispensação</span>
            </Button>

            <ul className="sub-menu">
              <li className="menu no-arrow">
                <NavLink to="/app/pharmacy/dismissal-patient">
                  <span className="nav-text">Separação</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/pharmacy/conference">
                  <span className="nav-text">Conferência</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/pharmacy/dismissal">
              {/* <i className="zmdi zmdi-view-module zmdi-hc-fw" /> */}
              <span className="nav-text">Medicamentos Reservados</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/pharmacy/movement">
              {/* <i className="zmdi zmdi-swap-vertical zmdi-hc-fw" /> */}
              <span className="nav-text">Movimentação</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/pharmacy/product-stock">
              {/* <i className="zmdi zmdi-grid zmdi-hc-fw" /> */}
              <span className="nav-text">Cadastrar Produtos</span>
            </NavLink>
          </li>
          {/* <li className="menu no-arrow">
            <NavLink to="/app/pharmacy/initialization">
              <i className="zmdi zmdi-dns zmdi-hc-fw" />
              <span className="nav-text">Inicialização no Estoque</span>
            </NavLink>
          </li> */}

          <li className="menu no-arrow">
            <NavLink to="/app/pharmacy/stock-point">
              {/* <i className="zmdi zmdi-pin zmdi-hc-fw" /> */}
              <span className="nav-text">Cadastrar Ponto de Estoque</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/pharmacy/provider">
              {/* <i className="zmdi zmdi-store zmdi-hc-fw" /> */}
              <span className="nav-text">Cadastrar Fornecedores</span>
            </NavLink>
          </li>

          {/* <li className="menu no-arrow">
            <NavLink to="/app/pharmacy/report">
              <i className="zmdi zmdi-assignment zmdi-hc-fw" />
              <span className="nav-text">Relatórios</span>
            </NavLink>
          </li> */}

          <li className="menu no-arrow">
            <NavLink className="active" to="/app/pharmacy/inventory">
              {/* <i className="zmdi zmdi-mall zmdi-hc-fw" /> */}
              <span className="nav-text">Inventário</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink className="active" to="/app/pharmacy/protocol">
              {/* <i className="zmdi zmdi-mall zmdi-hc-fw" /> */}
              <span className="nav-text">Protocolo</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/dashboard">
              <i className="zmdi zmdi-undo zmdi-hc-fw" />
              <span className="nav-text">Sair do Estoque</span>
            </NavLink>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(Stock);
