import { all, takeLatest, put, call } from "redux-saga/effects";
// import { delay } from "redux-saga";
import Api from "services/api";

const getCalendars = async (user, type, address, provider_type, manager) => {
  try {
    const response = await Api.post(`/ms_system/calendar/getCalendars/`, {
      user,
      type,
      address,
      provider_type,
      manager,
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetCalendars(action) {
  try {
    const response = yield call(
      getCalendars,
      action.payload.user,
      action.payload.type,
      action.payload.address,
      action.payload.provider_type,
      action.payload.manager
    );
    yield put({
      type: "GET_CALENDARS_SUCCESS",
      payload: {
        record:
          response.record === null || response.record === undefined
            ? []
            : response.record,
        distinctUser:
          response.distinctUser === null || response.distinctUser === undefined
            ? []
            : response.distinctUser,
        type: action.payload.type,
        width: action.payload.width,
      },
    });
  } catch (error) {
    yield put({
      type: "GET_CALENDARS_FAIL",
    });
  }
}

const getEvents = async (calendar, date, integrated, patient) => {
  try {
    if (calendar) {
      const response = await Api.post(`/ms_system/calendar/getEvents/`, {
        id: calendar,
        start: date.start,
        end: date.end,
        integrated,
        patient,
      });
      return response.data.record;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetEvents(action) {
  try {
    const response = yield call(
      getEvents,
      action.payload.calendar,
      action.payload.date,
      action.payload.integrated,
      action.payload.patient
    );
    yield put({
      type: "GET_EVENTS_SUCCESS",
      payload: {
        record: response,
      },
    });
  } catch (error) {
    yield put({
      type: "GET_EVENTS_FAIL",
    });
  }
}

const getConfigsCalendar = async (user) => {
  try {
    const response = await Api.post(`/ms_system/calendar/getConfigsCalendar/`, {
      user,
    });
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetCalendarConfig(action) {
  try {
    const response = yield call(getConfigsCalendar, action.user);
    if (response.length > 0) {
      let hidden_days =
        response[0].hidden_days !== null
          ? response[0].hidden_days.split(",")
          : [];
      hidden_days = hidden_days.map((row) => parseInt(row));
      yield put({
        type: "GET_CONFIGS_SUCCESS",
        payload: {
          record: {
            ...response[0],
            hidden_days,
            loaded: true,
          },
        },
      });
    } else {
      yield put({
        type: "LOAD_CONFIGS_CALENDAR",
      });
    }
  } catch (error) {
    yield put({
      type: "LOAD_CONFIGS_CALENDAR",
    });
  }
}

const getEventLog = async (calendar) => {
  try {
    const response = await Api.post(`/ms_system/calendar/getEventsLog/`, {
      calendar,
    });
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetEventLog({ calendar }) {
  try {
    const response = yield call(getEventLog, calendar);
    yield put({
      type: "GET_EVENT_LOG_SUCCESS",
      payload: response !== null ? response : [],
    });
  } catch (error) {}
}

export default function* rootSaga() {
  yield all([takeLatest("GET_CALENDARS", asyncGetCalendars)]);
  yield all([takeLatest("GET_CONFIGS", asyncGetCalendarConfig)]);
  yield all([takeLatest("GET_EVENTS", asyncGetEvents)]);
  yield all([takeLatest("GET_EVENT_LOG", asyncGetEventLog)]);
}
