import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

export default function Intranet({ match, history }) {
  return (
    <Switch>
      <Route
        path={`${match.url}/compromisses`}
        component={asyncComponent(() => import("./routes/telemedicine"))}
      />
      <Route
        path={`${match.url}/doctor`}
        component={asyncComponent(() => import("./routes/telemedicineDoctor"))}
      />
      <Route component={asyncComponent(() => import("./routes/default"))} />
    </Switch>
  );
}

// <div className="app-wrapper px-0">
//   <div className="animated slideInUpTiny animation-duration-3">
//     <ContainerHeader
//       title={<IntlMessages id="module.calendar" />}
//       showConfig={true}
//       config="calendar"
//       match={match}
//     />
//     <ComponentCalendar />
//   </div>
// </div>
