import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Comunication = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route
        path={`${match.url}/contacts`}
        component={asyncComponent(() => import("./routes/contacts"))}
      />
      <Route
        path={`${match.url}/chat`}
        component={asyncComponent(() => import("./routes/chat"))}
      />
      <Route
        path={`${match.url}/video`}
        component={asyncComponent(() => import("./routes/video"))}
      />
      <Route
        path={`${match.url}/scheduled`}
        component={asyncComponent(() => import("./routes/scheduled"))}
      />
      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default Comunication;
