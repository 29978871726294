const INITIAL_STATE = {
  loader: true,
  list_files: []
};
export default function exames(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SAVE_NEW_UPLOAD_FILES_EVOLUTION":
      return {
        ...state,
        loader: true
      };
    case "SET_LIST_PICTURES":
      return {
        ...state,
        loader: false,
        list_files: action.list
      };
    case "GET_LIST_OF_IMAGES":
      return {
        ...state,
        loader: true
      };
    case "DELETE_PICTURES_COMPARE":
      return {
        ...state,
        loader: true
      };
    case "RESET_COMPARE_PICTURES":
      return INITIAL_STATE;
    default:
      return state;
  }
}
