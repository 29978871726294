import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
} from "constants/ActionTypes";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignOut = () => {
  return {
    type: "SIGNOUT_USER",
  };
};

export const userSignInSuccess = (
  authUser,
  address = "",
  secretary = false
) => {
  return {
    type: "SIGNIN_USER_SUCCESS",
    payload: {
      user: authUser,
      address,
      secretary,
    },
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const setPermissionsConsult = (area) => {
  return {
    type: "SET_PERMISSIONS_CONSULT",
    area,
  };
};

export const setAuthData = (
  name,
  photo,
  type,
  area,
  provider_type,
  teacher,
  gender,
  birthday,
  cpf,
  rg,
  fullname
) => {
  return {
    type: "SET_AUTH_DATA",
    payload: {
      name,
      photo,
      type,
      area,
      provider_type,
      teacher,
      gender,
      birthday,
      cpf,
      rg,
      fullname,
    },
  };
};

export const changeAddress = (address) => {
  return {
    type: "CHANGE_ADDRESS",
    payload: { address },
  };
};

export const setListAddress = (list, managers) => {
  return {
    type: "SET_LIST_ADDRESS",
    list,
    managers,
  };
};

export const setUserManage = (id, type, provider_type) => {
  return {
    type: "SET_USER_MANAGE",
    payload: {
      id,
      type,
      provider_type,
    },
  };
};
