import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import api from "services/api";

//Buscas prescrição manipulada
function* asyncSaveFormula(action) {
  try {
    const response = yield call(saveFormula, action);
    if (response.success) {
      yield put({
        type: "SAVE_FORMULA_SUCCESS",
        payload: response
      });
      yield put({
        type: "SET_INIT_APPOINTMENT",
        payload: { id: response.idAppointment }
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!"
      });
    }
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const saveFormula = async action => {
  try {
    const response = await api.post(
      `/microservice/prescriptionmanipulated/saveNewFormula/`,
      action
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncSaveRecipe(action) {
  try {
    const response = yield call(saveRecipe, action);
    if (response.success) {
      yield put({
        type: "SET_INIT_APPOINTMENT",
        payload: {
          id: response.idAppointment
        }
      });
      if (response.saved !== "" && response.saved !== null) {
        yield put({
          type: "SET_SAVED_ACTIONS",
          payload: {
            field: "prescriptionManipulated",
            id: response.saved
          }
        });
      }
      yield put({
        type: "SAVE_RECIPE_SUCCESS",
        payload: response
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!"
      });
    }
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    console.log(error);
  }
}

const saveRecipe = async action => {
  try {
    const response = await api.post(
      `/microservice/prescriptionmanipulated/saveRecipe/`,
      {
        params: action.data
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

const removePrescriptionManipulated = async prescription => {
  try {
    const response = await api.post(
      `/microservice/prescriptionmanipulated/deletePrescription/`,
      {
        prescription
      }
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

function* asyncRemovePrescriptionManipulated(action) {
  try {
    const response = yield call(
      removePrescriptionManipulated,
      action.prescription
    );

    if (response.success) {
      yield put({
        type: "REMOVE_PRESCRIPTION_MAN_SUCCESS",
        payload: {
          success: response.success,
          id_prescription: action.prescription
        }
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Removido com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao remover!"
      });
    }
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!"
    });
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  }
}

const getListPrescriptionManipulated = async action => {
  try {
    const response = await api.post(
      `/microservice/prescriptionmanipulated/getPrescManipulated/`,
      { patient: action.patient, appointment: action.appointment }
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetPrescManipulated(action) {
  try {
    const response = yield call(getListPrescriptionManipulated, action);
    yield put({
      type: "GET_MANIPULATE_PRES_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getOrigins = async doctor => {
  try {
    const response = await api.post(
      `/microservice/prescription/getOriginManipulated/`,
      { doctor }
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetOrigin(action) {
  try {
    const response = yield call(getOrigins, action.doctor);
    yield put({
      type: "GET_ORIGIN_PRES_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getClass = async origin => {
  try {
    const response = await api.post(
      `/microservice/prescription/getClassManipulated/`,
      { origin }
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetClass(action) {
  try {
    const response = yield call(getClass, action.origin);
    yield put({
      type: "GET_CLASS_MPN_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getTreatment = async (classMpn, origin) => {
  try {
    const response = await api.post(
      `/microservice/prescription/getTreatmentManipulated/`,
      { class: classMpn, origin: origin }
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetTreatment(action) {
  try {
    const response = yield call(getTreatment, action.classMpn, action.origin);
    yield put({
      type: "GET_TREATMENT_MPN_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getFormula = async treatment => {
  try {
    const response = await api.post(
      `/microservice/prescription/getFormulaManipulated/`,
      { treatment }
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetFormula(action) {
  try {
    const response = yield call(getFormula, action.treatment);
    yield put({
      type: "GET_FORMULA_MPN_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getDosage = async () => {
  try {
    const response = await api.post(`/microservice/prescription/getDosage/`);
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetDosage() {
  try {
    const response = yield call(getDosage);
    yield put({
      type: "GET_DOSAGE_MPN_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getPresentation = async formula => {
  try {
    const response = await api.post(
      `/microservice/prescription/getPresentationManipulated/`,
      { formula }
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetPresentation(action) {
  try {
    const response = yield call(getPresentation, action.formula);

    yield put({
      type: "GET_PRESENTATION_MPN_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
  }
}

const getPresentationStatic = async () => {
  try {
    const response = await api.post(
      `/microservice/prescription/getPresentationStatic/`
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetPresentationStatic() {
  try {
    const response = yield call(getPresentationStatic);
    yield put({
      type: "GET_PRES_STATIC_MPN_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
  }
}

const getIngredient = async formula => {
  try {
    const response = await api.post(
      `/microservice/prescriptionmanipulated/getIngredientsFormula/`,
      { formula }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetIngredientNew(action) {
  try {
    const response = yield call(getIngredient, action.formula);
    yield put({
      type: "GET_INGREDIENT_MPN_NEW_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncGetIngredient(action) {
  try {
    const response = yield call(getIngredient, action.formula);
    yield put({
      type: "GET_INGREDIENT_MPN_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncGetIngredientAdd(action) {
  try {
    const response = yield call(getIngredient, action.formula);
    yield put({
      type: "GET_INGREDIENT_MPN_NEW_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncGetPattern(action) {
  try {
    const response = yield call(getPatternMpn, action.doctor);
    yield put({
      type: "GET_PATTERN_MPN_SUCCESS",
      payload: {
        pattern: response.default,
        patternSalutem: response.defaultSalutem
      }
    });
  } catch (error) {
    console.log(error);
  }
}

const getPatternMpn = async doctor => {
  try {
    const response = await api.post(
      `/microservice/prescriptionManipulated/getDefaultManipulated/`,
      { doctor }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetPrescription(action) {
  try {
    const response = yield call(getPrescription, action.idPresc);
    console.log(response);
    yield put({
      type: "GET_PRESCRIPTION_MPN_SUCCESS",
      payload: response
    });
  } catch (error) {
    console.log(error);
  }
}

const getPrescription = async id => {
  try {
    const response = await api.post(
      `/microservice/prescriptionManipulated/getPrescription/`,
      { id }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};
//Fim busca prescrição manipulada

// PRESCRIÇÃO MANIPULADA
export function* requestSaveRecipe() {
  yield takeLatest("REQUEST_SAVE_RECIPE", asyncSaveRecipe);
}

export function* requestGetOrigin() {
  yield takeLatest("GET_ORIGIN_PRES", asyncGetOrigin);
}

export function* requestGetClass() {
  yield takeLatest("GET_CLASS_MPN", asyncGetClass);
}

export function* requestGetTreatment() {
  yield takeLatest("GET_TREATMENT_MPN", asyncGetTreatment);
}

export function* requestGetFormula() {
  yield takeLatest("GET_FORMULA_MPN", asyncGetFormula);
}

export function* requestGetPresentation() {
  yield takeLatest("GET_PRESENTATION_MPN", asyncGetPresentation);
}

export function* requestGetForms() {
  yield takeLatest("GET_PRES_STATIC_MPN", asyncGetPresentationStatic);
}

export function* requestGetDosage() {
  yield takeLatest("GET_DOSAGE_MPN", asyncGetDosage);
}

export function* requestGetIngredient() {
  yield takeLatest("GET_INGREDIENT_MPN", asyncGetIngredient);
}

export function* requestGetIngredientNew() {
  yield takeLatest("GET_INGREDIENT_NEW_MPN", asyncGetIngredientNew);
}

export function* requestGetIngredientAdd() {
  yield takeLatest("GET_INGREDIENT_MPN_NEW", asyncGetIngredientAdd);
}

export function* requestSaveFormula() {
  yield takeLatest("REQUEST_NEW_FORMULA", asyncSaveFormula);
}

export function* requestGetListManipulated() {
  yield takeLatest("GET_MANIPULATE_PRES", asyncGetPrescManipulated);
}

export function* requestRemovePrescriptionManipulate() {
  yield takeLatest(
    "REMOVE_PRESCRIPTION_MANIPULATED",
    asyncRemovePrescriptionManipulated
  );
}

export function* requestPattern() {
  yield takeLatest("GET_PATTERN_MPN", asyncGetPattern);
}

export function* requestGetPrescription() {
  yield takeLatest("GET_PRESCRIPTION_MPN", asyncGetPrescription);
}

export default function* rootSaga() {
  yield all([
    // prescrição manipulada
    fork(requestGetOrigin),
    fork(requestGetClass),
    fork(requestGetTreatment),
    fork(requestGetFormula),
    fork(requestGetIngredient),
    fork(requestGetIngredientNew),
    fork(requestGetIngredientAdd),
    fork(requestGetPresentation),
    fork(requestGetDosage),
    fork(requestGetForms),
    fork(requestGetListManipulated),
    fork(requestSaveFormula),
    fork(requestSaveRecipe),
    fork(requestRemovePrescriptionManipulate),
    fork(requestPattern),
    fork(requestGetPrescription)
  ]);
}
