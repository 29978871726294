import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import api from "services/api";

const postSaveScale = async (action) => {
  const { doctor, patient, appointment, address, value, idScale, obs } = action;
  const response = await api.post(`/microservice/scalepain/saveScale/`, {
    doctor,
    patient,
    appointment,
    address,
    value,
    idScale,
    obs,
  });
  const data = await response.data;
  return data;
};

function* SaveScale(action) {
  try {
    const response = yield call(postSaveScale, action);

    yield put({
      type: "REQUEST_SAVE_SCALE_SUCCESS",
      payload: response,
    });

    yield put({
      type: "SET_INIT_APPOINTMENT",
      payload: { id: response.appointment },
    });

    if (response.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!",
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!",
      });
    }
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!",
    });
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  }
}

const getListScale = async (patient) => {
  const response = await api.post(`/microservice/scalepain/listScale/`, {
    id: patient,
  });
  const data = await response.data.record;
  return data;
};

function* asyncGetScale(action) {
  try {
    // variável que faz a busca
    const getList = yield call(getListScale, action.patient);
    // parte do método que identifica qual método usar após a conclusão do método acima
    yield put({
      type: "GET_LIST_SCALE",
      payload: getList === null ? [] : getList,
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const deleteScale = async (scale) => {
  const response = await api.post(`/microservice/scalepain/deleteScale/`, {
    id: scale,
  });
  const data = await response.data;
  return data;
};

function* asyncDeleteScale(action) {
  try {
    const response = yield call(deleteScale, action.scale);
    yield put({
      type: "DELETE_SCALE_SUCCESS",
      payload: response,
    });

    if (response.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Removido com sucesso!",
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao remover!",
      });
    }
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

export function* requestSaveScale() {
  yield takeLatest("REQUEST_SAVE_SCALE", SaveScale);
}

export function* requestGetScale() {
  yield takeLatest("GET_SCALE", asyncGetScale);
}

export function* requestDeleteScale() {
  yield takeLatest("DELETE_SCALE", asyncDeleteScale);
}

export default function* rootSaga() {
  yield all([
    fork(requestSaveScale),
    fork(requestGetScale),
    fork(requestDeleteScale),
  ]);
}
