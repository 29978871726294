import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setAppointment, setSavedActions } from "actions/consult/Appointment";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { initial } from "../CareInitialState";
import Api from "services/api";

import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan, BtnSave, Btn } from "components/Button";
import moment from "moment";
import CircularProgress from "components/CircularProgress";

import CareViews from "../views";
import CareForms from "../forms";
import validatePrescriptionCare from "../Validate";
import SelectPatient from "./SelectPatient";

/**
 * Modal de Visualizalção e ciração de Prescrições de Cuidados
 * @param {Boolean} open obrigatório
 * @param {Function} handleClose obrigatório Função para fechar o modal
 * @param {Object} record obrigatório dados da prescrição
 * @param {Function} setView obrigatório Função de troca de visualização
 * @param {Boolean} set_appointment [opcional] define se o appointment deve ser setado após criação da prescrição
 * @param {String} patient_no_appointment [opcional] Paciente q não está no appointment
 */
export default function ModalCare({
  open,
  handleClose,
  record,
  view,
  setView,
  set_appointment = true,
  patient_no_appointment,
  pattern = false,
}) {
  const [typeCare, setTypeCare] = useState("");
  const [state, setState] = useState(initial);
  const doctor = useSelector(({ auth }) => auth.authUser);
  const patient = useSelector(({ appointment }) => appointment.patient);
  const address = useSelector(({ auth }) => auth.address);
  const appointment_id = useSelector((state) => state.appointment.id);
  const saved_id = useSelector((state) => state.appointment.saved.id);
  const prof_resp = useSelector((state) => state.appointment.prof_resp);
  const start = useSelector((state) => state.appointment.start);
  const [selectPatient, setSelectPatient] = useState(false);
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setTypeCare("");
    handleClose();
    setError(false);
  };

  useEffect(() => {
    if (record) {
      setTypeCare(record.type);
      setState({
        id: record.id,
        care: record.care,
        name: record.name,
        hours: record.hours ? record.hours.split(",") : [""],
        quantity: parseInt(record.times_a_day),
        observation: record.observation ? record.observation : "",
        duration: parseInt(record.duration),
        interval: record.interval,
        start: moment(record.start).isBefore(moment())
          ? new Date()
          : new Date(record.start),
        end: new Date(record.end),
        classification: record.classification
          ? record.classification.split(",")
          : [""],
        materials: record.materials ? record.materials.split(",") : [""],
        subClassification: record.sub_classification
          ? record.sub_classification.split(",")
          : [""],
        flow: record.flow,
        volume: record.volume,
        volume_type: record.volume_type,
        type_hydratation: record.type_hydratation
          ? record.type_hydratation
          : "",
        serum_type: record.serum_type ? record.serum_type : "",
        period: record.period ? record.period.split(",") : [""],
        degree: record.degree ? record.degree : "",
        single_period: record.single_period ? record.single_period : "",
        decubit: record.decubits ? record.decubits.split(",") : [""],
        time_interval: record.time_interval ? record.time_interval : "",
        hour_interval: record.hour_interval,
        respiratory: record.respiratory === "1" ? true : false,
        capillary: record.capillary === "1" ? true : false,
        blood_pressure: record.blood_pressure === "1" ? true : false,
        pulse: record.pulse === "1" ? true : false,
        temperature: record.temperature === "1" ? true : false,
        consciousness: record.consciousness === "1" ? true : false,
        behavior: record.behavior === "1" ? true : false,
        sleep: record.sleep === "1" ? true : false,
      });
    } else {
      setState(initial);
    }
  }, [record]);

  const handleSave = () => {
    setLoading(true);
    Api.post(`/microservice/prescriptioncare/savePrescriptionCare/`, {
      appointment_id,
      saved_id,
      start,
      doctor,
      address,
      patient: !set_appointment ? patient_no_appointment : patient,
      prof_resp,
      type_care: typeCare,
      record: {
        ...state,
        start: moment(state.start).format("MM-DD-YYYY"),
        end: moment(state.end).format("MM-DD-YYYY"),
      },
    })
      .then(({ data }) => {
        if (set_appointment && !appointment_id) {
          dispatch(setAppointment(data.appointment_id));
        }
        if (set_appointment && data.saved) {
          dispatch(setSavedActions("prescriptionCare", data.saved));
        }
        handleClose(data.newList);
        setTypeCare("");
        setState(initial);
        setError(false);
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch((error) => {
        dispatch(
          viewAlertMessage(
            "error",
            "Algo deu errado, por favor tente novamente!"
          )
        );
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validate = () => {
    if (validatePrescriptionCare(state, typeCare)) {
      setError(true);
    } else {
      pattern ? setSelectPatient(true) : handleSave();
    }
  };

  const handleSavePattern = () => {
    setLoading(true);
    Api.post(`/ms_managment/carepattern/saveCarePattern`, {
      address,
      type_care: typeCare,
      record: state,
      patients: patients.filter((row) => row.checked),
    })
      .then(({ data }) => {
        handleClose(data.newList);
        setTypeCare("");
        setState(initial);
        setError(false);
        setSelectPatient(true);
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch((error) => {
        dispatch(
          viewAlertMessage(
            "error",
            "Algo deu errado, por favor tente novamente!"
          )
        );
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalResponsive
      title={view ? "Cuidado Prescrito" : "Prescrever Cuidados"}
      open={open}
      close={handleCloseModal}
      maxWidth={"md"}
      buttons={
        !loading && (
          <div className="col-12">
            {selectPatient ? (
              <Btn
                name={"Voltar"}
                icon={"zmdi zmdi-arrow-left"}
                title={"Voltar"}
                onClick={() => setSelectPatient(false)}
                float="left"
              />
            ) : (
              <BtnCan
                title={"Cancelar"}
                onClick={handleCloseModal}
                float="left"
              />
            )}
            {((typeCare && !view) || selectPatient) && (
              <BtnSave
                title={"Salvar"}
                onClick={selectPatient ? handleSavePattern : validate}
                float="right"
              />
            )}
            {view && record.is_pattern !== "1" && (
              <Btn
                name={"Editar"}
                icon={"zmdi zmdi-edit"}
                title={"Editar"}
                onClick={() => setView(false)}
                float="right"
              />
            )}
          </div>
        )
      }
    >
      {loading ? (
        <CircularProgress />
      ) : view ? (
        <CareViews typeCare={typeCare} record={record} />
      ) : !selectPatient ? (
        <CareForms
          typeCare={typeCare}
          setTypeCare={(value) => {
            setTypeCare(value);
            setState(initial);
          }}
          state={state}
          setState={setState}
          error={error}
        />
      ) : (
        <SelectPatient patients={patients} setPatients={setPatients} />
      )}
    </ModalResponsive>
  );
}
