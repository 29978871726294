import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%",
  },
  root: {
    backgroundColor: "#fff",
  },
  selected: {
    backgroundColor: "#e2e2e8 !important",
  },
};

function AutocompleteDoctor({
  handleChangeAutocomplete,
  error,
  doctor,
  specialty,
  specialtySelected,
  add_new,
  professional,
  edit,
  clear,
  classes,
}) {
  const [suggestions, setSuggestions] = useState([]);

  function getSuggestions(e) {
    if (e.target.value.length > 2) {
      if (specialty) {
        if (specialtySelected.specialty === "Não especificada") {
          Api.post(`/microservice/autocomplete/getUsersInArea/`, {
            term: e.target.value,
            area: specialtySelected.area_id,
          }).then((res) => {
            setSuggestions(res.data.record);
          });
        } else {
          Api.post(`/ms_system/workplace/getUsersInSpecialty/`, {
            term: e.target.value,
            specialty,
          }).then((res) => {
            setSuggestions(res.data.record);
          });
        }
      } else {
        Api.post(`/microservice/autocomplete/getUser/`, {
          term: e.target.value,
          add_new,
          professional,
        }).then((res) => {
          if (res.data.record.length > 0) {
            setSuggestions(res.data.record);
          }
        });
      }
    }
  }

  function renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
      >
        {suggestion.name}
      </MenuItem>
    );
  }

  function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        error={error}
        disabled={edit}
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...InputProps,
        }}
        onKeyUp={(e) => getSuggestions(e)}
        onChange={() => {}}
      />
    );
  }

  return (
    <Downshift
      onSelect={(selectedItem, obj) => {
        let item = suggestions.filter((row) => {
          return row.name === selectedItem;
        });
        handleChangeAutocomplete(item[0].id, item[0].name);
        if (clear) {
          obj.setState({ inputValue: "" });
        }
      }}
      selectedItem={doctor.name ? doctor.name : null}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex,
      }) => (
        <div className={classes.container}>
          {renderInput({
            fullWidth: true,
            classes,
            InputProps: getInputProps({
              id: `ac-doctor`,
            }),
            label: "Informe o nome:",
            margin: "normal",
          })}
          {isOpen ? (
            <Paper square>
              {suggestions.map((suggestion, index) =>
                renderSuggestion({
                  suggestion,
                  index,
                  itemProps: getItemProps({
                    item: suggestion.name,
                  }),
                  highlightedIndex,
                  selectedItem,
                })
              )}
            </Paper>
          ) : null}
        </div>
      )}
    </Downshift>
  );
}

AutocompleteDoctor.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutocompleteDoctor);
