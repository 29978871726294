import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import NoRecord from "components/Alert/NoRecord";
import FormResidents from "./FormResidents";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function Residents({ addressId, hiddenButton }) {
  const [state, setState] = useState([]);
  const [modal_form, setModalForm] = useState(false);
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [resident_selected, setResidentSelected] = useState({});

  useEffect(() => {
    Api.post(`/ms_system/workplace/getMembersInResidential/`, {
      addressId
    }).then(res => setState(res.data.record));
  }, []);

  function addResident(obj) {
    setState([...state, ...obj]);
  }

  function deleteResident() {
    Api.post(`/ms_system/workplace/removeResidentAddress/`, {
      id: resident_selected.id_member_in_group
    }).then(() => {
      let aux = state.filter(row => row.id !== resident_selected.id);
      setState(aux);
      setModalDelete(false);
      setSuccessDelete(true);
    });
  }

  return (
    <>
      <ExpansionPanel
        square
        style={{
          boxShadow: "10px 5px 5px white",
          borderBottom: "1px solid #d2d2d2"
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
        >
          Residentes
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: "block" }}>
          <div className="w-100">
            <Button
              onClick={() => {
                setModalForm(true);
                setResidentSelected("");
              }}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
              style={{ float: "right" }}
              hidden={hiddenButton}
            >
              <i className="zmdi zmdi-plus" /> <span> Adicionar</span>
            </Button>
          </div>
          {state.length > 0 ? (
            <Table className="table-finances w-100">
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Residente</TableCell>
                  <TableCell align="right" width="30%">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.map(row => (
                  <TableRow hover key={row.id}>
                    <TableCell component="th" scope="row" width="25%">
                      {row.name}
                    </TableCell>
                    <TableCell width="25%" align="right" hidden={hiddenButton}>
                      <IconButton
                        onClick={() => {
                          setResidentSelected(row);
                          setModalDelete(true);
                        }}
                        color="secondary"
                        aria-label="Remover"
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoRecord />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={modal_form}
        onClose={() => {
          setModalForm(false);
        }}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogContent>
          <FormResidents
            addressId={addressId}
            close={() => setModalForm(false)}
            add={obj => addResident(obj)}
          />
        </DialogContent>
      </Dialog>
      <SweetAlert
        show={modal_delete}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Deseja realmente remover este residente?"
        onConfirm={() => deleteResident()}
        onCancel={() => setModalDelete(false)}
      />
      <SweetAlert
        show={success_delete}
        success
        title="Sala de atendimento excluída com sucesso!"
        onConfirm={() => setSuccessDelete(false)}
      />
    </>
  );
}

export default Residents;
