import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAccounts } from "actions/Finance";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import {
  TextField,
  Button,
  IconButton,
  Tooltip,
  MenuItem,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
} from "@material-ui/core";
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import Api from "services/api";
import MaskMoney from "components/Masks/MaskMoney";
import { getFormatValueMoney } from "services/functions";
import moment from "moment";

function FormFinance({ id, multiple, close }) {
  const [accs_bank, setAccsBank] = useState([]);
  const [accs_in, setAccsIn] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [accountOutId, setAccountOutId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState(false);
  const accounts = useSelector(({ finance }) => finance.accounts);

  const dispatch = useDispatch();

  const [parcel, setParcel] = useState("0");
  const [numberParcel, setNumberParcel] = useState(1);
  const [repeatParcel, setRepeatParcel] = useState(30);

  const objLaunch = {
    description: "",
    document: "",
    numberDocument: "",
    date: moment().format("MM-DD-YYYY"),
    dateEfet: moment().format("MM-DD-YYYY"),
    value: "0,00",
    status: "",
    operation: "",
  };
  const [launch, setLaunch] = useState([objLaunch]);

  useEffect(() => {
    Api.post("/ms_system/calendar/getTransactionEvent/", {
      event: id,
      multiple,
    }).then((res) => {
      const { record } = res.data;
      if (record.length > 0) {
        dispatch(getAccounts(record[0].index_id));
        setTransactionId(record[0].id);
        setAccountId(record[0].acc_in);
        setAccountOutId(record[0].acc_out);
        setTitle(record[0].title);

        if (parseInt(record[0].tot_sched) > 1) {
          setParcel("1");
          setNumberParcel(record[0].tot_sched);
        }

        let aux_launch = record.map((row) => {
          let dtfim = moment().format("MM-DD-YYYY");
          if (row.final_date) {
            dtfim = row.date.split("/");
            dtfim = dtfim[1] + "-" + dtfim[0] + "-" + dtfim[2];
          }
          let dt = row.date.split("/");
          dt = dt[1] + "-" + dt[0] + "-" + dt[2];
          return {
            operation: row.operation ? row.operation : "",
            value: row.value,
            status: row.status,
            document: row.document ? row.document : "",
            numberDocument: row.number_document ? row.number_document : "",
            description: row.description ? row.description : "",
            dateEfet: dtfim,
            date: dt,
          };
        });

        setLaunch(aux_launch);
      }
    });
  }, []);

  useEffect(() => {
    let rev = [];
    let bank = [];
    accounts.forEach((row) => {
      if (row.has_transaction === "1") {
        if (row.type === "1" || row.type === "0") {
          let info_parent = accounts.find((i) => i.id === row.parent_id);
          bank.push({ id: row.id, name: `${info_parent.name} - ${row.name}` });
        }
        if (row.type === "2") {
          rev.push({ id: row.id, name: row.name });
        }
      }
    });
    setAccsIn(rev);
    setAccsBank(bank);
  }, [accounts]);

  useEffect(() => {
    let aux = [];
    let date = moment();
    for (let index = 0; index < numberParcel; index++) {
      aux.push({
        ...objLaunch,
        date: date.format("MM-DD-YYYY"),
      });
      date = date.add(parseInt(repeatParcel), "days");
    }

    setLaunch(aux);
  }, [numberParcel]);

  useEffect(() => {
    let date = launch[0].date.split("-");
    date = moment(`${date[2]}-${date[0]}-${date[1]}`);
    setLaunch(
      launch.map((row) => {
        let obj = { ...row, date: date.format("MM-DD-YYYY") };
        date = date.add(parseInt(repeatParcel), "days");
        return obj;
      })
    );
  }, [repeatParcel]);

  useEffect(() => {
    if (parcel === "0") {
      if (launch.length > 1) {
        setLaunch([objLaunch]);
      }
    }
  }, [parcel]);

  function save() {
    if (validate()) {
      let formData = {
        transactionId,
        accountId,
        accountOutId,
        title,
        launch: launch.map((row) => ({
          ...row,
          value: getFormatValueMoney(row.value, ","),
        })),
        parcel,
        numberParcel,
        repeatParcel,
      };

      Api.post("/ms_system/calendar/saveFinanceEvent/", formData)
        .then(() => {
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => {
            dispatch(closeAlertMessage("success", "Salvo com sucesso!"));
          }, 5000);
          close();
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => {
            dispatch(closeAlertMessage("success", "Salvo com sucesso!"));
          }, 5000);
        });
    }
  }

  function validate() {
    // if (accountId && accountOutId && type && title && date && value && status) {
    if (accountId && accountOutId && title) {
      let bool = true;
      launch.forEach((row) => {
        if (!row.date || row.value === "0,00" || !row.status) {
          bool = false;
        }
      });

      if (parcel === "1" && (!numberParcel || !repeatParcel)) {
        bool = false;
      }

      setError(bool);
      return bool;
    } else {
      setError(true);
    }
  }

  const changeLaunch = (index, field, value) => {
    setLaunch(
      launch.map((row, key) =>
        key === index
          ? {
              ...row,
              [field]: value,
            }
          : row
      )
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <h2 className="text-primary font-weight-semibold">
          Faturar agendamento
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
      </div>

      <div className="col-md-12">
        <TextField
          id="title"
          label="Título:"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
          error={error && !title}
        />
      </div>

      <div className="col-md-6 col-xs-12 mt-3">
        <TextField
          id="accountId"
          select
          label={"Conta receita"}
          value={accountId}
          onChange={(e) => setAccountId(e.target.value)}
          SelectProps={{}}
          fullWidth
          error={error && !accountId}
        >
          {accs_in.map((row) => (
            <MenuItem key={row.id} value={row.id}>
              {row.name}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <div className="col-md-6 col-xs-12 mt-3">
        <TextField
          id="accountOutId"
          select
          label="Caixa/Bancos:"
          value={accountOutId}
          onChange={(e) => setAccountOutId(e.target.value)}
          SelectProps={{}}
          fullWidth
          error={error && !accountOutId}
        >
          {accs_bank.map((row) => (
            <MenuItem key={row.id} value={row.id}>
              {row.name}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <hr className="w-100" />
      <div className="col-md-4 col-xs-12">
        <Grid component="label" container alignItems="center">
          <Grid item>
            <FormLabel
              error={error && !parcel}
              component="legend"
              className="mt-1"
            >
              Parcelado:
            </FormLabel>
          </Grid>
          <Grid item>
            <RadioGroup
              aria-label="check"
              name="des_check"
              value={parcel}
              onChange={(e) => setParcel(e.target.value)}
              style={{ display: "inline", marginLeft: "20px" }}
            >
              <FormControlLabel
                value="0"
                control={<Radio color="primary" />}
                label="Não"
              />
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label="Sim"
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </div>
      {parcel === "1" && (
        <>
          <div className="col-md-4 col-xs-12">
            <TextField
              type="number"
              label="Número de parcelas:"
              value={numberParcel}
              onChange={(e) => setNumberParcel(e.target.value)}
              fullWidth
              error={error && !numberParcel}
              inputProps={{ min: 1 }}
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <TextField
              id="repeat"
              select
              label="Repetir:"
              value={repeatParcel}
              onChange={(e) => setRepeatParcel(e.target.value)}
              SelectProps={{}}
              fullWidth
              error={error && !repeatParcel}
            >
              <MenuItem key="rp1" value="1">
                Diariamente
              </MenuItem>
              <MenuItem key="rp2" value="7">
                Semanalmente
              </MenuItem>
              <MenuItem key="rp3" value="15">
                Quizenalmente
              </MenuItem>
              <MenuItem key="rp4" value="30">
                Mensalmente
              </MenuItem>
              <MenuItem key="rp5" value="60">
                Bimestralmente
              </MenuItem>
              <MenuItem key="rp6" value="90">
                Trimestralmente
              </MenuItem>
              <MenuItem key="rp7" value="180">
                Semestrealmente
              </MenuItem>
              <MenuItem key="rp8" value="365">
                Anualmente
              </MenuItem>
            </TextField>
          </div>
        </>
      )}

      {launch.map((row, index) => (
        <>
          <hr className="w-100" />
          <h4 className="font-weight-semibold mt-1 text-primary ml-3 w-100">
            {parcel === "0"
              ? "Lançamento"
              : `Parcela ${index + 1}/${numberParcel}`}
          </h4>
          <div className="col-md-4">
            <TextField
              id="description"
              label="Tipo documento:"
              value={row.document}
              onChange={(e) => changeLaunch(index, "document", e.target.value)}
              fullWidth
              margin="normal"
              style={{ marginTop: "0px" }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              id="description"
              label="Número documento:"
              value={row.numberDocument}
              onChange={(e) =>
                changeLaunch(index, "numberDocument", e.target.value)
              }
              fullWidth
              margin="normal"
              style={{ marginTop: "0px" }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              id="description"
              label="Descrição:"
              value={row.description}
              onChange={(e) =>
                changeLaunch(index, "description", e.target.value)
              }
              fullWidth
              margin="normal"
              style={{ marginTop: "0px" }}
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <MaskMoney
              label="Valor:"
              value={row.value}
              onChange={(e) => changeLaunch(index, "value", e.target.value)}
              error={error && !row.value}
            />
          </div>

          <div className="col-md-4 col-xs-12 mt-3">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
              <DatePicker
                label="Data:"
                value={row.date}
                onChange={(e) =>
                  changeLaunch(index, "date", moment(e).format("MM-DD-YYYY"))
                }
                format="dd/MM/yyyy"
                fullWidth
                error={error && !row.date}
                cancelLabel="Cancelar"
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="col-md-4 col-xs-12 mt-3">
            <TextField
              id="operation"
              select
              label="Tipo de operação:"
              value={row.operation}
              onChange={(e) => changeLaunch(index, "operation", e.target.value)}
              SelectProps={{}}
              fullWidth
              error={error && !row.operation}
            >
              <MenuItem key="cd" value="cd">
                Cartão de debito
              </MenuItem>
              <MenuItem key="cc" value="cc">
                Cartão de Crédito
              </MenuItem>
              <MenuItem key="ch" value="ch">
                Cheque
              </MenuItem>
              <MenuItem key="di" value="di">
                Dinheiro
              </MenuItem>
              <MenuItem key="da" value="da">
                Débito automatico
              </MenuItem>
              <MenuItem key="ps" value="ps">
                Plano de Saúde
              </MenuItem>
            </TextField>
          </div>

          <div className="col-md-6 col-xs-12 mt-3">
            <Grid component="label" container alignItems="center">
              <Grid item>
                <FormLabel
                  error={error && !row.status}
                  component="legend"
                  className="mt-1"
                >
                  Situação:
                </FormLabel>
              </Grid>
              <Grid item>
                <RadioGroup
                  aria-label="check"
                  name="des_check"
                  value={row.status}
                  onChange={(e) =>
                    changeLaunch(index, "status", e.target.value)
                  }
                  style={{ display: "inline", marginLeft: "20px" }}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="Pendente"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Quitado"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </div>

          {row.status === "1" && (
            <div className="col-md-6 col-xs-12 mt-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                <DatePicker
                  label="Data efetiva:"
                  value={row.dateEfet}
                  onChange={(e) =>
                    changeLaunch(
                      index,
                      "dateEfet",
                      moment(e).format("MM-DD-YYYY")
                    )
                  }
                  format="dd/MM/yyyy"
                  fullWidth
                  cancelLabel="Cancelar"
                />
              </MuiPickersUtilsProvider>
            </div>
          )}
        </>
      ))}

      <div className="col-md-12" style={{ marginTop: "30px" }}>
        <Button
          color="secondary"
          variant="contained"
          className="jr-btn jr-btn-sm"
          style={{ float: "left" }}
          onClick={() => close()}
        >
          <i className="zmdi zmdi-close zmdi-hc-lg" />
          <span> Cancelar </span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          className="jr-btn jr-btn-sm bg-success"
          style={{ float: "right" }}
          onClick={() => save()}
        >
          <i className="zmdi zmdi-check zmdi-hc-lg" />
          <span> Faturar </span>
        </Button>
      </div>
    </div>
  );
}

export default FormFinance;
