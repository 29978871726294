const INITIAL_STATE = {
  list_types: [],
  idTypeEvent: "",
  loaded: false,
};
export default function typeEvent(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_TYPE_EVENT":
      return INITIAL_STATE;
    case "GET_LIST_TYPE_EVENT_SUCCESS":
      return {
        ...state,
        list_types: action.payload === null ? [] : action.payload,
        loaded: true,
      };
    case "NEW_TYPE_EVENT":
      let list = state.list_types.filter((row) => row.id !== action.payload.id);
      return {
        ...state,
        list_types: [
          {
            id: action.payload.id,
            price: action.payload.value,
            label: action.payload.description,
            days: action.payload.days_of_return
              ? action.payload.days_of_return
              : "0",
            color: action.payload.color,
            duration: action.payload.duration,
            procedures: action.payload.procedures,
          },
          ...list,
        ],
        typeEvent: action.payload.id,
      };
    case "EDIT_TYPE_EVENT":
      return {
        ...state,
        list_types: state.list_types.map((row) =>
          row.id !== action.payload.id
            ? row
            : {
                id: action.payload.id,
                price: action.payload.value,
                label: action.payload.description,
                days: action.payload.days_of_return
                  ? action.payload.days_of_return
                  : "0",
                color: action.payload.color,
                duration: action.payload.duration,
                procedures: action.payload.procedures,
              }
        ),

        typeEvent: action.payload.id,
      };
    case "REMOVE_TYPE_EVENT":
      let listAux2 = state.list_types.filter((row) => {
        return row.id !== action.payload.id;
      });
      return {
        ...state,
        list_types: [...listAux2],
        typeEvent: action.payload.id,
      };
    default:
      return state;
  }
}
