import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import Button from "@material-ui/core/Button";

class Finance extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    const { match } = this.props;

    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="module.finance" />
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/finance/list" className="active">
              <i className="zmdi zmdi-money-box zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="module.finance.list" />
              </span>
            </NavLink>
          </li>
          {match.path.indexOf("list") === -1 && (
            <>
              <li className="menu no-arrow">
                <NavLink to="/app/finance/suppliers">
                  <i className="zmdi zmdi-truck zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="module.finance.suppliers" />
                  </span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/finance/account">
                  <i className="zmdi zmdi-filter-frames zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="module.finance.account" />
                  </span>
                </NavLink>
              </li>
              <li className="menu open">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="module.finance.report" />
                  </span>
                </Button>

                <ul className="sub-menu">
                  <li>
                    <NavLink to="/app/finance/report/expenses">
                      <span className="nav-text">Despesas</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/finance/report/extract">
                      <span className="nav-text">Extrato Caixa/Bancos</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/finance/report/cash-flow">
                      <span className="nav-text">Fluxo de caixa</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/finance/report/movement">
                      <span className="nav-text">Movimentação</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/finance/report/account-movement">
                      <span className="nav-text">Movimentação das contas</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/finance/report/revenues">
                      <span className="nav-text">Receitas</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* <li className="menu no-arrow">
            <NavLink to="/app/finance/resume">
              <i className="zmdi zmdi-view-week zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="module.finance.resume" />
              </span>
            </NavLink>
          </li> */}
              <li className="menu no-arrow">
                <NavLink to="/app/finance/transaction">
                  <i className="zmdi zmdi-swap zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="module.finance.transaction" />
                  </span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/finance/pattern-transaction">
                  <i className="zmdi zmdi-view-day zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="module.finance.pattern_transaction" />
                  </span>
                </NavLink>
              </li>
            </>
          )}
          <li className="menu no-arrow">
            <NavLink to="/app/dashboard">
              <i className="zmdi zmdi-undo zmdi-hc-fw" />
              <span className="nav-text">Sair do financeiro</span>
            </NavLink>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(Finance);
