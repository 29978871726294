import React, { useState } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DataTable from "components/Tables/DataTable";
import Api from "services/api";
import ViewQuestionnaire from "./ViewQuestionnaire";
import ViewLesion from "./ViewLesion";
import ViewLimbs from "./ViewLimbs";

export default function HistorySystemReview({ category, patient }) {
  const [record, setRecord] = useState([]);
  const [selected, setSelected] = useState("");
  const [mode, setMode] = useState("list");

  const getHistory = () => {
    Api.post(`/microservice/systemreview/getHistory/`, {
      id: category.id,
      patient
    }).then(res => {
      setRecord(res.data.record);
    });
  };

  return (
    <ExpansionPanel
      style={{ boxShadow: "none", width: "100%" }}
      onChange={(ev, open) => {
        if (open && record.length === 0) {
          getHistory();
        }
      }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        {category.description}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="w-100">
        <div className="w-100">
          {mode === "list" ? (
            <DataTable
              columns={["Profissional", "Especialidade", "Data", "Ações"]}
              rows={record.map(row => {
                return [
                  row.name,
                  row.specialty,
                  row.date,
                  [
                    <IconButton
                      onClick={() => {
                        setMode("view");
                        setSelected(row);
                      }}
                      color="primary"
                      aria-label="Visualizar"
                    >
                      <i className="zmdi zmdi-eye zmdi-hc-fw" />
                    </IconButton>
                  ]
                ];
              })}
            />
          ) : category.id === "15" ? (
            <ViewLesion
              record={selected}
              patient={patient}
              onClose={() => {
                setMode("list");
              }}
            />
          ) : category.id === "16" ? (
            <ViewLimbs
              record={selected}
              onClose={() => {
                setMode("list");
              }}
            />
          ) : (
            <ViewQuestionnaire
              record={selected}
              hasSubcategory={category.id === "13"}
              onClose={() => {
                setMode("list");
              }}
            />
          )}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
