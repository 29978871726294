import React, { useState } from "react";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan, BtnSave, Btn } from "components/Button";
import Checkbox from "@material-ui/core/Checkbox";

export default function CustomizePrint({ open, close, handleOptionsPrint }) {
  const formsDg = [
    {
      id: "1",
      label: "Identificação Do Familiar/Responsável",
    },
    {
      id: "2",
      label: "Identificação Do Idoso",
    },
    {
      id: "3",
      label: "Motivo Da Admissão",
    },
  ];
  const formsAa = [
    {
      id: "4",
      label: "Alergias",
    },
    {
      id: "5",
      label: "Atividades Basicas",
    },
    {
      id: "6",
      label: "Atividades instrumentais",
    },
    {
      id: "7",
      label: "Dados Vitais",
    },
    {
      id: "8",
      label: "Medicamentos em uso",
    },
    {
      id: "9",
      label: "Patologias",
    },
    {
      id: "10",
      label: "Principais Queixas",
    },
  ];

  const formsOs = [
    {
      id: "11",
      label: "Olfato",
    },
    {
      id: "12",
      label: "Olhos",
    },
    {
      id: "13",
      label: "Ouvidos",
    },
    {
      id: "14",
      label: "Paladar",
    },
  ];

  const formsRs = [
    {
      id: "15",
      label: "Aparelho Digestivo",
    },
    {
      id: "16",
      label: "Cardiovascular ",
    },
    {
      id: "17",
      label: "Cutâneo/Tegumentar",
    },
    {
      id: "18",
      label: "Gênito-Urinário",
    },
    {
      id: "19",
      label: "Nervoso",
    },
    {
      id: "20",
      label: "Osteomuscular",
    },
    {
      id: "21",
      label: "Respiratório",
    },
  ];

  const formsAf = [
    {
      id: "22",
      label: "Avaliação da Saude Mental",
    },
    {
      id: "23",
      label: "Avaliação de mobilidade",
    },
    {
      id: "24",
      label: "Avaliação Nutricional",
    },
    {
      id: "25",
      label: "Escala de Depressão Abreviada",
    },
  ];

  const formsHp = [
    {
      id: "26",
      label: "Atividade Física",
    },
    {
      id: "27",
      label: "Distúrbios do sono",
    },
    {
      id: "28",
      label: "Fraturas",
    },
    {
      id: "29",
      label: "Imunização",
    },
    {
      id: "30",
      label: "Quedas",
    },
    {
      id: "31",
      label: "Uso de drogas",
    },
  ];

  const formsHfp = [
    {
      id: "32",
      label: "Histórico Familiar Positivo",
    },
  ];

  const formsApf = [
    {
      id: "33",
      label: "Avaliação Psíquica E Sócio Familiar",
    },
  ];

  const [state, setState] = useState([]);

  const handlePrint = () => {
    handleOptionsPrint(state);
  };

  const resetState = () => {
    setState([]);
  };
  const renderOptionsPrint = (row) => {
    let optionsPrint = [];

    const jsx = (
      <div className="vp-options-print d-flex align-items-center col-6 mb-1">
        <Checkbox
          checked={state.indexOf(row.id) === -1}
          onChange={() => {
            if (state.indexOf(row.id) === -1) {
              setState([...state, row.id]);
            } else {
              setState(state.filter((l) => l !== row.id));
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <label className="mr-1">{row.label}</label>
      </div>
    );

    optionsPrint.push(jsx);
    return optionsPrint;
  };

  return (
    <div>
      <ModalResponsive
        title={"Customizar Impressão"}
        open={open}
        close={() => {
          resetState();
          close();
        }}
        maxWidth={"sm"}
        buttons={
          <div className="col-12">
            <>
              <BtnCan
                title="Fechar"
                onClick={() => {
                  resetState();
                  close();
                }}
                float="left"
              />

              <BtnSave
                title="Imprimir"
                onClick={() => {
                  handlePrint();
                  close();
                  resetState();
                }}
                float="right"
              />
            </>
          </div>
        }
      >
        <strong className="mb-2 w-100 d-flex justify-content-left">
          Dados Gerais:{" "}
          <Checkbox
            checked={
              state.indexOf("1") === -1 &&
              state.indexOf("2") === -1 &&
              state.indexOf("3") === -1
            }
            onChange={() => {
              let aux = state.filter(
                (row) => ["1", "2", "3"].indexOf(row) === -1
              );
              if (
                state.indexOf("1") === -1 &&
                state.indexOf("2") === -1 &&
                state.indexOf("3") === -1
              ) {
                setState([...aux, "1", "2", "3"]);
              } else {
                setState(aux);
              }
            }}
            color="primary"
            style={{
              marginTop: "-10px",
            }}
          />
        </strong>
        <div className="row">
          {formsDg.map((row) => renderOptionsPrint(row))}
        </div>

        <strong className="mb-2 w-100 d-flex justify-content-left">
          Avaliação Admissional:{" "}
          <Checkbox
            checked={
              state.indexOf("4") === -1 &&
              state.indexOf("5") === -1 &&
              state.indexOf("6") === -1 &&
              state.indexOf("7") === -1 &&
              state.indexOf("8") === -1 &&
              state.indexOf("9") === -1 &&
              state.indexOf("10") === -1
            }
            onChange={() => {
              let aux = state.filter(
                (row) =>
                  ["4", "5", "6", "7", "8", "9", "10"].indexOf(row) === -1
              );
              if (
                state.indexOf("4") === -1 &&
                state.indexOf("5") === -1 &&
                state.indexOf("6") === -1 &&
                state.indexOf("7") === -1 &&
                state.indexOf("8") === -1 &&
                state.indexOf("9") === -1 &&
                state.indexOf("10") === -1
              ) {
                setState([...aux, "4", "5", "6", "7", "8", "9", "10"]);
              } else {
                setState(aux);
              }
            }}
            color="primary"
            style={{
              marginTop: "-10px",
            }}
          />
        </strong>
        <div className="row">
          {formsAa.map((row) => renderOptionsPrint(row))}
        </div>

        <strong className="mb-2 w-100 d-flex justify-content-left">
          Órgãos Dos Sentidos:{" "}
          <Checkbox
            checked={
              state.indexOf("11") === -1 &&
              state.indexOf("12") === -1 &&
              state.indexOf("13") === -1 &&
              state.indexOf("14") === -1
            }
            onChange={() => {
              let aux = state.filter(
                (row) => ["11", "12", "13", "14"].indexOf(row) === -1
              );
              if (
                state.indexOf("11") === -1 &&
                state.indexOf("12") === -1 &&
                state.indexOf("13") === -1 &&
                state.indexOf("14") === -1
              ) {
                setState([...aux, "11", "12", "13", "14"]);
              } else {
                setState(aux);
              }
            }}
            color="primary"
            style={{
              marginTop: "-10px",
            }}
          />
        </strong>
        <div className="row">
          {formsOs.map((row) => renderOptionsPrint(row))}
        </div>

        <strong className="mb-2 w-100 d-flex justify-content-left">
          Revisão de Sistemas:{" "}
          <Checkbox
            checked={
              state.indexOf("15") === -1 &&
              state.indexOf("16") === -1 &&
              state.indexOf("17") === -1 &&
              state.indexOf("18") === -1 &&
              state.indexOf("19") === -1 &&
              state.indexOf("20") === -1 &&
              state.indexOf("21") === -1
            }
            onChange={() => {
              let aux = state.filter(
                (row) =>
                  ["15", "16", "17", "18", "19", "20", "21"].indexOf(row) === -1
              );
              if (
                state.indexOf("15") === -1 &&
                state.indexOf("16") === -1 &&
                state.indexOf("17") === -1 &&
                state.indexOf("18") === -1 &&
                state.indexOf("19") === -1 &&
                state.indexOf("20") === -1 &&
                state.indexOf("21") === -1
              ) {
                setState([...aux, "15", "16", "17", "18", "19", "20", "21"]);
              } else {
                setState(aux);
              }
            }}
            color="primary"
            style={{
              marginTop: "-10px",
            }}
          />
        </strong>
        <div className="row">
          {formsRs.map((row) => renderOptionsPrint(row))}
        </div>

        <strong className="mb-2 w-100 d-flex justify-content-left">
          Avaliação Funcional:{" "}
          <Checkbox
            checked={
              state.indexOf("22") === -1 &&
              state.indexOf("23") === -1 &&
              state.indexOf("24") === -1 &&
              state.indexOf("25") === -1
            }
            onChange={() => {
              let aux = state.filter(
                (row) => ["22", "23", "24", "25"].indexOf(row) === -1
              );
              if (
                state.indexOf("22") === -1 &&
                state.indexOf("23") === -1 &&
                state.indexOf("24") === -1 &&
                state.indexOf("25") === -1
              ) {
                setState([...aux, "22", "23", "24", "25"]);
              } else {
                setState(aux);
              }
            }}
            color="primary"
            style={{
              marginTop: "-10px",
            }}
          />
        </strong>
        <div className="row">
          {formsAf.map((row) => renderOptionsPrint(row))}
        </div>

        <strong className="mb-2 w-100 d-flex justify-content-left">
          História Pessoal Atual E Pregressa:{" "}
          <Checkbox
            checked={
              state.indexOf("26") === -1 &&
              state.indexOf("27") === -1 &&
              state.indexOf("28") === -1 &&
              state.indexOf("29") === -1 &&
              state.indexOf("30") === -1 &&
              state.indexOf("31") === -1
            }
            onChange={() => {
              let aux = state.filter(
                (row) =>
                  ["26", "27", "28", "29", "30", "31"].indexOf(row) === -1
              );
              if (
                state.indexOf("26") === -1 &&
                state.indexOf("27") === -1 &&
                state.indexOf("28") === -1 &&
                state.indexOf("29") === -1 &&
                state.indexOf("30") === -1 &&
                state.indexOf("31") === -1
              ) {
                setState([...aux, "26", "27", "28", "29", "30", "31"]);
              } else {
                setState(aux);
              }
            }}
            color="primary"
            style={{
              marginTop: "-10px",
            }}
          />
        </strong>
        <div className="row">
          {formsHp.map((row) => renderOptionsPrint(row))}
        </div>

        <strong className="mb-2 w-100 d-flex justify-content-left">
          Histórico Familiar Positivo:
        </strong>
        <div className="row">
          {formsHfp.map((row) => renderOptionsPrint(row))}
        </div>

        <strong className="mb-2 w-100 d-flex justify-content-left">
          Avaliação Psíquica E Sócio Familiar:
        </strong>
        <div className="row">
          {formsApf.map((row) => renderOptionsPrint(row))}
        </div>
      </ModalResponsive>
    </div>
  );
}
