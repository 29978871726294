import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as FormGroupActions from "actions/configuration/ProcedureGroups";
import TextField from "@material-ui/core/TextField";
import {
  Grid,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from "@material-ui/core";
import NoRecord from "components/Alert/NoRecord";

class TableExams extends Component {
  constructor(props) {
    super(props);
    this.deleteSelectedProcedure = this.deleteSelectedProcedure.bind(this);
    this.saveGroupMedicines = this.saveGroupMedicines.bind(this);
  }
  state = {};

  deleteSelectedProcedure = id => event => {
    this.props.delItemProcedure(id, event);
  };

  handleChangeObs = index => event => {
    this.props.changeObs(index, event.target.value);
  };

  saveGroupMedicines = () => {
    this.props.saveMedicinesGroups(
      this.props.doctor,
      this.props.MedicinesGroups.nameGroup,
      this.props.MedicinesGroups.descGroup,
      this.props.MedicinesGroups.listMedicinesSelected,
      this.props.MedicinesGroups.idEdit
    );
  };

  render() {
    const proceduresTableValue = this.props.ProcedureGroups
      .listProceduresSelected;
    return (
      <Grid item>
        {typeof proceduresTableValue !== "undefined" ? (
          <div className="flex-auto">
            <div className="table-responsive-material">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Procedimento</TableCell>
                    <TableCell>observações</TableCell>
                    <TableCell align="right">Excluir</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Tabela de medicamento escolhidos */}
                  {proceduresTableValue.length === 0 && (
                    <TableRow key="1">
                      <TableCell colSpan={3}>
                        <NoRecord />
                      </TableCell>
                    </TableRow>
                  )}
                  {proceduresTableValue.map((procedure, index) => {
                    return (
                      <TableRow key={procedure.id}>
                        <TableCell align="left">
                          {procedure.description}
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            placeholder="Observações"
                            onChange={this.handleChangeObs(index)}
                            value={procedure.obs}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Excluir">
                            <IconButton
                              arial-label="Excluir"
                              color="secondary"
                              onClick={this.deleteSelectedProcedure(
                                procedure.id
                              )}
                            >
                              <i className="zmdi zmdi-delete" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {/* <div className="col-md-12" style={{ marginTop: "20px" }}>
                  <Button
                    color="primary"
                    className="jr-btn jr-btn-sm bg-success"
                    onClick={this.saveGroupMedicines}
                    variant="contained"
                    style={{ float: "right" }}
                  >
                    <i className="zmdi zmdi-check zmdi-hc-lg" />
                    <span> Salvar </span>
                  </Button>
                </div> */}
              </Table>
            </div>
            {/* <div className="col-md-12" style={{ marginTop: "20px" }}>
              <Button
                color="secondary"
                variant="contained"
                className="jr-btn jr-btn-sm"
                onClick={() =>
                  this.props.handleAddFunction(
                    "view",
                    "primary",
                    "Adicionar",
                    "hidden",
                    "plus"
                  )
                }
                style={{ float: "left" }}
              >
                <i className="zmdi zmdi-close zmdi-hc-lg" />
                <span> Cancelar </span>
              </Button>
              <Button
                color="primary"
                className="jr-btn jr-btn-sm bg-success"
                onClick={this.saveGroupMedicines}
                variant="contained"
                style={{ float: "right" }}
              >
                <i className="zmdi zmdi-check zmdi-hc-lg" />
                <span> Salvar </span>
              </Button>
            </div> */}
          </div>
        ) : (
          ""
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  doctor: state.auth.authUser,
  ProcedureGroups: state.ProcedureGroups
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(FormGroupActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableExams);
