import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import NoRecord from "components/Alert/NoRecord";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Dialog,
  DialogContent
} from "@material-ui/core";
function Agreement({ data, addressId, hiddenButton }) {
  const [state, setState] = useState({
    id: "",
    name: "",
    beneficy: "",
    error: false
  });
  const [showForm, setShowForm] = useState(false);
  const [list, setList] = useState(data);
  const [modal_delete, setModalDelete] = useState(false);
  const [idDel, setIdDel] = useState("");
  const dispatch = useDispatch();
  const ComponentForm = () => {
    return (
      <>
        <div className="col-12">
          <TextField
            id="name"
            error={state.error && !state.name ? true : false}
            label="Nome:"
            value={state.name}
            margin="normal"
            fullWidth
            onChange={e => setState({ ...state, name: e.target.value })}
          />
        </div>
        <div className="col-12">
          <TextField
            id="beneficy"
            label="Beneficio:"
            value={state.beneficy}
            margin="normal"
            fullWidth
            onChange={e => setState({ ...state, beneficy: e.target.value })}
          />
        </div>
      </>
    );
  };
  function save() {
    if (state.name) {
      const obj = {
        ...state,
        addressId
      };
      Api.post(`/ms_system/workplace/saveAgreementsAddress/`, obj)
        .then(res => {
          const payload = {
            id: res.data.id,
            name: res.data.name,
            beneficy: res.data.beneficy
          };
          if (res.data.success) {
            const newList = list.filter(el => el.id !== payload.id);
            newList.push(payload);
            setList(newList);
            dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
            setTimeout(() => dispatch(closeAlertMessage()), 5000);
            setShowForm(false);
          } else {
            dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
            setTimeout(() => dispatch(closeAlertMessage(), 5000));
          }
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage(), 5000));
        });
    }
  }
  function deleteAgreement() {
    Api.post(`/ms_system/workplace/deleteAgreementsAddress/`, { id: idDel })
      .then(res => {
        const newList = list.filter(el => el.id !== idDel);
        setList(newList);
        dispatch(viewAlertMessage("success", "Removido com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
        setModalDelete(false);
        setIdDel("");
      })
      .catch(() => {
        setModalDelete(false);
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao remover!"));
        setTimeout(() => dispatch(closeAlertMessage(), 5000));
      });
  }
  return (
    <>
      <ExpansionPanel
        square
        style={{
          boxShadow: "10px 5px 5px white",
          borderBottom: "1px solid #d2d2d2"
          // marginTop: "10px"
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
        >
          Convênios
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: "block" }}>
          <div className="w-100">
            <Button
              hidden={hiddenButton}
              onClick={() => {
                setShowForm(true);
                setState({
                  id: "",
                  name: "",
                  beneficy: "",
                  error: false
                });
              }}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-plus" /> <span> Adicionar</span>
            </Button>
          </div>
          {list.length > 0 ? (
            <Table className="table-finances w-100">
              <TableHead>
                <TableRow>
                  <TableCell width="30%">Nome</TableCell>
                  <TableCell align="left" width="40%">
                    Beneficios
                  </TableCell>
                  <TableCell align="right" width="30%">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(row => (
                  <TableRow hover key={row.id}>
                    <TableCell component="th" scope="row" width="30%">
                      {row.name}
                    </TableCell>
                    <TableCell align="left" width="40%">
                      {row.beneficy}
                    </TableCell>
                    <TableCell width="30%" align="right" hidden={hiddenButton}>
                      <IconButton
                        onClick={() => {
                          setState({
                            id: row.id,
                            name: row.name,
                            beneficy: row.beneficy,
                            error: false
                          });
                          setShowForm(true);
                        }}
                        color="primary"
                        aria-label="Editar"
                      >
                        <i className="zmdi zmdi-edit zmdi-hc-fw" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setModalDelete(true);
                          setIdDel(row.id);
                        }}
                        color="secondary"
                        aria-label="Remover"
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoRecord />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={showForm}
        onClose={() => setShowForm(false)}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogContent>
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="text-primary font-weight-semibold">
                Convênios
                <Tooltip title="Fechar" placement="top">
                  <IconButton
                    aria-label="Fechar"
                    onClick={() => setShowForm(false)}
                    style={{ float: "right", marginTop: "-10px" }}
                  >
                    <i className="zmdi zmdi-close" />
                  </IconButton>
                </Tooltip>
              </h2>
            </div>
            {ComponentForm()}
            <div className="col-md-12 mt-3">
              <Button
                color="secondary"
                variant="contained"
                className="jr-btn jr-btn-sm"
                onClick={() => setShowForm(false)}
                style={{ float: "left" }}
              >
                <i className="zmdi zmdi-close zmdi-hc-lg" />
                <span> Cancelar </span>
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="jr-btn jr-btn-sm bg-success"
                onClick={() => save()}
                style={{ float: "right" }}
              >
                <i className="zmdi zmdi-check zmdi-hc-lg" />
                <span> Salvar </span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <SweetAlert
        show={modal_delete}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Deseja realmente excluir este plano de saúde?"
        onConfirm={() => deleteAgreement()}
        onCancel={() => setModalDelete(false)}
      />
    </>
  );
}
export default Agreement;
