import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Downshift from "downshift";
import Api from "services/api";
import ComponentsProfile from "components/ComponentsProfile";
import Chip from "@material-ui/core/Chip";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const styles = {
  container: {
    flexGrow: 1,
    // height: 150,
    width: "100%",
    "& .MuiPaper-elevation1": {
      display: "block",
    },
  },
  root: {
    backgroundColor: "#fff",
  },
  selected: {
    backgroundColor: "#e2e2e8 !important",
  },
};

class AutoCompleteProf extends Component {
  state = {
    suggestions: [],
  };

  getSuggestions = (e) => {
    if (e.target.value.length > 2) {
      Api.post(
        `/microservice/managementprofessional/getProfManagementAutocomplete`,
        {
          term: e.target.value,
        }
      ).then((res) => {
        let newList = res.data.list_prof;
        //.filter (
        //   (row) => this.props.listProf.indexOf(row.id) === -1
        // );
        this.setState({
          suggestions: newList.reverse(),
        });
      });
    }
  };

  renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    const { classes } = this.props;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
      >
        <ListItemIcon>
          <ComponentsProfile
            id={suggestion.id}
            nameProfile={suggestion.name}
            subTitle={suggestion.speciality}
            photo={suggestion.photo}
          />
          {suggestion.id === "new" && (
            <Chip
              className="m-1 ml-3"
              size="small"
              color="primary"
              icon={<PersonAddIcon />}
              label="Novo usuário"
            />
          )}
        </ListItemIcon>
      </MenuItem>
    );
  }

  renderInput(inputProps) {
    let { InputProps, classes, ref, ...other } = inputProps;
    return (
      <TextField
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...InputProps,
        }}
        onKeyUp={this.getSuggestions}
        onChange={this.handleChange}
      />
    );
  }

  render() {
    const { classes, handleChangeAutocomplete } = this.props;
    const { suggestions } = this.state;

    return (
      <Downshift
        onSelect={(selectedItem, obj) => {
          let item = suggestions.filter((row) => {
            return row.name === selectedItem;
          });
          handleChangeAutocomplete(item[0]);
          obj.setState({ inputValue: "" });
        }}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            {this.renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                id: "patient",
                value: inputValue,
              }),
              label: "Adicionar Profissional:",
              margin: "normal",
            })}
            {isOpen ? (
              <Paper square>
                {suggestions.map((suggestion, index) =>
                  this.renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.name,
                    }),
                    highlightedIndex,
                    selectedItem,
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}
export default withStyles(styles)(AutoCompleteProf);
