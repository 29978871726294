import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Choose = ({ match }) => {
  const provider_type = useSelector(
    ({ auth }) => auth.address_selected.provider_type
  );
  return (
    <div className="app-wrapper">
      <Switch>
        <Route
          path={`${match.url}/data-consult`}
          component={asyncComponent(() => import("./routes/dataConsult"))}
        />
        <Route
          path={`${match.url}/data-admission`}
          component={asyncComponent(() => import("./routes/dataAdmission"))}
        />
        <Route
          path={`${match.url}/triage`}
          component={asyncComponent(() => import("./routes/triage"))}
        />
        <Route
          path={`${match.url}/attendance`}
          component={asyncComponent(() => import("./routes/covid"))}
        />
        <Route
          path={`${match.url}/sessions`}
          component={asyncComponent(() => import("./routes/sessions"))}
        />
        {provider_type === "55" ? (
          <Route
            path={`${match.url}/home-care`}
            component={asyncComponent(() => import("./routes/homeCare"))}
          />
        ) : (
          <Redirect path={`${match.url}/home-care`} to={`/`} />
        )}
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Choose;
