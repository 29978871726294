import React from "react";
import TextField from "@material-ui/core/TextField";

// Example
// import InputControlLength from "components/TextFields/InputControlLength";
// <InputControlLength
//   multiline={true}
//   rows={4}
//   onChange={e => setState({ ...state, description: e.target.value })}
//   value={state.description}
//   label={record.id ? "Errata:" : "Descrição do plantão:"}
//   maxLength={200}
//   error={error}
// />

export default function InputControlLength({
  label,
  multiline,
  rows,
  value,
  onChange,
  maxLength,
  error,
  disabled,
}) {
  const [errorLenght, setErrorLenght] = React.useState(false);
  return (
    <TextField
      multiline={multiline}
      rows={rows}
      label={`${label} (${value.length}/${maxLength})`}
      value={value}
      onChange={onChange}
      onInput={(e) => {
        if (e.target.value.length >= maxLength) {
          e.target.value = e.target.value.substr(0, maxLength);
          setErrorLenght(true);
        }
      }}
      InputLabelProps={{
        shrink: true,
      }}
      margin="normal"
      fullWidth
      error={error || (errorLenght && value.length === maxLength)}
      helperText={
        errorLenght &&
        value.length === maxLength &&
        "Você atingiu o limite de caracteres para este campo!"
      }
      disabled={disabled}
    />
  );
}
