export const GET_PERSONAL_DATA = "GET_PERSONAL_DATA_CONSULT";

export function getPersonalData(patient, address) {
  return {
    type: GET_PERSONAL_DATA,
    patient,
    address
  };
}

export function handleChange(index, value) {
  return {
    type: "HANDLE_CHANGE",
    index,
    value
  };
}

export function handleChangeRecreation(value) {
  return {
    type: "HANDLE_CHANGE_RECREATION",
    value
  };
}

// Planos de saúde
export function addHealthPlan(health_plan) {
  return {
    type: "ADD_HEALTH_PLAN",
    health_plan
  };
}

export function editHealthPlan(health_plan) {
  return {
    type: "EDIT_HEALTH_PLAN",
    health_plan
  };
}

// Endereço pessoal
export function addPersonAddress(person_address) {
  return {
    type: "ADD_PERSON_ADDRESS_PD",
    person_address
  };
}

export function editPersonAddress(person_address) {
  return {
    type: "EDIT_PERSON_ADDRESS_PD",
    person_address
  };
}

// CONTATO DE EMERGENCIA
export function addEmergencyContact(contact) {
  return {
    type: "ADD_EMERGENCY_CONTACT_PD",
    contact
  };
}

export function editEmergencyContact(contact) {
  return {
    type: "EDIT_EMERGENCY_CONTACT_PD",
    contact
  };
}

// FILHOS
export function addChildren(children) {
  return {
    type: "ADD_CHILDREN",
    children
  };
}

export function editChildren(children) {
  return {
    type: "EDIT_CHILDREN",
    children
  };
}

//LEGADO
export function attLegacy(id, number) {
  return {
    type: "UPDATE_LEGACY",
    id,
    number
  };
}

export function handleLegacy(value) {
  return {
    type: "HANDLE_LEGACY",
    value
  };
}
