import React, { useState } from "react";
import { useSelector } from "react-redux";
import ModalImage from "../ModalImage";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import GetAppIcon from "@material-ui/icons/GetApp";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Icon_PDF from "../fileIcons/Icon_PDF.svg";
import Icon_DOCX from "../fileIcons/Icon_DOCX.svg";
import Icon_PPT from "../fileIcons/Icon_PPT.svg";
import Icon_XLS from "../fileIcons/Icon_XLS.svg";
import Icon_TXT from "../fileIcons/Icon_TXT.svg";
import Icon_RAR from "../fileIcons/Icon_RAR.svg";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  List,
  Slider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  secondary: {
    "&& .MuiListItemText-multiline": {
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  mChatItem: {
    padding: "6px 0"
  },
  list: {
    paddingTop: "0",
    paddingBottom: "0",
    "&& .MuiListItem-gutters": {
      paddingLeft: "0"
    },
    "&& .MuiListItem-root": {
      paddingTop: "0",
      paddingBottom: "0"
    }
  }
}));
const SentMessageCell = ({ conversation }) => {
  const openModalDiag = useSelector(({ chat }) => chat.chatModal);
  const photo = useSelector(({ auth }) => auth.photo);
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [aud, setAud] = useState(null);
  const [play, setplay] = useState(false);
  const [slideAudio, setSlideAudio] = useState(0);
  const toggleModal = () => {
    setModal(!modal);
  };

  const playPause = () => {
    if (aud.paused) {
      aud.play();
      setplay(true);
    } else {
      aud.pause();
      setplay(false);
    }
  };
  aud &&
    aud.addEventListener("ended", event => {
      setplay(false);
      setSlideAudio(0);
    });

  aud &&
    (aud.ontimeupdate = () => {
      setSlideAudio((aud.currentTime / aud.duration) * 100);
    });
  const changeCurrentTimeAudio = (event, newValue) => {
    aud.currentTime = newValue;
    setSlideAudio(newValue);
  };
  function formatSecondsAsTime(secs, format) {
    var hr = Math.floor(secs / 3600);
    var min = Math.floor((secs - hr * 3600) / 60);
    var sec = Math.floor(secs - hr * 3600 - min * 60);

    if (min < 10) {
      min = "0" + min;
    }
    if (sec < 10) {
      sec = "0" + sec;
    }

    return min + ":" + sec;
  }
  const getIconFile = ext => {
    switch (ext) {
      case "pdf":
        return <img alt="imagem do sistema" src={Icon_PDF} />;
      case "doc" || "docx":
        return <img alt="imagem do sistema" src={Icon_DOCX} />;
      case "ppt" || "pptx":
        return <img alt="imagem do sistema" src={Icon_PPT} />;
      case "xls" || "xlsx":
        return <img alt="imagem do sistema" src={Icon_XLS} />;
      case "txt":
        return <img alt="imagem do sistema" src={Icon_TXT} />;
      case "rar":
        return <img alt="imagem do sistema" src={Icon_RAR} />;
      default:
        return <InsertDriveFileIcon />;
    }
  };

  return (
    <div
      className={`d-flex flex-nowrap chat-item flex-row-reverse ${openModalDiag &&
        classes.mChatItem}`}
    >
      {!openModalDiag && (
        <img
          className="rounded-circle avatar size-40 align-self-end"
          src={photo}
          alt={conversation.name}
        />
      )}

      <div
        className={`bubble jambo-card ${openModalDiag &&
          "mr-2"} ${openModalDiag && conversation.type === "archive" && "p-0"}`}
      >
        <div className="message" style={{ maxWidth: "300px" }}>
          {conversation.type === "img" && (
            <>
              <img
                style={{ maxWidth: "250px" }}
                onClick={toggleModal}
                src={conversation.message}
              />
              <ModalImage
                open={modal}
                handleClose={toggleModal}
                img={conversation.message}
              />
            </>
          )}
          {(conversation.type === "text" || conversation.type === undefined) &&
            conversation.message}
          {conversation.type === "archive" && (
            <List
              className={`${classes.secondary} ${openModalDiag &&
                classes.list}`}
            >
              <ListItem>
                <ListItemAvatar>
                  {/* <Avatar> */}
                  {getIconFile(
                    conversation.message.split("/")[6].split(".")[1]
                  )}
                  {/* </Avatar> */}
                </ListItemAvatar>
                <ListItemText
                  primary={conversation.message.split("/")[6]}
                  secondary={conversation.sentAt}
                />
                <ListItemSecondaryAction style={{ right: "0" }}>
                  <IconButton>
                    <a
                      download
                      href={conversation.message}
                      style={{ color: "inherit" }}
                    >
                      <GetAppIcon />
                    </a>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          )}
          {conversation.type === "audio" && (
            <div className="d-flex" style={{ width: "250px" }}>
              <audio
                ref={ref => setAud(ref)}
                hidden
                controls="controls"
                src={conversation.message}
              ></audio>
              <div>
                <IconButton onClick={playPause}>
                  {!play ? <PlayArrowIcon /> : <PauseIcon />}
                </IconButton>
              </div>
              <div style={{ width: "100%" }}>
                <Slider
                  style={{ marginTop: "12px", paddingBottom: "0" }}
                  value={slideAudio}
                  onChange={changeCurrentTimeAudio}
                  aria-labelledby="continuous-slider"
                />
                {aud && aud.duration !== Infinity && !isNaN(aud.duration) ? (
                  <span>{`${formatSecondsAsTime(
                    aud.currentTime
                  )}/${formatSecondsAsTime(aud.duration)}`}</span>
                ) : (
                  <span>00:00</span>
                )}
              </div>
            </div>
          )}
        </div>
        {conversation.type !== "archive" && (
          <div className="time text-muted text-right mt-2">
            {conversation.sentAt}
          </div>
        )}
      </div>
    </div>
  );
};

export default SentMessageCell;
