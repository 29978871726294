import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import LayoutPrint from "./routes/LayoutPrint";
import Api from "services/api";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { listStyleFormPrint } from "actions/Print";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditComponentPrint from "./routes/EditComponentPrint";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class FormPrint extends Component {
  state = {
    value: 0,
    print: null,
    listStyle: {},
    textPrint: "",
    latlon: null,
    edit: false,
  };

  handlelistStyle = (list) => {
    let formData = {
      ...this.props.listFormPrint,
      ...list,
    };
    this.props.listStyleFormPrint(formData);
  };

  saveFormPrint = () => {
    let formData = {
      list: this.props.listFormPrint,
      address: this.props.addressId,
      authUser: this.props.auth.authUser,
      secretary: this.props.auth.secretary,
    };

    Api.post(`/microservice/formprint/saveFormPrint/`, formData)
      .then((res) => {
        this.setState({ print: res.data.getPrint });
        this.props.updateListWorkPlace(res.data.listFromPrint);
        this.props.closeModal();
        this.props.viewAlertMessage("success", "Salvo com sucesso!");
        setTimeout(() => this.props.closeAlertMessage(), 5000);
      })
      .catch(() => {});
  };

  handleEditPrint = (textPrint) => {
    this.setState({ ...this.state, edit: true });
    setTimeout(
      () =>
        this.setState({
          ...this.state,
          textPrint,
        }),
      500
    );
  };

  setEditListFormPrint = (editText) => {
    let formData = {
      ...this.props.listFormPrint,
      [`${editText.textPrint}`]: editText.value,
    };
    this.props.listStyleFormPrint(formData);
  };

  // testeGetMaps = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       this.setState({
  //         latlon: position.coords.latitude + "," + position.coords.longitude
  //       });
  //     });
  //   }
  // };
  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        {/* 
        <button onClick={this.testeGetMaps}>get</button>
        <img
          src={`https://maps.googleapis.com/maps/api/staticmap?center=
          "+${this.state.latlon}+"&zoom=14&size=400x300&sensor=false&key=AIzaSyCx-skiDq9oPsf6-Ekhgv0nvnzAllOnJ50`}
        /> */}

        <Dialog
          fullScreen
          fullWidth={true}
          open={this.props.openModalPrint}
          aria-labelledby="max-width-dialog-title"
          className="p-0 m-0"
        >
          <AppBar>
            <div className="col-12 col-md-12">
              <Toolbar>
                <div className="col-2 col-md-2">
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={this.props.handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="col-8 col-md-8 d-flex justify-content-center">
                  <div className="d-block">
                    <Typography
                      className="d-flex justify-content-center"
                      variant="h6"
                      style={{ color: "#ffffff" }}
                    >
                      Formulario Impressão
                    </Typography>
                    <Typography
                      className="d-flex justify-content-center"
                      style={{
                        lineHeight: "20px",
                        fontWeight: "400",
                        textAlign: "center",
                        margin: "0px",
                      }}
                      variant="overline"
                      display="block"
                      gutterBottom
                    >
                      <LocationOnIcon fontSize="small" />
                      {this.props.nameLocalAttendance}
                    </Typography>
                  </div>
                </div>
                {!this.props.assoc ? (
                  <div className="col-2 col-md-2 d-flex justify-content-end">
                    <Button color="inherit" onClick={this.saveFormPrint}>
                      Salvar
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </Toolbar>
            </div>
          </AppBar>

          <DialogContent>
            <div className="d-flex col-12">
              {this.props.listFormPrint !== null && (
                <>
                  <div
                    className={
                      !this.props.assoc && this.state.edit
                        ? "col-5 d-flex justify-content-center"
                        : "col-12 d-flex justify-content-center"
                    }
                    style={{
                      margin: "-150px !important",
                    }}
                  >
                    <LayoutPrint
                      print={this.props.listFormPrint}
                      listStyle={this.handlelistStyle}
                      setEditPrint={this.handleEditPrint}
                    />
                  </div>
                  {!this.props.assoc && this.state.edit ? (
                    <div className="col-7 mt-5 pr-0 pl-0">
                      <EditComponentPrint
                        authUser={this.props.auth.authUser}
                        textPrint={this.state.textPrint}
                        setEditListFormPrint={this.setEditListFormPrint}
                        teste={this.props.listFormPrint}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

FormPrint.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    patient: state.appointment.patient,
    auth: state.auth,
    listFormPrint: state.print.listFormPrint,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { viewAlertMessage, closeAlertMessage, listStyleFormPrint },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FormPrint));
