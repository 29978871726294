export const ALLERGIES = "1";

export const DYSFUNCTIONS = "2";

export const CHILD_DISEASE = "3";

export const FAMILY_HISTORY = "4";

export const HABITS = "5";

export const MEDICINES = "6";

export const PERSONAL_HISTORY = "7";

export const PROCEDURES = "8";

export const OBSTETRIC = "9";

export const ANAMNESIS_ARRAY = [
  { id: ALLERGIES, link: "allergies", description: "Alergias" },
  { id: DYSFUNCTIONS, link: "dysfunctions", description: "Disfunções" },
  {
    id: CHILD_DISEASE,
    link: "child-disease",
    description: "Doenças da infância",
  },
  {
    id: FAMILY_HISTORY,
    link: "family-history",
    description: "Histórico Familiar",
  },
  { id: HABITS, link: "habits", description: "Hábitos" },
  { id: MEDICINES, link: "medicines", description: "Medicamentos" },
  { id: PERSONAL_HISTORY, link: "personal-history", description: "Patologias" },
  { id: PROCEDURES, link: "procedures", description: "Procedimentos" },
  { id: OBSTETRIC, link: "obstetric", description: "Antecedentes Obstétricos" },
];
