import React, { useState } from "react";
import { useSelector } from "react-redux";
import WorkplaceProfessional from "./professional";
import WorkplaceProvider from "./provider";
import FormWorkplace from "./FormWorkplace";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import WorkplaceIlpi from "./workplaceIlpi";

function Workplace({ match, history }) {
  const type = useSelector((state) => state.auth.type);
  const provider_type = useSelector((state) => state.auth.provider_type);
  const [modal_form, setModalForm] = useState(false);
  const [form, setForm] = useState("");
  const [workplace_selected, setWorkplaceSelected] = useState("");
  const user = useSelector((state) => state.auth.authUser);
  const secretary = useSelector((state) => state.auth.secretary);
  const user_manage = useSelector((state) => state.auth.user_manage);
  return (
    <div className="app-wrapper">
      <div className="animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          title={<IntlMessages id="module.workplace" />}
          match={match}
        />

        <div className="row">
          <div className="col-md-12 mb-3">
            <Button
              onClick={() => {
                setWorkplaceSelected("");
                setForm("address");
                setModalForm(true);
              }}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-plus" /> <span> Novo local</span>
              {/* <i className="zmdi zmdi-plus" />{" "}
              <span> Nova Unidade/Franquia</span> */}
            </Button>
          </div>
        </div>

        {!secretary ? (
          type === "0" ? (
            <WorkplaceProfessional
              user={user}
              userAuth={user}
              type={secretary ? user_manage.type : type}
              provider_type={
                secretary ? user_manage.provider_type : provider_type
              }
            />
          ) : (
            <WorkplaceProvider
              user={user}
              userAuth={user}
              type={secretary ? user_manage.type : type}
              provider_type={
                secretary ? user_manage.provider_type : provider_type
              }
            />
          )
        ) : user_manage.type === "0" ? (
          <WorkplaceProfessional
            user={user_manage.id}
            userAuth={user}
            type={secretary ? user_manage.type : type}
            provider_type={
              secretary ? user_manage.provider_type : provider_type
            }
          />
        ) : (
          <WorkplaceProvider
            user={user_manage.id}
            userAuth={user}
            type={secretary ? user_manage.type : type}
            provider_type={
              secretary ? user_manage.provider_type : provider_type
            }
          />
        )}
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={modal_form}
        onClose={() => {
          setModalForm(false);
        }}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogContent>
          {form === "address" && (
            <FormWorkplace
              user={!secretary ? user : user_manage.id}
              userAuth={secretary ? user : user_manage.id}
              type={secretary ? user_manage.type : type}
              provider_type={
                secretary ? user_manage.provider_type : provider_type
              }
              addressId={workplace_selected}
              close={() => setModalForm(false)}
            />
          )}

          {/* <WorkplaceIlpi
            user={!secretary ? user : user_manage.id}
            authUser={user}
            provider_type={
              secretary ? user_manage.provider_type : provider_type
            }
            close={() => setModalForm(false)}
          /> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Workplace;
