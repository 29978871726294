import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeAddress } from "actions/Auth";
import {
  setAppointment,
  setDataAppointment,
  setContinue,
  getAppointmentActions,
} from "actions/consult/Appointment";
import { getInfoPatient } from "actions/consult/PatientInfo";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  TextField,
  MenuItem,
  Zoom,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PlaceIcon from "@material-ui/icons/Place";
import api from "services/api";
import { useHistory } from "react-router-dom";
import AutocompletePatient from "./AutocompletePatient";
import AutocompleteOnlyPatientsDoctor from "./AutocompleteOnlyPatientsDoctor";
import RegisterUser from "components/Forms/RegisterUser";

export default function DataProfessional({ url, onClose }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal_continue, setModalContinue] = useState(false);
  const [id_appointment, setIdAppointment] = useState(null);
  // const [pp_start, setPpStart] = useState(null);
  const [id_user, setIdUser] = useState(null);
  const [new_user, setNewUser] = useState(false);
  const [userName, setUserName] = useState("");
  const [modal_add_user, setModalAddUser] = useState(false);
  const [title_add_user, setTitleAddUser] = useState("");

  const auth = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);
  const list_address = useSelector((state) => state.auth.list_address);
  const address_selected = useSelector((state) => state.auth.address_selected);

  const handleSelected = (selected) => {
    if (selected.id === "new") {
      //PARA CADASTRAR UM NOVO PACINTE NO PORTAL E ASSOCIÁ-LO A INTRANET
      setNewUser(true);
      setUserName(selected.name);
    } else if (selected.id < 0) {
      //PARA INCLUIR UM PACINTE JA EXISTENTE AO GRUPO DE INTRANET
      setIdUser(selected.id.substr(1));
      setTitleAddUser(
        "Deseja adicionar o(a) paciente " +
          selected.name +
          " em seu grupo de pacientes?"
      );
      setModalAddUser(true);
    } else {
      //PARA ATENDER UM PACIENTE JA INCLUSO NO GRUPO DA INTRANET
      api
        .post(`/microservice/consult/getLastAppointment/`, {
          patient: selected.id,
          doctor: auth,
        })
        .then((res) => {
          if (res.data.finished !== "1" && !res.data.notFound) {
            setModalContinue(true);
            setIdAppointment(res.data.id);
            setIdUser(selected.id);
            dispatch(getInfoPatient(selected.id));
            dispatch(setDataAppointment(selected.id, ""));
          } else {
            dispatch(setDataAppointment(selected.id, ""));
            dispatch(setContinue(false));
            dispatch(getInfoPatient(selected.id));
            history.push(url);
            onClose();
          }
        });
    }
  };

  const finishPlay = () => {
    api
      .post(`/microservice/consult/finishAppointment/`, {
        appointment: id_appointment,
      })
      .then((res) => {
        if (res.data.success) {
          dispatch(setDataAppointment(id_user, ""));
          dispatch(setContinue(false));
          setModalContinue(false);
          history.push("/app/consult/consultation-summary");
        }
      });
  };

  const handleContinue = () => {
    dispatch(setAppointment(id_appointment));
    dispatch(setContinue(true));
    dispatch(setDataAppointment(id_user, ""));
    dispatch(getAppointmentActions(id_appointment));
    onClose();
    history.push(url);
  };

  const addUserIntranet = () => {
    dispatch({
      type: "INSERT_GROUP_INTRANET",
      payload: { patient: id_user, address, auth },
    });
    dispatch(setDataAppointment(id_user, ""));
    onClose();
    history.push(url);
  };

  return (
    <>
      <div className="row">
        <div style={{ margin: "15px 20px" }}>
          <PlaceIcon color="error" fontSize="large" />
        </div>
        <div className="col-md-8 col-md-offset-1">
          <TextField
            select
            value={address}
            onChange={(e) => dispatch(changeAddress(e.target.value))}
            fullWidth
            SelectProps={{}}
            margin="normal"
            label="Local de atendimento"
          >
            {list_address.map((row) => (
              <MenuItem key={row.id} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </TextField>
          <Zoom in={address !== ""}>
            <div className="mt-15">
              {address_selected.provider_type !== "5" &&
              address_selected.provider_type !== "55" ? (
                <AutocompletePatient
                  address={address}
                  handleChangeAutocomplete={(selected) =>
                    handleSelected(selected)
                  }
                  doctor={auth}
                />
              ) : (
                <AutocompleteOnlyPatientsDoctor
                  address={address}
                  handleChangeAutocomplete={(selected) =>
                    handleSelected(selected)
                  }
                  doctor={auth}
                />
              )}
            </div>
          </Zoom>
        </div>
      </div>
      <RegisterUser
        add={(id, name) => {
          let selected = { id, name };
          handleSelected(selected);
          setNewUser(false);
        }}
        nameUser={userName}
        open={new_user}
        close={() => setNewUser(false)}
        address={address}
      />
      <Dialog open={modal_continue} onClose={() => setModalContinue(false)}>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <ErrorOutlineIcon fontSize="large" color="error" />
              </div>
              <h3 className="text-center">
                Você ja possui um atendimento em aberto com este paciente,
                deseja continuar?
              </h3>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="col-4">
            <Button
              fullWidth
              onClick={() => setModalContinue(false)}
              className="jr-btn jr-btn-sm"
              color="secondary"
              variant="contained"
              style={{ float: "left" }}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span>Cancelar</span>
            </Button>
          </div>
          <div className="col-4">
            <Button
              fullWidth
              onClick={finishPlay}
              className="jr-btn jr-btn-sm"
              color="default"
              variant="contained"
              style={{ float: "left" }}
            >
              <i className="zmdi zmdi-skip-next zmdi-hc-lg" />
              <span>Finalizar e Iniciar</span>
            </Button>
          </div>
          <div className="col-4">
            <Button
              fullWidth
              onClick={handleContinue}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
              style={{ float: "left" }}
            >
              <i className="zmdi zmdi-play zmdi-hc-lg" />
              <span>Continuar</span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <SweetAlert
        show={modal_add_user}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim"
        confirmButtonColor="#bc0003"
        cancelBtnText="Não"
        title={title_add_user}
        onConfirm={addUserIntranet}
        onCancel={() => setModalAddUser(false)}
      />
    </>
  );
}
