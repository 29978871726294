import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { TextField, MenuItem } from "@material-ui/core";
import PersonalHygiene from "./PersonalHygiene";
import OralDiet from "./OralDiet";
import EnteralDiet from "./EnteralDiet";
import Hydratation from "./Hydratation";
import Curative from "./Curative";
import Decubits from "./Decubits";
import VitalSigns from "./VitalSings";
import Points from "./Points";
import SondaVesical from "./SondaVesical";
import ElevatedHeadboard from "./ElevatedHeadboard";
import OxygenTherapy from "./OxygenTherapy";
import Moviment from "./Moviment";
import InflatableLeggings from "./InflatableLeggings";
import PainScale from "./PainScale";
import FallRisk from "./FallRisk";

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down("md")]: {
      padding: "0.5rem !important",
    },
  },
}));

/**
 * Componentes de formulário dos cuiados
 * @param {int} typeCare obrigatório tipo do cuidado
 * @param {Function} setTypeCare obrigatório função que altera o tipo do cuidado
 * @param {Object} state obrigatório estado doscuiados
 * @param {Function} setState obrigatório Função que seta o estado
 * @param {Boolean} error obrigatório retorno da função de validação
 * @param {Boolean} pattern [opcional] é uma prescrição padrão
 */
export default function CareForms({
  typeCare,
  setTypeCare,
  state,
  setState,
  error,
  pattern = false,
}) {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div className="col-12 p-0">
        <TextField
          onChange={(e) => setTypeCare(e.target.value)}
          fullWidth
          select
          value={typeCare}
          label={"Tipo do Cuidado"}
        >
          {/* <MenuItem value="15">Avaliar Risco de queda</MenuItem> */}
          <MenuItem value="8">Aferições Gerais</MenuItem>
          <MenuItem value="2">Cabeceira Elevada</MenuItem>
          {!pattern && <MenuItem value="4">Curativo</MenuItem>}
          {!pattern && <MenuItem value="6">Dieta - Enteral</MenuItem>}
          <MenuItem value="5">Dieta - Oral</MenuItem>
          <MenuItem value="12">Estimular Movimentação</MenuItem>
          <MenuItem value="7">Hidratação</MenuItem>
          <MenuItem value="1">Higiene Pessoal</MenuItem>
          <MenuItem value="3">
            Mudança de Decubito - Relógio de Mudança
          </MenuItem>
          {!pattern && <MenuItem value="11">Oxigenioterapia</MenuItem>}
          {!pattern && (
            <MenuItem value="10">Passagem de Sonda Vesical</MenuItem>
          )}
          {!pattern && <MenuItem value="13">Perneira Inflável</MenuItem>}
          {/* <MenuItem value="14">Registrar escala de dor</MenuItem> */}
          {!pattern && <MenuItem value="9">Retirada de Pontos</MenuItem>}
        </TextField>
      </div>
      {typeCare === "1" && (
        <PersonalHygiene
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "2" && (
        <ElevatedHeadboard
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "3" && (
        <Decubits
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "4" && (
        <Curative
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "5" && (
        <OralDiet
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "6" && (
        <EnteralDiet
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "7" && (
        <Hydratation
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "8" && (
        <VitalSigns
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "9" && (
        <Points
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "10" && (
        <SondaVesical
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "11" && (
        <OxygenTherapy
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "12" && (
        <Moviment
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "13" && (
        <InflatableLeggings
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "14" && (
        <PainScale
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
      {typeCare === "15" && (
        <FallRisk
          type={typeCare}
          error={error}
          state={state}
          setState={(e) => setState(e)}
          pattern={pattern}
        />
      )}
    </div>
  );
}
