const INITIAL_STATE = {
  schedule: "",
  patient: "",
  appointment: "",
};

export default function monitoring(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_MONITORING":
      return INITIAL_STATE;

    case "SET_DATA_MONITORING":
      return {
        ...state,
        schedule: action.payload.schedule,
        patient: action.payload.patient,
        appointment: action.payload.appointment,
      };
    case "SET_MONITORING_APPOINTMENT":
      return {
        ...state,
        appointment: action.payload.appointment,
      };
    default:
      return state;
  }
}
