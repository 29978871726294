import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ListForward from "./ListForward";

const styles = theme => ({});

class index extends Component {
  render() {
    const { patient } = this.props;
    return (
      <div className="container-person border">
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400"
          }}
        >
          Encaminhamento deste paciente
        </h3>
        <ListForward patient={patient} />
      </div>
    );
  }
}

index.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(index);
