import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Portal from "@material-ui/core/Portal";

const AlertMessage = ({ open, message, type = "success" }) => {
  const bg_type = type === "success" ? "bg-success" : "bg-danger";
  const icon = type === "success" ? "zmdi-check" : "zmdi-alert-triangle";
  return (
    <Portal>
      <Snackbar
        style={{ zIndex: "1400" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
      >
        <SnackbarContent
          style={{ zIndex: "1400" }}
          className={`mb-3 ${bg_type}`}
          message={
            <span style={{ fontSize: "18px" }}>
              <i className={`zmdi ${icon}`} />
              {`   ${message}`}
            </span>
          }
        />
      </Snackbar>
    </Portal>
  );
};

export default AlertMessage;
