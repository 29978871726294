import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import api from "services/api";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChatUserList from "components/chatPanel/ChatUserList";
import { selectedUserChat, setSelectedChat } from "actions/comunication/Chat";
import ContactList from "components/chatPanel/ContactList/index";
import SearchBox from "components/SearchBox/index";
import IntlMessages from "util/IntlMessages";
import BarProgress from "components/CircularProgress/ProgressBar";
import CustomScrollbars from "util/CustomScrollbars";
import { Btn } from "components/Button";
import ModalAddContact from "../contacts/ModalAdd";
import { getUrlPhoto } from "services/functions";

export default function ChatUsers({
  closeDrawer,
  setUsersMobile,
  toggleUserState,
}) {
  const [state, setState] = useState({
    userNotFound: "Contato não encontrado",
    selectedSectionId: "",
    selectedTabIndex: 0,
    searchChatUser: "",
  });
  const dispatch = useDispatch();
  const width = useSelector(({ settings }) => settings.width);
  const selectedChat = useSelector(({ chat }) => chat.selectedChat);
  const userLoged = useSelector(({ auth }) => auth);
  const [contactList, setContactList] = useState([]);
  const [chatUsers, setChatUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [chatIds, setChatIds] = useState([]);
  const [loader, setLoader] = useState(true);
  const [modalAdd, setModalAdd] = useState(false);
  console.log("users", users);

  useEffect(() => {
    api
      .post(`/ms_comunication/chat/getContactList/`, {
        user: userLoged.authUser,
      })
      .then(({ data }) => {
        setContactList(
          data.map((row) => ({ ...row, thumb: getUrlPhoto(row.id, row.thumb) }))
        );
      });
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/`)
      .once("value", (snap) => {
        setChatIds(Object.keys(snap.val() ? snap.val() : []));
      });
  }, []);
  getDataFirebase().on("child_changed", (snap) => {
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/`)
      .once("value", (snap) => {
        setChatIds(Object.keys(snap.val() ? snap.val() : []));
      });
  });
  useEffect(() => {
    if (chatIds.length > 0) {
      api
        .post(`/ms_comunication/chat/getChats/`, {
          user: userLoged.authUser,
          ids: chatIds.join(","),
        })
        .then(({ data }) => {
          setChatUsers(
            data.map((row) => {
              let lastMessage;
              getDataFirebase()
                .child(`chats${getEnviromentFirebase()}/` + row.chat_id)
                .limitToLast(1)
                .once("value", (snap) => {
                  lastMessage = Object.values(snap.val())[0];
                });
              return {
                ...row,
                thumb: getUrlPhoto(row.id, row.thumb),
                lastMessage,
              };
            })
          );
          setUsers(
            data.map((row) => {
              let lastMessage;
              getDataFirebase()
                .child(`chats${getEnviromentFirebase()}/` + row.chat_id)
                .limitToLast(1)
                .once("value", (snap) => {
                  lastMessage = Object.values(snap.val())[0];
                });
              return {
                ...row,
                thumb: getUrlPhoto(row.id, row.thumb),
                lastMessage,
              };
            })
          );
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  }, [chatIds]);

  useEffect(() => {
    if (width < 900 && users.length > 0) {
      setUsersMobile(users);
    }
  }, [users]);

  const handleChange = (event, value) => {
    setState({ ...state, selectedTabIndex: value });
  };

  const handleChangeIndex = (index) => {
    setState({ ...state, selectedTabIndex: index });
  };

  const filterContact = (userName) => {
    if (userName === "") {
      return users;
    }
    return users.filter(
      (user) => user.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
    );
  };
  const filterUsers = (userName) => {
    if (userName === "") {
      return users;
    }
    return users.filter(
      (user) => user.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
    );
  };
  const onSelectUser = (user) => {
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/` + selectedChat)
      .off();
    setState({ ...state, selectedSectionId: user.id });
    dispatch(setSelectedChat(user.chat_id));
    dispatch(selectedUserChat(user));
    closeDrawer();
  };
  return (
    <div className="chat-sidenav-main">
      <div className="chat-sidenav-header">
        <div className="chat-user-hd">
          <div className="chat-avatar mr-3" onClick={() => toggleUserState()}>
            <div className="chat-avatar-mode">
              <img
                id="user-avatar-button"
                src={userLoged.photo}
                className="rounded-circle size-50"
                alt=""
              />
              {/* <span className="chat-mode online" /> */}
            </div>
          </div>

          <div className="module-user-info d-flex flex-column justify-content-center">
            <div className="module-title">
              <h5 className="mb-0">{userLoged.name}</h5>
            </div>
            {/* <div className="module-user-detail">
              <span className="jr-link text-grey">robert@example.com</span>
            </div> */}
          </div>
        </div>

        <div className="search-wrapper">
          <SearchBox
            placeholder="Pesquisar..."
            onChange={(e) => {
              setState({ ...state, searchChatUser: e.target.value });
              setContactList(filterContact(e.target.value));
              setChatUsers(filterUsers(e.target.value));
            }}
            value={state.searchChatUser}
          />
        </div>
      </div>
      <div className="chat-sidenav-content">
        {loader ? (
          <div
            className="loader-view w-100"
            style={{ height: "calc(100vh - 120px)" }}
          >
            <BarProgress />
          </div>
        ) : (
          <>
            <AppBar position="static" className="no-shadow chat-tabs-header">
              <Tabs
                className="chat-tabs"
                value={state.selectedTabIndex}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label={<IntlMessages id="chat.chatUser" />} />
                <Tab label={<IntlMessages id="chat.contacts" />} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              index={state.selectedTabIndex}
              onChangeIndex={handleChangeIndex}
            >
              <CustomScrollbars
                className="chat-sidenav-scroll scrollbar"
                style={{
                  height:
                    width >= 1200
                      ? "calc(100vh - 328px)"
                      : "calc(100vh - 202px)",
                }}
              >
                {chatUsers.length === 0 ? (
                  <div className="p-5">{state.userNotFound}</div>
                ) : (
                  <ChatUserList
                    chatUsers={chatUsers}
                    selectedSectionId={state.selectedSectionId}
                    onSelectUser={onSelectUser}
                  />
                )}
              </CustomScrollbars>

              <CustomScrollbars
                className="chat-sidenav-scroll scrollbar"
                style={{
                  height:
                    width >= 1200
                      ? "calc(100vh - 328px)"
                      : "calc(100vh - 202px)",
                }}
              >
                {contactList.length === 0 ? (
                  <div className="p-5">
                    <Btn
                      name="Adicionar contato"
                      title="novo contato"
                      icon="zmdi zmdi-plus"
                      onClick={() => setModalAdd(!modalAdd)}
                    />
                    <ModalAddContact
                      open={modalAdd}
                      handleClose={() => setModalAdd(!modalAdd)}
                      handleSuccess={(e) => {}}
                    />
                  </div>
                ) : (
                  <ContactList
                    contactList={contactList}
                    selectedSectionId={state.selectedSectionId}
                    onSelectUser={onSelectUser}
                  />
                )}
              </CustomScrollbars>
            </SwipeableViews>
          </>
        )}
      </div>
    </div>
  );
}
