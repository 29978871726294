import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";

import { IconButton } from "@material-ui/core";
import Table from "components/Tables/DataTable";
import ViewIcon from "@material-ui/icons/Visibility";
import { Btn } from "components/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "components/CircularProgress";
import moment from "moment";

export default function Monitoring({ patient }) {
  const dispatch = useDispatch();
  const [record, setRecord] = useState([]);
  const [schedulePatient, setSchedulePatient] = useState([]);
  const [historicDay, setHistoricDay] = useState([]);
  const [view, setView] = useState(0);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    patient && getMonitoring();
  }, [patient]);

  function getMonitoring() {
    Api.post("/ms_system/notificationcovid/getListMonitoringIdPati", {
      patient,
    })
      .then((res) => {
        setRecord(res.data.record);
        setLoader(false);
      })
      .catch(() => {
        setRecord([]);
        setLoader(false);
        showMsg("error", "Não foi possível realizar a busca", dispatch);
      });
  }

  function getListMonitoringScheduleId(id) {
    Api.post("/ms_system/notificationcovid/getSchedulePatientMonit", { id })
      .then((res) => {
        setSchedulePatient(res.data.record);
      })
      .catch(() => {
        setSchedulePatient([]);
        showMsg("error", "Não foi possível realizar a busca", dispatch);
      });
  }

  function getHistoryMonit(appointment) {
    Api.post("/ms_system/notificationcovid/getHistoryMonit", {
      appointment,
      patient,
    })
      .then((res) => {
        setHistoricDay(res.data);
      })
      .catch(() => {
        setHistoricDay([]);
        showMsg("error", "Não foi possível realizar a busca", dispatch);
      });
  }

  if (loader) {
    return <CircularProgress />;
  }

  return (
    <div className="container-person border">
      <h3
        className="title-container d-flex justify-content-center"
        style={{
          fontSize: "26px",
          fontWeight: "400",
        }}
      >
        Monitoramento
      </h3>

      {view === 0 && (
        <Table
          columns={["Intervalo", "Duração", "Início", "Término", "Status", ""]}
          rows={record.map((row) => [
            row.interval,
            row.duration,
            row.start,
            row.end,
            row.closing === "1"
              ? "Encerrado"
              : moment(row.end).isAfter(moment().format("YYYY-MM-DD"))
              ? "Em andamento"
              : "Concluido",
            <IconButton
              onClick={() => {
                getListMonitoringScheduleId(row.id);
                setView(1);
              }}
              color="primary"
            >
              <ViewIcon color="primary" />
            </IconButton>,
          ])}
        />
      )}
      {view === 1 && (
        <Table
          columns={["Data", "Status", "Motivo Encerramento", "Ações"]}
          rows={schedulePatient.map((row) => [
            moment(row.date).format("DD/MM/YYYY"),
            row.closing === "1"
              ? "Encerrado"
              : moment(row.date).isAfter(moment().format("YYYY-MM-DD"))
              ? "Em andamento"
              : "Concluido",
            row.description ? row.description : "--",
            row.fk_ls_pp_appointment ? (
              <IconButton
                onClick={() => {
                  getHistoryMonit(row.fk_ls_pp_appointment);
                  setView(2);
                }}
                color="primary"
              >
                <ViewIcon color="primary" />
              </IconButton>
            ) : (
              "Nenhum Registro"
            ),
          ])}
        />
      )}
      {/* <Table
          columns={["Date Monitoramento", "Visualizar"]}
          rows={schedulePatient.map((row) => [
            moment(row.date).format("DD/MM/YYYY"),
            row.fk_ls_pp_appointment ? (
              <IconButton
                onClick={() => {
                  getHistoryMonit(row.fk_ls_pp_appointment);
                  setView(2);
                }}
                color="primary"
              >
                <ViewIcon color="primary" />
              </IconButton>
            ) : (
              ""
            ),
          ])}
        /> */}
      {view === 2 && (
        <div>
          <Typography variant="h6" gutterBottom>
            Registro do Atendimento
          </Typography>
          <Typography variant="body2" gutterBottom>
            {historicDay &&
              historicDay.attendance &&
              historicDay.attendance.free_text}
          </Typography>

          <Typography variant="h6" gutterBottom className="mt-4">
            Antropometria{" "}
          </Typography>

          {historicDay &&
            historicDay.imc &&
            historicDay.imc.map((row) => (
              <div className="col-12 row">
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Peso atual:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.weight}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Altura:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.tall}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    I.M.C:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.imc}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Classe I.M.C:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.class_imc}
                  </Typography>
                </div>
              </div>
            ))}

          <Typography variant="h6" gutterBottom className="mt-4">
            Circunferência
          </Typography>

          {historicDay &&
            historicDay.circumferences &&
            historicDay.circumferences.map((row) => (
              <div className="col-12 row">
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Circ. Abdominal:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.c_abdominal}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Clas. Circ. Abdominal:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.class_abdominal}
                  </Typography>
                </div>
              </div>
            ))}

          <Typography variant="h6" gutterBottom className="mt-4">
            Dados Vitais
          </Typography>

          {historicDay &&
            historicDay.vital_signs &&
            historicDay.vital_signs.map((row) => (
              <div className="col-12 row">
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Temp. Axi:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.at}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    P.A.S:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.ost0_pas}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    P.A.D:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.ost0_pad}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Frequência Cardiaca:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.ost0_fcbasal}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Frequência Respiratória:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.ost0_fcrsp}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Saturação:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.saturation}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Murmúrio vesicular:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.saturation}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Sono e repouso:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.sleep_rest}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Nível de consciência:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.level_consciousness}
                  </Typography>
                </div>
              </div>
            ))}

          <Typography variant="h6" gutterBottom className="mt-4">
            Sintomas
          </Typography>

          {historicDay &&
            historicDay.initialCovid &&
            historicDay.initialCovid.map((row) => (
              <div className="col-12 row ">
                <div className="col-3">
                  <Typography variant="body2" gutterBottom>
                    {row.name_type}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Nível de Gravidade:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.gravity}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Data de Início:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.start ? moment(row.start).format("DD/MM/YYYY") : "--"}
                  </Typography>
                </div>
                <div className="col-3">
                  <Typography variant="subtitle2" gutterBottom>
                    Data de Fim:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {row.end ? moment(row.end).format("DD/MM/YYYY") : "--"}
                  </Typography>
                </div>
                <hr className="w-100" />
              </div>
            ))}
        </div>
      )}

      {view !== 0 && (
        <div className="col-12 p-2 d-flex justify-content-center">
          <Btn
            title="Voltar"
            icon="zmdi zmdi-long-arrow-left"
            name="Voltar"
            onClick={() => {
              view == 1 ? setView(0) : setView(1);
            }}
            float="right"
          />
        </div>
      )}
    </div>
  );
}
