import React, { useState, useEffect } from "react";
import Api from "services/api";
import { useSelector, useDispatch } from "react-redux";
import CardViewAddress from "./CardViewAddress";
import ExamsAddress from "./ExamsAddress";
import ListPatient from "./ListPatient";
import ListDoctor from "./ListDoctor";
import ListMedicines from "./ListMedicines";
import ListProcedures from "./ListProcedures";
import ListEvents from "./ListEvents";
import CircularProgress from "components/CircularProgress";
import { Paper } from "@material-ui/core";

//tabs
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PersonIcon from "@material-ui/icons/Person";
import DateRangeIcon from "@material-ui/icons/DateRange";
import WorkIcon from "@material-ui/icons/Work";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function ViewAddress({
  view,
  setView,
  viewPatient,
  viewProfessional,
}) {
  const [value, setValue] = React.useState(0);
  const data_dash = useSelector((state) => state.dashboard.data_dash);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (view === 1) {
      setValue(view);
      setView(0);
    }
  }, [view]);

  const address = useSelector((state) => state.auth.address);
  const [record, setRecord] = useState({
    exams: [],
    patients: [],
    doctors: [],
    medicines: [],
    procedures: [],
    events: [],
  });
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    Api.post(`/ms_system/dashboard/getInfoPrefectureAddress`, {
      address,
      date: data_dash,
    })
      .then((res) => {
        setRecord(res.data);
        setLoader(false);
      })
      .catch(() => {
        setRecord({
          exams: [],
          patients: [],
          doctors: [],
          medicines: [],
          procedures: [],
          events: [],
        });
        setLoader(false);
      });
  }, [data_dash.start, data_dash.end]);

  return (
    <div className="app-wrapper">
      <div className="animated slideInUpTiny animation-duration-3">
        <Paper elevation={3}>
          <CardViewAddress />

          <AppBar
            position="static"
            color="default"
            style={{
              background: "white",
              boxShadow: "none",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              <Tab
                label="Atendimentos"
                icon={<PersonIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label="Profissionais"
                icon={<WorkIcon />}
                {...a11yProps(1)}
              />
              <Tab
                label="Agendamentos"
                icon={<DateRangeIcon />}
                {...a11yProps(2)}
              />
              <Tab
                label="Medicamentos"
                icon={<LocalHospitalIcon />}
                {...a11yProps(3)}
              />
              <Tab label="Exames" icon={<AssignmentIcon />} {...a11yProps(4)} />
              <Tab
                label="Procedimentos"
                icon={<LibraryBooksIcon />}
                {...a11yProps(5)}
              />
              {/* <Tab label="Item Six" icon={<ThumbDown />} {...a11yProps(5)} />
              <Tab label="Item Seven" icon={<ThumbUp />} {...a11yProps(6)} /> */}
            </Tabs>
          </AppBar>
        </Paper>
        {loader ? (
          <CircularProgress />
        ) : (
          <div className="row justify-content-center mt-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center">
              <TabPanel value={value} index={0} className="m-0 p-0">
                <ListPatient
                  record={record.patients}
                  viewPatient={viewPatient}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ListDoctor
                  record={record.doctors}
                  viewProfessional={viewProfessional}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ListEvents record={record.events} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ListMedicines record={record.medicines} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <ExamsAddress record={record.exams} />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <ListProcedures record={record.procedures} />
              </TabPanel>
            </div>
          </div>
        )}
      </div>
      <Button
        onClick={() =>
          dispatch({
            type: "CHANGE_ADDRESS",
            payload: {
              address: "",
            },
          })
        }
        className="jr-btn jr-btn-sm mt-3"
        color="secondary"
        variant="contained"
        style={{ float: "left", textTransform: "initial" }}
      >
        <i className="zmdi zmdi-undo zmdi-hc-lg" />
        <span>Voltar</span>
      </Button>
    </div>
  );
}
