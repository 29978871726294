import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { chatModalMobile } from "actions/comunication/Chat";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Chat from "app/routes/comunication/routes/chat";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChatModal() {
  const dispatch = useDispatch();
  const modalMobile = useSelector(({ chat }) => chat.modalMobile);
  const handleCloseModal = () => {
    dispatch(chatModalMobile(false));
  };

  return (
    <Dialog
      open={modalMobile}
      onClose={handleCloseModal}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <Typography
            variant="h6"
            className="ml-2"
            style={{ flex: "1", color: "white" }}
          >
            Chat
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div style={{ margin: "90px 10px 0 10px" }}>
        <Chat />
      </div>
    </Dialog>
  );
}
