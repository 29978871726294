import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Finance from "./contents/Finance";
import Evolution from "./contents/Evolution";
import Default from "./contents/Default";
import Consult from "./contents/Consult";
import Admission from "./contents/Admission";
import Nutrition from "./contents/Nutrition";
import HomeCare from "./contents/HomeCare";
import Patient from "./contents/Patient";
import PatientPortal from "./contents/PatientPortal";
import FamilyAccompaniment from "./contents/FamilyAccompaniment";
import SchedulingHomeCare from "./contents/SchedulingHomeCare";
import Stock from "./contents/Stock";
import UserInfo from "components/UserInfo";
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { toggleCollapsedNav, updateWindowWidth } from "actions/Setting";
import { setAuthData, setPermissionsConsult } from "actions/Auth";
import { changeConfigSr, changeConfigAnamnesis } from "actions/Configuration";
import Api from "services/apiLogin";

class SideNav extends React.PureComponent {
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  componentWillReceiveProps(nextProps) {
    // Fecha o menu lateral no mobile
    if (this.props.navCollapsed && nextProps.navCollapsed) {
      this.props.toggleCollapsedNav(false);
    }
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.props.updateWindowWidth(window.innerWidth);
    });

    if (this.props.userAuth && !this.props.loaded_info) {
      Api.post(`/microservice/consult/getInfoUser/`, {
        user: this.props.userAuth,
      }).then((res) => {
        const {
          name,
          fullname,
          photo,
          area,
          type,
          provider_type,
          teacher,
          gender,
          birthday,
          cpf,
          rg,
          customSystemReview,
          anamnesis,
        } = res.data.record;
        this.props.setAuthData(
          name,
          photo,
          type,
          area,
          provider_type,
          teacher,
          gender,
          birthday,
          cpf,
          rg,
          fullname
        );
        customSystemReview.length > 0 &&
          this.props.changeConfigSr(
            customSystemReview[0].id,
            customSystemReview[0].view
          );
        anamnesis.length > 0 &&
          this.props.changeConfigAnamnesis(anamnesis[0].id, anamnesis[0].view);
        if (area) {
          this.props.setPermissionsConsult(area);
        }
      });
    }
  }

  getContent = (value) => {
    switch (value) {
      case "consult":
        return <Consult />;
      case "admission":
        return <Admission />;
      case "finance":
        return <Finance />;
      case "evolution":
        return <Evolution />;
      case "nutrition":
        return <Nutrition />;
      case "homecare":
        return <HomeCare />;
      case "family-accompaniment":
        return <FamilyAccompaniment />;
      case "scheduler-homecare":
        return <SchedulingHomeCare />;
      case "stock":
        return <Stock />;
      case "pp":
        return <PatientPortal />;
      case "nc":
        return "";
      default:
        if (this.props.type_user === "3" && !this.props.secretary) {
          return <Patient />;
        } else {
          return <Default />;
        }
    }
  };

  render() {
    const { navCollapsed, drawerType, width, navigationStyle } = this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "d-xl-flex"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? ""
      : "d-flex";
    let type = "permanent";
    if (
      drawerType.includes(COLLAPSED_DRAWER) ||
      (drawerType.includes(FIXED_DRAWER) && width < 1200)
    ) {
      type = "temporary";
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = "";
      type = "temporary";
    }

    if (this.props.type === "evolution" && width > 1200) {
      drawerStyle = "d-flex";
      type = "permanent";
    }

    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes("temporary") ? navCollapsed : true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper:
              this.props.type === "nc"
                ? "side-nav side-nav-height"
                : "side-nav",
          }}
        >
          <UserInfo user={this.props.userAuth} history={this.props.history} />
          {this.getContent(this.props.type)}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { navCollapsed, drawerType, width, navigationStyle } = settings;
  return {
    navCollapsed,
    drawerType,
    width,
    navigationStyle,
    userAuth: auth.authUser,
    loaded_info: auth.loaded_info,
    type_user: auth.type,
    secretary: auth.secretary,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    toggleCollapsedNav,
    updateWindowWidth,
    setAuthData,
    setPermissionsConsult,
    changeConfigSr,
    changeConfigAnamnesis,
  })(SideNav)
);
