import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Api from "services/api";
import { getUrlPhoto } from "services/functions";

import SearchComponent from "components/SearchComponent";
import BarProgress from "components/CircularProgress/ProgressBar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

export default function SelectPatient({ patients, setPatients }) {
  const addressId = useSelector(({ auth }) => auth.address);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setLoading(true);
    Api.post(`/ms_system/workplace/getMembersInResidential/`, { addressId })
      .then(({ data }) => {
        setPatients(data.record.map(row => ({ ...row, checked: true })));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const togglePatient = id => {
    setPatients(
      patients.map(row => {
        if (row.id === id) {
          row = {
            ...row,
            checked: !row.checked
          };
        }
        return row;
      })
    );
  };

  const handleChangeCheckAll = event => {
    setPatients(
      patients.map(row => ({
        ...row,
        checked: event.target.checked
      }))
    );
  };

  return (
    <div>
      {loading ? (
        <div
          className="col-12 d-flex justify-content-center py-4"
          style={{ alignItems: "center" }}
        >
          <BarProgress />
        </div>
      ) : patients.length > 0 ? (
        <div className="row">
          <div className={"w-100 d-flex p-2"}>
            {/* ChackBox para todos os Cuidados */}
            <Checkbox
              checked={
                [...patients.map(el => el.checked)].indexOf(false) === -1
              }
              tabIndex={-1}
              disableRipple
              onChange={handleChangeCheckAll}
              color="primary"
              className="mx-3"
            />
            {/* Barra de Pesquisa */}
            <SearchComponent
              searchContact={setSearch}
              placeholder={"Buscar Pacientes"}
            />
          </div>
          <List>
            {patients
              .filter(
                row =>
                  row.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
              )
              .map(patient => (
                <ListItem
                  key={patient.id}
                  role={undefined}
                  dense
                  button
                  onClick={() => togglePatient(patient.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      edge="start"
                      checked={!!patient.checked}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemAvatar>
                    <Avatar
                      alt={patient.name}
                      src={
                        patient.photo
                          ? getUrlPhoto(patient.id, patient.photo)
                          : "teste.jpg"
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={patient.name}
                    secondary={patient.birthday}
                  />
                </ListItem>
              ))}
          </List>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
