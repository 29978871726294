import React from "react";

import { Tooltip } from "@material-ui/core/";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: { marginTop: "-35px" },
  },
  title: {
    fontSize: "26px",
    color: "#000",
  },
}));
export default function ViewVitalSigns({ data, is_pattern, justInfo }) {
  const classes = useStyles();
  return (
    <div className={`col-12 p-0 ${classes.root}`}>
      {!justInfo && (
        <div className={`col-12 d-flex align-items-center ${classes.title}`}>
          <Tooltip placement="top" title={"Dados Vitais"}>
            <FavoriteIcon style={{ color: "#575757" }} />
          </Tooltip>
          <span className="pl-2">{data.name}</span>
        </div>
      )}
      <div className={`row p-3`}>
        <div className={`col-12`}>
          <span>Medições: </span>
          <br />
          <span className={`pl-2`}>
            {data.respiratory === "1" && "Freq. Respiratória, "}
            {data.capillary === "1" && "Glicemia Capilar, "}
            {data.blood_pressure === "1" && "Pressão Arterial, "}
            {data.pulse === "1" && "Pulso, "}
            {data.temperature === "1" && "Temperatura, "}
            {data.consciousness === "1" && "Consciência, "}
            {data.behavior === "1" && "Comportamento, "}
            {data.sleep === "1" && "Sono, "}
          </span>
        </div>
      </div>
      <div className={`row p-3`}>
        <div className={`col-4`}>
          <span>x/Dia</span>
          <br />
          <span className={`pl-2`}>{data.times_a_day}</span>
        </div>
        <div className={`col-4`}>
          <span>Horários</span>
          <br />
          <span className={`pl-2`}>{data.hours.split(",").join(" e ")}</span>
        </div>
        <div className={`col-4`} />
      </div>
      <div className={`row p-3`}>
        <div className={`col-4`}>
          <span>Intervalo</span>
          <br />
          <span className={`pl-2`}>{data.interval}</span>
        </div>
        <div className={`col-4`}>
          <span>Data Inicial</span>
          <br />
          <span className={`pl-2`}>
            {moment(data.start).format("DD/MM/YYYY")}
          </span>
        </div>
        {data.end && !is_pattern && (
          <div className={`col-4`}>
            <span>Data Final</span>
            <br />
            <span className={`pl-2`}>
              {moment(data.end).format("DD/MM/YYYY")}
            </span>
          </div>
        )}
      </div>
      {data.observation && (
        <div className={`row p-3`}>
          <div className={`col-12`}>
            <span>Observações</span>
            <br />
            <span className={`pl-2`}>{data.observation}</span>
          </div>
        </div>
      )}
    </div>
  );
}
