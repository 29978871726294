import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import {
  TextField,
  FormControl,
  Input,
  InputAdornment,
  FormHelperText,
  MenuItem,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  Button,
} from "@material-ui/core";
import MaskFloat from "components/Masks/MaskFloat";
import IconChart from "components/Charts/IconChart";
import Api from "services/api";

export default function ModalFilter({ open, handleClose, data, beforeSave }) {
  const [state, setState] = useState({});

  const auth = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);

  const dispatch = useDispatch();

  useEffect(() => {
    if (open && (data.service_id === "7" || data.service_id === "34")) {
      Api.post(`/microservice/exam/verifyCitopatologico/`, {
        patient: data.fk_ls_user_patient,
      }).then((res) => {
        let { record } = res.data;
        if (record) {
          setState({
            request: record.request,
            order: record.id,
            siscan: record.siscan,
            av_date: record.date,
            av_observation: record.info_doctor,
          });
        }
      });
    }
  }, [open]);

  useEffect(() => {
    let { weight, tall, imc, class_imc } = state;
    if (weight && tall) {
      weight = convertStrToFloat(weight);
      tall = convertStrToFloat(tall);
      const result = weight / ((tall / 100) * (tall / 100));
      imc = result ? result.toFixed(1) : "";

      if (result < 16) {
        class_imc = "Magreza Grau III";
      } else if (result >= 16 && result < 17) {
        class_imc = "Magreza Grau II";
      } else if (result >= 17 && result < 18.5) {
        class_imc = "Magreza Grau I";
      } else if (result >= 18.5 && result < 25) {
        class_imc = "Eutrofia";
      } else if (result >= 25 && result < 30) {
        class_imc = "Pré-obeso";
      } else if (result >= 30 && result < 35) {
        class_imc = "Obesidade I";
      } else if (result >= 35 && result < 40) {
        class_imc = "Obesidade II";
      } else {
        class_imc = "Obesidade III";
      }
      setState({ ...state, imc, class_imc });
    } else {
      setState({ ...state, imc: "", class_imc: "" });
    }
  }, [state.tall, state.weight]);

  useEffect(() => {
    let value = parseInt(state.abdomen);
    if (state.abdomen) {
      let classific = "";
      if (data.gender === "1") {
        if (value <= 80) {
          classific = "Normal";
        } else if (value < 84) {
          classific = "Risco médio";
        } else if (value < 88) {
          classific = "Risco alto";
        } else {
          classific = "Risco altíssimo";
        }
      } else {
        if (value <= 90) {
          classific = "Normal";
        } else if (value < 94) {
          classific = "Risco médio";
        } else if (value < 102) {
          classific = "Risco alto";
        } else {
          classific = "Risco altíssimo";
        }
      }
      setState({ ...state, class_abdomen: classific });
    } else {
      setState({ ...state, class_abdomen: "" });
    }
  }, [state.abdomen]);

  const convertStrToFloat = (str) => {
    if (typeof str == "string") {
      str = str.replace(",", ".");
      return parseFloat(str);
    } else if (typeof str == "number") {
      if (parseFloat(str)) {
        return parseFloat(str);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const onClose = () => {
    setState({});
    handleClose();
  };

  const handleSave = () => {
    let formData = {
      patient: data.fk_ls_user_patient,
      auth,
      address,
      record: state,
      service: data.id,
      service_type: data.service_id,
    };
    Api.post(`/ms_system/dashboard/saveFormAttendance/`, formData)
      .then((res) => {
        beforeSave(data.id);
        onClose();
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      scroll="body"
    >
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-primary font-weight-semibold">
              Dados - {data.service}
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={() => onClose()}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>

          {data.service_id !== "7" && data.service_id !== "34" && (
            <>
              <div className="col-md-3 col-xs-6 d-flex mt-2">
                <MaskFloat
                  label="Peso atual:"
                  unity="Kg"
                  value={state.weight}
                  onChange={(e) =>
                    setState({ ...state, weight: e.target.value })
                  }
                />
                <IconChart
                  title="Evolução - Peso"
                  type="weight"
                  patient={data.fk_ls_user_patient}
                />
              </div>

              <div className="col-md-3 col-xs-6 d-flex">
                <FormControl className="my-3" fullWidth>
                  <FormHelperText>Altura: </FormHelperText>
                  <Input
                    id="tall"
                    type="number"
                    value={state.tall}
                    onChange={(e) =>
                      setState({ ...state, tall: e.target.value })
                    }
                    endAdornment={
                      <InputAdornment position="end">Cm</InputAdornment>
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .substr(0, 3);
                    }}
                  />
                </FormControl>
                <IconChart
                  title="Evolução - Altura"
                  type="tall"
                  patient={data.fk_ls_user_patient}
                />
              </div>

              <div className="col-md-3 col-xs-6 d-flex">
                <FormControl className="my-3" fullWidth>
                  <FormHelperText>I.M.C:</FormHelperText>
                  <Input
                    readOnly
                    id="imc"
                    value={state.imc}
                    onChange={(e) => {}}
                  />
                </FormControl>
                <IconChart
                  title="Evolução - IMC"
                  type="imc"
                  patient={data.fk_ls_user_patient}
                />
              </div>

              <div className="col-md-3 col-xs-6">
                <FormControl className="my-3" fullWidth>
                  <FormHelperText>Classe I.M.C:</FormHelperText>
                  <Input
                    readOnly
                    id="class_imc"
                    value={state.class_imc}
                    onChange={() => {}}
                  />
                </FormControl>
              </div>
            </>
          )}

          {data.service_id === "6" && (
            <>
              <div className="col-md-3 col-12 d-flex mt-1">
                <MaskFloat
                  label="Perímetro Cefálico:"
                  unity="cm"
                  value={state.cephalic}
                  onChange={(e) =>
                    setState({ ...state, cephalic: e.target.value })
                  }
                />
                <IconChart
                  title="Evolução - Perímetro Cefálico"
                  type="cephalic"
                  patient={data.fk_ls_user_patient}
                />
              </div>
              <div className="col-md-3 col-12 d-flex mt-1">
                <MaskFloat
                  label="Circ. Abdominal:"
                  unity="cm"
                  value={state.abdomen}
                  onChange={(e) =>
                    setState({ ...state, abdomen: e.target.value })
                  }
                />
                <IconChart
                  title="Evolução - Circunferência Abdominal"
                  type="circ_abm"
                  patient={data.fk_ls_user_patient}
                />
              </div>

              <div className="col-md-3 col-12">
                <FormControl className="my-3" fullWidth>
                  <FormHelperText>Clas. Circ. Abdominal:</FormHelperText>
                  <Input
                    readOnly
                    id="class_imc"
                    value={state.class_abdomen}
                    onChange={() => {}}
                  />
                </FormControl>
              </div>

              <div className="col-md-3 col-12 d-flex mt-1">
                <MaskFloat
                  label="Circ. Panturrilha:"
                  unity="cm"
                  value={state.calf}
                  onChange={(e) => setState({ ...state, calf: e.target.value })}
                />
                <IconChart
                  title="Evolução - Circunferência da Panturrilha"
                  type="circ_calf"
                  patient={data.fk_ls_user_patient}
                />
              </div>

              <div className="col-md-3 col-12 d-flex mt-1">
                <MaskFloat
                  label="Circ. da Coxa:"
                  unity="cm"
                  value={state.circ_cx}
                  onChange={(e) =>
                    setState({ ...state, circ_cx: e.target.value })
                  }
                />
                <IconChart
                  title="Evolução - Circ. da Coxa"
                  type="circ_cx"
                  patient={data.fk_ls_user_patient}
                />
              </div>
              <div className="col-md-3 col-12 d-flex mt-1">
                <MaskFloat
                  label="Circ. do Braço:"
                  unity="cm"
                  value={state.circ_bc}
                  onChange={(e) =>
                    setState({ ...state, circ_bc: e.target.value })
                  }
                />
                <IconChart
                  title="Evolução - Circ. do Braço"
                  type="circ_bc"
                  patient={data.fk_ls_user_patient}
                />
              </div>
            </>
          )}

          {data.service_id === "4" && (
            <>
              <div className="col-md-3 col-12">
                <h4 className="font-weight-semibold">
                  Pressão Arterial{" "}
                  <IconChart
                    title="Evolução - Pressão Arterial"
                    type="pa"
                    patient={data.fk_ls_user_patient}
                  />
                </h4>
                <FormControl fullWidth>
                  <FormHelperText>PAS (Maior valor):</FormHelperText>
                  <Input
                    value={state.pas}
                    onChange={(e) =>
                      setState({ ...state, pas: e.target.value })
                    }
                    inputProps={{ maxLength: 3 }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-md-3 col-12 mt-2">
                <h4 className="font-weight-semibold"> </h4>
                <FormControl fullWidth className="mt-3">
                  <FormHelperText>PAD (Menor valor):</FormHelperText>
                  <Input
                    value={state.pad}
                    onChange={(e) =>
                      setState({ ...state, pad: e.target.value })
                    }
                    inputProps={{ maxLength: 3 }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </FormControl>
              </div>
            </>
          )}

          {(data.service_id === "5" || data.service_id === "6") && (
            <div className="col-md-4 col-12 mt-3">
              <TextField
                select
                label="Vacinação em dia:"
                value={state.vaccine}
                onChange={(e) =>
                  setState({ ...state, vaccine: e.target.value })
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem key="1" value="Não">
                  Não
                </MenuItem>
                <MenuItem key="2" value="Sim">
                  Sim
                </MenuItem>
              </TextField>
            </div>
          )}
          {data.service_id === "5" && (
            <div className="col-md-4 col-12 mt-3">
              <TextField
                type="date"
                label="DUM:"
                value={state.dum}
                onChange={(e) => setState({ ...state, dum: e.target.value })}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          )}

          {(data.service_id === "7" || data.service_id === "34") && (
            <>
              <div className="col-md-12 col-12">
                <TextField
                  label="N° protocolo SISCAN:"
                  value={state.siscan}
                  onChange={(e) =>
                    setState({ ...state, siscan: e.target.value })
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <h4 className="font-weight-semibold">Avaliação</h4>
              </div>
              <div className="col-md-4 col-12">
                <TextField
                  type="date"
                  label="Data:"
                  value={state.av_date}
                  onChange={(e) =>
                    setState({ ...state, av_date: e.target.value })
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              {/* <div className="col-md-4 col-12">
                <TextField
                  type="time"
                  label="Hora:"
                  value={state.av_hour}
                  onChange={(e) =>
                    setState({ ...state, av_hour: e.target.value })
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div> */}
              <div className="col-12 mt-3">
                <TextField
                  label="Observação Avaliação:"
                  value={state.av_observation}
                  onChange={(e) =>
                    setState({ ...state, av_observation: e.target.value })
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-md-12 mt-3">
                <h4 className="font-weight-semibold">Resultado</h4>
              </div>
              <div className="col-md-12 col-12">
                <TextField
                  type="date"
                  label="Data do Resultado:"
                  value={state.res_date}
                  onChange={(e) =>
                    setState({ ...state, res_date: e.target.value })
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-md-12 col-12 mt-3">
                <TextField
                  label="Resultado:"
                  value={state.result}
                  onChange={(e) =>
                    setState({ ...state, result: e.target.value })
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-md-12 col-12 mt-3">
                <TextField
                  label="Observação Resultado:"
                  value={state.observation}
                  onChange={(e) =>
                    setState({ ...state, observation: e.target.value })
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </>
          )}

          <div className="col-md-12" style={{ marginTop: "30px" }}>
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => onClose()}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right" }}
              onClick={handleSave}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Salvar </span>
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
