import React, { Component } from "react";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import Api from "services/apiFile";
import PropTypes from "prop-types";
import "./styles.css";

import $ from "jquery";
window.jQuery = $;
require("bootstrap");

class EditComponentPrint extends Component {
  state = {
    content: "",
  };

  updateContent = (value) => {
    this.setState({ content: value });
    let formData = {
      value: value,
      textPrint: this.props.textPrint,
    };
    this.props.setEditListFormPrint(formData);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.textPrint !== undefined) {
      this.setState({
        content: nextProps.teste[`${nextProps.textPrint}`],
      });
    }
  }

  onImageUpload = async (files) => {
    const data = new FormData();
    data.append("file", files[0]);
    data.append("authUser", this.props.authUser);

    const reader = new FileReader();
    await Api.post(`/microservice/formprint/uploadImage/`, data).then((res) => {
      if (res.data.result) {
        reader.onloadend = () => {
          ReactSummernote.insertImage(res.data.result.link);
        };
      }
      reader.readAsDataURL(files[0]);
    });
  };

  render() {
    return (
      <>
        <ReactSummernote
          value={this.state.content}
          options={{
            lang: "pt-PT",
            height: 350,
            dialogsInBody: true,
            toolbar: [
              ["font", ["bold", "underline", "clear"]],
              ["fontname", ["fontname"]],
              ["fontsize", ["fontsize"]],
              ["para", ["ul", "ol", "paragraph"]],
              ["height", ["height"]],
              ["insert", ["link", "picture"]],
            ],
          }}
          onChange={this.updateContent}
          onImageUpload={this.onImageUpload}
        />
      </>
    );
  }
}

ReactSummernote.propTypes = {
  onImageUpload: PropTypes.func,
};

export default EditComponentPrint;
