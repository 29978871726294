import React from "react";
import NoRecord from "components/Alert/NoRecord";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

export default function PainScaleGeneral({ listScale }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setrowsPerPage] = React.useState(10);
  return (
    <div className="row">
      <div className="col-12">
        {listScale !== null && listScale.length > 0 ? (
          <div
            className="col-12"
            style={{
              minWidth: "650",
              overflowX: "auto",
            }}
          >
            <Table className="table-list-scale">
              <TableHead>
                <TableRow>
                  <TableCell>Resultado</TableCell>
                  <TableCell>Valor (pts)</TableCell>
                  <TableCell>Observações</TableCell>
                  <TableCell>Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listScale
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((el) => (
                    <TableRow key={el.id}>
                      <TableCell component="th" scope="row">
                        {el.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {el.value}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {el.obs === null ? "---" : el.obs}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {el.date}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={listScale.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Registros por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `Mostrando ${from} - ${to} de ${count}`
                    }
                    page={page}
                    onChangePage={(event, page) => setPage(page)}
                    onChangeRowsPerPage={(e) => setrowsPerPage(e.target.value)}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        ) : (
          <NoRecord />
        )}
      </div>
    </div>
  );
}
