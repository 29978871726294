import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";

import MaskCpf from "components/Masks/MaskCpf";
import { BtnSave } from "components/Button";
import AutocompleteOcuppation from "../autocomplete/Ocuppation";
import AutocompleteNaturality from "../autocomplete/CompositeAddress";
import { TextField, MenuItem } from "@material-ui/core";

export default function LegalRepresentative() {
  const dispatch = useDispatch();
  const id_provider = useSelector((state) => state.Profile.provider.id);
  const [state, setState] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    getLegalRep();
  }, []);

  function getLegalRep() {
    Api.post("ms_system/user/getLegalRep", {})
      .then(({ data }) => {
        if (data.record.length > 0) {
          setState(data.record[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleChange(field, value) {
    setState({ ...state, [field]: value });
  }

  function save() {
    if (!error) {
      Api.post("ms_system/user/saveLegalRep", { ...state, id: id_provider })
        .then(({ data }) => {
          if (data.success) {
            showMsg("success", "Salvo com sucesso!", dispatch);
          } else {
            showMsg("error", "Não foi possível salvar!", dispatch);
          }
        })
        .catch((err) => {
          showMsg("error", "Não foi possível salvar!", dispatch);
        });
    }
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <span className="card-title text-primary title-form">
          Representante Legal
        </span>
      </div>
      <div className="col-md-8 col-12">
        <TextField
          id="name_rep"
          required
          margin="normal"
          label="Nome do Representante:"
          value={state.name}
          onChange={(e) => handleChange("name", e.target.value)}
          fullWidth
          error={error && !state.name ? true : false}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="cpf"
          label="CPF:"
          margin="normal"
          required
          fullWidth
          InputProps={{
            inputComponent: MaskCpf,
            value: state.cpf,
            onChange: (e) => handleChange("cpf", e.target.value),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="nat"
          required
          margin="normal"
          label="Natural de :"
          value={state.nat}
          onChange={(e) => handleChange("nat", e.target.value)}
          fullWidth
          error={error && !state.nat ? true : false}
          InputLabelProps={{ shrink: true }}
        />
        {/* <AutocompleteNaturality
          address={{
            id: state.naturality_id,
            label: state.naturality,
          }}
          naturality={true}
          change={(value) => handleChange("naturality", value)}
        /> */}
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="prof"
          required
          margin="normal"
          label="Ocupação:"
          value={state.prof}
          onChange={(e) => handleChange("prof", e.target.value)}
          fullWidth
          error={error && !state.prof ? true : false}
          InputLabelProps={{ shrink: true }}
        />
        {/* <AutocompleteOcuppation
          ocuppation={{
            id: state.fk_ocuppation,
            name: state.ocuppation,
          }}
          change={(value) => handleChange("ocuppation", value)}
        /> */}
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="estado_civil"
          select
          required
          label="Estado civil:"
          value={state.estado_civil ? state.estado_civil : ""}
          onChange={(e) => handleChange("estado_civil", e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem key="1" value="Casado">
            Casado(a)
          </MenuItem>
          <MenuItem key="2" value="Divorciado">
            Divorciado(a)
          </MenuItem>
          <MenuItem key="3" value="Separado">
            Separado(a)
          </MenuItem>
          <MenuItem key="4" value="Solteiro">
            Solteiro(a)
          </MenuItem>
          <MenuItem key="5" value="Companheiro">
            Companheiro(a)
          </MenuItem>
          <MenuItem key="6" value="Viúvo">
            Viúvo(a)
          </MenuItem>
        </TextField>
      </div>

      <div className="col-12">
        <BtnSave float="right" title="Salvar" onClick={save} />
      </div>
    </div>
  );
}
