import React, { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";

import { Button } from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CustomDropzone from "components/CustomDropzone";
import { Btn, BtnCan } from "components/Button";

export default function TakePhoto(props) {
  const initState = {
    img: null,
    displayCam: false,
    accpted: [],
    captured: false
  };
  const [state, setState] = useState(initState);

  const setRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = setRef.current.getScreenshot();
    props.setCaptured({ preview: imageSrc });
  }, [setRef]);

  const { displayCam } = state;
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };
  return (
    <>
      {displayCam ? (
        <div className="row">
          <div className="col-12">
            <Webcam
              audio={false}
              ref={setRef}
              style={{ width: "100%", height: "100%" }}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          </div>
          <div className="col-md-12">
            <BtnCan
              title={"Voltar"}
              icon={"zmdi zmdi-close"}
              name={"Cancelar"}
              onClick={() => setState({ ...state, displayCam: false })}
              float="left"
            />
            <Btn
              title={"Capturar"}
              icon={"zmdi zmdi-camera"}
              name={"Capturar"}
              onClick={capture}
              float="right"
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <Button
              onClick={() => setState({ ...state, displayCam: true })}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
              fullWidth
            >
              <CameraAltIcon />
              <span>Tirar Foto</span>
            </Button>
          </div>
          <div className="col-12">
            <CustomDropzone
              returnAcceptedFiles={doc => {
                props.setCaptured(doc[0]);
              }}
              extensions="image/*"
              max_files={1}
            />
          </div>
        </div>
      )}
    </>
  );
}
