import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import Api from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { setListAddress, changeAddress } from "actions/Auth";
// import { setDataDash } from "actions/Dashboard";
// import Attendance from "../../components/attendance";
import ViewAddress from "../../components/prefecture/ViewAddress";
import ViewPatient from "../../components/prefecture/ViewPatient";
import ViewProfessional from "../../components/prefecture/ViewProfessional";
// import SearchPatient from "../../components/prefecture/SearchPatient";
// import SearchProfessional from "../../components/prefecture/SearchProfessional";
// import Events from "../../components/prefecture/Events";
import Childs from "../../components/prefecture/Childs";
import GroupIcon from "@material-ui/icons/Group";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import CircularProgress from "components/CircularProgress";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";

export default function DashboardPrefecture({ match, user }) {
  // const user = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);
  const list_address = useSelector((state) => state.auth.list_address);
  const loaded_address = useSelector((state) => state.auth.loaded_address);
  const data_dash = useSelector((state) => state.dashboard.data_dash);

  const [patient, setPatient] = useState({});
  const [professional, setProfessional] = useState({});
  const [viewChilds, setViewChilds] = useState(false);
  const [loader, setLoader] = useState(true);
  const [record, setRecord] = useState([]);
  const [data, setData] = useState(false);
  const [childVaccine, setChildVaccine] = useState("");
  const [view, setView] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded_address) {
      Api.post(`/ms_system/dashboard/getAddressProvider`, {
        user,
      }).then((res) => {
        dispatch(
          setListAddress(
            res.data.listAddress.map((row) => ({
              ...row,
              type: "1",
              provider_type: "56",
            }))
          )
        );
      });
    }
  }, []);

  useEffect(() => {
    if (list_address.length > 0) {
      Api.post(`/ms_system/dashboard/getOverviewAttendancePrefecture`, {
        list: list_address
          .filter((row) => row.id !== "28379")
          .map((row) => row.id),
        start: data_dash.start,
        end: data_dash.end,
      })
        .then((res) => {
          let aux = [];
          res.data.record.forEach((row) => {
            if (parseInt(row.data.total) > 0) {
              let info = list_address.find((add) => add.id === row.id);

              aux.push({
                ...info,
                total: row.data.total,
                professional: row.data.professional,
              });
            }
          });
          setRecord(aux);
          setChildVaccine({
            total_child: res.data.total_child,
            total_child_vaccine: res.data.total_child_vaccine,
          });
        })
        .finally(() => setLoader(false));
    } else {
      if (loaded_address) {
        setLoader(false);
      }
    }
  }, [list_address, data_dash]);

  if (loader) {
    return <CircularProgress />;
  }
  // const handleSerDateDash = (name, date) => {
  //   dispatch(setDataDash({name: date: }));
  // };

  if (patient.id) {
    return (
      <ViewPatient patient={patient} alterPatient={() => setPatient({})} />
    );
  } else if (professional.id) {
    return (
      <ViewProfessional
        professional={professional}
        alterProfessional={() => {
          setProfessional({});
          setView(1);
        }}
      />
    );
  } else if (address) {
    return (
      <ViewAddress
        viewPatient={(e) => setPatient(e)}
        viewProfessional={(e) => setProfessional(e)}
        view={view}
        setView={() => setView(0)}
      />
    );
  } else {
    return (
      <div className="row ml-1">
        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-1 justify-content-center">
          <Card
            className="shadow border-0 mb-3"
            style={{
              marginBottom: "0px",
            }}
          >
            <Attendance />
          </Card>
          <Card className="shadow border-0 mb-3">
            <CardBody>
              <div className="row">
                <div className="col-12">
                  <h2 className="font-weight-semibold d-flex">
                    <span className="">Pesquisa de Pacientes</span>
                  </h2>
                </div>
                <div className="col-12">
                  <SearchPatient onChange={(e) => setPatient(e)} user={user} />
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="shadow border-0 mb-3">
            <CardBody>
              <div className="row">
                <div className="col-12">
                  <h2 className="font-weight-semibold d-flex">
                    <span className="">Pesquisa de Profissionais</span>
                  </h2>
                </div>
                <div className="col-12">
                  <SearchProfessional
                    onChange={(e) => setProfessional(e)}
                    user={user}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-1 justify-content-center">
          <Card className="shadow border-0 mb-3">
            <Events user={user} />
          </Card>
        </div> */}
        <div className="col-12 pl-0">
          <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
            <h2 className="title mb-3 mb-sm-0">Dashboard</h2>
            {data ? (
              <div className="row">
                <IconButton
                  aria-label="delete"
                  onClick={() => setData(!data)}
                  color="primary"
                >
                  <EventIcon />
                </IconButton>
                <TextField
                  id="date"
                  label="Inicio:"
                  type="date"
                  value={data_dash.start}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_DATA_DASH",
                      payload: { title: "start", value: e.target.value },
                    })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  label="Término:"
                  className="ml-2"
                  type="date"
                  value={data_dash.end}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_DATA_DASH",
                      payload: { title: "end", value: e.target.value },
                    })
                  }
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      min: data_dash.start,
                      max: moment().format("YYYY-MM-DD"),
                    },
                  }}
                />
              </div>
            ) : (
              <div className="row align-items-center">
                <IconButton
                  aria-label="delete"
                  onClick={() => setData(!data)}
                  color="primary"
                  className="p-0"
                >
                  <EventIcon />
                </IconButton>
                <span
                  style={{
                    fontSize: "initial",
                    fontWeight: "bold",
                    color: "#177493",
                  }}
                >
                  {moment(data_dash.start).format("D MMM")} -{" "}
                  {moment(data_dash.end).format("D MMM")}
                </span>
              </div>
            )}
          </div>
        </div>

        {record.map((row) => (
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 p-1">
            <Card
              className="shadow border-0"
              onClick={() => dispatch(changeAddress(row.id))}
              style={{ marginBottom: 0, cursor: "pointer" }}
            >
              <CardBody>
                <div className="row">
                  <div style={{ display: "inline-flex" }}>
                    <div>
                      <LocalHospitalIcon color="primary" fontSize="large" />
                    </div>
                    <div className="ml-3">
                      <h4 className="font-weight-semibold">{row.name}</h4>
                      <span>Atendimentos: {row.total}</span>
                      <br />
                      <span>Profissionais: {row.professional}</span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        ))}
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 p-1">
          <Card
            className="shadow border-0"
            onClick={() => setViewChilds(!viewChilds)}
            style={{ marginBottom: 0, cursor: "pointer" }}
          >
            <CardBody>
              <div className="row">
                <div style={{ display: "inline-flex" }}>
                  <div>
                    <GroupIcon color="primary" fontSize="large" />
                  </div>
                  <div className="ml-3">
                    <h4 className="font-weight-semibold">
                      Cadastro Vacinação Infantil
                    </h4>
                    Total de Criança:{" "}
                    {childVaccine.total_child ? childVaccine.total_child : "0"}{" "}
                    {/* <span>Registros das crianças registradas no Portal.</span> */}
                    <br />
                    {/* <span>Crianças a partir de 5 anos de idade.</span> */}
                    Crianças Vacinadas:{" "}
                    {childVaccine.total_child_vaccine
                      ? childVaccine.total_child_vaccine
                      : "0"}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {viewChilds && (
          <div className="col-12 p-1 justify-content-center">
            <Childs />
          </div>
        )}
      </div>
    );
  }
}
