import React from "react";
import api from "services/api";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IcomAssignment from "@material-ui/icons/Assignment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InputControlLength from "components/TextFields/InputControlLength";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import moment from "moment";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import "./styles.css";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const width = useSelector(({ settings }) => settings.width);
  const user = useSelector(({ auth }) => auth.authUser);
  const address = useSelector(({ auth }) => auth.address);
  const draft = useSelector((state) => state.evolutionDaily.draftShiftChange);
  const savedId = useSelector((state) => state.evolutionDaily.savedId);
  const intervals = useSelector(
    ({ evolutionDaily }) => evolutionDaily.intervals
  );

  const [state, setState] = React.useState({
    top: false,
  });
  const dispatch = useDispatch();

  const closeModal = () => {
    setState({ top: false });
    saveDraftShiftChange();
  };

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!open) {
      saveDraftShiftChange();
    }
    setState({ ...state, [side]: open });
  };

  const saveDraftShiftChange = async () => {
    if (draft) {
      await api
        .post(`/ms_evolution/shiftchange/saveDraftShiftChange`, {
          user,
          address,
          start: intervals[0].start,
          end: intervals[intervals.length - 1].end,
          draft,
          savedId,
        })
        .then((res) => {
          dispatch({
            type: "SET_DRAFT_SAVED_ID",
            payload: res.data.id,
          });
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
        });
    }
  };

  const DialogTitleModal = (side) => (
    <div className={`col-12 m-2 ${classes.fullList} `} role="presentation">
      <InputControlLength
        multiline={true}
        rows={10}
        onChange={(e) =>
          dispatch({ type: "SET_DRAFT_SHIFT_CHANGE", payload: e.target.value })
        }
        value={draft}
        label={"Descrição do plantão:"}
        maxLength={5000}
      />

      <div align="center" className="d-block">
        <Tooltip title="Fechar">
          <IconButton className="m-0 p-0" onClick={() => closeModal()}>
            <ExpandLessIcon
              className={`animated slideInUpTiny animation-duration-3 ${classes.icon}`}
            />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );

  return (
    <div className="mr-2 contHerder">
      <Tooltip title="Passagem de Ponto" aria-label="add">
        {width < 1100 ? (
          <IconButton
            style={{ fontSize: "1.4rem", padding: "1px", marginTop: "-2px" }}
            onClick={() => setState({ ...state, top: true })}
          >
            <IcomAssignment style={{ fontSize: "1.3rem" }} />
          </IconButton>
        ) : (
          <IconButton
            style={{ fontSize: "1.4rem", padding: "1px", marginTop: "-2px" }}
            onMouseMove={toggleDrawer("top", true)}
          >
            <IcomAssignment style={{ fontSize: "1.3rem" }} />
          </IconButton>
        )}
      </Tooltip>
      <Drawer
        anchor="top"
        open={state.top}
        onClose={toggleDrawer("top", false)}
      >
        {DialogTitleModal("top")}
      </Drawer>
    </div>
  );
}
