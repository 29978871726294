import React from "react";
import { CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import TableCalendar from "./TableCalendar";
import Typography from "@material-ui/core/Typography";
import "./styles.css";

function Calendar({ history, area }) {
  return (
    <>
      <div className="col-12 m-0 p-0 animated slideInUpTiny animation-duration-4">
        <CardBody style={{ paddingBottom: 0, marginBottom: 0 }}>
          <div className="col-12 m-0 p-0">
            <div style={{ marginBottom: "-44px" }}>
              <Typography
                className="d-flex justify-content-start"
                variant="h6"
                gutterBottom
                onClick={() => history.push("/app/calendar")}
                style={{ cursor: "pointer" }}
              >
                {area === "9" ? "Consultas" : "Agenda"}
              </Typography>
            </div>
          </div>
          <div className="col-12 p-0">
            <TableCalendar />
          </div>
        </CardBody>
      </div>
    </>
  );
}

export default withRouter(Calendar);
