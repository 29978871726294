import moment from "moment";

export default function validatePrescriptionCare(state, type) {
  switch (type) {
    case "1":
      return validatePersonalHygiene(state);
    case "2":
      return validateElevatedHeadboard(state);
    case "3":
      return validDecubits(state);
    case "4":
      return validCurative(state);
    case "5":
      return validateOralDiet(state);
    case "6":
      return validateEnteralDiet(state);
    case "7":
      return validateHydratation(state);
    case "8":
      return validateVitalSings(state);
    case "9":
      return validatePoints(state);
    case "10":
      return validateSondaVesical(state);
    case "11":
      return validateOxygenTherapy(state);
    case "12":
      return validateMoviment(state);
    case "13":
      return validateInflatableLeggings(state);
    case "14":
      return validatePainScale(state);
    case "15":
      return validateFallRisk(state);

    default:
      break;
  }
}

const validCurative = state => {
  if (!state.care) {
    return true;
  } else if (state.materials.length <= 0) {
    return true;
  } else if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validDecubits = state => {
  if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validateElevatedHeadboard = state => {
  if (state.period.length <= 0) {
    return true;
  } else if (state.degree.length <= 0) {
    return true;
  } else if (
    state.period.indexOf("Período Determinado") !== -1 &&
    !state.quantity
  ) {
    return true;
  } else if (
    state.period.indexOf("Período Determinado") !== -1 &&
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validateEnteralDiet = state => {
  if (state.classification <= 0) {
    return true;
  } else if (!state.volume) {
    return true;
  } else if (!state.flow) {
    return true;
  } else if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validateHydratation = state => {
  if (!state.type_hydratation) {
    return true;
  } else if (!state.volume) {
    return true;
  } else if (
    state.type_hydratation !== "Oral" &&
    state.type_hydratation !== "Administração Livre" &&
    !state.flow
  ) {
    return true;
  } else if (
    (state.type_hydratation === "Subcutânea" ||
      state.type_hydratation === "Venosa") &&
    !state.serum_type
  ) {
    return true;
  } else if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validateOralDiet = state => {
  if (state.classification <= 0) {
    return true;
  } else if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validateOxygenTherapy = state => {
  if (!state.care) {
    return true;
  } else if ((state.care === "57" || state.care === "58") && !state.flow) {
    return true;
  } else if (state.care === "58" && parseInt(state.flow) < 5) {
    return true;
  } else if (
    (state.care === "59" || state.care === "60") &&
    !state.single_period
  ) {
    return true;
  } else if (
    (state.care === "57" ||
      state.care === "58" ||
      state.care === "61" ||
      state.period === "Período Determinado") &&
    !state.quantity
  ) {
    return true;
  } else if (
    (state.care === "57" ||
      state.care === "58" ||
      state.care === "61" ||
      state.period === "Período Determinado") &&
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validatePersonalHygiene = state => {
  if (!state.care) {
    return true;
  } else if (
    ["30", "31", "32", "34", "35"].indexOf(state.care) === -1 &&
    !state.quantity
  ) {
    return true;
  } else if (
    ["30", "31", "32", "34", "35"].indexOf(state.care) === -1 &&
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validatePoints = state => {
  if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else {
    return false;
  }
};

const validateSondaVesical = state => {
  if (!state.care) {
    return true;
  } else if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validateVitalSings = state => {
  if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validateMoviment = state => {
  if (!state.care) {
    return true;
  } else if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validateInflatableLeggings = state => {
  if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validatePainScale = state => {
  if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};

const validateFallRisk = state => {
  if (!state.quantity) {
    return true;
  } else if (
    state.hours.map(row => moment(row, "HH:mm").isValid()).indexOf(false) !== -1
  ) {
    return true;
  } else if (!state.interval) {
    return true;
  } else if (parseInt(state.duration) <= 0) {
    return true;
  } else if (
    moment(state.start)
      .add("1", "days")
      .isBefore(moment())
  ) {
    return true;
  } else if (moment(state.end).isBefore(moment(state.start))) {
    return true;
  } else {
    return false;
  }
};
