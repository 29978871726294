import React from "react";
import { useSelector } from "react-redux";
import FormLogin from "components/Forms/Login";
import imgLogo from "assets/images/logo.svg";
import imgLogoSenior from "assets/images/Logo_Salutem_Senior-01.svg";
import moment from "moment";

const Login = ({ history }) => {
  const width = window.screen.width;
  const auth = useSelector((state) => state.auth.authUser);
  const area = useSelector((state) => state.auth.area);

  const hostname = window.location.hostname;

  React.useEffect(() => {
    let init = localStorage.getItem("init");
    if (auth && init && ["5", "6", "29"].indexOf(area) !== -1) {
      if (moment().isBefore(moment(init).add(12, "hours"))) {
        history.push("app/dashboard");
      }
    }
  }, []);

  const handleSuccess = (success) => {
    if (success) {
      history.push("app/dashboard");
    }
  };

  if (width < 948) {
    return (
      <div
        className="d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
        style={{
          margin: "0 auto",
          width: "100%",
          background:
            hostname === "senior.salutemplus.com" ? "#b6dce9" : "#177493",
        }}
      >
        <div className="row w-100">
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <img
              src={
                hostname === "senior.salutemplus.com" ? imgLogoSenior : imgLogo
              }
              style={{ width: "250px", cursor: "pointer" }}
              onClick={() => {
                window.location.href = "https://salutemplus.com";
              }}
            />
          </div>
          <div className="col-12" style={{ padding: "0 20px" }}>
            <FormLogin handleSuccess={handleSuccess} history={history} />
            {hostname === "senior.salutemplus.com" && (
              <div className="card mt-3">
                <span
                  className="py-3 text-center"
                  style={{ fontSize: "16px", fontWeight: "500" }}
                >
                  <span>Conheça nossas soluções</span> <br />
                  <a href="https://www.salutemplus.com/">www.salutemplus.com</a>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
        style={{
          margin: "0 auto",
          width: "100%",
          backgroundImage:
            hostname === "senior.salutemplus.com"
              ? `url(${require("assets/images/background_SalutemSenior.jpg")})`
              : `url(${require("assets/images/background-login.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
      >
        <div className="logo" style={{ marginRight: "75px" }}>
          <img
            src={
              hostname === "senior.salutemplus.com" ? imgLogoSenior : imgLogo
            }
            style={{ width: "250px", cursor: "pointer" }}
            onClick={() => {
              window.location.href = "https://salutemplus.com";
            }}
          />
        </div>
        <div style={{ marginRight: "400px" }}>
          <FormLogin handleSuccess={handleSuccess} history={history} />
          {hostname === "senior.salutemplus.com" && (
            <div className="card mt-3">
              <span
                className="py-3 text-center"
                style={{ fontSize: "16px", fontWeight: "500" }}
              >
                <span>Conheça nossas soluções</span> <br />
                <a href="https://www.salutemplus.com/">www.salutemplus.com</a>
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default Login;
