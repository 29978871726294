import React, { useState } from "react";
import {
  MenuItem,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import TextField from "components/TextFields";
import { useSelector, useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from "components/CircularProgress";
import ModalObservation from "components/Modal/Observation";
import AutocompleteCity from "components/Autocomplete/AutocompleteCity";
import moment from "moment";

const firstVaccine = [
  {
    id: "63",
    name: "Astrazeneca",
  },
  {
    id: "56",
    name: "Coronavac",
  },
  {
    id: "60",
    name: "Pfizer",
  },
  {
    id: "65",
    name: "Sputnik",
  },
  {
    id: "68",
    name: "Covaxin",
  },
  {
    id: "62",
    name: "Janssen (Dose Única)",
  },
];
const secondVaccine = [
  {
    id: "64",
    name: "Astrazeneca",
  },
  {
    id: "57",
    name: "Coronavac",
  },
  {
    id: "61",
    name: "Pfizer",
  },
  {
    id: "67",
    name: "Sputnik",
  },
  {
    id: "69",
    name: "Covaxin",
  },
];
const refVaccine = [
  {
    id: "70",
    name: "Astrazeneca",
  },
  {
    id: "72",
    name: "Coronavac",
  },
  {
    id: "71",
    name: "Pfizer",
  },
  {
    id: "75",
    name: "Sputnik",
  },
  {
    id: "74",
    name: "Covaxin",
  },
  {
    id: "73",
    name: "Janssen",
  },
];

export default function Vaccines({ record, setRecord }) {
  const [to_delete, setToDelete] = useState("");
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const objModalObs = {
    open: false,
    index: "",
    observation: "",
  };
  const [modalObs, setModalObs] = useState(objModalObs);
  const user = useSelector((state) => state.auth.authUser);
  const dispatch = useDispatch();

  const handleAdd = () => {
    setRecord([
      ...record,
      {
        id: "",
        vaccine: "",
        name: "",
        applied: "",
        date: "",
        observation: "",
        alter: false,
      },
    ]);
  };

  const handleCancel = (obj, index) => {
    if (!obj.id) {
      setRecord(record.filter((row, key) => key !== index));
    } else {
      setModalDelete(true);
      setToDelete(obj.id);
    }
  };

  const deleteVaccine = () => {
    Api.post(`/microservice/admission/removeVaccine/`, {
      vaccine: to_delete,
      user,
    })
      .then(() => {
        setModalDelete(false);
        setSuccessDelete(true);
        let aux = record.filter((row) => row.id !== to_delete);
        if (aux.length === 0) {
          setRecord([
            {
              id: "",
              vaccine: "",
              name: "",
              applied: "",
              date: "",
              observation: "",
              alter: false,
            },
          ]);
        } else {
          setRecord(aux);
        }
      })
      .catch(() => {
        setModalDelete(false);
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => {
          dispatch(closeAlertMessage());
        }, 5000);
      });
  };

  return (
    <form noValidate style={{ display: "contents" }}>
      <hr className="col-12" />
      {record.map((row, index) => {
        if (!row.view) {
          return (
            <div
              style={{
                display:
                  index !== 1
                    ? "contents"
                    : record[0].vaccine === "62"
                    ? "none"
                    : "contents",
              }}
            >
              <div
                className="col-md-1 col-12"
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {row.dose}
              </div>
              <div className="col-md-3 col-12">
                <TextField
                  select
                  fullWidth
                  margin="normal"
                  label="Vacina:"
                  value={row.vaccine}
                  onChange={(e) => {
                    setRecord(
                      record.map((val, key) =>
                        key === index
                          ? {
                              ...val,
                              vaccine: e.target.value,
                              name: e.nativeEvent.target.innerText,
                              alter: true,
                            }
                          : val
                      )
                    );
                  }}
                >
                  {(index === 0
                    ? firstVaccine
                    : index === 1
                    ? secondVaccine
                    : refVaccine
                  ).map((row) => (
                    <MenuItem key={row.id} value={row.id} name={row.name}>
                      {row.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-3 col-12">
                <FormControl component="fieldset" className="mt-3">
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={row.applied}
                    onChange={(e) => {
                      setRecord(
                        record.map((val, key) =>
                          key === index
                            ? {
                                ...val,
                                applied: e.target.value,
                                alter: true,
                              }
                            : val
                        )
                      );
                    }}
                    className="mt-1"
                    row
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          color="primary"
                          disabled={
                            index === 0
                              ? false
                              : record[index - 1].applied !== "1"
                          }
                        />
                      }
                      label="Aplicada"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label="Prevista"
                      labelPlacement="end"
                      disabled={index === 0}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-md-2 col-12">
                <TextField
                  type="date"
                  label={
                    row.applied === "1"
                      ? "Data da Aplicação:"
                      : row.applied === "0"
                      ? "Data Prevista"
                      : "Data"
                  }
                  fullWidth
                  value={row.date}
                  className="mt-1"
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.substr(1, 11);
                    }
                  }}
                  onChange={(e) => {
                    setRecord(
                      record.map((val, key) =>
                        key === index
                          ? {
                              ...val,
                              date: e.target.value,
                              alter: true,
                            }
                          : val
                      )
                    );
                  }}
                  inputProps={{
                    max:
                      row.applied === "1" ? moment().format("YYYY-MM-DD") : "",
                    min:
                      row.applied === "0" ? moment().format("YYYY-MM-DD") : "",
                  }}
                />
              </div>

              <div className="col-md-2 col-12">
                <AutocompleteCity
                  handleChange={(e) =>
                    setRecord(
                      record.map((val, key) =>
                        key === index
                          ? {
                              ...val,
                              city: e,
                              alter: true,
                            }
                          : val
                      )
                    )
                  }
                  label="Cidade:"
                  value={row.city}
                />
              </div>

              <div className="col-md-1 col-12">
                <Tooltip title="Adicionar observação">
                  <IconButton
                    arial-label="Add Observação"
                    onClick={() =>
                      setModalObs({
                        open: true,
                        index,
                        observation: row.observation,
                      })
                    }
                    color={row.observation ? "primary" : "default"}
                    className="mt-3"
                  >
                    <i className="zmdi zmdi-comment-edit" />
                  </IconButton>
                </Tooltip>
                {/* {record.length > 1 && (
                    <Tooltip title="Remover registro">
                      <IconButton
                        aria-label="Delete"
                        color="secondary"
                        onClick={() => handleCancel(row, index)}
                        className="mt-3"
                      >
                        <i className="zmdi zmdi-delete" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {index === record.length - 1 && (
                    <Tooltip title="Adicionar novo registro">
                      <IconButton
                        color="primary"
                        onClick={() => handleAdd(row)}
                        className="mt-3"
                      >
                        <i className="zmdi zmdi-plus" />
                      </IconButton>
                    </Tooltip>
                  )} */}
              </div>
              <hr className="col-12" />
            </div>
          );
        } else {
          return (
            <>
              <div className="col-md-1 col-12">{row.dose}</div>
              <div className="col-md-3 col-12">
                <strong>{row.name}</strong>
              </div>
              <div className="col-md-2 col-xs-6">Aplicada</div>
              <div className="col-md-2 col-xs-6">
                {moment(row.date).format("DD/MM/YYYY")}
              </div>
              <div className="col-md-2 col-12">{row.city}</div>
              <div className="col-md-2 col-12">{row.observation}</div>
              <hr className="col-12" />
            </>
          );
        }
      })}
      <SweetAlert
        show={modal_delete}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, remover"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Confirma a remoção desta vacina?"
        onConfirm={deleteVaccine}
        onCancel={() => setModalDelete(false)}
      />
      <SweetAlert
        show={success_delete}
        success
        title="Vacina removido com sucesso!"
        onConfirm={() => setSuccessDelete(false)}
      />
      <ModalObservation
        open={modalObs.open}
        handleClose={() => setModalObs(objModalObs)}
        value={modalObs.observation}
        changeObservation={(value) =>
          setRecord(
            record.map((val, key) =>
              key === modalObs.index
                ? {
                    ...val,
                    observation: value,
                    alter: true,
                  }
                : val
            )
          )
        }
      />
    </form>
  );
}
