import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
  TableCell
} from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import * as AddProcedureActions from "actions/configuration/AddProcedure";
import { bindActionCreators } from "redux";
import NoRecord from "components/Alert/NoRecord";

class ViewAddProcedure extends Component {
  state = {
    modal_delete: false,
    idProcedureDelete: "",
    success_delete: false,
    success_add: false,
    children_form: ""
  };

  handleAdd = id => event => {
    this.props.handleAddFunction(
      "form",
      "secondary",
      "cancelar",
      true,
      "close",
      id
    );
  };

  modalConfirmStats() {
    this.setState({ modal_delete: true });
  }

  componentWillMount() {
    this.props.requestListDoctorProcedures(this.props.doctor);
  }

  render() {
    const headerTable = {
      color: "rgba(0, 0, 0, 0.35)",
      height: "40px"
    };

    const doctorProcedures = this.props.AddProcedure.listDoctorProcedures;
    return (
      <form>
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto"
          }}
        >
          {" "}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={headerTable}> Procedimento </TableCell>
                <TableCell style={headerTable}>Custo</TableCell>
                <TableCell style={headerTable} align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doctorProcedures.map(listProcedures => {
                return (
                  <TableRow key={listProcedures.id}>
                    <TableCell>{listProcedures.description}</TableCell>
                    <TableCell>R${listProcedures.price}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Editar">
                        <IconButton
                          arial-label="Editar"
                          color="primary"
                          onClick={this.handleAdd(
                            listProcedures.fk_ls_cbhpm_procedure
                          )}
                        >
                          <i className="zmdi zmdi-edit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Excluir">
                        <IconButton
                          arial-label="Excluir"
                          color="secondary"
                          onClick={() =>
                            this.setState({
                              modal_delete: true,
                              idProcedureDelete:
                                listProcedures.fk_ls_cbhpm_procedure
                            })
                          }
                        >
                          <i className="zmdi zmdi-delete" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
              {doctorProcedures.length === 0 && (
                <TableRow key="1">
                  <TableCell colSpan={4}>
                    <NoRecord />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <SweetAlert
          show={this.state.modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, excluir"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Excluir este Grupo?"
          onConfirm={() => {
            this.setState({ modal_delete: false });
            this.props.delProcedure(
              this.state.idProcedureDelete,
              this.props.doctor
            );
          }}
          onCancel={() => this.setState({ modal_delete: false })}
        />
        {/* <SweetAlert
          show={this.props.MedicinesGroups.modalSuccessDeleteGroup}
          success
          title="Grupo excluido com sucesso!"
          onConfirm={() => this.props.closeModalDeleteSuccess()}
        /> */}
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    doctor: state.auth.authUser,
    AddProcedure: state.AddProcedure
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(AddProcedureActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAddProcedure);
