const INITIAL_STATE = {
  loader: true,
  listDysf: [],
  listuserDysfs: [],
  listSn: [],
  listuserSn: [],

  active: "view",
  color: "primary",
  texto: "Adicionar",
  hidden: "hidden",
  icon: "edit",
  //Special Needs
  activeSpecialNeeds: "view",
  textoSpecialNeeds: "Adicionar",
  colorSpecialNeeds: "primary",
  hiddenSpecialNeeds: "hidden",
  iconSpecialNeeds: "edit",
};

export default function dysfunctions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "HANDLE_ADD_FUNC":
      return {
        ...state,
        active: action.payload.active,
        color: action.payload.color,
        texto: action.payload.texto,
        hidden: action.payload.hidden,
        icon: action.payload.icon,
      };
    case "HANDLE_ADD_SN_FUNC":
      return {
        ...state,
        activeSpecialNeeds: action.payload.active,
        colorSpecialNeeds: action.payload.color,
        textoSpecialNeeds: action.payload.texto,
        hiddenSpecialNeeds: action.payload.hidden,
        iconSpecialNeeds: action.payload.icon,
      };

    case "REQUEST_LIST_DYSF":
      return {
        ...state,
        loader: true,
      };
    case "REQUEST_LIST_DYSF_SUCCESS":
      return {
        ...state,
        loader: false,
        listDysf: action.payload.listDysf,
      };
    case "CHANGE_SWITCH_DYSF":
      let aux = [];
      state.listDysf.forEach((element, key) => {
        if (key === action.payload.index) {
          aux.push({
            ...element,
            check: action.payload.check,
          });
        } else {
          aux.push(element);
        }
      });
      return {
        ...state,
        listDysf: [...aux],
        // listDysf[action.payload.index].check =
        // loader: true
      };
    case "CHANGE_OBS_DYSF":
      let auxObs = [];
      state.listDysf.forEach((element, key) => {
        if (key === action.payload.index) {
          auxObs.push({
            ...element,
            obs: action.payload.value,
          });
        } else {
          auxObs.push(element);
        }
      });
      return {
        ...state,
        listDysf: [...auxObs],
        // listDysf[action.payload.index].check =
        // loader: true
      };
    case "SAVE_DYSF":
      return {
        ...state,
        loader: true,
        doctor: action.payload.doctor,
        patient: action.payload.patient,
        data: action.payload.data,
        notApplicable: action.payload.notApplicable,
      };

    case "REQUEST_USER_DYSFUNCTIONS":
      return {
        ...state,
        loader: true,
        patient: action.payload.patient,
      };

    case "REQUEST_USER_DYSF_SUCCESS":
      return {
        ...state,
        loader: false,
        listuserDysfs: action.payload.listuserDysfs,
        texto: action.payload.listuserDysfs.length > 0 ? "Editar" : "Adicionar",
      };

    /* REDUCERS ESPECIAL NEEDS*/
    /*BUSCAR A LISTA DE NECESSIDADES ESPECIAIS CADASTRADAS NO BANCO*/
    case "REQUEST_LIST_SN":
      return {
        ...state,
        loader: true,
      };

    case "REQUEST_LIST_SN_SUCCESS":
      return {
        ...state,
        loader: false,
        listSn: action.payload.listSn,
      };

    case "REQUEST_USER_SN_SUCCESS":
      return {
        ...state,
        loader: false,
        listuserSn: action.payload.listuserSn,
        textoSpecialNeeds:
          action.payload.listuserSn.length > 0 ? "Editar" : "Adicionar",
      };

    case "CHANGE_SWITCH_SN":
      let auxSn = [];
      state.listSn.forEach((element, key) => {
        if (key === action.payload.index) {
          auxSn.push({
            ...element,
            check: action.payload.check,
          });
        } else {
          auxSn.push(element);
        }
      });
      return {
        ...state,
        listSn: [...auxSn],
      };
    case "CHANGE_OBS_SN":
      let auxObsSN = [];
      state.listSn.forEach((element, key) => {
        if (key === action.payload.index) {
          auxObsSN.push({
            ...element,
            obs: action.payload.value,
          });
        } else {
          auxObsSN.push(element);
        }
      });
      return {
        ...state,
        listSn: [...auxObsSN],
      };

    case "SAVE_SN":
      return {
        ...state,
        loader: true,
        doctor: action.payload.doctor,
        patient: action.payload.patient,
        data: action.payload.data,
        notApplicable: action.payload.notApplicable,
      };
    case "REQUEST_USER_SN":
      return {
        ...state,
        loader: true,
        patient: action.payload.patient,
      };
    case "HANDLE_VALIDATE_DYSFUNCTION":
      return {
        ...state,
        listuserDysfs: state.listuserDysfs.map((row) =>
          row.id === action.payload.id
            ? { ...row, approved_by: localStorage.getItem("userAudit") }
            : row
        ),
      };
    case "HANDLE_VALIDATE_SN":
      return {
        ...state,
        listuserSn: state.listuserSn.map((row) =>
          row.id === action.payload.id
            ? { ...row, approved_by: localStorage.getItem("userAudit") }
            : row
        ),
      };
    case "RESET_DYSFUNCTIONS":
      return INITIAL_STATE;
    default:
      return state;
  }
}
