import React from "react";
import { useDispatch } from "react-redux";
import { Tooltip, IconButton } from "@material-ui/core";
import ProfileNew from "components/ComponentsProfile/ProfileNew";
import HistoryPatient from "components/HistoryPatient";
import { viewHistory } from "actions/consult/Appointment";
import { getInfoPatient } from "actions/consult/PatientInfo";
import HistoryIcon from "@material-ui/icons/History";
import ViewAppointment from "./ViewAppointment";
import DataTable from "components/Tables/DataTable";
import SearchComponent from "components/SearchComponent";
import { Card, CardBody } from "reactstrap";
import { getStringToSearch } from "services/functions";

export default function ListPatient({ record, viewPatient }) {
  const [patient, setPatient] = React.useState("");
  const [viewDataAppointment, setViewDataAppointment] = React.useState({
    open: false,
    appointment: {},
  });
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();

  return (
    <Card className="shadow border-0 mb-3">
      <CardBody>
        <div className="row">
          <div className="col-12">
            <h2 className="font-weight-semibold d-flex">
              <span className="mt-3 mr-5">Atendimentos</span>
              {record.length > 0 && (
                <div className="w-100 pl-3">
                  <SearchComponent searchContact={(e) => setSearch(e)} />
                </div>
              )}
            </h2>
          </div>
          <div className="col-12">
            <DataTable
              name={""}
              rows={record
                .filter(
                  (row) =>
                    search.length === 0 ||
                    getStringToSearch(row.patient_name).includes(
                      getStringToSearch(search)
                    ) ||
                    getStringToSearch(row.date ? row.date : "").includes(
                      getStringToSearch(search)
                    ) ||
                    getStringToSearch(
                      row.professional_name ? row.professional_name : ""
                    ).includes(getStringToSearch(search))
                )
                .map((row, index) => [
                  row.date,
                  <Tooltip title="Visualizar Informações do Paciente">
                    <div
                      style={{
                        display: "-webkit-inline-box",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        viewPatient({
                          id: row.patient,
                          name: row.patient_name,
                          photo: row.photo,
                          birthday: row.birthday,
                          mother_name: row.mother_name,
                          cpf: row.cpf,
                          cns: row.cns,
                        })
                      }
                    >
                      <ProfileNew
                        profile={{
                          id: row.patient,
                          name: row.patient_name,
                          photo: row.photo,
                          birthday: row.birthday,
                          mother_name: row.mother_name,
                          cpf: row.cpf,
                          cns: row.cns,
                        }}
                      />
                    </div>
                  </Tooltip>,
                  row.professional_name,
                  [
                    <div style={{ whiteSpace: "nowrap" }}>
                      <Tooltip title="Visualizar Informações do Paciente">
                        <IconButton
                          onClick={() =>
                            viewPatient({
                              id: row.patient,
                              name: row.patient_name,
                              photo: row.photo,
                              birthday: row.birthday,
                              mother_name: row.mother_name,
                              cpf: row.cpf,
                              cns: row.cns,
                            })
                          }
                          style={{ padding: "6px" }}
                        >
                          <i className="zmdi zmdi-sign-in zmdi-hc-fw text-primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Histórico do paciente" placement="top">
                        <IconButton
                          style={{ padding: "6px" }}
                          onClick={() => {
                            setPatient(row.patient);
                            dispatch(viewHistory("personalData"));
                            dispatch(getInfoPatient(row.patient));
                          }}
                        >
                          <HistoryIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Visualizar Dados do Atendimento">
                        <IconButton
                          style={{ padding: "6px" }}
                          color="primary"
                          onClick={() =>
                            setViewDataAppointment({
                              open: true,
                              appointment: {
                                patient: row.patient,
                                patient_name: row.patient_name,
                                photo: row.photo,
                                patient_birthday: row.birthday,
                                mother_name: row.mother_name,
                                cpf: row.cpf,
                                cns: row.cns,
                                id: row.id,
                                doctor: row.doctor,
                              },
                            })
                          }
                        >
                          <i className="zmdi zmdi-eye zmdi-fw" />
                        </IconButton>
                      </Tooltip>
                    </div>,
                  ],
                ])}
              columns={["Data", "Paciente", "Profissional", "Ações"]}
              hideSearch={true}
            />
          </div>

          <HistoryPatient id_patient={patient} />
          <ViewAppointment
            open={viewDataAppointment.open}
            appointment={viewDataAppointment.appointment}
            handleClose={() =>
              setViewDataAppointment({ open: false, appointment: {} })
            }
          />
        </div>
      </CardBody>
    </Card>
  );
}
