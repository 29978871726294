import React, { useEffect, useState } from "react";
import ValidateDigitalSignature from "./validateDigitalSignature";
import { ModalResponsive } from "components/ModalResponsive";
import Login from "components/Forms/Login";

export default function FormPrescSignedLogin({
  open,
  handleClose,
  arrFiles,
  logged,
  handleLogged,
}) {
  const handleSuccess = (success, id) => {
    if (success) {
      handleLogged();
    }
  };

  return (
    <div>
      <ModalResponsive
        title={"Validação de Assinatura Digital"}
        open={open}
        close={() => {
          handleClose();
        }}
        maxWidth={"sm"}
        buttons={
          <div className="col-12">
            <>
              {/* <BtnCan
                title="Fechar"
                onClick={() => {
                  close();
                }}
                float="left"
              /> */}

              {/* <BtnSave title="Salvar" onClick={() => close()} float="right" /> */}
            </>
          </div>
        }
      >
        {/* {logged ? ( */}
        <ValidateDigitalSignature
          open={true}
          close={() => {
            handleClose();
          }}
          arrFiles={arrFiles}
        />
        {/* ) : (
          <Login
            hiddenHeader={true}
            handleSuccess={handleSuccess}
            // noRender={true}
            prescription={true}
          />
        )} */}
      </ModalResponsive>
    </div>
  );
}
