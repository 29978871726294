import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";

import { Typography, makeStyles } from "@material-ui/core";
import { ButtonLarge } from "components/Button";
import CustomSlider from "components/CustomSlider";

import Dr_prancheta from "assets/images/dr_prancheta.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    width: "100%",
  },
  dFlex: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  box: {
    border: "solid 2px #c1c1c1",
    borderRadius: "25px",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
  },
  icon: {
    overflow: "inherit",
    fontSize: "40px",
    width: "auto",
    height: "auto",
  },
  positionBad: {
    position: "absolute",
    bottom: "-5px",
    left: 0,
  },
  positionGood: {
    position: "absolute",
    bottom: "-5px",
    right: 0,
  },
}));
export default function AboutDay({ handleNext, setAboutDay }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = useState({
    mood: "",
  });

  function handleSave() {
    Api.post("microservice/patientportal/saveAboutDay", state)
      .then(({ data }) => {
        if (data.success) {
          handleNext();
          setAboutDay(data.aboutDay);
          localStorage.setItem("savedQuizze", "1");
        } else {
          throw true;
        }
      })
      .catch(() => {
        showMsg("error", "Não foi possível savar as informações", dispatch);
      });
  }
  return (
    <div className={classes.root}>
      <div className={classes.dFlex}>
        <div className="col-12 col-sm-12 col-md-8">
          <Typography variant="subtitle1" gutterBottom>
            <strong>Como está sua energia/disposição de 1 a 10?</strong>
          </Typography>
          <div className="row px-4 mt-4">
            <div className="col-12">
              <span className={classes.positionBad}>Ruim</span>
              <CustomSlider
                step={1}
                marks
                min={1}
                max={10}
                value={state.mood}
                onChange={(event, newValue) =>
                  setState({ ...state, mood: newValue })
                }
                style={{
                  color: !state.mood
                    ? "#d1d1d1"
                    : state.mood >= 8
                    ? "#00B1E2"
                    : state.mood > 4
                    ? "#EEE800"
                    : "#D6201B",
                }}
                valueLabelDisplay="on"
                aria-labelledby="continuous-slider"
              />
              <span className={classes.positionGood}>Boa</span>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-4 justify-content-end">
          <img src={Dr_prancheta} alt="Dra. Prancehta" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ButtonLarge
            onClick={handleNext}
            style={{
              padding: "1px",
              width: "unset",
              minWidth: "100px",
              float: "left",
            }}
          >
            Sair
          </ButtonLarge>
          <ButtonLarge
            onClick={state.mood ? handleSave : () => {}}
            style={{
              padding: "1px",
              width: "unset",
              minWidth: "100px",
              float: "right",
              backgroundColor: state.mood ? "#3d8b40" : "unset",
              color: state.mood ? "#fff" : "#8c8c8c",
            }}
          >
            Confirmar
          </ButtonLarge>
        </div>
      </div>
    </div>
  );
}
