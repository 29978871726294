import React from "react";
// import MUIDataTable from 'mui-datatables';
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = theme => ({
  tableStyle: {
    backgroundColor: "white",
    textShadow: "none",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.75)",
    marginBottom: 0,
    with: "100"
  }
});

function TableAga({ dados, handleClickRegister }) {
  const handleClick = id => {
    handleClickRegister(id);
  };

  return (
    <React.Fragment>
      <div
        className="col-12"
        style={{
          minWidth: "650",
          overflowX: "auto"
        }}
      >
        <Table className="table-history">
          <TableHead>
            <TableRow>
              <TableCell>Profissional</TableCell>
              <TableCell align="left">Data</TableCell>
              <TableCell align="left">Resposta</TableCell>
              <TableCell align="left">total</TableCell>
              <TableCell align="left">Inf</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dados.map(ele => (
              <TableRow key={ele.id}>
                <TableCell align="left">{ele.professional}</TableCell>
                <TableCell align="left">{ele.created_at}</TableCell>
                <TableCell align="left">
                  {ele.description !== null ? ele.description : " - "}
                </TableCell>
                <TableCell align="left">
                  {ele.total_answer !== null ? ele.total_answer : "-"}
                </TableCell>
                <TableCell align="left">
                  <IconButton
                    onClick={() =>
                      handleClick({ id: ele.id, name: ele.professional })
                    }
                    color="primary"
                    aria-label="Visualizar"
                  >
                    <i className="zmdi zmdi-eye zmdi-hc-fw" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(TableAga);
