const INITIAL_STATE = {
  list: [],
  selected: {
    fk_ls_user: "",
    name: "",
    photo: "",
    address_id: "",
    hidden_cares: null,
  },
};

export default function familyAccompaniment(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_EVOLUTION_ACCOMPANIMENT":
      return INITIAL_STATE;
    case "CHOOSE_PATIENT_ACCOMPANIMENT":
      return {
        ...state,
        selected: action.patient,
      };
    case "SET_LIST_PARENTS":
      return {
        list: action.list,
        selected: action.list.length === 1 ? action.list[0] : state.selected,
      };

    default:
      return state;
  }
}
