export const saveForward = (patient, doctor, data, appointment) => ({
  type: "REQUEST_SAVE_FORWARD",
  patient,
  doctor,
  data,
  appointment
});

export const getListForward = patient => ({
  type: "GET_LIST_FORWARD",
  patient
});
export const getForwardAppointment = appointment => ({
  type: "GET_FORWARD_APPOINTMENT",
  appointment
});

export const removeForward = (idForward, patient) => ({
  type: "REQUEST_REMOVE_FORWARD",
  idForward,
  patient
});
