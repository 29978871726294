import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import Api from "services/api";
import { showMsg } from "services/functions";
import AutocompleteCid from "components/Autocomplete/Cid";

export default function FormDiagnosticGroups({
  open,
  data,
  handleEdit,
  handleAdd,
  doctor,
  onClose,
}) {
  const [state, setState] = useState({
    id: "",
    name: "",
    description: "",
    list: [],
  });
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.id) {
      Api.post(`/microservice/diagnosticgroup/getItensGroup/`, {
        id: data.id,
      }).then((res) => {
        setState({
          id: data.id,
          name: data.name,
          description: data.description,
          list: res.data.record,
        });
      });
    }
  }, []);

  const handleChange = (field, value) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleChangeObs = (value, index) => {
    setState({
      ...state,
      list: state.list.map((row, key) =>
        key === index ? { ...row, observation: value } : row
      ),
    });
  };

  const handleRemove = (index) => {
    setState({
      ...state,
      list: state.list.filter((row, key) => key !== index),
    });
  };

  const handleSave = () => {
    if (state.name && state.list.length > 0) {
      Api.post(`/microservice/diagnosticgroup/saveDiagnosticGroup/`, {
        doctor,
        ...state,
      })
        .then((res) => {
          let obj = {
            id: res.data.id,
            name: state.name,
            description: state.description,
          };
          if (data.id) {
            handleEdit(obj);
          } else {
            handleAdd(obj);
          }
          onClose();
          showMsg("success", "Salvo com sucesso!", dispatch);
        })
        .catch(() => showMsg("error", "Ocorreu um erro!", dispatch));
    } else {
      setError(true);
    }
  };

  return (
    <ModalResponsive
      title="Novo grupo de diagnósticos"
      open={open}
      close={onClose}
      maxWidth="md"
      buttons={
        <div className="col-12">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={onClose}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>

          <Button
            color="primary"
            className="jr-btn jr-btn-sm bg-success"
            onClick={handleSave}
            variant="contained"
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar grupo</span>
          </Button>
        </div>
      }
    >
      <div className="row">
        <div className="col-md-6">
          <TextField
            id="name"
            label="Nome do Grupo"
            value={state.name}
            onChange={(e) => handleChange("name", e.target.value)}
            fullWidth
            error={error && !state.name}
          />
        </div>
        <div className="col-md-6">
          <TextField
            id="Descricao"
            label="Descrição"
            value={state.description}
            onChange={(e) => handleChange("description", e.target.value)}
            fullWidth
          />
        </div>

        <div className="col-md-12 mt-3">
          <AutocompleteCid
            handleChangeAutocomplete={(e) => {
              if (e.id) {
                setState({
                  ...state,
                  list: [
                    ...state.list,
                    {
                      id: e.id,
                      name: e.short_description,
                      observation: "",
                    },
                  ],
                });
              }
            }}
            error={error && !state.cid}
          />
        </div>

        {state.list.length > 0 && (
          <div className="col-md-12">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Diagnóstico (CID)</TableCell>
                  <TableCell>Observação</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.list.map((cid, index) => {
                  return (
                    <TableRow key={cid.id}>
                      <TableCell align="left" width="60%">
                        {cid.name}
                      </TableCell>
                      <TableCell width="30%">
                        <TextField
                          fullWidth
                          placeholder="Observações"
                          onChange={(e) =>
                            handleChangeObs(e.target.value, index)
                          }
                          value={cid.observation}
                        />
                      </TableCell>
                      <TableCell align="left" width="10%">
                        <Tooltip title="Excluir">
                          <IconButton
                            arial-label="Excluir"
                            color="secondary"
                            onClick={() => handleRemove(index)}
                          >
                            <i className="zmdi zmdi-delete" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </ModalResponsive>
  );
}
