import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getPersonalData,
  getProviderData,
} from "actions/configuration/Profile";
import { Card, CardBody } from "reactstrap";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import BasicData from "./basicData/index";
import HealthPlan from "./healthPlan";
import PersonAddress from "./personAddress";
import EmergencyContact from "./emergencyContact";
import AdditionalData from "./additionalData/index";
import Childrens from "./groupFamily";
import Specialty from "./specialty";
import BasicDataProvider from "./basicDataProvider";
import ProfessionalData from "./professionalData";
import LegalRepresentative from "./LegalRepresentative";
import LegalAddress from "./legalAddress";
import "./styles.css";

class Profile extends Component {
  componentDidMount() {
    if (this.props.typeUser === "1") {
      this.props.getProviderData(this.props.user);
    } else {
      this.props.getPersonalData(this.props.user);
    }
  }

  render() {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        {this.props.match ? (
          <ContainerHeader
            title={<IntlMessages id="module.configuration.profile" />}
            match={this.props.match}
          />
        ) : (
          ""
        )}
        {this.props.typeUser === "1" ? (
          <div>
            <Card className="border">
              <CardBody>
                <BasicDataProvider />
              </CardBody>
            </Card>
            <Card className="border">
              <CardBody>
                <LegalRepresentative />
              </CardBody>
            </Card>
            <Card className="border">
              <CardBody>
                <LegalAddress />
              </CardBody>
            </Card>
          </div>
        ) : (
          <div>
            <Card className="border">
              <CardBody>
                <BasicData />
              </CardBody>
            </Card>
            {this.props.typeUser === "0" && (
              <Card className="border">
                <CardBody>
                  <ProfessionalData />
                </CardBody>
              </Card>
            )}
            <Card className="border">
              <CardBody>
                <Specialty />
              </CardBody>
            </Card>
            <Card className="border">
              <CardBody>
                <HealthPlan />
              </CardBody>
            </Card>

            <Card className="border">
              <CardBody>
                <PersonAddress />
              </CardBody>
            </Card>

            <Card className="border">
              <CardBody>
                <EmergencyContact />
              </CardBody>
            </Card>

            <Card className="border">
              <CardBody>
                <AdditionalData />
              </CardBody>
            </Card>

            <Card className="border">
              <CardBody>
                <Childrens />
              </CardBody>
            </Card>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.authUser,
    typeUser: state.auth.type,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getPersonalData, getProviderData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
