import React from "react";

import moment from "moment";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import StopIcon from "@material-ui/icons/Stop";
import ReactHtmlParser from "react-html-parser";

const synth = window.speechSynthesis;

export default function DataConsult({ dataConsult }) {
  const [speech, setSpeech] = React.useState(false);
  const FIELD_NAMES = {
    examsRequest: "Pedido de Exame",
    forward: "Encaminhamento do Paciente",
    prescription: "Prescrição Industrializada",
    prescriptionManipulated: "Prescrição Manipulada",
    prescriptionCare: "Prescrição de Cuidados",
  };

  function startSpeech() {
    setSpeech(true);
    let temp = document.getElementById("dt-consult");
    let text = temp.textContent || temp.innerText || "";
    const utterThis = new SpeechSynthesisUtterance(text);
    utterThis.lang = "pt-BR";
    synth.speak(utterThis);
  }

  function stopSpeech() {
    synth.cancel();
    setSpeech(false);
  }

  return (
    <div id="dt-consult">
      {/* <center> */}
      <h4
        className="text-primary"
        style={{
          width: "100%",
          marginTop: "40px",
          justifyContent: "space-between",
        }}
      >
        Registro do atendimento
        {synth && (
          <Tooltip title={speech ? "Parar" : "Falar"}>
            <IconButton
              color={speech ? "secondary" : "primary"}
              onClick={speech ? stopSpeech : startSpeech}
            >
              {speech ? <StopIcon /> : <RecordVoiceOverIcon />}
            </IconButton>
          </Tooltip>
        )}
      </h4>
      {/* </center> */}
      {dataConsult.appointment.free_text && (
        <div className="col-12 ">
          <h4 className="font-weight-semibold"> Informações da consulta:</h4>
          {dataConsult.appointment.free_text !== "" && (
            <p> {dataConsult.appointment.free_text} </p>
          )}
        </div>
      )}

      {dataConsult.restrict_info && (
        <div className="col-12 ">
          <h4 className="font-weight-semibold"> Informações Restritas:</h4>
          {dataConsult.restrict_info.free_text !== "" && (
            <p> {dataConsult.restrict_info.free_text} </p>
          )}
        </div>
      )}

      {dataConsult.diagDetails.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CID(Diágnostico)</TableCell>
              <TableCell>Suspeito</TableCell>
              <TableCell>Confirmado</TableCell>
              <TableCell>Crônico</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataConsult.diagDetails.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                  {row.date_suspect
                    ? moment(row.date_suspect).format("DD/MM/YYYY")
                    : ""}
                </TableCell>
                <TableCell>
                  {row.date_confirm
                    ? moment(row.date_confirm).format("DD/MM/YYYY")
                    : ""}
                </TableCell>
                <TableCell>
                  {row.is_cronic ? (row.is_cronic === "0" ? "Não" : "Sim") : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {dataConsult.procedures.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Procedimento</TableCell>
              <TableCell>Observação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataConsult.procedures.map((row, index) => (
              <TableRow key={index}>
                <TableCell width="50%">
                  {row.code_id},{row.description}
                </TableCell>
                <TableCell width="50%">{row.obs}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {dataConsult.appointment.reason_child && (
        <div className="col-md-12" style={{ marginTop: "20px" }}>
          <h4 className="font-weight-semibold"> Motivo da consulta </h4>
          <span style={{ marginLeft: "10px" }}>
            {dataConsult.appointment.reason_child}
          </span>
        </div>
      )}

      {dataConsult.vital_signs ? (
        <div className="col-md-12" style={{ marginTop: "20px" }}>
          {(dataConsult.vital_signs.weight ||
            dataConsult.vital_signs.tall ||
            dataConsult.vital_signs.imc ||
            dataConsult.vital_signs.class_imc ||
            dataConsult.antopometry.length > 0) && (
            <div className="row" style={{ padding: "10px" }}>
              <h4 className="font-weight-semibold w-100">Antopometria</h4>
              {dataConsult.vital_signs.weight && (
                <div className="col-md-3 col-xs-4">
                  <span>
                    Peso: {dataConsult.vital_signs.weight.replace(".", ",")}
                  </span>
                </div>
              )}
              {dataConsult.vital_signs.tall && (
                <div className="col-md-3 col-xs-4">
                  <span>Altura: {dataConsult.vital_signs.tall}</span>
                </div>
              )}
              {dataConsult.vital_signs.imc && (
                <div className="col-md-3 col-xs-4">
                  <span>
                    I.M.C:{" "}
                    {dataConsult.vital_signs.imc
                      ? dataConsult.vital_signs.imc.replace(".", ",")
                      : ""}
                  </span>
                </div>
              )}
              {dataConsult.vital_signs.class_imc && (
                <div className="col-md-3 col-xs-4">
                  <span>Classe I.M.C: {dataConsult.vital_signs.class_imc}</span>
                </div>
              )}

              {dataConsult.antopometry.length > 0 && (
                <>
                  {dataConsult.antopometry[0].c_abdominal && (
                    <div className="col-md-3 col-xs-4">
                      <span>
                        Circunf. abdominal:{" "}
                        {dataConsult.antopometry[0].c_abdominal
                          ? dataConsult.antopometry[0].c_abdominal.replace(
                              ".",
                              ","
                            )
                          : ""}
                      </span>
                    </div>
                  )}
                  {dataConsult.antopometry[0].class_abdominal && (
                    <div className="col-md-6 col-xs-4">
                      <span>
                        Classif. Circunf. abdominal:{" "}
                        {dataConsult.antopometry[0].class_abdominal
                          ? dataConsult.antopometry[0].class_abdominal
                          : ""}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          <div className="row" style={{ padding: "10px" }}>
            <h4 className="font-weight-semibold w-100">Dados Vitais</h4>

            {dataConsult.vital_signs.naf && (
              <div className="col-md-3 col-xs-4">
                <span>
                  N.A.F:{" "}
                  {dataConsult.vital_signs.naf === "1"
                    ? "Sedentária"
                    : dataConsult.vital_signs.naf === "2"
                    ? "Leve"
                    : dataConsult.vital_signs.naf === "3"
                    ? "Moderada"
                    : "Intensa"}
                </span>
              </div>
            )}

            {dataConsult.vital_signs.at && (
              <div className="col-md-3 col-xs-4">
                <span>Temp. Aux: {dataConsult.vital_signs.at}</span>
              </div>
            )}
            {dataConsult.vital_signs.saturation && (
              <div className="col-md-3 col-xs-4">
                <span>Saturação: {dataConsult.vital_signs.saturation}%</span>
              </div>
            )}
            {dataConsult.vital_signs.vesicular_murmur && (
              <div className="col-md-3 col-xs-4">
                <span>
                  Múrmurio vesicular:{" "}
                  {dataConsult.vital_signs.vesicular_murmur === "0"
                    ? "Não"
                    : "Fisiológico"}
                </span>
              </div>
            )}
            {dataConsult.vital_signs.which_murmur && (
              <div className="col-md-3 col-xs-4">
                <span>
                  Tipo murmúrio: {dataConsult.vital_signs.which_murmur}
                </span>
              </div>
            )}
            {dataConsult.vital_signs.sleep_rest && (
              <div className="col-md-3 col-xs-4">
                <span>
                  Sono e repouso: {dataConsult.vital_signs.sleep_rest}
                </span>
              </div>
            )}
            {dataConsult.vital_signs.sleep_aid && (
              <div className="col-md-3 col-xs-4">
                <span>
                  Qual auxíliar do sono: {dataConsult.vital_signs.sleep_aid}
                </span>
              </div>
            )}
            {dataConsult.vital_signs.level_consciousness && (
              <div className="col-md-3 col-xs-4">
                <span>
                  Nível de consciência:{" "}
                  {dataConsult.vital_signs.level_consciousness}
                </span>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {dataConsult.glice.length > 0 && (
        <>
          <h4 className="font-weight-semibold col-12 mt-20">
            Glicemia Capilar
          </h4>
          <div className="col-md-12 d-flex">
            {dataConsult.glice.map((row) => (
              <div className="col-md-3 col-xs-4">
                <span>Horário: {row.hour.substr(0, 5)}</span>
                <br />
                <span>Mg/dl: {row.value}</span>
              </div>
            ))}
          </div>
        </>
      )}

      {dataConsult.vital_signs && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>P.A.S</TableCell>
              <TableCell>P.A.D</TableCell>
              <TableCell>F.C.Basal</TableCell>
              <TableCell>F.C.Repouso</TableCell>
              <TableCell>F.C.Máxima</TableCell>
              <TableCell>F.C.Reserva</TableCell>
              <TableCell>F.C.Respiratoria</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Ortostatismo 0 min.</TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost0_pas != ""
                  ? dataConsult.vital_signs.ost0_pas
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost0_pad != ""
                  ? dataConsult.vital_signs.ost0_pad
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost0_fcbasal != ""
                  ? dataConsult.vital_signs.ost0_fcbasal
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost0_fcrep != ""
                  ? dataConsult.vital_signs.ost0_fcrep
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost0_fcmax != ""
                  ? dataConsult.vital_signs.ost0_fcmax
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost0_fcrsv != ""
                  ? dataConsult.vital_signs.ost0_fcrsv
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost0_fcrsp != ""
                  ? dataConsult.vital_signs.ost0_fcrsp
                  : "---"}
              </TableCell>
            </TableRow>
            {/* Linha ort1 */}
            <TableRow>
              <TableCell>Ortostatismo 1 min.</TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost1_pas != ""
                  ? dataConsult.vital_signs.ost1_pas
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost1_pad != ""
                  ? dataConsult.vital_signs.ost1_pad
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost1_fcbasal != ""
                  ? dataConsult.vital_signs.ost1_fcbasal
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost1_fcrep != ""
                  ? dataConsult.vital_signs.ost1_fcrep
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost1_fcmax != ""
                  ? dataConsult.vital_signs.ost1_fcmax
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost1_fcrsv != ""
                  ? dataConsult.vital_signs.ost1_fcrsv
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost1_fcrsp != ""
                  ? dataConsult.vital_signs.ost1_fcrsp
                  : "---"}
              </TableCell>
            </TableRow>

            {/* Linha ort3 */}
            <TableRow>
              <TableCell>Ortostatismo 3 min.</TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost3_pas != ""
                  ? dataConsult.vital_signs.ost3_pas
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost3_pad != ""
                  ? dataConsult.vital_signs.ost3_pad
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost3_fcbasal != ""
                  ? dataConsult.vital_signs.ost3_fcbasal
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost3_fcrep != ""
                  ? dataConsult.vital_signs.ost3_fcrep
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost3_fcmax != ""
                  ? dataConsult.vital_signs.ost3_fcmax
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost3_fcrsv != ""
                  ? dataConsult.vital_signs.ost3_fcrsv
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.ost3_fcrsp != ""
                  ? dataConsult.vital_signs.ost3_fcrsp
                  : "---"}
              </TableCell>
            </TableRow>
            {/* Linha deitado5 */}
            <TableRow>
              <TableCell>Deitado 5 min.</TableCell>
              <TableCell>
                {dataConsult.vital_signs.d5_pas != ""
                  ? dataConsult.vital_signs.d5_pas
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.d5_pad != ""
                  ? dataConsult.vital_signs.d5_pad
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.d5_fcbasal != ""
                  ? dataConsult.vital_signs.d5_fcbasal
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.d5_fcrep != ""
                  ? dataConsult.vital_signs.d5_fcrep
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.d5_fcmax != ""
                  ? dataConsult.vital_signs.d5_fcmax
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.d5_fcrsv != ""
                  ? dataConsult.vital_signs.d5_fcrsv
                  : "---"}
              </TableCell>
              <TableCell>
                {dataConsult.vital_signs.d5_fcrsp != ""
                  ? dataConsult.vital_signs.d5_fcrsp
                  : "---"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {dataConsult.appointment.text_conduct_guidance && (
        <div className="col-12">
          <h4 className="font-weight-semibold">Orientação de Conduta</h4>
          <p>
            {ReactHtmlParser(dataConsult.appointment.text_conduct_guidance)}
          </p>
        </div>
      )}

      {dataConsult.exams.length > 0 && (
        <>
          <h4 className="font-weight-semibold col-12 mt-20">Exames</h4>
          {dataConsult.exams.map((value) => (
            <div className="col-md-12 col-xs-12" style={{ marginTop: "20px" }}>
              <strong> Exame: </strong> {value.test}
              <br />
              <strong> Amostra: </strong> {value.sample ? value.sample : "---"}{" "}
              <br />
              {value.register_result === "1" && (
                <>
                  <strong> Resultado: </strong>
                  <ul>
                    {value.result.map((row) => (
                      <li>
                        {row.field_names ? `${row.field_names}:` : ""}{" "}
                        <span>
                          {row.result1} {row.unit_result1}
                        </span>{" "}
                        <span className="ml-5">
                          {row.result2} {row.unit_result2}
                        </span>
                        {row.status ? (
                          <span className="ml-5">Status: {row.status}</span>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <br />
            </div>
          ))}
        </>
      )}
      {dataConsult.others.length > 0 && (
        <div className="col-12">
          <h4 className="font-weight-semibold">
            Outras ações deste atendimento
          </h4>
          <div className="row">
            {dataConsult.others.map((field) => (
              <Chip
                className="m-2"
                color="primary"
                label={FIELD_NAMES[`${field}`]}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
