import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import api from "services/api";
import { delay } from "redux-saga";
const cadMedicinesGroups = async (
  doctor,
  nameGroupValue,
  descGroupValue,
  medicinesSelected,
  idEdit
) => {
  const response = await api.post(
    `/microservice/medicinesGroups/cadMedicinesGroups/`,
    {
      doctor,
      nameGroupValue,
      descGroupValue,
      medicinesSelected,
      idEdit
    }
  );

  return response;
};

const getlistMedicinesGroups = async doctor => {
  const response = await api.post(
    `/microservice/medicinesGroups/getListMedicinesGroup/`,
    { doctor }
  );

  const data = await response.data.record;
  const aux = [];
  data.forEach(element => {
    element = {
      ...element,
      check: false
    };
    aux.push(element);
  });
  return aux;
};

const delMedicinesGroup = async id => {
  const response = await api.post(
    `/microservice/medicinesGroups/delMedicinesGroup/`,
    {
      id
    }
  );

  return response;
};

const getInfoMedicinesGroupsForEdit = async id => {
  const response = await api.post(
    `/microservice/medicinesGroups/getInfoMedicinesGroups/`,
    { id }
  );
  //Retorna Infos do Grupo(Nome, Desc)
  const data = await response.data.record;
  //Retorna lista de exames cadastrados no respectivo Grupo
  const list = await response.data.ItensMedicines;
  //   const aux = [];
  //   const auxList = [];
  // //   data.forEach(element => {
  //     element = {
  //       ...element,
  //       check: false
  //     };
  //     aux.push(element);
  //   });

  //   list.forEach(element => {
  //     element = {
  //       ...element,
  //       check: false
  //     };
  //     auxList.push(element);
  //   });
  return { data, list };
};

function* asyncCadMedicinesGroups(action) {
  try {
    const resultCadGroupExams = yield call(
      cadMedicinesGroups,
      action.payload.doctor,
      action.payload.nameGroupValue,
      action.payload.descGroupValue,
      action.payload.medicinesSelected,
      action.payload.idEdit
    );
    if (resultCadGroupExams.data.success) {
      yield put({
        type: "HANDLE_CAD_EXAMS_GROUP_SUCCESS",
        payload: { resultCadGroupExams: resultCadGroupExams }
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });

      yield put({
        type: "REQUEST_LIST_DOCTOR_EXAMS_GROUP",
        payload: { doctor: action.payload.doctor }
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao executar esta operação"
      });
    }
    yield put({
      type: "HANDLE_ADD_FUNC",
      payload: {
        active: "view",
        color: "primary",
        texto: "Adicionar",
        hidden: "hidden",
        icon: "plus"
      }
    });
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log(error);
  }
}

function* asyncListMedicinesGroups(action) {
  try {
    const listMedicinesGroups = yield call(
      getlistMedicinesGroups,
      action.payload.doctor
    );
    yield put({
      type: "REQUEST_LIST_MEDICINES_GROUP_SUCCESS",
      payload: { listMedicinesGroups: listMedicinesGroups }
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncDelMedicinesGroups(action) {
  try {
    yield call(delMedicinesGroup, action.payload.id);
    yield put({
      type: "REMOVE_GROUP_MEDICINES_SUCESS",
      id: action.payload.id
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncDataMedicinesGroupForEdit(action) {
  try {
    const InfosGroup = yield call(
      getInfoMedicinesGroupsForEdit,
      action.payload.id
    );
    yield put({
      type: "REQUEST_INFO_MEDICINES_GROUP_SUCCESS",
      payload: {
        InfosGroup: InfosGroup.data,
        ListItensExamsEdit: InfosGroup.list
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export function* requestCadMedicinesGroups() {
  yield takeEvery("CAD_MEDICINES_GROUPS", asyncCadMedicinesGroups);
}

export function* requestListMedicinesGroups() {
  yield takeEvery(
    "REQUEST_LIST_DOCTOR_MEDICINES_GROUP",
    asyncListMedicinesGroups
  );
}

export function* requestDelMedicinesGroups() {
  yield takeEvery("DEL_GROUP_MEDICINE", asyncDelMedicinesGroups);
}

export function* requestDataMedicinesGroupForEdit() {
  yield takeEvery(
    "GET_DATA_GROUP_MEDICINES_FOR_EDIT",
    asyncDataMedicinesGroupForEdit
  );
}

export default function* rootSaga() {
  yield all([
    fork(requestCadMedicinesGroups),
    fork(requestListMedicinesGroups),
    fork(requestDelMedicinesGroups),
    fork(requestDataMedicinesGroupForEdit)
  ]);
}
