import { all, takeLatest, put, call, fork } from 'redux-saga/effects';
import Api from 'services/api';

const RequestgetPrint = async (dados) => {
  const response = await Api.post(`/microservice/print/getPrint/`, dados);
  const data = await response.data;
  return data;
};

function* getPrint(action) {
  try {
    const response = yield call(RequestgetPrint, action.name);
    yield put({ type: 'GET_PRINT_SUCCESS', response });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

export function* requestGetPrint() {
  yield takeLatest('GET_PRINT', getPrint);
}

export default function* rootSaga() {
  yield all([fork(requestGetPrint)]);
}
