export function setConditions(type, payload) {
  return {
    type,
    payload
  };
}

export function saveConditions(payload) {
  return {
    type: "SAVE_CONDITIONS",
    payload
  };
}

export function setEditTag(tag) {
  return {
    type: "SET_EDIT_TAG",
    tag
  };
}

export function AddNewTag() {
  return {
    type: "ADD_NEW_TAG"
  };
}

export function getProgrammingFromTag(id) {
  return {
    type: "GET_PROGRAMMING_FROM_TAG",
    id
  };
}

export function setInitialConditions() {
  return {
    type: "SET_INITIAL_CONDITIONS_TAGS"
  };
}

export function getAllPatientsFromTag(id_tag) {
  return {
    type: "GET_ALL_PATIENTS_FROM_TAG",
    id_tag
  };
}

export function setInitialAllPatientsFromTag() {
  return {
    type: "SET_INITIAL_ALL_PATIENTS_FROM_TAG"
  };
}

export function deleteUserHasTag(id_has_tag, id_tag) {
  return {
    type: "DELETE_USER_HAS_TAG",
    id_has_tag,
    id_tag
  };
}

export function getPatientesNotHasTag(id_tag, address) {
  return {
    type: "GET_PATIENTS_NOT_HAS_TAG",
    id_tag,
    address
  };
}

export function includeTagToPatients(tags, id_doctor, arr_patients) {
  return {
    type: "INCLUDE_TAG_TO_PATIENTS",
    payload: {
      tags,
      id_doctor,
      arr_patients
    }
  };
}
