import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    "& .react-calendar": {
      width: "auto",
      border: "0px solid #a0a096",
    },
  },
}));

export default function ComponentCalendar({ setDate, date }) {
  const classes = useStyles();
  const [value, setValue] = useState(new Date());

  const handleSetDate = (value) => {
    setDate(value);
    setValue(value);
  };

  return (
    <div className={classes.row}>
      <Calendar onChange={handleSetDate} value={value} />
    </div>
  );
}
