import React, { useState, useEffect } from "react";
import {
  MenuItem,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import TextField from "components/TextFields";
import { useSelector, useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { BtnSave } from "components/Button";
import Api from "services/api";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from "components/CircularProgress";
import InfoRegister from "components/Forms/Consult/InfoRegister";
import { ButtonLarge } from "components/Button";
import { showMsg } from "services/functions";

export default function Vaccines({
  type,
  handleEdited,
  beforeSave,
  quize,
  progress,
  handleBack,
  handleClose,
  vaccine_covid,
}) {
  const [record, setRecord] = useState([]);
  const [listVaccines, setListVaccines] = useState({
    complete: [],
    view: [],
  });
  const [to_delete, setToDelete] = useState("");
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const patient = useSelector((state) =>
    type === "consult"
      ? state.appointment.patient
      : type === "pp"
      ? state.auth.authUser
      : state.admission.patient
  );
  const appointment = useSelector((state) =>
    type === "consult"
      ? state.appointment.id
      : type === "pp"
      ? ""
      : state.admission.appointment
  );
  const admission =
    type === "consult"
      ? ""
      : type === "pp"
      ? ""
      : useSelector((state) => state.admission.id);
  const admission_form =
    type === "consult"
      ? ""
      : useSelector((state) => state.admission.forms.vaccines);
  const triage = useSelector((state) => state.appointment.triage);
  const user = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);
  const dispatch = useDispatch();
  const [data, setState] = useState({
    vaccine_covid: vaccine_covid,
    user: useSelector((state) => state.auth.authUser),
  });

  const getDataVaccines = () => {
    Api.post(`/microservice/admission/getDataVaccines/`, {
      admission,
      patient,
    }).then((res) => {
      let { data, list } = res.data;
      setListVaccines({ complete: list, view: list });
      if (data.length > 0) {
        setRecord(
          data.map((row) => ({
            id: row.id,
            vaccine: row.vaccine,
            name: `${row.name} - ${row.dosage} (${row.age})`,
            applied: row.is_applied,
            date:
              row.is_applied === "0" ? row.expected_date : row.date_of_vaccine,
            observation: row.observation ? row.observation : "",
            alter: false,
            added_for: row.added_for,
            approved_by: row.approved_by,
            created: row.created,
          }))
        );
      }
      setLoader(false);
    });
  };

  const validate = () => {
    let next = true;

    record.forEach((row) => {
      if (next && row.applied === "") {
        next = false;
      }
    });

    return next;
  };

  const saveVaccines = () => {
    if (validate()) {
      Api.post(`/microservice/admission/saveVaccines/`, {
        patient,
        user,
        address,
        appointment,
        admission,
        admission_form,
        triage,
        type,
        record,
      })
        .then((res) => {
          // let { ids } = res.data;
          // setRecord(
          //   record.map((row, key) => ({
          //     ...row,
          //     id: ids[key],
          //     alter: false,
          //   }))
          // );
          getDataVaccines();

          if (type === "admission") {
            if (!admission_form) {
              dispatch({
                type: "SET_ADMISSION_DATA",
                payload: {
                  admission: res.data.admission,
                  form: "vaccines",
                  form_value: res.data.form,
                  appointment: res.data.appointment,
                },
              });
            }
            beforeSave();
          } else {
            if (!appointment) {
              dispatch({
                type: "SET_INIT_APPOINTMENT",
                payload: {
                  id: res.data.appointment,
                },
              });
            }
          }

          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => {
            dispatch(closeAlertMessage());
          }, 10000);
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => {
            dispatch(closeAlertMessage());
          }, 5000);
        });

      if (quize) {
        Api.post("microservice/patientportal/saveVaccineCovid", data) //falta criar função backend
          .then(({ data }) => {
            if (data.success) {
              handleClose();
              localStorage.setItem("savedQuizze", "1");
            } else {
              throw true;
            }
          })
          .catch(() => {
            showMsg("error", "Não foi possível savar as informações", dispatch);
          });
      }
    } else {
      setError(true);
      dispatch(viewAlertMessage("error", "Informe o status da vacina"));
      setTimeout(() => {
        dispatch(closeAlertMessage());
      }, 5000);
    }
  };

  const handleChange = (e) => {
    setRecord([
      ...record,
      {
        id: "",
        vaccine: e.target.value,
        name: e.nativeEvent.target.innerText,
        applied: "",
        date: "",
        observation: "",
        alter: false,
      },
    ]);
    if (type === "admission") {
      handleEdited();
    }
  };

  const handleCancel = (obj) => {
    if (!obj.id) {
      setRecord(record.filter((row) => row.vaccine !== obj.vaccine));
    } else {
      setModalDelete(true);
      setToDelete(obj.id);
    }
  };

  const deleteVaccine = () => {
    Api.post(`/microservice/admission/removeVaccine/`, {
      vaccine: to_delete,
      user,
    })
      .then(() => {
        setModalDelete(false);
        setSuccessDelete(true);
        setRecord(record.filter((row) => row.id !== to_delete));
      })
      .catch(() => {
        setModalDelete(false);
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => {
          dispatch(closeAlertMessage());
        }, 5000);
      });
  };

  useEffect(() => {
    getDataVaccines();
  }, []);

  useEffect(() => {
    let ids = record.map((row) => row.vaccine);
    setListVaccines({
      ...listVaccines,
      view: listVaccines.complete.filter((row) => ids.indexOf(row.id) === -1),
    });
  }, [record]);

  const handleValidate = (id) => {
    setRecord(
      record.map((row) => (row.id === id ? { ...row, approved_by: user } : row))
    );
  };

  if (loader) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row w-100">
        <div className="col-12">
          <TextField
            select
            fullWidth
            margin="normal"
            label="Adicionar registro de vacinação:"
            value={""}
            onChange={handleChange}
          >
            {listVaccines.view.map((row) => (
              <MenuItem key={row.id} value={row.id} name={row.name}>
                {`${row.vaccine} - ${row.dosage} (${row.age})`}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {record.length > 0 && (
          <div
            className="col-12 mt-5"
            style={{
              minWidth: "650",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Vacina(s)</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Observações</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell
                      style={{
                        borderBottom:
                          error && row.applied === "" ? "2px solid red" : "",
                      }}
                    >
                      <FormControl component="fieldset" className="ml-3">
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          value={row.applied}
                          onChange={(e) => {
                            setRecord(
                              record.map((val) =>
                                val.vaccine === row.vaccine
                                  ? {
                                      ...val,
                                      applied: e.target.value,
                                      alter: true,
                                    }
                                  : val
                              )
                            );
                            if (type === "admission") {
                              handleEdited();
                            }
                          }}
                          className="mt-1"
                          row
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label="Aplicada"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label="Prevista"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="date"
                        fullWidth
                        value={row.date}
                        onInput={(e) => {
                          if (e.target.value.length > 10) {
                            e.target.value = e.target.value.substr(1, 11);
                          }
                        }}
                        onChange={(e) => {
                          setRecord(
                            record.map((val) =>
                              val.vaccine === row.vaccine
                                ? {
                                    ...val,
                                    date: e.target.value,
                                    alter: true,
                                  }
                                : val
                            )
                          );
                          if (type === "admission") {
                            handleEdited();
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        fullWidth
                        placeholder="Observações"
                        value={row.observation}
                        onChange={(e) => {
                          setRecord(
                            record.map((val) =>
                              val.vaccine === row.vaccine
                                ? {
                                    ...val,
                                    observation: e.target.value,
                                    alter: true,
                                  }
                                : val
                            )
                          );
                          if (type === "admission") {
                            handleEdited();
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell width="10%" align="right">
                      <div className="d-flex">
                        <IconButton
                          aria-label="Delete"
                          color="secondary"
                          onClick={() => handleCancel(row)}
                        >
                          <i className="zmdi zmdi-delete" />
                        </IconButton>

                        <InfoRegister
                          form={type}
                          type="vaccines"
                          id={row.id}
                          patient={patient}
                          added={row.added_for}
                          approved={row.approved_by}
                          created={row.created}
                          handleValidate={handleValidate}
                          // style={{ marginTop: "5px" }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        <div className="col-12 mt-5">
          {quize ? (
            <>
              <ButtonLarge
                onClick={progress === 0 ? handleClose : handleBack}
                style={{
                  padding: "1px",
                  width: "unset",
                  minWidth: "100px",
                  float: "left",
                }}
              >
                {progress === 0 ? "Sair" : "Voltar"}
              </ButtonLarge>
              <ButtonLarge
                onClick={saveVaccines}
                style={{
                  padding: "1px",
                  width: "unset",
                  minWidth: "100px",
                  float: "right",
                  backgroundColor: "#3d8b40",
                  color: "#fff",
                }}
              >
                Confirmar
              </ButtonLarge>
            </>
          ) : (
            <BtnSave title="Salvar" onClick={saveVaccines} float="right" />
          )}
        </div>
        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, remover"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Confirma a remoção desta vacina?"
          onConfirm={deleteVaccine}
          onCancel={() => setModalDelete(false)}
        />
        <SweetAlert
          show={success_delete}
          success
          title="Vacina removido com sucesso!"
          onConfirm={() => setSuccessDelete(false)}
        />
      </div>
    );
  }
}
