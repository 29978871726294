import React, { useState, useEffect } from "react";
import IntlMessages from "util/IntlMessages";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Api from "services/api";
import ListMedicines from "./ListMedicines";
import moment from "moment";

export default function Medicines({ patient }) {
  const [value, setValue] = useState(0);
  const [in_use, setInUse] = useState([]);
  const [interromped, setInterromped] = useState([]);
  const [handleEvent, setHandleEvent] = useState(0);

  useEffect(() => {
    getData();
  }, [handleEvent]);

  const getData = () => {
    Api.post(`/microservice/consult/getListMedicines/`, { patient }).then(
      (res) => {
        let aux_use = [];
        let aux_int = [];
        res.data.record.forEach((row) => {
          if (row.interromped_use) {
            aux_int.push(row);
          } else {
            if (moment().format("YYYY-MM-DD") <= row.use_end) {
              aux_use.push(row);
            } else if (
              !(
                moment().format("YYYY-MM-DD") > row.use_end &&
                row.is_continuos === "1"
              )
            ) {
              aux_int.push(row);
            }
          }
        });
        setInUse(aux_use);
        setInterromped(aux_int);
      }
    );
  };

  function handleChange(event, value) {
    setValue(value);
  }

  return (
    <div className="container-person border">
      <div className="row">
        <div className="col-12 jr-tabs-classic">
          <Tabs
            className=""
            indicatorColor="secondary"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
          >
            <Tab
              className="jr-tabs-label"
              label={<IntlMessages id="module.consult.medicinesUse" />}
              style={{ borderRight: "1px solid #c9c3c3" }}
            />
            <Tab
              className="jr-tabs-label"
              label={<IntlMessages id="module.consult.medicinesStopped" />}
            />
          </Tabs>
          <div className="jr-tabs-content jr-task-list">
            {value === 0 && <ListMedicines data={in_use} type="0" />}
            {value === 1 && <ListMedicines data={interromped} type="1" />}
          </div>
        </div>
      </div>
    </div>
  );
}
