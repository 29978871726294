import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAppointment, setSavedActions } from "actions/consult/Appointment";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { getProceduresGroupsInfos } from "actions/consult/AttendanceRecord";
import { requestListDoctorProceduresGroups } from "actions/configuration/ProcedureGroups";
import {
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@material-ui/core";
import { BtnSave, BtnCan } from "components/Button";
import TextField from "components/TextFields";
import ConfirmCloseCollpase from "components/Modal/ConfirmCloseCollpase";
import Api from "services/api";
import AutocompleteProcedures from "./SearchProcedures";

export default function FormProcedure({
  record,
  open,
  handleClose,
  handleAdd,
  type,
}) {
  const initial = {
    id: "",
    group: "all",
    procedure: "",
    procedure_description: "",
    observation: "",
  };
  const [state, setState] = useState(initial);
  const [error, setError] = useState(false);
  const [edited, setEdited] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const patient = useSelector((state) => state.appointment.patient);
  const address = useSelector((state) => state.auth.address);
  const user_address = useSelector(
    (state) => state.auth.address_selected.user_id
  );
  const doctor = useSelector((state) => state.auth.authUser);
  const prof_resp = useSelector((state) => state.appointment.prof_resp);
  const appointment = useSelector((state) => state.appointment.id);
  const triage = useSelector((state) => state.appointment.triage);
  const start = useSelector((state) => state.appointment.start);
  const saved_id = useSelector((state) => state.appointment.saved.id);
  const saved = useSelector((state) => state.appointment.saved.procedure);
  const group_procedures = useSelector((state) => state.ProcedureGroups);
  const list_procedures_group = useSelector(
    (state) => state.attendanceRecord.tableProcedure
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (group_procedures.listProceduresGroups.length === 0) {
      dispatch(requestListDoctorProceduresGroups(doctor, user_address));
    }
  }, []);

  useEffect(() => {
    if (open && state.procedure) {
      setState(initial);
      setError(false);
      setEdited(false);
    }
  }, [open]);

  useEffect(() => {
    if (record.id) {
      setState({
        id: record.id,
        group: "all",
        procedure: record.procedure ? record.procedure : "",
        procedure_description: record.procedure_description
          ? record.procedure_description
          : "",
        observation: record.observation ? record.observation : "",
        reason: record.reason ? record.reason : "",
        date: record.date ? record.date : "",
        hospitalization: record.hospitalization
          ? record.hospitalization === "1"
          : "",
        hospitalization_start: record.hospitalization_start
          ? record.hospitalization_start
          : "",
        hospitalization_end: record.hospitalization_end
          ? record.hospitalization_end
          : "",
      });
    }
  }, [record]);

  useEffect(() => {
    if (state.group && state.group !== "all") {
      dispatch(getProceduresGroupsInfos(state.group));
    }
  }, [state.group]);

  const handleCloseModal = () => {
    if (edited) {
      setConfirmClose(true);
    } else {
      handleClose();
    }
  };

  const handleSave = () => {
    if (state.procedure) {
      let formData = {
        appointment: { id: appointment, start, triage },
        patient,
        doctor,
        address,
        prof_resp,
        saved_id,
        saved,
        record: state,
        type,
      };
      Api.post(`/microservice/consult/saveAppointmentProcedures`, formData)
        .then((res) => {
          if (!appointment) {
            dispatch(setAppointment(res.data.id));
          }
          if (saved === "0") {
            dispatch(setSavedActions("procedure", res.data.saved));
          }
          handleAdd(
            {
              ...state,
              id: res.data.procedure,
              hospitalization: state.hospitalization ? "1" : "0",
            },
            !state.id
          );
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
        });
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="max-width-dialog-title"
      scroll="body"
    >
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-primary font-weight-semibold">
              {record.id ? "Editar" : "Adicionar"} procedimento
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={handleCloseModal}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>
          {group_procedures.listProceduresGroups.length > 0 && (
            <div className="col-12">
              <TextField
                select
                fullWidth
                label="Grupo de procedimento:"
                margin="normal"
                value={state.group}
                onChange={(e) => {
                  setState({ ...state, group: e.target.value });
                  if (!edited) {
                    setEdited(true);
                  }
                }}
              >
                <MenuItem key="0" value="all">
                  Todos procedimentos
                </MenuItem>
                {group_procedures.listProceduresGroups.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}

          {state.group === "all" ? (
            <div className="col-12">
              <AutocompleteProcedures
                onChange={(obj) => {
                  console.log("obj", obj);

                  setState({
                    ...state,
                    procedure: obj ? obj.id : "",
                    procedure_description: obj ? obj.description : "",
                  });
                  if (!edited) {
                    setEdited(true);
                  }
                }}
                value={state.procedure_description}
                procedure={state.procedure}
                error={error && !state.procedure}
              />
            </div>
          ) : (
            <div className="col-12">
              <TextField
                select
                fullWidth
                label="Procedimento:"
                margin="normal"
                value={state.procedure}
                onChange={(e) => {
                  let info = list_procedures_group.find(
                    (row) => row.id === e.target.value
                  );
                  setState({
                    ...state,
                    procedure: e.target.value,
                    procedure_description: info.description,
                  });
                  if (!edited) {
                    setEdited(true);
                  }
                }}
              >
                {list_procedures_group.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.description}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}
          {state.procedure && (
            <>
              <div className="col-12">
                <TextField
                  // select
                  fullWidth
                  label="Motivo:"
                  margin="normal"
                  value={state.reason}
                  onChange={(e) => {
                    setState({ ...state, reason: e.target.value });
                    if (!edited) {
                      setEdited(true);
                    }
                  }}
                />
                {/* <MenuItem key="0" value="0">
                    Opção 1
                  </MenuItem>
                  <MenuItem key="1" value="1">
                    Opção 2
                  </MenuItem>
                </TextField> */}
              </div>
              <div className="col-12">
                <TextField
                  type="date"
                  value={state.date}
                  onChange={(e) => {
                    setState({
                      ...state,
                      date: e.target.value,
                    });
                    if (!edited) {
                      setEdited(true);
                    }
                  }}
                  label="Data de realização:"
                  fullWidth
                />
              </div>
              <div className="col-12 pl-0 mt-3">
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={state.hospitalization}
                          onChange={(e) => {
                            setState({
                              ...state,
                              hospitalization: e.target.checked,
                            });
                            if (!edited) {
                              setEdited(true);
                            }
                          }}
                        />
                      }
                      label="Houve internação? "
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              {state.hospitalization && (
                <>
                  <div className="col-6">
                    <TextField
                      type="date"
                      value={state.hospitalization_start}
                      onChange={(e) => {
                        setState({
                          ...state,
                          hospitalization_start: e.target.value,
                        });
                        if (!edited) {
                          setEdited(true);
                        }
                      }}
                      label="Início internação:"
                      fullWidth
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      type="date"
                      value={state.hospitalization_end}
                      onChange={(e) => {
                        setState({
                          ...state,
                          hospitalization_end: e.target.value,
                        });
                        if (!edited) {
                          setEdited(true);
                        }
                      }}
                      label="Término internação:"
                      fullWidth
                    />
                  </div>
                </>
              )}

              <div className="col-12 mt-3">
                <TextField
                  multiline
                  rows={3}
                  value={state.observation}
                  onChange={(e) => {
                    setState({
                      ...state,
                      observation: e.target.value,
                    });
                    if (!edited) {
                      setEdited(true);
                    }
                  }}
                  label="Observação:"
                  fullWidth
                />
              </div>
            </>
          )}
          <div className="col-12 mt-3">
            <BtnCan title="Fechar" onClick={handleCloseModal} float="left" />
            <BtnSave onClick={handleSave} float="right" />
          </div>
        </div>
      </DialogContent>
      <ConfirmCloseCollpase
        open={confirmClose}
        message="Alterações não salvas! Deseja sair sem salvar?"
        confirm={() => {
          setConfirmClose(false);
          setEdited(false);
          handleClose();
        }}
        cancel={() => setConfirmClose(false)}
      />
    </Dialog>
  );
}
