import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";
import Cropper from "components/cropper";
import { BtnCan } from "components/Button";
import Api from "services/api";

export default function TakePhotoWithCropper({
  hasImage,
  urlImage,
  imageReceive,
  handleRemove,
  user,
  open,
  handleClose,
}) {
  const [state, setState] = useState({
    img: null,
    displayCam: false,
    accpted: [],
    captured: false,
    cropper: null,
    mode: "",
  });

  useEffect(() => {
    setState({ ...state, mode: hasImage ? "VIEW" : "UPLOAD" });
  }, [open, hasImage]);

  const getImage = (img) => {
    imageReceive(img);
    closeModal();
  };

  const closeModal = () => {
    if (state.displayCam) {
      setState({ ...state, displayCam: false });
    } else {
      handleClose();
    }
  };

  const removePhoto = () => {
    Api.post("ms_system/user/removePhoto", { user }).then(() => {
      handleRemove();
    });
  };

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>
        <h2 className="text-primary font-weight-semibold">
          Foto de Perfil
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={closeModal}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
      </DialogTitle>
      <DialogContent>
        {state.mode === "VIEW" ? (
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => setState({ ...state, mode: "UPLOAD" })}
                  className="jr-btn jr-btn-sm"
                  color="primary"
                  variant="contained"
                >
                  <i className="zmdi zmdi-close zmdi-hc-lg" />
                  <span>Alterar Foto</span>
                </Button>
                <Button
                  onClick={removePhoto}
                  className="jr-btn jr-btn-sm"
                  color="secondary"
                  variant="contained"
                >
                  <i className="zmdi zmdi-close zmdi-hc-lg" />
                  <span>Remover Foto</span>
                </Button>
              </div>
            </div>
            <div className="col-12 mt-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={urlImage}
                  alt="Imagem de perfil"
                  className="img-responsive"
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <BtnCan title="Fechar" onClick={handleClose} float="left" />
            </div>
          </div>
        ) : (
          <Cropper src={state.img} getCroppedImage={getImage} />
        )}
      </DialogContent>
    </Dialog>
  );
}
