const INITIAL_STATE = {
  plateIndex: "",
  totalPercent: "",
  resultDescription: "",
  listPlateIndex: []
};
export default function plateIndex(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_PLATE_INDEX":
      return INITIAL_STATE;
    case "SAVE_PLATEINDEX_SUCCESS":
      return {
        ...state,
        plateIndex: action.payload.idPlateIndex,
        totalPercent: action.payload.totalPercent,
        resultDescription: action.payload.resultDescription
      };
    case "SET_LIST_PLATE_INDEX":
      let newList = state.listPlateIndex;
      newList.push(action.payload);
      return {
        ...state,
        listPlateIndex: newList
      };
    case "DELETE_ITENS_LIST_PLATE_INDEX":
      let newListDelete = state.listPlateIndex.filter(
        row => row.idTeeth !== action.payload
      );
      return {
        ...state,
        listPlateIndex: newListDelete
      };
    case "UPDATE_ITENS_LIST_PLATE_INDEX":
      return {
        ...state,
        listPlateIndex: action.payload
      };
    default:
      return state;
  }
}
