import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { getUrlPhoto } from "services/functions";

const useStyles = makeStyles((theme) => ({
  userName: {
    marginBottom: "2px",
    fontWeight: "400",
  },
  userDescription: {
    fontSize: "13px",
    marginBottom: "0",
    color: "grey",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const ComponentsProfile = ({ profile, onClick }) => {
  const classes = useStyles();
  return (
    <div
      className="d-flex col-12 p-0 avatar-text mr-auto"
      onClick={() => (onClick ? onClick() : null)}
    >
      <Avatar
        className={`mr-2 ${classes.large}`}
        alt={profile.name}
        src={getUrlPhoto(
          profile.id ? profile.id.replace("-", "") : "",
          profile.photo
        )}
      />

      <div>
        <span className={`${classes.userName}`}>{profile.name}</span>
        <p className={`${classes.userDescription}`}>
          Data de Nascimento: {profile.birthday ? profile.birthday : "---"}
        </p>
        <p className={`${classes.userDescription}`}>
          Nome da Mãe: {profile.mother_name ? profile.mother_name : "---"}
        </p>
        <p className={`${classes.userDescription}`}>
          CPF: {profile.cpf ? profile.cpf : "---"}
        </p>
        <p className={`${classes.userDescription}`}>
          CNS: {profile.cns ? profile.cns : "---"}
        </p>
      </div>
    </div>
  );
};

export default ComponentsProfile;
