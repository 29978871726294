import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Api from "services/api";
import { debounce, showMsg, getStringToSearch } from "services/functions";

import AutocompleteGeneric from "./AutocompleteGeneric";
import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { getUrlPhoto } from "services/functions";

const useStyles = makeStyles((theme) => ({
  userName: {
    marginBottom: "2px",
    fontWeight: "400",
  },
  userDescription: {
    fontSize: "13px",
    marginBottom: "0",
    color: "grey",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    margin: "auto 0",
  },
}));

export default function AutocompletePatient({
  handleChangeAutocomplete,
  value,
  isILPI,
  address,
  doctor,
  blurOnSelect = true,
  clearOnBlur = true,
  error = false,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);

  const getMedicines = async (term) => {
    if (term.length > 2) {
      setLoader(true);
      let url = !isILPI
        ? "/microservice/autocomplete/getPatientToAttendance/"
        : "/microservice/autocomplete/getPatientsILPI/";

      try {
        const { data } = await Api.post(url, {
          term: getStringToSearch(term),
          address,
          id_doc: doctor,
        });
        setOptions(data.record);
      } catch (err) {
        showMsg("error", "Não foi possível encontar resultados", dispatch);
        setOptions([]);
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <AutocompleteGeneric
      filterOptions={(x) => x}
      disableCloseOnSelect
      onChange={(e) => handleChangeAutocomplete(e)}
      onInputChange={debounce(getMedicines)}
      value={value}
      getOptionLabel={(option) => option.name}
      label="Pesquise pelo paciente"
      options={options}
      loading={loader}
      blurOnSelect={blurOnSelect}
      clearOnBlur={clearOnBlur}
      shrink={true}
      error={error}
      renderOption={(suggestion, { inputValue }) => {
        return (
          <div className="row w-100">
            <div className="col-9">
              <div className="w-100 d-flex">
                <Avatar
                  className={`mr-2 ${classes.large}`}
                  alt={suggestion.name}
                  src={getUrlPhoto(suggestion.id, suggestion.photo)}
                />
                <div>
                  <span className={`${classes.userName}`}>
                    {suggestion.name}
                  </span>
                  <p className={`${classes.userDescription}`}>
                    Data de Nascimento:{" "}
                    {suggestion.birthday ? suggestion.birthday : "---"}
                  </p>
                  <p className={`${classes.userDescription}`}>
                    Nome da Mãe:{" "}
                    {suggestion.mother_name ? suggestion.mother_name : "---"}
                  </p>
                  <p className={`${classes.userDescription}`}>
                    CPF: {suggestion.cpf ? suggestion.cpf : "---"}
                  </p>
                  <p className={`${classes.userDescription}`}>
                    CNS: {suggestion.cns ? suggestion.cns : "---"}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-3"
              style={{ display: "flex", justifyContent: "end" }}
            >
              {suggestion.id === "new" && (
                <Chip
                  className="m-1 ml-3"
                  size="small"
                  color="primary"
                  icon={<PersonAddIcon />}
                  label="Novo usuário"
                />
              )}
              {suggestion.id < 0 && (
                <Chip
                  className="m-1 ml-3 bg-success"
                  size="small"
                  color="secondary"
                  icon={<PersonAddIcon />}
                  label="Associar"
                />
              )}
              {suggestion.id !== "new" && suggestion.id > 0 && (
                <Chip
                  className="m-1 ml-3"
                  color="info"
                  size="small"
                  icon={<PersonAddIcon />}
                  label="Associado"
                />
              )}
            </div>
          </div>
        );
      }}
    />
  );
}
