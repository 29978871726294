import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";
import ComponentsProfile from "components/ComponentsProfile";
import DB from "services/indexedDB";

const styles = {
  container: {
    flexGrow: 1,
    height: 100,
    width: "100%",
  },
  root: {
    backgroundColor: "#fff",
  },
  selected: {
    backgroundColor: "#e2e2e8 !important",
  },
};

function AutocompletePatient({
  address,
  doctor,
  handleChangeAutocomplete,
  classes,
}) {
  const [suggestions, setSuggestions] = React.useState([]);

  async function getPatientsPWA() {
    var tx = await DB.result.transaction("patients").objectStore("patients");

    var allRecords = await tx.getAll();

    allRecords.onsuccess = () => {
      setSuggestions(allRecords.result);
    };
  }

  React.useEffect(() => {
    if (!navigator.onLine) {
      getPatientsPWA();
    } else {
      Api.post(`/microservice/autocomplete/getPatientsDoctorILPI/`, {
        address,
        doctor,
      }).then((res) => {
        setSuggestions(res.data.record);
      });
    }
  }, []);

  const getSuggestions = (e) => {
    if (e.target.value.length > 2) {
    }
  };

  const renderSuggestion = (params) => {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
          minWidth: "150",
          overflowX: "auto",
          zIndex: "50000",
        }}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
      >
        <div className="row">
          <div className="col-12">
            <ComponentsProfile
              id={suggestion.id}
              nameProfile={suggestion.name}
              subTitle={
                suggestion.birthday ? suggestion.birthday : "Não informado."
              }
              photo={suggestion.photo}
            />
          </div>
        </div>
      </MenuItem>
    );
  };

  const renderInput = (inputProps) => {
    let { InputProps, classes, ref, ...other } = inputProps;
    return (
      <TextField
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...InputProps,
        }}
        onKeyUp={getSuggestions}
        onChange={() => {}}
      />
    );
  };

  return (
    <Downshift
      onSelect={(selectedItem, obj) => {
        let item = suggestions.filter((row) => {
          return row.name === selectedItem;
        });
        handleChangeAutocomplete(item[0]);
        obj.setState({ inputValue: "" });
      }}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex,
      }) => (
        <div className={classes.container}>
          {renderInput({
            fullWidth: true,
            classes,
            InputProps: getInputProps({
              id: "patient",
              value: inputValue,
            }),
            label: "Pesquise pelo paciente:",
            margin: "normal",
          })}
          {isOpen ? (
            <Paper square>
              {suggestions
                .filter(
                  (row) =>
                    !inputValue ||
                    row.name.toLowerCase().includes(inputValue.toLowerCase())
                )
                .map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.name,
                    }),
                    highlightedIndex,
                    selectedItem,
                  })
                )}
            </Paper>
          ) : null}
        </div>
      )}
    </Downshift>
  );
}

export default withStyles(styles)(AutocompletePatient);
