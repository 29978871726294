import React, { useState, useEffect } from "react";
import { Button, Tooltip, IconButton } from "@material-ui/core";
import Api from "services/api";
import CircularProgress from "components/CircularProgress";

export default function ViewQuestionnaire({ record, patient, onClose }) {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (record.id) {
      Api.post(`/microservice/systemreview/getAnswerLesionHistory/`, {
        questionnaire: record.id
      }).then(res => {
        setAnswers(res.data.record);
      });
    }
  }, []);

  return answers.length > 0 ? (
    <div className="row">
      {answers[0].isset === "0" ? (
        <div className="col-12 mt-1">
          <span>Presença de lesões cutâneas:</span> <strong>Não</strong>
        </div>
      ) : (
        <>
          <div className="col-12">
            <strong className="mr-1">Profissional:</strong>
            {record.name}
            <strong className="ml-3 mr-1">Especialidade:</strong>
            {record.specialty}
            <strong className="ml-3 mr-1">Data:</strong>
            {record.date}
            <Tooltip title="Voltar" placement="top">
              <IconButton
                aria-label="Voltar"
                onClick={() => onClose()}
                style={{ float: "right", marginTop: "-10px" }}
              >
                <i className="zmdi zmdi-close" />
              </IconButton>
            </Tooltip>
          </div>
          {answers.map(row => (
            <>
              <div className="col-12">
                <h4>
                  <strong>Local: {row.place} </strong>
                </h4>
              </div>
              <div className="col-12 ml-3">
                Classificação: {row.classification}
              </div>
              <div className="col-12 ml-3">Descrição: {row.description}</div>

              <div className="col-12 ml-3">Extensão:</div>
              <div className="w-100 d-inline ml-5">
                <span>Horizontal: {row.ex_horiz} cm</span>
                <span className="ml-2">Profundidade: {row.ex_prof} cm</span>
                <span className="ml-2">Vertical: {row.ex_vert} cm</span>
                <span className="ml-2">Solapamento: {row.ex_solap} cm</span>
              </div>
              <div className="col-12 ml-3">
                Presença de tecido necrótico:{" "}
                {row.necro === "1" ? "Sim" : "Não"}
              </div>
              <div className="col-12 ml-3">
                Presença de tecido inviável:{" "}
                {row.unfeasible_fabric === "1" ? "Sim" : "Não"}
              </div>
              <div className="col-12 ml-3">
                Dor: {row.pain === "1" ? "Sim" : "Não"}
              </div>
              {row.pain === "1" && (
                <div className="col-12 ml-3">
                  Descrição da Dor: {row.pain_description}
                </div>
              )}
              <div className="col-12 ml-3">
                Edema: {row.edem ? row.edem : "---"}
              </div>
              <div className="col-12 ml-3">
                Conduta/Orientações:{" "}
                {row.conduct_guidelines ? row.conduct_guidelines : "---"}
              </div>
              <div className="col-12 ml-3">
                Cobertura Utilizada:{" "}
                {row.coverage_used ? row.coverage_used : "---"}
              </div>
              {row.fk_ls_file && (
                <div className="col-12 ml-3">
                  Imagem:{" "}
                  <img
                    alt="Lesão"
                    width="100%"
                    height="300px"
                    src={`https://salutem-user-files.s3.amazonaws.com/${patient}/systemreview/lesion/${row.file}`}
                  />
                </div>
              )}
              <hr className="w-100" />
            </>
          ))}
        </>
      )}

      <div className="col-md-12 text-right">
        <Button onClick={() => onClose()} color="primary" aria-label="Voltar">
          <i className="zmdi zmdi-undo" /> Voltar
        </Button>
      </div>
    </div>
  ) : (
    <CircularProgress />
  );
}
