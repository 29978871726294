import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Typography,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { changeAddress, setListAddress } from "actions/Auth";
import BarProgress from "components/CircularProgress/ProgressBar";
import { getUrlPhoto } from "services/functions";
import Api from "services/api";
import NoRecord from "components/Alert/NoRecord";
import moment from "moment";

export default function Academic() {
  const [state, setState] = useState({
    list_specialties: [],
    specialty: "",
    list_teams: [],
    team: "",
    group_teacher: "",
    group_student: "",
    events: [],
    loaded: false,
  });
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);

  const user = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);
  const list_address = useSelector((state) => state.auth.list_address);
  const loaded_address = useSelector((state) => state.auth.loaded_address);
  const dispatch = useDispatch();

  // Listagem de endeços
  useEffect(() => {
    if (!loaded_address) {
      Api.post(`/ms_system/dashboard/getRecordAcademcic`, {
        user,
      })
        .then((res) => {
          dispatch(
            setListAddress(
              res.data.listAddress.map((row) => ({
                ...row,
                type: "1",
                provider_type: "53",
              }))
            )
          );
          setState({ ...state, loaded: true });
        })
        .catch(() => {
          setState({ ...state, loaded: true });
        });
    }
  }, []);

  // Listagem de especialidades
  useEffect(() => {
    if (address && list_address.length > 0) {
      Api.post(`/ms_system/dashboard/getSpecialtiesAddress`, {
        address,
      }).then((res) => {
        setState({
          ...state,
          list_specialties: res.data.record,
          list_teams: [],
          events: [],
          loaded: true,
        });
        setTeachers([]);
        setStudents([]);
      });
    }
  }, [address, list_address]);

  // Listagem de equipes de atendimento
  useEffect(() => {
    if (state.specialty) {
      Api.post(`/ms_system/dashboard/getTeamsAcademicBySpecialty`, {
        specialty: state.specialty,
      }).then((res) => {
        setState({
          ...state,
          list_teams: res.data.record,
          team: "",
          events: [],
        });

        setTeachers([]);
        setStudents([]);
      });
    }
  }, [state.specialty]);

  // Informações da equipe de atendimento
  useEffect(() => {
    if (state.team) {
      let info_team = state.list_teams.find((row) => row.id === state.team);
      setState({
        ...state,
        group_teacher: info_team.fk_ls_group_intranet_teacher,
        group_student: info_team.fk_ls_group_intranet_student,
      });
      Api.post(`/ms_system/dashboard/getEventsByTeam`, {
        team: state.team,
      }).then((res) => {
        setState({
          ...state,
          events: res.data.record,
        });
      });
    }
  }, [state.team]);

  useEffect(() => {
    if (state.group_teacher) {
      getMembersGroup(state.group_teacher, "teacher");
    }
  }, [state.group_teacher]);

  useEffect(() => {
    if (state.group_student) {
      getMembersGroup(state.group_student, "student");
    }
  }, [state.group_student]);

  const getMembersGroup = (group, type) => {
    Api.post("ms_system/workplace/getMembersAcademic", {
      group,
    }).then((res) => {
      if (type === "teacher") {
        setTeachers(res.data.record);
      } else {
        setStudents(res.data.record);
      }
    });
  };

  return (
    <div className="col-12 animated slideInUpTiny animation-duration-4 mb-5">
      <Typography className="pt-3 ml-1" variant="h6" gutterBottom>
        Dashboard
      </Typography>
      {state.loaded ? (
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <TextField
              select
              value={address}
              onChange={(e) => dispatch(changeAddress(e.target.value))}
              fullWidth
              label="Local de atendimento:"
            >
              {list_address.map((e) => (
                <MenuItem value={e.id}>{e.name} </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="col-md-6 col-xs-12">
            <TextField
              select
              value={state.specialty}
              onChange={(e) =>
                setState({ ...state, specialty: e.target.value })
              }
              fullWidth
              label="Especialidade:"
            >
              {state.list_specialties.map((row) => (
                <MenuItem value={row.id}>
                  {`${row.area} - ${row.specialty}`}{" "}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="col-12">
            <TextField
              select
              value={state.team}
              onChange={(e) => setState({ ...state, team: e.target.value })}
              fullWidth
              label="Equipes de atendimento:"
              className="mt-3"
            >
              {state.list_teams.map((row) => (
                <MenuItem value={row.id}>{row.description}</MenuItem>
              ))}
            </TextField>
          </div>

          {state.team && (
            <>
              <div className="col-md-6 mt-4">
                <Typography>Professores</Typography>
                <div
                  className="w-100 mb-3"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  {teachers.map((row) => (
                    <div className="d-flex mt-3 pl-3">
                      <Avatar
                        alt={row.name}
                        src={getUrlPhoto(row.user_id, row.photo)}
                        size="40"
                      />
                      <span className="ml-3 mt-1 d-flex">{row.name}</span>
                    </div>
                  ))}
                  {teachers.length === 0 && <NoRecord />}
                </div>
                <Typography>Alunos</Typography>
                <div
                  className="w-100"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  {students.map((row) => (
                    <div className="d-flex mt-3 pl-3">
                      <Avatar
                        alt={row.name}
                        src={getUrlPhoto(row.user_id, row.photo)}
                        size="40"
                      />
                      <span className="ml-3 mt-1 d-flex">{row.name}</span>
                    </div>
                  ))}
                  {students.length === 0 && <NoRecord />}
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <Typography>
                  Agenda - {moment().format("DD/MM/YYYY")}
                </Typography>
                <table className="table mt-3">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Horario</th>
                      <th scope="col"></th>
                      <th scope="col">Nome</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.events.map((e, index) => (
                      <tr
                        key={index}
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        <th scope="row">{index + 1}</th>
                        <td
                          style={{
                            borderLeft: "1px solid red",
                          }}
                        >
                          {moment(e.start).format("HH:mm")} -{" "}
                          {moment(e.end).format("HH:mm")}
                        </td>
                        <td>
                          <Tooltip title={e.calendar} placement="top">
                            <span
                              style={{
                                backgroundColor: e.color,
                                padding: "0.3rem",
                              }}
                              className="size-10 lighten-1 rounded-circle d-inline-block mr-2"
                            />
                          </Tooltip>
                        </td>
                        <td>{e.title}</td>
                      </tr>
                    ))}
                    {state.events.length === 0 && (
                      <tr>
                        <td colspan="3">
                          <NoRecord />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="col-12 d-flex justify-content-center m-2">
          <BarProgress />
        </div>
      )}
    </div>
  );
}
