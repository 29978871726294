import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import DataTable from "components/Tables/DataTable";

const useStyles = makeStyles((theme) => ({
  imgType: {
    width: "24px",
    height: "24px",
  },
  imgTarget: {
    width: "24px",
    height: "24px",
    marginLeft: "15px",
  },
  nameDisplay: {
    marginLeft: "15px",
  },
}));

export default function MedicinesPatient({ record }) {
  const classes = useStyles();

  const getClassImage = (type) => {
    let imgIcon = "";
    switch (type) {
      case "Similar":
        imgIcon = require("assets/images/consult/similar.jpg");
        break;
      case "Generico":
        imgIcon = require("assets/images/consult/generico.jpg");
        break;
      case "Referencia":
      case "Especifico":
        imgIcon = require("assets/images/consult/referencia.jpg");
        break;
      case "Biológicos":
        imgIcon = require("assets/images/consult/biologico.jpg");
        break;
      default:
        imgIcon = require("assets/images/consult/outros.jpg");
        break;
    }
    return imgIcon;
  };

  const getTarge = (targe) => {
    if (targe === "TP") {
      return require("assets/images/consult/Preta.svg");
    } else if (targe === "TV") {
      return require("assets/images/consult/Vermelha.svg");
    } else {
      return require("assets/images/consult/Branca.svg");
    }
  };

  return (
    <DataTable
      name="Medicamentos"
      columns={["Medicamento", "Forma", "Quantidade Total"]}
      rows={record.map((row, index) => [
        <>
          <span style={{ float: "left" }}>
            <img
              src={getClassImage(row.medtype_display)}
              alt="Tipo do medicamento"
              className={classes.imgType}
            />
            <img
              src={getTarge(row.targe)}
              alt="Tarja do medicamento"
              className={classes.imgTarget}
            />
            <span className={classes.nameDisplay}>
              {row.name_display} {row.dosage}
            </span>
            {row.medtype_display !== "Referencia" && (
              <Tooltip title={row.active_principle}>
                <i className="zmdi zmdi-info zmdi-hc-fw text-primary" />
              </Tooltip>
            )}
          </span>
        </>,
        row.form,
        row.total_quantity,
      ])}
      hideSearch={true}
    />
  );
}
