export const setAllPermission = (field, value) => {
  return {
    type: "SET_ALL_PERMISSION",
    field,
    value
  };
};

export const setManagimentPermission = payload => ({
  type: "SET_MANAGMENT_PERMISSIONS",
  payload
});
