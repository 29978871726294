import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as alertActions from "actions/Alerts";
import Api from "services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormPlan from "./FormPlan";
import SweetAlert from "react-bootstrap-sweetalert";
import NoRecord from "components/Alert/NoRecord";

class HealthPlan extends React.Component {
  state = {
    open_modal: false,
    modal_delete: false,
    success_delete: false,
    plan_form: ""
  };

  isEmpty(obj) {
    return obj ? Object.keys(obj).length === 0 : true;
  }

  newPlan = () => {
    this.setState({ open_modal: true, plan_form: "" });
  };

  handleClose = () => {
    this.setState({ open_modal: false });
  };

  editPlan(plan) {
    this.setState({ open_modal: true, plan_form: plan });
  }

  alertDeletePlan(plan) {
    this.setState({ modal_delete: true, plan_form: plan });
  }

  onCancelDelete = () => {
    this.setState({ modal_delete: false, plan_form: "" });
  };

  deletePlan(id) {
    Api.post(`/microservice/consult/deleteHealthPlan/`, { id })
      .then(res => {
        let id = this.state.plan_form.id;
        this.props.plans.forEach((row, key) => {
          if (row.id === id) {
            this.props.plans.splice(key, 1);
          }
        });
        this.setState({ modal_delete: false, success_delete: true });
      })
      .catch(() => {
        this.props.viewAlertMessage(
          "error",
          "Ocorreu um erro, tente novamente!"
        );
        setTimeout(() => this.props.closeAlertMessage(), 5000);
        this.setState({ modal_delete: false, success_delete: false });
      });
  }

  render() {
    const { plans } = this.props;
    const { plan_form, modal_delete, success_delete } = this.state;
    return (
      <div className="row">
        <div className="col-md-12 div-header">
          <span className="card-title text-primary title-form">
            Planos de Saúde
          </span>
          <div className="div-button">
            <Button
              onClick={() => this.newPlan()}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
            >
              <i className="zmdi zmdi-plus text-right" />{" "}
              <span> Adicionar</span>
            </Button>
          </div>
        </div>
        {!this.isEmpty(plans) ? (
          <div
            className="col-12"
            style={{
              minWidth: "650",
              overflowX: "auto"
            }}
          >
            <Table className="table-history table">
              <TableHead>
                <TableRow>
                  <TableCell>Plano</TableCell>
                  <TableCell align="left">Tipo</TableCell>
                  <TableCell align="left">Registro/Carteirinha</TableCell>
                  <TableCell align="left">Validade</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plans.map(row => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.plan_name}
                    </TableCell>
                    <TableCell align="left">{row.identfy}</TableCell>
                    <TableCell align="left">{row.register}</TableCell>
                    <TableCell align="left">
                      {row.validate ? row.validate : "Não informado"}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => this.editPlan(row)}
                        color="primary"
                        aria-label="Editar"
                      >
                        <i className="zmdi zmdi-edit zmdi-hc-fw" />
                      </IconButton>
                      <IconButton
                        onClick={() => this.alertDeletePlan(row)}
                        color="secondary"
                        aria-label="Remover"
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <NoRecord />
        )}

        {/* Modal de edição / adição */}
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={this.state.open_modal}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
          scroll="body"
        >
          <DialogContent>
            <FormPlan
              plan={plan_form}
              handleClose={this.handleClose.bind(this)}
              patient={this.props.patient}
            />
          </DialogContent>
        </Dialog>

        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, excluir"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Excluir este plano de saúde?"
          onConfirm={() => this.deletePlan(this.state.plan_form.id)}
          onCancel={this.onCancelDelete}
        />
        <SweetAlert
          show={success_delete}
          success
          title="Plano de saúde excluido com sucesso!"
          onConfirm={() => this.setState({ success_delete: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    plans: state.personalData.health_plan
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(alertActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthPlan);
