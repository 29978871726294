import React from "react";
import { CardBody } from "reactstrap";
import ListAttendance from "./ListAttendance";
import Typography from "@material-ui/core/Typography";
// import "./styles.css";

export default function Attendance() {
  return (
    <>
      <div className="col-12 m-0 p-0 animated slideInUpTiny animation-duration-4">
        <CardBody style={{ paddingBottom: 0, marginBottom: 0 }}>
          <div className="col-12 m-0 p-0">
            <div style={{ marginBottom: "-44px" }}>
              <Typography
                className="d-flex justify-content-start"
                variant="h6"
                gutterBottom
              >
                Atendimentos
              </Typography>
            </div>
          </div>
          <div className="col-12 p-0">
            <ListAttendance />
          </div>
        </CardBody>
      </div>
    </>
  );
}
