import React, { useEffect } from "react";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import { useDispatch, useSelector } from "react-redux";

const UserCell = ({ chat, selectedSectionId, onSelectUser }) => {
  const dispatch = useDispatch();
  const notRead = useSelector(({ chat }) => chat.notRead);
  useEffect(() => {
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/`)
      .once("value", (snap) => {
        dispatch({ type: "SET_NOT_READ", selectedSectionId, chat });
      });
  }, []);

  getDataFirebase()
    .child(`chats${getEnviromentFirebase()}/`)
    .on("child_changed", (snap) => {
      dispatch({ type: "SET_NOT_READ", selectedSectionId, chat });
    });

  return (
    <div
      key={chat.id}
      className={`chat-user-item ${
        selectedSectionId === chat.id ? "active" : ""
      }`}
      onClick={() => {
        onSelectUser(chat);
      }}
    >
      <div className="chat-user-row row">
        <div className="chat-avatar col-xl-2 col-3">
          <div className="chat-avatar-mode">
            <img
              src={chat.thumb}
              className="rounded-circle size-40"
              alt={chat.name}
            />
            <span className={`chat-mode small ${chat.status}`} />
          </div>
        </div>

        <div className="chat-info col-xl-8 col-6">
          <span className="name h4">{chat.name}</span>
          <div className="chat-info-des">
            {chat.mood && chat.mood.length > 25
              ? chat.mood.substring(0, 25) + "..."
              : chat.mood}
          </div>
          {/* <div className="last-message-time">{chat.lastMessageTime}</div> */}
        </div>

        {notRead[`${chat.chat_id}`] > 0 && (
          <div className="chat-date col-xl-2 col-3">
            <div className="bg-primary rounded-circle badge text-white">
              {notRead[`${chat.chat_id}`]}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserCell;
