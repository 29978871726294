const INITIAL_STATE = {
  home_care_visit: "",
  patient: {}
};

export default function homecareProfessional(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_PATIENT_SELECTED":
      return {
        ...state,
        patient: action.payload.patient,
        home_care_visit: action.payload.home_care_visit
      };
    case "RESET_HC_PROFESSIONAL":
      return INITIAL_STATE;
    default:
      return state;
  }
}
