import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import Api from "services/api";
import { getUrlPhoto } from "services/functions";
// import ContactList from "components/chatPanel/ContactList/index";
// import ContainerHeader from "components/ContainerHeader";
import CloseIcon from "@material-ui/icons/Close";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import moment from "moment";
import Jitsi from "react-jitsi";
import { Prompt } from "react-router-dom";
import ConfirmCloseCollpase from "components/Modal/ConfirmCloseCollpase";
import Alert from "components/Alert/AlertSnack";
import IconButton from "@material-ui/core/IconButton";
import "./style.css";

export default function Scheduled({ match, history, selected, handleClose }) {
  const [contactList, setContactList] = useState([]);
  const [contactSelected, setContactSelected] = useState({});
  const [view, setView] = useState("0");
  const [confirmClose, setConfirmClose] = useState({
    open: false,
    obj: {},
  });
  const [modalAlert, setModalAlert] = useState(false);
  const width = useSelector((state) => state.settings.width);
  const [viewVideo, setviewVideo] = useState(false);

  const user = useSelector((state) => state.auth.authUser);
  const name = useSelector((state) => state.auth.name);
  const photo = useSelector((state) => state.auth.photo);

  useEffect(() => {
    setContactSelected(selected);
    setView("1");
  }, [selected]);

  useEffect(() => {
    console.log("match", match);
    console.log("contactSelected", contactSelected);
    if (contactSelected.id) {
      handleInitCall();
    }
  }, [contactSelected.id]);

  useEffect(() => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      if (navigator.userAgent.match(/CriOS/i)) {
        setModalAlert(true);
      }
    }

    Api.post(`/ms_comunication/chat/getContactList/`, {
      user,
    }).then(({ data }) => {
      setContactList(
        data.map((row) => ({ ...row, thumb: getUrlPhoto(row.id, row.thumb) }))
      );
    });
  }, []);

  useEffect(() => {
    if (contactList.length > 0) {
      const data_get = window.location.href;
      const arr_data = data_get.split("?");
      const param = arr_data[1] ? arr_data[1].split("=")[1] : "";
      if (param) {
        let obj = contactList.filter((row) => row.chat_id === param);
        if (obj.length > 0) {
          setContactSelected(obj[0]);
          setView("3");
        }
      }
    }
  }, [contactList]);

  // const handleSelectUser = (e) => {
  //   if (view === "3") {
  //     setConfirmClose({
  //       open: true,
  //       value: e,
  //     });
  //   } else {
  //     setContactSelected(e);
  //     setView("1");
  //   }
  // };

  const handleInitCall = () => {
    getDataFirebase()
      .child(`notification${getEnviromentFirebase()}/` + contactSelected.id)
      .push({
        user_from: user,
        user_to: contactSelected.id,
        date: moment().format("DD/MM/YYYY HH:mm"),
        title: `Iniciar videochamada com ${name}`,
        type: "videoconference",
        action: `comunication/video?room=${contactSelected.chat_id}`,
        image: photo,
        icon: "zmdi-comment-video",
        read: false,
      });
    setView("3");
  };

  useEffect(() => {
    if (view === "1") {
      document.getElementById("div-start").classList.remove("d-none");
      document.getElementById("div-start").scrollIntoView();
    }
  }, [view]);

  const handleLoadJitsi = (jitsi) => {
    jitsi.addEventListener("videoConferenceLeft", () => {
      if (document.getElementById("load-jitsi")) {
        document.getElementById("load-jitsi").classList.remove("d-none");
      }
      if (document.getElementById("div-jitsi")) {
        document.getElementById("div-jitsi").classList.add("d-block");
      }
      setView("2");
    });
    jitsi.addEventListener("readyToClose", () => {
      document.getElementById("load-jitsi").classList.remove("d-none");
      document.getElementById("div-jitsi").classList.add("d-block");
      setView("2");
    });
    jitsi.addEventListener("videoConferenceJoined", () => {
      document.getElementById("div-jitsi").classList.remove("d-none");
      document.getElementById("div-jitsi").classList.add("d-block");
      document.getElementById("load-jitsi").classList.add("d-none");
    });
    setviewVideo(width < 500 ? true : false);
  };

  const close = () => {
    setConfirmClose({
      open: false,
      value: {},
    });
    handleClose();
  };

  return (
    <div>
      {/* <ContainerHeader title="Videochamada" match={match} className="mt-4" /> */}
      <Card className="shadow border-0">
        <CardBody>
          <div className="row">
            <div className="col-12">
              <div id="teste" />
            </div>
            <div className="col-12 d-flex justify-content-end">
              <IconButton aria-label="delete" onClick={() => close()}>
                <CloseIcon />
              </IconButton>
            </div>

            {/* {!viewVideo && (
              <div className="col-md-4 col-xs-12 mb-5">
                <h2 className="text-primary">Contatos</h2>
                <div
                  className="w-100"
                  style={{
                    maxHeight: "450px",
                    overflowY: "auto",
                  }}
                >
                  <ContactList
                    contactList={contactList}
                    selectedSectionId={contactSelected.id}
                    onSelectUser={(e) => handleSelectUser(e)}
                  />
                </div>
              </div>
            )} */}
            <div className="col-md-8 col-xs-12">
              {view === "0" ? (
                <div className="loader-view" style={{ height: "80vh" }}>
                  <i className="zmdi zmdi-comment-video s-128 text-muted" />
                  <h1 className="text-muted">
                    Selecione um contato para iniciar
                  </h1>
                </div>
              ) : view === "1" ? (
                <div
                  className="loader-view"
                  id="div-start"
                  style={{ height: "80vh" }}
                >
                  <i
                    className="zmdi zmdi-play s-128 text-muted text-primary"
                    onClick={handleInitCall}
                    style={{ cursor: "pointer" }}
                  />
                  <h1 className="text-muted">
                    <span
                      className="text-primary"
                      onClick={handleInitCall}
                      style={{ cursor: "pointer" }}
                    >
                      Iniciar videochamada
                    </span>
                  </h1>
                </div>
              ) : view === "2" ? (
                <div className="loader-view" style={{ height: "80vh" }}>
                  <i className="zmdi zmdi-videocam-off s-128 text-muted" />
                  <h1 className="text-muted">Videochamada encerrada</h1>
                </div>
              ) : (
                <>
                  <div
                    id="load-jitsi"
                    className="loader-view"
                    style={{ height: "80vh" }}
                  >
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <h1 className="text-muted">Iniciando videochamada ...</h1>
                  </div>
                  <div id="div-jitsi" className="d-none">
                    <Jitsi
                      roomName={`salutemplus-5242654807${contactSelected.chat_id}9531784`}
                      displayName={name}
                      config={{
                        defaultLanguage: "pt",
                        disableDeepLinking: true,
                        prejoinPageEnabled: false,
                        enableWelcomePage: false,
                      }}
                      interfaceConfig={{
                        BRAND_WATERMARK_LINK:
                          "https://balancer.salutemplus.com",
                        DEFAULT_REMOTE_DISPLAY_NAME: "SalutemPlus",
                        SHOW_JITSI_WATERMARK: false,
                        SHOW_WATERMARK_FOR_GUESTS: false,
                        DISPLAY_WELCOME_PAGE_CONTENT: false,
                        HIDE_INVITE_MORE_HEADER: true,
                        APP_NAME: "SalutemPlus",
                        NATIVE_APP_NAME: "SalutemPlus",
                        PROVIDER_NAME: "SalutemPlus",
                        MOBILE_APP_PROMO: false,
                        TOOLBAR_BUTTONS: [
                          "microphone",
                          "camera",
                          "closedcaptions",
                          "desktop",
                          "fullscreen",
                          "hangup",
                          // "fodeviceselection",
                          // "profile",
                          // "settings",
                          // "videoquality",
                          // "filmstrip",
                          // "feedback",
                          // "stats",
                          // "shortcuts",
                          // "tileview",
                          // "videobackgroundblur",
                          // "download",
                        ],
                      }}
                      containerStyle={{ width: "100%", height: "500px" }}
                      onAPILoad={handleLoadJitsi}
                    />
                  </div>
                </>
              )}
              {/* {viewVideo && (
                <div className="col-md-4 col-xs-12 mb-5">
                  <h2 className="text-primary">Contatos</h2>
                  <div
                    className="w-100"
                    style={{
                      maxHeight: "450px",
                      overflowY: "auto",
                    }}
                  >
                    <ContactList
                      contactList={contactList}
                      selectedSectionId={contactSelected.id}
                      onSelectUser={(e) => handleSelectUser(e)}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </CardBody>
      </Card>
      <Prompt
        when={view === "3"}
        message={"Deseja desconectar desta chamada?"}
      />
      <ConfirmCloseCollpase
        open={confirmClose.open}
        message="Deseja desconectar desta chamada?"
        confirm={() => {
          setConfirmClose({
            open: false,
            value: {},
          });
          setContactSelected(confirmClose.value);
          setView("1");
        }}
        cancel={() =>
          setConfirmClose({
            open: false,
            value: {},
          })
        }
      />

      <Alert
        open={modalAlert}
        onClose={() => setModalAlert(false)}
        severity="warning"
      >
        Este navegador não é compatível com os recursos utilizados na
        videochamada, recomendamos o uso do{" "}
        <a
          style={{ color: "white", textDecoration: "underline" }}
          href="https://support.apple.com/pt_BR/downloads/safari"
          target="_blank"
        >
          Safari
        </a>
        .
      </Alert>
    </div>
  );
}
