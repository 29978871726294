import { all, takeLatest, put, call } from "redux-saga/effects";
import { delay } from "redux-saga";
import Api from "services/api";

const getTypes = async () => {
  try {
    const response = await Api.get(`/ms_finance/list/getTypesFinance/`);
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetTypes(action) {
  try {
    const response = yield call(getTypes);
    yield put({
      type: "GET_TYPES_SUCCESS",
      payload: {
        record: response
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getFinance = async user => {
  try {
    const response = await Api.post(`/ms_finance/list/getListFinance/`, {
      user
    });
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetFinance(action) {
  try {
    const response = yield call(getFinance, action.user);
    yield put({
      type: "LIST_FINANCE_SUCCESS",
      payload: {
        record: response === null || response === undefined ? [] : response
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getTransaction = async index => {
  try {
    const response = await Api.post(
      `/ms_finance/transaction/getTransactions/`,
      {
        index
      }
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetTransaction(action) {
  try {
    const response = yield call(getTransaction, action.index);
    yield put({
      type: "GET_TRANSACTION_SUCCESS",
      payload: {
        record: response
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getPatternTransaction = async index => {
  try {
    const response = await Api.post(
      `/ms_finance/patterntransaction/getPatternTransaction/`,
      {
        index
      }
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetPatternTransaction(action) {
  try {
    const response = yield call(getPatternTransaction, action.index);
    yield put({
      type: "GET_PATTERN_TRANSACTION_SUCCESS",
      payload: {
        record: response
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getAccounts = async index => {
  try {
    const response = await Api.post(`/ms_finance/account/getAccounts/`, {
      index
    });
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetAccounts(action) {
  try {
    const response = yield call(getAccounts, action.index);
    yield put({
      type: "GET_ACCOUNTS_SUCCESS",
      payload: {
        record: response
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const getSuppliers = async index => {
  try {
    const response = await Api.post(`/ms_finance/supplier/getSuppliers/`, {
      index
    });
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetSuppliers(action) {
  try {
    const response = yield call(getSuppliers, action.index);
    yield put({
      type: "GET_SUPPLIERS_SUCCESS",
      payload: {
        record: response
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const setTransactionPending = async transaction => {
  try {
    const response = await Api.post(
      `/ms_finance/transaction/setTransactionPending/`,
      {
        transaction
      }
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncSetTransactionPending(action) {
  try {
    yield call(setTransactionPending, action.transaction);
    yield put({
      type: "TRANSACTION_PENDING_SUCCESS",
      payload: {
        id: action.transaction
      }
    });
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "success",
      text_message: "Transação atualizada!"
    });

    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

const setTransactionQuit = async (transaction, value, date) => {
  try {
    const response = await Api.post(
      `/ms_finance/transaction/setTransactionQuit/`,
      {
        transaction,
        value,
        date
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncSetTransactionQuit(action) {
  try {
    const response = yield call(
      setTransactionQuit,
      action.transaction,
      action.value,
      action.date
    );
    yield put({
      type: "GET_TRANSACTION",
      index: response.index
    });
    yield put({
      type: "TRANSACTION_QUIT_SUCCESS",
      payload: {
        id: action.transaction,
        date: response.date
      }
    });
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "success",
      text_message: "Transação atualizada!"
    });

    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest("SET_TRANSACTION_PENDING", asyncSetTransactionPending)
  ]);
  yield all([takeLatest("SET_TRANSACTION_QUIT", asyncSetTransactionQuit)]);
  yield all([takeLatest("GET_SUPPLIERS", asyncGetSuppliers)]);
  yield all([takeLatest("GET_ACCOUNTS", asyncGetAccounts)]);
  yield all([takeLatest("GET_TRANSACTION", asyncGetTransaction)]);
  yield all([
    takeLatest("GET_PATTERN_TRANSACTIONS", asyncGetPatternTransaction)
  ]);
  yield all([takeLatest("GET_LIST_FINANCE", asyncGetFinance)]);
  yield all([takeLatest("GET_TYPES", asyncGetTypes)]);
}
