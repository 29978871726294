import React, { useState } from "react";
import { Button, IconButton } from "@material-ui/core";
import Api from "services/api";
import RegisterProfessional from "components/Forms/RegisterProfessional";
import RegisterProfessionalAdd from "components/Forms/RegisterProfessional";
import AutoCompleteProf from "./AutoCompleteProf";
import ComponentsProfile from "components/ComponentsProfile";
import CloseIcon from "@material-ui/icons/Close";

export default function PersonalData({
  handleNext,
  handleBack,
  handleSetState,
}) {
  const [state, setState] = useState({});
  const [alert, setAlert] = useState(false);
  const [new_user, setNew_user] = useState(false);
  const [new_user_add, setNew_user_add] = useState(false);
  const [userName, setUserName] = useState("");
  const [userSelectedRepres, setuserSelectedRepres] = useState("");
  const [listManger, setlistManger] = useState([]);

  const validate = async () => {
    handleSetState("managers", { userSelectedRepres, listManger });
    handleNext();
  };

  React.useEffect(() => {
    let search = window.location.search;
    if (search) {
      let user = search.split("d20");
      if (user.length > 1) {
        getDataUser(user[1]);
      }
    }
  }, []);

  const getDataUser = (id) => {
    Api.post(`/ms_system/user/getUserQuickRegister`, { user: id }).then(
      (res) => {
        const {
          id,
          idData,
          gender,
          name,
          birthday,
          smartphone,
          email,
          cpf,
          quick_register,
        } = res.data.dataUser;

        if (quick_register === "1") {
          setState({
            ...state,
            id,
            idData,
            sex: gender,
            date: birthday,
            name,
            cellphone: smartphone ? smartphone : "",
            cpf,
            email,
          });
        } else {
          setAlert(true);
        }
      }
    );
  };

  const handleChangeAutocomplete = (params) => {
    if (params.id === "new") {
      setNew_user(true);
      setUserName(params.name);
    } else {
      setUserName(params.name);
      setuserSelectedRepres(params);
      setlistManger([...listManger, params]);
    }
  };

  const handleChangeAutocompleteAdd = (params) => {
    if (params.id === "new") {
      setNew_user_add(true);
      setUserName(params.name);
    } else {
      let newList = [...listManger, params];
      setlistManger(newList);
    }
  };

  const handleSelected = (params) => {
    setUserName(params.name);
    setuserSelectedRepres(params);
  };

  const handleSelectedAddManger = (params) => {
    let newList = [...listManger, params];
    setlistManger(newList);
  };

  const hanldeRemoveItem = (index) => {
    setlistManger(listManger.filter((row, idx) => index !== idx));
  };

  return (
    <>
      <div className="col-12 d-flex justify-content-start p-0">
        <h2 className="font-weight-bold">Representante Legal</h2>
      </div>
      {userSelectedRepres.id ? (
        <>
          <div className="col-12 col-md-12 col-sm-12 d-flex justify-content-center p-0">
            <div className="col-11 col-md-11 col-sm-11">
              <ComponentsProfile
                nameProfile={userSelectedRepres.name}
                subTitle={
                  userSelectedRepres.speciality
                    ? userSelectedRepres.speciality.substr(0, 55)
                    : ""
                }
                id={userSelectedRepres.id}
                photo={userSelectedRepres.photo}
              />
            </div>
            <div className="col-1 col-md-1 col-sm-1">
              <IconButton onClick={() => setuserSelectedRepres("")}>
                <CloseIcon color="secondary" fontSize="inherit" />
              </IconButton>
            </div>
          </div>
        </>
      ) : (
        <AutoCompleteProf handleChangeAutocomplete={handleChangeAutocomplete} />
      )}

      <RegisterProfessional
        add={(id, name) => {
          let selected = { id, name };
          handleSelected(selected.id);
          setNew_user(false);
        }}
        name={userName}
        open={new_user}
        handleClose={() => setNew_user(false)}
      />

      <RegisterProfessionalAdd
        add={(id, name) => {
          let selected = { id, name };
          handleSelectedAddManger(selected.id);
          setNew_user_add(false);
        }}
        name={userName}
        open={new_user_add}
        handleClose={() => setNew_user_add(false)}
      />

      <div className="col-12 p-0 pt-3">
        <div className="col-12 d-flex justify-content-start p-0 mt-2">
          <h2 className="font-weight-bold">Gestor(es)</h2>
        </div>

        <AutoCompleteProf
          handleChangeAutocomplete={handleChangeAutocompleteAdd}
        />

        {listManger.length > 0 && (
          <>
            <div
              className="col-12 d-flex justify-content-center mt-3"
              style={{
                borderBottom: "1px solid #d1d1d1",
              }}
            >
              <Button color="primary">Gestor(es)</Button>
            </div>
            {listManger.map((row, index) => (
              <>
                <div className="col-12 col-md-12 col-sm-12 d-flex justify-content-center p-0 my-3">
                  <div className="col-11 col-md-11 col-sm-11">
                    <ComponentsProfile
                      nameProfile={row.name}
                      subTitle={
                        row.speciality ? row.speciality.substr(0, 55) : ""
                      }
                      id={row.id}
                      photo={row.photo}
                    />
                  </div>
                  <div className="col-1 col-md-1 col-sm-1">
                    <IconButton onClick={() => hanldeRemoveItem(index)}>
                      <CloseIcon color="secondary" fontSize="inherit" />
                    </IconButton>
                  </div>
                </div>
              </>
            ))}
          </>
        )}
      </div>

      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <Button className="jr-btn" onClick={handleBack}>
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={validate}
          className="jr-btn"
          style={{ float: "right", marginRight: 0 }}
        >
          Próxima
        </Button>
      </div>
    </>
  );
}
