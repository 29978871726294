import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAppointment, setSavedActions } from "actions/consult/Appointment";
import { BtnCan, BtnSave } from "components/Button";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  TextField,
  Button,
} from "@material-ui/core";
import SearchComponent from "components/SearchComponent";
import Api from "services/api";
import ModalAdd from "../childDisease/ModalAdd";

export default function FormPatologies({
  patient,
  type,
  record,
  handleClose,
  beforeSave,
}) {
  const cids = [
    {
      cid: null,
      short_description: "Não possui Patologias",
    },
    {
      cid: "1012",
      short_description: "Câncer de mama",
      sex_restriction: "",
    },
    {
      cid: "1030",
      short_description: "Câncer de ovário",
      sex_restriction: "F",
    },
    {
      cid: "1102",
      short_description: "Câncer de tireóide",
      sex_restriction: "",
    },
    {
      cid: "1022",
      short_description: "Câncer de útero",
      sex_restriction: "F",
    },
    {
      cid: "1771",
      short_description: "Diabetes",
      sex_restriction: "",
    },
    {
      cid: "2449",
      short_description: "Doenças psiquiátricas",
      sex_restriction: "",
    },
    {
      cid: "6177",
      short_description: "Eclampsia",
      sex_restriction: "",
    },
    {
      cid: "5592",
      short_description: "Eclampsia",
      sex_restriction: "F",
    },
    {
      cid: "2554",
      short_description: "Epilepsia",
      sex_restriction: "",
    },
    {
      cid: "3958",
      short_description: "Hérnia inguinal",
      sex_restriction: "",
    },
    {
      cid: "3184",
      short_description: "Hipertensão ",
      sex_restriction: "",
    },
    {
      cid: "1697",
      short_description: "Hipotireiodismo",
      sex_restriction: "",
    },
    {
      cid: "5588",
      short_description: "Pré-eclampsia",
      sex_restriction: "F",
    },
  ];

  const [search, setSearch] = useState("");
  const [state, setState] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const dispatch = useDispatch();
  const appointment = useSelector((state) => state.appointment.id);
  const appointment_action = useSelector((state) => state.appointment.saved.id);
  const [notApplicable, setNotApplicable] = useState(false);
  const appointment_action_form = useSelector(
    (state) => state.appointment.saved.personalHistory
  );
  const user = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);

  const gender = useSelector((state) =>
    type === "pp"
      ? state.Profile.basic.genderVal
      : state.patientInfo.patient.gender
  );

  useEffect(() => {
    const ids = record.map((row) => row.cid);
    // Lista os ja adicionados acima e as posibilidades abaixo
    setState([
      ...record.map((row) => {
        if (row.cid) {
          return {
            ...row,
            checked: true,
            alter: false,
            initial: true,
          };
        } else {
          return {
            ...row,
            checked: true,
            alter: false,
            initial: true,
            short_description: "Não possui Patologias",
          };
        }
      }),

      ...cids
        .filter(
          (row) =>
            ids.indexOf(row.cid) === -1 &&
            !(row.sex_restriction === "F" && (gender === 0 || gender === "M"))
        )
        .map((row) => ({
          ...row,
          observation: "",
          checked: false,
          alter: false,
        })),
    ]);
  }, []);

  const handleChange = (index, field, value) => {
    // setState(
    //   state.map((row, key) =>
    //     key === index ? { ...row, [field]: value, alter: true } : row
    //   )
    // );

    setState(
      state.map((row, key) => {
        if (key === index) {
          if (row.cid == null && value == true) {
            setNotApplicable(true);
            return { ...row, [field]: value, alter: true };
          } else if (row.cid == null && value == false) {
            setNotApplicable(false);
            return { ...row, [field]: value, alter: true };
          } else {
            return { ...row, [field]: value, alter: true };
          }
        } else {
          return row;
        }
      })
    );
  };

  console.log("state", state);
  const handleSave = () => {
    let toDelete = state.filter((row) => row.initial && !row.checked);
    let toAdd = state.filter((row) => row.alter && row.checked);

    Api.post("/microservice/personalhistory/savePatologies/", {
      type,
      patient,
      user,
      address,
      appointment,
      appointment_action,
      appointment_action_form,
      notApplicable,
      toDelete,
      toAdd,
    }).then((res) => {
      if (type !== "pp") {
        if (!appointment) {
          dispatch(setAppointment(res.data.appointment));
        }
        if (!appointment_action) {
          dispatch(setSavedActions("personalHistory", res.data.saved));
        }
      }
      setNotApplicable(false);
      beforeSave();
    });
  };

  return (
    <React.Fragment>
      <div
        className="col-12"
        style={{
          minWidth: "650",
          overflowX: "auto",
        }}
      >
        <SearchComponent searchContact={(e) => setSearch(e)} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Diagnóstico</TableCell>
              <TableCell>Observações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state
              .filter(
                (el) =>
                  el.short_description &&
                  el.short_description
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1
              )
              .map((row, key) => (
                <TableRow key={key}>
                  <TableCell width="30%">
                    {row.personal_description
                      ? row.personal_description
                      : row.short_description}
                  </TableCell>
                  <TableCell width="20%">
                    <Switch
                      id={key}
                      color="primary"
                      checked={row.checked}
                      onChange={(e) =>
                        handleChange(key, "checked", e.target.checked)
                      }
                      aria-label="checked"
                    />
                  </TableCell>
                  <TableCell width="50%">
                    {row.checked ? (
                      <TextField
                        fullWidth
                        placeholder="Observações"
                        value={row.observation}
                        onChange={(e) =>
                          handleChange(key, "observation", e.target.value)
                        }
                      />
                    ) : (
                      <span />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            <TableRow key="new">
              <TableCell colSpan={2}>
                <Button color="primary" onClick={() => setModalAdd(true)}>
                  <i className="zmdi zmdi-plus zmdi-fw" />
                  Adicionar diagnóstico não listado
                </Button>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className="col-12 mt-3">
        <BtnCan title="cancelar" onClick={() => handleClose()} float="left" />
        <BtnSave title="Salvar" float="right" onClick={handleSave} />
      </div>
      <ModalAdd
        open={modalAdd}
        handleSelect={(obj) => setState([...state, ...obj])}
        handleClose={() => setModalAdd(false)}
      />
    </React.Fragment>
  );
}
