import React from "react";
import ProgressBar from "../CircularProgress/ProgressBar";
export default function BarProgress() {
  return (
    <div
      className="col-12 d-flex justify-content-center py-4"
      style={{ alignItems: "center" }}
    >
      <ProgressBar />
    </div>
  );
}
