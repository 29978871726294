import React from "react";
import DataTable from "components/Tables/DataTable";

export default function ProceduresPatient({ record }) {
  return (
    <DataTable
      name="Procedimentos"
      columns={["Data", "Procedimento"]}
      rows={record.map((row, index) => [row.date, row.procedure])}
      download={false}
      print={false}
      hideSearch={true}
      rowsPerPage={25}
    />
  );
}
