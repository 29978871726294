import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import api from "services/api";
import { getUrlPhoto } from "services/functions";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import {
  showChatModal,
  setSelectedChat,
  selectedUserChat,
  chatModalMobile,
} from "actions/comunication/Chat";

import ChatUserList from "components/chatPanel/ChatUserList";
import ContactsList from "components/chatPanel/ContactList/index";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import IntlMessages from "util/IntlMessages";
import Tab from "@material-ui/core/Tab";
import SearchComponent from "components/SearchComponent";
import BarProgress from "components/CircularProgress/ProgressBar";
import { BtnAdd } from "components/Button";
import ModalAdd from "../../app/routes/comunication/routes/contacts/ModalAdd";

export default function ContactList({ contacts, closeDrawer }) {
  const [selectedTabIndex, setselectedTabIndex] = useState(0);
  const [searchContact, setSearchContact] = useState("");
  const [searchChats, setSearchChats] = useState("");
  const [chatIds, setChatIds] = useState([]);
  const [chatUsers, setChatUsers] = useState([]);
  const authUser = useSelector(({ auth }) => auth.authUser);
  const width = useSelector(({ settings }) => settings.width);
  const [loadingChats, setLoadingChats] = useState(true);
  const [modalAdd, setModalAdd] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => setselectedTabIndex(newValue);
  const handleChangeIndex = (index) => setselectedTabIndex(index);

  useEffect(() => {
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/`)
      .once("value", (snap) => {
        setChatIds(Object.keys(snap.val() ? snap.val() : []));
      });
  }, []);

  getDataFirebase().on("child_changed", (snap) => {
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/`)
      .once("value", (snap) => {
        setChatIds(Object.keys(snap.val() ? snap.val() : []));
      });
  });

  useEffect(() => {
    if (chatIds.length > 0) {
      setLoadingChats(true);
      api
        .post(`/ms_comunication/chat/getChats/`, {
          user: authUser,
          ids: chatIds.join(","),
        })
        .then(({ data }) => {
          setChatUsers(
            data.map((row) => {
              let lastMessage;
              getDataFirebase()
                .child(`chats${getEnviromentFirebase()}/${row.chat_id}`)
                .limitToLast(1)
                .once("value", (snap) => {
                  lastMessage = Object.values(snap.val())[0];
                });
              return {
                ...row,
                thumb: getUrlPhoto(row.id, row.thumb),
                lastMessage,
              };
            })
          );
          setLoadingChats(false);
        })
        .catch(() => {
          setLoadingChats(false);
          dispatch(
            viewAlertMessage(
              "error",
              "Desculpe, não foi possível carregar suas conversas"
            )
          );
          setTimeout(() => {
            dispatch(closeAlertMessage());
          }, 5000);
        });
    }
  }, [chatIds]);

  const onSelectUser = (user) => {
    if (width > 1200) {
      dispatch(showChatModal(user));
    } else {
      dispatch(setSelectedChat(user.chat_id));
      dispatch(selectedUserChat(user));
      dispatch(chatModalMobile(true));
    }
    closeDrawer();
  };
  return (
    <div style={{ maxWidth: "350px" }}>
      <AppBar position="static" className="no-shadow chat-tabs-header">
        <Tabs
          className="chat-tabs"
          value={selectedTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label={<IntlMessages id="chat.chatUser" />} />
          <Tab label={<IntlMessages id="chat.contacts" />} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        index={selectedTabIndex}
        onChangeIndex={handleChangeIndex}
      >
        {loadingChats ? (
          <div
            className="col-12 d-flex mt-3 justify-content-center"
            style={{ alignItems: "center" }}
          >
            <BarProgress />
          </div>
        ) : (
          <>
            <SearchComponent searchContact={(text) => setSearchChats(text)} />
            {chatUsers.filter(
              (el) =>
                el.name.toLowerCase().indexOf(searchChats.toLowerCase()) !== -1
            ).length > 0 ? (
              <ChatUserList
                chatUsers={chatUsers.filter(
                  (el) =>
                    el.name.toLowerCase().indexOf(searchChats.toLowerCase()) !==
                    -1
                )}
                selectedSectionId={null}
                onSelectUser={onSelectUser}
              />
            ) : (
              <div
                className="col-12 d-flex mt-3 justify-content-center"
                style={{ alignItems: "center" }}
              >
                <p style={{ color: "#a59f9f", fontStyle: "italic" }}>
                  Nenhum Chat encontrado
                </p>
              </div>
            )}
          </>
        )}
        <>
          <SearchComponent searchContact={(text) => setSearchContact(text)} />
          {contacts.filter(
            (el) =>
              el.name.toLowerCase().indexOf(searchContact.toLowerCase()) !== -1
          ).length > 0 ? (
            <ContactsList
              contactList={contacts.filter(
                (el) =>
                  el.name.toLowerCase().indexOf(searchContact.toLowerCase()) !==
                  -1
              )}
              selectedSectionId={null}
              onSelectUser={onSelectUser}
            />
          ) : (
            <div
              className="col-12 mt-3 justify-content-center"
              style={{ alignItems: "center" }}
            >
              <p
                style={{
                  color: "#a59f9f",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Nenhum contato encotrado encontrado
              </p>
              <div className="col-12 text-center">
                <BtnAdd
                  title="adicionar"
                  onClick={() => setModalAdd(true)}
                  float={"unset"}
                />
                <ModalAdd
                  open={modalAdd}
                  handleSuccess={(list) => {}}
                  handleClose={() => setModalAdd(false)}
                />
              </div>
            </div>
          )}
        </>
      </SwipeableViews>
    </div>
  );
}
