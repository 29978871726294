import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import api from "services/api";

import { setConditions } from "actions/configuration/TagsConditions";

import { Button, Tooltip, Chip, Menu, MenuItem } from "@material-ui/core";

import AutocompleteMedicine from "./autocomplete/AutocompleteMedicine";

class ConditionalMedicines extends Component {
  state = {
    medicines: [],
    open: false,
    anchorEl: undefined,
    button_text: true
  };
  handleChange = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };
  handleRequestClose = value => {
    this.setState({ open: false, button_text: value });
  };
  handleChangeAutocomplete = () => event => {
    let medic = this.state.medicines.map(med => med);
    medic.push(event);
    this.setState({ medicines: medic });
    this.props.setConditions("SET_CONDITION_MEDICINES", medic);
  };
  handleDeleteMedicine = index => {
    let newArr = this.state.medicines.map(med => med);
    newArr.splice(index, 1);
    this.setState({ medicines: newArr });
    this.props.setConditions("SET_CONDITION_MEDICINES", newArr);
  };
  componentDidMount() {
    if (this.props.params !== null) {
      api
        .post(`/ms_configuration/configurationTags/getMedicinesFromId/`, {
          ids: this.props.params
        })
        .then(res => {
          let cids = res.data;
          this.setState({ medicines: cids });
          this.props.setConditions("SET_CONDITION_MEDICINES", cids);
        });
    } else {
      this.setState({ medicines: [] });
    }
  }
  render() {
    return (
      <div className="row" style={{ marginTop: "20px" }}>
        <div className="col-md-12">
          <h4>Medicamentos: </h4>
        </div>
        <div style={{ padding: "10px" }}>
          <Button
            onClick={this.handleChange}
            fullWidth={true}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            {this.state.button_text ? "Igual A " : "Diferente De "}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </Button>
          <Menu
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
          >
            <MenuItem onClick={() => this.handleRequestClose(true)}>
              Igual A
            </MenuItem>
            <MenuItem onClick={() => this.handleRequestClose(false)}>
              Diferente De
            </MenuItem>
          </Menu>
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-12">
              <AutocompleteMedicine
                handleChangeAutocomplete={this.handleChangeAutocomplete()}
              />
            </div>
          </div>
          <div className="row">
            {this.state.medicines.map((med, index) => (
              <Tooltip title={med.name_display} key={med.id}>
                <Chip
                  label={med.name_display}
                  onDelete={() => this.handleDeleteMedicine(index)}
                  className="chip-conditions"
                  color="primary"
                />
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setConditions }, dispatch);

export default connect(null, mapDispatchToProps)(ConditionalMedicines);
