import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import MaskCellphone from "components/Masks/MaskCellphone";
import Api from "services/api";
import MaskCep from "components/Masks/MaskCep";
import MaskCnpj from "components/Masks/MaskCnpj";
import { allowCNPJ } from "../Register/Functions";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";

export default function PersonalData({
  handleNext,
  handleSetState,
  stateInit,
  validateData,
  handleBack,
  history,
}) {
  const [state, setState] = useState(stateInit);
  const [modalEmail, setModalEmail] = useState(false);
  const [validaEmail, setValidateEmail] = useState(validateData.email);
  const [validaCnpj, setValidateCnpj] = useState(validateData.cnpj);
  const [modalCnpj, setModalCnpj] = useState(false);
  const [cnpjMessage, setCnpjMessage] = useState("");

  const [alert, setAlert] = useState(false);

  const {
    name,
    corporateName,
    cnpj,
    phone,
    registerMuniciapl,
    registerState,
    typeProvider,
    postalcode,
    number,
    neighborhood,
    address,
    complement,
    city,
    country,
    email,
    error,
  } = state;

  function searchCep(value) {
    let cep = value.replace(/_/g, "");
    if (cep.length === 9) {
      cep = cep.replace(",", "");
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => {
          setState({
            ...state,
            postalcode: res.data.cep,
            address: res.data.logradouro,
            neighborhood: res.data.bairro,
            city: res.data.localidade,
            state: res.data.uf,
            country: "Brasil",
          });
        })
        .catch(() => {
          console.log("Ocorreu um erro ao recuperar as informações do cep.");
        });
    }
  }

  const validate = () => {
    if (
      validaEmail &&
      validaCnpj &&
      corporateName &&
      name &&
      cnpj &&
      email &&
      phone &&
      phone.indexOf("_") === -1 &&
      postalcode &&
      number &&
      address &&
      city
    ) {
      setState({ ...state, error: false });
      handleSetState("providedData", state);
      handleNext();
    } else {
      setState({ ...state, error: true });
    }
  };

  React.useEffect(() => {
    let search = window.location.search;
    if (search) {
      let user = search.split("d20");
      if (user.length > 1) {
        getDataUser(user[1]);
      }
    }
  }, []);

  const getDataUser = (id) => {
    Api.post(`/ms_system/user/getUserQuickRegister`, { user: id }).then(
      (res) => {
        const {
          id,
          idData,
          gender,
          name,
          birthday,
          smartphone,
          email,
          cpf,
          quick_register,
        } = res.data.dataUser;

        if (quick_register === "1") {
          setState({
            ...state,
            id,
            idData,
            sex: gender,
            date: birthday,
            name,
            cellphone: smartphone ? smartphone : "",
            cpf,
            email,
          });
        } else {
          setAlert(true);
        }
      }
    );
  };

  const checkEmail = () => {
    setValidateEmail(false);
    if (email)
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setValidateEmail(false);
        return false;
      } else {
        setValidateEmail(true);
        Api.post(`/ms_system/user/checkEmail`, { email }).then((res) => {
          if (res.data.record.length !== 0) {
            setModalEmail(true);
          }
        });
        return true;
      }
  };

  const CheckCnpj = () => {
    setValidateCnpj(false);
    if (cnpj !== stateInit.cnpj) {
      if (cnpj.length !== 0) {
        const verifica = allowCNPJ(cnpj);
        if (verifica) {
          Api.post(`/ms_system/user/checkCNPJ`, { cnpj }).then((res) => {
            if (res.data.record.length !== 0) {
              setCnpjMessage(
                "Este CNPJ já está cadastrado. Por favor utilize outro."
              );
              setModalCnpj(true);
              setValidateCnpj(false);
              setState({ ...state, error: true });
              return false;
            } else {
              setValidateCnpj(true);
              return true;
            }
          });
        } else {
          setCnpjMessage("CNPJ Inválido");
          setModalCnpj(true);
          setValidateCnpj(false);
          setState({ ...state, error: true });
          return false;
        }
      } else {
        setValidateCnpj(false);
        setState({ ...state, error: true });
        return false;
      }
    } else {
      setValidateCnpj(true);
      return true;
    }
  };

  function handleChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <TextField
            id="name"
            required
            label="Nome do Prestador/Nome Fantasia:"
            margin="normal"
            helperText={error && !name ? "Campo Obrigatório" : ""}
            fullWidth
            value={name}
            error={error && !name ? true : false}
            onChange={(e) => setState({ ...state, name: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <TextField
            id="name"
            required
            label="Razão Social:"
            margin="normal"
            helperText={error && !corporateName ? "Campo Obrigatório" : ""}
            fullWidth
            value={corporateName}
            error={error && !corporateName ? true : false}
            onChange={(e) => handleChange("corporateName", e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <TextField
            id="cnpj"
            label="CNPJ"
            required
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCnpj,
              value: cnpj,
              onChange: (e) => handleChange("cnpj", e.target.value),
            }}
            helperText={
              error && !cnpj
                ? "Campo Obrigatório"
                : cnpj && !validaCnpj
                ? "Por Favor insira outro CNPJ."
                : ""
            }
            error={(error && !cnpj) || (error && !validaCnpj) ? true : false}
            onBlur={CheckCnpj}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="cnpj"
            label="Inscrição Municipal: "
            margin="normal"
            fullWidth
            value={registerMuniciapl}
            onChange={(e) => handleChange("registerMuniciapl", e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="cnpj"
            label="Inscrição Estadual: "
            margin="normal"
            fullWidth
            value={registerState}
            onChange={(e) => handleChange("registerState", e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="celular"
            label="Telefone"
            required
            InputProps={{
              inputComponent: MaskCellphone,
              value: phone,
              onChange: (e) => setState({ ...state, phone: e.target.value }),
            }}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            helperText={error && !phone ? "Campo Obrigatório" : ""}
            error={error && !phone}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="email"
            label="E-mail"
            required
            margin="normal"
            fullWidth
            value={email}
            onBlur={checkEmail}
            onChange={(e) => handleChange("email", e.target.value)}
            helperText={
              error && !email
                ? "Campo Obrigatório."
                : !validaEmail && email
                ? "E-mail Invalido."
                : ""
            }
            error={(error && !email) || (error && !validaEmail) ? true : false}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="col-12 d-flex justify-content-start p-0 mt-3">
        <h2 className="font-weight-bold">Endereço</h2>
      </div>
      <div className="row">
        <div className="col-md-4">
          <TextField
            id="postalcode"
            label="CEP"
            margin="normal"
            className="mt-0"
            fullWidth
            value={postalcode}
            InputProps={{
              inputComponent: MaskCep,
              value: state.postalcode,
              onChange: (e) => searchCep(e.target.value),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-8 col-12 ">
          <TextField
            required
            id="address"
            label="Logradouro"
            value={address}
            margin="normal"
            className="mt-0"
            fullWidth
            onChange={(e) => handleChange("address", e.target.value)}
            error={state.error && !state.address ? true : false}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4 col-12">
          <TextField
            required
            id="number"
            label="Número"
            value={number}
            margin="normal"
            fullWidth
            onChange={(e) => handleChange("number", e.target.value)}
            error={state.error && !state.number ? true : false}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4 col-12">
          <TextField
            id="complement"
            label="Complemento"
            value={complement}
            margin="normal"
            fullWidth
            onChange={(e) => handleChange("complement", e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4 col-12">
          <TextField
            id="neighborhood"
            label="Bairro"
            value={neighborhood}
            margin="normal"
            fullWidth
            onChange={(e) => handleChange("neighborhood", e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4 col-12">
          <TextField
            required
            id="city"
            label="Cidade"
            value={city}
            margin="normal"
            fullWidth
            InputProps={{ readOnly: state.city ? true : false }}
            onChange={(e) => handleChange("city", e.target.value)}
            error={state.error && !state.city ? true : false}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4 col-12">
          <TextField
            required
            id="state"
            label="Estado"
            value={state.state}
            margin="normal"
            fullWidth
            InputProps={{ readOnly: state.state ? true : false }}
            onChange={(e) => handleChange("state", e.target.value)}
            error={state.error && !state.state ? true : false}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="col-md-4 col-12">
          <TextField
            id="country"
            label="País"
            value={country}
            margin="normal"
            fullWidth
            InputProps={{ readOnly: state.country ? true : false }}
            onChange={(e) => handleChange("country", e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>

      <div className="d-flex mt-3" style={{ justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={validate}
          className="jr-btn"
          style={{ float: "right", marginRight: 0 }}
        >
          Próxima
        </Button>
      </div>
      <SweetAlert
        show={modalCnpj}
        warning
        showConfirm={true}
        title={cnpjMessage}
        onConfirm={() => setModalCnpj(false)}
      />
    </>
  );
}
