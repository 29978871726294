import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";
import Chip from "@material-ui/core/Chip";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ComponentsProfile from "components/ComponentsProfile";

const styles = {
  container: {
    flexGrow: 1,
    // height: 100,
    width: "100%"
  },
  root: {
    backgroundColor: "#fff"
  },
  selected: {
    backgroundColor: "#e2e2e8 !important"
  }
};

class AutocompletePatient extends Component {
  state = {
    suggestions: []
  };

  getSuggestions = e => {
    if (e.target.value.length > 2) {
      Api.post(`/microservice/autocomplete/getPatientToAttendance/`, {
        term: e.target.value,
        address: this.props.address,
        id_doc: this.props.doctor
      }).then(res => {
        this.setState({
          suggestions: res.data.record
        });
      });
    }
  };

  renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    const { classes } = this.props;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
          minWidth: "150",
          overflowX: "auto"
        }}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      >
        <div className="row">
          <div className="col-8">
            <ComponentsProfile
              id={suggestion.id}
              nameProfile={suggestion.name}
              subTitle={
                suggestion.birthday ? suggestion.birthday : "Não informado."
              }
              photo={suggestion.photo}
            />
          </div>
          <div className="col-4">
            {suggestion.id === "new" && (
              <Chip
                className="m-1 ml-3"
                size="small"
                color="primary"
                icon={<PersonAddIcon />}
                label="Novo usuário"
              />
            )}
            {suggestion.id < 0 && (
              <Chip
                className="m-1 ml-3 bg-success"
                size="small"
                color="secondary"
                icon={<PersonAddIcon />}
                label="Associar"
              />
            )}
            {suggestion.id !== "new" && suggestion.id > 0 && (
              <Chip
                className="m-1 ml-3"
                color="info"
                size="small"
                icon={<PersonAddIcon />}
                label="Associado"
              />
            )}
          </div>
        </div>
      </MenuItem>
    );
  }

  renderInput(inputProps) {
    let { InputProps, classes, ref, ...other } = inputProps;
    return (
      <TextField
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input
          },
          ...InputProps
        }}
        onKeyUp={this.getSuggestions}
        onChange={this.handleChange}
      />
    );
  }

  render() {
    const { classes, handleChangeAutocomplete } = this.props;
    const { suggestions } = this.state;

    return (
      <Downshift
        onSelect={(selectedItem, obj) => {
          let item = suggestions.filter(row => {
            return row.name === selectedItem;
          });
          handleChangeAutocomplete(item[0]);
          obj.setState({ inputValue: "" });
        }}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex
        }) => (
          <div className={classes.container}>
            {this.renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                id: "patient",
                value: inputValue
              }),
              label: "Pesquise pelo paciente",
              margin: "normal"
            })}
            {isOpen ? (
              <Paper square>
                {suggestions.map((suggestion, index) =>
                  this.renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.name
                    }),
                    highlightedIndex,
                    selectedItem
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}
export default withStyles(styles)(AutocompletePatient);
