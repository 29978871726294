import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogContent } from "@material-ui/core";
import { hideModalConfig } from "actions/Configuration";
import SidenavConsult from "./components/sidenavConsult";
import SystemReview from "./components/systemReview";
import Aga from "./components/aga";
import VitalSigns from "./components/vitalSigns";
import Calendar from "./components/calendar";
import EventType from "./components/eventType";
import Tags from "./components/tags";
import MedicinesGroups from "./components/medicinesGroups";
import Procedure from "./components/procedure";
import ProcedureGroups from "./components/procedureGroups";
import ExamsGroups from "./components/examsGroups";
import DiagnosticGroups from "./components/diagnosticGroups";
import SystemReviewForms from "./components/SystemReviewForms";
import Security from "./components/security";
import Anamnesis from "./components/anamnesis";
import AdmissionForm from "./components/admissionForm";
import Admission from "./components/admission";
import {
  ALL_QUESTIONS_ARR,
  QUESTION_URINARIO,
  URINARIO,
  getTypeUrinarioGender,
} from "constants/CustomSystemReview";

export default function Configuration() {
  const show = useSelector((state) => state.configuration.modal_config);
  const view = useSelector((state) => state.configuration.modal_config_view);
  const gender = useSelector(({ patientInfo }) => patientInfo.patient.gender);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModalConfig());
  };

  return (
    <Dialog
      fullWidth
      maxWidth={view !== "security" ? "lg" : "md"}
      open={show}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="body"
    >
      <DialogContent>
        {view === "security" && <Security close={handleClose} />}
        {view === "sidenav_consult" && <SidenavConsult close={handleClose} />}
        {view === "systemReview" && <SystemReview close={handleClose} />}
        {view === "aga" && <Aga close={handleClose} />}
        {view === "vitalSigns" && <VitalSigns close={handleClose} />}
        {view === "calendar" && <Calendar close={handleClose} />}
        {view === "eventType" && <EventType close={handleClose} />}
        {view === "tags" && <Tags close={handleClose} />}
        {view === "medicinesGroups" && <MedicinesGroups close={handleClose} />}
        {view === "procedure" && <Procedure close={handleClose} />}
        {view === "procedureGroups" && <ProcedureGroups close={handleClose} />}
        {view === "examsGroups" && <ExamsGroups close={handleClose} />}
        {view === "anamnesis" && <Anamnesis close={handleClose} />}
        {view === "admissionForm" && <AdmissionForm close={handleClose} />}
        {view === "admission" && <Admission close={handleClose} />}
        {view === "diagnosticGroups" && (
          <DiagnosticGroups close={handleClose} />
        )}
        {view.indexOf("Forms") > -1 && (
          <SystemReviewForms
            arrayQuestion={
              Number(view.split("Forms")[1]) === URINARIO
                ? QUESTION_URINARIO(gender)
                : ALL_QUESTIONS_ARR[Number(view.split("Forms")[1])]
            }
            formType={
              Number(view.split("Forms")[1]) === URINARIO
                ? getTypeUrinarioGender(gender)
                : Number(view.split("Forms")[1])
            }
            close={handleClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
