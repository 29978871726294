import React, { useState, useEffect } from "react";
import { getListTypesEvent } from "actions/configuration/TypeEvent";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Tooltip,
} from "@material-ui/core";
import TypesEventForm from "./TypesEventForm";
import CircularProgress from "components/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";
import DataTable from "components/Tables/DataTable";

function EventType({ close }) {
  const [modal_form, setModalForm] = useState(false);
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [typeEvent, setTypeEvent] = useState("");
  const [typeDelete, setTypeDelete] = useState("");
  const [state, setState] = useState({
    procedureDimensional: "",
    procedureName: "",
    value: "",
    label: "",
  });
  const user = useSelector((state) => state.auth.authUser);
  const provider_type = useSelector((state) => state.auth.provider_type);
  const secretary = useSelector((state) => state.auth.secretary);
  const user_manage = useSelector((state) => state.auth.user_manage);
  const loaded = useSelector(({ typeEvent }) => typeEvent.loaded);
  const list = useSelector(({ typeEvent }) => typeEvent.list_types);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListTypesEvent(secretary ? user_manage.id : user));
  }, []);

  function deleteTypeEvent() {
    Api.post(`/ms_configuration/typeevent/deleteTypeEvent/`, {
      id: typeDelete,
    }).then((res) => {
      dispatch({
        type: "REMOVE_TYPE_EVENT",
        payload: { id: typeDelete },
      });
      setModalDelete(false);
      setSuccessDelete(true);
    });
  }

  const handleClickRemove = (id) => {
    setModalDelete(true);
    setTypeDelete(id);
  };

  const editType = (params) => {
    setState({
      ...params,
      value:
        params.price === null || params.price === 0
          ? 0.0
          : params.price.replace(".", ","),
    });
    setTypeEvent(params.id);
    setModalForm(true);
  };

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        <h2 className="text-primary font-weight-semibold w-100">
          Meus Serviços
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
        <div className="col-12">
          <Button
            onClick={() => {
              setTypeEvent("");
              setState({
                label: "",
                procedure: "",
                procedureDimensional: "",
                procedureName: "",
                value: "",
                edit: false,
              });
              setModalForm(true);
            }}
            className="jr-btn jr-btn-sm"
            color="primary"
            variant="contained"
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-plus" />
            <span> Novo Serviço</span>
          </Button>
        </div>
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          {list !== undefined && list.length > 0 && (
            <DataTable
              columns={[
                "",
                "Descrição",
                "Procedimento(s)",
                provider_type !== "56" && "Valor",
                "Duração",
                "Dias p/ retorno",
                "Ações",
              ]}
              rows={list.map((el) => {
                return [
                  <i
                    className="zmdi zmdi-tag zmdi-hc-lg"
                    style={{ color: el.color, width: 30, height: 15 }}
                  ></i>,
                  el.label,
                  el.procedures ? (
                    <ul>
                      {el.procedures.map((proc) => (
                        <li key={proc.id}>{proc.description}</li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  ),
                  provider_type !== "56" && (
                    <span>R$ {el.price === null ? "0,00" : el.price}</span>
                  ),
                  el.duration.substr(0, 5),
                  el.days === null ? "---" : `${el.days} Dias`,
                  [
                    <>
                      <IconButton
                        onClick={() => {
                          editType({
                            ...el,
                            edit: true,
                          });
                        }}
                        color="primary"
                        aria-label="Editar"
                      >
                        <i className="zmdi zmdi-edit zmdi-hc-fw" />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        aria-label="Remover"
                        onClick={() => handleClickRemove(el.id)}
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw" />
                      </IconButton>
                    </>,
                  ],
                ];
              })}
            />
          )}
        </div>

        <TypesEventForm
          open={modal_form}
          type="config"
          id={typeEvent}
          data={{
            ...state,
            id: typeEvent,
          }}
          edit={state.edit}
          close={() => setModalForm(false)}
        />

        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, excluir"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Deseja realmente excluir este registro?"
          onConfirm={() => deleteTypeEvent()}
          onCancel={() => setModalDelete(false)}
        />
        <SweetAlert
          show={success_delete}
          success
          title="Registro excluido com sucesso!"
          onConfirm={() => setSuccessDelete(false)}
        />
      </div>
    );
  }
}

export default withRouter(EventType);
