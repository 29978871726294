import moment from "moment";

const INITIAL_STATE = {
  prescription_id: "",
  pattern_id: "",
  pattern: false,
  name: "",
  orientation: "",
  form: false,
  patterns: {},
  medicines: [],
  new_medicine: false,
  medicine_atual: {},
  selected_medicine: "",
  saved: false,
  loaded: false,
  prescriptions: [],
  modal_delete_prescription: false,
  list_med_group: [],
  list_med: [],
  medicine_selected: "",
  in_use: [],
  in_allergic: [],
  //Transcrição
  is_transcription: false,
  name_doctor: "",
  crm_doctor: "",
  file: {},
  save_medicine: false,

  // prescription Manipulated
  recipe_id: "",
  originNew: null,
  classNew: null,
  treatmentNew: null,
  presentationNew: null,
  formulaNew: null,
  ingredientNew: [],
  origin: [],
  class: [],
  treatment: [],
  formula: [],
  ingredient: [],
  ingredientAdd: [],
  dosage: [],
  forms: [],
  list_manipulated: [],
  savedM: false,

  //load
  LoadSavePrescription: false,
};

export default function prescription(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_PATTERN_SUCCESS":
      const { salutem, doctor, patient, treatments } = action.payload;
      return {
        ...state,
        patterns: {
          salutem,
          doctor,
          patient,
          treatments,
        },
        loaded: true,
      };
    case "CHANGE_PATTERN_SUCCESS":
      let pattern = state.patterns.doctor.filter((row) => {
        return row.id === action.payload.pattern;
      });

      if (pattern.length === 0) {
        pattern = state.patterns.patient.filter((row) => {
          return row.id === action.payload.pattern;
        });
      }

      if (pattern.length === 0) {
        pattern = state.patterns.salutem.filter((row) => {
          return row.id === action.payload.pattern;
        });
      }

      var div_interval = 1;
      action.payload.medicines.forEach((element) => {
        switch (element.interval) {
          case "Semanal":
            div_interval = 7;
            break;
          case "2x por semana":
            div_interval = 3.75;
            break;
          case "3x por semana":
            div_interval = 2.33;
            break;
          case "15 em 15 dias":
            div_interval = 15;
            break;
          case "Mensal":
            div_interval = 30;
            break;
          case "Bimestral":
            div_interval = 60;
            break;
          case "Trimestral":
            div_interval = 90;
            break;
          case "Semestral":
            div_interval = 182.5;
            break;
          case "Anual":
            div_interval = 365;
            break;
          case "Dia sim, dia não":
            div_interval = 2;
            break;
          case "Dias específicos":
            div_interval = 0;
            break;
          default:
            div_interval = 1;
            break;
        }

        element.interval_divisor = div_interval;
        element.date_start = moment().format("MM-DD-YYYY");
      });

      return {
        ...state,
        medicines: action.payload.medicines,
        orientation: pattern[0].recipe_orientation,
        name: pattern[0].name,
        prescription_id: "",
        name_doctor: action.payload.medicines[0].name_doctor,
        crm_doctor: action.payload.medicines[0].crm_doctor,
        in_use: action.payload.in_use,
        in_allergic: action.payload.in_allergic,
        form: true,
      };

    case "CHANGE_MEDICINE_USE_SUCCESS":
      var div_interval = 1;
      action.payload.medicines.forEach((element) => {
        switch (element.interval) {
          case "Semanal":
            div_interval = 7;
            break;
          case "2x por semana":
            div_interval = 3.75;
            break;
          case "3x por semana":
            div_interval = 2.33;
            break;
          case "15 em 15 dias":
            div_interval = 15;
            break;
          case "Mensal":
            div_interval = 30;
            break;
          case "Bimestral":
            div_interval = 60;
            break;
          case "Trimestral":
            div_interval = 90;
            break;
          case "Semestral":
            div_interval = 182.5;
            break;
          case "Anual":
            div_interval = 365;
            break;
          case "Dia sim, dia não":
            div_interval = 2;
            break;
          case "Dias específicos":
            div_interval = 0;
            break;
          default:
            div_interval = 1;
            break;
        }

        element.interval_divisor = div_interval;
      });

      return {
        ...state,
        medicines: action.payload.medicines,
        prescription_id: "",
        name_doctor: action.payload.medicines[0].name_doctor,
        crm_doctor: action.payload.medicines[0].crm_doctor,
        in_use: action.payload.in_use,
        form: true,
      };

    case "EDIT_PRESCRIPTION_SUCCESS":
      let pattern_edit = state.patterns.doctor.filter((row) => {
        return row.id === action.payload.prescription;
      });

      if (pattern_edit.length === 0) {
        pattern_edit = state.patterns.patient.filter((row) => {
          return row.id === action.payload.prescription;
        });
      }

      if (pattern_edit.length === 0) {
        pattern_edit = state.patterns.salutem.filter((row) => {
          return row.id === action.payload.prescription;
        });
      }

      var div_interval = 1;
      action.payload.medicines.forEach((element) => {
        switch (element.interval) {
          case "Semanal":
            div_interval = 7;
            break;
          case "2x por semana":
            div_interval = 3.75;
            break;
          case "3x por semana":
            div_interval = 2.33;
            break;
          case "15 em 15 dias":
            div_interval = 15;
            break;
          case "Mensal":
            div_interval = 30;
            break;
          case "Bimestral":
            div_interval = 60;
            break;
          case "Trimestral":
            div_interval = 90;
            break;
          case "Semestral":
            div_interval = 182.5;
            break;
          case "Anual":
            div_interval = 365;
            break;
          case "Dia sim, dia não":
            div_interval = 2;
            break;
          case "Dias específicos":
            div_interval = 0;
            break;
          default:
            div_interval = 1;
            break;
        }

        element.interval_divisor = div_interval;
      });

      return {
        ...state,
        medicines: action.payload.medicines,
        orientation: pattern_edit[0].recipe_orientation,
        name: pattern_edit[0].name,
        prescription_id: action.payload.prescription,
        name_doctor:
          action.payload.transcription !== null
            ? action.payload.transcription.name_doctor
            : "",
        crm_doctor:
          action.payload.transcription !== null
            ? action.payload.transcription.crm_doctor
            : "",
        file: action.payload.transcription,
        in_use: action.payload.in_use,
        in_allergic: action.payload.in_allergic,
        form: true,
      };
    case "ALTER_MODE":
      return {
        ...state,
        prescription_id: "",
        pattern_id: "",
        pattern: false,
        name: "",
        orientation: "",
        name_doctor: "",
        crm_doctor: "",
        file: {},
        form: action.form,
        is_transcription: action.is_transcription,
        medicines: [],
        selected_medicine: "",
        saved: false,
      };
    case "NEW_MEDICINE":
      return {
        ...state,
        new_medicine: true,
        selected_medicine: action.id_medicine,
        medicine_atual: action.medicine,
      };
    case "ADD_MEDICINE":
      let returnManipulation = "";
      let tmpMedicine = action.medicine;

      returnManipulation +=
        tmpMedicine.quantity +
        " " +
        (tmpMedicine.unity_measure !== "SEM" ? tmpMedicine.unity_measure : "") +
        ". ";
      if (!tmpMedicine.is_necessary) {
        if (tmpMedicine.times_a_day === 1) {
          returnManipulation += "por dia ";
        } else {
          returnManipulation += tmpMedicine.times_a_day + "x ao dia ";
        }
        if (tmpMedicine.interval === "Diário") {
          returnManipulation += "";
        } else if (tmpMedicine.interval === "Dia sim, dia não") {
          returnManipulation += "dia sim, dia não ";
        } else if (tmpMedicine.interval === "Semanal") {
          returnManipulation += "1x por semana ";
        } else if (tmpMedicine.interval === "Mensal") {
          returnManipulation += "1x por mês ";
        } else if (tmpMedicine.interval === "2x por semana") {
          returnManipulation += "2x por semana ";
        } else if (tmpMedicine.interval === "15 em 15 dias") {
          returnManipulation += "de 15 em 15 dias ";
        } else if (tmpMedicine.interval === "Sempre") {
          returnManipulation += " ";
        } else if (tmpMedicine.interval === "Anual") {
          returnManipulation += "1x por ano ";
        } else if (tmpMedicine.interval === "3 X POR SEMANA") {
          returnManipulation += "3x por semana ";
        } else if (tmpMedicine.interval === "trimestral") {
          returnManipulation += "1x a cada trimestre ";
        } else if (tmpMedicine.interval === "Dias específicos") {
          returnManipulation += tmpMedicine.days_week_list.toString() + " ";
        } else {
          returnManipulation += "";
        }
        returnManipulation += "durante " + tmpMedicine.duration + " dias";
      } else {
        returnManipulation += "apenas se necessário ";
      }

      action.medicine.detail = returnManipulation;
      return {
        ...state,
        medicines: [...state.medicines, action.medicine],
        new_medicine: false,
      };

    case "EDIT_MEDICINE":
      let edit_medicine = [];
      state.medicines.forEach((row) => {
        if (row.ls_mp_medicine === action.edit) {
          let returnManipulation = "";
          let tmpMedicine = action.medicine;

          returnManipulation +=
            tmpMedicine.quantity +
            " " +
            (tmpMedicine.unity_measure !== "SEM"
              ? tmpMedicine.unity_measure
              : "") +
            ". ";

          if (!tmpMedicine.is_necessary) {
            if (tmpMedicine.times_a_day === 1) {
              returnManipulation += "por dia ";
            } else {
              returnManipulation += tmpMedicine.times_a_day + "x ao dia ";
            }
            if (tmpMedicine.interval === "Diário") {
              returnManipulation += "";
            } else if (tmpMedicine.interval === "Dia sim, dia não") {
              returnManipulation += "dia sim, dia não ";
            } else if (tmpMedicine.interval === "Semanal") {
              returnManipulation += "1x por semana ";
            } else if (tmpMedicine.interval === "Mensal") {
              returnManipulation += "1x por mês ";
            } else if (tmpMedicine.interval === "2x por semana") {
              returnManipulation += "2x por semana ";
            } else if (tmpMedicine.interval === "15 em 15 dias") {
              returnManipulation += "de 15 em 15 dias ";
            } else if (tmpMedicine.interval === "Sempre") {
              returnManipulation += " ";
            } else if (tmpMedicine.interval === "Anual") {
              returnManipulation += "1x por ano ";
            } else if (tmpMedicine.interval === "3 X POR SEMANA") {
              returnManipulation += "3x por semana ";
            } else if (tmpMedicine.interval === "trimestral") {
              returnManipulation += "1x a cada trimestre ";
            } else if (tmpMedicine.interval === "Dias específicos") {
              returnManipulation += tmpMedicine.days_week_list.toString() + " ";
            } else {
              returnManipulation += "";
            }

            returnManipulation += "durante " + tmpMedicine.duration + " dias";
          } else {
            returnManipulation += "apenas se necessário ";
          }
          action.medicine.detail = returnManipulation;
          edit_medicine.push(action.medicine);
        } else {
          edit_medicine.push(row);
        }
      });

      return {
        ...state,
        medicines: [...edit_medicine],
        new_medicine: false,
      };

    case "ADD_MEDICINE_COMPLEX":
      let medicine_complex = {
        name_display: action.name_display,
        ls_mp_medicine: action.ls_mp_medicine,
        posology: action.posology,
        is_continuos: action.is_continuos,
      };
      return {
        ...state,
        medicines: [...state.medicines, medicine_complex],
        new_medicine: false,
      };

    case "EDIT_MEDICINE_COMPLEX":
      let aux_med_complex = state.medicines.filter(
        (e) => e.ls_mp_medicine !== action.old_medicine
      );
      let medicine_complex_edit = {
        name_display: action.name_display,
        ls_mp_medicine: action.ls_mp_medicine,
        posology: action.posology,
        is_continuos: action.is_continuos,
      };

      aux_med_complex.push(medicine_complex_edit);

      return {
        ...state,
        medicines: aux_med_complex,
        new_medicine: false,
      };

    case "REMOVE_MEDICINE":
      let meds = state.medicines.filter((row) => {
        return row.ls_mp_medicine !== action.medicine;
      });
      return {
        ...state,
        medicines: meds,
      };
    case "CANCEL_MEDICINE_IN_USE":
      return {
        ...state,
        medicines: state.medicines.filter(
          (row) => row.ls_mp_medicine !== action.medicine
        ),
        in_use: state.in_use.filter(
          (row) => row.ls_mp_medicine !== action.medicine
        ),
      };
    case "CANCEL_FORM_MEDICINE_IN_USE":
      return {
        ...state,
        prescription_id: "",
        pattern_id: "",
        pattern: false,
        name: "",
        orientation: "",
        form: false,
        new_medicine: false,
        selected_medicine: "",
        saved: false,
        loaded: false,
        modal_delete_prescription: false,
        medicine_selected: "",
        //Transcrição
        is_transcription: false,
        name_doctor: "",
        crm_doctor: "",
        save_medicine: false,
      };
    case "CANCEL_MEDICINE":
      return {
        ...state,
        new_medicine: false,
      };
    case "HANDLE_CHANGE":
      const { index, value } = action;
      return {
        ...state,
        [`${index}`]: value,
      };
    case "SAVE_PRESCRIPTION_SUCCESS":
      let presc = state.prescriptions.filter((row) => {
        return row.id !== action.payload.prescription_id;
      });
      return {
        ...state,
        prescription_id: action.payload.prescription_id,
        pattern_id: action.payload.pattern_id,
        saved: true,
        form: false,
        LoadSavePrescription: false,
        prescription_name: action.payload.prescription_name,
        prescriptions: [
          ...presc,
          {
            name_doctor: action.payload.name_doctor,
            crm_doctor: action.payload.crm_doctor,
            id: action.payload.prescription_id,
            name: action.payload.prescription_name,
            medicines: state.medicines,
            orientation: state.orientation,
          },
        ],
      };
    case "RESET_FORM_PRESCRIPTION":
      return {
        ...state,
        name_doctor: "",
        crm_doctor: "",
        name: "",
        orientation: "",
        form: false,
        medicines: [],
        new_medicine: false,
        medicine_atual: {},
        selected_medicine: "",
        list_med_group: [],
        list_med: [],
        medicine_selected: "",
        in_use: [],
        in_allergic: [],
      };
    case "REMOVE_PRESCRIPTION_SUCCESS":
      if (action.payload.success) {
        let prescs;
        prescs = state.prescriptions.filter((row) => {
          return row.id !== action.payload.id_prescription;
        });
        return {
          ...state,
          prescription_id: "",
          name: "",
          orientation: "",
          prescriptions: prescs,
        };
      } else {
        return state;
      }
    case "EDIT_PRESCRIPTION":
      const { id, name, orientation, medicines } = action.prescription;

      return {
        ...state,
        prescription_id: id,
        name,
        orientation,
        medicines,
        form: true,
      };

    case "GET_GROUP_MED_LIST_SUCCESS":
      const { list_group } = action.payload;

      return {
        ...state,
        list_med_group: list_group,
      };

    case "GET_MED_LIST_SUCCESS":
      const { list_med } = action.payload;

      return {
        ...state,
        list_med: list_med,
      };

    case "SET_MED_SELECTED":
      const { medicine_id, medicine } = action;

      medicine.is_necessary = medicine.is_necessary === "1" ? true : false;
      medicine.is_continuos = medicine.is_continuos === "1" ? true : false;

      return {
        ...state,
        medicine_selected: medicine_id,
        medicine_atual: medicine,
      };

    case "RENEW_MEDICINE":
      let id_medicine = action.id_medicine;
      let date = action.date;
      let list_medicines = action.medicines;

      let aux = list_medicines.filter(
        (e) => e.ls_mp_medicine === id_medicine
      )[0];
      aux.date_start = date;

      let aux2 = list_medicines.filter((e) => e.ls_mp_medicine !== id_medicine);

      aux2 = [...aux2, aux];

      return {
        ...state,
        medicines: aux2,
      };
    case "RESET_PRESCRIPTION":
      return INITIAL_STATE;
    case "REQUEST_FORM_MEDICINE_USE":
      return { ...state, save_medicine: true };
    case "UNREQUEST_FORM_MEDICINE_USE":
      return { ...state, save_medicine: false };
    case "SET_LOAD_PRESCRIPTION":
      return { ...state, LoadSavePrescription: action.payload.load };
    default:
      return state;
  }
}
