import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Typography,
  Avatar,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { changeProfResp } from "actions/consult/Appointment";
import { changeAddress } from "actions/Auth";
import { viewHistory } from "actions/consult/Appointment";
import BarProgress from "components/CircularProgress/ProgressBar";
import Api from "services/api";
import NoRecord from "components/Alert/NoRecord";
import moment from "moment";
import HistoryIcon from "@material-ui/icons/History";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { setDataAppointment } from "actions/consult/Appointment";
import { getUrlPhoto } from "services/functions";
import HistoryPatient from "components/HistoryPatient";

export default function Student({ history }) {
  const [list_teachers, setListTeachers] = useState([]);
  const [events, setEvents] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [patient, setPatient] = useState("");

  const student = useSelector(state => state.auth.authUser);
  const prof_resp = useSelector(state => state.appointment.prof_resp);
  const dispatch = useDispatch();

  const playConsult = patient => {
    dispatch(setDataAppointment(patient, prof_resp));
    history.push("/app/consult/attendance-record");
  };

  // Listagem de endeços
  useEffect(() => {
    Api.post(`/microservice/autocomplete/getTeacherResponsibleStudent`, {
      student
    })
      .then(res => {
        setListTeachers(res.data.record);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    Api.post(`/ms_system/dashboard/getEventsByStudent`, {
      student
    }).then(res => {
      setEvents(res.data.record);
    });
  }, []);

  const handleChangeProfResp = value => {
    dispatch(changeProfResp(value));
    let info = list_teachers.find(row => row.id === value);
    dispatch(changeAddress(info.address));
  };

  return (
    <div className="col-12 animated slideInUpTiny animation-duration-4 mb-5">
      <Typography className="pt-3 ml-1" variant="h6" gutterBottom>
        Dashboard
      </Typography>
      {loaded ? (
        <div className="row">
          <div className="col-12 col-xs-12">
            <TextField
              select
              value={prof_resp}
              onChange={e => handleChangeProfResp(e.target.value)}
              fullWidth
              SelectProps={{}}
              margin="normal"
              label="Professor responsável:"
            >
              {list_teachers.map(row => (
                <MenuItem key={row.id} value={row.id}>
                  <div style={{ display: "flex" }}>
                    <Avatar
                      alt={row.name}
                      src={getUrlPhoto(row.id, row.photo)}
                      className="ml-3"
                    />
                    <span className="ml-2 mt-2">{row.name}</span>
                  </div>
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="col-12 mt-4">
            <Typography>Agenda - {moment().format("DD/MM/YYYY")}</Typography>
            <table className="table mt-3">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Horario</th>
                  <th scope="col"></th>
                  <th scope="col">Nome</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {events.map((e, index) => (
                  <tr
                    key={index}
                    style={{
                      fontSize: "14px",
                      fontWeight: "500"
                    }}
                  >
                    <th scope="row">{index + 1}</th>
                    <td
                      style={{
                        borderLeft: "1px solid red"
                      }}
                    >
                      {moment(e.start).format("HH:mm")} -{" "}
                      {moment(e.end).format("HH:mm")}
                    </td>
                    <td>
                      <Tooltip title={e.calendar} placement="top">
                        <span
                          style={{
                            backgroundColor: e.color,
                            padding: "0.3rem"
                          }}
                          className="size-10 lighten-1 rounded-circle d-inline-block mr-2"
                        />
                      </Tooltip>
                    </td>
                    <td>{e.title}</td>
                    <td>
                      {e.pacient_id && (
                        <div className="d-flex">
                          <Tooltip
                            title="Histórico do paciente"
                            placement="top"
                          >
                            <IconButton
                              style={{ padding: "6px" }}
                              color="primary"
                              onClick={() => {
                                setPatient(e.pacient_id);
                                dispatch(viewHistory("personalData"));
                              }}
                            >
                              <HistoryIcon
                                style={{
                                  fontSize: "19px",
                                  margin: "0px",
                                  padding: "0px"
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          {prof_resp && (
                            <Tooltip
                              title="Iniciar atendimento"
                              placement="top"
                            >
                              <IconButton
                                style={{ padding: "6px" }}
                                color="primary"
                                onClick={() =>
                                  playConsult(e.pacient_id, e.address)
                                }
                              >
                                <PlayArrowIcon
                                  style={{
                                    fontSize: "19px",
                                    margin: "0px",
                                    padding: "0px"
                                  }}
                                />{" "}
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
                {events.length === 0 && (
                  <tr>
                    <td colspan="4">
                      <NoRecord />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="col-12 d-flex justify-content-center m-2">
          <BarProgress />
        </div>
      )}
      <HistoryPatient id_patient={patient} />
    </div>
  );
}
