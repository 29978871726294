import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

export default function familyAccompaniment({ match, history }) {
  return (
    <div className="app-wrapper">
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          component={asyncComponent(() =>
            import("./routes/familyAccompaniment")
          )}
        />

        <Route
          path={`${match.url}/accompaniment`}
          component={asyncComponent(() => import("./routes/accompaniment"))}
        />
        <Route
          path={`${match.url}/report`}
          component={asyncComponent(() => import("./routes/report"))}
        />
      </Switch>
    </div>
  );
}
