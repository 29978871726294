import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Paper, TextField, MenuItem } from "@material-ui/core";

export default function CardChooseAddress() {
  const list_address = useSelector((state) => state.auth.list_address);
  const dispatch = useDispatch();

  return (
    <Paper elevation={3} className="p-3">
      <Typography className="pt-3 ml-1" variant="h6" gutterBottom>
        Local de Atendimento
      </Typography>{" "}
      <TextField
        select
        onChange={(e) => {
          dispatch({
            type: "CHANGE_ADDRESS",
            payload: {
              address: e.target.value,
            },
          });
          dispatch({
            type: "RESET_STOCK",
          });
        }}
        fullWidth
        label="Local:"
      >
        {list_address.map((e) => (
          <MenuItem value={e.id}>{e.name} </MenuItem>
        ))}
      </TextField>
    </Paper>
  );
}
