const INITIAL_STATE = {
  condition_sexo: null,
  condition_idade: [],
  condition_sickness: [],
  condition_producere: [],
  condition_medicines: [],
  condition_food_allergy: [],
  condition_drug_allergy: [],
  condition_diseases_allergy: [],
  condition_chemicals_allergy: [],
  edit_tag: null,
  tag_programming: null,
  programming_on_register: "1",
  loader_modal_patients: true,
  list_patirntes_from_tag: [],
  patients_not_has_tag: []
};
export default function tagsConditions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_TAGS_CONDITIONS":
      return INITIAL_STATE;
    case "SET_CONDITION_SEXO":
      return {
        ...state,
        condition_sexo: action.payload
      };
    case "SET_CONDITION_IDADE":
      return {
        ...state,
        condition_idade: action.payload
      };
    case "SET_CONDITION_SICKNESS":
      return {
        ...state,
        condition_sickness: action.payload
      };
    case "SET_CONDITION_PRODUCERE":
      return {
        ...state,
        condition_producere: action.payload
      };
    case "SET_CONDITION_MEDICINES":
      return {
        ...state,
        condition_medicines: action.payload
      };
    case "SET_CONDITION_FOOD_ALLERGY":
      return {
        ...state,
        condition_food_allergy: action.payload
      };
    case "SET_CONDITION_DRUG_ALLERGY":
      return {
        ...state,
        condition_drug_allergy: action.payload
      };
    case "SET_CONDITION_DISEASES_ALLERGY":
      return {
        ...state,
        condition_diseases_allergy: action.payload
      };
    case "SET_CONDITION_CHEMICALS_ALLERGY":
      return {
        ...state,
        condition_chemicals_allergy: action.payload
      };
    case "SET_INITIAL_CONDITIONS_TAGS":
      return {
        ...INITIAL_STATE
      };
    case "SET_EDIT_TAG":
      return {
        ...state,
        edit_tag: action.tag
      };
    case "ADD_NEW_TAG":
      return {
        ...state,
        edit_tag: null
      };
    case "SET_PROGRAMMING":
      return {
        ...state,
        tag_programming: action.payload
      };
    case "SET_ON_REGISTER":
      return {
        ...state,
        programming_on_register: action.payload
      };
    case "SET_ALL_PATIENTS_FROM_TAG":
      return {
        ...state,
        loader_modal_patients: false,
        list_patirntes_from_tag: action.payload
      };
    case "DELETE_USER_HAS_TAG":
      return {
        ...state,
        loader_modal_patients: true,
        list_patirntes_from_tag: []
      };
    case "SET_INITIAL_ALL_PATIENTS_FROM_TAG":
      return {
        ...state,
        loader_modal_patients: true,
        list_patirntes_from_tag: []
      };
    case "GET_PATIENTS_NOT_HAS_TAG":
      return {
        ...state,
        loader_modal_patients: true
      };
    case "SET_PATIENTS_NOT_HAS_TAG":
      return {
        ...state,
        patients_not_has_tag: action.payload,
        loader_modal_patients: false
      };
    case "INCLUDE_TAG_TO_PATIENTS":
      return {
        ...state,
        loader_modal_patients: true
      };
    default:
      return state;
  }
}
