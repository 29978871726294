import React, { useEffect } from "react";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan } from "components/Button";
import ComponentsProfile from "components/ComponentsProfile";
import Api from "services/api";
import moment from "moment";

export default function ViewEvent({ open, close, event }) {
  const [data, setData] = React.useState({});
  useEffect(() => {
    if (event.id) {
      Api.post("/ms_system/dashboard/getInfoEvent", { event: event.id }).then(
        (res) => {
          setData(res.data.result);
        }
      );
    }
  }, [event]);

  const getDisplayAddress = () => {
    return `${data.address}, ${data.number} ${
      data.complement ? data.complement : ""
    }, ${data.neighborhood} - CEP: ${data.postalcode}, ${data.city}/${
      data.state
    }`;
  };

  return (
    <div>
      <ModalResponsive
        title="Visualizar Compromisso"
        open={open}
        close={() => close()}
        maxWidth="sm"
        buttons={
          <div className="col-12">
            <BtnCan
              title="Fechar"
              onClick={() => {
                close();
              }}
              float="left"
            />
          </div>
        }
      >
        <div className="row">
          <div className="col-6">
            <strong>Profissional:</strong>{" "}
            <ComponentsProfile
              nameProfile={event.name}
              subTitle=""
              photo={event.photo}
              id={event.doctor}
            />
          </div>
          <div className="col-6">
            <strong>Data:</strong>{" "}
            {moment(event.date).format("DD/MM/YYYY HH:mm")}
          </div>
          {data.description && (
            <div className="col-12">
              <strong>Orientações:</strong> {`${data.description}`}
            </div>
          )}
          <div className="col-12">
            <strong>Local:</strong> {`${data.name}`}
          </div>
          <div className="col-12">
            <strong>Endereço:</strong> {getDisplayAddress()}
          </div>
          <div className="col-12">
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
                getDisplayAddress()
              )}&zoom=15&size=300x300&maptype=roadmap&markers=color:red|${encodeURIComponent(
                getDisplayAddress()
              )}&key=AIzaSyCx-skiDq9oPsf6-Ekhgv0nvnzAllOnJ50`}
              className="img-responsive"
              height="300px"
              width="100%"
            />
          </div>
        </div>
      </ModalResponsive>
    </div>
  );
}
