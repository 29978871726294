import React, { useState, useEffect } from "react";
import { BtnCan, BtnSave } from "components/Button";
import { TextField } from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import { MultipleSelect } from "react-select-material-ui";

export default function FormFilter({ open, handleClose, handleFilter, data }) {
  const [state, setState] = useState({
    doctor: [],
    list_doctor: [],
    specialty: [],
    list_specialty: [],
    start: "",
    end: "",
  });

  useEffect(() => {
    let list_doctor = [];
    let list_specialty = [];
    data.forEach((row) => {
      if (list_doctor.indexOf(row.name) === -1) {
        list_doctor.push(row.name);
      }
      if (row.specialty) {
        row.specialty.split(",").forEach((el) => {
          if (list_specialty.indexOf(el) === -1) {
            list_specialty.push(el);
          }
        });
      }
      setState({
        ...state,
        list_doctor,
        list_specialty,
      });
    });
  }, [data]);

  return (
    <ModalResponsive
      title="Filtro"
      open={open}
      close={handleClose}
      maxWidth="md"
      buttons={
        <div className="col-12">
          <BtnCan title="Fechar" onClick={handleClose} float="left" />

          <BtnSave
            title="Filtrar"
            onClick={() => {
              handleFilter({
                doctor: state.doctor,
                specialty:
                  state.specialty.length > 0 ? state.specialty.join(",") : "",
                start: state.start,
                end: state.end,
              });
              handleClose();
            }}
            float="right"
          />
        </div>
      }
    >
      <div className="row">
        <div className="col-12">
          <MultipleSelect
            label="Profissionais:"
            value={state.doctor}
            onChange={(e) => setState({ ...state, doctor: e })}
            options={state.list_doctor}
            SelectProps={{
              isCreatable: false,
              msgNoOptionsAvailable: "Todos as opções foram selecionadas",
              msgNoOptionsMatchFilter: "Nenhuma opção para este filtro",
            }}
          />
        </div>
        <div className="col-12">
          <MultipleSelect
            label="Especilidade:"
            value={state.specialty}
            onChange={(e) => setState({ ...state, specialty: e })}
            options={state.list_specialty}
            SelectProps={{
              isCreatable: false,
              msgNoOptionsAvailable: "Todos as opções foram selecionadas",
              msgNoOptionsMatchFilter: "Nenhuma opção para este filtro",
            }}
          />
        </div>
        <div className="col-12 mt-3">
          <TextField
            type="date"
            label="Início:"
            value={state.start}
            onChange={(e) => setState({ ...state, start: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            type="date"
            label="Fim:"
            value={state.end}
            onChange={(e) => setState({ ...state, end: e.target.value })}
            InputLabelProps={{ shrink: true }}
            className="ml-3"
          />
        </div>
      </div>
    </ModalResponsive>
  );
}
