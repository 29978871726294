import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Requests = ({ match, history }) => {
  const permissions = useSelector(state => state.permissions);
  const { manager, approver, master } = permissions;
  React.useEffect(() => {
    if (!manager && !approver && !master) {
      history.push("/app/dashboard");
    }
  }, []);
  return (
    <div className="app-wrapper">
      <Switch>
        <Route
          path={`${match.url}/`}
          component={asyncComponent(() => import("./routes/allRequests"))}
        />
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Requests;
