import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

export default function PatientPortal({ match, history }) {
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/`}
        component={asyncComponent(() => import("../profile/index"))}
      />
      <Route
        exact
        path={`${match.url}/personal-data`}
        component={asyncComponent(() => import("../profile/index"))}
      />
      <Route
        exact
        path={`${match.url}/allergies`}
        component={asyncComponent(() => import("./routes/allergies"))}
      />
      <Route
        exact
        path={`${match.url}/files-cloud`}
        component={asyncComponent(() => import("./routes/filesCloud"))}
      />
      <Route
        exact
        path={`${match.url}/compare-pictures`}
        component={asyncComponent(() => import("./routes/comparePictures"))}
      />
      <Route
        exact
        path={`${match.url}/family-history`}
        component={asyncComponent(() => import("./routes/familyHistory"))}
      />
      <Route
        exact
        path={`${match.url}/disabilities`}
        component={asyncComponent(() => import("./routes/disabilities"))}
      />
      <Route
        exact
        path={`${match.url}/child-disease`}
        component={asyncComponent(() => import("./routes/childDisease"))}
      />
      <Route
        exact
        path={`${match.url}/patologies`}
        component={asyncComponent(() => import("./routes/patologies"))}
      />
      <Route
        exact
        path={`${match.url}/habits`}
        component={asyncComponent(() => import("./routes/habits"))}
      />
      <Route
        exact
        path={`${match.url}/procedures`}
        component={asyncComponent(() => import("./routes/procedures"))}
      />

      <Route
        exact
        path={`${match.url}/medicines`}
        component={asyncComponent(() => import("./routes/medicines"))}
      />
      <Route
        exact
        path={`${match.url}/vaccines`}
        component={asyncComponent(() => import("./routes/vaccines"))}
      />
      <Route
        exact
        path={`${match.url}/data`}
        component={asyncComponent(() => import("./routes/data"))}
      />

      <Route
        exact
        path={`${match.url}/healthy-habits`}
        component={asyncComponent(() => import("./routes/healthyHabits"))}
      />

      <Route
        exact
        path={`${match.url}/defects`}
        component={asyncComponent(() => import("./routes/defects"))}
      />

      <Route
        exact
        path={`${match.url}/exams`}
        component={asyncComponent(() => import("./routes/exams"))}
      />

      <Route
        exact
        path={`${match.url}/attendance`}
        component={asyncComponent(() => import("./routes/attendance"))}
      />
    </Switch>
  );
}
