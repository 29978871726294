/**
 * ID tipo Tratemndo Médico
 */
export const TYPE_MEDICAL = "1";

/**
 * ID tipo Tratemndo Cirúrgico
 */
export const TYPE_SURGICAL = "2";

/**
 * ID tipo Tratemndo Ativo
 */
export const TYPE_ACTIVE = "3";

/**
 * ID tipo Tratemndo Paliativo
 */
export const TYPE_PALLIATIVE = "4";

/**
 * ID tipo Tratemndo Sintomático
 */
export const TYPE_SYMPTOMATIC = "5";

/**
 * ID tipo Tratemndo Alternativo
 */
export const TYPE_ALTERNATIVE = "6";

/**
 * ID tipo Tratemndo Preventivo
 */
export const TYPE_PREVENTIVE = "7";

/**
 * Nome dos tipos de Tratamento
 */
export const TT_NAMES = {
  [TYPE_MEDICAL]: "Médico",
  [TYPE_SURGICAL]: "Cirúrgico",
  [TYPE_ACTIVE]: "Activo",
  [TYPE_PALLIATIVE]: "Paliativo",
  [TYPE_SYMPTOMATIC]: "Sintomático",
  [TYPE_ALTERNATIVE]: "Alternativo",
  [TYPE_PREVENTIVE]: "Preventivo",
};

/**
 * Array de typos de tratamento
 * @example [[0, "foo"], [1, 'teste']]
 */
export const ARR_TT_NAMES = Object.entries(TT_NAMES);
