import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import {
  getRecordVitalSigns,
  changeConfigVitalSigns
} from "actions/Configuration";
import Api from "services/api";
import { BtnSave, BtnCan } from "components/Button";
import { Tooltip, IconButton, Button } from "@material-ui/core";
import CircularProgress from "components/CircularProgress";
import FormContainer from "../SortableContainer";

export default function CustomVitalSigns({ close }) {
  const fields = [
    {
      id: "discomfort",
      description: "O que está te incomodando hoje?",
      checked: true
    },
    {
      id: "imc",
      description: "Peso / Altura / I.M.C / Classe I.M.C",
      checked: true
    },
    { id: "temp_axi", description: "Temperatura Axilar", checked: true },
    { id: "sat", description: "Saturação", checked: true },
    { id: "mumur", description: "Murmúrio vesicular", checked: true },
    { id: "naf", description: "Nível de Atividade Física", checked: true },
    { id: "sleep", description: "Sono e repouso", checked: true },
    { id: "level_disc", description: "Nível de consciência", checked: true },
    { id: "glice", description: "Glicemia capilar", checked: true },
    { id: "ost0", description: "Ortostatismo 0 min.", checked: true },
    { id: "ost1", description: "Ortostatismo 1 min.", checked: true },
    { id: "ost3", description: "Ortostatismo 3 min.", checked: true },
    { id: "d5", description: "Deitado 5 min.", checked: true }
  ];

  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [list, setList] = useState(fields);
  const [loaded, setLoaded] = useState(false);
  const user = useSelector(state => state.auth.authUser);
  const config_vital_signs = useSelector(
    state => state.configuration.config_vital_signs
  );

  useEffect(() => {
    if (!config_vital_signs.loaded) {
      dispatch(getRecordVitalSigns(user));
    }
  }, []);

  useEffect(() => {
    if (config_vital_signs.loaded) {
      setLoaded(true);
      if (config_vital_signs.record.length > 0) {
        let ids = config_vital_signs.record[0].view.split(",");
        let aux = fields.map(row =>
          ids.indexOf(row.id) === -1 ? { ...row, checked: false } : row
        );
        setList(aux);
        setId(config_vital_signs.record[0].id);
      }
    }
  }, [config_vital_signs]);

  const handleCheck = (cat, value) => {
    setList(
      list.map(row => (row.id === cat ? { ...row, checked: value } : row))
    );
  };

  const resetConfig = () => {
    setList(fields);
  };

  const handleSave = () => {
    let marked = list.filter(row => row.checked);
    let ids = marked.map(row => row.id);

    Api.post(`/ms_configuration/custom/saveVitalSigns/`, {
      user,
      id,
      forms: ids.join(",")
    })
      .then(res => {
        if (!id) {
          setId(res.data.id);
        }
        dispatch(changeConfigVitalSigns(res.data.id, ids.join(",")));
        close();
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage(), 5000));
      });
  };

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        <h2 className="text-primary font-weight-semibold w-100">
          Customização Registro Inicial
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
        <div className="col-12">
          <Button
            color="primary"
            className="jr-btn jr-btn-md"
            onClick={resetConfig}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-settings zmdi-hc-lg" />
            <span> Resetar configurações </span>
          </Button>
        </div>
        <div className="col-12">
          <div className="animated slideInUpTiny animation-duration-3">
            <FormContainer
              forms={list}
              handleCheck={handleCheck}
              useDragHandle={false}
            />
          </div>
        </div>
        <div className="col-12 mt-3">
          <BtnCan title="Fechar" onClick={close} float="left" />
          <BtnSave title="Salvar" onClick={handleSave} float="right" />
        </div>
      </div>
    );
  }
}
