import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
import { BtnSave, BtnCan } from "components/Button";

import Api from "services/api";

function EditObservation({ event, value, edit, close }) {
  const [observation, setObservation] = useState(value);

  function save() {
    Api.post(`/ms_system/calendar/editObservation/`, {
      event,
      observation
    }).then(() => {
      edit(observation);
    });
  }

  return (
    <Dialog open={true} onClose={() => close()}>
      <DialogTitle className="text-primary font-weight-semibold">
        Editar Observação
      </DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <TextField
              autoFocus
              multiline={true}
              rows={4}
              margin="dense"
              id="obs"
              label="Observação"
              type="text"
              fullWidth
              onChange={e => setObservation(e.target.value)}
              value={observation}
              style={{ minWidth: "400px", marginTop: "0px" }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="col-12">
          <BtnCan title={"Cancelar"} onClick={() => close()} float="left" />
          <BtnSave title={"Salvar"} onClick={() => save()} float="right" />
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default EditObservation;
