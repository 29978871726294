import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { Badge } from "reactstrap";
import NoRecord from "components/Alert/NoRecord";
import moment from "moment";

const ListMedicines = ({ data, type }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  return (
    <div className="row">
      {data.length > 0 ? (
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Medicamento </TableCell>
                <TableCell>Dosagem</TableCell>
                <TableCell>X. ao dia</TableCell>
                <TableCell>Via A.</TableCell>
                <TableCell>Data Início </TableCell>
                {type === "1" && <TableCell>Data final</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row.name_display}
                      {
                        <Tooltip
                          title={
                            <React.Fragment>
                              {row.active_principle}
                            </React.Fragment>
                          }
                          placement="right"
                        >
                          <InfoIcon
                            style={{
                              fontSize: "14px",
                              marginLeft: "3px",
                              color: "#177493",
                            }}
                          />
                        </Tooltip>
                      }{" "}
                      {moment(row.use_start) > moment() ? (
                        <Badge
                          style={{
                            padding: "1px 3px",
                            fontSize: "10px",
                            marginLeft: "10px",
                          }}
                          color="primary"
                        >
                          Não iniciado
                        </Badge>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>{row.dosage}</TableCell>
                    <TableCell>
                      {row.times_a_day !== null
                        ? row.times_a_day
                        : "se necessário"}
                    </TableCell>
                    <TableCell>{row.mode_all}</TableCell>
                    <TableCell>
                      {moment(row.use_start).format("DD/MM/YYYY")}
                    </TableCell>
                    {type === "1" && (
                      <TableCell>
                        {moment(
                          row.interromped_use
                            ? row.interromped_use
                            : row.use_end
                        ).format("DD/MM/YYYY")}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage="Registros por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `Mostrando ${from} - ${to} de ${count}`
                  }
                  page={page}
                  onChangePage={(e, pg) => setPage(pg)}
                  onChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default ListMedicines;
