import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { getRecordSr, changeConfigSr } from "actions/Configuration";
import Api from "services/api";
import { BtnSave, BtnCan } from "components/Button";
import { arrayMove } from "react-sortable-hoc";
import { Tooltip, IconButton, Button } from "@material-ui/core";
import CircularProgress from "components/CircularProgress";
import FormContainer from "../SortableContainer";

export default function CustomSystemReview({ close }) {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [list, setList] = useState(["teste cardio", "teste respiraório"]);
  const [loaded, setLoaded] = useState(false);
  const user = useSelector(state => state.auth.authUser);

  const categories = useSelector(
    state => state.configuration.config_system_review.categories
  );

  const record = useSelector(
    state => state.configuration.config_system_review.record
  );

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getRecordSr(user));
    }
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      setLoaded(true);
      if (record[0].view === "") {
        setList(categories.map(row => ({ ...row, checked: true })));
      } else {
        let ids = record[0].view.split(",");
        let selecteds = [];
        ids.forEach(row => {
          let obj = categories.find(cat => cat.id === parseInt(row));
          selecteds.push({ ...obj, checked: true });
        });
        let noSelecteds = categories.filter(
          row => ids.indexOf(String(row.id)) === -1
        );
        noSelecteds = noSelecteds.map(row => ({ ...row, checked: false }));
        setList([...selecteds, ...noSelecteds]);
        setId(record[0].id);
      }
    }
  }, [categories, record]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setList(arrayMove(list, oldIndex, newIndex));
  };

  const handleCheck = (cat, value) => {
    setList(
      list.map(row => (row.id === cat ? { ...row, checked: value } : row))
    );
  };

  const resetConfig = () => {
    setList(categories.map(row => ({ ...row, checked: true })));
  };

  const handleSave = () => {
    let marked = list.filter(row => row.checked);
    let ids = marked.map(row => row.id);

    Api.post(`/ms_configuration/custom/saveSystemReview/`, {
      user,
      id,
      categories: ids.join(",")
    })
      .then(res => {
        if (!id) {
          setId(res.data.id);
        }
        dispatch(changeConfigSr(res.data.id, ids.join(",")));
        close();
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage(), 5000));
      });
  };

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        <h2 className="text-primary font-weight-semibold w-100">
          Customização Revisão dos sistemas
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
        <div className="col-12">
          <Button
            color="primary"
            className="jr-btn jr-btn-md"
            onClick={resetConfig}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-settings zmdi-hc-lg" />
            <span> Resetar configurações </span>
          </Button>
        </div>
        <div className="col-12">
          <div className="animated slideInUpTiny animation-duration-3">
            <FormContainer
              forms={list}
              handleCheck={handleCheck}
              onSortEnd={onSortEnd}
              useDragHandle={true}
              sort={true}
            />
          </div>
        </div>
        <div className="col-12 mt-3">
          <BtnCan title="Fechar" onClick={close} float="left" />
          <BtnSave title="Salvar" onClick={handleSave} float="right" />
        </div>
      </div>
    );
  }
}
