import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Finance = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
      <Route
        path={`${match.url}/list`}
        component={asyncComponent(() => import("./routes/list"))}
      />
      <Route
        path={`${match.url}/account`}
        component={asyncComponent(() => import("./routes/account"))}
      />
      <Route
        path={`${match.url}/transaction`}
        component={asyncComponent(() => import("./routes/transaction"))}
      />
      <Route
        path={`${match.url}/resume`}
        component={asyncComponent(() => import("./routes/resume"))}
      />
      <Route
        path={`${match.url}/report/:type`}
        component={asyncComponent(() => import("./routes/report"))}
      />
      <Route
        path={`${match.url}/suppliers`}
        component={asyncComponent(() => import("./routes/suppliers"))}
      />
      <Route
        path={`${match.url}/pattern-transaction`}
        component={asyncComponent(() => import("./routes/patternTransaction"))}
      />
      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default Finance;
