import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableHistoryFold from "./tableHistory";
import Api from "services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";

export default function Folds({ match, history }) {
  const dispatch = useDispatch();
  const id_patient = useSelector(state => state.patientInfo.patient);
  const [state, setState] = React.useState({
    listFolds: [],
    listDetails: [],
    name: "",
    date: null,
    mode_list: true,
    rowsPerPage: 10,
    page: 0
  });
  const [view, setView] = React.useState(true);
  const { rowsPerPage, page } = state;
  useEffect(() => {
    Api.post(`/microservice/folds/getHistoryFolds`, { patient: id_patient.id })
      .then(res => {
        setState({ ...state, listFolds: res.data.historyFolds });
      })
      .catch(() => {
        dispatch({
          type: "VIEW_ALERT_MESSAGE",
          message_type: "error",
          text_message: "Ocorreu um erro ao salvar!"
        });
        setTimeout(() => dispatch({ type: "CLOSE_ALERT_MESSAGE" }), 5000);
      });
  }, []);

  const handleFoldsPatientDetailsn = dados => {
    Api.post(`/microservice/folds/getFoldsPatientDetails`, { dados: dados.id })
      .then(res => {
        setState({
          ...state,
          listDetails: res.data,
          name: dados.row.name,
          date: dados.row.date
        });
      })
      .catch(() => {
        dispatch({
          type: "VIEW_ALERT_MESSAGE",
          message_type: "error",
          text_message: "Ocorreu um erro ao salvar!"
        });
        setTimeout(() => dispatch({ type: "CLOSE_ALERT_MESSAGE" }), 5000);
      });
    setView(false);
  };
  const handleClose = () => {
    setView(true);
  };

  const handleChangePage = (event, page) => {
    this.setState({ page });
  };

  const handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  return (
    <div className="col-12 container-person border">
      <h3
        className="title-container d-flex justify-content-center"
        style={{
          fontSize: "26px",
          fontWeight: "400",
          marginLeft: "14px"
        }}
      >
        {" "}
        Dobras
      </h3>
      {view === true && (
        <>
          <div
            className="col-12"
            style={{
              minWidth: "650",
              overflowX: "auto"
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Visualizar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.listFolds && (
                  <>
                    {state.listFolds
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(row => (
                        <TableRow key={row.id}>
                          <TableCell className="p-1">{row.name}</TableCell>
                          <TableCell className="p-1">
                            {moment(row.date).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell className="p-1">
                            <IconButton
                              className="m-0 p-0"
                              onClick={() =>
                                handleFoldsPatientDetailsn({
                                  id: row.id,
                                  row: row
                                })
                              }
                              color="primary"
                              aria-label="Visualizar"
                            >
                              <i className="zmdi zmdi-eye zmdi-hc-fw" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={state.listFolds.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Registros por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `Mostrando ${from} - ${to} de ${count}`
                    }
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </>
      )}
      {view === false && (
        <div className="col-12">
          <TableHistoryFold
            name={state.name}
            date={moment(state.date).format("DD/MM/YYYY")}
            onClose={handleClose}
            listDetails={state.listDetails}
          />
        </div>
      )}
    </div>
  );
}
