import React from "react";
import MaskedInput from "react-text-mask";

const MaskHour = props => {
  let { inputRef, ...others } = props;
  return (
    <MaskedInput {...others} mask={[/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]} />
  );
};

export default MaskHour;
