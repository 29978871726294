import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField
} from "@material-ui/core";

function ModalObservation({ open, handleClose, value, changeObservation }) {
  const [observation, setObservation] = React.useState("");

  React.useEffect(() => {
    setObservation(value);
  }, [value]);

  const closeModal = () => {
    setObservation("");
    handleClose();
  };

  return (
    <div className="icon-daialog">
      <Dialog
        open={open}
        onClose={() => closeModal()}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle className="text-primary font-weight-semibold">
          Adicionar Observação
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-md-12">
              <TextField
                multiline
                rows={4}
                autoFocus
                margin="normal"
                id="obs"
                label="Observação:"
                type="text"
                fullWidth
                onChange={e => setObservation(e.target.value)}
                value={observation}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="col-12">
            <Button
              onClick={() => closeModal()}
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span>Cancelar</span>
            </Button>
            <Button
              onClick={() => {
                changeObservation(observation);
                closeModal();
              }}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-plus" />
              <span>Adicionar</span>
            </Button>
          </div>
          {/* <Button color="secondary"variant="contained"className="jr-btn jr-btn-sm"style={{ float:"left" }}onClick={() =>close()}>  <iclassName="zmdi zmdi-close zmdi-hc-lg" />  <span> Cancelar </span></Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalObservation;
