import React from "react";
import { Icon } from "@material-ui/core";
import BathtubIcon from "@material-ui/icons/Bathtub";
import HealingIcon from "@material-ui/icons/Healing";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import HotelIcon from "@material-ui/icons/Hotel";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AirlineSeatFlatAngledIcon from "@material-ui/icons/AirlineSeatFlatAngled";
import DetailsIcon from "@material-ui/icons/Details";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";

export const setDataEvolution = (payload) => ({
  type: "SET_DATA_EVOLUTION",
  payload,
});

export const setReadOnly = (payload) => ({ type: "SET_READ_ONLY", payload });

export const CARE_TYPES_INFO = {
  0: {
    name: "Intercorrência",
    icon: [<DetailsIcon style={{ color: "#575757" }} />],
  },
  1: {
    name: "Higiene Pessoal",
    icon: [<BathtubIcon style={{ color: "#575757" }} />],
  },
  2: {
    name: "Cabeceira Elevada",
    icon: [<AirlineSeatFlatAngledIcon style={{ color: "#575757" }} />],
  },
  3: {
    name: "Mudança de Decúbito",
    icon: [<HotelIcon style={{ color: "#575757" }} />],
  },
  4: {
    name: "Curativo",
    icon: [<HealingIcon style={{ color: "#575757" }} />],
  },
  5: {
    name: "Dieta Oral",
    icon: [<RestaurantIcon style={{ color: "#575757" }} />],
  },
  6: {
    name: "Dieta Enteral",
    icon: [<FormatColorFillIcon style={{ color: "#575757" }} />],
  },
  7: {
    name: "Hidratação",
    icon: [<LocalDrinkIcon style={{ color: "#575757" }} />],
  },
  8: {
    name: "Dados Vitais",
    icon: [<FavoriteIcon style={{ color: "#575757" }} />],
  },
  9: {
    name: "Retirada de Pontos",
    icon: [
      <Icon className="zmdi zmdi-scissors" style={{ color: "#575757" }} />,
    ],
  },
  10: {
    name: "Sonda Vesical",
    icon: [<DetailsIcon style={{ color: "#575757" }} />],
  },
  11: {
    name: "Oxigenioterapia",
    icon: [<DetailsIcon style={{ color: "#575757" }} />],
  },
  12: {
    name: "Estimular Movimentação",
    icon: [<DirectionsWalkIcon style={{ color: "#575757" }} />],
  },
  13: {
    name: "Perneira Inflável",
    icon: [<DetailsIcon style={{ color: "#575757" }} />],
  },
  14: {
    name: "Registrar escala de dor",
    icon: [<SentimentDissatisfiedIcon style={{ color: "#575757" }} />],
  },
  15: {
    name: "Avaliar Risco de queda",
    icon: [<DirectionsWalkIcon style={{ color: "#575757" }} />],
  },
  16: {
    name: "Medicamentos",
    icon: [<DetailsIcon style={{ color: "#575757" }} />],
  },
};
