import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";

function DownloadFiles({ open, handleClose, files }) {
  return (
    <div className="icon-daialog">
      <Dialog
        open={open}
        onClose={() => handleClose()}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle className="text-primary font-weight-semibold">
          Download Arquivos
        </DialogTitle>
        <DialogContent>
          <div className="row">
            {files.map((row, index) => (
              <div className="col-12 mt-1">
                <a href={row.url_file} download>
                  <i className="zmdi zmdi-download mr-3 mt-1" />
                  Download Arquivo {index + 1}
                </a>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="col-12">
            <Button
              onClick={() => handleClose()}
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span>Fechar</span>
            </Button>
          </div>
          {/* <Button color="secondary"variant="contained"className="jr-btn jr-btn-sm"style={{ float:"left" }}onClick={() =>close()}>  <iclassName="zmdi zmdi-close zmdi-hc-lg" />  <span> Cancelar </span></Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DownloadFiles;
