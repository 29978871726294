import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import NoRecord from "components/Alert/NoRecord";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MultipleSelect } from "react-select-material-ui";
import {
  Switch,
  Button,
  TextField,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
} from "@material-ui/core";
import MaskCpfCnpj from "components/Masks/MaskCpfCnpj";
import TypesEventForm from "components/Configuration/components/eventType/TypesEventForm";
import SelectWithChip from "components/TextFields/SelectWithChip";
import { CirclePicker } from "react-color";

function SpecialtyCalendar({
  data,
  addressId,
  addressType,
  user,
  typesEventData,
  nameAddress,
}) {
  const [showForm, setShowForm] = useState(false);
  const [modalNewEvent, setModalNewEvent] = useState(false);
  const [list, setList] = useState(data);
  const [listTypes, setListTypes] = useState(typesEventData);
  const [typesEvent, setTypesEvent] = useState([]);
  const [state, setState] = useState({
    online_scheduling: false,
    typesSelected: [],
    typesSelectedText: [],
    number_patients: 1,
    colorEvent: "#177493",
    displayColorEvent: false,
    displayTextEvent: false,
    textColor: "#ffffff",
    calendar: "",
    id: "",
    type_online_scheduler: "0",
    time_to_confirme: "01:00",
    online_first_attendance: "",
    online_attendance: "",
    online_return: "",
    telemedicine: false,
    payments: [],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    getTypesEvent();
  }, []);

  useEffect(() => {
    setState({
      ...state,
      telemedicine: addressType === "0",
    });
  }, [addressType]);

  const getTypesEvent = () => {
    Api.post(`/ms_system/workplace/getTypesEventsUser/`, { user }).then(
      (res) => {
        if (res.data.record.length > 0) {
          setTypesEvent(res.data.record);
        }
      }
    );
  };

  const ComponentForm = () => {
    return (
      <>
        <div className="col-12">
          <h4 className="font-weight-semibold">Eventos da Agenda</h4>
          <MultipleSelect
            label="Serviços oferecidos:"
            values={state.typesSelected}
            options={[
              ...typesEvent,
              { value: "new", label: "+ Novo Tipo de Evento" },
            ]}
            onChange={(e, c, d) => {
              if (e.indexOf("new") === -1) {
                setState({ ...state, typesSelected: e });
              } else {
                setModalNewEvent(true);
              }
            }}
            SelectProps={{
              isCreatable: false,
              msgNoOptionsAvailable: "Todos serviços foram selecionados",
              msgNoOptionsMatchFilter: "Nenhum tipo de evento para este filtro",
            }}
            style={{
              borderBottom:
                state.error && state.typesSelected.length === 0
                  ? "2px solid red"
                  : "none",
            }}
          />
        </div>

        <div className="col-12 mt-3">
          <span
            style={{
              color: "#0000008a",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "1.3125rem",
              marginBotton: "5px",
            }}
          >
            Selecione a cor dos eventos:
          </span>
          <CirclePicker
            width="100%"
            color={state.colorEvent}
            onChange={(val) => {
              setState({ ...state, colorEvent: val.hex });
            }}
            colors={[
              "#177493",
              "#f44336",
              "#e91e63",
              "#9c27b0",
              "#673ab7",
              "#3f51b5",
              "#2196f3",
              "#03a9f4",
              "#00bcd4",
              "#009688",
              "#4caf50",
              "#8bc34a",
              "#cddc39",
              "#ffeb3b",
              "#ffc107",
              "#ff9800",
              "#ff5722",
              "#795548",
              "#607d8b",
            ]}
          />
        </div>

        <div className="col-12 mt-3">
          <h4 className="font-weight-semibold">Agendamento online</h4>
          <Grid component="label" container alignItems="center">
            <Grid item>
              <span
                style={{
                  color: "#0000008a",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "1.3125rem",
                }}
              >
                Permitir Agendamento online:
              </span>
            </Grid>
            <Grid item>
              <Switch
                color="primary"
                checked={state.online_scheduling}
                onChange={(e) => {
                  setState({ ...state, online_scheduling: e.target.checked });
                }}
                aria-label="checked"
              />
            </Grid>
          </Grid>
        </div>

        {state.online_scheduling && (
          <>
            <div className="col-md-4 col-12 mt-3">
              <TextField
                select
                fullWidth
                label="Primeira consulta:"
                value={state.online_first_attendance}
                onChange={(e) =>
                  setState({
                    ...state,
                    online_first_attendance: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
              >
                {typesEvent.map((row, key) => (
                  <MenuItem key={key} value={row.value}>
                    {row.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-4 col-12 mt-3">
              <TextField
                select
                fullWidth
                label="Consulta:"
                value={state.online_attendance}
                onChange={(e) =>
                  setState({
                    ...state,
                    online_attendance: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
              >
                {typesEvent.map((row, key) => (
                  <MenuItem key={key} value={row.value}>
                    {row.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-4 col-12 mt-3">
              <TextField
                select
                fullWidth
                label="Retorno:"
                value={state.online_return}
                onChange={(e) =>
                  setState({
                    ...state,
                    online_return: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
              >
                {typesEvent.map((row, key) => (
                  <MenuItem key={key} value={row.value}>
                    {row.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-6 col-12 mt-3">
              <TextField
                id="number_patients"
                type="number"
                label="Número de pacientes por horário:"
                value={state.number_patients}
                onChange={(e) =>
                  setState({ ...state, number_patients: e.target.value })
                }
                fullWidth
              />
            </div>
          </>
        )}

        <div className="col-12 mt-3">
          <h4 className="font-weight-semibold">Pagamento</h4>
          <SelectWithChip
            label="Formas de pagamento:"
            selecteds={state.payments}
            handleChange={(e) => {
              setState({
                ...state,
                payments: [...state.payments, e.target.value],
              });
            }}
            handleDelete={(e) => {
              setState({
                ...state,
                payments: state.payments.filter((row) => row !== e),
              });
            }}
            options={[
              "Boleto",
              "Cartão de crédito",
              "Transferência bancária",
              "PIX",
            ]}
          />
        </div>

        {state.payments.indexOf("Transferência bancária") !== -1 && (
          <>
            <div className="col-12 my-3">
              <span
                style={{
                  color: "#0000008a",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "1.3125rem",
                }}
              >
                Dados para Transferência Bancária:
              </span>
            </div>
            <div className="col-md-6 col-12 mb-2">
              <TextField
                id="cpf"
                label="CPF / CNPJ"
                fullWidth
                InputProps={{
                  inputComponent: MaskCpfCnpj,
                  value: state.cpf ? state.cpf : "",
                  onChange: (event) => {
                    setState({ ...state, cpf: event.target.value });
                  },
                }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-md-6 col-12 mb-2">
              <TextField
                fullWidth
                label="Beneficiário:"
                value={state.beneficiary}
                onChange={(e) =>
                  setState({
                    ...state,
                    beneficiary: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-md-4 col-12">
              <TextField
                fullWidth
                label="Banco:"
                value={state.bank}
                onChange={(e) =>
                  setState({
                    ...state,
                    bank: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-md-4 col-12">
              <TextField
                fullWidth
                label="Agência:"
                value={state.agency}
                onChange={(e) =>
                  setState({
                    ...state,
                    agency: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-md-4 col-12">
              <TextField
                fullWidth
                label="Conta:"
                value={state.account}
                onChange={(e) =>
                  setState({
                    ...state,
                    account: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </>
        )}
        {state.payments.indexOf("PIX") !== -1 && (
          <>
            <div className="col-12 my-3">
              <span
                style={{
                  color: "#0000008a",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "1.3125rem",
                }}
              >
                Dados para Pix:
              </span>
            </div>
            <div className="col-md-6 col-12 mb-2">
              <TextField
                select
                label="Tipo da chave Pix:"
                fullWidth
                onChange={(event) => {
                  setState({ ...state, pix: event.target.value });
                }}
                value={state.pix}
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem key="0" value="CPF ou CNPJ">
                  CPF ou CNPJ
                </MenuItem>
                <MenuItem key="1" value="E-mail">
                  E-mail
                </MenuItem>
                <MenuItem key="2" value="Número de telefone celular">
                  Número de telefone celular
                </MenuItem>
                <MenuItem key="3" value="Chave aleatória">
                  Chave aleatória
                </MenuItem>
              </TextField>
            </div>
            <div className="col-md-6 col-12 mb-2">
              {state.pix === "CPF ou CNPJ" ? (
                <TextField
                  label="Chave Pix:"
                  fullWidth
                  onChange={(event) => {
                    setState({ ...state, pix_key: event.target.value });
                  }}
                  InputProps={{
                    value: state.pix_key ? state.pix_key : "",
                    inputComponent: MaskCpfCnpj,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              ) : (
                <TextField
                  fullWidth
                  label="Chave Pix:"
                  value={state.pix_key}
                  onChange={(e) =>
                    setState({
                      ...state,
                      pix_key: e.target.value,
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </div>
          </>
        )}
      </>
    );
  };

  function save() {
    if (state.typesSelected.length > 0) {
      setState({ ...state, error: false });
      const obj = {
        ...state,
        name: nameAddress,
        addressId,
        user_id: user,
      };
      Api.post(`/ms_system/workplace/saveCalendarProfessional/`, obj)
        .then((res) => {
          const {
            idsTypes,
            calendar,
            colorEvent,
            number_patient,
            textColor,
            online_scheduling,
            type_online_scheduler,
            time_to_confirme,
            online_first_attendance,
            online_attendance,
            online_return,
          } = res.data;
          const newListTypes = [];
          idsTypes.forEach((element) => {
            const newtype = typesEvent.find((el) => el.value === element);
            if (newtype !== undefined) {
              newListTypes.push({
                id: newtype.value,
                description: newtype.label,
                active: "1",
              });
            }
          });
          setListTypes(newListTypes);
          setState({ ...state, typesSelected: idsTypes });
          const newList = {
            id: calendar,
            color: colorEvent,
            number_patient,
            text_color: textColor,
            online_scheduling,
            type_online_scheduler,
            time_to_confirme,
            online_first_attendance,
            online_attendance,
            online_return,
          };
          setList([newList]);
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
          setShowForm(false);
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage(), 5000));
        });
    } else {
      setState({ ...state, error: true });
    }
  }

  return (
    <>
      <ExpansionPanel
        square
        style={{
          boxShadow: "10px 5px 5px white",
          borderBottom: "1px solid #d2d2d2",
          // marginTop: "10px"
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
        >
          Especificações da Agenda
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: "block" }}>
          {list.length > 0 ? (
            <Table className="table-finances w-100">
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Serviços</TableCell>
                  <TableCell align="left" width="25%">
                    Agend. online
                  </TableCell>
                  <TableCell width="25%">Núm. de pacientes</TableCell>
                  <TableCell align="right" width="30%">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row) => (
                  <TableRow hover key={row.id}>
                    <TableCell align="left" width="30%">
                      {listTypes.length > 0 ? (
                        <ul>
                          {listTypes.map((el) => (
                            <li key={el.id}>{el.description}</li>
                          ))}
                        </ul>
                      ) : (
                        <div>nenhum registro</div>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" width="25%">
                      {row.online_scheduling === "1" ||
                      row.online_scheduling === true
                        ? "Sim"
                        : "Não"}
                    </TableCell>
                    <TableCell align="left" width="25%">
                      {row.number_patient} Paciente(s)
                    </TableCell>

                    <TableCell width="20%" align="right">
                      <IconButton
                        onClick={() => {
                          const types = listTypes.map((el) => el.id);
                          setState({
                            online_scheduling:
                              row.online_scheduling === "1" ||
                              row.online_scheduling
                                ? true
                                : false,
                            typesSelected: types,
                            number_patients: row.number_patient,
                            colorEvent: row.color,
                            displayColorEvent: false,
                            displayTextEvent: false,
                            textColor: row.text_color,
                            calendar: row.id,
                            type_online_scheduler: row.type_online_scheduler,
                            time_to_confirme: row.time_to_confirme,
                            online_first_attendance:
                              row.online_first_attendance,
                            online_attendance: row.online_attendance,
                            online_return: row.online_return,
                            telemedicine: row.telemedicine === "1",
                            payments: row.forms_payment
                              ? row.forms_payment.split(";")
                              : [],
                            cpf: row.ted_cpf_cnpj,
                            beneficiary: row.ted_beneficiary,
                            bank: row.ted_bank,
                            agency: row.ted_agency,
                            account: row.ted_account,
                            pix: row.pix_type,
                            pix_key: row.pix_key,
                          });
                          setShowForm(true);
                        }}
                        color="primary"
                        aria-label="Editar"
                      >
                        <i className="zmdi zmdi-edit zmdi-hc-fw" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <>
              <div className="w-100">
                <Button
                  onClick={() => {
                    setShowForm(true);
                    setState({
                      ...state,
                      calendar: "",
                      online_scheduling: addressType === "0",
                      typesSelected: [],
                      number_patients: 1,
                      colorEvent: "#177493",
                      displayColorEvent: false,
                      displayTextEvent: false,
                      textColor: "#ffffff",
                    });
                  }}
                  className="jr-btn jr-btn-sm"
                  color="primary"
                  variant="contained"
                  style={{ float: "right" }}
                >
                  <i className="zmdi zmdi-plus" /> <span> Adicionar</span>
                </Button>
              </div>
              <Table className="table-finances w-100">
                <TableHead>
                  <TableRow>
                    <TableCell width="20%">Serviços</TableCell>
                    <TableCell align="left" width="25%">
                      Agend. online
                    </TableCell>
                    <TableCell width="25%">Núm. de pacientes</TableCell>
                    <TableCell align="right" width="30%">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="1">
                    <TableCell colSpan={3}>
                      <NoRecord />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={showForm}
        onClose={() => setShowForm(false)}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
        style={{ marginTop: -150 }}
      >
        <DialogContent>
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="text-primary font-weight-semibold">
                Especificações da Agenda
                <Tooltip title="Fechar" placement="top">
                  <IconButton
                    aria-label="Fechar"
                    onClick={() => setShowForm(false)}
                    style={{ float: "right", marginTop: "-10px" }}
                  >
                    <i className="zmdi zmdi-close" />
                  </IconButton>
                </Tooltip>
              </h2>
            </div>
            {ComponentForm()}

            <div className="col-md-12 mt-3">
              <Button
                color="secondary"
                variant="contained"
                className="jr-btn jr-btn-sm"
                onClick={() => setShowForm(false)}
                style={{ float: "left" }}
              >
                <i className="zmdi zmdi-close zmdi-hc-lg" />
                <span> Cancelar </span>
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="jr-btn jr-btn-sm bg-success"
                onClick={() => save()}
                style={{ float: "right" }}
              >
                <i className="zmdi zmdi-check zmdi-hc-lg" />
                <span> Salvar </span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <TypesEventForm
        open={modalNewEvent}
        type="modal"
        close={() => setModalNewEvent(false)}
        beforeSave={(newValue) => {
          getTypesEvent();
          setState({
            ...state,
            typesSelected: [...state.typesSelected, newValue],
          });
        }}
      />
    </>
  );
}

export default SpecialtyCalendar;
