import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MaskTemperature from "components/Masks/MaskTemperature";

export default function Symptoms({ list, handleChange, error }) {
  const currencies = [
    {
      label: "Moderado",
      value: 1,
    },
    {
      label: "Médio",
      value: 1,
    },
    {
      label: "Acentuado",
      value: 1,
    },
  ];

  return (
    <>
      {list.map((row, index) => (
        <>
          {row.title && (
            <div className="col-12 mt-3">
              <h3 className="font-weight-bold">
                <u>{row.title}</u>
              </h3>
            </div>
          )}
          {/* <div className={row.status ? "col-md-3 col-12" : "col-12"}> */}
          <div className="col-12">
            {row.description}
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  onClick={(e) => handleChange(index, !row.status, "status")}
                  checked={row.status}
                />
              }
              className="ml-3"
            />
          </div>
          {row.status && (
            // <div className="col-md-9 col-12">
            <>
              {row.id !== "5243" ? (
                <>
                  <div className="col-md-4 col-12">
                    <TextField
                      select
                      value={row.gravity}
                      label="Nível de Gravidade:"
                      onChange={(e) =>
                        handleChange(index, e.target.value, "gravity")
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      error={error && !row.gravity}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="col-md-4 col-12">
                    <TextField
                      label="Data de Início:"
                      type="date"
                      value={row.dateStart}
                      onChange={(e) =>
                        handleChange(index, e.target.value, "dateStart")
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={error && !row.dateStart}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <TextField
                      label="Data de Fim:"
                      type="date"
                      value={row.dateEnd}
                      onChange={(e) =>
                        handleChange(index, e.target.value, "dateEnd")
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={error && !row.dateEnd}
                    />
                  </div>
                </>
              ) : (
                <div className="col-12">
                  <TextField
                    label="Temperatura:"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">°C</InputAdornment>
                      ),
                      inputComponent: MaskTemperature,
                      value: row.temperature,
                      onChange: (e) =>
                        handleChange(index, e.target.value, "temperature"),
                    }}
                  />
                </div>
              )}
            </>
          )}
        </>
      ))}
    </>
  );
}
