import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import IncludeTags from "./IncludeTags";
import FormTags from "components/Configuration/components/tags/formTags";
import { Btn } from "components/Button";

class AddTags extends Component {
  state = {
    active: "include"
  };
  handleChangeButton = () => {
    if (this.state.active === "include") {
      this.setState({ active: "add" });
    } else {
      this.setState({ active: "include" });
    }
  };
  handleClose = () => {
    this.setState({ active: "include" });
    this.props.handleRequestClose();
  };
  render() {
    const { open, patient } = this.props;
    const { active } = this.state;
    return (
      <Dialog open={open} onClose={this.handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Incluir Tag
          {active === "include" ? (
            <Btn
              onClick={this.handleChangeButton}
              title="Nova Tag"
              icon="zmdi zmdi-plus zmdi-hc-lg"
              name="Nova tag"
              float="right"
            />
          ) : null}
        </DialogTitle>
        {active === "include" ? (
          <IncludeTags
            patient={patient}
            handleChangeButton={this.handleChangeButton}
            handleRequestClose={this.handleClose}
          />
        ) : (
          <DialogContent>
            <FormTags
              patient={patient}
              handleChangeButton={this.handleChangeButton}
            />
          </DialogContent>
        )}
      </Dialog>
    );
  }
}

export default AddTags;
