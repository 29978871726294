import React, { Component } from "react";
import { connect } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DescriptionIcon from "@material-ui/icons/Description";
import Tooltip from "@material-ui/core/Tooltip";

class FileTranscription extends Component {
  render() {
    const { nameFile, descriptionFile, file, patient } = this.props;

    let type = nameFile.split(".")[1];

    const getUrlPhoto = (patient, file) => {
      return `https://salutem-user-files.s3.amazonaws.com/${patient}/${file}`;
    };

    return (
      <ListItem
        key={1}
        button
        className="d-flex border-bottom"
        style={{ margin: "0px", padding: "2px" }}
        onClick={() => window.open(getUrlPhoto(patient, file), "_blank")}
      >
        <Tooltip
          placement="top"
          title={
            type === "jpg" || type === "png" ? (
              <img
                style={{
                  height: "188px",
                  width: "188px"
                }}
                alt=""
                src={getUrlPhoto(patient, file)}
              />
            ) : (
              ""
            )
          }
        >
          <ListItemAvatar>
            <Avatar>
              {type === "jpg" || type === "png" ? (
                <img
                  style={{
                    height: "-webkit-fill-available",
                    width: "-webkit-fill-available"
                  }}
                  alt=""
                  src={getUrlPhoto(patient, file)}
                />
              ) : (
                <DescriptionIcon />
              )}
            </Avatar>
          </ListItemAvatar>
        </Tooltip>
        <ListItemText
          style={{ textAlign: "left" }}
          primary={nameFile}
          secondary={descriptionFile}
        />
      </ListItem>
    );
  }
}

const mapStateToProps = ({ appointment }) => {
  return {
    patient: appointment.patient
  };
};

export default connect(mapStateToProps)(FileTranscription);
