import React from "react";
import { BtnCan, BtnSave } from "components/Button";
import {
  MenuItem,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "white",
    flex: 1,
  },
}));

export default function ModalExam({ open, handleClose, start, end, onFilter }) {
  const [state, setState] = React.useState({
    option: "365",
    start,
    end,
  });
  const classes = useStyles();

  const handleFilter = () => {
    let init = state.start;
    let last = state.end;

    switch (state.option) {
      case "30":
        init = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        last = moment().format("YYYY-MM-DD");
        break;
      case "60":
        init = moment()
          .subtract(60, "days")
          .format("YYYY-MM-DD");
        last = moment().format("YYYY-MM-DD");
        break;
      case "90":
        init = moment()
          .subtract(90, "days")
          .format("YYYY-MM-DD");
        last = moment().format("YYYY-MM-DD");
        break;
      case "180":
        init = moment()
          .subtract(180, "days")
          .format("YYYY-MM-DD");
        last = moment().format("YYYY-MM-DD");
        break;

      case "365":
        init = moment()
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        last = moment().format("YYYY-MM-DD");
        break;
    }
    onFilter(init, last);
    handleClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
      PaperProps={{
        style: {
          overflowX: "hidden",
        },
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Período
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="row pl-3 pr-3 mt-3">
        <div className="col-12">
          <TextField
            select
            value={state.option}
            onChange={(e) => setState({ ...state, option: e.target.value })}
            label="Período:"
            fullWidth
          >
            <MenuItem key="0" value="30">
              Últimos 30 dias
            </MenuItem>
            <MenuItem key="1" value="60">
              Últimos 60 dias
            </MenuItem>
            <MenuItem key="2" value="90">
              Últimos 90 dias
            </MenuItem>
            <MenuItem key="3" value="180">
              Últimos 180 dias
            </MenuItem>
            <MenuItem key="4" value="365">
              Últimos 365 dias
            </MenuItem>
            <MenuItem key="5" value="especific">
              Período específico
            </MenuItem>
          </TextField>
        </div>
        {state.option === "especific" && (
          <>
            <div className="col-6 mt-3">
              <TextField
                id="date"
                type="date"
                label="Data Inicial"
                value={state.start}
                onChange={(e) =>
                  setState({
                    ...state,
                    start: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                id="date"
                type="date"
                label="Data Final"
                value={state.end}
                onChange={(e) =>
                  setState({
                    ...state,
                    end: e.target.value,
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </>
        )}
        <div className="col-12 mt-3">
          <BtnCan title="Fechar" onClick={handleClose} float="left" />
          <BtnSave title="Filtrar" onClick={handleFilter} float="right" />
        </div>
      </div>
    </Dialog>
  );
}
