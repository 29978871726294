import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import FormBasicData from "./FormBasicData";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import * as AddProcedureActions from "actions/configuration/AddProcedure";
import MaskMoney from "components/Masks/MaskMoney";
import { getFormatValueMoney } from "services/functions";

class FormAddProcedure extends Component {
  componentWillMount() {
    if (this.props.addProcedure.idEdit) {
      this.props.getDataProcedureForEdit(this.props.addProcedure.idEdit);
    }
  }

  handleNameProcedure = (event) => {
    this.props.setNameProcedure(event.target.value);
  };

  setValueMoney = (e) => {
    this.props.setValProcedure(e);
  };

  saveNewProcedure = () => {
    this.props.saveProcedure(
      this.props.doctor,
      this.props.addProcedure.nameProcedure,
      getFormatValueMoney(this.props.addProcedure.valProcedure, ","),
      this.props.addProcedure.idEdit
    );
  };

  handleRequestClose = () => {
    this.props.handleAddFunction(
      "view",
      "primary",
      "Adicionar",
      "hidden",
      "plus"
    );
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
          <h2 className="text-primary font-weight-semibold">
            Adicionar procedimento
          </h2>
        </div>
        <div className="col-md-6">{""}</div>
        <div className="col-md-8 mt-4">
          <TextField
            id="name"
            label="Nome do Procedimento"
            autoFocus={true}
            value={this.props.addProcedure.nameProcedure}
            onChange={this.handleNameProcedure}
            fullWidth
          />
        </div>
        <div className="col-md-4">
          <MaskMoney
            label="Valor"
            value={this.props.addProcedure.valProcedure}
            onChange={(e) => this.setValueMoney(e.target.value)}
          />
        </div>

        <div className="col-md-12" style={{ marginTop: "20px" }}>
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={this.handleRequestClose}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>
          <Button
            color="primary"
            className="jr-btn jr-btn-sm bg-success"
            onClick={this.saveNewProcedure}
            variant="contained"
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doctor: state.auth.authUser,
    addProcedure: state.AddProcedure,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AddProcedureActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormAddProcedure);
