const INITIAL_STATE = {
  records: [],
  loaded: false
};

export default function workplaces(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_WORKPLACE":
      return INITIAL_STATE;
    case "GET_WORKPLACES_SUCCESS":
      return {
        records: action.payload.record,
        loaded: true
      };

    case "EDIT_WORKPLACE":
      let recs = state.records.map(row =>
        row.id === action.payload.id ? { ...row, ...action.payload } : row
      );

      return {
        ...state,
        records: recs
      };

    case "NEW_WORKPLACE":
      return {
        ...state,
        records: [action.payload, ...state.records]
      };

    case "REMOVE_WORKPLACE":
      let aux = state.records.filter(row => row.id !== action.payload.id);
      return {
        ...state,
        records: aux
      };

    case "UPDATE_LIST_WORKPLACE_FORMPRINT":
      let newListRecords = [];
      let newRecords = state.records.filter(row => row.id === action.id);
      newRecords.forEach(row => {
        row.formPrint = [action.list];
      });
      if (newRecords.length > 0) {
        newListRecords = state.records.map(row =>
          row.id === action.id ? newRecords[0] : row
        );
      } else {
        newListRecords = state.records;
      }

      return {
        ...state,
        records: newListRecords
      };

    default:
      return state;
  }
}
