import React, { useEffect } from "react";

import {
  TextField,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
} from "@material-ui/core/";
import moment from "moment";

export default function PersonalHygiene({ state, setState, error }) {
  useEffect(() => {
    if (state.quantity !== state.hours.length) {
      let arr = [];
      for (let index = 0; index < state.quantity; index++) {
        arr.push("");
      }
      setState({ ...state, hours: arr });
    }
  }, [state.quantity]);

  useEffect(() => {
    if (state.duration) {
      let diff = state.duration - 1;
      if (diff === 0) {
        setState({ ...state, end: state.start });
      } else {
        setState({
          ...state,
          end: moment(state.start)
            .add(diff, "days")
            .format("YYYY-MM-DD"),
        });
      }
    }
  }, [state.start, state.duration]);

  useEffect(() => {
    let start = state.start;
    let end = state.end;
    let diff = moment(end).diff(moment(start), "days") + 1;
    setState({ ...state, duration: diff });
  }, [state.end]);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const handleChangeHour = (index, value) => {
    let aux = state.hours.map((row, key) => (key === index ? value : row));
    setState({ ...state, hours: aux });
  };
  return (
    <>
      <div className="row">
        <div className="col-6" style={{ paddingTop: "2.2rem" }}>
          <TextField
            type="date"
            label="Data:"
            value={state.start}
            onChange={(e) => handleChange("start", e.target.value)}
            fullWidth
          />
        </div>
        {state.hours.map((row, index) => (
          <div key={index} className="col-6" style={{ paddingTop: "2.2rem" }}>
            <TextField
              type="time"
              label="Horário:"
              value={row}
              onChange={(e) => handleChangeHour(index, e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={error && !row}
            />
          </div>
        ))}
        <div className="d-none" style={{ marginTop: "-5px" }}>
          <FormControl className="my-3" fullWidth>
            <FormHelperText>Duração:</FormHelperText>
            <Input
              id="weight"
              value={state.duration}
              onChange={(e) => handleChange("duration", e.target.value)}
              endAdornment={
                <InputAdornment position="end">dias</InputAdornment>
              }
              inputProps={{ maxLength: 3 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
            />
          </FormControl>
        </div>
        <div className="d-none">
          <TextField
            type="date"
            label="Fim:"
            value={state.end}
            onChange={(e) => handleChange("end", e.target.value)}
            fullWidth
          />
        </div>
        <div className="col-md-12">
          <TextField
            multiline
            rows={4}
            margin="normal"
            id="obs"
            label="Orientações:"
            type="text"
            fullWidth
            onChange={(e) => handleChange("observation", e.target.value)}
            value={state.observation}
          />
        </div>
      </div>
    </>
  );
}
