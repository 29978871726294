import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip, IconButton } from "@material-ui/core";
import Api from "services/api";
import { showMsg } from "services/functions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import { BtnSave, BtnCan } from "components/Button";
import SweetAlert from "react-bootstrap-sweetalert";

export default function DiagnosticGroups({ close }) {
  const [error, setError] = useState(false);
  const [access, setAccess] = useState({
    open: false,
    code: "",
  });
  const [state, setState] = useState({
    option: "",
    old_password: "",
    old_access: "",
    new_password: "",
  });
  const user = useSelector((state) => state.auth.authUser);
  const dispatch = useDispatch();

  const validate = () => {
    if (state.option) {
      if (!state.old_access && !state.old_password) {
        setError(true);
        return false;
      } else {
        if (
          state.new_password !== state.confirm_password &&
          state.option === "password"
        ) {
          setError(true);
          return false;
        } else {
          return true;
        }
      }
    } else {
      setError(true);
      return false;
    }
  };

  const handleSave = () => {
    if (validate()) {
      Api.post(`/ms_system/user/alterAccess/`, {
        user,
        ...state,
      })
        .then((res) => {
          if (res.data.success) {
            showMsg("success", "Alterado com sucesso!", dispatch);
            if (state.option === "access") {
              setAccess({
                open: true,
                code: res.data.code,
              });
            } else {
              close();
            }
          } else {
            setError(true);
            setState({
              ...state,
              old_access: "",
              old_password: "",
            });
            showMsg("error", res.data.msg, dispatch);
          }
        })
        .catch(() => showMsg("error", "Ocorreu um erro!", dispatch));
    }
  };

  return (
    <div className="row">
      <h2 className="text-primary font-weight-semibold w-100">
        Alterar senha/chave de acesso
        <Tooltip title="Fechar" placement="top">
          <IconButton
            aria-label="Fechar"
            onClick={close}
            style={{ float: "right", marginTop: "-10px" }}
          >
            <i className="zmdi zmdi-close" />
          </IconButton>
        </Tooltip>
      </h2>
      <div className="col-md-12">
        <FormControl
          className="d-flex"
          component="fieldset"
          error={error && !state.option}
        >
          <FormLabel component="legend">Alterar: </FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={state.option}
            onChange={(e) => setState({ ...state, option: e.target.value })}
            className="d-inline ml-3"
          >
            <FormControlLabel
              value="password"
              control={<Radio color="primary" />}
              label="Senha"
            />
            <FormControlLabel
              value="access"
              control={<Radio color="primary" />}
              label="Chave de acesso"
            />
          </RadioGroup>
        </FormControl>
      </div>
      {state.option !== "" && (
        <>
          <div className="col-12 mt-3">
            <FormLabel component="legend">
              Informe sua Chave de Acesso ou Senha atual:{" "}
            </FormLabel>
          </div>
          <div className="col-md-4 col-xs-12 ml-3">
            <TextField
              type="password"
              label="Chave de acesso"
              value={state.old_access}
              onChange={(e) =>
                setState({ ...state, old_access: e.target.value })
              }
              fullWidth
              error={error && !state.old_access && !state.old_password}
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <TextField
              type="password"
              label="Senha"
              value={state.old_password}
              onChange={(e) =>
                setState({ ...state, old_password: e.target.value })
              }
              error={error && !state.old_password && !state.old_access}
              fullWidth
            />
          </div>
        </>
      )}
      {state.option === "password" &&
        (state.old_access !== "" || state.old_password !== "") && (
          <>
            <div className="col-12 mt-3">
              <FormLabel component="legend">Nova senha: </FormLabel>
            </div>
            <div className="col-md-4 col-xs-12 ml-3">
              <TextField
                type="password"
                label="Senha"
                value={state.new_password}
                onChange={(e) =>
                  setState({ ...state, new_password: e.target.value })
                }
                fullWidth
                error={error && !state.new_password}
              />
            </div>
            <div className="col-md-4 col-xs-12">
              <TextField
                type="password"
                label="Confirmar senha"
                value={state.confirm_password}
                onChange={(e) =>
                  setState({ ...state, confirm_password: e.target.value })
                }
                fullWidth
                error={error && state.new_password !== state.confirm_password}
                helperText={
                  error && !state.confirm_password
                    ? "Campo Obrigatório"
                    : error && state.confirm_password !== state.new_password
                    ? "As senhas devem ser iguais."
                    : ""
                }
              />
            </div>
          </>
        )}
      <div className="col-md-12 mt-5">
        <BtnCan title="Cancelar" onClick={close} float="left" />
        <BtnSave onClick={handleSave} float="right" />
      </div>
      <SweetAlert
        show={access.open}
        success
        title={`Nova chave de acesso: ${access.code}`}
        onConfirm={() => {
          setAccess({
            open: false,
            code: "",
          });
          close();
        }}
      />
    </div>
  );
}
