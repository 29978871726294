import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

export default function ModalFilter({
  open,
  handleClose,
  handleFilter,
  data,
  services,
}) {
  const [state, setState] = useState({});

  useEffect(() => {
    setState(data);
  }, [data]);

  function saveFilter() {
    handleFilter(state);
  }

  const handleChange = (value) => {
    if (state.status.indexOf(value) === -1) {
      setState({
        ...state,
        status: [...state.status, value],
      });
    } else {
      setState({
        ...state,
        status: state.status.filter((row) => row !== value),
      });
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      scroll="body"
    >
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-primary font-weight-semibold">
              Filtros
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={() => handleClose()}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>
          {state.status && (
            <>
              <div className="col-12">
                <h4>Status do Atendimento</h4>
              </div>
              <div className="col-md-4 col-12">
                <FormControlLabel
                  key="0"
                  control={
                    <Checkbox
                      checked={state.status.indexOf("0") !== -1}
                      onChange={() => handleChange("0")}
                      color="primary"
                    />
                  }
                  label={
                    <span
                      style={{
                        borderLeft: `8px solid #45ad30`,
                        paddingLeft: "10px",
                      }}
                    >
                      Aguardando atendimento
                    </span>
                  }
                />
              </div>
              <div className="col-md-4 col-12">
                <FormControlLabel
                  key="1"
                  control={
                    <Checkbox
                      checked={state.status.indexOf("1") !== -1}
                      onChange={() => handleChange("1")}
                      color="primary"
                    />
                  }
                  label={
                    <span
                      style={{
                        borderLeft: `8px solid #870e6a`,
                        paddingLeft: "10px",
                      }}
                    >
                      Em atendimento
                    </span>
                  }
                />
              </div>
              <div className="col-md-4 col-12">
                <FormControlLabel
                  key="2"
                  control={
                    <Checkbox
                      checked={state.status.indexOf("2") !== -1}
                      onChange={() => handleChange("2")}
                      color="primary"
                    />
                  }
                  label={
                    <span
                      style={{
                        borderLeft: `8px solid #d6307b`,
                        paddingLeft: "10px",
                      }}
                    >
                      Em escuta inicial
                    </span>
                  }
                />
              </div>
              <div className="col-md-4 col-12">
                <FormControlLabel
                  key="3"
                  control={
                    <Checkbox
                      checked={state.status.indexOf("3") !== -1}
                      onChange={() => handleChange("3")}
                      color="primary"
                    />
                  }
                  label={
                    <span
                      style={{
                        borderLeft: `8px solid #0057ae`,
                        paddingLeft: "10px",
                      }}
                    >
                      Atendimento realizado
                    </span>
                  }
                />
              </div>
              <div className="col-md-8 col-12">
                <FormControlLabel
                  key="4"
                  control={
                    <Checkbox
                      checked={state.status.indexOf("4") !== -1}
                      onChange={() => handleChange("4")}
                      color="primary"
                    />
                  }
                  label={
                    <span
                      style={{
                        borderLeft: `8px solid #c1c2ce`,
                        paddingLeft: "10px",
                      }}
                    >
                      Não aguardou
                    </span>
                  }
                />
              </div>
            </>
          )}

          <div className="col-md-3 mt-3">
            <TextField
              type="date"
              label="Inicio:"
              value={state.start}
              onChange={(e) => {
                setState({ ...state, start: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="col-md-3 mt-3">
            <TextField
              type="date"
              label="Fim:"
              value={state.end}
              onChange={(e) => {
                setState({ ...state, end: e.target.value });
              }}
              inputProps={{ max: moment().format("YYYY-MM-DD") }}
              fullWidth
            />
          </div>

          <div className="col-md-12 d-flex">
            <TextField
              label="Paciente:"
              value={state.patient}
              onChange={(e) => setState({ ...state, patient: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <IconButton
              aria-label="delete"
              size="small"
              className="mt-3"
              onClick={() => setState({ ...state, patient: "" })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>

          <div className="col-md-12 d-flex">
            <TextField
              label="Profissional:"
              value={state.professional}
              onChange={(e) =>
                setState({ ...state, professional: e.target.value })
              }
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <IconButton
              aria-label="delete"
              size="small"
              className="mt-3"
              onClick={() => setState({ ...state, professional: "" })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>

          <div className="col-12 d-flex">
            <TextField
              label="Serviço:"
              select
              value={state.service}
              onChange={(e) => {
                setState({ ...state, service: e.target.value });
              }}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            >
              {services.map((row) => (
                <MenuItem key={row.value} value={row.value}>
                  {row.label}
                </MenuItem>
              ))}
            </TextField>
            <IconButton
              aria-label="delete"
              size="small"
              className="mt-3"
              onClick={() => setState({ ...state, service: "" })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
          <div className="col-12 d-flex">
            <TextField
              label="Classificação de Risco/Vulnerabilidade:"
              select
              value={state.risk_classification}
              onChange={(e) => {
                setState({ ...state, risk_classification: e.target.value });
              }}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem key="0" value="0">
                <i
                  className="zmdi zmdi-circle zmdi-fw mr-3"
                  style={{ color: "blue" }}
                />{" "}
                Não aguda
              </MenuItem>
              <MenuItem key="1" value="1">
                <i
                  className="zmdi zmdi-circle zmdi-fw mr-3"
                  style={{ color: "green" }}
                />{" "}
                Baixa
              </MenuItem>
              <MenuItem key="2" value="2">
                <i
                  className="zmdi zmdi-circle zmdi-fw mr-3"
                  style={{ color: "yellow" }}
                />{" "}
                Intermediária
              </MenuItem>
              <MenuItem key="3" value="3">
                <i
                  className="zmdi zmdi-circle zmdi-fw mr-3"
                  style={{ color: "red" }}
                />{" "}
                Alta
              </MenuItem>
            </TextField>
            <IconButton
              aria-label="delete"
              size="small"
              className="mt-3"
              onClick={() => setState({ ...state, risk_classification: "" })}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>

          <div className="col-md-12" style={{ marginTop: "30px" }}>
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => handleClose()}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right" }}
              onClick={() => {
                saveFilter();
                handleClose();
              }}
            >
              <i className="zmdi zmdi-filter-list zmdi-hc-lg" />
              <span> Filtrar </span>
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
