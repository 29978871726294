const INITIAL_STATE = {
  id: "",
  appointment: "",
  patient: "",
  forms: {
    dataPatient: "",
    responsible: "",
    reason: "",
    complaint: "",
    basicActivities: "",
    instrumentalActivities: "",
    systemReview: "",
    vital_signs: "",
    disease: "",
    mental_health: "",
    depression_scale: "",
    avaliation_nutritional: "",
    mobility: "",
    sleep_disorders: "",
    physical_activities: "",
    drugs: "",
    sr_cardioSystem: "",
    sr_digestiveSystem: "",
    sr_nervousSystem: "",
    sr_cutaneousSystem: "",
    sr_respiratorySystem: "",
    sr_musculoskeletal: "",
    vaccines: "",
    family_history: "",
    fractures: "",
    falls: "",
    global_diagnostic: "",
    plan_care: "",
    psychic_avaliation: "",
    medical_care: "",
    old_disease: "",
    current_disease: "",
    allergyDiseases: "",
    allergyChemicals: "",
    allergyFood: "",
    allergyDrug: "",
  },
};

export default function admissionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_PATIENT_ADMISSION":
      return {
        ...state,
        patient: action.patient,
      };
    case "SET_ADMISSION_DATA":
      return {
        ...state,
        id: action.payload.admission,
        appointment: action.payload.appointment,
        forms: {
          ...state.forms,
          [action.payload.form]: action.payload.form_value,
        },
      };
    case "CONTINUE_ADMISSION": {
      return {
        ...state,
        id: action.payload.id,
        patient: action.payload.patient,
        forms: { ...state.forms, ...action.payload.forms },
      };
    }
    case "RESET_ADMISSION":
      return INITIAL_STATE;
    default:
      return state;
  }
}
