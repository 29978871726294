const INIT_STATE = {
  place_attendance: "",
  profnl_attendance: "",
  has_specialty: "",
  events: [],
  date: {
    start: "",
    end: "",
  },
  selectedInfo: {
    calendar: "",
    user: "",
    dateStart: "",
    end: "",
    obs: "",
    type: "",
    title: "",
  },
  file: "",
};
export default function OnlineScheduler(state = INIT_STATE, action) {
  switch (action.type) {
    case "SET_PLACE_ATTENDANCE":
      return {
        ...state,
        place_attendance: action.payload.local,
        profnl_attendance: action.payload.profnl,
        has_specialty: action.payload.specialty,
      };
    case "SET_EVENTS_ONLINE":
      return {
        ...state,
        events: action.payload.events,
        date: action.payload.date,
      };
    case "SET_SELECTED_INFO":
      return {
        ...state,
        selectedInfo: {
          ...action.payload,
        },
      };
    case "SET_EVENT_DATA":
      return {
        ...state,
        selectedInfo: {
          ...state.selectedInfo,
          ...action.payload,
        },
      };
    case "SET_PAYMENT_ONLINE_SCHEDULER":
      return {
        ...state,
        selectedInfo: {
          ...state.selectedInfo,
          payment: action.payload.payment,
          card: action.payload.card,
        },
        file: action.payload.file,
      };
    default:
      return state;
  }
}
