import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  Button,
  Tooltip,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import Api from "services/api";
import MaskPhone from "components/Masks/MaskPhone";
import MaskCellphone from "components/Masks/MaskCellphone";
import MaskCpf from "components/Masks/MaskCpf";
import TakePhotoWithCropper from "./TakePhotoWithCropper";
import { getUrlPhoto } from "services/functions";
import { allowCPF } from "containers/Register/Functions";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskCep from "components/Masks/MaskCep";
import axios from "axios";
import { ModalResponsive } from "components/ModalResponsive";
import AutocompleteHealthPlan from "components/Autocomplete/HealthPlan";
import AutocompleteParents from "components/Autocomplete/Parents";
import MaskCns from "components/Masks/MaskCns";
import AutocompleteNaturalty from "containers/Register/AutocompleteNaturalty";

function RegisterUser({
  nameUser,
  open,
  close,
  add,
  address,
  registerAddress,
  healthPlan = true,
}) {
  const [patient, setPatient] = useState({
    id: "",
    name: nameUser,
    genderVal: "",
    idNaturalty: "",
    naturalty: "",
    cpf: "",
    email: "",
    phone: "",
    smartphone: "",
    birthday: "",
    postalcode: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    country: "",
    healthPlan: [
      {
        id: "",
        plan_id: "",
        plan_name: "",
        register: "",
        validate: "",
        identfy: "",
        alter: false,
      },
    ],
    mother_id: "",
    mother_name: "",
    cns: "",
  });
  const [error, setError] = useState(false);
  const [take_photo, setTakePhoto] = useState(false);
  const [imgPatient, setImgPatient] = useState("");
  const [modalCpf, setModalCpf] = useState({
    open: false,
    message: "",
  });
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.authUser);

  useEffect(() => {
    if (nameUser) {
      let formtCase = nameUser.split(" ").map((row) => {
        return row === "Da" ||
          row === "da" ||
          row === "De" ||
          row === "de" ||
          row === "Dos" ||
          row === "dos"
          ? row.charAt(0).toLowerCase() + row.slice(1)
          : row.charAt(0).toUpperCase() + row.slice(1);
      });
      setPatient({ ...patient, name: formtCase.join(" ") });
    }
    setError(false);
  }, [nameUser]);

  const CheckCpf = () => {
    const { cpf } = patient;
    if (cpf.length !== 0) {
      const verifica = allowCPF(cpf);
      if (verifica) {
        Api.post(`/ms_system/user/checkCPF`, { cpf }).then((res) => {
          if (res.data.record.length !== 0) {
            setModalCpf({ open: true, message: "CPF já está cadastrado." });
            setPatient({ ...patient, cpf: "" });
          }
        });
      } else {
        setModalCpf({ open: true, message: "CPF Inválido" });
        setPatient({ ...patient, cpf: "" });
      }
    } else {
      setModalCpf({ open: true, message: "CPF Inválido" });
      setPatient({ ...patient, cpf: "" });
    }
  };

  const validContact = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      re.test(String(patient.email).toLowerCase()) ||
      (patient.smartphone.length === 15 &&
        patient.smartphone.indexOf("_") === -1)
    );
  };

  const searchCep = (event) => {
    let cep = event.target.value.replace(/_/g, "");
    if (cep.length === 9) {
      cep = cep.replace(",", "");
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => {
          setPatient({
            ...patient,
            postalcode: res.data.cep,
            street: res.data.logradouro,
            neighborhood: res.data.bairro,
            city: res.data.localidade,
            state: res.data.uf,
            country: "Brasil",
          });
        })
        .catch(() => {
          console.log("Ocorreu um erro ao recuperar as informações do cep.");
        });
    }
  };

  const validate = () => {
    if (
      patient.name &&
      patient.genderVal &&
      patient.birthday &&
      validContact()
    ) {
      if (registerAddress) {
        if (
          patient.postalcode &&
          patient.street &&
          patient.number &&
          patient.city &&
          patient.state &&
          patient.country
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  function save() {
    if (validate()) {
      Api.post(`/ms_system/user/fastRegisterUser/`, {
        ...patient,
        auth,
        registerAddress,
        cns: patient.cns ? patient.cns.replace(/[^0-9]/g, "") : "",
      }).then((res) => {
        if (imgPatient) {
          dispatch({
            type: "ALTER_PHOTO",
            payload: { patient: res.data.patient_id, image: imgPatient },
          });
        }
        if (address) {
          dispatch({
            type: "INSERT_GROUP_INTRANET",
            payload: { patient: res.data.patient_id, address, auth },
          });
        }
        add(res.data.patient_id, patient.name, patient);
      });
    } else {
      setError(true);
    }
  }

  const handleAdd = () => {
    setPatient({
      ...patient,
      healthPlan: [
        ...patient.healthPlan,
        {
          id: "",
          plan_id: "",
          plan_name: "",
          register: "",
          validate: "",
          identfy: "",
          alter: false,
        },
      ],
    });
  };

  const handleRemove = (index) => {
    setPatient({
      ...patient,
      healthPlan: patient.healthPlan.filter((row, key) => key !== index),
    });
  };

  const handleChange = (item) => {
    setPatient({ ...patient, naturalty: item.label, idNaturalty: item.id });
  };

  return (
    <ModalResponsive
      title="Cadastro rápido usuário"
      open={open}
      close={() => close()}
      buttons={
        <div className="col-md-12">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            style={{ float: "left" }}
            onClick={() => close()}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm bg-success"
            style={{ float: "right" }}
            onClick={() => save()}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Cadastrar </span>
          </Button>
        </div>
      }
    >
      <div className="row">
        <div className="col-md-1 col-xs-3">
          <Tooltip title="Alterar foto">
            <img
              src={imgPatient ? imgPatient : getUrlPhoto(patient.id, "")}
              alt={patient.name}
              className="rounded-circle img-responsive"
              style={{
                width: "60px",
                height: "60px",
                cursor: "pointer",
              }}
              onClick={() => setTakePhoto(true)}
            />
          </Tooltip>
        </div>

        <div className="col-md-11 col-xs-9">
          <TextField
            required
            id="name"
            type="name"
            label="Nome:"
            value={patient.name}
            // onChange={(e) => setPatient({ ...patient, name: e.target.value })}
            fullWidth
            required
            margin="normal"
            className="mt-0"
            error={error && !patient.name}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              onInput: (e) => {
                if (e.target.value) {
                  let formtCase = e.target.value.split(" ").map((row) => {
                    return row === "Da" ||
                      row === "da" ||
                      row === "De" ||
                      row === "de" ||
                      row === "Dos" ||
                      row === "dos"
                      ? row.charAt(0).toLowerCase() + row.slice(1).toLowerCase()
                      : row.charAt(0).toUpperCase() +
                          row.slice(1).toLowerCase();
                  });
                  setPatient({ ...patient, name: formtCase.join(" ") });
                } else {
                  setPatient({ ...patient, name: "" });
                }
              },
            }}
          />
        </div>

        <div className="col-md-6 col-12">
          <TextField
            required
            id="sex"
            select
            label="Sexo:"
            value={patient.genderVal}
            onChange={(e) =>
              setPatient({ ...patient, genderVal: e.target.value })
            }
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            error={error && !patient.genderVal}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="0">
              Masculino
            </MenuItem>
            <MenuItem key="2" value="1">
              Feminino
            </MenuItem>
            <MenuItem key="3" value="2">
              Outro
            </MenuItem>
          </TextField>
        </div>

        <div className="col-md-6 col-12">
          <TextField
            required
            id="dt_nasc"
            type="date"
            label="Data de Nascimento:"
            value={patient.birthday}
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              setPatient({ ...patient, birthday: e.target.value })
            }
            onInput={(e) => {
              if (e.target.value.length > 10) {
                e.target.value = e.target.value.substr(1, 11);
              }
            }}
            error={error && !patient.birthday}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-6 col-12">
          <TextField
            id="cpf"
            label="CPF:"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCpf,
              value: patient.cpf,
              onChange: (e) => setPatient({ ...patient, cpf: e.target.value }),
            }}
            onBlur={CheckCpf}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-6">
          <AutocompleteParents
            label="Nome da mãe:"
            value={{
              id: patient.mother_id,
              label: patient.mother_name,
            }}
            handleChange={(e) =>
              setPatient({ ...patient, mother_id: e.id, mother_name: e.label })
            }
          />
        </div>

        <div className="col-md-6 col-12">
          <TextField
            id="cns"
            label="Cartão Nacional de Saúde"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCns,
              value: patient.cns,
              onChange: (e) => setPatient({ ...patient, cns: e.target.value }),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-6">
          <TextField
            required
            id="email"
            type="email"
            label="Email:"
            value={patient.email}
            onChange={(e) => setPatient({ ...patient, email: e.target.value })}
            fullWidth
            margin="normal"
            helperText={
              error && !validContact()
                ? "Preencha pelo menos uma informação de contato (Email ou Celular)"
                : ""
            }
            error={error && !validContact()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-6">
          <TextField
            required
            id="smartphone"
            label="Celular para contato:"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCellphone,
              value: patient.smartphone,
              onChange: (e) =>
                setPatient({ ...patient, smartphone: e.target.value }),
            }}
            error={error && !validContact()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-6">
          <TextField
            id="fixo"
            label="Telefone de contato:"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskPhone,
              value: patient.phone,
              onChange: (e) =>
                setPatient({ ...patient, phone: e.target.value }),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-6">
          <AutocompleteNaturalty
            setNaturalty={handleChange}
            naturalty={{ id: patient.idNaturalty, label: patient.naturalty }}
            error={false}
          />
        </div>

        {healthPlan && (
          <div className="col-md-12 mt-3">
            <h4 className="text-primary">Plano de Saúde</h4>
            <Table className="table-history">
              <TableBody>
                {patient.healthPlan.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <AutocompleteHealthPlan
                        healthPlan={{
                          id: row.plan_id,
                          name: row.plan_name,
                        }}
                        handleChangeAutocomplete={(obj) => {
                          setPatient({
                            ...patient,
                            healthPlan: patient.healthPlan.map((st, key) =>
                              key === index
                                ? { ...st, ...obj, alter: true }
                                : st
                            ),
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        label="Registro/Carteirinha"
                        margin="normal"
                        fullWidth
                        value={row.register}
                        onChange={(e) => {
                          console.log("index", index);
                          setPatient({
                            ...patient,
                            healthPlan: patient.healthPlan.map((st, key) =>
                              key === index
                                ? {
                                    ...st,
                                    register: e.target.value,
                                    alter: true,
                                  }
                                : st
                            ),
                          });
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        label="Validade"
                        type="date"
                        margin="normal"
                        fullWidth
                        value={row.validate}
                        onChange={(e) => {
                          setPatient({
                            ...patient,
                            healthPlan: patient.healthPlan.map((st, key) =>
                              key === index
                                ? {
                                    ...st,
                                    validate: e.target.value,
                                    alter: true,
                                  }
                                : st
                            ),
                          });
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {patient.healthPlan.length > 1 && (
                        <Tooltip title="Remover registro">
                          <IconButton
                            onClick={() => handleRemove(index)}
                            color="secondary"
                            aria-label="Remover"
                          >
                            <i className="zmdi zmdi-delete zmdi-hc-fw" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {patient.healthPlan.length - 1 === index && (
                        <Tooltip title="Adicionar novo registro">
                          <IconButton
                            onClick={() => handleAdd()}
                            color="primary"
                            aria-label="Editar"
                          >
                            <i className="zmdi zmdi-plus zmdi-hc-fw" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}

        {registerAddress && (
          <>
            <div className="col-12 mt-3 pb-0 mb-0">
              <h4 className="font-weight-semibold">Endereço</h4>
            </div>
            <div className="col-md-4 col-12">
              <TextField
                id="postalcode"
                label="CEP"
                margin="normal"
                fullWidth
                InputProps={{
                  inputComponent: MaskCep,
                  value: patient.postalcode,
                  onChange: searchCep,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={error && !patient.postalcode}
              />
            </div>

            <div className="col-md-8 col-12">
              <TextField
                id="address"
                label="Logradouro"
                value={patient.street}
                margin="normal"
                fullWidth
                onChange={(e) =>
                  setPatient({ ...patient, street: e.target.value })
                }
                error={error && !patient.street}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="col-md-2 col-12">
              <TextField
                id="number"
                label="Número:"
                value={patient.number}
                margin="normal"
                fullWidth
                onChange={(e) =>
                  setPatient({ ...patient, number: e.target.value })
                }
                error={error && !patient.number}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="col-md-6 col-12">
              <TextField
                id="complement"
                label="Complemento:"
                value={patient.complement}
                margin="normal"
                fullWidth
                onChange={(e) =>
                  setPatient({ ...patient, complement: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-md-4 col-12">
              <TextField
                id="neighborhood"
                label="Bairro"
                value={patient.neighborhood}
                margin="normal"
                fullWidth
                onChange={(e) =>
                  setPatient({ ...patient, neighborhood: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-md-4 col-12">
              <TextField
                id="city"
                label="Cidade:"
                value={patient.city}
                margin="normal"
                fullWidth
                InputProps={{ readOnly: patient.postalcode ? true : false }}
                onChange={(e) =>
                  setPatient({ ...patient, city: e.target.value })
                }
                error={error && !patient.city}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-md-4 col-12">
              <TextField
                id="state"
                label="Estado:"
                value={patient.state}
                margin="normal"
                fullWidth
                InputProps={{ readOnly: patient.postalcode ? true : false }}
                onChange={(e) =>
                  setPatient({ ...patient, state: e.target.value })
                }
                error={error && !patient.state}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-md-4 col-12">
              <TextField
                id="country"
                label="País:"
                value={patient.country}
                margin="normal"
                fullWidth
                InputProps={{ readOnly: patient.postalcode ? true : false }}
                onChange={(e) =>
                  setPatient({ ...patient, country: e.target.value })
                }
                error={error && !patient.postalcode}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </>
        )}
        <TakePhotoWithCropper
          open={take_photo}
          handleClose={() => setTakePhoto(false)}
          imageReceive={(img) => {
            setImgPatient(img);
          }}
        />
        <SweetAlert
          show={modalCpf.open}
          warning
          showConfirm={true}
          title={modalCpf.message}
          onConfirm={() => setModalCpf({ open: false, message: "" })}
        />
      </div>
    </ModalResponsive>
  );
}

export default RegisterUser;
