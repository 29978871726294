import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { getCalendars } from "actions/Calendar";
import {
  TextField,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Checkbox,
} from "@material-ui/core";
import Api from "services/api";
import moment from "moment";
import CircularProgress from "components/CircularProgress";

function FormEvent({ date, close, id, modal, patientPortal }) {
  const [title, setTitle] = useState("");
  const [observation, setObservation] = useState("");
  const [error, setError] = useState(false);
  const [dateEvent, setDateEvent] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [repeat, setRepeat] = useState("0");
  const [repeatDate, setRepeatDate] = useState();
  const [typeRepeat, setTypeRepeat] = useState();
  const [weekdays, setWeekdays] = useState([]);
  const [numberOcurrency, setNumberOcurrency] = useState(0);

  const user = useSelector((state) => state.auth.authUser);
  const calendars = useSelector((state) => state.calendar.calendars);
  const calendar = useSelector(
    (state) => state.calendar.calendar_to_scheduling
  );
  const events = useSelector((state) => state.calendar.events);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (patientPortal) {
      dispatch(getCalendars(user, "3", ""));
    }
  }, []);

  useEffect(() => {
    if (!id) {
      setDateEvent(moment(date).format("YYYY-MM-DD"));
      setStart(moment(date).format("HH:mm"));
      setEnd(
        moment(date)
          .add(30, "minutes")
          .format("HH:mm")
      );
    } else {
      let info = events.find((row) => row.id === id);
      setTitle(info.title);
      setDateEvent(moment(info.start).format("YYYY-MM-DD"));
      setStart(moment(info.start).format("HH:mm"));
      setEnd(moment(info.end).format("HH:mm"));
      setObservation(info.description ? info.description : "");
    }
  }, []);

  function cancelEvent() {
    setSaving(true);
    Api.post(`/ms_system/calendar/cancelEventPerson/`, {
      id,
      user,
    })
      .then(() => {
        dispatch(viewAlertMessage("success", "Desmarcado com sucesso!"));
        setTimeout(() => {
          dispatch(closeAlertMessage());
        }, 5000);
        setSaving(false);
        close();
      })
      .catch(() => {
        setSaving(false);
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao desmarcar!"));
        setTimeout(() => {
          dispatch(closeAlertMessage());
        }, 5000);
      });
  }

  function save() {
    if (validate()) {
      setSaving(true);
      Api.post(`/ms_system/calendar/createEventPerson/`, {
        id,
        user,
        calendar,
        title,
        observation,
        start: `${dateEvent} ${start}`,
        end: `${dateEvent} ${end}`,
        repeat,
        repeatDate,
        typeRepeat,
        numberOcurrency,
        weekdays,
      })
        .then(() => {
          dispatch(viewAlertMessage("success", "Agendado com sucesso!"));
          setTimeout(() => {
            dispatch(closeAlertMessage());
          }, 5000);
          setSaving(false);
          close();
        })
        .catch(() => {
          setSaving(false);
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao agendar!"));
          setTimeout(() => {
            dispatch(closeAlertMessage());
          }, 5000);
        });
    }
  }

  function validate() {
    if (title && start && end && dateEvent) {
      return true;
    } else {
      setError(true);
      return false;
    }
  }

  function handleChangeWeekday(value) {
    if (weekdays.indexOf(value) === -1) {
      setWeekdays([...weekdays, value]);
    } else {
      setWeekdays(weekdays.filter((row) => row !== value));
    }
  }

  if (saving) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        {!modal && (
          <div className="col-12">
            <h2 className="text-primary font-weight-semibold">
              Agendar evento
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={() => close()}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>
        )}

        {patientPortal && (
          <div className="col-12 mb-3">
            <TextField
              id="calendar"
              select
              label="Agenda:"
              value={calendar}
              onChange={(e) => {
                let info_cal = calendars.find(
                  (row) => row.id === e.target.value
                );
                dispatch({
                  type: "CHANGE_CALENDAR_INTEGRATED_WEEK",
                  calendar: e.target.value,
                  doctor: info_cal.doctor_id,
                });
              }}
              fullWidth
            >
              {calendars.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  <>
                    <span
                      className="badge"
                      style={{
                        background: row.color,
                        display: "inline",
                        width: "30px",
                        height: "15px",
                        marginTop: "10px",
                      }}
                    />
                    <span>{row.name}</span>
                  </>
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}

        <div className="col-12">
          <TextField
            label="Título:"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            required
            margin="normal"
            className="mt-0"
          />
        </div>

        <div className="col-md-4" style={{ marginTop: "15px" }}>
          <TextField
            id="date"
            type="date"
            label="Data:"
            value={dateEvent}
            onChange={(e) => setDateEvent(e.target.value)}
            fullWidth
            required
            margin="normal"
            error={error && !dateEvent}
          />
        </div>

        <div className="col-md-4 mt-3">
          <TextField
            id="start"
            type="time"
            label="Início:"
            value={start}
            onChange={(e) => setStart(e.target.value)}
            fullWidth
            required
            margin="normal"
            error={error && !start}
          />
        </div>

        <div className="col-md-4 mt-3">
          <TextField
            id="end"
            type="time"
            label="Fim:"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
            fullWidth
            required
            margin="normal"
            error={error && !end}
          />
        </div>

        <div className="col-md-12">
          <TextField
            id="observation"
            multiline={true}
            rows={4}
            label="Observações:"
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
            fullWidth
            margin="normal"
          />
        </div>

        <div className="col-md-12">
          <TextField
            id="repeat"
            select
            label="Repetir:"
            value={repeat}
            onChange={(e) => {
              setRepeat(e.target.value);
              if (e.target.value === "esp") {
                setTypeRepeat("1");
              }
            }}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            error={error && !repeat ? true : false}
          >
            <MenuItem key="rp1" value="0">
              Sem repetição
            </MenuItem>
            <MenuItem key="rp1" value="1">
              Diariamente
            </MenuItem>
            <MenuItem key="rp2" value="7">
              Semanalmente
            </MenuItem>
            <MenuItem key="rp3" value="15">
              Quizenalmente
            </MenuItem>
            <MenuItem key="rp4" value="30">
              Mensalmente
            </MenuItem>
            <MenuItem key="rp5" value="60">
              Bimestralmente
            </MenuItem>
            <MenuItem key="rp6" value="90">
              Trimestralmente
            </MenuItem>
            <MenuItem key="rp7" value="180">
              Semestrealmente
            </MenuItem>
            <MenuItem key="rp8" value="365">
              Anualmente
            </MenuItem>
            <MenuItem key="esp" value="esp">
              Dias específicos
            </MenuItem>
          </TextField>
        </div>
        {repeat === "esp" && (
          <div className="col-12 d-flex">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={weekdays.indexOf("0") !== -1}
                  onChange={() => handleChangeWeekday("0")}
                />
              }
              label="Domingo"
              labelPlacement="end"
              style={{
                marginTop: "13px",
                marginLeft: "0px",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={weekdays.indexOf("1") !== -1}
                  onChange={() => handleChangeWeekday("1")}
                />
              }
              label="Segunda"
              labelPlacement="end"
              style={{
                marginTop: "13px",
                marginLeft: "0px",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={weekdays.indexOf("2") !== -1}
                  onChange={() => handleChangeWeekday("2")}
                />
              }
              label="Terça"
              labelPlacement="end"
              style={{
                marginTop: "13px",
                marginLeft: "0px",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={weekdays.indexOf("3") !== -1}
                  onChange={() => handleChangeWeekday("3")}
                />
              }
              label="Quarta"
              labelPlacement="end"
              style={{
                marginTop: "13px",
                marginLeft: "0px",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={weekdays.indexOf("4") !== -1}
                  onChange={() => handleChangeWeekday("4")}
                />
              }
              label="Quinta"
              labelPlacement="end"
              style={{
                marginTop: "13px",
                marginLeft: "0px",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={weekdays.indexOf("5") !== -1}
                  onChange={() => handleChangeWeekday("5")}
                />
              }
              label="Sexta"
              labelPlacement="end"
              style={{
                marginTop: "13px",
                marginLeft: "0px",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={weekdays.indexOf("6") !== -1}
                  onChange={() => handleChangeWeekday("6")}
                />
              }
              label="Sábado"
              labelPlacement="end"
              style={{
                marginTop: "13px",
                marginLeft: "0px",
              }}
            />
          </div>
        )}
        {repeat !== "0" && (
          <div className="col-md-12" style={{ marginTop: 15 }}>
            <FormLabel component="legend">Termina: </FormLabel>
            <RadioGroup
              name="repeatType"
              value={typeRepeat}
              onChange={(e) => setTypeRepeat(e.target.value)}
              style={{ marginTop: 0, marginLeft: 20 }}
            >
              <div className="d-flex">
                <div>
                  <FormControlLabel
                    style={{ justifyItems: "baseline" }}
                    control={
                      <Radio
                        checked={typeRepeat === "1"}
                        value="1"
                        name="radio-button-demo"
                        color="primary"
                      />
                    }
                    label="Em: "
                  />
                </div>
                <div className="row" style={{ marginLeft: 10, minWidth: 350 }}>
                  <div className="col-md-12">
                    <TextField
                      id="repeatDate"
                      type="date"
                      value={repeatDate}
                      onChange={(e) => setRepeatDate(e.target.value)}
                      fullWidth
                      required
                      margin="normal"
                      error={error && !date}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
              </div>
              {repeat !== "esp" && (
                <div className="d-flex">
                  <div>
                    <FormControlLabel
                      style={{
                        marginTop: "15px",
                        justifyItems: "baseline",
                      }}
                      control={
                        <Radio
                          checked={typeRepeat === "2"}
                          value="2"
                          name="radio-button-demo"
                          color="primary"
                        />
                      }
                      label="Após: "
                    />
                  </div>
                  <div
                    className="row"
                    style={{ marginLeft: 10, minWidth: 350 }}
                  >
                    <div className="col-md-12" style={{ marginTop: "15px" }}>
                      <TextField
                        id="quantityDays"
                        disabled={typeRepeat === "2" ? false : true}
                        type="number"
                        min={1}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ marginRight: 15 }}
                            >
                              Agendamentos
                            </InputAdornment>
                          ),
                        }}
                        value={numberOcurrency}
                        onChange={(e) => setNumberOcurrency(e.target.value)}
                        fullWidth
                        error={error && !numberOcurrency ? true : false}
                      />
                    </div>
                  </div>
                </div>
              )}
            </RadioGroup>
          </div>
        )}

        <div className="col-md-12" style={{ marginTop: "30px" }}>
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            style={{ float: "left" }}
            onClick={() => close()}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>

          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm bg-success"
            style={{ float: "right" }}
            onClick={() => save()}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>

          {id && (
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm mr-2"
              style={{ float: "right" }}
              onClick={() => cancelEvent()}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Desmarcar </span>
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default FormEvent;
