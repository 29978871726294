import React from "react";
import { useSelector, useDispatch } from "react-redux";
import EventIcon from "@material-ui/icons/Event";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";

export default function CompomentsDataDash() {
  const dispatch = useDispatch();
  const data_dash = useSelector((state) => state.dashboard.data_dash);

  const [data, setData] = React.useState(false);

  return (
    <>
      {data ? (
        <div className="row">
          <IconButton
            aria-label="delete"
            onClick={() => setData(!data)}
            color="primary"
          >
            <EventIcon />
          </IconButton>
          <TextField
            id="date"
            label="Inicio:"
            type="date"
            value={data_dash.start}
            onChange={(e) =>
              dispatch({
                type: "SET_DATA_DASH",
                payload: { title: "start", value: e.target.value },
              })
            }
            // className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date"
            label="Término:"
            className="ml-2"
            type="date"
            value={data_dash.end}
            onChange={(e) =>
              dispatch({
                type: "SET_DATA_DASH",
                payload: { title: "end", value: e.target.value },
              })
            }
            // className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: data_dash.start,
                max: moment().format("YYYY-MM-DD"),
              },
            }}
          />
        </div>
      ) : (
        <div className="row align-items-center">
          <IconButton
            aria-label="delete"
            onClick={() => setData(!data)}
            color="primary"
            className="p-0"
          >
            <EventIcon />
          </IconButton>
          <span
            style={{
              fontSize: "initial",
              fontWeight: "bold",
              color: "#177493",
            }}
          >
            {moment(data_dash.start).format("D MMM")} -{" "}
            {moment(data_dash.end).format("D MMM")}
          </span>
        </div>
      )}
    </>
  );
}
