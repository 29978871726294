import React from "react";
import MaskedInput from "react-text-mask";

const MaskTemperature = (props) => {
  let { inputRef, ...others } = props;
  return (
    <MaskedInput {...others} mask={[/[0-9]/, /[0-9]/, ",", /[0-9]/, /[0-9]/]} />
  );
};

export default MaskTemperature;
