import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardBody, CardImg, CardText } from "reactstrap";
import Api from "services/api";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import StopIcon from "@material-ui/icons/Stop";
import momemt from "moment";
import CircularProgress from "components/CircularProgress";
import SendIcon from "@material-ui/icons/Send";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import ShareIcon from "@material-ui/icons/Share";
import { getUrlPhoto } from "services/functions";
import { Btnclose } from "components/Button";
// import Chip from "@material-ui/core/Chip";
// import PersonAddIcon from "@material-ui/icons/PersonAdd";
// import Link from "@material-ui/core/Link";
import { useSelector } from "react-redux";

const synth = window.speechSynthesis;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: "10px",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input_comment: {
    width: "70%",
    marginTop: "0px",
    marginLeft: "5px",
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginTop: "17px",
  },
  history_icon: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  icon: {
    cursor: "pointer",
  },
  share_icon: {
    float: "right",
  },
  style_login: {
    width: "100%",
    background: "#cce7ff",
    border: "none",
    color: "#000",
    justifyContent: "center",
    display: "flex",
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "400",
  },
}));

export default function ViewPost({
  record,
  back,
  user,
  name,
  photo,
  setLogin,
  sendComment,
  handleLiked,
}) {
  const [state, setState] = useState({
    post: {},
    id_category: "",
    text_content: "",
    speech: false,
  });
  const [comment, setComment] = useState("");
  const width = useSelector((state) => state.settings.width);
  const classes = useStyles();

  useEffect(() => {
    if (record.id) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function(event) {
        back();
      });
      Api.post(`/ms_blog/post/getFullPost`, {
        id: record.id,
      }).then((res) => {
        setState({
          post: res.data,
          id_category: res.data.id_category,
          text_content: stripHtml(res.data.content),
        });
      });
    }
  }, [record]);

  function startSpeech(text) {
    setState({ ...state, speech: true });
    const utterThis = new SpeechSynthesisUtterance(text);
    utterThis.lang = "pt-BR";
    synth.speak(utterThis);
  }

  function stopSpeech() {
    synth.cancel();
    setState({ ...state, speech: false });
  }

  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const handleComment = () => {
    sendComment(record.id, comment, true);
    setState({
      ...state,
      post: {
        ...state.post,
        comments: [
          ...state.post.comments,
          {
            id: "",
            comment,
            name,
            photo,
            user,
          },
        ],
      },
    });
    setComment("");
  };

  const handleShare = () => {
    navigator.share({
      title: state.post.title,
      text: state.post.title,
      url: state.post.url_new,
    });
  };

  const { post, text_content, speech } = state;

  return (
    <>
      <div className="row animated slideInUpTiny animation-duration-3">
        <div className="col-12 d-flex justify-content-center">
          {/* <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 p-0 m-0 animated slideInUpTiny animation-duration-3"> */}
          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-0 m-0 animated slideInUpTiny animation-duration-3">
            <Card className="shadow border-0">
              {post.category !== undefined ? (
                <>
                  <CardImg
                    top
                    width="100%"
                    src={record.photo}
                    alt={record.title}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "0 20px",
                    }}
                  >
                    <Fab
                      color="primary"
                      aria-label="add"
                      style={{
                        top: "-29px",
                      }}
                      onClick={() => back()}
                    >
                      <ArrowBackIcon />
                    </Fab>
                    {synth && (
                      <Tooltip title={speech ? "Parar" : "Ler"}>
                        <Fab
                          color={speech ? "secondary" : "primary"}
                          aria-label="add"
                          style={{
                            top: "-29px",
                          }}
                          onClick={() =>
                            speech ? stopSpeech() : startSpeech(text_content)
                          }
                        >
                          {speech ? <StopIcon /> : <RecordVoiceOverIcon />}
                        </Fab>
                      </Tooltip>
                    )}
                  </div>
                  <CardBody
                    style={{
                      marginTop: "-40px",
                    }}
                  >
                    <h3
                      className="card-title"
                      style={{
                        fontSize: "28px",
                        color: "#626262",
                        fontFamily: "open sans pro,Helvetica,Arial",
                        fontWeight: "600",
                      }}
                    >
                      {post.title}
                    </h3>
                    <div className="mb-3">
                      <div className="d-block">
                        <span className="text font-weight-bold">
                          Data de publicação:
                        </span>
                        <span className="text ml-1 font-italic">
                          {momemt(post.published_date).format(
                            "DD/MM/YYYY HH:mm"
                          )}{" "}
                        </span>
                      </div>
                      <div className="d-block">
                        <span className="text font-weight-bold">
                          Categoria:
                        </span>
                        <span className="text ml-1 font-italic">
                          {post.category}
                        </span>
                      </div>
                    </div>
                    <CardText>
                      <div dangerouslySetInnerHTML={{ __html: post.content }} />
                    </CardText>
                  </CardBody>

                  <CardBody
                    style={{
                      marginTop: "-40px",
                    }}
                  >
                    <hr className="w-100" />
                    <div className={classes.root}>
                      <ThumbUpOutlinedIcon
                        className={classes.icon}
                        color={record.liked ? "primary" : "default"}
                        onClick={() =>
                          handleLiked(record.id, record.liked, true)
                        }
                      />{" "}
                      <span
                        className={record.liked ? "mt-2 text-primary" : "mt-2"}
                      >
                        {record.likes}
                      </span>
                      <ChatBubbleOutlineIcon className={classes.icon} />{" "}
                      <span className="mt-2">{record.comments}</span>
                      <ShareIcon
                        className={classes.share_icon}
                        onClick={handleShare}
                      />{" "}
                      <span className="mt-2" onClick={handleShare}>
                        Compartilhar
                      </span>
                    </div>
                    <hr className="w-100" />
                    {post.comments.map((row) => (
                      <div className="d-flex col-12 p-0 avatar-text mr-auto">
                        <Avatar
                          className={classes.icon}
                          alt={row.name}
                          src={getUrlPhoto(row.user, row.photo)}
                        />
                        <div className="ml-2">
                          <span>
                            <strong>{row.name}</strong>
                          </span>
                          <p>{row.comment}</p>
                        </div>
                      </div>
                    ))}
                    <div className="d-flex col-12 p-0 avatar-text mr-auto">
                      <Avatar className={classes.icon} src={photo} />
                      <TextField
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        label="Escreva um comentário..."
                        className={classes.input_comment}
                      />
                      <SendIcon
                        color="primary"
                        className="mt-3 ml-2"
                        onClick={handleComment}
                      />
                    </div>
                  </CardBody>
                  <div className="col-12 p-3">
                    <Btnclose title={"voltar"} onClick={back} float="left" />
                  </div>
                  {/* <div className="m-3">
                      <span className="font-weight-bold">
                        Gostou? Compartilhe:
                      </span>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${post.url_share}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span class="icon twitter-icon jr-link">
                          <i class="zmdi zmdi-facebook zmdi-hc-fw zmdi-hc-lg"></i>
                        </span>
                      </a>

                      <a
                        href={`http://twitter.com/intent/tweet?text=${post.title}&url=${post.url_share}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span class="icon twitter-icon jr-link">
                          <i class="zmdi zmdi-twitter zmdi-hc-fw zmdi-hc-lg"></i>
                        </span>
                      </a>
                      <a
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${post.url_share}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span class="icon linkedin-icon jr-link">
                          <i class="zmdi zmdi-linkedin zmdi-hc-fw zmdi-hc-lg"></i>
                        </span>
                      </a>
                    </div> */}
                  {/* <div className="col-12 mt-5"></div> */}
                </>
              ) : (
                <div className="col-12 m-3">
                  <CircularProgress />
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
