import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContainerHeader from "components/ContainerHeader";
import Glice from "app/routes/evolution/routes/components/Glice";
import {
  TextField,
  FormControl,
  Input,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@material-ui/core";
import { BtnSave } from "components/Button";
import MaskTemperature from "components/Masks/MaskTemperature";
import MaskFloat from "components/Masks/MaskFloat";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";
import { showMsg } from "services/functions";
import IconChart from "components/Charts/IconChart";

export default function ClinicData({ match, modal, beforeSave }) {
  const [dados, setDados] = useState({
    id: "",
    weight: "",
    tall: "",
    imc: "",
    class_imc: "",
    glice: [{ value: "", hour: "" }],
  });
  const [modalAlert, setModalAlert] = useState(false);
  const gender = useSelector((state) => state.auth.gender);
  const user = useSelector((state) => state.auth.authUser);
  const dispatch = useDispatch();

  useEffect(() => {
    let { weight, tall, imc, class_imc } = dados;
    if (weight && tall) {
      weight = convertStrToFloat(weight);
      tall = convertStrToFloat(tall);
      const result = weight / ((tall / 100) * (tall / 100));
      imc = result ? result.toFixed(1) : "";

      if (result < 16) {
        class_imc = "Magreza Grau III";
      } else if (result >= 16 && result < 17) {
        class_imc = "Magreza Grau II";
      } else if (result >= 17 && result < 18.5) {
        class_imc = "Magreza Grau I";
      } else if (result >= 18.5 && result < 25) {
        class_imc = "Eutrofia";
      } else if (result >= 25 && result < 30) {
        class_imc = "Pré-obeso";
      } else if (result >= 30 && result < 35) {
        class_imc = "Obesidade I";
      } else if (result >= 35 && result < 40) {
        class_imc = "Obesidade II";
      } else {
        class_imc = "Obesidade III";
      }
      setDados({ ...dados, imc, class_imc });
    } else {
      setDados({ ...dados, imc: "", class_imc: "" });
    }
  }, [dados.tall, dados.weight]);

  const convertStrToFloat = (str) => {
    if (typeof str == "string") {
      str = str.replace(",", ".");
      return parseFloat(str);
    } else if (typeof str == "number") {
      if (parseFloat(str)) {
        return parseFloat(str);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const handleChange = (field) => (event) => {
    setDados({ ...dados, [field]: event.target.value });
  };

  const handleChangeGlice = (value, field, index) => {
    setDados({
      ...dados,
      glice: dados.glice.map((row, key) =>
        key === index ? { ...row, [field]: value } : row
      ),
    });
  };

  const handleSave = () => {
    Api.post("microservice/patientportal/saveClinicData", dados)
      .then((res) => {
        setDados({ ...dados, id: res.data.id });
        showMsg("success", "Salvo com sucesso!", dispatch);
        if (modal) {
          beforeSave();
        }
      })
      .catch(() => showMsg("error", "Ocorreu um erro ao salvar.", dispatch));
  };

  return (
    <div className="animated slideInUpTiny animation-duration-3">
      {!modal && <ContainerHeader title="Dados Clinicos" match={match} />}
      <div className="row" style={{ padding: "5px 15px" }}>
        <div className={!modal ? "jr-card jr-card-widget card w-100" : "w-100"}>
          {!modal && (
            <div className="row">
              <div className="col-12">
                <h4 className="font-weight-semibold text-primary">
                  Dados Clínicos
                </h4>
              </div>
            </div>
          )}

          {gender === "1" && (
            <div className="row">
              <div className="col-12">
                <h4 className="font-weight-semibold">Dados da Mulher</h4>
              </div>
              <div className="col-md-3 col-xs-6">
                <TextField
                  type="date"
                  label="Última menstruação:"
                  margin="normal"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dados.last_menstruation}
                  onChange={handleChange("last_menstruation")}
                />
              </div>
              <div className="col-md-3 col-xs-6">
                <TextField
                  type="date"
                  label="Última visita ao ginecologista:"
                  margin="normal"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dados.last_control}
                  onChange={handleChange("last_control")}
                />
              </div>
              <div className="col-md-3 col-xs-6">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Gestante</FormLabel>
                  <RadioGroup
                    aria-label="action"
                    name="customized-radios"
                    value={dados.pregnant}
                    onChange={handleChange("pregnant")}
                    row
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label="Sim"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label="Não"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-md-3 col-xs-6">
                {dados.pregnant === "1" && (
                  <FormControl className="my-3" fullWidth>
                    <FormHelperText>Semanas de gestação:</FormHelperText>
                    <Input
                      id="weeks"
                      type="number"
                      value={dados.pregnant_week}
                      onChange={handleChange("pregnant_week")}
                      endAdornment={
                        <InputAdornment position="end">semanas</InputAdornment>
                      }
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9]/g, "")
                          .substr(0, 3);
                      }}
                    />
                  </FormControl>
                )}
              </div>
            </div>
          )}

          {gender === "0" && (
            <div className="row">
              <div className="col-12">
                <h4 className="font-weight-semibold">Dados do Homem</h4>
              </div>
              <div className="col-md-3 col-xs-6">
                <TextField
                  type="date"
                  label="Última visita ao urologista:"
                  margin="normal"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dados.last_control}
                  onChange={handleChange("last_control")}
                />
              </div>
              <div className="col-md-3 col-xs-6">
                <MaskFloat
                  label="PSA:"
                  unity="ng/mL"
                  value={dados.psa ? dados.psa : ""}
                  onChange={(e) => setDados({ ...dados, psa: e.target.value })}
                />
              </div>
            </div>
          )}

          {/* Medidas */}
          <div className="row">
            <div className="col-12 mt-3">
              <h4 className="font-weight-semibold">Medidas</h4>
            </div>

            <div className="col-md-3 col-xs-6 d-flex">
              <MaskFloat
                label="Peso atual:"
                unity="Kg"
                value={dados.weight}
                onChange={(e) => setDados({ ...dados, weight: e.target.value })}
              />
              <IconChart title="Evolução - Peso" type="weight" patient={user} />
            </div>

            <div className="col-md-3 col-xs-6 d-flex">
              <FormControl className="my-3" fullWidth>
                <FormHelperText>Altura: </FormHelperText>
                <Input
                  id="tall"
                  type="number"
                  value={dados.tall}
                  onChange={handleChange("tall")}
                  endAdornment={
                    <InputAdornment position="end">Cm</InputAdornment>
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .substr(0, 3);
                  }}
                />
              </FormControl>
              <IconChart title="Evolução - Altura" type="tall" patient={user} />
            </div>

            <div className="col-md-3 col-xs-6 d-flex">
              <FormControl className="my-3" fullWidth>
                <FormHelperText>I.M.C:</FormHelperText>
                <Input
                  readOnly
                  id="imc"
                  value={dados.imc}
                  onChange={handleChange("imc")}
                />
              </FormControl>
              <IconChart title="Evolução - IMC" type="imc" patient={user} />
            </div>

            <div className="col-md-3 col-xs-6">
              <FormControl className="my-3" fullWidth>
                <FormHelperText>Classe I.M.C:</FormHelperText>
                <Input
                  readOnly
                  id="class_imc"
                  value={dados.class_imc}
                  onChange={handleChange("class_imc")}
                />
              </FormControl>
            </div>

            {/*   <div className="col-12">
             
            </div> */}
            <div className="col-md-3 col-12">
              <h4 className="font-weight-semibold">
                Pressão Arterial{" "}
                <IconChart
                  title="Evolução - Pressão Arterial"
                  type="pa"
                  patient={user}
                />
              </h4>
              <FormControl fullWidth>
                <FormHelperText>PAS (Maior valor):</FormHelperText>
                <Input
                  value={dados.pas}
                  onChange={handleChange("pas")}
                  inputProps={{ maxLength: 3 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </FormControl>
            </div>
            <div className="col-md-3 col-12">
              <h4 className="font-weight-semibold"> </h4>
              <FormControl fullWidth className="mt-3">
                <FormHelperText>PAD (Menor valor):</FormHelperText>
                <Input
                  value={dados.pad}
                  onChange={handleChange("pad")}
                  inputProps={{ maxLength: 3 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </FormControl>
            </div>

            {/*  <div className="col-12">
              
            </div> */}
            <div className="col-md-3 col-12">
              <h4 className="font-weight-semibold">
                Pulso - Frequência Cardíaca
                <IconChart
                  title="Evolução - Frequência Cardíaca Basal"
                  type="fc"
                  patient={user}
                />
              </h4>
              <FormControl fullWidth>
                <FormHelperText>Frequência:</FormHelperText>
                <Input
                  type="number"
                  value={dados.freq}
                  onChange={handleChange("freq")}
                  endAdornment={
                    <InputAdornment position="end">bpm</InputAdornment>
                  }
                  onBlur={() => {
                    if (
                      parseInt(dados.freq) < 41 ||
                      parseInt(dados.freq) > 140
                    ) {
                      setModalAlert(true);
                    }
                  }}
                />
              </FormControl>
            </div>

            {/* <div className="col-12 mb-2">
              
            </div> */}
            <div className="col-md-3 col-12">
              <h4 className="font-weight-semibold">
                Frequência Respiratória{" "}
                <IconChart
                  title="Evolução - Frequência Respiratória"
                  type="fr"
                  patient={user}
                />
              </h4>
              <FormControl fullWidth>
                <FormHelperText> </FormHelperText>
                <Input
                  value={dados.fc_resp}
                  onChange={handleChange("fc_resp")}
                  onBlur={() => {
                    let fc_resp = parseInt(dados.fc_resp);
                    if (fc_resp > 30 && fc_resp < 41) {
                      setModalAlert(true);
                    } else {
                      if (fc_resp < 10 || fc_resp > 40) {
                        setDados({ ...dados, fc_resp: "" });
                        dispatch(
                          viewAlertMessage(
                            "error",
                            "Valor invalido! (Informe um valor entre 10 e 40)"
                          )
                        );
                        setTimeout(() => {
                          dispatch(closeAlertMessage());
                        }, 5000);
                      }
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">IRPM</InputAdornment>
                  }
                  inputProps={{ maxLength: 2 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </FormControl>
            </div>

            <div className="col-md-3 col-12 mt-3">
              <h4 className="font-weight-semibold">
                Temperatura{" "}
                <IconChart
                  title="Evolução - Temperatura"
                  type="tc"
                  patient={user}
                />
              </h4>
              <TextField
                margin="normal"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">°C</InputAdornment>
                  ),
                  inputComponent: MaskTemperature,
                  value: dados.temp ? dados.temp : "",
                  onChange: (e) => setDados({ ...dados, temp: e.target.value }),
                }}
              />
            </div>

            <div className="col-md-3 col-12 mt-3">
              <h4 className="font-weight-semibold">
                Saturação{" "}
                <IconChart
                  title="Evolução - Saturação"
                  type="sat"
                  patient={user}
                />
              </h4>
              <FormControl className="my-3" fullWidth>
                <FormHelperText></FormHelperText>
                <Input
                  id="saturation"
                  value={dados.saturation}
                  onChange={handleChange("saturation")}
                  inputProps={{ maxLength: 3 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                />
              </FormControl>
            </div>

            <div className="col-md-6 col-12 mt-3">
              <h4 className="font-weight-semibold">
                Glicemia capilar{" "}
                <IconChart
                  title="Evolução - Glicemia Capilar"
                  type="bg"
                  patient={user}
                />
              </h4>
              <Glice
                glice={dados.glice}
                handleChange={handleChangeGlice}
                handleAdd={() =>
                  setDados({
                    ...dados,
                    glice: [...dados.glice, { hour: "", value: "" }],
                  })
                }
                handleRemove={(index) =>
                  setDados({
                    ...dados,
                    glice: dados.glice.filter((row, i) => i !== index),
                  })
                }
                hideMore={true}
              />
            </div>

            <div className="col-12">
              <BtnSave onClick={handleSave} float="right" />
            </div>
          </div>

          <SweetAlert
            show={modalAlert}
            warning
            title="Risco - Acione imediatamente uma ambulância!"
            onConfirm={() => setModalAlert(false)}
            confirmBtnBsStyle="danger"
            confirmButtonColor="#bc0003"
          />
        </div>
      </div>
    </div>
  );
}
