const INITIAL_STATE = {
  // prescription Manipulated
  form: "exist",
  recipe_id: "",
  origin_new: null,
  class_new: null,
  treatment_new: null,
  presentation_new: null,
  formula_new: null,
  ingredient_new: [],
  interval_new: "",
  quantity_new: "",
  recipe_orientation_new: "",
  time_new: "",
  total_quantity_new: "",
  va_new: "",
  x_day_new: "",
  origin: [],
  class: [],
  treatment: [],
  formula: [],
  formula_list: [],
  formula_selected: "",
  default: "",
  new_formula: false,
  formula_atual: {},
  interval: "",
  quantity: "",
  presentation: "",
  recipe_obs: "",
  recipe_orientation: "",
  time: "",
  total_quantity: "",
  va: "",
  x_day: "",
  ingredient: "",
  name_recipe: "",
  orientation: [],
  ingredientAdd: [],
  dosage: [],
  forms: [],
  list_manipulated: [],
  savedM: false,
  pattern_mpn: [],
  patternSalutem: []
};

export default function PrescriptionManipulated(state = INITIAL_STATE, action) {
  switch (action.type) {
    // prescrição manipulada
    case "GET_PRESCRIPTION_MPN_SUCCESS":
      return {
        ...state,
        name_recipe: action.payload.recipe[0].name,
        recipe_id: action.payload.recipe[0].id,
        recipe_obs: action.payload.recipe[0].recipe_orientation,
        default: action.payload.recipe[0].default,
        formula_list: action.payload.formulaList
      };
    case "CHANGE_FORM_MPN":
      return {
        ...state,
        form: action.form
      };
    case "CLEAN_MPN_FORM":
      return {
        ...state,
        formula_atual: {},
        name_recipe: "",
        recipe_id: "",
        recipe_obs: "",
        formula_list: [],
        formula_selected: "",
        ingredient: [],
        interval: "",
        quantity: "",
        presentation: "",
        recipe_orientation: "",
        time: "",
        total_quantity: "",
        va: "",
        x_day: ""
      };
    case "CANCEL_FORMULA_MPN":
      return {
        ...state,
        new_formula: false,
        form: "exist",
        formula_atual: {},
        formula_selected: ""
      };
    case "NEW_FORMULA_MPN":
      return {
        ...state,
        new_formula: true,
        formula_atual: action.formula,
        ingredient:
          action.formula.ingredients === undefined
            ? []
            : action.formula.ingredients,
        formula_selected: action.id_formula,
        interval: action.formula.interval,
        nameForm: action.formula.nameForm,
        quantity: action.formula.quantity,
        presentation: action.formula.presentation,
        recipe_orientation: action.formula.recipe_orientation,
        time: action.formula.time,
        total_quantity: action.formula.total_quantity,
        va: action.formula.va,
        x_day: action.formula.x_day
      };
    case "ADD_INGREDIENT_LIST_NEW":
      return {
        ...state,
        ingredient_new: [...state.ingredient_new, action.data]
      };
    case "UPDATE_LIST_MPN":
      return {
        ...state,
        ingredient: action.list
      };
    case "UPDATE_LIST_MPN_NEW":
      return {
        ...state,
        ingredient_new: action.list
      };
    case "ADD_INGREDIENT_LIST":
      return {
        ...state,
        ingredient: [...state.ingredient, action.data]
      };
    case "ADD_FORMULA_LIST":
      const updateList = state.formula_list.filter(
        el => el.idForm !== action.data.idForm
      );
      return {
        ...state,
        formula_list: [...updateList, action.data]
      };
    case "REMOVE_FORMULA_MPN":
      const listForm = state.formula_list.filter(
        el => el.idForm !== action.id_formula
      );
      return {
        ...state,
        formula_list: listForm
      };
    case "REMOVE_INGREDIENT_LIST_NEW":
      const listNew = state.ingredient_new.filter(
        el => el.id !== action.ingList
      );
      return {
        ...state,
        ingredient_new: listNew
      };
    case "REMOVE_INGREDIENT_LIST":
      const list = state.ingredient.filter(el => el.id !== action.ingList);
      return {
        ...state,
        ingredient: list
      };
    case "GET_ORIGIN_PRES_SUCCESS":
      return {
        ...state,
        origin: action.payload
      };
    case "GET_CLASS_MPN_SUCCESS":
      return {
        ...state,
        class: action.payload
      };
    case "GET_TREATMENT_MPN_SUCCESS":
      return {
        ...state,
        treatment: action.payload
      };
    case "GET_FORMULA_MPN_SUCCESS":
      return {
        ...state,
        formula: action.payload
      };
    case "GET_PRESENTATION_MPN_SUCCESS":
      return {
        ...state,
        presentation: action.payload
      };
    case "GET_PRES_STATIC_MPN_SUCCESS":
      return {
        ...state,
        forms: action.payload
      };
    case "GET_INGREDIENT_MPN_NEW_SUCCESS":
      return {
        ...state,
        ingredient_new:
          action.payload.ingredients === null ? [] : action.payload.ingredients,
        interval_new: action.payload.orientation[0].interval,
        quantity_new: action.payload.orientation[0].quantity,
        presentation_new: action.payload.orientation[0].presentation,
        recipe_orientation_new:
          action.payload.orientation[0].aditional_consideration,
        time_new: action.payload.orientation[0].time,
        total_quantity_new: action.payload.orientation[0].total_quantity,
        va_new: action.payload.orientation[0].va,
        x_day_new: action.payload.orientation[0].x_day
      };
    case "GET_INGREDIENT_MPN_SUCCESS":
      return {
        ...state,
        ingredient: action.payload.ingredients,
        interval: action.payload.orientation[0].interval,
        quantity: action.payload.orientation[0].quantity,
        presentation: action.payload.orientation[0].presentation,
        recipe_orientation:
          action.payload.orientation[0].aditional_consideration,
        time: action.payload.orientation[0].time,
        total_quantity: action.payload.orientation[0].total_quantity,
        va: action.payload.orientation[0].va,
        x_day: action.payload.orientation[0].x_day
      };
    case "GET_PATTERN_MPN_SUCCESS":
      return {
        ...state,
        pattern_mpn: action.payload.pattern,
        patternSalutem: action.payload.patternSalutem
      };
    case "GET_INGREDIENT_MPN_NEW_SUCCESS":
      return {
        ...state,
        ingredientAdd: action.payload
      };
    case "GET_DOSAGE_MPN_SUCCESS":
      return {
        ...state,
        dosage: action.payload
      };
    case "GET_MANIPULATE_PRES_SUCCESS":
      return {
        ...state,
        list_manipulated: action.payload
      };
    case "SAVE_FORMULA_SUCCESS":
      return {
        ...state,
        originNew: action.payload.idOrigin,
        origin: [
          ...state.origin,
          { id: action.payload.idOrigin, name: action.payload.nameOrigin }
        ],
        classNew: action.payload.idClass,
        treatment_new: action.payload.idTratamento,
        formula_new: action.payload.Formula,
        ingredient: action.payload.ingredients,
        formula_atual: action.payload.Formula,
        formula_selected: action.payload.Formula.idForm,
        nameForm: action.payload.Formula.nameForm,
        interval: action.payload.Formula.interval,
        quantity: action.payload.Formula.quantity,
        presentation: action.payload.Formula.presentation,
        recipe_orientation: action.payload.Formula.aditional_consideration,
        time: action.payload.Formula.time,
        total_quantity: action.payload.Formula.total_quantity,
        va: action.payload.Formula.va,
        x_day: action.payload.Formula.x_day
      };
    case "SAVE_RECIPE_SUCCESS":
      let recipe = state.list_manipulated.filter(row => {
        return row.id !== action.payload.idRecipe;
      });
      return {
        ...state,
        savedM: true,
        list_manipulated: [
          ...recipe,
          {
            id: action.payload.idRecipe,
            name: action.payload.recipe_name
          }
        ],
        recipe_id: action.payload.idRecipe
      };
    case "REMOVE_PRESCRIPTION_MAN_SUCCESS":
      if (action.payload.success) {
        let prescs;
        prescs = state.list_manipulated.filter(row => {
          return row.id !== action.payload.id_prescription;
        });
        return {
          ...state,
          list_manipulated: prescs
        };
      } else {
        return state;
      }
    case "RESET_PRESCRIPTION_MANIPULATED":
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
}
