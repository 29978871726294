import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import NoRecord from "components/Alert/NoRecord";
import ViewConsult from "./ViewConsult";
import FormFilter from "./FormFilter";
import ComponentToPrint from "components/print";
import moment from "moment";

import "./styles.css";
import Api from "services/api";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 10,
  },
  table: {
    minWidth: 700,
  },
  borderStyle: {
    border: "1px solid #dadce0",
    borderRadius: "4px",
  },
});

class HistoryConsult extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    rows: [],
    mode_list: true,
    id_consult: "",
    modal_filter: false,
    doctor: [],
    specialty: "",
    start: "",
    end: "",
  };

  componentDidMount() {
    Api.post(`/microservice/consult/getHistoryConsult/`, {
      patient: this.props.patient,
    })
      .then((res) => {
        this.setState({ rows: res.data.appointments });
      })
      .catch(() => {});
  }

  handleClose = () => {
    this.setState({ mode_list: true });
  };

  viewConsult(data) {
    this.setState({ mode_list: false, id_consult: data });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const {
      rows,
      rowsPerPage,
      page,
      mode_list,
      id_consult,
      doctor,
      specialty,
      start,
      end,
    } = this.state;
    const { classes } = this.props;
    const record = rows.filter(
      (el) =>
        (doctor.length === 0 || doctor.indexOf(el.name) !== -1) &&
        (!specialty || (el.specialty && el.specialty.includes(specialty))) &&
        (!start || moment(el.date_of_occurrence).isAfter(moment(start))) &&
        (!end || moment(el.date_of_occurrence).isBefore(moment(end)))
    );
    return (
      <div className={`container-person ${classes.borderStyle}`}>
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400",
          }}
        >
          Consultas
          <Tooltip title="Filtro">
            <IconButton
              onClick={() => this.setState({ modal_filter: true })}
              className="pt-0"
              style={{ float: "right" }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </h3>
        {mode_list ? (
          rows.length > 0 ? (
            <div
              className="col-12"
              style={{
                minWidth: "650",
                overflowX: "auto",
              }}
            >
              <Table className="">
                <TableHead>
                  <TableRow>
                    <TableCell>Profissional</TableCell>
                    <TableCell align="left">Especialidade</TableCell>
                    <TableCell align="left">Data</TableCell>
                    <TableCell align="left">Registro do Atendimento</TableCell>
                    <TableCell align="right">Visualizar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n) => {
                      return (
                        <TableRow hover tabIndex={-1} key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.name}
                          </TableCell>
                          <TableCell align="left">{n.specialty}</TableCell>
                          <TableCell align="left">{n.date}</TableCell>
                          <TableCell align="left">{n.free_text}</TableCell>
                          <TableCell align="right">
                            <ComponentToPrint
                              dados={{
                                id: n.id,
                                name: "consult",
                                type: "iconButton",
                              }}
                            />
                            <IconButton
                              className="m-0 p-0"
                              onClick={() => this.viewConsult(n.id)}
                              color="primary"
                              aria-label="Visualizar"
                            >
                              <i className="zmdi zmdi-eye zmdi-hc-fw" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={record.length}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Registros por página"
                      labelDisplayedRows={({ from, to, count }) =>
                        `Mostrando ${from} - ${to} de ${count}`
                      }
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          ) : (
            <NoRecord />
          )
        ) : (
          <ViewConsult
            appointment_id={id_consult}
            onClose={this.handleClose.bind(this)}
          />
        )}
        <FormFilter
          open={this.state.modal_filter}
          data={rows}
          handleFilter={(obj) => this.setState({ ...obj })}
          handleClose={() => this.setState({ modal_filter: false })}
        />
      </div>
    );
  }
}

HistoryConsult.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HistoryConsult);
