import React from "react";

/* Serviços */
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { getStringToSearch } from "services/functions";

/* Components */
import SearchComponent from "components/SearchComponent";
import CareIcons from "components/CareIcons";
import NoRecord from "components/Alert/NoRecord";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import SweetAlert from "react-bootstrap-sweetalert";
import { Badge } from "reactstrap";
import BlockIcon from "@material-ui/icons/Block";
import {
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { data } from "jquery";

/* Estilos */
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "600",
    fontSize: "20px",
  },
  cellButtons: {
    [theme.breakpoints.down("md")]: {
      display: "flex !important",
    },
  },
}));

export default function TablePrescriptionCare({
  listCare,
  setListCare,
  setOpenView,
  setEdit,
  handleDelete,
}) {
  const classes = useStyles();

  /* Variáveis Locais */
  const [search, setSearch] = React.useState("");
  const [modalDelete, setModalDelete] = React.useState(null);

  /* Marca cuidado selecionado */
  const handleChangeCheck = (created_at) => {
    setListCare(
      listCare.map((row) =>
        row.created_at === created_at ? { ...row, check: !row.check } : row
      )
    );
  };

  /* Seleciona todos os cuidados */
  const handleChangeCheckAll = (event) => {
    setListCare(
      listCare.map((row) => ({
        ...row,
        check: row.is_pattern !== "1" ? event.target.checked : false,
      }))
    );
  };

  return (
    <div>
      <div className={"w-100 d-flex p-2"}>
        {/* ChackBox para todos os Cuidados */}
        <Checkbox
          checked={
            [
              ...listCare
                .filter((el) => el.is_pattern !== "1")
                .map((el) => el.check),
            ].indexOf(false) === -1
          }
          tabIndex={-1}
          disableRipple
          onChange={handleChangeCheckAll}
          color="primary"
          className="mr-3 ml-3"
        />
        {listCare.filter((el) => el.check === true).length > 0 ? (
          /* Botão deletar selecionados */
          <IconButton
            color="secondary"
            onClick={() =>
              setModalDelete(
                listCare
                  .filter((el) => el.check === true)
                  .map((row) => ({ id: row.id, type: row.type }))
              )
            }
          >
            <BlockIcon />
          </IconButton>
        ) : (
          /* Barra de Pesquisa */
          <SearchComponent
            searchContact={setSearch}
            placeholder={"Buscar Cuidados Prescritos"}
          />
        )}
      </div>
      <div
        className="col-12 mt-3"
        style={{
          minWidth: "650",
          overflowX: "auto",
        }}
      >
        {/* Tabela de Cuidados */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell style={{ minWidth: "300px" }}>Cuidado</TableCell>
              <TableCell>x/Dia</TableCell>
              <TableCell>Periodicidade</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listCare.length > 0 ? (
              listCare
                .filter(
                  //filtro de pesquisa
                  (el) => getStringToSearch(el.name).includes(search)
                )
                .sort((a, b) => {
                  //Ordena pela data de criação
                  if (moment(a.created_at).isBefore(moment(b.created_at))) {
                    return -1;
                  }
                  if (!moment(a.created_at).isBefore(moment(b.created_at))) {
                    return 1;
                  }
                  // a must be equal to b
                  return 0;
                })
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ padding: "0", width: "40px" }}>
                      <Checkbox
                        checked={row.check}
                        onChange={() => handleChangeCheck(row.created_at)}
                        value="secondary"
                        color="primary"
                        disabled={row.is_pattern === "1"}
                      />
                    </TableCell>
                    <TableCell>{<CareIcons type={row.type} />}</TableCell>
                    <TableCell>
                      {row.name}
                      {row.type_shower && " - "}
                      {row.type_shower && row.type_shower === "1" && "Leito"}
                      {row.type_shower && row.type_shower === "0" && "Aspersão"}
                      {row.is_pattern === "1" && (
                        <Badge className="ml-2" color={"primary"} pill>
                          Padrão
                        </Badge>
                      )}
                    </TableCell>
                    {row.type === "2" ? (
                      <TableCell colSpan="2">
                        {row.period.split(",").join(" e ")}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell>{row.times_a_day}</TableCell>
                        <TableCell>{row.interval}</TableCell>
                      </>
                    )}
                    <TableCell className={classes.cellButtons}>
                      <Tooltip title="Visualizar">
                        <IconButton
                          onClick={() => setOpenView(row)}
                          color={"primary"}
                          // disabled={row.is_pattern === "1"}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Editar">
                        <IconButton
                          onClick={() => setEdit(row)}
                          color={"primary"}
                          disabled={row.is_pattern === "1"}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Interromper">
                        <IconButton
                          onClick={() =>
                            setModalDelete([{ id: row.id, type: row.type }])
                          }
                          color={"secondary"}
                          disabled={row.is_pattern === "1"}
                        >
                          <BlockIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              //Sem Registros
              <TableRow>
                <TableCell colSpan={4}>
                  <NoRecord />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {/* Modal Aviso Delete */}
      <SweetAlert
        show={Boolean(modalDelete)}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Interromper a(s) prescrição(ões) deste(s) cuidado(s)?"
        onConfirm={() => {
          handleDelete(modalDelete);
          setModalDelete(null);
        }}
        onCancel={() => setModalDelete(null)}
      />
    </div>
  );
}
