import React, { useState, useEffect } from "react";
import { ModalResponsive } from "components/ModalResponsive";
import Api from "services/api";
import { BtnCan } from "components/Button";
import CircularProgress from "components/CircularProgress";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

export default function ViewRehabilitation({ data, open, handleClose }) {
  const [record, setRecord] = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (data.id) {
      setLoader(true);
      Api.post(`/microservice/fisio/getInfoRehabilitation/`, {
        id: data.id,
        schedule: data.schedule,
      })
        .then(({ data }) => {
          setRecord(data);
          setLoader(false);
        })
        .catch(() => {
          setRecord({});
          setLoader(false);
        });
    }
  }, [data]);

  const getDescriptionResult = (row) => {
    let str = "";
    if (row.realized === "1") {
      str = "Realizado ( " + row.satisfaction + " ) ";
    } else {
      str = "Não Realizado ( " + row.reason + " ) ";
    }
    if (row.ex_obs) {
      return str + " - " + row.ex_obs;
    } else {
      return str;
    }
  };

  const getInterval = (interval) => {
    switch (interval) {
      case "1":
        return "Diário";
      case "7":
        return "Semanal";
      case "15":
        return "Segunda a Sexta";
      case "16":
        return "Segunda a Sábado";
      case "30":
        return "Mensal";
      case "0":
        return "Dias Específicos";
    }
  };

  const getType = (type) => {
    if (type === "0") {
      return "Reabilitação Respiratória";
    } else if (type === "1") {
      return "Reabilitação Cardiorrespiratória";
    } else if (type === "2") {
      return "Reabilitação Motora";
    } else {
      return "Eletroterapia";
    }
  };

  return (
    <ModalResponsive
      open={open}
      close={handleClose}
      maxWidth="md"
      title="Visualizar Reabilitação"
      buttons={
        <div className="col-12">
          <BtnCan title="Fechar" onClick={() => handleClose()} float="left" />
        </div>
      }
    >
      {!loader ? (
        <div className="row">
          <div className="col-md-3 col-12">
            <h4 className="font-weight-semibold">Reabilitação</h4>
            <p> {data.name} </p>
          </div>

          <div className="col-md-3 col-12">
            <h4 className="font-weight-semibold">Tipo da Reabilitação</h4>
            <p> {getType(data.type)} </p>
          </div>

          <div className="col-md-3 col-12">
            <h4 className="font-weight-semibold">Número de sessôes</h4>
            <p> {data.number_sessions} </p>
          </div>

          <div className="col-md-3 col-12">
            <h4 className="font-weight-semibold">Frequência</h4>
            <p>{getInterval(data.interval)}</p>
          </div>
          {record.exercises && record.exercises.length > 0 && (
            <div className="col-12 mt-3">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Exercícios da Reabilitação</TableCell>
                    {data.type === "2" && <TableCell>Peso</TableCell>}
                    {data.type === "3" && <TableCell>Local</TableCell>}
                    <TableCell>N° de Séries</TableCell>
                    <TableCell>Repetições/Tempo</TableCell>
                    <TableCell>Orientações</TableCell>
                    {data.schedule && <TableCell>Resultado</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record.exercises.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      {data.type === "2" && (
                        <TableCell width="10%">{row.weight}</TableCell>
                      )}
                      {data.type === "3" && (
                        <TableCell width="10%">{row.place}</TableCell>
                      )}
                      <TableCell>{row.series}</TableCell>
                      <TableCell>{row.time}</TableCell>
                      <TableCell>{row.observation}</TableCell>
                      {data.schedule && (
                        <TableCell>{getDescriptionResult(row)}</TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}

          {record.schedules && record.schedules.length > 0 && (
            <div className="col-12 mt-3">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sessões</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record.schedules.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {moment(row.date).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                      <TableCell>
                        {row.fk_ls_pp_appointment && "Registrado"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      ) : (
        <div className="row">
          <CircularProgress />
        </div>
      )}
    </ModalResponsive>
  );
}
