import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { IconButton, Dialog, DialogContent } from "@material-ui/core";
import FormWorkplace from "../FormWorkplace";
import CircularProgress from "components/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";

import HealthPlan from "../HealthPlan";
import Agreement from "../Agreement";
import Contacts from "../Contacts";
import Room from "./clinic/Room";
import Specialty from "../Specialty";
import Doctors from "./clinic/Doctors";
import ResidentialProfessional from "./residential/ResidentialProfessional";
import Residents from "./residential/Residents";
import Courses from "./academic/Course";
import FormPrint from "../FormPrint";

function Workplace({ user, userAuth, type, provider_type }) {
  const [form, setForm] = useState("");
  const [modal_form, setModalForm] = useState(false);
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [workplace_selected, setWorkplaceSelected] = useState("");
  const workplaces = useSelector(({ workplace }) => workplace.records);
  const loaded = useSelector(({ workplace }) => workplace.loaded);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "GET_WORKPLACES_PROVIDER",
      payload: {
        user,
        provider_type,
      },
    });
  }, []);

  function deleteWorkplace() {
    Api.post(`/ms_system/workplace/deleteWorkplace/`, {
      id: workplace_selected,
      userAuth,
    }).then(() => {
      dispatch({
        type: "REMOVE_LIST_ADDRESS",
        payload: { id: workplace_selected },
      });
      dispatch({
        type: "REMOVE_WORKPLACE",
        payload: { id: workplace_selected },
      });

      setModalDelete(false);
      setSuccessDelete(true);
    });
  }

  function displayAddress(obj) {
    let display = `${obj.address} - ${obj.number}, `;
    if (obj.complement) {
      display += `Comp.: ${obj.complement}, `;
    }
    display += `${obj.neighborhood} - ${obj.postalcode}. ${obj.city}/${obj.state} - ${obj.country}`;
    return display;
  }

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <>
        {workplaces.length > 0 && (
          <>
            {workplaces.map(
              (row) =>
                row.active === "1" && (
                  <Card className="shadow border-0" key={row.id}>
                    <CardBody>
                      <div className="row">
                        {/* <div className="col-md-3 col-xs-4">
                          <img
                            src={row.maps}
                            alt="local de atendimento"
                            className="img-responsive"
                          />
                        </div> */}
                        <div className="col-md-12 col-xs-12">
                          <div className="row pl-1">
                            <div className="col-md-9">
                              <h3>{row.name}</h3>
                              <span>{displayAddress(row)}</span>
                            </div>
                            <div className="col-md-3 text-right">
                              <IconButton
                                onClick={() => {
                                  setWorkplaceSelected(row.id);
                                  setModalForm(true);
                                  setForm("address");
                                }}
                                color="primary"
                                aria-label="Editar"
                              >
                                <i className="zmdi zmdi-edit zmdi-hc-fw" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setWorkplaceSelected(row.id);
                                  setModalDelete(true);
                                }}
                                color="secondary"
                                aria-label="Remover"
                              >
                                <i className="zmdi zmdi-delete zmdi-hc-fw" />
                              </IconButton>
                            </div>
                          </div>
                          <div className="col-md-12">
                            {provider_type !== "53" && provider_type !== "56" && (
                              <>
                                <HealthPlan
                                  data={row.plans}
                                  addressId={row.id}
                                />
                                <Agreement
                                  data={row.agreements}
                                  addressId={row.id}
                                />
                                <Contacts
                                  data={row.contacts}
                                  addressId={row.id}
                                />
                              </>
                            )}
                            {["5", "53", "55"].indexOf(provider_type) ===
                              -1 && (
                              <>
                                <Specialty
                                  data={row.specialty}
                                  addressId={row.id}
                                  type={"1"}
                                />
                                <Room data={row.rooms} addressId={row.id} />
                                <Doctors
                                  user={user}
                                  data={row.professionals}
                                  addressId={row.id}
                                  addressType={row.address_type}
                                  providerType={provider_type}
                                />
                              </>
                            )}
                            {/* {provider_type === "5" && (
                              <>
                                <ResidentialProfessional
                                  data={row.professionals}
                                  addressId={row.id}
                                />
                                <Residents addressId={row.id} />
                              </>
                            )} */}
                            {provider_type === "53" && (
                              <>
                                <Courses
                                  user={user}
                                  data={row.specialty}
                                  addressId={row.id}
                                />
                              </>
                            )}
                            <FormPrint
                              data={row}
                              formPrint={row.formPrint}
                              addressId={row.id}
                              hiddenButton={true}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                )
            )}
          </>
        )}

        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={modal_form}
          onClose={() => {
            setModalForm(false);
          }}
          aria-labelledby="max-width-dialog-title"
          scroll="body"
        >
          <DialogContent>
            {form === "address" && (
              <FormWorkplace
                user={user}
                userAuth={userAuth}
                type={type}
                provider_type={provider_type}
                addressId={workplace_selected}
                close={() => setModalForm(false)}
              />
            )}
          </DialogContent>
        </Dialog>
        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, excluir"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Deseja realmente excluir este local de atendimento?"
          onConfirm={() => deleteWorkplace()}
          onCancel={() => setModalDelete(false)}
        />
        <SweetAlert
          show={success_delete}
          success
          title="Local de atendimento excluido com sucesso!"
          onConfirm={() => setSuccessDelete(false)}
        />
      </>
    );
  }
}

export default Workplace;
