import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import {
  getRecordSidenavConsult,
  changeConfigSidenavConsult
} from "actions/Configuration";
import Api from "services/api";
import { BtnSave, BtnCan } from "components/Button";
import { Tooltip, IconButton, Button } from "@material-ui/core";
import CircularProgress from "components/CircularProgress";
import FormContainer from "../SortableContainer";

export default function CustomSidenavConsult({ close }) {
  const permissions = useSelector(state => state.auth.permissionsConsult);

  const forms = [
    {
      id: "registerAttendance",
      description: "Registro do atendimento",
      checked: true,
      permission: true
    },
    {
      id: "personalData",
      description: "Dados pessoais",
      checked: true,
      permission: true
    },
    {
      id: "allergies",
      description: "Alergias",
      checked: true,
      permission: true
    },
    {
      id: "diseases",
      description: "Disfunções funcionais e outros",
      checked: true,
      permission: true
    },
    {
      id: "childhoodDiseases",
      description: "Doenças da infância",
      checked: true,
      permission: true
    },
    {
      id: "familyHistory",
      description: "Histórico familiar",
      checked: true,
      permission: true
    },
    { id: "habits", description: "Hábitos", checked: true, permission: true },
    {
      id: "medicines",
      description: "Medicamentos",
      checked: true,
      permission: true
    },
    {
      id: "patologies",
      description: "Patologias",
      checked: true,
      permission: true
    },
    {
      id: "procedures",
      description: "Procedimentos",
      checked: true,
      permission: true
    },
    {
      id: "fileCloud",
      description: "Arquivos na nuvem",
      checked: true,
      permission: true
    },
    {
      id: "aga",
      description: "AGA (Avaliação Geriátrica Ampla)",
      checked: true,
      permission: permissions.aga
    },
    {
      id: "circumferences",
      description: "Circunferências",
      checked: true,
      permission: permissions.circumferences
    },
    {
      id: "comparePicture",
      description: "Comparador de fotos",
      checked: true,
      permission: true
    },
    {
      id: "conduct",
      description: "Conduta",
      checked: true,
      permission: permissions.conduct
    },
    {
      id: "nurseDiagnostic",
      description: "Diagnóstico de Enfermagem",
      checked: true,
      permission: permissions.nurse_diagnostic
    },
    {
      id: "diagnosticIntegral",
      description: "Diagnóstico Integral",
      checked: true,
      permission: permissions.diagnostic_integral
    },
    {
      id: "folds",
      description: "Dobras",
      checked: true,
      permission: permissions.folds
    },
    {
      id: "forward",
      description: "Encaminhar paciente",
      checked: true,
      permission: true
    },
    {
      id: "painScale",
      description: "Escala de dor",
      checked: true,
      permission: permissions.pain_scale
    },
    {
      id: "exams",
      description: "Exames",
      checked: true,
      permission: permissions.exam
    },
    {
      id: "plateIndex",
      description: "Índice de Placa",
      checked: true,
      permission: permissions.plate_index
    },
    {
      id: "prescriptionCare",
      description: "Prescrição de cuidados",
      checked: true,
      permission: permissions.prescription_care
    },
    {
      id: "prescription",
      description: "Prescrição industrializada",
      checked: true,
      permission: permissions.prescription
    },
    {
      id: "manipulated",
      description: "Prescrição manipulada",
      checked: true,
      permission: permissions.prescription_manipuled
    },
    {
      id: "periodontogram",
      description: "Periodontograma",
      checked: true,
      permission: permissions.periodontogram
    },
    {
      id: "nursePrescription",
      description: "Periodontograma",
      checked: true,
      permission: permissions.nursePrescription
    },
    {
      id: "systemReview",
      description: "Revisão dos sistemas",
      checked: true,
      permission: permissions.system_review
    },
    {
      id: "sae",
      description: "SAE - Idoso",
      checked: true,
      permission: permissions.sae
    },
    {
      id: "prescriptionTranscription",
      description: "Transcrever Prescrição",
      checked: true,
      permission: permissions.prescription_transcription
    },
    { id: "vaccines", description: "Vacinas", checked: true, permission: true }
  ];

  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [list, setList] = useState(forms.filter(row => row.permission));
  const [loaded, setLoaded] = useState(false);
  const user = useSelector(state => state.auth.authUser);
  const config_sidenav_consult = useSelector(
    state => state.configuration.config_sidenav_consult
  );

  useEffect(() => {
    if (!config_sidenav_consult.loaded) {
      dispatch(getRecordSidenavConsult(user));
    }
  }, []);

  useEffect(() => {
    if (config_sidenav_consult.loaded) {
      setLoaded(true);
      if (config_sidenav_consult.record.length > 0) {
        let ids = config_sidenav_consult.record[0].view.split(",");
        let aux = forms
          .filter(row => row.permission)
          .map(row =>
            ids.indexOf(row.id) === -1 ? { ...row, checked: false } : row
          );
        setList(aux);
        setId(config_sidenav_consult.record[0].id);
      }
    }
  }, [config_sidenav_consult]);

  const handleCheck = (cat, value) => {
    setList(
      list.map(row => (row.id === cat ? { ...row, checked: value } : row))
    );
  };

  const resetConfig = () => {
    setList(forms.filter(row => row.permission));
  };

  const handleSave = () => {
    let marked = list.filter(row => row.checked);
    let ids = marked.map(row => row.id);

    Api.post(`/ms_configuration/custom/saveSidenavConsult/`, {
      user,
      id,
      forms: ids.join(",")
    })
      .then(res => {
        if (!id) {
          setId(res.data.id);
        }
        dispatch(changeConfigSidenavConsult(res.data.id, ids.join(",")));
        close();
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage(), 5000));
      });
  };

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        <h2 className="text-primary font-weight-semibold w-100">
          Customização ferramentas consultório
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
        <div className="col-12">
          <Button
            color="primary"
            className="jr-btn jr-btn-md"
            onClick={resetConfig}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-settings zmdi-hc-lg" />
            <span> Resetar configurações </span>
          </Button>
        </div>
        <div className="col-12">
          <div className="animated slideInUpTiny animation-duration-3">
            <FormContainer
              forms={list}
              handleCheck={handleCheck}
              useDragHandle={false}
            />
          </div>
        </div>
        <div className="col-12 mt-3">
          <BtnCan title="Fechar" onClick={close} float="left" />
          <BtnSave title="Salvar" onClick={handleSave} float="right" />
        </div>
      </div>
    );
  }
}
