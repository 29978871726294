import { all, takeLatest, put, call } from "redux-saga/effects";
import Api from "services/api";

const getWorkplaces = async (user) => {
  try {
    const response = await Api.post(`/ms_system/workplace/getWorkplacesUser/`, {
      user,
    });
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetWorkplaces(action) {
  try {
    const response = yield call(getWorkplaces, action.user);
    yield put({
      type: "GET_WORKPLACES_SUCCESS",
      payload: {
        record: response === null ? [] : response,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

const getWorkplacesProvider = async (user, type) => {
  try {
    const response = await Api.post(
      `/ms_system/workplace/getWorkplacesProvider/`,
      {
        user,
        type,
      }
    );
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetWorkplacesProvider(action) {
  try {
    const response = yield call(
      getWorkplacesProvider,
      action.payload.user,
      action.payload.provider_type
    );
    yield put({
      type: "GET_WORKPLACES_SUCCESS",
      payload: {
        record: response === null ? [] : response,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([takeLatest("GET_WORKPLACES", asyncGetWorkplaces)]);
  yield all([
    takeLatest("GET_WORKPLACES_PROVIDER", asyncGetWorkplacesProvider),
  ]);
}
