import axios from "axios";
import { getBaseUrl } from "services/functions";

const ApiLogin = axios.create({
  baseURL: getBaseUrl(),
  dataType: "json",
  headers: {
    Accept: "application/x-www-form-urlencoded",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export default ApiLogin;
