import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as patientInfoActions from "actions/consult/PatientInfo";
import * as alertActions from "actions/Alerts";
import { viewHistory } from "actions/consult/Appointment";
import { changeConfigAdmission, setListAdmission } from "actions/Configuration";
import { NavLink, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import HistoryPatient from "components/HistoryPatient";
import SweetAlert from "react-bootstrap-sweetalert";
import AlertMessage from "components/Alert/AlertMessage";
import HistoryIcon from "@material-ui/icons/History";
import { Chip, Tooltip, Button, Avatar } from "@material-ui/core";
import PauseCircleOutline from "@material-ui/icons/PauseCircleOutline";
import IconBlock from "@material-ui/icons/Block";
import Api from "services/api";
import { ADMISSION_ARRAY_LIST } from "constants/CustomAdmissionForm";
import "./styles.css";
import Tags from "../Tags";

class Admission extends Component {
  state = {
    modal_finish: false,
    modal_stop: false,
    success_finish: false,
    fieldsToFill: "",
    list_admission: "",
  };

  componentDidMount() {
    this.props.getInfoPatient(this.props.patient);
    this.getAdmission();

    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}${history.location.search}`; // get current path
    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentDidUpdate() {
    const { history } = this.props;

    const pathname = `${history.location.pathname}${history.location.search}`; // get current path
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  getAdmission = () => {
    Api.post("/ms_configuration/custom/getAdmission", {
      authId:
        this.props.managers && this.props.managers.length > 0
          ? this.props.managers[0].provider
          : this.props.authId,
    }).then((res) => {
      if (res.data.record) {
        this.props.setListAdmission(res.data.record);
        this.props.changeConfigAdmission({
          id: res.data.record.id,
          view: res.data.record.view,
          view_sub_group: res.data.record.view_sub_group,
        });
      }
      // else {
      //   this.props.setListAdmission({
      //     id: null,
      //     view: null,
      //     view_sub_group: null,
      //   });
      // }
    });
  };

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  handleStop = () => {
    this.props.history.push("/app/choose/data-admission");
  };

  handleFinish = () => {
    Api.post("microservice/admission/finishAdmission", {
      admission: this.props.admission,
    }).then((res) => {
      if (res.data.success) {
        this.setState({ success_finish: true });
      } else {
        this.setState({ modal_finish: false });
        this.props.viewAlertMessage("error", "Ocorreu um erro ao salvar!");
        setTimeout(() => this.props.closeAlertMessage(), 5000);
      }
    });
  };

  handleValidateFields = () => {
    Api.post("microservice/admission/getValidateFields", {
      patient: this.props.patientInfo.patient.id,
      address: this.props.address.id,
    }).then((res) => {
      if (res.data.success) {
        this.setState({ fieldsToFill: res.data.record });
      }
      this.setState({ modal_finish: true });
    });
  };

  render() {
    const { start, admission } = this.props;
    const {
      modal_finish,
      modal_stop,
      success_finish,
      fieldsToFill,
    } = this.state;
    const { patient } = this.props.patientInfo;
    const pathname = window.location.pathname;

    return (
      <CustomScrollbars className="scrollbar">
        <ul className="nav-menu" style={{ marginTop: "10px" }}>
          <h6 className="title-patient">Paciente</h6>
          <div className="content-patient">
            <Avatar
              alt={patient.name}
              src={patient.photo}
              className="avatar-position"
            />
            <div className="jr-card-hd-content ml-2">
              <Tooltip title={`Nº Tel.: ${patient.smartphone}`}>
                <h5 id="name-patient" className="mb-0 text-white">
                  {patient.name}
                </h5>
              </Tooltip>
              <p className="jr-fs-xs mb-0 mt-1 text-grey text-lighten-2">
                {patient.birthday_br}
              </p>
            </div>
          </div>
          <div className="div-btn-history">
            <Chip
              className="w-100"
              size="small"
              color="primary"
              onClick={() => this.props.viewHistory("personalData")}
              icon={<HistoryIcon />}
              label="Histórico"
            />
          </div>
          <div className="row">
            <Tags type="admission" />
          </div>
          <hr className="hr-divider" />
          <div className="text-center">
            {!admission ? (
              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                <Chip
                  className="w-100"
                  size="small"
                  color="secondary"
                  onClick={() =>
                    this.props.history.push("/app/choose/data-admission")
                  }
                  icon={<IconBlock />}
                  label="Alterar paciente"
                />
              </div>
            ) : (
              <div className="row">
                <div
                  className="col-6"
                  style={{ paddingLeft: "25px", paddingRight: "5px" }}
                >
                  <Chip
                    className="w-100"
                    size="small"
                    color="primary"
                    onClick={() => this.setState({ modal_stop: true })}
                    icon={<PauseCircleOutline />}
                    label="Pausar"
                  />
                </div>

                <div
                  className="col-6"
                  style={{ paddingLeft: "5px", paddingRight: "25px" }}
                >
                  <Chip
                    className="w-100"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      this.handleValidateFields();
                    }}
                    icon={<IconBlock />}
                    label="Finalizar"
                  />
                </div>
              </div>
            )}
          </div>
          <hr className="hr-divider" />
          {/* FERRAMENTAS */}

          {pathname === "/app/admission/report" ? (
            <li className="menu no-arrow mt-3">
              <NavLink to="/app/admission/report">
                <span className="nav-text">
                  <IntlMessages id="module.admission.report" />
                </span>
              </NavLink>
            </li>
          ) : (
            <>
              {this.props.list_admission && this.props.list_admission.view
                ? this.props.list_admission.view.split(",").map((row) => {
                    let menuItem = ADMISSION_ARRAY_LIST.find(
                      (el) => el.id === row
                    );
                    return (
                      <li className="menu no-arrow mt-3" key={menuItem.id}>
                        {menuItem.sub_group ? (
                          <Button>
                            <span className="nav-text">
                              {menuItem.description}
                            </span>
                          </Button>
                        ) : (
                          <NavLink to={`/app/admission/${menuItem.link}`}>
                            <span className="nav-text">
                              {menuItem.description}
                            </span>
                          </NavLink>
                        )}
                        {menuItem.sub_group &&
                          this.props.list_admission.view_sub_group &&
                          this.props.list_admission.view_sub_group
                            .split(",")
                            .map((ele) => {
                              let menuItemSub = menuItem.sub_group.find(
                                (e) => e.id === ele
                              );
                              if (menuItemSub) {
                                return (
                                  <ul className="sub-menu">
                                    <li>
                                      <NavLink
                                        to={`/app/admission/${menuItemSub.link}`}
                                      >
                                        <span className="nav-text">
                                          {menuItemSub.description}
                                        </span>
                                      </NavLink>
                                    </li>
                                  </ul>
                                );
                              }
                            })}
                      </li>
                    );
                  })
                : ADMISSION_ARRAY_LIST.map((row) => (
                    <li className="menu no-arrow mt-3" key={row.id}>
                      {row.sub_group ? (
                        <Button>
                          <span className="nav-text">{row.description}</span>
                        </Button>
                      ) : (
                        <NavLink to={`/app/admission/${row.link}`}>
                          <span className="nav-text"> {row.description}</span>
                        </NavLink>
                      )}
                      {row.sub_group &&
                        row.sub_group.map((ele) => (
                          <ul className="sub-menu">
                            <li>
                              <NavLink to={`/app/admission/${ele.link}`}>
                                <span className="nav-text">
                                  {ele.description}
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        ))}
                    </li>
                  ))}
            </>
          )}

          <li className="menu no-arrow mt-3">
            <NavLink to="/app/dashboard">
              <i className="zmdi zmdi-undo zmdi-hc-fw" />
              <span className="nav-text">Voltar ao dashboard</span>
            </NavLink>
          </li>

          {/* <li className="menu no-arrow mt-3">
            <NavLink to="/app/admission/data-patient">
              <span className="nav-text">
                <IntlMessages id="module.admission.data_patient" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow mt-3">
            <NavLink to="/app/admission/responsible">
              <span className="nav-text">
                <IntlMessages id="module.admission.responsible" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow mt-3">
            <NavLink to="/app/admission/reason">
              <span className="nav-text">
                <IntlMessages id="module.admission.reason" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow mt-3">
            <NavLink to="/app/admission/avaliation">
              <span className="nav-text">
                <IntlMessages id="module.admission.avaliation" />
              </span>
            </NavLink>
          </li>

          <li className="menu mt-3">
            <Button>
              <span className="nav-text">Revisão de sistemas</span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink to="/app/admission/system_review/sense_organ">
                  <span className="nav-text"> Órgão dos Sentidos</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/admission/system_review/cardio_system">
                  <span className="nav-text"> Cardiovascular</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/admission/system_review/respiratory_system">
                  <span className="nav-text"> Respiratório </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/admission/system_review/digestive_system">
                  <span className="nav-text"> Aparelho Digestivo</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/admission/system_review/urinary_system">
                  <span className="nav-text"> Aparelho Gênito-Urinário</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/admission/system_review/musculoskeletal">
                  <span className="nav-text"> Osteomuscular</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/admission/system_review/cutaneous_system">
                  <span className="nav-text"> Cutâneo</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/admission/system_review/nervous_system">
                  <span className="nav-text"> Nervoso</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu no-arrow mt-3">
            <NavLink to="/app/admission/functional-avaliation">
              <span className="nav-text">
                <IntlMessages id="module.admission.functional_avaliation" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow mt-3">
            <NavLink to="/app/admission/personal-history">
              <span className="nav-text">
                <IntlMessages id="module.admission.personal_history" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow mt-3">
            <NavLink to="/app/admission/family-history">
              <span className="nav-text">
                <IntlMessages id="module.admission.history_family" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow mt-3">
            <NavLink to="/app/admission/psychic-avaliation">
              <span className="nav-text">
                <IntlMessages id="module.admission.psychic_avaliation" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow mt-3">
            <NavLink to="/app/admission/diagnostic-conclusion">
              <span className="nav-text">
                <IntlMessages id="module.admission.diagnostic_conclusion" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow mt-3">
            <NavLink to="/app/admission/report">
              <span className="nav-text">
                <IntlMessages id="module.admission.report" />
              </span>
            </NavLink>
          </li>*/}
        </ul>

        {/* fim  */}

        <HistoryPatient id_patient={patient.id} />
        <SweetAlert
          show={modal_finish}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, finalizar"
          confirmButtonColor="#bc0003"
          cancelBtnText="Não"
          title="Finalizar esta admissão?"
          onConfirm={this.handleFinish}
          onCancel={() => this.setState({ modal_finish: false })}
        >
          {fieldsToFill ? "Campos não preenchidos: " + fieldsToFill : ""}
        </SweetAlert>

        <SweetAlert
          show={success_finish}
          success
          title="Admissão finalizada com sucesso!"
          onConfirm={() =>
            this.props.history.push("/app/choose/data-admission")
          }
        />
        <SweetAlert
          show={modal_stop}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText={"Sim, pausar"}
          confirmButtonColor="#bc0003"
          cancelBtnText="Não"
          title={"Pausar esta admissão?"}
          onConfirm={this.handleStop}
          onCancel={() => this.setState({ modal_stop: false })}
        />
        <AlertMessage
          open={this.props.alert.alert_message}
          message={this.props.alert.text_message}
          type={this.props.alert.message_type}
        />
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patient: state.admission.patient,
    modal_finish: state.appointment.modal_finish,
    modal_pause: state.appointment.modal_pause,
    finish: state.appointment.finish,
    start: state.appointment.start,
    admission: state.admission.id,
    patientInfo: state.patientInfo,
    alert: state.alert,
    address: state.auth.address_selected,
    authId: state.auth.authUser,
    customAdmission: state.configuration.config_admission,
    list_admission: state.configuration.list_admission,
    managers: state.auth.managers,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...patientInfoActions,
      ...alertActions,
      viewHistory,
      changeConfigAdmission,
      setListAdmission,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Admission)
);
