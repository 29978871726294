import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import { userSignInSuccess } from "actions/Auth";
// import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
// import { getDataFirebase, getEnviromentFirebase } from "services/firebase";

import api from "services/api";
// import apiFile from "services/apiFile";
// import IntlMessages from "util/IntlMessages";
// import ContainerHeader from "components/ContainerHeader";
import { Card, CardBody } from "reactstrap";
import { IconButton, Tooltip, MenuItem } from "@material-ui/core";
// import CircularProgress from "components/CircularProgress";

// import moment from "moment";
import PersonIcon from "@material-ui/icons/Person";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

import Login from "components/Forms/LoginNotification";
import RegisterSimple from "containers/Register/CompleteRegisterSimple";
// import Register from "containers/Register/CompleteRegister";
import RegisterProviderSimple from "containers/Register/RegisterProviderSimple";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tutorial from "components/Header/Tutorial";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#177493 !important",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline !important",
    },
  },
  text: {
    color: "#0000008a",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.3125rem",
    marginBottom: "0px",
    textAlign: "center",
  },
}));

export default function SchedulerStepper({ match, history, type }) {
  const dispatch = useDispatch();
  const windowWidth = window.innerWidth;

  const classes = useStyles();
  const [mode, setMode] = useState(0);
  const [modalTutorial, setModalTutorial] = useState(false);

  const handleSuccess = (success, id) => {
    if (success) {
      dispatch(userSignInSuccess(id, "", false));
      if (type === "vaccine") {
        history.push("/app/vaccine-child");
      } else {
        history.push("/app/form-notification-covid");
      }
    }
  };

  const getContent = () => {
    switch (mode) {
      case 0:
        return (
          <Login
            noShadow={true}
            noRender={true}
            handleSuccess={handleSuccess}
          />
        );
      case 1:
        return (
          <div className="row">
            <div className="col-12">
              <h3 className="font-weight-semibold">
                Informe o tipo do notificante
              </h3>
            </div>
            <div className="col-12">
              <MenuItem key="0" onClick={() => setMode(2)}>
                <PersonIcon /> <span className="ml-2">Pessoa Física</span>
              </MenuItem>
              <MenuItem key="1" onClick={() => setMode(3)} className="mt-2">
                <BusinessCenterIcon />{" "}
                <span className="ml-2">Pessoa Jurídica</span>
              </MenuItem>
            </div>
          </div>
        );
      case 2:
        return <RegisterSimple beforeRegister={handleSuccess} modal={true} />;
      case 3:
        return (
          <RegisterProviderSimple
            beforeRegister={handleSuccess}
            modal={true}
            notification={true}
          />
        );
    }
  };

  return (
    <div
      className={
        mode === 0
          ? windowWidth < 700
            ? "row login-not-mobile"
            : "row login-not"
          : "row m-1 w-100"
      }
      style={{
        height: "100%",
        background: "#d8ebf1",
        overflowY: "auto",
      }}
    >
      <div
        className={
          mode < 2
            ? "col-md-5 col-xs-12 d-flex justify-content-end align-items-center"
            : "col-md-12 col-xs-12 d-flex justify-content-center align-items-center"
        }
      >
        {/* <div className="login-header mb-3 col-12 mt-3"> */}
        <div
          className=""
          style={
            windowWidth < 700
              ? {
                  display: "flex",
                  marginTop: "10px",
                  marginBottom: "10px",
                  justifyContent: "space-evenly",
                }
              : {
                  display: mode < 2 ? "grid" : "flex",
                }
          }
        >
          <img
            style={
              windowWidth < 700
                ? { height: "40px", margin: "6px" }
                : {
                    height: "50px",
                    marginBottom: mode < 2 ? "10%" : "0%",
                    margin: mode < 2 ? "0px" : "9px",
                  }
            }
            src={require("assets/images/logoCaboVerde/logo-cabo-verde.png")}
            alt="salutem+"
            title="salutem+"
          />
          <img
            style={
              windowWidth < 700
                ? { height: "40px" }
                : { height: "50px", marginTop: "25px", marginLeft: "10px" }
            }
            src={require("assets/images/Logo_Salutem-Plus.png")}
            alt="salutem+"
            title="salutem+"
          />
        </div>
      </div>
      {mode > 1 && windowWidth > 700 && <div className="col-md-2" />}
      <div
        className={mode < 2 ? "col-md-3 col-xs-12" : "col-md-8 col-xs-12"}
        style={{
          display: windowWidth < 700 ? "block" : "table",
          alignSelf: "center",
        }}
      >
        <Card className="shadow border-0 mb-2">
          <CardBody>{getContent()}</CardBody>
        </Card>
        <Card className="shadow border-0">
          <CardBody>
            <div className="text-center">
              {mode === 0 ? (
                <p className={classes.text}>
                  Não possui conta?{" "}
                  <a className={classes.link} onClick={() => setMode(1)}>
                    Cadastre-se
                  </a>
                </p>
              ) : (
                <>
                  <p className={classes.text}>
                    Voltar para{" "}
                    <a className={classes.link} onClick={() => setMode(0)}>
                      login
                    </a>
                    <Tooltip
                      title="Tutorial"
                      style={{ display: "contents", justifyContent: "end" }}
                    >
                      <IconButton onClick={() => setModalTutorial(true)}>
                        <HelpOutlineIcon
                          style={{
                            color: "#074c5e",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </p>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="col-md-5 col-xs-12"></div>
      {modalTutorial && (
        <Tutorial
          open={modalTutorial}
          onCLose={() => setModalTutorial(false)}
          mode="register"
        />
      )}
    </div>
  );
}
