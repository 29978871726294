const INITIAL_STATE = {
  active: "view",
  color: "primary",
  text: "Adicionar",
  icon: "plus",
  hidden: "hidden",
  loader: true,
};

export default function procedure(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_LIST_PROCEDURE":
      return {
        ...state,
        rows: action.payload.list,
      };
    case "REQUEST_CAD_PROCEDURE":
      return {
        ...state,
        loader: true,
      };
    case "REQUEST_CAD_PROCEDURE_SUCCESS":
      return {
        ...state,
        loader: false,
        cadList: action.payload.cadList,
      };
    case "ADD_PROCEDURE_SUCCESS":
      return {
        ...state,
        success: action.payload.success,
      };
    case "CHANGE_BUTTON_PROCEDURE":
      return {
        ...state,
        color: action.color,
        text: action.text,
        active: action.active,
        icon: action.icon,
      };
    case "ADD_NEW_DISEASE_TO_P":
      let idsRows = state.rows.map((el) => el.id);
      let newRows = [];
      action.rows.forEach((element) => {
        if (idsRows.indexOf(element.id) === -1) {
          newRows.push(element);
        }
      });
      let idsCad = state.cadList.map((el) => el.fk_ls_procedure_personal_list);
      let newCads = [];
      action.cadList.forEach((element) => {
        if (idsCad.indexOf(element.fk_ls_procedure_personal_list) === -1) {
          newCads.push(element);
        }
      });
      return {
        ...state,
        rows: [...state.rows, ...newRows],
        cadList: [...state.cadList, ...newCads],
      };
    case "HANDLE_VALIDATE_PROCEDURE":
      return {
        ...state,
        cadList: state.cadList.map((row) =>
          row.id_procedure_history === action.id
            ? { ...row, approved_by: action.user }
            : row
        ),
      };
    case "RESET_PROCEDURE":
      return INITIAL_STATE;
    default:
      return state;
  }
}
