import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { IconButton, Dialog, DialogContent } from "@material-ui/core";

import FormWorkplace from "../FormWorkplace";
import ViewWorkplace from "./ViewWorkplace";
import CircularProgress from "components/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";

import HealthPlan from "../HealthPlan";
import Agreement from "../Agreement";
import Contacts from "../Contacts";
import FormPrint from "../FormPrint";
import Calendar from "./Calendar";
import SpecialtyCalendar from "./SpecialtyCalendar";
import Room from "../provider/clinic/Room";
import Specialty from "../Specialty";
import Doctors from "../provider/clinic/Doctors";
import "./style.css";

function Workplace({ user, userAuth, type, provider_type }) {
  const [form, setForm] = useState(true);
  const [modal_form, setModalForm] = useState(false);
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [workplace_selected, setWorkplaceSelected] = useState("");
  const workplaces = useSelector(({ workplace }) => workplace.records);
  const loaded = useSelector(({ workplace }) => workplace.loaded);
  const dispatch = useDispatch();
  // const state = useSelector(state => state);

  useEffect(() => {
    dispatch({
      type: "GET_WORKPLACES",
      user,
    });
  }, []);

  function deleteWorkplace() {
    Api.post(`/ms_system/workplace/deleteWorkplace/`, {
      id: workplace_selected,
      userAuth,
    }).then(() => {
      dispatch({
        type: "REMOVE_LIST_ADDRESS",
        payload: { id: workplace_selected },
      });
      dispatch({
        type: "REMOVE_WORKPLACE",
        payload: { id: workplace_selected },
      });

      setModalDelete(false);
      setSuccessDelete(true);
    });
  }

  function displayAddress(obj) {
    let display = `${obj.address} - ${obj.number}, `;
    if (obj.complement) {
      display += `Comp.: ${obj.complement}, `;
    }
    display += `${obj.neighborhood} - ${obj.postalcode}. ${obj.city}/${obj.state} - ${obj.country}`;
    return display;
  }

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <>
        {workplaces &&
          workplaces.length > 0 &&
          workplaces.map(
            (row) =>
              row.active === "1" && (
                <Card className="shadow border-0" key={row.id}>
                  <CardBody>
                    <div className="row">
                      {/* <div className="col-md-3 styleImg">
                        <img
                          src={row.maps}
                          className="img-responsive"
                          alt="local de atendimento"
                        />
                      </div> */}
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-9">
                            <h3 className="textNameLocal">{row.name}</h3>
                            <span>{displayAddress(row)}</span>
                          </div>
                          <div
                            className="col-md-3 text-right"
                            hidden={row.assoc === undefined ? false : true}
                          >
                            <IconButton
                              onClick={() => {
                                setWorkplaceSelected(row.id);
                                setModalForm(true);
                                setForm(true);
                              }}
                              color="primary"
                              aria-label="Editar"
                            >
                              <i className="zmdi zmdi-edit zmdi-hc-fw" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setWorkplaceSelected(row.id);
                                setModalDelete(true);
                              }}
                              color="secondary"
                              aria-label="Remover"
                            >
                              <i className="zmdi zmdi-delete zmdi-hc-fw" />
                            </IconButton>
                          </div>
                        </div>
                        <div className="col-md-12 m-0 p-0">
                          <HealthPlan
                            data={row.plans}
                            addressId={row.id}
                            hiddenButton={
                              row.assoc === undefined ? false : true
                            }
                          />
                          <Agreement
                            data={row.agreements}
                            addressId={row.id}
                            hiddenButton={
                              row.assoc === undefined ? false : true
                            }
                          />
                          <Contacts
                            data={row.contacts}
                            addressId={row.id}
                            hiddenButton={
                              row.assoc === undefined ? false : true
                            }
                          />
                          {row.provider_type !== "5" && (
                            <Specialty
                              data={row.specialty}
                              addressId={row.id}
                              hiddenButton={row.assoc === "1"}
                              type={"0"}
                            />
                          )}
                          {row.assoc === "1" ? (
                            <>
                              {row.provider_type !== "5" && (
                                <>
                                  <Room
                                    data={row.rooms}
                                    addressId={row.id}
                                    hiddenButton={true}
                                  />
                                  <Doctors
                                    data={row.professionals}
                                    addressId={row.id}
                                    hiddenButton={true}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {row.provider_type !== "5" && (
                                <>
                                  <Calendar
                                    data={row.office_hour}
                                    addressId={row.id}
                                    hiddenButton={
                                      row.assoc === undefined ? false : true
                                    }
                                  />
                                  <SpecialtyCalendar
                                    user={user}
                                    nameAddress={row.name}
                                    typesEventData={row.typesEvent}
                                    data={row.calendar}
                                    addressId={row.id}
                                    addressType={row.address_type}
                                    hiddenButton={
                                      row.assoc === undefined ? false : true
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}
                          {row.formPrint ? (
                            <FormPrint
                              data={row}
                              formPrint={row.formPrint}
                              addressId={row.id}
                              hiddenButton={true}
                              assoc={false}
                            />
                          ) : (
                            <FormPrint
                              data={row}
                              formPrint={row.formPrintAssoc}
                              addressId={row.id}
                              hiddenButton={true}
                              assoc={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )
          )}

        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={modal_form}
          onClose={() => {
            setModalForm(false);
          }}
          aria-labelledby="max-width-dialog-title"
          scroll="body"
        >
          <DialogContent>
            {form ? (
              <FormWorkplace
                user={user}
                userAuth={userAuth}
                type={type}
                provider_type={provider_type}
                addressId={workplace_selected}
                close={() => setModalForm(false)}
              />
            ) : (
              <ViewWorkplace
                addressId={workplace_selected}
                close={() => setModalForm(false)}
              />
            )}
          </DialogContent>
        </Dialog>
        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, excluir"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Deseja realmente excluir este local de atendimento?"
          onConfirm={() => deleteWorkplace()}
          onCancel={() => setModalDelete(false)}
        />
        <SweetAlert
          show={success_delete}
          success
          title="Local de atendimento excluido com sucesso!"
          onConfirm={() => setSuccessDelete(false)}
        />
      </>
    );
  }
}

export default Workplace;
