import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  saveConditions,
  setInitialConditions
} from "actions/configuration/TagsConditions";

import { TextField, InputLabel, Divider, Switch } from "@material-ui/core";
import { CirclePicker } from "react-color";
import { Btn, BtnCan } from "components/Button";
import TransferList from "./TransferList";

class FormTags extends Component {
  state = {
    error: false,
    id: null,
    name: "",
    desc: "",
    color: "#f44336",
    condicional: "",
    query: "",
    is_automatic: true
  };
  handleChangeName = e => {
    this.setState({
      name: e.target.value,
      error: e.target.value === "" ? true : false
    });
  };

  handleChangeDesc = e => {
    this.setState({ desc: e.target.value });
  };

  handleChangePicker = (color, event) => {
    this.setState({ color: color.hex });
  };

  handleChangeSelect = e => {
    this.setState({ condicional: e.target.value });
  };

  handleSave = () => {
    if (this.state.name !== "") {
      this.props.saveConditions({
        state: this.state,
        reducer: this.props.tags_conditions,
        address: this.props.address,
        doctor: this.props.doctor,
        patient: this.props.patient
      });
      this.props.handleChangeButton();
    } else {
      this.setState({ error: true });
      document.getElementById("tag-name").focus();
    }
  };
  componentDidMount() {
    if (this.props.tags_conditions.edit_tag) {
      let { edit_tag } = this.props.tags_conditions;
      this.setState({
        id: edit_tag.id,
        name: edit_tag.name,
        desc: edit_tag.description,
        color: edit_tag.color,
        query: edit_tag.query
      });
    }
  }
  handleChangeSwitch = e => {
    this.setState({ is_automatic: e.target.checked });
  };
  componentWillUnmount() {
    this.props.setInitialConditions();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      is_automatic:
        nextProps.tags_conditions.programming_on_register === "1" ? true : false
    });
  }
  render() {
    const { name, desc, color, error } = this.state;
    return (
      <div className="container">
        <div className="row mb-2">
          <div className="col-md-8">
            <TextField
              id="tag-name"
              error={error}
              autoFocus
              fullWidth
              value={name}
              label="Digite o nome da tag"
              onChange={this.handleChangeName}
            />
            <TextField
              fullWidth
              multiline
              value={desc}
              rows="2"
              label="Digite a descrição"
              onChange={this.handleChangeDesc}
              style={{ marginTop: "15px" }}
            />
          </div>
          <div className="col-md-4">
            <InputLabel style={{ marginBottom: "15px" }}>
              Selecione a cor desejada
            </InputLabel>
            <CirclePicker
              onChangeComplete={this.handleChangePicker.bind(this)}
              color={color}
            />
          </div>
        </div>
        <div className="row">
          <h4 className="card-title text-primary" style={{ marginTop: "20px" }}>
            Condições <i className="zmdi zmdi-code-setting" />
          </h4>
          <Divider />
        </div>
        <div className="row">
          <InputLabel style={{ marginTop: "10px" }}>
            Aplicar durante o cadastro?
          </InputLabel>
          <Switch
            checked={this.state.is_automatic}
            onChange={this.handleChangeSwitch}
            color="primary"
            aria-label="checked"
          />
        </div>
        <div className="row">
          <TransferList />
        </div>
        <div className="col-md-12" style={{ marginTop: "30px" }}>
          <BtnCan
            onClick={this.props.handleChangeButton}
            title="cancelar"
            float="left"
          />

          <Btn
            onClick={this.handleSave}
            title="aplicar"
            icon="zmdi zmdi-check zmdi-hc-lg"
            name="Aplicar"
            float="right"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tags_conditions: state.tagsConditions,
  address: state.auth.address,
  doctor: state.auth.authUser
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveConditions,
      setInitialConditions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormTags);
