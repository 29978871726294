import React from "react";
import { useDispatch } from "react-redux";
import { Tooltip, IconButton } from "@material-ui/core";
import ComponentsProfile from "components/ComponentsProfile";
import DataTable from "components/Tables/DataTable";

export default function ListDoctor({ record, viewProfessional }) {
  return (
    <DataTable
      name="Profissionais em Atendimento"
      columns={["Profissional", "Área"]}
      rows={record.map((row, index) => [
        <Tooltip title="Visualizar Informações do Profissional">
          <div
            onClick={() =>
              viewProfessional({
                id: row.doctor,
                name: row.doctor_name,
                photo: row.photo,
                area: row.area,
              })
            }
            style={{ display: "-webkit-inline-box", cursor: "pointer" }}
          >
            <ComponentsProfile
              nameProfile={row.doctor_name}
              photo={row.photo}
              id={row.doctor}
            />
            <IconButton style={{ padding: "6px" }}>
              <i className="zmdi zmdi-sign-in zmdi-hc-fw text-primary" />
            </IconButton>
          </div>
        </Tooltip>,
        row.area,
      ])}
      hideSearch={true}
    />
  );
}
