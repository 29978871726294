export function viewAlertMessage(message_type, text_message) {
  return {
    type: "VIEW_ALERT_MESSAGE",
    message_type,
    text_message,
  };
}

export function closeAlertMessage() {
  return {
    type: "CLOSE_ALERT_MESSAGE",
  };
}

export const wrongBrowser = (payload) => {
  return {
    type: "WRONG_BROWSER",
    payload,
  };
};
