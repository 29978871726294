import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { handleChange } from "actions/configuration/Profile";
import * as alertActions from "actions/Alerts";
import Api from "services/api";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MaskCpf from "components/Masks/MaskCpf";
import MaskDate from "components/Masks/MaskDate";
// import Api from "services/api";
import AutocompleteParents from "../autocomplete/Parents";


class AdditionalData extends Component {
  handleChangeAutocomplete = () => (event) => {
    this.props.handleChange(event.index, event.value);
  };

  handleChangeAdditional = (name) => (event) => {
    this.props.handleChange(name, event.target.value);
  };

  handleChangeStatusCivil = () => (event) => {
    let value = event.target.value;
    if (value === "Divorciado" || value === "Separado") {
      this.props.handleChange("mariage_regime", "");
      this.props.handleChange("wife_id", "");
      this.props.handleChange("wife_name", "");
    } else if (value === "Casado") {
      this.props.handleChange("end_mariage", "");
    } else {
      this.props.handleChange("mariage_regime", "");
      this.props.handleChange("end_mariage", "");
      this.props.handleChange("wife_id", "");
      this.props.handleChange("wife_name", "");
    }
    this.props.handleChange("estado_civil", value);
  };

  save() {
    let formData = {
      user: this.props.user,
      data: {
        ...this.props.basic,
      },
    };

    Api.post(`/microservice/profile/saveAdditionalData/`, formData)
      .then((res) => {
        this.props.viewAlertMessage("success", "Salvo com sucesso!");
        setTimeout(() => this.props.closeAlertMessage(), 5000);
      })
      .catch(() => {
        this.props.viewAlertMessage("error", "Ocorreu um erro ao salvar!");
        setTimeout(() => this.props.closeAlertMessage(), 5000);
      });
  }

  render() {
    const { basic } = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <h3 className="card-title text-primary title-form">
            Informações complementares
          </h3>
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="gemelar"
            select
            label="Gemelar:"
            value={basic.gemelar ? basic.gemelar : ""}
            onChange={this.handleChangeAdditional("gemelar")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="0">
              Não
            </MenuItem>
            <MenuItem key="2" value="1">
              Sim
            </MenuItem>
          </TextField>
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="estado_civil"
            select
            label="Estado civil:"
            value={basic.estado_civil ? basic.estado_civil : ""}
            onChange={this.handleChangeStatusCivil("estado_civil")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="Casado">
              Casado(a)
            </MenuItem>
            <MenuItem key="2" value="Divorciado">
              Divorciado(a)
            </MenuItem>
            <MenuItem key="3" value="Separado">
              Separado(a)
            </MenuItem>
            <MenuItem key="4" value="Solteiro">
              Solteiro(a)
            </MenuItem>
            <MenuItem key="5" value="Companheiro">
              Companheiro(a)
            </MenuItem>
            <MenuItem key="6" value="Viúvo">
              Viúvo(a)
            </MenuItem>
          </TextField>
        </div>

        {basic.estado_civil === "Casado" ? (
          <div className="col-md-3 col-12">
            <TextField
              id="mariage_regime"
              select
              label="Regime de casamento:"
              value={basic.mariage_regime ? basic.mariage_regime : ""}
              onChange={this.handleChangeAdditional("mariage_regime")}
              SelectProps={{}}
              fullWidth
              style={{ marginTop: "17px" }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem key="1" value="cpb">
                Comunhão Parcial de Bens
              </MenuItem>
              <MenuItem key="2" value="stb">
                Separação Total de Bens
              </MenuItem>
              <MenuItem key="3" value="ctb">
                Comunhão Total de Bens
              </MenuItem>
              <MenuItem key="4" value="pfa">
                Participação Final nos Aquestos
              </MenuItem>
            </TextField>
          </div>
        ) : (
          ""
        )}
        <div
          className="col-md-3 col-12"
          style={{ display: basic.estado_civil === "Casado" ? "" : "none" }}
        >
          <AutocompleteParents
            parent={{
              id: basic.wife_id,
              label: basic.wife_name,
            }}
            field={`wife`}
            handleChangeAutocomplete={this.handleChangeAutocomplete().bind(
              this
            )}
          />
        </div>

        {basic.estado_civil === "Divorciado" ||
        basic.estado_civil === "Separado" ? (
          <div className="col-md-3 col-12">
            <TextField
              id="end_mariage"
              label="Data término"
              margin="normal"
              fullWidth
              InputProps={{
                inputComponent: MaskDate,
                value: basic.end_mariage,
                onChange: this.handleChangeAdditional("end_mariage"),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        ) : (
          ""
        )}

        <div className="col-md-3 col-12">
          <TextField
            id="religion"
            select
            label="Religião:"
            value={basic.religion ? basic.religion : ""}
            onChange={this.handleChangeAdditional("religion")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="Adventismo">
              Adventismo
            </MenuItem>
            <MenuItem key="2" value="Baháí">
              Bahá'í
            </MenuItem>
            <MenuItem key="3" value="Budismo">
              Budismo
            </MenuItem>
            <MenuItem key="4" value="Catolicismo">
              Catolicismo
            </MenuItem>
            <MenuItem key="5" value="Cristianismo">
              Cristianismo
            </MenuItem>
            <MenuItem key="6" value="Espiritismo">
              Espiritismo
            </MenuItem>
            <MenuItem key="7" value="Hinduísmo">
              Hinduísmo
            </MenuItem>
            <MenuItem key="8" value="Igreja Ortodoxa">
              Igreja Ortodoxa
            </MenuItem>
            <MenuItem key="9" value="Islamismo">
              Islamismo
            </MenuItem>
            <MenuItem key="10" value="Judaísmo">
              Judaísmo
            </MenuItem>
            <MenuItem key="11" value="Mormonismo">
              Mormonismo
            </MenuItem>
            <MenuItem key="12" value="Neopaganismo">
              Neopaganismo
            </MenuItem>
            <MenuItem key="13" value="Protestantismo">
              Protestantismo
            </MenuItem>
            <MenuItem key="14" value="Religiões afro-brasileiras e indígenas">
              Religiões afro-brasileiras e indígenas
            </MenuItem>
            <MenuItem key="15" value="Religiões hoasqueiras">
              Religiões hoasqueiras
            </MenuItem>
            <MenuItem key="16" value="Testemunhas de Jeová">
              Testemunhas de Jeová
            </MenuItem>
            <MenuItem key="17" value="Sem Religão">
              Sem Religão
            </MenuItem>
            <MenuItem key="18" value="Outros">
              Outros
            </MenuItem>
          </TextField>
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="choose_sexual"
            select
            label="Orientação sexual:"
            value={basic.choose_sexual ? basic.choose_sexual : ""}
            onChange={this.handleChangeAdditional("choose_sexual")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="Assexual">
              Assexual
            </MenuItem>
            <MenuItem key="2" value="Bissexual">
              Bissexual
            </MenuItem>
            <MenuItem key="3" value="Heterosexual">
              Heterosexual
            </MenuItem>
            <MenuItem key="4" value="Homosexual">
              Homosexual
            </MenuItem>
            <MenuItem key="5" value="Intergênero">
              Intergênero
            </MenuItem>
            <MenuItem key="6" value="Pansexual">
              Pansexual
            </MenuItem>
            <MenuItem key="7" value="Transgênero">
              Transgênero
            </MenuItem>
            <MenuItem key="8" value="Não informado">
              Não informado
            </MenuItem>
          </TextField>
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="cpf"
            label="CPF:"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCpf,
              value: basic.cpf,
              onChange: this.handleChangeAdditional("cpf"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="title_elector"
            label="Título de eleitor:"
            value={basic.title_elector ? basic.title_elector : ""}
            margin="normal"
            fullWidth
            onChange={this.handleChangeAdditional("title_elector")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="rg"
            label="RG:"
            value={basic.rg ? basic.rg : ""}
            margin="normal"
            fullWidth
            onChange={this.handleChangeAdditional("rg")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="state"
            select
            label="Estado:"
            value={basic.stateRg ? basic.stateRg : ""}
            onChange={this.handleChangeAdditional("stateRg")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="AC">
              AC
            </MenuItem>
            <MenuItem key="2" value="AL">
              AL
            </MenuItem>
            <MenuItem key="3" value="AP">
              AP
            </MenuItem>
            <MenuItem key="4" value="AM">
              AM
            </MenuItem>
            <MenuItem key="5" value="BA">
              BA
            </MenuItem>
            <MenuItem key="6" value="CE">
              CE
            </MenuItem>
            <MenuItem key="7" value="DF">
              DF
            </MenuItem>
            <MenuItem key="8" value="ES">
              ES
            </MenuItem>
            <MenuItem key="9" value="MA">
              MA
            </MenuItem>
            <MenuItem key="10" value="MT">
              MT
            </MenuItem>
            <MenuItem key="11" value="MS">
              MS
            </MenuItem>
            <MenuItem key="12" value="MG">
              MG
            </MenuItem>
            <MenuItem key="13" value="PA">
              PA
            </MenuItem>
            <MenuItem key="14" value="PB">
              PB
            </MenuItem>
            <MenuItem key="15" value="PR">
              PR
            </MenuItem>
            <MenuItem key="16" value="PE">
              PE
            </MenuItem>
            <MenuItem key="17" value="PI">
              PI
            </MenuItem>
            <MenuItem key="18" value="RJ">
              RJ
            </MenuItem>
            <MenuItem key="19" value="RN">
              RN
            </MenuItem>
            <MenuItem key="20" value="RS">
              RS
            </MenuItem>
            <MenuItem key="21" value="RO">
              RO
            </MenuItem>
            <MenuItem key="22" value="RR">
              RR
            </MenuItem>
            <MenuItem key="23" value="SC">
              SC
            </MenuItem>
            <MenuItem key="24" value="SP">
              SP
            </MenuItem>
            <MenuItem key="25" value="SE">
              SE
            </MenuItem>
            <MenuItem key="26" value="TO">
              TO
            </MenuItem>
          </TextField>
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="issuing_entity"
            label="Emissor:"
            value={basic.issuing_entity ? basic.issuing_entity : ""}
            margin="normal"
            fullWidth
            onChange={this.handleChangeAdditional("issuing_entity")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="date_issue"
            type="date"
            label="Data de Emissão:"
            value={basic.date_issue}
            margin="normal"
            fullWidth
            onChange={this.handleChangeAdditional("date_issue")}
            InputLabelProps={{
              shrink: true,
            }}
            onInput={(e) => {
              if (e.target.value.length > 10) {
                e.target.value = e.target.value.substr(1, 11);
              }
            }}
          />
        </div>

        <div className="col-md-6 col-12">
          <AutocompleteParents
            parent={{
              id: basic.father_id,
              label: basic.father_name,
            }}
            field={`father`}
            handleChangeAutocomplete={this.handleChangeAutocomplete().bind(
              this
            )}
          />
        </div>
        <div className="col-md-6 col-12">
          <AutocompleteParents
            parent={{
              id: basic.mother_id,
              label: basic.mother_name,
            }}
            field={`mother`}
            handleChangeAutocomplete={this.handleChangeAutocomplete().bind(
              this
            )}
          />
        </div>
        <div className="col-md-12 text-right">
          <Button
            onClick={() => {
              this.save();
            }}
            className="jr-btn jr-btn-sm bg-success"
            color="primary"
            variant="contained"
          >
            <i className="zmdi zmdi-check text-right" />
            <span> Salvar </span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.authUser,
    basic: state.Profile.basic,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ handleChange, ...alertActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalData);
