import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as patientInfoActions from "actions/consult/PatientInfo";
import * as appointmentActions from "actions/consult/Appointment";
import { modalVideo } from "actions/comunication/Chat";
import { NavLink, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import Button from "@material-ui/core/Button";
import HistoryPatient from "components/HistoryPatient";
import Stopwatch from "../Stopwatch";
import SweetAlert from "react-bootstrap-sweetalert";
import Tags from "../Tags";
import HistoryIcon from "@material-ui/icons/History";
import PauseCircleOutline from "@material-ui/icons/PauseCircleOutline";
import IconBlock from "@material-ui/icons/Block";
import {
  Chip,
  Tooltip,
  Avatar,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import ModalMedicalRecord from "../ModalMedicalRecord";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import { CATEGORIES_ARRAY } from "constants/CustomSystemReview";
import { ANAMNESIS_ARRAY } from "constants/CustomAnamnesis";
import Api from "services/api";
import moment from "moment";
import "./styles.css";
import ConfirmCloseCollpase from "components/Modal/ConfirmCloseCollpase";

class SidenavConsult extends Component {
  state = {
    modal_finish: false,
    modal_medical_record: false,
    numReg: null,
    alertWaiting: {
      open: false,
      id: "",
      name: "",
      photo: "",
    },
    confirmClose: false,
    typeConfirm: "",
  };

  componentDidUpdate() {
    if (this.props.finish) {
      this.handleRedirect();
      this.props.stopRedirect();
      /* this.props.history.push(
        this.props.triage === "1"
          ? "/app/choose/triage"
          : "/app/choose/data-consult"
      ); */
    }
    // code para deixar o submenu aberto
    const { history } = this.props;
    const pathname = `${history.location.pathname}${history.location.search}`; // get current path
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentDidMount() {
    this.props.getInfoPatient(this.props.patient);
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}${history.location.search}`; // get current path
    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}

    this.handlegetPatientNumRegiste();

    getDataFirebase()
      .child(`waiting-room${getEnviromentFirebase()}/` + this.props.user)
      .on("value", (snap) => {
        let values = snap.val();
        if (values !== null) {
          Object.keys(values).forEach((row) => {
            if (
              moment()
                .subtract(1, "minute")
                .format("DD/MM/YYYY HH:mm") === values[row].date ||
              moment().format("DD/MM/YYYY HH:mm") === values[row].date
            ) {
              this.setState({
                alertWaiting: {
                  open: true,
                  id: values[row].patient_id,
                  name: values[row].patient,
                  photo: values[row].photo,
                },
              });
            }
          });
        }
      });
  }

  handlegetPatientNumRegiste() {
    Api.post("/microservice/admission/getPatientNumRegiste", {
      address_selected_id: this.props.address_selected_id,
      patient: this.props.patient,
    })
      .then((res) => {
        this.setState({
          ...this.state,
          numReg: res.data.numReg,
        });
      })
      .catch((e) => {
        // dispatch(viewAlertMessage("error", "Ocorreu um erro!"));
        // setTimeout(() => dispatch(closeAlertMessage()), 3000);
      });
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  handleSetModal = () => {
    this.setState({
      ...this.state,
      modal_medical_record: true,
    });
  };

  handleRedirect = () => {
    // this.props.history.push(
    //   this.props.triage === "1"
    //     ? "/app/choose/triage"
    //     : this.props.covid
    //     ? "/app/choose/attendance"
    //     : "/app/choose/data-consult"
    // );
    this.props.history.push("/app/dashboard");
  };

  render() {
    const {
      start,
      appointment_id,
      modal_finish,
      modal_correction,
      permissions,
      permission_presc_care,
      modal_pause,
      secretary,
      customSystemReview,
      customAnamnesis,
      history,
      triage,
      covid,
      authArea,
      authType,
    } = this.props;

    const { patient } = this.props.patientInfo;
    const { alertWaiting, confirmClose, typeConfirm } = this.state;

    return (
      <CustomScrollbars className="scrollbar">
        <ul className="nav-menu">
          {/* CONSULTÓRIO */}
          {/* <h6 className="title-patient">Paciente</h6> */}
          <hr className="hr-divider mt-0 mb-2" />
          <div className="content-patient">
            <Avatar
              alt={patient.name}
              src={patient.photo}
              className="avatar-position"
            />
            <div className="jr-card-hd-content ml-2">
              <Tooltip title={`Nº Tel.: ${patient.smartphone}`}>
                <h5 id="name-patient" className="mb-0 text-white">
                  {patient.name}
                </h5>
              </Tooltip>
              <p className="jr-fs-xs mb-0 mt-1 text-grey text-lighten-2">
                Dt. Nascimento: {patient.birthday_br}
                <br /> Nome da Mãe: {patient.mother_name}
                <br />
                CPF: {patient.cpf}
                <br />
                <Chip
                  className="ml-2"
                  size="small"
                  color="default"
                  onClick={() => this.props.viewHistory("personalData")}
                  icon={<HistoryIcon style={{ color: "white" }} />}
                  label="Histórico"
                  variant="outlined"
                  style={{ color: "white" }}
                />
              </p>
            </div>
          </div>
          {/* <div
            className="d-flex justify-content-center"
            style={{ cursor: "pointer" }}
          >
            <span
              style={{ fontSize: "11px", color: "#fff" }}
              onClick={this.handleSetModal}
            >
              Prontuário.: {this.state.numReg ? this.state.numReg : "--"}
              <i className="zmdi zmdi-edit ml-2" />
            </span>
          </div> */}
          {/* <div className="div-btn-history">
            <Chip
              className="w-100"
              size="small"
              color="primary"
              onClick={() => this.props.viewHistory("personalData")}
              icon={<HistoryIcon />}
              label="Histórico"
            />
          </div> */}

          {history.location.pathname !==
            "/app/consult/transcription-prescription-out" &&
            history.location.pathname !== "/app/consult/medicines-out" && (
              <div className="row">
                <Tags type="consult" />
              </div>
            )}
          <hr className="hr-divider mt-0 mb-2" />
          <div className="text-center">
            {start && <Stopwatch init={start} />}
            {!appointment_id ? (
              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginTop: "5px",
                }}
              >
                <Chip
                  className="w-100"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    if (this.props.edited) {
                      this.setState({
                        confirmClose: true,
                        typeConfirm: "alter",
                      });
                    } else {
                      this.props.modalVideo(false);
                      this.handleRedirect();
                      this.props.alterPatient();
                      /* this.props.history.push(
                        this.props.triage === "1"
                          ? "/app/choose/triage"
                          : "/app/choose/data-consult"
                      ); */
                    }
                  }}
                  icon={<IconBlock />}
                  label="Alterar paciente"
                />
              </div>
            ) : (
              <div className="row mt-2">
                <div
                  className="col-6"
                  style={{ paddingLeft: "25px", paddingRight: "5px" }}
                >
                  <Chip
                    className="w-100"
                    size="small"
                    color="primary"
                    onClick={() => {
                      if (this.props.edited) {
                        this.setState({
                          confirmClose: true,
                          typeConfirm: "pause",
                        });
                      } else {
                        this.props.modalPause(true);
                      }
                    }}
                    icon={<PauseCircleOutline />}
                    label="Pausar"
                  />
                </div>
                {authType === "2" ? (
                  <div
                    className="col-6"
                    style={{
                      paddingLeft: "5px",
                      paddingRight: "25px",
                      marginTop: "5px",
                    }}
                  >
                    <Chip
                      className="w-100"
                      size="small"
                      color="secondary"
                      onClick={() => this.props.modalCorrection(true)}
                      icon={<IconBlock />}
                      label="Env. p/ correção"
                    />
                  </div>
                ) : (
                  <div
                    className="col-6"
                    style={{ paddingLeft: "5px", paddingRight: "25px" }}
                  >
                    <Chip
                      className="w-100"
                      size="small"
                      color="secondary"
                      onClick={() => {
                        if (this.props.edited) {
                          this.setState({
                            confirmClose: true,
                            typeConfirm: "finish",
                          });
                        } else {
                          this.props.modalFinish(true);
                        }
                      }}
                      icon={<IconBlock />}
                      label="Finalizar"
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <hr className="hr-divider mb-2" />

          {history.location.pathname !==
            "/app/consult/transcription-prescription-out" &&
            history.location.pathname !== "/app/consult/medicines-out" &&
            triage !== "1" &&
            !covid && (
              <>
                {/* FERRAMENTAS */}

                <li className="menu open">
                  <Button>
                    <span className="nav-text">Atendimento</span>
                  </Button>

                  <ul className="sub-menu">
                    {authArea === "16" ? (
                      <>
                        <li>
                          <NavLink to="/app/consult/initial-record">
                            <span className="nav-text"> Registro inicial</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/app/consult/attendance-record">
                            <span className="nav-text">
                              <IntlMessages id="module.consult.attendance_record" />
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/app/consult/initial-odonto">
                            <span className="nav-text">
                              Atendimento Inicial
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/app/consult/legal-dentistry">
                            <span className="nav-text">
                              Avaliação Odontológica
                            </span>
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <NavLink to="/app/consult/consultation-summary">
                            <span className="nav-text">
                              {" "}
                              Últimos atendimentos
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/app/consult/initial-record">
                            <span className="nav-text"> Registro inicial</span>
                          </NavLink>
                        </li>
                        {(!secretary || authType === "0") && (
                          <li>
                            <NavLink to="/app/consult/attendance-record">
                              <span className="nav-text">
                                <IntlMessages id="module.consult.attendance_record" />
                              </span>
                            </NavLink>
                          </li>
                        )}

                        {patient.pregnancy && (
                          <li>
                            <NavLink to="/app/consult/prenatal">
                              <span className="nav-text"> Pré-natal</span>
                            </NavLink>
                          </li>
                        )}

                        {(authArea === "9" || authArea === "46") && (
                          <>
                            {/* <li>
                        <NavLink to="/app/consult/fisio-avaliation/">
                          <span className="nav-text">
                            Avaliação Fisioterapêutica
                          </span>
                        </NavLink>
                      </li> */}
                            {/* <li>
                        <NavLink to="/app/consult/fisio-pos-covid/">
                          <span className="nav-text">
                            Avaliação Pós-covid
                          </span>
                        </NavLink>
                      </li> */}
                            <li>
                              <NavLink to="/app/consult/fisio-rehabilitation/">
                                <span className="nav-text">Reabilitação</span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/app/consult/sessions/">
                                <span className="nav-text">Sessões</span>
                              </NavLink>
                            </li>
                          </>
                        )}
                        {authArea === "22" && (
                          <>
                            <li>
                              <NavLink to="/app/consult/rehabilitation/">
                                <span className="nav-text">Reabilitação</span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/app/consult/session/">
                                <span className="nav-text">Sessões</span>
                              </NavLink>
                            </li>
                          </>
                        )}

                        {/* <li>
              <NavLink to="/app/consult/physical-avaliation">
                <span className="nav-text"> Avaliação Física</span>
              </NavLink>
            </li> */}

                        <li className="menu no-arrow">
                          <NavLink to={`/app/consult/exam`}>
                            <span className="nav-text">
                              <IntlMessages id="module.consult.exams" />
                            </span>
                          </NavLink>
                        </li>

                        {permissions.prescription && (
                          <li>
                            <NavLink to="/app/consult/prescription/">
                              <span className="nav-text">
                                Prescrição industrializada
                              </span>
                            </NavLink>
                          </li>
                        )}
                        {permissions.prescription_manipulated && (
                          <li>
                            <NavLink to="/app/consult/manipulated/">
                              <span className="nav-text">
                                Prescrição manipulada
                              </span>
                            </NavLink>
                          </li>
                        )}
                        <li>
                          <NavLink to={`/app/consult/conduct-guidance`}>
                            <span className="nav-text">
                              Orientação de conduta
                            </span>
                          </NavLink>
                        </li>
                        {permissions.pain_scale && (
                          <li>
                            <NavLink to="/app/consult/pain-scale">
                              <span className="nav-text"> Escala de Dor </span>
                            </NavLink>
                          </li>
                        )}
                        {permission_presc_care && (
                          <li>
                            <NavLink to="/app/consult/prescription-care/">
                              <span className="nav-text">
                                <IntlMessages id="module.consult.prescription.care" />
                              </span>
                            </NavLink>
                          </li>
                        )}
                        {(this.props.address_provider_type === "5" ||
                          authArea === "9" ||
                          authArea === "46") && (
                          <li>
                            <NavLink to="/app/consult/ivcf/">
                              <span className="nav-text">IVCF</span>
                            </NavLink>
                          </li>
                        )}

                        {/* <li>
              <NavLink to="/app/consult/video">
                <span className="nav-text">Teleconsulta</span>
              </NavLink>
            </li> */}
                      </>
                    )}
                  </ul>
                </li>

                {/* MENU REVISÃO DE SISTEMAS */}

                {permissions.system_review && (
                  <li className="menu">
                    <Button>
                      <span className="nav-text">Revisão de sistemas</span>
                    </Button>
                    <ul className="sub-menu">
                      {customSystemReview.id
                        ? customSystemReview.view.split(",").map((id) => {
                            let categoria = CATEGORIES_ARRAY.find(
                              (categoria) => categoria.id === Number(id)
                            );
                            return (
                              <li key={id}>
                                <NavLink
                                  to={`/app/consult/system-review/${categoria.url}`}
                                >
                                  <span className="nav-text">
                                    {" "}
                                    {categoria.description}
                                  </span>
                                </NavLink>
                              </li>
                            );
                          })
                        : CATEGORIES_ARRAY.filter((row) => {
                            if (
                              (authArea === "9" || authArea === "46") &&
                              [9, 3, 4, 7, 8].indexOf(row.id) === -1
                            ) {
                              return false;
                            } else {
                              return true;
                            }
                          }).map((categoria) => (
                            <li key={categoria.id}>
                              <NavLink
                                to={`/app/consult/system-review/${categoria.url}`}
                              >
                                <span className="nav-text">
                                  {" "}
                                  {categoria.description}
                                </span>
                              </NavLink>
                            </li>
                          ))}
                    </ul>
                  </li>
                )}

                <hr className="hr-divider my-2" />

                {/* MENU ANAMINESE */}
                <li className="menu">
                  <Button>
                    <span className="nav-text">
                      <IntlMessages id="module.consult.anamnese" />
                    </span>
                  </Button>

                  <ul className="sub-menu">
                    <li className="menu no-arrow">
                      <NavLink to={`/app/consult/personal-data`}>
                        <span className="nav-text">Dados Pessoais</span>
                      </NavLink>
                    </li>
                    {customAnamnesis.view && customAnamnesis.id
                      ? customAnamnesis.view.split(",").map((row) => {
                          let menuItem = ANAMNESIS_ARRAY.find(
                            (el) => el.id === row
                          );
                          return (
                            <li key={menuItem.id}>
                              <NavLink to={`/app/consult/${menuItem.link}`}>
                                <span className="nav-text">
                                  {" "}
                                  {menuItem.description}
                                </span>
                              </NavLink>
                            </li>
                          );
                        })
                      : ANAMNESIS_ARRAY.map((row) => (
                          <li key={row.id}>
                            <NavLink to={`/app/consult/${row.link}`}>
                              <span className="nav-text">
                                {" "}
                                {row.description}
                              </span>
                            </NavLink>
                          </li>
                        ))}
                  </ul>
                </li>

                <li className="menu no-arrow">
                  <NavLink to="/app/consult/files-cloud">
                    <span className="nav-text">Arquivos na Nuvem</span>
                  </NavLink>
                </li>
                {!secretary && permissions.attest && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/attestation">
                      <span className="nav-text"> Atestados</span>
                    </NavLink>
                  </li>
                )}
                {permissions.aga && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/aga">
                      <span className="nav-text">
                        Avaliação Geriatrica Ampla
                      </span>
                    </NavLink>
                  </li>
                )}

                <li className="menu no-arrow">
                  <NavLink to={`/app/consult/compare-pictures`}>
                    <span className="nav-text">Comparador de Fotos</span>
                  </NavLink>
                </li>
                {permissions.circumferences && (
                  <li className="menu no-arrow">
                    <NavLink to={`/app/consult/circumferences`}>
                      <span className="nav-text">Circunferências</span>
                    </NavLink>
                  </li>
                )}
                {permissions.conduct && (
                  <li className="menu no-arrow">
                    <NavLink to={`/app/consult/conduct`}>
                      <span className="nav-text">Conduta</span>
                    </NavLink>
                  </li>
                )}

                {permissions.nurse_diagnostic && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/nurse-diagnostic">
                      <span className="nav-text">
                        {" "}
                        Diagnóstico de Enfermagem{" "}
                      </span>
                    </NavLink>
                  </li>
                )}
                {permissions.diagnostic_integral && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/integral-diagnostic">
                      <span className="nav-text"> Diagnóstico Integral </span>
                    </NavLink>
                  </li>
                )}

                {permissions.folds && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/folds">
                      <span className="nav-text"> Dobras </span>
                    </NavLink>
                  </li>
                )}
                {!secretary && permissions.attest && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/forward">
                      <span className="nav-text"> Encaminhar Paciente </span>
                    </NavLink>
                  </li>
                )}
                {permissions.plate_index && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/plate-index/">
                      <span className="nav-text">Índice de Placa</span>
                    </NavLink>
                  </li>
                )}

                {/* <li className="menu no-arrow">
                  <NavLink
                    className="menu no-arrow"
                    to="/app/consult/medicines"
                  >
                    <span className="nav-text">Medicamentos em uso</span>
                  </NavLink>
                </li> */}

                {permissions.periodontogram && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/periodotogram/">
                      <span className="nav-text">Periodontograma</span>
                    </NavLink>
                  </li>
                )}

                {authArea !== "29" && authArea !== "9" && authType === "0" && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/transcription-prescription">
                      <span className="nav-text">Transcrever Prescrição</span>
                    </NavLink>
                  </li>
                )}

                {/* <li className="menu no-arrow">
            <NavLink to="/app/consult/tbca">
              <span className="nav-text">Tbca</span>
            </NavLink>
          </li> */}
                {authArea !== "9" && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/vaccines">
                      <span className="nav-text"> Vacinas</span>
                    </NavLink>
                  </li>
                )}

                {authType === "2" && (
                  <li className="menu no-arrow">
                    <NavLink to="/app/consult/odonto">
                      <span className="nav-text"> Odonto</span>
                    </NavLink>
                  </li>
                )}
              </>
            )}

          {triage === "1" && (
            <>
              <li>
                <NavLink to="/app/consult/initial-record">
                  <span className="nav-text"> Registro inicial</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to={`/app/consult/exam`}>
                  <span className="nav-text">
                    <IntlMessages id="module.consult.exams" />
                  </span>
                </NavLink>
              </li>

              {ANAMNESIS_ARRAY.map((row) => (
                <li key={row.id}>
                  <NavLink to={`/app/consult/${row.link}`}>
                    <span className="nav-text"> {row.description}</span>
                  </NavLink>
                </li>
              ))}

              <li className="menu no-arrow">
                <NavLink to="/app/consult/vaccines">
                  <span className="nav-text"> Vacinas</span>
                </NavLink>
              </li>

              <li className="menu no-arrow">
                <NavLink to="/app/consult/files-cloud">
                  <span className="nav-text">Arquivos na Nuvem</span>
                </NavLink>
              </li>

              <li className="menu no-arrow">
                <NavLink to={`/app/consult/compare-pictures`}>
                  <span className="nav-text">Comparador de Fotos</span>
                </NavLink>
              </li>

              <li className="menu no-arrow">
                <NavLink to="/app/consult/transcription-prescription">
                  <span className="nav-text">Transcrever Prescrição</span>
                </NavLink>
              </li>
              {appointment_id && (
                <li className="menu no-arrow">
                  <NavLink to="/app/consult/outcome">
                    <span className="nav-text">Desfecho</span>
                  </NavLink>
                </li>
              )}
            </>
          )}

          {covid && (
            <>
              <li>
                <NavLink to="/app/consult/consultation-summary">
                  <span className="nav-text"> Últimos atendimentos</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/consult/initial">
                  <span className="nav-text">Atendimento inicial</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/consult/attendance-record">
                  <span className="nav-text">
                    <IntlMessages id="module.consult.attendance_record" />
                  </span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to={`/app/consult/personal-data`}>
                  <span className="nav-text"> Dados Pessoais</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/consult/history">
                  <span className="nav-text">História Pessoal</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/consult/prescription/">
                  <span className="nav-text">Prescrição industrializada</span>
                </NavLink>
              </li>

              <li className="menu no-arrow">
                <NavLink to={`/app/consult/exam`}>
                  <span className="nav-text">
                    <IntlMessages id="module.consult.exams" />
                  </span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/consult/vaccines">
                  <span className="nav-text">Vacinas</span>
                </NavLink>
              </li>

              <li className="menu no-arrow">
                <NavLink to="/app/consult/files-cloud">
                  <span className="nav-text">Arquivos na Nuvem</span>
                </NavLink>
              </li>

              <li className="menu no-arrow">
                <NavLink to={`/app/consult/attestation`}>
                  <span className="nav-text">Atestados</span>
                </NavLink>
              </li>

              <li className="menu no-arrow">
                <NavLink to="/app/consult/forward">
                  <span className="nav-text">Encaminhar Paciente</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={`/app/consult/conduct-guidance`}>
                  <span className="nav-text">Orientação de conduta</span>
                </NavLink>
              </li>
            </>
          )}

          <li
            className="menu no-arrow mt-3 pl-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.props.alterPatient();
              this.handleRedirect();
            }}
          >
            {/* <NavLink to="/app/dashboard"> */}

            <i className="zmdi zmdi-undo zmdi-hc-fw" />
            <span className="nav-text">
              Sair {triage === "1" ? "da Escuta Inicial" : "do Consultório"}
            </span>
            {/* </NavLink> */}
          </li>
        </ul>
        <ModalMedicalRecord
          getPatientNumRegiste={() => this.handlegetPatientNumRegiste()}
          medicalRecord={this.state.numReg}
          open={this.state.modal_medical_record}
          close={() =>
            this.setState({
              ...this.state,
              modal_medical_record: false,
            })
          }
        />

        <HistoryPatient id_patient={patient.id} />
        <SweetAlert
          show={modal_finish}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, finalizar"
          confirmButtonColor="#bc0003"
          cancelBtnText="Não"
          title="Finalizar este atendimento?"
          onConfirm={() =>
            this.props.finishAppointment(appointment_id, this.props.service_id)
          }
          onCancel={() => this.props.closeModalFinish()}
        />
        <SweetAlert
          show={modal_pause}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText={"Sim, pausar"}
          confirmButtonColor="#bc0003"
          cancelBtnText="Não"
          title={"Pausar este atendimento?"}
          onConfirm={() => this.props.pauseAppointment(false)}
          onCancel={() => this.props.closeModalPause()}
        />
        <SweetAlert
          show={modal_correction}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText={"Sim, enviar"}
          confirmButtonColor="#bc0003"
          cancelBtnText="Não"
          title={"Enviar para correção?"}
          onConfirm={() => this.props.pauseAppointment(true)}
          onCancel={() => this.props.closeModalCorrection()}
        />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={alertWaiting.open}
          onClose={() =>
            this.setState({
              alertWaiting: {
                open: false,
                id: "",
                name: "",
                photo: "",
              },
            })
          }
          key={"1"}
          autoHideDuration={10000}
        >
          <SnackbarContent
            message={
              <div className="d-flex p-0 avatar-text mr-auto">
                <Avatar
                  className="mr-2"
                  alt={alertWaiting.name}
                  src={alertWaiting.photo}
                />
                <span>{alertWaiting.name} esta aguardando atendimento.</span>
              </div>
            }
          />
        </Snackbar>
        <ConfirmCloseCollpase
          open={confirmClose}
          message="Registro do atendimento não foi salvo! Deseja sair sem salvar?"
          confirm={() => {
            if (typeConfirm === "alter") {
              this.handleRedirect();
              this.props.alterPatient();
              /* this.props.history.push(
                this.props.triage === "1"
                  ? "/app/choose/triage"
                  : "/app/choose/data-consult"
              ); */
            } else if (typeConfirm === "pause") {
              this.props.modalPause(true);
            } else {
              this.props.modalFinish(true);
            }
            this.setState({ confirmClose: false, typeConfirm: "" });
          }}
          cancel={() => this.setState({ confirmClose: false })}
        />
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patient: state.appointment.patient,
    modal_finish: state.appointment.modal_finish,
    modal_pause: state.appointment.modal_pause,
    modal_correction: state.appointment.modal_correction,
    toApprove: state.appointment.toApprove,
    finish: state.appointment.finish,
    start: state.appointment.start,
    appointment_id: state.appointment.id,
    patientInfo: state.patientInfo,
    user: state.auth.authUser,
    authArea: state.auth.area,
    authType: state.auth.type,
    permissions: state.auth.permissionsConsult,
    permission_presc_care: state.permissions.prescription_care,
    address_selected_id: state.auth.address_selected.id,
    address_provider_type: state.auth.address_selected.provider_type,
    secretary: state.auth.secretary,
    customSystemReview: state.configuration.config_system_review.record[0],
    customAnamnesis: state.configuration.config_anamnesis,
    edited: state.attendanceRecord.register.edited,
    triage: state.appointment.triage,
    covid: state.appointment.covid,
    service_id: state.appointment.service_id,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...patientInfoActions,
      ...appointmentActions,
      modalVideo,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidenavConsult)
);
