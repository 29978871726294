import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import api from "services/api";

const getListTypeEvent = async user => {
  const response = await api.post(`/ms_configuration/typeevent/getList`, {
    user
  });
  const data = await response.data.record;
  return data;
};

function* asyncGetList(action) {
  try {
    // variável que faz a busca
    const getList = yield call(getListTypeEvent, action.doctor);
    // parte do método que identifica qual método usar após a conclusão do método acima
    yield put({
      type: "GET_LIST_TYPE_EVENT_SUCCESS",
      payload: getList
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

export function* requestList() {
  yield takeLatest("GET_LIST_TYPE_EVENT", asyncGetList);
}

export default function* rootSaga() {
  yield all([fork(requestList)]);
}
