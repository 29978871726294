const INITIAL_STATE = {
  nameProcedure: "",
  modalSuccessDeleteGroup: false,
  active: "view",
  color: "primary",
  texto: "Adicionar",
  hidden: false,
  icon: "plus",
  idEdit: "",
  valProcedure: "0,00",
  listDoctorProcedures: []
};

export default function AddProcedure(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_ADD_PROCEDURE":
      return INITIAL_STATE;
    case "HANDLE_ADD_FUNC":
      if (action.payload.id) {
        return {
          ...state,
          active: action.payload.active,
          color: action.payload.color,
          texto: action.payload.texto,
          hidden: action.payload.hidden,
          icon: action.payload.icon,
          idEdit: action.payload.id
        };
      } else {
        return {
          ...state,
          active: action.payload.active,
          color: action.payload.color,
          texto: action.payload.texto,
          hidden: action.payload.hidden,
          icon: action.payload.icon,
          idEdit: action.payload.id,
          InfosGroup: [],
          tables_exam: [],
          nameGroup: "",
          descGroup: "",
          listMedicinesSelected: []
        };
      }

    case "SET_VAL_PROCEDURE":
      return {
        ...state,
        valProcedure: action.payload.valor
      };

    case "SET_NAME_PROCEDURE":
      return {
        ...state,
        nameProcedure: action.payload.valor
      };

    case "CAD_NEW_PROCEDURE":
      return {
        ...state,
        doctor: action.payload.doctor,
        nameProcedure: action.payload.nameProcedure,
        valorProcedure: action.payload.valorProcedure,
        idEdit: action.payload.idEdit
      };

    case "REQUEST_DOCTOR_PROCEDURES":
      return {
        ...state,
        doctor: action.payload.doctor
      };

    case "REQUEST_DOCTOR_PROCEDURE_SUCCESS":
      return {
        ...state,
        listDoctorProcedures: action.payload.listDoctorProcedures
      };

    case "DEL_PROCEDURE":
      return {
        ...state,
        idProcedure: action.payload.idProcedure,
        doctor: action.payload.doctor
      };

    case "GET_PROCEDURE_FOR_EDIT":
      return {
        ...state,
        idProcedure: action.payload.idProcedure
      };

    case "REQUEST_INFO_PROCEDURE_SUCCESS":
      return {
        ...state,
        nameProcedure: action.payload.nameProcedure,
        valProcedure: action.payload.valProcedure
      };

    default:
      return state;
  }
}
