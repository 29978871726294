import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addMedicineComplex,
  editMedicineComplex,
} from "actions/consult/Prescription";
import {
  Checkbox,
  TextField,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import MaskHour from "components/Masks/MaskHour";
import AutocompleteMedicine from "./AutocompleteMedicine";
import MedicinesDefault from "./MedicinesDefault";
import Api from "services/api";
import { getFormatOnlyDate } from "services/functions";
import moment from "moment";

export default function PlusPosology({ openModal, closeModal, patient }) {
  const dispatch = useDispatch();
  const medicine_atual = useSelector(
    (state) => state.prescription.medicine_atual
  );
  const xdiaInput = useRef(null);
  const [ls_mp_medicine, setLsMpMedicine] = useState("");
  const [name_display, setNameDisplay] = useState("");
  const [is_continuos, setIsContinuous] = useState(false);
  const [unit_measure, setUnitMeasure] = useState("");
  const [listDosage, setListDosage] = useState([]);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  const [inUse, setInUse] = useState(false);
  const [days_week_modal, setDaysWeekModal] = useState({ open: false, id: "" });
  const [posology, setPosology] = useState([
    {
      id: 1,
      xdia: "",
      date_start: new Date(),
      duration: "",
      interval: "Diário",
      days_week: [],
      detail: [],
    },
  ]);

  useEffect(() => {
    if (medicine_atual !== undefined && medicine_atual.posology !== undefined) {
      getListDosage(medicine_atual.name_display);

      if (medicine_atual.posology !== null) {
        setPosology(
          medicine_atual.posology.map((row) => ({
            ...row,
            date_start: new Date(`${row.date_start}T00:00:00`),
          }))
        );
      } else {
        setPosology([
          {
            id: 1,
            xdia: "",
            date_start: new Date(),
            duration: "",
            interval: "Diário",
            days_week: [],
            detail: [],
          },
        ]);
      }

      setNameDisplay(medicine_atual.name_display);
      setUnitMeasure(medicine_atual.unity_measure);
      setLsMpMedicine(
        medicine_atual.ls_mp_medicine
          ? medicine_atual.ls_mp_medicine
          : medicine_atual.id
      );
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [medicine_atual]);

  const handleChangeAutocomplete = async (obj) => {
    if (obj) {
      let all_mode_use = obj.mode_all.split("/");
      let use = "";
      if (all_mode_use.length === 1) {
        use = obj.mode_all;
      }

      validMedicine(obj.id);

      await getListDosage(obj.name_display);
      await setLsMpMedicine(obj.id);
      await setNameDisplay(obj.name_display);
      await setUnitMeasure(obj.unit_measure);

      xdiaInput.current.children[1].children[0].focus();
    }
  };

  const getListDosage = (name) => {
    Api.post(`/microservice/prescription/getListDosage/`, {
      medicine: name,
    }).then(({ data }) => {
      if (data.record.length > 0) {
        setListDosage(data.record);
      }
    });
  };

  const validMedicine = (med) => {
    //método que verifica se o paciente já utiliza um medicamento com o mesmo princípio ativo
    Api.post(`/microservice/prescription/validMedicine/`, {
      medicine: med,
      patient,
    }).then(({ data }) => {
      if (data.record.length > 0) {
        setInUse({
          in_use: true,
          data_use: data.record[0],
          date_start: new Date(`${data.record[0].use_end}T00:00:00`),
          min_date: new Date(`${data.record[0].use_end}T00:00:00`),
        });
      }
    });
  };

  const plusPosology = () => {
    let aux = posology.length;
    let lastDate = new Date(posology[aux - 1].date_start);
    let addDay = posology[aux - 1].duration;
    lastDate = new Date(
      lastDate.setDate(lastDate.getDate() + parseInt(addDay))
    );

    setPosology([
      ...posology,
      {
        id: aux + 1,
        xdia: "",
        date_start: lastDate,
        duration: "",
        interval: "Diário",
        days_week: [],
        detail: [],
      },
    ]);
  };

  const validate = () => {
    if (ls_mp_medicine) {
      let valid = true;
      posology.forEach((row) => {
        if (row.xdia && row.duration) {
          row.detail.forEach((dt) => {
            if (!dt.when || !dt.time || !dt.dosage || !dt.qtd) {
              valid = false;
            }
          });
        } else {
          valid = false;
        }
      });

      if (valid) {
        return true;
      } else {
        setError(true);
        return false;
      }
    } else {
      setError(true);
      return false;
    }
  };

  const savePlusPosology = () => {
    if (validate()) {
      if (edit) {
        dispatch(
          editMedicineComplex(
            medicine_atual.ls_mp_medicine,
            name_display,
            ls_mp_medicine,
            posology.map((row) => ({
              ...row,
              date_start: getFormatOnlyDate(row.date_start),
            })),
            patient,
            is_continuos
          )
        );
      } else {
        dispatch(
          addMedicineComplex(
            name_display,
            ls_mp_medicine,

            posology.map((row) => ({
              ...row,
              date_start: getFormatOnlyDate(row.date_start),
            })),
            patient,
            is_continuos
          )
        );
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setLsMpMedicine("");
    setNameDisplay("");
    setListDosage([]);
    setEdit(false);
    setError(false);
    setInUse(false);
    setDaysWeekModal({ open: false, id: "" });
    setPosology([
      {
        id: 1,
        xdia: "",
        date_start: new Date(),
        duration: "",
        interval: "Diário",
        days_week: [],
        detail: [],
      },
    ]);
    closeModal();
  };

  const handleRemovePosology = (index) => {
    setPosology(posology.filter((row, key) => key !== index));
  };

  const isChecked = (day) => {
    let aux = posology.find((i) => i.id === days_week_modal.id);
    if (aux !== undefined) {
      if (aux.days_week.find((e) => e === day) !== undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const dynamicsort = (property, order) => {
    let sort_order = 1;
    if (order === "desc") {
      sort_order = -1;
    }
    return function(a, b) {
      // a should come before b in the sorted order
      if (a[property] < b[property]) {
        return -1 * sort_order;
        // a should come after b in the sorted order
      } else if (a[property] > b[property]) {
        return 1 * sort_order;
        // a and b are the same
      } else {
        return 0 * sort_order;
      }
    };
  };

  let posology_order;
  if (posology !== null) {
    posology_order = posology.sort(dynamicsort("id", "asc"));
    posology_order.map((e) => {
      e.detail.sort(dynamicsort("id", "asc"));
    });
  }

  return (
    <>
      <ModalResponsive
        title="Posologia avançada"
        open={openModal}
        maxWidth={"lg"}
        close={() => handleClose()}
        buttons={
          <div className="col-12">
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => handleClose()}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right" }}
              onClick={() => savePlusPosology()}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Salvar </span>
            </Button>
          </div>
        }
      >
        <>
          {/* Padrões */}
          <MedicinesDefault plusPosology={true} />
          {/* Padrões */}
          <div className="row">
            <div className="col-md-8">
              <AutocompleteMedicine
                handleChangeAutocomplete={handleChangeAutocomplete.bind(this)}
                error={error && !ls_mp_medicine}
                medicine={{
                  id: ls_mp_medicine,
                  name: name_display,
                }}
                user={patient}
                plusPosology={true}
              />
            </div>
            {ls_mp_medicine && (
              <div className="col-md-4 mt-4">
                <Checkbox
                  checked={is_continuos === true || is_continuos === "1"}
                  onChange={() => setIsContinuous(!is_continuos)}
                  color="primary"
                />
                {"Uso contínuo"}
              </div>
            )}
          </div>

          {posology_order.map((pos, index) => (
            <>
              <div className="row">
                <div className="col-md-2">
                  <TextField
                    id="xdia"
                    type="number"
                    label="x/dia:"
                    value={pos.xdia}
                    onChange={(e) => {
                      let aux = posology.filter((j) => j.id !== pos.id);
                      let aux2 = posology.find((j) => j.id === pos.id);
                      aux2.xdia = e.target.value;
                      if (aux2.detail.length === 0) {
                        for (let i = 0; i < e.target.value; i++) {
                          aux2.detail.push({
                            id: i + 1,
                            when: "",
                            time: "",
                            dosage: "",
                            qtd: "",
                          });
                        }
                      } else {
                        if (aux2.detail.length < e.target.value) {
                          for (
                            let i = aux2.detail.length;
                            i < e.target.value;
                            i++
                          ) {
                            aux2.detail.push({
                              id: i + 1,
                              when: "",
                              time: "",
                              dosage: "",
                              qtd: "",
                            });
                          }
                        } else {
                          for (
                            let i = aux2.detail.length;
                            i > e.target.value;
                            i--
                          ) {
                            aux2.detail.pop();
                          }
                        }
                      }
                      setPosology([...aux, aux2]);
                    }}
                    fullWidth
                    margin="normal"
                    // error={error && !times_a_day}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    ref={xdiaInput}
                    error={error && !pos.xdia}
                  />
                </div>
                <div className="col-md-3" style={{ paddingTop: "16px" }}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={brLocale}
                  >
                    <KeyboardDatePicker
                      label="Data de início:"
                      value={pos.date_start}
                      onChange={(e) => {
                        let aux = posology.filter((j) => j.id !== pos.id);
                        let aux2 = posology.find((j) => j.id === pos.id);
                        aux2.date_start = e;
                        setPosology([...aux, aux2]);
                      }}
                      // minDate={min_date}
                      format="dd/MM/yyyy"
                      fullWidth
                      // disabled
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="col-md-3">
                  <TextField
                    id="tempo"
                    type="number"
                    // inputProps={{ min: "1", max: this.getLimitDuration() }}
                    label="Tempo de uso (dias):"
                    value={pos.duration}
                    onChange={(e) => {
                      let aux = posology.filter((j) => j.id !== pos.id);
                      let aux2 = posology.find((j) => j.id === pos.id);
                      aux2.duration = e.target.value;
                      setPosology([...aux, aux2]);
                    }}
                    fullWidth
                    margin="normal"
                    // error={error && !duration}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error && !pos.duration}
                  />
                </div>
                <div className="col-md-3">
                  <TextField
                    id="Intervalo"
                    select
                    label="Intervalo:"
                    value={pos.interval}
                    onChange={(e) => {
                      if (e.target.value === "Dias específicos") {
                        setDaysWeekModal({ open: true, id: pos.id });
                      }
                      let aux = posology.filter((j) => j.id !== pos.id);
                      let aux2 = posology.find((j) => j.id === pos.id);
                      aux2.interval = e.target.value;
                      setPosology([...aux, aux2]);
                    }}
                    SelectProps={{}}
                    fullWidth
                    style={{ marginTop: "17px" }}
                    // error={error && !interval}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem key="1" value="Diário">
                      Diário
                    </MenuItem>
                    <MenuItem key="12" value="Dia sim, dia não">
                      Dia sim, dia não
                    </MenuItem>
                    <MenuItem key="2" value="2x por semana">
                      2x por semana
                    </MenuItem>
                    <MenuItem key="3" value="3x por semana">
                      3x por semana
                    </MenuItem>
                    <MenuItem key="4" value="Semanal">
                      Semanal
                    </MenuItem>
                    <MenuItem key="5" value="15 em 15 dias">
                      De 15 em 15 dias
                    </MenuItem>
                    <MenuItem key="6" value="Mensal">
                      Mensal
                    </MenuItem>
                    <MenuItem key="7" value="Bimestral">
                      Bimestral
                    </MenuItem>
                    <MenuItem key="11" value="Trimestral">
                      Trimestral
                    </MenuItem>
                    <MenuItem key="10" value="Semestral">
                      Semestral
                    </MenuItem>
                    <MenuItem key="8" value="Anual">
                      Anual
                    </MenuItem>
                    <MenuItem key="9" value="Sempre">
                      Sempre
                    </MenuItem>
                    <MenuItem key="13" value="Dias específicos">
                      Dias específicos
                    </MenuItem>
                  </TextField>
                </div>
                <div className="col-md-1 mt-4">
                  {posology_order.length > 1 && (
                    <Tooltip title="Remover posologia">
                      <IconButton
                        onClick={() => handleRemovePosology(index)}
                        color="secondary"
                        aria-label="Remover"
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="row">
                {pos.detail.map((det, i) => (
                  <div className="col-md-12">
                    <div className="row">
                      <div
                        style={{
                          borderLeft: "solid 1px #ddd",
                          borderBottom: "solid 1px #ddd",
                          width: "15px",
                          height: "50px",
                          marginLeft: "15px",
                        }}
                      ></div>
                      <div className="col-md-3">
                        <TextField
                          id="when"
                          select
                          label="Quando:"
                          value={det.when}
                          onChange={(e) => {
                            setPosology(
                              posology.map((ps, idx) =>
                                idx === index
                                  ? {
                                      ...ps,
                                      detail: ps.detail.map((el) =>
                                        el.id === det.id
                                          ? {
                                              ...el,
                                              when: e.target.value,
                                            }
                                          : {
                                              ...el,
                                              when:
                                                !el.when &&
                                                e.target.value ===
                                                  "Horário específico"
                                                  ? e.target.value
                                                  : el.when,
                                            }
                                      ),
                                    }
                                  : ps
                              )
                            );
                          }}
                          SelectProps={{}}
                          fullWidth
                          style={{ marginTop: "17px" }}
                          // error={error && !interval}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error && !det.when}
                        >
                          <MenuItem key="1" value="De manhã">
                            De manhã
                          </MenuItem>
                          <MenuItem key="2" value="De tarde">
                            De tarde
                          </MenuItem>
                          <MenuItem key="3" value="De Noite">
                            De Noite
                          </MenuItem>
                          <MenuItem key="4" value="Antes do café">
                            Antes do café
                          </MenuItem>
                          <MenuItem key="5" value="Depois do café">
                            Depois do café
                          </MenuItem>
                          <MenuItem key="6" value="Antes do almoço">
                            Antes do almoço
                          </MenuItem>
                          <MenuItem key="7" value="Depois do almoço">
                            Depois do almoço
                          </MenuItem>
                          <MenuItem key="8" value="Antes do jantar">
                            Antes do jantar
                          </MenuItem>
                          <MenuItem key="9" value="Depois do jantar">
                            Depois do jantar
                          </MenuItem>
                          <MenuItem key="10" value="Horário específico">
                            Horário específico
                          </MenuItem>
                          <MenuItem key="11" value="Outro">
                            Outro
                          </MenuItem>
                        </TextField>
                      </div>
                      <div className="col-md-2">
                        <TextField
                          id="hr_notification"
                          label={
                            !det.when || det.when === "Horário específico"
                              ? "Horário:"
                              : "Hr. notificação:"
                          }
                          margin="normal"
                          fullWidth
                          InputProps={{
                            inputComponent: MaskHour,
                            value: det.time,
                            onChange: (e) => {
                              let interval = 24 / parseInt(pos.xdia);
                              setPosology(
                                posology.map((ps, idx) =>
                                  idx === index
                                    ? {
                                        ...ps,
                                        detail: ps.detail.map((el, j) =>
                                          el.id === det.id
                                            ? {
                                                ...el,
                                                time: e.target.value,
                                              }
                                            : {
                                                ...el,
                                                time: el.time
                                                  ? el.time
                                                  : e.target.value.indexOf(
                                                      "_"
                                                    ) === -1 &&
                                                    (!det.when ||
                                                      det.when ===
                                                        "Horário específico")
                                                  ? moment(
                                                      `2020-01-01 ${e.target.value}:00`
                                                    )
                                                      .add(
                                                        interval * j,
                                                        "hours"
                                                      )
                                                      .format("HH:mm")
                                                  : "",
                                              }
                                        ),
                                      }
                                    : ps
                                )
                              );
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error && !det.time}
                        />
                      </div>
                      <div className="col-md-3">
                        <TextField
                          id="dosage"
                          label="Formulação:"
                          value={det.dosage}
                          onChange={(e) => {
                            setPosology(
                              posology.map((ps, idx) =>
                                idx === index
                                  ? {
                                      ...ps,
                                      detail: ps.detail.map((el) =>
                                        el.id === det.id
                                          ? {
                                              ...el,
                                              dosage: e.target.value,
                                            }
                                          : {
                                              ...el,
                                              dosage: el.dosage
                                                ? el.dosage
                                                : e.target.value,
                                            }
                                      ),
                                    }
                                  : ps
                              )
                            );
                          }}
                          fullWidth
                          margin="normal"
                          select
                          SelectProps={{}}
                          style={{ marginTop: "17px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error && !det.dosage}
                        >
                          {listDosage.map((dos, dos_id) => (
                            <MenuItem key={dos_id} value={dos.dosage}>
                              {dos.dosage}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div className="col-md-3">
                        {unit_measure !== "com" ? (
                          <TextField
                            id="qtd"
                            type="number"
                            step="any"
                            label="Quantidade:"
                            value={det.qtd}
                            onChange={(e) => {
                              let aux = posology.filter((j) => j.id !== pos.id);
                              let aux2 = posology.find((j) => j.id === pos.id);
                              let aux3 = aux2.detail.filter(
                                (h) => h.id !== det.id
                              );
                              let aux4 = aux2.detail.find(
                                (h) => h.id === det.id
                              );
                              aux4.qtd = e.target.value;
                              aux3.push(aux4);
                              setPosology([...aux, aux2]);
                            }}
                            fullWidth
                            margin="normal"
                            // error={error && !quantity}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={error && !det.qtd}
                          />
                        ) : (
                          <TextField
                            id="qtd"
                            label="Quantidade:"
                            value={det.qtd}
                            onChange={(e) => {
                              let aux = posology.filter((j) => j.id !== pos.id);
                              let aux2 = posology.find((j) => j.id === pos.id);
                              let aux3 = aux2.detail.filter(
                                (h) => h.id !== det.id
                              );
                              let aux4 = aux2.detail.find(
                                (h) => h.id === det.id
                              );
                              aux4.qtd = e.target.value;
                              aux3.push(aux4);
                              setPosology([...aux, aux2]);
                            }}
                            fullWidth
                            margin="normal"
                            select
                            SelectProps={{}}
                            style={{ marginTop: "17px" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={error && !det.qtd}
                          >
                            <MenuItem key={1} value={"0.25"}>
                              {"1/4"}
                            </MenuItem>
                            <MenuItem key={2} value={"0.50"}>
                              {"1/2"}
                            </MenuItem>
                            <MenuItem key={13} value={"0.66"}>
                              {"2/3"}
                            </MenuItem>
                            <MenuItem key={3} value={"0.75"}>
                              {"3/4"}
                            </MenuItem>
                            <MenuItem key={4} value={"1.00"}>
                              {"1"}
                            </MenuItem>
                            <MenuItem key={5} value={"1.25"}>
                              {"1 e 1/4"}
                            </MenuItem>
                            <MenuItem key={6} value={"1.50"}>
                              {"1 e 1/2"}
                            </MenuItem>
                            <MenuItem key={7} value={"1.75"}>
                              {"1 e 3/4"}
                            </MenuItem>
                            <MenuItem key={8} value={"2.00"}>
                              {"2"}
                            </MenuItem>
                            <MenuItem key={9} value={"2.25"}>
                              {"2 e 1/4"}
                            </MenuItem>
                            <MenuItem key={10} value={"2.50"}>
                              {"2 e 1/2"}
                            </MenuItem>
                            <MenuItem key={11} value={"2.75"}>
                              {"2 e 3/4"}
                            </MenuItem>
                            <MenuItem key={12} value={"3.00"}>
                              {"3"}
                            </MenuItem>
                          </TextField>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <hr className="w-100" />
            </>
          ))}
          <div className="row">
            <div className="col-md-12">
              <Button
                color="primary"
                variant="contained"
                className="jr-btn jr-btn-sm"
                style={{ float: "left", marginTop: "15px" }}
                onClick={() => plusPosology()}
              >
                <i className="zmdi zmdi-plus zmdi-hc-lg" />
                <span> Adicionar Posologia </span>
              </Button>
            </div>
          </div>
        </>
      </ModalResponsive>
      {/* Modal para selecionar os dias da semana do intervalo de medicamentos */}
      <ModalResponsive
        title="Selecione os dias para a administração dos medicamentos"
        open={days_week_modal.open}
        close={() => {
          setDaysWeekModal({ open: false });
        }}
        buttons={
          <div className="col-12">
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right" }}
              onClick={() => {
                setDaysWeekModal({ open: false });
              }}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Ok </span>
            </Button>
          </div>
        }
        maxWidth="xs"
      >
        <>
          <div className="row">
            <div className="col-md-12">
              <Checkbox
                checked={isChecked("segunda")}
                onChange={() => {
                  let aux = posology.filter((j) => j.id !== days_week_modal.id);
                  let aux2 = posology.find((j) => j.id === days_week_modal.id);

                  if (
                    aux2.days_week.find((e) => e === "segunda") !== undefined
                  ) {
                    aux2.days_week = aux2.days_week.filter(
                      (e) => e !== "segunda"
                    );
                  } else {
                    aux2.days_week = [...aux2.days_week, "segunda"];
                  }

                  setPosology([...aux, aux2]);
                }}
                color="primary"
              />
              {"Segunda-feira"}
            </div>
            <div className="col-md-12">
              <Checkbox
                checked={isChecked("terca")}
                onChange={() => {
                  let aux = posology.filter((j) => j.id !== days_week_modal.id);
                  let aux2 = posology.find((j) => j.id === days_week_modal.id);

                  if (aux2.days_week.find((e) => e === "terca") !== undefined) {
                    aux2.days_week = aux2.days_week.filter(
                      (e) => e !== "terca"
                    );
                  } else {
                    aux2.days_week = [...aux2.days_week, "terca"];
                  }

                  setPosology([...aux, aux2]);
                }}
                color="primary"
              />
              {"Terça-feira"}
            </div>
            <div className="col-md-12">
              <Checkbox
                checked={isChecked("quarta")}
                onChange={() => {
                  let aux = posology.filter((j) => j.id !== days_week_modal.id);
                  let aux2 = posology.find((j) => j.id === days_week_modal.id);

                  if (
                    aux2.days_week.find((e) => e === "quarta") !== undefined
                  ) {
                    aux2.days_week = aux2.days_week.filter(
                      (e) => e !== "quarta"
                    );
                  } else {
                    aux2.days_week = [...aux2.days_week, "quarta"];
                  }

                  setPosology([...aux, aux2]);
                }}
                color="primary"
              />
              {"Quarta-feira"}
            </div>
            <div className="col-md-12">
              <Checkbox
                checked={isChecked("quinta")}
                onChange={() => {
                  let aux = posology.filter((j) => j.id !== days_week_modal.id);
                  let aux2 = posology.find((j) => j.id === days_week_modal.id);

                  if (
                    aux2.days_week.find((e) => e === "quinta") !== undefined
                  ) {
                    aux2.days_week = aux2.days_week.filter(
                      (e) => e !== "quinta"
                    );
                  } else {
                    aux2.days_week = [...aux2.days_week, "quinta"];
                  }

                  setPosology([...aux, aux2]);
                }}
                color="primary"
              />
              {"Quinta-feira"}
            </div>
            <div className="col-md-12">
              <Checkbox
                checked={isChecked("sexta")}
                onChange={() => {
                  let aux = posology.filter((j) => j.id !== days_week_modal.id);
                  let aux2 = posology.find((j) => j.id === days_week_modal.id);

                  if (aux2.days_week.find((e) => e === "sexta") !== undefined) {
                    aux2.days_week = aux2.days_week.filter(
                      (e) => e !== "sexta"
                    );
                  } else {
                    aux2.days_week = [...aux2.days_week, "sexta"];
                  }

                  setPosology([...aux, aux2]);
                }}
                color="primary"
              />
              {"Sexta-feira"}
            </div>
            <div className="col-md-12">
              <Checkbox
                checked={isChecked("sabado")}
                onChange={() => {
                  let aux = posology.filter((j) => j.id !== days_week_modal.id);
                  let aux2 = posology.find((j) => j.id === days_week_modal.id);

                  if (
                    aux2.days_week.find((e) => e === "sabado") !== undefined
                  ) {
                    aux2.days_week = aux2.days_week.filter(
                      (e) => e !== "sabado"
                    );
                  } else {
                    aux2.days_week = [...aux2.days_week, "sabado"];
                  }

                  setPosology([...aux, aux2]);
                }}
                color="primary"
              />
              {"Sábado"}
            </div>
            <div className="col-md-12">
              <Checkbox
                checked={isChecked("domingo")}
                onChange={() => {
                  let aux = posology.filter((j) => j.id !== days_week_modal.id);
                  let aux2 = posology.find((j) => j.id === days_week_modal.id);

                  if (
                    aux2.days_week.find((e) => e === "domingo") !== undefined
                  ) {
                    aux2.days_week = aux2.days_week.filter(
                      (e) => e !== "domingo"
                    );
                  } else {
                    aux2.days_week = [...aux2.days_week, "domingo"];
                  }

                  setPosology([...aux, aux2]);
                }}
                color="primary"
              />
              {"Domingo"}
            </div>
          </div>
        </>
      </ModalResponsive>
    </>
  );
}
