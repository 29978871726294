import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";

import { Typography, makeStyles, IconButton } from "@material-ui/core";
import { ButtonLarge } from "components/Button";
import CustomSlider from "components/CustomSlider";

import CloseIcon from "@material-ui/icons/Close";
import Dr_prancheta from "assets/images/dr_prancheta.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    width: "100%",
  },
  dFlex: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  box: {
    border: "solid 2px #c1c1c1",
    borderRadius: "25px",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
  },
  icon: {
    overflow: "inherit",
    fontSize: "40px",
    width: "auto",
    height: "auto",
  },
  positionBad: {
    position: "absolute",
    bottom: "-5px",
    left: 0,
  },
  positionGood: {
    position: "absolute",
    bottom: "-5px",
    right: 0,
  },
}));

export default function Hydration({ handleClose, saved }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    watter: saved ? saved.watter : "",
  });
  const max = saved && saved.max_watter ? Number(saved.max_watter) : 10;

  const classes = useStyles();

  function handleSave() {
    Api.post("microservice/patientportal/saveHydration", {
      ...state,
      id: saved ? saved.id : "",
    })
      .then(({ data }) => {
        if (data.success) {
          handleClose();
          localStorage.setItem("savedQuizze", "1");
        } else {
          throw true;
        }
      })
      .catch(() => {
        showMsg("error", "Não foi possível savar as informações", dispatch);
      });
  }
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-10 text-left">
          <Typography className="pt-1" variant="h6" gutterBottom>
            Hidratação
          </Typography>
        </div>
        <div className="col-2 ">
          <IconButton onClick={handleClose} color="default">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div
        className="card"
        style={{
          marginLeft: "-15px",
          marginRight: "-15px",
          paddingTop: "1rem",
        }}
      >
        <div className={classes.root}>
          <div className={classes.dFlex}>
            <div className="col-12 col-sm-12 col-md-8">
              <Typography variant="subtitle1" gutterBottom>
                <strong>Quantos copos d'àgua você bebeu?</strong>
              </Typography>
              <div className="row px-4 mt-4">
                <div className="col-12">
                  <span className={classes.positionBad}>Ruim</span>
                  <CustomSlider
                    step={1}
                    marks
                    min={1}
                    max={max + 4}
                    value={state.watter}
                    onChange={(event, newValue) => {
                      if (saved) {
                        newValue < saved.watter
                          ? setState({ ...state, watter: saved.watter })
                          : setState({ ...state, watter: newValue });
                      } else {
                        setState({ ...state, watter: newValue });
                      }
                    }}
                    valueLabelDisplay="on"
                    aria-labelledby="continuous-slider"
                    style={{
                      color: !state.watter
                        ? "#d1d1d1"
                        : state.watter >= max
                        ? "#00B1E2"
                        : state.watter > max / 2
                        ? "#EEE800"
                        : "#D6201B",
                    }}
                  />
                  <span className={classes.positionGood}>Bom</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 justify-content-end">
              <img src={Dr_prancheta} alt="Dra. prancehta" />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ButtonLarge
                onClick={handleClose}
                style={{
                  padding: "1px",
                  width: "unset",
                  minWidth: "100px",
                  float: "left",
                }}
              >
                Sair
              </ButtonLarge>
              <ButtonLarge
                onClick={state.watter ? handleSave : () => {}}
                style={{
                  padding: "1px",
                  width: "unset",
                  minWidth: "100px",
                  float: "right",
                  backgroundColor: state.watter ? "#3d8b40" : "unset",
                  color: state.watter ? "#fff" : "#8c8c8c",
                }}
              >
                Confirmar
              </ButtonLarge>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
