import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import api from "services/api";

const getListHabits = async patient => {
  const response = await api.post(`/microservice/procedure/getList/`, {
    patient
  });
  const data = await response.data.record;
  return data;
};

const getCadProcedure = async patient => {
  const response = await api.post(`/microservice/procedure/getCadList/`, {
    patient
  });

  const data = await response.data.record;
  return data;
};

const postAddProcedure = async params => {
  const { data, patient, user } = params;
  const obj = { data, patient, user };

  const response = await api.post(
    `/microservice/procedure/saveProcedure/`,
    obj
  );

  const res = await response.data;
  return res;
};

function* asyncGetList(action) {
  try {
    // variável que faz a busca
    const getListP = yield call(getListHabits, action.patient);
    // parte do método que identifica qual método usar após a conclusão do método acima
    yield put({ type: "GET_LIST_PROCEDURE", payload: { list: getListP } });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

function* asyncCadList(action) {
  try {
    const cadProcedure = yield call(getCadProcedure, action.patient);
    yield put({
      type: "REQUEST_CAD_PROCEDURE_SUCCESS",
      payload: { cadList: cadProcedure }
    });
  } catch (error) {
    console.log(error);
  }
}

function* requestAddNewProcedure(action) {
  try {
    const response = yield call(postAddProcedure, action);

    yield put({
      type: "ADD_PROCEDURE_SUCCESS",
      payload: { success: response.success }
    });

    yield call(asyncCadList, action);

    if (response.success) {
      yield put({
        type: "CHANGE_BUTTON_PROCEDURE",
        active: "view",
        color: "primary",
        text: "Adicionar",
        icon: "plus"
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!"
      });
    }
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!"
    });
    yield put({
      type: "ADD_PROCEDURE_SUCCESS",
      payload: { success: false }
    });
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  }
}

export function* requestList() {
  yield takeLatest("REQUEST_GET_PROCEDURE", asyncGetList);
}

export function* requestCadList() {
  yield takeLatest("REQUEST_CAD_PROCEDURE", asyncCadList);
}

export function* requestAddProcedure() {
  yield takeLatest("ADD_PROCEDURE", requestAddNewProcedure);
}

export default function* rootSaga() {
  yield all([
    fork(requestList),
    fork(requestCadList),
    fork(requestAddProcedure)
  ]);
}
