import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader";
import { Stepper, Step, StepLabel, Typography } from "@material-ui/core";
import Api from "services/api";
import apiFile from "services/apiFile";
import Header from "components/Header/index";
import ProvidedData from "./ProvidedData";
import RepresentanteMangers from "./RepresentanteMangers";
import { BtnSave } from "components/Button";
import Terms from "./ComponentTerms";
import Photo from "./ComponentPhoto";

function getSteps() {
  return [
    "Dados Prestador",
    "Representante e Gestores",
    "Adicionar Foto",
    "Termos de Uso",
  ];
}

export default function RegisterPatient({ match, modal, history }) {
  const [activeStep, setactiveStep] = React.useState(0);
  const initState = {
    skipped: new Set(),
  };
  const initValidate = {
    cpf: true,
    username: true,
    email: true,
    password: false,
  };
  const initData = {
    providedData: {
      name: "",
      corporateName: "",
      cnpj: "",
      cpf: "",
      phone: "",
      registerMuniciapl: "",
      registerState: "",
      typeProvider: "",
      date: "",
      email: "",
      cellphone: "",
      neighborhood: "",
    },
    photo: { image: "", blob: "" },
    managers: {},
  };

  const handleSave = async () => {
    Api.post(`/ms_system/user/saveRegisterIlpi`, {
      providedData: data.providedData,
      managers: data.managers,
    }).then(async (res) => {
      if (res.data.success) {
        if (data.photo.image !== "") {
          uploadPhotoUser(data.photo, res.data.id);
        }
        setactiveStep(4);
      }
    });
  };

  const [state, setState] = useState(initState);
  const [data, setData] = useState(initData);
  const [cadOk, setCadOk] = useState(false);
  const [validate, setValidate] = useState(initValidate);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ProvidedData
            history={history}
            handleBack={handleBack}
            handleNext={handleNext}
            validateData={validate}
            stateInit={data.providedData}
            handleSetState={handleSetState}
          />
        );
      case 1:
        return (
          <RepresentanteMangers
            history={history}
            getState={getState}
            handleNext={handleNext}
            handleBack={handleBack}
            validateData={validate}
            stateInit={data.providedData}
            handleSetState={handleSetState}
          />
        );
      case 2:
        if (data.providedData.type !== "0") {
          return (
            <>
              <Photo
                handleBack={handleBack}
                handleNext={handleNext}
                getState={handleSetState}
                stateInit={data.photo}
              />
            </>
          );
        }
      case 3:
        return (
          <>
            <Terms
              handleBack={handleBack}
              handleNext={handleNext}
              getState={getState}
            />{" "}
            <BtnSave title={"salvar"} onClick={handleSave} float="right" />
          </>
        );
      case 4:
        return (
          <>
            <div className="col-12 justify-content-center">
              <p style={{ textAlign: "center" }}>
                <strong>Cadastro realizado com sucesso!</strong>
              </p>
              <p style={{ textAlign: "center" }}>
                Acesse o portal <strong>SalutemPlus</strong> atrav&eacute;s do
                perfil do{" "}
                <strong>Respons&aacute;vel legal ou Gestor de sua ILPI</strong>.
              </p>
              <BtnSave title={"ok"} onClick={handleSetLocal} float="right" />
            </div>
          </>
        );
      default:
        return "";
    }
  };

  const handleSetLocal = () => {
    history.push("/login");
  };

  const uploadPhotoUser = async (photo, user) => {
    let formData = new FormData();
    formData.append("user", user);
    let block = photo.image.split(";");
    let contentType = block[0].split(":")[1];
    let realData = block[1].split(",")[1];
    let blob = await b64toBlob(realData, contentType);
    formData.append("image", blob);

    apiFile.post(`/ms_system/user/savePhotoRegister/`, formData);
  };

  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const getState = async (stateForm, form) => {
    // console.log("stateForm", stateForm);
    // console.log("form", form);
    // if (form === "validate") {
    //   await setValidate(stateForm);
    // } else {
    //   await setData({ ...data, [form]: { ...stateForm } });
    // }
  };

  const handleSetState = async (type, value) => {
    await setData({ ...data, [type]: { ...value } });
  };

  const handleNext = () => {
    setactiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setactiveStep(activeStep - 1);
  };

  const handleReset = () => {
    setState({
      ...state,
      activeStep: 0,
    });
  };

  const getContainer = () => (
    <div className="container">
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className="horizontal-stepper-linear"
      >
        {steps.map((label, index) => {
          return (
            <Step
              key={label}
              className={`horizontal-stepper ${
                index === activeStep ? "active" : ""
              }`}
            >
              <StepLabel className="stepperlabel">{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>
          <Typography className="my-2">{getStepContent(activeStep)}</Typography>
        </div>
        <SweetAlert
          success
          show={cadOk}
          showConfirm={false}
          title="Cadastrado completado com sucesso."
        />
      </div>
    </div>
  );

  const steps = getSteps();
  if (!modal) {
    return (
      <div className={`app-container`}>
        <div className="app-main-container">
          <div className={`app-header`}>
            <Header left={true} />
          </div>
          <main className="app-main-content-wrapper">
            <div className="container mt-2">
              <ContainerHeader title="Cadastre-se" match={match} />
              <Card className="shadow border-0">
                <CardBody>
                  {getContainer()}
                  {/* <div
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent:
                        activeStep !== 0 ? "space-between" : "flex-end",
                    }}
                  >
                    {activeStep !== 0 && (
                      <Button className="jr-btn" onClick={handleBack}>
                        Voltar
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className="jr-btn"
                      style={{ float: "right", marginRight: 0 }}
                    >
                      Próxima
                    </Button>
                  </div> */}
                </CardBody>
              </Card>
            </div>
          </main>
        </div>
      </div>
    );
  } else {
    return getContainer();
  }
}
