import React from "react";
import MaskedInput from "react-text-mask";

const MaskCns = (props) => {
  let { inputRef, ...others } = props;
  return (
    <MaskedInput
      {...others}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  );
};

export default MaskCns;
