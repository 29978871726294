import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Tooltip,
} from "@material-ui/core";
import AutocompleteHealthPlan from "components/Autocomplete/HealthPlan";
import Api from "services/api";

export default function PersonalData({
  user,
  handleNext,
  handleBack,
  getState,
  stateInit,
}) {
  const [state, setState] = useState(stateInit);

  const handleProx = () => {
    getState(state, "healthPlan");
    handleNext();
  };

  useEffect(() => {
    if (user) {
      Api.post(`/ms_system/user/getHealthPlansUser`, { user }).then((res) => {
        setState(res.data.record);
      });
    }
  }, [user]);

  const handleAdd = () => {
    setState([
      ...state,
      {
        id: "",
        plan_id: "",
        plan_name: "",
        register: "",
        validate: "",
        identfy: "",
        alter: false,
      },
    ]);
  };

  const handleRemove = (index) => {
    setState(state.filter((row, key) => key !== index));
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Table className="table-history">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <AutocompleteHealthPlan
                      healthPlan={{
                        id: row.plan_id,
                        name: row.plan_name,
                      }}
                      handleChangeAutocomplete={(obj) => {
                        setState(
                          state.map((st, key) =>
                            key === index ? { ...st, ...obj, alter: true } : st
                          )
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      label="Registro/Carteirinha"
                      margin="normal"
                      fullWidth
                      value={row.register}
                      onChange={(e) => {
                        setState(
                          state.map((st, key) =>
                            key === index
                              ? { ...st, register: e.target.value, alter: true }
                              : st
                          )
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      label="Validade"
                      type="date"
                      margin="normal"
                      fullWidth
                      value={row.validate}
                      onChange={(e) => {
                        setState(
                          state.map((st, key) =>
                            key === index
                              ? { ...st, validate: e.target.value, alter: true }
                              : st
                          )
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {state.length > 1 && (
                      <Tooltip title="Remover registro">
                        <IconButton
                          onClick={() => handleRemove(index)}
                          color="secondary"
                          aria-label="Remover"
                        >
                          <i className="zmdi zmdi-delete zmdi-hc-fw" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {state.length - 1 === index && (
                      <Tooltip title="Adicionar novo registro">
                        <IconButton
                          onClick={() => handleAdd()}
                          color="primary"
                          aria-label="Editar"
                        >
                          <i className="zmdi zmdi-plus zmdi-hc-fw" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="align-center mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={handleProx}
          className="jr-btn"
          style={{ float: "right", marginRight: 0 }}
        >
          Próxima
        </Button>
        <Button
          className="jr-btn"
          onClick={handleBack}
          style={{ float: "left", marginLeft: 0 }}
        >
          Voltar
        </Button>
      </div>
    </>
  );
}
