const INIT_STATE = {
  modal_config: false,
  modal_config_view: "",
  config_system_review: {
    categories: [
      { id: 7, description: "Cutâneo", obs: "" },
      { id: 2, description: "Genito-urinário", obs: "" },
      { id: 8, description: "Lesões", obs: "" },
      { id: 9, description: "Membros superiores e inferiores", obs: "" },
      { id: 6, description: "Orgão dos Sentidos", obs: "" },
      { id: 1, description: "Osteomuscular", obs: "" },
      { id: 3, description: "Sistema Cardiovascular", obs: "" },
      { id: 5, description: "Sistema Digestivo", obs: "" },
      { id: 10, description: "Sistema Nervoso", obs: "" },
      { id: 4, description: "Sistema Respiratório", obs: "" },
    ],
    record: [{ view: "" }],
  },
  config_aga: {
    forms: [],
    record: [],
    loaded: false,
  },
  config_sidenav_consult: {
    record: [],
    loaded: false,
  },
  config_vital_signs: {
    record: [],
    loaded: false,
  },
  config_anamnesis: {
    id: "",
    view: "",
  },
  config_admission: {
    id: "",
    view: "",
  },
  list_admission: [],
  compoment_admission_config: {
    data_patient: "",
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SHOW_MODAL_CONFIG": {
      return {
        ...state,
        modal_config: true,
        modal_config_view: action.view,
      };
    }
    case "HIDE_MODAL_CONFIG": {
      return {
        ...state,
        modal_config: false,
        modal_config_view: "",
      };
    }
    // case "GET_RECORD_SR_SUCCESS": {
    //   return {
    //     ...state,
    //     config_system_review: {
    //       ...state.config_system_review,
    //       // categories: action.payload.categories,
    //       record: rec
    //     }
    //   };
    // }
    case "CHANGE_CONFIG_SR": {
      return {
        ...state,
        config_system_review: {
          ...state.config_system_review,
          record: [
            {
              id: action.id,
              view: action.view,
            },
          ],
        },
      };
    }
    case "GET_RECORD_AGA_SUCCESS": {
      return {
        ...state,
        config_aga: {
          forms: action.payload.forms,
          record: action.payload.record,
          loaded: true,
        },
      };
    }
    case "CHANGE_CONFIG_AGA": {
      return {
        ...state,
        config_aga: {
          ...state.config_aga,
          record: [
            {
              id: action.id,
              view: action.view,
            },
          ],
        },
      };
    }
    case "GET_RECORD_SNC_SUCCESS": {
      let rec = [{ view: "" }];
      if (action.payload.recordSr.length > 0) {
        rec = action.payload.recordSr;
      }
      return {
        ...state,
        config_sidenav_consult: {
          record: action.payload.record,
          loaded: true,
        },
        config_system_review: {
          ...state.config_system_review,
          record: rec,
        },
      };
    }
    case "CHANGE_CONFIG_SNC": {
      return {
        ...state,
        config_sidenav_consult: {
          ...state.config_sidenav_consult,
          record: [
            {
              id: action.id,
              view: action.view,
            },
          ],
        },
      };
    }
    case "GET_RECORD_VS_SUCCESS": {
      return {
        ...state,
        config_vital_signs: {
          record: action.payload.record,
          loaded: true,
        },
      };
    }
    case "CHANGE_CONFIG_VS": {
      return {
        ...state,
        config_vital_signs: {
          ...state.config_vital_signs,
          record: [
            {
              id: action.id,
              view: action.view,
            },
          ],
        },
      };
    }
    case "CHANGE_CONFIG_ANAMNESIS": {
      return {
        ...state,
        config_anamnesis: {
          id: action.id,
          view: action.view,
        },
      };
    }
    case "CHANGE_CONFIG_ADMISSION": {
      return {
        ...state,
        config_admission: {
          id: action.payload.id,
          view: action.payload.view,
          view_sub_group: action.payload.view_sub_group,
        },
      };
    }
    case "SET_LIST_ADMISSION": {
      return {
        ...state,
        list_admission: action.list,
      };
    }
    case "SET_COMPOMENT_ADMISSION": {
      return {
        ...state,
        compoment_admission_config: {
          ...state.compoment_admission_config,
          [action.payload.compoment]: action.payload.value,
        },
      };
    }
    case "RESET_CONFIGURATION": {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
