import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from "components/CircularProgress";
import { Card, CardBody } from "reactstrap";
import { BtnAdd } from "components/Button";
import { Tooltip, IconButton } from "@material-ui/core";
import FormPause from "./FormPause";
import NoRecord from "components/Alert/NoRecord";

export default function Pause({ patient, type, form, beforeSave }) {
  const [to_delete, setToDelete] = useState("");
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [loader, setLoader] = useState(true);
  const [modal, setModal] = useState({ open: false, data: { id: "" } });
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Api.post(`/microservice/patientportal/getSchedulePause/`, {
      patient,
    }).then((res) => {
      setRecord(res.data.record);
      setList(res.data.list);
      setLoader(false);
    });
  };

  const confirmDelete = () => {
    Api.post(`/microservice/patientportal/removeSchedulePause/`, {
      id: to_delete,
    })
      .then(() => {
        setModalDelete(false);
        setSuccessDelete(true);
        setRecord(record.filter((row) => row.id !== to_delete));
      })
      .catch(() => {
        setModalDelete(false);
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => {
          dispatch(closeAlertMessage());
        }, 5000);
      });
  };

  const handleAdd = (obj) => {
    if (modal.data.id) {
      setRecord(record.map((row) => (row.id === modal.data.id ? obj : row)));
    } else {
      setRecord([obj, ...record]);
    }
    if (form === "dash") {
      beforeSave();
    }
  };

  return (
    <Card className={form === "dash" ? "border-0" : "shadow border-0 mb-2"}>
      <CardBody>
        <div className="row">
          <div className="col-12">
            <h4
              className="text-primary"
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              Pausa Programada
              <BtnAdd
                title="Adicionar"
                float="right"
                onClick={() =>
                  setModal({
                    open: true,
                    data: { id: "" },
                  })
                }
              />
            </h4>
          </div>
          {loader ? (
            <CircularProgress />
          ) : (
            <>
              {record.map((row, index) => (
                <>
                  <div className="col-md-10 col-xs-9 mt-2">
                    <strong>{row.name}</strong> <br />
                    <span>
                      {row.repetition} de {row.start.substr(0, 5)} às{" "}
                      {row.end.substr(0, 5)} a cada {row.interval.substr(0, 5)}
                    </span>
                  </div>
                  <div
                    className="col-md-2 col-xs-3 d-flex"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Tooltip title="Editar" placement="top">
                      <IconButton
                        onClick={() =>
                          setModal({
                            open: true,
                            data: row,
                          })
                        }
                        color="primary"
                        aria-label="Editar"
                      >
                        <i className="zmdi zmdi-edit zmdi-hc-fw" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remover" place="top">
                      <IconButton
                        aria-label="Delete"
                        color="secondary"
                        onClick={() => {
                          setToDelete(row.id);
                          setModalDelete(true);
                        }}
                      >
                        <i className="zmdi zmdi-delete" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <hr className="w-100" />
                </>
              ))}
              {record.length === 0 && <NoRecord />}
            </>
          )}
        </div>
        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, remover"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Confirma a remoção deste registro?"
          onConfirm={confirmDelete}
          onCancel={() => setModalDelete(false)}
        />
        <SweetAlert
          show={success_delete}
          success
          title="Registro removido com sucesso!"
          onConfirm={() => setSuccessDelete(false)}
        />
      </CardBody>
      <FormPause
        open={modal.open}
        data={modal.data}
        handleClose={() => {
          setModal({ open: false, data: { id: "" } });
        }}
        patient={patient}
        type={type}
        record={record.map((row) => row.activitie)}
        list={list}
        handleAdd={handleAdd}
      />
    </Card>
  );
}
