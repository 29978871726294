import React from "react";

import ViewPersonalHygiene from "./ViewPersonalHygiene";
import ViewOralDiet from "./ViewOralDiet";
import ViewEnteralDiet from "./ViewEnteralDiet";
import ViewHydration from "./ViewHydration";
import ViewCurative from "./ViewCurative";
import ViewDecubits from "./ViewDecubits";
import ViewVitalSigns from "./ViewVitalSigns";
import ViewSondaVesical from "./ViewSondaVesical";
import ViewPoints from "./ViewPoints";
import ViewElevatedHeadboard from "./ViewElevatedHeadboard";
import ViewOxygentherapy from "./ViewOxygentherapy";
import ViewMoviment from "./ViewMoviment";
import ViewInflatableLeggings from "./ViewInflatableLeggings";
import ViewPainScale from "./ViewPainScale";
import ViewFallRisk from "./ViewFallRisk";

export default function CareViews({
  typeCare,
  record,
  is_pattern = false,
  justInfo = false
}) {
  return (
    <>
      {typeCare === "1" && (
        <ViewPersonalHygiene
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "2" && (
        <ViewElevatedHeadboard
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "3" && (
        <ViewDecubits
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "4" && (
        <ViewCurative
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "5" && (
        <ViewOralDiet
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "6" && (
        <ViewEnteralDiet
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "7" && (
        <ViewHydration
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "8" && (
        <ViewVitalSigns
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "9" && (
        <ViewPoints data={record} is_pattern={is_pattern} justInfo={justInfo} />
      )}
      {typeCare === "10" && (
        <ViewSondaVesical
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "11" && (
        <ViewOxygentherapy
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "12" && (
        <ViewMoviment
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "13" && (
        <ViewInflatableLeggings
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "14" && (
        <ViewPainScale
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
      {typeCare === "15" && (
        <ViewFallRisk
          data={record}
          is_pattern={is_pattern}
          justInfo={justInfo}
        />
      )}
    </>
  );
}
