import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Tooltip,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import ComponentsProfile from "components/ComponentsProfile";
import Api from "services/api";
import { BtnSave, BtnCan } from "components/Button";
import { showMsg } from "services/functions";
import moment from "moment";

export default function WaitingRoom({ open, handleClose }) {
  const [record, setRecord] = useState([]);
  const [error, setError] = useState(false);
  const objIn = {
    open: false,
    event: "",
    hour: "",
    type: "",
  };
  const [modalIn, setModalIn] = useState(objIn);
  const address = useSelector((state) => state.auth.address);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      Api.post(`/ms_system/calendar/getWaitingRoom/`, {
        address,
      }).then((res) => {
        setRecord(res.data.record);
      });
    }
  }, [open]);

  const handleIn = () => {
    Api.post(`/ms_system/calendar/editStatusEvent/`, {
      multiple: modalIn.type === "2",
      event: modalIn.event,
      status: "4",
      opt: "entered",
      otherValue: modalIn.hour,
    }).then(() => {
      setModalIn(objIn);
      setRecord(record.filter((row) => row.id !== modalIn.event));
      showMsg("success", "Salvo com sucesso!", dispatch);
    });
  };

  return (
    <ModalResponsive
      title="Sala de espera"
      open={open}
      close={handleClose}
      maxWidth="md"
      buttons={
        <div className="col-12">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={handleClose}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Fechar </span>
          </Button>
        </div>
      }
    >
      <div className="row">
        {record.length > 0 ? (
          <div className="col-md-12">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Paciente</TableCell>
                  <TableCell>Chegada</TableCell>
                  <TableCell>Agendado</TableCell>
                  <TableCell>Tempo de espera</TableCell>
                  <TableCell>Profissional</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell align="left">
                        <ComponentsProfile
                          nameProfile={row.name}
                          photo={row.photo}
                          id={row.pacient_id}
                        />
                      </TableCell>
                      <TableCell>{row.arrival.substr(0, 5)}</TableCell>
                      <TableCell>{row.hour}</TableCell>
                      <TableCell>
                        {moment().diff(
                          moment(
                            `${moment().format("YYYY-MM-DD")} ${row.arrival}`
                          ),
                          "minutes"
                        )}{" "}
                        minutos
                      </TableCell>
                      <TableCell>
                        <ComponentsProfile
                          nameProfile={row.doctor_name}
                          photo={row.photo_doctor}
                          id={row.doctor}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title="Marcar entrada para atendimento">
                          <IconButton
                            arial-label="start-attendance"
                            color="primary"
                            onClick={() =>
                              setModalIn({
                                open: true,
                                event: row.id,
                                hour: moment().format("HH:mm"),
                                type: row.type,
                              })
                            }
                          >
                            <i className="zmdi zmdi-check" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div
            color="light"
            style={{ width: "100%", textAlign: "center", marginTop: "10px" }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#a6a6a6",
                fontStyle: "italic",
              }}
            >
              Nenhum paciente aguardando atendimento neste momento.
            </p>
          </div>
        )}
      </div>
      <Dialog open={modalIn.open} onClose={() => setModalIn(objIn)}>
        <DialogTitle className="text-primary font-weight-semibold">
          Entrada para atendimento
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mt-3">
              <TextField
                type="time"
                id="value"
                label="Entrada consultório:"
                value={modalIn.hour}
                onChange={(e) =>
                  setModalIn({
                    ...modalIn,
                    hour: e.target.value,
                  })
                }
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="col-12">
            <BtnCan
              title={"Cancelar"}
              onClick={() => setModalIn(objIn)}
              float="left"
            />
            <BtnSave
              title={"Salvar"}
              onClick={() => handleIn()}
              float="right"
            />
          </div>
        </DialogActions>
      </Dialog>
    </ModalResponsive>
  );
}
