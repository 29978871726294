import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  IconButton
} from "@material-ui/core";
import Api from "services/api";
import CircularProgress from "components/CircularProgress";

export default function ViewQuestionnaire({ record, onClose }) {
  const [answer, setAnswer] = useState({ id: "" });

  useEffect(() => {
    if (record.id) {
      Api.post(`/microservice/systemreview/getAnswerLimbsHistory/`, {
        questionnaire: record.id
      }).then(res => {
        setAnswer(res.data.record[0]);
      });
    }
  }, []);

  return answer.id ? (
    <div className="row">
      <div className="col-12">
        <strong className="mr-1">Profissional:</strong>
        {record.name}
        <strong className="ml-3 mr-1">Especialidade:</strong>
        {record.specialty}
        <strong className="ml-3 mr-1">Data:</strong>
        {record.date}
        <Tooltip title="Voltar" placement="top">
          <IconButton
            aria-label="Voltar"
            onClick={() => onClose()}
            style={{ float: "right", marginTop: "-10px" }}
          >
            <i className="zmdi zmdi-close" />
          </IconButton>
        </Tooltip>
      </div>
      <div className="col-12">
        <strong>Movimentação: </strong> {answer.movement}
      </div>
      <div className="col-12">
        <strong>Edema de MMII: </strong> {answer.edema === "1" ? "Sim" : "Não"}
      </div>
      <div className="col-12">
        <strong>Dor nos MMII ao caminhar: </strong>{" "}
        {answer.pain === "1" ? "Sim" : "Não"}
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="left">Sensibilidade preservada</TableCell>
            <TableCell align="left">Parestesia</TableCell>
            <TableCell align="left">Câimbras</TableCell>
            <TableCell align="left">Varizes</TableCell>
            <TableCell align="center">Força Motora</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="0">
            <TableCell component="th" scope="row">
              MSD
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.msd_sensibility === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.msd_paraesthesia === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.msd_cramps === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.msd_varicose === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.msd_force ? answer.msd_force : "---"}
            </TableCell>
          </TableRow>
          <TableRow key="1">
            <TableCell component="th" scope="row">
              MSE
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mse_sensibility === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mse_paraesthesia === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mse_cramps === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mse_varicose === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mse_force ? answer.mse_force : "---"}
            </TableCell>
          </TableRow>
          <TableRow key="2">
            <TableCell component="th" scope="row">
              MID
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mid_sensibility === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mid_paraesthesia === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mid_cramps === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mid_varicose === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mid_force ? answer.mid_force : "---"}
            </TableCell>
          </TableRow>
          <TableRow key="3">
            <TableCell component="th" scope="row">
              MIE
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mie_sensibility === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mie_paraesthesia === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mie_cramps === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mie_varicose === "1" ? "Sim" : "Não"}
            </TableCell>
            <TableCell component="th" scope="row">
              {answer.mie_force ? answer.mie_force : "---"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  ) : (
    <CircularProgress />
  );
}
