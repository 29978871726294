import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import ContainerHeader from "components/ContainerHeader";
import { Card, CardBody } from "reactstrap";
import {
  Paper,
  Avatar,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
  Button,
  RadioGroup,
  Radio,
  MenuItem,
} from "@material-ui/core";
import { getUrlPhoto } from "services/functions";
import CloseIcon from "@material-ui/icons/Close";
import { BtnSave, BtnCan } from "components/Button";
import MaskTemperature from "components/Masks/MaskTemperature";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HealingIcon from "@material-ui/icons/Healing";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import GroupIcon from "@material-ui/icons/Group";
import AutocompleteUser from "components/Autocomplete/AutocompleteUser";
import AutocompleteCity from "components/Autocomplete/AutocompleteCity";
import RegisterUser from "components/Forms/RegisterUser";
import FormComorbidity from "./FormComorbidity";
import ListVaccines from "./ListVaccines";
import FormSymptoms from "./FormSymptoms";
import { SYMPTOMS_ARRAY } from "constants/CustomSymptoms";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#177493 !important",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline !important",
    },
  },
  text: {
    color: "#0000008a",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1.3125rem",
    marginTop: "20px",
    marginBottom: "0px",
    textAlign: "center",
  },
  userName: {
    marginBottom: "2px",
    fontWeight: "500",
  },
  userDescription: {
    fontSize: "13px",
    marginBottom: "0",
    color: "grey",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

export default function SchedulerStepper({ match, history }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [initialMode, setInitialMode] = useState(0);
  const [mode, setMode] = useState(0);
  const [accept, setAccept] = useState(false);
  const [selecteds, setSelecteds] = useState([]);
  const [comorbiditys, setComorbiditys] = useState([]);
  const [comorbidityOther, setComorbidityOther] = useState("");
  const [vaccine, setVaccine] = useState("");
  const [vaccineReaction, setVaccineReaction] = useState("");
  const [vaccineReactionList, setVaccineReactionList] = useState([]);
  const [vaccineReactionOther, setVaccineReactionOther] = useState("");
  const [reasonNotVaccine, setReasonNotVaccine] = useState("");
  const [reasonNotVaccineOther, setReasonNotVaccineOther] = useState("");
  const [temperature, setTemperature] = useState("");

  const [record, setRecord] = useState([]);

  const [listSymptoms, setListSymptoms] = useState(
    SYMPTOMS_ARRAY.map(
      (row) =>
        row && { ...row, text: "", dateStart: "", dateEnd: "", gravity: "" }
    )
  );

  const initial_sequel = {
    date: "",
    fatigue: "",
    cough: "",
    smell: "",
    taste: "",
    muscle: "",
    sex: "",
    others: "",
  };
  const [sequel, setSequel] = useState(initial_sequel);

  const [city, setCity] = useState({
    id: "1668",
    label: "Cabo Verde - Minas Gerais - Brasil",
  });
  const [modalRegisterUser, setModalRegisterUser] = useState({
    open: false,
    name: "",
  });
  const [error, setError] = useState(false);
  const [hideVaccine, setHideVaccine] = useState(false);
  const authUser = useSelector((state) => state.auth.authUser);
  const authType = useSelector((state) => state.auth.type);
  const [auth, setAuth] = useState({});
  const [user, setUser] = useState({});

  useEffect(() => {
    Api.post(`/ms_system/notificationcovid/getInfoUser/`, {
      user: authUser,
    }).then((res) => {
      let obj = {
        id: res.data.record.id,
        name: res.data.record.fullname,
        photo: res.data.record.photo_name,
        birthday: moment(res.data.record.birthday).format("DD/MM/YYYY"),
      };

      setAuth(obj);

      if (res.data.record.type === "3") {
        setInitialMode(4);
        setMode(4);
        setUser(obj);
      }
    });
    if (authType === "1") {
      setMode(2);
    }
  }, []);

  useEffect(() => {
    if (mode === 4 && authUser) {
      getDataVaccine(authUser);
    }
    if (mode === 1 && user.id) {
      getSymptoms();
    }

    if (mode === 6) {
      getSequels();
    }
  }, [mode, authUser]);

  const getSymptoms = () => {
    if (listSymptoms) {
      Api.post(`/microservice/consult/getInitialCovid`, {
        patient: user.id,
      })
        .then((res) => {
          let listNew = listSymptoms;
          listNew.forEach((element, idx) => {
            res.data.record.forEach((row) => {
              if (row.fk_ls_table_type === element.id) {
                listSymptoms[idx] = {
                  ...element,
                  id_symptoms: row.id,
                  dateStart: row.start,
                  gravity: row.gravity,
                  status: true,
                  text: row.conduct,
                };
              }
            });
          });

          setListSymptoms(listNew);
        })
        .catch(() => {});
    }
  };

  const getSequels = () => {
    Api.post(`/ms_system/notificationcovid/getSequels/`, {
      patient: authUser,
    }).then((res) => {
      let { record } = res.data;
      if (record.length > 0) {
        record = record[0];
        setSequel({
          date: record.sequel_date,
          fatigue: record.sequel_fatigue,
          cough: record.sequel_cough,
          smell: record.sequel_smell,
          taste: record.sequel_taste,
          muscle: record.sequel_muscle,
          sex: record.sequel_sex,
          others: record.sequel_others,
        });
      }
    });
  };

  useEffect(() => {
    setSelecteds([]);
    // setComorbiditys([]);
    setTemperature("");
    // setVaccine("");
    // setRecord([]);
  }, [user]);

  useEffect(() => {
    if (vaccine === "1" && user.id && user.id !== authUser) {
      getDataVaccine(user.id);
    }
  }, [vaccine]);

  const getDataVaccine = (patient) => {
    Api.post(`/ms_system/notificationcovid/getDataVaccines/`, {
      patient,
    }).then((res) => {
      let { data, comorbiditys } = res.data;

      if (comorbiditys.length > 0) {
        setComorbiditys(comorbiditys[0].comorbiditys.split(","));
        if (comorbiditys[0].comorbiditys_other) {
          setComorbidityOther(comorbiditys[0].comorbiditys_other);
        }
      }

      let obj_aux = {
        id: "",
        vaccine: "",
        name: "",
        applied: "",
        date: "",
        observation: "",
        alter: false,
        added_for: "",
        approved_by: "",
        created: "",
        view: "",
      };
      let obj_first = { ...obj_aux, dose: "1° Dose" };
      let obj_second = { ...obj_aux, dose: "2° Dose" };
      let obj_ref = { ...obj_aux, dose: "Reforço" };
      let first_dosage = false;
      let second_dosage = false;
      let ref_dosage = false;
      let unique_dosage = false;

      if (data.length > 0) {
        data.forEach((el) => {
          if (el.is_applied === "1" && vaccine !== "1") {
            setVaccine("1");
          }
          if (el.dosage_id === "3") {
            first_dosage = true;
            obj_first = {
              id: el.id,
              dose: "1° Dose",
              vaccine: el.vaccine,
              name: el.name,
              applied: el.is_applied,
              date:
                el.is_applied === "0" ? el.expected_date : el.date_of_vaccine,
              observation: el.observation ? el.observation : "",
              alter: false,
              added_for: el.added_for,
              approved_by: el.approved_by,
              city: el.city,
              created: el.created,
              view: el.is_applied === "1",
            };
          }
          if (el.dosage_id === "4") {
            second_dosage = true;
            obj_second = {
              id: el.id,
              dose: "2° Dose",
              vaccine: el.vaccine,
              name: el.name,
              applied: el.is_applied,
              date:
                el.is_applied === "0" ? el.expected_date : el.date_of_vaccine,
              observation: el.observation ? el.observation : "",
              alter: false,
              added_for: el.added_for,
              approved_by: el.approved_by,
              city: el.city,
              created: el.created,
              view: el.is_applied === "1",
            };
          }
          if (el.dosage_id === "17") {
            ref_dosage = true;
            obj_ref = {
              id: el.id,
              dose: "Reforço",
              vaccine: el.vaccine,
              name: el.name,
              applied: el.is_applied,
              date:
                el.is_applied === "0" ? el.expected_date : el.date_of_vaccine,
              observation: el.observation ? el.observation : "",
              alter: false,
              added_for: el.added_for,
              approved_by: el.approved_by,
              city: el.city,
              created: el.created,
              view: el.is_applied === "1",
            };
          }
          if (el.dosage_id === "8") {
            unique_dosage = true;
            obj_first = {
              id: el.id,
              dose: "1° Dose",
              vaccine: el.vaccine,
              name: el.name,
              applied: el.is_applied,
              date:
                el.is_applied === "0" ? el.expected_date : el.date_of_vaccine,
              observation: el.observation ? el.observation : "",
              alter: false,
              added_for: el.added_for,
              approved_by: el.approved_by,
              created: el.created,
              city: el.city,
              view: el.is_applied === "1",
            };
          }
        });

        setRecord([obj_first, obj_second, obj_ref]);

        if (
          ((first_dosage && second_dosage && obj_second.applied === "1") ||
            unique_dosage) &&
          ref_dosage &&
          obj_ref.applied === "1"
        ) {
          setHideVaccine(true);
        }
      } else {
        setRecord([
          {
            id: "",
            dose: "1° Dose",
            vaccine: "",
            name: "",
            applied: "",
            date: "",
            observation: "",
            alter: false,
            added_for: "",
            approved_by: "",
            created: "",
          },
          {
            id: "",
            dose: "2° Dose",
            vaccine: "",
            name: "",
            applied: "",
            date: "",
            observation: "",
            alter: false,
            added_for: "",
            approved_by: "",
            created: "",
          },
          {
            id: "",
            dose: "Reforço",
            vaccine: "",
            name: "",
            applied: "",
            date: "",
            observation: "",
            alter: false,
            added_for: "",
            approved_by: "",
            created: "",
          },
        ]);
      }
      setVaccineReactionList([]);
      setVaccineReactionOther("");
    });
  };

  const handleChangeReaction = (value) => {
    if (vaccineReactionList.indexOf(value) !== -1) {
      setVaccineReactionList(
        vaccineReactionList.filter((row) => row !== value)
      );
    } else {
      setVaccineReactionList([...vaccineReactionList, value]);
    }
  };

  const handleChangeComorbiditys = (value) => {
    if (comorbiditys.indexOf(value) !== -1) {
      setComorbiditys(comorbiditys.filter((row) => row !== value));
    } else {
      setComorbiditys([...comorbiditys, value]);
    }
  };

  const handleChangeSymptoms = (index, value, title) => {
    if (title === "status" && value) {
      setListSymptoms(
        listSymptoms.map((row, idx) =>
          index === idx
            ? {
                ...row,
                status: value,
                dateStart: moment().format("YYYY-MM-DD"),
                edited: true,
              }
            : row
        )
      );
    } else {
      setListSymptoms(
        listSymptoms.map((row, idx) =>
          index === idx ? { ...row, [title]: value, edited: true } : row
        )
      );
    }
  };

  const handleSave = () => {
    if (city.id) {
      if (accept) {
        Api.post(`/ms_system/notificationcovid/notificationCovid`, {
          user: user.id,
          authUser,
          selecteds,
          symptoms: listSymptoms.filter(
            (row) => row.status === true && row.edited === true
          ),
          temperature,
          city,
          comorbiditys: comorbiditys.join(","),
          comorbidityOther,
          vaccine,
          recordVaccine: record,
          mode,
          sequel,
          reasonNotVaccine,
          reasonNotVaccineOther,
          vaccineReaction,
          vaccineReactionList: vaccineReactionOther
            ? [...vaccineReactionList, vaccineReactionOther].join(",")
            : vaccineReactionList.join(","),
        })
          .then((res) => {
            dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
            setMode(3);
            setSelecteds([]);
            setComorbiditys([]);
            setTemperature("");
            setReasonNotVaccine("");
            setReasonNotVaccineOther("");
            setVaccine("");
            setRecord([]);
            setSequel(initial_sequel);
            setAccept(false);
            setError(false);
            setTimeout(() => dispatch(closeAlertMessage()), 5000);
          })
          .catch(() => {
            dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
            setTimeout(() => dispatch(closeAlertMessage()), 5000);
          });
      } else {
        setError(true);
        dispatch(viewAlertMessage("error", "Confirme os dados para salvar!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      }
    } else {
      setError(true);
      dispatch(viewAlertMessage("error", "Informe o município"));
      setTimeout(() => dispatch(closeAlertMessage()), 5000);
    }
  };

  const getTitle = () => {
    switch (mode) {
      case 1:
        return "Notificar Sintomas de Covid-19";
      case 5:
        return "Notificar Vacinação de Covid-19";
      case 6:
        return "Notificar Sequelas de Covid-19";
      case 8:
        return "Notificar Comorbidades";
      case 7:
        return "Vacinação Covid-19";
      default:
        return "Notificações - Covid-19";
    }
  };

  return (
    <div className="row mt-3 px-3">
      <div
        className={
          mode === 0 || mode === 3 || mode === 4
            ? "col-md-3 col-xs-12"
            : "col-md-1 col-xs-12"
        }
      ></div>
      <div
        className={
          mode === 0 || mode === 3 || mode === 4
            ? "col-md-4 col-xs-12"
            : "col-md-10 col-xs-12"
        }
      >
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader title={getTitle()} match={match} />
        </div>
        {mode === 4 && (
          <>
            {/* <Card className="shadow border-0 mb-3">
              <CardBody>
                <div className="row">
                  <div className="col-12">
                    <h4 className="font-weight-semibold">
                      Informe o município a ser notificado:
                    </h4>
                    <div className="col-12">
                      <AutocompleteCity
                        handleChange={(e) => setCity(e)}
                        value={city}
                        error={error && !city.id}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card> */}
            {!hideVaccine && authType !== "1" && (
              <Card className="shadow border-0 mb-3">
                <CardBody
                  onClick={() => {
                    // setUser(auth);
                    setMode(5);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="row">
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <AccountCircleIcon color="primary" fontSize="large" />
                      </div>
                      <div className="ml-3">
                        <h4 className="font-weight-bold">
                          Notificar Vacinação de Covid-19
                        </h4>
                        <span>
                          Criar uma notificação sobre vacinação do Covid-19
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
            {hideVaccine && authType !== "1" && (
              <Card className="shadow border-0 mb-3">
                <CardBody
                  onClick={() => {
                    // setUser(auth);
                    setMode(7);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="row">
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <AccountCircleIcon color="primary" fontSize="large" />
                      </div>
                      <div className="ml-3">
                        <h4 className="font-weight-bold">
                          Vacinação de Covid-19
                        </h4>
                        <span>
                          Visualizar meu esquema vacinal em relação a Covid-19
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}

            <Card className="shadow border-0 mb-3 mt-2">
              <CardBody
                // onClick={() => setMode(0)}
                onClick={() => {
                  // setUser(auth);
                  setMode(1);
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="row">
                  <div style={{ display: "inline-flex" }}>
                    <div>
                      <MoodBadIcon color="primary" fontSize="large" />
                    </div>
                    <div className="ml-3">
                      <h4 className="font-weight-bold">
                        Notificar Sintomas de Covid-19
                      </h4>
                      <span>
                        Criar uma notificação de paciente com suspeita de
                        Covid-19
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            {authType !== "1" && (
              <Card className="shadow border-0 mb-3 mt-2">
                <CardBody
                  onClick={() => {
                    // setUser(auth);
                    setMode(6);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="row">
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <HealingIcon color="primary" fontSize="large" />
                      </div>
                      <div className="ml-3">
                        <h4 className="font-weight-bold">
                          Notificar Sequelas de Covid-19
                        </h4>
                        <span>
                          Criar uma notificação de paciente com sequela de
                          Covid-19
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
            {authType !== "1" && (
              <Card className="shadow border-0 mb-3 mt-2">
                <CardBody
                  onClick={() => {
                    // setUser(auth);
                    setMode(8);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="row">
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <HowToRegIcon color="primary" fontSize="large" />
                      </div>
                      <div className="ml-3">
                        <h4 className="font-weight-bold">
                          Notificar Comorbidades
                        </h4>
                        <span>
                          Criar uma notificação de paciente com comorbidades
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </>
        )}
        {mode === 0 && (
          <>
            {authType !== "1" && (
              <Card className="shadow border-0">
                <CardBody
                  onClick={() => {
                    setUser(auth);
                    setMode(4);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="row">
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <AccountCircleIcon color="primary" fontSize="large" />
                      </div>
                      <div className="ml-3">
                        <h4 className="font-weight-bold">
                          Notificar sintomas pessoais
                        </h4>
                        <span>
                          Criar uma notificação para suspeita de Covid-19
                          pessoal
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
            <Card className="shadow border-0 mt-2">
              <CardBody
                onClick={() => {
                  setMode(2);
                  setUser({});
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="row">
                  <div style={{ display: "inline-flex" }}>
                    <div>
                      <GroupIcon color="primary" fontSize="large" />
                    </div>
                    <div className="ml-3">
                      <h4 className="font-weight-bold">
                        Notificar sintomas de terceiros
                      </h4>
                      <span>
                        Criar uma notificação para suspeita de Covid-19 de um
                        terceiro
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </>
        )}
        {[1, 2, 5, 6, 7, 8].indexOf(mode) !== -1 && (
          <div className="row">
            <div className="col-12">
              <Paper elevation={3}>
                <div className="row p-3">
                  {mode === 1 || mode === 5 || mode === 6 || mode === 7 ? (
                    <>
                      {/* <div className="col-12">
                        <h3 className="font-weight-semibold">
                          Paciente
                        </h3>
                      </div> */}
                      <div className="col-10 d-flex">
                        <Avatar
                          className={`mr-2 ${classes.large}`}
                          alt={user.name}
                          src={getUrlPhoto(user.id, user.photo)}
                        />
                        <div className="mr-3">
                          <span className={`${classes.userName}`}>
                            {user.name}
                          </span>
                          <p className={`${classes.userDescription}`}>
                            {user.birthday}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-md-10 col-12">
                      <h3 className="font-weight-semibold">
                        Selecione o terceiro:
                      </h3>
                      <div className="col-12">
                        <AutocompleteUser
                          handleChange={(e) => {
                            if (e.id !== "new") {
                              setUser(e);
                              setMode(4);
                            } else {
                              setModalRegisterUser({
                                open: true,
                                name: e.name,
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-2">
                    <Tooltip title="Alterar usuário">
                      <IconButton
                        style={{ float: "right" }}
                        onClick={() => {
                          setMode(initialMode);
                        }}
                        color="secondary"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Paper>
            </div>

            {user.id && (
              <div className="col-12 mt-3">
                <Card className="shadow border-0">
                  <CardBody>
                    <div className="row">
                      {mode === 1 && (
                        <FormSymptoms
                          list={listSymptoms}
                          handleChange={handleChangeSymptoms}
                        />
                      )}

                      {mode === 6 && (
                        <>
                          <div className="col-12">
                            <h3 className="font-weight-semibold">
                              <u>Diagnóstico Covid-19</u>
                            </h3>
                          </div>
                          <div className="col-md-4 col-12">
                            <TextField
                              type="date"
                              label="Data"
                              value={sequel.date}
                              onChange={(e) =>
                                setSequel({
                                  ...sequel,
                                  date: e.target.value,
                                })
                              }
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                          <div className="col-12 mt-4">
                            <h3 className="font-weight-semibold">
                              <u>Sequelas</u>
                            </h3>
                          </div>
                          <div className="col-md-3 col-12 my-3">
                            <TextField
                              select
                              label="Fadiga"
                              value={sequel.fatigue}
                              onChange={(e) =>
                                setSequel({
                                  ...sequel,
                                  fatigue: e.target.value,
                                })
                              }
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem key="0" value="0">
                                Não
                              </MenuItem>
                              <MenuItem key="1" value="1">
                                Sim
                              </MenuItem>
                            </TextField>
                          </div>

                          <div className="col-md-3 col-12 my-3">
                            <TextField
                              select
                              label="Tosse"
                              value={sequel.cough}
                              onChange={(e) =>
                                setSequel({ ...sequel, cough: e.target.value })
                              }
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem key="6" value="Não">
                                Não
                              </MenuItem>
                              <MenuItem key="0" value="Produtiva">
                                Produtiva
                              </MenuItem>
                              <MenuItem key="1" value="Seca">
                                Seca
                              </MenuItem>
                              <MenuItem key="2" value="Contínua">
                                Contínua
                              </MenuItem>
                              <MenuItem key="3" value="Águda">
                                Águda
                              </MenuItem>
                              <MenuItem key="4" value="Crônica">
                                Crônica
                              </MenuItem>
                              <MenuItem key="5" value="Outras">
                                Outras
                              </MenuItem>
                            </TextField>
                          </div>

                          <div className="col-md-3 col-12 my-3">
                            <TextField
                              select
                              label="Perda de paladar"
                              value={sequel.taste}
                              onChange={(e) =>
                                setSequel({ ...sequel, taste: e.target.value })
                              }
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem key="0" value="0">
                                Não
                              </MenuItem>
                              <MenuItem key="1" value="1">
                                Sim
                              </MenuItem>
                            </TextField>
                          </div>

                          <div className="col-md-3 col-12 my-3">
                            <TextField
                              select
                              label="Perda de Olfato"
                              value={sequel.smell}
                              onChange={(e) =>
                                setSequel({ ...sequel, smell: e.target.value })
                              }
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem key="0" value="0">
                                Não
                              </MenuItem>
                              <MenuItem key="1" value="1">
                                Sim
                              </MenuItem>
                            </TextField>
                          </div>
                          <div className="col-md-3 col-12 my-3">
                            <TextField
                              select
                              label="Cansaço Muscular:"
                              value={sequel.muscle}
                              onChange={(e) =>
                                setSequel({ ...sequel, muscle: e.target.value })
                              }
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem key="0" value="0">
                                Não
                              </MenuItem>
                              <MenuItem key="1" value="1">
                                Sim
                              </MenuItem>
                            </TextField>
                          </div>
                          <div className="col-md-3 col-12 my-3">
                            <TextField
                              select
                              label="Impotência Sexual:"
                              value={sequel.sex}
                              onChange={(e) =>
                                setSequel({ ...sequel, sex: e.target.value })
                              }
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem key="0" value="0">
                                Não
                              </MenuItem>
                              <MenuItem key="1" value="1">
                                Sim
                              </MenuItem>
                            </TextField>
                          </div>
                          <div className="col-md-6 col-12 my-3">
                            <TextField
                              label="Outros"
                              value={sequel.others}
                              onChange={(e) =>
                                setSequel({ ...sequel, others: e.target.value })
                              }
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </>
                      )}

                      {mode === 5 && (
                        <>
                          <div className="col-12 mt-3">
                            <h3 className="font-weight-semibold">
                              <u>Vacinação Covid-19</u>
                            </h3>
                          </div>
                          <div className="col-12 ml-3">
                            <span className="font-weight-semibold">
                              Você já se vacinou contra a Covid-19?
                            </span>
                            <RadioGroup
                              className="d-flex flex-row"
                              value={vaccine ? vaccine : ""}
                              onChange={(e) => setVaccine(e.target.value)}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio color="primary" />}
                                label="Sim"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio color="primary" />}
                                label="Não"
                              />
                            </RadioGroup>
                          </div>
                          {vaccine === "0" && (
                            <div className="col-12">
                              <TextField
                                select
                                fullWidth
                                label="Motivo por não se vacinar:"
                                value={reasonNotVaccine}
                                onChange={(e) =>
                                  setReasonNotVaccine(e.target.value)
                                }
                                InputLabelProps={{ shrink: true }}
                              >
                                <MenuItem key="0" value="Antivacina">
                                  Antivacina
                                </MenuItem>
                                <MenuItem key="1" value="Fora do calendário">
                                  Fora do calendário
                                </MenuItem>
                                <MenuItem key="2" value="Perdeu a data">
                                  Perdeu a data
                                </MenuItem>
                                <MenuItem key="3" value="Outros">
                                  Outros
                                </MenuItem>
                              </TextField>
                            </div>
                          )}
                          {reasonNotVaccine === "Outros" && (
                            <div className="col-12 mt-3">
                              <TextField
                                fullWidth
                                label="Qual?"
                                value={reasonNotVaccineOther}
                                onChange={(e) =>
                                  setReasonNotVaccineOther(e.target.value)
                                }
                                InputLabelProps={{ shrink: true }}
                              />
                            </div>
                          )}
                          {vaccine === "1" && (
                            <>
                              <ListVaccines
                                record={record}
                                setRecord={setRecord}
                              />
                              <div className="col-12 mt-3 mb-2">
                                <span className="font-weight-semibold">
                                  Teve alguma reação a vacina?
                                </span>
                                <RadioGroup
                                  className="d-flex flex-row ml-3"
                                  value={vaccineReaction}
                                  onChange={(e) =>
                                    setVaccineReaction(e.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={<Radio color="primary" />}
                                    label="Sim"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    control={<Radio color="primary" />}
                                    label="Não"
                                  />
                                </RadioGroup>
                              </div>
                              {vaccineReaction === "1" && (
                                <>
                                  <div className="col-12">
                                    <h4 className="font-weight-semibold">
                                      Reações
                                    </h4>
                                  </div>
                                  {[
                                    "Calafrios",
                                    "Cansaço e fadiga ",
                                    "Coceira",
                                    "Congestão nasal",
                                    "Coriza",
                                    "Diarreia",
                                    "Dor ao engolir",
                                    "Dor de cabeça",
                                    "Dor de garganta",
                                    "Dor muscular",
                                    "Dor nas articulações",
                                    "Dor no local da aplicação",
                                    "Enjoos (vômitos); náusea",
                                    "Febre acima de 38°C",
                                    "Hematomano local da aplicação",
                                    "Inchaço no local da aplicação",
                                    "Perda de apetite",
                                    "Sensação de calor",
                                    "Sensação de indisposição de forma geral",
                                    "Tosse",
                                    "Vermelhidão no local da aplicação",
                                    "Outros",
                                  ].map((row, index) => (
                                    <div className="col-md-4 col-12">
                                      <FormControlLabel
                                        key={index}
                                        control={
                                          <Checkbox
                                            checked={
                                              vaccineReactionList.indexOf(
                                                row
                                              ) !== -1
                                            }
                                            onChange={() =>
                                              handleChangeReaction(row)
                                            }
                                            color="primary"
                                          />
                                        }
                                        label={row}
                                      />
                                    </div>
                                  ))}
                                  {vaccineReactionList.indexOf("Outros") !==
                                    -1 && (
                                    <div className="col-12 mt-3">
                                      <TextField
                                        label="Qual?"
                                        value={vaccineReactionOther}
                                        onChange={(e) =>
                                          setVaccineReactionOther(
                                            e.target.value
                                          )
                                        }
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {mode === 7 && (
                        <>
                          <div className="col-12 mb-3">
                            <h4 className="font-weight-semibold">
                              Meus Registros de Vacinação
                            </h4>
                          </div>
                          {record.map((row) => (
                            <>
                              <div className="col-md-1 col-12">{row.dose}</div>
                              <div className="col-md-3 col-12">
                                <strong>{row.name}</strong>
                              </div>
                              <div className="col-md-2 col-xs-6">Aplicada</div>
                              <div className="col-md-2 col-xs-6">
                                {row.date
                                  ? moment(row.date).format("DD/MM/YYYY")
                                  : "Data não informada"}
                              </div>
                              <div className="col-md-2 col-12">{row.city}</div>
                              <div className="col-md-2 col-12">
                                {row.observation}
                              </div>
                              <hr className="col-12" />
                            </>
                          ))}
                          {comorbiditys.length > 0 && (
                            <div className="col-12 mt-2">
                              <strong> Comobirdade(s): </strong>
                              <ul>
                                {comorbiditys.map((el) => (
                                  <li key={el}>{el}</li>
                                ))}
                                {comorbidityOther && (
                                  <li key="0">{comorbidityOther}</li>
                                )}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                      {mode !== 7 && (
                        <FormComorbidity
                          selecteds={comorbiditys}
                          handleChange={(e) => handleChangeComorbiditys(e)}
                          comorbidityOther={comorbidityOther}
                          setComorbidityOther={setComorbidityOther}
                        />
                      )}
                      {mode === 5 && (
                        <div className="col-12">
                          <hr className="w-100" />
                          <h3 className="font-weight-semibold">
                            <u>Informações importantes</u>
                          </h3>
                          <span className="d-block">
                            Vacinas que possuem segunda dose, deverão ser
                            aplicadas com intervalo de 21 dias.
                          </span>
                          <span className="d-block">
                            O reforço (3° dose) deverá ser aplicado 4 meses após
                            a segunda dose.
                          </span>
                          <span className="d-block mt-2">
                            Sala de vacina: Rua Prefeito Carlos de Souza Filho,
                            138
                          </span>
                          <span className="d-block">
                            Centro de COVID: Rua Prefeito Carlos de Souza Filho,
                            94
                          </span>
                          <span className="d-block">
                            Atendimento Pós Covid: Rua Dr. Augusto de Melo
                            Souza, 45
                          </span>
                        </div>
                      )}
                      {mode !== 7 && (
                        <div className="col-12">
                          <hr
                            className="w-100"
                            style={{
                              background: error && !accept ? "red" : "",
                            }}
                          />

                          <span
                            className="d-block"
                            style={{
                              color: error && !accept ? "red" : "",
                            }}
                          >
                            <Checkbox
                              checked={accept}
                              onChange={() => setAccept(!accept)}
                              color="primary"
                              error={error}
                            />
                            Declaro que os dados informados acima são
                            verdadeiros.
                          </span>

                          <hr
                            className="w-100"
                            style={{
                              background: error && !accept ? "red" : "",
                            }}
                          />
                        </div>
                      )}
                      <div className="col-12">
                        <BtnCan
                          title={mode === 7 ? "Voltar" : "Cancelar"}
                          onClick={() => setMode(4)}
                          float="left"
                        />
                        {mode !== 7 && (
                          <BtnSave onClick={handleSave} float="right" />
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
          </div>
        )}
        {mode === 3 && (
          <Card className="shadow border-0">
            <CardBody>
              <div className="row">
                <div style={{ display: "inline-flex" }}>
                  <div>
                    <CheckCircleIcon color="primary" fontSize="large" />
                  </div>
                  <div className="ml-3">
                    <h4 className="font-weight-bold">Notificação enviada!</h4>
                    <span>
                      Agradecemos seu contato. <br />
                      Nossa equipe entrará em contato em breve.
                      <br />
                    </span>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        // window.location.href = "https://www.salutemplus.com";
                        setMode(initialMode);
                      }}
                      className="mt-3"
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
      <div className="col-md-2 col-xs-12"></div>
      <RegisterUser
        add={(id, name) => {
          setUser({ id, name, photo: "", birthday: "" });
          setModalRegisterUser({
            open: false,
            name: "",
          });
          setMode(1);
        }}
        nameUser={modalRegisterUser.name}
        open={modalRegisterUser.open}
        close={() =>
          setModalRegisterUser({
            open: false,
            name: "",
          })
        }
        address={false}
        healthPlan={false}
      />
    </div>
  );
}
