import React from "react";
import DataTable from "components/Tables/DataTable";
import { Tooltip, IconButton } from "@material-ui/core";
import ViewAppointment from "./ViewAppointment";
import ComponentsProfile from "components/ComponentsProfile";

export default function AttendancesPatient({ record, patient }) {
  const [viewDataAppointment, setViewDataAppointment] = React.useState({
    open: false,
    appointment: {},
  });

  return (
    <>
      <DataTable
        name="Atendimentos"
        columns={["Data", "Profissional", "Local", "Ações"]}
        rows={record.map((row, index) => [
          row.date,

          <ComponentsProfile
            nameProfile={row.name}
            photo={row.photo}
            id={row.doctor}
            subTitle={row.area}
          />,
          row.address,
          [
            <div style={{ whiteSpace: "nowrap" }}>
              <Tooltip title="Visualizar Dados do Atendimento">
                <IconButton
                  style={{ padding: "6px" }}
                  color="primary"
                  onClick={() =>
                    setViewDataAppointment({
                      open: true,
                      appointment: {
                        ...patient,
                        id: row.id,
                        doctor: row.doctor,
                        patient: patient.id,
                        patient_name: patient.name,
                        patient_birthday: patient.birthday,
                      },
                    })
                  }
                >
                  <i className="zmdi zmdi-eye zmdi-fw" />
                </IconButton>
              </Tooltip>
            </div>,
          ],
        ])}
        hideSearch={true}
      />
      <ViewAppointment
        open={viewDataAppointment.open}
        appointment={viewDataAppointment.appointment}
        handleClose={() =>
          setViewDataAppointment({ open: false, appointment: {} })
        }
      />
    </>
  );
}
