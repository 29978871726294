import React from "react";
import TableHistory from "./tableHistory";

const Index = ({ patient }) => {
  return (
    <div className="container-person border">
      <h3
        className="title-container d-flex justify-content-center"
        style={{
          fontSize: "26px",
          fontWeight: "400"
        }}
      >
        Histórico Familiar
      </h3>
      <TableHistory patient={patient} />
    </div>
  );
};

export default Index;
