export function getCalendars(
  user,
  type,
  address,
  width,
  provider_type,
  manager
) {
  return {
    type: "GET_CALENDARS",
    payload: {
      user,
      type,
      address,
      width,
      provider_type,
      manager,
    },
  };
}

export function getEvents(calendar, date, integrated, patient) {
  return {
    type: "GET_EVENTS",
    payload: {
      calendar,
      date,
      integrated,
      patient,
    },
  };
}

export function getConfigCalendar(user) {
  return {
    type: "GET_CONFIGS",
    user,
  };
}

export function getEventLog(calendar) {
  return {
    type: "GET_EVENT_LOG",
    calendar,
  };
}
