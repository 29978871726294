import React, { useEffect, useState } from "react";
import {
  MenuItem,
  TextField,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
} from "@material-ui/core/";
import moment from "moment";
import { MultipleSelect } from "react-select-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Api from "services/api";

const useStyles = makeStyles((theme) => ({
  txtError: {
    color: "#f44336",
  },
}));

export default function Curative({ type, state, setState, error, pattern }) {
  const classes = useStyles();
  const [cares, setCares] = useState([]);

  useEffect(() => {
    Api.post(`/microservice/prescriptioncare/getCaresFromType/`, { type }).then(
      ({ data }) => {
        setCares(data.record);
      }
    );
  }, []);

  useEffect(() => {
    if (state.quantity !== state.hours.length) {
      let arr = [];
      for (let index = 0; index < state.quantity; index++) {
        arr.push("");
      }
      setState({ ...state, hours: arr });
    }
  }, [state.quantity]);

  useEffect(() => {
    if (state.duration) {
      let diff = state.duration - 1;
      if (diff === 0) {
        setState({ ...state, end: state.start });
      } else {
        setState({
          ...state,
          end: moment(state.start)
            .add(diff, "days")
            .format("YYYY-MM-DD"),
        });
      }
    }
  }, [state.start, state.duration]);

  useEffect(() => {
    let start = state.start;
    let end = state.end;
    let diff = moment(end).diff(moment(start), "days") + 1;
    setState({ ...state, duration: diff });
  }, [state.end]);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const handleChangeHour = (index, value) => {
    let aux = state.hours.map((row, key) => (key === index ? value : row));
    setState({ ...state, hours: aux });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-12 mt-3">
          <TextField
            id="Cuidado"
            select
            label="Cuidado:"
            value={state.care}
            onChange={(e) => handleChange("care", e.target.value)}
            fullWidth
            error={error && !state.care}
            helperText={error && !state.care && "Selecione um cuidado"}
            InputLabelProps={{ shrink: true }}
          >
            {cares.map((row) => (
              <MenuItem key={row.id} value={row.id}>
                {row.description}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="col-md-6 col-12">
          <MultipleSelect
            label="Selecione os materiais:"
            values={state.materials}
            options={[
              "Gazinha",
              "Bandagem",
              "Soro fisológico",
              "Sulfadiazina de Prata",
              "Oleo Age",
              "Papaina",
              "Carvão ativado",
              "Colagenase",
              "Dersane",
              "Hidrogel",
              "Adaptic",
              "Esparadrapo",
              "Micropore",
              "outros",
            ]}
            onChange={(e) => handleChange("materials", e)}
            SelectProps={{
              isCreatable: false,
              msgNoOptionsAvailable: "Todas as opções foram selecionadas",
              msgNoOptionsMatchFilter: "Nenhuma opção para este filtro",
            }}
            style={{
              marginTop: "10px",
              borderBottom:
                error && state.materials.length <= 0 ? "2px solid red" : "none",
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-12">
          <FormControl className="my-3" fullWidth>
            <FormHelperText>x/Dia:</FormHelperText>
            <Input
              fullWidth
              value={state.quantity}
              onChange={(e) => handleChange("quantity", e.target.value)}
              inputProps={{ maxLength: 2 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              error={error && !state.quantity}
            />
            {error && !state.quantity && (
              <FormHelperText className={classes.txtError}>
                Defina quantas vezes por dia
              </FormHelperText>
            )}
          </FormControl>
        </div>
        {state.hours.map((row, index) => (
          <div
            key={index}
            className="col-md-2 col-6"
            style={{ paddingTop: "1.2rem" }}
          >
            <TextField
              type="time"
              label="Horário:"
              value={row}
              onChange={(e) => handleChangeHour(index, e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={error && !row}
            />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-md-3 col-6 mt-3">
          <TextField
            id="Intervalo"
            select
            label="Intervalo:"
            value={state.interval}
            onChange={(e) => handleChange("interval", e.target.value)}
            fullWidth
            error={error && !state.interval}
            helperText={error && !state.interval && "Selecione um intervalo"}
          >
            <MenuItem key="1" value="Diário">
              Diário
            </MenuItem>
            <MenuItem key="12" value="Dias da Semana">
              Dias da Semana
            </MenuItem>
            <MenuItem key="2" value="2x por semana">
              2x por semana
            </MenuItem>
            <MenuItem key="3" value="3x por semana">
              3x por semana
            </MenuItem>
            <MenuItem key="4" value="Semanal">
              Semanal
            </MenuItem>
            <MenuItem key="5" value="15 em 15 dias">
              De 15 em 15 dias
            </MenuItem>
            <MenuItem key="6" value="Mensal">
              Mensal
            </MenuItem>
            <MenuItem key="7" value="Bimestral">
              Bimestral
            </MenuItem>
            <MenuItem key="11" value="Trimestral">
              Trimestral
            </MenuItem>
            <MenuItem key="10" value="Semestral">
              Semestral
            </MenuItem>
            <MenuItem key="8" value="Anual">
              Anual
            </MenuItem>
          </TextField>
        </div>
        {state.interval === "Dias da Semana" && (
          <div className="col-md-9 col-6">
            <MultipleSelect
              label="Selecione os materiais:"
              values={state.days}
              options={[
                "Domingo",
                "Segunda",
                "Terça",
                "Quarta",
                "Quinta",
                "Sexta",
                "Sábado",
              ]}
              onChange={(e) => handleChange("days", e)}
              SelectProps={{
                isCreatable: false,
                msgNoOptionsAvailable: "Todas as opções foram selecionadas",
                msgNoOptionsMatchFilter: "Nenhuma opção para este filtro",
              }}
              style={{
                marginTop: "10px",
                borderBottom:
                  error && state.days.length <= 0 ? "2px solid red" : "none",
              }}
            />
          </div>
        )}
        {!pattern && (
          <div className="col-md-3 col-6" style={{ marginTop: "-5px" }}>
            <FormControl className="my-3" fullWidth>
              <FormHelperText>Duração:</FormHelperText>
              <Input
                id="weight"
                value={state.duration}
                onChange={(e) => handleChange("duration", e.target.value)}
                endAdornment={
                  <InputAdornment position="end">dias</InputAdornment>
                }
                inputProps={{ maxLength: 3 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                error={error && parseInt(state.duration) <= 0}
              />
            </FormControl>
          </div>
        )}

        <div className="col-md-3 col-6 mt-3">
          <TextField
            type="date"
            label="Início:"
            value={state.start}
            onChange={(e) => handleChange("start", e.target.value)}
            fullWidth
          />
        </div>
        {!pattern && (
          <div className="col-md-3 col-6 mt-3">
            <TextField
              type="date"
              label="Fim:"
              value={state.end}
              onChange={(e) => handleChange("end", e.target.value)}
              fullWidth
            />
          </div>
        )}
        <div className="col-md-12">
          <TextField
            multiline
            rows={4}
            margin="normal"
            id="obs"
            label="Orientações:"
            type="text"
            fullWidth
            onChange={(e) => handleChange("observation", e.target.value)}
            value={state.observation}
          />
        </div>
      </div>
    </>
  );
}
