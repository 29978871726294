import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "services/api";

import DataConsult from "components/DataConsult";
import BarProgress from "components/BarProgress";
import DoctorDatails from "components/DoctorDatails";
import { Button } from "@material-ui/core";

export default function ViewConsult({ appointment_id, onClose }) {
  const patient = useSelector((state) => state.appointment.patient);
  const doctor = useSelector((state) => state.auth.authUser);
  const [dataConsult, setDataConsult] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (appointment_id) {
      getDataConsult();
    }
  }, [appointment_id]);

  const getDataConsult = () => {
    setLoading(true);
    Api.post(`/microservice/consult/getHistoryAppointment/`, {
      appointment_id,
      doctor,
      patient,
    })
      .then(({ data }) => {
        setDataConsult(data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <BarProgress />}
      {!loading && dataConsult && (
        <div>
          <div className="col-12">
            <center>
              <h4
                className="text-primary"
                style={{
                  width: "100%",
                  marginTop: "40px",
                  justifyContent: "space-between",
                }}
              >
                Dados do Profissional
              </h4>
            </center>
            <DoctorDatails
              name={dataConsult.appointment.professional}
              photo={dataConsult.photo}
              specialitys={dataConsult.appointment.specialitys}
              council={dataConsult.council}
              date={dataConsult.appointment.date_of_occurrence}
              address={dataConsult.address_appointment.address}
            />
          </div>
          <DataConsult dataConsult={dataConsult} />
          <div className="col-12 text-right">
            <Button onClick={onClose} color="primary" aria-label="Voltar">
              <i className="zmdi zmdi-undo" /> Voltar
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
