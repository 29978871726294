import React, { useState } from "react";
import { getStringToSearch } from "services/functions";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover, &:focus, &:active": {
      borderBottom: "1px solid #177493",
      webkitTransition: "color 2000ms ease",
      mozTransition: "color 2000ms ease",
      msTransition: "color 2000ms ease",
      oTransition: "color 2000ms ease",
      transition: "color 2000ms ease",
    },
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    boxShadow: "none",
    borderBottom: "1px solid #acacac",
    borderRadius: "0px",
  },
  rootHover: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    boxShadow: "none",
    borderBottom: "1px solid #177493",
    borderRadius: "0px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    backgroundColor: "white",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function SearchComponent({
  searchContact,
  placeholder = "Pesquisar",
  autoFocus = false,
}) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [hover, setHover] = useState(false);
  const handleTextSearch = (str) => {
    searchContact(getStringToSearch(str));
  };

  return (
    <Paper
      className={
        `animated fadeIn animation-duration-3 ` +
        (hover ? classes.rootHover : classes.root)
      }
    >
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        value={value}
        onFocus={() => setHover(true)}
        onBlur={() => setHover(false)}
        autoFocus={autoFocus}
        inputProps={{ "aria-label": "search google maps" }}
        onChange={(e) => {
          setValue(e.target.value);
          handleTextSearch(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleTextSearch(value);
          }
        }}
      />
      <IconButton className={classes.iconButton} aria-label="search">
        {value ? (
          <CloseIcon
            onClick={() => {
              setValue("");
              handleTextSearch("");
            }}
          />
        ) : (
          <SearchIcon />
        )}
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
    </Paper>
  );
}
