import React, { Component } from "react";
import api from "services/api";
import {
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
// import { Container } from './styles';

export default class ViewNursePrescription extends Component {
  state = {
    record: []
  };
  componentDidMount() {
    api
      .post(`/microservice/sae/getItensFromPrescription`, {
        id: this.props.prescription
      })
      .then(res => {
        this.setState({ record: res.data });
      })
      .catch(() => {});
  }
  render() {
    const { record } = this.state;
    return record.length > 0 ? (
      <div
        className="col-12"
        style={{
          minWidth: "650",
          overflowX: "auto"
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Diagnóstico</TableCell>
              <TableCell>Cuidado</TableCell>
              <TableCell>Observações</TableCell>
              <TableCell>Horário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.map(item => (
              <TableRow key={item.id_itens}>
                <TableCell>{item.diagnostic_name}</TableCell>
                <TableCell>{item.name_care}</TableCell>
                <TableCell>{item.note === null ? "---" : item.note}</TableCell>
                <TableCell colSpan="2">
                  {item.hour !== null
                    ? item.hour
                        .split("|")
                        .map((hour, index) =>
                          index > 0
                            ? `, ${hour.split(":")[0] +
                                ":" +
                                hour.split(":")[1]}`
                            : hour.split(":")[0] + ":" + hour.split(":")[1]
                        )
                    : "---"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    ) : (
      <div className="text-center">
        <CircularProgress />
      </div>
    );
  }
}
