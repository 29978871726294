/* import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { getUrlPhoto } from "services/functions";

import Downshift from "downshift";
import Api from "services/api";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%"
  },
  root: {
    backgroundColor: "#fff"
  },
  selected: {
    backgroundColor: "#e2e2e8 !important"
  }
};

function AutocompleteUser({
  handleChangeAutocomplete,
  error,
  classes,
  user_id
}) {
  const [suggestions, setSuggestions] = useState([]);

  function getSuggestions(e) {
    if (e.target.value.length > 2) {
      Api.post(`/ms_comunication/contact/autocompleteUserToContact/`, {
        term: e.target.value,
        user_id
      }).then(res => {
        const { data } = res;
        setSuggestions(data);
      });
    }
  }

  function renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      >
        <ListItemIcon>
          <img
            className="rounded-circle"
            style={{ width: "40px", height: "40px" }}
            alt={suggestion.name}
            src={getUrlPhoto(suggestion.id, suggestion.photo)}
          />
        </ListItemIcon>
        {suggestion.name}
      </MenuItem>
    );
  }

  function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        autoFocus
        error={error}
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input
          },
          ...InputProps
        }}
        onKeyUp={e => getSuggestions(e)}
      />
    );
  }

  return (
    <Downshift
      onSelect={(selectedItem, obj) => {
        let item = suggestions.filter(row => {
          return row.name === selectedItem;
        });
        if (item.length > 0) {
          handleChangeAutocomplete(item[0]);
        }
        obj.setState({ inputValue: "" });
      }}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        selectedItem,
        highlightedIndex
      }) => (
        <div className={classes.container}>
          {renderInput({
            fullWidth: true,
            classes,
            InputProps: getInputProps({
              id: `ac-patient`
            }),
            label: "Adicionar membro do portal:",
            margin: "normal",
            style: { marginTop: 0, marginBottom: 16 }
          })}
          {isOpen ? (
            <Paper square>
              {suggestions.map((suggestion, index) =>
                renderSuggestion({
                  suggestion,
                  index,
                  itemProps: getItemProps({
                    item: suggestion.name
                  }),
                  highlightedIndex,
                  selectedItem
                })
              )}
            </Paper>
          ) : null}
        </div>
      )}
    </Downshift>
  );
}

AutocompleteUser.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AutocompleteUser); */

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Api from "services/api";
import { debounce, showMsg } from "services/functions";

import AutocompleteGeneric from "components/Autocomplete/AutocompleteGeneric";

export default function Exames({ onChange, user_id, secretary, type }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);

  const getUsers = async (term) => {
    if (term.length > 2) {
      setLoader(true);
      try {
        const { data } = await Api.post(
          secretary || type === "0"
            ? "/ms_comunication/contact/autocompleteUserToContact/"
            : "/ms_comunication/contact/autocompleteContactPatient",
          {
            term,
            user_id,
          }
        );
        if (data.length > 0) {
          setOptions(data);
        } else {
          setOptions([{ id: "new", name: term }]);
        }
      } catch (err) {
        showMsg("error", "Não foi possível encontrar os exames", dispatch);
        setOptions([]);
      } finally {
        setLoader(false);
      }
    }
  };
  return (
    <AutocompleteGeneric
      multiple={false}
      limitTags={1}
      filterOptions={(x) => x}
      disableCloseOnSelect
      onChange={onChange}
      onInputChange={debounce(getUsers)}
      value={null}
      getOptionLabel={(option) => option.name}
      label="Adicionar Contato:"
      options={options}
      loading={loader}
      blurOnSelect={true}
      clearOnBlur={true}
      shrink={true}
    />
  );
}
