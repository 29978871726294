const INITIAL_STATE = {
  listAllVaccines: [],
  valSelVaccine: "",
  idEdit: "",
  InfosGroup: [],
  Newdate: new Date(),
  isApplied: false,
  listUserVaccines: [],
  modalCadVaccine: false,
  modalSuccessDeleteVaccine: false,
  infoVaccine: []
};

export default function vaccines(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_VACCINES":
      return INITIAL_STATE;
    case "GET_VACCINES_LIST":
      return {
        ...state
      };

    case "GET_LIST_VACCINES_SUCCESS":
      return {
        ...state,
        listAllVaccines: action.payload.listAllVaccines
      };

    case "CHAGE_VACCINE_SELECT":
      //   let aux2 = [];

      //   state.listAllVaccines.forEach(row => {
      //     if (row.id === action.payload.event) {
      //       aux2.push({ ...row, check: true });
      //     } else {
      //       aux2.push({ ...row, check: false });
      //     }
      //   });

      return {
        ...state,
        valSelVaccine: action.payload.event
      };

    case "CHANGE_DATA":
      return {
        ...state,
        loader: true,
        Newdate: action.payload.event
      };

    case "CHANGE_STATUS_VACCINE":
      return {
        ...state,
        isApplied: action.payload.value
      };

    case "CAD_VACCINE":
      return {
        ...state,
        patient: action.payload.patient,
        vcSelect: action.payload.vcSelect,
        date: action.payload.date,
        isApplied: action.payload.isApplied,
        idEdit: action.payload.idEdit
      };

    case "GET_USER_VACCINES":
      return {
        ...state,
        patient: action.payload.patient
      };

    case "GET_LIST_USER_VACCINES_SUCCESS":
      return {
        ...state,
        listUserVaccines: action.payload.listUserVaccines
      };

    case "OPEN_MODAL_CAD":
      return {
        ...state,
        modalCadVaccine: action.payload.value,
        idEdit: "",
        valSelVaccine: "",
        infoVaccine: "",
        Newdate: new Date()
      };

    case "CLOSE_MODAL_CAD":
      return {
        ...state,
        modalCadVaccine: action.payload.value
      };

    case "REQUEST_CAD_VACCINE_SUCCESS":
      return {
        ...state,
        listUserVaccines: action.payload.listUserVaccines,
        modalCadVaccine: false
      };

    case "DEL_USER_VACCINE":
      return {
        ...state,
        id: action.payload.id,
        patient: action.payload.patient
      };

    case "DEL_USER_VACCINE_SUCCESS":
      return {
        ...state,
        listUserVaccines: action.payload.listUserVaccines,
        modalSuccessDeleteVaccine: true
      };

    case "CLOSE_DELETE_SUCCESS":
      return {
        ...state,
        modalSuccessDeleteVaccine: false
      };

    case "SET_STATS_EDIT":
      return {
        ...state,
        idEdit: action.payload.id,
        modalCadVaccine: true
      };

    case "GET_VACCINE_FOR_EDIT":
      return {
        ...state,
        id: action.payload.id
      };

    case "GET_VACCINE_FOR_EDIT_SUCCESS":
      let valedit = action.payload.infoVaccine[0];
      let dateVal = "";
      let Applied = false;
      let vacina = parseInt(valedit.vaccine_id);
      if (valedit.is_applied === "1") {
        Applied = true;
      }

      if (Applied === true) {
        dateVal = valedit.date_of_vaccine;
      } else {
        dateVal = valedit.expected_date;
      }

      return {
        ...state,
        infoVaccine: action.payload.infoVaccine,
        valSelVaccine: vacina,
        isApplied: Applied,
        Newdate: dateVal
      };

    default:
      return state;
  }
}
