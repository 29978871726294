import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TextField } from "@material-ui/core";
import { Btnclose, BtnSave } from "components/Button";
import { useSelector, useDispatch } from "react-redux";
import Api from "services/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  close,
  medicalRecord,
  getPatientNumRegiste,
}) {
  const dispatch = useDispatch();
  const address_selected_id = useSelector(
    (state) => state.auth.address_selected.id
  );
  const patient = useSelector((state) => state.appointment.patient);
  const [numReg, setnumReg] = React.useState(null);
  const [legacy_check, setlegacy_check] = React.useState(false);

  React.useEffect(() => {
    setnumReg(medicalRecord);
  }, [medicalRecord]);

  const handleSaveNumRegis = () => {
    if (numReg !== medicalRecord) {
      Api.post("/microservice/admission/saveNumRegiste", {
        patient: patient,
        address: address_selected_id,
        number: numReg,
      })
        .then((res) => {
          setlegacy_check(res.data.checkNumReg);
          if (res.data.checkNumReg === false) {
            close();
            dispatch(viewAlertMessage("success", "Salvo com Sucesso!"));
            setTimeout(() => dispatch(closeAlertMessage()), 3000);
            getPatientNumRegiste();
          }
        })
        .catch((e) => {
          // dispatch(viewAlertMessage("error", "Ocorreu um erro!"));
          // setTimeout(() => dispatch(closeAlertMessage()), 3000);
        });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={"sm"}
        onClose={close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Prontuário</DialogTitle>
        <DialogContent className="pt-0">
          <TextField
            error={legacy_check}
            helperText={legacy_check && "Numero de prontuário existente"}
            id="name"
            label="N° Prontuário"
            value={numReg}
            margin="normal"
            fullWidth
            onChange={(e) => setnumReg(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <div className="col-12">
            <Btnclose className="mt-2" onClick={close} float="left" />
            <BtnSave
              name="Salvar"
              float="right"
              // icon="zmdi zmdi-plus"
              onClick={handleSaveNumRegis}
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
