import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap";
import Api from "services/api";
import apiFile from "services/apiFile";
import Header from "components/Header/index";
import { BtnSave } from "components/Button";
import ComponentsProfile from "components/ComponentsProfile";
import InputControlLength from "components/TextFields/InputControlLength";
import CustomDropzone from "components/CustomDropzone";
import { viewHistory } from "actions/consult/Appointment";
import { getInfoPatient } from "actions/consult/PatientInfo";
import HistoryPatient from "components/HistoryPatient";
import HistoryIcon from "@material-ui/icons/History";
import { Tooltip, IconButton } from "@material-ui/core";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";

export default function ForwardForm() {
  const dispatch = useDispatch();

  const [state, setstate] = useState("");
  const [file, setfile] = useState({ text: "", accpted: [] });
  const [error, setError] = useState(false);

  React.useEffect(() => {
    Api.post(`microservice/forward/getForwardId`, {
      id: window.location.search.split("H87")[1],
    })
      .then((res) => {
        setstate(res.data.record[0]);
        // setlistForwardPt(res.data.record);
      })
      .catch(() => {
        // dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        // setTimeout(() => dispatch(closeAlertMessage()), 5000);
      });
  }, []);

  const handleSetAccpted = (params) => {
    setfile({ ...file, accpted: params });
  };

  const handleValidate = () => {
    if (file.text) {
      setError(false);
      handleSave();
    } else {
      setError(true);
    }
  };

  const handleSave = () => {
    let images = file.accpted;
    let formData = new FormData();
    let count = 0;

    images.forEach((row) => {
      formData.append(`file-${count}`, row);
      count = count + 1;
    });
    formData.append("id", state.id);
    formData.append("accpted", file.accpted);
    formData.append("text_return", file.text);
    formData.append("patient", state.fk_ls_patient);

    apiFile
      .post(`microservice/forward/saveReturnForward`, formData)
      .then((res) => {
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      });
  };

  return (
    <div className={`app-container`}>
      <div className="app-main-container">
        <div className={`app-header`}>
          <Header left={true} />
        </div>
        <main className="app-main-content-wrapper">
          <div
            className="container mt-2"
            style={{
              textAlign: "inherit",
            }}
          >
            {/* <ContainerHeader title="Cadastre-se" match={match} /> */}
            <Card className="shadow border-0">
              <CardBody>
                {state && (
                  <div className="col-12">
                    <div className="ml-3">
                      <strong> Encaminhado por:</strong>
                      <ComponentsProfile
                        id={state.doctor}
                        nameProfile={state.doctor_name}
                        subTitle={""}
                        photo={state.doctor_photo}
                      />
                    </div>
                    <div className="col-12">
                      <hr style={{ borderBottom: "1px solid #fcfcfc" }} />
                    </div>
                    <div className="ml-3">
                      <strong>
                        Paciente Encaminhado{" "}
                        <Tooltip title="Histórico do paciente" placement="top">
                          <IconButton
                            style={{ padding: "6px" }}
                            onClick={() => {
                              dispatch(viewHistory("personalData"));
                              dispatch(getInfoPatient(state.fk_ls_patient));
                            }}
                          >
                            <HistoryIcon
                              style={{
                                fontSize: "19px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </strong>
                      <ComponentsProfile
                        id={state.fk_ls_patient}
                        nameProfile={state.patient}
                        subTitle={""}
                        photo={state.photo}
                      />
                    </div>
                    <div className="col-12">
                      <hr style={{ borderBottom: "1px solid #fcfcfc" }} />
                    </div>
                    <div className="ml-3">
                      <strong>Data encaminhamento:</strong>
                      {state.date}
                    </div>
                    <div className="ml-3">
                      <strong>Procedimento:</strong>
                      {state.procedure ? state.procedure : "--"}
                    </div>
                    <div className="col-12">
                      <hr style={{ borderBottom: "1px solid #fcfcfc" }} />
                    </div>
                    <div className="ml-3">
                      <strong>
                        Informa&ccedil;&otilde;es do encaminhamento:{" "}
                      </strong>
                      {state.free_text}
                    </div>
                    <div className="col-12">
                      <hr style={{ borderBottom: "1px solid #fcfcfc" }} />
                    </div>
                    <div className="row">
                      <div className="col-12 mx-3">
                        <InputControlLength
                          rows={5}
                          multiline={true}
                          onChange={(e) =>
                            setfile({ ...state, text: e.target.value })
                          }
                          value={file.text ? file.text : ""}
                          label={"Retorno do encaminhamento"}
                          maxLength={5000}
                          error={error}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="row justify-content-center">
                  <CustomDropzone
                    returnAcceptedFiles={handleSetAccpted}
                    extensions="image/*"
                    max_files={5}
                  />
                </div>

                <BtnSave
                  title="Salvar"
                  name="Salvar"
                  onClick={handleValidate}
                  float="right"
                />
              </CardBody>
            </Card>
          </div>
        </main>
        <HistoryPatient id_patient={state.fk_ls_patient} />;
      </div>
    </div>
  );
}
