import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ProvidedDataIlpi from "./ProvidedDataIlpi";
import Api from "services/api";
import RepresentanteMangers from "./RepresentanteMangers";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { setListAddress } from "actions/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Dados Prestador", "Representante e Gestores"];
}

export default function HorizontalLabelPositionBelowStepper({ user, close }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [listProvider, setlistProvider] = React.useState([]);
  const steps = getSteps();

  const initData = {
    providedData: {
      name: "",
      corporateName: "",
      cnpj: "",
      cpf: "",
      phone: "",
      registerMuniciapl: "",
      registerState: "",
      typeProvider: "",
      date: "",
      email: "",
      cellphone: "",
      neighborhood: "",
    },
    managers: {},
    list_manager: [],
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  React.useEffect(() => {
    Api.post(`/ms_system/workplace/getProviderManager`, { id: user })
      .then((res) => {
        setlistProvider(res.data.listProvider);
      })
      .catch(() => {});
  }, []);

  const handleSetRepresentation = (managers, list) => {
    setData({
      ...data,
      managers,
      list_manager: list,
    });
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <ProvidedDataIlpi
            handleNext={handleNext}
            handleClose={close}
            validateData={"validate"}
            listProvider={listProvider}
            stateInit={data.providedData}
            handleSetState={handleSetState}
          />
        );
      case 1:
        return (
          <RepresentanteMangers
            handleBack={handleBack}
            handleSetState={handleSetState}
            handleSetRepresentation={handleSetRepresentation}
            handleSave={saveWorkplaceIlpi}
          />
        );
      // case 2:
      //   return "This is the bit I really care about!";
      // default:
      //   return "Unknown stepIndex";
    }
  }

  const handleSetState = async (type, value) => {
    await setData({ ...data, [type]: { ...value } });
  };

  const [data, setData] = React.useState(initData);

  console.log("data", data);

  function saveWorkplaceIlpi() {
    Api.post(`/ms_system/workplace/saveWorkplaceIlpi/`, { ...data })
      .then((res) => {
        // Atualiza a listagem de endereços na tela de locais de atendimento
        dispatch({
          type: "GET_WORKPLACES",
          user,
        });
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);

        // Atualiza a listagem geral de locais de atendimento
        Api.post(`/ms_system/workplace/getListWorkplace`, {
          loaded_address: false,
        }).then((res) => {
          dispatch(setListAddress(res.data.listAddress, res.data.managers));
        });
        close();
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage(), 5000));
      });
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          <Typography className={classes.instructions}>
            {getStepContent(activeStep)}
          </Typography>
        </div>
      </div>
    </div>
  );
}
