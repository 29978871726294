import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import { Button } from "@material-ui/core";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import BathtubIcon from "@material-ui/icons/Bathtub";
import ModalChoose from "components/modalChoose";
// import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import moment from "moment";
class Evolution extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}${
      window.location.href.split("?")[1]
        ? `?${window.location.href.split("?")[1]}`
        : ""
    }`; // get current path
    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
    this.getActive();

    const scrollbar = document.getElementsByClassName("scrollbar");
    scrollbar[0].addEventListener("mouseenter", () => {
      let menus = document.getElementsByClassName("menu open");
      for (let j = 0; j < menus.length; j++) {
        menus[j].classList.remove("open");
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}${
      window.location.href.split("?")[1]
        ? `?${window.location.href.split("?")[1]}`
        : ""
    }`; // get current path
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
        this.closest(activeLi, "li").classList.add("active");
      }
    } catch (error) {}
    this.getActive();
  }

  getActive() {
    document.querySelectorAll(".nav-menu li a").forEach((el) => {
      if (el.href !== window.location.href) {
        el.classList.remove("active");
      } else {
        el.classList.add("active");
      }
    });
  }

  componentDidUpdate() {
    this.getActive();
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  getIntervals = () => {
    const { intervals, addressInterval } = this.props;
    let start,
      new_end,
      end = moment();
    if (intervals[0].start) {
      start = moment(intervals[0].start);
      new_end = moment(intervals[0].start);
      end = moment(intervals[intervals.length - 1].end);
    } else {
      start = moment().startOf("day");
      new_end = moment().startOf("day");
      end = moment().endOf("day");
    }
    let arrHours = [];
    let key = 0;

    while (start.isBefore(end)) {
      new_end.add(parseInt(addressInterval), "minutes");
      if (new_end.isAfter(end)) {
        new_end = moment(end.format("YYYY-MM-DD HH:mm"));
      }

      arrHours.push(
        <li key={key}>
          <NavLink
            to={`/app/evolution/hour-evolution?hous=${start.format(
              "YYYY-MM-DDHH:mm"
            )}x${new_end.format("YYYY-MM-DDHH:mm")}`}
          >
            <span className="nav-text">{`${start.format(
              "HH:mm"
            )}-${new_end.format("HH:mm")}`}</span>
          </NavLink>
        </li>
      );
      start = start.add(parseInt(addressInterval), "minutes");
      key++;
    }

    return <ul className="sub-menu">{arrHours}</ul>;
  };

  render() {
    return (
      <CustomScrollbars className="scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="module.evolution.group" />
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/evolution/time-evolution" className="active">
              <i className="zmdi zmdi-trending-up zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="module.evolution.timeLine" />
              </span>
            </NavLink>
          </li>

          <li className="menu">
            <Button>
              <i className="zmdi zmdi-time zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="module.evolution.hour" />
              </span>
            </Button>

            {this.getIntervals()}
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/evolution/patient-evolution">
              <i className="zmdi zmdi-account zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="module.evolution.patient" />
              </span>
            </NavLink>
          </li>
          {/* <li className="menu no-arrow">
            <NavLink to="/app/evolution/time-evolution?type=FR">
              <i className="zmdi zmdi-walk zmdi-hc-fw" />
              <span className="nav-text">Avaliar risco de queda</span>
            </NavLink>
          </li> */}

          <li className="menu">
            <Button>
              <i className="zmdi zmdi-triangle-down zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="module.evolution.care" />
              </span>
            </Button>

            {this.props.area !== "43" ? (
              <ul className="sub-menu">
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=VS">
                    <i className="zmdi zmdi-favorite zmdi-hc-fw" />
                    <span className="nav-text">Aferições Gerais</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=EH">
                    <i className="zmdi zmdi-airline-seat-flat-angled zmdi-hc-fw" />
                    <span className="nav-text">Cabeceira Elevada</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=C">
                    <i className="zmdi zmdi-plaster zmdi-hc-fw" />
                    <span className="nav-text">Curativo</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=D">
                    <i className="zmdi zmdi-hotel zmdi-hc-fw" />
                    <span className="nav-text">Decúbito</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=ED">
                    <i className="zmdi zmdi-format-color-fill zmdi-hc-fw" />
                    <span className="nav-text">Dieta - Enteral</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=OD">
                    <i className="zmdi zmdi-hc-fw">
                      <RestaurantIcon style={{ fontSize: "15px" }} />
                    </i>
                    <span className="nav-text">Dieta - Oral</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=M">
                    <i className="zmdi zmdi-walk zmdi-hc-fw" />
                    <span className="nav-text">Estimular Movimentação</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=H">
                    <i className="zmdi zmdi-drink zmdi-hc-fw" />
                    <span className="nav-text">Hidratação</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=PH">
                    <i className="zmdi zmdi-hc-fw">
                      <BathtubIcon style={{ fontSize: "15px" }} />
                    </i>
                    <span className="nav-text">Higiene Pessoal</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=IT">
                    <i className="zmdi zmdi-triangle-down zmdi-hc-fw" />
                    <span className="nav-text">Intercorrências</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=MP">
                    <i className="zmdi zmdi-eyedropper zmdi-hc-fw" />
                    <span className="nav-text">Medicamentos</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=IL">
                    <i className="zmdi zmdi-triangle-down zmdi-hc-fw" />
                    <span className="nav-text">Perneira Inflável</span>
                  </NavLink>
                </li>
                {/* <li >
            <NavLink to="/app/evolution/time-evolution?type=PS">
              <i className="zmdi zmdi-hc-fw">
                <SentimentDissatisfiedIcon style={{ fontSize: "15px" }} />
              </i>
              <span className="nav-text">Registrar escala de dor</span>
            </NavLink>
          </li> */}
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=P">
                    <i className="zmdi zmdi-scissors zmdi-hc-fw" />
                    <span className="nav-text">Retirada de Pontos</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=S">
                    <i className="zmdi zmdi-triangle-down zmdi-hc-fw" />
                    <span className="nav-text">Sonda Vesical</span>
                  </NavLink>
                </li>
              </ul>
            ) : (
              <ul className="sub-menu">
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=ED">
                    <i className="zmdi zmdi-format-color-fill zmdi-hc-fw" />
                    <span className="nav-text">Dieta - Enteral</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=OD">
                    <i className="zmdi zmdi-hc-fw">
                      <RestaurantIcon style={{ fontSize: "15px" }} />
                    </i>
                    <span className="nav-text">Dieta - Oral</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/evolution/time-evolution?type=H">
                    <i className="zmdi zmdi-drink zmdi-hc-fw" />
                    <span className="nav-text">Hidratação</span>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          {this.props.mdl_evolution && (
            <li className="menu no-arrow">
              <NavLink to="/app/evolution/intercurrence">
                <i className="zmdi zmdi-assignment-alert zmdi-hc-fw" />
                <span className="nav-text">Intercorrências</span>
              </NavLink>
            </li>
          )}

          <li className="menu no-arrow">
            <NavLink to="/app/evolution/shift-change">
              <i className="zmdi zmdi-swap zmdi-hc-fw" />
              <span className="nav-text">Passagem de plantão</span>
            </NavLink>
          </li>
          {this.props.prescription_care && (
            <li className="menu no-arrow">
              <NavLink to="/app/evolution/prescriptioncare">
                <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                <span className="nav-text">Prescrever Cuidado</span>
              </NavLink>
            </li>
          )}
          {/* {!this.props.prescription_care && ( */}
          <li className="menu no-arrow">
            <NavLink to="/app/evolution/requests">
              <i className="zmdi zmdi-mail-send zmdi-hc-fw" />
              <span className="nav-text">Solicitações</span>
            </NavLink>
          </li>
          {/* )} */}
          {/* {this.props.mdl_evolution && (
            <li className="menu no-arrow">
              <NavLink to="/app/evolution/requests">
                <i className="zmdi zmdi-mail-send zmdi-hc-fw" />
                <span className="nav-text">Solicitar remarcação</span>
              </NavLink>
            </li>
          )} */}
          {/* {this.props.area !== "29" && (
            <li className="menu no-arrow">
              <ModalChoose
                title="Transcrever Prescrição"
                url="/app/evolution/prescription-transcription"
                icon={<i className="zmdi zmdi-file-text zmdi-hc-fw" />}
              />
            </li>
          )} */}
          {/* <li className="menu no-arrow">
            <ModalChoose
              title="Medicamentos em uso"
              url="/app/evolution/medicines"
              icon={<i className="zmdi zmdi-eyedropper zmdi-hc-fw" />}
            />
          </li> */}

          <li className="menu no-arrow">
            <NavLink to="/app/dashboard">
              <i className="zmdi zmdi-undo zmdi-hc-fw" />
              <span className="nav-text">Sair da evolução</span>
            </NavLink>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = (state) => ({
  intervals: state.evolutionDaily.intervals,
  addressInterval: state.evolutionDaily.addressInterval,
  mdl_evolution: state.permissions.mdl_evolution,
  prescription_care: state.permissions.prescription_care,
  area: state.auth.area,
});

export default withRouter(connect(mapStateToProps)(Evolution));
