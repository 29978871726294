import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
} from "@material-ui/core";
import NoRecord from "components/Alert/NoRecord";
import FormDoctors from "./FormDoctors";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ModalPointRegister from "app/routes/management/routes/professional/management/modalPointRegister";

function Doctors({
  user,
  data,
  addressId,
  addressType,
  providerType,
  hiddenButton,
}) {
  const [state, setState] = useState([]);
  const [modal_form, setModalForm] = useState(false);
  const [modal_delete, setModalDelete] = useState(false);
  const [modal_point, setModalPoint] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [doctor_selected, setDoctorSelected] = useState({});

  useEffect(() => {
    setState(data);
  }, []);

  console.log("state doctors", state);

  function addRoom(obj) {
    setState([...state, obj]);
  }

  function editRoom(obj) {
    let aux = state.map((row) => (row.id === obj.id ? obj : row));
    setState(aux);
  }

  function deleteDoctor() {
    Api.post(`/ms_system/workplace/deleteDoctorAddress/`, {
      id: doctor_selected.id,
    }).then(() => {
      let aux = state.filter((row) => row.id !== doctor_selected.id);
      setState(aux);
      setModalDelete(false);
      setSuccessDelete(true);
    });
  }

  function getDisplayDay(day) {
    let display = "";
    switch (day) {
      case "0":
        display = "Domingo";
        break;
      case "1":
        display = "Segunda-Feira";
        break;
      case "2":
        display = "Terça-Feira";
        break;
      case "3":
        display = "Quarta-Feira";
        break;
      case "4":
        display = "Quinta-Feira";
        break;
      case "5":
        display = "Sexta-Feira";
        break;
      case "6":
        display = "Sábado";
        break;
      case "7":
        display = "Segunda a Sexta";
        break;
      case "8":
        display = "Todos os dias";
        break;

      default:
        display = "Não informado.";
        break;
    }
    return display;
  }

  return (
    <>
      <ExpansionPanel
        square
        style={{
          boxShadow: "10px 5px 5px white",
          borderBottom: "1px solid #d2d2d2",
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
        >
          Profissionais
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: "block" }}>
          <div className="w-100">
            <Button
              onClick={() => {
                setModalForm(true);
                setDoctorSelected("");
              }}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
              style={{ float: "right" }}
              hidden={hiddenButton}
            >
              <i className="zmdi zmdi-plus" /> <span> Adicionar</span>
            </Button>
          </div>
          {state.length > 0 ? (
            <Table className="table-finances w-100">
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Profissional</TableCell>
                  <TableCell align="left" width="50%">
                    Horários
                  </TableCell>
                  <TableCell align="right" width="30%">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.map((row) => (
                  <TableRow hover key={row.fk_ls_user}>
                    <TableCell component="th" scope="row" width="25%">
                      {row.name}
                    </TableCell>
                    <TableCell align="left" width="50%">
                      <ul>
                        {row.hours.map((obj, index) => (
                          <li key={index}>
                            <i>
                              {obj.specialty !== "Não especificada"
                                ? obj.specialty
                                : obj.area}
                            </i>
                            <br />
                            {obj.room}: {getDisplayDay(obj.day)} de{" "}
                            {obj.start.substr(0, 5)} às {obj.end.substr(0, 5)}
                          </li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell width="25%" align="right" hidden={hiddenButton}>
                      <Tooltip title="Registro de ponto">
                        <IconButton
                          onClick={() => {
                            setModalPoint(true);
                            setDoctorSelected({ ...row, view: "point" });
                          }}
                          color="primary"
                          aria-label="Registro de ponto"
                        >
                          <AssignmentTurnedInIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Horários">
                        <IconButton
                          onClick={() => {
                            setModalForm(true);
                            setDoctorSelected({ ...row, view: "hour" });
                          }}
                          color="primary"
                          aria-label="Horários"
                        >
                          <i className="zmdi zmdi-timer zmdi-hc-fw" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Editar">
                        <IconButton
                          onClick={() => {
                            setModalForm(true);
                            setDoctorSelected({ ...row, view: "all" });
                          }}
                          color="primary"
                          aria-label="Editar"
                        >
                          <i className="zmdi zmdi-edit zmdi-hc-fw" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remover">
                        <IconButton
                          onClick={() => {
                            setDoctorSelected(row);
                            setModalDelete(true);
                          }}
                          color="secondary"
                          aria-label="Remover"
                        >
                          <i className="zmdi zmdi-delete zmdi-hc-fw" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoRecord />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={modal_form}
        onClose={() => {
          setModalForm(false);
        }}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogContent>
          <FormDoctors
            user={user}
            data={{
              id: doctor_selected.id,
              doctor: doctor_selected.fk_ls_user,
              doctorName: doctor_selected.name,
              view: doctor_selected.view,
            }}
            addressId={addressId}
            addressType={addressType}
            providerType={providerType}
            close={() => setModalForm(false)}
            add={(obj) => addRoom(obj)}
            edit={(obj) => editRoom(obj)}
          />
        </DialogContent>
      </Dialog>
      {modal_point && (
        <ModalPointRegister
          openModal={modal_point}
          closeModal={() => setModalPoint(false)}
          userId={doctor_selected.fk_ls_user}
          addressId={addressId}
          profile={{
            id: doctor_selected.fk_ls_user,
            name: doctor_selected.name,
            url: doctor_selected.photo,
          }}
        />
      )}

      <SweetAlert
        show={modal_delete}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Deseja realmente remover este profissional?"
        onConfirm={() => deleteDoctor()}
        onCancel={() => setModalDelete(false)}
      />
      <SweetAlert
        show={success_delete}
        success
        title="Profissional excluído com sucesso!"
        onConfirm={() => setSuccessDelete(false)}
      />
    </>
  );
}

export default Doctors;
