import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";

const MaskMoney = ({
  label,
  value,
  onChange,
  unity,
  error,
  helperText = "",
  disabled = false,
}) => {
  return (
    <TextField
      label={label}
      margin="normal"
      fullWidth
      value={value}
      onChange={(e) => onChange(e)}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">{unity}</InputAdornment>,
        onInput: (e) => {
          if (e.target.value) {
            if (e.target.value.length === 1) {
              e.target.value = "0,0" + e.target.value;
            } else {
              let aux = e.target.value
                ? e.target.value.replace(/[^0-9]/gi, "")
                : 0;
              let leng = aux.length;
              let aux2 =
                aux.substring(0, leng - 2) +
                "." +
                aux.substring(leng - 2, leng);
              e.target.value = parseFloat(aux2)
                .toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
                .substr(3);
            }
          }
        },
      }}
      error={error}
      helperText={helperText}
      disabled={disabled}
    />
  );
};

export default MaskMoney;
