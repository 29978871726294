import { all, takeLatest, put, call } from "redux-saga/effects";
import api from "services/api";

const getDataPersonal = async user => {
  try {
    const response = await api.post(`/microservice/profile/getDataUser/`, {
      user
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

const getDataProvider = async user => {
  try {
    const response = await api.post(`/microservice/profile/getDataProvider/`, {
      user
    });
    return response.data.record;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetPersonalData(action) {
  try {
    const response = yield call(getDataPersonal, action.user);
    yield put({
      type: "PERSONAL_DATA",
      payload: {
        basic: response.basic,
        provider: response.provider,
        address: response.address[0],
        childrens: response.childrens[0],
        emergencyContacts: response.emergencyContacts[0],
        plansHealth: response.plansHealth[0],
        recreations: response.recreation,
        loaded: true
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

function* asyncGetProviderData(action) {
  try {
    const response = yield call(getDataProvider, action.user);
    yield put({
      type: "PROVIDER_DATA",
      payload: {
        provider: response,
        loaded: true
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest("GET_PERSONAL_DATA", asyncGetPersonalData),
    takeLatest("GET_PROVIDER_DATA", asyncGetProviderData)
  ]);
}
