import React, { useState, useEffect } from "react";
import api from "services/api";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton
} from "@material-ui/core";
import NoRecord from "components/Alert/NoRecord";
import ViewFoodPlan from "./ViewFoodPlan";

export default function FoodPlan(props) {
  const initState = {
    view: false,
    page: 0,
    rowsPerPage: 10,
    rows: [],
    selected: null
  };
  const [state, setState] = useState(initState);

  const handleChangePage = (event, page) => {
    setState({ ...state, page });
  };
  const handleChangeRowsPerPage = event => {
    setState({ ...state, rowsPerPage: event.target.value });
  };
  useEffect(() => {
    api
      .post(`/ms_nutrition/nutfoodplan/getHistoryListFoodPlan`, {
        patient: props.patient
      })
      .then(res => {
        setState({ ...state, rows: res.data.list });
      });
  }, []);
  const toogleView = (selected = null) => {
    setState({ ...state, view: !state.view, selected });
  };
  const { view, page, rows, rowsPerPage } = state;
  return (
    <div className="row m-2">
      <div className="col-12 container-person border">
        <h3
          className="title-container d-flex justify-content-start"
          style={{
            fontSize: "26px",
            fontWeight: "400",
            marginLeft: "14px"
          }}
        >
          Plano Alimentar
          <IconButton
            color="primary"
            onClick={() => setState({ ...state, view: !view })}
            hidden={!view}
          >
            <i className="zmdi zmdi-undo zmdi-hc-fw" />
          </IconButton>
        </h3>

        {!view ? (
          rows.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Profissional</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>
                        <IconButton
                          className="m-0 p-0"
                          onClick={() => toogleView(row.id)}
                          color="primary"
                        >
                          <i className="zmdi zmdi-eye zmdi-hc-fw" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Registros por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `Mostrando ${from} - ${to} de ${count}`
                    }
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          ) : (
            <NoRecord />
          )
        ) : (
          <ViewFoodPlan plan={state.selected} />
        )}
      </div>
    </div>
  );
}
