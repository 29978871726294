import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setConditions } from "actions/configuration/TagsConditions";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts.js";

import {
  IconButton,
  Divider,
  TextField,
  Button,
  Menu,
  MenuItem,
  Slider
} from "@material-ui/core";

class ConditionalIdade extends Component {
  state = {
    value: [[20, 40]],
    inputs: [
      {
        txt0: 20,
        txt1: 40
      }
    ],
    bg_active: [
      {
        maior: false,
        menor: false,
        igual: false,
        intervalo: true
      }
    ],
    input_global: [0],
    arr_open: [false],
    anchorEl: [undefined]
  };

  handleChangeTextField = (event, index, indexVal) => {
    let aux = this.state.value;
    let { value } = event.target;
    if (indexVal === 1 && value < this.state.value[index][0]) {
      this.alertMessage("error", "Insira um valor maior que o inicial");
      this.setState(state => {
        state.inputs[index].txt1 = this.state.value[index][1];
      });
      document.getElementById(`${index}-txt1`).focus();
    } else if (indexVal === 0 && value > this.state.value[index][1]) {
      this.alertMessage("error", "Insira um valor menor que o final");
      this.setState(state => {
        state.inputs[index].txt0 = this.state.value[index][0];
      });
      document.getElementById(`${index}-txt0`).focus();
    } else {
      if (value > 125 || value < 0) {
        this.alertMessage("error", "Insira um valor entre 0 e 125 anos");
        this.setState(state => {
          state.inputs[index].txt0 = this.state.value[index][0];
        });
        this.setState(state => {
          state.inputs[index].txt1 = this.state.value[index][1];
        });
        document.getElementById(`${index}-txt0`).focus();
      } else {
        aux[index][indexVal] = value === "" ? 0 : parseInt(value);
      }
    }
    this.setState({ value: aux });
  };

  handleKeyUpTxt = (key, index, indexVal) => {
    if (key === 13) {
      if (indexVal === 0) {
        document.getElementById(`${index}-txt1`).focus();
      } else {
        document.getElementById(`${index}-txt1`).blur();
      }
    }
  };

  alertMessage = async (type, message) => {
    await this.props.viewAlertMessage(type, message);
    await setTimeout(this.props.closeAlertMessage, 5000);
  };

  handleChangeValues = (e, index, indexVal) => {
    let { value } = e.target;
    if (indexVal === 0) {
      this.setState(state => {
        state.inputs[index].txt0 = value;
        return state;
      });
    } else {
      this.setState(state => {
        state.inputs[index].txt1 = value;
        return state;
      });
    }
  };

  handleChangeSliderToText = index => {
    let { value } = this.state;
    this.setState(state => {
      state.inputs[index].txt0 = value[index][0];
      state.inputs[index].txt1 = value[index][1];
      return state;
    });
  };

  handleChange = index => (event, newValue) => {
    let newArr = [];
    let cont = 0;
    this.state.value.forEach(row => {
      if (cont === index) {
        newArr.push(newValue);
      } else {
        newArr.push(row);
      }
      cont++;
    });
    this.setState({ value: newArr });
    this.props.setConditions("SET_CONDITION_IDADE", newArr);
  };
  handleClickPlus = () => {
    let newArr = this.state.value.map(row => row);
    let aux = this.state.inputs;
    let new_bg_active = this.state.bg_active;
    let aux_arr_open = this.state.arr_open;
    let aux_anchorEl = this.state.anchorEl;
    aux.push({ txt0: 20, txt1: 40 });
    new_bg_active.push({
      maior: false,
      menor: false,
      igual: false,
      intervalo: true
    });
    newArr.push([20, 40]);
    aux_arr_open.push(false);
    aux_anchorEl.push(undefined);
    this.setState({
      value: newArr,
      inputs: aux,
      arr_open: aux_arr_open,
      anchorEl: aux_anchorEl
    });
    this.props.setConditions("SET_CONDITION_IDADE", newArr);
  };
  handleClickMinus = index => {
    let newArr = this.state.value.map(row => row);
    let aux = this.state.inputs;
    let new_bg_active = this.state.bg_active;
    let aux_arr_open = this.state.arr_open;
    let aux_anchorEl = this.state.anchorEl;
    aux.splice(index, 1);
    new_bg_active.splice(index, 1);
    newArr.splice(index, 1);
    aux_arr_open.splice(index, 1);
    aux_anchorEl.splice(index, 1);
    this.setState({
      value: newArr,
      inputs: aux,
      bg_active: new_bg_active,
      arr_open: aux_arr_open,
      anchorEl: aux_anchorEl
    });
    this.props.setConditions("SET_CONDITION_IDADE", newArr);
  };
  handleChangeButton = (index, value) => {
    let new_bg_active = {
      maior: false,
      menor: false,
      igual: false,
      intervalo: false
    };
    new_bg_active[`${value}`] = true;
    this.setState(state => {
      state.bg_active[`${index}`] = new_bg_active;
      return state;
    });
  };

  handleChangeTextGlobal = ({ value }, index) => {
    if (value < 0) {
      this.alertMessage("error", "Insira um valor maior que 0");
      this.setState(state => {
        state.input_global[index] = "";
        return state;
      });
      let arr_keys = Object.keys(this.state.bg_active[index]);
      let arr_values = Object.values(this.state.bg_active[index]);

      arr_values.forEach((val, i) => {
        if (val) {
          switch (arr_keys[i]) {
            case "maior":
              this.setState(state => {
                state.value[index] = [0, 0];
                return state;
              });
              break;
            case "menor":
              this.setState(state => {
                state.value[index] = [0, 0];
                return state;
              });
              break;
            case "igual":
              this.setState(state => {
                state.value[index] = [0, 0];
                return state;
              });
              break;
            default:
              break;
          }
        }
      });
    } else {
      this.setState(state => {
        state.input_global[index] = value === "" ? "" : parseInt(value);
        return state;
      });
      let arr_keys = Object.keys(this.state.bg_active[index]);
      let arr_values = Object.values(this.state.bg_active[index]);

      arr_values.forEach((val, i) => {
        if (val) {
          switch (arr_keys[i]) {
            case "maior":
              this.setState(state => {
                state.value[index] = [parseInt(value), 125];
              });
              break;
            case "menor":
              this.setState(state => {
                state.value[index] = [0, parseInt(value)];
              });
              break;
            case "igual":
              this.setState(state => {
                state.value[index] = [parseInt(value), parseInt(value)];
              });
              break;
            default:
              break;
          }
        }
      });
    }
    this.props.setConditions("SET_CONDITION_IDADE", this.state.value);
  };

  returnTxtButton = index => {
    let values = Object.values(this.state.bg_active[index]);
    let keys = Object.keys(this.state.bg_active[index]);
    let index_bg = values.indexOf(true);
    return keys[index_bg];
  };

  handleChangeMenu = (index, value) => {
    let aux_bg_active = {
      maior: false,
      menor: false,
      igual: false,
      intervalo: false
    };
    aux_bg_active[value] = true;
    this.setState(state => {
      state.bg_active[index] = aux_bg_active;
    });
    this.handleRequestCloseButton(index);
  };

  handleClickButton = (event, index) => {
    let target = event.currentTarget;
    this.setState(state => {
      state.arr_open[index] = true;
      state.anchorEl[index] = target;
      return state;
    });
  };

  handleRequestCloseButton = index => {
    this.setState(state => {
      state.arr_open[index] = false;
      state.anchorEl[index] = undefined;
      return state;
    });
  };

  componentDidMount() {
    if (this.props.params !== null) {
      let newValue = this.props.params.map(row => [row.val1, row.val2]);
      let aux = this.props.params.map(row => {
        return { txt0: row.val1, txt1: row.val2 };
      });
      let aux_input_global = [];
      let aux_bg_active = [];
      this.props.params.forEach(row => {
        if (row.val1 === row.val2) {
          aux_input_global.push(parseInt(row.val1));
          aux_bg_active.push({
            maior: false,
            menor: false,
            igual: true,
            intervalo: false
          });
        } else if (row.val2 === "125") {
          aux_input_global.push(parseInt(row.val1));
          aux_bg_active.push({
            maior: true,
            menor: false,
            igual: false,
            intervalo: false
          });
        } else if (row.val1 === "0") {
          aux_input_global.push(parseInt(row.val2));
          aux_bg_active.push({
            maior: false,
            menor: true,
            igual: false,
            intervalo: false
          });
        }
      });
      if (aux_bg_active.length === 0) {
        aux_input_global.push(0);
        aux_bg_active.push({
          maior: false,
          menor: false,
          igual: false,
          intervalo: true
        });
      }
      this.setState({
        value: newValue,
        inputs: aux,
        input_global: aux_input_global,
        bg_active: aux_bg_active
      });
      this.props.setConditions("SET_CONDITION_IDADE", newValue);
    }
  }
  render() {
    const { value, bg_active, input_global } = this.state;
    return (
      <div className="row" style={{ marginTop: "20px" }}>
        <div className="col-md-12">
          <h4>Idade:</h4>
        </div>
        {value.map((val, index) => (
          <React.Fragment key={index}>
            <div style={{ width: "90%" }}>
              {bg_active[`${index}`].intervalo ? (
                <div style={{ width: "100%", display: "flex", margin: "10px" }}>
                  <div style={{ marginTop: "30px" }}>
                    <Button
                      onClick={event => this.handleClickButton(event, index)}
                      fullWidth={true}
                      variant="outlined"
                      style={{ textTransform: "none" }}
                    >
                      {this.returnTxtButton(index)}
                      <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
                    </Button>
                    <Menu
                      anchorEl={this.state.anchorEl[index]}
                      open={this.state.arr_open[index]}
                      onClose={() => this.handleRequestCloseButton(index)}
                    >
                      <MenuItem
                        onClick={() => this.handleChangeMenu(index, "maior")}
                      >
                        Maior
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleChangeMenu(index, "menor")}
                      >
                        Menor
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleChangeMenu(index, "igual")}
                      >
                        Igual
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleChangeMenu(index, "intervalo")
                        }
                      >
                        Intervalo
                      </MenuItem>
                    </Menu>
                  </div>
                  <div style={{ width: "100%", display: "flex" }}>
                    <div>
                      <TextField
                        id={`${index}-txt0`}
                        label="Início"
                        type="number"
                        onKeyDown={event =>
                          this.handleKeyUpTxt(event.keyCode, index, 0)
                        }
                        value={this.state.inputs[index].txt0}
                        onChange={event =>
                          this.handleChangeValues(event, index, 0)
                        }
                        style={{ width: "80px", float: "left", margin: "15px" }}
                        onBlur={event =>
                          this.handleChangeTextField(event, index, 0)
                        }
                      />
                    </div>
                    <div style={{ paddingTop: "50px", width: "70%" }}>
                      <Slider
                        value={val}
                        onChange={this.handleChange(index)}
                        onChangeCommitted={() =>
                          this.handleChangeSliderToText(index)
                        }
                        aria-labelledby="range-slider"
                        max={125}
                        valueLabelDisplay="on"
                      />
                    </div>
                    <div>
                      <TextField
                        id={`${index}-txt1`}
                        onKeyDown={event =>
                          this.handleKeyUpTxt(event.keyCode, index, 1)
                        }
                        label="Fim"
                        type="number"
                        value={this.state.inputs[index].txt1}
                        onChange={event =>
                          this.handleChangeValues(event, index, 1)
                        }
                        style={{
                          width: "80px",
                          float: "right",
                          margin: "15px"
                        }}
                        onBlur={event =>
                          this.handleChangeTextField(event, index, 1)
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ width: "100%", display: "flex", margin: "10px" }}>
                  <div style={{ marginTop: "30px" }}>
                    <Button
                      onClick={event => this.handleClickButton(event, index)}
                      fullWidth={true}
                      variant="outlined"
                      style={{ textTransform: "none" }}
                    >
                      {this.returnTxtButton(index)}
                      <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
                    </Button>
                    <Menu
                      anchorEl={this.state.anchorEl[index]}
                      open={this.state.arr_open[index]}
                      onClose={() => this.handleRequestCloseButton(index)}
                    >
                      <MenuItem
                        onClick={() => this.handleChangeMenu(index, "maior")}
                      >
                        Maior
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleChangeMenu(index, "menor")}
                      >
                        Menor
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleChangeMenu(index, "igual")}
                      >
                        Igual
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleChangeMenu(index, "intervalo")
                        }
                      >
                        Intervalo
                      </MenuItem>
                    </Menu>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: "15px",
                      padding: "0 15px"
                    }}
                  >
                    {/* <h4>Digite o valor desejado: </h4> */}
                    <TextField
                      value={input_global[index]}
                      style={{ minWidth: "200px" }}
                      id={`${index}-text-field-global`}
                      onChange={event =>
                        this.handleChangeTextGlobal(event.target, index)
                      }
                      type="number"
                      label="Digite o valor desejado:"
                    />
                  </div>
                </div>
              )}
            </div>

            <div style={{ width: "10%", paddingTop: "25px" }}>
              {index === 0 ? (
                <IconButton color="primary" onClick={this.handleClickPlus}>
                  <i className="zmdi zmdi-plus-square" />
                </IconButton>
              ) : (
                <IconButton
                  color="secondary"
                  onClick={() => this.handleClickMinus(index)}
                >
                  <i className="zmdi zmdi-minus-square" />
                </IconButton>
              )}
            </div>
          </React.Fragment>
        ))}
        <Divider />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { setConditions, viewAlertMessage, closeAlertMessage },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(ConditionalIdade);
