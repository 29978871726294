const DB = window.indexedDB.open("salutem_homecare", 5);

DB.onsuccess = function(event) {
  console.log("success");
  // Fazer algo com DB.result!
};

DB.onerror = function(event) {
  // Fazer algo com DB.errorCode!
  console.log("error");
};

DB.onupgradeneeded = function(event) {
  var db = event.target.result;

  if (event.oldVersion < 1) {
    db.createObjectStore("patients", { keyPath: "id" });

    db.createObjectStore("cares", { keyPath: "user" });

    db.createObjectStore("saved_cares", {
      autoIncrement: true,
    });

    db.createObjectStore("visit", {
      autoIncrement: true,
    });

    db.createObjectStore("access", {
      autoIncrement: true,
    });
  }

  if (event.oldVersion > 1 && event.oldVersion < 4) {
    db.deleteObjectStore("saved_routes", {
      autoIncrement: true,
    });
  }

  if (event.oldVersion < 5) {
    db.createObjectStore("saved_routes", {
      keyPath: "id",
    });
  }
};

export default DB;
