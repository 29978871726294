import React from "react";

const NoRecord = () => {
  return (
    <div
      color="light"
      style={{ width: "100%", textAlign: "center", marginTop: "10px" }}
    >
      {/* <span style={{ color: "#0000008a" }}>Nenhum registro.</span> */}
      <p
        style={{
          textAlign: "center",
          color: "#a6a6a6",
          fontStyle: "italic"
        }}
      >
        Nenhum Registro Encontrado
      </p>
    </div>
  );
};

export default NoRecord;
