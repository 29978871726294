const INITIAL_STATE = {
  patient: {
    id: "",
    name: "",
    smartphone: "",
    email: "",
    photo: `${require("assets/images/avatar_user_men.jpg")}`,
    gender: "",
    birthday: "",
    birthday_br: "",
    pregnancy: false,
  },
  loaded: false,
};

export default function patientInfo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_PATIENT_INFO":
      return {
        ...state,
      };
    case "PATIENT_INFO":
      const { patient } = action.payload;
      return {
        patient: {
          ...patient,
        },
        loaded: true,
      };
    case "UNSET_PATIENT_INFO":
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}
