const INITIAL_STATE = {
  basic: {
    name: "",
    sex: "",
    age: "",
    birthday: "",
    raca: "",
    genderVal: "",
    naturality: "",
    naturality_id: "",
    procedence: "",
    procedence_id: "",
    blood_type: "",
    schooling: "",
    area_id: "",
    area: "",
    photo: "",
    fk_profession: "",
    profession: "",
    fk_ocuppation: "",
    ocuppation: "",
    ocupation: "",
    email: "",
    cellphone: "",
    smartphone: "",
    whatsapp: "",
    phone: "",
    gemelar: "",
    choose_sexual: "",
    estado_civil: "",
    mariage_regime: "",
    religion: "",
    cpf: "",
    rg: "",
    issuing_entity: "",
    title_elector: "",
    mother_name: "",
    mother_id: "",
    father_name: "",
    father_id: "",
    wife_name: "",
    wife_id: "",
    end_mariage: "",
    rent: "",
    council: "",
    state: "",
    yearRegister: "",
    numberRegister: "",
    stateRg: "",
    date_issue: "",
  },
  health_plan: {},
  childrens: {},
  person_address: {},
  emergency_contacts: {},
  all_recreations: [],
  recreations_user: [],
  provider: {
    about: "",
    cellphone: "",
    cnpj: "",
    email: "",
    facebook: "",
    fk_provider_type: "",
    fk_user: "",
    foundation_date: "",
    google_plus: "",
    instagram: "",
    linkedin: "",
    name: "",
    phone: "",
    photo: "",
    twitter: "",
    website: "",
    whatsapp: "",
    who_recommended: "",
  },
};

export default function profile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_PERSONAL_DATA":
      return {
        ...state,
      };
    case "PERSONAL_DATA":
      return {
        ...state,
        basic: action.payload.basic,
        person_address: action.payload.address,
        childrens: action.payload.childrens,
        emergency_contacts: action.payload.emergencyContacts,
        health_plan: action.payload.plansHealth,
        all_recreations: action.payload.recreations.all,
        recreations_user: action.payload.recreations.user,
        loaded: true,
      };

    case "GET_PROVIDER_DATA":
      return {
        ...state,
      };
    case "PROVIDER_DATA":
      return {
        ...state,
        provider: action.payload.provider,
      };
    case "HANDLE_CHANGE":
      const { index, value } = action;
      return {
        ...state,
        basic: {
          ...state.basic,
          [`${index}`]: value,
        },
      };

    case "HANDLE_CHANGE_PROVIDER":
      const { indexProvider, valueProvider } = action;
      return {
        ...state,
        provider: {
          ...state.provider,
          [`${indexProvider}`]: valueProvider,
        },
      };

    case "HANDLE_CHANGE_RECREATION":
      return {
        ...state,
        recreations_user: action.value,
      };
    // PLANOS DE SAÚDE
    case "ADD_HEALTH_PLAN_PROFILE":
      return {
        ...state,
        health_plan: [
          ...state.health_plan,
          {
            ...action.health_plan,
          },
        ],
      };
    case "EDIT_HEALTH_PLAN_PROFILE":
      let newArr = [];
      state.health_plan.forEach((row) => {
        if (row.id === action.health_plan.id) {
          row = { ...action.health_plan };
        }
        newArr.push(row);
      });
      return {
        ...state,
        health_plan: [...newArr],
      };

    // ENDEREÇO PESSOAL
    case "ADD_PERSON_ADDRESS":
      return {
        ...state,
        person_address: [
          ...state.person_address,
          {
            ...action.person_address,
          },
        ],
      };
    case "EDIT_PERSON_ADDRESS":
      let arrPersonAddress = [];
      state.person_address.forEach((row) => {
        if (row.userAddress.id === action.person_address.userAddress.id) {
          row = { ...action.person_address };
        }
        arrPersonAddress.push(row);
      });
      return {
        ...state,
        person_address: [...arrPersonAddress],
      };
    // CONTATO DE EMERGENCIA
    case "ADD_EMERGENCY_CONTACT":
      return {
        ...state,
        emergency_contacts: [
          ...state.emergency_contacts,
          {
            ...action.contact,
          },
        ],
      };
    case "EDIT_EMERGENCY_CONTACT":
      let arrContacts = [];
      state.emergency_contacts.forEach((row) => {
        if (row.id === action.contact.id) {
          row = { ...action.contact };
        }
        arrContacts.push(row);
      });
      return {
        ...state,
        emergency_contacts: [...arrContacts],
      };
    // FILHOS
    case "ADD_CHILDREN":
      return {
        ...state,
        childrens: [
          ...state.childrens,
          {
            ...action.children,
          },
        ],
      };
    case "EDIT_CHILDREN":
      let arrChildrens = [];
      state.childrens.forEach((row) => {
        if (row.id === action.children.id) {
          row = { ...action.children };
        }
        arrChildrens.push(row);
      });
      return {
        ...state,
        childrens: [...arrChildrens],
      };
    case "RESET_PERSONAL_DATA":
      return INITIAL_STATE;
    case "GET_COUNCIL":
      return {
        ...state,
      };
    default:
      return state;
  }
}
