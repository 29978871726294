import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Blog = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Route
          path={`${match.url}/`}
          exact
          component={asyncComponent(() => import("./routes/list"))}
        />
        <Route
          path={`${match.url}/post`}
          component={asyncComponent(() => import("./routes/post"))}
        />
      </Switch>
    </div>
  );
};

export default Blog;
