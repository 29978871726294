import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

export default function Intranet({ match, history }) {
  return (
    <Switch>
      <Route
        path={`${match.url}/user-manage`}
        component={asyncComponent(() => import("./usermanagement"))}
      />
      <Route
        path={`${match.url}/group-manage`}
        component={asyncComponent(() => import("./groupManagement"))}
      />
    </Switch>
  );
}
