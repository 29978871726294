import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import api from "services/api";

const requestGetDetailFood = async id => {
  const res = await api.post(`/microservice/tbca/getDetailFood`, { id });
  const data = await res.data;
  return data;
};

function* getDetailFood(action) {
  try {
    const res = yield call(requestGetDetailFood, action.id);
    yield put({
      type: "GET_DETAIL_FOOD_SUCCESS",
      payload: res
    });
  } catch (error) {
    console.log("Erro getDetailFood() ** Tbca **", error);
  }
}

export function* listenGetDetailFood() {
  yield takeLatest("GET_DETAIL_FOOD", getDetailFood);
}

/**
 * Exporta funções do saga
 */
export default function* rootSaga() {
  yield all([fork(listenGetDetailFood)]);
}
