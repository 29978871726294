import React from "react";
import DataTable from "components/Tables/DataTable";
import { Tooltip, IconButton } from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan } from "components/Button";
import { useSelector } from "react-redux";
import ProfileNew from "components/ComponentsProfile/ProfileNew";
import Api from "services/api";

export default function ExamsAddress({ record }) {
  const [listE, setlistE] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [exam, setExam] = React.useState("");
  const data_dash = useSelector((state) => state.dashboard.data_dash);
  const address = useSelector((state) => state.auth.address);

  const getPatientExa = (id) => {
    Api.post(`/ms_system/dashboard/getPatientExa`, {
      address,
      exa_id: id,
      start: data_dash.start,
      end: data_dash.end,
    }).then((res) => {
      setlistE(res.data.record);
      setOpen(true);
    });
  };

  return (
    <>
      <DataTable
        name="Pedidos de Exame"
        columns={["Exame", "Quantidade", "Ação"]}
        rows={record.map((row, index) => [
          row.test,
          row.total,
          <Tooltip title="Visualizar Pacientes">
            <IconButton
              style={{ padding: "6px" }}
              color="primary"
              onClick={() => {
                getPatientExa(row.id);
                setExam(row.test);
              }}
            >
              <i className="zmdi zmdi-eye zmdi-fw" />
            </IconButton>
          </Tooltip>,
        ])}
        hideSearch={true}
      />
      <ModalResponsive
        open={open}
        close={() => setOpen(false)}
        maxWidth="md"
        title="Exames Pacientes"
        buttons={
          <div className="col-12">
            <BtnCan
              title="Fechar"
              onClick={() => setOpen(false)}
              float="left"
            />
          </div>
        }
      >
        <>
          <DataTable
            name={`${exam}`}
            columns={["Pacientes"]}
            rows={listE.map((row, index) => [
              <ProfileNew
                profile={{
                  id: row.id,
                  name: row.name,
                  photo: row.photo,
                  birthday: row.birthday,
                  mother_name: row.mother_name,
                }}
              />,
            ])}
            hideSearch={true}
          />
        </>
      </ModalResponsive>
    </>
  );
}
