import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Api from "services/api";
import { setManagimentPermission } from "actions/Permissions";
import { setDataEvolution } from "actions/evolution/Daily";
import { wrongBrowser } from "actions/Alerts";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Login from "./routes/login";
import Consult from "./routes/consult";
import Nutrition from "./routes/consult/routes/nutrition";
import Evolution from "./routes/evolution";
import Finance from "./routes/finance";
import Workplace from "./routes/workplace";
import Academic from "./routes/academic/index";
import Dashboard from "./routes/dashboard/index";
import Calendar from "./routes/calendar/index";
import Confirm from "./routes/confirm/index";
import Choose from "./routes/choose";
import Blog from "./routes/blog";
import Intranet from "./routes/intranet";
import OnlineScheduler from "./routes/onlineScheduler";
import Telemedicine from "./routes/comunication/routes/telemedicine";
import FormPrint from "./routes/formPrint";
import Comunication from "./routes/comunication";
import Configuration from "components/Configuration";
import Profile from "./routes/profile";
import Management from "./routes/management";
import Report from "./routes/report";
import Admission from "./routes/admission";
import Requests from "./routes/requests";
import Pharmacy from "./routes/stock";
import FamilyAccompaniment from "./routes/familyAccompaniment";
import PointRegister from "./routes/management/routes/pointRegister";
import ManagePointRegister from "./routes/management/routes/managePointRegister";
import Homecare from "./routes/homecare";
import PatientPortal from "./routes/patientPortal/index";
import FormForward from "./routes/forwardForm/index";
import Prescriptioncare from "./routes/prescriptioncare";
import PrescriptionSigned from "app/routes/consult/routes/prescription/ViewPrescriptionSigned";
import ViewExamSigned from "components/Forms/Consult/exams/ViewExamSigned/index";
import Alert from "components/Alert/AlertSnack";
import FormNotificationCovid from "./routes/notificationCovid/routes/form";
import ListNotificationCovid from "./routes/notificationCovid/routes/list";
import VaccineChild from "./routes/notificationCovid/routes/vaccineChild";
import Monitoring from "./routes/monitoring";

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import TopNav from "components/TopNav";
import moment from "moment";
import { CARE_TYPES_INFO } from "actions/evolution/Daily";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import SweetAlert from "react-bootstrap-sweetalert";

class App extends React.Component {
  state = {
    modalCareNotification: false,
    caresNotification: [],
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.address !== nextProps.address) {
      this.getNewInfoAddress(nextProps.address);
    }
  }

  componentDidMount() {
    if (this.props.address) {
      if (
        moment().isAfter(
          moment(this.props.intervals[this.props.intervals.length - 1].end)
        )
      ) {
        this.getNewInfoAddress(this.props.address);
      }
    }
  }

  getNewInfoAddress(address) {
    if (address) {
      Api.post(
        `/microservice/managementprofessional/getProfessionalPermissions`,
        { idDoctor: this.props.authUser, idAddress: address }
      )
        .then(({ data }) => {
          if (data.success) {
            this.props.setManagimentPermission(data.list);
            if (data.workHour.length > 0) {
              this.props.setDataEvolution({
                intervals: data.workHour,
                isWorkday: true,
                addressInterval: data.workInterval,
                groupId: data.id,
                residents: data.members,
              });
              if (["5", "6", "29"].indexOf(this.props.area) !== -1) {
                this.validCares(data.workHour, data.members);
              }
            } else {
              this.props.setDataEvolution({
                intervals: [{ start: "", end: "" }],
                isWorkday: false,
                addressInterval: data.workInterval,
                groupId: data.id,
                residents: data.members,
              });
            }
          }
        })
        .catch(() => {});
    } else {
      this.props.setManagimentPermission({
        manager: false,
        approver: false,
        master: false,
        mdl_admission: false,
        mdl_evolution: false,
        manager_patient: false,
      });
    }
  }

  validCares = (interval, members) => {
    let patients = members.map((row) => row.id).join(",");

    Api.post(`/ms_evolution/daily/validCares`, {
      patients,
      start: interval[0].start,
      address: this.props.address,
    }).then((res) => {
      const { record, managers } = res.data;
      let aux = [];
      let str = "";
      let date = moment().format("DD/MM/YYYY HH:mm");
      Object.keys(record).forEach((row) => {
        if (record[row].professional) {
          aux.push({
            care: CARE_TYPES_INFO[row].name,
            count: record[row].professional,
          });
        }
        if (record[row].manager) {
          str += CARE_TYPES_INFO[row].name + " (" + record[row].manager + ");";
        }
      });
      if (aux.length > 0) {
        this.setState({
          caresNotification: aux,
          modalCareNotification: true,
        });
      }
      managers.forEach((manager) => {
        getDataFirebase()
          .child(`notification${getEnviromentFirebase()}/` + manager.fk_ls_user)
          .push({
            user_from: "15",
            user_to: manager.fk_ls_user,
            date,
            title: `Cuidado(s) ainda não realizado(s):`,
            text: str,
            type: "cares_unrealized",
            action: "",
            image:
              "https:app.salutemplus.com/static/media/Logo_Salutem Plus_Branca-01.5e06ce8c.svg",
            icon: "zmdi-alert-triangle",
            read: false,
          });
      });
    });
  };

  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
      history,
      wrongBrowser,
      isEdg,
    } = this.props;

    const drawerStyle =
      this.props.location.pathname.split("/")[2] === "evolution" &&
      window.innerWidth > 1200
        ? "mini-drawer"
        : drawerType.includes(FIXED_DRAWER)
        ? "fixed-drawer"
        : drawerType.includes(COLLAPSED_DRAWER)
        ? "collapsible-drawer"
        : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }
    let url = window.location.href;
    url = url.split("?")[0];
    url = url.split("/");
    const condition =
      url.indexOf("confirm") !== -1 ||
      url.indexOf("caboverde") !== -1 ||
      url.indexOf("blog") !== -1 ||
      url.indexOf("online-scheduler") !== -1 ||
      url.indexOf("prescription-signed") !== -1 ||
      url.indexOf("telemedicine") !== -1 ||
      url.indexOf("notification-covid") !== -1 ||
      url.indexOf("exam-signed") !== -1;

    return (
      <>
        {!condition ? (
          <div className={`app-container ${drawerStyle}`}>
            {/* <Tour/> */}
            <Switch>
              {/* Sidenav */}
              <Route
                path={`${match.url}/consult/`}
                render={(_props) => <Sidebar type="consult" match={match} />}
              />
              <Route
                path={`${match.url}/admission/`}
                render={(_props) => <Sidebar type="admission" match={match} />}
              />
              <Route
                path={`${match.url}/nutrition/`}
                render={(_props) => <Sidebar type="nutrition" match={match} />}
              />
              <Route
                path={`${match.url}/finance/list`}
                render={(_props) => <Sidebar type="finance" match={match} />}
              />
              <Route
                path={`${match.url}/finance/`}
                render={(_props) => <Sidebar type="finance" match={match} />}
              />
              <Route
                path={`${match.url}/evolution/`}
                render={(_props) => <Sidebar type="evolution" match={match} />}
              />
              <Route
                path={`${match.url}/pharmacy/`}
                render={(_props) => <Sidebar type="stock" match={match} />}
              />

              <Route
                path={`${match.url}/homecare/scheduling-professional`}
                render={(_props) => (
                  <Sidebar type="scheduler-homecare" match={match} />
                )}
              />
              <Route
                path={`${match.url}/homecare/scheduling-patient`}
                render={(_props) => (
                  <Sidebar type="scheduler-homecare" match={match} />
                )}
              />
              <Route
                path={`${match.url}/homecare/schedule-professional`}
                render={(_props) => (
                  <Sidebar type="scheduler-homecare" match={match} />
                )}
              />
              <Route
                path={`${match.url}/homecare/`}
                render={(_props) => <Sidebar type="homecare" match={match} />}
              />
              <Route
                path={`${match.url}/family-accompaniment`}
                render={(_props) => (
                  <Sidebar type="family-accompaniment" match={match} />
                )}
              />
              <Route
                path={`${match.url}/patient-portal`}
                render={(_props) => <Sidebar type="pp" match={match} />}
              />
              <Route
                path={`${match.url}/form-notification-covid`}
                render={(_props) => <Sidebar type="nc" match={match} />}
              />
              <Route
                path={`${match.url}/vaccine-child`}
                render={(_props) => <Sidebar type="nc" match={match} />}
              />
              <Route
                render={(_props) => <Sidebar type="default" match={match} />}
              />
            </Switch>

            <div className="app-main-container">
              <div
                className={`app-header ${
                  navigationStyle === HORIZONTAL_NAVIGATION
                    ? "app-header-horizontal"
                    : ""
                }`}
              >
                {navigationStyle === HORIZONTAL_NAVIGATION &&
                  horizontalNavPosition === ABOVE_THE_HEADER && (
                    <TopNav styleName="app-top-header" />
                  )}
                <Header />
                {navigationStyle === HORIZONTAL_NAVIGATION &&
                  horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
              </div>

              <main
                className="app-main-content-wrapper"
                // style={{ padding: "0px" }}
              >
                <div className="app-main-content">
                  <Switch>
                    <Route path={`${match.url}/profile`} component={Profile} />

                    <Route
                      path={`${match.url}/calendar`}
                      component={Calendar}
                    />
                    <Route exact path={`${match.url}`} component={Dashboard} />
                    <Route
                      path={`${match.url}/dashboard`}
                      component={Dashboard}
                    />
                    {/* Rotas Modulos salutem */}
                    <Route path={`${match.url}/Login`} component={Login} />
                    <Route
                      path={`${match.url}/form-forward`}
                      component={FormForward}
                    />
                    <Route path={`${match.url}/consult`} component={Consult} />
                    <Route
                      path={`${match.url}/nutrition`}
                      component={Nutrition}
                    />
                    <Route path={`${match.url}/confirm`} component={Confirm} />
                    <Route
                      path={`${match.url}/evolution`}
                      component={Evolution}
                    />
                    <Route path={`${match.url}/finance`} component={Finance} />
                    <Route
                      path={`${match.url}/workplace`}
                      component={Workplace}
                    />
                    <Route
                      path={`${match.url}/academic`}
                      component={Academic}
                    />
                    <Route path={`${match.url}/choose`} component={Choose} />
                    <Route
                      path={`${match.url}/intranet`}
                      component={Intranet}
                    />
                    <Route
                      path={`${match.url}/formPrint`}
                      component={FormPrint}
                    />
                    <Route
                      path={`${match.url}/comunication`}
                      component={Comunication}
                    />
                    <Route path={`${match.url}/report`} component={Report} />
                    <Route
                      path={`${match.url}/admission`}
                      component={Admission}
                    />
                    <Route
                      path={`${match.url}/management`}
                      component={Management}
                    />
                    <Route
                      path={`${match.url}/requests`}
                      component={Requests}
                    />
                    <Route
                      path={`${match.url}/homecare`}
                      component={Homecare}
                    />
                    <Route
                      path={`${match.url}/prescriptioncare`}
                      component={Prescriptioncare}
                    />
                    <Route
                      path={`${match.url}/family-accompaniment`}
                      component={FamilyAccompaniment}
                    />
                    <Route
                      path={`${match.url}/point-register`}
                      component={PointRegister}
                    />
                    <Route
                      path={`${match.url}/manage-point-register`}
                      component={ManagePointRegister}
                    />
                    <Route
                      path={`${match.url}/pharmacy`}
                      component={Pharmacy}
                    />
                    <Route
                      path={`${match.url}/patient-portal`}
                      component={PatientPortal}
                    />
                    {/* <Route path={`${match.url}/blog`} component={Blog} /> */}

                    <Route
                      path={`${match.url}/form-notification-covid`}
                      component={FormNotificationCovid}
                    />
                    <Route
                      path={`${match.url}/list-notification-covid`}
                      component={ListNotificationCovid}
                    />
                    <Route
                      path={`${match.url}/vaccine-child`}
                      component={VaccineChild}
                    />
                    <Route
                      path={`${match.url}/monitoring`}
                      component={Monitoring}
                    />

                    <Route component={Dashboard} />
                  </Switch>
                </div>
                {/* <Footer /> */}
              </main>
            </div>
          </div>
        ) : (
          <div className={`app-container ${drawerStyle}`}>
            <div className="app-main-container">
              <div className={`app-header`}>
                <Header left={true} />
              </div>
              <main
                className="app-main-content-wrapper"
                // style={{ padding: "0px" }}
              >
                <div className="app-main-content">
                  <Switch>
                    <Route path={`${match.url}/confirm`} component={Confirm} />
                    <Route path={`${match.url}/blog`} component={Blog} />
                    <Route
                      path={`${match.url}/online-scheduler`}
                      component={OnlineScheduler}
                    />
                    <Route
                      path={`${match.url}/comunication/telemedicine`}
                      component={Telemedicine}
                    />
                    <Route
                      path={`${match.url}/prescription-signed`}
                      component={PrescriptionSigned}
                    />
                    <Route
                      path={`${match.url}/exam-signed`}
                      component={ViewExamSigned}
                    />
                  </Switch>
                </div>
              </main>
            </div>
          </div>
        )}
        <Configuration />
        <Alert
          open={isEdg}
          onClose={() => wrongBrowser(false)}
          severity="warning"
        >
          Este navegador não é compatível com todas as funções, recomendamos que
          use o{" "}
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="https://www.google.com/chrome/"
            target="_blank"
          >
            Google Chrome
          </a>
          ,{" "}
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="https://support.apple.com/pt_BR/downloads/safari"
            target="_blank"
          >
            Safari
          </a>{" "}
          ou{" "}
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="https://www.mozilla.org/pt-BR/firefox/new/"
            target="_blank"
          >
            Firefox
          </a>
        </Alert>
        <SweetAlert
          show={this.state.modalCareNotification}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Ir para evolução"
          confirmButtonColor="#bc0003"
          cancelBtnText="Continuar no Dashboard"
          title="Cuidados com marcação em atraso:"
          onConfirm={() => {
            this.setState({ modalCareNotification: false });
            history.push("/app/evolution/hour-evolution");
          }}
          onCancel={() => {
            this.setState({ modalCareNotification: false });
          }}
        >
          <ul>
            {this.state.caresNotification.map((row) => (
              <li style={{ listStyle: "none" }}>
                {row.care} <strong>({row.count})</strong>
              </li>
            ))}
          </ul>
        </SweetAlert>
      </>
    );
  }
}

const mapStateToProps = ({ settings, auth, evolutionDaily, alert }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { address, authUser, area } = auth;
  const { intervals } = evolutionDaily;
  const { wrongBrowser } = alert;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    address,
    authUser,
    area,
    intervals,
    isEdg: wrongBrowser,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setManagimentPermission, setDataEvolution, wrongBrowser },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
