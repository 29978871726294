import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  MenuItem,
  Grid,
  Switch,
} from "@material-ui/core";
import { CirclePicker } from "react-color";
import { MultipleSelect } from "react-select-material-ui";
import SearchDoctor from "../SearchDoctor";
import CircularProgress from "components/CircularProgress";
import MaskCpfCnpj from "components/Masks/MaskCpfCnpj";
import TypesEventForm from "components/Configuration/components/eventType/TypesEventForm";

function FormDoctors({
  user,
  data,
  addressId,
  addressType,
  providerType,
  close,
  add,
  edit,
}) {
  const [specialty, setSpecialty] = useState(""); // id da especialidade do endereco
  const [idSpecialty, setIdSpecialty] = useState(""); // id da especialidade do medico
  const [specialtySelected, setSpecialtySelected] = useState({}); // id da especialidade do medico
  const [specialtiesAddress, setSpecialtiesAddress] = useState([]); // especialidades do medico
  const [roomsAddress, setRoomsAddress] = useState([]); // Salas de atendimento do endereço
  const [doctor, setDoctor] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [type_online_scheduler, setType_online_scheduler] = useState("0");
  const [time_to_confirme, set_time_to_confirme] = useState("");
  const [online_first_attendance, set_online_first_attendance] = useState("");
  const [online_attendance, set_online_attendance] = useState("");
  const [online_return, set_online_return] = useState("");
  const [calendar_id, setCalendarId] = useState("");
  const objHour = {
    id: "",
    room: "",
    specialty: "",
    start: "--:--",
    end: "--:--",
    day: "",
  };
  const [hours, setHours] = useState([objHour]);
  const [hoursDelete, setHoursDelete] = useState([]);
  const [modalNewEvent, setModalNewEvent] = useState(false);
  const [typesEvent, setTypesEvent] = useState([]);
  const [typesSelected, setTypesSelected] = useState([]);
  const [colorEvent, setColorEvent] = useState("#177493");
  const [textColor, setTextColor] = useState("#ffffff");
  const [online_scheduling, setOnlineScheduling] = useState(false);
  const [number_patients, setNumberPatients] = useState(1);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    payments: [],
  });

  useEffect(() => {
    Api.post(`/ms_system/workplace/getDataAddressProvider/`, {
      addressId,
    }).then((res) => {
      setSpecialtiesAddress(res.data.specialties);
      setRoomsAddress(res.data.rooms);
    });

    if (data.view !== "hour") {
      getTypesEvents();
    }

    if (data.id) {
      Api.post(`/ms_system/workplace/getInfoProfessionalAddress/`, {
        id: data.id,
      }).then((res) => {
        setHours(res.data.hours);
        setSpecialty(res.data.specialty);
        setColorEvent(res.data.color_event);
        setTextColor(res.data.color_text);
        setNumberPatients(res.data.number_patient);
        setOnlineScheduling(res.data.online_scheduling);
        let arr_selecteds = res.data.types_events.map((row) => row.id);
        setTypesSelected(arr_selecteds);
        setDoctor(data.doctor);
        setDoctorName(data.doctorName);
        setType_online_scheduler(res.data.type_online_scheduler);
        set_time_to_confirme(res.data.time_to_confirme);
        set_online_first_attendance(res.data.online_first_attendance);
        set_online_attendance(res.data.online_attendance);
        set_online_return(res.data.online_return);
        setCalendarId(res.data.calendar_id);
        setState({
          telemedicine: res.data.telemedicine === "1",
          payments: res.data.forms_payment
            ? res.data.forms_payment.split(";")
            : [],
          cpf: res.data.ted_cpf_cnpj,
          beneficiary: res.data.ted_beneficiary,
          bank: res.data.ted_bank,
          agency: res.data.ted_agency,
          account: res.data.ted_account,
          pix: res.data.pix_type,
          pix_key: res.data.pix_key,
        });
        setLoaded(true);
      });
    } else {
      setState({
        ...state,
        telemedicine: addressType === "0",
      });
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (specialty && specialtiesAddress.length > 0) {
      let info = specialtiesAddress.find((row) => row.id === specialty);
      setIdSpecialty(info.specialty_id);
      setSpecialtySelected(info);
    }
  }, [specialty, specialtiesAddress]);

  useEffect(() => {
    if (typesEvent.length > 5 && typesSelected.length === typesEvent.length) {
      setTypesSelected(["all"]);
    }
  }, [typesEvent, typesSelected]);

  const getTypesEvents = () => {
    Api.post(`/ms_system/workplace/getTypesEventsUser/`, { user }).then(
      (res) => {
        if (res.data.record.length > 0) {
          setTypesEvent(res.data.record);
        }
      }
    );
  };

  function validate() {
    if (specialty && doctor && typesSelected.length > 0) {
      setError(false);
      setLoaded(false);
      return true;
    } else {
      setError(true);
      return false;
    }
  }

  function save() {
    if (validate()) {
      let info = specialtiesAddress.find((row) => row.id === specialty);
      let typesEv = typesSelected;
      if (typesSelected.indexOf("all") !== -1) {
        typesEv = typesEvent.map((row) => row.value);
      }
      Api.post(`/ms_system/workplace/saveDoctorAdress/`, {
        id: data.id,
        calendar_id,
        addressId,
        specialty,
        specialty_name: info.specialty,
        doctor,
        doctorName,
        hours,
        hoursDelete,
        typesSelected: typesEv,
        colorEvent,
        textColor,
        online_scheduling,
        number_patients,
        type_online_scheduler,
        time_to_confirme,
        online_first_attendance,
        online_attendance,
        online_return,
        ...state,
      })
        .then((res) => {
          setLoaded(true);
          let info_hours = hours.map((row) => {
            let info_room = roomsAddress.find((val) => val.id === row.room);
            return {
              ...row,
              specialty: info.specialty,
              room: info_room.name,
            };
          });

          const payload = {
            id: res.data.id,
            name: doctorName,
            fk_ls_user: doctor,
            hours: info_hours,
          };
          if (!data.id) {
            add(payload);
          } else {
            edit(payload);
          }
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
          close();
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage(), 5000));
        });
    }
  }

  function handleChangeHour(index, field, value) {
    let aux = hours.map((row, key) =>
      key === index ? { ...row, [field]: value } : row
    );
    setHours(aux);
  }

  const handleDeleteHour = (id, index) => {
    if (id) {
      let obj = hours.find((row) => row.id === id);
      setHoursDelete([...hoursDelete, obj]);
    }
    let arr = hours.filter((obj, key) => key !== index);
    setHours(arr);
  };

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="text-primary font-weight-semibold">
            {data.view !== "hour" ? "Profissionais" : "Horários de atendimento"}
            <Tooltip title="Fechar" placement="top">
              <IconButton
                aria-label="Fechar"
                onClick={() => close()}
                style={{ float: "right", marginTop: "-10px" }}
              >
                <i className="zmdi zmdi-close" />
              </IconButton>
            </Tooltip>
          </h2>
        </div>
        {data.view !== "hour" && (
          <>
            <div className="col-12">
              <TextField
                id="specialty"
                select
                disabled={data.id ? true : false}
                label="Especialidade:"
                value={specialty}
                margin="normal"
                fullWidth
                onChange={(e) => setSpecialty(e.target.value)}
                error={error && !specialty ? true : false}
              >
                {specialtiesAddress.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {`${row.area} - ${row.specialty}`}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-12">
              <SearchDoctor
                doctor={{ id: doctor, name: doctorName }}
                specialty={idSpecialty}
                specialtySelected={specialtySelected}
                handleChangeAutocomplete={(id, value) => {
                  setDoctor(id);
                  setDoctorName(value);
                }}
                edit={data.id ? true : false}
                error={error && !doctor ? true : false}
              />
            </div>
            <div className="col-12 mt-3">
              <h4 className="font-weight-semibold">Horários de Atendimento</h4>
              <hr />
            </div>
          </>
        )}

        {hours.map((row, index) => (
          <>
            <div className="col-md-3 col-xs-4">
              <TextField
                id="day_week"
                select
                label="Dia da Semana:"
                value={row.day}
                onChange={(e) => handleChangeHour(index, "day", e.target.value)}
                SelectProps={{}}
                fullWidth
                style={{ marginTop: "15px" }}
                error={error && !row.day ? true : false}
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem key="1" value="0">
                  Domingo
                </MenuItem>
                <MenuItem key="2" value="1">
                  Segunda-Feira
                </MenuItem>
                <MenuItem key="3" value="2">
                  Terça-Feira
                </MenuItem>
                <MenuItem key="3" value="3">
                  Quarta-Feira
                </MenuItem>
                <MenuItem key="3" value="4">
                  Quinta-Feira
                </MenuItem>
                <MenuItem key="3" value="5">
                  Sexta-Feira
                </MenuItem>
                <MenuItem key="3" value="6">
                  Sábado
                </MenuItem>
                <MenuItem key="3" value="7">
                  Segunda à Sexta
                </MenuItem>
                <MenuItem key="3" value="8">
                  Todos os dias
                </MenuItem>
              </TextField>
            </div>
            <div className="col-md-2 col-xs-4">
              <TextField
                id="start"
                type="time"
                label="Início:"
                value={row.start}
                margin="normal"
                fullWidth
                onChange={(e) =>
                  handleChangeHour(index, "start", e.target.value)
                }
                error={error && row.start === "--:--" ? true : false}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-md-2 col-xs-4">
              <TextField
                id="start"
                type="time"
                label="Fim:"
                value={row.end}
                margin="normal"
                fullWidth
                onChange={(e) => handleChangeHour(index, "end", e.target.value)}
                error={error && row.end === "--:--" ? true : false}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col-md-3 col-xs-8">
              <TextField
                id="room"
                select
                label="Sala de atendimento:"
                value={row.room}
                margin="normal"
                fullWidth
                onChange={(e) =>
                  handleChangeHour(index, "room", e.target.value)
                }
                error={error && !row.room ? true : false}
                InputLabelProps={{ shrink: true }}
              >
                {roomsAddress.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-2 col-xs-4">
              {index + 1 === hours.length && (
                <IconButton
                  onClick={() => setHours([...hours, objHour])}
                  color="primary"
                  aria-label="Adicionar"
                  style={{ marginTop: "25px" }}
                >
                  <i className="zmdi zmdi-plus zmdi-hc-fw" />
                </IconButton>
              )}
              {hours.length > 1 && (
                <IconButton
                  onClick={() => handleDeleteHour(row.id, index)}
                  color="secondary"
                  aria-label="Remover"
                  style={{ marginTop: "25px" }}
                >
                  <i className="zmdi zmdi-delete zmdi-hc-fw" />
                </IconButton>
              )}
            </div>
          </>
        ))}

        {data.view !== "hour" && (
          <>
            <div className="col-12">
              <h4 className="font-weight-semibold mt-3">
                Especificações da agenda
              </h4>
              <hr />
            </div>

            <div className="col-md-12 col-12">
              <MultipleSelect
                label="Serviços:"
                values={typesSelected}
                options={
                  typesEvent.length > 5
                    ? [
                        { value: "all", label: "Todos os serviços" },
                        ...typesEvent,
                        { value: "new", label: "+ Novo Tipo de Evento" },
                      ]
                    : [
                        ...typesEvent,
                        { value: "new", label: "+ Novo Tipo de Evento" },
                      ]
                }
                onChange={(e, c, d) => {
                  if (e.indexOf("new") === -1) {
                    setTypesSelected(e);
                  } else {
                    setModalNewEvent(true);
                  }
                }}
                SelectProps={{
                  isCreatable: false,
                  msgNoOptionsAvailable: "Todos serviços foram selecionados",
                  msgNoOptionsMatchFilter:
                    "Nenhum tipo de evento para este filtro",
                }}
                style={{
                  borderBottom:
                    error && typesSelected.length === 0
                      ? "2px solid red"
                      : "none",
                }}
                // error={error && typesSelected.length === 0 ? true : false}
              />
            </div>

            <div className="col-md-4 col-12 mt-3">
              <TextField
                id="number_patients"
                type="number"
                label="Número de pacientes por horário:"
                value={number_patients}
                onChange={(e) => setNumberPatients(e.target.value)}
                fullWidth
              />
            </div>

            <div className="col-12 mt-3 mb-3">
              <span
                style={{
                  color: "#0000008a",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "1.3125rem",
                  marginBotton: "5px",
                }}
              >
                Selecione a cor dos eventos:
              </span>
              <CirclePicker
                width="100%"
                color={colorEvent}
                onChange={(val) => {
                  setColorEvent(val.hex);
                }}
                colors={[
                  "#177493",
                  "#f44336",
                  "#e91e63",
                  "#9c27b0",
                  "#673ab7",
                  "#3f51b5",
                  "#2196f3",
                  "#03a9f4",
                  "#00bcd4",
                  "#009688",
                  "#4caf50",
                  "#8bc34a",
                  "#cddc39",
                  "#ffeb3b",
                  "#ffc107",
                  "#ff9800",
                  "#ff5722",
                  "#795548",
                  "#607d8b",
                ]}
              />
            </div>

            {/* <div className="col-md-4 col-12 mt-3">
              <Grid
                component="label"
                container
                alignItems="center"
                style={{ marginTop: "15px" }}
              >
                <Grid item>
                  <span
                    style={{
                      color: "#0000008a",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "1.3125rem",
                    }}
                  >
                    Cor do texto dos eventos:
                  </span>
                </Grid>
                <Grid item>
                  <div
                    className="cp-swatch ml-3"
                    onClick={() => setDisplayTextEvent(!displayTextEvent)}
                  >
                    <div
                      className="cp-color"
                      style={{ backgroundColor: textColor, width: "70px" }}
                    />
                  </div>
                  {displayTextEvent ? (
                    <div className="cp-popover">
                      <div
                        className="cp-cover"
                        onClick={() => setDisplayTextEvent(!displayTextEvent)}
                      />
                      <SketchPicker
                        color={textColor}
                        onChange={(val) => setTextColor(val.hex)}
                      />
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </div> */}

            <div className="col-md-6 col-12 mt-3">
              <Grid component="label" container alignItems="center">
                <Grid item>
                  <span
                    style={{
                      color: "#0000008a",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "1.3125rem",
                    }}
                  >
                    Teleconsulta:
                  </span>
                </Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={state.telemedicine}
                    onChange={(e) => {
                      setState({ ...state, telemedicine: e.target.checked });
                    }}
                    aria-label="checked"
                  />
                </Grid>
              </Grid>
            </div>

            {providerType !== "56" && (
              <>
                <div className="col-md-6 col-12">
                  <MultipleSelect
                    label="Formas de pagamento:"
                    values={state.payments}
                    options={[
                      "Boleto",
                      "Cartão de crédito",
                      "Transferência bancária",
                      "PIX",
                    ]}
                    onChange={(e) => {
                      setState({ ...state, payments: e });
                    }}
                    SelectProps={{
                      isCreatable: false,
                      msgNoOptionsAvailable:
                        "Todos as opções foram selecionados",
                      msgNoOptionsMatchFilter:
                        "Nenhum resultado para este filtro",
                    }}
                  />
                </div>

                {state.payments.indexOf("Transferência bancária") !== -1 && (
                  <>
                    <div className="col-12 my-3">
                      <span
                        style={{
                          color: "#0000008a",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "1.3125rem",
                        }}
                      >
                        Dados para Transferência Bancária:
                      </span>
                    </div>
                    <div className="col-md-6 col-12 mb-2">
                      <TextField
                        id="cpf"
                        label="CPF / CNPJ"
                        fullWidth
                        InputProps={{
                          inputComponent: MaskCpfCnpj,
                          value: state.cpf ? state.cpf : "",
                          onChange: (event) => {
                            setState({ ...state, cpf: event.target.value });
                          },
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="col-md-6 col-12 mb-2">
                      <TextField
                        fullWidth
                        label="Beneficiário:"
                        value={state.beneficiary}
                        onChange={(e) =>
                          setState({
                            ...state,
                            beneficiary: e.target.value,
                          })
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="col-md-4 col-12">
                      <TextField
                        fullWidth
                        label="Banco:"
                        value={state.bank}
                        onChange={(e) =>
                          setState({
                            ...state,
                            bank: e.target.value,
                          })
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="col-md-4 col-12">
                      <TextField
                        fullWidth
                        label="Agência:"
                        value={state.agency}
                        onChange={(e) =>
                          setState({
                            ...state,
                            agency: e.target.value,
                          })
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="col-md-4 col-12">
                      <TextField
                        fullWidth
                        label="Conta:"
                        value={state.account}
                        onChange={(e) =>
                          setState({
                            ...state,
                            account: e.target.value,
                          })
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </>
                )}
                {state.payments.indexOf("PIX") !== -1 && (
                  <>
                    <div className="col-12 my-3">
                      <span
                        style={{
                          color: "#0000008a",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "1.3125rem",
                        }}
                      >
                        Dados para Pix:
                      </span>
                    </div>
                    <div className="col-md-6 col-12 mb-2">
                      <TextField
                        select
                        label="Tipo da chave Pix:"
                        fullWidth
                        onChange={(event) => {
                          setState({ ...state, pix: event.target.value });
                        }}
                        value={state.pix}
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem key="0" value="CPF ou CNPJ">
                          CPF ou CNPJ
                        </MenuItem>
                        <MenuItem key="1" value="E-mail">
                          E-mail
                        </MenuItem>
                        <MenuItem key="2" value="Número de telefone celular">
                          Número de telefone celular
                        </MenuItem>
                        <MenuItem key="3" value="Chave aleatória">
                          Chave aleatória
                        </MenuItem>
                      </TextField>
                    </div>
                    <div className="col-md-6 col-12 mb-2">
                      {state.pix === "CPF ou CNPJ" ? (
                        <TextField
                          label="Chave Pix:"
                          fullWidth
                          onChange={(event) => {
                            setState({ ...state, pix_key: event.target.value });
                          }}
                          InputProps={{
                            value: state.pix_key ? state.pix_key : "",
                            inputComponent: MaskCpfCnpj,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          label="Chave Pix:"
                          value={state.pix_key}
                          onChange={(e) =>
                            setState({
                              ...state,
                              pix_key: e.target.value,
                            })
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    </div>
                  </>
                )}

                <div className="col-md-12 col-12 mt-3">
                  <Grid component="label" container alignItems="center">
                    <Grid item>
                      <span
                        style={{
                          color: "#0000008a",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "1.3125rem",
                        }}
                      >
                        Agendamento online:
                      </span>
                    </Grid>
                    <Grid item>
                      <Switch
                        color="primary"
                        checked={online_scheduling}
                        onChange={(e) => setOnlineScheduling(e.target.checked)}
                        aria-label="checked"
                      />
                    </Grid>
                  </Grid>
                </div>
              </>
            )}

            {/* <div className="d-flex">
                  <div className="col-md-6 col-12 mt-3">
                    <p style={{ color: "#0000008a" }}>
                      Tipo do agendamento online:
                    </p>
                    <RadioGroup
                      aria-label="type_online"
                      name="type_online"
                      value={type_online_scheduler ? type_online_scheduler : ""}
                      onChange={(e) => setType_online_scheduler(e.target.value)}
                      style={{ display: "inline" }}
                    >
                      <Tooltip title="paciente agenda consulta diretamente">
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label="Confirmação"
                        />
                      </Tooltip>
                      <Tooltip title="paciente solicita agendamento">
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Solicitação"
                        />
                      </Tooltip>
                    </RadioGroup>
                  </div>
                  <div className="col-md-6 col-12 mt-5">
                    <TextField
                      className="mb-3"
                      label="Prazo de confirmação do paciente"
                      type="time"
                      value={time_to_confirme}
                      onChange={(e) => set_time_to_confirme(e.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div> */}
            {online_scheduling && (
              <>
                <div className="col-md-4 col-12 mt-3">
                  <TextField
                    select
                    fullWidth
                    label="Primeira consulta:"
                    value={online_first_attendance}
                    onChange={(e) =>
                      set_online_first_attendance(e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                  >
                    {typesEvent.map((row, key) => (
                      <MenuItem key={key} value={row.value}>
                        {row.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-md-4 col-12 mt-3">
                  <TextField
                    select
                    fullWidth
                    label="Consulta:"
                    value={online_attendance}
                    onChange={(e) => set_online_attendance(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  >
                    {typesEvent.map((row, key) => (
                      <MenuItem key={key} value={row.value}>
                        {row.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-md-4 col-12 mt-3">
                  <TextField
                    select
                    fullWidth
                    label="Retorno:"
                    value={online_return}
                    onChange={(e) => set_online_return(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  >
                    {typesEvent.map((row, key) => (
                      <MenuItem key={key} value={row.value}>
                        {row.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            )}
          </>
        )}
        <div className="col-md-12 mt-3">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={() => close()}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm bg-success"
            onClick={() => save()}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>
        </div>
        <TypesEventForm
          open={modalNewEvent}
          type="modal"
          close={() => setModalNewEvent(false)}
          beforeSave={(newValue) => {
            getTypesEvents();
            setTypesSelected([...typesSelected, newValue]);
          }}
        />
      </div>
    );
  }
}

export default FormDoctors;
