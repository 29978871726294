import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "services/api";
import CalendarPatient from "../../components/calendarPatient";
// import Medicines from "../../components/medicines";
// import PatientQuestion from "../../components/patientQuestion";
import Feed from "../../components/feed";
import PatientInfo from "../../components/patientInfo";
import PatientGraphic from "../../components/patientGraphic";
import PatientQuizes from "../../components/patientQuizes";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import EqualizerIcon from "@material-ui/icons/Equalizer";
// import ColorizeIcon from "@material-ui/icons/Colorize";
import HomeIcon from "@material-ui/icons/Home";
import EventNoteIcon from "@material-ui/icons/EventNote";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Route, Switch, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function DashFeedMobile({ match, history }) {
  // const [record, setRecord] = useState({
  //   medicines: [],
  //   events: [],
  //   pauses: [],
  //   activities: [],
  //   info: {},
  // });
  // const [loader, setLoader] = useState(true);
  // const user = useSelector((state) => state.auth.authUser);
  // const width = useSelector((state) => state.settings.width);
  const dispatch = useDispatch();

  const [value, setValue] = useState("feed");
  const width = useSelector((state) => state.settings.width);
  const route = useSelector((state) => state.blog.route);

  useEffect(() => {
    if (history.location.search) {
      history.push(`/app/dashboard/feed/${history.location.search}`);
    } else {
      history.push(`/app/dashboard/feed`);
    }

    dispatch({
      type: "SET_ROUTE",
      payload: "feed",
    });
  }, []);

  // const handleGetFullPost = (id) => {
  //   if (id) {
  //     window.history.pushState(null, document.title, window.location.href);
  //     window.addEventListener("popstate", function(event) {
  //       back();
  //     });
  //     Api.post(`/ms_blog/post/getFullPost`, {
  //       id: id,
  //     }).then((res) => {
  //       setState({
  //         post: res.data,
  //         id_category: res.data.id_category,
  //         text_content: stripHtml(res.data.content),
  //       });
  //     });
  //   }
  // };

  // useEffect(() => {
  //   setValue(`${route}`);
  // }, [route]);

  const handleChange = (event, newValue) => {
    history.push(`/app/dashboard/${newValue}`);
    // setValue(`${newValue}`);
  };

  const handldeSetComp = () => {};

  /*  useEffect(() => {
    Api.post("/ms_system/dashboard/getDataDashPatient", { user })
      .then((res) => {
        setRecord({
          medicines: res.data.medicines,
          events: res.data.events,
          pauses: res.data.pauses,
          activities: res.data.activities,
          info: res.data.info,
        });
      })
      .finally(() => setLoader(false));
  }, []); */

  return (
    <div className="row">
      <Switch>
        <Route path={`${match.url}/feed`} component={Feed} />
        <Route path={`${match.url}/patient-quizes`} component={PatientQuizes} />
        <Route
          path={`${match.url}/patient-graphic`}
          component={PatientGraphic}
        />
        <Route
          path={`${match.url}/calendar-patient`}
          component={CalendarPatient}
        />
        <Route path={`${match.url}/patient-info`} component={PatientInfo} />
      </Switch>

      {width < 960 && (
        <div
          style={{
            bottom: 0,
            width: "100%",
            padding: "11px",
            position: "fixed",
            background: "#fff",
            alignContent: "flex-end",
            marginLeft: "5px",
            zIndex: "1",
          }}
        >
          <BottomNavigation value={route} onChange={handleChange}>
            <BottomNavigationAction value="feed" icon={<HomeIcon />} />
            {/* <BottomNavigationAction value="1" icon={<ColorizeIcon />} /> */}
            <BottomNavigationAction
              value="patient-quizes"
              icon={<FilterNoneIcon />}
              style={{
                maxWidth: "140px",
                minWidth: "70px",
              }}
            />
            <BottomNavigationAction
              value="patient-graphic"
              icon={<EqualizerIcon />}
              style={{
                maxWidth: "140px",
                minWidth: "70px",
              }}
            />
            <BottomNavigationAction
              value="calendar-patient"
              icon={<EventNoteIcon />}
              style={{
                maxWidth: "140px",
                minWidth: "70px",
              }}
            />
            <BottomNavigationAction
              value="patient-info"
              icon={<AccountCircleIcon />}
              style={{
                maxWidth: "140px",
                minWidth: "70px",
              }}
            />
          </BottomNavigation>
        </div>
      )}
    </div>
  );
}
