import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editHealthPlan, addHealthPlan } from "actions/consult/PersonalData";
import * as alertActions from "actions/Alerts";
import Api from "services/api";
import TextField from "@material-ui/core/TextField";
import MaskDate from "components/Masks/MaskDate";
import Button from "@material-ui/core/Button";
import AutocompleteHealthPlan from "../autocomplete/HealthPlan";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

class FormPlan extends Component {
  state = {
    id: "",
    plan_id: "",
    plan_name: "",
    register: "",
    identfy: "",
    validate: "",
    new_plan: false,
    false: true
  };

  componentDidMount() {
    if (this.props.plan.id) {
      const {
        id,
        plan_id,
        plan_name,
        register,
        identfy,
        validate
      } = this.props.plan;
      this.setState({
        id: id,
        plan_id,
        plan_name,
        register,
        identfy: identfy ? (identfy === "Médico" ? "1" : "2") : "",
        validate
      });
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChangeAutocomplete = () => event => {
    event.identfy !== "new"
      ? this.setState({
          plan_id: event.plan_id,
          plan_name: event.plan_name,
          identfy: event.identfy
        })
      : this.setState({
          plan_id: event.plan_id,
          plan_name: event.plan_name,
          new_plan: true
        });
  };

  validate = () => {
    const { plan_name, register, identfy, validate } = this.state;
    if (plan_name && register && identfy && validate) {
      this.setState({ error: false });
      return true;
    } else {
      this.setState({ error: true });
      return false;
    }
  };

  save() {
    if (this.validate()) {
      const { patient, doctor } = this.props;
      let formData = {
        patient,
        doctor,
        data: this.state
      };

      Api.post(`/microservice/consult/saveHealthPlan/`, formData)
        .then(res => {
          let data = this.state;
          data.identfy = data.identfy === "1" ? "Médico" : "Odontológico";
          if (data.id > 0) {
            this.props.editHealthPlan(data);
          } else {
            data.id = res.data.record.id;
            this.props.addHealthPlan(data);
          }
          this.props.viewAlertMessage("success", "Salvo com sucesso!");
          setTimeout(() => this.props.closeAlertMessage(), 5000);
          this.props.handleClose();
        })
        .catch(() => {
          this.props.viewAlertMessage("error", "Ocorreu um erro ao salvar!");
          setTimeout(() => this.props.closeAlertMessage(), 5000);
        });
    }
  }

  render() {
    const {
      id,
      plan_id,
      plan_name,
      register,
      identfy,
      new_plan,
      validate,
      error
    } = this.state;

    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="text-primary font-weight-semibold">
            {id > 0 ? "Editar plano de saúde" : "Cadastrar plano de saúde"}
          </h2>
        </div>
        <div className="col-md-12 col-12">
          <AutocompleteHealthPlan
            healthPlan={{
              id: plan_id,
              name: plan_name
            }}
            handleChangeAutocomplete={this.handleChangeAutocomplete().bind(
              this
            )}
            error={error && !plan_name ? true : false}
          />
        </div>
        <div className="col-md-12 col-12">
          {new_plan && (
            <FormControl>
              <FormLabel component="legend">Tipo:</FormLabel>
              <RadioGroup
                className="d-flex flex-row"
                aria-label="identfy"
                name="identfy"
                value={identfy}
                onChange={this.handleChange("identfy")}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label="Médico"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  label="Odontológico"
                />
              </RadioGroup>
            </FormControl>
          )}
        </div>
        <div className="col-md-12 col-12">
          <TextField
            required
            id="name"
            label="Registro/Carteirinha"
            value={register}
            margin="normal"
            fullWidth
            onChange={this.handleChange("register")}
            error={error && !register ? true : false}
          />
        </div>
        <div className="col-md-12 col-12">
          <TextField
            required
            id="name"
            label="Validade"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskDate,
              value: validate,
              onChange: this.handleChange("validate")
            }}
            error={error && !validate ? true : false}
          />
        </div>
        <div className="col-md-12 mt-20">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={() => this.props.handleClose()}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>

          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm bg-success"
            onClick={() => this.save()}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    doctor: state.auth.authUser
    // patient: state.appointment.patient
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { editHealthPlan, addHealthPlan, ...alertActions },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormPlan);
