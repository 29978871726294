import { delay } from "redux-saga";
import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import api from "services/api";

const listVaccines = async patient => {
  const response = await api.post(
    `/microservice/vaccines/getVaccinesList/`,
    {}
  );
  const data = await response.data.record;
  const aux = [];
  data.forEach(element => {
    element = {
      ...element,
      check: false
    };
    aux.push(element);
  });
  return aux;
};

const cadNewVaccine = async (patient, vcSelect, date, isApplied, idEdit) => {
  const response = await api.post(`/microservice/vaccines/cadVaccine/`, {
    patient,
    vcSelect,
    date,
    isApplied,
    idEdit
  });

  const newListuserVaccines = await response.data.newlist;
  return newListuserVaccines;
};

const delUserVaccine = async (id, patient) => {
  const response = await api.post(`/microservice/vaccines/delUserVaccine/`, {
    id,
    patient
  });

  // const data = await response.data.success;
  const newListuserVaccines = await response.data.newlist;
  return newListuserVaccines;
};

const GetListUserVaccines = async patient => {
  const response = await api.post(
    `/microservice/vaccines/getUserVaccinesList/`,
    { patient }
  );
  const data = await response.data.record;
  const aux = [];
  data.forEach(element => {
    element = {
      ...element,
      check: false
    };
    aux.push(element);
  });
  return aux;
};

const GetVaccineForEdit = async id => {
  const response = await api.post(`/microservice/vaccines/getVaccineForEdit/`, {
    id
  });
  const data = await response.data.record;
  const aux = [];
  data.forEach(element => {
    element = {
      ...element,
      check: false
    };
    aux.push(element);
  });
  return aux;
};

function* asyncGetVaccinesList(action) {
  try {
    const listAllVaccines = yield call(listVaccines);
    yield put({
      type: "GET_LIST_VACCINES_SUCCESS",
      payload: { listAllVaccines: listAllVaccines }
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncCadVaccine(action) {
  try {
    const listUserVaccines = yield call(
      cadNewVaccine,
      action.payload.patient,
      action.payload.vcSelect,
      action.payload.date,
      action.payload.isApplied,
      action.payload.idEdit
    );

    yield put({
      type: "REQUEST_CAD_VACCINE_SUCCESS",
      payload: { listUserVaccines: listUserVaccines }
    });
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "success",
      text_message: "Salvo com sucesso!"
    });
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncGetUserVaccines(action) {
  try {
    const listUserVaccines = yield call(
      GetListUserVaccines,
      action.payload.patient
    );
    yield put({
      type: "GET_LIST_USER_VACCINES_SUCCESS",
      payload: { listUserVaccines: listUserVaccines }
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncDelUserVaccine(action) {
  try {
    const listUserVaccines = yield call(
      delUserVaccine,
      action.payload.id,
      action.payload.patient
    );
    yield put({
      type: "DEL_USER_VACCINE_SUCCESS",
      payload: { listUserVaccines: listUserVaccines }
    });
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "success",
      text_message: "Salvo com sucesso!"
    });
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncGetVaccineForEdit(action) {
  try {
    const infoVaccine = yield call(GetVaccineForEdit, action.payload.id);
    yield put({
      type: "GET_VACCINE_FOR_EDIT_SUCCESS",
      payload: { infoVaccine: infoVaccine }
    });
  } catch (error) {
    console.log(error);
  }
}

export function* requestVaccinesList() {
  yield takeEvery("GET_VACCINES_LIST", asyncGetVaccinesList);
}

export function* requestCadVaccine() {
  yield takeEvery("CAD_VACCINE", asyncCadVaccine);
}

export function* requestGetUserVaccines() {
  yield takeEvery("GET_USER_VACCINES", asyncGetUserVaccines);
}

export function* requestDelUserVaccine() {
  yield takeEvery("DEL_USER_VACCINE", asyncDelUserVaccine);
}

export function* requestGetVaccineForEdit() {
  yield takeEvery("GET_VACCINE_FOR_EDIT", asyncGetVaccineForEdit);
}

export default function* rootSaga() {
  yield all([
    fork(requestVaccinesList),
    fork(requestCadVaccine),
    fork(requestGetUserVaccines),
    fork(requestDelUserVaccine),
    fork(requestGetVaccineForEdit)
  ]);
}
