import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ModalResponsive } from "components/ModalResponsive";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import { selectedUserChat, setSelectedChat } from "actions/comunication/Chat";
// import { BtnSave } from "components/Button";
// import { BtnCan } from "components/Button";
import api from "services/api";
import ContactList from "components/chatPanel/ContactList/index";
import { getUrlPhoto } from "services/functions";

export default function ModalAttachments({ open, handleClose }) {
  const dispatch = useDispatch();
  const [contactList, setContactList] = useState([]);
  const userLoged = useSelector(({ auth }) => auth);
  const selectedChat = useSelector(({ chat }) => chat.selectedChat);
  const [chatIds, setChatIds] = useState([]);
  const [state, setState] = useState({
    userNotFound: "Contato não encontrado",
    selectedSectionId: "",
    selectedTabIndex: 0,
    searchChatUser: "",
  });

  useEffect(() => {
    api
      .post(`/ms_comunication/chat/getContactList/`, {
        user: userLoged.authUser,
      })
      .then(({ data }) => {
        setContactList(
          data.map((row) => ({ ...row, thumb: getUrlPhoto(row.id, row.thumb) }))
        );
      });
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/`)
      .once("value", (snap) => {
        setChatIds(Object.keys(snap.val() ? snap.val() : []));
      });
  }, []);
  getDataFirebase().on("child_changed", (snap) => {
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/`)
      .once("value", (snap) => {
        setChatIds(Object.keys(snap.val() ? snap.val() : []));
      });
  });

  const onSelectUser = (user) => {
    getDataFirebase()
      .child(`chats${getEnviromentFirebase()}/` + selectedChat)
      .off();
    setState({ ...state, selectedSectionId: user.id });
    dispatch(setSelectedChat(user.chat_id));
    dispatch(selectedUserChat(user));
    handleClose();
  };

  return (
    <ModalResponsive
      title={"Contatos"}
      paddingContent={true}
      open={open}
      close={handleClose}
      maxWidth={"sm"}
      buttons={
        <div className="col-12">
          {/* <BtnCan title={"fechar"} onClick={handleClose} float="left" />
          {/* <BtnSave
            title={"Salvar Edição"}
            onClick={handleSaveEditBudget}
            float="right"
          />  */}
        </div>
      }
    >
      {contactList.length > 0 && (
        <ContactList
          contactList={contactList}
          selectedSectionId={state.selectedSectionId}
          onSelectUser={onSelectUser}
        />
      )}
    </ModalResponsive>
  );
}
