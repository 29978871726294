import React, { useState } from "react";
import Api from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ExamsPatient from "./ExamsPatient";
import MedicinesPatient from "./MedicinesPatient";
import ProceduresPatient from "./ProceduresPatient";
import AttendancesPatient from "./AttendancesPatient";
import CircularProgress from "components/CircularProgress";
import { getUrlPhoto } from "services/functions";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import HistoryPatient from "components/HistoryPatient";
import { viewHistory } from "actions/consult/Appointment";
import { getInfoPatient } from "actions/consult/PatientInfo";
import HistoryIcon from "@material-ui/icons/History";

//tabs
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PersonIcon from "@material-ui/icons/Person";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CompomentsDataDash from "./CompomentsDataDash";
import ProfileNew from "components/ComponentsProfile/ProfileNew";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  timelineItem: {
    "&:last-child:before": {
      bottom: "-20px",
      borderLeftStyle: "solid",
    },
  },
  userName: {
    marginBottom: "2px",
    fontWeight: "500",
  },
  userDescription: {
    fontSize: "13px",
    marginBottom: "0",
    color: "grey",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function ViewAddress({ patient, alterPatient }) {
  const data_dash = useSelector((state) => state.dashboard.data_dash);

  const [record, setRecord] = useState({
    exams: [],
    patients: [],
    attendances: [],
    medicines: [],
    procedures: [],
  });
  const [loader, setLoader] = useState(true);
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (patient.id) {
      setLoader(true);
      Api.post(`/ms_system/dashboard/getDataDashPatientPrefecture`, {
        patient: patient.id,
        start: data_dash.start,
        end: data_dash.end,
      })
        .then((res) => {
          setRecord(res.data);
          setLoader(false);
        })
        .catch(() => {
          setRecord({
            exams: [],
            patients: [],
            attendances: [],
            medicines: [],
            procedures: [],
          });
          setLoader(false);
        });
    }
  }, [patient, data_dash.start, data_dash.end]);

  return (
    <div className="app-wrapper">
      <div className="animated slideInUpTiny animation-duration-3">
        <Paper elevation={3}>
          <div className="row p-3">
            <div className="col-md-6 col-12 d-flex">
              <Avatar
                className={`mr-2 ${classes.large}`}
                alt={patient.name}
                src={getUrlPhoto(patient.id, patient.photo)}
              />
              <div className="mr-3">
                <span className={`${classes.userName}`}>{patient.name}</span>
                <p className={`${classes.userDescription}`}>
                  Data de Nascimento:{" "}
                  {patient.birthday ? patient.birthday : "---"}
                </p>
                <p className={`${classes.userDescription}`}>
                  Nome da Mãe:{" "}
                  {patient.mother_name ? patient.mother_name : "---"}
                </p>
                <p className={`${classes.userDescription}`}>
                  CPF: {patient.cpf ? patient.cpf : "---"}
                </p>
                <p className={`${classes.userDescription}`}>
                  CNS: {patient.cns ? patient.cns : "---"}
                </p>
              </div>
              {/* <ProfileNew profile={patient} /> */}
              <Tooltip title="Histórico do paciente" placement="top">
                <IconButton
                  style={{ padding: "6px" }}
                  onClick={() => {
                    dispatch(viewHistory("personalData"));
                    dispatch(getInfoPatient(patient.id));
                  }}
                >
                  <HistoryIcon
                    style={{
                      fontSize: "19px",
                      margin: "0px",
                      padding: "0px",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <div className="col-md-6 col-12 d-flex justify-content-end">
              <CompomentsDataDash />

              <Tooltip title="Sair">
                <IconButton
                  style={{ float: "right", marginLeft: "30px" }}
                  onClick={alterPatient}
                  color="secondary"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <AppBar
            position="static"
            color="default"
            style={{
              background: "white",
              boxShadow: "none",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              <Tab
                label="Atendimento"
                icon={<PersonIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label="Medicamentos"
                icon={<LocalHospitalIcon />}
                {...a11yProps(1)}
              />
              <Tab
                label="Pedidos de Exame"
                icon={<AssignmentIcon />}
                {...a11yProps(2)}
              />
              <Tab
                label="Procedimentos"
                icon={<LibraryBooksIcon />}
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>
        </Paper>
        {loader ? (
          <CircularProgress />
        ) : (
          <div className="row justify-content-center mt-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center">
              <TabPanel value={value} index={0} className="m-0 p-0">
                <AttendancesPatient
                  record={record.attendances}
                  patient={patient}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <MedicinesPatient record={record.medicines} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ExamsPatient record={record.exams} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ProceduresPatient record={record.procedures} />
              </TabPanel>
            </div>

            {/* <div className="row justify-content-center mt-3">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 justify-content-center">
              <AttendancesPatient record={record.attendances} />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 justify-content-center">
              <MedicinesPatient record={record.medicines} />
              <ExamsPatient record={record.exams} />
              <ProceduresPatient record={record.procedures} />
            </div>
          </div> */}
          </div>
        )}
      </div>
      <Button
        onClick={alterPatient}
        className="jr-btn jr-btn-sm mt-3"
        color="secondary"
        variant="contained"
        style={{ float: "left", textTransform: "initial" }}
      >
        <i className="zmdi zmdi-undo zmdi-hc-lg" />
        <span>Voltar</span>
      </Button>
      <HistoryPatient id_patient={patient.id} />
    </div>
  );
}
