import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { Card, CardBody } from "reactstrap";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  Tooltip,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import IconDelete from "@material-ui/icons/Delete";
import { BtnSave } from "components/Button";
import moment from "moment";
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import Api from "services/api";

export default function FormRegisterPoint({ user, address }) {
  const [date, setDate] = useState(new Date());
  const initial = {
    id: "",
    hour: "",
    status: "",
    observation: "",
    alter: false,
  };
  const [record, setRecord] = useState([initial]);
  const [workWeek, setWorkWeek] = useState(0);
  const [workMonth, setWorkMonth] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && address) {
      Api.post("/ms_system/registerpoint/getRegisterUser/", {
        user,
        address,
        date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      }).then((res) => {
        setWorkMonth(res.data.workMonth);
        setWorkWeek(res.data.workWeek);
        if (res.data.record.length > 0) {
          setRecord(
            res.data.record.map((row) => ({
              ...row,
              alter: false,
            }))
          );
        } else {
          setRecord([initial]);
        }
      });
    }
  }, [date]);

  const handleChange = (index, field, value) => {
    if (field === "hour") {
      if (date.toLocaleDateString() === moment().format("DD/MM/YYYY")) {
        if (
          moment().isBefore(moment(`${moment().format("YYYY-MM-DD")} ${value}`))
        ) {
          value = "";
          dispatch(
            viewAlertMessage(
              "error",
              "A hora informada não pode ser superior ao horário atual!"
            )
          );
          setTimeout(() => {
            dispatch(closeAlertMessage("success", "Salvo com sucesso!"));
          }, 5000);
        }
      }
    }
    setRecord(
      record.map((row, key) =>
        key === index ? { ...row, [field]: value, alter: true } : row
      )
    );
  };

  const handleAdd = () => {
    setRecord([
      ...record,
      {
        ...initial,
        status: record[record.length - 1].status === "1" ? "0" : "1",
      },
    ]);
  };

  const handleRemove = (index) => {
    setRecord(record.filter((row, key) => key !== index));
  };

  const handleSave = () => {
    Api.post("/ms_system/registerpoint/save/", {
      user,
      address,
      date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      record,
    })
      .then((res) => {
        let { ids, success } = res.data;
        if (success) {
          setRecord(
            record.map((row, index) => ({
              ...row,
              id: ids[index],
              alter: false,
            }))
          );
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => {
            dispatch(closeAlertMessage());
          }, 5000);
        }
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => {
          dispatch(closeAlertMessage());
        }, 5000);
      });
  };

  return (
    <Card className="shadow border-0 mt-3">
      <CardBody>
        <div className="row">
          <div className="col-12">
            <h4 className="font-weight-semibold">
              <span className="pt-3 mr-3">Registros para o dia:</span>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                <KeyboardDatePicker
                  value={date}
                  onChange={(e) => setDate(e)}
                  maxDate={new Date()}
                  format="dd/MM/yyyy"
                />
              </MuiPickersUtilsProvider>
            </h4>
          </div>
          <div className="col-12">
            <p
              className="text-left"
              style={{ color: "gray", fontSize: "13px" }}
            >
              {`Total de horas trabalhadas na semana: ${workWeek}hrs`}
              <br />
              {`Total de horas trabalhadas no mês: ${workMonth}hrs`}
            </p>
          </div>
          <div
            className="col-12 mt-3"
            style={{
              minWidth: "250",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Horário</TableCell>
                  <TableCell>Entrada/Saída</TableCell>
                  <TableCell>Observações</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell width="10%">
                      <TextField
                        type="time"
                        fullWidth
                        margin="normal"
                        value={row.hour}
                        onChange={(e) =>
                          handleChange(index, "hour", e.target.value)
                        }
                        onClick={() => {
                          if (!row.hour) {
                            handleChange(
                              index,
                              "hour",
                              moment().format("HH:mm")
                            );
                          }
                        }}
                        className="mt-0"
                      />
                    </TableCell>
                    <TableCell width="25%">
                      <FormControl component="fieldset" className="ml-3">
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          value={row.status}
                          onChange={(e) =>
                            handleChange(index, "status", e.target.value)
                          }
                          className="mt-1"
                          row
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label="Entrada"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label="Saída"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                    <TableCell width="50%">
                      <TextField
                        fullWidth
                        margin="normal"
                        value={row.observation}
                        onChange={(e) =>
                          handleChange(index, "observation", e.target.value)
                        }
                        className="mt-0"
                      ></TextField>
                    </TableCell>
                    <TableCell width="15%" align="right">
                      {record.length > 1 && !row.id && (
                        <Tooltip title="Remover" placement="top">
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              handleRemove(index);
                            }}
                          >
                            <IconDelete />
                          </IconButton>
                        </Tooltip>
                      )}
                      {index + 1 === record.length && (
                        <Tooltip title="Adicionar novo" placement="top">
                          <IconButton color="primary" onClick={handleAdd}>
                            <i className="zmdi zmdi-plus zmdi-hc-lg" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className="col-12 mt-3">
            <BtnSave title="Salvar" onClick={handleSave} float="right" />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
