import React, { useState, useEffect } from "react";
import {
  MenuItem,
  Button,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import Api from "services/api";
import NoRecord from "components/Alert/NoRecord";
import moment from "moment";
import SearchPatient from "./SearchPatient";
import { ModalResponsive } from "components/ModalResponsive";

export default function HistoryEvent({ calendar, closeMenu, width }) {
  const [modal_form, setModalForm] = useState(false);
  const [patient, setPatient] = useState({
    id: "",
    name: "",
    email: "",
    cellphone: "",
    smartphone: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (patient.id) {
      Api.post(`/ms_system/calendar/getEventsPatient/`, {
        patient: patient.id,
        calendar,
      }).then((res) => setEvents(res.data.record));
    }
  }, [patient.id]);

  function closeModal() {
    setModalForm(false);
    setPatient({
      id: "",
      name: "",
      email: "",
      cellphone: "",
      smartphone: "",
    });
    closeMenu();
    setEvents([]);
  }

  const ComponentForm = () => (
    <div className="row w-100 mt-5">
      {!patient.id ? (
        <div className="col-12">
          <SearchPatient handleChangeAutocomplete={(obj) => setPatient(obj)} />
        </div>
      ) : (
        <>
          <div className="col-md-6 col-xs-12 d-flex">
            <img
              src={getUrlPhoto(patient.id, patient.photo)}
              alt={patient.name}
              className="rounded-circle img-responsive"
              style={{ width: "60px", height: "60px", cursor: "pointer" }}
            />
            <div className="pl-2 f-date">
              <strong>
                {patient.name}{" "}
                <Tooltip title="Alterar paciente" placement="top">
                  <i
                    className="zmdi zmdi-close ml-3"
                    style={{ cursor: "pointer", color: "#bc0003" }}
                    onClick={() => setPatient({ id: "", name: "" })}
                  />
                </Tooltip>
              </strong>
              <br />
              {patient.email}
              <br />
              {patient.smartphone}
            </div>
          </div>
          <div className="col-12">
            <Table className="table-finances">
              <TableHead>
                <TableRow>
                  <TableCell>Agenda</TableCell>
                  <TableCell align="left">Data</TableCell>
                  <TableCell align="left">Tipo</TableCell>
                  <TableCell align="left">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.length > 0 ? (
                  events
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow hover key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.doctor}
                        </TableCell>
                        <TableCell align="left">
                          {moment(row.start).format("DD/MM/YYYY HH:mm")} -{" "}
                          {moment(row.end).format("HH:mm")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.type}
                        </TableCell>
                        <TableCell align="left">
                          {row.canceled === "1"
                            ? getDisplayStatus("canceled")
                            : getDisplayStatus(row.status)}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow key="1">
                    <TableCell colSpan={3}>
                      <NoRecord />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={events.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Registros por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `Mostrando ${from} - ${to} de ${count}`
                    }
                    page={page}
                    onChangePage={(e, page) => setPage(page)}
                    onChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </>
      )}
    </div>
  );

  function getUrlPhoto(id, photo) {
    if (photo) {
      return `https://s3.sa-east-1.amazonaws.com/salutem-webapp-files/upload/user/${id}/${photo}`;
    } else {
      return "https://balancer.salutemplus.com/images/avatar_user_men.jpg";
    }
  }

  function getDisplayStatus(value) {
    switch (value) {
      case "1":
        return (
          <span>
            <i className="zmdi zmdi-calendar ml-1" /> Agendado
          </span>
        );
      case "2":
        return (
          <span className="text-primary">
            <i className="zmdi zmdi-assignment-check ml-1" /> Confirmado
          </span>
        );
      case "3":
        return (
          <span style={{ color: "#ff9800" }}>
            <i className="zmdi zmdi-time ml-1" /> Aguardando atendimento
          </span>
        );
      case "4":
        return (
          <span style={{ color: "#6f42c1" }}>
            <i className="zmdi zmdi-spinner ml-1" /> Em atendimento
          </span>
        );
      case "5":
        return (
          <span style={{ color: "#4caf50" }}>
            <i className="zmdi zmdi-check-all ml-1" /> Finalizado
          </span>
        );
      case "6":
        return (
          <span style={{ color: "#bc0003" }}>
            {" "}
            <i className="zmdi zmdi-block-alt ml-1" /> Não compareceu
          </span>
        );
      case "canceled":
        return (
          <span style={{ color: "#bc0003" }}>
            {" "}
            <i className="zmdi zmdi-close ml-1" /> Desmarcado
          </span>
        );
      default:
        break;
    }
  }

  return (
    <>
      {width > 500 ? (
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 15, marginLeft: 10 }}
          onClick={() => setModalForm(true)}
        >
          <i className="zmdi zmdi-time-restore mr-3" /> Histórico de
          Agendamentos
        </Button>
      ) : (
        <MenuItem onClick={() => setModalForm(true)}>
          <i className="zmdi zmdi-time-restore mr-3" /> Histórico de
          Agendamentos
        </MenuItem>
      )}

      <ModalResponsive
        title={width > 500 ? "Histórico de Agendamentos" : "Agendamentos"}
        open={modal_form}
        close={closeModal}
        maxWidth="lg"
        buttons={
          <div className="col-md-12">
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => closeModal()}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Fechar </span>
            </Button>
          </div>
        }
      >
        {ComponentForm()}
      </ModalResponsive>
    </>
  );
}
