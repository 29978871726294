import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";
import { setSystemReviewCustom } from "actions/configuration/Custom/SystemReviewForms";
import { arrayMove } from "react-sortable-hoc";
import { CATEGORIES_ARRAY, URINARIO } from "constants/CustomSystemReview";

import BarProgress from "components/BarProgress";
import { BtnSave, BtnCan } from "components/Button";
import FormContainer from "../SortableContainer";
import { Button, IconButton, Tooltip } from "@material-ui/core";

function SystemReviewForms({ close, formType, arrayQuestion }) {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [idSaved, setIdSaved] = useState(null);
  const [loading, setLoading] = useState(false);
  const address_selected = useSelector(({ auth }) => auth.address_selected);

  useEffect(() => {
    getList();
  }, []);

  function getList() {
    setLoading(true);
    Api.post("ms_configuration/custom/getSystemReviewForms/", {
      user: address_selected.user_id,
      type: formType,
    })
      .then(({ data }) => {
        if (data) {
          setIdSaved(data.id);
          setList([
            ...data.view.split(",").map((id) => ({
              ...arrayQuestion.find((question) => question.id === Number(id)),
              checked: true,
            })),
            ...arrayQuestion
              .filter((question) => data.view.indexOf(question.id) < 0)
              .map((questionFilter) => ({ ...questionFilter, checked: false })),
          ]);
        } else {
          setList(
            arrayQuestion.map((question) => ({
              ...question,
              checked: true,
            }))
          );
        }
      })
      .catch((err) => {
        showMsg(
          "error",
          "Não foi possível buscar suas configurações salvas",
          dispatch
        );
        setList(
          arrayQuestion.map((question) => ({ ...question, checked: true }))
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleCheck(cat, value) {
    setList(
      list.map((row) => (row.id === cat ? { ...row, checked: value } : row))
    );
  }

  function onSortEnd({ oldIndex, newIndex }) {
    setList(arrayMove(list, oldIndex, newIndex));
  }

  function handleRestart() {
    setList(
      arrayQuestion.map((question) => ({
        ...question,
        checked: true,
      }))
    );
  }

  function handleSave() {
    setLoading(true);
    let obj = {
      user: address_selected.user_id,
      view: list
        .filter((el) => el.checked)
        .map((row) => row.id)
        .join(","),
      type: formType,
      idSaved,
    };
    Api.post("ms_configuration/custom/saveSystemReviewForms", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch(
            setSystemReviewCustom({
              type: formType,
              id: data.id,
              view: data.view,
            })
          );
          showMsg("success", "Salvo com successo!", dispatch);
          close();
        } else {
          showMsg(
            "error",
            "Não foi possível salvar esta configuração",
            dispatch
          );
        }
      })
      .catch(() => {
        showMsg("error", "Não foi possível salvar esta configuração", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getTitle = () => {
    let title = CATEGORIES_ARRAY.find(
      (categoria) => categoria.id === Number(formType)
    );
    return title
      ? title.description
      : CATEGORIES_ARRAY.find((categoria) => categoria.id === URINARIO)
          .description;
  };

  return (
    <div>
      <div className="row">
        <h2 className="text-primary font-weight-semibold w-100">
          Customização de formulário - {getTitle()}
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
        <div className="col-12">
          <Button
            color="primary"
            className="jr-btn jr-btn-md"
            onClick={handleRestart}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-settings zmdi-hc-lg" />
            <span> Resetar configurações </span>
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="animated slideInUpTiny animation-duration-3">
            {loading ? (
              <BarProgress />
            ) : (
              <FormContainer
                forms={list}
                handleCheck={handleCheck}
                onSortEnd={onSortEnd}
                useDragHandle={true}
                sort={true}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-3">
          <BtnCan title="Fechar" onClick={close} float="left" />
          <BtnSave title="Salvar" onClick={handleSave} float="right" />
        </div>
      </div>
    </div>
  );
}

export default SystemReviewForms;
