import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class GraphicFinance extends Component {
  state = {
    dados: {},
    chartData: {
      labels: ["Corrente", "Garantida", "Crédito"],
      datasets: [
        {
          label: "Population",
          data: [594, 181045, 353060],
          backgroundColor: ["#1B9CFC", "#182C61", "#25CCF7"]
        }
      ]
    },
    balance_cc: "",
    balance_credit: "",
    balance_total: ""
  };

  UNSAFE_componentWillMount() {
    // let dadosGra = this.props.dadosGraphi;
    this.teste();
  }

  teste = () => {
    this.setState({});
  };

  static defaultProps = {
    // displayTitle: true,
    // displayLegend: true,
    // legendPosition: "center",
    location: "City"
  };

  render() {
    return (
      <div
        className="chart-container"
        style={{ height: "20vh", width: "20vw" }}
      >
        <Bar
          data={this.state.chartData}
          options={{
            title: {
              responsive: true,
              fontSize: 20
            },
            legend: {
              display: this.props.displayLegend,
              position: this.props.legendPosition
            }
          }}
        />
      </div>
    );
  }
}

export default GraphicFinance;
