import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  handleChange,
  handleChangeRecreation,
} from "actions/consult/PersonalData";
import { setImage } from "actions/configuration/Profile";
import { TextField, Button, Tooltip, MenuItem } from "@material-ui/core";
import * as alertActions from "actions/Alerts";
import Api from "services/api";
import AutocompleteProfession from "../autocomplete/Profession";
import AutocompleteOcuppation from "../autocomplete/Ocuppation";
import AutocompleteNaturality from "../autocomplete/CompositeAddress";
import AutocompleteDrugStore from "../autocomplete/DrugStore";
import MaskPhone from "components/Masks/MaskPhone";
import MaskCellphone from "components/Masks/MaskCellphone";
import MaskMoney from "components/Masks/MaskMoney";
import MaskCns from "components/Masks/MaskCns";
import { getFormatValueMoney } from "services/functions";
import { MultipleSelect } from "react-select-material-ui";
import TakePhotoWithCropper from "components/Forms/TakePhotoWithCropper";
import { getUrlPhoto } from "services/functions";
import moment from "moment";

class BasicData extends Component {
  state = {
    statsPhotoModal: false,
    setImgPatient: "",
  };
  submit = () => {
    let formData = {
      user: this.props.user,
      data: {
        ...this.props.basicData,
        rent: getFormatValueMoney(this.props.basicData.rent, ","),
        recreations: this.props.recreations_user,
        cns: this.props.basicData.cns
          ? this.props.basicData.cns.replace(/[^0-9]/g, "")
          : "",
      },
    };

    /* if (this.state.setImgPatient) {
      this.props.setImage(this.props.basicData.id, this.state.setImgPatient);
    } */

    Api.post(`/microservice/profile/saveBasicData/`, formData)
      .then((res) => {
        res.data.success
          ? this.setAlertMessage("success", "Salvo com sucesso!")
          : this.setAlertMessage("error", "Ocorreu um erro ao salvar!");
      })
      .catch(() => this.setAlertMessage("error", "Ocorreu um erro ao salvar!"));
  };

  setAlertMessage = (type, message) => {
    this.props.viewAlertMessage(type, message);
    setTimeout(() => this.props.closeAlertMessage(), 5000);
  };

  handleChangeBasic = (name) => (event) => {
    this.props.handleChange(name, event.target.value);
  };

  handleChangeAutocomplete = (index, value) => {
    this.props.handleChange(index, value);
  };

  imageReceive = (img) => {
    this.props.setImage(this.props.user, img);
    this.setState({ setImgPatient: img });
  };

  calcAge = () => {
    let birth = this.props.basicData.birthday.split("-");
    let date = new Date();
    let today = moment([
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
      ]),
      birthday = moment([birth[0], birth[1], birth[2]]);
    let value = this.getFormattedDateDiff(birthday, today);

    this.props.handleChange("age", value);
  };

  displayAge = (interval, diff) => {
    if (interval === "years") {
      return diff == 1 ? " ano" : " anos";
    } else {
      return diff == 1 ? " mês" : " meses";
    }
  };

  getFormattedDateDiff = (date1, date2) => {
    let b = moment(date1),
      a = moment(date2),
      intervals = ["years", "months"],
      out = [];

    intervals.forEach((interval) => {
      let diff = a.diff(b, interval);
      b.add(diff, interval);
      out.push(diff + " " + this.displayAge(interval, diff));
    });
    return out.join(", ");
  };

  setTakePhoto(value) {
    this.setState({ statsPhotoModal: value });
  }

  render() {
    const { basicData, user } = this.props;
    const { setImgPatient } = this.state;
    const imgPatient = basicData.photo;
    return (
      <div className="row">
        <div className="col-md-12">
          <span className="card-title text-primary title-form">
            Informações básicas
          </span>
        </div>
        <div className="col-md-2" style={{ float: "left" }}>
          <div className="col-md-1 col-xs-3">
            <Tooltip title="Alterar foto">
              <img
                src={
                  setImgPatient ? setImgPatient : getUrlPhoto(user, imgPatient)
                }
                alt={basicData.name}
                className="rounded-circle img-responsive"
                style={{
                  marginTop: "10px",
                  width: "170px",
                  height: "170px",
                  cursor: "pointer",
                }}
                onClick={() => this.setTakePhoto(true)}
              />
            </Tooltip>
          </div>
        </div>
        <div className="col-md-10" style={{ float: "rigth" }}>
          <div className="col-md-12 col-12">
            <TextField
              id="name"
              label="Nome"
              value={basicData.name}
              margin="normal"
              fullWidth
              onChange={this.handleChangeBasic("name")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-md-12 col-12">
            <TextField
              id="email"
              label="Email"
              value={basicData.email}
              margin="normal"
              fullWidth
              onChange={this.handleChangeBasic("email")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="sex"
            select
            label="Sexo:"
            value={basicData.genderVal}
            onChange={this.handleChangeBasic("genderVal")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="0">
              Masculino
            </MenuItem>
            <MenuItem key="2" value="1">
              Feminino
            </MenuItem>
            <MenuItem key="3" value="2">
              Outro
            </MenuItem>
          </TextField>
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="dt_nasc"
            type="date"
            label="Data de Nascimento:"
            value={basicData.birthday}
            margin="normal"
            fullWidth
            onChange={this.handleChangeBasic("birthday")}
            onBlur={() => this.calcAge()}
            InputLabelProps={{
              shrink: true,
            }}
            onInput={(e) => {
              if (e.target.value.length > 10) {
                e.target.value = e.target.value.substr(1, 11);
              }
            }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="age"
            label="Idade:"
            value={basicData.age}
            margin="normal"
            fullWidth
            readOnly
            onChange={this.handleChangeBasic("age")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="blood_type"
            select
            label="Tipo sanguíneo:"
            value={basicData.blood_type}
            onChange={this.handleChangeBasic("blood_type")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="a+">
              A+
            </MenuItem>
            <MenuItem key="2" value="a-">
              A-
            </MenuItem>
            <MenuItem key="3" value="b+">
              B+
            </MenuItem>
            <MenuItem key="4" value="b-">
              B-
            </MenuItem>
            <MenuItem key="5" value="o+">
              O+
            </MenuItem>
            <MenuItem key="6" value="o-">
              O-
            </MenuItem>
            <MenuItem key="7" value="ab+">
              AB+
            </MenuItem>
            <MenuItem key="8" value="ab-">
              AB-
            </MenuItem>
          </TextField>
        </div>

        <div className="col-md-6 col-12">
          <AutocompleteNaturality
            address={{
              id: basicData.naturality_id,
              label: basicData.naturality,
            }}
            naturality={true}
            change={this.handleChangeAutocomplete.bind(this)}
          />
        </div>

        <div className="col-md-6 col-12">
          <AutocompleteNaturality
            address={{
              id: basicData.procedence_id,
              label: basicData.procedence,
            }}
            naturality={false}
            change={this.handleChangeAutocomplete.bind(this)}
          />
        </div>

        <div className="col-md-3 col-12">
          <AutocompleteProfession
            profession={{
              id: basicData.fk_profession,
              name: basicData.profession,
            }}
            change={this.handleChangeAutocomplete.bind(this)}
          />
        </div>

        <div className="col-md-3 col-12">
          <AutocompleteOcuppation
            ocuppation={{
              id: basicData.fk_ocuppation,
              name: basicData.ocuppation,
            }}
            change={this.handleChangeAutocomplete.bind(this)}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            select
            label="Situação Econômica:"
            value={basicData.economic}
            onChange={this.handleChangeBasic("economic")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="Aposentado">
              Aposentado(a)
            </MenuItem>
            <MenuItem key="2" value="Desempregado">
              Desempregado(a)
            </MenuItem>
            <MenuItem key="3" value="Sob Licença Médica">
              Sob Licença Médica
            </MenuItem>
            <MenuItem key="4" value="Empregado">
              Empregado
            </MenuItem>
            <MenuItem key="5" value="Outro">
              Outro
            </MenuItem>
          </TextField>
        </div>
        <div className="col-md-3 col-12">
          <TextField
            select
            label="Situação Socio-econômica:"
            value={basicData.socioeconomic}
            onChange={this.handleChangeBasic("socioeconomic")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="Baixo Inferior">
              Baixo Inferior
            </MenuItem>
            <MenuItem key="2" value="Baixo Superior">
              Baixo Superior
            </MenuItem>
            <MenuItem key="3" value="Médio Inferior">
              Médio Inferior
            </MenuItem>
            <MenuItem key="4" value="Médio">
              Médio
            </MenuItem>
            <MenuItem key="6" value="Médio Superior">
              Médio Superior
            </MenuItem>
            <MenuItem key="5" value="Superior">
              Superior
            </MenuItem>
          </TextField>
        </div>
        <div className="col-md-3 col-12">
          <TextField
            select
            label="Situação Habitacional:"
            value={basicData.home}
            onChange={this.handleChangeBasic("home")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="Própria">
              Própria
            </MenuItem>
            <MenuItem key="2" value="Alugada">
              Alugada
            </MenuItem>
            <MenuItem key="3" value="Cedida">
              Cedida
            </MenuItem>
          </TextField>
        </div>

        <div className="col-md-3 col-12" style={{ marginTop: "-5px" }}>
          <MaskMoney
            label="Renda:"
            value={basicData.rent}
            onChange={(e) => this.props.handleChange("rent", e.target.value)}
          />
        </div>

        <div className="col-md-3 col-12">
          <AutocompleteDrugStore
            drugStore={{
              id: basicData.fk_drug_store,
              name: basicData.drug_store,
            }}
            change={this.handleChangeAutocomplete.bind(this)}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="cns"
            label="Cartão Nacional de Saúde"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCns,
              value: basicData.cns,
              onChange: this.handleChangeBasic("cns"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-6 col-12">
          <MultipleSelect
            label="Lazer:"
            values={this.props.recreations_user}
            options={this.props.all_recreations}
            onChange={(e) => this.props.handleChangeRecreation(e)}
            SelectProps={{
              isCreatable: false,
              msgNoOptionsAvailable: "Todos lazeres foram selecionado",
              msgNoOptionsMatchFilter: "Nenhum lazer para este filtro",
            }}
            style={{ marginTop: "10px" }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="raca"
            select
            label="Etnia:"
            value={basicData.raca}
            onChange={this.handleChangeBasic("raca")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="Amarelo">
              Amarelo(a)
            </MenuItem>
            <MenuItem key="2" value="Branco">
              Branco(a)
            </MenuItem>
            <MenuItem key="3" value="Caboclo">
              Caboclo
            </MenuItem>
            <MenuItem key="4" value="Cafuzo">
              Cafuzo
            </MenuItem>
            <MenuItem key="5" value="Mameluco">
              Mameluco
            </MenuItem>
            <MenuItem key="6" value="Mulato">
              Mulato
            </MenuItem>
            <MenuItem key="7" value="Negro">
              Negro(a)
            </MenuItem>
            <MenuItem key="8" value="Pardo">
              Pardo(a)
            </MenuItem>
          </TextField>
        </div>

        <div className="col-md-3 col-12">
          <TextField
            select
            label="Adotivo:"
            value={basicData.adoptive}
            onChange={this.handleChangeBasic("adoptive")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="Não">
              Não
            </MenuItem>
            <MenuItem key="2" value="Sim">
              Sim
            </MenuItem>
          </TextField>
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="schooling"
            select
            label="Escolaridade:"
            value={basicData.schooling}
            onChange={this.handleChangeBasic("schooling")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="1" value="Analfabeto">
              Analfabeto
            </MenuItem>
            <MenuItem key="2" value="Fundamental">
              Fundamental
            </MenuItem>
            <MenuItem key="3" value="Medio">
              Médio
            </MenuItem>
            <MenuItem key="4" value="Superior">
              Superior (Graduação)
            </MenuItem>
            <MenuItem key="5" value="Pos-graduacao">
              Pós-graduação
            </MenuItem>
            <MenuItem key="6" value="Mestrado">
              Mestrado
            </MenuItem>
            <MenuItem key="7" value="Doutorado">
              Doutorado
            </MenuItem>
            <MenuItem key="8" value="Pos-doutorado">
              Pós-doutorado
            </MenuItem>
          </TextField>
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="cellphone"
            label="Celular:"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCellphone,
              value: basicData.cellphone
                ? basicData.cellphone
                : basicData.smartphone,
              onChange: this.handleChangeBasic("cellphone"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="whatsapp"
            label="Whatsapp:"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCellphone,
              value: basicData.whatsapp,
              onChange: this.handleChangeBasic("whatsapp"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="phone"
            label="Telefone Fixo:"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskPhone,
              value: basicData.phone,
              onChange: this.handleChangeBasic("phone"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-12 text-right">
          <Button
            onClick={() => this.submit()}
            className="jr-btn jr-btn-sm bg-success"
            color="primary"
            variant="contained"
          >
            <i className="zmdi zmdi-check text-right" />
            <span> Salvar </span>
          </Button>
        </div>
        <TakePhotoWithCropper
          open={this.state.statsPhotoModal}
          handleClose={() => this.setTakePhoto(false)}
          imageReceive={this.imageReceive}
          hasImage={setImgPatient || imgPatient}
          urlImage={
            setImgPatient ? setImgPatient : getUrlPhoto(user, imgPatient)
          }
          handleRemove={() => {
            this.props.handleChange("photo", null);
            this.setState({ setImgPatient: "" });
            this.setTakePhoto(false);
          }}
          user={user}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.authUser,
    paramsConsult: state.paramsConsult,
    basicData: state.Profile.basic,
    all_recreations: state.Profile.all_recreations,
    recreations_user: state.Profile.recreations_user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { handleChange, handleChangeRecreation, ...alertActions, setImage },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BasicData);
