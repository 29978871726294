import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";
import { ADMISSION_ARRAY } from "constants/CustomAdmissionForm";
import { changeConfigAdmission, setListAdmission } from "actions/Configuration";

import { BtnSave, BtnCan } from "components/Button";
import { arrayMove } from "react-sortable-hoc";
import { Tooltip, IconButton, Button } from "@material-ui/core";
import CircularProgress from "components/CircularProgress";
import FormContainer from "../SortableContainer";

export default function AdmissionForm({ close }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);

  const user = useSelector(({ auth }) => auth.authUser);
  const managers = useSelector(({ auth }) => auth.managers);

  const view = useSelector(
    ({ configuration }) => configuration.list_admission.view
  );
  const view_sub_group = useSelector(
    ({ configuration }) => configuration.list_admission.view_sub_group
  );
  const id = useSelector(
    ({ configuration }) => configuration.list_admission.id
  );

  useEffect(() => {
    Api.post("/ms_configuration/custom/getAdmission", {
      authId: managers && managers.length > 0 ? managers[0].provider : user,
    }).then((res) => {
      if (res.data.record) {
        setListAdmission(res.data.record);
        changeConfigAdmission({
          id: res.data.record.id,
          view: res.data.record.view,
          view_sub_group: res.data.record.view_sub_group,
        });
      }
      //  else {
      //   setListAdmission({
      //     id: null,
      //     view: null,
      //     view_sub_group: null,
      //   });
      // }
    });
  }, []);

  useEffect(() => {
    if (view) {
      let ids = view.split(",");
      let idsSub = view_sub_group.split(",");
      let new_arr = [];
      ids.forEach((row) => {
        let obj = ADMISSION_ARRAY.find((el) => el.id === row);
        new_arr.push({ ...obj, checked: true });
      });
      let notInids = ADMISSION_ARRAY.filter(
        (row) => ids.indexOf(row.id) === -1
      );

      new_arr.forEach((row) => {
        if (row.sub_group) {
          row.sub_group.forEach((ele, idx) => {
            let obj = idsSub.find((el) => el === ele.id);
            if (obj) {
              row.sub_group[idx] = { ...ele, checked: true };
            }
          });
        }
      });

      setList([
        ...new_arr,
        ...notInids.map((row) => ({ ...row, checked: false })),
      ]);
    } else {
      let list = ADMISSION_ARRAY.map((row) => ({ ...row, checked: true }));

      list.forEach((row) => {
        if (row.sub_group) {
          row.sub_group.forEach((ele, idx) => {
            row.sub_group[idx] = { ...ele, checked: true };
          });
        }
      });
      setList(list);
    }
  }, [view]);

  function handleCheck(cat, value) {
    setList(
      list.map((row) => (row.id === cat ? { ...row, checked: value } : row))
    );
  }

  function handleCheckSubGroup(index, idx) {
    let newlist = list.map((row, idex) =>
      index === idex
        ? {
            ...row,
            sub_group: row.sub_group.map((ele, idxx) =>
              idxx === idx
                ? {
                    ...ele,
                    checked: !ele.checked,
                  }
                : {
                    ...ele,
                  }
            ),
          }
        : { ...row }
    );
    setList(newlist);
  }

  function onSortEnd({ oldIndex, newIndex }) {
    setList(arrayMove(list, oldIndex, newIndex));
  }

  function resetConfig() {
    setList(ADMISSION_ARRAY.map((row) => ({ ...row, checked: true })));
  }

  function handleSave() {
    let marked = list.filter((row) => row.checked);
    let ids = marked.map((row) => row.id);

    let listSubGroup = [];
    list.forEach((el) => {
      if (el.sub_group) {
        el.sub_group.map(
          (row) => row.checked === true && listSubGroup.push(row.id)
        );
      }
    });

    Api.post("/ms_configuration/custom/saveAdmission", {
      user: managers && managers.length > 0 ? managers[0].provider : user,
      id: id ? id : null,
      admission: ids.join(","),
      viewSung: listSubGroup.join(","),
    })
      .then(({ data }) => {
        dispatch(
          changeConfigAdmission({
            id: data.id,
            view: data.view,
            view_sub_group: data.view_sub_group,
          })
        );
        dispatch(setListAdmission(data));

        showMsg("success", "Configuração salva com sucesso!", dispatch);
        close();
      })
      .catch(() => {
        showMsg("error", "Erro ao salvar!", dispatch);
      });
  }

  return (
    <>
      {loader && <CircularProgress />};
      {!loader && (
        <div className="row">
          <h2 className="text-primary font-weight-semibold w-100">
            Customização Ficha Admissão
            <Tooltip title="Fechar" placement="top">
              <IconButton
                aria-label="Fechar"
                onClick={() => close()}
                style={{ float: "right", marginTop: "-10px" }}
              >
                <i className="zmdi zmdi-close" />
              </IconButton>
            </Tooltip>
          </h2>
          <div className="col-12">
            <Button
              color="primary"
              className="jr-btn jr-btn-md"
              onClick={resetConfig}
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-settings zmdi-hc-lg" />
              <span> Resetar configurações </span>
            </Button>
          </div>
          <div className="col-12">
            <div className="animated slideInUpTiny animation-duration-3">
              <FormContainer
                forms={list}
                handleCheck={handleCheck}
                handleCheckSubGroup={handleCheckSubGroup}
                onSortEnd={onSortEnd}
                useDragHandle={true}
                sort={true}
              />
            </div>
          </div>
          <div className="col-12 mt-3">
            <BtnCan title="Fechar" onClick={close} float="left" />
            <BtnSave title="Salvar" onClick={handleSave} float="right" />
          </div>
        </div>
      )}
    </>
  );
}
