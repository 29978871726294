import React, { Component } from "react";
// import './styles.css';
import api from "services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";

class Disabilities extends Component {
  state = {
    patient: this.props.patient,
    disabilities: [],
    special_needs: [],
    //Pagination Disabilities
    rowsPerPageD: 10,
    pageD: 0,
    //Pagination SN
    rowsPerPageSN: 10,
    pageSN: 0
  };

  componentDidMount() {
    api
      .post(`/microservice/consult/getDeficiencies/`, {
        patient: this.state.patient
      })
      .then(res => {
        this.setState({
          disabilities: res.data.disabilities,
          special_needs: res.data.special_needs
        });
      })
      .catch(() => {});
  }

  //Disabilities
  handleChangePageD = (event, pageD) => {
    this.setState({ pageD });
  };

  handleChangeRowsPerPageD = event => {
    this.setState({ rowsPerPageD: event.target.value });
  };

  //SN
  handleChangePageSN = (event, pageSN) => {
    this.setState({ pageSN });
  };

  handleChangeRowsPerPageSN = event => {
    this.setState({ rowsPerPageSN: event.target.value });
  };

  render() {
    const { rowsPerPageD, pageD } = this.state;
    const { rowsPerPageSN, pageSN } = this.state;
    const { disabilities, special_needs } = this.state;
    return (
      <div className="container-person border">
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400"
          }}
        >
          Disfunções funcionais e outros
        </h3>

        <h4 style={{ padding: "10px", fontWeight: "bold" }}>Disfunções</h4>
        {disabilities ? (
          <Table className="table-history">
            <TableHead>
              <TableRow>
                <TableCell>Disfunções</TableCell>
                <TableCell align="left">Observação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {disabilities
                .slice(
                  pageD * rowsPerPageD,
                  pageD * rowsPerPageD + rowsPerPageD
                )
                .map((row, key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {" "}
                      {row.name_registry}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {row.additional_considerations
                        ? row.additional_considerations
                        : "---"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={disabilities.length}
                  rowsPerPage={rowsPerPageD}
                  labelRowsPerPage="Registros por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `Mostrando ${from} - ${to} de ${count}`
                  }
                  page={pageD}
                  onChangePage={this.handleChangePageD}
                  onChangeRowsPerPage={this.handleChangeRowsPerPageD}
                />
              </TableRow>
            </TableFooter>
          </Table>
        ) : (
          <p
            style={{
              textAlign: "center",
              color: "#a6a6a6",
              fontStyle: "italic"
            }}
          >
            Nenhum Registro Encontrado
          </p>
        )}

        <h4 style={{ padding: "10px", fontWeight: "bold", marginTop: "20px" }}>
          Necessidades especiais
        </h4>
        {special_needs ? (
          <Table className="table-history">
            <TableHead>
              <TableRow>
                <TableCell>Necessidades especiais</TableCell>
                <TableCell align="left">Observação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {special_needs
                .slice(
                  pageSN * rowsPerPageSN,
                  pageSN * rowsPerPageSN + rowsPerPageSN
                )
                .map((row, key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {" "}
                      {row.name_registry}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {row.additional_considerations
                        ? row.additional_considerations
                        : "---"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={special_needs.length}
                  rowsPerPage={rowsPerPageSN}
                  labelRowsPerPage="Registros por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `Mostrando ${from} - ${to} de ${count}`
                  }
                  page={pageSN}
                  onChangePage={this.handleChangePageSN}
                  onChangeRowsPerPage={this.handleChangeRowsPerPageSN}
                />
              </TableRow>
            </TableFooter>
          </Table>
        ) : (
          <p
            style={{
              textAlign: "center",
              color: "#a6a6a6",
              fontStyle: "italic"
            }}
          >
            Nenhum Registro Encontrado
          </p>
        )}
      </div>
    );
  }
}

export default Disabilities;
