const INITIAL_STATE = {
  fields: {
    first: [
      { id: 1, label: "Nome", name: "u.name" },
      { id: 2, label: "Sexo", name: "sex" },
      { id: 3, label: "Raça", name: "raca" },
      { id: 4, label: "Religião", name: "religion" },
      { id: 5, label: "RG", name: "rg" },
      { id: 16, label: "Grupo Familiar", name: "groupFamily" },
    ],
    second: [
      { id: 6, label: "Idade", name: "age" },
      { id: 7, label: "E-mail", name: "email" },
      { id: 8, label: "Orientação sexual", name: "choose_sexual" },
      { id: 9, label: "Escolaridade", name: "schooling" },
      { id: 10, label: "Endereço", name: "address" },
    ],
    third: [
      { id: 11, label: "Data de nascimento", name: "birthday" },
      { id: 12, label: "Telefone", name: "phone" },
      { id: 13, label: "Celular", name: "cellphone" },
      { id: 14, label: "Estado civil", name: "estado_civil" },
      { id: 15, label: "CPF", name: "cpf" },
    ],
  },
  fields_selected: [1],
  conditions: [
    {
      name: "Agendamento",
      value: 25,
      slug: "scheduling",
      form: 1,
    },
    {
      name: "Alcoólatra",
      value: 29,
      slug: "alcoholic",
      form: 0,
    },
    {
      name: "Alergias Alimentares",
      value: 20,
      slug: "foodAllergies",
      form: 0,
    },
    /* {
      name: "Alergias Medicamentos",
      value: 22,
      slug: "medicinesAllergies",
      form: 0,
    }, */
    {
      name: "Alergias Produtos Quimicos",
      value: 21,
      slug: "chemicalsAllergies",
      form: 0,
    },
    {
      name: "Atividades físicas",
      value: 30,
      slug: "physicalActivities",
      form: 0,
    },
    {
      name: "Cuidados",
      value: 31,
      slug: "cares",
      form: 0,
    },
    {
      name: "Disfunção",
      value: 12,
      slug: "dysfunctions",
      form: 0,
    },
    {
      name: "Doenças",
      value: 1,
      slug: "sickness",
      form: 0,
    },
    /* {
      name: "Doenças Alérgicas",
      value: 6,
      slug: "diseaseAllergic",
      form: 0,
    }, */
    {
      name: "Doenças da Infância",
      value: 14,
      slug: "childhoodDiseases",
      form: 0,
    },
    {
      name: "Estado Civil",
      value: 15,
      slug: "maritalStatus",
      form: 0,
    },
    {
      name: "Histórico Familiar",
      value: 19,
      slug: "familyHistory",
      form: 0,
    },
    {
      name: "Idade",
      value: 2,
      slug: "age",
      form: 0,
    },
    {
      name: "Intercorrência",
      value: 32,
      slug: "intercorrence",
      form: 0,
    },
    {
      name: "Medicamentos",
      value: 3,
      slug: "medicine",
      form: 0,
    },

    {
      name: "Necessidades Especiais",
      value: 13,
      slug: "specialNeeds",
      form: 0,
    },
    {
      name: "Orientação Sexual",
      value: 17,
      slug: "sexualOrientation",
      form: 0,
    },
    {
      name: "Pedidos de exames",
      value: 16,
      slug: "examsRequest",
      form: 0,
    },
    {
      name: "Período",
      value: 11,
      slug: "period",
      form: 0,
    },
    {
      name: "Planos de Saúde",
      value: 18,
      slug: "healthPlan",
      form: 0,
    },
    {
      name: "Presença",
      value: 26,
      slug: "presence",
      form: 1,
    },
    {
      name: "Procedimentos",
      value: 4,
      slug: "procedure",
      form: 0,
    },
    {
      name: "Sexo",
      value: 5,
      slug: "sex",
      form: 0,
    },
    {
      name: "Tabagismo",
      value: 28,
      slug: "smoking",
      form: 0,
    },
    {
      name: "Tags",
      value: 10,
      slug: "tag",
      form: 0,
    },
    {
      name: "Tipo de consulta",
      value: 27,
      slug: "typeConsult",
      form: 1,
    },
  ],
  conditions_selected: [],
  conditions_value: {},
  work_place_select: [],
  all_work_place_user: [],
};

export default function extract(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_EXTRACT":
      return INITIAL_STATE;
    case "RESET_CONDITIONS_VALUE":
      return {
        ...state,
        conditions_selected: [],
        conditions_value: {},
      };
    case "HANDLE_SELECT_FIELD":
      if (state.fields_selected.indexOf(action.field) === -1) {
        return {
          ...state,
          fields_selected: [...state.fields_selected, action.field],
        };
      } else {
        return {
          ...state,
          fields_selected: state.fields_selected.filter(
            (row) => row !== action.field
          ),
        };
      }
    case "REPORT_SELECT_ALL_FIELDS":
      return {
        ...state,
        fields_selected: [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
        ],
      };
    case "REPORT_UNSELECT_ALL_FIELDS":
      return {
        ...state,
        fields_selected: [],
      };
    case "HANDLE_SELECT_CONDITION":
      if (state.conditions_selected.indexOf(action.condition) === -1) {
        return {
          ...state,
          conditions_selected: [...state.conditions_selected, action.condition],
        };
      } else {
        let newArrr = Object.keys(state.conditions_value).filter(
          (row) => row !== action.slug
        );

        let newArr2 = {};
        newArrr.forEach(
          (row) =>
            (newArr2 = { ...newArr2, [row]: state.conditions_value[row] })
        );

        return {
          ...state,
          conditions_selected: state.conditions_selected.filter(
            (row) => row !== action.condition
          ),
          conditions_value: newArr2,
        };
      }
    case "SET_CONDITIONS_VALUE_EXTRACT":
      return {
        ...state,
        conditions_value: {
          ...state.conditions_value,
          [action.payload.condition]: {
            value: action.payload.value,
            operator: action.payload.operator,
            value2: action.payload.value2,
          },
        },
      };

    case "SET_WORK_PLACE_USER_SELECT":
      return {
        ...state,
        work_place_select: action.payload.value,
      };

    case "SET_ALL_WORK_PLACE_USER":
      return {
        ...state,
        all_work_place_user: action.payload.value,
      };

    default:
      return state;
  }
}
