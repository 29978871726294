import React from "react";
import FormLogin from "components/Forms/Login";
import imgLogo from "assets/images/logo.svg";

const LoginFamilyAccompaniment = ({ history }) => {
  const width = window.screen.width;
  const handleSuccess = (success) => {
    if (success) {
      history.push("/app/family-accompaniment");
    }
  };

  if (width < 948) {
    return (
      <div
        className="d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
        style={{
          margin: "0 auto",
          width: "100%",
          background: "#177493",
        }}
      >
        <div className="row w-100">
          <div
            className="col-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <img src={imgLogo} style={{ width: "250px" }}></img>
          </div>
          <div className="col-12" style={{ padding: "0 20px" }}>
            <FormLogin handleSuccess={handleSuccess} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3"
        style={{
          margin: "0 auto",
          width: "100%",
          backgroundImage: `url(${require("assets/images/background-login.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
      >
        <div className="logo" style={{ marginRight: "75px" }}>
          <img src={imgLogo} style={{ width: "250px" }}></img>
        </div>
        <div style={{ marginRight: "400px" }}>
          <FormLogin handleSuccess={handleSuccess} />
        </div>
      </div>
    );
  }
};

export default LoginFamilyAccompaniment;
