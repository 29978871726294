import React from "react";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan } from "components/Button";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function ModalHistory({ open, close, data }) {
  const classes = useStyles();

  return (
    <div>
      <ModalResponsive
        title={"Historico"} // titulo
        open={open}
        close={close}
        maxWidth={"md"}
        buttons={
          <div className="col-12">
            <BtnCan title={"fechar"} onClick={close} float="left" />
          </div>
        }
      >
        <div className="col-12">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sintoma</TableCell>
                <TableCell>Nível de Gravidade</TableCell>
                <TableCell align="left">Início</TableCell>
                <TableCell align="left">Término</TableCell>
                <TableCell align="left">Conduta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.list &&
                data.list.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      <span className="font-weight-bold">{row.name}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.gravity}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row.start).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="left">
                      {row.end ? moment(row.end).format("DD/MM/YYYY") : "-"}
                    </TableCell>
                    <TableCell align="left">
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: row.conduct }}
                      ></div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </ModalResponsive>
    </div>
  );
}
