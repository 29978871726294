export const handleAddFunction = (active, color, texto, hidden, icon, id) => ({
  type: "HANDLE_ADD_FUNC",
  payload: { active, color, texto, hidden, icon, id },
});

export function changeNameGroup(value) {
  return {
    type: "SET_NAME_GROUP",
    payload: { value },
  };
}

export function changeDescGroup(value) {
  return {
    type: "SET_DESC_GROUP",
    payload: { value },
  };
}

export function setProceduresItensGroups(value) {
  return {
    type: "SET_PROCEDURES_ITENS_GROUPS",
    payload: { value },
  };
}

export const delItemProcedure = (id, event) => ({
  type: "DEL_ITEM_PROCEDURE",
  payload: { id },
});

export const changeObs = (index, value) => ({
  type: "CHANGE_OBS",
  payload: { index, value },
});

export function saveProceduresGroups(
  doctor,
  nameGroupValue,
  descGroupValue,
  proceduresSelected,
  idEdit
) {
  return {
    type: "CAD_PROCEDURES_GROUPS",
    payload: {
      doctor,
      nameGroupValue,
      descGroupValue,
      proceduresSelected,
      idEdit,
    },
  };
}

export const requestListDoctorProceduresGroups = (doctor, user_address) => ({
  type: "REQUEST_LIST_DOCTOR_PROCEDURES_GROUP",
  payload: { doctor, user_address },
});

export function delGroup(id, event) {
  return {
    type: "DEL_GROUP_PROCEDURES",
    payload: { id, event },
  };
}

export function closeModalDeleteSuccess() {
  return {
    type: "CLOSE_MODAL_DELETE_SUCCESS",
  };
}

export function getDataGroupForEdit(id) {
  return {
    type: "GET_DATA_GROUP_PROCEDURES_FOR_EDIT",
    payload: { id },
  };
}
