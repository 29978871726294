const INITIAL_STATE = {
  id: "",
  patient: "",
  prof_resp: "",
  start: "",
  finish: false,
  modal_finish: false,
  modal_history: false,
  modal_correction: false,
  actual_form: "",
  modal_pause: false,
  continue: false,
  toApprove: false,
  saved: {
    id: "",
    aga: "0",
    forward: "0",
    painScale: "0",
    examsRequest: "0",
    prescription: "0",
    prescriptionManipulated: "0",
    complainsts: "0",
    systemsReview: "0",
    registerInitial: "0",
    registerAttendance: "0",
    diagnostic: "0",
    procedure: "0",
    physicalAssessment: "0",
    diagnosticIntegral: "0",
    eog: "0",
    generalObjective: "0",
    periodotogram: "0",
    plateIndex: "0",
    sae: "0",
    prescriptionTranscription: "0",
    circumferences: "0",
    folds: "0",
    conduct: "0",
    foodReminder: "0",
    vaccines: "0",
    familyHistory: "",
    allergyDiseases: "0",
    allergyFood: "",
    allergyChemicals: "",
    allergyDrug: "0",
    anthropometry: "0",
    vitalSigns: "0",
    childDisease: "0",
    personalHistory: "0",
    attestation: "0",
    defect: "0",
    healthy_habits: "0",
    physical_activitie: "0",
    schedule_pause: "0",
    result_exam: "0",
    meals: "0",
    treatment: "0",
    text_conduct_guidance: "0",
    drugs: "0",
    av_pos_covid: "0",
    rehabilitation: "0",
    session: "0",
    physical_evaluation: "0",
    exam_physical: "0",
    motor_evaluation: "0",
    postural_evaluation: "0",
    obstetric: "0",
    prenatal: "0",
  },
  triage: "0",
  covid: false,
  service_id: "",
};

export default function appointmentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "INIT_APPOINTMENT":
      return {
        ...state,
      };
    case "SET_DATA_APPOINTMENT":
      return {
        ...state,
        patient: action.patient,
        prof_resp: action.prof_resp,
        triage: action.triage,
        covid: action.covid,
        service_id: action.service_id,
      };
    case "SET_START_APPOINTMENT":
      return {
        ...state,
        start: action.start,
      };
    case "SET_INIT_APPOINTMENT":
      return {
        ...state,
        id: action.payload.id,
        saved: action.payload.saved
          ? {
              ...state.saved,
              id: action.payload.saved,
              [action.payload.saved_form]: "1",
            }
          : state.saved,
      };
    case "VIEW_HISTORY":
      return {
        ...state,
        modal_history: true,
        actual_form: action.form,
      };
    case "CLOSE_HISTORY":
      return {
        ...state,
        modal_history: false,
      };
    case "FINISH_APPOINTMENT":
      return {
        ...state,
      };
    case "FINISH_APPOINTMENT_SUCCESS":
      return {
        ...INITIAL_STATE,
        prof_resp: state.prof_resp,
        saved: {
          id: "",
          aga: "0",
          forward: "0",
          painScale: "0",
          examsRequest: "0",
          prescription: "0",
          prescriptionManipulated: "0",
          complainsts: "0",
          systemsReview: "0",
          registerInitial: "0",
          registerAttendance: "0",
          diagnostic: "0",
          procedure: "0",
          physicalAssessment: "0",
          diagnosticIntegral: "0",
          eog: "0",
          generalObjective: "0",
          periodotogram: "0",
          plateIndex: "0",
          sae: "0",
          prescriptionTranscription: "0",
          circumferences: "0",
          folds: "0",
          conduct: "0",
          prescriptionCare: "0",
          treatment: "0",
        },
        finish: true,
        covid: state.covid,
      };
    case "FINISH_APPOINTMENT_FAILED":
      return {
        ...state,
        modal_finish: false,
      };
    case "VIEW_MODAL_FINISH":
      return {
        ...state,
        modal_finish: true,
      };
    case "CLOSE_MODAL_FINISH":
      return {
        ...state,
        modal_finish: false,
      };
    case "STOP_REDIRECT":
      return {
        ...state,
        finish: false,
      };
    case "VIEW_MODAL_STOP":
      return {
        ...state,
        modal_pause: true,
        toApprove: action.payload,
      };
    case "CLOSE_MODAL_STOP":
      return {
        ...state,
        modal_pause: false,
      };
    case "VIEW_MODAL_CORRECTION":
      return {
        ...state,
        modal_correction: true,
        toApprove: action.payload,
      };
    case "CLOSE_MODAL_CORRECTION":
      return {
        ...state,
        modal_correction: false,
      };
    case "SET_CONTINUE":
      return {
        ...state,
        continue: action.payload,
      };
    case "RESET_APPOINTMENT":
      return {
        ...INITIAL_STATE,
        prof_resp: state.prof_resp,
        covid: state.covid,
      };
    case "SET_ACTIONS_APPOINTMENT":
      return {
        ...state,
        saved: action.payload,
      };
    case "SET_SAVED_ACTIONS":
      let aux = { ...state.saved };
      aux[action.payload.field] = "1";
      aux["id"] = action.payload.id;
      return {
        ...state,
        saved: aux,
      };
    case "CHANGE_PROF_RESP": {
      return {
        ...state,
        prof_resp: action.prof_resp,
      };
    }
    default:
      return state;
  }
}
