import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { getUrlPhoto } from "services/functions";

const useStyles = makeStyles((theme) => ({
  userName: {
    marginBottom: "2px",
    fontWeight: "400",
  },
  userDescription: {
    fontSize: "13px",
    marginBottom: "0",
    color: "grey",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const ComponentsProfile = ({ id, nameProfile, subTitle, photo, onClick }) => {
  const classes = useStyles();
  return (
    <div
      className="d-flex col-12 p-0 avatar-text mr-auto"
      onClick={() => (onClick ? onClick() : null)}
    >
      <Avatar
        className={`mr-2 ${classes.large}`}
        alt={nameProfile}
        src={getUrlPhoto(id, photo)}
      />
      <div>
        <span className={`${classes.userName}`}>{nameProfile}</span>
        <p className={`${classes.userDescription}`}>{subTitle}</p>
      </div>
    </div>
  );
};

export default ComponentsProfile;
