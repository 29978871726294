import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import api from "services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";

const styles = {
  HeaderTable: {
    fontSize: 16,
  },
};
class TableHistory extends Component {
  state = {
    record: [],
    rowsPerPage: 10,
    page: 0,
  };

  componentDidMount() {
    api
      .post(`/microservice/consult/getChildHistory/`, {
        patient: this.props.patient,
      })
      .then((res) => {
        this.setState({ record: res.data.record });
      })
      .catch(() => {});
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { rowsPerPage, page } = this.state;
    const { classes } = this.props;
    const { record } = this.state;

    return record ? (
      <div
        className="col-12"
        style={{
          minWidth: "650",
          overflowX: "auto",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.HeaderTable}>Doenças</TableCell>
              <TableCell className={classes.HeaderTable}>Observações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.alias_description
                      ? row.alias_description
                      : row.description}
                  </TableCell>
                  <TableCell>
                    {row.additional_considerations
                      ? row.additional_considerations
                      : "---"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={record.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Registros por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `Mostrando ${from} - ${to} de ${count}`
                }
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    ) : (
      <p
        style={{
          textAlign: "center",
          color: "#a6a6a6",
          fontStyle: "italic",
        }}
      >
        Nenhum Registro Encontrado
      </p>
    );
  }
}

TableHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableHistory);
