import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTagsFromPatient,
  excluirTagFromPatient,
} from "actions/consult/Tags";
import {
  IconButton,
  Tooltip,
  Chip,
  Avatar,
  Button,
  Collapse,
} from "@material-ui/core";
import AddTags from "./AddTags";

export default function Tags({ type }) {
  const [state, setState] = React.useState({
    open: false,
    show: true,
    btn_icon: "zmdi zmdi-chevron-down zmdi-hc-lg mr-2",
  });

  const patient = useSelector((state) =>
    type == "consult" ? state.appointment.patient : state.admission.patient
  );
  const address = useSelector((state) => state.auth.address);
  const tags = useSelector((state) => state.tags.tags_from_patient);
  const dispatch = useDispatch();

  const handleRequestClose = () => {
    setState({ ...state, open: false });
  };

  const handleClickOpen = () => {
    setState({ ...state, open: true });
  };

  const handleShow = () => {
    if (state.show) {
      setState({
        ...state,
        show: false,
        btn_icon: "zmdi zmdi-chevron-up zmdi-hc-lg mr-2",
      });
    } else {
      setState({
        ...state,
        show: true,
        btn_icon: "zmdi zmdi-chevron-down zmdi-hc-lg mr-2",
      });
    }
  };

  const handleRequestDelete = (id) => {
    dispatch(excluirTagFromPatient(id, patient, address));
  };

  useEffect(() => {
    if (patient && tags.length === 0) {
      dispatch(getTagsFromPatient(patient, address));
    }
  }, [patient]);

  useEffect(() => {
    if (tags.length === 0 && state.show) {
      setState({ ...state, show: false });
    }
  }, [tags]);

  return (
    <div className="col-md-12 ml-3">
      <small>Tags: </small>
      {/* <div style={{ width: "100%", padding: "10px", paddingBottom: "0px" }}>
        <Button
          className="jr-btn jr-btn-xs"
          onClick={handleShow}
          fullWidth
          style={{ color: "#fff" }}
        >
          <span>Tags</span>
          <i className={state.btn_icon} />
        </Button>
      </div>
      {state.show ? ( */}
      {/* <Collapse in={state.show} style={{ padding: "0 20px" }}> */}
      {tags.length > 0
        ? tags.map((tag) => (
            <Tooltip title={tag.name}>
              <Chip
                key={tag.id}
                style={{
                  margin: "1px",
                  backgroundColor: tag.color,
                  color: "#fff",
                }}
                // avatar={<Avatar style={{ backgroundColor: tag.color }} />}
                size="small"
                // label={tag.name}
                onDelete={() => handleRequestDelete(tag.id_has_tag)}
              />
            </Tooltip>
          ))
        : null}

      <Tooltip title="Adicionar Tags">
        <IconButton
          arial-label="Adicionar Tags"
          onClick={handleClickOpen}
          color="primary"
        >
          <i className="zmdi zmdi-plus zmdi-fw" style={{ color: "#e0e0e0" }} />
        </IconButton>
      </Tooltip>
      {/* </Collapse> */}
      {/* ) : null} */}
      <AddTags
        open={state.open}
        patient={patient}
        handleRequestClose={handleRequestClose}
      />
    </div>
  );
}
