import React from "react";
import MaskedInput from "react-text-mask";

const MaskCpf = (props) => {
  let { inputRef, ...others } = props;

  return props.value.replace(/\D+/g, "").length <= 11 ? (
    <MaskedInput
      {...others}
      placeholderChar={"\u2000"}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ".",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ".",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
    />
  ) : (
    <MaskedInput
      {...others}
      placeholderChar={"\u2000"}
      mask={[
        /[0-9]/,
        /[0-9]/,
        ".",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ".",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "/",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
      ]}
    />
  );
};

export default MaskCpf;
