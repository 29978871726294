import { all, takeLatest, put, call } from "redux-saga/effects";
import api from "services/api";

const getDataPersonal = async (patient, address) => {
  try {
    const response = await api.post(`/microservice/consult/getDataPatient/`, {
      user: patient,
      address
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetPersonalData(action) {
  try {
    const response = yield call(
      getDataPersonal,
      action.patient,
      action.address
    );
    yield put({
      type: "PERSONAL_DATA_CONSULT",
      payload: {
        basic: response.basic,
        legacy: response.legacy,
        address: response.address[0],
        childrens: response.childrens[0],
        emergencyContacts: response.emergencyContacts[0],
        plansHealth: response.plansHealth[0],
        recreations: response.recreation,
        loaded: true
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

export default function* rootSaga() {
  yield all([takeLatest("GET_PERSONAL_DATA_CONSULT", asyncGetPersonalData)]);
}
