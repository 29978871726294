import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  avatar: {
    height: "70px",
    width: "70px",
    margin: "1rem",
  },
}));
export default function DoctorDatails({
  nome,
  photo,
  specialitys,
  council,
  date,
  address,
  area,
}) {
  const classes = useStyles();
  return (
    <div className={`row ${classes.root}`}>
      <Avatar alt={nome} className={classes.avatar} src={photo} />
      <div style={{ display: "grid" }}>
        {nome && (
          <span>
            <b className="font-weight-semibold">Nome: </b>
            {nome}
          </span>
        )}
        {area && (
          <span>
            <b className="font-weight-semibold">Area: </b>
            {area}
          </span>
        )}
        {specialitys && (
          <span>
            <b className="font-weight-semibold">Especialidade: </b>
            {specialitys}
          </span>
        )}
        {council && (
          <span>
            <b className="font-weight-semibold">Conselho: </b>
            {council}
          </span>
        )}
        {date && (
          <span>
            <b className="font-weight-semibold">Data: </b>
            {date}
          </span>
        )}
        {address && (
          <span>
            <b className="font-weight-semibold">Local: </b>
            {address}
          </span>
        )}
      </div>
    </div>
  );
}
