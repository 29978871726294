import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Tooltip,
  IconButton,
  Avatar,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
} from "@material-ui/core";
import Api from "services/api";
import EditObservation from "./EditObservation";
import EditTypeEvent from "./EditTypeEvent";
import EditHourEvent from "./EditHourEvent";
import EditStatus from "./EditStatus";
import EditHealthPlan from "./EditHealthPlan";
import moment from "moment";
import CircularProgress from "components/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert";
import TakePhotoWithCropper from "components/Forms/TakePhotoWithCropper";
import FormFinance from "./FormFinance";
import { BtnSave } from "components/Button";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import { showMsg } from "services/functions";

function ViewEvent({ event, close, getEvents }) {
  const [state, setState] = useState({});
  const [edit, setEdit] = useState({
    hour: false,
    status: false,
    type_event: false,
    health_plan: false,
    observation: false,
    finance: false,
  });
  const [data_more_user, setDataMoreUser] = useState("");
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [take_photo, setTakePhoto] = useState(false);
  const [imgPatient, setImgPatient] = useState("");
  const user = useSelector((state) => state.auth.authUser);
  const config = useSelector((state) => state.calendar.config);
  const dispatch = useDispatch();

  useEffect(() => {
    Api.post(`/ms_system/calendar/getInfoEvent/`, { event }).then((res) => {
      setState(res.data);
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (state.status === "3" && !state.arrival) {
      setState({ ...state, arrival: moment().format("HH:mm") });
    }
    if (state.status === "4" && !state.entered) {
      setState({ ...state, entered: moment().format("HH:mm") });
    }
    if (state.status === "5" && !state.departure) {
      setState({ ...state, departure: moment().format("HH:mm") });
    }
  }, [state.status]);

  function cancelEvent() {
    if (state.multiple) {
      if (data_more_user) {
        Api.post(`/ms_system/calendar/cancelPatientInEventMultiple/`, {
          event: data_more_user.id,
          user,
          config,
        }).then(() => {
          let aux = state.record.filter((row) => row.id !== data_more_user.id);
          setState({ ...state, record: aux });
          setModalDelete(false);
          setSuccessDelete(true);
          getEvents();
          // close();
        });
      } else {
        Api.post(`/ms_system/calendar/cancelEventMultiple/`, {
          event,
          user,
          config,
        }).then(() => {
          getEvents();
          close();
        });
      }
    } else {
      Api.post(`/ms_system/calendar/cancelEvent/`, {
        event,
        user,
        config,
      }).then(() => {
        getEvents();
        close();
      });
    }
  }

  function getDisplayStatus(value) {
    switch (value) {
      case "1":
        return (
          <span>
            <i className="zmdi zmdi-calendar ml-1" /> Agendado
          </span>
        );
      case "2":
        return (
          <span className="text-primary">
            <i className="zmdi zmdi-assignment-check ml-1" /> Confirmado
          </span>
        );
      case "3":
        return (
          <span style={{ color: "#ff9800" }}>
            <i className="zmdi zmdi-time ml-1" /> Aguardando atendimento
          </span>
        );
      case "4":
        return (
          <span style={{ color: "#6f42c1" }}>
            <i className="zmdi zmdi-spinner ml-1" /> Em atendimento
          </span>
        );
      case "5":
        return (
          <span style={{ color: "#4caf50" }}>
            <i className="zmdi zmdi-check-all ml-1" /> Finalizado
          </span>
        );
      case "6":
        return (
          <span style={{ color: "#bc0003" }}>
            {" "}
            <i className="zmdi zmdi-block-alt ml-1" /> Não compareceu
          </span>
        );
      default:
        break;
    }
  }

  function eventBloqued() {
    return (
      <>
        <div className="col-12 f-date">
          <strong>{state.title}</strong>
        </div>
        <div className="col-12 mt-3 f-date">
          <strong>Data:</strong>{" "}
          <span className="text-primary">
            {moment(state.start).format("DD/MM/YYYY HH:mm")} às{" "}
            {moment(state.end).format("HH:mm")}
          </span>
          <Tooltip title="Editar" placement="top">
            <i
              className="zmdi zmdi-edit zmdi-hc-md text-primary ml-3"
              onClick={() => setEdit({ ...edit, hour: true })}
            />
          </Tooltip>
        </div>
        <div className="col-12 mt-3 f-date">
          <strong>Observações:</strong>{" "}
          {state.description ? state.description : "---"}{" "}
          <Tooltip title="Editar" placement="top">
            <i
              className="zmdi zmdi-edit zmdi-hc-md text-primary ml-3"
              onClick={() => setEdit({ ...edit, observation: true })}
            />
          </Tooltip>
        </div>
      </>
    );
  }

  function onePatient() {
    return (
      <>
        <div className="col-md-1 col-xs-3">
          <Tooltip title="Alterar foto" placement="bottom">
            <img
              src={imgPatient ? imgPatient : state.patient_photo}
              alt={state.patient_name}
              className="rounded-circle img-responsive"
              style={{ width: "60px", height: "60px", cursor: "pointer" }}
              onClick={() => setTakePhoto(true)}
            />
          </Tooltip>
        </div>
        <div className="col-md-11 col-xs-9 pl-0 f-date">
          <strong>{state.patient_name}</strong>
          <br />
          {state.patient_mail}
          <br />
          {state.patient_phone}
        </div>
        <div className="col-12 mt-3 f-date">
          <strong>Data:</strong>{" "}
          <span className="text-primary">
            {moment(state.start).format("DD/MM/YYYY HH:mm")} às{" "}
            {moment(state.end).format("HH:mm")}
          </span>
          <Tooltip title="Editar" placement="top">
            <i
              className="zmdi zmdi-edit zmdi-hc-md text-primary ml-3"
              onClick={() => setEdit({ ...edit, hour: true })}
            />
          </Tooltip>
        </div>
        {/* <div className="col-12 mt-3 f-date"> */}
        <div className="col-md-1 mt-3 f-date">
          <strong>Status:</strong>
        </div>
        <div className="col-md-4 mt-3 f-date">
          <TextField
            autoFocus
            id="status"
            select
            value={state.status}
            onChange={(e) => {
              setState({ ...state, status: e.target.value, edit_status: true });
            }}
            fullWidth
          >
            <MenuItem key="1" value="1">
              <span>
                <i className="zmdi zmdi-calendar ml-1" /> Agendado
              </span>
            </MenuItem>
            <MenuItem key="2" value="2">
              <span className="text-primary">
                <i className="zmdi zmdi-assignment-check ml-1" /> Confirmado
              </span>
            </MenuItem>
            <MenuItem key="3" value="3">
              <span style={{ color: "#ff9800" }}>
                <i className="zmdi zmdi-time ml-1" /> Aguardando atendimento
              </span>
            </MenuItem>
            <MenuItem key="4" value="4">
              <span style={{ color: "#6f42c1" }}>
                <i className="zmdi zmdi-spinner ml-1" /> Em atendimento
              </span>
            </MenuItem>
            <MenuItem key="5" value="5">
              <span style={{ color: "#4caf50" }}>
                <i className="zmdi zmdi-check-all ml-1" /> Finalizado
              </span>
            </MenuItem>
            <MenuItem key="6" value="6">
              <span style={{ color: "#bc0003" }}>
                {" "}
                <i className="zmdi zmdi-block-alt ml-1" /> Não compareceu
              </span>
            </MenuItem>
          </TextField>
        </div>

        {state.status === "3" && (
          <div className="col-md-3 f-date">
            <TextField
              type="time"
              id="value"
              label="Horário chegada:"
              value={state.arrival}
              onChange={(e) =>
                setState({
                  ...state,
                  arrival: e.target.value,
                  edit_status: true,
                })
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        )}
        {state.status === "4" && (
          <div className="col-md-3 f-date">
            <TextField
              type="time"
              id="value"
              label="Entrada consultório:"
              value={state.entered}
              onChange={(e) =>
                setState({
                  ...state,
                  entered: e.target.value,
                  edit_status: true,
                })
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        )}
        {state.status === "5" && (
          <div className="col-md-3 f-date">
            <TextField
              type="time"
              id="value"
              label="Horário saída:"
              value={state.departure}
              onChange={(e) =>
                setState({
                  ...state,
                  departure: e.target.value,
                  edit_status: true,
                })
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        )}
        {state.edit_status && (
          <div className="col-md-3 mt-3">
            <BtnSave
              title={"Salvar"}
              onClick={() => saveStatus()}
              float="left"
            />
          </div>
        )}
        <div className="col-12 mt-3 f-date">
          <strong>Tipo do agendamento:</strong> {state.type_event}{" "}
          <Tooltip title="Editar" placement="top">
            <i
              className="zmdi zmdi-edit zmdi-hc-md text-primary ml-3"
              onClick={() => setEdit({ ...edit, type_event: true })}
            />
          </Tooltip>
        </div>
        <div className="col-12 mt-3 f-date">
          <strong>Faturamento:</strong> {state.health_plan_name}{" "}
          {state.health_plan_card && (
            <>
              <strong className="ml-2">Carteirinha:</strong>{" "}
              {state.health_plan_card}
            </>
          )}
          <Tooltip title="Editar" placement="top">
            <i
              className="zmdi zmdi-edit zmdi-hc-md text-primary ml-3"
              onClick={() => setEdit({ ...edit, health_plan: true })}
            />
          </Tooltip>
          {/* Deixar comentado enquato financeiro nao esta em prod */}
          {/* <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-xs bg-success ml-3"
            // style={{ float: "left" }}
            onClick={() => setEdit({ ...edit, finance: true })}
          >
            <i className="zmdi zmdi-money zmdi-hc-lg" />
            <span> Faturar </span>
          </Button> */}
        </div>
        <div className="col-12 mt-3 f-date">
          <strong>Observações:</strong>{" "}
          {state.description ? state.description : "---"}{" "}
          <Tooltip title="Editar" placement="top">
            <i
              className="zmdi zmdi-edit zmdi-hc-md text-primary ml-3"
              onClick={() => setEdit({ ...edit, observation: true })}
            />
          </Tooltip>
        </div>
      </>
    );
  }

  function saveStatus() {
    let opt =
      state.status === "3"
        ? "arrival"
        : state.status === "4"
        ? "entered"
        : state.status === "5"
        ? "departure"
        : "";
    Api.post(`/ms_system/calendar/editStatusEvent/`, {
      multiple: state.multiple,
      event: state.multiple ? data_more_user.id : state.event,
      status: state.status,
      opt,
      arrival: state.arrival,
      entered: state.entered,
      departure: state.departure,
    }).then(() => {
      showMsg("success", "Salvo com sucesso!", dispatch);
      setState({ ...state, edit_status: false });
      if (state.status === "3") {
        let info = !state.multiple
          ? {
              id: state.patient_id,
              name: state.patient_name,
              photo: state.patient_photo,
            }
          : {
              id: data_more_user.patient_id,
              name: data_more_user.patient_name,
              photo: data_more_user.patient_photo,
            };

        getDataFirebase()
          .child(`waiting-room${getEnviromentFirebase()}/` + state.doctor)
          .push({
            date: moment().format("DD/MM/YYYY HH:mm"),
            patient: info.name,
            patient_id: info.id,
            photo: info.photo,
          });
      }
    });
  }

  function multiplePatient() {
    return (
      <>
        <div className="col-12 mt-3 f-date">
          <strong>Data:</strong>{" "}
          <span className="text-primary">
            {moment(state.start).format("DD/MM/YYYY HH:mm")} às{" "}
            {moment(state.end).format("HH:mm")}
          </span>
          <Tooltip title="Editar" placement="top">
            <i
              className="zmdi zmdi-edit zmdi-hc-md text-primary ml-3"
              onClick={() => setEdit({ ...edit, hour: true })}
            />
          </Tooltip>
        </div>
        <div className="col-12 mt-3 f-date">
          <strong>Tipo do agendamento:</strong> {state.type_event}{" "}
          <Tooltip title="Editar" placement="top">
            <i
              className="zmdi zmdi-edit zmdi-hc-md text-primary ml-3"
              onClick={() => setEdit({ ...edit, type_event: true })}
            />
          </Tooltip>
        </div>
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> Paciente(s) </TableCell>
                <TableCell>Faturamento</TableCell>
                <TableCell>Observações</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.record.map((row) => (
                <TableRow key={row.patient_id}>
                  <TableCell width="40%">
                    <div style={{ display: "flex" }}>
                      <Avatar
                        alt={row.patient_name}
                        src={row.patient_photo}
                        size="40"
                      />
                      <span style={{ margin: "auto 10px" }}>
                        {row.patient_name} <br />
                        <small>{row.patient_phone}</small>
                        <br />
                        <small>{row.patient_mail}</small>
                      </span>
                    </div>
                  </TableCell>
                  <TableCell width="30%">
                    {row.health_plan_name}
                    {row.health_plan_card && (
                      <>
                        <strong className="ml-2">Carteirinha:</strong>{" "}
                        {row.health_plan_card}
                      </>
                    )}
                    <Tooltip title="Editar" placement="top">
                      <i
                        className="zmdi zmdi-edit zmdi-hc-md text-primary ml-3"
                        onClick={() => {
                          setDataMoreUser(row);
                          setEdit({ ...edit, health_plan: true });
                        }}
                      />
                    </Tooltip>
                    {/* Deixar comentado enquanto financeiro nao esta em prd */}
                    {/* <Button
                      color="primary"
                      variant="contained"
                      className="jr-btn jr-btn-xs bg-success mt-1"
                      // style={{ float: "left" }}
                      onClick={() => {
                        setDataMoreUser(row);
                        setEdit({ ...edit, finance: true });
                      }}
                    >
                      <i className="zmdi zmdi-money zmdi-hc-lg" />
                      <span> Faturar </span>
                    </Button> */}
                  </TableCell>
                  <TableCell width="30%">
                    {row.observation ? row.observation : "---"}
                  </TableCell>
                  <TableCell width="20%">
                    {getDisplayStatus(row.status)}
                    <Tooltip title="Editar status" placement="top">
                      <i
                        className="zmdi zmdi-edit zmdi-hc-md text-primary ml-3"
                        onClick={() => {
                          setDataMoreUser(row);
                          setEdit({ ...edit, status: true });
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell width="10%">
                    <Tooltip title="Desmarcar este paciente" placement="top">
                      <IconButton
                        onClick={() => {
                          setDataMoreUser(row);
                          setModalDelete(true);
                        }}
                        color="secondary"
                        aria-label="Remover"
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </>
    );
  }

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="text-primary font-weight-semibold">
            Informações do agendamento
            <Tooltip title="Fechar" placement="top">
              <IconButton
                aria-label="Fechar"
                onClick={() => close()}
                style={{ float: "right", marginTop: "-10px" }}
              >
                <i className="zmdi zmdi-close" />
              </IconButton>
            </Tooltip>
          </h2>
        </div>

        {state.bloqued
          ? eventBloqued()
          : state.multiple
          ? multiplePatient()
          : onePatient()}
        <div className="col-md-12 mt-3">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            style={{ float: "left" }}
            onClick={() => {
              setDataMoreUser("");
              setModalDelete(true);
            }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Desmarcar </span>
          </Button>
          {!state.bloqued && state.multiple && (
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "right", marginLeft: "10px" }}
              onClick={() =>
                dispatch({ type: "ALTER_MODAL_TYPE", modal_type: "add" })
              }
            >
              <i className="zmdi zmdi-plus zmdi-hc-lg" />
              <span> Agendar outros pacientes </span>
            </Button>
          )}
        </div>
        {edit.observation && (
          <EditObservation
            event={state.event}
            value={state.description}
            edit={(res) => {
              setState({ ...state, description: res });
              setEdit({ ...edit, observation: false });
            }}
            close={() => setEdit({ ...edit, observation: false })}
          />
        )}
        {edit.type_event && (
          <EditTypeEvent
            event={state.event}
            calendar={state.calendar_id}
            value={{ id: state.id_type_event, description: state.type_event }}
            edit={(id, val) => {
              setState({ ...state, id_type_event: id, type_event: val });
              setEdit({ ...edit, type_event: false });
            }}
            close={() => setEdit({ ...edit, type_event: false })}
          />
        )}
        {edit.health_plan && (
          <EditHealthPlan
            multiple={state.multiple}
            event={state.multiple ? data_more_user.id : state.event}
            calendar={state.calendar_id}
            plan={
              state.multiple ? data_more_user.health_plan : state.health_plan
            }
            plan_card={
              state.multiple
                ? data_more_user.health_plan_card
                : state.health_plan_card
            }
            edit={(id, name, card) => {
              if (!state.multiple) {
                setState({
                  ...state,
                  health_plan: id,
                  health_plan_name: name,
                  health_plan_card: card,
                });
              } else {
                let aux = state.record.map((row) =>
                  row.id === data_more_user.id
                    ? {
                        ...row,
                        health_plan: id,
                        health_plan_name: name,
                        health_plan_card: card,
                      }
                    : row
                );
                setState({ ...state, record: aux });
              }
              setEdit({ ...edit, health_plan: false });
            }}
            close={() => setEdit({ ...edit, health_plan: false })}
          />
        )}
        {edit.status && (
          <EditStatus
            multiple={state.multiple}
            event={state.multiple ? data_more_user.id : state.event}
            value={state.multiple ? data_more_user.status : state.status}
            otherVal={
              state.status === "3"
                ? state.arrival
                : state.status === "4"
                ? state.entered
                : state.status === "5"
                ? state.departure
                : ""
            }
            edit={(val, opt, valOpt) => {
              if (!state.multiple) {
                if (!opt) {
                  setState({ ...state, status: val });
                } else {
                  setState({ ...state, status: val, [opt]: valOpt });
                }
              } else {
                let aux = state.record.map((row) =>
                  row.id === data_more_user.id ? { ...row, status: val } : row
                );
                setState({ ...state, record: aux });
              }
              setEdit({ ...edit, status: false });
            }}
            close={() => setEdit({ ...edit, status: false })}
            doctor={state.doctor}
            info={
              !state.multiple
                ? {
                    id: state.patient_id,
                    name: state.patient_name,
                    photo: state.patient_photo,
                  }
                : {
                    id: data_more_user.patient_id,
                    name: data_more_user.patient_name,
                    photo: data_more_user.patient_photo,
                  }
            }
          />
        )}
        {edit.hour && (
          <EditHourEvent
            event={state.event}
            user={user}
            date={moment(state.start).format("YYYY-MM-DD")}
            start={moment(state.start).format("HH:mm")}
            end={moment(state.end).format("HH:mm")}
            edit={(st, ed) => {
              setState({ ...state, start: st, end: ed });
              setEdit({ ...edit, hour: false });
              getEvents();
            }}
            close={() => setEdit({ ...edit, hour: false })}
          />
        )}
        {edit.finance && (
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={edit.finance}
            onClose={() => setEdit({ ...edit, finance: false })}
            aria-labelledby="max-width-dialog-title"
            scroll="body"
          >
            <DialogContent>
              <FormFinance
                id={state.multiple ? data_more_user.id : state.event}
                multiple={state.multiple}
                close={() => setEdit({ ...edit, finance: false })}
              />
            </DialogContent>
          </Dialog>
        )}

        {/* Desmarcar consulta */}
        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, desmarcar"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title={
            data_more_user
              ? "Desmarcar este paciente?"
              : "Desmarcar este horário?"
          }
          onConfirm={() => cancelEvent()}
          onCancel={() => setModalDelete(false)}
        />
        <SweetAlert
          show={success_delete}
          success
          title={
            state.multiple ? "Paciente desmarcado!" : "Consulta Desmarcada!"
          }
          onConfirm={() => setSuccessDelete(false)}
        />
        <TakePhotoWithCropper
          open={take_photo}
          handleClose={() => setTakePhoto(false)}
          imageReceive={(img) => {
            setImgPatient(img);
            dispatch({
              type: "ALTER_PHOTO",
              payload: { patient: state.patient_id, image: img },
            });
          }}
        />
      </div>
    );
  }
}

export default ViewEvent;
