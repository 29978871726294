import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
import Api from "services/api";
import MaskCep from "components/Masks/MaskCep";
import {
  Button,
  Grid,
  Switch,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
  IconButton,
  Tooltip,
  Input,
  InputAdornment,
} from "@material-ui/core";
import SearchHealthPlan from "../SearchHealthPlan";
import SearchContact from "../SearchContact";
import MaskCellphone from "components/Masks/MaskCellphone";
import MaskPhone from "components/Masks/MaskPhone";
import { SketchPicker } from "react-color";
import { MultipleSelect } from "react-select-material-ui";

function ViewWorkplace({ addressId, close }) {
  const [state, setState] = useState({
    name: "",
    time_alert: "",
    home_visit: false,
    website: "",
    postalcode: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    country: "",
    hours: [
      {
        id: "",
        day: "",
        start: "08:00",
        end: "18:00",
      },
    ],
    plans: [
      {
        id: "",
        name: "",
        return_days: "",
      },
    ],
    agreements: [
      {
        id: "",
        name: "",
        beneficy: "",
      },
    ],
    contacts: [
      {
        id: "",
        fk_ls_user: "",
        name: "",
        phone1: "",
        phone2: "",
        cellphone: "",
        whatsapp: "",
        fax: "",
        email: "",
      },
    ],
    error: false,
  });
  const [typesEvent] = useState([]);
  const [typesSelected, setTypesSelected] = useState([]);
  const [colorEvent, setColorEvent] = useState("#177493");
  const [displayColorEvent, setDisplayColorEvent] = useState(false);
  const [textColor, setTextColor] = useState("#ffffff");
  const [displayTextEvent, setDisplayTextEvent] = useState(false);
  const [online_scheduling, setOnlineScheduling] = useState(false);
  const [number_patients, setNumberPatients] = useState(1);
  const [calendarId, setCalendarId] = useState("");

  useEffect(() => {
    Api.post(`/ms_system/workplace/getInfoWorkplace/`, { addressId }).then(
      (res) => {
        const {
          address,
          contacts,
          plans,
          hours,
          agreements,
          calendar,
          event_types,
        } = res.data;
        let obj = {
          ...state,
          ...address[0],
          hours,
          calendarId,
        };

        if (agreements.length > 0) obj = { ...obj, agreements };
        if (contacts.length > 0) obj = { ...obj, contacts };
        if (plans.length > 0) obj = { ...obj, plans };
        let typesSel = event_types.map((row) => row.id);
        setState(obj);
        setColorEvent(calendar[0].color);
        setTextColor(calendar[0].text_color);
        setOnlineScheduling(calendar[0].online_scheduling);
        setNumberPatients(calendar[0].number_patient);
        setCalendarId(calendar[0].id);
        setTypesSelected(typesSel);
      }
    );
  }, []);

  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <h2 className="text-primary font-weight-semibold">
          Visualizar Local de Atendimento
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
      </div>
      <div className="col-md-12 col-12">
        <Grid component="label" container alignItems="center">
          <Grid item>
            <span
              style={{
                color: "#0000008a",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "1.3125rem",
              }}
            >
              Visita em domícilio:
            </span>
          </Grid>
          <Grid item>
            <Switch
              color="primary"
              checked={state.home_visit}
              onChange={(e) => {}}
              aria-label="checked"
            />
          </Grid>
        </Grid>
      </div>
      <div className="col-md-12 col-12">
        <TextField
          id="name"
          readOnly
          label="Descrição:"
          value={state.name}
          onChange={(e) => {}}
          fullWidth
          error={state.error && !state.name ? true : false}
        />
      </div>
      <div className="col-md-4 col-12">
        <FormControl className="my-3" fullWidth>
          <FormHelperText>Tempo alerta paciente esperando:</FormHelperText>
          <Input
            id="time_alert"
            type="number"
            value={state.time_alert}
            onChange={(e) => {}}
            endAdornment={<InputAdornment position="end">Min</InputAdornment>}
          />
        </FormControl>
      </div>
      <div className="col-md-8 col-12">
        <FormControl className="my-3" fullWidth>
          <FormHelperText>Website:</FormHelperText>
          <Input
            id="website"
            value={state.website}
            onChange={(e) => {}}
            startAdornment={
              <InputAdornment position="start">https://</InputAdornment>
            }
          />
        </FormControl>
      </div>

      <div className="col-12">
        <h3 className="font-weight-semibold mt-3">Endereço</h3>
        <hr />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="postalcode"
          label="CEP"
          margin="normal"
          className="mt-0"
          fullWidth
          InputProps={{
            inputComponent: MaskCep,
            value: state.postalcode,
            onChange: (e) => {},
          }}
        />
      </div>
      <div className="col-md-8 col-12 ">
        <TextField
          id="address"
          label="Logradouro"
          value={state.address}
          margin="normal"
          className="mt-0"
          fullWidth
          onChange={(e) => {}}
          error={state.error && !state.address ? true : false}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="number"
          label="Número"
          value={state.number}
          margin="normal"
          fullWidth
          onChange={(e) => {}}
          error={state.error && !state.number ? true : false}
        />
      </div>
      <div className="col-md-4 col-12">
        <TextField
          id="complement"
          label="Complemento"
          value={state.complement}
          margin="normal"
          fullWidth
          onChange={(e) => {}}
        />
      </div>
      <div className="col-md-4 col-12">
        <TextField
          id="neighborhood"
          label="Bairro"
          value={state.neighborhood}
          margin="normal"
          fullWidth
          onChange={(e) => {}}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="city"
          label="Cidade"
          value={state.city}
          margin="normal"
          fullWidth
          InputProps={{ readOnly: state.postalcode ? true : false }}
          onChange={(e) => {}}
          error={state.error && !state.city ? true : false}
        />
      </div>
      <div className="col-md-4 col-12">
        <TextField
          id="state"
          label="Estado"
          value={state.state}
          margin="normal"
          fullWidth
          InputProps={{ readOnly: state.postalcode ? true : false }}
          onChange={(e) => {}}
          error={state.error && !state.state ? true : false}
        />
      </div>
      <div className="col-md-4 col-12">
        <TextField
          id="country"
          label="País"
          value={state.country}
          margin="normal"
          fullWidth
          InputProps={{ readOnly: state.postalcode ? true : false }}
          onChange={(e) => {}}
        />
      </div>

      <div className="col-12">
        <h3 className="font-weight-semibold mt-3">Horários de atendimento</h3>
        <hr />
      </div>
      {state.hours.map((row, index) => (
        <>
          <div className="col-md-4 col-12">
            <TextField
              id="day_week"
              select
              label="Dia da Semana:"
              value={row.day}
              onChange={(e) => {}}
              SelectProps={{}}
              fullWidth
              className="mt-0"
            >
              <MenuItem key="1" value="0">
                Domingo
              </MenuItem>
              <MenuItem key="2" value="1">
                Segunda-Feira
              </MenuItem>
              <MenuItem key="3" value="2">
                Terça-Feira
              </MenuItem>
              <MenuItem key="3" value="3">
                Quarta-Feira
              </MenuItem>
              <MenuItem key="3" value="4">
                Quinta-Feira
              </MenuItem>
              <MenuItem key="3" value="5">
                Sexta-Feira
              </MenuItem>
              <MenuItem key="3" value="6">
                Sábado
              </MenuItem>
              <MenuItem key="3" value="7">
                Segunda à Sexta
              </MenuItem>
              <MenuItem key="3" value="8">
                Todos os dias
              </MenuItem>
            </TextField>
          </div>
          <div className="col-md-3 col-12">
            <TextField
              id="day_week"
              type="time"
              label="Início:"
              value={row.start}
              onChange={(e) => {}}
              SelectProps={{}}
              fullWidth
              className="mt-0"
            />
          </div>
          <div className="col-md-3 col-12">
            <TextField
              id="day_week"
              type="time"
              label="Fim:"
              value={row.end}
              onChange={(e) => {}}
              SelectProps={{}}
              fullWidth
              className="mt-0"
            />
          </div>
          <div className="col-md-2 col-12">
            {index + 1 === state.hours.length && (
              <IconButton
                onClick={() => {
                  setState({
                    ...state,
                    hours: [
                      ...state.hours,
                      {
                        id: "",
                        day: "",
                        start: "08:00",
                        end: "18:00",
                      },
                    ],
                  });
                }}
                color="primary"
                aria-label="Adicionar"
              >
                <i className="zmdi zmdi-plus zmdi-hc-fw" />
              </IconButton>
            )}
            {state.hours.length > 1 && (
              <IconButton
                onClick={() => {}}
                color="secondary"
                aria-label="Remover"
              >
                <i className="zmdi zmdi-delete zmdi-hc-fw" />
              </IconButton>
            )}
          </div>
        </>
      ))}

      <div className="col-12">
        <h3 className="font-weight-semibold mt-3">Especificações da agenda</h3>
        <hr />
      </div>

      <div className="col-md-12 col-12">
        <MultipleSelect
          label="Serviços:"
          values={typesSelected}
          options={typesEvent}
          onChange={(e) => setTypesSelected(e)}
          SelectProps={{
            isCreatable: false,
            msgNoOptionsAvailable: "Todos serviços foram selecionados",
            msgNoOptionsMatchFilter: "Nenhum tipo de evento para este filtro",
          }}
        />
      </div>

      <div className="col-md-3 col-12 mt-3">
        <Grid component="label" container alignItems="center">
          <Grid item>
            <span
              style={{
                color: "#0000008a",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "1.3125rem",
              }}
            >
              Agendamento online:
            </span>
          </Grid>
          <Grid item>
            <Switch
              color="primary"
              checked={online_scheduling}
              onChange={(e) => setOnlineScheduling(e.target.checked)}
              aria-label="checked"
            />
          </Grid>
        </Grid>
      </div>

      <div className="col-md-3 col-12 mt-3">
        <TextField
          id="number_patients"
          type="number"
          label="Número de pacientes por horário:"
          value={number_patients}
          onChange={(e) => setNumberPatients(e.target.value)}
          fullWidth
        />
      </div>

      <div className="col-md-3 col-12 mt-3">
        <Grid
          component="label"
          container
          alignItems="center"
          style={{ marginTop: "15px" }}
        >
          <Grid item>
            <span
              style={{
                color: "#0000008a",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "1.3125rem",
              }}
            >
              Cor dos eventos:
            </span>
          </Grid>
          <Grid item>
            <div
              className="cp-swatch ml-3"
              onClick={() => setDisplayColorEvent(!displayColorEvent)}
            >
              <div
                className="cp-color"
                style={{ backgroundColor: colorEvent, width: "70px" }}
              />
            </div>
            {displayColorEvent ? (
              <div className="cp-popover">
                <div
                  className="cp-cover"
                  onClick={() => setDisplayColorEvent(!displayColorEvent)}
                />
                <SketchPicker
                  color={colorEvent}
                  onChange={(val) => setColorEvent(val.hex)}
                />
              </div>
            ) : null}
          </Grid>
        </Grid>
      </div>

      <div className="col-md-3 col-12 mt-3">
        <Grid
          component="label"
          container
          alignItems="center"
          style={{ marginTop: "15px" }}
        >
          <Grid item>
            <span
              style={{
                color: "#0000008a",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "1.3125rem",
              }}
            >
              Cor do texto dos eventos:
            </span>
          </Grid>
          <Grid item>
            <div
              className="cp-swatch ml-3"
              onClick={() => setDisplayTextEvent(!displayTextEvent)}
            >
              <div
                className="cp-color"
                style={{ backgroundColor: textColor, width: "70px" }}
              />
            </div>
            {displayTextEvent ? (
              <div className="cp-popover">
                <div
                  className="cp-cover"
                  onClick={() => setDisplayTextEvent(!displayTextEvent)}
                />
                <SketchPicker
                  color={textColor}
                  onChange={(val) => setTextColor(val.hex)}
                />
              </div>
            ) : null}
          </Grid>
        </Grid>
      </div>

      <div className="col-12 mt-3">
        <h3 className="font-weight-semibold mt-3">Planos de Saúde</h3>
        <hr />
      </div>
      {state.plans.map((row, index) => (
        <>
          <div className="col-md-6 col-12">
            <SearchHealthPlan
              healthPlan={{
                id: row.id,
                name: row.name,
              }}
              handleChangeAutocomplete={(obj) => {
                let aux = state.plans.map((row, key) =>
                  key === index
                    ? { ...row, id: obj.plan_id, name: obj.plan_name }
                    : row
                );
                setState({
                  ...state,
                  plans: aux,
                });
              }}
              error={state.error && !row.name ? true : false}
            />
          </div>
          <div className="col-md-4 col-12">
            <TextField
              id="day_week"
              type="number"
              label="Dias para retorno:"
              value={row.return_days}
              onChange={(e) => {}}
              SelectProps={{}}
              fullWidth
              className="mt-0"
            />
          </div>
          <div className="col-md-2 col-12">
            {index + 1 === state.plans.length && (
              <IconButton
                onClick={() => {}}
                color="primary"
                aria-label="Adicionar"
              >
                <i className="zmdi zmdi-plus zmdi-hc-fw" />
              </IconButton>
            )}
            {state.plans.length > 1 && (
              <IconButton
                onClick={() => {}}
                color="secondary"
                aria-label="Remover"
              >
                <i className="zmdi zmdi-delete zmdi-hc-fw" />
              </IconButton>
            )}
          </div>
        </>
      ))}

      <div className="col-12 mt-3">
        <h3 className="font-weight-semibold mt-3">Convênios</h3>
        <hr />
      </div>
      {state.agreements.map((row, index) => (
        <>
          <div className="col-md-6 col-12">
            <TextField
              id="name"
              label="Empresa:"
              value={row.name}
              onChange={(e) => {}}
              fullWidth
              className="mt-0"
            />
          </div>
          <div className="col-md-4 col-12">
            <TextField
              id="beneficy"
              label="Benefício:"
              value={row.beneficy}
              onChange={(e) => {}}
              fullWidth
              className="mt-0"
            />
          </div>
          <div className="col-md-2 col-12">
            {index + 1 === state.agreements.length && (
              <IconButton
                onClick={() => {}}
                color="primary"
                aria-label="Adicionar"
              >
                <i className="zmdi zmdi-plus zmdi-hc-fw" />
              </IconButton>
            )}
            {state.agreements.length > 1 && (
              <IconButton
                onClick={() => {}}
                color="secondary"
                aria-label="Remover"
              >
                <i className="zmdi zmdi-delete zmdi-hc-fw" />
              </IconButton>
            )}
          </div>
        </>
      ))}

      <div className="col-12 mt-3">
        <h3 className="font-weight-semibold mt-3">Contatos</h3>
        <hr />
      </div>
      {state.contacts.map((row, index) => (
        <>
          <div className="col-md-6 col-12">
            <SearchContact
              contact={{ id: row.fk_ls_user, label: row.name }}
              handleChangeAutocomplete={(obj) => {}}
              error={state.error && !row.name ? true : false}
            />
          </div>
          <div className="col-md-3 col-12">
            <TextField
              id="phone"
              label="Telefone 1:"
              margin="normal"
              fullWidth
              InputProps={{
                inputComponent: MaskPhone,
                value: row.phone1,
                onChange: (e) => {},
              }}
            />
          </div>
          <div className="col-md-3 col-12">
            <TextField
              id="phone"
              label="Telefone 2:"
              margin="normal"
              fullWidth
              InputProps={{
                inputComponent: MaskPhone,
                value: row.phone2,
                onChange: (e) => {},
              }}
            />
          </div>
          <div className="col-md-2 col-12">
            <TextField
              id="cellphone"
              label="Celular"
              margin="normal"
              fullWidth
              InputProps={{
                inputComponent: MaskCellphone,
                value: row.cellphone,
                onChange: (e) => {},
              }}
            />
          </div>
          <div className="col-md-2 col-12">
            <TextField
              id="whatsapp"
              label="Whatsapp"
              margin="normal"
              fullWidth
              InputProps={{
                inputComponent: MaskCellphone,
                value: row.whatsapp,
                onChange: (e) => {},
              }}
            />
          </div>
          <div className="col-md-2 col-12">
            <TextField
              id="fax"
              label="Fax:"
              margin="normal"
              fullWidth
              InputProps={{
                inputComponent: MaskPhone,
                value: row.fax,
                onChange: (e) => {},
              }}
            />
          </div>
          <div className="col-md-4 col-12">
            <TextField
              id="email"
              label="E-mail:"
              value={row.email}
              margin="normal"
              fullWidth
              onChange={(e) => {}}
            />
          </div>

          <div className="col-md-2 col-12" style={{ marginTop: "20px" }}>
            {index + 1 === state.contacts.length && (
              <IconButton
                onClick={() => {}}
                color="primary"
                aria-label="Adicionar"
              >
                <i className="zmdi zmdi-plus zmdi-hc-fw" />
              </IconButton>
            )}
            {state.contacts.length > 1 && (
              <IconButton
                onClick={() => {}}
                color="secondary"
                aria-label="Remover"
              >
                <i className="zmdi zmdi-delete zmdi-hc-fw" />
              </IconButton>
            )}
          </div>
          <div className="col-12">
            <hr />
          </div>
        </>
      ))}

      <div className="col-md-12 mt-3">
        <Button
          color="secondary"
          variant="contained"
          className="jr-btn jr-btn-sm"
          onClick={() => close()}
          style={{ float: "left" }}
        >
          <i className="zmdi zmdi-close zmdi-hc-lg" />
          <span> Cancelar </span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          className="jr-btn jr-btn-sm bg-success"
          onClick={() => {}}
          style={{ float: "right" }}
        >
          <i className="zmdi zmdi-check zmdi-hc-lg" />
          <span> Salvar </span>
        </Button>
      </div>
    </div>
  );
}

export default ViewWorkplace;
