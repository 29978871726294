import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import api from "services/api";
import apiFile from "services/apiFile";

// Busca padrão de prescrição
const getPatterns = async (doctor, patient) => {
  try {
    const response = await api.post(
      `/microservice/prescription/getPatternsPrescription/`,
      {
        doctor,
        patient,
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetPatterns(action) {
  try {
    const response = yield call(getPatterns, action.doctor, action.patient);
    yield put({
      type: "GET_PATTERN_SUCCESS",
      payload: {
        salutem: response.patternSalutem,
        doctor: response.patternDoctor,
        patient: response.patternPatient,
        treatments: response.workingTreatments,
      },
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

// Busca dados do padrão selecionado
const changePatterns = async (pattern, patient) => {
  try {
    const response = await api.post(
      `/microservice/prescription/changePatternPrescription/`,
      {
        pattern,
        patient,
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncChangePattern(action) {
  try {
    const response = yield call(changePatterns, action.pattern, action.patient);
    yield put({
      type: "CHANGE_PATTERN_SUCCESS",
      payload: {
        medicines: response.record,
        pattern: action.pattern,
        in_use: response.in_use,
        in_allergic: response.inAllergic,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

// Busca dados do padrão do paciente selecionado
const changePatternPatient = async (pattern, patient) => {
  try {
    const response = await api.post(
      `/microservice/prescription/changePatternPatientPrescription/`,
      {
        pattern,
        patient,
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncChangePatternPatient(action) {
  try {
    const response = yield call(
      changePatternPatient,
      action.pattern,
      action.patient
    );
    yield put({
      type: "CHANGE_PATTERN_SUCCESS",
      payload: {
        medicines: response.record,
        pattern: action.pattern,
        in_use: response.in_use,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

// Busca medicamentos em uso
const changeMedicineUse = async (patient) => {
  try {
    const response = await api.post(`/microservice/prescription/getInUse/`, {
      patient,
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncChangeMedicineUse(action) {
  try {
    const response = yield call(changeMedicineUse, action.patient);
    yield put({
      type: "CHANGE_MEDICINE_USE_SUCCESS",
      payload: {
        medicines: action.medicines,
        in_use: response.in_use,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncEditPrescription(action) {
  try {
    const response = yield call(changePatternPatient, action.payload.id);
    yield put({
      type: "EDIT_PRESCRIPTION_SUCCESS",
      payload: {
        medicines: response.record,
        prescription: action.payload.id,
        in_use: response.in_use,
        transcription: response.data_transcription,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

// SAVE PRESCRIPTION
const savePrescription = async (formData) => {
  try {
    let ret = null;
    await api
      .post(`/microservice/prescription/savePrescription/`, {
        formData,
      })
      .then(async (res) => {
        ret = res;
        if (formData.transcription) {
          if (formData.file !== "") {
            await saveFileTranscription(
              formData.file,
              res.data.prescription_id,
              res.data.patient,
              formData.data.name_doctor,
              formData.data.crm_doctor,
              res.data.saved,
              formData.data.id_transcription
            );
          } else {
            await saveTranscription(
              res.data.prescription_id,
              null,
              formData.data.name_doctor,
              formData.data.crm_doctor,
              res.data.saved,
              formData.data.id_transcription
            );
          }
        }
      });
    if (ret !== null) {
      return ret.data;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

async function saveFileTranscription(
  file,
  prescription,
  id_patient,
  name_doctor,
  crm_doctor,
  saved,
  id_transcription
) {
  let formData = new FormData();
  formData.append("prescription", prescription);
  formData.append("id_patient", id_patient);
  if (typeof file.type !== "string") {
    let block = file.split(";");
    let contentType = block[0].split(":")[1];
    let realData = block[1].split(",")[1];
    let blob = await b64toBlob(realData, contentType);
    formData.append("image", blob);
  } else {
    formData.append("image", file);
  }
  await apiFile
    .post(
      `/microservice/prescriptiontranscription/saveFileTranscription/`,
      formData
    )
    .then(async (res) => {
      await saveTranscription(
        prescription,
        res.data.file_id,
        name_doctor,
        crm_doctor,
        saved,
        id_transcription
      );
      return true;
    });
}

async function saveTranscription(
  recipe_id,
  file_id,
  name_doctor,
  crm_doctor,
  saved,
  id_transcription
) {
  let obj = {
    recipe_id,
    file_id,
    name_doctor,
    crm_doctor,
    saved,
    id_transcription,
  };

  await api
    .post(`/microservice/prescriptiontranscription/saveTranscription/`, obj)
    .then((res) => {
      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    });
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function* asyncSavePrescription(action) {
  try {
    yield put({
      type: "SET_LOAD_PRESCRIPTION",
      payload: {
        load: true,
      },
    });
    const response = yield call(savePrescription, action.formData);

    if (!action.formData.appointment_id) {
      yield put({
        type: "SET_INIT_APPOINTMENT",
        payload: {
          id: response.appointment_id,
        },
      });
    }
    if (!action.formData.medicineUse) {
      yield put({
        type: "SAVE_PRESCRIPTION_SUCCESS",
        payload: {
          prescription_id: response.prescription_id,
          pattern_id: response.pattern_id,
          prescription_name: response.name,
          name_doctor: action.formData.data.name_doctor,
          crm_doctor: action.formData.data.crm_doctor,
        },
      });
    } else {
      yield put({
        type: "SET_LOAD_PRESCRIPTION",
        payload: {
          load: false,
        },
      });
    }

    if (response.success) {
      if (response.saved !== "" && response.saved !== null) {
        yield put({
          type: "SET_SAVED_ACTIONS",
          payload: {
            field: "prescription",
            id: response.saved,
          },
        });
        if (action.formData.transcription) {
          yield put({
            type: "SET_SAVED_ACTIONS",
            payload: {
              field: "prescriptionTranscription",
              id: response.saved,
            },
          });
        }
      }
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!",
      });
      yield put({
        type: "RESET_FORM_PRESCRIPTION",
      });
      yield put({
        type: "REQUEST_FORM_MEDICINE_USE",
      });
    } else {
      yield put({
        type: "SET_LOAD_PRESCRIPTION",
        payload: {
          load: false,
        },
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!",
      });
    }
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  } catch (error) {
    yield put({
      type: "SET_LOAD_PRESCRIPTION",
      payload: {
        load: false,
      },
    });
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!",
    });
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!",
    });
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  }
}

// REMOVE PRESCRIPTION
const removePrescription = async (prescription) => {
  try {
    const response = await api.post(
      `/microservice/prescription/deletePrescription/`,
      {
        prescription,
      }
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

function* asyncRemovePrescription(action) {
  try {
    const response = yield call(removePrescription, action.prescription);

    yield put({
      type: "REMOVE_PRESCRIPTION_SUCCESS",
      payload: {
        success: response.success,
        id_prescription: action.prescription,
      },
    });

    if (response.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Removido com sucesso!",
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao remover!",
      });
    }
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!",
    });
    yield delay(5000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE",
    });
  }
}

const getGroupMedList = async (doctor, user_address) => {
  try {
    const response = await api.post(
      `/microservice/medicinesGroups/getGroupMedList/`,
      {
        doctor,
        user_address,
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetGroupMedList(action) {
  try {
    const response = yield call(
      getGroupMedList,
      action.doctor,
      action.user_address
    );
    yield put({
      type: "GET_GROUP_MED_LIST_SUCCESS",
      payload: {
        list_group: response.list_group,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

const getMedList = async (group, plusPosology, provider) => {
  try {
    const response = await api.post(
      `/microservice/medicinesGroups/getMedList/`,
      {
        group,
        plusPosology,
        provider,
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetMedList(action) {
  try {
    const response = yield call(
      getMedList,
      action.group,
      action.plusPosology,
      action.provider
    );
    yield put({
      type: "GET_MED_LIST_SUCCESS",
      payload: {
        list_med: response.list_med,
      },
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

export function* requestGetPattern() {
  yield takeLatest("GET_PATTERNS", asyncGetPatterns);
}

export function* requestChangePattern() {
  yield takeLatest("CHANGE_PATTERN", asyncChangePattern);
}

export function* requestChangePatternPatient() {
  yield takeLatest("CHANGE_PATTERN_PATIENT", asyncChangePatternPatient);
}

export function* requestChangeMedicineUse() {
  yield takeLatest("CHANGE_MEDICINE_USE", asyncChangeMedicineUse);
}

export function* requestEditPrescription() {
  yield takeLatest("EDIT_MP_PRESCRIPTION", asyncEditPrescription);
}

export function* requestSavePrescription() {
  yield takeLatest("SAVE_PRESCRIPTION", asyncSavePrescription);
}

export function* requestRemovePrescription() {
  yield takeLatest("REMOVE_PRESCRIPTION", asyncRemovePrescription);
}

export function* requestGetGroupMedList() {
  yield takeLatest("REQUEST_GROUP_MED_LIST", asyncGetGroupMedList);
}

export function* requestGetMedList() {
  yield takeLatest("REQUEST_MED_LIST", asyncGetMedList);
}

export default function* rootSaga() {
  yield all([
    fork(requestGetPattern),
    fork(requestChangePattern),
    fork(requestChangePatternPatient),
    fork(requestChangeMedicineUse),
    fork(requestEditPrescription),
    fork(requestSavePrescription),
    fork(requestRemovePrescription),
    fork(requestGetGroupMedList),
    fork(requestGetMedList),
  ]);
}
