const INITIAL_STATE = {
  groupId: "",
  residents: [],
  isWorkday: true,
  intervals: [
    {
      start: "",
      end: "",
    },
  ],
  readOnly: false,
  draftShiftChange: "",
  savedId: "",
  addressInterval: [],
  readOnly: false,
};

export default function Daily(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_ID_GROUP":
      return {
        ...state,
        groupId: action.payload,
      };
    case "SET_USERS_RESIDENTIAL":
      return {
        ...state,
        residents: action.payload,
      };
    case "SET_DATA_EVOLUTION":
      const {
        isWorkday,
        intervals,
        addressInterval,
        groupId,
        residents,
      } = action.payload;
      return {
        ...state,
        isWorkday,
        intervals,
        addressInterval,
        groupId,
        residents,
      };
    case "UPDATE_ADDRESS_INTERVAL":
      return {
        ...state,
        addressInterval: action.payload.interval,
      };
    case "SET_READ_ONLY":
      return {
        ...state,
        readOnly: action.payload,
      };
    case "SET_DRAFT_SHIFT_CHANGE":
      return {
        ...state,
        draftShiftChange: action.payload,
      };
    case "SET_DRAFT_SAVED_ID":
      return {
        ...state,
        savedId: action.payload,
      };
    case "SET_NEW_MEMBER_DAILY":
      return {
        ...state,
        residents: [action.payload, ...state.residents],
      };
    case "RESET_EVOLUTION_DAILY":
      return INITIAL_STATE;

    default:
      return state;
  }
}
