import React from "react";
import { Card } from "reactstrap";
import Academic from "../../components/academic";

export default function DashboardAcademic() {
  return (
    <div className="row col-12 d-flex justify-content-center ml-1">
      <div className="col-12 p-1 justify-content-center m-0 p-0">
        <Card className="shadow border-0 mb-3 mt-1">
          <Academic />
        </Card>
      </div>
    </div>
  );
}
