import React, { Component } from "react";
import api from "services/api";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import BarProgress from "components/BarProgress";

export default class ViewComplaints extends Component {
  state = {
    record: [],
  };
  componentDidMount() {
    api
      .post(`/microservice/complaints/getComplainstAppointment`, {
        appointment: this.props.appointment,
      })
      .then((res) => {
        this.setState({ record: res.data.list });
      })
      .catch(() => {});
  }

  getDescription = (value) => {
    switch (value) {
      case "queimacao":
        return "Queimação";

      case "pontada":
        return "Pontada";

      case "irradiando":
        return "Irradiando";

      case "difusa":
        return "Difusa";

      case "embarra":
        return "Em barra";

      case "colica":
        return "Cólica";

      case "neuropatica":
        return "Neuropática";

      case "aldinea":
        return "Aldinea";

      case "parestesia":
        return "Parestesia";

      case "continua":
        return "Contínua";

      case "membrofantasma":
        return "Membro Fantasma";

      case "disistesia":
        return "Disistesia";

      case "zoster":
        return "Pós Herpétcia (zoster)";

      case "neumogiatrigenio":
        return "Neumogia Trigenio";
      case "pulsatil":
        return "Pulsátil";
      default:
        return "---";
    }
  };

  render() {
    const { record } = this.state;
    return record.length > 0 ? (
      <div
        className="col-12"
        style={{
          minWidth: "650",
          overflowX: "auto",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Parte do Corpo</TableCell>
              <TableCell>Nível de dor</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Observações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.body_part}</TableCell>
                <TableCell>{item.level ? item.level : "---"}</TableCell>
                <TableCell>
                  {item.type
                    ? item.type === "cronica"
                      ? "Crônica"
                      : "Águda"
                    : "---"}
                </TableCell>
                <TableCell>
                  {this.getDescription(item.description_type)}
                </TableCell>
                <TableCell>{item.note ? item.note : "---"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="col-md-12 text-right">
          <Button
            onClick={this.props.onClose}
            color="primary"
            aria-label="Voltar"
          >
            <i className="zmdi zmdi-undo" /> Voltar
          </Button>
        </div>
      </div>
    ) : (
      <BarProgress />
    );
  }
}
