import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Stock = ({ match, history }) => {
  // const permissions = useSelector((state) => state.permissions);

  // const { manager, master, mdl_admission } = permissions;
  // React.useEffect(() => {
  //   if (!manager && !master && !mdl_admission) {
  //     history.push("/app/dashboard");
  //   }
  // }, []);

  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/report`} />
        <Route
          path={`${match.url}/inventory`}
          component={asyncComponent(() => import("./routes/product"))}
        />
        <Route
          path={`${match.url}/initialization`}
          component={asyncComponent(() => import("./routes/initialization"))}
        />
        <Route
          path={`${match.url}/product-stock`}
          component={asyncComponent(() => import("./routes/productStock"))}
        />
        <Route
          path={`${match.url}/stock-point`}
          component={asyncComponent(() => import("./routes/stockPoint"))}
        />
        <Route
          path={`${match.url}/provider`}
          component={asyncComponent(() => import("./routes/provider"))}
        />
        <Route
          path={`${match.url}/report`}
          component={asyncComponent(() => import("./routes/report"))}
        />
        <Route
          path={`${match.url}/movement`}
          component={asyncComponent(() => import("./routes/movement"))}
        />
        <Route
          path={`${match.url}/dismissal`}
          component={asyncComponent(() => import("./routes/dismissal"))}
        />
        <Route
          path={`${match.url}/dismissal-patient`}
          component={asyncComponent(() => import("./routes/dismissalPatient"))}
        />
        <Route
          path={`${match.url}/dismissal-simple`}
          component={asyncComponent(() =>
            import("./routes/dismissalPatient/DismissalFast")
          )}
        />
        <Route
          path={`${match.url}/conference`}
          component={asyncComponent(() => import("./routes/conference"))}
        />
        <Route
          path={`${match.url}/note-entry`}
          component={asyncComponent(() => import("./routes/noteEntry"))}
        />
        <Route
          path={`${match.url}/input-product`}
          component={asyncComponent(() => import("./routes/inputProduct"))}
        />
        <Route
          path={`${match.url}/protocol`}
          component={asyncComponent(() => import("./routes/protocol"))}
        />
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Stock;
