import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import asyncComponent from "../../../util/asyncComponent";
import {
  setStartAppointment,
  setSavedActions,
} from "actions/consult/Appointment";
import moment from "moment";
import PersonalData from "./routes/personalData";
import Api from "services/api";

export default function Consult({ match, history }) {
  const start = useSelector(({ appointment }) => appointment.start);
  const patient = useSelector(({ appointment }) => appointment.patient);
  const appointment = useSelector(({ appointment }) => appointment.id);
  const saved_id = useSelector(({ appointment }) => appointment.saved.id);
  const service_id = useSelector(({ appointment }) => appointment.service_id);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (patient) {
      if (!start) {
        dispatch(setStartAppointment(moment().format("YYYY-MM-DD HH:mm:ss")));
      }
    } else {
      history.push("/app/choose/data-consult");
    }
  }, []);

  React.useEffect(() => {
    // Ao salvar um atendimento registra caso esteja relacionado a um serviço o procedimento do serviço
    if (service_id) {
      Api.post("microservice/consult/saveProcedureService", {
        patient,
        appointment,
        service_id,
        saved_id,
      }).then(() => {
        dispatch(setSavedActions("procedure", saved_id));
      });
    }
  }, [appointment]);

  return (
    <Switch>
      <Route
        path={`${match.url}/allergies`}
        component={asyncComponent(() => import("./routes/allergies"))}
      />
      <Route
        path={`${match.url}/prescription`}
        component={asyncComponent(() => import("./routes/prescription"))}
      />
      <Route
        path={`${match.url}/prescription-manipulated`}
        component={asyncComponent(() =>
          import("./routes/prescriptionManipulated")
        )}
      />
      <Route
        path={`${match.url}/exam`}
        component={asyncComponent(() => import("./routes/exams"))}
      />
      <Route
        path={`${match.url}/print`}
        component={asyncComponent(() => import("./routes/print"))}
      />

      {/* <Route
        path={`${match.url}/aga`}
        component={asyncComponent(() => import("./routes/aga"))}
      /> */}
      <Route
        path={`${match.url}/aga`}
        component={asyncComponent(() => import("./routes/agaNew"))}
      />
      <Route
        path={`${match.url}/ivcf`}
        component={asyncComponent(() => import("./routes/ivcf"))}
      />
      <Route
        path={`${match.url}/consultation-summary`}
        component={asyncComponent(() => import("./routes/consultationSummary"))}
      />

      <Route
        path={`${match.url}/personal-data`}
        render={(_props) => <PersonalData patient={patient} match={match} />}
      />
      <Route
        path={`${match.url}/medicines`}
        component={asyncComponent(() => import("./routes/medicines"))}
      />
      <Route
        path={`${match.url}/medicines-out`}
        component={asyncComponent(() => import("./routes/medicines"))}
      />
      <Route
        path={`${match.url}/dysfunctions`}
        component={asyncComponent(() => import("./routes/dysfunctions"))}
      />
      <Route
        path={`${match.url}/vaccines`}
        component={asyncComponent(() => import("./routes/vaccines"))}
      />
      <Route
        path={`${match.url}/pain-scale`}
        component={asyncComponent(() => import("./routes/painScale"))}
      />

      <Route
        path={`${match.url}/personal-history`}
        component={asyncComponent(() => import("./routes/formPersonalHistory"))}
      />
      <Route
        path={`${match.url}/child-disease`}
        component={asyncComponent(() => import("./routes/formChildDisease"))}
      />
      <Route
        path={`${match.url}/family-history`}
        component={asyncComponent(() => import("./routes/familyHistory"))}
      />
      <Route
        path={`${match.url}/habits`}
        component={asyncComponent(() => import("./routes/formHabits"))}
      />
      <Route
        path={`${match.url}/forward`}
        component={asyncComponent(() => import("./routes/forward"))}
      />
      <Route
        path={`${match.url}/procedures`}
        component={asyncComponent(() => import("./routes/formProcedures"))}
      />
      <Route
        path={`${match.url}/attendance-record`}
        component={asyncComponent(() => import("./routes/attendanceRecord"))}
      />
      <Route
        path={`${match.url}/system-review`}
        component={asyncComponent(() => import("./routes/systemReview"))}
      />
      <Route
        path={`${match.url}/compare-pictures`}
        component={asyncComponent(() => import("./routes/comparePictures"))}
      />

      <Route
        path={`${match.url}/transcription-prescription-out`}
        component={asyncComponent(() =>
          import("./routes/prescriptionTranscription")
        )}
      />
      <Route
        path={`${match.url}/transcription-prescription`}
        component={asyncComponent(() =>
          import("./routes/prescriptionTranscription")
        )}
      />
      <Route
        path={`${match.url}/prescription-care`}
        component={asyncComponent(() => import("./routes/prescriptionCare"))}
      />
      <Route
        path={`${match.url}/conduct`}
        component={asyncComponent(() => import("./routes/conduct"))}
      />
      <Route
        path={`${match.url}/tbca`}
        component={asyncComponent(() => import("./routes/tbca"))}
      />
      <Route
        path={`${match.url}/circumferences`}
        component={asyncComponent(() => import("./routes/circumferences"))}
      />
      <Route
        path={`${match.url}/folds`}
        component={asyncComponent(() => import("./routes/folds"))}
      />
      <Route
        exact
        path={`${match.url}/`}
        component={asyncComponent(() => import("./routes/attendanceRecord"))}
      />
      <Route
        exact
        path={`${match.url}/initial-care`}
        component={asyncComponent(() => import("./routes/attendanceRecord"))}
      />
      {/* Odonto */}
      <Route
        path={`${match.url}/eog`}
        component={asyncComponent(() => import("./routes/odonto/eog"))}
      />
      <Route
        path={`${match.url}/plate-index`}
        component={asyncComponent(() => import("./routes/odonto/plateIndex"))}
      />
      <Route
        path={`${match.url}/integral-diagnostic`}
        component={asyncComponent(() =>
          import("./routes/odonto/diagnosticIntegral")
        )}
      />
      <Route
        path={`${match.url}/initial-odonto`}
        component={asyncComponent(() =>
          import("./routes/odontoPref/initialAssessmentOdonto")
        )}
      />
      {/* <Route
        path={`${match.url}/odonto`}
        component={asyncComponent(() => import("./routes/odonto/index"))}
      /> */}
      <Route
        path={`${match.url}/daily-procedure`}
        component={asyncComponent(() =>
          import("./routes/odontoPref/dailyProcedureOdonto")
        )}
      />
      <Route
        path={`${match.url}/legal-dentistry`}
        component={asyncComponent(() =>
          import("./routes/odontoPref/legalDentistry")
        )}
      />
      {/* <Route
        path={`${match.url}/form-print-odonto`}
        component={asyncComponent(() =>
          import("./routes/odonto/formPrintOdonto")
        )}
      /> */}
      <Route
        path={`${match.url}/general-objective`}
        component={asyncComponent(() =>
          import("./routes/odonto/generalObjective")
        )}
      />
      {/* <Route
        path={`${match.url}/periodotogram`}
        component={asyncComponent(() =>
          import("./routes/odonto/periodotogram")
        )}
      /> */}
      <Route
        path={`${match.url}/files-cloud`}
        component={asyncComponent(() => import("./routes/filesCloud"))}
      />

      <Route
        path={`${match.url}/physical-avaliation`}
        component={asyncComponent(() => import("./routes/physicalAvaliation"))}
      />

      <Route
        path={`${match.url}/initial-record`}
        component={asyncComponent(() => import("./routes/initialRecord"))}
      />

      <Route
        path={`${match.url}/initial`}
        component={asyncComponent(() => import("./routes/initial"))}
      />

      <Route
        path={`${match.url}/attestation`}
        component={asyncComponent(() => import("./routes/attestation"))}
      />
      {/* Nutrição */}
      <Route
        path={`${match.url}/food-reminder`}
        component={asyncComponent(() =>
          import("./routes/nutrition/foodReminder")
        )}
      />
      <Route
        path={`${match.url}/food-plan`}
        component={asyncComponent(() => import("./routes/nutrition/foodPlan"))}
      />

      <Route
        path={`${match.url}/defects`}
        component={asyncComponent(() => import("./routes/defects"))}
      />

      <Route
        path={`${match.url}/healthy-habits`}
        component={asyncComponent(() => import("./routes/healthyHabits"))}
      />

      <Route
        path={`${match.url}/manipulated`}
        component={asyncComponent(() => import("./routes/manipulated"))}
      />

      <Route
        path={`${match.url}/conduct-guidance`}
        component={asyncComponent(() => import("./routes/conductGuidance"))}
      />
      <Route
        path={`${match.url}/video`}
        component={asyncComponent(() => import("./routes/video"))}
      />
      <Route
        path={`${match.url}/history`}
        component={asyncComponent(() => import("./routes/history"))}
      />

      {/* Fisio */}
      <Route
        path={`${match.url}/fisio-avaliation`}
        component={asyncComponent(() => import("./routes/fisio/avaliation"))}
      />
      <Route
        path={`${match.url}/fisio-pos-covid`}
        component={asyncComponent(() => import("./routes/fisio/posCovid"))}
      />
      <Route
        path={`${match.url}/fisio-rehabilitation`}
        component={asyncComponent(() =>
          import("./routes/fisio/rehabilitation")
        )}
      />
      <Route
        path={`${match.url}/rehabilitation`}
        component={asyncComponent(() => import("./routes/rehabilitation"))}
      />
      <Route
        path={`${match.url}/sessions/:id`}
        component={asyncComponent(() => import("./routes/fisio/sessions"))}
      />
      <Route
        path={`${match.url}/sessions/`}
        component={asyncComponent(() => import("./routes/fisio/sessions"))}
      />

      <Route
        path={`${match.url}/session/:id`}
        component={asyncComponent(() => import("./routes/sessions"))}
      />
      <Route
        path={`${match.url}/session/`}
        component={asyncComponent(() => import("./routes/sessions"))}
      />
      <Route
        path={`${match.url}/obstetric/`}
        component={asyncComponent(() => import("./routes/obstetric"))}
      />
      <Route
        path={`${match.url}/outcome/`}
        component={asyncComponent(() => import("./routes/outcome"))}
      />
      <Route
        path={`${match.url}/prenatal/`}
        component={asyncComponent(() => import("./routes/prenatal"))}
      />
    </Switch>
  );
}
