import React, { useState } from "react";
import Api from "services/api";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ExamsProfessional from "./ExamsProfessional";
import ListMedicines from "./ListMedicines";
import ListProcedures from "./ListProcedures";
import CircularProgress from "components/CircularProgress";
import moment from "moment";
import { getUrlPhoto } from "services/functions";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import AttendanceDoctor from "./AttendanceDoctor";

//tabs
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import BusinessIcon from "@material-ui/icons/Business";
import CompomentsDataDash from "./CompomentsDataDash";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  timelineItem: {
    "&:last-child:before": {
      bottom: "-20px",
      borderLeftStyle: "solid",
    },
  },
  userName: {
    marginBottom: "2px",
    fontWeight: "500",
  },
  userDescription: {
    fontSize: "13px",
    marginBottom: "0",
    color: "grey",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function ViewProfessional({ professional, alterProfessional }) {
  const [record, setRecord] = useState({
    exams: [],
    patients: [],
    attendances: [],
    medicines: [],
    procedures: [],
  });
  const [value, setValue] = React.useState(0);
  const [loader, setLoader] = useState(true);
  const data_dash = useSelector((state) => state.dashboard.data_dash);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (professional.id) {
      setLoader(true);
      Api.post(`/ms_system/dashboard/getDataDashProfessionalPrefecture`, {
        professional: professional.id,
        start: data_dash.start,
        end: data_dash.end,
      })
        .then((res) => {
          setRecord(res.data);
          setLoader(false);
        })
        .catch(() => {
          setRecord({
            exams: [],
            patients: [],
            attendances: [],
            medicines: [],
            procedures: [],
          });
          setLoader(false);
        });
    }
  }, [professional, data_dash]);

  return (
    <div className="app-wrapper">
      <div className="animated slideInUpTiny animation-duration-3">
        <Paper elevation={3}>
          <div className="row p-3">
            <div className="col-md-6 col-12 d-flex">
              <Avatar
                className={`mr-2 ${classes.large}`}
                alt={professional.name}
                src={getUrlPhoto(professional.id, professional.photo)}
              />
              <div className="mr-3">
                <span className={`${classes.userName}`}>
                  {professional.name}
                </span>
                <p className={`${classes.userDescription}`}>
                  {professional.area}
                </p>
              </div>
            </div>
            {/* <div className="col-md-2 col-6">
              <TextField
                type="date"
                value={start}
                onChange={(e) => setStart(e.target.value)}
                label="Início:"
              />
            </div>
            <div className="col-md-2 col-6">
              <TextField
                type="date"
                value={end}
                onChange={(e) => setEnd(e.target.value)}
                label="Fim:"
              />
            </div> */}
            <div className="col-md-6 col-12 d-flex justify-content-end">
              <CompomentsDataDash />

              <Tooltip title="Sair">
                <IconButton
                  style={{ float: "right", marginLeft: "30px" }}
                  onClick={alterProfessional}
                  color="secondary"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <AppBar
            position="static"
            color="default"
            style={{
              background: "white",
              boxShadow: "none",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              <Tab
                label="Atendimentos"
                icon={<PersonIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label="Pedidos de Exame"
                icon={<BusinessIcon />}
                {...a11yProps(1)}
              />
              <Tab
                label="Medicamentos Prescritos"
                icon={<AssignmentIcon />}
                {...a11yProps(2)}
              />
              <Tab
                label="Procedimentos Realizados"
                icon={<LibraryBooksIcon />}
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>
        </Paper>
        {loader ? (
          <CircularProgress />
        ) : (
          <div className="row justify-content-center mt-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center">
              <TabPanel value={value} index={0} className="m-0 p-0">
                <AttendanceDoctor record={record.attendances} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ExamsProfessional record={record.exams} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ListMedicines record={record.medicines} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ListProcedures record={record.procedures} />
              </TabPanel>
            </div>
          </div>
        )}
        {/* <div className="row justify-content-center mt-3">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 justify-content-center">
              <AttendancesPatient record={record.attendances} />
              <ExamsProfessional record={record.exams} />
              <ListMedicines record={record.medicines} />
              <ListProcedures record={record.procedures} />
            </div>
          </div> */}
      </div>
      <Button
        onClick={alterProfessional}
        className="jr-btn jr-btn-sm mt-3"
        color="secondary"
        variant="contained"
        style={{ float: "left", textTransform: "initial" }}
      >
        <i className="zmdi zmdi-undo zmdi-hc-lg" />
        <span>Voltar</span>
      </Button>
    </div>
  );
}
