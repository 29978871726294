import React, { useState, useEffect } from "react";

import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AboutDay from "./AboutDay";

export default function Energy({ handleClose, saved, setAboutDay }) {
  const [progress, setProgress] = useState(0);

  function next() {
    setProgress(progress + 1);
  }

  useEffect(() => {
    progress === 1 && handleClose();
  }, [progress]);

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-10 text-left">
          <Typography className="pt-1" variant="h6" gutterBottom>
            Como está a sua energia
          </Typography>
        </div>
        <div className="col-2">
          <IconButton onClick={handleClose} color="default">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div
        className="card"
        style={{
          marginLeft: "-15px",
          marginRight: "-15px",
          paddingTop: "1rem",
        }}
      >
        {progress === 0 && (
          <AboutDay handleNext={next} saved={saved} setAboutDay={setAboutDay} />
        )}
      </div>
    </div>
  );
}
