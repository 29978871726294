export default {
  // caixa de texto
  "&multiLine": {
    width: "100%",
    heigth: "100%",
    minHeight: 150,
    control: {
      backgroundColor: "transparent",
      fontSize: "inherit"
    },

    highlighter: {
      padding: 9
    },

    input: {
      padding: 0,
      minHeight: 63,
      heigth: "100%",
      outline: 0,
      border: 0
    }
  },

  suggestions: {
    zIndex: 10000,
    list: {
      width: 400,
      maxHeigth: 400,
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16
    },

    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",

      "&focused": {
        backgroundColor: "#cee4e5"
      }
    }
  }
};
