import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
import { BtnSave } from "components/Button";
import Api from "services/api";

function SelectUserManage({ open, users, select }) {
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState("");
  useEffect(() => {
    if (users.length > 0) {
      Api.post(`/ms_system/user/getNameUsers/`, { users }).then(res => {
        setList(res.data.record);
      });
    }
  }, [users]);

  return (
    <Dialog open={open}>
      <DialogTitle className="text-primary font-weight-semibold">
        Gerir usuário:
      </DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <TextField
              autoFocus
              id="type"
              select
              label="Usuário:"
              value={selected}
              onChange={e => setSelected(e.target.value)}
              fullWidth
              style={{ minWidth: "400px", marginTop: "0px" }}
            >
              {list.map(row => (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="col-12">
          <BtnSave
            title={"Salvar"}
            onClick={() => select(selected)}
            float="right"
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default SelectUserManage;
