import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import NoRecord from "components/Alert/NoRecord";
import FormRoom from "./FormRoom";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function Room({ data, addressId, hiddenButton }) {
  const [state, setState] = useState([]);
  const [modal_form, setModalForm] = useState(false);
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [room_selected, setRoomSelected] = useState({});

  useEffect(() => {
    setState(data);
  }, []);

  function addRoom(obj) {
    setState([...state, obj]);
  }

  function editRoom(obj) {
    let aux = state.map(row => (row.id === obj.id ? obj : row));
    setState(aux);
  }

  function deleteRoom() {
    Api.post(`/ms_system/workplace/deleteRoom/`, {
      id: room_selected
    }).then(res => {
      let aux = state.filter(row => row.id !== room_selected);
      setState(aux);
      setModalDelete(false);
      setSuccessDelete(true);
    });
  }
  return (
    <>
      <ExpansionPanel
        square
        style={{
          boxShadow: "10px 5px 5px white",
          borderBottom: "1px solid #d2d2d2"
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
        >
          Salas de atendimento
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: "block" }}>
          <div className="w-100">
            <Button
              onClick={() => {
                setModalForm(true);
                setRoomSelected("");
              }}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
              style={{ float: "right" }}
              hidden={hiddenButton}
            >
              <i className="zmdi zmdi-plus" /> <span> Adicionar</span>
            </Button>
          </div>
          {state.length > 0 ? (
            <Table className="table-finances w-100">
              <TableHead>
                <TableRow>
                  <TableCell width="25%">Nome</TableCell>
                  <TableCell align="left" width="50%">
                    Descrição
                  </TableCell>
                  <TableCell align="right" width="25%">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.map(row => (
                  <TableRow hover key={row.id}>
                    <TableCell component="th" scope="row" width="25%">
                      {row.name}
                    </TableCell>
                    <TableCell align="left" width="50%">
                      {row.description}
                    </TableCell>
                    <TableCell width="25%" align="right" hidden={hiddenButton}>
                      <IconButton
                        onClick={() => {
                          setModalForm(true);
                          setRoomSelected(row);
                        }}
                        color="primary"
                        aria-label="Editar"
                      >
                        <i className="zmdi zmdi-edit zmdi-hc-fw" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setRoomSelected(row.id);
                          setModalDelete(true);
                        }}
                        color="secondary"
                        aria-label="Remover"
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoRecord />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={modal_form}
        onClose={() => {
          setModalForm(false);
        }}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogContent>
          <FormRoom
            data={room_selected}
            addressId={addressId}
            close={() => setModalForm(false)}
            add={obj => addRoom(obj)}
            edit={obj => editRoom(obj)}
          />
        </DialogContent>
      </Dialog>
      <SweetAlert
        show={modal_delete}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Deseja realmente excluir esta sala de atendimento?"
        onConfirm={() => deleteRoom()}
        onCancel={() => setModalDelete(false)}
      />
      <SweetAlert
        show={success_delete}
        success
        title="Sala de atendimento excluída com sucesso!"
        onConfirm={() => setSuccessDelete(false)}
      />
    </>
  );
}

export default Room;
