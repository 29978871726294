const INITIAL_STATE = {
  text_header: "",
  text_column_left: "",
  text_footer: "",
  text_dados: "",
  listFormPrint: [],
};

export default function alertMessage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_PRINT":
      return {
        ...state,
      };
    case "GET_PRINT_SUCCESS":
      return {
        ...state,
        text_dados: action.response.text_dados,
        text_header: action.response.text_header,
        text_footer: action.response.text_footer,
        text_column_left: action.response.text_column_left,
      };
    case "LIST_STYLE_FORM_PRINT":
      let value = action.payload;
      if (value.free_text_column_left === undefined) {
        value.free_text_column_left = "";
      }
      if (value.free_text_header === undefined) {
        value.free_text_header = "";
      }
      if (value.free_text_footer === undefined) {
        value.free_text_footer = "";
      }
      return {
        ...state,
        listFormPrint: action.payload,
      };
    default:
      return state;
  }
}
