import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { BtnAdd } from "components/Button";
import FormAllergyChemicals from "./FormAllergyChemicals";
import {
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import Api from "services/api";
import NoRecord from "components/Alert/NoRecord";
import InfoRegister from "../../InfoRegister";

export default function AllergicChemicals({ patient, type }) {
  const [view, setView] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Api.post("/microservice/allergies/getAllergyChemicalsPatient/", {
      patient,
    }).then((res) => {
      setList(res.data.record);
    });
  };

  const handleValidate = (id) => {
    setList(
      list.map((row) =>
        row.id === id
          ? { ...row, approved_by: localStorage.getItem("userAudit") }
          : row
      )
    );
  };

  return (
    <Card className={`${type !== "admission" ? "shadow" : ""} border-0`}>
      <CardBody>
        <div className="row">
          <div className="col-12">
            <h4
              className="text-primary"
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              Alergias a produtos químicos
              {view && (
                <BtnAdd
                  title={list.length > 0 ? "Alterar" : "Adicionar"}
                  onClick={() => setView(false)}
                  float="right"
                />
              )}
            </h4>
          </div>
          {view ? (
            <div
              className="col-12"
              style={{
                minWidth: "650",
                overflowX: "auto",
              }}
            >
              {list.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="60%"></TableCell>
                      <TableCell width="30%">Observações</TableCell>
                      <TableCell width="10%"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell width="60%">{row.description}</TableCell>
                        <TableCell>
                          {row.additional_considerations
                            ? row.additional_considerations
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          <InfoRegister
                            form={type}
                            type="chemicalAllergic"
                            id={row.id}
                            patient={patient}
                            added={row.created_by}
                            approved={row.approved_by}
                            created={row.created}
                            handleValidate={handleValidate}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <NoRecord />
              )}
            </div>
          ) : (
            <FormAllergyChemicals
              patient={patient}
              type={type}
              record={list}
              handleClose={() => setView(true)}
              beforeSave={() => {
                getData();
                setView(true);
              }}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
}
