import React from "react";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: ""
    };
  }
  state = {
    type: ""
  };

  componentDidUpdate() {
    // if (this.props.showMessage) {
    //   setTimeout(() => {
    //     this.props.hideMessage();
    //   }, 3000);
    // }
    // if (this.props.authUser !== null) {
    //   this.props.history.push('/');
    // }
  }

  render() {
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div
          className="app-login-main-content"
          style={{ justifyContent: "space-between" }}
        >
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="SalutemPlus">
              <img
                className="w-100"
                src={require("assets/images/Logo_Salutem-Plus_Branca.png")}
                alt="Salutem"
                title="Salutem"
              />
            </Link>
          </div>

          <div className="app-login-content" style={{ width: 370 }}>
            <div className="app-login-header">
              <h1>Cadastro de Usuário</h1>
            </div>

            <div className="mb-4">
              <h2>
                {/* <IntlMessages id="appModule.createAccount" /> */}
                Selecione o perfil
              </h2>
            </div>

            <div className="app-login-form">
              <form method="post" className="w-100">
                <List className="mb-4 mr-2 align-items-center">
                  <Link to="/register/doctor" style={{ color: "#000" }}>
                    <ListItem
                      button
                      onClick={() => this.setState({ type: 0 })}
                      selected={this.state.type === 0 ? true : false}
                    >
                      <ListItemIcon>
                        <i className="zmdi zmdi-account-o zmdi-hc-fw zmdi-hc-2x" />
                      </ListItemIcon>
                      <ListItemText primary="Profissional" />
                    </ListItem>
                  </Link>
                  <Link to="/register/patient" style={{ color: "#000" }}>
                    <ListItem
                      button
                      onClick={() => this.setState({ type: 3 })}
                      selected={this.state.type === 3 ? true : false}
                    >
                      <ListItemIcon>
                        <i className="zmdi zmdi-account zmdi-hc-fw zmdi-hc-2x" />
                      </ListItemIcon>
                      <ListItemText primary="Paciente" />
                    </ListItem>
                  </Link>
                  <Link to="/register/provider" style={{ color: "#000" }}>
                    <ListItem
                      button
                      onClick={() => this.setState({ type: 1 })}
                      selected={this.state.type === 1 ? true : false}
                    >
                      <ListItemIcon>
                        <i className="zmdi zmdi-hospital zmdi-hc-fw zmdi-hc-2x" />
                      </ListItemIcon>
                      <ListItemText primary="Prestador" />
                    </ListItem>
                  </Link>

                  {/* <ListItem button>
                    <ListItemIcon>
                      <i className="zmdi zmdi-star zmdi-hc-fw zmdi-hc-2x" />
                    </ListItemIcon>
                    <ListItemText primary="Estudante" />
                  </ListItem> */}
                </List>

                {/* 
                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    {this.state.type === 0 && <Link to="/register/doctor"></Link>}
                    <Link>
                      <Button variant="contained" color="primary">
                        {/* <IntlMessages id="appModule.regsiter" /> 
                        Selecionar
                        <i className="zmdi zmdi-check zmdi-hc-fw" />
                      </Button>
                    </Link>
                    <Link to="/signin">
                      <IntlMessages id="signUp.alreadyMember" />
                    </Link> 
                  </div>
                  <div className="app-social-block my-1 my-sm-3">
                    <IntlMessages id="signIn.connectWith" />
                    <ul className="social-link">
                      <li>
                        <IconButton
                          className="icon"
                          onClick={() => {
                            // this.props.showAuthLoader();
                            // this.props.userFacebookSignIn();
                          }}
                        >
                          <i className="zmdi zmdi-facebook" />
                        </IconButton>
                      </li>

                      <li>
                        <IconButton
                          className="icon"
                          onClick={() => {
                            // this.props.showAuthLoader();
                            // this.props.userTwitterSignIn();
                          }}
                        >
                          <i className="zmdi zmdi-twitter" />
                        </IconButton>
                      </li>

                      <li>
                        <IconButton
                          className="icon"
                          onClick={() => {
                            // this.props.showAuthLoader();
                            // this.props.userGoogleSignIn();
                          }}
                        >
                          <i className="zmdi zmdi-google-plus" />
                        </IconButton>
                      </li>

                      <li>
                        <IconButton
                          className="icon"
                          onClick={() => {
                            // this.props.showAuthLoader();
                            // this.props.userGithubSignIn();
                          }}
                        >
                          <i className="zmdi zmdi-github" />
                        </IconButton>
                      </li>
                    </ul>
                  </div> 
                */}
              </form>
            </div>
          </div>
        </div>

        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  {
    // userSignUp,
    // hideMessage,
    // showAuthLoader,
    // userFacebookSignIn,
    // userGoogleSignIn,
    // userGithubSignIn,
    // userTwitterSignIn
  }
)(SignUp);
