import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import {
  Today,
  Block,
  History,
  Videocam,
  MoreVert,
  Visibility,
  AssignmentInd,
} from "@material-ui/icons";

const ITEM_HEIGHT = 48;

export default function LongMenu({ handleSetValue, row }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (key, row) => {
    handleSetValue(key, row);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "28ch",
            left: "1346px",
          },
        }}
      >
        <MenuItem onClick={() => onSelect("0", row)}>
          <Visibility
            fontSize="small"
            className="mr-2"
            style={{ color: "#6a6a6a" }}
          />
          <Typography variant="inherit">Visualizar Notificação</Typography>
        </MenuItem>
        <MenuItem onClick={() => onSelect("1", row)}>
          <History
            fontSize="small"
            className="mr-2"
            style={{ color: "#6a6a6a" }}
          />
          <Typography variant="inherit">Histórico do Paciente</Typography>
        </MenuItem>
        <MenuItem onClick={() => onSelect("2", row)}>
          <Today
            fontSize="small"
            className="mr-2"
            style={{ color: "#6a6a6a" }}
          />
          <Typography variant="inherit">Agendar Triagem</Typography>
        </MenuItem>
        {/* <MenuItem onClick={() => onSelect("3", row)}>
          <Videocam
            fontSize="small"
            className="mr-2"
            style={{ color: "#6a6a6a" }}
          />
          <Typography variant="inherit">Agendar Teleatendimento </Typography>
        </MenuItem> */}
        {/* {row.monitoring !== "1" && (
          <MenuItem onClick={() => onSelect("4", row)}>
            <AssignmentInd
              fontSize="small"
              className="mr-2"
              style={{ color: "#6a6a6a" }}
            />
            <Typography variant="inherit">Monitorar Paciente </Typography>
          </MenuItem>
        )} */}
        {/* <MenuItem onClick={() => onSelect("5", row)}>
          <Block
            fontSize="small"
            className="mr-2"
            style={{ color: "#6a6a6a" }}
          />
          <Typography variant="inherit">Cancelar Notificação </Typography>
        </MenuItem> */}
      </Menu>
    </div>
  );
}
