import moment from "moment";

/**
 * Estado inicial para os cuidados
 */
export const initial = {
  id: "",
  care: "",
  hours: [""],
  quantity: "",
  observation: "",
  duration: 1,
  interval: "Diário",
  // start: new Date(),
  // end: new Date(),
  start: moment().format("YYYY-MM-DD"),
  end: moment().format("YYYY-MM-DD"),
  //Dieta Oral
  classification: [],
  subClassification: [],
  //Dieta enteral
  volume: "",
  volume_type: "",
  flow: "",
  //Hidratação
  type_hydratation: "",
  serum_type: "",
  materials: [],
  //Cabeceira Elevada
  period: [],
  degree: [],
  //Oxigenoterapia
  single_period: "",
  //Decubito
  decubit: [""],
  time_interval: "",
  hour_interval: "",
  //Dados Vitais
  respiratory: true,
  capillary: true,
  blood_pressure: true,
  pulse: true,
  temperature: true,
  consciousness: true,
  behavior: true,
  sleep: true,
};
