import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";

import {
  Typography,
  makeStyles,
  Icon,
  TextField,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { ButtonLarge } from "components/Button";
import CustomSlider from "components/CustomSlider";

import CloseIcon from "@material-ui/icons/Close";
import Dr_prancheta from "assets/images/dr_prancheta.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    width: "100%",
  },
  box: {
    border: "solid 2px #c1c1c1",
    borderRadius: "25px",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(220 243 255)",
    },
  },
  icon: {
    overflow: "inherit",
    fontSize: "40px",
    width: "auto",
    height: "auto",
  },
  dFlex: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  positionBad: {
    position: "absolute",
    bottom: "-5px",
    left: 0,
  },
  positionGood: {
    position: "absolute",
    bottom: "-5px",
    right: 0,
  },
}));

export default function Complainst({
  progress,
  handleNext,
  handleBack,
  handleClose,
}) {
  const gender = useSelector(({ auth }) => auth.gender);
  const width = useSelector((state) => state.settings.width);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = useState({
    complaint: "",
    what_complaint: [],
  });

  useEffect(() => {
    if (state.complaint === "1") handleNext();
    else if (state.complaint === "0") handleSave();
  }, [state.complaint]);

  const onBack = () => {
    setState({
      complaint: "",
      what_complaint: [],
    });
    handleBack();
  };

  function handleSave() {
    if (
      state.what_complaint.length === 0 ||
      state.what_complaint.filter((el) => !el.value).length > 0
    ) {
      showMsg("error", "Insira o nível de dor", dispatch);
    } else {
      Api.post("microservice/patientportal/saveComplainst", state)
        .then(({ data }) => {
          if (data.success) {
            handleClose();
            localStorage.setItem("savedQuizze", "1");
          } else {
            throw true;
          }
        })
        .catch(() => {
          showMsg("error", "Não foi possível savar as informações", dispatch);
        });
    }
  }

  function getArr() {
    if (gender === "0")
      return [
        "Azia / Má Digestão",
        "Câimbra",
        "Cólica Renal",
        "Diarreia",
        "Dificuldade Ou Dor Ao Urinar",
        "Dor De Barriga",
        "Dor De Cabeça",
        "Dor Articular",
        "Dor Lombar",
        "Dor De Dente",
        "Dor De Garganta",
        "Dor De Ouvido",
        "Dor Musculo Esquelética (Boneco)",
        "Dor Torácica",
        "Enjoo/ Vomito",
        "Febre / Calafrio",
        "Intestino Preso",
        "Tonteira/Vertigem",
        "Tosse Seca",
        "Tosse Secretiva/Coriza",
        "Outros",
      ];
    else
      return [
        "Azia / Má Digestão",
        "Câimbra",
        "Cólica Menstrual",
        "Cólica Renal",
        "Diarreia",
        "Dificuldade Ou Dor Ao Urinar",
        "Dor De Barriga",
        "Dor De Cabeça",
        "Dor Articular",
        "Dor Lombar",
        "Dor De Dente",
        "Dor De Garganta",
        "Dor De Ouvido",
        "Dor Musculo Esquelética (Boneco)",
        "Dor Torácica",
        "Enjoo/ Vomito",
        "Febre / Calafrio",
        "Intestino Preso",
        "Tonteira/Vertigem",
        "Tosse Seca",
        "Tosse Secretiva/Coriza",
        "Outros",
      ];
  }

  return (
    <div className={classes.root}>
      <div className={classes.dFlex}>
        <div className="col-12 col-sm-12 col-md-8">
          {progress === 0 && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Algum problema não relatado ao médico?</strong>
              </Typography>
              <div className="row">
                <div className="col-6 d-flex justify-content-end">
                  <div
                    className={classes.box}
                    onClick={() => setState({ ...state, complaint: "0" })}
                  >
                    <Icon className={classes.icon}>😃</Icon>
                    <span style={{ fontSize: "25px" }}>Não</span>
                  </div>
                </div>
                <div className="col-6 ">
                  <div
                    className={classes.box}
                    onClick={() => setState({ ...state, complaint: "1" })}
                  >
                    <Icon className={classes.icon}>🤒</Icon>
                    <span style={{ fontSize: "25px" }}>Sim</span>
                  </div>
                </div>
              </div>
            </>
          )}
          {progress === 1 && (
            <div className={"container text-left"}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Selecione o(s) problema(s) não relatado(s)?</strong>
              </Typography>
              <div className="w-100 px-2">
                <div className="col-12 my-3 px-0">
                  <TextField
                    select
                    fullWidth
                    label="Informe o(s) problema(s):"
                    value=""
                    onChange={(e) => {
                      setState({
                        ...state,
                        what_complaint: [
                          ...state.what_complaint,
                          { name: e.target.value, value: "" },
                        ],
                      });
                    }}
                    InputLabelProps={{ shrink: true }}
                  >
                    {getArr()
                      .filter(
                        (opt) =>
                          !state.what_complaint.find((el) => el.name === opt)
                      )
                      .map((row) => (
                        <MenuItem key={row} value={row}>
                          {row}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
                <div className="row">
                  {state.what_complaint.map((row, index) => (
                    <div className="col-12" key={row.name}>
                      {width > 500 ? (
                        <div className="d-flex align-items-center my-2">
                          <span style={{ width: "15vw" }}>{row.name}</span>
                          <CustomSlider
                            step={1}
                            marks
                            min={1}
                            max={10}
                            value={row.value}
                            onChange={(event, newValue) => {
                              let aux = state.what_complaint;
                              aux[index].value = newValue;
                              setState({ ...state, what_complaint: aux });
                            }}
                            style={{
                              color: !row.value
                                ? "#d1d1d1"
                                : row.value >= 8
                                ? "#D6201B"
                                : row.value < 4
                                ? "#00B1E2"
                                : "#EEE800",
                            }}
                            valueLabelDisplay="on"
                            aria-labelledby="continuous-slider"
                          />
                          <IconButton
                            onClick={() =>
                              setState({
                                ...state,
                                what_complaint: state.what_complaint.filter(
                                  (el) => el.name !== row.name
                                ),
                              })
                            }
                            color="secondary"
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      ) : (
                        <>
                          <div className="d-flex align-items-center">
                            <span>{row.name}</span>
                            <IconButton
                              onClick={() =>
                                setState({
                                  ...state,
                                  what_complaint: state.what_complaint.filter(
                                    (el) => el.name !== row.name
                                  ),
                                })
                              }
                              color="secondary"
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                          <CustomSlider
                            step={1}
                            marks
                            min={1}
                            max={10}
                            value={row.value}
                            onChange={(event, newValue) => {
                              let aux = state.what_complaint;
                              aux[index].value = newValue;
                              setState({ ...state, what_complaint: aux });
                            }}
                            style={{
                              color: !row.value
                                ? "#d1d1d1"
                                : row.value >= 8
                                ? "#D6201B"
                                : row.value < 4
                                ? "#00B1E2"
                                : "#EEE800",
                            }}
                            valueLabelDisplay="on"
                            aria-labelledby="continuous-slider"
                          />
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-12 col-sm-12 col-md-4 justify-content-end">
          <img src={Dr_prancheta} alt="Dra. Prancehta" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ButtonLarge
            onClick={progress === 0 ? handleClose : onBack}
            style={{
              padding: "1px",
              width: "unset",
              minWidth: "100px",
              float: "left",
            }}
          >
            {progress === 0 ? "Sair" : "Voltar"}
          </ButtonLarge>
          {progress === 1 && (
            <ButtonLarge
              onClick={handleSave}
              style={{
                padding: "1px",
                width: "unset",
                minWidth: "100px",
                float: "right",
                backgroundColor: state.complaint ? "#3d8b40" : "unset",
                color: state.complaint ? "#fff" : "#8c8c8c",
              }}
            >
              Confirmar
            </ButtonLarge>
          )}
        </div>
      </div>
    </div>
  );
}
