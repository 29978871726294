import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  handleChange,
  handleChangeRecreation
} from "actions/configuration/Profile";
import * as alertActions from "actions/Alerts";
import Api from "services/api";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

class ProfessionalData extends Component {
  state = {
    council: [],
    state: []
  };

  componentWillMount() {
    this.getCouncil();
    this.getState();
  }

  submit = () => {
    let formData = {
      user: this.props.user,
      data: {
        council: this.props.Profile.basic.council,
        numberRegister: this.props.Profile.basic.numberRegister,
        uf: this.props.Profile.basic.state,
        yearRegister: this.props.Profile.basic.yearRegister
      }
    };

    Api.post(`/microservice/profile/saveProfessionalData/`, formData)
      .then(res => {
        res.data.success
          ? this.setAlertMessage("success", "Salvo com sucesso!")
          : this.setAlertMessage("error", "Ocorreu um erro ao salvar!");
      })
      .catch(() => this.setAlertMessage("error", "Ocorreu um erro ao salvar!"));
  };

  getCouncil() {
    Api.post(`/microservice/profile/getCouncil/`, {})
      .then(res => {
        this.setState({ council: res.data });
      })
      .catch(() =>
        this.setAlertMessage("error", "Ocorreu um erro ao buscar council!")
      );
  }

  getState() {
    Api.post(`/microservice/profile/getState/`, {})
      .then(res => {
        this.setState({ state: res.data });
      })
      .catch(() =>
        this.setAlertMessage("error", "Ocorreu um erro ao buscar council!")
      );
  }

  setAlertMessage = (type, message) => {
    this.props.viewAlertMessage(type, message);
    setTimeout(() => this.props.closeAlertMessage(), 5000);
  };

  handleChangeBasic = name => event => {
    this.props.handleChange(name, event.target.value);
  };

  handleChangeAutocomplete = (index, value) => {
    this.props.handleChange(index, value);
  };

  calcAge = () => {
    let today = new Date();
    let birthDate = new Date(this.props.basicData.birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
      m = 11;
    }
    age = age + " Ano(s)";
    if (m > 0) {
      age = age + " " + m + " Mes(es)";
    }
    this.props.handleChange("age", age);
  };

  render() {
    const { basicData } = this.props;
    const council = this.state.council;
    const state = this.state.state;
    return (
      <div className="row">
        <div className="col-md-12">
          <span className="card-title text-primary title-form">
            Informações Profissionais
          </span>
        </div>
        <div className="col-md-6 col-12">
          <TextField
            id="area"
            label="Área"
            value={basicData.area}
            InputProps={{ readOnly: true }}
            margin="normal"
            fullWidth
            onChange={this.handleChangeBasic("name")}
          />
        </div>

        <div className="col-md-6 col-12">
          <TextField
            id="conselho"
            select
            label="Conselho:"
            value={basicData.council}
            margin="normal"
            fullWidth
            onChange={this.handleChangeBasic("council")}
            InputLabelProps={{
              shrink: true
            }}
          >
            {council.map(row => {
              return (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="numberRegister"
            type="number"
            label="Número de registro*:"
            value={basicData.numberRegister}
            margin="normal"
            fullWidth
            onChange={this.handleChangeBasic("numberRegister")}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="uf"
            select
            label="UF:"
            value={basicData.state}
            onChange={this.handleChangeBasic("state")}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
            InputLabelProps={{
              shrink: true
            }}
          >
            {state.map(row => {
              return (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              );
            })}
          </TextField>
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="anoRegistro"
            inputProps={{ maxLength: 4 }}
            onInput={e => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
            label="Ano do registro*:"
            value={basicData.yearRegister}
            margin="normal"
            fullWidth
            onChange={this.handleChangeBasic("yearRegister")}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>

        <div className="col-md-12 text-right">
          <Button
            onClick={() => this.submit()}
            className="jr-btn jr-btn-sm bg-success"
            color="primary"
            variant="contained"
          >
            <i className="zmdi zmdi-check text-right" />
            <span> Salvar </span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.authUser,
    Profile: state.Profile,
    paramsConsult: state.paramsConsult,
    basicData: state.Profile.basic,
    all_recreations: state.Profile.all_recreations,
    recreations_user: state.Profile.recreations_user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { handleChange, handleChangeRecreation, ...alertActions },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalData);
