import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
  TableCell,
} from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan } from "components/Button";
import NoRecord from "components/Alert/NoRecord";
import Api from "services/api";

export default function ListDiagnosticGroups({ record, edit, handleRemove }) {
  const headerTable = {
    color: "rgba(0, 0, 0, 0.35)",
    height: "40px",
  };

  const [modal, setModal] = React.useState({
    open: false,
    id: "",
  });

  const [view, setView] = React.useState({
    open: false,
    data: {},
    list: [],
  });

  const getData = (obj) => {
    Api.post(`/microservice/diagnosticgroup/getItensGroup/`, {
      id: obj.id,
    }).then((res) => {
      setView({
        open: true,
        data: obj,
        list: res.data.record,
      });
    });
  };

  return (
    <div
      className="col-12"
      style={{
        minWidth: "650",
        overflowX: "auto",
      }}
    >
      {record.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={headerTable}> Grupo </TableCell>
              <TableCell style={headerTable}>Descrição</TableCell>
              <TableCell style={headerTable} align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.map((row) => {
              return (
                <TableRow>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Visualizar">
                      <IconButton
                        arial-label="Visualizar"
                        color="primary"
                        onClick={() => {
                          getData(row);
                        }}
                      >
                        <i className="zmdi zmdi-eye" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                      <IconButton
                        arial-label="Editar"
                        color="primary"
                        onClick={() => edit(row)}
                      >
                        <i className="zmdi zmdi-edit" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir">
                      <IconButton
                        arial-label="Excluir"
                        color="secondary"
                        onClick={() => setModal({ open: true, id: row.id })}
                      >
                        <i className="zmdi zmdi-delete" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <NoRecord />
      )}
      <SweetAlert
        show={modal.open}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, remover"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Confirma a remoção deste grupo?"
        onConfirm={() => {
          handleRemove(modal.id);
          setModal({ open: false, id: "" });
        }}
        onCancel={() => setModal({ open: false, id: "" })}
      />

      <ModalResponsive
        open={view.open}
        close={() => setView({ open: false, data: {}, list: [] })}
        maxWidth="md"
        title="Grupo de diagnósticos"
        buttons={
          <div className="col-12">
            <BtnCan
              title="Fechar"
              onClick={() => setView({ open: false, data: {}, list: [] })}
              float="left"
            />
          </div>
        }
      >
        {view.list && (
          <div className="row">
            <div className="col-12">
              <strong>Grupo:</strong> {view.data.name}
            </div>
            <div className="col-12">
              <strong>Descrição:</strong> {view.data.description}
            </div>

            <div className="col-12">
              <strong>Diagnósticos (CID):</strong> <br />
              <ul>
                {view.list.map((row) => (
                  <li className="mt-1">
                    {row.name} {row.observation && `  (${row.observation})`}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </ModalResponsive>
    </div>
  );
}
