import React from "react";
import UserCell from "./UserCell/index";
import moment from "moment";

const ChatUserList = ({ chatUsers, selectedSectionId, onSelectUser }) => {
  chatUsers.sort((a, b) => {
    if (
      moment(a.lastMessage.sentAt, "DD/MM/YYYY HH:mm:ss") >
      moment(b.lastMessage.sentAt, "DD/MM/YYYY HH:mm:ss")
    ) {
      return -1;
    }
    if (
      moment(a.lastMessage.sentAt, "DD/MM/YYYY HH:mm:ss") <
      moment(b.lastMessage.sentAt, "DD/MM/YYYY HH:mm:ss")
    ) {
      return 1;
    }
    // a must be equal to b
    return 0;
  });
  return (
    <div className="chat-user">
      {chatUsers.map((chat, index) => (
        <UserCell
          key={index}
          chat={chat}
          selectedSectionId={selectedSectionId}
          onSelectUser={onSelectUser}
        />
      ))}
    </div>
  );
};

export default ChatUserList;
