import React from "react";
import DataTable from "components/Tables/DataTable";

export default function ListProcedures({ record }) {
  return (
    <DataTable
      name="Procedimentos Realizados"
      columns={["Procedimento", "Quantidade"]}
      rows={record.map((row, index) => [row.procedure, row.total])}
      hideSearch={true}
    />
  );
}
