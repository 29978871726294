import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "services/api";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { SketchPicker } from "react-color";
import { Button, TextField, Grid, MenuItem } from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";

export default function EditCalendar({
  colorText,
  colorEvent,
  title,
  idInit,
  calendarList,
  closeMenu,
  width,
}) {
  const dispatch = useDispatch();
  const [modal_form, setModalForm] = useState(false);
  const [id, setId] = useState(idInit);
  const auth = useSelector((state) => state.auth.authUser);
  const [state, setState] = useState({
    colorEvent: colorEvent,
    displayColorEvent: false,
    displayTextEvent: false,
    textColor: colorText,
    title: title,
    error: false,
  });

  useEffect(() => {
    setState({
      colorEvent: colorEvent,
      displayColorEvent: false,
      displayTextEvent: false,
      textColor: colorText,
      title: title,
      error: false,
    });
    setId(idInit);
  }, [title]);
  const ComponentColorEvent = () => (
    <div className="col-12 mt-3">
      <Grid
        component="label"
        container
        alignItems="center"
        fullWidth
        style={{ marginTop: 15 }}
      >
        <Grid item style={{ width: "20%" }}>
          <span
            style={{
              color: "#0000008a",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "1.3125rem",
            }}
          >
            Cor dos eventos:
          </span>
        </Grid>
        <Grid item style={{ width: "80%", paddingRight: 12 }}>
          <div
            className="cp-swatch ml-3"
            style={{ width: "100%", marginRigth: 10 }}
            onClick={() => {
              setState({
                ...state,
                displayColorEvent: !state.displayColorEvent,
              });
            }}
          >
            <div
              className="cp-color"
              style={{ backgroundColor: state.colorEvent, width: "100%" }}
            />
          </div>
          {state.displayColorEvent ? (
            <div
              className="cp-popover"
              style={{ position: "fixed", width: 250, marginLeft: 15 }}
            >
              <div
                className="cp-cover"
                onClick={() =>
                  setState({
                    ...state,
                    displayColorEvent: !state.displayColorEvent,
                  })
                }
              />
              <SketchPicker
                width="100%"
                color={state.colorEvent}
                onChange={(val) => {
                  setState({ ...state, colorEvent: val.hex });
                }}
              />
            </div>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );

  const ComponentColorText = () => (
    <div className="col-12 mt-3">
      <Grid
        component="label"
        container
        alignItems="center"
        fullWidth
        style={{ marginTop: 15 }}
      >
        <Grid item style={{ width: "20%" }}>
          <span
            style={{
              color: "#0000008a",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "1.3125rem",
            }}
          >
            Cor do texto:
          </span>
        </Grid>
        <Grid item style={{ width: "80%", paddingRight: 12 }}>
          <div
            className="cp-swatch ml-3"
            style={{ width: "100%", marginRigth: 10 }}
            onClick={() =>
              setState({
                ...state,
                displayTextEvent: !state.displayTextEvent,
              })
            }
          >
            <div
              className="cp-color"
              style={{ backgroundColor: state.textColor, width: "100%" }}
            />
          </div>
          {state.displayTextEvent ? (
            <div
              className="cp-popover"
              style={{ position: "fixed", width: 250, marginLeft: 15 }}
            >
              <div
                className="cp-cover"
                onClick={() =>
                  setState({
                    ...state,
                    displayTextEvent: !state.displayTextEvent,
                  })
                }
              />
              <SketchPicker
                width="100%"
                color={state.textColor}
                onChange={(val) => setState({ ...state, textColor: val.hex })}
              />
            </div>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );

  const save = () => {
    if (state.title) {
      const obj = {
        ...state,
        auth,
        id,
      };
      Api.post(`/ms_system/calendar/saveNewCalendar/`, obj).then((res) => {
        if (res.data.success) {
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
          setModalForm(false);
          setId(res.id);
          calendarList(res.data);
          closeMenu();
        }
      });

      setState({ ...state, error: false });
    } else {
      setState({ ...state, error: true });
    }
  };

  return (
    <>
      {width > 500 ? (
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 15, marginLeft: 10 }}
          onClick={() => setModalForm(true)}
        >
          <i className="zmdi zmdi-edit mr-3" /> Editar Agenda
        </Button>
      ) : (
        <MenuItem onClick={() => setModalForm(true)}>
          <i className="zmdi zmdi-edit mr-3" /> Editar Agenda
        </MenuItem>
      )}

      <ModalResponsive
        title="Editar Agenda"
        open={modal_form}
        close={() => {
          setModalForm(false);
          closeMenu();
        }}
        maxWidth="sm"
        buttons={
          <div className="col-md-12 mt-3">
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              onClick={() => {
                setModalForm(false);
                closeMenu();
              }}
              style={{ float: "left" }}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              onClick={() => save()}
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Salvar </span>
            </Button>
          </div>
        }
      >
        <div className="row">
          <div className="col-12" style={{ marginTop: 10 }}>
            <TextField
              label="Título"
              required
              fullWidth
              value={state.title}
              onChange={(e) => setState({ ...state, title: e.target.value })}
              error={state.error && !state.title ? true : false}
            />
          </div>
          {ComponentColorEvent()}
          {ComponentColorText()}
        </div>
      </ModalResponsive>
    </>
  );
}
