import React, { useState } from "react";
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import { BtnCan, BtnAdd } from "components/Button";
import AutocompleteCids from "components/Autocomplete/Cid";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAdd({ open, handleSelect, handleClose }) {
  const [listCids, setListCids] = useState([]);
  const user = useSelector((state) => state.auth.authUser);

  const handleChangeAutocomplete = () => (event) => {
    if (event.id) {
      let achou = false;
      listCids.forEach((cid) => {
        if (cid.id === event.id) {
          achou = !achou;
        }
      });
      !achou && setListCids([...listCids, { ...event, obs: "" }]);
    }
  };

  const changeObsCid = (id) => (e) => {
    setListCids(
      listCids.map((cid) => {
        if (cid.id === id) {
          cid.obs = e.target.value;
        }
        return cid;
      })
    );
  };

  const handleAdd = () => {
    handleSelect(
      listCids.map((row) => ({
        cid: row.id,
        short_description: row.short_description,
        checked: true,
        observation: row.obs,
        alter: true,
      }))
    );
    handleClose();
    setListCids([]);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"sm"}
      fullWidth={true}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Adicionar Diagnóstico</DialogTitle>
      <DialogContent className="mt-0 pt-0">
        <AutocompleteCids
          autoFocus={true}
          handleChangeAutocomplete={handleChangeAutocomplete()}
        />
        {listCids.length > 0 && (
          <Table style={{ marginTop: "20px" }}>
            <TableHead>
              <TableRow>
                <TableCell>CID</TableCell>
                <TableCell>Observações</TableCell>
                <TableCell>Remover</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listCids.map((cid) => (
                <TableRow key={cid.id}>
                  <TableCell>{cid.short_description}</TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      placeholder="Observações"
                      value={cid.obs}
                      onChange={changeObsCid(cid.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Delete"
                      color="secondary"
                      onClick={() =>
                        setListCids(listCids.filter((el) => el.id !== cid.id))
                      }
                    >
                      <i className="zmdi zmdi-delete" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <div className="col-md-12" style={{ marginTop: "5px" }}>
          <BtnAdd title={"adicionar"} onClick={handleAdd} float="right" />
          <BtnCan title={"Cancelar"} onClick={handleClose} float="left" />
        </div>
      </DialogActions>
    </Dialog>
  );
}
