import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import { Grid } from "@material-ui/core";
import Dropzone from "react-dropzone";

import Backup from "@material-ui/icons/Backup";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DescriptionIcon from "@material-ui/icons/Description";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";
import InfoIcon from "@material-ui/icons/Info";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

const style = (theme) => ({
  displayNone: {
    display: "none",
  },
  iconButtonDelete: {
    "&:hover": {
      color: "#bc0003",
    },
  },
});

const extensions_default =
  "image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
/**
 * how to use
 * @returnAcceptedFiles função que retorna arquivos aceitos, esta props é OBRIGATÓRIA
 * @extensions string com o MIME types aceitos, se não for informado será usado @extensions_default
 * @max_files inteiro com o número máximo de arquivos que podem ser inseridos
 * fim
 */
class CustomDropzone extends Component {
  state = {
    accepted: [],
    rejected: [],
  };

  returnTypeCommon = (type) => {
    switch (type) {
      case "application/pdf":
        return "PDF";
      case "application/msword":
        return "DOC";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "DOCX";
      case "application/vnd.ms-excel":
        return "XLS";
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "XLSX";
      default:
        return "N/A";
    }
  };

  setAccepted = () => {
    this.setState({ accepted: [], rejected: [] });
    this.props.returnAcceptedFiles([]);
  };

  deleteItem = (index) => {
    this.setState({
      ...this.state,
      accepted: this.state.accepted.filter((row, idx) => index !== idx),
    });
  };

  render() {
    const {
      classes,
      extensions,
      max_files,
      widthDrop,
      heightDrop,
    } = this.props;
    return (
      <Grid item>
        <div className="col-12">
          <div
            className="row dropzone-card"
            style={{ marginTop: "20px", color: "#929293" }}
          >
            <div
              className={
                this.state.accepted.length > 0 || this.state.rejected.length > 0
                  ? "col-md-4 dropzone d-flex justify-content-start"
                  : "col-12 dropzone"
              }
            >
              <Dropzone
                accept={
                  extensions !== undefined ? extensions : extensions_default
                }
                style={{
                  width: widthDrop ? widthDrop : "unset",
                  height: heightDrop ? heightDrop : "269px",
                  borderStyle: "dashed",
                  borderRadius: "5px",
                }}
                onDrop={(accepted, rejected) => {
                  if (max_files !== undefined) {
                    console.log("accepted", accepted);
                    if (
                      accepted.length + this.state.accepted.length <=
                      max_files
                    ) {
                      this.setState({
                        accepted: [...this.state.accepted, ...accepted],
                        rejected,
                      });
                      this.props.returnAcceptedFiles(accepted);
                    } else {
                      this.setState({ rejected: rejected });
                      this.props.returnAcceptedFiles([]);
                    }
                  } else {
                    this.setState({ accepted, rejected });
                    this.props.returnAcceptedFiles(accepted);
                  }
                }}
              >
                <div className="justify-content-center">
                  <div className="d-flex justify-content-center">
                    <Backup style={{ fontSize: "3.5rem", color: "#929293" }} />
                  </div>
                  <div className="text-center">
                    <i className="material-icons">
                      Arraste o seu arquivo ou click para selecioná-lo
                    </i>
                  </div>
                </div>
              </Dropzone>
              {max_files !== undefined && this.state.accepted.length <= 0 ? (
                <div className="col-12 d-flex" style={{ color: "#9e0000" }}>
                  <div className="col-12 d-flex justify-content-center">
                    <InfoIcon className="mr-2" />
                    <i className="material-icons">
                      Número máximo de arquivos: {max_files}
                    </i>
                  </div>
                </div>
              ) : null}
            </div>
            <div
              className={`col-md-8 dropzone-content ${
                this.state.accepted.length <= 0 &&
                this.state.rejected.length <= 0
                  ? classes.displayNone
                  : ""
              }`}
              style={{ textAlign: "left" }}
            >
              {this.state.accepted.length > 0 && (
                <List className="upload-file-list">
                  <h2 style={{ fontWeight: "40", fontSize: "27px" }}>
                    Arquivos Selecionados
                  </h2>
                  {this.state.accepted.map((f, index) => (
                    <ListItem
                      key={index}
                      button
                      className="d-flex border-bottom"
                      style={{ margin: "0px", padding: "2px" }}
                    >
                      <Tooltip
                        placement="top"
                        title={
                          f.type === "image/png" || f.type === "image/jpeg" ? (
                            <img
                              style={{
                                height: "188px",
                                width: "188px",
                              }}
                              alt=""
                              src={f.preview}
                            />
                          ) : (
                            this.returnTypeCommon(f.type)
                          )
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            {f.type === "image/png" ||
                            f.type === "image/jpeg" ? (
                              <img
                                style={{
                                  height: "-webkit-fill-available",
                                  width: "-webkit-fill-available",
                                }}
                                alt=""
                                src={f.preview}
                              />
                            ) : (
                              <DescriptionIcon />
                            )}
                          </Avatar>
                        </ListItemAvatar>
                      </Tooltip>
                      <ListItemText
                        style={{ textAlign: "left" }}
                        primary={f.name}
                        secondary={`${f.size} bytes - ${String(
                          f.lastModifiedDate
                        ).substring(0, 23)}`}
                      />

                      <IconButton
                        aria-label="delete"
                        onClick={() => this.deleteItem(index)}
                      >
                        <DeleteIcon
                          className={classes.iconButtonDelete}
                          style={{ fontSize: "1.3rem", cursor: "hand" }}
                        />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              )}
              {this.state.rejected.length > 0 && (
                <ul className="upload-file-list mt-3">
                  <h2
                    style={{
                      fontWeight: "40",
                      fontSize: "27px",
                      marginTop: "3px",
                    }}
                  >
                    Não foi possível inserir os arquivos
                  </h2>
                  {this.state.rejected.map((f) => (
                    <ListItem
                      button
                      className="d-flex border-bottom"
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <ErrorIcon className="mr-1" />
                      <ListItemText
                        style={{ textAlign: "left" }}
                        primary={f.name}
                      />
                      {f.size} bytes
                    </ListItem>
                  ))}
                </ul>
              )}
              {this.state.accepted.length > 0 && (
                <div
                  className="d-flex justify-content-end"
                  style={{ align: "right" }}
                  onClick={this.setAccepted}
                >
                  <ClearIcon style={{ fontSize: "1.3rem", cursor: "hand" }} />
                  <p>Limpar</p>
                </div>
              )}{" "}
            </div>
          </div>
        </div>
      </Grid>
    );
  }
}

CustomDropzone.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(CustomDropzone);
