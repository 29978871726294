import { all, takeLatest, put } from "redux-saga/effects";
import apiFile from "services/apiFile";

async function savePhotoPatient(action) {
  let formData = new FormData();
  formData.append("patient", action.payload.patient);

  if (typeof action.payload.image.type !== "string") {
    let block = action.payload.image.split(";");
    let contentType = block[0].split(":")[1];
    let realData = block[1].split(",")[1];
    let blob = await b64toBlob(realData, contentType);
    formData.append("image", blob);
  } else {
    formData.append("image", action.payload.image);
  }

  apiFile.post(`/ms_system/calendar/savePhotoPatient/`, formData);
}

async function savePhotoRegister(action) {
  let formData = new FormData();
  // formData.append("patient", action.payload.patient);

  if (typeof action.payload.image.type !== "string") {
    let block = action.payload.image.split(";");
    let contentType = block[0].split(":")[1];
    let realData = block[1].split(",")[1];
    let blob = await b64toBlob(realData, contentType);
    formData.append("image", blob);
  } else {
    formData.append("image", action.payload.image);
  }

  apiFile.post(`/ms_system/user/savePhotoUser/`, formData);
}

/**
 * RESET REDUCERS ADMISSION
 */
function* asyncResetAdmission(action) {
  yield put({ type: "UNSET_PATIENT_INFO" });
  yield put({ type: "RESET_ALLERGIES" });
  yield put({ type: "RESET_VACCINES" });
  yield put({ type: "RESET_ADMISSION" });
  yield put({ type: "RESET_PRESCRIPTION" });
  yield put({ type: "RESET_MEDICINES_USE" });
}

/**
 * RESET ALL REDUCERS
 */

function* asyncResetAll(action) {
  yield put({ type: "UNSET_PATIENT_INFO" });
  yield put({ type: "RESET_AGA" });
  yield put({ type: "RESET_ALLERGIES" });
  yield put({ type: "RESET_APPOINTMENT" });
  yield put({ type: "RESET_ATTENDANCE_RECORD" });
  yield put({ type: "RESET_CHILD_DISEASE" });
  yield put({ type: "RESET_CIRCUMFERENCES" });
  yield put({ type: "RESET_CLINICAL_RATE" });
  yield put({ type: "RESET_COMPARE_PICTURES" });
  yield put({ type: "RESET_DYSFUNCTIONS" });
  yield put({ type: "RESET_EXAMS" });
  yield put({ type: "RESET_EXAMS_GROUP" });
  yield put({ type: "RESET_FAMILY_HISTORY" });
  yield put({ type: "RESET_FORWARD" });
  yield put({ type: "RESET_HABITS" });
  yield put({ type: "RESET_MEDICINES_USE" });
  yield put({ type: "RESET_NURSE_PRESCRIPTION" });
  yield put({ type: "RESET_PAIN_SCALE" });
  yield put({ type: "RESET_PERSONAL_DATA" });
  yield put({ type: "RESET_PERSONAL_HISTORY" });
  yield put({ type: "RESET_PLATE_INDEX" });
  yield put({ type: "RESET_PRESCRIPTION" });
  yield put({ type: "RESET_PRESCRIPTION_MANIPULATED" });
  yield put({ type: "RESET_PROCEDURE" });
  yield put({ type: "RESET_SAE" });
  yield put({ type: "RESET_TAGS" });
  yield put({ type: "RESET_TBCA" });
  yield put({ type: "RESET_VACCINES" });
  yield put({ type: "RESET_REMINDER" });
  yield put({ type: "RESET_FOOD_PLAN" });
  yield put({ type: "RESET_CHAT" });
  yield put({ type: "RESET_ADMISSION" });
  yield put({ type: "RESET_STOCK" });

  if (action.opt === "FINISH") {
    yield put({ type: "FINISH_CONSULT" });
    yield put({ type: "FINISH_APPOINTMENT_SUCCESS" });
  }
  if (action.opt === "SIGNOUT") {
    yield put({ type: "SIGNOUT_USER_SUCCESS" });
    yield put({ type: "RESET_EVOLUTION_DAILY" });
  }
  if (action.opt === "SIGNIN") {
    yield put({ type: "RESET_ADD_PROCEDURE" });
    yield put({ type: "RESET_CALENDAR" });
    yield put({ type: "RESET_EVOLUTION_GROUP" });
    yield put({ type: "RESET_EVOLUTION_PERSONAL" });
    yield put({ type: "RESET_FINANCE" });
    yield put({ type: "RESET_MEDICINE_GROUP" });
    yield put({ type: "RESET_PROCEDURE_GROUPS" });
    yield put({ type: "RESET_TAGS_CONDITIONS" });
    yield put({ type: "RESET_TYPE_EVENT" });
    yield put({ type: "RESET_WORKPLACE" });
    yield put({ type: "SIGNOUT_INTRANET" });
    yield put({ type: "RESET_CONFIGURATION" });
    yield put({ type: "RESET_EVOLUTION_DAILY" });
    yield put({ type: "RESET_ALL_PERMISSIONS" });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest("ALTER_PHOTO", savePhotoPatient),
    takeLatest("SAVE_PHOTO_SIGNUP", savePhotoRegister),
    takeLatest("RESET_ALL", asyncResetAll),
    takeLatest("RESET_ALL_ADMISSION", asyncResetAdmission),
  ]);
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
