export function getAttendanceRecord() {
  return {
    type: "GET_ATTENDANCE_RECORD",
  };
}

export function handleChangeRegister(index, value) {
  return {
    type: "HANDLE_CHANGE_REGISTER",
    index,
    value,
  };
}

export function handleChange(index, value) {
  return {
    type: "HANDLE_CHANGE",
    index,
    value,
  };
}

// Procedimentos
export function addProcedure(procedure) {
  return {
    type: "ADD_LIST_PROCEDURE",
    payload: procedure,
  };
}

export function removeProcedure(procedure) {
  return {
    type: "REMOVE_LIST_PROCEDURE",
    payload: procedure,
  };
}

export function changeObsProcedure(procedure, value) {
  return {
    type: "CHANGE_OBS_PROCEDURE",
    procedure,
    value,
  };
}

export function getProceduresGroupsInfos(id) {
  return {
    type: "GET_INFO_PROCEDURE_GROUP",
    payload: { id },
  };
}

export function setSelectedProcedure(id, value) {
  return {
    type: "SET_SELECTED_PROCEDURE",
    payload: { id, value },
  };
}

export function setGroupOutros() {
  return {
    type: "SET_OUTROS",
  };
}

export function alterEditRegAtt() {
  return {
    type: "ALTER_EDITED_REGISTER_ATTENDANCE",
  };
}

export function initRegAtt(consult_info, restrict_info) {
  return {
    type: "INIT_REG_ATTENDANCE",
    consult_info,
    restrict_info,
  };
}
