export const ALLERGIES = "1";

export const SYMPTOMS_ARRAY = [
  {
    id: "5243",
    description: "Febre",
    status: false,
    type: 1,
    title: "Sintomas Comuns",
  },
  { id: "5244", description: "Tosse Seca", status: false, type: 1 },
  { id: "5245", description: "Cansaço/Fadiga", status: false, type: 1 },
  {
    id: "5246",
    description: "Tosse Produtiva",
    status: false,
    type: 2,
    title: "Sintomas menos Comuns",
  },
  {
    id: "5247",
    description: "Dores e Desconfortos/Mialgia",
    status: false,
    type: 2,
  },
  { id: "5248", description: "Dor e Garganta", status: false, type: 2 },
  { id: "5249", description: "Diarreia", status: false, type: 2 },
  { id: "5250", description: "Conjutivite", status: false, type: 2 },
  { id: "5252", description: "Dor de Cabeça/Cefaléia", status: false, type: 2 },
  {
    id: "5253",
    description: "Perda de Olfato ou Paladar",
    status: false,
    type: 2,
  },
  {
    id: "5254",
    description: "Erupção Cutânea/Descoloração dos dedos",
    status: false,
    type: 2,
  },
  {
    id: "5264",
    description: "Dificuldade de Respirar/Falta de Ar",
    status: false,
    type: 3,
    title: "Sistomas Graves",
  },
  {
    id: "5255",
    description: "Dor ou Pressão no Peito",
    status: false,
    type: 3,
  },
  {
    id: "5258",
    description: "Cianose(Lábio e extremidades roxeadas)",
    status: false,
    type: 3,
  },
  { id: "5260", description: "Dissaturação < 90%", status: false, type: 3 },
  {
    id: "5261",
    description: "Ederma de membro inferior",
    status: false,
    type: 3,
  },
];
