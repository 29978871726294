import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserManage, setListAddress } from "actions/Auth";
import { Card } from "reactstrap";
import Api from "services/api";
import Attendance from "../../components/attendance";
import Calendar from "../../components/calendar";
import SelectUserManage from "./SelectUserManage";

export default function DashboardSecretary({ match }) {
  const user = useSelector(state => state.auth.authUser);
  const user_manage = useSelector(state => state.auth.user_manage);
  const loaded_address = useSelector(state => state.auth.loaded_address);
  const [modal_select, setModalSelect] = useState(false);
  const [users, setUsers] = useState([]);
  const [userManagement, setUserManagement] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded_address) {
      Api.post(`/ms_system/dashboard/getAddressesSecretary`, {
        user
      }).then(res => {
        let { record } = res.data;
        dispatch(setListAddress(record));
        if (!user_manage.id) {
          let list_users = record.map(row => row.user_id);
          let user_manage = list_users[0];
          let find = false;
          let arr_users = [list_users[0]];
          for (let index = 1; index < list_users.length; index++) {
            if (!find) {
              find = list_users[index] !== user_manage ? true : false;
            }
            let isset = arr_users.filter(row => row !== list_users[index]);
            if (isset.length > 0) {
              arr_users.push(list_users[index]);
            }
          }
          if (!find) {
            setUserManagement(user_manage);
          } else {
            setUsers(arr_users);
            setModalSelect(true);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (userManagement && !user_manage.id) {
      Api.post(`/microservice/consult/getInfoUser`, {
        user: userManagement
      }).then(res => {
        let { id, type, provider_type } = res.data.record;
        dispatch(setUserManage(id, type, provider_type));
      });
    }
  }, [userManagement]);

  return (
    <div className="row d-flex justify-content-center ml-1">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-1 justify-content-center m-0 p-0">
        <Card className="shadow border-0 mb-3 mt-1">
          <Attendance />
        </Card>
        <Card className="shadow border-0 mb-3">
          <Calendar match={match} />
        </Card>
      </div>
      <SelectUserManage
        open={modal_select}
        users={users}
        select={val => {
          setUserManagement(val);
          setModalSelect(false);
        }}
      />
    </div>
  );
}
