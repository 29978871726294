import React, { Component } from "react";
import api from "services/api";

export default class ViewPeriodotogram extends Component {
  state = {
    record: []
  };
  componentDidMount() {
    api
      .post(`/microservice/od_periodotogram/getHistoryPeriodotogram`, {
        id: this.props.selected
      })
      .then(res => {
        this.setState({ record: res.data.list });
      });
  }
  render() {
    return (
      this.state.record.length > 0 && (
        <div className="row">
          {this.state.record.map(row => (
            <div className="col-12" key={row.id}>
              <h4 style={{ fontWeight: "bold" }}>{`Dente ${row.code}`}</h4>

              <div className="row d-flex mt-20">
                <div className="col-12" style={{ fontWeight: "bold" }}>
                  Profundidade de Sondagem
                </div>
                <div className="col-md-3">
                  D: {row.ps_d === null || row.ps_d === "" ? "--" : row.ps_d}
                </div>
                <div className="col-md-3">
                  V: {row.ps_v === null || row.ps_v === "" ? "--" : row.ps_v}
                </div>
                <div className="col-md-3">
                  M: {row.ps_m === null || row.ps_m === "" ? "--" : row.ps_m}
                </div>
                <div className="col-md-3">
                  L: {row.ps_l === null || row.ps_l === "" ? "--" : row.ps_l}
                </div>
              </div>
              <div className="row d-flex mt-20">
                <hr />
                <div className="col-12" style={{ fontWeight: "bold" }}>
                  Nível de Inserção
                </div>
                <div className="col-md-3">
                  D: {row.ni_d === null || row.ni_d === "" ? "--" : row.ni_d}
                </div>
                <div className="col-md-3">
                  V: {row.ni_v === null || row.ni_v === "" ? "--" : row.ni_v}
                </div>
                <div className="col-md-3">
                  M: {row.ni_m === null || row.ni_m === "" ? "--" : row.ni_m}
                </div>
                <div className="col-md-3">
                  L: {row.ni_l === null || row.ni_l === "" ? "--" : row.ni_l}
                </div>
              </div>

              <div className="row d-flex mt-20">
                <hr />
                <div className="col-12" style={{ fontWeight: "bold" }}>
                  Sangramento à Sondagem
                </div>
                <div className="col-md-3">
                  D: {row.ss_d === null || row.ss_d === "" ? "--" : row.ss_d}
                </div>
                <div className="col-md-3">
                  V: {row.ss_v === null || row.ss_v === "" ? "--" : row.ss_v}
                </div>
                <div className="col-md-3">
                  M: {row.ss_m === null || row.ss_m === "" ? "--" : row.ss_m}
                </div>
                <div className="col-md-3">
                  L: {row.ss_l === null || row.ss_l === "" ? "--" : row.ss_l}
                </div>
              </div>

              <div className="row d-flex">
                <div className="col-4 col-md-4 col-sm-12">
                  <span style={{ fontWeight: "bold" }}>Furca: </span>
                  {row.furca ? (
                    row.furca
                  ) : (
                    <span
                      style={{
                        fontStyle: "italic",
                        padding: "0px",
                        margin: "0px"
                      }}
                    >
                      Não Informado
                    </span>
                  )}
                </div>
                <div className="col-4 col-md-4 col-sm-12">
                  <span style={{ fontWeight: "bold" }}>Mobilidade: </span>
                  {row.mobility ? (
                    row.mobility
                  ) : (
                    <span
                      style={{
                        fontStyle: "italic",
                        padding: "0px",
                        margin: "0px"
                      }}
                    >
                      Não Informado
                    </span>
                  )}
                </div>
                <div className="col-4 col-md-4 col-sm-12">
                  <span style={{ fontWeight: "bold" }}>Prognóstico: </span>
                  {row.prognostic ? (
                    row.prognostic
                  ) : (
                    <span
                      style={{
                        fontStyle: "italic",
                        padding: "0px",
                        margin: "0px"
                      }}
                    >
                      Não Informado
                    </span>
                  )}
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>
      )
    );
  }
}
