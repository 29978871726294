import { withStyles, Slider } from "@material-ui/core";

const CustomSlider = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem",
    },
  },
  thumb: {
    backgroundColor: "#fff",
    border: "1px solid #707070",
  },
  valueLabel: {
    left: "calc(-50% - 3px)",
    top: "-25px",
    "& > span": {
      width: "1.6rem",
      height: "1.6rem",
    },
  },
}))(Slider);

export default CustomSlider;
