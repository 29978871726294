import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
  TableCell,
} from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import * as FormGroupActions from "actions/configuration/ProcedureGroups";
import { bindActionCreators } from "redux";
import NoRecord from "components/Alert/NoRecord";
import { ModalResponsive } from "components/ModalResponsive";
import Api from "services/api";
import { BtnCan } from "components/Button";

class ViewProcedureGroups extends Component {
  state = {
    modal_delete: false,
    idGroupDelete: "",
    success_delete: false,
    success_add: false,
    children_form: "",
    modal_view: false,
    data_view: {},
  };

  handleAdd = (id) => (event) => {
    this.props.handleAddFunction(
      "form",
      "secondary",
      "cancelar",
      true,
      "close",
      id
    );
  };

  modalConfirmStats() {
    this.setState({ modal_delete: true });
  }

  componentWillMount() {
    this.props.requestListDoctorProceduresGroups(this.props.doctor);
  }

  getData(obj) {
    Api.post(`/microservice/proceduresGroups/getInfoProceduresGroups/`, {
      id: obj.id,
    }).then((res) => {
      this.setState({
        modal_view: true,
        data_view: {
          id: obj.id,
          name: obj.name,
          description: obj.description,
          list: res.data.ItensProcedures,
        },
      });
    });
  }

  render() {
    const headerTable = {
      color: "rgba(0, 0, 0, 0.35)",
      height: "40px",
    };
    const listProceduresGroups = this.props.ProcedureGroups
      .listProceduresGroups;
    const { modal_view, data_view } = this.state;
    return (
      <form>
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={headerTable}> Grupo </TableCell>
                <TableCell style={headerTable}>Descrição</TableCell>
                <TableCell style={headerTable} align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listProceduresGroups.map((listProcGroups) => {
                return (
                  <TableRow key={listProcGroups.id}>
                    <TableCell>{listProcGroups.name}</TableCell>
                    <TableCell>{listProcGroups.description}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Visualizar">
                        <IconButton
                          arial-label="Visualizar"
                          color="primary"
                          onClick={() => {
                            this.getData(listProcGroups);
                          }}
                        >
                          <i className="zmdi zmdi-eye" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Editar">
                        <IconButton
                          arial-label="Editar"
                          color="primary"
                          onClick={this.handleAdd(listProcGroups.id)}
                        >
                          <i className="zmdi zmdi-edit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Excluir">
                        <IconButton
                          arial-label="Excluir"
                          color="secondary"
                          onClick={() =>
                            this.setState({
                              modal_delete: true,
                              idGroupDelete: listProcGroups.id,
                            })
                          }
                        >
                          <i className="zmdi zmdi-delete" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
              {listProceduresGroups.length === 0 && (
                <TableRow key="1">
                  <TableCell colSpan={4}>
                    <NoRecord />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <SweetAlert
            show={this.state.modal_delete}
            warning
            showCancel
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            confirmBtnText="Sim, excluir"
            confirmButtonColor="#bc0003"
            cancelBtnText="Cancelar"
            title="Excluir este Grupo?"
            onConfirm={() => {
              this.setState({ modal_delete: false });
              this.props.delGroup(this.state.idGroupDelete);
            }}
            onCancel={() => this.setState({ modal_delete: false })}
          />
        </div>
        <SweetAlert
          show={this.props.ProcedureGroups.modalSuccessDeleteGroup}
          success
          title="Grupo excluido com sucesso!"
          onConfirm={() => this.props.closeModalDeleteSuccess()}
        />
        <ModalResponsive
          open={modal_view}
          close={() => this.setState({ modal_view: false, data_view: {} })}
          maxWidth="md"
          title="Grupo de procedimentos"
          buttons={
            <div className="col-12">
              <BtnCan
                title="Fechar"
                onClick={() =>
                  this.setState({ modal_view: false, data_view: {} })
                }
                float="left"
              />
            </div>
          }
        >
          {data_view.name && (
            <div className="row">
              <div className="col-12">
                <strong>Grupo:</strong> {data_view.name}
              </div>
              <div className="col-12">
                <strong>Descrição:</strong> {data_view.description}
              </div>

              <div className="col-12">
                <strong>Procedimentos:</strong> <br />
                <ul>
                  {data_view.list.map((row) => (
                    <li className="mt-1">
                      {row.description} {row.obs && `  (${row.obs})`}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </ModalResponsive>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doctor: state.auth.authUser,
    ProcedureGroups: state.ProcedureGroups,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(FormGroupActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewProcedureGroups);
