import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  deleteUserHasTag,
  getPatientesNotHasTag,
  includeTagToPatients
} from "actions/configuration/TagsConditions";

import CircularProgress from "components/CircularProgress";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  ListItemSecondaryAction,
  DialogActions,
  TextField
} from "@material-ui/core";

import { BtnSave } from "components/Button";
import NoRecord from "components/Alert/NoRecord";

class ModalUsers extends Component {
  state = {
    view: "list",
    checked: [],
    search: ""
  };
  handleChageView = () => {
    if (this.state.view === "list") {
      this.setState({ view: "add" });
      this.setState({ checked: [], search: "" });
      this.props.getPatientesNotHasTag(this.props.tag.id, this.props.address);
    } else {
      this.setState({ view: "list", search: "" });
    }
  };
  handleDeleteHasTag = (id_has_tag, id_tag) => {
    this.props.deleteUserHasTag(id_has_tag, id_tag);
    this.setState({ search: "" });
  };
  handleClose = () => {
    this.setState({ view: "list", search: "" });
    this.props.handleClose();
  };

  handleChangeCheck = (event, id) => {
    let { checked } = this.state;
    let currentIndex = checked.indexOf(id);
    let newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };

  handleAddToTag = () => {
    this.props.includeTagToPatients(
      this.props.tag.id,
      this.props.doctor,
      this.state.checked
    );
    this.handleChageView();
  };

  handleChangeSearch = e => {
    this.setState({ search: e.target.value });
  };

  list = () => {
    return (
      <div className="col-md-12">
        {this.props.loader ? (
          <CircularProgress />
        ) : (
          <React.Fragment>
            <TextField
              autoFocus
              fullWidth
              label="Digite o nome do paciente"
              onChange={this.handleChangeSearch}
            />
            <div
              className="col-12"
              style={{
                minWidth: "650",
                overflowX: "auto"
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Pacientes</TableCell>
                    <TableCell align="right">Excluir</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.list_patients.length > 0 ? (
                    this.props.list_patients
                      .filter(
                        pat =>
                          pat.name
                            .toLowerCase()
                            .indexOf(this.state.search.toLowerCase()) !== -1
                      )
                      .map(pat => (
                        <TableRow key={pat.id}>
                          <TableCell>
                            <div className="user-profile d-flex flex-row align-items-center">
                              <Avatar
                                alt={pat.name}
                                src={pat.url_photo}
                                className="user-avatar"
                              />
                              <div className="user-detail">
                                <h5 className="user-name">{pat.name} </h5>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Excluir">
                              <IconButton
                                arial-label="Excluir"
                                onClick={() =>
                                  this.handleDeleteHasTag(
                                    pat.id_has_tag,
                                    this.props.tag.id
                                  )
                                }
                                color="secondary"
                              >
                                <i className="zmdi zmdi-delete" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan="2">
                        <NoRecord />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };
  add = () => {
    return (
      <div className="col-md-12">
        {this.props.loader ? (
          <CircularProgress />
        ) : (
          <React.Fragment>
            <TextField
              autoFocus
              fullWidth
              label="Digite o nome do paciente"
              onChange={this.handleChangeSearch}
            />
            <List dense={true}>
              {this.props.patients_not_has_tag
                .filter(
                  pat =>
                    pat.name
                      .toLowerCase()
                      .indexOf(this.state.search.toLowerCase()) !== -1
                )
                .map(user => (
                  <ListItem
                    button
                    key={user.id}
                    onClick={event => this.handleChangeCheck(event, user.id)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={user.name}
                        src={user.url_photo}
                        className="user-avatar"
                      />
                    </ListItemAvatar>
                    <ListItemText primary={user.name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        color="primary"
                        onClick={event =>
                          this.handleChangeCheck(event, user.id)
                        }
                        checked={this.state.checked.indexOf(user.id) !== -1}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </React.Fragment>
        )}
      </div>
    );
  };
  render() {
    return (
      <Dialog
        fullWidth={true}
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle className="text-primary font-weight-semibold">
          {`Pacientes com a TAG ${this.props.tag.name}`}
          {this.state.view === "list" ? (
            <Tooltip title="Adicionar">
              <IconButton
                style={{ float: "right" }}
                arial-label="adicionar"
                onClick={this.handleChageView}
                color="primary"
              >
                <i className="zmdi zmdi-plus-circle-o" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Voltar">
              <IconButton
                style={{ float: "right" }}
                arial-label="voltar"
                onClick={this.handleChageView}
                color="primary"
              >
                <i className="zmdi zmdi-undo" />
              </IconButton>
            </Tooltip>
          )}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            {this.state.view === "list" ? this.list() : this.add()}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="col-md-12" style={{ marginTop: "30px" }}>
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={this.handleClose}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Fechar </span>
            </Button>
            {this.state.view !== "list" && this.state.checked.length > 0 ? (
              <BtnSave
                title="salvar"
                onClick={this.handleAddToTag}
                float="right"
              />
            ) : null}
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  loader: state.tagsConditions.loader_modal_patients,
  list_patients: state.tagsConditions.list_patirntes_from_tag,
  address: state.auth.address,
  patients_not_has_tag: state.tagsConditions.patients_not_has_tag,
  doctor: state.auth.authUser
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { deleteUserHasTag, getPatientesNotHasTag, includeTagToPatients },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalUsers);
