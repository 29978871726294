const INITIAL_STATE = {
  nameGroup: "",
  descGroup: "",
  modalSuccessDeleteGroup: false,
  active: "view",
  color: "primary",
  texto: "Adicionar",
  hidden: false,
  icon: "plus",
  idEdit: "",
  InfosGroup: [],
  listMedicinesSelected: [],
  listMedicinesGroups: []
};

export default function GroupsMedicines(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_MEDICINE_GROUP":
      return INITIAL_STATE;
    case "HANDLE_ADD_FUNC":
      if (action.payload.id) {
        return {
          ...state,
          active: action.payload.active,
          color: action.payload.color,
          texto: action.payload.texto,
          hidden: action.payload.hidden,
          icon: action.payload.icon,
          idEdit: action.payload.id
        };
      } else {
        return {
          ...state,
          active: action.payload.active,
          color: action.payload.color,
          texto: action.payload.texto,
          hidden: action.payload.hidden,
          icon: action.payload.icon,
          idEdit: action.payload.id,
          InfosGroup: [],
          tables_exam: [],
          nameGroup: "",
          descGroup: "",
          listMedicinesSelected: []
        };
      }

    case "SET_NAME_GROUP":
      return {
        ...state,
        nameGroup: action.payload.value
      };

    case "SET_DESC_GROUP":
      return {
        ...state,
        descGroup: action.payload.value
      };

    case "SET_MEDICINES_ITENS_GROUPS":
      let aux = state.listMedicinesSelected;
      let exist = aux.filter(el => el.id === action.payload.value.id);
      if (exist.length === 0) {
        aux.unshift(action.payload.value);
      }
      return {
        ...state,
        listMedicinesSelected: aux
      };

    case "DEL_ITEM_EXAM":
      let auxDel = [];

      state.listMedicinesSelected.forEach(exam => {
        if (exam.id !== action.payload.id) {
          auxDel.push(exam);
        }
      });

      return {
        ...state,
        listMedicinesSelected: auxDel
      };

    case "CAD_MEDICINES_GROUPS":
      return {
        ...state,
        doctor: action.payload.doctor,
        nameGroupValue: action.payload.nameGroupValue,
        descGroupValue: action.payload.descGroupValue,
        medicinesSelected: action.payload.medicinesSelected,
        idEdit: action.payload.idEdit
      };

    case "REQUEST_LIST_DOCTOR_MEDICINES_GROUP":
      return {
        ...state,
        doctor: action.payload.doctor
      };

    case "REQUEST_LIST_MEDICINES_GROUP_SUCCESS":
      return {
        ...state,
        listMedicinesGroups: action.payload.listMedicinesGroups
      };

    case "DEL_GROUP_MEDICINE":
      return {
        ...state,
        id: action.payload.id
      };

    case "REMOVE_GROUP_MEDICINES_SUCESS":
      let auxListGroup = state.listMedicinesGroups.filter(
        row => row.id !== action.id
      );
      return {
        ...state,
        listMedicinesGroups: auxListGroup,
        modalSuccessDeleteGroup: true
      };

    case "CLOSE_MODAL_DELETE_SUCCESS":
      return {
        ...state,
        modalSuccessDeleteGroup: false
      };

    case "GET_DATA_GROUP_MEDICINES_FOR_EDIT": {
      return {
        ...state
      };
    }

    case "REQUEST_INFO_MEDICINES_GROUP_SUCCESS": {
      return {
        ...state,
        nameGroup: action.payload.InfosGroup[0].name,
        descGroup: action.payload.InfosGroup[0].description,
        listMedicinesSelected: action.payload.ListItensExamsEdit
      };
    }

    case "CHANGE_OBS":
      let auxObs = [];
      state.listMedicinesSelected.forEach((element, key) => {
        if (key === action.payload.index) {
          auxObs.push({
            ...element,
            obs: action.payload.value
          });
        } else {
          auxObs.push(element);
        }
      });
      return {
        ...state,
        listMedicinesSelected: [...auxObs]
      };

    default:
      return state;
  }
}
