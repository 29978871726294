import React from "react";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import { withStyles } from "@material-ui/styles";

//import { BtnAdd } from "components/Button";
// Ex=> <BtnAdd title={"teste"} onClick={this.handleTeste} float="right" />
export function BtnAdd({ title, onClick, float, disabled }) {
  const handleClick = () => {
    onClick();
  };
  return (
    <div>
      <Tooltip
        title={title ? title : "Adicionar"}
        disableHoverListener={title ? false : true}
      >
        <Button
          disabled={disabled}
          onClick={handleClick}
          className="jr-btn jr-btn-sm"
          color="primary"
          variant="contained"
          style={{ float: float }}
        >
          <i className="zmdi zmdi-plus" />
          <span>{title ? title : "Adicionar"}</span>
        </Button>
      </Tooltip>
    </div>
  );
}

// import { BtnSave} from "components/Button";
// Ex=> <BtnSave title={"teste"} onClick={this.handleTeste} float="right"/>
export function BtnSave({ title, onClick, float, disabled }) {
  const handleClick = () => {
    onClick();
  };
  return (
    <div>
      <Tooltip
        title={title ? title : "Salvar"}
        disableHoverListener={title === undefined ? true : false}
      >
        <Button
          disabled={disabled}
          onClick={handleClick}
          color="primary"
          variant="contained"
          className={`jr-btn jr-btn-sm ${!disabled && "bg-success"}`}
          style={{ float: float }}
        >
          <i className="zmdi zmdi-check zmdi-hc-lg" />
          <span>{title ? title : "Salvar"}</span>
        </Button>
      </Tooltip>
    </div>
  );
}

//  import { BtnCan } from "components/Button";
// Ex=> <BtnCan title={"teste"} onClick={this.handleTeste} float="right"/>
export function BtnCan({ title, onClick, float, disabled }) {
  const handleClick = () => {
    onClick();
  };
  return (
    <div>
      <Tooltip
        title={title ? title : "Cancelar"}
        disableHoverListener={title ? false : true}
      >
        <Button
          disabled={disabled}
          onClick={handleClick}
          className="jr-btn jr-btn-sm"
          color="secondary"
          variant="contained"
          style={{ float: float }}
        >
          <i className="zmdi zmdi-close zmdi-hc-lg" />
          <span>{title ? title : "Cancelar"}</span>
        </Button>
      </Tooltip>
    </div>
  );
}

//  import { Btnclose } from "components/Button";
// Ex=> <Btnclose title={"teste"} onClick={this.handleTeste} float="right"/>
export function Btnclose({ title, onClick, float, disabled }) {
  const handleClick = () => {
    onClick();
  };
  return (
    <div>
      <Tooltip
        title={title ? title : "Fechar"}
        disableHoverListener={title ? false : true}
      >
        <Button
          disabled={disabled}
          onClick={handleClick}
          className="jr-btn jr-btn-sm"
          color="secondary"
          variant="contained"
          style={{ float: float }}
        >
          <i className="zmdi zmdi-close zmdi-hc-lg" />
          <span>{title ? title : "Fechar"}</span>
        </Button>
      </Tooltip>
    </div>
  );
}

//import { Btn } from "components/Button";
// Ex=> <Btn title={"teste"} icon={"zmdi zmdi-close zmdi-hc-lg"} name={"carrregar"} onClick={this.handleTeste} float="right"/>
export function Btn({ title, onClick, name, icon, float, disabled }) {
  const handleClick = () => {
    onClick();
  };
  return (
    <div>
      <Tooltip title={title} disableHoverListener={title ? false : true}>
        <Button
          disabled={disabled}
          onClick={handleClick}
          className="jr-btn jr-btn-sm"
          color="primary"
          variant="contained"
          style={{ float: float }}
        >
          <i className={icon} />
          <span>{name}</span>
        </Button>
      </Tooltip>
    </div>
  );
}

//  import { BtnBack } from "components/Button";
// Ex=> <Btnclose title={"teste"} onClick={this.handleTeste} float="right"/>
export function BtnBack({ title, onClick, float }) {
  const handleClick = () => {
    onClick();
  };
  return (
    <div>
      <Tooltip title={title} disableHoverListener={title ? false : true}>
        <Button
          onClick={handleClick}
          className="jr-btn jr-btn-sm"
          color="secondary"
          variant="contained"
          style={{ float: float }}
        >
          {/* <i className="zmdi zmdi-close zmdi-hc-lg" /> */}
          <span>Voltar</span>
        </Button>
      </Tooltip>
    </div>
  );
}

//  import { FabSave } from "components/Button";
// Ex=> <FabSave onClick={this.handleTeste}/>
export function FabSave({ onClick, float }) {
  return (
    <Tooltip placement="top" title="Salvar">
      <Fab
        onClick={() => onClick()}
        color="primary"
        aria-label="add"
        className="bg-success"
        style={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
          zIndex: 999,
        }}
      >
        <CheckIcon />
      </Fab>
    </Tooltip>
  );
}

export const ButtonLarge = withStyles((theme) => ({
  root: {
    backgroundColor: "#177493",
    color: "#fff",
    width: "100%",
    borderRadius: "25px",
    display: "block",
    textTransform: "none",
    marginTop: "16px",
    marginBottom: "16px",
    border: "solid 2px #8c8c8c",
    "&:hover": {
      backgroundColor: "#01739a",
    },
    "&:active": {
      backgroundColor: "#01739a",
    },
    "&:focus": {
      backgroundColor: "#01739a",
    },
  },
}))(Button);
