import React, { useState } from "react";

import Api from "services/api";

import General from "./General";
import Located from "./Located";
import Tabs from "components/TabsDefault";
import BarProgress from "components/BarProgress";

function PainScaleHistory({ patient }) {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [painScale, setPainScale] = useState([]);
  const [complaints, setComplaints] = useState([]);

  React.useEffect(() => {
    if (patient) {
      getHistory();
    }
  }, [patient]);

  const getHistory = () => {
    setLoading(true);
    Api.post(`microservice/scalepain/getHistory/`, { patient })
      .then(({ data }) => {
        setPainScale(data.painScale);
        setComplaints(data.complaints);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container-person border">
      <h3
        className="title-container d-flex justify-content-center"
        style={{
          fontSize: "26px",
          fontWeight: "400",
          color: "black",
        }}
      >
        Escala de Dor
      </h3>
      <div className="row" style={{ padding: "5px 15px" }}>
        <div className="w-100" style={{ paddingTop: "10px" }}>
          {loading && <BarProgress />}
          {!loading && (
            <Tabs
              value={value}
              setValue={setValue}
              tabs={["Geral", "Localizada"]}
            >
              {value === 0 && <General listScale={painScale} />}
              {value === 1 && <Located list={complaints} />}
            </Tabs>
          )}
        </div>
      </div>
    </div>
  );
}

export default PainScaleHistory;
