import React from "react";

import DataTable from "components/Tables/DataTable";
import CareIcons from "components/CareIcons";
import { Badge } from "reactstrap";

export default function TableInterrupted({ prescriptions }) {
  return (
    <div
      className="col-12"
      style={{
        minWidth: "650",
        overflowX: "auto",
      }}
    >
      <DataTable
        columns={["Tipo", "Cuidado", "Data Inicial", "Status", "Data Final"]}
        rows={prescriptions.map((row) => [
          [<CareIcons type={row.type} />],
          row.description,
          row.start,
          [
            <>
              {row.interrupted_date ? (
                <Badge color={"warning"}>Interrompido</Badge>
              ) : (
                <Badge color={"primary"}>Finalizada</Badge>
              )}
            </>,
          ],
          row.interrupted_date ? row.interrupted_date : row.end,
        ])}
      />
    </div>
  );
}
