import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import axios from "axios";
import Api from "services/api";
import { showMsg } from "services/functions";

import MaskCep from "components/Masks/MaskCep";
import { BtnSave } from "components/Button";
import { TextField } from "@material-ui/core";

export default function LegalAddress() {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    getLegalAddress();
  }, []);

  function getLegalAddress() {
    Api.post("ms_system/user/getLegalAddess", {})
      .then(({ data }) => {
        if (data.record.length > 0) {
          setState({ ...data.record[0], country: "Brasil" });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleChange(field, value) {
    setState({ ...state, [field]: value });
  }

  function searchCep(value) {
    let cep = value.replace(/_/g, "");
    if (cep.length === 9) {
      cep = cep.replace(",", "");
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => {
          setState({
            ...state,
            postalcode: res.data.cep,
            address: res.data.logradouro,
            neighborhood: res.data.bairro,
            city: res.data.localidade,
            state: res.data.uf,
            country: "Brasil",
          });
          document.getElementById("number").focus();
        })
        .catch(() => {
          console.log("Ocorreu um erro ao recuperar as informações do cep.");
        });
    }
  }

  function save() {
    if (!error) {
      Api.post("ms_system/user/saveLegalAddress", { ...state })
        .then(({ data }) => {
          if (data.success) {
            setState({ ...state, id: data.id });
            showMsg("success", "Salvo com sucesso!", dispatch);
          } else {
            showMsg("error", "Não foi possível salvar!", dispatch);
          }
        })
        .catch((err) => {
          showMsg("error", "Não foi possível salvar!", dispatch);
        });
    }
  }

  return (
    <div className="row">
      <div className="col-md-12 mb-3">
        <span className="card-title text-primary title-form">
          Enderço do CNPJ
        </span>
      </div>

      <div className="col-12">
        <TextField
          id="name_workplace"
          required
          label="Nome do Endereço:"
          value={state.name}
          onChange={(e) => handleChange("name", e.target.value)}
          fullWidth
          error={state.error && !state.name ? true : false}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="postalcode"
          label="CEP"
          margin="normal"
          required
          fullWidth
          InputProps={{
            inputComponent: MaskCep,
            value: state.postalcode,
            onChange: (e) => searchCep(e.target.value),
          }}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-md-8 col-12 ">
        <TextField
          required
          id="address"
          label="Logradouro"
          value={state.address}
          margin="normal"
          fullWidth
          onChange={(e) => handleChange("address", e.target.value)}
          error={state.error && !state.address ? true : false}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          required
          id="number"
          label="Número"
          value={state.number}
          margin="normal"
          fullWidth
          onChange={(e) => handleChange("number", e.target.value)}
          error={state.error && !state.number ? true : false}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="complement"
          label="Complemento"
          value={state.complement}
          margin="normal"
          fullWidth
          onChange={(e) => handleChange("complement", e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="neighborhood"
          label="Bairro"
          value={state.neighborhood}
          margin="normal"
          required
          fullWidth
          onChange={(e) => handleChange("neighborhood", e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          required
          id="city"
          label="Cidade"
          value={state.city}
          margin="normal"
          fullWidth
          InputProps={{ readOnly: state.postalcode ? true : false }}
          onChange={(e) => handleChange("city", e.target.value)}
          error={state.error && !state.city ? true : false}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          required
          id="state"
          label="Estado"
          value={state.state}
          margin="normal"
          fullWidth
          InputProps={{ readOnly: state.postalcode ? true : false }}
          onChange={(e) => handleChange("state", e.target.value)}
          error={state.error && !state.state ? true : false}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-md-4 col-12">
        <TextField
          id="country"
          label="País"
          value={state.country}
          margin="normal"
          required
          fullWidth
          InputProps={{ readOnly: state.postalcode ? true : false }}
          onChange={(e) => handleChange("country", e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </div>

      <div className="col-12">
        <div className="col-12">
          <BtnSave float="right" title="Salvar" onClick={save} />
        </div>
      </div>
    </div>
  );
}
