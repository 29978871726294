export const DATA_PATIENT = "1";

export const RESPONSIBLE = "2";

export const REASON = "3";

export const AVALIATION = "4";

export const SYSTEM_REVIEW = "5";

// sub menu => SYSTEM_REVIEW

export const SENSE_ORGAN = "6";
export const CARDIO_SYSTEM = "7";
export const RESPIRATORY_SYSTEM = "8";
export const DIGESTIVE_SYSTEM = "9";
export const URINAY_SYSTEM = "10";
export const MUSCULOSKELETAL = "11";
export const CUTANEOUS_SYSTEM = "12";
export const NERVOUS_SYSTEM = "13";

// sub menu
export const FUNCTIONAL_AVALIATION = "14";

export const PERSONAL_HISTORY = "15";

export const FAMILY_HISTORY = "16";

export const PSYCHIC_AVALIANTION = "17";

export const DIAGNOSTIC_CONCLUSION = "18";

export const REPORT = "19";

//subgroup avaliacao Admis
export const MEDICINES = "20";
export const COMPLAINT = "21";
export const DISEASE = "22";
export const VITALSIGNS = "23";
export const BASICACTIVITIES = "24";
export const INSTRUMENTALACTIVITIES = "25";
export const IVCF = "26";
export const ALLERGIES = "27";

//subgroup avaliacao fun
export const MENTALHEALTH = "28";
export const DEPRESSIONSCALE = "29";
export const MOBILITYAAVALIATION = "30";
export const NUTRITIONALAVALIATION = "31";

//subgroup história Pessoal
export const SLEEP = "32";
export const PHYSICALACTIVITIES = "33";
export const DRUGS = "34";
export const VACCINES = "35";
export const FALLS = "36";
export const FRACTURES = "37";

// //subgroup Conclusão Diagnóstica
export const GLOBALDIAGNOSTIC = "38";
export const OLDDISEASES = "39";
export const CURRENTDISEASES = "40";
export const CAREPLAN = "41";
export const MEDICALCARE = "42";
export const ASSOCIATECAREPATTERN = "43";
export const INVENTARY = "44";

export const NAMEPATIENT = "1";
export const BIRTHDATE = "2";
export const ADMITTED = "3";
export const MEDICALRECORD = "4";
export const SEX = "5";
export const AGE = "6";
export const MARITALSTATUS = "7";
export const EDUCATION = "8";
export const ORIGIN = "9";
export const NATURALNESS = "10";
export const PROFESSION = "11";

export const PATIENT_DATA = [
  {
    id: NAMEPATIENT,
    description: "Nome Paciente",
  },
  {
    id: BIRTHDATE,
    description: "Data Nascimento",
  },
  {
    id: ADMITTED,
    description: "Admitido",
  },
  {
    id: MEDICALRECORD,
    description: "N° Prontuário",
  },
  {
    id: SEX,
    description: "Sexo",
  },
  {
    id: AGE,
    description: "Idade",
  },
  {
    id: MARITALSTATUS,
    description: "Estado civil",
  },
  {
    id: EDUCATION,
    description: "Escolaridade",
  },
  {
    id: ORIGIN,
    description: "Procedência",
  },
  {
    id: NATURALNESS,
    description: "Naturalidade",
  },
  {
    id: PROFESSION,
    description: "Profissão",
  },
];

export const ADMISSION_ARRAY = [
  {
    id: DATA_PATIENT,
    link: "data-patient",
    description: "Identificação do idoso",
  },
  {
    id: RESPONSIBLE,
    link: "responsible",
    description: "Identificação do familiar/responsável",
  },
  { id: REASON, link: "reason", description: "Motivo da admissão" },
  {
    id: AVALIATION,
    link: "avaliation",
    description: "Avaliação admissional",
    //sub grupo
    sub_group: [
      {
        id: MEDICINES,
        link: "",
        description: "Medicamentos em uso",
      },
      {
        id: COMPLAINT,
        link: "",
        description: "Queixa principal",
      },
      {
        id: DISEASE,
        link: "",
        description: "Patologias",
      },
      {
        id: VITALSIGNS,
        link: "",
        description: "Dados Vitais",
      },
      {
        id: BASICACTIVITIES,
        link: "",
        description:
          "Índice de Barthel - Independência nas atividades básicas da vida diária",
      },
      {
        id: INSTRUMENTALACTIVITIES,
        link: "",
        description:
          "Escala de Lawton e Brody - Atividades instrumentais da vida diária",
      },
      {
        id: IVCF,
        link: "",
        description: "Ivcf",
      },
      {
        id: ALLERGIES,
        link: "",
        description: "Alergias",
      },
    ],
  },
  {
    id: SYSTEM_REVIEW,
    link: "system_review",
    description: "Revisão De Sistemas",
    //sub grupo
    sub_group: [
      {
        id: SENSE_ORGAN,
        link: "system_review/sense_organ",
        description: "Órgãos Dos Sentidos",
      },
      {
        id: CARDIO_SYSTEM,
        link: "system_review/cardio_system",
        description: "Sistema Cardiovascular",
      },
      {
        id: RESPIRATORY_SYSTEM,
        link: "system_review/respiratory_system",
        description: "Sistema Respiratório",
      },
      {
        id: DIGESTIVE_SYSTEM,
        link: "system_review/digestive_system",
        description: "Aparelho Digestivo",
      },
      {
        id: URINAY_SYSTEM,
        link: "system_review/urinary_system",
        description: "Sistema Gênito-Urinário",
      },
      {
        id: MUSCULOSKELETAL,
        link: "system_review/musculoskeletal",
        description: "Sistema Osteomuscular",
      },
      {
        id: CUTANEOUS_SYSTEM,
        link: "system_review/cutaneous_system",
        description: "Sistema Cutâneo/Tegumentar",
      },
      {
        id: NERVOUS_SYSTEM,
        link: "system_review/nervous_system",
        description: "Sistema Nervoso",
      },
    ],
  },

  //sub grupo
  {
    id: FUNCTIONAL_AVALIATION,
    link: "functional-avaliation",
    description: "Avaliação funcional",
    //sub grupo
    sub_group: [
      {
        id: MENTALHEALTH,
        link: "",
        description: "Avaliação da Saúde Mental",
      },
      {
        id: DEPRESSIONSCALE,
        link: "",
        description: "Escala de Depressão Geriátrica Abreviada",
      },
      {
        id: MOBILITYAAVALIATION,
        link: "",
        description: "Avaliação de mobilidade",
      },
      {
        id: NUTRITIONALAVALIATION,
        link: "",
        description: "Avaliação nutricional",
      },
    ],
  },
  {
    id: PERSONAL_HISTORY,
    link: "personal-history",
    description: "História pessoal atual e pregressa",
    sub_group: [
      {
        id: SLEEP,
        link: "",
        description: "Distúrbios do sono",
      },
      {
        id: PHYSICALACTIVITIES,
        link: "",
        description: "Atividade Física",
      },
      {
        id: DRUGS,
        link: "",
        description: "Uso de drogas",
      },
      {
        id: VACCINES,
        link: "",
        description: "Imunização",
      },
      {
        id: FALLS,
        link: "",
        description: "Quedas",
      },
      {
        id: FRACTURES,
        link: "",
        description: "Fraturas",
      },
    ],
  },
  {
    id: FAMILY_HISTORY,
    link: "family-history",
    description: "Histórico Familiar Positivo",
  },
  {
    id: PSYCHIC_AVALIANTION,
    link: "psychic-avaliation",
    description: "Avaliação Psíquica e Sócio Familiar",
  },
  {
    id: DIAGNOSTIC_CONCLUSION,
    link: "diagnostic-conclusion",
    description: "Conclusão Diagnóstica / Plano de Cuidado",
    sub_group: [
      {
        id: GLOBALDIAGNOSTIC,
        link: "",
        description: "Diagnóstico Funcional Global",
      },
      {
        id: OLDDISEASES,
        link: "",
        description: "Patologias pré-existentes",
      },
      {
        id: CURRENTDISEASES,
        link: "",
        description: "Patologias em tratamento",
      },
      {
        id: CAREPLAN,
        link: "",
        description: "Plano de cuidados (Conduta)",
      },
      {
        id: MEDICALCARE,
        link: "",
        description: "Cuidados médicos",
      },
      {
        id: ASSOCIATECAREPATTERN,
        link: "",
        description: "Associação de Padrões de Cuidados",
      },
      {
        id: INVENTARY,
        link: "",
        description: "Inventário",
      },
    ],
  },
  { id: REPORT, link: "report", description: "Relatórios" },
];

export const ADMISSION_ARRAY_LIST = [
  {
    id: DATA_PATIENT,
    link: "data-patient",
    description: "Identificação do idoso",
  },
  {
    id: RESPONSIBLE,
    link: "responsible",
    description: "Identificação do familiar/responsável",
  },
  { id: REASON, link: "reason", description: "Motivo da admissão" },
  {
    id: AVALIATION,
    link: "avaliation",
    description: "Avaliação admissional",
    //sub grupo
  },
  {
    id: SYSTEM_REVIEW,
    link: "system_review",
    description: "Revisão De Sistemas",
    //sub grupo
    sub_group: [
      {
        id: SENSE_ORGAN,
        link: "system_review/sense_organ",
        description: "Órgãos Dos Sentidos",
      },
      {
        id: CARDIO_SYSTEM,
        link: "system_review/cardio_system",
        description: "Sistema Cardiovascular",
      },
      {
        id: RESPIRATORY_SYSTEM,
        link: "system_review/respiratory_system",
        description: "Sistema Respiratório",
      },
      {
        id: DIGESTIVE_SYSTEM,
        link: "system_review/digestive_system",
        description: "Aparelho Digestivo",
      },
      {
        id: URINAY_SYSTEM,
        link: "system_review/urinary_system",
        description: "Sistema Gênito-Urinário",
      },
      {
        id: MUSCULOSKELETAL,
        link: "system_review/musculoskeletal",
        description: "Sistema Osteomuscular",
      },
      {
        id: CUTANEOUS_SYSTEM,
        link: "system_review/cutaneous_system",
        description: "Sistema Cutâneo/Tegumentar",
      },
      {
        id: NERVOUS_SYSTEM,
        link: "system_review/nervous_system",
        description: "Sistema Nervoso",
      },
    ],
  },

  //sub grupo
  {
    id: FUNCTIONAL_AVALIATION,
    link: "functional-avaliation",
    description: "Avaliação funcional",
  },
  {
    id: PERSONAL_HISTORY,
    link: "personal-history",
    description: "História pessoal atual e pregressa",
  },
  {
    id: FAMILY_HISTORY,
    link: "family-history",
    description: "Histórico Familiar Positivo",
  },
  {
    id: PSYCHIC_AVALIANTION,
    link: "psychic-avaliation",
    description: "Avaliação Psíquica e Sócio Familiar",
  },
  {
    id: DIAGNOSTIC_CONCLUSION,
    link: "diagnostic-conclusion",
    description: "Conclusão Diagnóstica / Plano de Cuidado",
  },
  // { id: REPORT, link: "report", description: "Relatórios" },
];
