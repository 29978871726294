const INIT_STATE = {};
export default function forms(state = INIT_STATE, action) {
  switch (action.type) {
    case "CHANGE_FORM":
      return {
        ...state,
        ...action.payload
      };
    case "CHANGE_FORM_CLEAR":
      return {};
    default:
      return state;
  }
}
