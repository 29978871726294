import React, { Component } from "react";
import Api from "services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import moment from "moment";

class Vaccines extends Component {
  state = {
    patient: this.props.patient,
    record: [],
    rowsPerPage: 10,
    page: 0
  };

  componentDidMount() {
    Api.post(`/microservice/admission/getVaccines/`, {
      patient: this.state.patient
    })
      .then(res => {
        this.setState({ record: res.data.record });
      })
      .catch(() => {});
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { rowsPerPage, page } = this.state;
    const { record } = this.state;
    return (
      <div className="container-person border">
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400"
          }}
        >
          {" "}
          Vacinas
        </h3>
        {record.length > 0 ? (
          <div
            className="col-12"
            style={{
              minWidth: "650",
              overflowX: "auto"
            }}
          >
            <Table className="table-history">
              <TableHead>
                <TableRow>
                  <TableCell>Vacina</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Data</TableCell>
                  <TableCell align="left">Observação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {`${row.name} - ${row.dosage} (${row.age})`}
                      </TableCell>
                      <TableCell align="left">
                        {row.is_applied === "1" ? "Aplicado" : "Previsto"}
                      </TableCell>
                      <TableCell align="left">
                        {row.is_applied === "1"
                          ? row.date_of_vaccine
                            ? moment(row.date_of_vaccine).format("DD/MM/YYYY")
                            : "---"
                          : row.expected_date
                          ? moment(row.expected_date).format("DD/MM/YYYY")
                          : "---"}
                      </TableCell>
                      <TableCell align="left">
                        {row.observation ? row.observation : " --- "}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={record.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Registros por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `Mostrando ${from} - ${to} de ${count}`
                    }
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        ) : (
          <p
            style={{
              textAlign: "center",
              color: "#a6a6a6",
              fontStyle: "italic"
            }}
          >
            Nenhum Registro Encontrado
          </p>
        )}
      </div>
    );
  }
}

export default Vaccines;
