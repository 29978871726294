import { all, takeLatest, takeEvery, put, fork } from "redux-saga/effects";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";

function* getNewNotRead(action) {
  let { selectedSectionId, chat } = action;
  let ref = getDataFirebase().child(
    `chats${getEnviromentFirebase()}/${chat.chat_id}`
  );
  if (selectedSectionId === chat.id) {
    yield ref.off();
    yield put({ type: "NEW_NOT_READ", notRead: 0, id: chat.chat_id });
  } else {
    let count = 0;
    yield ref
      .orderByChild("read")
      .equalTo(false)
      .once("value", (snap) => {
        if (snap.val()) {
          Object.keys(snap.val()).forEach((row) => {
            if (snap.val()[row].from === chat.id) {
              count++;
            }
          });
        }
      });
    yield put({ type: "NEW_NOT_READ", notRead: count, id: chat.chat_id });
  }
}

function* setNewConversation(action) {
  let conversation = [];
  yield getDataFirebase()
    .child(`chats${getEnviromentFirebase()}/${action.payload}`)
    .limitToLast(25)
    .once("value", (snap) => {
      conversation = snap.val();
    });
  yield put({
    type: "SET_NEW_CONVERSATION_CHAT",
    payload: { conversation, id: action.payload },
  });
}

function* listenGetEvents() {
  yield takeEvery("SET_NOT_READ", getNewNotRead);
}
function* listenSetConversation() {
  yield takeLatest("SET_CONVERSATION_CHAT", setNewConversation);
}

export default function* rootSaga() {
  yield all([fork(listenGetEvents), fork(listenSetConversation)]);
}
