import React from "react";
import { CardBody } from "reactstrap";
import NoRecord from "components/Alert/NoRecord";
import ComponentsProfile from "components/ComponentsProfile";
import SearchComponent from "components/SearchComponent";
import { getStringToSearch } from "services/functions";
import Api from "services/api";

export default function ResidentialProfessionals({ address }) {
  const [record, setRecord] = React.useState([]);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    if (address) {
      Api.post(`/ms_system/dashboard/getProfessionalsInDay`, {
        address,
      }).then((res) => {
        setRecord(res.data.record);
      });
    }
  }, [address]);

  return (
    <div className="col-12 m-0 p-0 animated slideInUpTiny animation-duration-4">
      <CardBody>
        <div className="col-12 m-0 p-0">
          <h2 className="font-weight-semibold d-flex">
            <span className="mt-3 mr-5">Profissionais</span>
            {record.length > 0 && (
              <SearchComponent searchContact={(e) => setSearch(e)} />
            )}
          </h2>
        </div>
        <div className="col-12">
          {record.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nome</th>
                </tr>
              </thead>
              <tbody>
                {record
                  .filter(
                    (row) =>
                      search.length === 0 ||
                      getStringToSearch(row.name).includes(
                        getStringToSearch(search)
                      )
                  )
                  .map((e, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="w-100 d-flex">
                        <ComponentsProfile
                          nameProfile={e.name}
                          subTitle={e.speciality}
                          photo={e.photo}
                          id={e.id}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <NoRecord />
          )}
        </div>
      </CardBody>
    </div>
  );
}
