import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCalendars, getEvents, getConfigCalendar } from "actions/Calendar";
import { Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  TextField,
  MenuItem,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  Drawer,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CircularProgress from "components/CircularProgress";
import FormContact from "./FormContact";
import FormBloquedHour from "./FormBloquedHour";
import HistoryEvent from "./HistoryEvent";
import FullCalendar from "./FullCalendar";
import FormNewCalendar from "./FormNewCalendar";
import EditCalendar from "./EditCalendar";
import FullCalendarIntegrated from "./FullCalendarIntegrated";
import FormScheduling from "./FormScheduling";
import FormEvent from "./FormEvent";
import ViewEvent from "./ViewEvent";
import LogCalendar from "./LogCalendar";
import IntegratedWeek from "./IntegratedWeek";
import ListProcedures from "./ListProcedures";
import Api from "services/api";

function Calendar() {
  const [warning, setWarning] = useState("");
  const [warningModal, setModalWarning] = useState(false);
  const [selected, setSelected] = useState({
    colorEvent: "",
    colorText: "",
    title: "",
    user_id: "",
    id: "",
    doctor_id: "",
  });
  const [open, setOpen] = useState(false);

  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const user = useSelector((state) => state.auth.authUser);
  const userType = useSelector((state) => state.auth.type);
  const providerType = useSelector((state) => state.auth.provider_type);
  const address = useSelector((state) => state.auth.address);
  const address_selected = useSelector((state) => state.auth.address_selected);
  const manager = useSelector((state) => state.permissions.manager);
  const secretary = useSelector((state) => state.auth.secretary);
  const userManage = useSelector((state) => state.auth.user_manage);
  const [calendars, setCalendars] = useState([]);
  const calendarsInit = useSelector((state) => state.calendar.calendars);
  const distinctUser = useSelector((state) => state.calendar.distinct_user);
  const loaded = useSelector((state) => state.calendar.loaded);
  const event_id = useSelector((state) => state.calendar.event_id);
  const date = useSelector((state) => state.calendar.date_calendar);
  const load_config = useSelector((state) => state.calendar.config.loaded);
  const calendar_id = useSelector((state) => state.calendar.calendar_id);
  const date_selected = useSelector((state) => state.calendar.date_selected);
  const modal_open = useSelector((state) => state.calendar.modal_open);
  const modal_type = useSelector((state) => state.calendar.modal_type);
  const width = useSelector((state) => state.settings.width);
  const dispatch = useDispatch();

  const idsCalendars = calendars.map((row) => row.id).join(",");
  const aux_user = secretary ? userManage.id : user;
  const aux_type = secretary ? userManage.type : userType;
  const aux_prov_type = secretary ? userManage.provider_type : providerType;

  useEffect(() => {
    dispatch(
      getCalendars(
        aux_user,
        aux_type,
        address,
        width,
        address_selected.provider_type,
        manager
      )
    );
    if (!load_config) {
      dispatch(getConfigCalendar(aux_user));
    }
  }, []);

  useEffect(() => {
    let data_get = window.location.href;
    let data = data_get.split("?")[1];
    if (data) {
      dispatch({
        type: "CALENDAR_NOFICATION",
        calendar: data.split("&")[0],
        event: data.split("&")[1],
      });
    }
  }, [window.location.href]);

  useEffect(() => {
    if (calendarsInit.length > 0) {
      setCalendars(calendarsInit);

      let aux = calendarsInit.filter((row) => row.address_id === address);
      if (aux.length === 1) {
        setSelected({
          colorEvent: aux[0].color,
          colorText: aux[0].text_color,
          title: aux[0].name,
          user_id: aux[0].user_id,
          id: aux[0].id,
          doctor_id: aux[0].doctor_id,
        });
        dispatch({
          type: "CHANGE_CALENDAR",
          calendar: aux[0].id,
        });
      }
    }
  }, [calendarsInit]);

  const updateCalendars = (obj) => {
    const newList = calendars.filter((el) => el.id !== obj.id);
    newList.push(obj);
    setCalendars(newList);
    dispatch({
      type: "UPDATE_CALENDARS",
      calendars: newList,
      calendar_selected: obj.id,
    });
    setSelected({
      colorEvent: obj.color,
      colorText: obj.text_color,
      title: obj.name,
      user_id: obj.user_id,
      id: obj.id,
      doctor_id: "",
    });
  };

  const warningRepetition = (warning) => {
    if (warning !== "") {
      setWarning(warning);
      setModalWarning(true);
    }
  };

  const removeCalendar = () => {
    Api.post(`/ms_system/calendar/removeCalendar/`, {
      calendar: selected.id,
    }).then((res) => {
      setModalDelete(false);
      setSuccessDelete(true);
      dispatch({
        type: "REMOVE_CALENDAR",
        calendar: selected.id,
      });
      setSelected({
        colorEvent: "",
        colorText: "",
        title: "",
        user_id: "",
        id: "",
        doctor_id: "",
      });
    });
  };

  console.log("modal_type", modal_type);

  if (!loaded || !load_config) {
    return <CircularProgress />;
  } else {
    return (
      <Card className="border-0">
        <CardBody>
          <div className="row">
            <div
              className="col-12"
              style={{ display: width > 500 ? "block" : "inline-flex" }}
            >
              <TextField
                id="day_week"
                select
                label="Agenda:"
                value={calendar_id}
                onChange={(e) => {
                  dispatch({
                    type: "CHANGE_CALENDAR",
                    calendar: e.target.value,
                  });
                  if (
                    e.target.value !== "integrated" &&
                    e.target.value !== "integrated_week"
                  ) {
                    let parent = calendars.find(
                      (row) => row.id === e.target.value
                    );
                    setSelected({
                      colorEvent: parent.color,
                      colorText: parent.text_color,
                      title: parent.name,
                      user_id: parent.user_id,
                      id: parent.id,
                      doctor_id: parent.doctor_id,
                    });
                  } else {
                    setSelected({
                      colorEvent: "",
                      colorText: "",
                      title: "",
                      user_id: "",
                      id: "",
                      doctor_id: "",
                    });
                  }
                }}
                SelectProps={{}}
                fullWidth
                style={{ marginTop: "0px" }}
              >
                {!(userType === "3" && !secretary) && width > 500 && (
                  <MenuItem key="integrated" value="integrated">
                    Agenda consolidada
                  </MenuItem>
                )}
                {!(userType === "3" && !secretary) && (
                  <MenuItem key="integrated_week" value="integrated_week">
                    Agenda consolidada - visão periódica
                  </MenuItem>
                )}
                {calendars
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      <>
                        <span
                          className="badge"
                          style={{
                            background: row.color,
                            display: "inline",
                            width: "30px",
                            height: "15px",
                            marginTop: "10px",
                          }}
                        />
                        <span>{row.name}</span>
                      </>
                    </MenuItem>
                  ))}
              </TextField>
              {width <= 500 && (
                <div className="mt-2">
                  <IconButton onClick={() => setOpen(true)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Drawer
                    anchor="bottom"
                    open={open}
                    onClose={() => setOpen(false)}
                  >
                    <FormNewCalendar
                      calendarList={updateCalendars}
                      closeMenu={() => setOpen(false)}
                      width={width}
                    />

                    {selected.id && userType === "3" && !secretary && (
                      <MenuItem
                        onClick={() => {
                          setModalDelete(true);
                          setOpen(false);
                        }}
                      >
                        <i className="zmdi zmdi-delete mr-3" /> Remover Agenda
                      </MenuItem>
                    )}
                    {selected.user_id === aux_user && (
                      <EditCalendar
                        idInit={selected.id}
                        title={selected.title}
                        colorEvent={selected.colorEvent}
                        colorText={selected.colorText}
                        user_id={selected.user_id}
                        calendarList={updateCalendars}
                        closeMenu={() => setOpen(false)}
                        width={width}
                      />
                    )}

                    <LogCalendar
                      calendar={
                        calendar_id !== "integrated" &&
                        calendar_id !== "integrated_week"
                          ? calendar_id
                          : idsCalendars
                      }
                      closeMenu={() => setOpen(false)}
                      width={width}
                    />

                    {calendar_id !== "integrated" &&
                      calendar_id !== "integrated_week" && (
                        <FormBloquedHour
                          calendar={calendar_id}
                          closeMenu={() => setOpen(false)}
                          width={width}
                        />
                      )}

                    {(userType !== "3" || secretary) &&
                      address_selected.provider_type !== "5" && (
                        <>
                          <HistoryEvent
                            calendar={idsCalendars}
                            closeMenu={() => setOpen(false)}
                            width={width}
                          />
                          <FormContact
                            users={distinctUser}
                            closeMenu={() => setOpen(false)}
                            width={width}
                          />
                          <ListProcedures
                            calendar={idsCalendars}
                            closeMenu={() => setOpen(false)}
                            width={width}
                          />
                        </>
                      )}
                  </Drawer>
                </div>
              )}
            </div>
            {width > 500 && (
              <div className="col-12">
                <FormNewCalendar
                  calendarList={updateCalendars}
                  width={width}
                  closeMenu={() => setOpen(false)}
                />
                {selected.id && !selected.doctor_id && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 15, marginLeft: 10 }}
                    onClick={() => setModalDelete(true)}
                  >
                    <i className="zmdi zmdi-delete mr-3" /> Remover Agenda
                  </Button>
                )}
                {selected.user_id === aux_user && (
                  <EditCalendar
                    idInit={selected.id}
                    title={selected.title}
                    colorEvent={selected.colorEvent}
                    colorText={selected.colorText}
                    user_id={selected.user_id}
                    calendarList={updateCalendars}
                    width={width}
                  />
                )}

                <LogCalendar
                  calendar={
                    calendar_id !== "integrated" &&
                    calendar_id !== "integrated_week"
                      ? calendar_id
                      : idsCalendars
                  }
                  closeMenu={() => setOpen(false)}
                  width={width}
                />

                {calendar_id !== "integrated" &&
                  calendar_id !== "integrated_week" && (
                    <FormBloquedHour
                      calendar={calendar_id}
                      width={width}
                      closeMenu={() => setOpen(false)}
                    />
                  )}

                {(userType !== "3" || secretary) &&
                  address_selected.provider_type !== "5" && (
                    <>
                      <HistoryEvent calendar={idsCalendars} width={width} />
                      <FormContact users={distinctUser} width={width} />
                      <ListProcedures calendar={idsCalendars} width={width} />
                    </>
                  )}
              </div>
            )}
            <div className="col-12 mt-3">
              {calendar_id === "integrated" ? (
                <FullCalendarIntegrated providerType={aux_prov_type} />
              ) : calendar_id === "integrated_week" ? (
                <IntegratedWeek />
              ) : (
                <FullCalendar calendar={calendar_id} />
              )}
            </div>
          </div>
        </CardBody>
        <Dialog
          fullScreen={width <= 500}
          fullWidth={true}
          maxWidth="md"
          open={modal_open}
          onClose={() => {
            dispatch({ type: "CLOSE_MODAL" });
          }}
          aria-labelledby="max-width-dialog-title"
          scroll="body"
        >
          <DialogContent>
            {/* Eventos p/ agenda pessoal */}
            {(modal_type === "add_event" || modal_type === "view_event") && (
              <FormEvent
                id={event_id}
                date={date_selected}
                close={() => {
                  dispatch(
                    getEvents(
                      calendar_id === "integrated" ||
                        calendar_id === "integrated_week"
                        ? idsCalendars
                        : calendar_id,
                      date
                    )
                  );
                  dispatch({ type: "CLOSE_MODAL" });
                }}
              />
            )}
            {/* Eventos p/ agenda de local de atendimento */}
            {modal_type === "add" && (
              <FormScheduling
                id={event_id}
                date={date_selected}
                warningRepetition={warningRepetition}
                close={() => {
                  dispatch(
                    getEvents(
                      calendar_id === "integrated" ||
                        calendar_id === "integrated_week"
                        ? idsCalendars
                        : calendar_id,
                      date
                    )
                  );
                  dispatch({ type: "CLOSE_MODAL" });
                }}
              />
            )}
            {modal_type === "view" && (
              <ViewEvent
                event={event_id}
                close={() => {
                  dispatch({ type: "CLOSE_MODAL" });
                }}
                getEvents={() =>
                  dispatch(
                    getEvents(
                      calendar_id === "integrated" ||
                        calendar_id === "integrated_week"
                        ? idsCalendars
                        : calendar_id,
                      date
                    )
                  )
                }
              />
            )}
          </DialogContent>
        </Dialog>
        <SweetAlert
          show={warningModal}
          // show={true}
          warning
          showConfirm={true}
          title={warning}
          onConfirm={() => setModalWarning(false)}
        />

        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, remover"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title={`Confirma a exclusão da agenda: "${selected.title}" ?`}
          onConfirm={() => removeCalendar()}
          onCancel={() => setModalDelete(false)}
        />
        <SweetAlert
          show={success_delete}
          success
          title="Agenda removida com sucesso!"
          onConfirm={() => setSuccessDelete(false)}
        />
      </Card>
    );
  }
}

export default Calendar;
