import React from "react";
import { BtnCan } from "components/Button";
import { ModalResponsive } from "components/ModalResponsive";
import PersonalData from "app/routes/consult/routes/personalData";

export default function FormPause({ open, handleClose, patient }) {
  return (
    <ModalResponsive
      title="Informações do paciente"
      open={open}
      close={handleClose}
      maxWidth="md"
      buttons={
        <div className="col-12">
          <BtnCan title="Fechar" onClick={handleClose} float="left" />
        </div>
      }
    >
      <div className="row">
        <PersonalData history={true} patient={patient} />
      </div>
    </ModalResponsive>
  );
}
