import React from "react";
import { ModalResponsive } from "components/ModalResponsive";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";

export default function CalendarPatient({ open, post, onClose }) {
  const [state, setstate] = React.useState({ link: "" });

  const handleCopy = () => {
    const textInput = document.getElementById("text");

    textInput.select();
    document.execCommand("copy");
  };

  return (
    <ModalResponsive
      title={"Compartilhar"}
      open={open}
      close={onClose}
      maxWidth={"sm"}
      buttons={
        <div className="col-12">
          {/* <BtnCan title={"fechar"} onClick={onClose} float="left" /> */}
          {/* <BtnSave
            title={"Salvar Edição"}
            onClick={handleSaveEditBudget}
            float="right"
          /> */}
        </div>
      }
    >
      <div
        className="m-3 col-12 row d-flex"
        style={{ justifyContent: "center" }}
      >
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${post.url_share}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="col-3"
            style={{
              justifyContent: "center",
              borderRadius: "9px",
              display: "flex",
            }}
          >
            <FacebookIcon style={{ fontSize: "60px" }} />
          </div>
        </a>

        <a
          href={`http://twitter.com/intent/tweet?text=${post.title}&url=${post.url_share}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="col-3"
            style={{
              justifyContent: "center",
              borderRadius: "9px",
              display: "flex",
            }}
          >
            <TwitterIcon style={{ fontSize: "60px" }} />
          </div>
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${post.url_share}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="col-3"
            style={{
              justifyContent: "center",
              borderRadius: "9px",
              display: "flex",
            }}
          >
            <LinkedInIcon style={{ fontSize: "60px" }} />
          </div>
        </a>

        <div className="col-12 p-2 d-flex justify-content-center">
          <input
            id="text"
            placeholder="Seu Instagram.."
            style={{
              fontSize: "25px",
              height: "initial",
              borderRadius: "5px",
              textAlign: "center",
              border: "1px solid #a9a9a9",
              width: "-webkit-fill-available",
              // color: "#fff",
              // background: "#3b3b3b",
              // border: color.border,
              // width: "-webkit-fill-available",
            }}
            value={post.url_share}
          />
          {/* {copy && ( */}
          <IconButton id="copy" onClick={() => handleCopy()}>
            <FileCopyIcon style={{ color: "#177493" }} />
          </IconButton>
          {/* )} */}
        </div>
      </div>
    </ModalResponsive>
  );
}
