import React, { useState } from "react";
import { useSelector } from "react-redux";

import ChatUsers from "./ChatUsers";
import AppUsersInfo from "./AppUsersInfo";
import Comunication from "./Comunication";
import ModalComunication from "./ModalComunication";
import ChatIcon from "@material-ui/icons/Chat";
import Typography from "@material-ui/core/Typography";
import { Fab, Tooltip, Drawer } from "@material-ui/core";
import ModalChatActiver from "./ModalChatActiver";

export default function Chat({ match }) {
  const [userState, setUserState] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [drState, setDrState] = useState(false);
  const [listChats, setListChats] = useState([]);
  const [chatsAtctiver, setChatsAtctiver] = useState(false);
  const width = useSelector((state) => state.settings.width);
  const selectedUser = useSelector(({ chat }) => chat.selectedUser);

  const handleSetUsersMobile = (params) => {
    setListChats(params);
    setChatsAtctiver(true);
  };

  return (
    // <div className="app-wrapper app-wrapper-module">
    <div className="chat-module animated slideInUpTiny animation-duration-3">
      <div className="chat-module-box">
        <div className="d-block d-xl-none">
          <Drawer
            open={drawerState}
            onClose={() => setDrawerState(!drawerState)}
          >
            {userState ? (
              <ChatUsers
                closeDrawer={() => setDrawerState(false)}
                toggleUserState={() => setUserState(!userState)}
                setUsersMobile={handleSetUsersMobile}
              />
            ) : (
              <AppUsersInfo toggleUserState={() => setUserState(!userState)} />
            )}
          </Drawer>
        </div>

        <div className="col-12 justify-content-center chat-sidenav d-none d-xl-flex">
          {userState ? (
            <ChatUsers
              closeDrawer={() => setDrawerState(false)}
              toggleUserState={() => setUserState(!userState)}
              setUsersMobile={handleSetUsersMobile}
            />
          ) : (
            <AppUsersInfo toggleUserState={() => setUserState(!userState)} />
          )}
        </div>
        {/* {width > 900 ? ( */}
        <div className="chat-box">
          <div className="chat-box-main">
            {!selectedUser[0] ? (
              <div className="loader-view" style={{ height: "100vh" }}>
                <Typography variant="h6" gutterBottom>
                  Você ainda não possui nenhum chat
                </Typography>
                <img
                  height="30%"
                  src={require("assets/images/chat/Doutora_Chat_Prancheta.png")}
                />
                <span
                  style={{
                    color: "#b4b4b4",
                    fontSize: "large",
                    fontWeight: "400",
                  }}
                >
                  Selecione um contanto para iniciar
                </span>
                {width < 900 && (
                  <div className="col-12" style={{ display: "contents" }}>
                    <Tooltip placement="top" title="Editar">
                      <Fab
                        onClick={() => setDrState(!drState)}
                        color="primary"
                        aria-label="add"
                        style={{
                          margin: 0,
                          top: "auto",
                          right: 20,
                          bottom: width < 960 ? 80 : 10,
                          left: "auto",
                          position: "fixed",
                          zIndex: 999,
                        }}
                      >
                        <ChatIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                )}
              </div>
            ) : (
              <Comunication
                match={match}
                selectedUser={selectedUser[0]}
                onToggleDrawer={() => setDrawerState(!drawerState)}
              />
            )}
          </div>
        </div>
        {/*  ) : (
           <div className="col-12" style={{ display: "contents" }}>
             <Tooltip placement="top" title="Editar">
               <Fab
                 onClick={() => setDrState(!drState)}
                 color="primary"
                 aria-label="add"
                 style={{
                   margin: 0,
                   top: "auto",
                   right: 20,
                   bottom: width < 960 ? 80 : 10,
                   left: "auto",
                   position: "fixed",
                   zIndex: 999,
                 }}
               >
                 <ChatIcon />
               </Fab>
             </Tooltip>
           </div>
         )} */}
      </div>
      <ModalComunication open={drState} handleClose={() => setDrState(false)} />
      <ModalChatActiver
        width={width}
        drState={drState}
        setDrState={(e) => setDrState(e)}
        open={chatsAtctiver}
        listchats={listChats}
        handleClose={() => setChatsAtctiver(false)}
      />
    </div>
  );
}
