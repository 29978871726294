import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editChildren, addChildren } from "actions/configuration/Profile";
import * as alertActions from "actions/Alerts";
import TextField from "@material-ui/core/TextField";
import MaskDate from "components/Masks/MaskDate";
import Button from "@material-ui/core/Button";
import AutocompleteParents from "../autocomplete/Parents";
import Api from "services/api";
import { MenuItem } from "@material-ui/core";
import MaskCpf from "components/Masks/MaskCpf";
import MaskCellphone from "components/Masks/MaskCellphone";
import MaskPhone from "components/Masks/MaskPhone";
import { MultipleSelect } from "react-select-material-ui";

class FormChildren extends Component {
  state = {
    id: "",
    children_id: "",
    name: "",
    birthday: "",
    error: false,
    optionsParent: [],
    grau: "",
    gender: "",
    cpf: "",
    email: "",
    smartphone: "",
    phone: "",
    edit: "",
    responsability: [],
  };

  componentDidMount() {
    if (this.props.edit === true) {
      if (this.state.edit !== true) {
        this.setState({ edit: true });
      }
    } else {
      if (this.state.edit !== false) {
        this.setState({ edit: false });
      }
    }
    const { id, name, idGrau, action } = this.props.children;
    this.setState({
      id,
      children_id: id,
      name: name,
      grau: idGrau,
      responsability: action ? action.split("|") : [],
    });

    this.getGroupFamilyOptions();
  }

  getGroupFamilyOptions = () => {
    Api.post(`/microservice/profile/getOptionsFamiliGroup/`, {})
      .then((res) => {
        if (this.state.optionsParent !== res.data.record) {
          this.setState({ optionsParent: res.data.record });
        }
      })
      .catch(() => {
        this.props.viewAlertMessage("error", "Ocorreu um erro ao salvar!");
        setTimeout(() => this.props.closeAlertMessage(), 5000);
      });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeAutocomplete = () => (event) => {
    this.setState({
      [`${event.index}`]: event.value ? event.value : -1,
    });
  };

  getAge = (birthday) => {
    birthday = birthday.split("/");
    birthday = `${birthday[2]}-${birthday[1]}-${birthday[0]}`;
    var today = new Date();
    var birthDate = new Date(birthday);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  validate = () => {
    if (this.state.name) {
      this.setState({ error: false });
      return true;
    } else {
      this.setState({ error: true });
      return false;
    }
  };

  save() {
    const user = this.props.user;
    const genderUser = this.props.genderUser;
    const edit = this.state.edit;

    let {
      id,
      children_id,
      name,
      birthday,
      grau,
      gender,
      cpf,
      email,
      smartphone,
      phone,
      responsability,
    } = this.state;
    let formData = {
      user,
      genderUser,
      edit,
      data: {
        id,
        children_id,
        name,
        birthday,
        grau,
        gender,
        cpf,
        email,
        smartphone,
        phone,
        responsability,
      },
    };

    Api.post(`/microservice/profile/addFamily/`, formData)
      .then((res) => {
        // res.data.record.map((row) => {
        //   if (row.birthday) {
        //     row.birthday = this.getAge(row.birthday);
        //   } else {
        //     row.birthday = "--";
        //   }
        // });
        this.props.pushNewParent(res.data.record);
        this.props.viewAlertMessage("success", "Salvo com sucesso!");
        setTimeout(() => this.props.closeAlertMessage(), 5000);
        this.props.handleClose();
      })
      .catch(() => {
        this.props.viewAlertMessage("error", "Catch!");
        setTimeout(() => this.props.closeAlertMessage(), 5000);
      });
  }

  render() {
    const { id, children_id, name, birthday, error } = this.state;
    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="text-primary font-weight-semibold">
            {id > 0 ? "Editar membro familiar" : "Cadastrar membro familiar"}
          </h2>
        </div>
        <div className="col-md-12 col-12">
          <AutocompleteParents
            parent={{
              id: children_id,
              label: name,
            }}
            field={`children`}
            handleChangeAutocomplete={this.handleChangeAutocomplete().bind(
              this
            )}
            error={error && !name ? true : false}
          />
        </div>

        <div className="col-md-6 col-12">
          <TextField
            id="grau"
            select
            label="Grau de parentesco:"
            value={this.state.grau}
            onChange={(e) => this.setState({ grau: e.target.value })}
            SelectProps={{}}
            fullWidth
            style={{ marginTop: "17px" }}
          >
            {this.state.optionsParent.map((row) => {
              return (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              );
            })}
          </TextField>
        </div>

        <div className="col-md-6 col-12">
          <MultipleSelect
            label="Responsabilidade:"
            value={this.state.responsability}
            onChange={(e) =>
              this.setState({ ...this.state, responsability: e })
            }
            options={[
              "Acompanhate terapeutico",
              "Amigo(a)",
              "Assessor jurídico",
              "Companheiro(a)",
              "Curador",
              "Familiar",
              "Financeiro",
              "Responsável junto a ILPI",
            ]}
            SelectProps={{
              open: true,
              isCreatable: false,
              msgNoOptionsAvailable: "Todos as opções foram selecionadas",
              msgNoOptionsMatchFilter: "Nenhuma opção para este filtro",
            }}
            style={{ marginTop: "13px" }}
          />
        </div>

        {this.state.children_id === -1 && (
          <>
            <div className="col-md-12 col-12">
              <TextField
                id="birthday"
                label="Data de nascimento"
                margin="normal"
                fullWidth
                InputProps={{
                  inputComponent: MaskDate,
                  value: birthday,
                  onChange: this.handleChange("birthday"),
                }}
              />
            </div>
            <div className="col-md-4 col-12">
              <TextField
                id="sex"
                select
                label="Sexo:"
                value={this.state.gender}
                onChange={(e) => this.setState({ gender: e.target.value })}
                SelectProps={{}}
                fullWidth
                style={{ marginTop: "17px" }}
              >
                <MenuItem key="1" value="0">
                  Masculino
                </MenuItem>
                <MenuItem key="2" value="1">
                  Feminino
                </MenuItem>
                <MenuItem key="3" value="2">
                  Outro
                </MenuItem>
              </TextField>
            </div>

            <div className="col-md-4 col-12">
              <TextField
                id="cpf"
                label="CPF:"
                margin="normal"
                fullWidth
                InputProps={{
                  inputComponent: MaskCpf,
                  value: this.state.cpf,
                  onChange: (e) => this.setState({ cpf: e.target.value }),
                }}
              />
            </div>

            <div className="col-md-12">
              <TextField
                id="email"
                type="email"
                label="Email:"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                fullWidth
                required
                margin="normal"
                className="mt-0"
              />
            </div>
            <div className="col-md-6">
              <TextField
                id="smartphone"
                label="Celular:"
                margin="normal"
                fullWidth
                InputProps={{
                  inputComponent: MaskCellphone,
                  value: this.state.smartphone,
                  onChange: (e) =>
                    this.setState({ smartphone: e.target.value }),
                }}
                className="mt-0"
              />
            </div>
            <div className="col-md-6">
              <TextField
                id="fixo"
                label="Telefone Fixo:"
                margin="normal"
                fullWidth
                InputProps={{
                  inputComponent: MaskPhone,
                  value: this.state.phone,
                  onChange: (e) => this.setState({ phone: e.target.value }),
                }}
                className="mt-0"
              />
            </div>
          </>
        )}
        <div className="col-md-12 mt-20">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={() => this.props.handleClose()}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm bg-success"
            onClick={() => this.save()}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.authUser,
    genderUser: state.Profile.basic.gender,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ editChildren, addChildren, ...alertActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormChildren);
