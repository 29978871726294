import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import CustomScrollbars from "util/CustomScrollbars";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles(theme => ({
  dialogTranparent: {
    "&& .MuiPaper-root": {
      backgroundColor: "transparent"
    },
    "&& .MuiPaper-elevation24": {
      boxShadow: "none"
    }
  },
  buttonClose: {
    float: "right",
    top: "5%",
    right: "5%",
    position: "fixed",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ffffff14"
    }
  },
  scroll: {
    "&& div": {
      "&:nth-child(3)": {
        position: "fixed !important"
      }
    }
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalImage({ open, handleClose, img }) {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialogTranparent}
    >
      <div className="col-12">
        <IconButton
          className={classes.buttonClose}
          onClick={handleClose}
          style={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <CustomScrollbars
        className={`chat-list-scroll scrollbar ${classes.scroll}`}
        style={{ height: "600px" }}
      >
        <DialogContent style={{ backgroundColor: "transparent" }}>
          <img
            src={img}
            alt="nova imagem chat"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </DialogContent>
      </CustomScrollbars>
    </Dialog>
  );
}
