import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import NoRecord from "components/Alert/NoRecord";
import FormSpecialty from "../clinic/FormSpecialty";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Teams from "./Team";

function Course({ user, data, addressId, hiddenButton }) {
  const [state, setState] = useState([]);
  const [modal_form, setModalForm] = useState(false);

  useEffect(() => {
    setState(data);
  }, []);

  function addSpecialty(obj) {
    setState([...state, obj]);
  }

  const removeSpecialty = value => {
    let aux = state.filter(row => row.id !== value);
    setState(aux);
  };

  return (
    <div className="row">
      <hr className="w-100" />
      <div className="col-12 mt-3">
        <h4 className="text-weight-semibold mb-5">
          Especialidades
          <Button
            onClick={() => setModalForm(true)}
            className="jr-btn jr-btn-sm"
            color="primary"
            variant="contained"
            style={{ float: "right" }}
            hidden={hiddenButton}
          >
            <i className="zmdi zmdi-plus" /> <span> Adicionar</span>
          </Button>
        </h4>
        {state.length > 0 ? (
          state.map(row => (
            <ExpansionPanel
              square
              style={{
                boxShadow: "10px 5px 5px white",
                borderBottom: "1px solid #d2d2d2"
              }}
              key={row.id}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1d-content"
              >
                {row.specialty !== "Não especificada"
                  ? row.specialty
                  : row.area}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ display: "block" }}>
                <Teams
                  user={user}
                  data={row.teams ? row.teams : []}
                  specialty={row}
                  addressId={addressId}
                  removeSpecialty={() => removeSpecialty(row.id)}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))
        ) : (
          <NoRecord />
        )}
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={modal_form}
        onClose={() => {
          setModalForm(false);
        }}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogContent>
          <FormSpecialty
            data={""}
            addressId={addressId}
            close={() => setModalForm(false)}
            add={obj => addSpecialty(obj)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Course;
