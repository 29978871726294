import { delay } from "redux-saga";
import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import api from "services/api";

/**
 * Realiza requisições
 */

const requestSaveTagsWithConditions = async params => {
  const res = await api.post(
    `/ms_configuration/configurationTags/saveNewTagWithProgramming/`,
    params
  );
  const data = await res.data;
  return data;
};

const requestGetProgramming = async id => {
  const res = await api.post(
    `/ms_configuration/configurationTags/returnConditionsFromTag/`,
    { id }
  );
  const data = await res.data;
  return data;
};

const requestGetAllPatientsFromTag = async id => {
  const res = await api.post(
    `/ms_configuration/configurationTags/getAllPatientesFromTag`,
    { id }
  );
  const data = res.data;
  return data;
};
const requestDeleteHasTag = async id => {
  const res = await api.post(
    `/ms_configuration/configurationTags/deleteHastag`,
    { id }
  );
  return res.data;
};

const requestgetPatientesNotHasTag = async params => {
  const res = await api.post(
    `/ms_configuration/configurationTags/getPatientesNotHasTag`,
    params
  );
  return res.data;
};

const requestIncludeTagsToPatients = async params => {
  const res = await api.post(
    `/ms_configuration/configurationTags/includTagToPatients`,
    params
  );
  return res.data;
};

/**
 * Recebe resposta da requisição e chama o reducer
 */

function* executaSaveTagsWithConditions(action) {
  try {
    const res = yield call(requestSaveTagsWithConditions, action.payload);
    if (res.success) {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "TAG inserida com sucesso!"
      });
      yield put({
        type: "SET_INITIAL_CONDITIONS_TAGS"
      });
      yield put({
        type: "GET_TAGS_FROM_PROVIDER",
        address: action.payload.address
      });
      if (action.payload.patient !== undefined) {
        yield put({
          type: "GET_TAGS_FROM_PATIENT",
          address: action.payload.address,
          patient: action.payload.patient
        });
      }
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao executar esta operação"
      });
    }
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log(
      "Erro executaSaveTagsWithConditions() ** TagsConditions **",
      error
    );
  }
}

function* executaGetProgramming(action) {
  try {
    const res = yield call(requestGetProgramming, action.id);
    if (res.length > 0) {
      const programing_on_registe = yield res[0].on_register;
      yield put({
        type: "SET_ON_REGISTER",
        payload: programing_on_registe
      });
    }
    const conditions_edit = yield {
      sex: res.filter(row => row.category === "Sexo").map(row => row.val1),
      age: res
        .filter(row => row.category === "Idade")
        .map(row => ({ val1: row.val1, val2: row.val2 })),
      sickness: res
        .filter(row => row.category === "Sickness")
        .map(row => row.id_selected_itens),
      medicines: res
        .filter(row => row.category === "Medicines")
        .map(row => row.id_selected_itens),
      producere: res
        .filter(row => row.category === "Producere")
        .map(row => row.id_selected_itens),
      diseases_allergy: res
        .filter(row => row.category === "DiseasesAllergy")
        .map(row => row.id_selected_itens),
      food_allergy: res
        .filter(row => row.category === "FoodAllergy")
        .map(row => row.id_selected_itens),
      drug_allergy: res
        .filter(row => row.category === "DrugAllergy")
        .map(row => row.id_selected_itens),
      chemicals_allergy: res
        .filter(row => row.category === "ChemicalsAllergy")
        .map(row => row.id_selected_itens)
    };
    yield put({
      type: "SET_PROGRAMMING",
      payload: conditions_edit
    });
  } catch (error) {
    console.log("Erro executaGetProgramming() ** TagsConditions **", error);
  }
}

function* executaGetAllPatientes(action) {
  try {
    const res = yield call(requestGetAllPatientsFromTag, action.id_tag);
    if (res.success) {
      yield put({
        type: "SET_ALL_PATIENTS_FROM_TAG",
        payload: res.list
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Não foi possível encontrar os pacientes!"
      });
      yield delay(5000);
      yield put({ type: "CLOSE_ALERT_MESSAGE" });
    }
  } catch (error) {
    console.log("Erro executaGetAllPatientes() ** TagsConditions **", error);
  }
}

function* executaDeleteHasTag(action) {
  try {
    const res = yield call(requestDeleteHasTag, action.id_has_tag);
    if (res.success) {
      const patients_from_tag = yield call(
        requestGetAllPatientsFromTag,
        action.id_tag
      );
      if (patients_from_tag.success) {
        yield put({
          type: "SET_ALL_PATIENTS_FROM_TAG",
          payload: patients_from_tag.list
        });
      } else {
        yield put({
          type: "VIEW_ALERT_MESSAGE",
          message_type: "error",
          text_message: "Não foi possível encontrar os pacientes!"
        });
        yield delay(5000);
        yield put({ type: "CLOSE_ALERT_MESSAGE" });
      }
    }
  } catch (error) {
    console.log("Erro executaDeleteHasTag() ** TagsConditions **", error);
  }
}

function* executaGetPatientesNotHasTag(action) {
  try {
    const res = yield call(requestgetPatientesNotHasTag, {
      id_tag: action.id_tag,
      address: action.address
    });
    if (res.success) {
      yield put({
        type: "SET_PATIENTS_NOT_HAS_TAG",
        payload: res.list
      });
    }
  } catch (error) {
    console.log(
      "Erro executaGetPatientesNotHasTag() ** TagsConditions **",
      error
    );
  }
}

function* executaIncludeTagToPatients(action) {
  try {
    const res = yield call(requestIncludeTagsToPatients, action.payload);
    if (res.success) {
      const patients_from_tag = yield call(
        requestGetAllPatientsFromTag,
        action.payload.tags
      );
      if (patients_from_tag.success) {
        yield put({
          type: "SET_ALL_PATIENTS_FROM_TAG",
          payload: patients_from_tag.list
        });
      } else {
        yield put({
          type: "VIEW_ALERT_MESSAGE",
          message_type: "error",
          text_message: "Não foi possível encontrar os pacientes!"
        });
        yield delay(5000);
        yield put({ type: "CLOSE_ALERT_MESSAGE" });
      }
    }
  } catch (error) {
    console.log(
      "Erro executaIncludeTagToPatients() ** TagsConditions **",
      error
    );
  }
}

/**
 * Escuta actions e executa funções
 */

function* listenSaveTagsWithConditions() {
  yield takeLatest("SAVE_CONDITIONS", executaSaveTagsWithConditions);
}

function* listenGetProgramming() {
  yield takeLatest("GET_PROGRAMMING_FROM_TAG", executaGetProgramming);
}

function* listenGetAllPaTientsFromTag() {
  yield takeLatest("GET_ALL_PATIENTS_FROM_TAG", executaGetAllPatientes);
}

function* listenDeleteHasTag() {
  yield takeLatest("DELETE_USER_HAS_TAG", executaDeleteHasTag);
}

function* listenGetPatientesNotHasTag() {
  yield takeLatest("GET_PATIENTS_NOT_HAS_TAG", executaGetPatientesNotHasTag);
}

function* listenIncludeTagToPatients() {
  yield takeLatest("INCLUDE_TAG_TO_PATIENTS", executaIncludeTagToPatients);
}

/**
 * Exporta funções do saga
 */

export default function* rootSaga() {
  yield all([
    fork(listenSaveTagsWithConditions),
    fork(listenGetProgramming),
    fork(listenGetAllPaTientsFromTag),
    fork(listenDeleteHasTag),
    fork(listenGetPatientesNotHasTag),
    fork(listenIncludeTagToPatients)
  ]);
}
