import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%",
  },
  root: {
    backgroundColor: "#fff",
  },
  selected: {
    backgroundColor: "#e2e2e8 !important",
  },
};

function AutocompleteNaturalty({ setNaturalty, error, classes, naturalty }) {
  const [suggestions, setSuggestions] = useState([]);

  function getSuggestions(e) {
    if (e.target.value.length > 2) {
      Api.post(`/ms_system/user/autocompleteNaturalty/`, {
        term: e.target.value,
      }).then((res) => {
        const { data } = res;
        setSuggestions(data.record);
      });
    }
  }

  function renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        value={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
      >
        {suggestion.label}
      </MenuItem>
    );
  }

  function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        error={error}
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...InputProps,
        }}
        helperText={error ? "Campo Obrigatório" : ""}
        onKeyUp={(e) => getSuggestions(e)}
        onChange={() => {}}
        InputLabelProps={{ shrink: true }}
      />
    );
  }

  return (
    <Downshift
      onSelect={(selectedItem) => {
        let item = suggestions.filter((row) => {
          return row.label === selectedItem;
        });
        setNaturalty(item[0]);
      }}
      selectedItem={naturalty.label ? naturalty.label : null}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        // inputValue,
        selectedItem,
        highlightedIndex,
      }) => (
        <div className={classes.container}>
          {renderInput({
            fullWidth: true,
            classes,
            InputProps: getInputProps({
              id: `ac-naturalty`,
            }),
            label: "Residente Em:",
            margin: "normal",
          })}
          {isOpen ? (
            <Paper square>
              {suggestions.map((suggestion, index) =>
                renderSuggestion({
                  suggestion,
                  index,
                  itemProps: getItemProps({
                    item: suggestion.label,
                  }),
                  highlightedIndex,
                  selectedItem,
                })
              )}
            </Paper>
          ) : null}
        </div>
      )}
    </Downshift>
  );
}

AutocompleteNaturalty.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutocompleteNaturalty);
