import React, { PureComponent } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function ChildsGraphic({ record }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        layout="vertical"
        width="100%"
        height={250}
        data={[
          {
            name: "Crianças",
            cadastradas: record.length,
            vacinadas: record.filter((row) => row.vaccine).length,
          },
        ]}
        margin={{
          top: 20,
          right: 40,
          bottom: 0,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" scale="band" />
        <Tooltip />
        <Legend />
        <Bar dataKey="cadastradas" barSize={20} fill="#028ebe" />
        <Bar dataKey="vacinadas" barSize={20} fill="#4caf50" />
        {/* <Line dataKey="uv" stroke="#ff7300" /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
