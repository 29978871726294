import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import { Button, TextField, IconButton, Tooltip } from "@material-ui/core";

function FormRoom({ data, addressId, close, add, edit }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.id) {
      setName(data.name);
      setDescription(data.description);
    }
  }, []);

  function save() {
    if (name) {
      Api.post(`/ms_system/workplace/saveRoom/`, {
        addressId,
        id: data.id ? data.id : "",
        name,
        description
      })
        .then(res => {
          const payload = {
            id: res.data.id,
            name,
            description
          };
          if (!data.id) {
            add(payload);
          } else {
            edit(payload);
          }
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
          close();
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage(), 5000));
        });
    } else {
      setError(true);
    }
  }

  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <h2 clas sName="text-primary font-weight-semibold">
          Salas de atendimento
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
      </div>

      <div className="col-12">
        <TextField
          id="name"
          label="Nome:"
          value={name}
          margin="normal"
          fullWidth
          onChange={e => setName(e.target.value)}
          error={error && !name ? true : false}
        />
      </div>
      <div className="col-12">
        <TextField
          id="description"
          label="Descrição:"
          value={description}
          margin="normal"
          fullWidth
          onChange={e => setDescription(e.target.value)}
        />
      </div>

      <div className="col-md-12 mt-3">
        <Button
          color="secondary"
          variant="contained"
          className="jr-btn jr-btn-sm"
          onClick={() => close()}
          style={{ float: "left" }}
        >
          <i className="zmdi zmdi-close zmdi-hc-lg" />
          <span> Cancelar </span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          className="jr-btn jr-btn-sm bg-success"
          onClick={() => save()}
          style={{ float: "right" }}
        >
          <i className="zmdi zmdi-check zmdi-hc-lg" />
          <span> Salvar </span>
        </Button>
      </div>
    </div>
  );
}

export default FormRoom;
