import React, { Component } from "react";

export default class Stopwatch extends Component {
  constructor() {
    super();
    this.state = {
      second: 0 + "0",
      minute: 0 + "0",
      hour: 0 + "0",
    };
    this.handleContadorplus = this.handleContadorplus.bind(this);
    this.aumentar = this.aumentar.bind(this);
  }

  handleContadorplus() {
    const startDate = new Date(this.props.init);
    // Do your operations
    const endDate = new Date();
    const diff_seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    let diff_minutes = parseInt(parseInt(diff_seconds) / 60);
    let diff_sec = parseInt(parseInt(diff_seconds) % 60);
    let diff_hour = parseInt(parseInt(diff_minutes) / 60);
    diff_minutes = parseInt(parseInt(diff_minutes) % 60);
    diff_sec = diff_sec < 10 ? "0" + diff_sec : diff_sec;
    diff_minutes = diff_minutes < 10 ? "0" + diff_minutes : diff_minutes;
    diff_hour = diff_hour < 10 ? "0" + diff_hour : diff_hour;

    this.setState({ second: diff_sec, minute: diff_minutes, hour: diff_hour });
    this._interval = setInterval(this.aumentar, 1000);
  }
  aumentar() {
    const { second, minute, hour } = this.state;
    let sec = second;
    let min = minute;
    let hr = hour;

    if (sec < 59) {
      sec++;
      if (sec < 10) {
        sec = "0" + sec;
      }
    } else if (sec === 59 && min < 59) {
      sec = 0 + "0";
      min++;
      if (min < 10) {
        min = "0" + min;
      }
    }
    if (min === 59 && sec === 59 && hr < 23) {
      sec = 0 + "0";
      min = 0 + "0";
      hr++;
      if (hr < 10) {
        hr = "0" + hr;
      }
    } else if (min === 59 && sec === 59 && hr === 23) {
      sec = 0 + "0";
      min = 0 + "0";
      hr = 0 + "0";
    }

    this.setState({ second: sec, minute: min, hour: hr });
  }

  componentDidMount() {
    this.handleContadorplus();
  }

  render() {
    return (
      <span>
        <small>ATENDIMENTO:</small>{" "}
        <i className="zmdi zmdi-timer zmdi-hc-fw zmdi-hc-lg" />
        {`    ${this.state.hour}:${this.state.minute}:${this.state.second}`}
      </span>
    );
  }
}
