import React, { useState, useEffect } from "react";

import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Complainst from "./Complainst";

export default function Pain({ handleClose, saved }) {
  const [progress, setProgress] = useState(0);

  function next() {
    setProgress(progress + 1);
  }

  function back() {
    setProgress(progress - 1);
  }

  useEffect(() => {
    progress === 2 && handleClose();
  }, [progress]);

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-10 text-left">
          <Typography className="pt-1" variant="h6" gutterBottom>
            Problemas não relatados
          </Typography>
        </div>
        <div className="col-2">
          <IconButton onClick={handleClose} color="default">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div
        className="card"
        style={{
          marginLeft: "-15px",
          marginRight: "-15px",
          paddingTop: "1rem",
        }}
      >
        <Complainst
          progress={progress}
          handleNext={next}
          handleBack={back}
          handleClose={handleClose}
          saved={saved}
        />
      </div>
    </div>
  );
}
