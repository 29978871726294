const INIT_STATE = {
  id: "",
  creator: "",
  quantity: 0,
  list: [],
  loaded: false,
};

export default function intranet(state = INIT_STATE, action) {
  switch (action.type) {
    case "SIGNOUT_INTRANET":
      return INIT_STATE;
    case "SET_DATA_INTRANET":
      return {
        id: action.id,
        creator: action.creator,
        quantity: action.quantity,
        list: action.list,
        loaded: true,
      };
    case "SIGNIN_INTRANET":
      return {
        ...state,
        id: action.id,
        creator: action.creator,
      };
    case "EXIT_INTRANET":
      return {
        ...state,
        id: "",
        creator: "",
      };
    default:
      return state;
  }
}
