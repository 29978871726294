import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import asyncComponent from "../../../util/asyncComponent";

export default function Admission({ match, history }) {
  const patient = useSelector(({ admission }) => admission.patient);

  React.useEffect(() => {
    if (!patient) {
      history.push("/app/choose/data-admission");
    }
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/`}
        component={asyncComponent(() => import("./routes/dataPatient"))}
      />
      <Route
        path={`${match.url}/data-patient`}
        component={asyncComponent(() => import("./routes/dataPatient"))}
      />
      <Route
        path={`${match.url}/responsible`}
        component={asyncComponent(() => import("./routes/responsible"))}
      />
      <Route
        path={`${match.url}/reason`}
        component={asyncComponent(() => import("./routes/reason"))}
      />
      <Route
        path={`${match.url}/avaliation`}
        component={asyncComponent(() => import("./routes/avaliation"))}
      />
      <Route
        path={`${match.url}/system_review/`}
        component={asyncComponent(() => import("./routes/systemReview"))}
      />
      <Route
        path={`${match.url}/functional-avaliation`}
        component={asyncComponent(() =>
          import("./routes/functionalAvaliation")
        )}
      />
      <Route
        path={`${match.url}/personal-history`}
        component={asyncComponent(() => import("./routes/personalHistory"))}
      />
      <Route
        path={`${match.url}/family-history`}
        component={asyncComponent(() => import("./routes/familyHistory"))}
      />
      <Route
        path={`${match.url}/psychic-avaliation`}
        component={asyncComponent(() => import("./routes/psychicAvaliation"))}
      />
      <Route
        path={`${match.url}/diagnostic-conclusion`}
        component={asyncComponent(() =>
          import("./routes/diagnosticConclusion")
        )}
      />
      <Route
        path={`${match.url}/report`}
        component={asyncComponent(() => import("./routes/report"))}
      />
    </Switch>
  );
}
