const INITIAL_STATE = {
  register: {
    id_restrict_info: "",
    consult_info: "",
    restrict_info: "",
    edited: false,
  },
  cids: [],
  id_appointment_diagnostic: "",
  procedures: [],
  infoProcedureGroupSelect: [],
  tableProcedure: [],
  save_mention: 0,
  save_mention_procedure: 0,
  save_mention_ciap: 0,
  save_modal_reg_att: 0,
  outros: true,
};

export default function attendanceRecord(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_ATTENDANCE_RECORD":
      return {
        ...state,
      };
    case "HANDLE_CHANGE":
      const { index, value } = action;
      return {
        ...state,
        [`${index}`]: value,
      };
    case "HANDLE_CHANGE_REGISTER":
      return {
        ...state,
        register: {
          ...state.register,
          [`${action.index}`]: action.value,
          edited: true,
        },
      };
    case "INIT_REG_ATTENDANCE":
      return {
        ...state,
        register: {
          ...state.register,
          consult_info: action.consult_info,
          restrict_info: action.restrict_info,
          edited: false,
        },
      };
    case "ALTER_EDITED_REGISTER_ATTENDANCE":
      return {
        ...state,
        register: {
          ...state.register,
          edited: false,
        },
      };
    //PROCEDIMENTOS
    case "ADD_LIST_PROCEDURE":
      return {
        ...state,
        procedures: [...state.procedures, action.payload],
      };

    case "REMOVE_LIST_PROCEDURE":
      let list_proc = state.procedures.filter(
        (row) => row.id !== action.payload
      );
      return {
        ...state,
        procedures: [...list_proc],
      };
    case "CHANGE_OBS_PROCEDURE":
      let obs_proc = state.procedures.map((row) => {
        if (row.id === action.procedure) {
          return {
            ...row,
            obs: action.value,
          };
        }
        return row;
      });
      return {
        ...state,
        procedures: [...obs_proc],
      };

    case "GET_INFO_PROCEDURE_GROUP":
      return {
        ...state,
        id: action.payload.id,
        outros: false,
      };

    case "INFO_PROCEDURES_GROUP_SUCCESS":
      return {
        ...state,
        tableProcedure: action.payload.infoProcedureGroupSelect,
        outros: false,
      };

    case "SET_SELECTED_PROCEDURE":
      let aux2 = [...state.procedures];
      let aux = [];
      let exist = -1;
      state.tableProcedure.forEach((row) => {
        if (row.id === action.payload.id) {
          aux2.forEach((row2) => {
            if (row2.id === action.payload.id) {
              exist = 0;
            }
          });
          if (exist === -1) {
            aux2.push({ ...row, check: action.payload.value });
          }
        } else {
          aux.push({ ...row });
        }
      });
      return {
        ...state,
        procedures: aux2,
        tableProcedure: aux,
      };

    case "SET_OUTROS": {
      return {
        ...state,
        outros: true,
      };
    }
    case "HANDLE_SAVE_MENTION": {
      return {
        ...state,
        save_mention: state.save_mention + 1,
      };
    }
    case "HANDLE_SAVE_MENTION_PROCEDURE": {
      return {
        ...state,
        save_mention_procedure: state.save_mention_procedure + 1,
      };
    }
    case "HANDLE_SAVE_MENTION_CIAP": {
      return {
        ...state,
        save_mention_ciap: state.save_mention_ciap + 1,
      };
    }
    case "SAVE_MODAL_REG_ATT": {
      return {
        ...state,
        save_modal_reg_att: state.save_modal_reg_att + 1,
      };
    }
    case "RESET_ATTENDANCE_RECORD":
      return INITIAL_STATE;

    default:
      return state;
  }
}
