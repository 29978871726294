import { all, takeLatest, put } from "redux-saga/effects";

function* asyncSignOff() {
  try {
    yield put({ type: "RESET_ALL", opt: "SIGNOUT" });
  } catch (error) {}
}
export default function* rootSaga() {
  yield all([takeLatest("SIGNOUT_USER", asyncSignOff)]);
}
