import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from "components/CircularProgress";
import {
  Button,
  Switch,
  FormControlLabel,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from "@material-ui/core";
// import Api from "services/api";

export default function ComponentTerms({ handleBack, handleNext }) {
  const initialState = {
    license: false,
    terms: false,
    modalLicense: false,
    modalTerms: false,
    error: false,
  };
  const [state, setState] = useState(initialState);
  const [alert, setAlert] = useState(false);
  const [load, setLoad] = useState(false);
  const { license, terms, modalLicense, modalTerms, error } = state;

  const validate = () => {
    // if (license && terms) {
    if (terms) {
      setState({ ...state, error: false });
      setLoad(true);
      handleNext(license, terms);
    } else {
      setAlert(true);
      setState({ ...state, error: true });
    }
  };

  return (
    <>
      {load ? (
        <CircularProgress />
      ) : (
        <>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-6 panel" style={{ textAlign: "left" }}>
              <div className="titleCustomByPln title2">Termo de uso</div>
              <form>
                {/* <p>
                  <FormControlLabel
                    control={
                      <Switch
                        // value={license}
                        required
                        checked={license ? true : false}
                        onChange={() =>
                          setState({ ...state, license: !license })
                        }
                        color="primary"
                      />
                    }
                    error={error && !license ? true : false}
                    required
                    label="Licença de uso para avaliação"
                  />
                  <Tooltip title="Visualizar Licença" placement="top">
                    <IconButton
                      onClick={() => setState({ ...state, modalLicense: true })}
                    >
                      <i className="zmdi zmdi-eye"></i>
                    </IconButton>
                  </Tooltip>
                </p> */}
                <p>
                  <FormControlLabel
                    control={
                      <Switch
                        // value={license}
                        required
                        checked={terms ? true : false}
                        onChange={() => setState({ ...state, terms: !terms })}
                        color="primary"
                      />
                    }
                    error={error && !terms ? true : false}
                    required
                    label="Termos de uso"
                  />
                  <Tooltip title="Visualizar Termos" placement="top">
                    <IconButton
                      onClick={() => setState({ ...state, modalTerms: true })}
                    >
                      <i className="zmdi zmdi-eye"></i>
                    </IconButton>
                  </Tooltip>
                </p>
              </form>
              {/* <form action="http://localhost/salutem-webapp/app/system/user/terms/" method="post">
                <p>
                    <label style="margin-top: 24px;">
                        <input id="license" name="license" type="checkbox" title="Campo obrigatório" className="input-required-label">*
                        <a href="#" data-toggle="modal" data-target="#myModal2" required="">
                        Licença de uso para avaliação
                        </a>
                        <br>
                    </label>
                </p>
                <p>
                    <label>
                        <input id="terms" name="terms" type="checkbox" title="Campo obrigatório" className="input-required-label">*
                        <a href="#" data-toggle="modal" data-target="#myModal" required="">
                        Termos de uso
                        </a>
                        <br>
                    </label>
                </p>
                <p>
                    <a href="http://localhost/salutem-webapp/app///" className="btnCustomByPln btn btn-danger btn_danger_lay "><i className="fa fa-fw fa-remove"></i>Cancelar</a>
                    <button id="accept_terms" type="submit" className="btnCustomByPln btn btn-default btn_default_lay col-md-2" disabled="disabled"><i className="fa fa-fw fa-check"></i>Aceitar</button>
                </p>
            </form> */}
            </div>
          </div>
          <div
            className="d-flex align-center mt-4"
            style={{ justifyContent: "space-between" }}
          >
            <Button className="jr-btn" onClick={handleBack}>
              Voltar
            </Button>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={validate}
              className="jr-btn"
            >
              Finalizar
            </Button> */}
          </div>
          <Dialog
            onClose={() => setState({ ...state, modalLicense: false })}
            aria-labelledby="customized-dialog-title"
            open={modalLicense}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => setState({ ...state, modalLicense: false })}
            >
              Licença de Uso
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Direito de uso de todas as funcionalidades e ferramentas do
                Portal Ligado na Saúde, isento do pagamento da licença de uso
                para avaliação do mesmo no período de 3 meses, a serem contados
                a partir da data de ativação do cadastro. Como o portal
                possibilita que os Profissionais de Saúde, Prestadores de
                Serviço, Estudantes e Pacientes/Usuários utilizem a
                funcionalidade de armazenamento de arquivos na nuvem, será
                concedido sem custo 2GB para uso exclusivo no período de
                avaliação. Ultrapassando o espaço de 2GB concedido sem custo, o
                usuário pagará por cada pacote contratado de 5GB como segue:
              </Typography>
              <Typography gutterBottom>Pagamento a vista: US$ 12,00</Typography>
              <Typography gutterBottom>03 parcelas de: US$ 4,00</Typography>
              <Typography gutterBottom>
                Todo o espaço contratado no período de avaliação permanecerá
                disponível quando o usuário do Portal renovar a Licença de uso,
                optando por um dos planos disponíveis.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setState({ ...state, modalLicense: false })}
                color="primary"
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            onClose={() => setState({ ...state, modalTerms: false })}
            aria-labelledby="customized-dialog-title"
            open={modalTerms}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => setState({ ...state, modalTerms: false })}
            >
              Termos de Uso
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                O endereço eletrônico www.salutemplus.com de propriedade da
                empresa Ligado na Saúde Ltda., é uma plataforma web, que atua em
                atividades de prestação de serviços de tratamento de dados,
                provedores de serviços de aplicação e serviços de hospedagem na
                internet, desenvolvimento/criação de interfaces para internet
                (web design), desenvolvimento e licenciamento de programas de
                computador customizáveis e não customizáveis, consultoria em
                tecnologia da informação, suporte técnico, manutenção e outros
                serviços em tecnologia da informação, edição de listas de dados
                e de outras informações, cuja forma impressa, eletrônica e na
                internet, agência de publicidade e desenvolvimento de programas
                de computador sob encomenda. Ao acessar este site, você, usuário
                pessoa física ou jurídica, se obriga, declara compreender e
                aceita as condições aqui estabelecidas, da seguinte forma:
              </Typography>
              <Typography gutterBottom>
                <ol className="c4 lst-kix_list_1-0 start" start="1">
                  <li className="c1" id="h.gjdgxs">
                    <span className="c0">
                      O Termo de Uso pode ser modificado pelo Ligado na Saúde
                      Ltda. a qualquer tempo, sem necessidade de notificação
                      prévia, sendo que tais modificações tornar-se-ão válidas a
                      partir da data de sua veiculação neste site.
                    </span>
                  </li>
                </ol>
              </Typography>
              <Typography gutterBottom>
                <ol className="c4 lst-kix_list_1-0 start" start="2">
                  <li className="c1" id="h.gjdgxs">
                    <span className="c0">
                      Para aprovação de cadastro do profissional da saúde ou
                      prestador de serviço da área de saúde ao sistema, o Ligado
                      na Saúde Ltda. pode requisitar o fornecimento de
                      informações e/ou cópias de documentos adicionais, haja
                      vista a política de segurança adotada, objetivando
                      resguardar a segurança dos usuários e clientes nesse
                      ambiente virtual;
                    </span>
                  </li>
                </ol>
              </Typography>
              <Typography gutterBottom>
                <ol className="c4 lst-kix_list_1-0" start="3">
                  <li className="c1">
                    <span className="c0">
                      O usuário declara que as informações prestadas serão
                      verdadeiras, exatas, atuais e completas, e que deverão ser
                      mantidas atualizadas durante sua permanência como usuário
                      do site, ciente de que as informações e os documentos
                      solicitados e não enviados em data aprazada ensejarão o
                      cancelamento do seu pedido de cadastro/registro, e que o
                      Ligado na Saúde Ltda. não se responsabiliza pela
                      veracidade dessas informações.
                    </span>
                  </li>
                </ol>
              </Typography>
              <Typography gutterBottom>
                <ol className="c4 lst-kix_list_1-0" start="4">
                  <li className="c1">
                    <span className="c0">
                      O usuário aceita e declara compreender que o Ligado na
                      Saúde Ltda. envidará seus maiores esforços para assegurar
                      que as informações, instrumentos e materiais oferecidos
                      neste site sejam tão atualizados, precisos e completos
                      quanto possível, mas que, em nenhuma circunstância, o
                      Ligado na Saúde Ltda. poderá ser responsabilizada por
                      fatos que não lhe sejam exclusivamente atribuíveis e
                      eivados de dolo quanto à integridade, atualidade,
                      exatidão, sigilo e utilização de tais informações,
                      instrumentos e ferramentas virtuais;
                    </span>
                  </li>
                </ol>
              </Typography>
              <Typography gutterBottom>
                <ol className="c4 lst-kix_list_1-0" start="5">
                  <li className="c1">
                    <span className="c0">
                      O usuário aceita e declara compreender que, em razão do
                      peculiar ambiente da Internet, o Ligado na Saúde Ltda. não
                      poderá garantir que o acesso ao site seja livre de erros
                      ou problemas decorrentes de casos fortuitos, internos ou
                      externos, casos de força maior ou ainda de outros casos
                      não inteiramente sujeitos a controle direto dos
                      administradores do site, e portanto o usuário se obriga a
                      isentar Ligado na Saúde Ltda. de quaisquer reclamações
                      referentes a descumprimento de prazo, interrupções,
                      interceptações, invasões, pichações, disseminação de vírus
                      ou outros atos ilícitos, típicos e atípicos de ambiente
                      virtual, e de web, dos quais o Ligado na Saúde Ltda. não
                      tenha tido intenção deliberada de participar ou praticar;
                    </span>
                  </li>
                </ol>
              </Typography>
              <Typography gutterBottom>
                <ol className="c4 lst-kix_list_1-0" start="6">
                  <li className="c1">
                    <span className="c0">
                      O usuário aceita e declara compreender que qualquer texto,
                      marca, áudio, imagem, ou “CONTEÚDO” de terceiros
                      veiculados no site são protegidos por direitos de
                      propriedade intelectual pertencentes ao Ligado na Saúde
                      Ltda. e aos anunciantes/usuários dos produtos/serviços
                      divulgados, razão pela qual não podem ser copiados,
                      reproduzidos ou simplesmente republicados em nenhuma
                      hipótese, salvo se expressamente autorizado pelo
                      respectivo detentor dos referidos direitos, sob pena de
                      apuração das responsabilidades cabíveis, bem como cobrança
                      por danos morais e materiais;
                    </span>
                  </li>
                </ol>
              </Typography>
              <Typography gutterBottom>
                <ol className="c4 lst-kix_list_1-0" start="7">
                  <li className="c1">
                    <span className="c0">
                      O usuário aceita e declara compreender que a Ligado na
                      Saúde Ltda. poderá disponibilizar no endereço eletrônico
                      https://www.salutemplus.com, links de acesso para outros
                      sites e endereços virtuais, sem qualquer responsabilidade
                      pelos serviços ou funcionalidades ali dispostos, sendo a
                      decisão de utilização e a forma de relacionamento com os
                      mesmos de exclusiva responsabilidade do usuário, que
                      inclusive isenta o Ligado na Saúde Ltda. de fiscalizar o
                      conteúdo ou zelar pela integridade de tais sites ou
                      endereços virtuais;
                    </span>
                  </li>
                </ol>
              </Typography>
              <Typography gutterBottom>
                <ol className="c4 lst-kix_list_1-0" start="8">
                  <li className="c1">
                    <span className="c0">
                      O usuário (profissional da saúde e o prestador de serviço
                      da área da saúde) aceita e declara compreender que o
                      acesso a determinadas áreas do site será restrito. Para
                      acessá-las, o usuário (profissional da saúde e o prestador
                      de serviço da área da saúde) deverá fazer o login e
                      cadastrar uma senha de acesso. A senha é individual,
                      sigilosa e intransferível, sendo o usuário (profissional
                      da saúde e o prestador de serviço da área da saúde) o
                      único responsável pela guarda da mesma. O Usuário
                      (profissional da saúde e o prestador de serviço da área da
                      saúde) assume toda e qualquer responsabilidade pelo mau
                      uso ou pela utilização da senha por terceiros.
                    </span>
                  </li>
                </ol>
              </Typography>
              <Typography gutterBottom>
                <ol className="c4 lst-kix_list_1-0" start="9">
                  <li className="c1">
                    <span className="c6 c13">
                      As condições estabelecidas no Termo de aceite e Uso do
                      Site são regidas pela lei brasileira, e anuindo ao
                      presente o usuário se submete aos juizados e tribunais do
                      Foro Central da Comarca da Capital do Estado de Minas
                      Gerais, com renúncia expressa a qualquer outro, por mais
                      privilegiados que possam parecer.
                    </span>
                  </li>
                </ol>
              </Typography>

              <Typography gutterBottom>
                As condições estabelecidas no Termo de Uso do Site são regidas
                pela lei brasileira, e anuindo ao presente o usuário se submete
                aos juizados e tribunais do Foro Central da Comarca da Capital
                do Estado de Minas Gerais, com renúncia expressa a qualquer
                outro, por mais privilegiados que possam parecer.
              </Typography>
              <Typography gutterBottom>
                <b>Portal:</b> um portal é um site na internet que funciona como
                centro aglomerador e distribuidor de tráfego para uma série de
                outros sites ou subsites dentro, e fora, do domínio ou
                subdomínio da empresa gestora do portal.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setState({ ...state, modalTerms: false })}
                color="primary"
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
          <SweetAlert
            show={alert}
            // show={true}
            warning
            showConfirm={true}
            title="Por favor. Aceite todos os termos"
            onConfirm={() => setAlert(false)}
          />
        </>
      )}
    </>
  );
}
