import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeRegister } from "actions/consult/AttendanceRecord";
import { setAppointment, setSavedActions } from "actions/consult/Appointment";
import { Mention, MentionsInput } from "react-mentions";
import provideExampleValue from "./higher-order";
import defaultStyle from "./defaultStyle";
import Api from "services/api";

function fetchCids(query, callback) {
  if (query) {
    Api.post(`/microservice/autocomplete/getCidsNoCode`, { term: query })
      .then((res) =>
        res.data.map((el) => ({ display: el.short_description, id: el.id }))
      )
      .then(callback);
  }
}

function fetchProcedures(query, callback) {
  if (query) {
    Api.post(`/microservice/autocomplete/getProcedures`, { term: query })
      .then((res) =>
        res.data.map((el) => ({ display: el.description, id: el.id }))
      )
      .then(callback);
  }
}

function fetchCiap(query, callback) {
  if (query) {
    Api.post(`/microservice/autocomplete/getCiap`, { term: query })
      .then((res) =>
        res.data.record.map((el) => ({ display: el.label, id: el.id }))
      )
      .then(callback);
  }
}

function MultipleTriggers() {
  const address = useSelector((state) => state.auth.address);
  const doctor = useSelector((state) => state.auth.authUser);
  const prof_resp = useSelector((state) => state.appointment.prof_resp);
  const start = useSelector((state) => state.appointment.start);
  const saved_id = useSelector((state) => state.appointment.saved.id);
  const saved = useSelector((state) => state.appointment.saved.diagnostic);
  const saved_proc = useSelector((state) => state.appointment.saved.procedure);
  const saved_ciap = useSelector((state) => state.appointment.saved.ciap);
  const patient = useSelector((state) => state.appointment.patient);
  const appointment = useSelector((state) => state.appointment.id);
  const consult_info = useSelector(
    ({ attendanceRecord }) => attendanceRecord.register.consult_info
  );
  const dispatch = useDispatch();

  const handleSelectCid = (cid) => {
    let formData = {
      appointment: { id: appointment, start },
      patient,
      doctor,
      address,
      prof_resp,
      saved_id,
      saved,
      cid,
    };
    Api.post(`/microservice/consult/saveMentionDiagnostic`, formData).then(
      (res) => {
        if (res.data.success) {
          if (!appointment) {
            dispatch(setAppointment(res.data.appointment));
          }
          if (!saved_id) {
            dispatch(setSavedActions("diagnostic", res.data.saved));
          }
          dispatch({
            type: "HANDLE_SAVE_MENTION",
          });
        }
      }
    );
  };

  const handleSelectProcedure = (procedure) => {
    let formData = {
      appointment: { id: appointment, start },
      patient,
      doctor,
      address,
      prof_resp,
      saved_id,
      saved: saved_proc,
      record: {
        procedure,
        observation: "",
      },
      type: "appointment",
    };
    Api.post(`/microservice/consult/saveAppointmentProcedures`, formData).then(
      (res) => {
        if (!appointment) {
          dispatch(setAppointment(res.data.id));
        }
        if (saved_proc === "0") {
          dispatch(setSavedActions("procedure", res.data.saved));
        }
        dispatch({
          type: "HANDLE_SAVE_MENTION_PROCEDURE",
        });
      }
    );
  };

  const handleSelectCiap = (ciap) => {
    console.log("ciap", ciap);
    let formData = {
      appointment: { id: appointment, start },
      patient,
      doctor,
      address,
      prof_resp,
      saved_id,
      saved: saved_ciap,
      record: {
        ciap,
        observation: "",
      },
      type: "mention",
    };
    Api.post(`/microservice/consult/saveAppointmentCiap`, formData).then(
      (res) => {
        if (!appointment) {
          dispatch(setAppointment(res.data.id));
        }
        if (saved_proc === "0") {
          dispatch(setSavedActions("procedure", res.data.saved));
        }
        dispatch({
          type: "HANDLE_SAVE_MENTION_CIAP",
        });
      }
    );
  };

  return (
    <MentionsInput
      value={consult_info ? consult_info : ""}
      className="inputTeste"
      onChange={(e, x, value) => {
        dispatch(handleChangeRegister("consult_info", value));
      }}
      allowSpaceInQuery={true}
      style={defaultStyle}
      rows={8}
    >
      <Mention
        trigger="@"
        onAdd={handleSelectCid}
        data={fetchCids}
        style={defaultStyle}
      />
      <Mention
        trigger="$"
        onAdd={handleSelectProcedure}
        data={fetchProcedures}
        style={defaultStyle}
      />
      <Mention
        trigger="&"
        onAdd={handleSelectCiap}
        data={fetchCiap}
        style={defaultStyle}
      />
    </MentionsInput>
  );
}

const asExample = provideExampleValue("");

export default asExample(MultipleTriggers);
