import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import NoRecord from "components/Alert/NoRecord";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  MenuItem,
  Button,
  TextField,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Dialog,
  DialogContent
} from "@material-ui/core";

function Calendar({ data, addressId }) {
  const [showForm, setShowForm] = useState(false);
  const [list, setList] = useState(data);
  const [modal_delete, setModalDelete] = useState(false);
  const [idDel, setIdDel] = useState("");
  const [state, setState] = useState({
    id: "",
    start: "",
    end: "",
    weekday: "",
    hours_limit_rescheduling: "",
    error: false
  });
  const dispatch = useDispatch();

  const ComponentForm = () => {
    return (
      <>
        <div className="col-md-4 col-12">
          <TextField
            id="day_week"
            select
            label="Dia da Semana:"
            value={state.weekday}
            onChange={e => {
              setState({ ...state, weekday: e.target.value });
            }}
            SelectProps={{}}
            fullWidth
            className="mt-0"
            error={state.error && !state.day ? true : false}
          >
            <MenuItem key="1" value="0">
              Domingo
            </MenuItem>
            <MenuItem key="2" value="1">
              Segunda-Feira
            </MenuItem>
            <MenuItem key="3" value="2">
              Terça-Feira
            </MenuItem>
            <MenuItem key="3" value="3">
              Quarta-Feira
            </MenuItem>
            <MenuItem key="3" value="4">
              Quinta-Feira
            </MenuItem>
            <MenuItem key="3" value="5">
              Sexta-Feira
            </MenuItem>
            <MenuItem key="3" value="6">
              Sábado
            </MenuItem>
            <MenuItem key="3" value="7">
              Segunda à Sexta
            </MenuItem>
            <MenuItem key="3" value="8">
              Todos os dias
            </MenuItem>
          </TextField>
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="day_week"
            type="time"
            label="Início:"
            value={state.start}
            onChange={e => {
              setState({ ...state, start: e.target.value });
            }}
            SelectProps={{}}
            fullWidth
            className="mt-0"
            error={state.error && !state.start ? true : false}
          />
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="day_week"
            type="time"
            label="Fim:"
            value={state.end}
            onChange={e => {
              setState({ ...state, end: e.target.value });
            }}
            SelectProps={{}}
            fullWidth
            className="mt-0"
            error={state.error && !state.end ? true : false}
          />
        </div>
      </>
    );
  };

  function save() {
    if (state.weekday && state.start && state.end) {
      setState({ ...state, error: false });
      const obj = {
        ...state,
        addressId
      };
      Api.post(`/ms_system/workplace/saveCalendarOfficeHourAddress/`, obj)
        .then(res => {
          if (res.data.length > 0 && res.data.length !== undefined) {
            let newList = [];
            res.data.forEach(data => {
              const {
                id,
                start,
                end,
                weekday,
                hours_limit_rescheduling
              } = data;
              const payload = {
                id,
                start,
                end,
                weekday: String(weekday),
                hours_limit_rescheduling
              };
              newList.push(payload);
            });

            setList([...list, ...newList]);
          } else {
            const {
              id,
              start,
              end,
              weekday,
              hours_limit_rescheduling
            } = res.data;
            const payload = {
              id,
              start,
              end,
              weekday,
              hours_limit_rescheduling
            };
            const newList = list.filter(el => el.id !== payload.id);
            newList.push(payload);
            setList(newList);
          }
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
          setShowForm(false);
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage(), 5000));
        });
    } else {
      setState({ ...state, error: true });
    }
  }
  function deleteContact() {
    Api.post(`/ms_system/workplace/deleteCalendarOfficeHourAddres/`, {
      id: idDel
    })
      .then(res => {
        const newList = list.filter(el => el.id !== idDel);
        setList(newList);
        dispatch(viewAlertMessage("success", "Removido com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
        setModalDelete(false);
        setIdDel("");
      })
      .catch(() => {
        setModalDelete(false);
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao remover!"));
        setTimeout(() => dispatch(closeAlertMessage(), 5000));
      });
  }
  const convertDay = weekday => {
    switch (weekday) {
      case "0":
        return "Domingo";
      case "1":
        return "Segunda-Feira";
      case "2":
        return "Terça-Feira";
      case "3":
        return "Quarta-Feira";
      case "4":
        return "Quinta-Feira";
      case "5":
        return "Sexta-Feira";
      case "6":
        return "Sábado";
      default:
        break;
    }
  };
  const convertTime = time => {
    time = time.split(":");
    return time[0] + ":" + time[1];
  };
  return (
    <>
      <ExpansionPanel
        square
        style={{
          boxShadow: "10px 5px 5px white",
          borderBottom: "1px solid #d2d2d2"
          // marginTop: "10px"
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
        >
          Horários de Atendimento
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: "block" }}>
          <div className="w-100">
            <Button
              onClick={() => {
                setShowForm(true);
                setState({
                  ...state,
                  id: "",
                  start: "",
                  end: "",
                  day: "",
                  hours_limit_rescheduling: ""
                });
              }}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-plus" /> <span> Adicionar</span>
            </Button>
          </div>
          <Table className="table-finances w-100">
            <TableHead>
              <TableRow>
                <TableCell width="30%">Dia</TableCell>
                <TableCell align="left" width="20%">
                  Inicio
                </TableCell>
                <TableCell align="left" width="20%">
                  Fim
                </TableCell>
                <TableCell align="right" width="30%">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length > 0 ? (
                list.map(row => (
                  <TableRow hover key={row.id}>
                    <TableCell component="th" scope="row" width="30%">
                      {convertDay(row.weekday)}
                    </TableCell>
                    <TableCell align="left" width="20%">
                      {convertTime(row.start)}
                    </TableCell>
                    <TableCell align="left" width="20%">
                      {convertTime(row.end)}
                    </TableCell>
                    <TableCell width="30%" align="right">
                      <IconButton
                        onClick={() => {
                          setState({
                            ...state,
                            id: row.id,
                            start: row.start,
                            end: row.end,
                            weekday: row.weekday,
                            hours_limit_rescheduling:
                              row.hours_limit_rescheduling
                          });
                          setShowForm(true);
                        }}
                        color="primary"
                        aria-label="Editar"
                      >
                        <i className="zmdi zmdi-edit zmdi-hc-fw" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setModalDelete(true);
                          setIdDel(row.id);
                        }}
                        color="secondary"
                        aria-label="Remover"
                      >
                        <i className="zmdi zmdi-delete zmdi-hc-fw" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key="1">
                  <TableCell colSpan={3}>
                    <NoRecord />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={showForm}
        onClose={() => setShowForm(false)}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogContent>
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="text-primary font-weight-semibold">
                Horários de Atendimento
                <Tooltip title="Fechar" placement="top">
                  <IconButton
                    aria-label="Fechar"
                    onClick={() => setShowForm(false)}
                    style={{ float: "right", marginTop: "-10px" }}
                  >
                    <i className="zmdi zmdi-close" />
                  </IconButton>
                </Tooltip>
              </h2>
            </div>
            {ComponentForm()}
            <div className="col-md-12 mt-3">
              <Button
                color="secondary"
                variant="contained"
                className="jr-btn jr-btn-sm"
                onClick={() => setShowForm(false)}
                style={{ float: "left" }}
              >
                <i className="zmdi zmdi-close zmdi-hc-lg" />
                <span> Cancelar </span>
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="jr-btn jr-btn-sm bg-success"
                onClick={() => save()}
                style={{ float: "right" }}
              >
                <i className="zmdi zmdi-check zmdi-hc-lg" />
                <span> Salvar </span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <SweetAlert
        show={modal_delete}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Deseja realmente excluir este plano de saúde?"
        onConfirm={() => deleteContact()}
        onCancel={() => setModalDelete(false)}
      />
    </>
  );
}

export default Calendar;
