import React, { useState } from "react";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import TooltipMU from "@material-ui/core/Tooltip";
import GraphicPA from "./components/GraphicPA";
import GraphicIMC from "./components/GraphicIMC";
import GraphicWeight from "./components/GraphicWeight";
import GraphicTall from "./components/GraphicTall";
import GraphicFC from "./components/GraphicFC";
import GraphicFCR from "./components/GraphicFCR";
import GraphicFCM from "./components/GraphicFCM";
import GraphicFCRSV from "./components/GraphicFCRSV";
import GraphicTC from "./components/GraphicTC";
import GraphicBG from "./components/GraphicBG";
import GraphicSat from "./components/GraphicSat";
import GraphicFR from "./components/GraphicFR";
import GraphicCircAbm from "./components/GraphicCircAbm";
import GraphicCircCalf from "./components/GraphicCircCalf";
import GraphicCircumferences from "./components/GraphicCircumferences";
import { BtnCan } from "components/Button";
import { ModalResponsive } from "components/ModalResponsive";

function IconChart({ type, title, patient }) {
  const [modal, setModal] = useState(false);

  const getGraphic = () => {
    switch (type) {
      case "weight":
        return <GraphicWeight user={patient} />;
      case "tall":
        return <GraphicTall user={patient} />;
      case "imc":
        return <GraphicIMC user={patient} />;
      case "tc":
        return <GraphicTC user={patient} />;
      case "sat":
        return <GraphicSat user={patient} />;
      case "bg":
        return <GraphicBG user={patient} />;
      case "pa":
        return <GraphicPA user={patient} />;
      case "fc":
        return <GraphicFC user={patient} />;
      case "fr":
        return <GraphicFR user={patient} />;
      case "fcr":
        return <GraphicFCR user={patient} />;
      case "fcm":
        return <GraphicFCM user={patient} />;
      case "rsv":
        return <GraphicFCRSV user={patient} />;
      case "circ_abm":
        return <GraphicCircAbm user={patient} />;
      case "circ_calf":
        return <GraphicCircCalf user={patient} />;
      case "cephalic":
        return <GraphicCircumferences type="cephalic" user={patient} />;
      case "circ_cx":
        return <GraphicCircumferences type="circ_cx" user={patient} />;
      case "circ_bc":
        return <GraphicCircumferences type="circ_bc" user={patient} />;
    }
  };

  return (
    <>
      <TooltipMU title={title}>
        <InsertChartIcon fontSize="small" onClick={() => setModal(true)} />
      </TooltipMU>
      <ModalResponsive
        open={modal}
        close={() => setModal(false)}
        maxWidth="md"
        title={title}
        buttons={
          <div className="col-12">
            <BtnCan
              title="Fechar"
              onClick={() => setModal(false)}
              float="left"
            />
          </div>
        }
      >
        {getGraphic()}
      </ModalResponsive>
    </>
  );
}

export default IconChart;
