import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink, withRouter } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import Chip from "@material-ui/core/Chip";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import * as Alerts from "actions/Alerts";
import Api from "services/api";
import DB from "services/indexedDB";
import moment from "moment";
// import ModalFinishVisit from "components/ModalCacheHomeCare/ModalFinishVisit";
class Homecare extends Component {
  state = {
    open: false,
  };
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}${
      window.location.href.split("?")[1]
        ? `?${window.location.href.split("?")[1]}`
        : ""
    }`; // get current path
    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
    this.getActive();
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}${
      window.location.href.split("?")[1]
        ? `?${window.location.href.split("?")[1]}`
        : ""
    }`; // get current path
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
        this.closest(activeLi, "li").classList.add("active");
      }
    } catch (error) {}
    this.getActive();
  }

  getActive() {
    document.querySelectorAll(".nav-menu li a").forEach((el) => {
      if (el.href !== window.location.href) {
        el.classList.remove("active");
      } else {
        el.classList.add("active");
      }
    });
  }

  componentDidUpdate() {
    this.getActive();
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  finalizeVisit = (dados) => {
    this.setState({ open: true });
    if (navigator.onLine) {
      Api.post(`/ms_homecare/professional/finalizeVisit`, {
        home_care_visit: this.props.visit_id,
        dados,
        date: moment().format("YYYY-MM-DD HH:mm:ii"),
      })
        .then(({ data }) => {
          if (data.success) {
            this.props.viewAlertMessage(
              "success",
              "Visita finalizada com sucesso!"
            );
            setTimeout(() => {
              this.props.closeAlertMessage();
            }, 5000);
            this.props.history.push("/app/dashboard/");
          } else {
            this.props.viewAlertMessage(
              "error",
              "Não foi possível encerrar a visita! Tente novamente"
            );
            setTimeout(() => {
              this.props.closeAlertMessage();
            }, 5000);
          }
        })
        .catch(() => {
          this.props.viewAlertMessage(
            "error",
            "Não foi possível encerrar a visita! Tente novamente"
          );
          setTimeout(() => {
            this.props.closeAlertMessage();
          }, 5000);
        });
    } else {
      var tx = DB.result.transaction("visit", "readwrite");
      var visit = tx.objectStore("visit");

      let visit_atual = visit.get(this.props.visit_id);
      let visit_id = this.props.visit_id;

      let complete = true;

      visit_atual.onsuccess = function() {
        let aux = visit_atual.result;
        aux.finish = 1;
        aux.date = moment().format("YYYY-MM-DD HH:mm:ii");
        aux.dados = dados;

        let finish_visit = visit.put(aux, visit_id);
        finish_visit.onerror = function() {
          complete = false;
        };
      };
      visit_atual.onerror = function() {
        complete = false;
      };
      if (complete) {
        this.props.history.push("/app/dashboard/");
        this.props.viewAlertMessage(
          "success",
          "Visita finalizada com sucesso!"
        );
        setTimeout(() => {
          this.props.closeAlertMessage();
        }, 5000);
      } else {
        this.props.viewAlertMessage(
          "error",
          "Não foi possível encerrar a visita! Tente novamente"
        );
      }
      setTimeout(() => {
        this.props.closeAlertMessage();
      }, 5000);
    }
  };

  render() {
    return (
      <>
        <CustomScrollbars className="scrollbar">
          <ul className="nav-menu">
            <li className="nav-header">Evolução - Home care</li>
            <li className="menu no-arrow">
              <NavLink to="/app/homecare/professional" className="active">
                <i className="zmdi zmdi-assignment-account zmdi-hc-fw" />
                <span className="nav-text">Registro do atendimento</span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/homecare/intercurrence" className="active">
                <i className="zmdi zmdi-assignment-alert zmdi-hc-fw" />
                <span className="nav-text">Intercorrência</span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/homecare/requests">
                <i className="zmdi zmdi-mail-send zmdi-hc-fw" />
                <span className="nav-text">Solicitar remarcação</span>
              </NavLink>
            </li>
            <li className="menu no-arrow">
              <NavLink to="/app/homecare/final_check">
                <i className="zmdi zmdi-check zmdi-hc-fw" />
                <span className="nav-text">Checagem Final</span>
              </NavLink>
            </li>
            <div className="mx-3 my-2">
              <Chip
                className="w-100"
                size="small"
                color="secondary"
                onClick={() => {
                  this.props.history.push("/app/homecare/final_check");
                  // this.setState({ open: true });
                }}
                icon={<ExitToAppIcon />}
                label="Finalizar Visita"
              />
            </div>
          </ul>
        </CustomScrollbars>
        {/* <ModalFinishVisit
          open={this.state.open}
          handleClose={() => this.setState({ open: false })}
          handleSave={this.finalizeVisit}
        /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  visit_id: state.homecareProfessional.home_care_visit,
  type: state.auth.type,
  address_selected: state.auth.address_selected,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...Alerts }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Homecare)
);

{
  /* <CustomScrollbars className="scrollbar">
<ul className="nav-menu">
  <li className="nav-header">Evolução - Home care</li>
  <li className="menu no-arrow">
    <NavLink to="/app/homecare/professional" className="active">
      <i className="zmdi zmdi-assignment-account zmdi-hc-fw" />
      <span className="nav-text">Registro do atendimento</span>
    </NavLink>
  </li>
  <li className="menu no-arrow">
    <NavLink to="/app/homecare/intercurrence" className="active">
      <i className="zmdi zmdi-assignment-alert zmdi-hc-fw" />
      <span className="nav-text">Intercorrência</span>
    </NavLink>
  </li>
  {/* <li className="menu no-arrow">
    <NavLink to="/app/homecare/requests">
      <i className="zmdi zmdi-mail-send zmdi-hc-fw" />
      <span className="nav-text">Solicitar remarcação</span>
    </NavLink>
  </li> 
  <div className="mx-3 my-2">
    <Chip
      className="w-100"
      size="small"
      color="secondary"
      onClick={this.finalizeVisit}
      icon={<ExitToAppIcon />}
      label="Finalizar Visita"
    />
  </div>
</ul>
</CustomScrollbars> */
}
