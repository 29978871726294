import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BtnCan, BtnSave } from "components/Button";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  TextField,
} from "@material-ui/core";
import SearchComponent from "components/SearchComponent";
import Api from "services/api";

export default function FormFoodAllergies({
  patient,
  type,
  record,
  handleClose,
  beforeSave,
}) {
  const allergies = [
    {
      allergy: "4672",
      description: "Não possui Alergias Alimentares",
    },
    {
      allergy: "212",
      description:
        "Aditivos alimentares (corantes, conservantes, aditivos artificiais, sulfitos)",
    },
    {
      allergy: "178",
      description: "Amendoim",
    },
    {
      allergy: "202",
      description: "Aveia",
    },
    {
      allergy: "210",
      description: "Castanhas",
    },
    {
      allergy: "204",
      description: "Centeio",
    },
    {
      allergy: "203",
      description: "Cevada",
    },
    {
      allergy: "213",
      description: "Fermento natural",
    },
    {
      allergy: "206",
      description: "Frutas cítricas",
    },
    {
      allergy: "198",
      description: "Frutas secas",
    },
    {
      allergy: "179",
      description:
        "Frutos do mar (camarão, caranguejo, lagosta), crustáceos e moluscos",
    },
    {
      allergy: "199",
      description: "Glúten (doença celíaca)",
    },
    {
      allergy: "208",
      description: "Leguminosas",
    },
    {
      allergy: "177",
      description: "Leite de vaca",
    },
    {
      allergy: "209",
      description: "Milho",
    },
    {
      allergy: "176",
      description: "Ovo",
    },
    {
      allergy: "200",
      description: "Peixe",
    },
    {
      allergy: "207",
      description: "Refrigerante à base de cola",
    },
    {
      allergy: "180",
      description: "Soja",
    },
    {
      allergy: "211",
      description: "Temperos",
    },
    {
      allergy: "205",
      description: "Tomate",
    },
    {
      allergy: "201",
      description: "Trigo",
    },
  ];

  const [search, setSearch] = useState("");
  const [state, setState] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);
  const [notApplicable, setNotApplicable] = useState(false);

  const appointment = useSelector((state) =>
    type === "consult" ? state.appointment.id : state.admission.appointment
  );

  const start = useSelector((state) =>
    type === "consult" ? state.appointment.start : ""
  );
  const triage = useSelector((state) => state.appointment.triage);
  const appointment_action =
    type === "consult"
      ? useSelector((state) => state.appointment.saved.id)
      : "";
  const appointment_action_form =
    type === "consult"
      ? useSelector((state) => state.appointment.saved.allergyFood)
      : "";
  const admission =
    type === "admission" ? useSelector((state) => state.admission.id) : "";
  const admission_form =
    type === "admission"
      ? useSelector((state) => state.admission.forms.allergyFood)
      : "";

  useEffect(() => {
    const ids = record.map((row) => row.allergy);

    // Lista os ja adicionados acima e as posibilidades abaixo
    setState([
      ...record.map((row) => ({
        ...row,
        checked: true,
        alter: false,
        initial: true,
      })),
      ...allergies
        .filter((row) => ids.indexOf(row.allergy) === -1)
        .map((row) => ({
          ...row,
          additional_considerations: "",
          checked: false,
          alter: false,
        })),
    ]);
  }, []);

  const handleChange = (index, field, value) => {
    // setState(
    //   state.map((row, key) =>
    //     key === index ? { ...row, [field]: value, alter: true } : row
    //   )
    // );

    setState(
      state.map((row, key) => {
        if (key === index) {
          if (row.allergy == "4672" && value == true) {
            setNotApplicable(true);
            return { ...row, [field]: value, alter: true };
          } else if (row.allergy == "4672" && value == false) {
            setNotApplicable(false);
            return { ...row, [field]: value, alter: true };
          } else {
            return { ...row, [field]: value, alter: true };
          }
        } else {
          return row;
        }
      })
    );
  };

  const handleSave = () => {
    let toDelete = state.filter((row) => row.initial && !row.checked);
    let toAdd = state.filter((row) => row.alter && row.checked);

    Api.post("/microservice/allergies/saveFoodAllergies", {
      type,
      patient,
      appointment,
      start,
      triage,
      doctor: user,
      address,
      appointment_action,
      appointment_action_form,
      admission,
      admission_form,
      notApplicable,
      toDelete,
      toAdd,
    }).then(({ data }) => {
      if (type !== "pp") {
        if (type === "admission") {
          if (!admission_form) {
            dispatch({
              type: "SET_ADMISSION_DATA",
              payload: {
                admission: data.admission,
                form: "allergyFood",
                form_value: data.admission_form,
                appointment: data.idAppointment,
              },
            });
          }
        } else {
          if (!appointment) {
            dispatch({
              type: "SET_INIT_APPOINTMENT",
              payload: {
                id: data.idAppointment,
              },
            });
          }
          if (!appointment_action_form) {
            dispatch({
              type: "SET_SAVED_ACTIONS",
              payload: {
                field: "allergyFood",
                id: data.idActionFrom,
              },
            });
          }
        }
      }
      setNotApplicable(false);
      beforeSave();
    });
  };

  return (
    <React.Fragment>
      <div
        className="col-12"
        style={{
          minWidth: "650",
          overflowX: "auto",
        }}
      >
        <SearchComponent searchContact={(e) => setSearch(e)} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Alimento</TableCell>
              <TableCell>Observações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state
              .filter(
                (el) =>
                  el.description.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1
              )
              .map((row, key) => (
                <TableRow key={key}>
                  <TableCell width="30%">{row.description}</TableCell>
                  <TableCell width="20%">
                    <Switch
                      id={key}
                      color="primary"
                      checked={row.checked}
                      onChange={(e) =>
                        handleChange(key, "checked", e.target.checked)
                      }
                      aria-label="checked"
                    />
                  </TableCell>
                  <TableCell width="50%">
                    {row.checked ? (
                      <TextField
                        fullWidth
                        placeholder="Observações"
                        value={row.additional_considerations}
                        onChange={(e) =>
                          handleChange(
                            key,
                            "additional_considerations",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      <span />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="col-12 mt-3">
        <BtnCan title="cancelar" onClick={() => handleClose()} float="left" />
        <BtnSave title="Salvar" float="right" onClick={handleSave} />
      </div>
    </React.Fragment>
  );
}
