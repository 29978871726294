import React from "react";
import { Tooltip, Icon } from "@material-ui/core";
import ColorizeIcon from "@material-ui/icons/Colorize";
import BathtubIcon from "@material-ui/icons/Bathtub";
import HealingIcon from "@material-ui/icons/Healing";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import HotelIcon from "@material-ui/icons/Hotel";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AirlineSeatFlatAngledIcon from "@material-ui/icons/AirlineSeatFlatAngled";
import DetailsIcon from "@material-ui/icons/Details";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
export default function returnIconCare({ type, color }) {
  switch (type) {
    case "0":
      return (
        <Tooltip placement="top" title={"Intercorrência"}>
          <DetailsIcon style={{ color: color }} />
        </Tooltip>
      );
    case "1":
      return (
        <Tooltip placement="top" title={"Higiene Pessoal"}>
          <BathtubIcon style={{ color: color }} />
        </Tooltip>
      );
    case "2":
      return (
        <Tooltip placement="top" title={"Cabeceira Elevada"}>
          <AirlineSeatFlatAngledIcon style={{ color: color }} />
        </Tooltip>
      );
    case "3":
      return (
        <Tooltip placement="top" title={"Mudança de Decúbito"}>
          <HotelIcon style={{ color: color }} />
        </Tooltip>
      );
    case "4":
      return (
        <Tooltip placement="top" title={"Curativo"}>
          <HealingIcon style={{ color: color }} />
        </Tooltip>
      );
    case "5":
      return (
        <Tooltip placement="top" title={"Dieta Oral"}>
          <RestaurantIcon style={{ color: color }} />
        </Tooltip>
      );
    case "6":
      return (
        <Tooltip placement="top" title={"Dieta Enteral"}>
          <FormatColorFillIcon style={{ color: color }} />
        </Tooltip>
      );
    case "7":
      return (
        <Tooltip placement="top" title={"Hidratação"}>
          <LocalDrinkIcon style={{ color: color }} />
        </Tooltip>
      );
    case "8":
      return (
        <Tooltip placement="top" title={"Aferições Gerais"}>
          <FavoriteIcon style={{ color: color }} />
        </Tooltip>
      );
    case "9":
      return (
        <Tooltip placement="top" title={"Retirada de Pontos"}>
          <Icon className="zmdi zmdi-scissors" style={{ color: color }} />
        </Tooltip>
      );
    case "10":
      return (
        <Tooltip placement="top" title={"Sonda Vesical"}>
          <DetailsIcon style={{ color: color }} />
        </Tooltip>
      );
    case "11":
      return (
        <Tooltip placement="top" title={"Oxigenioterapia"}>
          <DetailsIcon style={{ color: color }} />
        </Tooltip>
      );
    case "12":
      return (
        <Tooltip placement="top" title={"Estimular Movimentação"}>
          <DirectionsWalkIcon style={{ color: color }} />
        </Tooltip>
      );
    case "13":
      return (
        <Tooltip placement="top" title={"Perneira Inflável"}>
          <DetailsIcon style={{ color: color }} />
        </Tooltip>
      );
    case "14":
      return (
        <Tooltip placement="top" title={"Registrar escala de dor"}>
          <SentimentDissatisfiedIcon style={{ color: color }} />
        </Tooltip>
      );
    case "15":
      return (
        <Tooltip placement="top" title={"Avaliar Risco de queda"}>
          <DirectionsWalkIcon style={{ color: color }} />
        </Tooltip>
      );
    default:
      return (
        <Tooltip placement="top" title={"Medicamentos"}>
          <ColorizeIcon style={{ color: color }} />
        </Tooltip>
      );
  }
}
