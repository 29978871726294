import React, { Component } from "react";
// import './styles.css';
import api from "services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import NoRecord from "components/Alert/NoRecord";
import CircularProgress from "components/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";

class Exams extends Component {
  state = {
    patient: this.props.patient,
    record: [],
    mode_list: true,
    exam: "",
    rowsPerPage: 10,
    page: 0,
  };

  componentDidMount() {
    api
      .post("/microservice/exam/getRequestsFromPatient/", {
        patient: this.state.patient,
      })
      .then((res) => {
        this.setState({ record: res.data.record });
      })
      .catch(() => {});
  }

  handleClose = () => {
    this.setState({ mode_list: true });
  };

  viewExam(data) {
    this.setState({ mode_list: false, exam: data });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { rowsPerPage, page, mode_list } = this.state;
    const { record, exam } = this.state;
    return (
      <div className="container-person border">
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400",
          }}
        >
          Exames
        </h3>
        {mode_list ? (
          record.length > 0 ? (
            <div
              className="col-12"
              style={{
                minWidth: "650",
                overflowX: "auto",
              }}
            >
              <Table className="table-history">
                <TableHead>
                  <TableRow>
                    <TableCell>Data do pedido</TableCell>
                    <TableCell align="left">Nome</TableCell>
                    <TableCell align="left">Solicitante</TableCell>
                    <TableCell align="left">Visualizar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.date}
                        </TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.solicitant}</TableCell>
                        <TableCell align="left">
                          <IconButton
                            className="m-0 p-0"
                            onClick={() => {
                              this.viewExam(row);
                            }}
                            color="primary"
                            aria-label="Visualizar"
                          >
                            <i className="zmdi zmdi-eye zmdi-hc-fw" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={record.length}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Registros por página"
                      labelDisplayedRows={({ from, to, count }) =>
                        `Mostrando ${from} - ${to} de ${count}`
                      }
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          ) : (
            <NoRecord />
          )
        ) : (
          <VisualizeExam request={exam} onClose={this.handleClose.bind(this)} />
        )}
      </div>
    );
  }
}

export default Exams;

class VisualizeExam extends Component {
  state = {
    record: [],
  };

  componentDidMount() {
    api
      .post(`/microservice/exam/getDetailsFromRequest/`, {
        request: this.props.request.id,
      })
      .then((res) => {
        this.setState({ record: res.data.record });
      })
      .catch(() => {});
  }

  render() {
    const { record } = this.state;
    const {
      date,
      solicitant,
      name,
      number,
      observation,
      place_laboratory,
    } = this.props.request;
    const { onClose } = this.props;
    return this.props.request ? (
      <div className="row">
        <div className="col-md-4 col-xs-4">
          <strong> Data do pedido: </strong> {date}
        </div>
        <div className="col-md-4 col-xs-4">
          <strong> Pedido: </strong> {name}
        </div>
        <div className="col-md-4 col-xs-4">
          <strong> Número: </strong> {number}
        </div>
        <div className="col-md-4 col-xs-4">
          <strong> Solicitante: </strong> {solicitant}
        </div>
        <div className="col-md-4 col-xs-4">
          <strong> Local do laboratório: </strong> {place_laboratory}
        </div>
        <div className="col-md-4 col-xs-4">
          <strong> Observações: </strong> {observation}
        </div>

        {record.map((value) => (
          <div className="col-md-12 col-xs-12" style={{ marginTop: "20px" }}>
            <strong> Exame: </strong> {value.test}
            <br />
            <strong> Amostra: </strong> {value.sample ? value.sample : "---"}{" "}
            <br />
            {value.register_result === "1" && (
              <>
                <strong> Resultado: </strong>
                <ul>
                  {value.result.map((row) => (
                    <li>
                      {row.field_names ? `${row.field_names}:` : ""}{" "}
                      <span>
                        {row.result1} {row.unit_result1}
                      </span>{" "}
                      <span className="ml-5">
                        {row.result2} {row.unit_result2}
                      </span>
                      {row.status ? (
                        <span className="ml-5">Status: {row.status}</span>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ul>
              </>
            )}
            <br />
          </div>
        ))}

        <div className="col-md-12 text-right">
          <Button
            onClick={() => {
              onClose();
            }}
            color="primary"
            aria-label="Voltar"
          >
            <i className="zmdi zmdi-undo" /> Voltar
          </Button>
        </div>
      </div>
    ) : (
      <CircularProgress />
    );
  }
}
