import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const NotificationCovid = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Route
          path={`${match.url}/index`}
          exact
          component={asyncComponent(() => import("./routes/index/index"))}
        />
        <Route
          path={`${match.url}/attendance`}
          exact
          component={asyncComponent(() => import("./routes/attendance"))}
        />
      </Switch>
    </div>
  );
};

export default NotificationCovid;
