import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Api from "services/api";
import ContainerHeader from "components/ContainerHeader";
import { Card, CardBody } from "reactstrap";
import {
  Tooltip,
  TextField,
  Button,
  MenuItem,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import GroupIcon from "@material-ui/icons/Group";
import MaskPhone from "components/Masks/MaskPhone";
import MaskCellphone from "components/Masks/MaskCellphone";
import MaskCpf from "components/Masks/MaskCpf";
import TakePhotoWithCropper from "components/Forms/TakePhotoWithCropper";
import { getUrlPhoto, formatName } from "services/functions";
import { allowCPF } from "containers/Register/Functions";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskCep from "components/Masks/MaskCep";
import axios from "axios";
import ModalObservation from "components/Modal/Observation";
import AutocompleteCity from "components/Autocomplete/AutocompleteCity";
import AutocompleteUser from "components/Autocomplete/AutocompleteUser";
import ComponentsProfile from "components/ComponentsProfile/ProfileNew";
// import AutocompleteParents from "components/Autocomplete/Parents";
import MaskCns from "components/Masks/MaskCns";
import moment from "moment";

export default function VaccineChild({ match, history }) {
  const dispatch = useDispatch();
  const [mode, setMode] = useState(0);

  const initial_state = {
    id: "",
    name: "",
    genderVal: "",
    naturalty: "",
    cpf: "",
    email: "",
    phone: "",
    smartphone: "",
    birthday: "",
    postalcode: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    country: "",
    mother_id: "",
    mother_name: "",
    cns: "",
  };

  const initial_vaccine = {
    id: "",
    vaccine: "60",
    name: "Pfizer",
    applied: "1",
    date: moment().format("YYYY-MM-DD"),
    observation: "",
    alter: true,
    city: {
      id: "1668",
      label: "Cabo Verde - Minas Gerais - Brasil",
    },
  };

  const listVaccine = [
    {
      id: "63",
      name: "Astrazeneca",
    },
    {
      id: "56",
      name: "Coronavac",
    },
    {
      id: "60",
      name: "Pfizer",
    },
    {
      id: "65",
      name: "Sputnik",
    },
    {
      id: "68",
      name: "Covaxin",
    },
    {
      id: "62",
      name: "Janssen (Dose Única)",
    },
  ];

  const [patient, setPatient] = useState(initial_state);
  const [vaccine, setVaccine] = useState(initial_vaccine);
  const [hasVaccine, setHasVaccine] = useState("");

  const objModalObs = {
    open: false,
    observation: "",
  };
  const [modalObs, setModalObs] = useState(objModalObs);

  const [error, setError] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [take_photo, setTakePhoto] = useState(false);
  const [imgPatient, setImgPatient] = useState("");
  const [modalCpf, setModalCpf] = useState({
    open: false,
    type: "",
    message: "",
  });
  const auth = useSelector((state) => state.auth.authUser);

  const CheckCpf = () => {
    const { cpf } = patient;
    if (cpf.length !== 0) {
      const verifica = allowCPF(cpf);
      if (verifica) {
        Api.post(`/ms_system/user/checkCPF`, { cpf }).then((res) => {
          if (res.data.record.length !== 0) {
            setModalCpf({
              open: true,
              type: "cpf",
              message: "CPF já está cadastrado.",
            });
            setPatient({ ...patient, cpf: "" });
          }
        });
      } else {
        setModalCpf({ open: true, type: "cpf", message: "CPF Inválido" });
        setPatient({ ...patient, cpf: "" });
      }
    }
  };

  const validContact = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      (patient.smartphone.length === 15 &&
        patient.smartphone.indexOf("_") === -1) ||
      (patient.phone.length === 14 && patient.phone.indexOf("_") === -1)
    );
  };

  const searchCep = (event) => {
    let cep = event.target.value.replace(/_/g, "");
    if (cep.length === 9) {
      cep = cep.replace(",", "");
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => {
          setPatient({
            ...patient,
            postalcode: res.data.cep,
            street: res.data.logradouro,
            neighborhood: res.data.bairro,
            city: res.data.localidade,
            state: res.data.uf,
            country: "Brasil",
          });
        })
        .catch(() => {
          console.log("Ocorreu um erro ao recuperar as informações do cep.");
        });
    }
  };

  const validate = () => {
    if (
      patient.name &&
      patient.genderVal &&
      patient.mother_name &&
      patient.birthday &&
      hasVaccine !== "" &&
      validContact()
    ) {
      if (
        patient.postalcode &&
        patient.street &&
        patient.number &&
        patient.city &&
        patient.state &&
        patient.country
      ) {
        if (hasVaccine === "1") {
          setModalConfirm(true);
        } else {
          handleSave();
        }
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  function handleSave() {
    Api.post(`/ms_system/user/fastRegisterUser/`, {
      ...patient,
      auth,
      registerAddress: true,
      cns: patient.cns ? patient.cns.replace(/[^0-9]/g, "") : "",
      validate: true,
      saveVaccine: hasVaccine === "1",
      vaccine,
    }).then((res) => {
      if (res.data.success) {
        if (imgPatient) {
          dispatch({
            type: "ALTER_PHOTO",
            payload: { patient: res.data.patient_id, image: imgPatient },
          });
        }
        setMode(3);
        setError(false);
        setPatient(initial_state);
        setVaccine(initial_vaccine);
        setHasVaccine("");

        /* if (address) {
          dispatch({
            type: "INSERT_GROUP_INTRANET",
            payload: { patient: res.data.patient_id, address, auth },
          });
        } */
        // add(res.data.patient_id, patient.name, patient);
      } else {
        if (res.data.isset) {
          setModalCpf({
            open: true,
            type: "user",
            message: "Usuário já cadastrado em nossa base de dados.",
          });
        }
      }
    });
  }

  const getFormVaccine = () => {
    return (
      <div
        style={{
          display: "contents",
        }}
      >
        <div className="col-12 mt-3">
          <h4 className="font-weight-semibold text-primary">Vacinação</h4>
        </div>
        <div className="col-12 ml-3">
          <span
            className="font-weight-semibold"
            style={{
              color: error && !hasVaccine ? "red" : "",
            }}
          >
            A criança já se vacinou contra a Covid-19?
          </span>
          <RadioGroup
            className="d-flex flex-row"
            value={hasVaccine ? hasVaccine : ""}
            onChange={(e) => setHasVaccine(e.target.value)}
            style={{
              color: error && !hasVaccine ? "red" : "",
            }}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Sim"
            />
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="Não"
            />
          </RadioGroup>
        </div>
        {hasVaccine === "1" && (
          <>
            <div
              className="col-md-1 col-12"
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              1° Dose
            </div>
            <div className="col-md-3 col-12">
              <TextField
                select
                fullWidth
                margin="normal"
                label="Vacina:"
                value={vaccine.vaccine}
                onChange={(e) => {
                  setVaccine({
                    ...vaccine,
                    vaccine: e.target.value,
                    name: e.nativeEvent.target.innerText,
                  });
                }}
              >
                {listVaccine.map((row) => (
                  <MenuItem key={row.id} value={row.id} name={row.name}>
                    {row.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-md-3 col-12">
              <FormControl component="fieldset" className="mt-3">
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={vaccine.applied}
                  onChange={(e) => {
                    setVaccine({
                      ...vaccine,
                      applied: e.target.value,
                    });
                  }}
                  className="mt-1"
                  row
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Aplicada"
                    labelPlacement="end"
                    style={{ color: error && !vaccine.applied ? "red" : "" }}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="Prevista"
                    labelPlacement="end"
                    style={{ color: error && !vaccine.applied ? "red" : "" }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="col-md-2 col-12 mt-3">
              <TextField
                type="date"
                label={"Data:"}
                fullWidth
                value={vaccine.date}
                onInput={(e) => {
                  if (e.target.value.length > 10) {
                    e.target.value = e.target.value.substr(1, 11);
                  }
                }}
                onChange={(e) => {
                  setVaccine({
                    ...vaccine,
                    date: e.target.value,
                  });
                }}
                inputProps={{
                  max:
                    vaccine.applied === "1"
                      ? moment().format("YYYY-MM-DD")
                      : "",
                  min:
                    vaccine.applied === "0"
                      ? moment().format("YYYY-MM-DD")
                      : "",
                }}
                InputLabelProps={{ shrink: true }}
                error={error && !vaccine.date}
              />
            </div>

            <div className="col-md-2 col-12">
              <AutocompleteCity
                handleChange={(e) =>
                  setVaccine({
                    ...vaccine,
                    city: e,
                  })
                }
                label="Cidade:"
                value={vaccine.city}
              />
            </div>

            <div className="col-md-1 col-12">
              <Tooltip title="Adicionar observação">
                <IconButton
                  arial-label="Add Observação"
                  onClick={() =>
                    setModalObs({
                      open: true,
                      observation: vaccine.observation,
                    })
                  }
                  color={vaccine.observation ? "primary" : "default"}
                  className="mt-3"
                >
                  <i className="zmdi zmdi-comment-edit" />
                </IconButton>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    );
  };

  function saveVaccine() {
    if (hasVaccine !== "" && vaccine.applied && vaccine.date) {
      if (hasVaccine === "1") {
        Api.post(`/ms_system/user/saveVaccineChild/`, {
          patient: patient.id,
          ...vaccine,
        }).then((res) => {
          if (res.data.success) {
            setMode(3);
            setError(false);
            setPatient(initial_state);
            setVaccine(initial_vaccine);
            setHasVaccine("");
          } else {
            setModalCpf({
              open: true,
              type: "vaccine",
              message: "Ocorreu um erro ao salvar.",
            });
          }
        });
      } else {
        setMode(3);
        setError(false);
        setPatient(initial_state);
        setVaccine(initial_vaccine);
        setHasVaccine("");
      }
    } else {
      setError(true);
    }
  }

  return (
    <div className="row mt-3 px-3">
      <div
        className={false ? "col-md-3 col-xs-12" : "col-md-1 col-xs-12"}
      ></div>
      <div className={false ? "col-md-4 col-xs-12" : "col-md-10 col-xs-12"}>
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader title="Pré-cadastro Infantil" match={match} />
        </div>

        {mode === 0 && (
          <Card className="shadow border-0 mt-2">
            <CardBody
              onClick={() => {
                setMode(4);
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="row">
                <div style={{ display: "inline-flex" }}>
                  <div>
                    <GroupIcon color="primary" fontSize="large" />
                  </div>
                  <div className="ml-3">
                    <h4 className="font-weight-bold">Cadastrar criança</h4>
                    <span>
                      Cadastro de crianças de 5 a 11 anos para Vacinação contra
                      COVID-19
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        )}

        {mode === 4 && (
          <Card className="shadow border-0 mt-2">
            <CardBody>
              <div className="row">
                <div className="col-12">
                  <h3 className="font-weight-semibold">Selecione a criança:</h3>
                  {!patient.id && (
                    <AutocompleteUser
                      handleChange={(e) => {
                        if (e.id === "new") {
                          setPatient({ ...patient, name: formatName(e.name) });
                          setMode(1);
                        } else {
                          setPatient({ ...patient, ...e });
                          // getDataUser(e.id);
                        }
                      }}
                      label="Nome da criança:"
                    />
                  )}
                </div>

                {patient.id && (
                  <>
                    <div className="col-md-4 col-xs-9">
                      <ComponentsProfile profile={patient} />
                    </div>
                    <div className="col-md-2 col-xs-3">
                      <Tooltip title="Alterar criança">
                        <IconButton
                          style={{ float: "right" }}
                          onClick={() => {
                            setPatient(initial_state);
                          }}
                          color="secondary"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </div>

                    {getFormVaccine()}
                    <div className="col-md-12 mt-3">
                      <Button
                        color="secondary"
                        variant="contained"
                        className="jr-btn jr-btn-sm"
                        style={{ float: "left" }}
                        onClick={() => {
                          setMode(0);
                          setError(false);
                          setPatient(initial_state);
                          setVaccine(initial_vaccine);
                          setHasVaccine("");
                        }}
                      >
                        <i className="zmdi zmdi-close zmdi-hc-lg" />
                        <span> Cancelar </span>
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        className="jr-btn jr-btn-sm bg-success"
                        style={{ float: "right" }}
                        onClick={() => saveVaccine()}
                      >
                        <i className="zmdi zmdi-check zmdi-hc-lg" />
                        <span> Salvar </span>
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        )}

        {mode === 1 && (
          <Card className="shadow border-0 mt-2">
            <CardBody>
              <div className="row">
                <div className="col-md-1 col-xs-3">
                  <Tooltip title="Alterar foto">
                    <img
                      src={
                        imgPatient
                          ? imgPatient
                          : getUrlPhoto(patient.id, patient.photo)
                      }
                      alt={patient.name}
                      className="rounded-circle img-responsive"
                      style={{
                        width: "60px",
                        height: "60px",
                        cursor: "pointer",
                      }}
                      onClick={() => setTakePhoto(true)}
                    />
                  </Tooltip>
                </div>

                <div className="col-md-11 col-xs-9">
                  <TextField
                    required
                    type="name"
                    label="Nome:"
                    value={patient.name}
                    // onChange={(e) => setPatient({ ...patient, name: e.target.value })}
                    fullWidth
                    required
                    margin="normal"
                    className="mt-0"
                    error={error && !patient.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="new-password"
                    InputProps={{
                      onInput: (e) => {
                        if (e.target.value) {
                          let formtCase = e.target.value
                            .split(" ")
                            .map((row) => {
                              return row === "Da" ||
                                row === "da" ||
                                row === "De" ||
                                row === "de" ||
                                row === "Do" ||
                                row === "do" ||
                                row === "Das" ||
                                row === "das" ||
                                row === "Dos" ||
                                row === "dos"
                                ? row.charAt(0).toLowerCase() +
                                    row.slice(1).toLowerCase()
                                : row.charAt(0).toUpperCase() +
                                    row.slice(1).toLowerCase();
                            });
                          setPatient({ ...patient, name: formtCase.join(" ") });
                        } else {
                          setPatient({ ...patient, name: "" });
                        }
                      },
                    }}
                  />
                </div>

                <div className="col-md-4 col-12">
                  <TextField
                    required
                    id="sex"
                    select
                    label="Sexo:"
                    value={patient.genderVal}
                    onChange={(e) =>
                      setPatient({ ...patient, genderVal: e.target.value })
                    }
                    SelectProps={{}}
                    fullWidth
                    style={{ marginTop: "17px" }}
                    error={error && !patient.genderVal}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem key="1" value="0">
                      Masculino
                    </MenuItem>
                    <MenuItem key="2" value="1">
                      Feminino
                    </MenuItem>
                    <MenuItem key="3" value="2">
                      Outro
                    </MenuItem>
                  </TextField>
                </div>

                <div className="col-md-4 col-12">
                  <TextField
                    required
                    id="dt_nasc"
                    type="date"
                    label="Data de Nascimento:"
                    value={patient.birthday}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setPatient({ ...patient, birthday: e.target.value })
                    }
                    onInput={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.substr(1, 11);
                      }
                    }}
                    error={error && !patient.birthday}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div className="col-md-4 col-12">
                  <TextField
                    id="cpf"
                    label="CPF:"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      inputComponent: MaskCpf,
                      value: patient.cpf,
                      onChange: (e) =>
                        setPatient({ ...patient, cpf: e.target.value }),
                    }}
                    onBlur={CheckCpf}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div className="col-6">
                  {/* <AutocompleteParents
                    label="Nome da mãe:"
                    value={{
                      id: patient.mother_id,
                      label: patient.mother_name,
                    }}
                    handleChange={(e) =>
                      setPatient({
                        ...patient,
                        mother_id: e.id,
                        mother_name: e.label,
                      })
                    }
                    error={error && !patient.mother_name}
                  /> */}
                  <TextField
                    required
                    label="Nome da mãe:"
                    value={patient.mother_name}
                    // onChange={(e) => setPatient({ ...patient, name: e.target.value })}
                    fullWidth
                    required
                    margin="normal"
                    // className="mt-0"
                    error={error && !patient.mother_name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      onInput: (e) => {
                        if (e.target.value) {
                          let formtCase = e.target.value
                            .split(" ")
                            .map((row) => {
                              return row === "Da" ||
                                row === "da" ||
                                row === "De" ||
                                row === "de" ||
                                row === "Do" ||
                                row === "do" ||
                                row === "Das" ||
                                row === "das" ||
                                row === "Dos" ||
                                row === "Dos" ||
                                row === "dos"
                                ? row.charAt(0).toLowerCase() +
                                    row.slice(1).toLowerCase()
                                : row.charAt(0).toUpperCase() +
                                    row.slice(1).toLowerCase();
                            });
                          setPatient({
                            ...patient,
                            mother_name: formtCase.join(" "),
                          });
                        } else {
                          setPatient({ ...patient, mother_name: "" });
                        }
                      },
                    }}
                  />
                </div>

                <div className="col-md-6 col-12">
                  <TextField
                    id="cns"
                    label="Cartão Nacional de Saúde"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      inputComponent: MaskCns,
                      value: patient.cns,
                      onChange: (e) =>
                        setPatient({ ...patient, cns: e.target.value }),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                {/* <div className="col-md-6">
                  <TextField
                    required
                    id="email"
                    type="email"
                    label="Email:"
                    value={patient.email}
                    onChange={(e) =>
                      setPatient({ ...patient, email: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    helperText={
                      error && !validContact()
                        ? "Preencha pelo menos uma informação de contato (Email ou Celular)"
                        : ""
                    }
                    error={error && !validContact()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div> */}

                <div className="col-md-6">
                  <TextField
                    id="smartphone"
                    label="Celular para contato:"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      inputComponent: MaskCellphone,
                      value: patient.smartphone,
                      onChange: (e) =>
                        setPatient({ ...patient, smartphone: e.target.value }),
                    }}
                    error={error && !validContact()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <TextField
                    id="fixo"
                    label="Telefone para contato:"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      inputComponent: MaskPhone,
                      value: patient.phone,
                      onChange: (e) =>
                        setPatient({ ...patient, phone: e.target.value }),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error && !validContact()}
                  />
                </div>

                <div className="col-12 mt-3 pb-0 mb-0">
                  <h4 className="font-weight-semibold text-primary">
                    Endereço
                  </h4>
                </div>
                <div className="col-md-4 col-12">
                  <TextField
                    id="postalcode"
                    label="CEP"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      inputComponent: MaskCep,
                      value: patient.postalcode,
                      onChange: searchCep,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error && !patient.postalcode}
                  />
                </div>

                <div className="col-md-8 col-12">
                  <TextField
                    id="address"
                    label="Logradouro"
                    value={patient.street}
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      setPatient({ ...patient, street: e.target.value })
                    }
                    error={error && !patient.street}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div className="col-md-2 col-12">
                  <TextField
                    id="number"
                    label="Número:"
                    value={patient.number}
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      setPatient({ ...patient, number: e.target.value })
                    }
                    error={error && !patient.number}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div className="col-md-6 col-12">
                  <TextField
                    id="complement"
                    label="Complemento:"
                    value={patient.complement}
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      setPatient({ ...patient, complement: e.target.value })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <TextField
                    id="neighborhood"
                    label="Bairro"
                    value={patient.neighborhood}
                    margin="normal"
                    fullWidth
                    onChange={(e) =>
                      setPatient({ ...patient, neighborhood: e.target.value })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <TextField
                    id="city"
                    label="Cidade:"
                    value={patient.city}
                    margin="normal"
                    fullWidth
                    InputProps={{ readOnly: patient.postalcode ? true : false }}
                    onChange={(e) =>
                      setPatient({ ...patient, city: e.target.value })
                    }
                    error={error && !patient.city}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <TextField
                    id="state"
                    label="Estado:"
                    value={patient.state}
                    margin="normal"
                    fullWidth
                    InputProps={{ readOnly: patient.postalcode ? true : false }}
                    onChange={(e) =>
                      setPatient({ ...patient, state: e.target.value })
                    }
                    error={error && !patient.state}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <TextField
                    id="country"
                    label="País:"
                    value={patient.country}
                    margin="normal"
                    fullWidth
                    InputProps={{ readOnly: patient.postalcode ? true : false }}
                    onChange={(e) =>
                      setPatient({ ...patient, country: e.target.value })
                    }
                    error={error && !patient.postalcode}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                {getFormVaccine()}

                <div className="col-md-12 mt-3">
                  <Button
                    color="secondary"
                    variant="contained"
                    className="jr-btn jr-btn-sm"
                    style={{ float: "left" }}
                    onClick={() => {
                      setMode(0);
                      setError(false);
                      setPatient(initial_state);
                    }}
                  >
                    <i className="zmdi zmdi-close zmdi-hc-lg" />
                    <span> Cancelar </span>
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className="jr-btn jr-btn-sm bg-success"
                    style={{ float: "right" }}
                    onClick={() => validate()}
                  >
                    <i className="zmdi zmdi-check zmdi-hc-lg" />
                    <span> Cadastrar </span>
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        )}

        {mode === 3 && (
          <Card className="shadow border-0">
            <CardBody>
              <div className="row">
                <div style={{ display: "inline-flex" }}>
                  <div>
                    <CheckCircleIcon color="primary" fontSize="large" />
                  </div>
                  <div className="ml-3">
                    <h4 className="font-weight-bold">Cadastro realizado!</h4>
                    <span>
                      Agradecemos seu registro. <br />
                      <br />
                    </span>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        // window.location.href = "https://www.salutemplus.com";
                        setMode(0);
                      }}
                      className="mt-3"
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
      <div className="col-md-2 col-xs-12"></div>
      <TakePhotoWithCropper
        open={take_photo}
        handleClose={() => setTakePhoto(false)}
        imageReceive={(img) => {
          setImgPatient(img);
        }}
      />
      <SweetAlert
        show={modalCpf.open}
        warning
        showConfirm={true}
        title={modalCpf.message}
        onConfirm={() => {
          setModalCpf({ open: false, type: "", message: "" });
          if (modalCpf.type === "user") {
            setMode(0);
          }
        }}
      />
      <SweetAlert
        show={modalConfirm}
        success
        showConfirm={true}
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, continuar"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Confirme os dados da vacina para continuar:"
        onConfirm={() => {
          handleSave();
          setModalConfirm(false);
        }}
        onCancel={() => setModalConfirm(false)}
      >
        <div>
          <span>Vacina: </span>{" "}
          {listVaccine.find((row) => row.id === vaccine.vaccine).name} <br />
          <span>Aplicada: </span> {vaccine.applied === "1" ? "Sim" : "Não"}
          <br />
          <span>Data: </span> {moment(vaccine.date).format("DD/MM/YYYY")}
        </div>
      </SweetAlert>
      <ModalObservation
        open={modalObs.open}
        handleClose={() => setModalObs(objModalObs)}
        value={modalObs.observation}
        changeObservation={(value) =>
          setVaccine({ ...vaccine, observation: value })
        }
      />
    </div>
  );
}
