export function setDataAppointment(
  patient,
  prof_resp = "",
  triage = "0",
  covid = false,
  service_id = ""
) {
  return {
    type: "SET_DATA_APPOINTMENT",
    patient,
    prof_resp,
    triage,
    covid,
    service_id,
  };
}

export function setStartAppointment(start) {
  return {
    type: "SET_START_APPOINTMENT",
    start,
  };
}

export function setAppointment(id) {
  return {
    type: "SET_INIT_APPOINTMENT",
    payload: {
      id,
    },
  };
}

export function viewHistory(form) {
  return {
    type: "VIEW_HISTORY",
    form,
  };
}

export function closeHistory() {
  return {
    type: "CLOSE_HISTORY",
  };
}

export function finishAppointment(appointment, service_id) {
  return {
    type: "FINISH_APPOINTMENT",
    appointment,
    service_id,
  };
}

export function alterPatient() {
  return {
    type: "ALTER_PATIENT",
  };
}

export function modalFinish() {
  return {
    type: "VIEW_MODAL_FINISH",
  };
}

export function closeModalFinish() {
  return {
    type: "CLOSE_MODAL_FINISH",
  };
}

export function stopRedirect() {
  return {
    type: "STOP_REDIRECT",
  };
}

export const modalCorrection = (toApprove = false) => {
  return {
    type: "VIEW_MODAL_CORRECTION",
    payload: toApprove,
  };
};

export const closeModalCorrection = () => {
  return {
    type: "CLOSE_MODAL_CORRECTION",
  };
};

export const modalPause = (toApprove = false) => {
  return {
    type: "VIEW_MODAL_STOP",
    payload: toApprove,
  };
};

export const closeModalPause = () => {
  return {
    type: "CLOSE_MODAL_STOP",
  };
};

export const pauseAppointment = (toApprove = false) => {
  return {
    type: "PAUSE_APPOINTMENT",
    payload: toApprove,
  };
};

export const setContinue = (value) => {
  return {
    type: "SET_CONTINUE",
    payload: value,
  };
};

export const getAppointmentActions = (id_pp) => {
  return {
    type: "GET_APPOINTMENT_ACTIONS",
    payload: id_pp,
  };
};

export const setSavedActions = (field, id) => {
  return {
    type: "SET_SAVED_ACTIONS",
    payload: {
      field,
      id,
    },
  };
};

export const changeProfResp = (prof_resp) => {
  return {
    type: "CHANGE_PROF_RESP",
    prof_resp,
  };
};
