import React, { Component } from "react";
import api from "services/api";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import NoRecord from "components/Alert/NoRecord";
import ViewNursePrescription from "./ViewNursePrescription";
import ComponentToPrint from "components/print";

export default class NursePrescription extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    rows: [],
    mode_list: true,
    prescription_selected: null
  };
  componentWillMount() {
    api
      .post(`/microservice/sae/getPrescriptionFromPatient`, {
        patient: this.props.patient
      })
      .then(res => {
        this.setState({ rows: res.data });
      })
      .catch(() => {});
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  render() {
    const {
      rows,
      page,
      rowsPerPage,
      mode_list,
      prescription_selected
    } = this.state;
    return (
      <div className="container-person border">
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400"
          }}
        >
          Sistematização da Assistência de Enfermagem - SAE
          {!mode_list ? (
            <React.Fragment>
              <IconButton
                onClick={() => this.setState({ mode_list: true })}
                color="primary"
                aria-label="Visualizar"
                style={{ float: "right" }}
              >
                <i className="zmdi zmdi-undo" />
              </IconButton>
              {prescription_selected.nurse !== null &&
                prescription_selected.nurse === this.props.doctor && (
                  <div style={{ float: "left" }}>
                    <ComponentToPrint
                      dados={{
                        name: "nurse_prescription",
                        id: prescription_selected.id,
                        type: "iconButton"
                      }}
                    />
                  </div>
                )}
            </React.Fragment>
          ) : (
            ""
          )}
        </h3>
        {mode_list ? (
          rows.length > 0 ? (
            <div
              className="col-12"
              style={{
                minWidth: "650",
                overflowX: "auto"
              }}
            >
              <Table className="">
                <TableHead>
                  <TableRow>
                    <TableCell>Profissional</TableCell>
                    <TableCell>Observação</TableCell>
                    <TableCell align="center">Data</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {
                      return (
                        <TableRow hover tabIndex={-1} key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.name}
                          </TableCell>
                          <TableCell>
                            {n.general_orientations !== null
                              ? n.general_orientations
                              : "---"}
                          </TableCell>
                          <TableCell align="center">
                            {n.date.split(" ")[0]}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() =>
                                this.setState({
                                  mode_list: false,
                                  prescription_selected: n
                                })
                              }
                              color="primary"
                              aria-label="Visualizar"
                            >
                              <i className="zmdi zmdi-eye zmdi-hc-fw" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Registros por página"
                      labelDisplayedRows={({ from, to, count }) =>
                        `Mostrando ${from} - ${to} de ${count}`
                      }
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          ) : (
            <NoRecord />
          )
        ) : (
          <ViewNursePrescription prescription={prescription_selected.id} />
        )}
      </div>
    );
  }
}
