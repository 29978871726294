const INITIAL_STATE = {
  tags_from_provider: [],
  tags_from_patient: [],
  loader: true
};

export default function reducerTags(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_TAGS_FROM_PROVIDER":
      return {
        ...state,
        tags_from_provider: action.payload,
        loader: false
      };
    case "SET_TAGS_FROM_PATIENT":
      return {
        ...state,
        tags_from_patient: action.payload
      };
    case "SAVE_CONDITIONS":
      return {
        ...state,
        loader: true
      };
    case "RESET_TAGS":
      return INITIAL_STATE;
    default:
      return state;
  }
}
