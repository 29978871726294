import React from "react";
import { Card } from "reactstrap";
import Api from "services/api";
import { setListAddress } from "actions/Auth";
import { useSelector, useDispatch } from "react-redux";
import Attendance from "../../components/attendance";
import Patients from "../../components/patients";
import ProviderProfessionals from "../../components/providerProfessionals";

export default function DashboardHomeCare({ match }) {
  const user = useSelector(state => state.auth.authUser);
  const address = useSelector(state => state.auth.address);
  const loaded_address = useSelector(state => state.auth.loaded_address);
  const [state, setState] = React.useState({
    professionals: [],
    patients: [],
    loaded: false
  });
  const dispatch = useDispatch();
  React.useEffect(() => {
    Api.post(`/ms_system/dashboard/getRecordsHomeCare`, {
      address,
      user,
      loaded_address
    })
      .then(({ data }) => {
        if (!loaded_address) {
          dispatch(
            setListAddress(
              data.listAddress.map(row => ({
                ...row,
                type: "1",
                provider_type: "55"
              }))
            )
          );
        }
        dispatch({
          type: "SET_PATIENTS_HOME_CARE",
          payload: {
            patients: data.patients
          }
        });
        setState({
          professionals: data.professionals,
          patients: data.patients,
          loaded: true
        });
      })
      .catch(() => setState({ ...state, loaded: true }));
  }, [address]);

  return (
    <div className="row col-12 d-flex justify-content-center ml-1">
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-1 justify-content-center m-0 p-0">
        <Card className="shadow border-0 mb-3 mt-1">
          <Attendance title="Home Care" loaded={state.loaded} />
        </Card>
        <Card className="shadow border-0 mb-3">
          <ProviderProfessionals
            address={address}
            professionals={state.professionals}
            loaded={state.loaded}
          />
        </Card>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-2 justify-content-center">
        <Card className="shadow border-0 mb-3">
          <Patients patients={state.patients} loaded={state.loaded} />
        </Card>
      </div>
    </div>
  );
}
