import { all, takeLatest, put, call } from "redux-saga/effects";
import api from "services/api";

const getInfoPatient = async patient => {
  try {
    const response = await api.post(`/microservice/consult/getInfoPatient/`, {
      patient
    });
    return await response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncGetInfoPatient(action) {
  try {
    const response = yield call(getInfoPatient, action.patient);
    yield put({
      type: "PATIENT_INFO",
      payload: {
        patient: { ...response.patient }
      }
    });
  } catch (error) {
    console.log(error);
    // yield put(showMailMessage(error));
  }
}

export default function* rootSaga() {
  yield all([takeLatest("GET_PATIENT_INFO", asyncGetInfoPatient)]);
}
