import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as personalDataActions from "actions/consult/PersonalData";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";
import Chip from "@material-ui/core/Chip";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%",
  },
  root: {
    backgroundColor: "#fff",
  },
  selected: {
    backgroundColor: "#e2e2e8 !important",
  },
};

class AutocompleteParents extends Component {
  state = {
    suggestions: [],
    label: "",
    field_id: "",
    field_name: "",
    selected: "",
    error: false,
  };

  componentDidMount() {
    this.setState({ error: this.props.error });
    if (this.props.parent.label) {
      const { id, label } = this.props.parent;
      this.setState({ selected: [{ id, label }] });
    }
    switch (this.props.field) {
      case "mother":
        this.setState({
          label: "Nome da mãe",
          field_id: "mother_id",
          field_name: "mother_name",
        });
        break;
      case "father":
        this.setState({
          label: "Nome do pai",
          field_id: "father_id",
          field_name: "father_name",
        });
        break;
      case "emergency_contact":
        this.setState({
          label: "Nome",
          field_id: "contact_id",
          field_name: "name",
        });
        break;
      case "children":
        this.setState({
          label: "Nome",
          field_id: "children_id",
          field_name: "name",
        });
        break;
      case "wife":
        this.setState({
          label: "Nome do cônjuge",
          field_id: "wife_id",
          field_name: "wife_name",
        });
        break;

      default:
        break;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.parent !== undefined) {
      const { id, label } = nextProps.parent;
      this.setState({ selected: [{ id, label }] });
    }
  }

  getSuggestions = (e) => {
    if (
      e.target.value.length > 2 &&
      e.keyCode !== 38 &&
      e.keyCode !== 40 &&
      e.keyCode !== 13
    ) {
      Api.post(`/microservice/autocomplete/getUserParents/`, {
        term: e.target.value,
        patient: this.props.patient,
      }).then((res) => {
        const { data } = res;
        this.setState({
          suggestions: data,
        });
      });
    }
  };

  renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    const { classes } = this.props;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
      >
        {suggestion.label}
        {!suggestion.id && (
          <Chip
            className="m-1 ml-3"
            size="small"
            color="primary"
            icon={<PersonAddIcon />}
            label="Novo usuário"
          />
        )}
      </MenuItem>
    );
  }

  renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        error={this.state.error}
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...InputProps,
        }}
        onKeyUp={this.getSuggestions}
        onChange={this.handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  render() {
    const { classes, handleChangeAutocomplete } = this.props;
    const { suggestions, label, field_id, field_name, selected } = this.state;
    return (
      <Downshift
        onSelect={(selectedItem) => {
          let item = suggestions.filter((row) => {
            return row.label === selectedItem;
          });
          handleChangeAutocomplete({
            index: field_id,
            value: item[0].id,
          });
          handleChangeAutocomplete({
            index: field_name,
            value: item[0].label,
          });
        }}
        selectedItem={selected ? selected[0].label : null}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            {this.renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                id: `ac-${field_name}`,
              }),
              label: label,
              margin: "normal",
            })}
            {isOpen ? (
              <Paper square>
                {suggestions.map((suggestion, index) =>
                  this.renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.label,
                    }),
                    highlightedIndex,
                    selectedItem,
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

AutocompleteParents.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { patient: state.auth.authUser };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(personalDataActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AutocompleteParents));
