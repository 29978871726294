import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContainerHeader from "components/ContainerHeader";
import { Card, CardBody } from "reactstrap";
import Api from "services/api";
import DataTable from "components/Tables/DataTable";
import ComponentsProfile from "components/ComponentsProfile";
import { IconButton, Tooltip, Chip } from "@material-ui/core";
import ViewNotification from "./ViewNotification";
import MenuItem from "./MenuItem";
import moment from "moment";
import HistoryPatient from "components/HistoryPatient";
import { viewHistory } from "actions/consult/Appointment";
import { getInfoPatient } from "actions/consult/PatientInfo";
import CardViewAddress from "components/Forms/CardViewAddress";
import CardChooseAddress from "components/Forms/CardChooseAddress";

//modal
import MotalMonitorPatient from "../modal/MonitorPatient";

export default function ListNotificationCovid({ match, history }) {
  const [record, setRecord] = useState([]);
  const [modal, setmodal] = useState({
    monitPatien: false,
    data: {},
  });
  const [patient, setPatient] = useState("");
  const address = useSelector((state) => state.auth.address);

  const [view, setView] = useState({
    open: false,
    data: {},
  });

  const dispatch = useDispatch();

  useEffect(() => {
    Api.post("/ms_system/notificationcovid/getListNotificationCovid/", {
      city: "1668",
    }).then((res) => {
      setRecord(res.data.record);
    });
  }, []);

  const listSymptons = (row) => {
    return (
      <>
        {row.fever === "1" && (
          <Chip size="small" className="mr-2" label="Febre" />
        )}
        {row.cough === "1" && (
          <Chip size="small" className="mr-2" label="Tosse Seca" />
        )}
        {row.tiredness === "1" && (
          <Chip size="small" className="mr-2" label="Cansaço/Fadiga" />
        )}
        {row.difficulty_breathing === "1" && (
          <Chip
            size="small"
            className="mr-2"
            label="Dificuldade de Respirar/Falta de Ar"
          />
        )}
        {row.sore_throat === "1" && (
          <Chip size="small" className="mr-2" label="Dor de garganta" />
        )}
        {row.diarrhea === "1" && (
          <Chip size="small" className="mr-2" label="Diarreia" />
        )}
        {row.chest_pain === "1" && (
          <Chip size="small" className="mr-2" label="Dor ou Pressão no Peito" />
        )}
        {row.smell_taste === "1" && (
          <Chip
            size="small"
            className="mr-2"
            label="Perda de Olfato ou Paladar"
          />
        )}
        {row.headache === "1" && (
          <Chip size="small" className="mr-2" label="Dor de Cabeça/Cefaléia" />
        )}
        {row.loss_speech_movement === "1" && (
          <Chip size="small" className="mr-2" label="Perda de Fala/Movimento" />
        )}
      </>
    );
  };

  const handleMenu = (type, row) => {
    switch (type) {
      case "0":
        setView({
          open: true,
          data: row,
        });
        break;
      case "1":
        {
          setPatient(row.patient);
          dispatch(viewHistory("personalData"));
          dispatch(getInfoPatient(row.patient));
        }
        break;
      case "2":
        {
          history.push("/app/calendar");
        }
        break;
      case "4":
        {
          setmodal({ ...modal, monitPatien: true, data: row });
        }
        break;

      default:
        break;
    }
  };

  const handleSaveMonitorPati = (params) => {
    Api.post("/ms_system/notificationcovid/saveMonitorPatient/", {
      ...params,
    }).then((res) => {
      setmodal({
        ...modal,
        monitPatien: false,
        data: {},
      });
    });
  };

  return (
    <div className="animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        title="Notificações de Pacientes Sintomáticos"
        match={match}
      />

      {address && (
        <>
          <CardViewAddress />

          <Card className="shadow border-0 mt-3">
            <CardBody>
              <DataTable
                name="Pacientes Notificados"
                columns={[
                  "Data",
                  "Tipo",
                  "Paciente",
                  "Monitorado",
                  // "Sintomas",
                  "Contato",
                  "Ações",
                ]}
                rows={record.map((row, index) => [
                  moment(row.created_at)
                    .add(1, "day")
                    .isBefore(moment()) ? (
                    <span style={{ color: "red" }}>
                      <i className="zmdi zmdi-alert-triangle zmdi-fw mr-3" />
                      {moment(row.created_at).format("DD/MM/YYYY")}
                    </span>
                  ) : (
                    moment(row.created_at).format("DD/MM/YYYY")
                  ),
                  row.type === "1"
                    ? "Vacinação"
                    : row.type === "2"
                    ? "Sequelas"
                    : row.type === "3"
                    ? "Comorbidades"
                    : "Sintomas",
                  <ComponentsProfile
                    id={row.patient}
                    nameProfile={row.patient_name}
                    photo={row.patient_photo}
                    subTitle={moment(row.patient_birthday).format("DD/MM/YYYY")}
                  />,
                  row.monitoring === "1" ? "Sim" : "Não",
                  // listSymptons(row),
                  row.smartphone
                    ? row.smartphone
                    : row.fixo
                    ? row.fixo
                    : row.email,
                  <div className="d-flex">
                    <MenuItem handleSetValue={handleMenu} row={row} />
                  </div>,
                ])}
                download={true}
                print={true}
                hideSearch={true}
              />
            </CardBody>
          </Card>
        </>
      )}
      {!address && <CardChooseAddress />}

      <ViewNotification
        open={view.open}
        handleClose={() => setView({ open: false, data: {} })}
        data={view.data}
      />

      <MotalMonitorPatient
        data={modal.data}
        open={modal.monitPatien}
        saveMonitorPati={handleSaveMonitorPati}
        close={() =>
          setmodal({
            ...modal,
            monitPatien: false,
            data: {},
          })
        }
      />
      <HistoryPatient id_patient={patient} />
    </div>
  );
}
