import React from "react";
import { Card } from "reactstrap";
import Api from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { setListAddress } from "actions/Auth";
import Attendance from "../../components/attendance";
import Calendar from "../../components/calendar";
// import Finance from "../../components/finance";
import Sessions from "../../components/sessions";
import ModalOffline from "components/ModalOffline";
// import News from "../../components/news";

export default function DashboardDoctor({ match, history }) {
  const doctor = useSelector((state) => state.auth.authUser);
  const area = useSelector((state) => state.auth.area);
  const loaded_address = useSelector((state) => state.auth.loaded_address);
  const [state, setState] = React.useState({ dadosDash: [], loaded: false });
  const dispatch = useDispatch();

  React.useEffect(() => {
    Api.post(`/ms_system/dashboard/getRecordsToDashProfessional`, {
      doctor,
      index: "1",
      loaded_address,
    }).then((res) => {
      if (!loaded_address) {
        dispatch(setListAddress(res.data.listAddress, res.data.managers));
      }
      setState({ dadosDash: res.data, loaded: true });
    });
  }, []);

  return (
    <div className="row col-12 d-flex justify-content-center ml-1">
      <div className="col-xl-12 col-lg-6 col-md-6 col-sm-12 col-12 p-1 justify-content-center m-0 p-0">
        <Card className="shadow border-0 mt-1 mb-1">
          <Attendance />
        </Card>
      </div>
      <div
        className={
          area === "9" || area === "22"
            ? "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-1 justify-content-center"
            : "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-1 justify-content-center"
        }
      >
        <Card className="shadow border-0 mb-3">
          <Calendar match={match} area={area} />
        </Card>
      </div>
      {(area === "9" || area === "22") && (
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-1 justify-content-center">
          <Sessions history={history} area={area} />
          {/* <Finance dadosDash={state.dadosDash} /> */}
        </div>
      )}

      <ModalOffline open={!navigator.onLine} />
    </div>
  );
}
