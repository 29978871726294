import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Api from "services/api";

class ViewGeneralObj extends Component {
  state = {
    record: []
  };

  componentDidMount() {
    Api.post(`/microservice/odGeneralObjective/getDetailsByAppointment/`, {
      appointment_id: this.props.appointment
    })
      .then(res => {
        this.setState({ record: res.data });
      })
      .catch(() => {});
  }

  render() {
    const { onClose } = this.props;
    const record = this.state.record;
    return (
      <div className="row">
        <div className="col-md-12" style={{ marginTop: "20px" }}>
          <div className="row" style={{ padding: "10px" }}>
            {record.map(row => {
              return (
                <div className="col-md-12 col-xs-4" key={row.idAnswer}>
                  <span className="font-weight-semibold">
                    {" "}
                    {row.descPergunta}:{" "}
                  </span>
                  {row.respostas}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-12 text-right">
          <Button
            onClick={() => {
              onClose();
            }}
            color="primary"
            aria-label="Voltar"
          >
            <i className="zmdi zmdi-undo" /> Voltar
          </Button>
        </div>
      </div>
    );
  }
}

export default ViewGeneralObj;
