import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getHistoryAga, getHistoryRegisterAga } from "actions/consult/Aga";
import TableAga from "./tableAga";
import PaperDados from "./Paper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NoRecord from "components/Alert/NoRecord";

class Aga extends Component {
  state = { tela: false };
  componentDidMount() {
    this.props.getHistoryAga(this.props.patient);
  }
  handleClickRegister = id => {
    this.props.getHistoryRegisterAga({ id: id });
    this.setState({ tela: true });
  };

  render() {
    const { aga } = this.props;
    return (
      <React.Fragment>
        <div className="container-person border">
          <h3
            className="title-container d-flex justify-content-center"
            style={{
              fontSize: "26px",
              fontWeight: "400"
            }}
          >
            Avaliação Geriátrica Ampla
          </h3>
          {aga.length === 0 && <NoRecord />}
          {this.state.tela === true && (
            <div className="mt-1">
              <PaperDados
                dados={this.props.respQuestions}
                handleClick={() => this.setState({ tela: false })}
              />{" "}
            </div>
          )}
        </div>
        {this.props.aga !== undefined && this.state.tela === false && (
          <div>
            <div>
              {Object.keys(aga).map((el, index) => (
                <div className="mt-1">
                  {" "}
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {aga[el].labelQuestionnaire}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="col-12">
                      <TableAga
                        className="col-12"
                        dados={aga[el].dados}
                        label={aga[el].labelQuestionnaire}
                        handleClickRegister={this.handleClickRegister}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              ))}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    respQuestions: state.aga.respQuestions,
    aga: state.aga.values
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getHistoryAga, getHistoryRegisterAga }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Aga);
