import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

export default function ManagementProfessional({ match, history }) {
  const permissions = useSelector((state) => state.permissions);
  const type = useSelector((state) => state.auth.type);
  const manager_provider = useSelector(
    (state) => state.auth.address_selected.manager
  );
  const { manager, master, manager_patient } = permissions;

  React.useEffect(() => {
    let path = window.location.href.split(match.url)[1];
    switch (path) {
      case "/patient":
        !(
          manager ||
          manager_provider ||
          master ||
          manager_patient ||
          type === "1"
        ) && history.push("/app/dashboard");
        break;
      case "/professional":
        !(manager || manager_provider || master || type === "1") &&
          history.push("/app/dashboard");
      case "/evolution-intervals":
        !(manager || manager_provider || master || type === "1") &&
          history.push("/app/dashboard");
      case "/evolution-notifications":
        !(manager || manager_provider || master || type === "1") &&
          history.push("/app/dashboard");
      case "/departments":
        !(manager || manager_provider || master || type === "1") &&
          history.push("/app/dashboard");
      default:
        break;
    }
  }, [window.location.href]);

  return (
    <div className="app-wrapper">
      <Switch>
        <Route
          path={`${match.url}/patient`}
          component={asyncComponent(() => import("./routes/patient"))}
        />
        <Route
          path={`${match.url}/professional`}
          component={asyncComponent(() =>
            import("./routes/professional/management")
          )}
        />
        <Route
          path={`${match.url}/care-pattern`}
          component={asyncComponent(() => import("./routes/carePattern"))}
        />
        <Route
          path={`${match.url}/evolution-intervals`}
          component={asyncComponent(() =>
            import("./routes/evolutionIntervals")
          )}
        />
        <Route
          path={`${match.url}/evolution-notifications`}
          component={asyncComponent(() =>
            import("./routes/evolutionNotifications")
          )}
        />

        <Route
          path={`${match.url}/departments`}
          component={asyncComponent(() => import("./routes/departments"))}
        />

        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
}
