import React from "react";

import Profile from "components/ComponentsProfile";
import { FormControl, FormLabel } from "@material-ui/core";
export default function ViewPrescription({ record }) {
  const style = {
    fieldset: {
      border: "1px solid #b3b3b3",
      padding: "10px",
      marginBottom: "1rem",
      width: "500px",
      borderRadius: "3px",
    },
    legend: {
      width: "unset",
      display: "flex",
      paddingRight: "5px",
    },
  };
  const {
    orientation,
    recipes,
    doc_id,
    doc_name,
    doc_photo,
    doc_email,
  } = record;
  return (
    <div className="mx-4">
      <div className="row">
        <h4 className=" w-100 font-weight-semibold">
          {" "}
          Informações do Profissional{" "}
        </h4>
        <div className="col-md-4">
          <Profile
            id={doc_id}
            nameProfile={doc_name}
            subTitle={doc_email}
            photo={doc_photo}
          />
        </div>
      </div>
      <div className="row mt-4">
        <h4 className="font-weight-semibold"> Prescrição </h4>
      </div>
      {recipes.map((row) => (
        <div className="row" key={row.id}>
          <FormControl style={style.fieldset} component="fieldset">
            <FormLabel style={style.legend} component="legend">
              {row.name}
            </FormLabel>
            <div className="row">
              <div className="col-12">
                <strong>Forma: </strong>
                {row.form_name} <strong>Quantidade: </strong>
                {row.amount} {row.measure}
              </div>
              <ul>
                {row.principles.map((el) => (
                  <li key={el.id}>{`${el.substance} - ${el.dosage}`}</li>
                ))}
              </ul>
              <div className="col-12">
                <p>
                  <strong>Posologia: </strong>
                  {row.posology}
                </p>
              </div>
              <div className="col-12">
                {row.orientation && (
                  <p>
                    <strong>Orientações da fórmula: </strong>
                    {row.posology}
                  </p>
                )}
              </div>
            </div>
          </FormControl>
        </div>
      ))}
      {orientation && (
        <p>
          <strong>Orientações da prescrição: </strong>
          {orientation}
        </p>
      )}
    </div>
  );
}
