import React from "react";
import BarProgress from "./BarProgress.gif";

export default function CustomizedProgressBars() {
  return (
    <div>
      <img
        src={BarProgress}
        alt="Preload salutem+"
        title="Preload salutem+"
        style={{
          height: "40px",
          width: "45px"
        }}
      />
    </div>
  );
}
