import React from "react";
import { Card } from "reactstrap";
import Api from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { setListAddress } from "actions/Auth";
import Attendance from "../../components/attendance";
import Residents from "../../components/residents";
import Professionals from "../../components/residentialProfessionals";

export default function DashboardResidential({ match }) {
  // const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);
  const loaded_address = useSelector((state) => state.auth.loaded_address);
  const dispatch = useDispatch();

  React.useEffect(() => {
    Api.post(`/ms_system/dashboard/getRecordILPI`, {
      user,
      loaded_address,
      index: "1",
    }).then((res) => {
      if (!loaded_address) {
        dispatch(
          setListAddress(
            res.data.listAddress.map((row) => ({
              ...row,
              type: "1",
              provider_type: "5",
            }))
          )
        );
      }
    });
  }, []);

  return (
    <div className="row col-12 d-flex justify-content-center ml-1">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-1 justify-content-center m-0 p-0">
        <Card
          className="shadow border-0 mt-1"
          style={{
            marginBottom: "0px",
          }}
        >
          <Attendance />
        </Card>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-1 justify-content-center m-0 p-0">
        <Card className="shadow border-0 mb-3">
          <Residents addressId={address} />
        </Card>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-1 justify-content-center">
        <Card className="shadow border-0 mb-3">
          <Professionals address={address} />
        </Card>
      </div>
    </div>
  );
}
