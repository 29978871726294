export const getTypeExams = () => ({
  type: "HANDLE_GET_TYPE_EXAMS",
  payload: {},
});
export const handleExamsType = (types) => ({
  type: "HANDLE_TYPE_EXAMS",
  payload: { types },
});

export const delItemExam = (id, event) => ({
  type: "DEL_ITEM_EXAM",
  payload: { id },
});

export function setSelectedExam(id, value) {
  return {
    type: "SET_SELECTED_EXAM",
    payload: { id, value },
  };
}

export function changeNameGroup(value) {
  return {
    type: "SET_NAME_GROUP",
    payload: { value },
  };
}

export function changeDescGroup(value) {
  return {
    type: "SET_DESC_GROUP",
    payload: { value },
  };
}

export function saveExamsGroups(
  doctor,
  nameGroupValue,
  descGroupValue,
  examsSelected,
  idEdit
) {
  return {
    type: "CAD_EXAMS_GROUP",
    payload: { doctor, nameGroupValue, descGroupValue, examsSelected, idEdit },
  };
}

export function requestListDoctorExamsGroups(doctor) {
  return {
    type: "REQUEST_LIST_DOCTOR_EXAMS_GROUP",
    payload: { doctor },
  };
}

export const handleAddFunction = (active, color, texto, hidden, icon, id) => ({
  type: "HANDLE_ADD_FUNC",
  payload: { active, color, texto, hidden, icon, id },
});

export function delGroup(id, event) {
  return {
    type: "DEL_GROUP",
    payload: { id, event },
  };
}

export function getDataGroupForEdit(id) {
  return {
    type: "GET_DATA_GROUP_FOR_EDIT",
    payload: { id },
  };
}

export function autocompleteExams(id, test) {
  return {
    type: "AUTO_COMPLETE_EXAM",
    payload: {
      id,
      test,
    },
  };
}

export const changeObs = (index, value) => ({
  type: "CHANGE_OBS",
  payload: { index, value },
});
