const INITIAL_STATE = {
  idScale: "",
  list_scale: []
};

export default function painScale(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_LIST_SCALE":
      return {
        ...state,
        list_scale: action.payload
      };
    case "REQUEST_SAVE_SCALE_SUCCESS":
      let list = state.list_scale.filter(row => {
        return row.id !== action.payload.idPainScale;
      });
      return {
        ...state,
        idScale: action.payload.idPainScale,
        list_scale: [
          ...list,
          {
            id: action.payload.idPainScale,
            name: action.payload.name,
            value: action.payload.value,
            date: action.payload.date,
            obs: action.payload.obs
          }
        ]
      };
    case "DELETE_SCALE_SUCCESS":
      let listDel = state.list_scale.filter(row => {
        return parseInt(row.id) !== action.payload.idScale;
      });
      return {
        ...state,
        idScale: "",
        list_scale: listDel
      };
    case "RESET_PAIN_SCALE":
      return INITIAL_STATE;
    default:
      return state;
  }
}
