import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import { getEvents } from "actions/Calendar";
import {
  Button,
  TextField,
  CircularProgress,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  InputAdornment,
} from "@material-ui/core";
import { ModalResponsive } from "components/ModalResponsive";
import Api from "services/api";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { showMsg } from "services/functions";

export default function FormBloquedHour({ calendar, width, closeMenu }) {
  const dispatch = useDispatch();
  const initial_state = {
    modal_form: false,
    modal_isset: false,
    title: "Horário bloqueado",
    dateStart: moment().format("YYYY-MM-DD"),
    hourStart: "",
    dateEnd: moment().format("YYYY-MM-DD"),
    hourEnd: "",
    error: false,
    loading: false,
  };
  const [state, setState] = useState(initial_state);
  const [repeat, setRepeat] = useState("0");
  const [repeatDate, setRepeatDate] = useState();
  const [typeRepeat, setTypeRepeat] = useState();
  const [numberOcurrency, setNumberOcurrency] = useState(0);
  const user = useSelector((state) => state.auth.authUser);
  const date = useSelector((state) => state.calendar.date_calendar);

  const ComponentForm = () => (
    <div className="row w-100">
      <div className="col-12">
        <TextField
          label="Título:"
          required
          fullWidth
          value={state.title}
          onChange={(e) => setState({ ...state, title: e.target.value })}
          error={state.error && !state.title}
        />
      </div>
      <div className="col-6 mt-2">
        <TextField
          label="Data inicial:"
          type="date"
          fullWidth
          value={state.dateStart}
          onChange={(e) => setState({ ...state, dateStart: e.target.value })}
          error={state.error && !state.dateStart}
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div className="col-6 mt-2">
        <TextField
          label="Horário:"
          type="time"
          fullWidth
          value={state.hourStart}
          onChange={(e) => setState({ ...state, hourStart: e.target.value })}
          error={state.error && !state.hourStart}
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div className="col-6 mt-2">
        <TextField
          label="Data final:"
          type="date"
          fullWidth
          value={state.dateEnd}
          onChange={(e) => setState({ ...state, dateEnd: e.target.value })}
          error={state.error && !state.dateEnd}
        />
      </div>
      <div className="col-6 mt-2">
        <TextField
          label="Horário:"
          type="time"
          fullWidth
          value={state.hourEnd}
          onChange={(e) => setState({ ...state, hourEnd: e.target.value })}
          error={state.error && !state.hourEnd}
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div className="col-12 mt-2">
        <TextField
          multiline
          rows={4}
          label="Observações:"
          fullWidth
          value={state.observation}
          onChange={(e) => setState({ ...state, observation: e.target.value })}
        />
      </div>
      <div className="col-md-12">
        <TextField
          id="repeat"
          select
          label="Repetir:"
          value={repeat}
          onChange={(e) => setRepeat(e.target.value)}
          fullWidth
          style={{ marginTop: "17px" }}
          error={state.error && !repeat ? true : false}
        >
          <MenuItem key="rp1" value="0">
            Sem repetição
          </MenuItem>
          <MenuItem key="rp1" value="1">
            Diariamente
          </MenuItem>
          <MenuItem key="rp2" value="7">
            Semanalmente
          </MenuItem>
          <MenuItem key="rp3" value="15">
            Quizenalmente
          </MenuItem>
          <MenuItem key="rp4" value="30">
            Mensalmente
          </MenuItem>
          <MenuItem key="rp5" value="60">
            Bimestralmente
          </MenuItem>
          <MenuItem key="rp6" value="90">
            Trimestralmente
          </MenuItem>
          <MenuItem key="rp7" value="180">
            Semestrealmente
          </MenuItem>
          <MenuItem key="rp8" value="365">
            Anualmente
          </MenuItem>
        </TextField>
      </div>
      {repeat !== "0" && (
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <FormLabel component="legend">Termina: </FormLabel>
          <RadioGroup
            name="repeatType"
            value={typeRepeat}
            onChange={(e) => setTypeRepeat(e.target.value)}
            style={{ marginTop: 0, marginLeft: 20 }}
          >
            <div className="d-flex">
              <div>
                <FormControlLabel
                  style={{ justifyItems: "baseline" }}
                  control={
                    <Radio
                      checked={typeRepeat === "1"}
                      value="1"
                      name="radio-button-demo"
                    />
                  }
                  label="Em: "
                />
              </div>
              <div className="row" style={{ marginLeft: 10, minWidth: 350 }}>
                <div className="col-md-12">
                  <TextField
                    label=""
                    type="date"
                    fullWidth
                    value={repeatDate}
                    onChange={(e) => setRepeatDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <FormControlLabel
                  style={{ marginTop: "15px", justifyItems: "baseline" }}
                  control={
                    <Radio
                      checked={typeRepeat === "2"}
                      value="2"
                      name="radio-button-demo"
                    />
                  }
                  label="Após: "
                />
              </div>
              <div className="row" style={{ marginLeft: 10, minWidth: 350 }}>
                <div className="col-md-12" style={{ marginTop: "15px" }}>
                  <TextField
                    id="quantityDays"
                    disabled={typeRepeat === "2" ? false : true}
                    type="number"
                    min={1}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ marginRight: 15 }}
                        >
                          Agendamentos
                        </InputAdornment>
                      ),
                    }}
                    value={numberOcurrency}
                    onChange={(e) => setNumberOcurrency(e.target.value)}
                    fullWidth
                    error={state.error && !numberOcurrency ? true : false}
                  />
                </div>
              </div>
            </div>
          </RadioGroup>
        </div>
      )}
      <CircularProgress hidden={!state.loading} style={{ float: "right" }} />
      <SweetAlert
        show={state.modal_isset}
        warning
        showConfirm={false}
        title=""
        onConfirm={() => {}}
      >
        <div className="row">
          <div className="col-12">
            <span style={{ fontWeight: "500" }}>
              Existem eventos conflitantes com este horário. Deseja continuar?
            </span>
          </div>
          <div className="col-6 mt-3">
            <Button
              variant="contained"
              className="jr-btn jr-btn-sm"
              fullWidth
              onClick={() => setState(initial_state)}
            >
              <span> Não, Cancelar </span>
            </Button>
          </div>
          <div className="col-6 mt-3">
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              fullWidth
              onClick={() => save(false, false)}
            >
              <span> Sim, Continuar </span>
            </Button>
          </div>
          <div className="col-12 mt-3">
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              fullWidth
              onClick={() => save(false, true)}
            >
              <span> Desmarcar agendamentos e continuar </span>
            </Button>
          </div>
        </div>
      </SweetAlert>
    </div>
  );

  function save(verify, deleteEvents) {
    if (
      state.title &&
      state.dateStart &&
      state.dateEnd &&
      state.hourStart &&
      state.hourEnd
    ) {
      setState({ ...state, error: false, loading: true });
      const obj = {
        title: state.title,
        start: state.dateStart + " " + state.hourStart,
        end: state.dateEnd + " " + state.hourEnd,
        calendar,
        user,
        verify,
        deleteEvents,
        repeat,
        repeatDate,
        typeRepeat,
        numberOcurrency,
      };
      Api.post(`/ms_system/calendar/saveBloquedHours/`, obj)
        .then((res) => {
          if (res.data.isset_events) {
            setState({ ...state, modal_isset: true });
          } else {
            dispatch(getEvents(calendar, date));
            showMsg("success", "Horário bloqueado!", dispatch);
            setState(initial_state);
            closeMenu();
          }
        })
        .catch(() => {
          showMsg("error", "Ocorreu um erro.", dispatch);
          setState({ ...state, loading: false });
        });
    } else {
      setState({ ...state, error: true });
    }
  }

  return (
    <>
      {width > 500 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setState({ ...state, modal_form: true })}
          style={{ marginTop: 15, marginLeft: 10 }}
        >
          <i className="zmdi zmdi-calendar-close mr-3" /> Bloquear Horário
        </Button>
      ) : (
        <MenuItem onClick={() => setState({ ...state, modal_form: true })}>
          <i className="zmdi zmdi-calendar-close mr-3" /> Bloquear Horário
        </MenuItem>
      )}
      <ModalResponsive
        title="Bloquear Horário"
        open={state.modal_form}
        close={() => {
          setState(initial_state);
          closeMenu();
        }}
        maxWidth="md"
        buttons={
          <div className="col-md-12">
            <Button
              hidden={state.loading}
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={() => {
                setState({ ...state, modal_form: false, error: false });
                closeMenu();
              }}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              hidden={state.loading}
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right", marginRight: 0 }}
              onClick={() => save(true, false)}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Salvar </span>
            </Button>
          </div>
        }
      >
        {ComponentForm()}
      </ModalResponsive>
    </>
  );
}
