import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";

export const getUrlPhoto = (id, photo) => {
  if (photo) {
    return `https://s3.sa-east-1.amazonaws.com/salutem-webapp-files/upload/user/${id}/${photo}`;
  } else {
    return "https://balancer.salutemplus.com/images/avatar_user_men.jpg";
  }
};

export const animateCSS = (node, animationName, callback) => {
  // const node = document.querySelector(element)
  node.classList.add("animated", animationName);

  function handleAnimationEnd() {
    node.classList.remove("animated", animationName);
    node.removeEventListener("animationend", handleAnimationEnd);

    if (typeof callback === "function") callback();
  }

  node.addEventListener("animationend", handleAnimationEnd);
};

export const getStringToSearch = (value) => {
  return value
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

/**
 * Mostra mensagem de alerta
 * @param {string} type error || success
 * @param {string} text texto da mensagem
 * @param {function} dispatch useDispatch from react-redux
 */
export const showMsg = (type, text, dispatch) => {
  dispatch(viewAlertMessage(type, text));
  setTimeout(() => {
    dispatch(closeAlertMessage());
  }, 5000);
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getBaseUrl = () => {
  return window.location.hostname == "localhost"
    ? "http://localhost/salutem-webapp/app"
    : "https://balancer.salutemplus.com";
};

/**
 * Retorna string em formato do banco para variaveis do tipo Date
 * @param {*Date} value
 */
export const getFormatDate = (value) => {
  let date = value.toLocaleDateString();
  date = date.split("/");
  date = `${date[2]}-${date[1]}-${date[0]}`;

  return `${date} ${value.toLocaleTimeString()}`;
};

export const getFormatOnlyDate = (value) => {
  let date = value.toLocaleDateString();
  date = date.split("/");
  date = `${date[2]}-${date[1]}-${date[0]}`;

  return date;
};

/**
 * Função de debounce
 * @param {Function} fn função que será executada com o método bounce
 * @param {int} wait tempo em milessegundos
 * @param {function} time timeout
 */
export const debounce = (fn, wait = 1000, time) => (...args) =>
  clearTimeout(time, (time = setTimeout(() => fn(...args), wait)));

/**
 * Função que retorna o valor dos inputs de moeda em float
 * @param {string} value valor no formato R$ 1.280,69
 */
export const getFormatValueMoney = (value, separator = ".") => {
  if (!value) {
    return null;
  }
  let aux = value ? value.replace(/[^0-9]/gi, "") : 0;
  let leng = aux.length;
  return aux.substring(0, leng - 2) + separator + aux.substring(leng - 2, leng);
};

export const getClassImage = (type) => {
  let imgIcon = "";
  switch (type) {
    case "Similar":
      imgIcon = require("assets/images/consult/similar.jpg");
      break;
    case "Generico":
      imgIcon = require("assets/images/consult/generico.jpg");
      break;
    case "Referencia":
    case "Especifico":
      imgIcon = require("assets/images/consult/referencia.jpg");
      break;
    case "Biológicos":
      imgIcon = require("assets/images/consult/biologico.jpg");
      break;
    default:
      imgIcon = require("assets/images/consult/outros.jpg");
      break;
  }
  return imgIcon;
};

export const getTarge = (targe) => {
  if (targe === "TP") {
    return require("assets/images/consult/Preta.svg");
  } else if (targe === "TV") {
    return require("assets/images/consult/Vermelha.svg");
  } else {
    return require("assets/images/consult/Branca.svg");
  }
};

export const formatName = (value) => {
  if (value) {
    let formtCase = value.split(" ").map((row) => {
      return row === "Da" ||
        row === "da" ||
        row === "De" ||
        row === "de" ||
        row === "Do" ||
        row === "do" ||
        row === "Das" ||
        row === "das" ||
        row === "Dos" ||
        row === "dos"
        ? row.charAt(0).toLowerCase() + row.slice(1).toLowerCase()
        : row.charAt(0).toUpperCase() + row.slice(1).toLowerCase();
    });
    return formtCase.join(" ");
  } else {
    return "";
  }
};

export const convertStrToFloat = (str) => {
  if (typeof str == "string") {
    str = str.replace(",", ".");
    return parseFloat(str);
  } else if (typeof str == "number") {
    if (parseFloat(str)) {
      return parseFloat(str);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};
