export const GET_PERSONAL_DATA = "GET_PERSONAL_DATA";

export function getPersonalData(user) {
  return {
    type: GET_PERSONAL_DATA,
    user
  };
}

export function getProviderData(user) {
  return {
    type: "GET_PROVIDER_DATA",
    user
  };
}

export function handleChange(index, value) {
  return {
    type: "HANDLE_CHANGE",
    index,
    value
  };
}

export function handleChangeProvider(indexProvider, valueProvider) {
  return {
    type: "HANDLE_CHANGE_PROVIDER",
    indexProvider,
    valueProvider
  };
}

export function handleChangeRecreation(value) {
  return {
    type: "HANDLE_CHANGE_RECREATION",
    value
  };
}

// Planos de saúde
export function addHealthPlan(health_plan) {
  return {
    type: "ADD_HEALTH_PLAN_PROFILE",
    health_plan
  };
}

export function editHealthPlan(health_plan) {
  return {
    type: "EDIT_HEALTH_PLAN_PROFILE",
    health_plan
  };
}

// Endereço pessoal
export function addPersonAddress(person_address) {
  return {
    type: "ADD_PERSON_ADDRESS",
    person_address
  };
}

export function editPersonAddress(person_address) {
  return {
    type: "EDIT_PERSON_ADDRESS",
    person_address
  };
}

// CONTATO DE EMERGENCIA
export function addEmergencyContact(contact) {
  return {
    type: "ADD_EMERGENCY_CONTACT",
    contact
  };
}

export function editEmergencyContact(contact) {
  return {
    type: "EDIT_EMERGENCY_CONTACT",
    contact
  };
}

// FILHOS
export function addChildren(children) {
  return {
    type: "ADD_CHILDREN",
    children
  };
}

export function editChildren(children) {
  return {
    type: "EDIT_CHILDREN",
    children
  };
}

export function getCouncil() {
  return {
    type: "GET_COUNCIL"
  };
}

export function setImage(patient, image) {
  return {
    type: "ALTER_PHOTO",
    payload: { patient, image }
  };
}
