import React, { useEffect, useState } from "react";
import Api from "services/api";

import DataConsult from "components/DataConsult";
import BarProgress from "components/BarProgress";
import DoctorDatails from "components/DoctorDatails";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan } from "components/Button";
import ProfileNew from "components/ComponentsProfile/ProfileNew";

export default function ViewAppointment({ appointment, open, handleClose }) {
  const [dataConsult, setDataConsult] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (appointment.id) {
      getDataConsult();
    }
  }, [appointment.id]);

  const getDataConsult = () => {
    setLoading(true);
    Api.post(`/microservice/consult/getHistoryAppointment/`, {
      appointment_id: appointment.id,
      doctor: appointment.doctor,
      patient: appointment.patient,
    })
      .then(({ data }) => {
        setDataConsult(data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <ModalResponsive
      open={open}
      close={handleClose}
      maxWidth="md"
      title="Visualizar Atendimento"
      buttons={
        <div className="col-12">
          <BtnCan title="Fechar" onClick={() => handleClose()} float="left" />
        </div>
      }
    >
      <>
        {loading && <BarProgress />}
        {!loading && dataConsult && (
          <div>
            <div className="col-12 m-0 p-0 pb-3">
              <h4
                className="text-primary"
                style={{
                  width: "100%",
                  // justifyContent: "space-between",
                }}
              >
                Paciente
              </h4>

              <ProfileNew
                profile={{
                  id: appointment.patient,
                  name: appointment.patient_name,
                  photo: appointment.photo,
                  birthday: appointment.patient_birthday,
                  mother_name: appointment.mother_name,
                  cpf: appointment.cpf,
                  cns: appointment.cns,
                }}
              />
            </div>
            <div className="col-12 m-0 p-0">
              {/* <center> */}
              <h4
                className="text-primary"
                style={{
                  width: "100%",
                  // justifyContent: "space-between",
                }}
              >
                Dados do Profissional
              </h4>
              {/* </center> */}
              <DoctorDatails
                nome={dataConsult.appointment.professional}
                area={dataConsult.appointment.name_area}
                photo={dataConsult.photo}
                specialitys={dataConsult.appointment.specialitys}
                council={dataConsult.council}
                date={dataConsult.appointment.date_of_occurrence}
                address={dataConsult.address_appointment.address}
              />
            </div>
            <DataConsult dataConsult={dataConsult} />
          </div>
        )}
      </>
    </ModalResponsive>
  );
}
