import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "services/api";
import FormProcedure from "./FormProcedure";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { BtnAdd } from "components/Button";
import NoRecord from "components/Alert/NoRecord";
import BarProgress from "components/CircularProgress/ProgressBar";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";

export default function Procedures({ type }) {
  const [modalAdd, setModalAdd] = useState(false);
  const [dataModal, setDataModal] = useState({ id: "" });
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [record, setRecord] = useState([]);
  const [loader, setLoader] = useState(true);
  const user = useSelector((state) => state.auth.authUser);
  const appointment = useSelector((state) => state.appointment.id);
  const patient = useSelector((state) => state.appointment.patient);
  const saved = useSelector((state) => state.appointment.saved.procedure);
  const save_mention = useSelector(
    (state) => state.attendanceRecord.save_mention_procedure
  );

  useEffect(() => {
    if (type === "history") {
      getProcedures();
    } else if (appointment && saved === "1") {
      getProcedures();
    } else {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    if (parseInt(save_mention) > 0) {
      getProcedures();
    }
  }, [save_mention]);

  const getProcedures = () => {
    Api.post(`/microservice/consult/getPersonalProcedures`, {
      appointment,
      patient,
      type,
    })
      .then(({ data }) => {
        if (data.success) {
          setRecord(data.procedure);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const removeProcedure = () => {
    Api.post(`/microservice/consult/removeProcedure/`, {
      id: dataModal.id,
      user,
    })
      .then(() => {
        setRecord(record.filter((row) => row.id !== dataModal.id));
        setDataModal({ id: "" });
        setModalDelete(false);
        setSuccessDelete(true);
      })
      .catch(() => {
        setModalDelete(false);
        setDataModal({ id: "" });
        alert("Ocorreu um erro! Tente novamente.");
      });
  };

  const handleAdd = (obj, new_obj) => {
    if (new_obj) {
      setRecord([...record, obj]);
    } else {
      setRecord(record.map((row) => (row.id === obj.id ? obj : row)));
    }
    setModalAdd(false);
    setDataModal({ id: "" });
  };

  return (
    <div className="row w-100">
      <div className="col-12">
        <BtnAdd
          title="Adicionar procedimento"
          onClick={() => setModalAdd(true)}
          float="right"
        />
      </div>

      {loader ? (
        <div className="col-12 d-flex justify-content-center m-2">
          <BarProgress />
        </div>
      ) : (
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          {record.length === 0 ? (
            <NoRecord />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Procedimento</TableCell>
                  <TableCell>Motivo</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Internação</TableCell>
                  <TableCell>Observações</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.procedure_description}</TableCell>
                    <TableCell>{row.reason}</TableCell>
                    <TableCell>
                      {row.date ? moment(row.date).format("DD/MM/YYYY") : ""}
                    </TableCell>
                    <TableCell>
                      {row.hospitalization === "1"
                        ? `Sim ${
                            row.hospitalization_start
                              ? ` (${moment(row.hospitalization_start).format(
                                  "DD/MM/YYYY"
                                )} - ${
                                  row.hospitalization_end
                                    ? moment(row.hospitalization_end).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "Atual"
                                })`
                              : ""
                          }`
                        : "Não"}
                    </TableCell>
                    <TableCell>{row.observation}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Editar procedimento" placement="top">
                        <IconButton
                          onClick={() => {
                            setModalAdd(true);
                            setDataModal(row);
                          }}
                          color="primary"
                          aria-label="Editar"
                        >
                          <i className="zmdi zmdi-edit zmdi-hc-fw" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remover procedimento" place="top">
                        <IconButton
                          aria-label="Delete"
                          color="secondary"
                          onClick={() => {
                            setDataModal(row);
                            setModalDelete(true);
                          }}
                        >
                          <i className="zmdi zmdi-delete" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      )}
      <FormProcedure
        record={dataModal}
        type={type}
        open={modalAdd}
        handleClose={() => setModalAdd(false)}
        handleAdd={handleAdd}
      />
      <SweetAlert
        show={modal_delete}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Remover o registro deste procedimento?"
        onConfirm={removeProcedure}
        onCancel={() => setModalDelete(false)}
      />
      <SweetAlert
        show={success_delete}
        success
        title="Registro removido com sucesso!"
        onConfirm={() => setSuccessDelete(false)}
      />
    </div>
  );
}
