import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const OnlineScheduler = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Route
          path={`${match.url}/`}
          exact
          component={asyncComponent(() => import("./routes/stepper"))}
        />
        <Route
          path={`${match.url}/confirm`}
          exact
          component={asyncComponent(() => import("./routes/confirm"))}
        />
      </Switch>
    </div>
  );
};

export default OnlineScheduler;
