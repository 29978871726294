import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import NoRecord from "components/Alert/NoRecord";
import { connect } from "react-redux";
import ViewGeneralObj from "./viewGeneralObj";
// import ViewConsult from "./ViewConsult";
// import teste from "../../../../public/vendors/";

import "./styles.css";
import Api from "services/api";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 10,
  },
  table: {
    minWidth: 700,
  },
  borderStyle: {
    border: "1px solid #dadce0",
    borderRadius: "4px",
  },
});

class GeneralObjective extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    rows: [],
    mode_list: true,
    id_consult: "",
  };

  componentDidMount() {
    Api.post(`/microservice/odGeneralObjective/getInfoForHistory/`, {
      user: this.props.patient,
    })
      .then((res) => {
        this.setState({ rows: res.data });
      })
      .catch(() => {});
  }

  handleClose = () => {
    this.setState({ mode_list: true });
  };

  viewConsult(data) {
    this.setState({ mode_list: false, id_consult: data });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { rows, rowsPerPage, page, mode_list, id_consult } = this.state;
    const { classes } = this.props;
    return (
      <div className={`container-person ${classes.borderStyle}`}>
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400",
            // borderBottom: "2px solid #177493"
          }}
        >
          Objetivo Geral
          {!mode_list ? (
            <IconButton
              onClick={() => this.handleClose()}
              color="primary"
              aria-label="Visualizar"
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-undo" />
            </IconButton>
          ) : (
            ""
          )}
        </h3>
        {mode_list ? (
          rows.length > 0 ? (
            <div
              className="col-12"
              style={{
                minWidth: "650",
                overflowX: "auto",
              }}
            >
              <Table className="">
                <TableHead>
                  <TableRow>
                    <TableCell>Profissional</TableCell>
                    <TableCell align="left">Data</TableCell>
                    <TableCell align="right">Visualizar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n) => {
                      return (
                        <TableRow hover tabIndex={-1} key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.name}
                          </TableCell>
                          <TableCell align="left">
                            {" "}
                            {n.date_of_occurrence}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              className="m-0 p-0"
                              onClick={() => this.viewConsult(n.id)}
                              color="primary"
                              aria-label="Visualizar"
                            >
                              <i className="zmdi zmdi-eye zmdi-hc-fw" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Registros por página"
                      labelDisplayedRows={({ from, to, count }) =>
                        `Mostrando ${from} - ${to} de ${count}`
                      }
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          ) : (
            <NoRecord />
          )
        ) : (
          <ViewGeneralObj
            appointment={id_consult}
            doctor={this.props.doctor}
            onClose={this.handleClose.bind(this)}
          />
        )}{" "}
      </div>
    );
  }
}

GeneralObjective.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    patient: state.appointment.patient,
  };
};

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(withStyles(styles)(GeneralObjective));

// export default withStyles(styles)(GeneralObjective);
