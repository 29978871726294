import React from "react";
import { CardBody } from "reactstrap";
import NoRecord from "components/Alert/NoRecord";
import ComponentsProfile from "components/ComponentsProfile";
import DataTable from "components/Tables/DataTable";
import BarProgress from "components/CircularProgress/ProgressBar";
import { Tooltip, IconButton, Chip } from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import { BtnCan } from "components/Button";
import { ModalResponsive } from "components/ModalResponsive";
import IconBlock from "@material-ui/icons/Block";
import Api from "services/api";
import SearchComponent from "components/SearchComponent";

export default function ProviderProfessionals({
  address,
  professionals,
  loaded
}) {
  const [modalHistory, setModalHistory] = React.useState({
    open: false,
    professional: "",
    record: []
  });
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    if (modalHistory.professional) {
      Api.post("ms_homecare/professional/getHistoryProfessional", {
        address,
        professional: modalHistory.professional
      }).then(res => {
        setModalHistory({
          ...modalHistory,
          record: res.data.record
        });
      });
    }
  }, [modalHistory.professional]);

  const handleCloseModal = () =>
    setModalHistory({
      open: false,
      professional: "",
      record: []
    });

  const formatString = value => {
    return value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  return (
    <div className="col-12 m-0 p-0 animated slideInUpTiny animation-duration-4">
      <CardBody>
        {loaded ? (
          <div className="col-12 m-0 p-0">
            <h2 className="font-weight-semibold d-flex">
              <span className="mt-3 mr-5">Profissionais</span>
              <SearchComponent searchContact={e => setSearch(e)} />
            </h2>
            {professionals.length > 0 ? (
              <DataTable
                // name="Profissionais"
                columns={["#", "Nome", "Ações"]}
                rows={professionals
                  .filter(
                    row =>
                      search.length === 0 ||
                      formatString(row.name).includes(formatString(search))
                  )
                  .map((e, index) => {
                    return [
                      index + 1,
                      [
                        <ComponentsProfile
                          nameProfile={e.name}
                          subTitle={e.specialitys}
                          photo={e.photo}
                          id={e.id}
                        />
                      ],
                      [
                        <Tooltip
                          title="Histórico do profissional"
                          placement="top"
                        >
                          <IconButton
                            style={{ padding: "6px" }}
                            onClick={() =>
                              setModalHistory({
                                open: true,
                                professional: e.id,
                                record: []
                              })
                            }
                          >
                            <HistoryIcon
                              style={{
                                fontSize: "19px",
                                margin: "0px",
                                padding: "0px"
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ]
                    ];
                  })}
                download={false}
                print={false}
                hideSearch={true}
                rowsPerPage={5}
                height="100%"
              />
            ) : (
              <NoRecord />
            )}
          </div>
        ) : (
          <div className="col-12 d-flex justify-content-center m-2">
            <BarProgress />
          </div>
        )}
      </CardBody>
      <ModalResponsive
        title="Histórico do Profissional"
        open={modalHistory.open}
        close={handleCloseModal}
        buttons={
          <div className="col-12">
            <BtnCan title="fechar" onClick={handleCloseModal} float="left" />
          </div>
        }
      >
        <DataTable
          columns={["Paciente", "Próximo de", "Entrada", "Saída"]}
          rows={modalHistory.record.map((e, index) => {
            return [
              [
                <ComponentsProfile
                  nameProfile={e.name}
                  subTitle={e.birthday}
                  photo={e.photo}
                  id={e.id}
                />
              ],
              e.display_location
                ? e.display_location
                : [
                    <Chip
                      size="small"
                      color="secondary"
                      icon={<IconBlock />}
                      label="Não informado"
                    />
                  ],
              e.start,
              e.end ? e.end : "Não registrado."
            ];
          })}
          download={false}
          print={false}
          rowsPerPage={5}
          height="100%"
        />
      </ModalResponsive>
    </div>
  );
}
