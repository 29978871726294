import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import "./style.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 500
  }
}));

export default function TableHistoryFold({ name, listDetails, onClose, date }) {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };
  return (
    <div className="row">
      <div className="col-12">
        {listDetails && (
          <>
            <Typography variant="h6" gutterBottom>
              {name}
            </Typography>
            <span className="font-italic">{date}</span>

            <div
              className="col-12"
              style={{
                minWidth: "650",
                overflowX: "auto"
              }}
            >
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className="m-0 p-0">Subescapular</TableCell>
                    <TableCell className="m-0 p-0">Supra Ilíaca</TableCell>
                    <TableCell className="m-0 p-0">Tricipital</TableCell>
                    <TableCell className="m-0 p-0">dc_bicipital</TableCell>
                    <TableCell className="m-0 p-0">Abdominal</TableCell>
                    <TableCell className="m-0 p-0">Coxa Medial</TableCell>
                    <TableCell className="m-0 p-0">Peitoral</TableCell>
                    <TableCell className="m-0 p-0">Axilar Medial</TableCell>
                    <TableCell className="m-0 p-0">Pant. Medial</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ padding: "0px" }}>
                  {listDetails.map((row, index) => (
                    <>
                      <TableRow key={index} className="p-0">
                        <TableCell className="table-root">
                          {row.dc_subescapular === null
                            ? "-"
                            : row.dc_subescapular}
                        </TableCell>
                        <TableCell className="table-root">
                          {row.dc_supra_iliaca === null
                            ? "-"
                            : row.dc_supra_iliaca}
                        </TableCell>
                        <TableCell className="table-root">
                          {row.dc_tricipital === null ? "-" : row.dc_tricipital}
                        </TableCell>
                        <TableCell className="table-root">
                          {row.dc_bicipital === null ? "-" : row.dc_bicipital}
                        </TableCell>
                        <TableCell className="table-root">
                          {row.dc_abdominal === null ? "-" : row.dc_abdominal}
                        </TableCell>
                        <TableCell>
                          {row.dc_coxa_medial === null
                            ? "-"
                            : row.dc_coxa_medial}
                        </TableCell>
                        <TableCell>
                          {row.dc_peitoral === null ? "-" : row.dc_peitoral}
                        </TableCell>
                        <TableCell>
                          {row.dc_axilar_medial === null
                            ? "-"
                            : row.dc_axilar_medial}
                        </TableCell>
                        <TableCell>
                          {row.dc_panturrilha_medial === null
                            ? "-"
                            : row.dc_panturrilha_medial}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}

        <div>
          <Button onClick={handleClose} color="primary" aria-label="Voltar">
            <i className="zmdi zmdi-undo" /> Voltar
          </Button>
        </div>
      </div>
    </div>
  );
}
