import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, showMsg } from "services/functions";
import AutocompleteGeneric from "components/Autocomplete/AutocompleteGeneric";
import Api from "services/api";

export default function SearchProcedures({ onChange, value, procedure }) {
  const dispatch = useDispatch();
  const area = useSelector((state) => state.auth.area);
  const provider_type = useSelector(
    (state) => state.auth.address_selected.provider_type
  );

  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);

  const getProcedures = async (term) => {
    if (term.length > 2) {
      setLoader(true);
      try {
        const { data } = await Api.post(
          "/microservice/autocomplete/getProcedures/",
          {
            term,
            area,
            provider_type,
          }
        );
        setOptions(data);
      } catch (err) {
        showMsg(
          "error",
          "Não foi possível encontrar resultados para esta busca",
          dispatch
        );
        setOptions([]);
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <AutocompleteGeneric
      multiple={false}
      limitTags={1}
      filterOptions={(x) => x}
      disableCloseOnSelect
      onChange={onChange}
      onInputChange={debounce(getProcedures)}
      value={
        value
          ? {
              id: procedure,
              description: value,
            }
          : null
      }
      getOptionLabel={(option) => option.description}
      label="Procedimento:"
      options={options}
      loading={loader}
      blurOnSelect={true}
      clearOnBlur={true}
      shrink={true}
    />
  );
}
