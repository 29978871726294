import React, { useState, useEffect } from "react";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Tooltip from "@material-ui/core/Tooltip";
import Api from "services/api";
import SweetAlert from "react-bootstrap-sweetalert";

function InfoRegister({
  id,
  type,
  form,
  patient,
  added,
  approved,
  created,
  handleValidate,
}) {
  const [name, setName] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  useEffect(() => {
    if (patient === added) {
      if (approved) {
        getName(approved);
      }
    } else {
      getName(added);
    }
  });

  const getName = (user) => {
    Api.post("microservice/consult/getInfoRegister", {
      user,
    }).then((res) => setName(res.data.name));
  };

  const validRegister = () => {
    Api.post("microservice/consult/validRegister", {
      id,
      type,
    }).then(() => {
      setModalConfirm(false);
      setModalSuccess(true);
      handleValidate(id);
    });
  };

  const mountTitle = () => {
    if (patient === added) {
      return `Inserido ${
        form === "pp" ? "por você" : "pelo paciente"
      } em ${created} ${approved ? "e aprovado por " + name : ""}.`;
    } else {
      return `Inserido por ${name} em ${created}`;
    }
  };

  if (patient === added) {
    return (
      <>
        {!approved && form !== "pp" && (
          <>
            <Tooltip placement="top" title="Validar Registro">
              <CheckCircleIcon
                color="secondary"
                style={{
                  color: "green",
                  height: "auto",
                  marginBottom: type === "medicines" ? "-10px" : "",
                }}
                onClick={() => setModalConfirm(true)}
              />
            </Tooltip>
            <SweetAlert
              show={modalConfirm}
              warning
              showCancel
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              confirmBtnText="Sim, validar"
              confirmButtonColor="#bc0003"
              cancelBtnText="Cancelar"
              title="Validar esta informação?"
              onConfirm={validRegister}
              onCancel={() => setModalConfirm(false)}
            />
          </>
        )}
        <Tooltip placement="top" title={mountTitle()}>
          <InfoIcon
            color={approved ? "primary" : "default"}
            style={{
              color: approved ? "#177493" : "#797373",
              height: "auto",
              marginBottom: type === "medicines" ? "-10px" : "",
            }}
          />
        </Tooltip>
        <SweetAlert
          show={modalSuccess}
          success
          title="Validado com sucesso!"
          onConfirm={() => setModalSuccess(false)}
        />
      </>
    );
  } else {
    return (
      <Tooltip placement="top" title={mountTitle()}>
        <InfoIcon color="primary" style={{ height: "auto" }} />
      </Tooltip>
    );
  }
}

export default InfoRegister;
