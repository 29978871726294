import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { SingleSelect } from "react-select-material-ui";

function FormSpecialty({ data, addressId, close, add, edit }) {
  const [area, setArea] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [areaOpts, setAreaOpts] = useState([]);
  const [specialtyOpts, setSpecialtyOpts] = useState([]);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    Api.post(`/microservice/autocomplete/getArea/`).then((res) => {
      let arr = res.data.map((row) => ({
        value: row.id,
        label: `${row.area_name} - ${row.name}`,
      }));
      setAreaOpts(arr);
    });
  }, []);

  useEffect(() => {
    Api.post(`/microservice/autocomplete/getSpecialty/`, { area }).then(
      (res) => {
        let result = res.data.map((row) => ({
          value: row.id,
          label: row.specialitys,
        }));
        setSpecialtyOpts(result);
      }
    );
  }, [area]);

  function save() {
    if (area && specialty) {
      Api.post(`/ms_system/workplace/saveSpecialty/`, {
        addressId,
        specialty,
      })
        .then((res) => {
          let info_specialty = specialtyOpts.find(
            (row) => row.value === specialty
          );
          add({
            id: res.data.id,
            area_id: area,
            specialty: info_specialty.label,
            specialty_id: specialty,
          });
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
          close();
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage(), 5000));
        });
    } else {
      setError(true);
    }
  }

  console.log("areaOpts", areaOpts);
  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <h2 className="text-primary font-weight-semibold">
          Especialidades
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={() => close()}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
      </div>
      {error && (
        <div className="col-12 alert alert-danger text-md-center">
          Informe a área e a especialidade
        </div>
      )}

      <div className="col-12">
        <SingleSelect
          label="Área:"
          options={areaOpts}
          value={area}
          onChange={(e) => setArea(e)}
          SelectProps={{
            isCreatable: false,
          }}
          error={error && !area}
        />
      </div>
      <div className="col-12">
        <SingleSelect
          label="Especialidade:"
          options={specialtyOpts}
          value={specialty}
          onChange={(e) => setSpecialty(e)}
          SelectProps={{
            isCreatable: false,
          }}
          style={{ marginTop: "10px" }}
          error={error && !specialty}
        />
      </div>

      <div className="col-md-12 mt-5">
        <Button
          color="secondary"
          variant="contained"
          className="jr-btn jr-btn-sm"
          onClick={() => close()}
          style={{ float: "left" }}
        >
          <i className="zmdi zmdi-close zmdi-hc-lg" />
          <span> Cancelar </span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          className="jr-btn jr-btn-sm bg-success"
          onClick={() => save()}
          style={{ float: "right" }}
        >
          <i className="zmdi zmdi-check zmdi-hc-lg" />
          <span> Salvar </span>
        </Button>
      </div>
    </div>
  );
}

export default FormSpecialty;
