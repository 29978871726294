import React from "react";
import { Card } from "reactstrap";
import Api from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { setListAddress } from "actions/Auth";
import Provider from "../../components/provider";
import Finance from "../../components/finance";
// import News from "../../components/news";

export default function Prestador({ match }) {
  // const dispatch = useDispatch();
  const provider = useSelector((state) => state.auth.authUser);
  const loaded_address = useSelector((state) => state.auth.loaded_address);
  const [state, setState] = React.useState({ dadosDash: [] });
  const dispatch = useDispatch();
  React.useEffect(() => {
    getDashProfessional();
  }, []);

  const getDashProfessional = async () => {
    let dadosGetProf = {
      provider,
      index: "1",
      loaded_address,
    };
    const data = await Api.post(
      `/ms_system/dashboard/getRecordToDashProviderFinance`,
      dadosGetProf
    );
    const response = await data.data;
    setState({ dadosDash: response, loaded: true });
    if (!loaded_address) {
      dispatch(setListAddress(response.address));
    }
  };
  return (
    <div className="row col-12 d-flex justify-content-center ml-1">
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-1 justify-content-center m-0 p-0">
        <Card className="shadow border-0 mb-3 mt-1">
          <Provider provider={provider} />
        </Card>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-1 justify-content-center">
        <Finance dadosDash={state.dadosDash} />
        {/* <News match={match} /> */}
      </div>
    </div>
  );
}
