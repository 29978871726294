import React from "react";
import { MenuItem, TextField, Chip } from "@material-ui/core";

function SelectWithChip({
  label,
  selecteds,
  handleChange,
  handleDelete,
  options,
  helperText,
}) {
  return (
    <>
      <TextField
        select
        fullWidth
        label={label}
        value=""
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        helperText={helperText ? helperText : ""}
      >
        {options
          .filter((opt) => selecteds.indexOf(opt) === -1)
          .map((row) => (
            <MenuItem key={row} value={row}>
              {row}
            </MenuItem>
          ))}
      </TextField>
      {selecteds.map((sel) => (
        <Chip
          label={sel}
          onDelete={() => handleDelete(sel)}
          color="default"
          className="mt-3 ml-1"
        />
      ))}
    </>
  );
}

export default SelectWithChip;
