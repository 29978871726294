import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Settings from "./Settings";
import Alerts from "./Alerts";

// Usuario logado
import Auth from "./Auth";

// Consultório
import Habits from "./consult/Habits";
import Blog from "./Blog";
import Forward from "./consult/Forward";
import Procedure from "./consult/Procedure";
import PatientInfo from "./consult/PatientInfo";
import PersonalData from "./consult/PersonalData";
import Dysfunctions from "./consult/Dysfunctions";
import Vaccines from "./consult/Vaccines";
import Appointment from "./consult/Appointment";
import AttendanceRecord from "./consult/AttendanceRecord";
import Circumferences from "./consult/Circumferences";
import Aga from "./consult/Aga";
import Prescription from "./consult/Prescription";
import PrescriptionManipulated from "./consult/PrescriptionManipulated";
import PainScale from "./consult/PainScale";
import Print from "./Print";
import Forms from "./Form";
import Tags from "./consult/Tags";
import ComparePictures from "./consult/ComparePictures";
// Consultório Odontologia
import Odonto from "./consult/Odonto";
import PlateIndex from "./consult/odonto/PlateIndex";
import DiagnosticIntegral from "./consult/odonto/DiagnosticIntegral";
// Consultório Nutrição
import FoodReminder from "./consult/nutrition/FoodReminder";
import FoodPlan from "./consult/nutrition/FoodPlan";

// Financeiro
import Finance from "./Finance";

// Configuração
import TagsConditions from "./configuration/TagsConditions";
import MedicinesGroups from "./configuration/MedicinesGroups";
import AddProcedure from "./configuration/AddProcedure";
import ProcedureGroups from "./configuration/ProcedureGroups";
import TypeEvent from "./configuration/TypeEvent";
import Profile from "./configuration/Profile";

import Configuration from "./Configuration";

// Evolução
import FamilyAccompaniment from "./evolution/Accompaniment";
import EvolutionPersonal from "./evolution/Personal";
import EvolutionGroup from "./evolution/Group";
import EvolutionDaily from "./evolution/Daily";

// Local de atendimento
import Workplace from "./Workplace";

// Dashboard
import Dashboard from "./Dashboard";
// Agenda
import Calendar from "./Calendar";

//Tbca
import Tbca from "./consult/Tbca";

//intranet
import Intranet from "./Intranet";

//OnlineScheduler
import OnlineScheduler from "./OnlineScheduler";

// Ficha de admissão
import Admission from "./admission/Admission";
//Comunicação
import Chat from "./comunication/Chat";

//Extrator de dados
import Extract from "./Extract";

// Management Professional
import ManagementProfessional from "./ManagementProfessional";

// Permissions
import Permissions from "./Permissions";

// Estoque
import Stock from "./Stock";

// HomeCare
import HomecareProfessional from "./homecare/HomecareProfessional";
import HomecareManager from "./homecare/HomecareManager";

//Configuration Custom
import SystemReviewForms from "./configuration/Custom/SystemReviewForms";

// Monitoramento Covid
import Monitoring from "./Monitoring";

const appointmentPersist = {
  key: "appointment",
  storage: storage,
};

const authPersist = {
  key: "auth",
  storage: storage,
};

const financePersist = {
  key: "finance",
  storage: storage,
};

const patientInfoPersist = {
  key: "patientInfo",
  storage: storage,
};

const familyAccompanimentPersist = {
  key: "familyAccompaniment",
  storage: storage,
};

const evolutionPersonalPersist = {
  key: "evolutionPersonal",
  storage: storage,
};

const evolutionGroupPersist = {
  key: "evolutionGroup",
  storage: storage,
};

const intranetPersist = {
  key: "intranet_id",
  storage: storage,
};

const configurationPersist = {
  key: "configuration",
  storage: storage,
};

const admissionPersist = {
  key: "admission",
  storage: storage,
};

const chatPersist = {
  key: "chat",
  storage: storage,
};

const evolutionDailyPersist = {
  key: "evolutionDaily",
  storage: storage,
};

const permissionsPersist = {
  key: "permissions",
  storage: storage,
};

const homecareManagerPersist = {
  key: "homecareManager",
  storage: storage,
};

const homecareProfessionalPersist = {
  key: "homecareProfessional",
  storage: storage,
};

const profilePersist = {
  key: "Profile",
  storage: storage,
};

const systemReviewFormsPersist = {
  key: "SystemReviewForms",
  storage: storage,
};

const monitoringPersist = {
  key: "Monitoring",
  storage: storage,
};

const stockPersist = {
  key: "Stock",
  storage: storage,
};

export default (history) =>
  combineReducers({
    auth: persistReducer(authPersist, Auth),
    permissions: persistReducer(permissionsPersist, Permissions),
    appointment: persistReducer(appointmentPersist, Appointment),
    patientInfo: persistReducer(patientInfoPersist, PatientInfo),
    evolutionDaily: persistReducer(evolutionDailyPersist, EvolutionDaily),
    prescription: Prescription,
    finance: persistReducer(financePersist, Finance),
    configuration: persistReducer(configurationPersist, Configuration),
    form: formReducer,
    alert: Alerts,
    formsOutReduxForm: Forms,
    attendanceRecord: AttendanceRecord,
    habits: Habits,
    forward: Forward,
    procedure: Procedure,
    personalData: PersonalData,
    dysfunctions: Dysfunctions,
    vaccines: Vaccines,
    Profile: persistReducer(profilePersist, Profile),
    aga: Aga,
    circumferences: Circumferences,
    prescriptionManipulated: PrescriptionManipulated,
    painScale: PainScale,
    print: Print,
    tags: Tags,
    comparePictures: ComparePictures,
    //blog
    blog: Blog,
    //consultório odontologia
    odonto: Odonto,
    plateIndex: PlateIndex,
    diagnosticIntegral: DiagnosticIntegral,
    //consultório nutrição
    foodReminder: FoodReminder,
    foodPlan: FoodPlan,
    tagsConditions: TagsConditions,
    MedicinesGroups: MedicinesGroups,
    AddProcedure: AddProcedure,
    ProcedureGroups: ProcedureGroups,
    typeEvent: TypeEvent,
    //Evolução
    familyAccompaniment: persistReducer(
      familyAccompanimentPersist,
      FamilyAccompaniment
    ),
    evolutionPersonal: persistReducer(
      evolutionPersonalPersist,
      EvolutionPersonal
    ),
    evolutionGroup: persistReducer(evolutionGroupPersist, EvolutionGroup),
    //Locais de Atendimento
    workplace: Workplace,
    //Dashboard
    dashboard: Dashboard,
    //Agenda
    calendar: Calendar,
    tbca: Tbca,
    //intranet
    intranet: persistReducer(intranetPersist, Intranet),
    // OnlineScheduler
    onlineScheduler: OnlineScheduler,
    // Ficha de admissão
    admission: persistReducer(admissionPersist, Admission),
    //Comunication
    chat: persistReducer(chatPersist, Chat),
    //Estoque
    stock: persistReducer(stockPersist, Stock),
    // Extrator de dados
    extract: Extract,
    // Gestao Profissional
    managementProfessional: ManagementProfessional,
    // HomeCare
    homecareManager: persistReducer(homecareManagerPersist, HomecareManager),
    homecareProfessional: persistReducer(
      homecareProfessionalPersist,
      HomecareProfessional
    ),
    //configuration custom
    systemReviewForms: persistReducer(
      systemReviewFormsPersist,
      SystemReviewForms
    ),
    monitoring: persistReducer(monitoringPersist, Monitoring),
    router: connectRouter(history),
    settings: Settings,
  });
