export function getPrint(name, id) {
  return {
    type: "GET_PRINT",
    name,
    id
  };
}

export const listStyleFormPrint = state => ({
  type: "LIST_STYLE_FORM_PRINT",
  payload: state
});
