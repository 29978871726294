import { delay } from "redux-saga";
import { all, takeLatest, put, call } from "redux-saga/effects";
import api from "services/api";

const finishAppointment = async (appointment, service_id) => {
  try {
    const response = await api.post(
      `/microservice/consult/finishAppointment/`,
      { appointment, service_id }
    );
    return response.data;
  } catch (e) {}
};

const requestAppointmentActions = async (id_appointment) => {
  const res = await api.post(`/microservice/consult/getAppointmentActions`, {
    id: id_appointment,
  });
  return res.data;
};

function* asyncFinishAppointment(action) {
  try {
    const response = yield call(
      finishAppointment,
      action.appointment,
      action.service_id
    );
    if (response.success) {
      yield put({ type: "CLOSE_MODAL_FINISH" });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Atendimento finalizado com sucesso!",
      });
      yield put({
        type: "RESET_ALL",
        opt: "FINISH",
      });
      yield delay(3000);
      yield put({ type: "CLOSE_ALERT_MESSAGE" });
      yield put({ type: "MODAL_VIDEO", payload: false });
    } else {
      yield put({ type: "FINISH_APPOINTMENT_FAILED" });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao finalizar o atendimento!",
      });
      yield delay(5000);
      yield put({ type: "CLOSE_ALERT_MESSAGE" });
    }
  } catch (error) {}
}

function* asyncPauseAppointment(action) {
  yield put({
    type: "VIEW_ALERT_MESSAGE",
    message_type: "success",
    text_message: action.payload
      ? "Atendimento enviado para a correção"
      : "Atendimento pausado com sucesso!",
  });
  yield put({
    type: "RESET_ALL",
    opt: "FINISH",
  });
  yield delay(5000);
  yield put({ type: "CLOSE_ALERT_MESSAGE" });
  yield put({ type: "MODAL_VIDEO", payload: false });
}
function* alterPatient() {
  yield put({
    type: "RESET_ALL",
    opt: "DEFAULT",
  });
}

function* getAppointmentActions(action) {
  try {
    const res = yield call(requestAppointmentActions, action.payload);
    if (res.success) {
      if (res.actions !== null) {
        yield put({ type: "SET_ACTIONS_APPOINTMENT", payload: res.actions });
      }
    }
  } catch (error) {}
}

export default function* rootSaga() {
  yield all([takeLatest("FINISH_APPOINTMENT", asyncFinishAppointment)]);
  yield all([takeLatest("PAUSE_APPOINTMENT", asyncPauseAppointment)]);
  yield all([takeLatest("ALTER_PATIENT", alterPatient)]);
  yield all([takeLatest("GET_APPOINTMENT_ACTIONS", getAppointmentActions)]);
}
