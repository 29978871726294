import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  AddNewTag,
  setInitialConditions
} from "actions/configuration/TagsConditions";

//import components style material
import { Button, IconButton, Tooltip } from "@material-ui/core";
import IntlMessages from "util/IntlMessages";

//import components Tags
import ViewTags from "./viewTags";
import FormTags from "./formTags";

class Tags extends Component {
  state = {
    button: {
      color: "primary",
      active: "view",
      text: "Adicionar"
    }
  };

  handleChangeButton = () => {
    if (this.state.button.active === "view") {
      this.setState({
        button: {
          color: "secondary",
          text: "Cancelar",
          active: "add"
        }
      });
    } else {
      this.setState({
        button: {
          color: "primary",
          active: "view",
          text: "Adicionar"
        }
      });
    }
  };
  AddNewTag = () => {
    this.props.AddNewTag();
    this.handleChangeButton();
  };
  render() {
    const { color, text, active } = this.state.button;
    return (
      <div className="row">
        <h2 className="text-primary font-weight-semibold w-100">
          <IntlMessages
            id={
              active === "view"
                ? "module.configuration.tags"
                : "module.configuration.add_tags"
            }
          />
          <Tooltip title="Fechar" placement="top">
            <IconButton
              aria-label="Fechar"
              onClick={this.props.close}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <i className="zmdi zmdi-close" />
            </IconButton>
          </Tooltip>
        </h2>
        {active === "view" && (
          <div className="col-12">
            <Button
              variant="contained"
              color={color}
              className="jr-btn jr-btn-sm"
              onClick={this.AddNewTag}
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-plus" />
              <span>{text}</span>
            </Button>
          </div>
        )}
        {active === "view" ? (
          <ViewTags handleChangeButton={this.handleChangeButton} />
        ) : (
          <FormTags handleChangeButton={this.handleChangeButton} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ AddNewTag, setInitialConditions }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(Tags);
