const INIT_STATE = {
  manager: false,
  approver: false,
  master: false,
  mdl_admission: false,
  mdl_evolution: false,
  manager_patient: false,
  shift_change: false,
  prescription_care: false,
  print_prescription_med: false,
};
export default function Permissions(state = INIT_STATE, action) {
  switch (action.type) {
    case "SET_ALL_PERMISSION":
      return {
        ...state,
        [`${action.field}`]: action.value,
      };
    case "SET_MANAGMENT_PERMISSIONS":
      return {
        ...state,
        manager: action.payload.manager,
        approver: action.payload.approver,
        master: action.payload.master,
        mdl_admission: action.payload.mdl_admission,
        mdl_evolution: action.payload.mdl_evolution,
        manager_patient: action.payload.manager_patient,
        shift_change: action.payload.shift_change,
        prescription_care: action.payload.prescription_care,
        print_prescription_med: action.payload.print_prescription_med,
      };
    case "RESET_ALL_PERMISSIONS":
      return {
        ...INIT_STATE,
      };
    default:
      return state;
  }
}
