import { all, takeLatest, call, put } from "redux-saga/effects";
import Api from "services/api";

const insGroupIntranet = async (data) => {
  try {
    const response = await Api.post(
      `/ms_intranet/intranet/addUserHasGroup/`,
      data
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

function* asyncInsGroupIntranet(action) {
  let data = {
    address: action.payload.address,
    patient: action.payload.patient,
    auth: action.payload.auth,
  };
  try {
    const { photo, name, id, birthday, id_member_in_group } = yield call(
      insGroupIntranet,
      data
    );
    yield put({
      type: "SET_NEW_MEMBER_DAILY",
      payload: {
        photo,
        name,
        id,
        birthday,
        id_member_in_group,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([takeLatest("INSERT_GROUP_INTRANET", asyncInsGroupIntranet)]);
}
