import moment from "moment";

const INITIAL_STATE = {
  list: [],
  accounts: [],
  pattern_transactions: [],
  transactions: [],
  types: [],
  suppliers: [],
  trs_filters: {
    title: "",
    type: "",
    status: "",
    acc_in: "",
    acc_out: "",
    start: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    end: moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
  },
  trs_pending_filters: {
    title: "",
    type: "",
    acc_in: "",
    acc_out: "",
    start: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    end: moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
  },
  filter_report: {
    account: "",
    start: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    end: moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
  },
  index: "",
  loaded: false,
  loaded_transactions: false,
  loaded_pat_transactions: false,
  loaded_sup: false,
};

export default function finance(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_FINANCE":
      return INITIAL_STATE;
    case "ACCESS_FINANCE":
      return {
        ...INITIAL_STATE,
        index: action.id,
      };
    case "LIST_FINANCE_SUCCESS":
      return {
        ...state,
        list: action.payload.record,
        loaded: true,
      };
    case "GET_ACCOUNTS_SUCCESS":
      return {
        ...state,
        accounts: action.payload.record,
        loaded: true,
      };
    case "NEW_ACCOUNT":
      return {
        ...state,
        accounts: [...state.accounts, action.form],
      };
    case "EDIT_ACCOUNT":
      let new_accs = state.accounts.map((row) =>
        row.id === action.form.id ? action.form : row
      );
      return {
        ...state,
        accounts: new_accs,
      };
    case "DELETE_ACCOUNT":
      let accs = state.accounts.filter((row) => row.id !== action.payload.id);
      return {
        ...state,
        accounts: accs,
      };
    case "GET_SUPPLIERS_SUCCESS":
      return {
        ...state,
        suppliers: action.payload.record,
        loaded_sup: true,
      };
    case "GET_TRANSACTION_SUCCESS":
      return {
        ...state,
        transactions: action.payload.record,
        loaded_transactions: true,
      };
    case "GET_PATTERN_TRANSACTION_SUCCESS":
      return {
        ...state,
        pattern_transactions: action.payload.record,
        loaded_pat_transactions: true,
      };
    case "GET_TYPES_SUCCESS":
      return {
        ...state,
        types: action.payload.record,
      };
    case "NEW_FINANCE":
      return {
        ...state,
        list: [...state.list, { ...action.payload }],
      };
    case "EDIT_FINANCE":
      let newList = state.list.map((row) =>
        row.id === action.payload.id ? { ...action.payload } : row
      );
      return {
        ...state,
        list: newList,
      };
    case "REMOVE_FINANCE":
      let listDel = state.list.filter((row) => row.id !== action.payload.id);
      return {
        ...state,
        list: listDel,
      };
    case "NEW_SUPPLIER":
      return {
        ...state,
        suppliers: [...state.suppliers, action.payload],
      };
    case "EDIT_SUPPLIER":
      let newSup = state.suppliers.map((row) =>
        row.id === action.payload.id ? { ...action.payload } : row
      );
      return {
        ...state,
        suppliers: newSup,
      };
    case "REMOVE_SUPPLIER":
      let supDel = state.suppliers.filter(
        (row) => row.id !== action.payload.id
      );
      return {
        ...state,
        suppliers: supDel,
      };
    case "TRANSACTION_PENDING_SUCCESS":
      let tranPending = state.transactions.map((row) =>
        row.id === action.payload.id
          ? { ...row, status: "0", final_date: "", final_value: "0,00" }
          : row
      );
      return {
        ...state,
        transactions: tranPending,
      };
    case "FILTER_TRANSACTION":
      return {
        ...state,
        trs_filters: action.filter,
      };
    case "FILTER_TRANSACTION_PENDING":
      return {
        ...state,
        trs_pending_filters: action.filter,
      };
    case "FILTER_REPORT":
      return {
        ...state,
        filter_report: action.filter,
      };
    case "REMOVE_TRANSACTION":
      let rmTr = state.transactions.map((row) =>
        row.id === action.payload.id ? { ...row, active: "0" } : row
      );
      return {
        ...state,
        transactions: rmTr,
      };
    case "RESTAURE_TRANSACTION":
      let resTr = state.transactions.map((row) =>
        row.id === action.payload.id ? { ...row, active: "1" } : row
      );
      return {
        ...state,
        transactions: resTr,
      };
    case "REMOVE_PATTERN_TRANSACTION":
      let rmPatTrx = state.pattern_transactions.filter(
        (row) => row.id !== action.payload.id
      );
      return {
        ...state,
        pattern_transactions: rmPatTrx,
      };
    default:
      return state;
  }
}
