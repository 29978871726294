import React, { useState, useEffect } from "react";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import { useSelector } from "react-redux";
import CardChooseAddress from "components/Forms/CardChooseAddress";
import CardViewAddress from "components/Forms/CardViewAddress";
import { Card, CardBody } from "reactstrap";
import Api from "services/api";
import { getFormatOnlyDate } from "services/functions";
import DataTable from "components/Tables/DataTable";
import ComponentsProfile from "components/ComponentsProfile";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ModalPointRegister from "../professional/management/modalPointRegister";

export default function ManagePointRegister({ match }) {
  const address = useSelector((state) => state.auth.address);
  const [record, setRecord] = useState([]);
  const [view, setView] = useState({
    open: false,
  });
  const date = new Date();

  useEffect(() => {
    if (address) {
      Api.post("/ms_system/registerpoint/getProfessionals/", {
        address,
        date: getFormatOnlyDate(date),
      }).then((res) => {
        setRecord(res.data.record);
      });
    }
  }, [address]);

  return (
    <div className="animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        title={<IntlMessages id="module.point_register" />}
        match={match}
      />
      {!address ? (
        <CardChooseAddress />
      ) : (
        <>
          <CardViewAddress />
          <Card className="shadow border-0 mt-3">
            <CardBody>
              <DataTable
                name="Profissionais"
                columns={["Profissional", "Visualizar"]}
                rows={record.map((row, index) => [
                  <ComponentsProfile
                    id={row.id}
                    nameProfile={row.name}
                    photo={row.photo}
                    subTitle={
                      <>
                        {`Horas trabalhadas nesta semana: ${row.sum_hour_week}hrs`}
                        <br />
                        {`Horas trabalhadas neste mês: ${row.sum_hour_month}hrs`}
                      </>
                    }
                  />,
                  <Tooltip title="Visualizar">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        setView({
                          open: true,
                          ...row,
                        })
                      }
                    >
                      <VisibilityIcon />{" "}
                    </IconButton>
                  </Tooltip>,
                ])}
                download={false}
                print={false}
                hideSearch={true}
              />
            </CardBody>
          </Card>
        </>
      )}
      <ModalPointRegister
        openModal={view.open}
        closeModal={() => setView({ open: false })}
        userId={view.id}
        addressId={address}
        profile={{
          id: view.id,
          name: view.name,
          url: view.photo,
        }}
      />
    </div>
  );
}
