import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Homecare = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} /> */}
      <Route
        path={`${match.url}/manager`}
        component={asyncComponent(() => import("./routes/manager"))}
      />
      <Route
        path={`${match.url}/professional`}
        component={asyncComponent(() => import("./routes/professional"))}
      />
      <Route
        path={`${match.url}/intercurrence`}
        component={asyncComponent(() =>
          import("./routes/professional/intercurrence")
        )}
      />
      <Route
        path={`${match.url}/requests`}
        component={asyncComponent(() =>
          import("./routes/professional/requests")
        )}
      />
      <Route
        path={`${match.url}/reschedule`}
        component={asyncComponent(() =>
          import("./routes/professional/approvedRequest")
        )}
      />
      <Route
        path={`${match.url}/scheduling-patient`}
        component={asyncComponent(() => import("./routes/schedulingPatient"))}
      />

      <Route
        path={`${match.url}/scheduling-professional`}
        component={asyncComponent(() =>
          import("./routes/schedulingProfessional")
        )}
      />

      <Route
        path={`${match.url}/schedule-professional`}
        component={asyncComponent(() =>
          import("./routes/scheduleProfessional")
        )}
      />
      <Route
        path={`${match.url}/final_check`}
        component={asyncComponent(() => import("./routes/finalCheck"))}
      />

      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default Homecare;
