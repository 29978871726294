import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Tooltip
} from "@material-ui/core";
import FormTeam from "./FormTeam";
import ListMembers from "./ListMembers";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";
import DataTable from "components/Tables/DataTable";

function Team({
  user,
  data,
  specialty,
  addressId,
  hiddenButton,
  removeSpecialty
}) {
  const [state, setState] = useState([]);
  const [modal, setModal] = useState("");
  const [modal_delete, setModalDelete] = useState(false);
  const [success_delete, setSuccessDelete] = useState(false);
  const [team_selected, setTeamSelected] = useState("");

  useEffect(() => {
    setState(data);
  }, []);

  const addTeam = obj => {
    setState([...state, obj]);
  };

  const editTeam = obj => {
    setState(
      state.map(row =>
        row.id === obj.id ? { ...row, description: obj.description } : row
      )
    );
  };

  function deleteSpecialty() {
    Api.post(`/ms_system/workplace/deleteSpecialtyAcademic/`, {
      id: specialty.id
    }).then(() => {
      setModalDelete(false);
      setSuccessDelete(true);
      removeSpecialty();
    });
  }

  function deleteTeam() {
    Api.post(`/ms_system/workplace/deleteTeamAcademic/`, {
      id: team_selected.id
    }).then(() => {
      setModalDelete(false);
      setSuccessDelete(true);
      setState(state.filter(row => row.id !== team_selected.id));
    });
  }

  return (
    <div className="col-12 mt-3">
      <h5 className="text-weight-semibold">
        Equipes de atendimento
        <Button
          onClick={() => setModalDelete(true)}
          color="secondary"
          variant="contained"
          aria-label="Remover"
          className="jr-btn jr-btn-sm ml-3"
          style={{ float: "right", marginBottom: "10px" }}
        >
          <i className="zmdi zmdi-delete zmdi-hc-fw" />{" "}
          <span>Remover especialidade</span>
        </Button>
        <Button
          onClick={() => {
            setModal("form");
            setTeamSelected("");
          }}
          className="jr-btn jr-btn-sm"
          color="primary"
          variant="contained"
          style={{ float: "right" }}
          hidden={hiddenButton}
        >
          <i className="zmdi zmdi-plus" /> <span> Adicionar equipe</span>
        </Button>
      </h5>

      <DataTable
        title="Equipes de atendimento"
        columns={["Equipe", "Ações"]}
        rows={state.map(row => {
          return [
            row.description,
            [
              <div style={{ float: "right" }}>
                <Tooltip title="Professores" placement="top">
                  <IconButton
                    onClick={() => {
                      setModal("teacher");
                      setTeamSelected(row);
                    }}
                    color="primary"
                  >
                    <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Estudantes" placement="top">
                  <IconButton
                    onClick={() => {
                      setModal("student");
                      setTeamSelected(row);
                    }}
                    color="primary"
                  >
                    <i className="zmdi zmdi-graduation-cap zmdi-hc-fw" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar" placement="top">
                  <IconButton
                    onClick={() => {
                      setModal("form");
                      setTeamSelected(row);
                    }}
                    color="primary"
                  >
                    <i className="zmdi zmdi-edit zmdi-hc-fw" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remover" placement="top">
                  <IconButton
                    onClick={() => {
                      setModalDelete(true);
                      setTeamSelected(row);
                    }}
                    color="secondary"
                  >
                    <i className="zmdi zmdi-delete zmdi-hc-fw" />
                  </IconButton>
                </Tooltip>
              </div>
            ]
          ];
        })}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={modal !== ""}
        onClose={() => setModal("")}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogContent>
          {modal === "form" && (
            <FormTeam
              user={user}
              record={team_selected}
              addressId={addressId}
              specialty={specialty.id}
              specialty_name={`${specialty.area} - ${specialty.specialty}`}
              close={() => setModal("")}
              add={obj => addTeam(obj)}
              edit={obj => editTeam(obj)}
            />
          )}
          {modal === "teacher" && (
            <ListMembers
              type="teacher"
              group={team_selected.fk_ls_group_intranet_teacher}
              close={() => setModal("")}
            />
          )}
          {modal === "student" && (
            <ListMembers
              type="student"
              group={team_selected.fk_ls_group_intranet_student}
              close={() => setModal("")}
            />
          )}
        </DialogContent>
      </Dialog>
      <SweetAlert
        show={modal_delete}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Sim, excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title={
          team_selected.id
            ? "Deseja realmente excluir esta equipe de atendimento?"
            : "Deseja realmente excluir esta especialidade?"
        }
        onConfirm={() => {
          if (team_selected.id) {
            deleteTeam();
          } else {
            deleteSpecialty();
          }
        }}
        onCancel={() => {
          setTeamSelected("");
          setModalDelete(false);
        }}
      />
      <SweetAlert
        show={success_delete}
        success
        title="Especialidade excluída com sucesso!"
        onConfirm={() => setSuccessDelete(false)}
      />
    </div>
  );
}

export default Team;
