import React, { useState, useEffect } from "react";
import Fab from "@material-ui/core/Fab";
import TooltipMui from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CustomGraphic from "./CustomGraphic";
import ModalPeriod from "./ModalPeriod";
import Api from "services/api";
import moment from "moment";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";
import CircularProgress from "components/CircularProgress";
import { Btn } from "components/Button";
import { useSelector, useDispatch } from "react-redux";

export default function PatientGraphic() {
  const dispatch = useDispatch();
  const width = useSelector((state) => state.settings.width);
  const user = useSelector((state) => state.auth.authUser);

  const [loader, setLoader] = useState(true);
  const [period, setPeriod] = useState("");
  const [modal, setModal] = useState(false);
  const [modalPeriod, setModalPeriod] = useState(false);
  const [state, setState] = useState({
    exams: [],
    custom: {},
    record: [],
    start: moment()
      .subtract(1, "year")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    getData(state.start, state.end);
    dispatch({
      type: "SET_ROUTE",
      payload: "patient-graphic",
    });
  }, []);

  function handleChangePeriod(e) {
    let value = e.target.value;
    let init = moment();
    let last = moment();
    if (value !== "especific") {
      switch (value) {
        case "7":
          init = moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD");
          last = moment().format("YYYY-MM-DD");
          break;
        case "15":
          init = moment()
            .subtract(15, "days")
            .format("YYYY-MM-DD");
          last = moment().format("YYYY-MM-DD");
          break;
        case "30":
          init = moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
          last = moment().format("YYYY-MM-DD");
          break;
        case "60":
          init = moment()
            .subtract(60, "days")
            .format("YYYY-MM-DD");
          last = moment().format("YYYY-MM-DD");
          break;
        case "90":
          init = moment()
            .subtract(90, "days")
            .format("YYYY-MM-DD");
          last = moment().format("YYYY-MM-DD");
          break;
        case "180":
          init = moment()
            .subtract(180, "days")
            .format("YYYY-MM-DD");
          last = moment().format("YYYY-MM-DD");
          break;

        case "365":
          init = moment()
            .subtract(1, "year")
            .format("YYYY-MM-DD");
          last = moment().format("YYYY-MM-DD");
          break;
      }
      setPeriod(value);
      getData(init, last);
    } else {
      setPeriod(value);
    }
  }

  const getData = (start, end) => {
    Api.post("ms_configuration/custom/getPatientGraphic", {
      user,
      start,
      end,
    })
      .then((res) => {
        let record = res.data.record.map((row) => {
          if (row.type === "exam") {
            let aux = [];
            row.data.forEach((el) => {
              let value = el.r1.split("||").map((v) => parseFloat(v));
              let date = el.date.split(",");
              let obj = [];
              for (let i = 0; i < value.length; i++) {
                obj.push({
                  value: value[i],
                  date: date[i],
                });
              }

              aux.push({
                name: el.field_names,
                data: obj.sort((a, b) => {
                  if (
                    moment(convertData(a.date)).isBefore(
                      moment(convertData(b.date))
                    )
                  ) {
                    return -1;
                  }
                  if (
                    !moment(convertData(a.date)).isBefore(
                      moment(convertData(b.date))
                    )
                  ) {
                    return 1;
                  }
                  // a must be equal to b
                  return 0;
                }),
              });
            });
            return {
              name: row.name,
              type: "exam",
              data: aux,
            };
          } else if (row.type !== "d7") {
            return {
              ...row,
              data: row.data
                .map((el) => ({
                  ...el,
                  Valor: parseFloat(el[`${row.field}`]),
                }))
                .reverse(),
            };
          } else {
            return {
              ...row,
              data: row.data
                .map((el) => ({
                  ...el,
                  pas: parseInt(el.pas),
                  pad: parseInt(el.pad),
                }))
                .reverse(),
            };
          }
        });

        setState({
          exams: res.data.exams,
          custom: res.data.custom ? res.data.custom : {},
          record,
          start,
          end,
        });
        setLoader(false);
      })
      .catch(() => setLoader(false));
  };

  const convertData = (dt) => {
    let aux = dt.split("/");
    return `${aux[2]}-${aux[1]}-${aux[0]}`;
  };

  if (loader) {
    return <CircularProgress />;
  }

  return (
    <div className={width >= 960 ? "col-12 pl-5 pr-5" : "w-100"}>
      <div className="d-flex col-12 ml-2">
        <Typography className="pt-1 d-contents" variant="h6" gutterBottom>
          Históricos de Saúde
        </Typography>
      </div>

      {state.record.length === 0 ? (
        <div className="col-12 pl-0 pr-0 mt-5">
          <div
            className={width < 960 ? "card" : "card shadow font-weight-bold"}
            style={{ cursor: "pointer" }}
            onClick={() => setModal(true)}
          >
            <Typography
              className="m-5 text-center"
              variant="subtitle1"
              gutterBottom
            >
              Clique aqui para criar seu painel de históricos de saúde.
            </Typography>
          </div>
        </div>
      ) : (
        <>
          <div className="col-sm-12">
            <TextField
              select
              value={period}
              onChange={handleChangePeriod}
              label="Período:"
              fullWidth
            >
              <MenuItem key="3" value="7">
                Últimos 7 dias
              </MenuItem>
              <MenuItem key="4" value="15">
                Últimos 15 dias
              </MenuItem>
              <MenuItem key="0" value="30">
                Últimos 30 dias
              </MenuItem>
              <MenuItem key="1" value="60">
                Últimos 60 dias
              </MenuItem>
              <MenuItem key="2" value="90">
                Últimos 90 dias
              </MenuItem>
              <MenuItem key="6" value="180">
                Últimos 180 dias
              </MenuItem>
              <MenuItem key="7" value="365">
                Último ano
              </MenuItem>
              <MenuItem key="5" value="especific">
                Período específico
              </MenuItem>
            </TextField>
          </div>
          {period === "especific" && (
            <div className="row align-items-center text-center col-12">
              <div className="col-sm-4 col-xs-12 mt-3">
                <TextField
                  id="date"
                  type="date"
                  label="Data Inicial"
                  value={state.start}
                  onChange={(e) =>
                    setState({
                      ...state,
                      start: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 col-xs-12 mt-3">
                <TextField
                  id="date"
                  type="date"
                  label="Data Final"
                  value={state.end}
                  onChange={(e) =>
                    setState({
                      ...state,
                      end: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 col-xs-12 mt-3">
                <Btn
                  title="filtrar"
                  onClick={() => getData(state.start, state.end)}
                  name="filtrar"
                />
              </div>
            </div>
          )}
          <div className={width >= 960 ? "col-12 row" : ""}>
            {state.record.map((row) => (
              <div
                className={
                  width >= 960 ? "col-6 mt-3" : "col-12 pl-0 pr-0 mt-3"
                }
              >
                {width >= 960 && (
                  <Typography variant="subtitle1" gutterBottom>
                    <strong>{row.name}</strong>
                  </Typography>
                )}
                <div className="card pl-3">
                  {width < 960 && (
                    <Typography variant="subtitle1" gutterBottom>
                      <strong>{row.name}</strong>
                    </Typography>
                  )}
                  {row.type === "exam" ? (
                    <>
                      {row.data.map((el) => (
                        <div className="col-md-12 mt-3">
                          <h4 className="font-weight-semibold ml-3">
                            {el.name}
                          </h4>
                          <ResponsiveContainer height={220} width="90%">
                            <ComposedChart
                              data={el.data}
                              height={220}
                              margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                            >
                              <XAxis dataKey="date" />
                              <YAxis />
                              <Tooltip />
                              <CartesianGrid
                                stroke="#f5f5f5"
                                strokeDasharray="3 3"
                              />
                              <Bar
                                dataKey="value"
                                barSize={25}
                                fill="#177493"
                              />
                              <Line
                                type="monotone"
                                dataKey="value"
                                stroke="#177493"
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      ))}
                    </>
                  ) : row.type !== "d7" ? (
                    <>
                      {row.data.length > 0 && (
                        <div className="col-md-12 mt-3">
                          <ResponsiveContainer height={220} width="90%">
                            <ComposedChart
                              data={row.data}
                              height={220}
                              margin={{
                                top: 10,
                                right: 10,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <XAxis dataKey="date" />
                              <YAxis />
                              <Tooltip />
                              <CartesianGrid
                                stroke="#f5f5f5"
                                strokeDasharray="3 3"
                              />
                              <Bar
                                dataKey="Valor"
                                barSize={25}
                                fill="#177493"
                              />
                              <Line
                                type="monotone"
                                dataKey="Valor"
                                stroke="#177493"
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {row.data.length > 0 && (
                        <div className="col-md-12 mt-3">
                          <ResponsiveContainer height={220} width="90%">
                            <ComposedChart
                              data={row.data}
                              height={220}
                              margin={{
                                top: 10,
                                right: 10,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <XAxis dataKey="date" />
                              <YAxis />
                              <Tooltip />
                              <CartesianGrid
                                stroke="#f5f5f5"
                                strokeDasharray="3 3"
                              />

                              <Bar dataKey="pas" barSize={25} fill="#177493" />
                              <Line
                                type="monotone"
                                dataKey="pas"
                                stroke="#177493"
                              />
                              <Bar dataKey="pad" barSize={25} fill="#86ddfb" />
                              <Line
                                type="monotone"
                                dataKey="pad"
                                stroke="#86ddfb"
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </>
                  )}

                  {row.data.length === 0 && (
                    <Typography
                      variant="subtitle2"
                      className="text-center"
                      gutterBottom
                    >
                      <i>Nenhum Registro.</i>
                    </Typography>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="col-12">
            <TooltipMui placement="top" title="Editar">
              <Fab
                onClick={() => setModal(true)}
                color="primary"
                aria-label="add"
                style={{
                  margin: 0,
                  top: "auto",
                  right: 20,
                  bottom: width < 960 ? 80 : 10,
                  left: "auto",
                  position: "fixed",
                  zIndex: 999,
                }}
              >
                {state.custom.id ? <EditIcon /> : <AddIcon />}
              </Fab>
            </TooltipMui>
          </div>
        </>
      )}

      <div className="col-md-12" style={{ marginTop: "80px" }}></div>
      <CustomGraphic
        open={modal}
        handleClose={() => setModal(false)}
        exams={state.exams}
        custom={state.custom}
        beforeSave={() => getData(state.start, state.end)}
      />
      <ModalPeriod
        open={modalPeriod}
        handleClose={() => setModalPeriod(false)}
        start={state.start}
        end={state.end}
        onFilter={(a, b) => {
          getData(a, b);
        }}
      />
    </div>
  );
}
