import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getTagFromProvider } from "actions/consult/Tags";
import {
  setEditTag,
  getAllPatientsFromTag,
  setInitialAllPatientsFromTag
} from "actions/configuration/TagsConditions";
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Badge
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import NoRecord from "components/Alert/NoRecord";
import ModalDelete from "./ModalDelete";
import CircularProgress from "components/CircularProgress";
import ModalEdit from "./ModalEdit";
import ModalUsers from "./ModalUsers";

class ViewTags extends Component {
  state = {
    open_delete: false,
    tag_to_delete: {},
    open_edit: false,
    tag_to_edit: {},
    open_pacientes: false,
    tag_pacientes: {}
  };
  handleOpenDelete = tag => {
    this.setState({ open_delete: true, tag_to_delete: tag });
  };
  handleCloseDelete = () => {
    this.setState({ open_delete: false, tag_to_delete: {} });
  };
  handleOpenEdit = tag => {
    this.props.setEditTag(tag);
    this.props.handleChangeButton();
  };
  handleCloseEdit = () => {
    this.setState({ open_edit: false, tag_to_edit: {} });
  };
  handleOpenPacientes = tag => {
    this.setState({ open_pacientes: true, tag_pacientes: tag });
    this.props.getAllPatientsFromTag(tag.id);
  };
  handleClosePacientes = () => {
    this.setState({ open_pacientes: false, tag_pacientes: {} });
    this.props.setInitialAllPatientsFromTag();
    this.props.getTagFromProvider(this.props.address);
  };
  componentDidMount() {
    this.props.getTagFromProvider(this.props.address);
  }
  render() {
    const { list_tags, loader } = this.props;
    if (loader) {
      return <CircularProgress />;
    } else {
      return (
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto"
          }}
        >
          {list_tags.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Cor</TableCell>
                  <TableCell>TAG</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_tags.map(tag => (
                  <TableRow hover key={tag.id}>
                    <TableCell>
                      {" "}
                      <i
                        className="zmdi zmdi-tag"
                        style={{ color: tag.color }}
                      />
                    </TableCell>
                    <TableCell>{tag.name}</TableCell>
                    <TableCell>{tag.description}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Pacientes">
                        <IconButton
                          arial-label="Pacientes"
                          onClick={() => this.handleOpenPacientes(tag)}
                        >
                          <Badge
                            badgeContent={tag.total}
                            color="primary"
                            invisible={tag.total === "0"}
                          >
                            <GroupIcon />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Editar">
                        <IconButton
                          arial-label="Editar"
                          onClick={() => this.handleOpenEdit(tag)}
                          color="primary"
                        >
                          <i className="zmdi zmdi-edit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Excluir">
                        <IconButton
                          arial-label="Excluir"
                          onClick={() => this.handleOpenDelete(tag)}
                          color="secondary"
                        >
                          <i className="zmdi zmdi-delete" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoRecord />
          )}

          <ModalDelete
            tag={this.state.tag_to_delete}
            open={this.state.open_delete}
            handleCloseDelete={this.handleCloseDelete}
          />
          <ModalEdit
            tag={this.state.tag_to_edit}
            open={this.state.open_edit}
            handleClose={this.handleCloseEdit}
          />
          <ModalUsers
            tag={this.state.tag_pacientes}
            open={this.state.open_pacientes}
            handleClose={this.handleClosePacientes}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  list_tags: state.tags.tags_from_provider,
  address: state.auth.address,
  loader: state.tags.loader
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTagFromProvider,
      setEditTag,
      getAllPatientsFromTag,
      setInitialAllPatientsFromTag
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTags);
