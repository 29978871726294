import React, { Component } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Checkbox
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setConditions,
  getProgrammingFromTag
} from "actions/configuration/TagsConditions.js";

import ConditionalIdade from "./conditions/Idade";
import ConditionalSexo from "./conditions/Sexo";
import ConditionalSickness from "./conditions/Sickness";
import ConditionalMedicines from "./conditions/Medicines";
import ConditionalProducere from "./conditions/Producere";
import ConditionalDiseasesAllergy from "./conditions/DiseasesAllergy";
import ConditionalAllergyChemicals from "./conditions/AllergyChemicals";
import ConditionalDrugAllergy from "./conditions/DrugAllergy";
import ConditionalFoodAllergy from "./conditions/FoodAllergy";

const conditions = [
  {
    name: "Sexo",
    value: 1
  },
  {
    name: "Idade",
    value: 2
  },
  {
    name: "Doenças",
    value: 3
  },
  {
    name: "Medicamentos",
    value: 4
  },
  {
    name: "Procedimentos",
    value: 5
  },
  {
    name: "Doenças Alérgicas",
    value: 6
  },
  {
    name: "Alergia a substâncias químicas",
    value: 7
  },
  {
    name: "Alergia medicamentosas",
    value: 8
  },
  {
    name: "Alergia alimentares",
    value: 9
  }
];

class TransferList extends Component {
  state = {
    checked: []
  };
  handleToggle = (event, value) => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      this.setInitialConstion(value);
    }
    this.setState({
      checked: newChecked
    });
  };

  setInitialConstion(value) {
    let type;
    let val = [];
    switch (value) {
      case 1:
        type = "SET_CONDITION_SEXO";
        val = null;
        break;
      case 2:
        type = "SET_CONDITION_IDADE";
        break;
      case 3:
        type = "SET_CONDITION_SICKNESS";
        break;
      case 4:
        type = "SET_CONDITION_MEDICINES";
        break;
      case 5:
        type = "SET_CONDITION_PRODUCERE";
        break;
      case 6:
        type = "SET_CONDITION_DISEASES_ALLERGY";
        break;
      case 7:
        type = "SET_CONDITION_CHEMICALS_ALLERGY";
        break;
      case 8:
        type = "SET_CONDITION_DRUG_ALLERGY";
        break;
      case 9:
        type = "SET_CONDITION_FOOD_ALLERGY";
        break;
      default:
        type = null;
        break;
    }
    if (type !== null) {
      this.props.setConditions(type, val);
    } else {
      return null;
    }
  }

  componentDidMount() {
    if (this.props.edit_tag !== null && this.props.edit_tag.query !== null) {
      this.props.getProgrammingFromTag(this.props.edit_tag.id);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.tag_programming !== null) {
      let newChecked = [...this.state.checked];
      Object.keys(nextProps.tag_programming).forEach(row => {
        if (nextProps.tag_programming[`${row}`].length > 0) {
          newChecked.push(this.returnValue(row));
        }
      });
      this.setState({ checked: newChecked });
    }
  }

  returnValue = name => {
    switch (name) {
      case "sex":
        return 1;
      case "age":
        return 2;
      case "sickness":
        return 3;
      case "medicines":
        return 4;
      case "producere":
        return 5;
      case "diseases_allergy":
        return 6;
      case "chemicals_allergy":
        return 7;
      case "drug_allergy":
        return 8;
      case "food_allergy":
        return 9;
      default:
        return null;
    }
  };

  render() {
    const componente_return = value => {
      switch (value) {
        case 1:
          return (
            <ConditionalSexo
              params={
                this.props.tag_programming !== null &&
                this.props.tag_programming[`sex`].length > 0
                  ? this.props.tag_programming[`sex`]
                  : null
              }
            />
          );
        case 2:
          return (
            <ConditionalIdade
              params={
                this.props.tag_programming !== null &&
                this.props.tag_programming[`age`].length > 0
                  ? this.props.tag_programming[`age`]
                  : null
              }
            />
          );
        case 3:
          return (
            <ConditionalSickness
              params={
                this.props.tag_programming !== null &&
                this.props.tag_programming[`sickness`].length > 0
                  ? this.props.tag_programming[`sickness`]
                  : null
              }
            />
          );
        case 4:
          return (
            <ConditionalMedicines
              params={
                this.props.tag_programming !== null &&
                this.props.tag_programming[`medicines`].length > 0
                  ? this.props.tag_programming[`medicines`]
                  : null
              }
            />
          );
        case 5:
          return (
            <ConditionalProducere
              params={
                this.props.tag_programming !== null &&
                this.props.tag_programming[`producere`].length > 0
                  ? this.props.tag_programming[`producere`]
                  : null
              }
            />
          );
        case 6:
          return (
            <ConditionalDiseasesAllergy
              params={
                this.props.tag_programming !== null &&
                this.props.tag_programming[`diseases_allergy`].length > 0
                  ? this.props.tag_programming[`diseases_allergy`]
                  : null
              }
            />
          );
        case 7:
          return (
            <ConditionalAllergyChemicals
              params={
                this.props.tag_programming !== null &&
                this.props.tag_programming[`chemicals_allergy`].length > 0
                  ? this.props.tag_programming[`chemicals_allergy`]
                  : null
              }
            />
          );
        case 8:
          return (
            <ConditionalDrugAllergy
              params={
                this.props.tag_programming !== null &&
                this.props.tag_programming[`drug_allergy`].length > 0
                  ? this.props.tag_programming[`drug_allergy`]
                  : null
              }
            />
          );
        case 9:
          return (
            <ConditionalFoodAllergy
              params={
                this.props.tag_programming !== null &&
                this.props.tag_programming[`food_allergy`].length > 0
                  ? this.props.tag_programming[`food_allergy`]
                  : null
              }
            />
          );
        default:
          return null;
      }
    };
    return (
      <React.Fragment>
        <Grid item className="col-md-4">
          <List style={{ border: "2px solid #ccc", borderRadius: "16px" }}>
            {conditions.map(condition => (
              <ListItem
                button
                key={condition.value}
                onClick={event => this.handleToggle(event, condition.value)}
                style={{ paddingTop: "0", paddingBottom: "0" }}
              >
                <Checkbox
                  color="primary"
                  checked={this.state.checked.indexOf(condition.value) !== -1}
                />
                <ListItemText primary={condition.name} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          className="col-md-8"
          style={{
            border: "2px solid #ccc",
            borderRadius: "16px",
            paddingBottom: "20px"
          }}
        >
          {this.state.checked.map((value, index) => (
            <React.Fragment key={index}>
              {componente_return(value)}
            </React.Fragment>
          ))}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  edit_tag: state.tagsConditions.edit_tag,
  tag_programming: state.tagsConditions.tag_programming
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setConditions, getProgrammingFromTag }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferList);
