import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  MenuItem,
} from "@material-ui/core";
import {
  requestGroupMedList,
  requestMedList,
  setMedSelected,
} from "actions/consult/Prescription";

class MedicinesDefault extends Component {
  state = {
    group_selected: false,
    group_id: "all",
    isOpen: true,
  };

  componentWillMount() {
    this.props.requestGroupMedList(this.props.doctor, this.props.user_address);
  }

  handleChangeSelectedMedicineGroup(group_id) {
    if (group_id !== "all") {
      this.props.requestMedList(group_id, this.props.plusPosology, false);
      this.setState({ group_selected: true, group_id });
    } else {
      this.setState({ group_selected: false, group_id });
    }
  }

  handleChangeSelectedMedicine(medicine_id, medicine) {
    medicine.date_start = moment().format("YYYY-MM-DD");
    this.props.setMedSelected(medicine_id, medicine);
    this.setState({ group_selected: false, group_id: "all", isOpen: false });
  }

  getClassImage = (type) => {
    let imgIcon = "";
    switch (type) {
      case "Similar":
        imgIcon = require("assets/images/consult/similar.jpg");
        break;
      case "Generico":
        imgIcon = require("assets/images/consult/generico.jpg");
        break;
      case "Referencia":
      case "Especifico":
        imgIcon = require("assets/images/consult/referencia.jpg");
        break;
      case "Biológicos":
        imgIcon = require("assets/images/consult/biologico.jpg");
        break;
      default:
        imgIcon = require("assets/images/consult/outros.jpg");
        break;
    }
    return imgIcon;
  };

  getTarge = (targe) => {
    if (targe === "TP") {
      return require("assets/images/consult/Preta.svg");
    } else if (targe === "TV") {
      return require("assets/images/consult/Vermelha.svg");
    } else {
      return require("assets/images/consult/Branca.svg");
    }
  };

  render() {
    const { list_med_group, list_med } = this.props;
    const { group_id, group_selected, isOpen } = this.state;
    return (
      <>
        {list_med_group.length > 0 && (
          <>
            <div className="row">
              <div className="col-12">
                <TextField
                  select
                  label="Grupo de Medicamentos:"
                  value={group_id}
                  onChange={(e) =>
                    this.handleChangeSelectedMedicineGroup(e.target.value)
                  }
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem key="all" value="all">
                    Todos os medicamentos
                  </MenuItem>
                  {list_med_group.map((group, index) => (
                    <MenuItem key={index} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-12 mt-3">
                {group_selected && (
                  <>
                    <h4 className="font-weight-semibold">
                      Selecione o medicamento:
                    </h4>
                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      <Table className="table-medicines">
                        <TableHead>
                          <TableRow>
                            <TableCell>Referência</TableCell>
                            <TableCell align="left">Princípio Ativo</TableCell>
                            <TableCell align="left">Medicamento</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {list_med.map((row) => (
                            <TableRow
                              hover
                              key={row.ls_mp_medicine}
                              onClick={() => {
                                this.handleChangeSelectedMedicine(row.id, row);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ display: "flex" }}
                              >
                                <>
                                  <img
                                    src={this.getClassImage(
                                      row.medtype_display
                                    )}
                                    width="30"
                                    alt="Tipo do medicamento"
                                    height="30"
                                  />
                                  <img
                                    src={this.getTarge(row.targe)}
                                    alt="Tarja do medicamento"
                                    width="30"
                                    height="30"
                                    style={{ marginLeft: "30px" }}
                                  />
                                </>
                              </TableCell>
                              <TableCell align="left">
                                {row.active_principle}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, prescription }) => {
  return {
    doctor: auth.authUser,
    list_med_group: prescription.list_med_group,
    list_med: prescription.list_med,
    user_address: auth.address_selected.user_id,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestGroupMedList,
      requestMedList,
      setMedSelected,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MedicinesDefault);
