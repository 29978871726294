import React, { Component } from "react";
import { connect } from "react-redux";
import api from "services/api";
import { bindActionCreators } from "redux";
import * as appointmentActions from "actions/consult/Appointment";
import * as attendanceRecordActions from "actions/consult/AttendanceRecord";
import * as alertActions from "actions/Alerts";
import {
  TextField,
  Button,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
// import TextField from "components/TextFields";
import MicOffIcon from "@material-ui/icons/MicOff";
import MicIcon from "@material-ui/icons/Mic";
import Api from "services/api";
import { withStyles } from "@material-ui/core/styles";
// plugin Autocomplete
import Mention from "./autocompleteText/Mention";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { Prompt } from "react-router-dom";

import "../styles.css";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  icon: {
    color: "#a6a6a6",
    fontSize: "35px",
    "&:hover": {
      color: "#4397c2",
      animationName: "bounce",
      animationDuration: "3s",
      animationDelay: "1s",
    },
  },
});

const SpeechRecognition =
  window.SpeechRecognition ||
  window.webkitSpeechRecognition ||
  window.mozSpeechRecognition ||
  window.msSpeechRecognition ||
  window.oSpeechRecognition;

const recognition =
  !SpeechRecognition || navigator.userAgent.indexOf("Edg") > -1
    ? null
    : new SpeechRecognition();
if (recognition !== null) recognition.lang = "pt-BR";

class FormAttendanceRecord extends Component {
  state = { listen: false, input: "", viewRestrictInfo: false, edited: false };
  save(alert) {
    let formData = {
      appointment: {
        id: this.props.id_appointment,
        start: this.props.start,
        triage: this.props.triage,
      },
      patient: this.props.patient,
      doctor: this.props.doctor,
      address: this.props.address,
      prof_resp: this.props.prof_resp,
      data: { ...this.props.attendanceRecord.register },
      saved_id: this.props.saved_id,
      saved: this.props.saved_registerAttendance,
    };

    Api.post(`/microservice/consult/saveRegisterAttendance`, formData)
      .then((res) => {
        if (!this.props.id_appointment) {
          this.props.setAppointment(res.data.id);
        }
        if (res.data.id_restrict_info) {
          this.props.handleChangeRegister(
            "id_restrict_info",
            res.data.id_restrict_info
          );
        }
        this.props.alterEditRegAtt();
        if (res.data.saved !== "" || res.data.saved !== null) {
          this.props.setSavedActions("registerAttendance", res.data.saved);
        }
        if (typeof this.props.closeDrawer !== "undefined") {
          this.props.closeDrawer();
        }
        if (alert) {
          this.props.viewAlertMessage("success", "Salvo com sucesso!");
          setTimeout(() => this.props.closeAlertMessage(), 5000);
        }
        this.setState({ edited: false });
      })
      .catch(() => {
        if (alert) {
          this.props.viewAlertMessage("error", "Ocorreu um erro ao salvar!");
          setTimeout(() => this.props.closeAlertMessage(), 5000);
        }
      });
  }

  toggleSpeech = (input) => {
    this.setState(
      {
        listen: !this.state.listen,
        input: input,
      },
      this.handleListen
    );
  };

  handleListen = () => {
    if (this.state.listen) {
      recognition.start();
      recognition.onend = () => {
        recognition.start();
      };
    } else {
      recognition.abort();
      recognition.onend = () => {
        recognition.stop();
      };
    }

    recognition.onresult = (event) => {
      let speech =
        this.state.input === "consult_info"
          ? this.props.attendanceRecord.register.consult_info
          : this.props.attendanceRecord.register.restrict_info;
      speech += " ";
      speech += event.results[0][0].transcript;
      this.props.handleChangeRegister(this.state.input, speech);
    };
  };

  closeModal = () => {
    this.save(false);
    this.props.closeModalAttendance();
  };

  componentDidMount() {
    if (
      this.props.attendanceRecord.register.consult_info === "" &&
      this.props.id_appointment !== "" &&
      this.props.saved_registerAttendance === "1"
    ) {
      api
        .post(`/microservice/consult/getAttendanceRestrict/`, {
          id: this.props.id_appointment,
        })
        .then(({ data }) => {
          this.props.initRegAtt(data.list.free_text, data.list.restrict_info);
        })
        .catch((res) => {});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.attendanceRecord) {
      if (
        nextProps.attendanceRecord.save_modal_reg_att !==
          this.props.attendanceRecord.save_modal_reg_att &&
        nextProps.attendanceRecord.save_modal_reg_att > 0
      ) {
        this.save(false);
      }
    }
  }

  render() {
    const {
      consult_info,
      restrict_info,
      edited,
    } = this.props.attendanceRecord.register;

    const { listen, input, viewRestrictInfo } = this.state;

    return (
      <form>
        <div className="row">
          <div className="col-12">
            <h4
              className="text-primary font-weight-semibold"
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              Registro do atendimento
            </h4>
          </div>
          <div className="col-md-12">
            <TextField
              id="consult_info2"
              label="Informações da consulta:"
              className="textField-padding"
              multiline={true}
              rows={8}
              value={consult_info}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: Mention,
                width: "100%",
              }}
              variant="outlined"
            />
            {recognition !== null && (
              <IconButton
                style={{
                  position: "absolute",
                  right: "20px",
                  width: "5%",
                  marginTop: "30px",
                }}
                title="falar"
                color={
                  listen && input === "consult_info" ? "secondary" : "primary"
                }
                onClick={() => this.toggleSpeech("consult_info")}
              >
                {listen && input === "consult_info" ? (
                  <MicOffIcon />
                ) : (
                  <MicIcon />
                )}
              </IconButton>
            )}
          </div>
          <div className="col-md-12">
            <FormHelperText style={{ color: "#000000de" }}>
              Informações restritas:{" "}
              <i
                className={
                  viewRestrictInfo ? "zmdi zmdi-eye-off" : "zmdi zmdi-eye"
                }
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                  color: viewRestrictInfo ? "#177493" : "",
                }}
                onClick={() =>
                  this.setState({
                    viewRestrictInfo: !this.state.viewRestrictInfo,
                  })
                }
              />
            </FormHelperText>
            {viewRestrictInfo && (
              <>
                <TextField
                  id="restrict_info"
                  className="textField-padding"
                  multiline={true}
                  rows={4}
                  value={restrict_info}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    this.props.handleChangeRegister(
                      "restrict_info",
                      e.target.value
                    );
                    this.setState({ edited: true });
                  }}
                  variant="outlined"
                />
                {recognition !== null && (
                  <IconButton
                    style={{
                      position: "absolute",
                      right: "20px",
                      width: "5%",
                      marginTop: "30px",
                    }}
                    title="falar"
                    color={
                      listen && input === "restrict_info"
                        ? "secondary"
                        : "primary"
                    }
                    onClick={() => this.toggleSpeech("restrict_info")}
                  >
                    {listen && input === "restrict_info" ? (
                      <MicOffIcon />
                    ) : (
                      <MicIcon />
                    )}
                  </IconButton>
                )}
              </>
            )}
          </div>
          <div className="col-md-12 text-right">
            {/* {this.props.buttonSave === true ? (
              <div align="center" className="d-block">
                <Tooltip title="Fechar">
                  <IconButton
                    className="m-0 p-0"
                    onClick={() => this.closeModal()}
                  >
                    <ExpandLessIcon
                      className={`animated slideInUpTiny animation-duration-3 ${classes.icon}`}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            ) : ( */}
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              onClick={() => this.save(true)}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span>Salvar Registro do Atendimento </span>
            </Button>
            {/* )} */}
          </div>
          <div className="col-12">
            <hr />
          </div>
        </div>
        <Prompt
          when={edited || this.state.edited}
          message="Alterações não salvas! Deseja sair sem salvar?"
        />
      </form>
    );
  }
}

const mapStateToProps = ({ auth, appointment, attendanceRecord }) => {
  return {
    doctor: auth.authUser,
    address: auth.address,
    patient: appointment.patient,
    prof_resp: appointment.prof_resp,
    id_appointment: appointment.id,
    start: appointment.start,
    triage: appointment.triage,
    saved_id: appointment.saved.id,
    saved_registerAttendance: appointment.saved.registerAttendance,
    attendanceRecord,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...appointmentActions,
      ...attendanceRecordActions,
      ...alertActions,
    },
    dispatch
  );

FormAttendanceRecord.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(FormAttendanceRecord);
