import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Api from "services/api";
import moment from "moment";
import { showMsg } from "services/functions";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@material-ui/lab/";
import { Typography } from "@material-ui/core/";
import { Btn } from "components/Button";
import ViewConsult from "components/HistoryPatient/consult/ViewConsult";
import BarProgress from "components/BarProgress";
import NoRecord from "components/Alert/NoRecord";

export default function ViewTreatment({ id, changeWidth }) {
  const dispatch = useDispatch();
  const [record, setRecord] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pp, setPp] = useState("");
  const [finished, setFinished] = useState({
    date: null,
    obs: null,
  });

  useEffect(() => {
    if (id) {
      getRecords();
    }
  }, [id]);

  useEffect(() => {
    changeWidth &&
      typeof changeWidth === "function" &&
      changeWidth(!pp ? "sm" : "md");
  }, [pp]);

  function getRecords() {
    setLoader(true);
    Api.post("microservice/treatment/getPPHistory", { id })
      .then(({ data }) => {
        setRecord(data.record);
        setFinished({ date: data.dateFinish, obs: data.obs });
      })
      .catch(() => {
        setRecord([]);
        showMsg(
          "error",
          "Não foi possível encontrar os atendimentos deste tratemento",
          dispatch
        );
      })
      .finally(() => {
        setLoader(false);
      });
  }

  return (
    <>
      {/* Loader */}
      {loader && <BarProgress />}

      {/* Nenhum atendimento encontrado */}
      {!loader && record.length === 0 && <NoRecord />}

      {/* TimeLine */}
      {!loader && record.length > 0 && !pp && (
        <Timeline>
          {finished.date && (
            <TimelineItem>
              <TimelineOppositeContent style={{ flex: "none" }}>
                <Typography variant="body2" color="textSecondary">
                  {moment(finished.date).format("DD/MM/YYYY")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="secondary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <p style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                  <strong>Finalizado</strong>
                  <br />
                  {finished.obs && (
                    <>
                      Conclusão: <strong>{finished.obs}</strong>
                    </>
                  )}
                </p>
              </TimelineContent>
            </TimelineItem>
          )}
          {record.map((row, index) => (
            <TimelineItem key={row.id}>
              <TimelineOppositeContent style={{ flex: "none" }}>
                <Typography variant="body2" color="textSecondary">
                  {row.date}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index !== record.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <p style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                  {index === record.length - 1
                    ? `Tratamento iniciado`
                    : `Atendimento realizado`}{" "}
                  por: <strong>{row.name}</strong>
                  <br />
                  Especialidade(s): <strong>{row.specialty}</strong>
                </p>
                <Btn
                  icon="zmdi zmdi-plus"
                  name="Ver mais"
                  title="Ver mais"
                  onClick={() => setPp(row.id)}
                  float="left"
                />
              </TimelineContent>
            </TimelineItem>
          ))}
          {/* {!finished.date && (
            <TimelineItem>
              <TimelineOppositeContent style={{ flex: "none" }}>
                <Typography variant="body2" color="textSecondary">
                  {moment().format("DD/MM/YYYY")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
              </TimelineSeparator>
              <TimelineContent>
                <p style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                  <strong>Em tratamento...</strong>
                </p>
              </TimelineContent>
            </TimelineItem>
          )} */}
        </Timeline>
      )}

      {/* Dados do atendimento */}
      {!loader && pp && (
        <ViewConsult appointment_id={pp} onClose={() => setPp("")} />
      )}
    </>
  );
}
