export const selectedUserChat = (user) => {
  return {
    type: "SELECTED_USER_CHAT",
    payload: user,
  };
};

export const setSelectedChat = (id) => {
  return { type: "SELECTED_CHAT", payload: id };
};

export const setConversation = (conversation) => {
  return { type: "SET_CONVERSATION_CHAT", payload: conversation };
};

export const showChatModal = (selectedChat) => {
  return { type: "SHOW_CHAT_MODAL", payload: selectedChat };
};

export const hideChatModal = (id) => {
  return { type: "HIDE_CHAT_MODAL", id };
};

export const setHasNotification = (notify) => {
  return { type: "SET_HAS_NOTIFICATION", payload: notify };
};

export const setMinimaze = (id, type = true) => {
  return { type: "SET_MINIMAZE_CHAT", payload: { id, type } };
};

export const chatModalMobile = (open) => {
  return { type: "CHAT_MODAL_MOBILE", payload: open };
};

export const modalVideo = (chatId) => {
  return { type: "MODAL_VIDEO", payload: chatId };
};

export const resetChat = () => {
  return { type: "RESET_CHAT" };
};
