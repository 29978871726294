import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  handleChangeProvider,
  handleChangeRecreation,
} from "actions/configuration/Profile";
import { setImage } from "actions/configuration/Profile";
import { TextField, Button, Tooltip } from "@material-ui/core";
import * as alertActions from "actions/Alerts";
import Api from "services/api";
import MaskPhone from "components/Masks/MaskPhone";
import MaskCellphone from "components/Masks/MaskCellphone";
import TakePhotoWithCropper from "components/Forms/TakePhotoWithCropper";
import { getUrlPhoto } from "services/functions";

class BasicDataProvider extends Component {
  state = {
    statsPhotoModal: false,
    setImgPatient: "",
  };
  submit = () => {
    let formData = {
      user: this.props.user,
      data: {
        ...this.props.provider,
      },
    };

    if (this.state.setImgPatient) {
      this.props.setImage(this.props.user, this.state.setImgPatient);
    }

    Api.post(`/microservice/profile/saveBasicDataProvider/`, formData)
      .then((res) => {
        res.data.success
          ? this.setAlertMessage("success", "Salvo com sucesso!")
          : this.setAlertMessage("error", "Ocorreu um erro ao salvar!");
      })
      .catch(() => this.setAlertMessage("error", "Ocorreu um erro ao salvar!"));
  };

  setAlertMessage = (type, message) => {
    this.props.viewAlertMessage(type, message);
    setTimeout(() => this.props.closeAlertMessage(), 5000);
  };

  handleChangeBasic = (name) => (event) => {
    this.props.handleChangeProvider(name, event.target.value);
  };

  calcAge = () => {
    let today = new Date();
    let birthDate = new Date(this.props.basicData.birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
      m = 11;
    }
    age = age + " Ano(s)";
    if (m > 0) {
      age = age + " " + m + " Mes(es)";
    }
    this.props.handleChange("age", age);
  };

  imageReceive = (img) => {
    this.props.setImage(this.props.user, img);
    this.setState({ setImgPatient: img });
  };

  setTakePhoto(value) {
    this.setState({ statsPhotoModal: value });
  }

  render() {
    const { provider } = this.props;
    const { setImgPatient } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          {/* <h3 className="card-title text-primary title-form">
            Informações básicas
          </h3> */}

          <span className="card-title text-primary title-form">
            Informações básicas
          </span>
        </div>
        <div className="col-md-2" style={{ float: "left" }}>
          <div className="col-md-1 col-xs-3">
            <Tooltip title="Alterar foto">
              <img
                src={
                  setImgPatient
                    ? setImgPatient
                    : getUrlPhoto(this.props.user, provider.photo)
                }
                alt={provider.name}
                className="rounded-circle img-responsive"
                style={{
                  marginTop: "10px",
                  width: "170px",
                  height: "170px",
                  cursor: "pointer",
                }}
                onClick={() => this.setTakePhoto(true)}
              />
            </Tooltip>
          </div>
        </div>
        <div className="col-md-10" style={{ float: "rigth" }}>
          <div className="col-md-12 col-12">
            <TextField
              id="name"
              label="Nome"
              value={provider.name}
              margin="normal"
              fullWidth
              onChange={this.handleChangeBasic("name")}
            />
          </div>
          <div className="col-md-12 col-12">
            <TextField
              id="email"
              label="Email"
              value={provider.email}
              margin="normal"
              fullWidth
              onChange={this.handleChangeBasic("email")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>

        <div className="col-12">
          <TextField
            id="company_name"
            label="Razão Social:"
            value={provider.company_name}
            margin="normal"
            fullWidth
            readOnly
            onChange={this.handleChangeBasic("company_name")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-12">
          <TextField
            id="fantasy_name"
            label="Nome Fantasia:"
            value={provider.fantasy_name}
            margin="normal"
            fullWidth
            readOnly
            onChange={this.handleChangeBasic("fantasy_name")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="cnpj"
            label="CNPJ:"
            value={provider.cnpj}
            margin="normal"
            fullWidth
            readOnly
            onChange={this.handleChangeBasic("CNPJ")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="foundation_date"
            type="date"
            label="Data de Fundação:"
            value={provider.foundation_date}
            margin="normal"
            fullWidth
            onChange={this.handleChangeBasic("foundation_date")}
            InputLabelProps={{
              shrink: true,
            }}
            onInput={(e) => {
              if (e.target.value.length > 10) {
                e.target.value = e.target.value.substr(1, 11);
              }
            }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="website"
            label="Website:"
            value={provider.website}
            margin="normal"
            onChange={this.handleChangeBasic("website")}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="twitter"
            label="Twitter:"
            value={provider.twitter}
            margin="normal"
            onChange={this.handleChangeBasic("twitter")}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="google_plus"
            label="Google +:"
            value={provider.google_plus}
            margin="normal"
            onChange={this.handleChangeBasic("google_plus")}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="facebook"
            label="Facebook:"
            value={provider.facebook}
            margin="normal"
            onChange={this.handleChangeBasic("facebook")}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="linkedin"
            label="Linkedin:"
            value={provider.linkedin}
            margin="normal"
            onChange={this.handleChangeBasic("linkedin")}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3 col-12">
          <TextField
            id="instagram"
            label="Instagram:"
            value={provider.instagram}
            margin="normal"
            onChange={this.handleChangeBasic("instagram")}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="whatsapp"
            label="Whatsapp:"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCellphone,
              value: provider.whatsapp,
              onChange: this.handleChangeBasic("whatsapp"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-3 col-12">
          <TextField
            id="phone"
            label="Telefone Fixo:"
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskPhone,
              value: provider.phone,
              onChange: this.handleChangeBasic("phone"),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-6 col-12">
          <TextField
            id="who_recommended"
            label="Como soube do portal:"
            value={provider.who_recommended}
            margin="normal"
            onChange={this.handleChangeBasic("who_recommended")}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-12">
          <TextField
            id="about"
            label="Informações Complementares"
            multiline={true}
            rows={4}
            className="textField-padding"
            value={provider.about}
            onChange={this.handleChangeBasic("about")}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div className="col-md-12 text-right">
          <Button
            onClick={() => this.submit()}
            className="jr-btn jr-btn-sm bg-success"
            color="primary"
            variant="contained"
          >
            <i className="zmdi zmdi-check text-right" />
            <span> Salvar </span>
          </Button>
        </div>

        <TakePhotoWithCropper
          open={this.state.statsPhotoModal}
          handleClose={() => this.setTakePhoto(false)}
          imageReceive={this.imageReceive}
          hasImage={setImgPatient || provider.photo}
          urlImage={
            setImgPatient
              ? setImgPatient
              : getUrlPhoto(this.props.user, provider.photo)
          }
          handleRemove={() => {
            this.props.handleChangeProvider("photo", null);
            this.setState({ setImgPatient: "" });
            this.setTakePhoto(false);
          }}
          user={this.props.user}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.authUser,
    paramsConsult: state.paramsConsult,
    provider: state.Profile.provider,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { handleChangeProvider, handleChangeRecreation, ...alertActions, setImage },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BasicDataProvider);
