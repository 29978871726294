import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import "./style.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 500
  }
}));

export default function TableHistory({ name, listDetails, onClose, date }) {
  // const [dateEvent, setDateEvent] = React.useState(
  //   moment(date).format("MM-DD-YYYY")
  // );

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };
  return (
    <div className="row">
      <div className="col-12">
        {listDetails && (
          <>
            <Typography variant="h6" gutterBottom>
              {name}
            </Typography>
            <span className="font-italic">{date}</span>
            <div
              className="col-12"
              style={{
                minWidth: "650",
                overflowX: "auto"
              }}
            >
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className="m-0 p-0">Cintura (CC)</TableCell>
                    <TableCell className="m-0 p-0">Quadril (CQ)</TableCell>
                    <TableCell className="m-0 p-0">Abdominal (CA)</TableCell>
                    <TableCell className="m-0 p-0">Braço (CB)</TableCell>
                    <TableCell className="m-0 p-0">Panturrilha (CP)</TableCell>
                    <TableCell className="m-0 p-0">
                      R. Cintura-Quadril (RCQ)
                    </TableCell>
                    <TableCell className="m-0 p-0">
                      Classificação C. Cintura
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ padding: "0px" }}>
                  <TableRow key={1} className="p-0">
                    {listDetails.map(row => (
                      <>
                        <TableCell className="table-root">
                          {row.c_waist === null ? "-" : row.c_waist}
                        </TableCell>
                        <TableCell className="table-root">
                          {row.c_hip === null ? "-" : row.c_hip}
                        </TableCell>
                        <TableCell className="table-root">
                          {row.c_abdominal === null ? "-" : row.c_abdominal}
                        </TableCell>
                        <TableCell className="table-root">
                          {row.c_arm === null ? "-" : row.c_arm}
                        </TableCell>
                        <TableCell className="table-root">
                          {row.c_calf === null ? "-" : row.c_calf}
                        </TableCell>
                        <TableCell>
                          {row.r_waist_hip === null ? "-" : row.r_waist_hip}
                        </TableCell>
                        <TableCell>
                          {row.class_waist === null ? "-" : row.class_waist}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </>
        )}

        <div>
          <Button onClick={handleClose} color="primary" aria-label="Voltar">
            <i className="zmdi zmdi-undo" /> Voltar
          </Button>
        </div>
      </div>
    </div>
  );
}
