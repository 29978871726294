import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import { BtnSave, BtnCan } from "components/Button";
import { arrayMove } from "react-sortable-hoc";
import {
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import FormContainer from "components/Configuration/components/SortableContainer";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "white",
    flex: 1,
  },
  content: {
    marginBottom: "100px",
    paddingLeft: "15px",
  },
  buttons: {
    position: "fixed",
    bottom: 0,
    background: "white",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomGraphic({
  open,
  handleClose,
  exams,
  custom,
  beforeSave,
}) {
  const forms = [
    { id: "d1", description: "Altura", checked: false },
    { id: "d2", description: "Frequência Cardiaca", checked: false },
    { id: "d3", description: "Frequência Respiratória", checked: false },
    { id: "d4", description: "Glicemia Capilar", checked: false },
    {
      id: "d5",
      description: "Índice de Massa Corporal (I.M.C)",
      checked: false,
    },
    { id: "d6", description: "Peso", checked: false },
    { id: "d7", description: "Pressão Arterial", checked: false },
    { id: "d8", description: "Saturação", checked: false },
    { id: "d9", description: "Temperatura", checked: false },
  ];

  const classes = useStyles();
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [list, setList] = useState(forms);
  const [listExams, setListExams] = useState([]);
  const user = useSelector((state) => state.auth.authUser);

  useEffect(() => {
    let aux = [];
    if (custom.view) {
      aux = custom.view.split(",");
    }
    let obj = exams.map((row) => ({
      id: row.id,
      description: row.test,
      checked: aux.indexOf(row.id) !== -1,
    }));

    setListExams([
      ...obj.filter((row) => row.checked),
      ...obj.filter((row) => !row.checked),
    ]);
  }, [exams]);

  useEffect(() => {
    if (custom.id) {
      let aux = custom.view.split(",");

      let markeds = [];
      let notMarkeds = [];

      list.forEach((row) => {
        if (aux.indexOf(row.id) !== -1) {
          markeds.push({
            ...row,
            checked: true,
          });
        } else {
          notMarkeds.push({
            ...row,
            checked: false,
          });
        }
      });
      setList([...markeds, ...notMarkeds]);
      setId(custom.id);
    }
  }, [custom]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setList(arrayMove(list, oldIndex, newIndex));
  };

  const handleCheck = (cat, value) => {
    let markeds = 0;
    let oldIndex = 0;
    let aux = list.map((row, index) => {
      if (row.id === cat) {
        oldIndex = index;
        return { ...row, checked: value };
      } else {
        if (row.checked) markeds++;
        return row;
      }
    });
    setList(arrayMove(aux, oldIndex, markeds));
  };

  const handleCheckExam = (cat, value) => {
    let markeds = 0;
    let oldIndex = 0;
    let aux = listExams.map((row, index) => {
      if (row.id === cat) {
        oldIndex = index;
        return { ...row, checked: value };
      } else {
        if (row.checked) markeds++;
        return row;
      }
    });
    setListExams(arrayMove(aux, oldIndex, markeds));
  };

  const handleSave = () => {
    let marked = list.filter((row) => row.checked);
    let ids = marked.map((row) => row.id);

    let markedExams = listExams
      .filter((row) => row.checked)
      .map((row) => row.id);

    let aux = [...ids, ...markedExams];

    Api.post(`/ms_configuration/custom/savePatientGraphic/`, {
      user,
      id,
      forms: aux.join(","),
    })
      .then((res) => {
        if (!id) {
          setId(res.data.id);
        }
        beforeSave();
        handleClose();
        dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
        setTimeout(() => dispatch(closeAlertMessage()), 5000);
      })
      .catch(() => {
        dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
        setTimeout(() => dispatch(closeAlertMessage(), 5000));
      });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            overflowX: "hidden",
          },
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Históricos de Saúde
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="row pl-3 pr-3">
          <div className={classes.content}>
            <div className="animated slideInUpTiny animation-duration-3">
              <Typography variant="subtitle1" className="mt-3">
                <strong>Dados Vitais e Antropometria</strong>
              </Typography>
              <FormContainer
                forms={list}
                handleCheck={handleCheck}
                onSortEnd={onSortEnd}
                useDragHandle={true}
                sort={true}
              />
              {listExams.length > 0 && (
                <>
                  <Typography variant="subtitle1" className="mt-3">
                    <strong>Exames</strong>
                  </Typography>
                  <FormContainer
                    forms={listExams}
                    handleCheck={handleCheckExam}
                    onSortEnd={onSortEnd}
                    useDragHandle={true}
                    sort={true}
                  />
                </>
              )}
            </div>
          </div>
          <div className={`col-12 py-3 ${classes.buttons}`}>
            <BtnCan title="Fechar" onClick={handleClose} float="left" />
            <BtnSave title="Salvar" onClick={handleSave} float="right" />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
