import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { showMsg } from "services/functions";

import { Typography, Icon, IconButton, Slider } from "@material-ui/core";
import { ButtonLarge } from "components/Button";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import RestoreIcon from "@material-ui/icons/Restore";
import ViewHistory from "./ViewHistory";

const useStyles = makeStyles((theme) => ({
  icon: {
    overflow: "inherit",
    fontSize: "60px",
    borderRadius: "50px",
    width: "100px",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    border: "solid 1px",
    padding: theme.spacing(1),
    borderRadius: "50%",
  },
}));

const CustomSlider = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
    marginTop: "1rem",
    color: "#dcdcdc",
    cursor: "default",
  },
  thumb: {
    backgroundColor: "#fff",
    border: "1px solid #707070",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% - 3px)",
    top: "-25px",
    "& > span": {
      width: "1.6rem",
      height: "1.6rem",
    },
    "& span > span": {
      color: "#000",
    },
  },
}))(Slider);

function patientQuizes({ setQuiz, progress, getData }) {
  const [show, setShow] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.authUser);
  const width = useSelector((state) => state.settings.width);
  const max =
    progress.watter && progress.watter.max_watter
      ? Number(progress.watter.max_watter)
      : 10;
  const [maxComplaint, setMaxComplaint] = useState(0);
  const classes = useStyles();
  const [viewHistory, setViewHistory] = useState({
    open: false,
    question: "complaint",
  });

  useEffect(() => {
    if (progress.complaint && progress.complaint.what_complaint) {
      let { what_complaint } = progress.complaint;
      let max_comp = 0;
      what_complaint.forEach((el) => {
        if (Number(el.score) > max_comp) {
          max_comp = Number(el.score);
        }
      });
      setMaxComplaint(max_comp);
    }
  }, [progress]);

  return (
    <div className={width > 500 && "row px-4 d-flex"}>
      {/* PROBLEMAS NÃO RELATADOS */}
      <div
        className={
          width < 500 ? "card" : "col-sm-6 col-md-4 col-lg-3 d-flex p-2"
        }
      >
        <div
          className={width < 500 ? "col-12 py-2" : "col-12 card p-3 shadow-sm"}
        >
          <div className="row">
            <div
              className={
                width < 500
                  ? "col-4 d-flex align-items-center justify-content-center"
                  : "col-12 d-flex align-items-center justify-content-center"
              }
            >
              <Icon
                className={classes.icon}
                style={{ backgroundColor: "#BB74F9" }}
              >
                📋
              </Icon>
            </div>
            <div className={width < 500 ? "col-8" : "col-12"}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Problemas não relatados</strong>
              </Typography>
              <p>
                Informe algum problema que ainda não foi relatado ao médico.
              </p>
              <div
                className={`row col-12 align-items-center ${width > 500 &&
                  "p-0 m-0"}`}
                style={{
                  minHeight:
                    width > 500 && !progress.complaint ? "60px" : "unset",
                }}
              >
                {progress.complaint && (
                  <CustomSlider
                    step={1}
                    marks
                    min={1}
                    max={10}
                    value={maxComplaint}
                    valueLabelDisplay="on"
                    aria-labelledby="continuous-slider"
                  />
                )}
              </div>
              {width > 500 && (
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <ButtonLarge onClick={() => setQuiz("pain")}>
                    Responder
                  </ButtonLarge>
                  <IconButton
                    onClick={() =>
                      setViewHistory({
                        open: true,
                        question: "complaint",
                      })
                    }
                    className={classes.iconButton}
                  >
                    <RestoreIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          {width < 500 && (
            <div className="row align-items-center justify-content-between">
              <div className="col-8">
                <ButtonLarge onClick={() => setQuiz("pain")}>
                  Responder
                </ButtonLarge>
              </div>
              <div className="col-4 d-flex justify-content-center">
                <IconButton
                  onClick={() =>
                    setViewHistory({
                      open: true,
                      question: "complaint",
                    })
                  }
                  className={classes.iconButton}
                >
                  <RestoreIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* QUALIDADE DO SONO */}
      <div
        className={
          width < 500 ? "card" : "col-sm-6 col-md-4 col-lg-3 d-flex p-2"
        }
      >
        <div
          className={width < 500 ? "col-12 py-2" : "col-12 card p-3 shadow-sm"}
        >
          <div className="row">
            <div
              className={
                width < 500
                  ? "col-4 d-flex align-items-center justify-content-center"
                  : "col-12 d-flex align-items-center justify-content-center"
              }
            >
              <Icon
                className={classes.icon}
                style={{ backgroundColor: "#57cb06" }}
              >
                😴
              </Icon>
            </div>

            <div className={width < 500 ? "col-8" : "col-12"}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Qualidade do Sono</strong>
              </Typography>
              <p>Nos conte como foi a sua noite de sono.</p>
              <div
                className={`row col-12 align-items-center ${width > 500 &&
                  "p-0 m-0"}`}
                style={{
                  minHeight: width > 500 && !progress.sleep ? "60px" : "unset",
                }}
              >
                {progress.sleep && (
                  <CustomSlider
                    step={1}
                    marks
                    min={1}
                    max={10}
                    value={Number(progress.sleep.sleep)}
                    valueLabelDisplay="on"
                    aria-labelledby="continuous-slider"
                  />
                )}
              </div>
              {width > 500 && (
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <ButtonLarge
                    disable={progress.sleep && progress.sleep.sleep}
                    onClick={() =>
                      progress.sleep && progress.sleep.sleep
                        ? showMsg(
                            "error",
                            "Apague a resposta do dia no histórico para responder novamente",
                            dispatch
                          )
                        : setQuiz("sleep")
                    }
                  >
                    Responder
                  </ButtonLarge>
                  <IconButton
                    onClick={() =>
                      setViewHistory({
                        open: true,
                        question: "sleep",
                      })
                    }
                    className={classes.iconButton}
                  >
                    <RestoreIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          {width < 500 && (
            <div className="row align-items-center justify-content-between">
              <div className="col-8">
                <ButtonLarge
                  disable={progress.sleep && progress.sleep.sleep}
                  onClick={() =>
                    progress.sleep && progress.sleep.sleep
                      ? showMsg(
                          "error",
                          "Apague a resposta do dia no histórico para responder novamente",
                          dispatch
                        )
                      : setQuiz("sleep")
                  }
                >
                  Responder
                </ButtonLarge>
              </div>
              <div className="col-4 d-flex justify-content-center">
                <IconButton
                  onClick={() =>
                    setViewHistory({
                      open: true,
                      question: "sleep",
                    })
                  }
                  className={classes.iconButton}
                >
                  <RestoreIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* HIDRATAÇÃO */}
      <div
        className={
          width < 500 ? "card" : "col-sm-6 col-md-4 col-lg-3 d-flex p-2"
        }
      >
        <div
          className={width < 500 ? "col-12 py-2" : "col-12 card p-3 shadow-sm"}
        >
          <div className="row">
            <div
              className={
                width < 500
                  ? "col-4 d-flex align-items-center justify-content-center"
                  : "col-12 d-flex align-items-center justify-content-center"
              }
            >
              <Icon
                className={classes.icon}
                style={{ backgroundColor: "#79d0fe" }}
              >
                💧
              </Icon>
            </div>
            <div className={width < 500 ? "col-8" : "col-12"}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Hidratação</strong>
              </Typography>
              <p>Você está se mantendo hidratado.</p>
              {show === "hidratation" && (
                <p>
                  -Número de copos <br />
                </p>
              )}
              <div
                className={`row col-12 align-items-center ${width > 500 &&
                  "p-0 m-0"}`}
                style={{
                  minHeight: width > 500 && !progress.watter ? "60px" : "unset",
                }}
              >
                {progress.watter && (
                  <CustomSlider
                    step={1}
                    marks
                    min={1}
                    max={max + 4}
                    value={progress.watter.watter}
                    valueLabelDisplay="on"
                    aria-labelledby="continuous-slider"
                  />
                )}
              </div>
              {width > 500 && (
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <ButtonLarge onClick={() => setQuiz("hydration")}>
                    Responder
                  </ButtonLarge>
                  <IconButton
                    onClick={() =>
                      setViewHistory({
                        open: true,
                        question: "watter",
                      })
                    }
                    className={classes.iconButton}
                  >
                    <RestoreIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          {width < 500 && (
            <div className="row align-items-center justify-content-between">
              <div className="col-8">
                <ButtonLarge onClick={() => setQuiz("hydration")}>
                  Responder
                </ButtonLarge>
              </div>
              <div className="col-4 d-flex justify-content-center">
                <IconButton
                  onClick={() =>
                    setViewHistory({
                      open: true,
                      question: "watter",
                    })
                  }
                  className={classes.iconButton}
                >
                  <RestoreIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* HISTÓRICO */}
      <ViewHistory
        open={viewHistory.open}
        question={viewHistory.question}
        patient={user}
        max_watter={progress.max_watter ? progress.max_watter : 8}
        handleClose={() =>
          setViewHistory({
            open: false,
            question: "",
          })
        }
        getDataQuizes={getData}
      />
    </div>
  );
}

export default patientQuizes;
