import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";
import { ANAMNESIS_ARRAY } from "constants/CustomAnamnesis";
import { changeConfigAnamnesis } from "actions/Configuration";

import { BtnSave, BtnCan } from "components/Button";
import { arrayMove } from "react-sortable-hoc";
import { Tooltip, IconButton, Button } from "@material-ui/core";
import CircularProgress from "components/CircularProgress";
import FormContainer from "../SortableContainer";

export default function Anamnesis({ close }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);

  const user = useSelector(({ auth }) => auth.authUser);

  const view = useSelector(
    ({ configuration }) => configuration.config_anamnesis.view
  );
  const id = useSelector(
    ({ configuration }) => configuration.config_anamnesis.id
  );

  useEffect(() => {
    if (view) {
      let ids = view.split(",");
      let new_arr = [];
      ids.forEach((row) => {
        let obj = ANAMNESIS_ARRAY.find((el) => el.id === row);
        new_arr.push({ ...obj, checked: true });
      });
      let notInids = ANAMNESIS_ARRAY.filter(
        (row) => ids.indexOf(row.id) === -1
      );

      setList([
        ...new_arr,
        ...notInids.map((row) => ({ ...row, checked: false })),
      ]);
    } else {
      setList(ANAMNESIS_ARRAY.map((row) => ({ ...row, checked: true })));
    }
  }, [view]);

  console.log(list);
  function handleCheck(cat, value) {
    setList(
      list.map((row) => (row.id === cat ? { ...row, checked: value } : row))
    );
  }
  function onSortEnd({ oldIndex, newIndex }) {
    setList(arrayMove(list, oldIndex, newIndex));
  }
  function resetConfig() {
    setList(ANAMNESIS_ARRAY.map((row) => ({ ...row, checked: true })));
  }
  function handleSave() {
    let marked = list.filter((row) => row.checked);
    let ids = marked.map((row) => row.id);

    Api.post("/ms_configuration/custom/saveAnamnesis", {
      user,
      id: id ? id : null,
      anamnesis: ids.join(","),
    })
      .then(({ data }) => {
        dispatch(changeConfigAnamnesis(data.id, data.ids));
        showMsg("success", "Configuração salva com sucesso!", dispatch);
        close();
      })
      .catch(() => {
        showMsg("error", "Erro ao salvar!", dispatch);
      });
  }
  return (
    <>
      {loader && <CircularProgress />};
      {!loader && (
        <div className="row">
          <h2 className="text-primary font-weight-semibold w-100">
            Customização da Anamnese
            <Tooltip title="Fechar" placement="top">
              <IconButton
                aria-label="Fechar"
                onClick={() => close()}
                style={{ float: "right", marginTop: "-10px" }}
              >
                <i className="zmdi zmdi-close" />
              </IconButton>
            </Tooltip>
          </h2>
          <div className="col-12">
            <Button
              color="primary"
              className="jr-btn jr-btn-md"
              onClick={resetConfig}
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-settings zmdi-hc-lg" />
              <span> Resetar configurações </span>
            </Button>
          </div>
          <div className="col-12">
            <div className="animated slideInUpTiny animation-duration-3">
              <FormContainer
                forms={list}
                handleCheck={handleCheck}
                onSortEnd={onSortEnd}
                useDragHandle={true}
                sort={true}
              />
            </div>
          </div>
          <div className="col-12 mt-3">
            <BtnCan title="Fechar" onClick={close} float="left" />
            <BtnSave title="Salvar" onClick={handleSave} float="right" />
          </div>
        </div>
      )}
    </>
  );
}
