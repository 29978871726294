import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%"
  },
  root: {
    backgroundColor: "#fff"
  },
  selected: {
    backgroundColor: "#e2e2e8 !important"
  }
};

class AutocompleteMedicine extends Component {
  state = {
    suggestions: [],
    selected: null,
    error: false
  };

  // componentWillReceiveProps(nextProps) {
  //   this.setState({ error: nextProps.error });
  //   if (nextProps.medicine.id) {
  //     const { id, name } = nextProps.medicine;
  //     this.setState({ selected: [{ id, name_display: name }] });
  //   }
  // }

  getSuggestions = e => {
    if (e.target.value.length > 2) {
      Api.post(`/microservice/autocomplete/getMedicine/`, {
        term: e.target.value
      }).then(res => {
        const { data } = res;
        this.setState({
          suggestions: data
        });
      });
    }
  };

  getClassImage = type => {
    let imgIcon = "";
    switch (type) {
      case "Similar":
        imgIcon = require("assets/images/consult/similar.jpg");
        break;
      case "Generico":
        imgIcon = require("assets/images/consult/generico.jpg");
        break;
      case "Referencia":
      case "Especifico":
        imgIcon = require("assets/images/consult/referencia.jpg");
        break;
      case "Biológicos":
        imgIcon = require("assets/images/consult/biologico.jpg");
        break;
      default:
        imgIcon = require("assets/images/consult/outros.jpg");
        break;
    }
    return imgIcon;
  };

  getTarge = targe => {
    if (targe === "TP") {
      return require("assets/images/consult/Preta.svg");
    } else if (targe === "TV") {
      return require("assets/images/consult/Vermelha.svg");
    } else {
      return require("assets/images/consult/Branca.svg");
    }
  };

  renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    const { classes } = this.props;
    let imgIcon = this.getClassImage(suggestion.medtype_display);
    let targe = this.getTarge(suggestion.targe);

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}
        classes={{
          root: classes.root,
          selected: classes.selected
        }}
      >
        <div style={{ width: "100%" }}>
          <span style={{ float: "left" }}>
            <img src={imgIcon} width="30" height="30" alt="icon-medicine" />
            <img
              src={targe}
              alt="icon-targe"
              width="30"
              height="30"
              style={{ marginLeft: "30px" }}
            />
            <strong style={{ marginLeft: "30px" }}>
              {suggestion.name_display} {suggestion.formulation}
            </strong>{" "}
            - <small>{suggestion.form}</small>
          </span>
          <span
            style={{
              float: "right",
              paddingRight: "10px",
              fontWeight: "300",
              width: "100px"
            }}
          >
            {suggestion.laboratory_display}
          </span>
        </div>
      </MenuItem>
    );
  }

  renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        error={this.state.error}
        required
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input
          },
          ...InputProps
        }}
        style={{ marginTop: "0" }}
        onKeyUp={this.getSuggestions}
        onChange={this.handleChange}
        autoFocus={true}
      />
    );
  }

  render() {
    const { classes, handleChangeAutocomplete } = this.props;
    const { suggestions } = this.state;

    return (
      <Downshift
        onSelect={(selectedItem, obj) => {
          let item = suggestions.filter(row => {
            return (
              row.name_display + " " + row.formulation + " - " + row.form ===
              selectedItem
            );
          });
          handleChangeAutocomplete(item[0]);
          obj.setState({ inputValue: "" });
        }}
        // selectedItem={selected ? selected[0].name_display : null}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex
        }) => (
          <div className={classes.container}>
            {this.renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                id: "ac-medicine"
              }),
              label: "Medicamento:",
              margin: "normal"
            })}
            {isOpen ? (
              <Paper square>
                {suggestions.map((suggestion, index) =>
                  this.renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item:
                        suggestion.name_display +
                        " " +
                        suggestion.formulation +
                        " - " +
                        suggestion.form
                    }),
                    highlightedIndex,
                    selectedItem
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

AutocompleteMedicine.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AutocompleteMedicine);
