import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editHealthPlan, addHealthPlan } from "actions/configuration/Profile";
import * as alertActions from "actions/Alerts";
import Api from "services/api";
import Button from "@material-ui/core/Button";
import { SingleSelect } from "react-select-material-ui";
class FormSpecialty extends Component {
  state = {
    id: "",
    specialty: [],
    new_plan: false,
    false: true,
    selectedSpecialty: ""
  };

  componentDidMount() {
    this.getAllSpecialtyForArea();
  }

  getAllSpecialtyForArea() {
    const areaId = this.props.areaId;
    Api.post(`/microservice/profile/getAllSpecialtyForArea/`, { areaId })
      .then(res => {
        this.setState({ specialty: res.data.record });
      })
      .catch(() => {});
  }

  selectSpecialty(event) {
    this.setState({ selectedSpecialty: event });
  }

  save() {
    const user = this.props.user;
    const idSpecialty = this.state.selectedSpecialty;
    Api.post(`/microservice/profile/saveNewUserSpecialty/`, {
      user,
      idSpecialty
    })
      .then(res => {
        this.props.viewAlertMessage("success", "Salvo com sucesso!");
        setTimeout(() => this.props.closeAlertMessage(), 5000);
        this.props.handleClose();
        this.props.updateListUserSpecialty(res.data.record);
      })
      .catch(() => {
        this.props.viewAlertMessage("error", "Ocorreu um erro ao salvar!");
        setTimeout(() => this.props.closeAlertMessage(), 5000);
        this.props.handleClose();
      });
  }
  render() {
    const { id } = this.state;
    const specialty = this.state.specialty;
    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="text-primary font-weight-semibold">
            {id > 0 ? "Editar especialidade" : "Cadastrar especialidade"}
          </h2>
        </div>

        <div className="col-12">
          <SingleSelect
            label="Selecione a especialidade:"
            value={this.state.selectedSpecialty}
            options={specialty}
            onChange={e => this.selectSpecialty(e)}
            SelectProps={{
              isCreatable: false,
              msgNoOptionsAvailable: "Todas as opções foram selecionadas",
              msgNoOptionsMatchFilter: "Nenhuma opção para este filtro"
            }}
            style={{ marginTop: "10px", zIndex: "1000" }}
          />
        </div>

        <div className="col-12 mt-5">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={() => this.props.handleClose()}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>

          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm bg-success"
            onClick={() => this.save()}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.authUser,
    areaId: state.auth.area
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { editHealthPlan, addHealthPlan, ...alertActions },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormSpecialty);
