const INITIAL_STATE = {
  loaded: false,
  calendars: [],
  events: [],
  distinct_user: [],
  modal_open: false,
  modal_type: "",
  event_id: "",
  calendar_id: "",
  calendar_to_scheduling: "",
  calendar_doctor: "",
  date_selected: "",
  date_calendar: {
    start: "",
    end: "",
  },
  config: {
    loaded: false,
    min_time: "06:00",
    max_time: "20:00",
    slot_interval: "00:30:00",
    mode_default: "timeGridWeek",
    hidden_days: [],
    accept: true,
    inMoment: true,
    treeDays: false,
    oneDay: true,
    inDay: false,
  },
  logs: [],
};

export default function calendar(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_CALENDAR":
      return INITIAL_STATE;
    case "GET_CALENDARS_SUCCESS":
      if (action.payload.type === "3") {
        return {
          ...state,
          calendars: action.payload.record,
          distinct_user: action.payload.distinctUser,
          calendar_id:
            action.payload.record.length > 0 ? action.payload.record[0].id : "",
          loaded: true,
        };
      } else {
        return {
          ...state,
          calendars: action.payload.record,
          distinct_user: action.payload.distinctUser,
          calendar_id:
            action.payload.width > 500 ? "integrated" : "integrated_week",
          loaded: true,
        };
      }
    case "GET_CALENDARS_FAIL":
      return {
        ...state,
        loaded: true,
      };
    case "GET_EVENTS_SUCCESS":
      return {
        ...state,
        events: action.payload.record,
      };
    case "GET_EVENTS_FAIL":
      return {
        ...state,
        events: [],
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modal_open: false,
      };
    case "DATE_CLICK":
      let info_cal = action.calendar
        ? state.calendars.find((row) => row.id === action.calendar)
        : {
            calendar: "",
            doctor_id: "",
            address_id: "",
          };
      console.log("action", action);
      return {
        ...state,
        event_id: "",
        date_selected: action.date,
        calendar_to_scheduling: action.calendar,
        calendar_doctor: info_cal.doctor_id,
        modal_open: true,
        modal_type:
          (!info_cal.address_id || info_cal.address_id === "0") &&
          action.calendar
            ? "add_event"
            : "add",
      };
    case "EVENT_CLICK":
      return {
        ...state,
        event_id: action.event,
        date_selected: "",
        modal_open: true,
        modal_type:
          !action.patient && action.number_patients < 2 ? "view_event" : "view",
      };
    case "CALENDAR_NOFICATION":
      return {
        ...state,
        event_id: action.event,
        date_selected: "",
        modal_open: true,
        modal_type: "view",
      };
    case "ALTER_MODAL_TYPE":
      return {
        ...state,
        modal_type: action.modal_type,
      };
    case "GET_CONFIGS_SUCCESS":
      return {
        ...state,
        config: action.payload.record,
      };
    case "LOAD_CONFIGS_CALENDAR":
      return {
        ...state,
        config: { ...state.config, loaded: true },
      };
    case "CHANGE_CONFIG_CALENDAR":
      return {
        ...state,
        config: action.config,
      };
    case "CHANGE_CALENDAR":
      return {
        ...state,
        calendar_id: action.calendar,
      };
    case "GET_EVENT_LOG_SUCCESS":
      return {
        ...state,
        logs: action.payload,
      };
    case "HANDLE_DATE":
      return {
        ...state,
        date_calendar: action.date,
      };
    case "CHANGE_CALENDAR_INTEGRATED_WEEK":
      return {
        ...state,
        calendar_to_scheduling: action.calendar,
        calendar_doctor: action.doctor,
      };
    case "REMOVE_CALENDAR":
      return {
        ...state,
        calendars: state.calendars.filter((row) => row.id !== action.calendar),
        calendar_id: "integrated",
      };
    case "UPDATE_CALENDARS":
      return {
        ...state,
        calendars: action.calendars,
        calendar_id: action.calendar_selected,
      };

    default:
      return state;
  }
}
