import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import { BtnAdd } from "components/Button";
import FormPatologies from "./FormPatologies";
import {
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import Api from "services/api";
import NoRecord from "components/Alert/NoRecord";
import InfoRegister from "../InfoRegister";

export default function Patologies({
  match,
  patient,
  type,
  modal,
  beforeSave,
}) {
  const [view, setView] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Api.post("/microservice/personalhistory/getPatologiesPatient/", {
      patient,
    }).then((res) => {
      setList(res.data.record);
    });
  };

  const handleValidate = (id) => {
    setList(
      list.map((row) =>
        row.id === id
          ? { ...row, approved_by: localStorage.getItem("userAudit") }
          : row
      )
    );
  };

  const getContent = () => (
    <div className="row">
      <div className="col-12">
        <h4
          className="text-primary"
          style={{
            display: "inline-flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          Patologias
          {view && (
            <BtnAdd
              title={list.length > 0 ? "Alterar" : "Adicionar"}
              onClick={() => setView(false)}
              float="right"
            />
          )}
        </h4>
      </div>
      {view ? (
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          {list.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="40%"></TableCell>
                  <TableCell width="50%">Observações</TableCell>
                  <TableCell width="10%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.personal_description
                        ? row.personal_description
                        : row.short_description
                        ? row.short_description
                        : "Não possui Patologias"}
                    </TableCell>
                    <TableCell>
                      {row.observation ? row.observation : ""}
                    </TableCell>
                    <TableCell align="right">
                      <InfoRegister
                        form={type}
                        type="patologies"
                        id={row.id}
                        patient={patient}
                        added={row.added_for}
                        approved={row.approved_by}
                        created={row.created}
                        handleValidate={handleValidate}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoRecord />
          )}
        </div>
      ) : (
        <FormPatologies
          patient={patient}
          type={type}
          record={list}
          handleClose={() => setView(true)}
          beforeSave={() => {
            if (!modal) {
              getData();
              setView(true);
            } else {
              beforeSave();
            }
          }}
        />
      )}
    </div>
  );

  if (!modal) {
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          title={<IntlMessages id="module.consult.personal_history" />}
          match={match}
        />
        <Card className="shadow border-0">
          <CardBody>{getContent()}</CardBody>
        </Card>
      </div>
    );
  } else {
    return getContent();
  }
}
