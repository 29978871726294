const INITIAL_STATE = {
  loader: true,
  detail_food: []
};

export default function reducerTbca(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_DETAIL_FOOD":
      return {
        ...state,
        id: action.id,
        loader: false,
        detail_food: []
      };

    case "GET_DETAIL_FOOD_SUCCESS":
      return {
        ...state,
        loader: false,
        detail_food: action.payload
      };
    case "RESET_TBCA":
      return INITIAL_STATE;
    default:
      return state;
  }
}
