import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import {
  Button,
  IconButton,
  Tooltip,
  TextField,
  MenuItem
} from "@material-ui/core";
import CircularProgress from "components/CircularProgress";

function FormSpecialty({ data, addressId, close, add }) {
  const [specialty, setSpecialty] = useState("");
  const [specialtyOpts, setSpecialtyOpts] = useState([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(true);
  const user = useSelector(state => state.auth.authUser);
  const secretary = useSelector(state => state.auth.secretary);
  const user_manage = useSelector(state => state.auth.user_manage);
  const dispatch = useDispatch();

  useEffect(() => {
    Api.post(`/microservice/profile/getUserSpecialty/`, {
      user: secretary ? user_manage.id : user
    }).then(res => {
      let ids = data.map(row => row.specialty_id);
      setSpecialtyOpts(
        res.data.record.filter(row => ids.indexOf(row.specialty_id) === -1)
      );
      setLoader(false);
    });
  }, []);

  function save() {
    if (specialty) {
      Api.post(`/ms_system/workplace/saveSpecialty/`, {
        addressId,
        specialty
      })
        .then(res => {
          let info_specialty = specialtyOpts.find(
            row => row.specialty_id === specialty
          );
          add({
            id: res.data.id,
            specialty: info_specialty.name,
            specialty_id: specialty
          });
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
          close();
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage(), 5000));
        });
    } else {
      setError(true);
    }
  }

  if (loader) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="text-primary font-weight-semibold">
            Especialidades
            <Tooltip title="Fechar" placement="top">
              <IconButton
                aria-label="Fechar"
                onClick={() => close()}
                style={{ float: "right", marginTop: "-10px" }}
              >
                <i className="zmdi zmdi-close" />
              </IconButton>
            </Tooltip>
          </h2>
        </div>

        <div className="col-12">
          {specialtyOpts.length > 0 ? (
            <TextField
              select
              label="Especialidade:"
              margin="normal"
              fullWidth
              value={specialty}
              onChange={e => setSpecialty(e.target.value)}
              error={error && !specialty ? true : false}
            >
              {specialtyOpts.map(row => (
                <MenuItem key={row.specialty_id} value={row.specialty_id}>
                  {row.name}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            "Você não possui mais especialidades para relacionar com este local de atendimento."
          )}
        </div>

        <div className="col-md-12 mt-5">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={() => close()}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>
          {specialtyOpts.length > 0 && (
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              onClick={() => save()}
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Salvar </span>
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default FormSpecialty;
