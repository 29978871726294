import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";
import ReceivedMessageCell from "./ReceivedMessageCell/index";
import SentMessageCell from "./SentMessageCell/index";
import BarProgress from "components/CircularProgress/ProgressBar";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  bgColor: {
    backgroundColor: "#f8f8f8",
  },
  fab: {
    width: "35px",
    height: "35px",
  },
}));

const Conversation = ({ loader, conversationData, selectedUser, toBottom }) => {
  const classes = useStyles();
  const openModalDiag = useSelector(({ chat }) => chat.chatModal);
  const minimaze = useSelector(({ chat }) => chat.minimaze);
  const [messageEnd, setMessageEnd] = useState();
  const selectedChat = useSelector(({ chat }) => chat.selectedChat);
  useEffect(() => {
    if (!minimaze[selectedUser.chat_id]) {
      let ref = getDataFirebase().child(
        `chats${getEnviromentFirebase()}/${selectedUser.chat_id}`
      );
      ref
        .orderByChild("read")
        .equalTo(false)
        .once("value", (snap) => {
          snap.val() && setFalseNotRead(snap.val());
        });
    }
  }, [selectedUser.chat_id, minimaze, conversationData]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  }, [selectedChat, messageEnd]);

  const scrollToBottom = () => {
    if (messageEnd) {
      messageEnd.scrollIntoView({ behavior: "instant" });
    }
  };
  const scrollToBottomSmooth = () => {
    if (messageEnd) {
      messageEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  const setFalseNotRead = (msg) => {
    Object.keys(msg).forEach((key) => {
      if (msg[key].from === selectedUser.id) {
        getDataFirebase()
          .child(
            `chats${getEnviromentFirebase()}/${selectedUser.chat_id}/${key}`
          )
          .update({ read: true });
        getDataFirebase()
          .child(
            `notification${getEnviromentFirebase()}/${msg[key].pathNotify}`
          )
          .update({ read: true });
      }
    });
  };

  return (
    <div
      className={`chat-main-content mt-3 ${openModalDiag && classes.bgColor}`}
    >
      {loader && (
        <div className="loader-view w-100 mt-4">
          <BarProgress />
        </div>
      )}
      {Object.keys(conversationData).map((key, index) =>
        conversationData[key].from !== selectedUser.id ? (
          <SentMessageCell key={index} conversation={conversationData[key]} />
        ) : (
          <ReceivedMessageCell
            key={index}
            conversation={conversationData[key]}
            user={selectedUser}
          />
        )
      )}
      <div
        style={{ float: "left", clear: "both" }}
        ref={(el) => {
          setMessageEnd(el);
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          bottom: openModalDiag ? "84px" : "160px",
          right: openModalDiag ? "6px" : "35px",
        }}
      >
        <Zoom direction="left" in={toBottom} mountOnEnter unmountOnExit>
          <Fab
            onClick={scrollToBottomSmooth}
            style={{ float: "right" }}
            aria-label="scroll to bottom"
            className={openModalDiag ? classes.fab : ""}
          >
            <ExpandMoreIcon />
          </Fab>
        </Zoom>
      </div>
    </div>
  );
};

export default Conversation;
