// List
export function getListFinances(user) {
  return {
    type: "GET_LIST_FINANCE",
    user
  };
}

export function getTypes() {
  return {
    type: "GET_TYPES"
  };
}

export function accessFinance(id) {
  return {
    type: "ACCESS_FINANCE",
    id
  };
}

// Transaction
export function getTransactions(index) {
  return {
    type: "GET_TRANSACTION",
    index
  };
}

export function setTransactionPending(transaction) {
  return {
    type: "SET_TRANSACTION_PENDING",
    transaction
  };
}

export function setTransactionQuit(transaction, value, date) {
  return {
    type: "SET_TRANSACTION_QUIT",
    transaction,
    value,
    date
  };
}

export function filterTransaction(filter) {
  return {
    type: "FILTER_TRANSACTION",
    filter
  };
}

export function filterTransactionPending(filter) {
  return {
    type: "FILTER_TRANSACTION_PENDING",
    filter
  };
}

//Conta
export function getAccounts(index) {
  return {
    type: "GET_ACCOUNTS",
    index
  };
}

export function newAccount(form) {
  return {
    type: "NEW_ACCOUNT",
    form
  };
}

export function editAccount(form) {
  return {
    type: "EDIT_ACCOUNT",
    form
  };
}

export function deleteAccount(id) {
  return {
    type: "DELETE_ACCOUNT",
    payload: { id }
  };
}

// Suppliers
export function getSuppliers(index) {
  return {
    type: "GET_SUPPLIERS",
    index
  };
}

// Transações padrão
export function getPatternTransactions(index) {
  return {
    type: "GET_PATTERN_TRANSACTIONS",
    index
  };
}

// Report
export function filterReport(filter) {
  return {
    type: "FILTER_REPORT",
    filter
  };
}
