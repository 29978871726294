import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as alertActions from "actions/Alerts";
import Api from "services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import NoRecord from "components/Alert/NoRecord";
import FormSpecialty from "./FormSpecialty";

class Specialty extends React.Component {
  state = {
    open_modal: false,
    modal_delete: false,
    success_delete: false,
    userSpecialty: [],
    specialty_form: ""
  };

  // getAllSpecialtyForArea() {
  //   const areaId = this.props.areaId;
  //   Api.post(`/microservice/profile/getAllSpecialtyForArea/`, { areaId })
  //     .then(res => {
  //       let id = this.state.plan_form.id;
  //       this.props.plans.forEach((row, key) => {
  //         if (row.id === id) {
  //           this.props.plans.splice(key, 1);
  //         }
  //       });
  //       this.setState({ modal_delete: false, success_delete: true });
  //     })
  //     .catch(() => {
  //       this.props.viewAlertMessage(
  //         "error",
  //         "Ocorreu um erro, tente novamente!"
  //       );
  //       setTimeout(() => this.props.closeAlertMessage(), 5000);
  //       this.setState({ modal_delete: false, success_delete: false });
  //     });
  // }

  getUserSpecialty() {
    const user = this.props.user;
    Api.post(`/microservice/profile/getUserSpecialty/`, { user })
      .then(res => {
        this.setState({ userSpecialty: res.data.record });
      })
      .catch(() => {
        this.props.viewAlertMessage(
          "error",
          "Ocorreu um erro, tente novamente!"
        );
        setTimeout(() => this.props.closeAlertMessage(), 5000);
        this.setState({ modal_delete: false, success_delete: false });
      });
  }

  isEmpty(obj) {
    return obj ? Object.keys(obj).length === 0 : true;
  }

  newSpecialty = () => {
    this.setState({ open_modal: true, plan_form: "" });
  };

  handleClose = () => {
    this.setState({ open_modal: false });
  };

  handleSpecialty = () => {
    this.setState({ open_modal: false });
  };

  updateListUserSpecialty(val) {
    this.setState({ userSpecialty: val });
  }

  editSpecialty(plan) {
    this.setState({ open_modal: true, plan_form: plan });
  }

  alertDeleteSpecialty(id) {
    this.setState({ modal_delete: true, specialty_form: id });
  }

  onCancelSpecialty = () => {
    this.setState({ modal_delete: false, plan_form: "" });
  };

  deleteSpecialty(id) {
    Api.post(`/microservice/profile/deleteUserSpecialty/`, { id })
      .then(res => {
        let id = this.state.specialty_form;
        this.state.userSpecialty.forEach((row, key) => {
          if (row.idUserSpecialty === id) {
            this.state.userSpecialty.splice(key, 1);
          }
        });
        this.setState({ modal_delete: false, success_delete: true });
      })
      .catch(() => {
        this.props.viewAlertMessage(
          "error",
          "Ocorreu um erro, tente novamente!"
        );
        setTimeout(() => this.props.closeAlertMessage(), 5000);
        this.setState({ modal_delete: false, success_delete: false });
      });
  }

  componentDidMount() {
    this.getUserSpecialty();
  }

  render() {
    const { Specialty } = this.props;
    const { specialty_form, modal_delete, success_delete } = this.state;
    const userSpecialty = this.state.userSpecialty;
    return (
      <div className="row">
        <div className="col-md-12 div-header">
          <span className="card-title text-primary title-form">
            Especialidades
          </span>
          <div className="div-button">
            <Button
              onClick={() => this.newSpecialty()}
              className="jr-btn jr-btn-sm"
              color="primary"
              variant="contained"
            >
              <i className="zmdi zmdi-plus text-right" />{" "}
              <span> Adicionar</span>
            </Button>
          </div>
        </div>
        <Table className="table-history">
          <TableHead>
            <TableRow>
              <TableCell>Especialidades</TableCell>
              <TableCell>Data</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userSpecialty.map(row => {
              return (
                <TableRow key={row.idUserSpecialty}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {moment(row.created_at).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() =>
                        this.alertDeleteSpecialty(row.idUserSpecialty)
                      }
                      color="secondary"
                      aria-label="Remover"
                    >
                      <i className="zmdi zmdi-delete zmdi-hc-fw" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {/* Modal de edição / adição */}
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={this.state.open_modal}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
          scroll="body"
        >
          <DialogContent>
            <FormSpecialty
              // Specialty={specialty_form}
              updateListUserSpecialty={this.updateListUserSpecialty.bind(this)}
              handleClose={this.handleClose.bind(this)}
            />
          </DialogContent>
        </Dialog>

        <SweetAlert
          show={modal_delete}
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          confirmBtnText="Sim, excluir"
          confirmButtonColor="#bc0003"
          cancelBtnText="Cancelar"
          title="Excluir esta Especialidade?"
          onConfirm={() => this.deleteSpecialty(this.state.specialty_form)}
          onCancel={this.onCancelSpecialty}
        />
        <SweetAlert
          show={success_delete}
          success
          title="Especialidade excluida com sucesso!"
          onConfirm={() => this.setState({ success_delete: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.authUser,
    areaId: state.auth.area
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(alertActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Specialty);
