import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

// import { Container } from './styles';

function AlertMiui(props) {
  return (
    <Snackbar {...props}>
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={props.onClose}
        severity={props.severity}
      >
        {props.children}
      </MuiAlert>
    </Snackbar>
  );
}

export default AlertMiui;
