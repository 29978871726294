import React from "react";
import { ModalResponsive } from "components/ModalResponsive";
import { BtnCan, BtnSave } from "components/Button";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { getUrlPhoto } from "services/functions";
import { MenuItem, TextField, InputAdornment } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import { MultipleSelect } from "react-select-material-ui";

const useStyles = makeStyles({
  title: {
    fontWeight: "500",
  },
});

const weekdays = [
  {
    value: 0,
    label: "Domingo",
  },
  {
    value: 1,
    label: "Segunda-feira",
  },
  {
    value: 2,
    label: "Terça-feira",
  },
  {
    value: 3,
    label: "Quarta-feira",
  },
  {
    value: 4,
    label: "Quinta-feira",
  },
  {
    value: 5,
    label: "Sexta-feira",
  },
  {
    value: 6,
    label: "Sábado",
  },
];

const initial = {
  days: "",
  interval: "",
  dateInit: moment().format("YYYY-MM-DD"),
  dateEnd: "",
  weekdays: [],
};

export default function Modal({ open, close, data, saveMonitorPati }) {
  const classes = useStyles();
  const authUser = useSelector((state) => state.auth.authUser);
  const providerId = useSelector(
    (state) => state.auth.address_selected.user_id
  );

  const [state, setstate] = React.useState(initial);

  const handleSave = () => {
    saveMonitorPati({
      ...state,
      id: data.id,
      idPatient: data.patient,
      authUser,
      providerId,
    });
  };

  const handleChange = (type, row) => {
    if (type === "days") {
      setstate({
        ...state,
        [type]: row,
        dateEnd: moment(state.dateInit)
          .add(row - 1, "days")
          .format("YYYY-MM-DD"),
      });
    } else {
      setstate({
        ...state,
        [type]: row,
        weekdays: row === "Dias da Semana" ? [1, 2, 3, 4, 5] : [],
      });
    }
  };

  // const handleChangeDate = (type, now) => {
  //   console.log("now", now);
  //   var duration = moment.duration(state.dateInit.diff(now));
  //   console.log("duration", duration);

  //   setstate({
  //     ...state,
  //     dateEnd: now,
  //     days: duration ? duration._data.days : state.days,
  //   });
  // };

  const handleClose = () => {
    setstate(initial);
    close();
  };

  return (
    <ModalResponsive
      title={"Monitorar Paciente"}
      open={open}
      close={handleClose}
      maxWidth={"md"}
      buttons={
        <div className="col-12">
          <BtnCan title="Cancelar" onClick={handleClose} float="left" />
          <BtnSave title="Salvar" onClick={handleSave} float="right" />
        </div>
      }
    >
      <div className="col-12 d-flex">
        <div className="col-2 row justify-content-center">
          <Avatar
            alt="Remy Sharp"
            src={getUrlPhoto(data.patient, data.notificator_photo)}
          />
        </div>
        <div className="col-3 row">
          <div className="col-12 row">
            <span className={classes.title}>Nome do Paciente:</span>
          </div>
          <div className="col-12 row">
            <span>{data.patient_name}</span>
          </div>
        </div>
        <div className="col-3 row">
          <div className="col-12 row">
            <span className={classes.title}>Data de Nascimento:</span>
          </div>
          <div className="col-12 row">
            <span>{moment(data.patient_birthday).format("DD/MM/YYYY")}</span>
          </div>
        </div>
        <div className="col-2 row">
          <div className="col-12 row">
            <span className={classes.title}>Sexo:</span>
          </div>
          <div className="col-12 row">
            <span>
              {!data.gender
                ? "--"
                : data.gender === "1"
                ? "Feminino"
                : "Masculino"}
            </span>
          </div>
        </div>
        <div className="col-3 row">
          <div className="col-12 row">
            <span className={classes.title}>CPF:</span>
          </div>
          <div className="col-12 row">
            <span>{data.cpf}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-6 mt-3">
          <TextField
            id="Intervalo"
            select
            label="Intervalo:"
            value={state.interval}
            onChange={(e) => handleChange("interval", e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            // error={error && !state.interval}
            // helperText={error && !state.interval && "Selecione um intervalo"}
          >
            <MenuItem key="1" value="Diário">
              Diário
            </MenuItem>
            <MenuItem key="12" value="Dias da Semana">
              Dias da Semana
            </MenuItem>
            <MenuItem key="4" value="Semanal">
              Semanal
            </MenuItem>
            <MenuItem key="5" value="15 em 15 dias">
              De 15 em 15 dias
            </MenuItem>
            <MenuItem key="6" value="Mensal">
              Mensal
            </MenuItem>
          </TextField>
        </div>
        {state.interval === "Dias da Semana" && (
          <div className="col-md-8 col-6 mt-3">
            <MultipleSelect
              label="Dias da Semana:"
              value={state.weekdays}
              onChange={(e, obj) => {
                let arr = e;
                if (e.indexOf(7) !== -1) {
                  arr = [1, 2, 3, 4, 5];
                } else if (e.indexOf(8) !== -1) {
                  arr = [1, 2, 3, 4, 5, 6];
                } else if (e.indexOf(9) !== -1) {
                  arr = [0, 1, 2, 3, 4, 5, 6];
                }
                setstate({ ...state, weekdays: arr });
              }}
              options={[...weekdays]}
              // SelectProps={{
              //   isCreatable: false,
              //   msgNoOptionsAvailable: "Todos as opções foram selecionadas",
              //   msgNoOptionsMatchFilter: "Nenhuma opção para este filtro",
              // }}
              style={{ marginTop: "-7px" }}
            />
          </div>
        )}
        <div className="col-md-3 col-6 mt-3">
          <TextField
            fullWidth
            type="number"
            label="Duração:"
            value={state.days}
            onChange={(e) => handleChange("days", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">dias</InputAdornment>
              ),
            }}
          />
        </div>
        <div className="col-md-3 col-6 mt-3">
          <TextField
            type="date"
            label="Inicio:"
            fullWidth
            value={state.dateInit}
            onChange={(e) => handleChange("dateInit", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-md-3 col-6 mt-3">
          <TextField
            type="date"
            label="Término:"
            fullWidth
            value={state.dateEnd}
            onChange={(e) => handleChange("dateEnd", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    </ModalResponsive>
  );
}
