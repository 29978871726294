const INITIAL_STATE = {
  appointment_id: "",
  appointment_action: "",
  patient_id: "",
  patient_name: "",
  patient_photo: "",
  date_evolution: "00/00/0000"
};

export default function evolutionPersonal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "RESET_EVOLUTION_PERSONAL":
      return INITIAL_STATE;
    case "ALTER_PATIENT_EVOLUTION":
      return INITIAL_STATE;
    case "CHOOSE_PATIENT":
      return { ...INITIAL_STATE, patient_id: action.patient };
    case "SET_APPOINTMENT":
      return {
        ...state,
        appointment_id: action.id,
        appointment_action: action.appointment_action
      };
    case "EVOLUTION_DATA_PATIENT":
      return {
        ...state,
        patient_id: action.payload.id,
        patient_name: action.payload.name,
        patient_photo: action.payload.photo
      };
    case "CHANGE_DATE_EVOLUTION":
      return {
        ...state,
        date_evolution: action.payload.date
      };
    default:
      return state;
  }
}
