import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import Cropper from "components/cropper";
// import Api from "services/api";

export default function ComponentPhoto({
  handleNext,
  handleBack,
  getState,
  stateInit,
}) {
  const [state, setState] = useState({ ...stateInit, src: null });
  const getImage = (image) => {
    setState({ ...state, image });
    getState("photo", { image: image });
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12" hidden={state.image === "" ? false : true}>
          <Cropper src={state.src} getCroppedImage={getImage} />
        </div>
        <div
          className="col-md-12"
          // style={{ textAlign: "center" }}
          hidden={state.image === "" ? true : false}
        >
          <Grid
            style={{
              border: "1px solid",
              display: "table-cell",
            }}
          >
            <img
              src={state.image}
              style={{ height: 300, margin: 10 }}
              alt="Foto de perfil"
            />
          </Grid>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: 10 }}
            onClick={() => {
              setState({ ...state, image: "", src: null });
            }}
          >
            Editar Imagem
          </Button>
        </div>
      </div>
      <div className="align-center mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className="jr-btn"
          style={{ float: "right", marginRight: 0 }}
        >
          {state.image === "" ? "Pular" : "Próximo"}
        </Button>
        <Button
          className="jr-btn"
          onClick={handleBack}
          style={{ float: "left", marginLeft: 0 }}
        >
          Voltar
        </Button>
      </div>
    </>
  );
}
