import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { saveNewTag } from "actions/consult/Tags";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  InputLabel
} from "@material-ui/core";
import { CirclePicker } from "react-color";

// import { Container } from './styles';

class ModalEdit extends Component {
  state = {
    id: this.props.tag.id,
    name: this.props.tag.name,
    desc: this.props.tag.description,
    color: this.props.tag.color
  };
  handleChangeName = e => {
    this.setState({ name: e.target.value });
  };

  handleChangeDesc = e => {
    this.setState({ desc: e.target.value });
  };

  handleChangePicker = (color, event) => {
    this.setState({ color: color.hex });
  };

  handleSave = () => {
    this.props.saveNewTag(this.state, this.props.address);
    this.props.handleClose();
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.tag.id,
      name: nextProps.tag.name,
      desc: nextProps.tag.description,
      color: nextProps.tag.color
    });
  }
  render() {
    const { name, color, desc } = this.state;
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <DialogTitle className="text-primary font-weight-semibold">
          {`Editar TAG ${this.props.tag.name}`}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-md-8">
              <TextField
                autoFocus
                fullWidth
                value={name}
                label="Digite o nome da tag"
                onChange={this.handleChangeName}
              />
              <TextField
                fullWidth
                multiline
                value={desc}
                rows="4"
                label="Digite a descrição"
                onChange={this.handleChangeDesc}
                style={{ marginTop: "15px" }}
              />
            </div>
            <div className="col-md-4">
              <InputLabel style={{ marginBottom: "15px" }}>
                Selecione a cor desejada
              </InputLabel>
              <CirclePicker
                onChangeComplete={this.handleChangePicker.bind(this)}
                color={color}
              />
            </div>
          </div>
          <div className="col-md-12" style={{ marginTop: "30px" }}>
            <Button
              color="secondary"
              variant="contained"
              className="jr-btn jr-btn-sm"
              style={{ float: "left" }}
              onClick={this.props.handleClose}
            >
              <i className="zmdi zmdi-close zmdi-hc-lg" />
              <span> Cancelar </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="jr-btn jr-btn-sm bg-success"
              style={{ float: "right" }}
              onClick={this.handleSave}
            >
              <i className="zmdi zmdi-check zmdi-hc-lg" />
              <span> Salvar </span>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  address: state.auth.address
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveNewTag }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEdit);
