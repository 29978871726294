import React from "react";
import { FormControl, FormHelperText, Input } from "@material-ui/core";

const MaskMoney = ({ label, value, onChange, error }) => {
  return (
    <FormControl className="my-3" fullWidth>
      <FormHelperText>{label}</FormHelperText>
      <Input
        value={value}
        onChange={onChange}
        onInput={(e) => {
          let aux = e.target.value ? e.target.value.replace(/[^0-9]/gi, "") : 0;
          let leng = aux.length;
          let aux2 =
            aux.substring(0, leng - 2) + "." + aux.substring(leng - 2, leng);
          e.target.value = parseFloat(aux2).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        }}
        error={error}
      />
    </FormControl>
  );
};

export default MaskMoney;
