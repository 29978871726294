import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ModalResponsive(props) {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      maxWidth={props.maxWidth !== undefined ? props.maxWidth : "md"}
      fullScreen={window.innerWidth < 1200} //window.innerWidth > 1200 => 💻 ||window.innerWidth < 1200 => 📱
      fullWidth={window.innerWidth > 1200}
      onClose={props.close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {window.innerWidth < 1200 ? (
          <AppBar style={{ padding: "17px" }}>
            <div className="d-flex col-12 m-0 p-0">
              <div className="col-10 m-0 p-0">{props.title}</div>
              <div className="col-1">
                <IconButton
                  className="p-0 m-0 justify-content-end"
                  edge="start"
                  color="inherit"
                  onClick={props.close}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </AppBar>
        ) : (
          <div className="col-12 m-0 p-0">
            <h2 className="text-primary font-weight-semibold">
              {props.title}
              <Tooltip title="Fechar" placement="top">
                <IconButton
                  aria-label="Fechar"
                  onClick={props.close}
                  style={{ float: "right", marginTop: "-10px" }}
                >
                  <i className="zmdi zmdi-close" />
                </IconButton>
              </Tooltip>
            </h2>
          </div>
        )}
      </DialogTitle>
      <DialogContent style={props.paddingContent ? { padding: "0px" } : {}}>
        <div
          id="alert-dialog-slide-description"
          style={
            window.innerWidth < 1200
              ? { marginTop: "20px", paddingLeft: "0px", paddingRight: "0px" }
              : {}
          }
        >
          {props.children}
        </div>
      </DialogContent>
      <DialogActions>{props.buttons}</DialogActions>
    </Dialog>
  );
}

// import { ModalResponsive } from "components/ModalResponsive";
// //COMO USAR 🔥
// <ModalResponsive
//   title={"Titulo Modal"} // titulo
//   open={state.modal}
//   close={handleClose}
//   maxWidth={"sm"} //não precisa instanciar(opcional) || default = "md"
//   // Estrutura rodape dos Buttons 🔽
//   buttons={
//     <div className="col-12">
//       <BtnCan title={"fechar"} onClick={handlecloseModal} float="left" />
//       <BtnSave
//         title={"Salvar Edição"}
//         onClick={handleSaveEditBudget}
//         float="right"
//       />
//     </div>
//   }
// >
//   {/* Estrutura a ser renderizada dentro do Modal 🔽*/}
//   <h1 className="Dialog-title">Bem-vindo</h1>
//   <p className="Dialog-message">Obrigado por visitar a nossa espaçonave!</p>
// </ModalResponsive>
