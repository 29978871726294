import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import NoRecord from "components/Alert/NoRecord";
import ComponentToPrintt from "app/routes/consult/routes/print";
import ViewPrescription from "./ViewPrescription";
import DownloadFiles from "components/Modal/DownloadFiles";

import $ from "jquery";

// import "./styles.css";
import Api from "services/api";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 10,
  },
  table: {
    minWidth: 700,
  },
});

class HistoryConsult extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    rows: [],
    mode_list: true,
    prescription: {},
    professional: "",
    modalDownload: false,
    files: [],
  };

  componentDidMount() {
    window.jQuery = $;

    Api.post(`/microservice/prescription/getHistoryPrescription/`, {
      patient: this.props.patient,
    })
      .then((res) => {
        this.setState({ rows: res.data.record });
      })
      .catch(() => {});
  }

  handleClose = () => {
    this.setState({ mode_list: true });
  };

  viewPrescription(n) {
    this.setState({
      mode_list: false,
      prescription: n,
    });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  downloadFiles = (files) => {
    if (files.length == 0) return;
    if (files.length === 1) {
      files.forEach((file) => {
        window.open(file.url_file);
      });
    } else {
      this.setState({ modalDownload: true, files });
    }
  };

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  render() {
    const { rows, rowsPerPage, page, mode_list, prescription } = this.state;

    return (
      <div className="container-person border">
        <h3
          className="title-container d-flex justify-content-center"
          style={{
            fontSize: "26px",
            fontWeight: "400",
          }}
        >
          Prescrição Médica
          {!mode_list ? (
            <IconButton
              onClick={() => this.handleClose()}
              color="primary"
              aria-label="Visualizar"
              style={{ float: "right" }}
            >
              <i className="zmdi zmdi-undo" />
            </IconButton>
          ) : (
            ""
          )}
        </h3>
        {mode_list ? (
          rows.length > 0 ? (
            <div
              className="col-12"
              style={{
                minWidth: "650",
                overflowX: "auto",
              }}
            >
              <Table className="">
                <TableHead>
                  <TableRow>
                    <TableCell>Profissional</TableCell>
                    <TableCell align="left">Especialidade</TableCell>
                    <TableCell align="left">Prescrição</TableCell>
                    <TableCell align="left">Data</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n) => {
                      return (
                        <TableRow hover tabIndex={-1} key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.prof_transcription !== null
                              ? n.prof_transcription
                              : n.professional}
                          </TableCell>
                          <TableCell align="left">
                            {n.speciality !== null ? n.speciality : "-"}
                          </TableCell>
                          <TableCell align="left">{n.name}</TableCell>
                          <TableCell align="left">{n.created_at}</TableCell>
                          <TableCell align="right">
                            <div
                              style={{ display: "flex", whiteSpace: "nowrap" }}
                            >
                              <IconButton
                                onClick={() => this.viewPrescription(n)}
                                color="primary"
                                aria-label="Visualizar"
                              >
                                <i className="zmdi zmdi-eye zmdi-hc-fw" />
                              </IconButton>
                              {n.id_prof === this.props.user ||
                              (this.props.permisions.print_prescription_med &&
                                n.address == this.props.address) ? (
                                n.file_signed === "1" ? (
                                  <IconButton
                                    onClick={() =>
                                      this.downloadFiles(n.infofile)
                                    }
                                    color="primary"
                                    aria-label="Download"
                                  >
                                    <i className="zmdi zmdi-cloud-download" />
                                  </IconButton>
                                ) : (
                                  <ComponentToPrintt
                                    dados={{
                                      doctor: n.id_prof,
                                      doctor_name: n.professional,
                                      address: n.address,
                                      name: "prescription",
                                      id: n.id,
                                      type: "iconButton",
                                    }}
                                    patient={this.props.patient}
                                  />
                                )
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      labelRowsPerPage="Registros por página"
                      labelDisplayedRows={({ from, to, count }) =>
                        `Mostrando ${from} - ${to} de ${count}`
                      }
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          ) : (
            <NoRecord />
          )
        ) : (
          <ViewPrescription
            prescription={prescription}
            onClose={this.handleClose.bind(this)}
          />
        )}
        <DownloadFiles
          open={this.state.modalDownload}
          files={this.state.files}
          handleClose={() => this.setState({ modalDownload: false, files: [] })}
        />
      </div>
    );
  }
}

HistoryConsult.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.authUser,
    address: state.auth.address,
    permisions: state.permissions,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(HistoryConsult));
