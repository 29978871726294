import React from "react";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import CustomScrollbars from "util/CustomScrollbars";

export default function AppUsersInfo({ toggleUserState }) {
  const width = useSelector(({ settings }) => settings.width);
  const userLoged = useSelector(({ auth }) => auth);

  return (
    <div className="chat-sidenav-main">
      <div className="bg-grey lighten-5 chat-sidenav-header">
        <div className="chat-user-hd mb-0">
          <IconButton
            className="back-to-chats-button"
            aria-label="back button"
            onClick={() => toggleUserState()}
          >
            <i className="zmdi zmdi-arrow-back" />
          </IconButton>
        </div>
        <div className="chat-user chat-user-center">
          <div className="chat-avatar mx-auto">
            <img
              src={userLoged.photo}
              className="avatar avatar-shadow rounded-circle size-60 huge"
              alt="John Doe"
            />
          </div>

          <div className="user-name h4 my-2">{userLoged.name}</div>
        </div>
      </div>
      <div className="chat-sidenav-content">
        <CustomScrollbars
          className="chat-sidenav-scroll scrollbar"
          style={{
            height:
              width >= 1200 ? "calc(100vh - 328px)" : "calc(100vh - 162px)"
          }}
        >
          <form className="p-4">
            <div className="form-group mt-4">
              <label>Status</label>

              <Input
                fullWidth={true}
                id="exampleTextarea"
                multiline
                rows={3}
                placeholder="Status"
                margin="none"
              />
            </div>
          </form>
        </CustomScrollbars>
      </div>
    </div>
  );
}
