import React, { useState } from "react";
import { ModalResponsive } from "components/ModalResponsive";
import { TextField } from "@material-ui/core";
import { BtnCan, BtnSave } from "components/Button";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalToken({
  id,
  open,
  error,
  handleClose,
  validationToken,
}) {
  const [token, setToken] = useState("");

  return (
    // <div>
    //   <ModalResponsive
    //     title={"Confirmação de Token"}
    //     open={open}
    //     close={() => {}}
    //     maxWidth="xs"
    //     buttons={
    //       <div className="col-12">
    //         <BtnCan title={"fechar"} onClick={handleClose} float="left" />
    //         <BtnSave
    //           title={"Confirmar"}
    //           onClick={() => validationToken(id, token)}
    //           float="right"
    //         />
    //       </div>
    //     }
    //   >
    //     <TextField
    //       id="token"
    //       fullWidth
    //       required
    //       label="Token"
    //       margin="normal"
    //       value={token}
    //       onChange={(e) => setToken(e.target.value.toUpperCase())}
    //       InputLabelProps={{ shrink: true }}
    //       helperText={!error ? "Campo Obrigatório" : "Token Invalido"}
    //       error={error ? true : false}
    //     />
    //   </ModalResponsive>
    // </div>
    <SweetAlert
      show={open}
      success
      onConfirm={() => validationToken(id, token)}
      title="Confirmação de token"
    >
      <TextField
        fullWidth
        required
        label="Token"
        margin="normal"
        value={token}
        onChange={(e) => setToken(e.target.value.toUpperCase())}
        InputLabelProps={{ shrink: true }}
        helperText={!error ? "Campo Obrigatório" : "Token Invalido"}
        error={error ? true : false}
      />
    </SweetAlert>
  );
}
