import React from "react";
import { Card } from "reactstrap";
import Api from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { setListAddress } from "actions/Auth";
import Attendance from "../../components/attendance";
import Residents from "../../components/residents";

export default function DashboardNurse({ address }) {
  const user = useSelector((state) => state.auth.authUser);
  const loaded_address = useSelector((state) => state.auth.loaded_address);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!loaded_address) {
      Api.post(`/ms_system/dashboard/getRecordNurse`, {
        user,
      }).then((res) => {
        dispatch(setListAddress(res.data.listAddress, res.data.managers));
      });
    }
  }, []);

  return (
    <div className="row col-12 d-flex justify-content-center ml-1">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-1 justify-content-center m-0 p-0">
        <Card className="shadow border-0 mb-3 mt-1">
          <Attendance />
        </Card>
        <Card className="shadow border-0 mb-3">
          <Residents addressId={address} />
        </Card>
      </div>
    </div>
  );
}
