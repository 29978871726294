import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Slide,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

// import FormPhysicalActivities from "components/Forms/Consult/healthyHabits/FormPhysicalActivities";
import PhysicalActivities from "components/Forms/Consult/healthyHabits/PhysicalActivities";
import Pause from "components/Forms/Consult/healthyHabits/Pause";
// import FormPause from "components/Forms/Consult/healthyHabits/FormPause";

import FormEvent from "components/calendar/FormEvent";
import FormPrescription from "app/routes/consult/routes/prescription/formPrescription/index";
import Api from "services/api";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "white",
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAdd({
  patient,
  title,
  type,
  open,
  handleClose,
  beforeSave,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [recordPause, setRecordPause] = useState([]);
  const [listPause, setListPause] = useState([]);

  useEffect(() => {
    if (type === "activity") {
      Api.post(`/microservice/patientportal/getPhysicalActivitiesPatient/`, {
        patient,
      }).then((res) => {
        setRecord(res.data.record);
        setList(res.data.list);
      });
    }
    if (type === "pause") {
      Api.post(`/microservice/patientportal/getSchedulePause/`, {
        patient,
      }).then((res) => {
        setRecordPause(res.data.record);
        setListPause(res.data.list);
      });
    }
  }, [type]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          overflowX: "hidden",
        },
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>

      {type === "event" && (
        <div className="row pl-3 pr-3">
          <div className="col-12 mt-3">
            <FormEvent
              patientPortal={true}
              modal={true}
              close={() => {
                beforeSave();
                handleClose();
              }}
            />
          </div>
        </div>
      )}

      {type === "medicines" && (
        <div className="row pl-3 pr-3">
          <div className="col-12 mt-3">
            <FormPrescription
              transcription={true}
              form="pp"
              medicineUse={true}
              handleClose={() => {
                beforeSave();
                handleClose();
                dispatch({ type: "CANCEL_FORM_MEDICINE_IN_USE" });
              }}
            />
          </div>
        </div>
      )}
      {type === "activity" && (
        <PhysicalActivities
          form="dash"
          patient={patient}
          type="pp"
          beforeSave={() => {
            beforeSave();
            handleClose();
          }}
        />
      )}
      {type === "pause" && (
        <Pause
          form="dash"
          patient={patient}
          type="pp"
          beforeSave={() => {
            beforeSave();
            handleClose();
          }}
        />
      )}
    </Dialog>
  );
}
