import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { TextField, MenuItem, Button } from "@material-ui/core";
import MaskCnpj from "components/Masks/MaskCnpj";
import MaskPhone from "components/Masks/MaskPhone";
import MaskCellPhone from "components/Masks/MaskCellphone";
import SweetAlert from "react-bootstrap-sweetalert";
import Api from "services/api";
import { allowCNPJ } from "../Functions";

export default function DrugStoreData({
  handleBack,
  handleNext,
  getState,
  stateInit,
  validateData,
}) {
  const [state, setState] = useState(stateInit);
  const [typesList, setProviderTypeList] = useState([
    {
      id: "36",
      name: "Drogarias / Farmácias",
    },
  ]);
  const [cnpjMessage, setCnpjMessage] = useState("");
  const [modalCnpj, setModalCnpj] = useState(false);
  const [modalUser, setModalUser] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const [validaCnpj, setValidateCnpj] = useState(validateData.cnpj);
  const [validaUserName, setValidateUsername] = useState(validateData.cnpj);
  const [validaEmail, setValidateEmail] = useState(validateData.email);
  const {
    name,
    username,
    cnpj,
    cnae,
    type,
    email,
    cellphone,
    phone,
    password,
    confirmPassword,
    error,
  } = state;

  // useEffect(() => {
  //   Api.post(`/ms_system/user/getTypeProvider`).then(res => {
  //     setProviderTypeList(res.data.record);
  //   });
  // }, []);

  const validate = async () => {
    CheckCnpj();
    CheckUsername();

    await verifyState();
  };

  const verifyState = () => {
    if (
      validaUserName &&
      validaEmail &&
      validaCnpj &&
      name &&
      username &&
      cnpj &&
      email &&
      cellphone &&
      cellphone.indexOf("_") === -1 &&
      phone &&
      phone.indexOf("_") === -1 &&
      password &&
      confirmPassword
    ) {
      setState({ ...state, error: false });
      getState(state, "providerData");
      getState(
        { cnpj: validaCnpj, username: validaUserName, email: validaEmail },
        "validate"
      );
      handleNext();
    } else {
      setState({ ...state, error: true });
    }
  };

  const CheckCnpj = () => {
    setValidateCnpj(false);
    if (cnpj !== stateInit.cnpj) {
      if (cnpj.length !== 0) {
        const verifica = allowCNPJ(cnpj);
        if (verifica) {
          Api.post(`/ms_system/user/checkCNPJ`, { cnpj }).then((res) => {
            if (res.data.record.length !== 0) {
              setCnpjMessage(
                "Este CNPJ já está cadastrado. Por favor utilize outro."
              );
              setModalCnpj(true);
              setValidateCnpj(false);
              return false;
            } else {
              setValidateCnpj(true);
              return true;
            }
          });
        } else {
          setCnpjMessage("CNPJ Inválido");
          setModalCnpj(true);
          setValidateCnpj(false);
          return false;
        }
      } else {
        setValidateCnpj(false);
        return false;
      }
    } else {
      setValidateCnpj(true);
      return true;
    }
  };

  const checkEmail = () => {
    setValidateEmail(false);
    if (email)
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setValidateEmail(false);
        return false;
      } else {
        setValidateEmail(true);
        Api.post(`/ms_system/user/checkEmail`, { email }).then((res) => {
          if (res.data.record.length !== 0) {
            setModalEmail(true);
          }
        });
        return true;
      }
  };

  const recuperarSenha = () => {
    setModalEmail(false);
  };

  const CheckUsername = () => {
    setValidateUsername(false);
    username
      ? Api.post(`/ms_system/user/checkUserName`, { username }).then((res) => {
          if (res.data.record.length !== 0) {
            setModalUser(true);
            setValidateUsername(false);
          } else {
            setValidateUsername(true);
          }
        })
      : setValidateUsername(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <TextField
            id="name"
            required
            label="Razão Social"
            margin="normal"
            helperText={error && !name ? "Campo Obrigatório" : ""}
            fullWidth
            value={name}
            error={error && !name ? true : false}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12">
          <TextField
            id="name"
            required
            label="Nome Fantasia"
            margin="normal"
            helperText={error && !name ? "Campo Obrigatório" : ""}
            fullWidth
            value={name}
            error={error && !name ? true : false}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-6">
          <TextField
            id="cnpj"
            label="CNPJ"
            required
            margin="normal"
            fullWidth
            InputProps={{
              inputComponent: MaskCnpj,
              value: cnpj,
              onChange: (e) => setState({ ...state, cnpj: e.target.value }),
            }}
            helperText={
              error && !cnpj
                ? "Campo Obrigatório"
                : cnpj && !validaCnpj
                ? "Por Favor insira outro CNPJ."
                : ""
            }
            error={(error && !cnpj) || (error && !validaCnpj) ? true : false}
            onBlur={CheckCnpj}
          />
        </div>
        <div className="col-md-6">
          <TextField
            id="name"
            required
            label="CNAE"
            margin="normal"
            helperText={error && !cnae ? "Campo Obrigatório" : ""}
            fullWidth
            value={cnae}
            error={error && !cnae ? true : false}
            onChange={(e) => setState({ ...state, cnae: e.target.value })}
          />
        </div>
        {/* <div className="col-md-4">
          <TextField
            id="name"
            required
            label="Endereço"
            margin="normal"
            helperText={error && !name ? "Campo Obrigatório" : ""}
            fullWidth
            value={name}
            error={error && !name ? true : false}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-4">
          <TextField
            id="email"
            label="E-mail"
            required
            margin="normal"
            fullWidth
            value={email}
            onBlur={checkEmail}
            onChange={(e) => setState({ ...state, email: e.target.value })}
            helperText={
              error && !email
                ? "Campo Obrigatório."
                : !validaEmail && email
                ? "E-mail Invalido."
                : ""
            }
            error={error && !email ? true : false}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="phone"
            label="Telefone"
            required
            helperText={
              phone.indexOf("_") !== -1
                ? "Telefone Inválido"
                : error && !phone
                ? "Campo Obrigatório"
                : ""
            }
            InputProps={{
              inputComponent: MaskPhone,
              value: phone,
              onChange: (e) => setState({ ...state, phone: e.target.value }),
            }}
            margin="normal"
            fullWidth
            error={error && !phone ? true : false}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="celular"
            label="Celular"
            required
            helperText={
              cellphone.indexOf("_") !== -1
                ? "Telefone Inválido"
                : error && !cellphone
                ? "Campo Obrigatório"
                : ""
            }
            InputProps={{
              inputComponent: MaskCellPhone,
              value: cellphone,
              onChange: (e) =>
                setState({ ...state, cellphone: e.target.value }),
            }}
            margin="normal"
            fullWidth
            error={error && !cellphone ? true : false}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="username"
            label="Nome de usuário"
            required
            margin="normal"
            fullWidth
            helperText={
              error && !username
                ? "Campo Obrigatório"
                : username && !validaUserName
                ? "Por Favor insira outro Nome de Usuário."
                : ""
            }
            value={username}
            onChange={(e) =>
              setState({ ...state, username: e.target.value.toLowerCase() })
            }
            onBlur={CheckUsername}
            error={
              (error && !username) || (error && !validaUserName) ? true : false
            }
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="senha"
            label="Senha"
            required
            type="password"
            margin="normal"
            fullWidth
            value={password}
            onChange={(e) => setState({ ...state, password: e.target.value })}
            helperText={error && !password ? "Campo Obrigatório" : ""}
            error={error && !password ? true : false}
          />
        </div>
        <div className="col-md-4">
          <TextField
            id="confirm_senha"
            label="Confirmar Senha"
            required
            type="password"
            margin="normal"
            fullWidth
            value={confirmPassword}
            helperText={
              error && !confirmPassword
                ? "Campo Obrigatório"
                : confirmPassword !== password
                ? "As senhas precisam ser iguais."
                : ""
            }
            onChange={(e) =>
              setState({ ...state, confirmPassword: e.target.value })
            }
            error={
              error && !confirmPassword && confirmPassword !== password
                ? true
                : false
            }
          />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12">
          <TextField
            select
            id="type"
            label="Tipo de Prestador"
            required
            helperText={error && !type ? "Campo Obrigatório" : ""}
            margin="normal"
            fullWidth
            value={type}
            onChange={(e) => setState({ ...state, type: e.target.value })}
            error={error && !type ? true : false}
          >
            {typesList.length > 0 &&
              typesList.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </div> */}
      <div className="align-center mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={validate}
          className="jr-btn"
          style={{ float: "right", marginRight: 0 }}
        >
          Próxima
        </Button>
        <Button
          className="jr-btn"
          onClick={handleBack}
          style={{ float: "left", marginLeft: 0 }}
        >
          Voltar
        </Button>
        {/* <NavLink to="/signup" style={{ float: "left", marginLeft: 0 }}>
          <Button className="jr-btn">Voltar</Button>
        </NavLink> */}
      </div>
      <SweetAlert
        show={modalCnpj}
        warning
        showConfirm={true}
        title={cnpjMessage}
        onConfirm={() => setModalCnpj(false)}
      />
      <SweetAlert
        show={modalUser}
        warning
        showConfirm={true}
        title="Este nome de usuário ja foi cadastrado. Por favor utilize outro."
        onConfirm={() => setModalUser(false)}
      />
      <SweetAlert
        show={modalEmail}
        warning
        showConfirm={true}
        showCancel={true}
        cancelBtnBsStyle="default"
        cancelBtnText="Recuperar Senha"
        confirmBtnText="Continuar"
        title="Este e-mail já está cadastrado. Deseja:"
        onConfirm={() => setModalEmail(false)}
        onCancel={() => recuperarSenha()}
      />
    </>
  );
}
