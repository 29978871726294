import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import Api from "services/api";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%",
    padding: 0,
  },
  root: {
    backgroundColor: "#fff",
  },
  selected: {
    backgroundColor: "#e2e2e8 !important",
  },
  paper: {
    position: "inherit",
    zIndex: 1,
    marginTop: 1,
    left: 0,
    right: 0,
  },
  background: {
    "&:hover": {
      backgroundColor: "Red",
    },
  },
};

class AutocompleteExam extends Component {
  state = {
    suggestions: [],
    selected: null,
  };

  getSuggestions = (e) => {
    if (e.target.value.length > 2) {
      Api.post(`/microservice/autocomplete/autocompleteExamByType/`, {
        type: this.props.type,
        term: e.target.value,
      }).then((res) => {
        this.setState({ suggestions: res.data.record });
      });
    }
  };

  renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.test;

    return (
      <div>
        <MenuItem
          {...itemProps}
          key={suggestion.tests_id}
          value={suggestion.tests_id}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
            zIndex: 1000,
          }}
        >
          {suggestion.test}{" "}
          {suggestion.sample ? ` - (${suggestion.sample})` : ""}
        </MenuItem>
      </div>
    );
  }

  renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...InputProps,
        }}
        onKeyUp={this.getSuggestions}
        onChange={this.handleChange}
        InputLabelProps={{ shrink: true }}
        error={this.props.error}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { suggestions } = this.state;

    return (
      <Downshift
        onSelect={(selectedItem, obj) => {
          let item = suggestions.filter((row) => {
            return row.tests_id === selectedItem;
          });
          this.props.handleChangeAutocomplete(item[0]);
          obj.setState({ inputValue: "" });
        }}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          selectedItem,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            {this.renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                id: "exam",
              }),
              label: "Adicionar Exame:",
              margin: "normal",
            })}
            {isOpen ? (
              <Paper className={classes.paper} square>
                {suggestions.map((suggestion, index) =>
                  this.renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.tests_id,
                    }),
                    highlightedIndex,
                    selectedItem,
                  })
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

AutocompleteExam.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutocompleteExam);
