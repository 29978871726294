import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { viewAlertMessage, closeAlertMessage } from "actions/Alerts";
import Api from "services/api";
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  MenuItem,
  Grid,
  Switch,
  Collapse,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { SketchPicker } from "react-color";
import { MultipleSelect, SingleSelect } from "react-select-material-ui";
import CircularProgress from "components/CircularProgress";

function FormTeam({
  user,
  record,
  addressId,
  specialty,
  specialty_name,
  close,
  add,
  edit,
}) {
  const [calendar_id, setCalendarId] = useState("");
  const [type_online_scheduler, setType_online_scheduler] = useState("");
  const [time_to_confirme, set_time_to_confirme] = useState("--:--");
  const [online_first_attendance, set_online_first_attendance] = useState("");
  const [online_attendance, set_online_attendance] = useState("");
  const [online_return, set_online_return] = useState("");
  const [description, setDescription] = useState("");
  const objHour = {
    id: "",
    specialty: "",
    start: "--:--",
    end: "--:--",
    day: "",
  };
  const [hours, setHours] = useState([objHour]);
  const [hoursDelete, setHoursDelete] = useState([]);
  const [typesEvent, setTypesEvent] = useState([]);
  const [typesSelected, setTypesSelected] = useState([]);
  const [colorEvent, setColorEvent] = useState("#177493");
  const [displayColorEvent, setDisplayColorEvent] = useState(false);
  const [textColor, setTextColor] = useState("#ffffff");
  const [displayTextEvent, setDisplayTextEvent] = useState(false);
  const [online_scheduling, setOnlineScheduling] = useState(false);
  const [number_patients, setNumberPatients] = useState(1);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    Api.post(`/ms_system/workplace/getTypesEventsUser/`, { user }).then(
      (res) => {
        if (res.data.record.length > 0) {
          setTypesEvent(res.data.record);
        }
      }
    );

    if (record.id) {
      Api.post(`/ms_system/workplace/getInfoTeamAcademic/`, {
        id: record.id,
      }).then((res) => {
        setDescription(record.description);
        setHours(res.data.hours);
        setColorEvent(res.data.color_event);
        setTextColor(res.data.color_text);
        setNumberPatients(res.data.number_patient);
        setOnlineScheduling(res.data.online_scheduling);
        let arr_selecteds = res.data.types_events.map((row) => row.id);
        setTypesSelected(arr_selecteds);
        setType_online_scheduler(res.data.type_online_scheduler);
        set_time_to_confirme(res.data.time_to_confirme);
        set_online_first_attendance(res.data.online_first_attendance);
        set_online_attendance(res.data.online_attendance);
        set_online_return(res.data.online_return);
        setCalendarId(res.data.calendar_id);
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
  }, []);

  function validate() {
    if (description && typesSelected.length > 0) {
      setError(false);
      return true;
    } else {
      setError(true);
      return false;
    }
  }

  function save() {
    if (validate()) {
      Api.post(`/ms_system/workplace/saveTeamAcademic/`, {
        id: record.id,
        calendar_id,
        description,
        addressId,
        specialty,
        specialty_name,
        hours,
        hoursDelete,
        typesSelected,
        colorEvent,
        textColor,
        online_scheduling,
        number_patients,
        type_online_scheduler,
        time_to_confirme,
        online_first_attendance,
        online_attendance,
        online_return,
      })
        .then(({ data }) => {
          const payload = {
            id: data.id,
            description,
            fk_ls_group_intranet_student: data.fk_ls_group_intranet_student,
            fk_ls_group_intranet_teacher: data.fk_ls_group_intranet_teacher,
          };
          if (!record.id) {
            add(payload);
          } else {
            edit({
              id: data.id,
              description,
            });
          }
          dispatch(viewAlertMessage("success", "Salvo com sucesso!"));
          setTimeout(() => dispatch(closeAlertMessage()), 5000);
          close();
        })
        .catch(() => {
          dispatch(viewAlertMessage("error", "Ocorreu um erro ao salvar!"));
          setTimeout(() => dispatch(closeAlertMessage(), 5000));
        });
    }
  }

  function handleChangeHour(index, field, value) {
    let aux = hours.map((row, key) =>
      key === index ? { ...row, [field]: value } : row
    );
    setHours(aux);
  }

  const handleDeleteHour = (id, index) => {
    if (id) {
      let obj = hours.find((row) => row.id === id);
      setHoursDelete([...hoursDelete, obj]);
    }
    let arr = hours.filter((obj, key) => key !== index);
    setHours(arr);
  };

  if (!loaded) {
    return <CircularProgress />;
  } else {
    return (
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="text-primary font-weight-semibold">
            Equipes de Atendimento
            <Tooltip title="Fechar" placement="top">
              <IconButton
                aria-label="Fechar"
                onClick={() => close()}
                style={{ float: "right", marginTop: "-10px" }}
              >
                <i className="zmdi zmdi-close" />
              </IconButton>
            </Tooltip>
          </h2>
        </div>

        <div className="col-12">
          <TextField
            label="Descrição:"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            error={error && !description ? true : false}
          />
        </div>

        <div className="col-12 mt-3">
          <h4 className="font-weight-semibold">Horários de Atendimento</h4>
          <hr />
        </div>
        {hours.map((row, index) => (
          <>
            <div className="col-md-4 col-xs-4">
              <TextField
                id="day_week"
                select
                label="Dia da Semana:"
                value={row.day}
                onChange={(e) => handleChangeHour(index, "day", e.target.value)}
                SelectProps={{}}
                fullWidth
                style={{ marginTop: "15px" }}
                error={error && !row.day ? true : false}
              >
                <MenuItem key="1" value="0">
                  Domingo
                </MenuItem>
                <MenuItem key="2" value="1">
                  Segunda-Feira
                </MenuItem>
                <MenuItem key="3" value="2">
                  Terça-Feira
                </MenuItem>
                <MenuItem key="3" value="3">
                  Quarta-Feira
                </MenuItem>
                <MenuItem key="3" value="4">
                  Quinta-Feira
                </MenuItem>
                <MenuItem key="3" value="5">
                  Sexta-Feira
                </MenuItem>
                <MenuItem key="3" value="6">
                  Sábado
                </MenuItem>
                <MenuItem key="3" value="7">
                  Segunda à Sexta
                </MenuItem>
                <MenuItem key="3" value="8">
                  Todos os dias
                </MenuItem>
              </TextField>
            </div>
            <div className="col-md-3 col-xs-4">
              <TextField
                id="start"
                type="time"
                label="Início:"
                value={row.start}
                margin="normal"
                fullWidth
                onChange={(e) =>
                  handleChangeHour(index, "start", e.target.value)
                }
                error={error && row.start === "--:--" ? true : false}
              />
            </div>
            <div className="col-md-3 col-xs-4">
              <TextField
                id="start"
                type="time"
                label="Fim:"
                value={row.end}
                margin="normal"
                fullWidth
                onChange={(e) => handleChangeHour(index, "end", e.target.value)}
                error={error && row.end === "--:--" ? true : false}
              />
            </div>

            <div className="col-md-2 col-xs-4">
              {index + 1 === hours.length && (
                <IconButton
                  onClick={() => setHours([...hours, objHour])}
                  color="primary"
                  aria-label="Adicionar"
                  style={{ marginTop: "25px" }}
                >
                  <i className="zmdi zmdi-plus zmdi-hc-fw" />
                </IconButton>
              )}
              {hours.length > 1 && (
                <IconButton
                  onClick={() => handleDeleteHour(row.id, index)}
                  color="secondary"
                  aria-label="Remover"
                  style={{ marginTop: "25px" }}
                >
                  <i className="zmdi zmdi-delete zmdi-hc-fw" />
                </IconButton>
              )}
            </div>
          </>
        ))}

        <div className="col-12">
          <h4 className="font-weight-semibold mt-3">
            Especificações da agenda
          </h4>
          <hr />
        </div>

        <div className="col-md-12 col-12">
          <MultipleSelect
            label="Serviços:"
            values={typesSelected}
            options={typesEvent}
            onChange={(e) => setTypesSelected(e)}
            SelectProps={{
              isCreatable: false,
              msgNoOptionsAvailable: "Todos serviços foram selecionados",
              msgNoOptionsMatchFilter: "Nenhum tipo de evento para este filtro",
            }}
            style={{
              borderBottom:
                error && typesSelected.length === 0 ? "2px solid red" : "none",
            }}
            // error={error && typesSelected.length === 0 ? true : false}
          />
        </div>

        <div className="col-md-6 col-12 mt-3">
          <Grid component="label" container alignItems="center">
            <Grid item>
              <span
                style={{
                  color: "#0000008a",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "1.3125rem",
                }}
              >
                Agendamento online:
              </span>
            </Grid>
            <Grid item>
              <Switch
                color="primary"
                checked={online_scheduling}
                onChange={(e) => setOnlineScheduling(e.target.checked)}
                aria-label="checked"
              />
            </Grid>
          </Grid>
        </div>

        <div className="col-md-6 col-12 mt-3">
          <TextField
            id="number_patients"
            type="number"
            label="Número de pacientes por horário:"
            value={number_patients}
            onChange={(e) => setNumberPatients(e.target.value)}
            fullWidth
          />
        </div>

        <div className="col-md-6 col-12">
          <Grid
            component="label"
            container
            alignItems="center"
            style={{ marginTop: "15px" }}
          >
            <Grid item>
              <span
                style={{
                  color: "#0000008a",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "1.3125rem",
                }}
              >
                Cor dos eventos:
              </span>
            </Grid>
            <Grid item>
              <div
                className="cp-swatch ml-3"
                onClick={() => setDisplayColorEvent(!displayColorEvent)}
              >
                <div
                  className="cp-color"
                  style={{ backgroundColor: colorEvent, width: "70px" }}
                />
              </div>
              {displayColorEvent ? (
                <div className="cp-popover">
                  <div
                    className="cp-cover"
                    onClick={() => setDisplayColorEvent(!displayColorEvent)}
                  />
                  <SketchPicker
                    color={colorEvent}
                    onChange={(val) => setColorEvent(val.hex)}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <div className="col-md-6 col-12">
          <Grid
            component="label"
            container
            alignItems="center"
            style={{ marginTop: "15px" }}
          >
            <Grid item>
              <span
                style={{
                  color: "#0000008a",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "1.3125rem",
                }}
              >
                Cor do texto dos eventos:
              </span>
            </Grid>
            <Grid item>
              <div
                className="cp-swatch ml-3"
                onClick={() => setDisplayTextEvent(!displayTextEvent)}
              >
                <div
                  className="cp-color"
                  style={{ backgroundColor: textColor, width: "70px" }}
                />
              </div>
              {displayTextEvent ? (
                <div className="cp-popover">
                  <div
                    className="cp-cover"
                    onClick={() => setDisplayTextEvent(!displayTextEvent)}
                  />
                  <SketchPicker
                    color={textColor}
                    onChange={(val) => setTextColor(val.hex)}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>
        <div className="col-md-12 col-12">
          <Collapse in={online_scheduling}>
            <div className="d-flex">
              <div className="col-md-6 col-12 mt-3">
                <p style={{ color: "#0000008a" }}>
                  Tipo do agendamento online:
                </p>
                <RadioGroup
                  aria-label="type_online"
                  name="type_online"
                  value={type_online_scheduler ? type_online_scheduler : ""}
                  onChange={(e) => setType_online_scheduler(e.target.value)}
                  style={{ display: "inline" }}
                >
                  <Tooltip title="paciente agenda consulta diretamente">
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label="Confirmação"
                    />
                  </Tooltip>
                  <Tooltip title="paciente solicita agendamento">
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label="Solicitação"
                    />
                  </Tooltip>
                </RadioGroup>
              </div>
              <div className="col-md-6 col-12 mt-5">
                <TextField
                  className="mb-3"
                  label="Prazo de confirmação do paciente"
                  type="time"
                  value={time_to_confirme}
                  onChange={(e) => set_time_to_confirme(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="d-flex">
                <div className="col-md-4 col-12">
                  <SingleSelect
                    label="Primeira consulta:"
                    value={online_first_attendance}
                    options={typesEvent}
                    onChange={(e) => set_online_first_attendance(e)}
                    SelectProps={{
                      isCreatable: false,
                      msgNoOptionsAvailable:
                        "Todas as opções foram selecionadas",
                      msgNoOptionsMatchFilter: "Nenhuma opção para este filtro",
                    }}
                    style={{ marginTop: "10px" }}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <SingleSelect
                    label="Consulta:"
                    value={online_attendance}
                    options={typesEvent}
                    onChange={(e) => set_online_attendance(e)}
                    SelectProps={{
                      isCreatable: false,
                      msgNoOptionsAvailable:
                        "Todas as opções foram selecionadas",
                      msgNoOptionsMatchFilter: "Nenhuma opção para este filtro",
                    }}
                    style={{ marginTop: "10px" }}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <SingleSelect
                    label="Retorno:"
                    value={online_return}
                    options={typesEvent}
                    onChange={(e) => set_online_return(e)}
                    SelectProps={{
                      isCreatable: false,
                      msgNoOptionsAvailable:
                        "Todas as opções foram selecionadas",
                      msgNoOptionsMatchFilter: "Nenhuma opção para este filtro",
                    }}
                    style={{ marginTop: "10px" }}
                  />
                </div>
              </div>
            </div>
          </Collapse>
        </div>

        <div className="col-md-12 mt-3">
          <Button
            color="secondary"
            variant="contained"
            className="jr-btn jr-btn-sm"
            onClick={() => close()}
            style={{ float: "left" }}
          >
            <i className="zmdi zmdi-close zmdi-hc-lg" />
            <span> Cancelar </span>
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="jr-btn jr-btn-sm bg-success"
            onClick={() => save()}
            style={{ float: "right" }}
          >
            <i className="zmdi zmdi-check zmdi-hc-lg" />
            <span> Salvar </span>
          </Button>
        </div>
      </div>
    );
  }
}

export default FormTeam;
