const INITIAL_STATE = {
  view: false,
  reminder: [],
  idReminder: "",
  nameReminder: "",
  edit: false,
  reminderHistory: []
};

export default function foodReminder(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_MEAL":
      return {
        ...state
      };
    case "REMOVE_REMINDER":
      const listFinal = state.reminderHistory.filter(
        el => el.idReminder !== action.payload
      );
      return {
        ...state,
        reminderHistory: listFinal,
        edit: false
      };
    case "SET_REMINDERS_MEALS":
      return {
        ...state,
        reminder: action.payload
      };
    case "EDIT_REMINDER":
      return {
        ...state,
        idReminder: action.payload.idReminder,
        nameReminder: action.payload.nameReminder,
        edit: true,
        view: false
      };
    case "SET_LIST_REMINDER":
      const list = [];
      action.payload.map(el => {
        list.push({ idReminder: el.id, nameReminder: el.name });
      });
      return {
        ...state,
        reminderHistory: list
      };
    case "CHANGE_VIEW_MEAL":
      return {
        ...state,
        view: !state.view
      };
    case "SAVE_FOOD_REMINDER":
      return {
        ...state,
        idReminder: action.payload.idReminder,
        nameReminder: action.payload.nameReminder,
        reminder: action.payload.reminder,
        view: true,
        edit: false
      };
    case "REMOVE_MEAL":
      const newReminder = state.reminder.filter(
        row => row.idMeal !== action.payload
      );
      return {
        ...state,
        reminder: newReminder
      };
    case "ADD_MEAL":
      const meal = [
        {
          idMeal: action.payload.idMeal,
          name: action.payload.meal,
          hour: action.payload.time,
          local: action.payload.local,
          kcal: action.payload.kcal,
          foods: action.payload.listItems
        }
      ];
      return {
        ...state,
        reminder: [...state.reminder, ...meal]
      };
    case "EDIT_MEAL":
      const mealEdit = state.reminder.map((row, index) => {
        return row.idMeal === action.payload.idMeal
          ? (state.reminder[index] = {
              idMeal: action.payload.idMeal,
              name: action.payload.meal,
              hour: action.payload.time,
              local: action.payload.local,
              kcal: action.payload.kcal,
              foods: action.payload.listItems
            })
          : row;
      });
      return {
        ...state,
        reminder: mealEdit
      };
    case "RESET_REMINDER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
