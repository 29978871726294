import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";

import {
  Typography,
  makeStyles,
  IconButton,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
} from "@material-ui/core";
import { ButtonLarge } from "components/Button";
import CustomSlider from "components/CustomSlider";

import CloseIcon from "@material-ui/icons/Close";
import SelectWithChip from "components/TextFields/SelectWithChip";
import Dr_prancheta from "assets/images/dr_prancheta.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    width: "100%",
  },
  dFlex: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  box: {
    border: "solid 2px #c1c1c1",
    borderRadius: "25px",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
  },
  icon: {
    overflow: "inherit",
    fontSize: "40px",
    width: "auto",
    height: "auto",
  },
  positionBad: {
    position: "absolute",
    bottom: "-5px",
    left: 0,
  },
  positionGood: {
    position: "absolute",
    bottom: "-5px",
    right: 0,
  },
}));

export default function Sleep({ handleClose }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    sleep: "",
    sleep_duration: "",
    what_sleep: [],
  });
  const classes = useStyles();

  useEffect(() => {
    calcBar();
  }, [state.sleep_duration, state.what_sleep]);

  function handleSave() {
    Api.post("microservice/patientportal/saveSleep", state)
      .then(({ data }) => {
        if (data.success) {
          handleClose();
          localStorage.setItem("savedQuizze", "1");
        } else {
          throw true;
        }
      })
      .catch(() => {
        showMsg("error", "Não foi possível savar as informações", dispatch);
      });
  }

  function calcBar() {
    const barValue = state.sleep_duration >= 10 ? 10 : state.sleep_duration;
    const decrecimo = state.what_sleep.length;
    const newBarValue = barValue - decrecimo;
    setState({ ...state, sleep: newBarValue > 0 ? newBarValue : 1 });
  }

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-10 text-left">
          <Typography className="pt-1" variant="h6" gutterBottom>
            Qualidade de Sono
          </Typography>
        </div>
        <div className="col-2 ">
          <IconButton onClick={handleClose} color="default">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div
        className="card"
        style={{
          marginLeft: "-15px",
          marginRight: "-15px",
          paddingTop: "1rem",
        }}
      >
        <div className={classes.root}>
          <div className={classes.dFlex}>
            <div className="col-12 col-sm-12 col-md-8">
              <Typography variant="subtitle1" gutterBottom>
                <strong>Como foi seu sono?</strong>
              </Typography>
              <div className="text-left">
                <div className="col-md-4 col-xs-12">
                  <FormControl className="my-3" fullWidth>
                    <FormHelperText>Duração do sono:</FormHelperText>
                    <Input
                      id="weeks"
                      type="number"
                      value={state.sleep_duration}
                      onChange={(e) =>
                        setState({
                          ...state,
                          sleep_duration: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">horas</InputAdornment>
                      }
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9]/g, "")
                          .substr(0, 2);
                      }}
                    />
                  </FormControl>
                </div>
                {/* {state.sleep < 6 && ( */}
                <div className="col-md-7 col-xs-12 mt-4">
                  <SelectWithChip
                    label="Informe o(s) problema(s):"
                    selecteds={state.what_sleep}
                    handleChange={(e) => {
                      setState({
                        ...state,
                        what_sleep: [...state.what_sleep, e.target.value],
                      });
                    }}
                    handleDelete={(e) => {
                      setState({
                        ...state,
                        what_sleep: state.what_sleep.filter((row) => row !== e),
                      });
                    }}
                    options={[
                      "Apneia do sono",
                      "Bruxismo",
                      "Hipersonia (muito sono)",
                      "Insônia",
                      "Narcolepsia (sonolência excessiva durante o dia)",
                      "Paralisia do sono (acordar e não conseguir se mexer)",
                      "Síndrome das pernas inquietas",
                      "Sonambulismo",
                    ]}
                  />
                </div>
                {/* )} */}
              </div>
              <div className="row px-4 mt-4">
                <div className="col-12">
                  <span className={classes.positionBad}>Ruim</span>
                  <CustomSlider
                    step={1}
                    marks
                    min={1}
                    max={10}
                    value={state.sleep}
                    onChange={(event, newValue) =>
                      setState({ ...state, sleep: newValue })
                    }
                    valueLabelDisplay="on"
                    aria-labelledby="continuous-slider"
                    style={{
                      color: !state.sleep
                        ? "#d1d1d1"
                        : state.sleep >= 6
                        ? "#00B1E2"
                        : state.sleep > 3
                        ? "#EEE800"
                        : "#D6201B",
                    }}
                  />
                  <span className={classes.positionGood}>Bom</span>
                </div>
              </div>
              {/* {state.sleep !== "" && ( */}

              {/* </div> */}
              {/* // )} */}
            </div>
            <div className="col-12 col-sm-12 col-md-4  justify-content-end">
              <img src={Dr_prancheta} alt="Dra. Prancheta" />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ButtonLarge
                onClick={state.sleep ? handleSave : () => {}}
                style={{
                  padding: "1px",
                  width: "unset",
                  minWidth: "100px",
                  float: "right",
                  backgroundColor: state.sleep ? "#3d8b40" : "unset",
                  color: state.sleep ? "#fff" : "#8c8c8c",
                }}
              >
                Confirmar
              </ButtonLarge>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
