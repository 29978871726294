import React from "react";
import Cell from "./Cell";
import { SortableContainer } from "react-sortable-hoc";

const Forms = SortableContainer(
  ({ forms, handleCheck, handleCheckSubGroup, sort }) => (
    <div className="row">
      {forms.map((cell, index) => (
        <>
          <Cell
            key={index}
            index={index}
            cell={cell}
            handleCheck={handleCheck}
            sort={sort}
          />
          {cell.sub_group &&
            cell.sub_group.map((row, idx) => (
              <>
                <div className="col-11 ml-5">
                  <Cell
                    key={idx}
                    index={idx}
                    cell={row}
                    handleCheck={(e) => handleCheckSubGroup(index, idx, e)}
                    sort={false}
                  />
                </div>
              </>
            ))}
        </>
      ))}
    </div>
  )
);

export default Forms;

// <div className="row">
// {console.log("sort", sort)}
// {forms.map((cell, index) => (
//   <>
//     <Cell
//       key={index}
//       index={index}
//       cell={cell}
//       handleCheck={handleCheck}
//       sort={sort}
//     />
//     {cell.sub_group &&
//       cell.sub_group.map((row, idx) => (
//         <>
//           <div className="col-11 ml-5">
//             <Cell
//               key={idx}
//               index={idx}
//               cell={row}
//               handleCheck={handleCheckSubGroup}
//               sort={false}
//             />
//           </div>
//         </>
//       ))}
//   </>
// ))}
// </div>

// <div className={cell.sub_group ? "col-11 ml-5" : ""}>
// <Cell
//   key={index}
//   index={index}
//   cell={cell}
//   handleCheck={handleCheck}
//   sort={cell.sub_group ? false : sort}
// />
// {/* {cell.sub_group &&
//     cell.sub_group.map((row, idx) => (
//       <>
//         <div className="col-11 ml-5">
//           <Cell
//             key={idx}
//             index={idx}
//             cell={row}
//             handleCheck={handleCheckSubGroup}
//             sort={false}
//           />
//         </div>
//       </>
//     ))} */}
// </div>
// ))}
// </div>
