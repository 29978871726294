const INITIAL_STATE = {
  selectedUser: [],
  selectedChat: [],
  notRead: {},
  conversation: {},
  chatModal: false,
  hasNotification: false,
  minimaze: {},
  modalMobile: false,
  modalVideo: false,
};

export default function Chat(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SELECTED_USER_CHAT":
      return {
        ...state,
        selectedUser: [action.payload],
        chatModal: false,
      };
    case "SELECTED_CHAT":
      return {
        ...state,
        selectedChat: [action.payload],
      };
    case "NEW_NOT_READ":
      return {
        ...state,
        notRead: {
          ...state.notRead,
          [action.id]: action.notRead,
        },
      };
    case "SET_NEW_CONVERSATION_CHAT":
      return {
        ...state,
        conversation: {
          ...state.conversation,
          [action.payload.id]: action.payload.conversation,
        },
      };
    case "SHOW_CHAT_MODAL":
      return {
        ...state,
        selectedChat:
          state.selectedChat.indexOf(action.payload.id) === -1
            ? [...state.selectedChat, action.payload.id]
            : state.selectedChat,
        selectedUser:
          state.selectedUser
            .map((row) => row.chat_id)
            .indexOf(action.payload.chat_id) === -1
            ? [...state.selectedUser, action.payload]
            : state.selectedUser,
        chatModal: true,
      };
    case "HIDE_CHAT_MODAL":
      let aux = {};
      Object.keys(state.conversation).forEach((row) => {
        if (row !== action.id) {
          aux = {
            ...aux,
            [row]: state.conversation[row],
          };
        }
      });
      return {
        ...state,
        selectedUser: state.selectedUser.filter(
          (row) => row.chat_id !== action.id
        ),
        selectedChat: state.selectedChat.filter((row) => row !== action.id),
        conversation: aux,
      };
    case "SET_HAS_NOTIFICATION":
      return {
        ...state,
        hasNotification: action.payload > 0 ? true : false,
      };
    case "SET_MINIMAZE_CHAT":
      return {
        ...state,
        minimaze: {
          ...state.minimaze,
          [action.payload.id]: action.payload.type,
        },
      };
    case "CHAT_MODAL_MOBILE":
      if (action.payload) {
        return {
          ...state,
          modalMobile: true,
        };
      } else {
        return {
          ...state,
          selectedUser: [],
          selectedChat: [],
          modalMobile: false,
        };
      }
    case "MODAL_VIDEO":
      return { ...state, modalVideo: action.payload };
    case "RESET_CHAT":
      return INITIAL_STATE;
    default:
      return state;
  }
}
