import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import {
  setSelectedChat,
  selectedUserChat,
  chatModalMobile,
  showChatModal,
} from "actions/comunication/Chat";
import api from "services/api";
import { getUrlPhoto } from "services/functions";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactHtmlParser, {
  htmlparser2,
  processNodes,
  convertNodeToElement,
} from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  bg_new: {
    backgroundColor: "#17749321",
  },
  onclick: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const NotificationItem = ({
  userKey,
  notification,
  history,
  database,
  removeItemId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [photo, setPhoto] = useState(null);
  const width = useSelector(({ settings }) => settings.width);

  useEffect(() => {
    if (type === "chat") {
      api
        .post(`ms_system/user/getPhotoUser`, { user: notification.user_from })
        .then(({ data }) => {
          setPhoto(getUrlPhoto(notification.user_from, data.photo));
        });
    }
  }, []);

  const handleClick = async () => {
    let update = {};
    update[userKey] = {
      ...notification,
      read: true,
    };
    await database.update(update);
    if (type === "chat") {
      if (width > 1200) {
        dispatch(showChatModal(action));
      } else {
        dispatch(setSelectedChat(action.chat_id));
        dispatch(selectedUserChat(action));
        dispatch(chatModalMobile(true));
      }
    } else {
      await history.push(`/app/` + action);
    }
  };

  const { icon, image, title, text, date, read, action, type } = notification;
  return (
    <li
      style={{ justifyContent: "space-between" }}
      className={`media ${!read && classes.bg_new} ${classes.onclick}`}
    >
      <div className="d-flex p-0" onClick={handleClick}>
        <Avatar
          alt={image}
          src={type === "chat" ? photo : image}
          className=" mr-2"
        />
        <div className="media-body align-self-center">
          <p className="sub-heading mb-0">{title}</p>
          <p className="sub-heading mb-0">{ReactHtmlParser(text)}</p>
          <span className="meta-date">
            <small>
              <i className={`zmdi ${icon} zmdi-hc-fw`} />
              {date}
            </small>
          </span>
        </div>
      </div>
      <IconButton aria-label="delete" onClick={() => removeItemId(userKey)}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </li>
  );
};

export default NotificationItem;
