const INIT_STATE = {
  stockId: "",
  stockType: "",
};
export default function stock(state = INIT_STATE, action) {
  switch (action.type) {
    case "SET_STOCK_ID":
      return {
        ...state,
        stockId: action.payload,
      };
    case "SET_STOCK_TYPE":
      return {
        ...state,
        stockType: action.payload,
      };
    case "RESET_STOCK":
      return INIT_STATE;
    default:
      return state;
  }
}
