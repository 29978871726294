const INITIAL_STATE = {
  listDiagnostic: []
};
export default function diagnosticIntegral(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_LIST_DIAGNOSTIC":
      let newList = state.listDiagnostic;
      newList.push(action.payload);
      return {
        ...state,
        listDiagnostic: newList
      };
    case "UPDATE_ITENS_LIST_DIAGNOSTIC":
      return {
        ...state,
        listDiagnostic: action.payload
      };
    default:
      return state;
  }
}
