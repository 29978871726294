import React, { useEffect, useState } from "react";
import Api from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { setDataAppointment } from "actions/consult/Appointment";
import { viewHistory } from "actions/consult/Appointment";
import {
  Typography,
  IconButton,
  Tooltip,
  MenuItem,
  Menu,
  Chip,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { BtnAdd } from "components/Button";
import NoRecord from "components/Alert/NoRecord";
import { withRouter } from "react-router-dom";
import HistoryPatient from "components/HistoryPatient";
import ComponentProfile from "components/ComponentsProfile/ProfileNew";
import FormNewAttendance from "./FormNewAttendance";
import ModalFilter from "./ModalFilter";
import ModalFormAttendance from "./ModalAttendance";
import ModalDeclarationComp from "./ModalDeclarationComp";
import { getStringToSearch } from "services/functions";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Delete,
  Block,
  History,
  Videocam,
  MoreVert,
  Visibility,
  AssignmentInd,
  Edit,
  AddAlarm,
  PlayArrow,
  FilterList,
  ListAlt,
  PostAdd,
} from "@material-ui/icons";

const useStyles = makeStyles({
  text: {
    color: "#868686",
    width: "100%",
    fontSize: "16px",
    fontVariant: "petite-caps",
    textAlign: "center",
    marginTop: "40px",
  },
  textTble: {
    fontSize: "14px",
    fontWeight: "500",
  },
});

const ITEM_HEIGHT = 48;

function ListAttendance({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address_selected);
  const [record, setRecord] = useState([]);
  const [patient, setPatient] = useState("");

  const [filter, setFilter] = useState({
    patient: "",
    service: "",
    professional: "",
    risk_classification: "",
    start: moment().format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    status: [],
  });
  const [order, setOrder] = useState("0");
  const [myAttendance, setMyAttendance] = useState(false);
  const [services, setServices] = useState([]);
  const [modalForm, setModalForm] = useState({ open: false, data: {} });
  const [modalAttendance, setModalAttendance] = useState({
    open: false,
    data: {},
  });
  const [modalDeclaration, setModalDeclaration] = useState({
    open: false,
    data: {},
  });
  const [modalDelete, setModalDelete] = useState({ open: false, id: "" });
  const [modal_filter, setModalFilter] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState("");

  const handleClick = (index) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(index);
  };

  const handleClose = () => {
    setOpen("");
  };

  useEffect(() => {
    Api.post(`/ms_system/dashboard/getServicesPrefecture/`, {
      user: address.user_id,
      address: address.id,
    }).then((res) => {
      if (res.data.record.length > 0) {
        setServices(res.data.record);
      }
    });
  }, []);

  useEffect(() => {
    getData();
  }, [filter.start, filter.end]);

  const getData = () => {
    Api.post(`/ms_system/dashboard/getServiceQueue/`, {
      address: address.id,
      start: filter.start,
      end: filter.end,
      order,
    }).then(({ data }) => {
      setRecord(data.record);
    });
  };

  const playConsult = (params) => {
    switch (params.service_id) {
      case "1": {
        alterStatus(params.id, "2", false);
        dispatch(
          setDataAppointment(
            params.fk_ls_user_patient,
            "",
            "1",
            false,
            params.id
          )
        );
        history.push("/app/consult/initial-record");
      }
      case "4":
      case "5":
      case "6":
      case "7":
      case "34": {
        setModalAttendance({ open: true, data: params });
        break;
      }
      default: {
        alterStatus(params.id, "1", false);
        dispatch(
          setDataAppointment(
            params.fk_ls_user_patient,
            "",
            "",
            false,
            params.id
          )
        );
        history.push("/app/consult/consultation-summary");
      }
    }
    setOpen("");
  };

  const getClassification = (value) => {
    switch (value) {
      case "0":
        return (
          <Chip
            label="Não aguda"
            variant="outlined"
            style={{
              border: "1px solid blue",
            }}
          />
        );
      case "1":
        return (
          <Chip
            label="Baixa"
            variant="outlined"
            style={{
              border: "1px solid green",
            }}
          />
        );
      case "2":
        return (
          <Chip
            label="Intermediária"
            variant="outlined"
            style={{
              border: "1px solid yellow",
            }}
          />
        );
      case "3":
        return (
          <Chip
            label="Alta"
            variant="outlined"
            style={{
              border: "1px solid red",
            }}
          />
        );
    }
  };

  const getColor = (value) => {
    switch (value) {
      case "0":
        return "#45ad30";
      case "1":
        return "#870e6a";

      case "2":
        return "#d6307b";
      case "3":
        return "#0057ae";
      case "4":
        return "#c1c2ce";
    }
  };

  const getChip = (value) => {
    switch (value) {
      case "0":
        return (
          <Chip
            label="Aguardando atendimento"
            variant="outlined"
            className="mt-2"
            style={{ border: "1px solid #45ad30", color: "#45ad30" }}
          />
        );

      case "1":
        return (
          <Chip
            label="Em atendimento"
            variant="outlined"
            className="mt-2"
            style={{ border: "1px solid #870e6a", color: "#870e6a" }}
          />
        );

      case "2":
        return (
          <Chip
            label="Em escuta inicial"
            variant="outlined"
            className="mt-2"
            style={{ border: "1px solid #d6307b", color: "#d6307b" }}
          />
        );
      case "3":
        return (
          <Chip
            label="Atendimento realizado"
            variant="outlined"
            className="mt-2"
            style={{ border: "1px solid #0057ae", color: "#0057ae" }}
          />
        );
      case "4":
        return (
          <Chip
            label="Não aguardou"
            variant="outlined"
            className="mt-2"
            style={{ border: "1px solid #c1c2ce", color: "#c1c2ce" }}
          />
        );
    }
  };

  const setPatientNotWait = (params) => {
    Api.post(`/ms_system/dashboard/alterStatusServiceQueue/`, {
      id: params.id,
      value: params.status === "0" ? "4" : "0",
    }).then(() => {
      setRecord(
        record.map((row) =>
          row.id === params.id
            ? { ...row, status: params.status === "0" ? "4" : "0" }
            : row
        )
      );
      setOpen("");
    });
  };

  const alterStatus = (id, value, att) => {
    Api.post(`/ms_system/dashboard/alterStatusServiceQueue/`, {
      id,
      value,
    }).then(() => {
      if (att) {
        setRecord(
          record.map((row) => (row.id === id ? { ...row, status: value } : row))
        );
        setOpen("");
      }
    });
  };

  const removeServiceQueue = () => {
    Api.post(`/ms_system/dashboard/removeServiceQueue/`, {
      id: modalDelete.id,
    }).then(() => {
      setRecord(record.filter((row) => row.id !== modalDelete.id));
      setModalDelete({ open: false, id: "" });
    });
  };

  const orderRecord = (type) => {
    if (type === "0") {
      setRecord(
        record.sort((a, b) => {
          if (moment(b.arrival).isBefore(moment(a.arrival))) {
            return -1;
          }
          if (!moment(b.arrival).isBefore(moment(a.arrival))) {
            return 1;
          }
          return 0;
        })
      );
    } else if (type === "2") {
      setRecord(
        record.sort((a, b) => {
          if (moment(a.arrival).isBefore(moment(b.arrival))) {
            return -1;
          }
          if (!moment(a.arrival).isBefore(moment(b.arrival))) {
            return 1;
          }
          return 0;
        })
      );
    } else {
      setRecord(
        record.sort((a, b) => {
          if (
            parseInt(a.risk_classification ? a.risk_classification : 0) <
            parseInt(b.risk_classification ? b.risk_classification : 0)
          ) {
            return 1;
          }
          if (
            parseInt(a.risk_classification ? a.risk_classification : 0) >
            parseInt(b.risk_classification ? b.risk_classification : 0)
          ) {
            return -1;
          }
          return 0;
        })
      );
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <BtnAdd
            onClick={() =>
              setModalForm({
                open: true,
                data: {},
              })
            }
            float="right"
          />

          <Tooltip title="Filtro" placement="top">
            <IconButton
              aria-label="Filtro"
              onClick={() => setModalFilter(true)}
              style={{ float: "right", marginTop: "-10px" }}
            >
              <Chip icon={<FilterList />} label="Filtro" variant="outlined" />
              {/* Filtros <FilterList /> */}
            </IconButton>
          </Tooltip>
          <FormControlLabel
            control={
              <Switch
                checked={myAttendance}
                onChange={() => setMyAttendance(!myAttendance)}
                color="primary"
              />
            }
            label="Ver somente os meus atendimentos"
            style={{ float: "right" }}
          />
          <div className="col-md-2 mr-3" style={{ float: "right" }}>
            <TextField
              select
              value={order}
              onChange={(e) => {
                orderRecord(e.target.value);
                setOrder(e.target.value);
              }}
              label="Ordenar por:"
              fullWidth
            >
              <MenuItem key="0" value="0">
                Data decrescente
              </MenuItem>
              <MenuItem key="2" value="2">
                Data crescente
              </MenuItem>
              <MenuItem key="1" value="1">
                Classificação de risco
              </MenuItem>
            </TextField>
          </div>
        </div>
        <div className="col-12 m-0 p-0 mt-3">
          {/* <Typography className={classes.text}>
          {moment().format("DD/MM/YYYY")}
        </Typography> */}
          <table className="table">
            {/* <thead>
              <tr>
                <th scope="col">Horario</th>
                <th scope="col">Paciente</th>
                <th scope="col"></th>
                <th scope="col">Profissional</th>
                <th scope="col">Serviço</th>
                <th scope="col"></th>
              </tr>
            </thead> */}
            <tbody>
              {record
                .filter(
                  (row) =>
                    (filter.status.length === 0 ||
                      filter.status.indexOf(row.status) !== -1) &&
                    (!filter.service || filter.service === row.service_id) &&
                    (!filter.risk_classification ||
                      filter.risk_classification === row.risk_classification) &&
                    (filter.patient.length === 0 ||
                      getStringToSearch(row.patient_name).includes(
                        getStringToSearch(filter.patient)
                      )) &&
                    (filter.professional.length === 0 ||
                      getStringToSearch(row.doctor_name).includes(
                        getStringToSearch(filter.professional)
                      )) &&
                    (!myAttendance || row.fk_ls_user_professional === auth)
                )
                .map((row, index) => (
                  <tr
                    key={index}
                    className={classes.textTble}
                    style={{
                      borderLeft: `8px solid ${getColor(row.status)}`,
                      borderBottom: `2px solid ${getColor(row.status)}`,
                    }}
                  >
                    <td
                      style={{
                        display: "inline-grid",
                      }}
                    >
                      {moment(row.arrival).format("DD/MM/YYYY HH:mm")}

                      {getChip(row.status)}
                    </td>

                    <td>
                      <ComponentProfile
                        profile={{
                          id: row.fk_ls_user_patient,
                          name: row.patient_name,
                          photo: row.patient_photo,
                          birthday: moment(row.birthday).format("DD/MM/YYYY"),
                          cpf: row.cpf,
                          mother_name: row.mother_name,
                          cns: row.cns,
                        }}
                      />
                    </td>
                    <td
                      style={{
                        display: "inline-grid",
                      }}
                    >
                      <>
                        {row.risk_classification &&
                          getClassification(row.risk_classification)}
                        <Chip
                          label={
                            row.type === "0"
                              ? "Demanda espontânea"
                              : row.type === "1"
                              ? "Agendado"
                              : "Encaminhado"
                          }
                          variant="outlined"
                          className="mt-2"
                        />
                      </>
                    </td>
                    <td>{row.doctor_name}</td>
                    <td>{row.service}</td>
                    <td>
                      <div className="d-flex">
                        {/* <Tooltip title="Histórico do paciente" placement="top">
                      <IconButton
                        style={{ padding: "6px" }}
                        color="primary"
                        onClick={() => {
                          setPatient(row.fk_ls_user_patient);
                          dispatch(viewHistory("personalData"));
                        }}
                      >
                        <HistoryIcon
                          style={{
                            fontSize: "19px",
                            margin: "0px",
                            padding: "0px",
                          }}
                        />
                      </IconButton>
                    </Tooltip> */}
                        <Tooltip title="Ações">
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            key={index}
                            onClick={handleClick(index)}
                            style={{ float: "right" }}
                          >
                            <MoreVert />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={open === index}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              // maxHeight: ITEM_HEIGHT * 4.5,
                              width: "28ch",
                              left: "1346px",
                            },
                          }}
                        >
                          {/* {(row.status === "0" || row.status === "4") && ( */}
                          <MenuItem
                            key="0"
                            onClick={() => setPatientNotWait(row)}
                          >
                            <Block
                              fontSize="small"
                              className="mr-2"
                              style={{ color: "#6a6a6a" }}
                            />
                            <Typography variant="inherit">
                              {row.status === "4"
                                ? "Cidadão retornou"
                                : "Não aguardou"}
                            </Typography>
                          </MenuItem>
                          {/* )} */}
                          {row.status === "0" &&
                            row.fk_ls_user_professional === auth && (
                              <MenuItem
                                key="1"
                                onClick={() => playConsult(row)}
                              >
                                <PlayArrow
                                  fontSize="small"
                                  className="mr-2"
                                  style={{ color: "#6a6a6a" }}
                                />
                                <Typography variant="inherit">
                                  Iniciar - {row.service}
                                </Typography>
                              </MenuItem>
                            )}

                          {false && row.status === "3" && (
                            <MenuItem
                              key="6"
                              onClick={() => {
                                setModalDeclaration({ open: true, data: row });
                                setOpen("");
                              }}
                            >
                              <PostAdd
                                fontSize="small"
                                className="mr-2"
                                style={{ color: "#6a6a6a" }}
                              />
                              <Typography variant="inherit">
                                Decl. comparecimento
                              </Typography>
                            </MenuItem>
                          )}

                          <MenuItem
                            key="2"
                            onClick={() => {
                              setPatient(row.fk_ls_user_patient);
                              dispatch(viewHistory("personalData"));
                              setOpen("");
                            }}
                          >
                            <History
                              fontSize="small"
                              className="mr-2"
                              style={{ color: "#6a6a6a" }}
                            />
                            <Typography variant="inherit">
                              Histórico do Paciente
                            </Typography>
                          </MenuItem>

                          <MenuItem
                            key="3"
                            onClick={() => {
                              setPatient(row.fk_ls_user_patient);
                              dispatch(viewHistory("consult"));
                              setOpen("");
                            }}
                          >
                            <ListAlt
                              fontSize="small"
                              className="mr-2"
                              style={{ color: "#6a6a6a" }}
                            />
                            <Typography variant="inherit">
                              Atendimentos
                            </Typography>
                          </MenuItem>

                          {/* {row.status === "0" && (
                            <> */}
                          <MenuItem
                            key="4"
                            onClick={() => {
                              setModalForm({
                                open: true,
                                data: row,
                              });
                              setOpen("");
                            }}
                          >
                            <Edit
                              fontSize="small"
                              className="mr-2"
                              style={{ color: "#6a6a6a" }}
                            />
                            <Typography variant="inherit">Editar</Typography>
                          </MenuItem>
                          <MenuItem
                            key="5"
                            onClick={() => {
                              setModalDelete({ open: true, id: row.id });
                              setOpen("");
                            }}
                          >
                            <Delete
                              fontSize="small"
                              className="mr-2"
                              style={{ color: "#6a6a6a" }}
                            />
                            <Typography variant="inherit">Excluir</Typography>
                          </MenuItem>
                          {/* </>
                          )} */}
                        </Menu>
                      </div>
                    </td>
                  </tr>
                ))}
              {record.filter(
                (row) =>
                  (filter.status.length === 0 ||
                    filter.status.indexOf(row.status) !== -1) &&
                  (!filter.service || filter.service === row.service_id) &&
                  (!filter.risk_classification ||
                    filter.risk_classification === row.risk_classification) &&
                  (filter.patient.length === 0 ||
                    getStringToSearch(row.patient_name).includes(
                      getStringToSearch(filter.patient)
                    )) &&
                  (filter.professional.length === 0 ||
                    getStringToSearch(row.doctor_name).includes(
                      getStringToSearch(filter.professional)
                    )) &&
                  (!myAttendance || row.fk_ls_user_professional === auth)
              ).length === 0 && (
                <tr>
                  <td colspan="5">
                    <NoRecord />
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <HistoryPatient id_patient={patient} />
        </div>
        <FormNewAttendance
          open={modalForm.open}
          data={modalForm.data}
          handleClose={() => setModalForm({ open: false, data: {} })}
          beforeSave={(e) => {
            if (e !== filter.start) {
              setFilter({
                ...filter,
                start: e,
              });
            } else {
              getData();
            }
          }}
          services={services}
        />
        <ModalFilter
          open={modal_filter}
          data={filter}
          handleClose={() => setModalFilter(false)}
          handleFilter={(e) => setFilter(e)}
          services={services}
        />
        <ModalFormAttendance
          open={modalAttendance.open}
          data={modalAttendance.data}
          handleClose={() => setModalAttendance({ open: false, data: {} })}
          beforeSave={(id) => {
            alterStatus(id, "3", true);
          }}
        />
        <ModalDeclarationComp
          open={modalDeclaration.open}
          data={modalDeclaration.data}
          handleClose={() => setModalDeclaration({ open: false, data: {} })}
        />
      </div>
      <SweetAlert
        show={modalDelete.open}
        warning
        showCancel
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        confirmBtnText="Excluir"
        confirmButtonColor="#bc0003"
        cancelBtnText="Cancelar"
        title="Remover o registro deste atendimento?"
        onConfirm={removeServiceQueue}
        onCancel={() => setModalDelete({ open: false, id: "" })}
      />
    </>
  );
}

export default withRouter(ListAttendance);
