import React, { Component } from "react";
import NoRecord from "components/Alert/NoRecord";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ViewLocated from "./ViewLocated";

export default class Complaints extends Component {
  state = {
    mode_list: true,
    page: 0,
    rowsPerPage: 10,
    rows: [],
    selected_appointment: null,
  };
  componentDidMount() {
    this.setState({ rows: this.props.list });
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const {
      rows,
      page,
      rowsPerPage,
      mode_list,
      selected_appointment,
    } = this.state;
    return mode_list ? (
      rows.length > 0 ? (
        <div
          className="col-12"
          style={{
            minWidth: "650",
            overflowX: "auto",
          }}
        >
          <Table className="">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Inserido por</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                  return (
                    <TableRow hover tabIndex={-1} key={n.fk_ls_pp_appointment}>
                      <TableCell component="th" scope="row">
                        {n.date}
                      </TableCell>
                      <TableCell>{n.name}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() =>
                            this.setState({
                              mode_list: false,
                              selected_appointment: n.fk_ls_pp_appointment,
                            })
                          }
                          color="primary"
                          aria-label="Visualizar"
                        >
                          <i className="zmdi zmdi-eye zmdi-hc-fw" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage="Registros por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `Mostrando ${from} - ${to} de ${count}`
                  }
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      ) : (
        <NoRecord />
      )
    ) : (
      <ViewLocated
        appointment={selected_appointment}
        onClose={() => this.setState({ mode_list: true })}
      />
    );
  }
}
