import { all, takeLatest, put, call, fork } from "redux-saga/effects";
import { delay } from "redux-saga";
import api from "services/api";

function* asyncSavePlateIndex(action) {
  try {
    const request = yield call(savePlateIndex, action.params);
    if (request.success) {
      yield put({
        type: "SAVE_PLATEINDEX_SUCCESS",
        payload: request
      });

      yield put({
        type: "SET_INIT_APPOINTMENT",
        payload: { id: request.idAppointment }
      });

      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao salvar!"
      });
    }
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  } catch (error) {
    yield put({
      type: "VIEW_ALERT_MESSAGE",
      message_type: "error",
      text_message: "Ocorreu um erro ao salvar!"
    });
    yield delay(8000);
    yield put({
      type: "CLOSE_ALERT_MESSAGE"
    });
  }
}

const savePlateIndex = async params => {
  const response = await api.post(
    `/microservice/od_indexplate/savePlateIndex/`,
    params
  );

  const res = await response.data;
  return res;
};

export function* requestSavePlateIndex() {
  yield takeLatest("SAVE_PLATEINDEX", asyncSavePlateIndex);
}

export default function* rootSaga() {
  yield all([fork(requestSavePlateIndex)]);
}
