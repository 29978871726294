import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Avatar,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import ShareIcon from "@material-ui/icons/Share";
import CircularProgress from "components/CircularProgress";
import BarProgress from "components/CircularProgress/ProgressBar";
import Api from "services/api";
import ViewPost from "./ViewPost";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Stories from "react-insta-stories";
import CloseIcon from "@material-ui/icons/Close";
import { getUrlPhoto } from "services/functions";
import moment from "moment";
import ViewAppointment from "./ViewAppointment";
import { useDispatch } from "react-redux";
import ModalSharesDesktop from "./ModalSharesDesktop";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: "10px",
    marginTop: theme.spacing(1),
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input_comment: {
    width: "70%",
    marginTop: "0px",
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginTop: "17px",
  },
  history_icon: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    cursor: "pointer",
  },
  icon: {
    cursor: "pointer",
  },
  share_icon: {
    float: "right",
  },
  emoji: {
    background: "white",
    borderRadius: "50%",
    padding: "4px",
    width: "48px",
    height: "48px",
    border: "2px solid #177493",
  },
  button_questionnaire: {
    width: "100%",
    background: "#177493",
    border: "none",
    color: "white",
    justifyContent: "space-between",
    display: "flex",
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "400",
  },
  close_storie: {
    float: "right",
    cursor: "pointer",
    marginRight: "20px",
    marginTop: "20px",
    position: "relative",
    color: "#969090",
    zIndex: 99999,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Feed({ history }) {
  const width = useSelector((state) => state.settings.width);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.authUser);
  const name = useSelector((state) => state.auth.name);
  const photo = useSelector((state) => state.auth.photo);
  const [posts, setPosts] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loaderProg, setLoaderProg] = useState(false);
  const [modalShares, setModalShares] = useState(false);
  const [postShares, setPostShares] = useState({});
  const [listStories, setListStories] = useState([]);
  const [mode, setMode] = useState({
    view: "feed",
    data: {},
  });

  const [storie, setStorie] = useState({
    open: false,
    index: 0,
  });

  const classes = useStyles();

  useEffect(() => {
    if (history.location.search) {
      Api.post("ms_blog/post/getFeedId/", {
        user: user,
        id: history.location.search.replace("?", ""),
      })
        .then((res) => {
          setMode({
            view: "post",
            data: {
              id: history.location.search.replace("?", ""),
              ...res.data,
            },
          });
          setLoader(false);
        })
        .catch(() => setLoader(false));
    } else {
      Api.post("ms_blog/post/getFeed/", { user })
        .then((res) => {
          setPosts(res.data);
          setLoader(false);
        })
        .catch(() => setLoader(false));
      dispatch({
        type: "SET_ROUTE",
        payload: "feed",
      });
    }
  }, []);

  useEffect(() => {
    if (mode.view === "post") {
      setMode({
        view: "feed",
        data: {},
      });
    }
  }, [history.location]);

  useEffect(() => {
    Api.post(`/ms_admin/stories/getListStoriesFeed/`, {
      date: moment().format("YYYY-MM-DD HH:mm:mm"),
    }).then((res) => {
      setListStories(res.data.record);
    });
  }, []);

  const handleLiked = (post, liked, view) => {
    Api.post("ms_blog/post/handleLiked/", {
      post,
      liked,
      user,
    }).then(() => {
      setPosts(
        posts.map((row) =>
          row.id === post
            ? {
                ...row,
                likes: liked
                  ? parseInt(row.likes) - 1
                  : parseInt(row.likes) + 1,
                liked: !row.liked,
              }
            : row
        )
      );

      if (view) {
        setMode({
          view: "post",
          data: {
            ...mode.data,
            likes: liked
              ? parseInt(mode.data.likes) - 1
              : parseInt(mode.data.likes) + 1,
            liked: !mode.data.liked,
          },
        });
      }
    });
  };

  const sendComment = (post, comment, view) => {
    if (comment) {
      Api.post("ms_blog/post/sendComment/", {
        post,
        user,
        comment,
      }).then((res) => {
        setPosts(
          posts.map((row) =>
            row.id === post
              ? {
                  ...row,
                  comments:
                    row.comments.length > 2
                      ? row.list_comments.unshift(res.data.comment)
                      : row.list_comments.push(res.data.comment),
                  my_comment: "",
                  // parseInt(
                }
              : row
          )
        );

        if (view) {
          setMode({
            view: "post",
            data: {
              ...mode.data,
              comments: parseInt(mode.data.comments) + 1,
            },
          });
        }
      });
    }
  };

  const handleGetComment = (value) => {
    setLoaderProg(true);
    Api.post("ms_blog/post/getFeedComenId/", { id: value.id })
      .then((res) => {
        setPosts(
          posts.map((row) =>
            value.id === row.id
              ? { ...row, list_comments: res.data, viewUpadteComment: true }
              : row
          )
        );
        setLoaderProg(false);
      })
      .catch(() => setLoader(false));
  };

  const handleSetComment = (value) => {
    setPosts(
      posts.map((row) =>
        value.id === row.id
          ? {
              ...row,
              list_comments: row.list_comments.filter(
                (ele, index) =>
                  index === row.list_comments.length - 1 ||
                  index === row.list_comments.length - 2
              ),
              viewUpadteComment: false,
            }
          : row
      )
    );
  };

  const handleChange = (post, value) => {
    setPosts(
      posts.map((row) =>
        row.id === post ? { ...row, my_comment: value } : row
      )
    );
  };

  // const handleViewComen = (value) => {
  //   var elmnt = document.getElementById(value);
  //   elmnt.scrollIntoView();
  // };

  const handleShareMobile = (title, id) => {
    navigator.share({
      title,
      text: title,
      url: `https://beta1.salutemplus.com/app/blog/post?post=${id}`,
    });
  };

  const handleShareDesktop = (value) => {
    setModalShares(true);
    setPostShares({
      title: value.title,
      url_share: `https://beta1.salutemplus.com/app/blog/post?post=${value.id}`,
      // url_share: `https://react.salutemplus.com/app/dashboard/feed/?${value.id}`,
    });
  };

  const handleSetFeed = () => {
    if (posts.lenght > 0) {
      history.push(`/app/dashboard/feed`);
      setMode({ view: "feed", data: {} });
    } else {
      Api.post("ms_blog/post/getFeed/", { user })
        .then((res) => {
          setPosts(res.data);
          setLoader(false);
        })
        .catch(() => setLoader(false));
      history.push(`/app/dashboard/feed`);
      setMode({ view: "feed", data: {} });
      dispatch({
        type: "SET_ROUTE",
        payload: "feed",
      });
    }
  };

  // const getData = () => {
  //   Api.post("/ms_system/dashboard/getCompromissesPatient", { user, date })
  //     .then((res) => {
  //       let aux = {};

  //       res.data.medicines.forEach((element) => {
  //         if (typeof aux[element.hour] !== "undefined") {
  //           aux[element.hour] = [
  //             ...aux[element.hour],
  //             { ...element, type: "1" },
  //           ];
  //         } else {
  //           aux[element.hour] = [{ ...element, type: "1" }];
  //         }
  //       });

  //       res.data.pauses.forEach((element) => {
  //         if (typeof aux[element.time] !== "undefined") {
  //           aux[element.time] = [
  //             ...aux[element.time],
  //             { ...element, type: "0" },
  //           ];
  //         } else {
  //           aux[element.time] = [{ ...element, type: "0" }];
  //         }
  //       });

  //       res.data.activities.forEach((element) => {
  //         if (typeof aux[element.time] !== "undefined") {
  //           aux[element.time] = [
  //             ...aux[element.time],
  //             { ...element, type: "2" },
  //           ];
  //         } else {
  //           aux[element.time] = [{ ...element, type: "2" }];
  //         }
  //       });

  //       res.data.events.forEach((element) => {
  //         if (typeof aux[element.hour] !== "undefined") {
  //           aux[element.hour] = [
  //             ...aux[element.hour],
  //             { ...element, type: "3" },
  //           ];
  //         } else {
  //           aux[element.hour] = [{ ...element, type: "3" }];
  //         }
  //       });

  //       res.data.compromisses.forEach((element) => {
  //         if (typeof aux[element.hour] !== "undefined") {
  //           aux[element.hour] = [
  //             ...aux[element.hour],
  //             { ...element, type: "3" },
  //           ];
  //         } else {
  //           aux[element.hour] = [{ ...element, type: "4" }];
  //         }
  //       });
  //       setListMed(aux);
  //     })
  //     .finally(() => setLoader(false));
  // };

  // const listStories = [
  //   {
  //     url: require("./Marketing_PortaldoPaciente_Instagram_Destaque.jpg"),
  //   },
  //   {
  //     url: require("./Marketing_ProntuarioEletronico_Instagram_Destaque.jpg"),
  //   },
  //   {
  //     url: require("./Marketing_Acompanhamento_Instagram_Destaque.jpg"),
  //   },
  //   {
  //     url: require("./Marketing_ILPI_Instagram_Destaque.jpg"),
  //   },
  // ];

  if (loader) {
    return <CircularProgress />;
  }

  if (mode.view === "feed") {
    return (
      <div className="w-100" style={{ marginTop: "-8px" }}>
        {storie.open ? (
          <Dialog
            fullScreen
            open={storie.open}
            onClose={() => setStorie({ open: false, index: 0 })}
            TransitionComponent={Transition}
          >
            <Stories
              stories={listStories.map((row) => ({
                content: (props) => (
                  <div
                    style={{
                      backgroundImage: `url(${`https://salutem-webapp-files.s3-sa-east-1.amazonaws.com/upload/stories/${row.id}/${row.file}`})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                    }}
                  >
                    <CloseIcon
                      onClick={() => setStorie({ open: false, index: 0 })}
                      className={classes.close_storie}
                    />
                  </div>
                ),
              }))}
              defaultInterval={10000}
              width="100%"
              height={window.screen.height}
              currentIndex={storie.index}
              onAllStoriesEnd={() => setStorie({ open: false, index: 0 })}
              storyStyles={{
                height: "100%",
              }}
              keyboardNavigation={true}
            />
          </Dialog>
        ) : (
          <div
            className={
              width < 960
                ? "col-12 m-0 p-0"
                : "col-12 d-flex justidy-content-end"
            }
          >
            <div
              className={
                width < 960 ? "" : "col-7 d-flex justify-content-end p-0 m-0"
              }
            >
              <div className={width >= 960 && "col-9 m-0 p-0"}>
                <div
                  className={
                    width < 960
                      ? classes.root
                      : "d-flex mt-4 shadow-sm p-2 bg-white justify-content-center"
                  }
                >
                  {listStories.map((row, index) => (
                    <Avatar
                      className={`${classes.history_icon} mr-2`}
                      src={`https://salutem-webapp-files.s3-sa-east-1.amazonaws.com/upload/stories/${row.id}/${row.file}`}
                      onClick={() => setStorie({ open: true, index })}
                    />
                  ))}
                </div>
                {width < 960 && <hr className="my-2" />}
                {localStorage.getItem("savedQuizze") !== "1" && (
                  <div
                    className="card mt-3"
                    style={{
                      backgroundColor: "#cce7ff",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      history.push("/app/dashboard/patient-quizes")
                    }
                  >
                    <div className="d-flex p-2">
                      <div style={{ width: "80%" }}>
                        <Typography
                          className="ml-3"
                          variant="subtitle1"
                          gutterBottom
                        >
                          <strong>Check up do dia.</strong>
                        </Typography>
                        <Typography
                          className="ml-3"
                          variant="body2"
                          gutterBottom
                        >
                          Conte como você está se sentindo, e mantenha seus
                          dados de saúde atualizados.
                        </Typography>
                      </div>
                      <div style={{ margin: "auto" }}>
                        {/* <SentimentVerySatisfiedIcon
                          className={classes.emoji}
                          fontSize="large"
                        /> */}
                        <span
                          className="emoji"
                          role="img"
                          aria-label={"Happy"}
                          aria-hidden="false"
                          style={{ fontSize: "35px" }}
                        >
                          😃
                        </span>
                      </div>
                    </div>
                    <div className="w-100">
                      <button className={classes.button_questionnaire}>
                        Clique e preencha agora <KeyboardArrowRightIcon />
                      </button>
                    </div>
                  </div>
                )}
                <Typography className="ml-3" variant="subtitle1" gutterBottom>
                  <strong style={width >= 960 ? { fontSize: "20px" } : {}}>
                    Últimas Notícias
                  </strong>
                </Typography>
                {posts.map((row, index) => (
                  <>
                    <div className={width < 960 ? "card" : "card shadow"}>
                      <div
                        className="col-12 pl-0 pr-0"
                        style={{
                          backgroundColor: "#cce7ff",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          alt={row.title}
                          src={row.photo}
                          className="w-100"
                          onClick={() =>
                            setMode({
                              view: "post",
                              data: row,
                            })
                          }
                        />
                      </div>
                      <div
                        className="col-12 mt-2"
                        onClick={() =>
                          setMode({
                            view: "post",
                            data: row,
                          })
                        }
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <Typography variant="subtitle2" gutterBottom>
                          <strong>{row.title}</strong>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {row.summary}
                        </Typography>
                      </div>
                      <div id={row.id} className={classes.root}>
                        <div
                          className="d-flex"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <ThumbUpOutlinedIcon
                            className={classes.icon}
                            color={row.liked ? "primary" : "default"}
                            onClick={() =>
                              handleLiked(row.id, row.liked, false)
                            }
                            style={{
                              cursor: "pointer",
                              marginRight: "9px",
                            }}
                          />
                          <span>{row.likes}</span>
                        </div>
                        <div
                          className="d-flex"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <ChatBubbleOutlineIcon
                            // onClick={() => handleViewComen(row.id)}
                            color={row.viewUpadteComment ? "primary" : ""}
                            onClick={() =>
                              row.viewUpadteComment
                                ? handleSetComment(row)
                                : handleGetComment(row)
                            }
                            className={classes.icon}
                            style={{
                              cursor: "pointer",
                              marginRight: "9px",
                            }}
                          />
                          <span>{row.comments}</span>
                        </div>
                        <div
                          className="d-flex"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <ShareIcon
                            className={classes.share_icon}
                            onClick={() =>
                              width < 960
                                ? handleShareMobile(row.title, row.id)
                                : handleShareDesktop(row)
                            }
                            style={{
                              cursor: "pointer",
                              marginRight: "9px",
                            }}
                          />
                          <span
                            onClick={() =>
                              width < 960
                                ? handleShareMobile(row.title, row.id)
                                : handleShareDesktop(row)
                            }
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Compartilhar
                          </span>
                        </div>
                      </div>
                      {row.list_comments.length > 0 && (
                        <>
                          <hr className="w-100 mt-1 mb-3" />

                          {row.list_comments.map((comment) => (
                            <div className="d-flex col-12 p-0 avatar-text mr-auto pl-3">
                              <Avatar
                                className={classes.icon}
                                alt={comment.name}
                                src={getUrlPhoto(comment.user, comment.photo)}
                              />
                              <div className="ml-2">
                                <span>
                                  <strong>{comment.name}</strong>
                                </span>
                                <p>{comment.comment}</p>
                              </div>
                            </div>
                          ))}
                          {loaderProg ? (
                            <div className="col-12 d-flex justify-content-center">
                              <BarProgress />
                            </div>
                          ) : (
                            !row.viewUpadteComment && (
                              <span
                                className="text-primary pl-3"
                                onClick={() => {
                                  handleGetComment(row);
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                Ver mais comentários ...
                              </span>
                            )
                          )}
                        </>
                      )}

                      {row.viewUpadteComment && (
                        <span
                          className="text-primary pl-3"
                          onClick={() => {
                            handleSetComment(row);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Ver menos comentários ...
                        </span>
                      )}

                      <hr className="w-100 mt-1 mb-1" />
                      <div className={classes.root}>
                        <Avatar className={classes.icon} src={photo} />
                        <TextField
                          label="Escreva um comentário..."
                          value={row.my_comment ? row.my_comment : ""}
                          className={`w-100 ${classes.input_comment}`}
                          onChange={(e) => handleChange(row.id, e.target.value)}
                        />
                        <SendIcon
                          color="primary"
                          className="mt-3"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            sendComment(row.id, row.my_comment, false)
                          }
                        />
                      </div>
                    </div>

                    {index === 3 &&
                      localStorage.getItem("savedQuizze") !== "1" && (
                        <div
                          className="card mt-3"
                          style={{
                            backgroundColor: "#cce7ff",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            history.push("/app/dashboard/patient-quizes")
                          }
                        >
                          <div className="d-flex p-2">
                            <div style={{ width: "80%" }}>
                              <Typography
                                className="ml-3"
                                variant="subtitle1"
                                gutterBottom
                              >
                                <strong>Check up do dia.</strong>
                              </Typography>
                              <Typography
                                className="ml-3"
                                variant="body2"
                                gutterBottom
                              >
                                Conte como você está se sentindo, e mantenha
                                seus dados de saúde atualizados.
                              </Typography>
                            </div>
                            <div style={{ margin: "auto" }}>
                              {/* <SentimentVerySatisfiedIcon
                                className={classes.emoji}
                                fontSize="large"
                              /> */}
                              <span
                                className="emoji"
                                role="img"
                                aria-label={"Happy"}
                                aria-hidden="false"
                                style={{ fontSize: "35px" }}
                              >
                                😃
                              </span>
                            </div>
                          </div>
                          <div className="w-100">
                            <button className={classes.button_questionnaire}>
                              Clique e preencha agora <KeyboardArrowRightIcon />
                            </button>
                          </div>
                        </div>
                      )}
                  </>
                ))}
              </div>
            </div>
            {width >= 960 && (
              <div className={"col-5 p-2"}>
                <div className="col-10 mt-3 card shadow-sm">
                  <div className="col-12 d-flex pt-2 m-0 p-0">
                    <div className="col-10 p-2">
                      <span
                        style={{
                          fontSize: "17px",
                          fontWeight: "600",
                        }}
                      >
                        Próximos compromissos
                      </span>
                    </div>
                    <div className="col-2 d-flex justify-content-end p-2">
                      {/* <MoreVertIcon /> */}
                    </div>
                  </div>
                  <div className="p-2">
                    <ViewAppointment user={user} />
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12" style={{ marginTop: "80px" }}></div>
          </div>
        )}
        <ModalSharesDesktop
          open={modalShares}
          onClose={() => setModalShares(false)}
          post={postShares}
        />
      </div>
    );
  } else {
    return (
      <div className="w-100">
        <ViewPost
          photo={photo}
          user={user}
          name={name}
          setLogin={() => history.push("/login")}
          record={mode.data}
          handleLiked={handleLiked}
          sendComment={sendComment}
          back={() => handleSetFeed()}
        />
      </div>
    );
  }
}
