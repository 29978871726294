import React from "react";
import { Card } from "reactstrap";
import GraphicFinance from "./graphicFinance";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CountUp from "react-countup";
import Chip from "@material-ui/core/Chip";
import BarProgress from "components/CircularProgress/ProgressBar";
import "./styles.css";

const useStyles = makeStyles({
  root: {
    overflow: "scroll",
  },
  text: {
    fontSize: "13px",
    fontWeight: "500",
    letterSpacing: "0.4px",
  },
  textConta: {
    fontWeight: "400 !important",
    fontSize: "14px",
  },
  listHove: {
    padding: "1px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
});

const Finance = ({ dadosDash }) => {
  const classes = useStyles();
  return (
    <>
      <span
        style={{
          marginLeft: window.screen.width < 500 ? "50px" : "160px",
          fontSize: "24px",
          position: "absolute",
          zIndex: 800,
          fontWeight: "bold",
          marginTop: "200px",
          color: "#252525",
        }}
      >
        {" "}
        Em desenvolvimento
      </span>
      <Card
        className="shadow border-0 p-0 m-1 animated slideInUpTiny animation-duration-4"
        style={{ opacity: 0.5 }}
      >
        {dadosDash.finance !== undefined ? (
          <>
            <Typography className="pt-3 ml-3" variant="h6" gutterBottom>
              Balanço Financeiro{" "}
            </Typography>
            <div className="col-12 d-flex mt-3">
              <div className="col-6 mb-3">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex">
                      <h1
                        className="mr-2 mb-0 jr-font-weight-medium d-flex"
                        style={{
                          fontSize: "25px !important",
                          fontWeight: "590",
                        }}
                      >
                        <span className="mr-1">R$</span>
                        <CountUp
                          start={-875.039}
                          end={
                            dadosDash.finance.current_balance !== ""
                              ? dadosDash.finance.current_balance
                              : 0
                          }
                          duration={2.3}
                          separator="."
                          decimals={2}
                          decimal=","
                        />
                      </h1>
                    </div>
                    <div className="d-flex">
                      <p className="text-grey">Saldo do Dia</p>
                      <p
                        className="ml-1 mt-1 jr-chart-up"
                        style={{
                          fontSize: "11px",
                          fontWeight: "400",
                        }}
                      >
                        <CountUp start={0} end={64} duration={4} />
                        % <i className="zmdi zmdi-caret-up" />
                      </p>
                    </div>
                  </div>
                  <div style={{ fontSize: "13px" }}>
                    <div className="font-weight-bold">
                      <span
                        style={{ backgroundColor: "#1B9CFC" }}
                        className="size-10 lighten-1 rounded-circle d-inline-block mr-2"
                      />
                      Conta Corrente| R${" "}
                      {dadosDash.finance.balance_cc !== ""
                        ? dadosDash.finance.balance_cc
                        : 0}
                    </div>
                    <div className="font-weight-bold">
                      <span
                        style={{ backgroundColor: "#182C61" }}
                        className="size-10 lighten-1 rounded-circle d-inline-block mr-2"
                      />
                      Conta Garantida| R${" "}
                      {dadosDash.finance.balance_total !== ""
                        ? dadosDash.finance.balance_total
                        : 0}
                    </div>
                    <div className="font-weight-bold">
                      <span
                        style={{ backgroundColor: "#25CCF7" }}
                        className="size-10 lighten-1 rounded-circle d-inline-block mr-2"
                      />
                      Cartão de Crédito| R${" "}
                      {dadosDash.finance.balance_credit !== ""
                        ? dadosDash.finance.balance_credit
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 d-flex justify-content-center">
                <div className="row">
                  <GraphicFinance dadosGraphi={dadosDash.finance} />
                </div>
              </div>
            </div>
            <div align="center" style={{ color: "#d4d4d4" }}>
              _____________________________________________
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className={`mb-3 ${classes.textConta}`} align="center">
                  <Chip
                    label={
                      <>
                        <span className="mr-1">Contas a Receber | R$</span>
                        <span style={{ fontWeight: "800" }}>
                          <CountUp
                            start={-875.039}
                            end={
                              dadosDash.finance.total_revenue !== ""
                                ? dadosDash.finance.total_revenue
                                : 0
                            }
                            duration={2}
                            separator="."
                            decimals={2}
                            decimal=","
                          />
                        </span>
                      </>
                    }
                    color="primary"
                    size="small"
                  />
                </div>
                <div
                  style={{
                    overflow: "auto",
                    height: "175px",
                  }}
                >
                  {dadosDash.finance.revenue.length > 0 ? (
                    <>
                      {dadosDash.finance.revenue.map((e, index) => (
                        <>
                          {/* <Tooltip
                            TransitionComponent={Zoom}
                            title={`Nome: ${e.title} \n Data: ${e.date}`}
                          > */}
                          <div
                            className={`col-12 d-flex border-bottom p-1 mt-2 ${classes.listHove}`}
                          >
                            <span
                              className={`col-7 d-flex justify-content-start font`}
                            >
                              {e.title}
                            </span>
                            <span className="col-5 d-flex justify-content-end fontSaldo">
                              R$ {e.value}
                            </span>
                          </div>
                          {/* </Tooltip> */}
                        </>
                      ))}
                    </>
                  ) : (
                    <div
                      className={`col-12 d-flex justify-content-center mt-5`}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          color: "#a6a6a6",
                          fontStyle: "italic",
                        }}
                      >
                        Sem dados
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className={`mb-3 ${classes.textConta}`} align="center">
                  <Chip
                    label={
                      <>
                        <span className="mr-1">Contas a Pagar | R$</span>
                        <span style={{ fontWeight: "800" }}>
                          <CountUp
                            start={-875.039}
                            end={
                              dadosDash.finance.total_expenses !== ""
                                ? dadosDash.finance.total_expenses
                                : 0
                            }
                            duration={2}
                            separator="."
                            decimals={2}
                            decimal=","
                          />
                        </span>
                      </>
                    }
                    color="primary"
                    size="small"
                  />
                </div>
                <div
                  style={{
                    overflow: "auto",
                    height: "175px",
                  }}
                >
                  {dadosDash.finance.expenses.length > 0 ? (
                    <>
                      {dadosDash.finance.expenses.map((e, index) => (
                        <>
                          <div
                            className={`col-12 d-flex border-bottom mt-1 ${classes.listHove}`}
                          >
                            <span
                              className={`col-6 d-flex justify-content-start font`}
                            >
                              {e.title}
                              {/* {String(e.title).substring(0, 13)
                                ? String(e.title).substring(0, 13)
                                : "-"}
                              {e.title && e.title.length > 13 ? "..." : ""} */}
                            </span>
                            <span className="col-6 d-flex justify-content-end fontSaldo">
                              R$ {e.value}
                            </span>
                          </div>{" "}
                        </>
                      ))}
                    </>
                  ) : (
                    <div className={`col-12 d-flex justify-content-center`}>
                      <p
                        style={{
                          textAlign: "center",
                          color: "#a6a6a6",
                          fontStyle: "italic",
                        }}
                      >
                        Sem dados
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="col-12 d-flex justify-content-center m-2">
            <BarProgress />
          </div>
        )}
      </Card>
    </>
  );
};

export default Finance;
