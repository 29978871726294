import axios from "axios";
import { getBaseUrl } from "services/functions";

const Api = axios.create({
  baseURL: getBaseUrl(),
  dataType: "json",
  headers: {
    Accept: "application/x-www-form-urlencoded",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

Api.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (config.method === "post") {
      return {
        ...config,
        data: { ...config.data, userAudit: localStorage.getItem("userAudit") },
      };
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default Api;
