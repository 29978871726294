import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { BtnSave, BtnCan } from "components/Button";

import Api from "services/api";

function EditHealthPlan({
  multiple,
  event,
  calendar,
  plan,
  plan_card,
  edit,
  close,
}) {
  const [healthPlans, setHealthPlans] = useState([]);
  const [planSelected, setPlanSelected] = useState(plan);
  const [health_plan_card, setHealthPlanCard] = useState(plan_card);
  const formPayments = [
    "Boleto Bancário",
    "Cartão de crédito",
    "Cartão de débito",
    "Moeda Corrente",
    "Transferência Bancária",
  ];

  useEffect(() => {
    Api.post(`/ms_system/calendar/getHealthPlansCalendar/`, {
      calendar,
    }).then((res) => {
      if (res.data.record.length > 0) {
        setHealthPlans(res.data.record);
      }
    });
  }, []);

  function save() {
    Api.post(`/ms_system/calendar/editHealthPlanEvent/`, {
      multiple,
      event,
      planSelected,
      health_plan_card,
    }).then(() => {
      if (formPayments.indexOf(planSelected) === -1) {
        let info = healthPlans.find((row) => row.plan_id === planSelected);
        edit(info.plan_id, info.name, health_plan_card);
      } else {
        edit(planSelected, planSelected, "");
      }
    });
  }

  return (
    <Dialog open={true} onClose={() => close()}>
      <DialogTitle className="text-primary font-weight-semibold">
        Editar Tipo do evento
      </DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-12">
            <TextField
              id="health_plan"
              select
              required
              label="Faturamento:"
              value={planSelected}
              onChange={(e) => {
                setPlanSelected(e.target.value);
                setHealthPlanCard(
                  formPayments.indexOf(e.target.value) !== -1
                    ? ""
                    : health_plan_card
                );
              }}
              fullWidth
              // style={{ marginTop: "0px" }}
            >
              {/* <MenuItem key="0" value="particular">
                Particular
              </MenuItem> */}
              {formPayments.map((pay, idx) => (
                <MenuItem key={idx} value={pay}>
                  {pay}
                </MenuItem>
              ))}
              {healthPlans.map((row) => (
                <MenuItem key={row.plan_id} value={row.plan_id}>
                  {row.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {planSelected && formPayments.indexOf(planSelected) === -1 && (
            <div className="col-12">
              <TextField
                id="health_plan_card"
                label="Carteirinha:"
                value={health_plan_card}
                onChange={(e) => setHealthPlanCard(e.target.value)}
                fullWidth
                margin="normal"
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="col-12">
          <BtnCan title={"Cancelar"} onClick={() => close()} float="left" />
          <BtnSave title={"Salvar"} onClick={() => save()} float="right" />
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default EditHealthPlan;
