import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { BtnSave, BtnCan } from "components/Button";
import moment from "moment";
import Api from "services/api";
import { getDataFirebase, getEnviromentFirebase } from "services/firebase";

function EditStatusEvent({
  multiple,
  event,
  value,
  otherVal,
  edit,
  close,
  doctor,
  info,
}) {
  const [status, setStatus] = useState(value);
  const [otherValue, setOtherValue] = useState(otherVal ? otherVal : "--:--");
  function save() {
    let opt =
      status === "3"
        ? "arrival"
        : status === "4"
        ? "entered"
        : status === "5"
        ? "departure"
        : "";
    Api.post(`/ms_system/calendar/editStatusEvent/`, {
      multiple,
      event,
      status,
      opt,
      otherValue,
    }).then(() => {
      edit(status, opt, otherValue);
      if (status === "3") {
        getDataFirebase()
          .child(`waiting-room${getEnviromentFirebase()}/` + doctor)
          .push({
            date: moment().format("DD/MM/YYYY HH:mm"),
            patient: info.name,
            patient_id: info.id,
            photo: info.photo,
          });
      }
    });
  }

  return (
    <Dialog open={true} onClose={() => close()}>
      <DialogTitle className="text-primary font-weight-semibold">
        Status do agendamento
      </DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-md-12">
            <TextField
              autoFocus
              id="status"
              select
              label="Status:"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              fullWidth
              style={{ minWidth: "400px", marginTop: "0px" }}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem key="1" value="1">
                Agendado
              </MenuItem>
              <MenuItem key="2" value="2">
                Confirmado
              </MenuItem>
              <MenuItem key="3" value="3">
                Aguardando atendimento
              </MenuItem>
              <MenuItem key="4" value="4">
                Em atendimento
              </MenuItem>
              <MenuItem key="5" value="5">
                Finalizado
              </MenuItem>
              <MenuItem key="6" value="6">
                Não compareceu
              </MenuItem>
            </TextField>
          </div>
          {status === "3" && (
            <div className="col-12 mt-3">
              <TextField
                type="time"
                id="value"
                label="Horário chegada:"
                value={otherValue}
                onClick={() => {
                  if (otherValue === "--:--") {
                    setOtherValue(moment().format("HH:mm"));
                  }
                }}
                onChange={(e) => setOtherValue(e.target.value)}
                fullWidth
                style={{ minWidth: "400px" }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          )}
          {status === "4" && (
            <div className="col-12 mt-3">
              <TextField
                type="time"
                id="value"
                label="Entrada consultório:"
                value={otherValue}
                onClick={() => {
                  if (otherValue === "--:--") {
                    setOtherValue(moment().format("HH:mm"));
                  }
                }}
                onChange={(e) => setOtherValue(e.target.value)}
                fullWidth
                style={{ minWidth: "400px" }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          )}
          {status === "5" && (
            <div className="col-12 mt-3">
              <TextField
                type="time"
                id="value"
                label="Horário saída:"
                value={otherValue}
                onClick={() => {
                  if (otherValue === "--:--") {
                    setOtherValue(moment().format("HH:mm"));
                  }
                }}
                onChange={(e) => setOtherValue(e.target.value)}
                fullWidth
                style={{ minWidth: "400px" }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="col-12">
          <BtnCan title={"Cancelar"} onClick={() => close()} float="left" />
          <BtnSave title={"Salvar"} onClick={() => save()} float="right" />
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default EditStatusEvent;
