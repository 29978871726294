import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Api from "services/api";
import { showMsg } from "services/functions";

import {
  Typography,
  makeStyles,
  Icon,
  TextField,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { ButtonLarge } from "components/Button";
import CustomSlider from "components/CustomSlider";

import CloseIcon from "@material-ui/icons/Close";
import Dr_prancheta from "assets/images/dr_prancheta.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    width: "100%",
  },
  dFlex: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  box1: (props) => ({
    border: "solid 2px #c1c1c1",
    borderRadius: "25px",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 40px",
    marginTop: "15px",
    cursor: "pointer",
    backgroundColor: props.physical_pain ? "#c6ecff" : "transparent",
    "&:hover": {
      backgroundColor: "rgb(220 243 255)",
    },
  }),
  box2: (props) => ({
    border: "solid 2px #c1c1c1",
    borderRadius: "25px",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 40px",
    marginTop: "15px",
    cursor: "pointer",
    backgroundColor: props.emotional_pain ? "#c6ecff" : "transparent",
    "&:hover": {
      backgroundColor: "rgb(220 243 255)",
    },
  }),
  box3: (props) => ({
    border: "solid 2px #c1c1c1",
    borderRadius: "25px",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 40px",
    marginTop: "15px",
    cursor: "pointer",
    backgroundColor: props.good ? "#c6ecff" : "transparent",
    "&:hover": {
      backgroundColor: "rgb(220 243 255)",
    },
  }),
  icon: {
    overflow: "inherit",
    fontSize: "40px",
    width: "auto",
    height: "auto",
  },
  positionBad: {
    position: "absolute",
    bottom: "-5px",
    left: 0,
  },
  positionGood: {
    position: "absolute",
    bottom: "-5px",
    right: 0,
  },
}));

export default function Humor({
  progress,
  handleNext,
  handleBack,
  handleClose,
  setFeeling,
}) {
  const gender = useSelector(({ auth }) => auth.gender);
  const dispatch = useDispatch();
  const width = useSelector((state) => state.settings.width);

  const [state, setState] = useState({
    humor: null,
    physical_pain: false,
    what_physical_pain: [],
    emotional_pain: false,
    what_emotional_pain: [],
    good: false,
  });

  const classes = useStyles(state);

  function handleSave() {
    Api.post("microservice/patientportal/saveHumor", state)
      .then(({ data }) => {
        if (data.success) {
          if (!state.good) {
            let humor = state.what_emotional_pain.reduce(
              (max, pain) => (max = max <= pain.value ? pain.value : max),
              state.what_physical_pain.reduce(
                (max, pain) => (max <= pain.value ? pain.value : max),
                0
              )
            );
            setFeeling({ ...data.feeling, humor });
          } else setFeeling(data.feeling);
          localStorage.setItem("savedQuizze", "1");
          handleClose();
        } else {
          throw true;
        }
      })
      .catch(() => {
        showMsg("error", "Não foi possível savar as informações", dispatch);
      });
  }

  function validate(show) {
    if (state.good) {
      if (state.humor) return true;
      else {
        show &&
          showMsg(
            "error",
            "Movimente a barra para nos dizer como está se sentindo",
            dispatch
          );
        return false;
      }
    } else {
      if (state.physical_pain || state.emotional_pain) {
        //Se algum motivo selecionado
        if (state.physical_pain && state.emotional_pain) {
          //se os dois motivos foram selecionados
          if (
            state.what_emotional_pain.filter((el) => el.value).length > 0 &&
            state.what_physical_pain.filter((el) => el.value).length > 0
          )
            //se os dois possuiem dores
            return true;
          else {
            show &&
              showMsg(
                "error",
                "Selecione pelo menos um problema para cada opção escolhida",
                dispatch
              );
            return false;
          }
        } else if (state.physical_pain) {
          //se apenas dores físicas foram selecionadas
          if (state.what_physical_pain.filter((el) => el.value).length > 0)
            return true;
          //se dores físicas possuir dores
          else {
            show &&
              showMsg(
                "error",
                "Selecione pelo menos uma dor física que estaja sentindo",
                dispatch
              );
            return false;
          }
        } else if (state.emotional_pain) {
          //se apenas dores emocionais for selecionadas
          if (state.what_emotional_pain.filter((el) => el.value).length > 0)
            return true;
          //se dorese emocionais possuir dores
          else {
            show &&
              showMsg(
                "error",
                "Selecione pelo menos uma dor emocional que esteja sentindo",
                dispatch
              );
            return false;
          }
        }
      } else {
        show &&
          showMsg("error", "Selecione pelo menos um dos motivos", dispatch);
        return false;
      }
    }
  }

  function getArr() {
    if (gender === "0")
      return [
        "Azia / Má Digestão",
        "Câimbra",
        "Cólica Renal",
        "Diarreia",
        "Dificuldade Ou Dor Ao Urinar",
        "Dor De Barriga",
        "Dor De Cabeça",
        "Dor Articular",
        "Dor Lombar",
        "Dor De Dente",
        "Dor De Garganta",
        "Dor De Ouvido",
        "Dor Musculo Esquelética (Boneco)",
        "Dor Torácica",
        "Enjoo/ Vomito",
        "Febre / Calafrio",
        "Intestino Preso",
        "Tonteira/Vertigem",
        "Tosse Seca",
        "Tosse Secretiva/Coriza",
        "Outros",
      ];
    else
      return [
        "Azia / Má Digestão",
        "Câimbra",
        "Cólica Menstrual",
        "Cólica Renal",
        "Diarreia",
        "Dificuldade Ou Dor Ao Urinar",
        "Dor De Barriga",
        "Dor De Cabeça",
        "Dor Articular",
        "Dor Lombar",
        "Dor De Dente",
        "Dor De Garganta",
        "Dor De Ouvido",
        "Dor Musculo Esquelética (Boneco)",
        "Dor Torácica",
        "Enjoo/ Vomito",
        "Febre / Calafrio",
        "Intestino Preso",
        "Tonteira/Vertigem",
        "Tosse Seca",
        "Tosse Secretiva/Coriza",
        "Outros",
      ];
  }

  function toogleGood() {
    if (state.good) setState({ ...state, good: !state.good });
    else
      setState({
        ...state,
        good: !state.good,
        emotional_pain: false,
        what_emotional_pain: [],
        physical_pain: false,
        what_physical_pain: [],
      });
  }

  return (
    <div className={classes.root}>
      <div className={classes.dFlex}>
        <div className="col-12 col-sm-12 col-md-8">
          {progress === 0 && (
            <div className="container text-left">
              <Typography variant="subtitle1" gutterBottom>
                <strong>Está sentindo alguma dessas dores?</strong>
              </Typography>
              <div className="row d-flex justify-content-center my-2">
                <div className={classes.box3} onClick={toogleGood}>
                  <Icon className={classes.icon}>😀</Icon>
                  <span style={{ fontSize: "25px", paddingLeft: "20px" }}>
                    Não
                  </span>
                </div>
                {state.good && (
                  <div className="w-100 mx-4">
                    <div className="col-12 my-3 px-0">
                      <Typography variant="subtitle2" gutterBottom>
                        <strong>Quão bem você está se sentindo?</strong>
                      </Typography>
                      <span className={classes.positionBad}>Mal</span>
                      <CustomSlider
                        step={1}
                        marks
                        min={1}
                        max={10}
                        value={state.humor}
                        onChange={(event, newValue) =>
                          setState({ ...state, humor: newValue })
                        }
                        style={{
                          color: !state.humor
                            ? "#d1d1d1"
                            : state.humor >= 6
                            ? "#00B1E2"
                            : state.humor > 3
                            ? "#EEE800"
                            : "#D6201B",
                        }}
                        valueLabelDisplay="on"
                        aria-labelledby="continuous-slider"
                      />
                      <span className={classes.positionGood}>Bem</span>
                    </div>
                  </div>
                )}
                {!state.good && (
                  <div
                    className={classes.box1}
                    onClick={() =>
                      setState({
                        ...state,
                        physical_pain: !state.physical_pain,
                      })
                    }
                  >
                    <Icon className={classes.icon}>🤕</Icon>
                    <span style={{ fontSize: "25px", paddingLeft: "20px" }}>
                      Dores Físicas
                    </span>
                  </div>
                )}
                {state.physical_pain && !state.good && (
                  <div className="w-100 mx-4">
                    <div className="col-12 my-3 px-0">
                      <TextField
                        select
                        fullWidth
                        label="Informe o(s) problema(s) físicos:"
                        value=""
                        onChange={(e) => {
                          setState({
                            ...state,
                            what_physical_pain: [
                              ...state.what_physical_pain,
                              { name: e.target.value, value: null },
                            ],
                          });
                        }}
                        InputLabelProps={{ shrink: true }}
                      >
                        {getArr()
                          .filter(
                            (opt) =>
                              !state.what_physical_pain.find(
                                (el) => el.name === opt
                              )
                          )
                          .map((row) => (
                            <MenuItem key={row} value={row}>
                              {row}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="row">
                      {state.what_physical_pain.map((row, index) => (
                        <div className="col-12" key={row.name}>
                          {width > 500 ? (
                            <div className="d-flex align-items-center my-2">
                              <span style={{ width: "15vw" }}>{row.name}</span>
                              <CustomSlider
                                step={1}
                                marks
                                min={1}
                                max={10}
                                value={row.value}
                                onChange={(event, newValue) => {
                                  let aux = state.what_physical_pain;
                                  aux[index].value = newValue;
                                  setState({
                                    ...state,
                                    what_physical_pain: aux,
                                  });
                                }}
                                style={{
                                  color: !row.value
                                    ? "#d1d1d1"
                                    : row.value >= 8
                                    ? "#D6201B"
                                    : row.value < 4
                                    ? "#00B1E2"
                                    : "#EEE800",
                                }}
                                valueLabelDisplay="on"
                                aria-labelledby="continuous-slider"
                              />
                              <IconButton
                                onClick={() =>
                                  setState({
                                    ...state,
                                    what_physical_pain: state.what_physical_pain.filter(
                                      (el) => el.name !== row.name
                                    ),
                                  })
                                }
                                color="secondary"
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <>
                              <div className="d-flex align-items-center">
                                <span>{row.name}</span>
                                <IconButton
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      what_physical_pain: state.what_physical_pain.filter(
                                        (el) => el.name !== row.name
                                      ),
                                    })
                                  }
                                  color="secondary"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </div>
                              <CustomSlider
                                step={1}
                                marks
                                min={1}
                                max={10}
                                value={row.value}
                                onChange={(event, newValue) => {
                                  let aux = state.what_physical_pain;
                                  aux[index].value = newValue;
                                  setState({
                                    ...state,
                                    what_physical_pain: aux,
                                  });
                                }}
                                style={{
                                  color: !row.value
                                    ? "#d1d1d1"
                                    : row.value >= 8
                                    ? "#D6201B"
                                    : row.value < 4
                                    ? "#00B1E2"
                                    : "#EEE800",
                                }}
                                valueLabelDisplay="on"
                                aria-labelledby="continuous-slider"
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {!state.good && (
                  <div
                    className={classes.box2}
                    onClick={() =>
                      setState({
                        ...state,
                        emotional_pain: !state.emotional_pain,
                      })
                    }
                  >
                    <Icon className={classes.icon}>😫</Icon>
                    <span style={{ fontSize: "25px", paddingLeft: "20px" }}>
                      Dores Emocionais
                    </span>
                  </div>
                )}
                {state.emotional_pain && !state.good && (
                  <div className="w-100 mx-4">
                    <div className="col-12 my-3 px-0">
                      <TextField
                        select
                        fullWidth
                        label="Informe o(s) problema(s) emocionais:"
                        value=""
                        onChange={(e) => {
                          setState({
                            ...state,
                            what_emotional_pain: [
                              ...state.what_emotional_pain,
                              { name: e.target.value, value: null },
                            ],
                          });
                        }}
                        InputLabelProps={{ shrink: true }}
                      >
                        {[
                          "Agitado",
                          "Ansiedade",
                          "Apatia",
                          "Depressão",
                          "Desarmonia",
                          "Estressado",
                          "Medo",
                          "Preocupação",
                          "Raiva",
                          "Tristeza",
                        ]
                          .filter(
                            (opt) =>
                              !state.what_emotional_pain.find(
                                (el) => el.name === opt
                              )
                          )
                          .map((row) => (
                            <MenuItem key={row} value={row}>
                              {row}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="row">
                      {state.what_emotional_pain.map((row, index) => (
                        <div className="col-12" key={row.name}>
                          {width > 500 ? (
                            <div className="d-flex align-items-center my-2">
                              <span style={{ width: "15vw" }}>{row.name}</span>
                              <CustomSlider
                                step={1}
                                marks
                                min={1}
                                max={10}
                                value={row.value}
                                onChange={(event, newValue) => {
                                  let aux = state.what_emotional_pain;
                                  aux[index].value = newValue;
                                  setState({
                                    ...state,
                                    what_emotional_pain: aux,
                                  });
                                }}
                                style={{
                                  color: !row.value
                                    ? "#d1d1d1"
                                    : row.value >= 8
                                    ? "#D6201B"
                                    : row.value < 4
                                    ? "#00B1E2"
                                    : "#EEE800",
                                }}
                                valueLabelDisplay="on"
                                aria-labelledby="continuous-slider"
                              />
                              <IconButton
                                onClick={() =>
                                  setState({
                                    ...state,
                                    what_emotional_pain: state.what_emotional_pain.filter(
                                      (el) => el.name !== row.name
                                    ),
                                  })
                                }
                                color="secondary"
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <>
                              <div className="d-flex align-items-center">
                                <span>{row.name}</span>
                                <IconButton
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      what_emotional_pain: state.what_emotional_pain.filter(
                                        (el) => el.name !== row.name
                                      ),
                                    })
                                  }
                                  color="secondary"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </div>
                              <CustomSlider
                                step={1}
                                marks
                                min={1}
                                max={10}
                                value={row.value}
                                onChange={(event, newValue) => {
                                  let aux = state.what_emotional_pain;
                                  aux[index].value = newValue;
                                  setState({
                                    ...state,
                                    what_emotional_pain: aux,
                                  });
                                }}
                                style={{
                                  color: !row.value
                                    ? "#d1d1d1"
                                    : row.value >= 8
                                    ? "#D6201B"
                                    : row.value < 4
                                    ? "#00B1E2"
                                    : "#EEE800",
                                }}
                                valueLabelDisplay="on"
                                aria-labelledby="continuous-slider"
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="col-12 col-sm-12 col-md-4 justify-content-end">
          <img src={Dr_prancheta} alt="Dra. Salutem+" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ButtonLarge
            onClick={handleClose}
            style={{
              padding: "1px",
              width: "unset",
              minWidth: "100px",
              float: "left",
            }}
          >
            Sair
          </ButtonLarge>
          <ButtonLarge
            onClick={validate() ? handleSave : () => validate(true)}
            style={{
              padding: "1px",
              width: "unset",
              minWidth: "100px",
              float: "right",
              backgroundColor: validate() ? "#3d8b40" : "unset",
              color: validate() ? "#fff" : "#8c8c8c",
            }}
          >
            Confirmar
          </ButtonLarge>
        </div>
      </div>
    </div>
  );
}
