import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Api from "services/api";
import { debounce, showMsg } from "services/functions";

import AutocompleteGeneric from "./AutocompleteGeneric";

import Chip from "@material-ui/core/Chip";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ComponentsProfile from "components/ComponentsProfile/ProfileNew";

export default function AutocompleteUser({
  handleChange,
  error,
  label,
  value,
  autoFocus,
}) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);

  const getOptions = async (term) => {
    if (term.length > 2) {
      setLoader(true);
      Api.post("/microservice/autocomplete/getAllUsers/", {
        term: term.toLowerCase(),
      })
        .then((res) => {
          setOptions([
            ...res.data.record,
            {
              id: "new",
              name: term ? term : "",
              photo: null,
              cpf: "",
              mother_name: null,
              birthday: "",
            },
          ]);
          setLoader(false);
        })
        .catch(() => {
          showMsg("error", "Não foi possível encontrar resultados", dispatch);
          setOptions([]);
          setLoader(false);
        });
    }
  };

  return (
    <AutocompleteGeneric
      multiple={false}
      limitTags={1}
      filterOptions={(x) => x}
      disableCloseOnSelect
      onChange={(obj) => {
        if (obj) {
          handleChange(obj);
        } else {
          handleChange({
            id: "",
            name: "",
          });
        }
      }}
      onInputChange={debounce(getOptions)}
      value={value ? value : null}
      getOptionLabel={(option) => option.name}
      label={label ? label : "Informe o usuário:"}
      options={options}
      loading={loader}
      autoFocus={autoFocus}
      blurOnSelect={true}
      clearOnBlur={true}
      shrink={true}
      error={error}
      renderOption={(option) => {
        return (
          <div className="row w-100">
            <div className="col-8">
              <ComponentsProfile profile={option ? option : {}} />
            </div>
            <div className="col-4">
              {option.id === "new" && (
                <Chip
                  className="m-1 ml-3"
                  size="small"
                  color="primary"
                  icon={<PersonAddIcon />}
                  label="Novo usuário"
                />
              )}
            </div>
          </div>
        );
      }}
    />
  );
}
