export const saveAgaQuestionnaire = (
  data,
  questionnaire,
  total,
  descTotal,
  patient,
  doctor
) => ({
  type: 'REQUEST_SAVE_AGA',
  data,
  questionnaire,
  total,
  descTotal,
  patient,
  doctor,
});

export const getQuestions = () => ({
  type: 'GET_QUESTIONS_AGA',
});

export const getHistoryAga = patient => ({
  type: 'REQUEST_HISTORY_AGA',
  patient,
});

export const getHistoryRegisterAga = header => ({
  type: 'REQUEST_HISTORY_REGISTER_AGA',
  header,
});
