import React from "react";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import { useSelector } from "react-redux";
import FormRegisterPoint from "./FormRegisterPoint";
import CardChooseAddress from "components/Forms/CardChooseAddress";
import CardViewAddress from "components/Forms/CardViewAddress";

export default function PointRegister({ match }) {
  const authUser = useSelector((state) => state.auth.authUser);
  const address = useSelector((state) => state.auth.address);

  return (
    <div className="animated slideInUpTiny animation-duration-3">
      <ContainerHeader
        title={<IntlMessages id="module.point_register" />}
        match={match}
      />
      {!address ? (
        <CardChooseAddress />
      ) : (
        <>
          <CardViewAddress />
          <FormRegisterPoint user={authUser} address={address} />
        </>
      )}
    </div>
  );
}
