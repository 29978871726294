export const handleAddFunction = (active, color, texto, hidden, icon, id) => ({
  type: "HANDLE_ADD_FUNC",
  payload: { active, color, texto, hidden, icon, id }
});

export const setValProcedure = val => ({
  type: "SET_VAL_PROCEDURE",
  payload: { valor: val }
});

export const setNameProcedure = val => ({
  type: "SET_NAME_PROCEDURE",
  payload: { valor: val }
});

export const saveProcedure = (
  doctor,
  nameProcedure,
  valorProcedure,
  idEdit
) => ({
  type: "CAD_NEW_PROCEDURE",
  payload: { doctor, nameProcedure, valorProcedure, idEdit }
});

export const requestListDoctorProcedures = doctor => ({
  type: "REQUEST_DOCTOR_PROCEDURES",
  payload: { doctor }
});

export const delProcedure = (idProcedure, doctor) => ({
  type: "DEL_PROCEDURE",
  payload: { idProcedure, doctor }
});

export const getDataProcedureForEdit = idProcedure => ({
  type: "GET_PROCEDURE_FOR_EDIT",
  payload: { idProcedure }
});
