import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@material-ui/core";
import Api from "services/api";
import apiFile from "services/apiFile";

import Header from "components/Header/index";
import PersonalData from "./PersonalData";
import Photo from "./ComponentPhoto";
import Terms from "./ComponentTerms";
import HealthPlan from "./HealthPlan";
import moment from "moment";

function getSteps() {
  return ["Dados Pessoais", "Plano de Saúde", "Adicionar Foto", "Termos"];
}

export default function RegisterPatient({ match, history }) {
  const dispatch = useDispatch();
  const initState = {
    activeStep: 0,
    skipped: new Set(),
  };
  const initValidate = {
    cpf: true,
    username: true,
    email: true,
    password: false,
  };
  const initData = {
    personalData: {
      date: "",
      id: "",
      idData: "",
      idNaturalty: "",
      name: "",
      username: "",
      cpf: "",
      sex: "",
      naturalty: "",
      email: "",
      cellphone: "",
      password: "",
      confirmPassword: "",
      error: false,
    },
    photo: { image: "", blob: "" },
    healthPlan: [
      {
        id: "",
        plan_id: "",
        plan_name: "",
        register: "",
        validate: "",
        identfy: "",
        alter: false,
      },
    ],
  };

  const [state, setState] = useState(initState);
  const [data, setData] = useState(initData);
  const [cadOk, setCadOk] = useState(false);
  const [validate, setValidate] = useState(initValidate);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PersonalData
            handleNext={handleNext}
            getState={getState}
            stateInit={data.personalData}
            validateData={validate}
            history={history}
          />
        );
      case 1:
        return (
          <HealthPlan
            user={data.personalData.id}
            handleBack={handleBack}
            handleNext={handleNext}
            getState={getState}
            stateInit={data.healthPlan}
          />
        );
      case 2:
        return (
          <Photo
            handleBack={handleBack}
            handleNext={handleNext}
            getState={getState}
            stateInit={data.photo}
          />
        );
      case 3:
        return (
          <Terms
            handleBack={handleBack}
            handleNext={registerForm}
            getState={getState}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const uploadPhotoUser = async (photo, user) => {
    let formData = new FormData();
    formData.append("user", user);
    let block = photo.image.split(";");
    let contentType = block[0].split(":")[1];
    let realData = block[1].split(",")[1];
    let blob = await b64toBlob(realData, contentType);
    formData.append("image", blob);

    apiFile.post(`/ms_system/user/savePhotoRegister/`, formData);
  };

  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const registerForm = async (license, terms) => {
    const obj = { ...data, terms: { license, terms }, type: "3" };
    Api.post(`/ms_system/user/saveRegister`, obj).then(async (res) => {
      if (res.data.success) {
        if (res.data.photo.image !== "") {
          uploadPhotoUser(res.data.photo, res.data.id);
        }
        setCadOk(true);
        await dispatch({ type: "RESET_ALL", opt: "SIGNIN" });
        await dispatch({
          type: "SIGNIN_USER_SUCCESS",
          payload: {
            user: res.data.id,
            address: "",
            secretary: false,
          },
        });
        localStorage.setItem("userAudit", res.data.id);
        localStorage.setItem("init", moment().format("YYYY-MM-DD HH:mm"));

        if (window.location.search) {
          history.push("/app/family-accompaniment");
        } else {
          history.push("/app/profile");
        }
      }
    });
  };

  const getState = async (stateForm, form) => {
    if (form === "validate") {
      await setValidate(stateForm);
    } else {
      await setData({ ...data, [form]: { ...stateForm } });
    }
  };

  const handleNext = () => {
    const { activeStep } = state;
    setState({
      ...state,
      activeStep: activeStep + 1,
    });
  };
  const handleBack = () => {
    const { activeStep } = state;
    setState({
      ...state,
      activeStep: activeStep - 1,
    });
  };
  const handleReset = () => {
    setState({
      ...state,
      activeStep: 0,
    });
  };

  const steps = getSteps();
  const { activeStep } = state;

  return (
    <div className={`app-container`}>
      <div className="app-main-container">
        <div className={`app-header`}>
          <Header left={true} />
        </div>
        <main className="app-main-content-wrapper">
          <div className="container mt-2">
            <ContainerHeader title="Cadastrar" match={match} />
            <Card className="shadow border-0">
              <CardBody>
                <div className="container">
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    className="horizontal-stepper-linear"
                  >
                    {steps.map((label, index) => {
                      return (
                        <Step
                          key={label}
                          className={`horizontal-stepper ${
                            index === activeStep ? "active" : ""
                          }`}
                        >
                          <StepLabel className="stepperlabel">
                            {label}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <div>
                    {activeStep === steps.length ? (
                      <div>
                        <Typography className="my-2">
                          All steps completed - you&quot;re finished
                        </Typography>
                        <Button onClick={handleReset} className="jr-btn">
                          Reset
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Typography className="my-2">
                          {getStepContent(activeStep)}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <SweetAlert
            show={cadOk}
            showConfirm={false}
            success
            title="Cadastrado com sucesso."
          />
        </main>
      </div>
    </div>
  );
}
