import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Downshift from "downshift";
import Api from "services/api";
import AddIcon from "@material-ui/icons/Add";
import ModalCreateMedicine from "./ModalCreateMedicine";
import axios from "axios";

const styles = {
  container: {
    flexGrow: 1,
    width: "100%",
  },
  root: {
    backgroundColor: "#fff",
  },
  selected: {
    backgroundColor: "#e2e2e8 !important",
  },
};

class AutocompleteMedicine extends Component {
  state = {
    suggestions: [],
    selected: null,
    modal_create: false,
    error: false,
    cancel: false,
    valueAtual: null,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ error: nextProps.error });
    if (nextProps.medicine.id) {
      const { id, name } = nextProps.medicine;
      this.setState({ selected: [{ id, name_display: name }] });
    }
  }

  componentDidMount() {
    let nextProps = this.props;
    this.setState({ error: nextProps.error });
    if (nextProps.medicine.id) {
      const { id, name } = nextProps.medicine;
      this.setState({ selected: [{ id, name_display: name }] });
    }
  }

  getSuggestions = async (e) => {
    const { value } = e.target;
    this.setState({ valueAtual: value });
    if (value) {
      // setTimeout(() => {
      if (this.state.cancel !== false) {
        await this.state.cancel.cancel();
        await this.setState({ cancel: false });
      }
      await this.requestSuggestions(value, e);
      // }, 600);
      // setTimeout(() => {
      // }, 500);
    }
  };

  requestSuggestions = async (value, e) => {
    if (value.length > 2 && e.keyCode !== 38 && e.keyCode !== 40) {
      const CancelToken = await axios.CancelToken;
      await this.setState({ cancel: CancelToken.source() });

      let url = this.props.plusPosology
        ? "/microservice/autocomplete/getMedicineName/"
        : "/microservice/autocomplete/getMedicine/";
      await Api.post(
        url,
        {
          term: value,
          user: this.props.user,
        },
        {
          cancelToken: this.state.cancel.token,
        }
      ).then((res) => {
        const { data } = res;
        if (data.length > 0) {
          this.setState({
            suggestions: data,
          });
        } else {
          this.setState({
            suggestions: [
              {
                id: "new",
                name_display: value,
              },
            ],
          });
        }
      });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  };

  getClassImage = (type) => {
    let imgIcon = "";
    switch (type) {
      case "Similar":
        imgIcon = require("assets/images/consult/similar.jpg");
        break;
      case "Generico":
        imgIcon = require("assets/images/consult/generico.jpg");
        break;
      case "Referencia":
      case "Especifico":
        imgIcon = require("assets/images/consult/referencia.jpg");
        break;
      case "Biológicos":
        imgIcon = require("assets/images/consult/biologico.jpg");
        break;
      default:
        imgIcon = require("assets/images/consult/outros.jpg");
        break;
    }
    return imgIcon;
  };

  getTarge = (targe) => {
    if (targe === "TP") {
      return require("assets/images/consult/Preta.svg");
    } else if (targe === "TV") {
      return require("assets/images/consult/Vermelha.svg");
    } else {
      return require("assets/images/consult/Branca.svg");
    }
  };

  renderSuggestion(params) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = params;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === suggestion.id;

    const { classes } = this.props;
    let imgIcon = this.getClassImage(suggestion.medtype_display);
    let targe = this.getTarge(suggestion.targe);
    if (suggestion.id !== "new") {
      return (
        <MenuItem
          {...itemProps}
          key={index}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
            minWidth: "250",
            overflowX: window.screen.width < 900 ? "auto" : "",
          }}
          classes={{
            root: classes.root,
            selected: classes.selected,
          }}
        >
          <div style={{ width: "100%" }}>
            <span style={{ float: "left" }}>
              {!this.props.medicineUse && (
                <>
                  <img
                    src={imgIcon}
                    width="30"
                    alt="Tipo do medicamento"
                    height="30"
                  />
                  <img
                    src={targe}
                    alt="Tarja do medicamento"
                    width="30"
                    height="30"
                    style={{ marginLeft: "30px" }}
                  />
                </>
              )}
              <strong
                style={{ marginLeft: !this.props.medicineUse ? "30px" : "" }}
              >
                {suggestion.active_principle} ({suggestion.name_display}){" "}
                {suggestion.formulation}
              </strong>{" "}
              - <small>{suggestion.form}</small>
            </span>

            {!this.props.medicineUse && (
              <span
                style={{
                  float: "right",
                  paddingRight: "10px",
                  fontWeight: "300",
                }}
              >
                {suggestion.laboratory_display}
              </span>
            )}
          </div>
        </MenuItem>
      );
    } else {
      return (
        <MenuItem
          {...itemProps}
          key={suggestion.id}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
          classes={{
            root: classes.root,
            selected: classes.selected,
          }}
        >
          <div style={{ width: "100%" }}>
            <span style={{ float: "left" }}>
              <strong style={{ marginLeft: "10px" }}>
                {suggestion.name_display}
              </strong>
              <Chip
                className="m-1 ml-3"
                size="small"
                color="primary"
                icon={<AddIcon />}
                label="Novo medicamento"
              />
            </span>
          </div>
        </MenuItem>
      );
    }
  }

  renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        error={this.state.error}
        required
        {...other}
        inputref={ref}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...InputProps,
        }}
        onKeyUp={(e) => {
          if (e.target.value !== this.state.valueAtual) {
            this.getSuggestions(e);
          }
        }}
        onChange={this.handleChange}
        autoFocus={true}
        InputLabelProps={{ shrink: true }}
      />
    );
  }

  render() {
    const { classes, handleChangeAutocomplete } = this.props;
    const { suggestions, selected, modal_create } = this.state;

    return (
      <>
        <Downshift
          onSelect={(selectedItem) => {
            let item = suggestions.filter((row) => {
              return this.props.plusPosology
                ? row.name_display + " - " + row.form === selectedItem
                : row.name_display +
                    " " +
                    row.formulation +
                    " - " +
                    row.form ===
                    selectedItem;
            });
            if (item.length > 0) {
              if (item[0].id !== "new") {
                handleChangeAutocomplete(item[0]);
              } else {
                this.setState({ modal_create: item[0].name_display });
              }
            }
          }}
          selectedItem={selected ? selected[0].name_display : null}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            inputValue,
            selectedItem,
            highlightedIndex,
          }) => (
            <div className={classes.container}>
              {this.renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  id: "ac-medicine",
                }),
                label: "Medicamento:",
                margin: "normal",
              })}
              {isOpen ? (
                <Paper square>
                  {suggestions.map((suggestion, index) =>
                    this.renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({
                        item: this.props.plusPosology
                          ? suggestion.name_display + " - " + suggestion.form
                          : suggestion.name_display +
                            " " +
                            suggestion.formulation +
                            " - " +
                            suggestion.form,
                      }),
                      highlightedIndex,
                      selectedItem,
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
        <ModalCreateMedicine
          open={modal_create}
          user={this.props.user}
          onSave={(obj) => handleChangeAutocomplete(obj)}
          close={() => this.setState({ modal_create: false })}
        />
      </>
    );
  }
}

AutocompleteMedicine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutocompleteMedicine);
