import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import ViewRequestExam from "./ViewRequestExam";
import DataTable from "components/Tables/DataTable";

export default function ExamsPatient({ record }) {
  const [modalView, setModalView] = React.useState({ open: false, data: {} });

  return (
    <>
      <DataTable
        name="Pedidos de Exame"
        columns={["Data", "Tipo do Exame", "Visualizar"]}
        rows={record.map((row, index) => [
          row.date,
          row.type_exam,
          <div style={{ whiteSpace: "nowrap" }}>
            <Tooltip title="Visualizar">
              <IconButton
                color="primary"
                aria-label="Visualizar"
                onClick={() => setModalView({ open: true, data: row })}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          </div>,
        ])}
        hideSearch={true}
      />

      <ViewRequestExam
        open={modalView.open}
        data={modalView.data}
        handleClose={() => setModalView({ open: false, data: {} })}
      />
    </>
  );
}
