import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import api from "services/api";
import { delay } from "redux-saga";
const cadNewProcedure = async (
  doctor,
  nameProcedure,
  valorProcedure,
  idEdit
) => {
  const response = await api.post(
    `/microservice/addProcedure/cadNewProcedure/`,
    {
      doctor,
      nameProcedure,
      valorProcedure,
      idEdit
    }
  );

  return response;
};

const getDoctorProcedures = async doctor => {
  const response = await api.post(
    `/microservice/addProcedure/getDoctorProcedures/`,
    { doctor }
  );

  const data = await response.data.record;
  const aux = [];
  data.forEach(element => {
    element = {
      ...element,
      check: false
    };
    aux.push(element);
  });
  return aux;
};

const delProcedure = async (id, doctor) => {
  const response = await api.post(`/microservice/addProcedure/delProcedure/`, {
    id,
    doctor
  });

  return response;
};

const getInfoProcedureForEdit = async id => {
  const response = await api.post(
    `/microservice/addProcedure/getInfoProcedureForEdit/`,
    { id }
  );
  const data = await response.data.record;
  return { data };
};

function* asyncCadNewProcedure(action) {
  try {
    const resultCadNewProcedure = yield call(
      cadNewProcedure,
      action.payload.doctor,
      action.payload.nameProcedure,
      action.payload.valorProcedure,
      action.payload.idEdit
    );
    if (resultCadNewProcedure.data.success) {
      yield put({
        type: "HANDLE_CAD_EXAMS_GROUP_SUCCESS",
        payload: { resultCadNewProcedure: resultCadNewProcedure }
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });

      yield put({
        type: "REQUEST_DOCTOR_PROCEDURES",
        payload: { doctor: action.payload.doctor }
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao executar esta operação"
      });
    }
    yield put({
      type: "HANDLE_ADD_FUNC",
      payload: {
        active: "view",
        color: "primary",
        texto: "Adicionar",
        hidden: "hidden",
        icon: "plus"
      }
    });
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log(error);
  }
}

function* asyncDoctorProcedures(action) {
  try {
    const listDoctorProcedures = yield call(
      getDoctorProcedures,
      action.payload.doctor
    );
    yield put({
      type: "REQUEST_DOCTOR_PROCEDURE_SUCCESS",
      payload: { listDoctorProcedures: listDoctorProcedures }
    });
  } catch (error) {
    console.log(error);
  }
}

function* asyncDelProcedure(action) {
  try {
    const resultDelProcedure = yield call(
      delProcedure,
      action.payload.idProcedure,
      action.payload.doctor
    );
    if (resultDelProcedure.data.success) {
      yield put({
        type: "REMOVE_PROCEDURE_SUCESS",
        id: action.payload.idProcedure
      });
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "success",
        text_message: "Salvo com sucesso!"
      });

      yield put({
        type: "REQUEST_DOCTOR_PROCEDURES",
        payload: { doctor: action.payload.doctor }
      });
    } else {
      yield put({
        type: "VIEW_ALERT_MESSAGE",
        message_type: "error",
        text_message: "Ocorreu um erro ao executar esta operação"
      });
    }
    yield delay(5000);
    yield put({ type: "CLOSE_ALERT_MESSAGE" });
  } catch (error) {
    console.log(error);
  }
}

function* asyncGetProcedureForEdit(action) {
  try {
    const InfosProcedure = yield call(
      getInfoProcedureForEdit,
      action.payload.idProcedure
    );
    yield put({
      type: "REQUEST_INFO_PROCEDURE_SUCCESS",
      payload: {
        IdProcedure: InfosProcedure.data[0].idProcedure,
        nameProcedure: InfosProcedure.data[0].description,
        valProcedure: InfosProcedure.data[0].price
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export function* requestCadNewProcedure() {
  yield takeEvery("CAD_NEW_PROCEDURE", asyncCadNewProcedure);
}
export function* requestDoctorProcedures() {
  yield takeEvery("REQUEST_DOCTOR_PROCEDURES", asyncDoctorProcedures);
}
export function* requestDeleteProcedure() {
  yield takeEvery("DEL_PROCEDURE", asyncDelProcedure);
}
export function* requestProcedureForEdit() {
  yield takeEvery("GET_PROCEDURE_FOR_EDIT", asyncGetProcedureForEdit);
}

export default function* rootSaga() {
  yield all([
    fork(requestCadNewProcedure),
    fork(requestDoctorProcedures),
    fork(requestDeleteProcedure),
    fork(requestProcedureForEdit)
  ]);
}
